import React from 'react'
import PropTypes from 'prop-types'
import { Result, Button } from 'antd'

class Status403 extends React.Component {
  render () {
    return(
      <div>
      <Result
        status="403"
        title="Toegang geweigerd"
        subTitle="Sorry, je bent niet gemachtigd om deze actie uit te voeren."
        extra={<Button onClick={() => window.history.go(-2)} type="primary">Ga terug</Button>}
      />
      </div>
    );
  }
}

export default Status403;
