/*global gantt*/
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Spin,
  Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
// import '../TotalSchedule.css';
// import 'http://export.dhtmlx.com/gantt/api.js';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGanttAction, updateScheduleSettingsAction, updateScheduleBlockedDatesAction, addDayScheduleAction, updateDayScheduleAction, updateScheduleGanttAction, getScheduleAction, getScheduleBlockedDatesAction, getScheduleGanttAction } from '../../../../../actions/scheduleActions';
import Settings from '../Form/Settings';
import BlockedDates from '../Form/BlockedDates';
import DaySchedule from '../Form/DaySchedule';
import Calendar from '../Form/Calendar';
import ScheduleSteps from '../Steps/ScheduleSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { showSuccess } from '../../../../../utils/Notifications';
var onAfterTaskUpdate;

class Schedule extends Component
{

  constructor(props) {
    super(props);

    this.state = {
      gantt_calendar: {data: [], links: []},
      loading: true,
      isFetching: false,
      json: {},
    }
  }

  componentDidMount()
  {
      const script = document.createElement("script");

      script.src = "https://export.dhtmlx.com/gantt/api.js";
      script.async = true;

      document.body.appendChild(script);

      //
    if(this.props.match.params.id) {
      this.props.getScheduleAction(this.props.match.params.id).then(() => {
        this.props.getGanttAction(this.props.match.params.id).then(() => {
          //this.props.getScheduleGanttAction(this.props.match.params.id).then(() => {
          this.props.getScheduleBlockedDatesAction(this.props.match.params.id).then(() => {
            this.setState({gantt_calendar: this.props.gantt_calendar}, () => {
              // alert('Gantt data received');
              // console.log(this.props.gantt_calendar);
              this.setupGantt();
              // this.setupGantt();
            });
          });
          //});
        });
      });
    }
  }

  setupGantt = () => {

    // gantt
    gantt.resetSkin();
    // gantt.config.start_date = new Date('2019-01-01 00:00');
    // gantt.config.end_date = new Date('2022-12-31 00:00');
    gantt.config.start_date = null;
    gantt.config.end_date = null;
    //
    for (var i = 0; i < this.props.blockeddates.length; i++)
    {
      gantt.setWorkTime({
        date: new Date(this.props.blockeddates[i].start_date),
        hours: false
      });
    }
    //
    // // work days
    if(this.props.schedule.monday_workday != 1){ gantt.setWorkTime({day: 1, hours: false}); }
    if(this.props.schedule.tuesday_workday != 1){ gantt.setWorkTime({day: 2, hours: false}); }
    if(this.props.schedule.wednesday_workday != 1){ gantt.setWorkTime({day: 3, hours: false}); }
    if(this.props.schedule.thursday_workday != 1){ gantt.setWorkTime({day: 4, hours: false}); }
    if(this.props.schedule.friday_workday != 1){ gantt.setWorkTime({day: 5, hours: false}); }
    if(this.props.schedule.saturday_workday != 1){ gantt.setWorkTime({day: 6, hours: false}); }
    if(this.props.schedule.sunday_workday != 1){ gantt.setWorkTime({day: 0, hours: false}); }
    //
    gantt.config.xml_date = "%Y-%m-%d %H:%i";
    // gantt.config.date_grid = "%d-%m-%Y";
    // gantt.config.autosize = "y";
    gantt.config.autofit = true;
    //
    gantt.config.drag_project = true;
    //
    gantt.config.work_time = true; //disable scheduling on non-work days
    //
    gantt.templates.timeline_cell_class = function (task, date) {
      if(!gantt.isWorkTime(date)) {
        return "weekend";
      }
      return "";
    };

    //
    gantt.config.columns = [
      {name: "text", tree: true,resize: true},
    ];


    //gantt.config.details_on_dblclick = false;
    gantt.config.drag_progress = false;
    gantt.config.drag_links = false;
    gantt.templates.task_text = function(start, end, task){ return (task.parent ? '' : task.text); };

    gantt.attachEvent("onParse", () => {
      this.setState({loading: false});
    });

    onAfterTaskUpdate = gantt.attachEvent("onAfterTaskUpdate", (id,item) => {

      var json = gantt.serialize();
      // console.log(id,item, json);
      // this.setState({isFetching: true});

      this.setState({gantt_calendar: json});

      // this.setState({isFetching: false});
      // this.props.updateScheduleBlockedDatesAction(this.props.match.params.id, json).then(() => {
      //   this.setState({isFetching: false});
      // });
    });

    // init
    gantt.init(this.ganttContainer);
    gantt.clearAll();
    gantt.refreshData();
    gantt.parse(this.state.gantt_calendar);

    // render
    gantt.render();

  }

  componentWillUnmount(){
    gantt.detachEvent(onAfterTaskUpdate);
    // gantt.detachEvent(onBeforeTaskUpdate);
  }

  save() {
    this.setState({isFetching: true});

    var ganttJSON = (typeof this.state.gantt_calendar == 'string' ? JSON.parse(this.state.gantt_calendar) : this.state.gantt_calendar);

    this.props.updateScheduleBlockedDatesAction(this.props.match.params.id, {data: ganttJSON.data, links: ganttJSON.links}).then(() => {
      this.setState({isFetching: false});
      showSuccess('Planning opgeslagen');
    });
  }

  exportToPDF()
  {

    gantt.exportToPDF({
        // server: "https://export.dhtmlx.com/beta/gantt",
        server: "https://export.dhtmlx.com/gantt",
        raw: true,
        header: '<style>.weekend { background-color: #f0f0f0; }</style>',
    });
  }

  closeAll()
  {
    gantt.eachTask(function(task2close){
        if (task2close.$level == 0) { //is a project, not a task
        	gantt.close(task2close.id);
        }//endif
    });
  }

  openAll()
  {
    gantt.eachTask(function(task2open){
        if (task2open.$level == 0) { //is a project, not a task
        	gantt.open(task2open.id);
            console.log(task2open.id);
        }//endif
    });

  }

  render()
  {
    return (
      <div>
        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleSteps current={3} {...this.props} />
          </Card>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card>

                <Alert
                  message="Let op!"
                  description="Voer je in deze balkenplanning wijzigen door? Kies dan voor de knop Opslaan om de gedane wijzigingen ook daadwerkelijk op te slaan."
                  type="info"
                  showIcon
                  style={{marginBottom: 15}}
                />

                <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={() => { this.save(); }}>Opslaan</Button>
                <Button type="default" onClick={() => { this.exportToPDF(); }}>Printen</Button>
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card bodyStyle={{height: 600}}>
              {this.state.isFetching && (
                <div style={{ zIndex: 10, display: 'flex', backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', top: 0, left: 0, right: 0, bottom:0, paddingTop: 30, justifyContent: 'center', alignItems: 'center'}}>
                  <Spin tip="Planning opslaan." />
                </div>
              )}
              {this.state.loading ? (
                <div style={{height: '100%',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Spin tip="Planning samenstellen. Dit kan even duren." />
                </div>
              ) : null}
                <div style={{marginBottom: 24}}>
                  <Button style={{marginRight: 10}} onClick={() => { this.closeAll(); }}>Alles sluiten</Button>
                  <Button type="" onClick={() => { this.openAll(); }}>Alles openen</Button>
                </div>
                <div key={'gant1'} ref={ (input) => { this.ganttContainer = input } } style={ { width: '100%', height: '100%' } }></div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    schedule: state.schedule.schedule,
    blockeddates: state.schedule.blockeddates,
    gantt_calendar : state.schedule.gantt_calendar,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    updateScheduleBlockedDatesAction : bindActionCreators(updateScheduleBlockedDatesAction, dispatch),
    addDayScheduleAction : bindActionCreators(addDayScheduleAction, dispatch),
    updateDayScheduleAction : bindActionCreators(updateDayScheduleAction, dispatch),
    updateScheduleGanttAction : bindActionCreators(updateScheduleGanttAction, dispatch),
    getScheduleAction : bindActionCreators(getScheduleAction, dispatch),
    getScheduleGanttAction : bindActionCreators(getScheduleGanttAction, dispatch),
    getScheduleBlockedDatesAction : bindActionCreators(getScheduleBlockedDatesAction, dispatch),
    getGanttAction : bindActionCreators(getGanttAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
