import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormAction, addCustomerFormAction, updateCustomerFormAction, resetFormAction, previewFormAction } from '../../../actions/formActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import PageHeader from '../../../components/PageHeader/PageHeader';
import LibrarySideMenu from '../LibrarySideMenu';
import FormSteps from '../Steps/FormSteps';


class LibraryFormDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      locked: false,
      formData: null,
      show_preview_buttons: (this.props.match.params.id ? true : false),
      loading: true,
    };
  }

  componentDidMount() {

    this.props.resetFormAction();

    if(this.props.match.params.id) {

      this.props.getFormAction(this.props.match.params.id).then(() => {

        this.setState({ locked: this.props.customer_form.locked });

        if(this.IsJsonString(this.props.customer_form.form))
        {
          this.setState({formData: JSON.parse(this.props.customer_form.form)}, () => {

            this.refs.FormBuilder.parse(JSON.parse(this.props.customer_form.form));

            this.setState({ loading: false });
          });
        }
        else
        {
            this.setState({ loading: false });
        }
      });
    }
    else
    {
      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': 'form', 'label': 'Nieuwe sectie', elements: []}]);

      this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.form.validateFields((err, values) => {

      if(err)
      {
        return showError('Niet alle velden zijn ingevuld.');
      }

      values.form = JSON.stringify(this.state.formData);

      if (this.props.match.params.id)
      {
        this.props.updateCustomerFormAction(this.props.match.params.id, values).then(() => {

          if(redirect) //  this.state.locked)
          {
            // if(this.state.locked)
            // {
                this.props.history.push('/library/form/' + this.props.customer_form.id + '/settings');
            // }
            // else
            // {
            //     this.props.history.push(`/library/forms`);
            // }
          }
        });
      }
      else
      {
        this.props.addCustomerFormAction(values).then(() => {

          this.props.history.push('/library/form/' + this.props.customer_form.id + '/detail');
        });
      }

      showSuccess();
    });
  }

  preview()
  {
      this.props.form.validateFields((err, values) => {

        if(!err)
        {
            values.form = JSON.stringify(this.state.formData);

            this.props.updateCustomerFormAction(this.props.match.params.id, values).then(() => {

                this.props.previewFormAction(this.props.match.params.id);
            });
         }
      });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Formulieren', 'link': '#'}, {'label': 'Formulier'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='19' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">

                  <Col span={12}>

                    <div>
                      <Button disabled={this.state.loading || !this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null, null, true)}>
                        { !this.props.match.params.id ? 'Opslaan' : 'Opslaan en volgende' }
                      </Button>

                      <Link to={`/library/forms`}>
                        <Button>Sluiten</Button>
                      </Link>

                    </div>
                  </Col>

                  <Col span={12} align={'right'}>
                      <Button disabled={this.props.match.params.id ? false : true} onClick={(event) => { this.preview(); }}>Voorbeeld</Button>
                  </Col>

                </Row>

                {/* steps */}
                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <FormSteps current={0} locked={this.props.customer_form.locked} {...this.props}/>
                    </Card>
                  </Col>
                </Row>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>

                      <Col span={16}>
                        <Form.Item required label="Naam">
                          {getFieldDecorator('name', {
                            initialValue: this.props.customer_form ? this.props.customer_form.name : '',
                            rules: [{ required: true, message: 'Vul een naam in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                          <Form.Item label="Vergrendeld">
                            {getFieldDecorator('locked', {
                              initialValue: this.props.customer_form.locked ? true : false,
                              onChange: (checked) => { this.setState({ locked: checked ? true : false }) },
                              valuePropName: 'checked',
                            })(
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                />
                            )}
                          </Form.Item>
                      </Col>

                      { this.props.selectedCustomer.id == 1 && this.props.HaveRole(['super-admin']) ?
                      <Col span={4}>
                          <Form.Item label="Gebruik voor demo project">
                            {getFieldDecorator('use_for_demo', {
                                valuePropName: 'checked',
                                initialValue: this.props.customer_form && this.props.customer_form.use_for_demo ? true : false
                            })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    />
                            )}
                          </Form.Item>
                      </Col> : null }

                    </Row>

                      <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                        <Col span={24}>
                          <FormBuilder
                              onChangeForm={(data) => this.setState({formData: data}, (e) => this.handleSubmit(e, data, false))}
                              ref={'FormBuilder'}
                              type={'form'}
                              formId={this.props.match.params.id}
                              cloneSections={true}
                              enabledElements={[
                                  'f_select',
                                  'f_textinput',
                                  'f_title',
                                  'f_textareainput',
                                  'f_numberinput',
                                  'f_timeinput',
                                  'f_dateinput',
                                  'f_contactinput', // is only available for addresses (not public projects)
                                  'f_photoinput',
                                  'f_drawinginput',
                                  'f_image'
                              ]}
                            />
                        </Col>
                      </Row>

                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const LibraryFormDetailForm = Form.create({ name: 'form_form' })(LibraryFormDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    customer_form : state.forms.form,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAction : bindActionCreators(getFormAction, dispatch),
    addCustomerFormAction : bindActionCreators(addCustomerFormAction, dispatch),
    updateCustomerFormAction : bindActionCreators(updateCustomerFormAction, dispatch),
    resetFormAction : bindActionCreators(resetFormAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewFormAction : bindActionCreators(previewFormAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryFormDetailForm);
