import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import BgImage from '../../../assets/img/forget.jpg';
import BgImage from '../../../assets/img/login_new.png';
import '../Login.css';
import Logo from '../../../assets/img/logo_zwart.svg';
import api from '../../../utils/api'

import { UserOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Layout, Col, Row, Input, Button, Typography, message } from 'antd';

import { showSuccess } from '../../../utils/Notifications';

class Forget extends Component {
  state = {
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        api('post', 'auth/forget', values, false).then(() => {
          showSuccess('Wachtwoord verzonden');
        });
      }
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { Title } = Typography;
    const { Paragraph } = Typography;

    return (
      <div className="login" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>
        <Layout style={{ backgroundColor: 'white' }}>

          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" justify="center" align="middle">
              <Col xs={0} sm={0} md={10} lg={8} xl={10} xxl={10}>
                <div
                  className="loginScreen__image"
                  style={{
                      backgroundImage: `url(${BgImage})`,
                      backgroundPosition: 'top right',
                      backgroundSize: 'cover'
                  }}>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={16} xl={14} xxl={14} type="flex" align="middle">
              <div style={{ maxWidth: '400px', padding:24 }}>
              <img width="200px" height="auto" alt="Cobee" src={Logo} style={{ width: 200, marginBottom: 50 }}></img>
                <Form onSubmit={this.handleSubmit} className="login-form" style={{ width: '100%', textAlign: 'middle',}}>
                  <Title level={2}>Wachtwoord vergeten</Title>
                  <Paragraph>Vul een gebruikersnaam in om een nieuw wachtwoord in te stellen.</Paragraph>
                  <Form.Item style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('email', {
                      rules: [{ required: true, message: 'Vul een gebruikersnaam in' }],
                    })(
                      <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Gebruikersnaam" />
                    )}
                  </Form.Item>
                  <Button style={{ width: '100%'}} type="primary" htmlType="submit" className="login-form-button" onClick={this.enterLoading}>
                    Verzenden
                  </Button>
                  <Link to="/login"><span style={{ display: 'block', margin: '16px 0 0 0'}} className="login-form-forgot">Inloggen</span></Link>
                </Form>
              </div>
            </Col>
          </Row>
        </Layout>
      </div>
    );
  }
}

const LoginForm = Form.create({ name: 'normal_login' })(Forget);

export default LoginForm;
