import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
//import Gantt from '../../../../../components/Gantt';
// import '../Schedule.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getScheduleAction, getScheduleBlockedDatesAction, getScheduleGanttAction } from '../../../../../actions/scheduleActions';

class Calendar extends Component
{
  state = {
    schedule: {},
    blockeddates: [],
    gantt_data: { data: [], links: [] },
    gantt: {}
  }

  componentWillUnmount(){
    gantt.clearAll();
    gantt.refreshData();
    gantt.render();
  }

  componentDidMount()
  {
    this.props.getScheduleAction(this.props.match.params.id).then(() => {

      this.setState({
        schedule: this.props.schedule
      });

      this.props.getScheduleGanttAction(this.props.match.params.id).then(() => {

        this.props.getScheduleBlockedDatesAction(this.props.match.params.id).then(() => {

          // blocked dates
          this.setState({
            blockeddates : this.props.blockeddates
          });

          for (var i = 0; i < this.props.blockeddates.length; i++)
          {
            gantt.setWorkTime({
              date: new Date(this.props.blockeddates[i].start_date),
              hours: false
            });
          }

          // work days
          // var workdays = [];
          //
          // if(this.props.schedule.monday_workday == 1){ workdays.push(1); }
          // if(this.props.schedule.tuesday_workday == 1){ workdays.push(2); }
          // if(this.props.schedule.wednesday_workday == 1){ workdays.push(3); }
          // if(this.props.schedule.thursday_workday == 1){ workdays.push(4); }
          // if(this.props.schedule.friday_workday == 1){ workdays.push(5); }
          // if(this.props.schedule.saturday_workday == 1){ workdays.push(6); }
          // if(this.props.schedule.sunday_workday == 1){ workdays.push(0); }
          if(this.props.schedule.monday_workday != 1){ gantt.setWorkTime({day: 1, hours: false}); }
          if(this.props.schedule.tuesday_workday != 1){ gantt.setWorkTime({day: 2, hours: false}); }
          if(this.props.schedule.wednesday_workday != 1){ gantt.setWorkTime({day: 3, hours: false}); }
          if(this.props.schedule.thursday_workday != 1){ gantt.setWorkTime({day: 4, hours: false}); }
          if(this.props.schedule.friday_workday != 1){ gantt.setWorkTime({day: 5, hours: false}); }
          if(this.props.schedule.saturday_workday != 1){ gantt.setWorkTime({day: 6, hours: false}); }
          if(this.props.schedule.sunday_workday != 1){ gantt.setWorkTime({day: 0, hours: false}); }



          gantt.config.xml_date = "%Y-%m-%d %H:%i";
          gantt.config.date_grid = "%d-%m-%Y";
          gantt.config.autosize = "y";
          gantt.config.start_date = this.state.schedule.start_date;
          gantt.config.end_date = this.state.schedule.end_date;

          // gantt.config.readonly = true;

          gantt.config.scale_unit = "day";
          gantt.config.date_scale = "%D, %d";
          gantt.config.min_column_width = 60;
          gantt.config.duration_unit = "day";
          gantt.config.scale_height = 20 * 3;
          gantt.config.row_height = 30;

          gantt.config.drag_project = true;

          gantt.config.work_time = true; //disable scheduling on non-work days

          // gantt.templates.scale_cell_class = function (date) {
          //     if(!workdays.includes(date.getDay())) {
          // 		return "weekend";
          // 	}
          //
          // 	if (!gantt.isWorkTime(date)) {
          // 		return "weekend";
          //     }
          //
          // 	return "";
          // };

          gantt.templates.timeline_cell_class = function (task, date) {
            // if(!workdays.includes(date.getDay())) {
            // 	return "weekend";
            // }

            if(!gantt.isWorkTime(date)) {
              return "weekend";
            }

            return "";
          };

          gantt.config.columns = [
            {name: "text", tree: true, width: 150, resize: true},
            //{name: "start_date", align: "center", width: 150, resize: true},
            //{name: "duration", align: "center", width: 70, resize: true},
            //{name: "add", width: 44, resize: true, hide: true}
          ];

          gantt.config.details_on_dblclick = false;

          gantt.config.drag_progress = false;
          gantt.config.drag_links = false;

          this.setState({
            gantt_data: this.props.gantt_data
          });

          gantt.templates.task_text = function(start, end, task){ return (task.parent ? '' : task.text); };

          gantt.init(this.ganttContainer);

          gantt.parse(this.state.gantt_data);

          gantt.render();
        });
      });

    });
  }

  render()
  {
    return (
      <div key={'gant2'} ref={ (input) => { this.ganttContainer = input } } style={ { width: '100%', height: '100%' } }></div>
    );
  }
}

const CalendarPage = Form.create({ name: 'data_form' })(Calendar);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.schedule.isFetching,
    isError : state.schedule.isError,
    schedule: state.schedule.schedule,
    blockeddates: state.schedule.blockeddates,
    gantt_data : state.schedule.gantt_data,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScheduleAction : bindActionCreators(getScheduleAction, dispatch),
    getScheduleBlockedDatesAction : bindActionCreators(getScheduleBlockedDatesAction, dispatch),
    getScheduleGanttAction : bindActionCreators(getScheduleGanttAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
