import api from '../utils/api';
import { GLOBAL_API_URL } from '../constants/settings';

export const loginAction = (params) => dispatch => {

  dispatch({
    type: 'LOGIN_ACTION_FETCH'
  })

  return api('post','/login', params, false).then((response) => {
    if(response.data.success) {

      localStorage.setItem('authToken', response.data.token);

      localStorage.setItem('apiURL', response.data.api_url ? response.data.api_url : GLOBAL_API_URL);

      dispatch({
        type: 'LOGIN_ACTION_SUCCESS',
        payload: response.data
      });

      return response.data;
    } else {
      dispatch({
        type: 'LOGIN_ACTION_ERROR',
      });

      return response.data;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOGIN_ACTION_ERROR',
    });
    return false;
  });
}

export const setTokenAndIDP = (token, idp) => dispatch => {

    localStorage.clear();

    localStorage.setItem('authToken', token);

    localStorage.setItem('idpName', idp);
}

export const setToken = (token) => dispatch => {

    localStorage.clear();

    localStorage.setItem('authToken', token);
}

export const getLoginUserAction = (token = false) => dispatch => {

  dispatch({
    type: 'LOGIN_USER_ACTION_FETCH'
  });

  if(!token)
  {
      token = localStorage.getItem('authToken');
  }

  return api('get','/user/?token='+token, null, false).then((response) => {

    if(response.data) {

      // localStorage.setItem('apiURL', response.data.api_url ? response.data.api_url : GLOBAL_API_URL);

      dispatch({
        type: 'LOGIN_USER_ACTION_SUCCESS',
        payload: response.data
      });
      return response.data;
    } else {
      dispatch({
        type: 'LOGIN_USER_ACTION_ERROR',
      });
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOGIN_USER_ACTION_ERROR',
    });
    return false;
  });
}

export const switchCustomerAction = (customer) => dispatch => {

  localStorage.setItem('apiURL', customer.api_url ? customer.api_url : GLOBAL_API_URL);

  localStorage.setItem('customerId', customer.id);

  dispatch({
    type: 'CUSTOMER_ACTION_SWITCH',
    payload: customer
  })

}

export const logoutAction = () => dispatch => {

  dispatch({
    type: 'USER_LOGOUT'
  })

  localStorage.removeItem('authToken')

}

export const getMyCustomersAction = (params) => dispatch => {

  // dispatch({
  //   type: 'CUSTOMERS_ACTION_FETCH'
  // })
  //
  // return api('get','/customers', null, true).then((response) => {
  //   dispatch({
  //     type: 'CUSTOMERS_ACTION_SUCCESS',
  //     payload: response.data
  //   });
  // }).catch((e) => {
  //   dispatch({
  //     type: 'CUSTOMERS_ACTION_ERROR',
  //   })
  //   return false;
  // });

  // dispatch({
  //   type: 'LOGIN_USER_ACTION_FETCH'
  // });

  return api('get','/user/?token='+localStorage.getItem('authToken'), null, false).then((response) => {

    if(response.data) {

      // localStorage.setItem('apiURL', response.data.api_url ? response.data.api_url : GLOBAL_API_URL);

      dispatch({
        type: 'LOGIN_USER_ACTION_SUCCESS',
        payload: response.data
      });
      return response.data;
    } else {
      // dispatch({
      //   type: 'LOGIN_USER_ACTION_ERROR',
      // });
      return false;
    }
  }).catch((e) => {
    // dispatch({
    //   type: 'LOGIN_USER_ACTION_ERROR',
    // });
    return false;
  });
}
