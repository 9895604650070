import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
  Select,
} from 'antd';

class EmailTestForm extends Component {

  state = {
    filelist: [],
    deleteImage: false,
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
        <Row gutter={24} >
          <Col span={24}>
            <Form.Item required label="E-mail">
              { getFieldDecorator('email_address', {
                initialValue: [],
                rules: [{ required: true, message: 'Vul een e-mailadres in'}],
              })(
                <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({emailTo: value.join(',')}); }}></Select>
              ) }
            </Form.Item>

          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'email_test_form' })(EmailTestForm);
