import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressesAction, searchAddressesAction, requestDownloadDossier, exportScheduleAction, requestDownloadFuseBoxLists, requestExportAddressesAction, requestExportScheduleAction } from '../../../../actions/addressesActions';
import { getSurveysAction, searchSurveysAction, exportSurveyAction, exportSurveyFormAction, requestExportSurveyFormAction, getSurveyAddressesAction } from '../../../../actions/surveysActions';
import { getAppointmentsAction, requestExportAppointmentAction } from '../../../../actions/appointmentsActions';
import { getDeliveryFormsAction, viewDeliveryFormPDFAction, getPointsAction, searchPointsAction, exportPointsAction, getUsersAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, getUserFilterAction } from '../../../../actions/deliverActions';
import { getChoiceFormsByProjectAction, getChoiceStatusAction, downloadDocumentsAction, downloadExcelAction, downloadStatusAction, requestExportChoiceStatusAction, requestDownloadExcelAction, getChoiceFormStatusAction } from '../../../../actions/choiceActions';
import { getSupportBaseFormsByProjectAction, searchSupportBaseFormsByProjectAction, downloadSupportBaseFormExcelAction, getSupportBaseStatusAction, downloadSignedDocumentsAction, downloadSupportBaseFormExcelResultsAction } from '../../../../actions/supportBaseActions';
import { getScheduleAction, getScheduledAddressesAction, searchScheduledAddressesAction } from '../../../../actions/scheduleActions';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { getProjectAction } from '../../../../actions/projectActions';
import { getNrOfNewQueuedItems } from '../../../../actions/queueActions';
import { getFormsAction, getFormAction, getFormsResultsAction, searchInFormAction, searchInFormsAction, exportFormAction, exportFormsAction } from '../../../../actions/formActions';
import moment from 'moment';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';

import {
    AuditOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ContactsOutlined,
    ContainerOutlined,
    ExperimentOutlined,
    FolderOutlined,
    ReconciliationOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
    Modal,
    Button,
    Dropdown,
    Menu,
    Input,
    Row,
    Col,
    Upload,
    message,
    Drawer,
    Transfer,
    Tag,
    InputNumber,
    Checkbox,
    Select,
    Badge,
    Alert,
    Spin,
    Radio,
} from 'antd';
import { showSuccess, showError } from '../../../../utils/Notifications';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../constants/settings';
import _ from 'underscore';
import './export.css';

const Search  = Input.Search;

const confirm = Modal.confirm;

const Dragger = Upload.Dragger;

class Export extends Component {
  state = {
    init: true,
    loading: true,
    selectedKeys: [],
    disabled: false,
    selectedRowKeys: [],
    selectedRowKeysAddresses: [],
    selectedRowKeysForms: [],
    addresses: [],
    checked: [],
    emailTo: '', // + (this.props.auth && this.props.auth.user ? this.props.auth.user.email : ''),
    module: 'addresses',
    exportType: false,
    is_valid_email: true,
    showChildForms: false,

    selectedFuseBoxListOptions: [],
    forms: [],
    choice_forms: [],
    choices: [],

    filteredInfo: null,
  };

  form_result_status = {
      // '': 'Nog niet geopend',
      'new': 'Nog niet ingediend',
      'answering': 'Deels beantwoord',
      'answered': 'Beantwoord',
      'summary': 'Samenvatting bekeken',
      'nr-of-signatures': 'Aantal handtekening opgegeven',
      'open': 'Open',
      'send': 'Verzonden',
      //'denied': 'Afgekeurd',
      //'approved': 'Goedgekeurd',
      'locked': 'Vergrendeld',
      'signed': 'Ondertekend',
      'completed': 'Afgerond'
  }

  descriptions = [];
  subjects = [];

  constructor(props) {
      super(props);
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {

              var group_found = false;

              if(record && record.groups && record.groups.length)
              {
                  record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              }

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  formStatusFilter(value, record)
  {
      var status_found = false;

      if(this.state.filteredInfo && this.state.filteredInfo.status/*ses*/ && this.state.filteredInfo.status/*ses*/.length > 0)
      {
          this.state.filteredInfo.status/*ses*/.map((status) => {

              // var status_found = false;

              // if(record && record.status)
              // {
              //     record.groups.map((record_group) => {
              //         if(record_group.name == group_name)
              //         {
              //             status_found = true;
              //         }
              //     });
              // }

              if(record.status == status)
              {
                  status_found = true;
              }

              // if(!group_found)
              // {
              //     all_groups_found = false;
              // }
          });
      }

      return status_found;
  }

  usersFilter(value, record)
  {
      var found = false;

      if(this.state.filteredInfo && this.state.filteredInfo.user_email_address && this.state.filteredInfo.user_email_address.length > 0)
      {
          this.state.filteredInfo.user_email_address.map((user_id) => {

              if(record.assigned_to_delivery_user_id == user_id)
              {
                  found = true;
              }
          });
      }

      return found;
  }

  descriptionsFilter(value, record)
  {
      var found = false;

      if(this.state.filteredInfo && this.state.filteredInfo.description && this.state.filteredInfo.description.length > 0)
      {
          this.state.filteredInfo.description.map((description) => {

              if(record.description == description)
              {
                  found = true;
              }
          });
      }

      return found;
  }

  subjectsFilter(value, record)
  {
      var found = false;

      if(this.state.filteredInfo && this.state.filteredInfo.subject && this.state.filteredInfo.subject.length > 0)
      {
          this.state.filteredInfo.subject.map((subject) => {

              if(record.subject == subject)
              {
                  found = true;
              }
          });
      }

      return found;
  }

  isDuplicateDescription(obj)
  {
      if(!obj || !obj.description){ return; }

      var description = obj.description;

      var duplicate = false;

      this.descriptions.map((value, index) => {
          if(value == description)
          {
              duplicate = true;
          }
      });

      if(!duplicate)
      {
          this.descriptions.push(description);
      }

      return duplicate;
  }

  isDuplicateSubject(obj)
  {
      if(!obj || !obj.subject){ return; }

      var subject = obj.subject;

      var duplicate = false;

      this.subjects.map((value, index) => {
          if(value == subject)
          {
              duplicate = true;
          }
      });

      if(!duplicate)
      {
          this.subjects.push(subject);
      }

      return duplicate;
  }

  onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys });
  }

  onSelectChangeChoices = selectedRowKeys => {

      this.setState({ selectedRowKeys });
  }

  componentDidMount()
  {
      this.setState({ emailTo: (this.props.auth && this.props.auth.user ? this.props.auth.user.email : '') });

      this.setState({ init: true, loading: true }, () => {

          var first_module = false;

          if((this.props.HaveAbility('address', 'read') || this.props.project.user_role === 'admin')){ first_module = 'addresses'; }
          else if((this.props.HaveAbility('dossier', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 17})){ first_module = 'dossier'; }
          else if((this.props.HaveAbility('appointment', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 3})){ first_module = 'appointments'; }
          else if((this.props.HaveAbility('support-base', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 14})){ first_module = 'support_base'; }
          else if((this.props.HaveAbility('form', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 19})){ first_module = 'form'; }
          else if((this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 16})){ first_module = 'choice_forms'; }
          else if((this.props.HaveAbility('form', 'read') || this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && (_.findWhere(this.props.project.modules, {id: 16}) ||  _.findWhere(this.props.project.modules, {id: 19}))){ first_module = 'fuse_box_list'; }
          else if((this.props.HaveAbility('scheduling', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 1})){ first_module = 'schedule'; }
          else if((this.props.HaveAbility('survey', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 9})){ first_module = 'survey_forms'; }
          else if((this.props.HaveAbility('deliver', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 15})){ first_module = 'delivery_points'; }/**/

          if(first_module)
          {
              this.setState({ module: this.props.match.params.export ? this.props.match.params.export : first_module }, () => {

                  this.props.getAddressesAction({projectId: this.props.match.params.id}).then(() => {

                      this.props.getGroupsAction({'projectId': this.props.match.params.id}).then(() => {

                          // this.props.getProjectAction(this.props.match.params.id).then(() => {

                              this.export(this.state.module, false);

                              // this.setState({ init: false, loading: false }, () => {
                                  // this.setState({ module: this.props.match.params.export ? this.props.match.params.export : 'addresses' }, () => {
                                      // this.export(this.state.module, false);
                                  // });
                              // });
                          // });
                      });
                  });
              });
          }/**/
      });
  }

  searchAddresses(value)
  {
      this.setState({ loading: true });

      this.props.searchAddressesAction(this.props.match.params.id, {'search': value}).then(() => {
          this.setState({ loading: false });
      });
  }

  searchScheduleAddresses(value)
  {
      this.setState({ loading: true });

      this.props.searchScheduledAddressesAction(this.props.match.params.id, {'search': value}).then(() => {
          this.setState({ loading: false });
      });
  }

  searchSurveys(value)
  {
      this.setState({ loading: true });

      this.props.searchSurveysAction(this.props.match.params.id, {'search': value}).then(() => {
          this.setState({ loading: false });
      });
  }

  reloadFormsResults(formIDs)
  {
      this.setState({ selectedRowKeys: [] }, () => {

          if(formIDs && formIDs.length > 0)
          {
              this.setState({ loading: true });

              this.props.getFormsResultsAction(this.props.match.params.id, this.state.formIDs).then((data) => {

                  this.setState({ loading: false });
              });
          }
      });
  }

  searchFormResults(value)
  {
      this.setState({ loading: true });

      this.props.searchInFormAction(this.state.formID, value, true).then((data) => {

          this.setState({ loading: false });
      });
  }


  searchFormsResults(value)
  {
      this.setState({ loading: true });

      this.props.searchInFormsAction(this.props.match.params.id, this.state.formIDs, value).then((data) => {

          this.setState({ loading: false });
      });
  }

  searchDeliveryPoints(value)
  {
      this.setState({ loading: true });

      this.props.searchPointsAction(this.props.match.params.id, value, this.state.deliveryPointsArchived).then((data) => {
          this.setState({ loading: false });
      });
  }

  searchSupportBaseForms(value)
  {
      this.setState({ loading: true });

      this.props.searchSupportBaseFormsByProjectAction(this.props.match.params.id, value).then((data) => {
          this.setState({ loading: false });
      });
  }

  export(type, load = true)
  {
      if(load)
      {
        this.props.history.push(`/projects/${this.props.match.params.id}/exports/${type}`);
      }

      this.setState({
          selectedRowKeys: [],

          init: true,
          modalExportAddresses: false,
          modalExportDossier: false,
          modalExportSurvey: false,
          modalExportAppointments: false,
          modalExportSchedules: false,
          modalExportForm: false,
          modalExportDeliveryPoints: false,
          modalExportChoiceFormResults: false,
          modalExportSupportBase: false,
          modalExportFuseBoxList: false,
          module: type,
      });

      if(type == 'addresses'){
          this.setState({ modalExportAddresses: true, init: false }, () => { this.setState({ loading: false }); });
      }
      else if(type == 'dossier'){
          this.setState({ modalExportDossier: true, init: false }, () => { this.setState({ loading: false }); });
      }
      else if(type == 'survey_forms')
      {
          this.setState({ modalExportSurvey: true, loading: true, surveyID: null, exportType: null });
          this.props.getSurveysAction(this.props.match.params.id).then(() => {
              this.setState({ loading: false, init: false });
          });
      }
      else if(type == 'appointments')
      {
          this.setState({ modalExportAppointments: true, loading: true, appointmentID: null });

          this.props.getAppointmentsAction(this.props.match.params.id).then(() => {
              this.setState({ loading: false, init: false });
          });
      }
      else if(type == 'schedule')
      {
          this.setState({ modalExportSchedules: true, exportType: null, init: false, loading: true }); //, () => { this.setState({ loading: false }); });

          // this.props.getScheduleAction(this.props.match.params.id).then(() => {
          this.props.getScheduledAddressesAction(this.props.match.params.id).then(() => {
              this.setState({ loading: false, init: false });
          });
      }
      else if(type == 'form')
      {
          this.setState({ modalExportForm: true, loading: true, exportType: null, formID: null });

          this.props.getFormsAction(this.props.match.params.id).then(() => {
              this.setState({ loading: false, init: false });
          });
      }
      else if(type == 'delivery_points')
      {
          this.setState({ modalExportDeliveryPoints: true, loading: true, deliveryPointsArchived: null, exportFormat: null });

          this.props.getDeliveryFormsAction(this.props.match.params.id);

          this.props.getPointsAction(this.props.match.params.id).then(() => {

              this.props.delivery_points.map((obj, index) => {
                  this.isDuplicateDescription(obj.description);
              });

              this.props.delivery_points.map((obj, index) => {
                  this.isDuplicateSubject(obj.subject);
              });

              // this.setState({ loaded: true, loading: false });
              this.props.getDeliveryFilterAction(this.props.match.params.id);
              this.props.getFormFilterAction(this.props.match.params.id);
              this.props.getLocationFilterAction(this.props.match.params.id);
              this.props.getUserFilterAction(this.props.match.params.id);

              this.props.getUsersAction(this.props.match.params.id, 'contractor').then(() => {
                  this.setState({ /*loaded: true,*/ loading: false, init: false });
              });
          });
      }
      else if(type == 'choice_forms')
      {
          this.setState({ modalExportChoiceFormResults: true, loading: true, exportType: false, exportFormat: false });

          this.props.getChoiceStatusAction(this.props.match.params.id).then(() => {
              this.props.getChoiceFormsByProjectAction(this.props.match.params.id).then(() => {
                  this.setState({ loading: false, init: false });
              });
          });
      }
      else if(type == 'support_base')
      {
          this.setState({ modalExportSupportBase: true, loading: true, exportType: null });

          this.props.getSupportBaseStatusAction(this.props.match.params.id).then(() => {
              this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id).then(() => {
                  this.setState({ loading: false, init: false });
              });
          });
      }
      else if(type == 'fuse_box_list')
      {
          if(((this.props.HaveAbility('choice', 'read') && this.props.HaveAbility('form', 'read')) || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 16}) && _.findWhere(this.props.project.modules, {id: 19}))
          {
              this.props.getFormsAction(this.props.match.params.id).then(() => {

                  this.props.getChoiceFormsByProjectAction(this.props.match.params.id).then(() => {

                      this.setState({ modalExportFuseBoxList: true, selectedFuseBoxListOptions: [], exportFormat: null, init: false }, () => { this.setState({ loading: false }); });
                  });
              });
          }
          else if((this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 16}))
          {
              this.props.getChoiceFormsByProjectAction(this.props.match.params.id).then(() => {

                  this.setState({ modalExportFuseBoxList: true, selectedFuseBoxListOptions: [], exportFormat: null, init: false }, () => { this.setState({ loading: false }); });
              });
          }
          else if((this.props.HaveAbility('form', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 19}))
          {
              this.props.getFormsAction(this.props.match.params.id).then(() => {

                  this.setState({ modalExportFuseBoxList: true, selectedFuseBoxListOptions: [], exportFormat: null, init: false }, () => { this.setState({ loading: false }); });
              });
          }
      }
  }

  downloadUrl(url, filename, method = 'GET', params = '') {

    let xhr = new XMLHttpRequest();

    let this_obj = this;

    xhr.open(method, url, true);

    if(method == 'POST')
    {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    }

    xhr.responseType = "blob";

    xhr.onload = function(e) {

      if (this.status == 200) {

        const blob = this.response;

        const a = document.createElement("a");

        document.body.appendChild(a);

        const blobUrl = window.URL.createObjectURL(blob);

        a.href = blobUrl;

        a.download = filename;

        a.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);

          document.body.removeChild(a);

          this_obj.setState({
              loading: false,

              // reset
              choice_form_id: null,
              exportFormat: null,
              selectedRowKeys: []
          });
        }, 0);
      }
    };

    // format params
    var formatted_params = '';

    if(params)
    {
      Object.keys(params).map((k) => {
        if(Array.isArray(params[k])) {
          for (var i = 0; i < params[k].length; i++)
          {
            if(params[k][i])
            {
              formatted_params += (formatted_params ? '&' : '')+k+'['+i+']='+params[k][i];
            }
            else if(params[k][i] === false || params[k][i] === '')
            {
              formatted_params += (formatted_params ? '&' : '')+k+'['+i+']=';
            }
          }
        }
        else
        {
          if(params[k])
          {
            formatted_params += (formatted_params ? '&' : '')+k+'='+params[k];
          }
          else if(params[k] === false || params[k] === '')
          {
            formatted_params += (formatted_params ? '&' : '')+k+'=';
          }
        }
      })
    }

    xhr.send(formatted_params);
  }

  sanitizeFilename(input, replacement = '')
  {
      var illegalRe = /[\/\?<>\\:\*\|":]/g;
      var controlRe = /[\x00-\x1f\x80-\x9f]/g;
      var reservedRe = /^\.+$/;
      var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;

      // function sanitize(input, replacement) {
        var sanitized = input
          .replace(illegalRe, replacement)
          .replace(controlRe, replacement)
          .replace(reservedRe, replacement)
          .replace(windowsReservedRe, replacement);

        return sanitized.length > 100 ? sanitized.substring(0, 98)+'--' : sanitized;
     // }
  }

  exportAddresses()
  {
      // this.setState({ loading: true });
      //
      // var url = API_URL + '/addresses/'+ this.props.match.params.id +'/export/xls/?token=' + localStorage.getItem('authToken');
      //
      // this.downloadUrl(url, 'adressen_project_'+this.props.match.params.id+'.xlsx');

      this.props.requestExportAddressesAction(this.props.match.params.id).then(() => {

          showSuccess('Export succesvol verzonden');

          // this.props.form.resetFields();

          this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

          this.props.getNrOfNewQueuedItems(); // refresh topnav counter
      });

      // window.open(url, '_blank');
  }

  exportDossier()
  {
      if(this.state.selectedRowKeys.length <= 0)
      { // error
        showError('Selecteer minimaal één adres');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // { // invalid emailaddress
      //   showError('Geef een geldig e-mailadres op');
      // }
      else
      { // action
          this.setState({ loading: true });

          this.props.requestDownloadDossier(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys }).then(() => {

              showSuccess('Export succesvol verzonden');

              // this.props.form.resetFields();

              this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  exportSurvey()
  {
      // if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      // else
      if(this.state.exportType == 'results' && this.state.selectedRowKeys.length <= 0)
      {   // error
          showError('Selecteer minimaal één adres');
      }
      else
      {   // action
          this.setState({ loading: true });

          if(this.state.exportType == 'results')
          {
              this.props.exportSurveyAction(this.state.surveyID, { ids: this.state.selectedRowKeys /*'email': this.state.emailTo*/ }).then(() => {

                  showSuccess('Export succesvol verzonden');

                  this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

                  this.props.getNrOfNewQueuedItems(); // refresh topnav counter
              });
          }
          else if(this.state.exportType == 'form')
          {
              this.props.requestExportSurveyFormAction(this.state.surveyID).then(() => {

                  showSuccess('Export succesvol verzonden');

                  this.setState({ loading: false });

                  this.props.getNrOfNewQueuedItems(); // refresh topnav counter
              });
          }
      }
  }

  exportAppointment()
  {
      this.setState({ loading: true });

      // var url = API_URL+'/appointment/'+this.state.appointmentID+'/export?token='+ localStorage.getItem('authToken');
      //
      // var appointment_name = this.state.appointmentID;
      //
      // this.props.appointments.map((element, index) => {
      //     if(element.id == this.state.appointmentID)
      //     {
      //         appointment_name = this.sanitizeFilename(element.subject);
      //     }
      // });
      //
      // this.downloadUrl(url, appointment_name+'_afspraken.xlsx');


      this.props.requestExportAppointmentAction(this.state.appointmentID).then(() => {

          showSuccess('Export succesvol aangevraagd');

          this.setState({ loading: false });

          this.props.getNrOfNewQueuedItems(); // refresh topnav counter
      });
  }

  exportSchedule(type = false)
  {
      // if(this.state.selectedRowKeys.length <= 0)
      // {   // error
      //     showError('Selecteer minimaal één adres');
      // }
      // else
      {
          this.setState({ loading: true });

          // this.props.exportScheduleAction(this.props.match.params.id, this.state.exportType, this.state.selectedRowKeys, 'planning_'+this.props.match.params.id+'_export'+(this.state.exportType != 'simple' ? '_uitgebreid' : '')+'.zip').then(() => {
          this.props.requestExportScheduleAction(this.props.match.params.id, this.state.exportType, this.state.selectedRowKeys).then(() => {

              // this.props.form.resetFields();

              showSuccess('Export succesvol aangevraagd');

              this.setState({ exportType: false, selectedRowKeys: [], loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  exportForm()
  {
      this.setState({ loading: true });

      this.props.exportFormAction(this.state.formID, this.state.exportType, this.state.exportSubType, this.state.selectedRowKeys).then(() => {

          showSuccess('Export succesvol aangevraagd');

          this.setState({ exportType: false, exportSubType: false, formID: false, formIDs: [], selectedRowKeys: [], loading: false });

          this.props.getNrOfNewQueuedItems(); // refresh topnav counter
      });
  }

  exportForms()
  {
      if(this.state.formIDs.length < 1)
      {   // error
          showError('Selecteer minimaal één formulier');
      }
      else if(this.state.selectedRowKeys.length < 1)
      {   // error
          showError('Selecteer minimaal één resultaat');
      }
      else
      {
          this.setState({ loading: true });

          // exportFormsAction = (project_id, ids, subtype, address_ids) => (dispatch, getState) => {
          this.props.exportFormsAction(this.props.match.params.id, this.state.formIDs, this.state.exportSubType, this.state.selectedRowKeys, this.state.exportFormat).then(() => {

              showSuccess('Export succesvol aangevraagd');

              this.setState({ exportType: false, exportSubType: false, formIDs: [], selectedRowKeys: [], exportFormat: 'zip', loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  exportIntakeFormResults()
  {
      if(this.state.selectedRowKeys.length < 1)
      {   // error
          showError('Selecteer minimaal één adres');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      else
      {   // action
          this.setState({ loading: true });

          this.props.requestDownloadIntake(this.state.intakeFormID, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys }).then(() => {

              this.setState({ formID: false, selectedRowKeys: [], loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  // exportFormResults()
  // {
  //     if(this.state.selectedRowKeys.length < 1)
  //     {   // error
  //         showError('Selecteer minimaal één adres');
  //     }
  //     // else if(!this.validateEmail(this.state.emailTo))
  //     // {   // invalid emailaddress
  //     //     showError('Geef een geldig e-mailadres op');
  //     // }
  //     else
  //     {   // action
  //         this.setState({ loading: true });
  //
  //         this.props.requestDownloadForm(this.state.formID, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys }).then(() => {
  //
  //             showSuccess('Export succesvol verzonden');
  //
  //             // this.props.form.resetFields();
  //
  //             this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });
  //
  //             this.props.getNrOfNewQueuedItems(); // refresh topnav counter
  //         });
  //     }
  // }

  exportDeliveryForm()
  {
      var error = false;

      if(!this.state.exportType)
      {   // error
          error = true;

          showError('Selecteer een type export bestand');
      }
      else if(!this.state.exportFormat)
      {   // error
          error = true;

          showError('Selecteer een opmaak voor het type export');
      }
      else if(!this.state.formID)
      {   // error
          error = true;

          showError('Selecteer een opleverformulier');
      }
      else if(this.state.exportType == 'form' && !this.state.selectedRowKeys || !this.state.selectedRowKeys.length)
      {   // only if form is multi
          this.props.delivery_forms && this.props.delivery_forms.map((form) => {

              if(form.id == this.state.formID) {
                  
                  if(!form.deliveries_combined) {
                      
                      error = true;

                      showError('Selecteer minimaal één subformulier');

                      return;
                  }
              }
          });
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
     
      if(!error)
      {   // action
          this.setState({ loading: true });

          var formID = this.state.formID;
          var deliveryID = false;

          if(formID.includes('_'))
          {
              var splitted = formID.split('_');
              formID = splitted[0];
              deliveryID = splitted[1];
          }

          this.props.viewDeliveryFormPDFAction(formID, deliveryID, this.state.exportType, this.state.exportFormat, this.state.selectedRowKeys).then(() => {

              showSuccess('Export succesvol aangevraagd');

              // this.props.form.resetFields();

              this.setState({ formID: false, deliveryID: false, /*emailTo: '',*/ loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  exportDeliveryPoints()
  {
      if(!this.state.exportFormat)
      {   // error
          showError('Selecteer een type export bestand');
      }
      else if(this.state.selectedRowKeys.length < 1)
      {   // error
          showError('Selecteer minimaal één punt');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      else
      {   // action
          this.setState({ loading: true });

          this.props.exportPointsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys, /*'email': this.state.emailTo*/}, this.state.exportFormat).then(() => {

              showSuccess('Export succesvol aangevraagd');

              // this.props.form.resetFields();

              this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  exportChoiceStatus(type)
  {
      // this.props.downloadStatusAction(this.props.match.params.id).then(() => {
      //     showSuccess('Export succesvol verzonden');
      // });

      this.setState({ loading: true });

      // var url = API_URL + '/choice/project/' + this.props.match.params.id + '/status/download/excel?token=' + localStorage.getItem('authToken');
      //
      // this.downloadUrl(url, 'keuze_status_project_'+this.props.match.params.id+'.xlsx');
      //
      // this.props.getNrOfNewQueuedItems(); // refresh topnav counter

      //
      this.props.requestExportChoiceStatusAction(this.props.match.params.id, {type: type}).then(() => {

          showSuccess('Export succesvol aangevraagd');

          this.setState({ loading: false });

          this.props.getNrOfNewQueuedItems(); // refresh topnav counter
      });
  }

  exportChoiceForm()
  {
      if(!this.state.exportFormat)
      {   // error
          showError('Selecteer een type export bestand');
      }
      else if(this.state.exportFormat != 'paid' && (!this.state.selectedRowKeys || this.state.selectedRowKeys.length < 1))
      {   // error
          showError('Selecteer minimaal één adres');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      else
      {   // action
          this.setState({ loading: true });

          if(this.state.exportFormat == 'excel' || this.state.exportFormat == 'order' || this.state.exportFormat == 'paid')
          {
              // var url = API_URL + '/choice/project/' + this.props.match.params.id + '/status/form/' + this.state.choice_form_id + '/download/excel?token=' + localStorage.getItem('authToken');
              //
              // this.downloadUrl(url, 'keuze-p'+this.props.match.params.id+'-f'+this.state.choice_form_id+'-overzicht.xlsx', 'POST', {'ids':this.state.selectedRowKeys});
              //
              // this.props.getNrOfNewQueuedItems(); // refresh topnav counter


              this.props.requestDownloadExcelAction(this.props.match.params.id, this.state.choice_form_id, { ids: this.state.selectedRowKeys, type: this.state.exportFormat }).then(() => {

                  showSuccess('Export succesvol aangevraagd');

                  this.setState({ choice_form_id: null, exportFormat: null, selectedRowKeys: [], loading: false });

                  this.props.getNrOfNewQueuedItems(); // refresh topnav counter
              });
          }
          else
          {
              this.props.downloadDocumentsAction(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'type': this.state.exportFormat, 'choice_form_id': this.state.choice_form_id, 'ids': this.state.selectedRowKeys }, this.state.exportFormat).then(() => {
                  showSuccess('Export succesvol aangevraagd');

                  this.setState({ choice_form_id: null, exportFormat: null, selectedRowKeys: [], /*selectedRowKeysForms: [], selectedRowKeysAddresses: [], /*emailTo: '',*/ loading: false });

                  this.props.getNrOfNewQueuedItems(); // refresh topnav counter
              });
          }
      }
  }

  exportSupportBaseForm(type = false)
  {
      if(this.state.selectedRowKeys.length != 1)
      {   // error
          showError('Selecteer één draagvlak formulier');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      else
      {   // action
          this.setState({ loading: true });

          if(type == 'results')
          {
              this.props.downloadSupportBaseFormExcelResultsAction(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys }).then(() => {

                  showSuccess('Export succesvol aangevraagd');

                  // this.props.form.resetFields();

                  this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

                  this.props.getNrOfNewQueuedItems(); // refresh topnav counter
              });
          }
          else
          {
              this.props.downloadSupportBaseFormExcelAction(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys }).then(() => {

                  showSuccess('Export succesvol aangevraagd');

                  // this.props.form.resetFields();

                  this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

                  this.props.getNrOfNewQueuedItems(); // refresh topnav counter
              });
          }
      }
  }

  exportSupportBaseFormResults()
  {
      if(this.state.selectedRowKeys.length < 1)
      {   // error
          showError('Selecteer minimaal één adres');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      else
      {   // action
          this.setState({ loading: true });

          this.props.downloadSignedDocumentsAction(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys }).then(() => {

              showSuccess('Export succesvol aangevraagd');

              // this.props.form.resetFields();

              this.setState({ selectedRowKeys: [], /*emailTo: '',*/ loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  exportFuseBoxList()
  {
      if(this.state.selectedRowKeys.length < 1)
      {   // error
          showError('Selecteer minimaal één adres');
      }
      else if(this.state.selectedFuseBoxListOptions.length < 1)
      {   // error
          showError('Selecteer minimaal één optie');
      }
      else if(this.state.selectedFuseBoxListOptions.includes('form') && this.state.forms.length < 1)
      {   // error
          showError('Selecteer minimaal één opname formulier');
      }
      else if(this.state.selectedFuseBoxListOptions.includes('choice') && this.state.choices.length < 1)
      {   // error
          showError('Selecteer minimaal één keuze formulier');
      }
      else if(!this.state.exportType)
      {   // error
          showError('Selecteer een type export');
      }
      // else if(!this.validateEmail(this.state.emailTo))
      // {   // invalid emailaddress
      //     showError('Geef een geldig e-mailadres op');
      // }
      else
      {   // action
          this.setState({ loading: true });

          // this.props.requestDownloadFuseBoxLists(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys, 'format': this.state.exportType, 'options': this.state.selectedFuseBoxListOptions, 'intakes': this.state.intakes /*this.state.forms*/, 'choices': this.state.choices }).then(() => {

          // this.props.requestDownloadFuseBoxLists(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys, 'format': this.state.exportType, 'options': this.state.selectedFuseBoxListOptions, 'forms': this.state.forms, 'choices': this.state.choices }).then(() => {

          this.props.requestDownloadFuseBoxLists(this.props.match.params.id, { /*'email': this.state.emailTo,*/ 'ids': this.state.selectedRowKeys, 'format': this.state.exportType, 'options': this.state.selectedFuseBoxListOptions, 'intakes': this.state.intakes, 'forms': this.state.forms, 'choices': this.state.choices }).then(() => {

              showSuccess('Export succesvol aangevraagd');

              this.setState({ selectedRowKeys: [], /*selectedFuseBoxListOptions: [], /*emailTo: '',*/ loading: false });

              this.props.getNrOfNewQueuedItems(); // refresh topnav counter
          });
      }
  }

  validateEmail(string)
  {
      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      this.setState({ is_valid_email: valid });

      return valid;
  }

  getDeliveryPointStatusText(status)
  {
      if(status == 'submitted'){ return 'Controleren'; }
      else if(status == 'completed'){ return 'Afgehandeld'; }
      else { return 'Open'; }
  }

  getDeliveryPointStatusColor(status)
  {
      if(status == 'submitted'){ return '#ff8800'; }
      else if(status == 'completed'){ return '#00ff00'; }
      else { return '#ff0000'; }
  }

  onSelectChoiceAddressChange = (value, record) => {

    if(this.state.selectedRowKeysAddresses.includes(value))
    { // deselect
      this.setState({ selectedRowKeysAddresses: this.state.selectedRowKeysAddresses.filter((v) => v !== value)}, () => {
          var filteredRowKeysForms = this.state.selectedRowKeysAddresses;

          record.children.map((form, index) => {
              filteredRowKeysForms = filteredRowKeysForms.filter((v) => v !== value+'-form-'+form.id);
          });

          this.setState({ selectedRowKeysForms: filteredRowKeysForms});
      });
    }
    else
    { // select
      this.setState({ selectedRowKeysAddresses: [...this.state.selectedRowKeysAddresses, value]});

      var selected_form_ids = [];

      record.children.map((form, index) => {
          selected_form_ids.push(value+'-form-'+form.id);
      });

      this.setState({ selectedRowKeysForms: this.state.selectedRowKeysForms.concat(selected_form_ids)});
    }
  }

  onSelectChoiceFormChange = (value, record) => {

    if(this.state.selectedRowKeysForms.includes(value))
    { // deselect
      this.setState({ selectedRowKeysForms: this.state.selectedRowKeysForms.filter((v) => v !== value)});

      // deselect address (no longer all address forms are selected)
      this.setState({ selectedRowKeysAddresses: this.state.selectedRowKeysAddresses.filter((v) => v !== 'address-'+record.address_id)});
    }
    else
    { // select
      this.setState({ selectedRowKeysForms: [...this.state.selectedRowKeysForms, value]});

      // TODO: check if all address forms are selected (if so: select address)
    }
  }

  onCheckChoiceAllAddresses = (value) => {

    if(value.target.checked)
    {
      var child_ids = [];
      this.props.choice_form_addresses.map((v) => {
        v.children && v.children.map((child) => {
          child_ids.push('address-'+child.address_id+'-form-'+child.id);
        })
      });

      var ids = [];

      this.props.choice_form_addresses.map((record) => {
          if(record.children)
          {
              ids.push('address-'+record.id);
          }
          else if(record.form)
          {
              child_ids.push('address-'+record.form.address_id+'-form-'+record.form.id);
          }
          else
          {
              child_ids.push('address-'+record.address_id+'-form-'+record.id);
          }
      });

      this.setState({ selectedRowKeysAddresses: ids});
      this.setState({ selectedRowKeysForms: child_ids});
    }
    else
    {
      this.setState({ selectedRowKeysAddresses: []});
      this.setState({ selectedRowKeysForms: []});
    }
  }

  onChangeFuseBoxListChecked(checkedValues)
  {
    this.setState({ selectedFuseBoxListOptions: checkedValues });
  }

  selectDeliveryForm(id)
  {
      this.setState({ formID: id, export: false, showChildForms: false, delivery_form_childs: [] });

      var has_childs = false;

      var childs = [];

      this.props.delivery_forms && this.props.delivery_forms.map((element) => {

          if(element.id == id && element.children)
          {
              has_childs = true;

              childs = element.children;
          }
      });

      if(has_childs)
      {
          this.setState({ showChildForms: true, delivery_form_childs: childs }, () => {});
      }
      else
      {
          this.setState({ export: true, delivery_form_childs: [] });
      }
  }

  translateSupportBaseFormResultStatus(status)
  {
      if(status == 'scheduled'){ return 'Ingepland'; }
      if(status == 'send'){ return 'Verzonden'; }
      if(status == 'resend'){ return 'Opnieuw verstuurd'; }
      if(status == 'reminder'){ return 'Herinnering verstuurd'; }
      if(status == 'answered'){ return 'Vragen beantwoord'; }
      if(status == 'signed'){ return 'Getekend'; }
      if(status == 'confirmed'){ return 'Bevestigd'; }

      return 'Onbekend';
  }

  /**
   * - Open
   * - Gestart
   * - *Ondertekend
   * - Afgerond
   */
  getFormStatus(status)
  {
      if(!status || status == '' || status == 'empty'){ return 'Leeg'; }
      else if(status == 'new'){ return 'Nieuw'; }
      else if(status == 'open'){ return 'Geopend'; }
      else if(status == 'started'){ return 'Gestart'; }
      else if(status == 'answering'){ return 'Deels beantwoord'; }
      else if(status == 'answered'){ return 'Beantwoord'; }
      else if(status == 'summary'){ return 'Samenvatting bekeken'; }
      else if(status == 'locked'){ return 'Vergrendeld'; }
      else if(status == 'nr-of-signatures'){ return 'Aantal handtekeningen ingesteld'; }
      else if(status == 'signed'){ return 'Ondertekend'; }
      else if(status == 'send'){ return 'Verzonden'; }
      else if(status == 'completed'){ return 'Afgerond'; }
      else { return status; }
  }

  getFormStatusses()
  {
      // text, value

      return [
          {
              text: 'Leeg',
              value: ''
          },
          {
              text: 'Nieuw',
              value: 'new'
          },
          {
              text: 'Geopend',
              value: 'open'
          },
          {
              text: 'Gestart',
              value: 'started'
          },
          {
              text: 'Deels beantwoord',
              value: 'answering'
          },
          {
              text: 'Beantwoord',
              value: 'answered'
          },
          {
              text: 'Samenvatting bekeken',
              value: 'summary'
          },
          {
              text: 'Vergrendeld',
              value: 'locked'
          },
          {
              text: 'Aantal handtekeningen ingesteld',
              value: 'nr-of-signatures'
          },
          {
              text: 'Ondertekend',
              value: 'signed'
          },
          {
              text: 'Verzonden',
              value: 'send'
          },
          {
              text: 'Afgerond',
              value: 'completed'
          }
      ]
  }

  getMappedFormStatus(status)
  {
           if(!status || status == '' || status == 'new'){ return 'empty'; }
      else if(status == 'open' || status == 'started' || status == 'answering' || status == 'answered' || status == 'summary' || status == 'locked' || status == 'nr-of-signatures' || status == 'signature'){ return 'started'; }
      // else if(status == 'signed'){ return 'signed'; }
      else if(status == 'send' || status == 'completed' || status == 'signed'){ return 'completed'; }
      else { return status; }

      // return this.getFormStatus(status);

      // return status;
  }

  getDeliveryFilters()
  {
      var filters = [];

      this.props.delivery_filters && this.props.delivery_filters.map((record) => {

          filters.push({
              text: record.description,
              value: record.id
          });
      });

      return filters;
  }

  getFormsFilters()
  {
      var filters = [];

      this.props.form_filters && this.props.form_filters.map((record) => {

          filters.push({
              text: record.name,
              value: record.id
          });
      });

      return filters;
  }

  getAddressFilters()
  {
      var filters = [];

      this.props.location_filters && this.props.location_filters.map((record) => {

          if(record.type == 'address')
          {
              filters.push({
                  text: record.full_address,
                  value: record.id
              });
          }
      });

      return filters;
  }

  getUserFilters()
  {
      var filters = [];

      this.props.user_filters && this.props.user_filters.map((record) => {

          filters.push({
              text: record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address,
              value: record.id
          });
      });

      return filters;
  }

  getStatusText(status)
  {
      // if(status == 'new'){ return 'Nieuw'; }
      if(status == 'submitted'){ return 'Controleren'; }
      else if(status == 'completed'){ return 'Afgehandeld'; }
      else { return 'Open'; }
  }

  getStatusColor(status)
  {
      // if(status == 'new'){ return '#888888'; }
      if(status == 'submitted'){ return '#ff8800'; }
      else if(status == 'completed'){ return '#00ff00'; }
      else { return '#ff0000'; }
  }

  //
  render()
  {

      const { selectedRowKeys, selectedRowKeysForms, selectedRowKeysAddresses } = this.state;

      let { filteredInfo } = this.state;
      filteredInfo = filteredInfo || {};

      // const { getFieldDecorator } = this.props.form;

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: this.props.addresses.map(value => value.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
      };

      const rowSelectionSupportBaseForms = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: this.props.support_base_forms.map(value => value.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
      };

      const rowSelectionSupportBaseResults = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: this.props.support_base_form_results.map(value => value.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
        getCheckboxProps: record => ({
          disabled: record.signed_document || record.signed_form ? false : true
        }),
      };

      const rowSelectionFormResults = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: (this.state.exportType == 'excel' ? this.props.form.results.map(value => value.id) : this.props.addresses.map(value => value.id)),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
        getCheckboxProps: record => ({
          disabled: record.id ? false : true
        }),
      };

      const rowSelectionChoiceResults = {
        selectedRowKeys,
        onChange: this.onSelectChangeChoices,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {

              // var selected_addresses = this.props.choice_form_addresses.map(form => 'address-'+form.id);
              // var selected_forms = this.props.choice_form_addresses.map(form => form.children.map(value => 'form-'+value.id) );
              //
              // this.setState({
              //   selectedRowKeys: selected_addresses.concat(selected_forms.flat()),
              // });

              this.setState({
                // selectedRowKeys: this.props.choice_form_addresses.map((address) => 'address-'+address.id),
                selectedRowKeys: this.props.choice_form_addresses.map((address) => address.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: []
              });
            },
          },
        ],
        getCheckboxProps: (record) => ({
          disabled: (
            this.state.exportFormat == 'forms' ?
            false : this.state.exportFormat != 'paid' ?
              ((record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form) ? false : true
              :
              ((record.form && record.form.total_amount == record.form.total_paid && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) /*|| record.filled_in_form*/) ? false : true
            )

          // disabled: (record) => {
          //     var has_form = ((record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form);
          //
          //     // return has_form ? false : true;
          //
          //     return (record.form || record.choice_form_id ? (has_form ? false : true) : true);
          // }
        }),
      };

      const rowSelectionDeliveryFormResults = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: this.state.delivery_form_childs.map(value => value.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
      };

      const address_columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>
              {text ? text : '-'}
          </span>
        )
      }, {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => {
            var zipcode_a = a.zipcode ? a.zipcode : '';
            var zipcode_b = b.zipcode ? b.zipcode : '';

            return zipcode_a.localeCompare(zipcode_b);
        },
      }, {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => {
            var city_a = a.city ? a.city : '';
            var city_b = b.city ? b.city : '';

            return city_a.localeCompare(city_b);
        },
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups.join(',');
            var groups_b = b.groups.join(',');

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const survey_address_columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>
              {text ? text : '-'}
          </span>
        )
      }, {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => {
            var zipcode_a = a.zipcode ? a.zipcode : '';
            var zipcode_b = b.zipcode ? b.zipcode : '';

            return zipcode_a.localeCompare(zipcode_b);
        },
      }, {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => {
            var city_a = a.city ? a.city : '';
            var city_b = b.city ? b.city : '';

            return city_a.localeCompare(city_b);
        },
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups.join(',');
            var groups_b = b.groups.join(',');

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const survey_columns = [{
          title: 'Titel',
          dataIndex: 'name',
          sorter: (a, b) => { return a.name.localeCompare(b.name)},
          render: (text, record) => (
              <span>
                  {text}
              </span>
          )
      }, {
          title: 'Aantal vragen',
          dataIndex: 'nr_of_questions',
          sorter: (a, b) => { return a.nr_of_questions.localeCompare(b.nr_of_questions)},
      }];

      const appointment_columns = [{
          title: 'Onderwerp',
          dataIndex: 'subject',
          sorter: (a, b) => { return a.subject.localeCompare(b.subject)},
          render: (text, record) => (
            <span>
                {text}
            </span>
          )
      },
      {
          title: 'Aantal adressen',
          dataIndex: 'nr_of_addresses',
          sorter: (a, b) => { return a.nr_of_addresses.localeCompare(b.nr_of_addresses)},
      },
      {
          title: 'Afspraken gemaakt',
          dataIndex: 'nr_of_appointments',
          sorter: (a, b) => { return a.nr_of_appointments.localeCompare(b.nr_of_appointments)},
      },
      {
          title: 'Afspraken niet gemaakt',
          dataIndex: 'nr_of_appointments_to_be_made',
          sorter: (a, b) => { return a.nr_of_appointments_to_be_made.localeCompare(b.nr_of_appointments_to_be_made)},
      }];

      const schedule_columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>
            {text ? text : '-'}
          </span>
        )
      },
      {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => {
          var zipcode_a = a.zipcode ? a.zipcode : '';
          var zipcode_b = b.zipcode ? b.zipcode : '';

          return zipcode_a.localeCompare(zipcode_b);
        },
      },
      {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => {
          var city_a = a.city ? a.city : '';
          var city_b = b.city ? b.city : '';

          return city_a.localeCompare(city_b);
        },
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups.join(',');
            var groups_b = b.groups.join(',');

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const form_columns = [{
          title: 'Naam',
          dataIndex: 'name',
          sorter: (a, b) => { return a.name.localeCompare(b.name)},
          render: (text, record) => (
            <span>
              {text}
            </span>
          )
      },
      {
          title: 'Uit te voeren',
          dataIndex: 'nr_of_forms',
          sorter: (a, b) => { return a.nr_of_forms.localeCompare(b.nr_of_forms)},
          render: (text, record) => (
            <span>
              {text}
            </span>
          )
      },
      {
          title: 'Voltooid',
          dataIndex: 'nr_of_completed_forms',
          sorter: (a, b) => { return a.nr_of_completed_forms.localeCompare(b.nr_of_completed_forms)},
          render: (text, record) => (
            <span>
              {text}
            </span>
          )
      },
      /*{
          title: 'Aanmaakdatum',
          dataIndex: 'created_at',
          render: (text, record) => (
            <span>
              { moment(text).format('DD/MM/YYYY') }
            </span>
          )
      },/**/
      /*{
          title: 'Laatst bijgewerkt',
          dataIndex: 'updated_at',
          render: (text, record) => (
            <span>
              { moment(text).format('DD/MM/YYYY - HH:mm') }
            </span>
          )
      }/**/];

      const form_results_columns = [{
          title: 'Adres',
          dataIndex: 'address',
          render: (text, record) => (
            <span>
              {record.address ? record.address.full_address : (record.description ? record.description : 'Geen adres of omschrijving opgegeven')}
            </span>
          ),
          sorter: (a, b) => { return a.address ? a.address.full_address.localeCompare(b.address ? b.address.full_address : '') : ('').localeCompare(b.address ? b.address.full_address : '')},
          sortDirections: ['descend', 'ascend'],
      },
      /*{
          title: 'Gebruiker',
          dataIndex: 'user_name',
          render: (text, record) => (
            <span>
                {text}
            </span>
          )
      },/**/
      {
          title: 'Status',
          dataIndex: 'status',
          render: (text, record) => (
            <span>
              { this.getFormStatus(record.status ? record.status : '') }
            </span>
          ),
          sorter: (a, b) => {

            var a_status = a ? a.status : '';
            var b_status = b ? b.status : '';

            if(a_status === undefined) {
              a_status = '';
            }

            if(b_status === undefined) {
              b_status = '';
            }

            a_status = this.getFormStatus(this.getMappedFormStatus(a_status));
            b_status = this.getFormStatus(this.getMappedFormStatus(b_status));

            return a_status.localeCompare(b_status);
          },
          sortDirections: ['descend', 'ascend'],
          filters: this.getFormStatusses(),
          filterMultiple: true,
          // onFilter: (value, record) => record.status === value,
          onFilter: (value, record) => this.formStatusFilter(value, record)
      },
      {
        title: 'Datum aangepast',
        dataIndex: 'updated_at',
        render: (text, record) => {
          return (text ?
            <span>
              { moment(text).format('DD/MM/YYYY - HH:mm') }
            </span> : 'onbekend'
          )
        },
        sorter: (a, b) => { return moment(a.updated_at).unix() - moment(b.updated_at).unix()},
        sortDirections: ['descend', 'ascend'],
      },/**/
      /*{
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups.join(',');
            var groups_b = b.groups.join(',');

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }/**/

      /*{
        title: 'Datum goedgekeurd',
        dataIndex: 'approved_on',
        render: (text, record) => {
          return (text ?
            <span>
              { moment(text).format('DD/MM/YYYY - HH:mm') }
            </span> : 'onbekend'
          )
        },
        sorter: (a, b) => { return moment(a.approved_on).unix() - moment(b.approved_on).unix()},
        sortDirections: ['descend', 'ascend'],
      },/**/
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.address.groups && a.address.groups.length ? a.address.groups.join(',') : '';
            var groups_b = b.address.groups && b.address.groups.length ? b.address.groups.join(',') : '';

            return groups_a.localeCompare(groups_b);
        },
        // sortDirections: ['ascend', 'descend'],
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups && record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record.address),
        render: (text, record) =>
        record.address && record.address.groups && record.address.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }/**/];

      const delivery_points_columns = [
          // {
          //     title: '',
          //     dataIndex: false,
          //     width: 64
          // },
          {
              title: 'Onderwerp',
              dataIndex: 'subject',
              render: (text, record) => (
                  <span>
                      {(record.parent_id ? '- ' : '') + text}
                  </span>
              )
          },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => { return a.status.localeCompare(b.status)},
            render: (text, record) => (
              <div style={{height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></div>
            ),
            filters: [
              {
                text: 'Open',
                value: 'open',
              },
              {
                text: 'Controleren',
                value: 'submitted',
              },
              {
                text: 'Afgehandeld',
                value: 'completed',
              }
            ],
            onFilter: (value, record) => record.status === value,
            width: 110
          },
          {
              title: 'Vooroplevering',
              dataIndex: 'description', // 'description',
              sorter: (a, b) => { return a.description.localeCompare(b.description)},
              render: (text, record) => (
                  <span>
                      {text ? text : record.parent_id ? 'N.v.t.' : 'Niet opgegeven'}
                  </span>
              ),
              filters: this.getDeliveryFilters(),
              onFilter: (value, record) => record.delivery_id === value,
          },
          {
              title: 'Adres',
              dataIndex: 'address_label',
              sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              filters: this.getAddressFilters(),
              onFilter: (value, record) => record.address_id === value,
          },
          {
              title: 'Opleverformulier(en)',
              dataIndex: 'delivery_form_name',
              sorter: (a, b) => { return a.delivery_form_name.localeCompare(b.delivery_form_name)},
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              filters: this.getFormsFilters(),
              onFilter: (value, record) => record.delivery_form_id && record.delivery_form_id.includes && record.delivery_form_id.includes(value),
          },
          {
              title: 'Verantwoordelijke',
              dataIndex: 'user_name',
              sorter: (a, b) => { return a.user_name.localeCompare(b.user_name)},
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              filters: this.getUserFilters(),
              onFilter: (value, record) => record.assigned_to_delivery_user_id === value,
          },
          {
              title: 'Uiterlijk gereed',
              dataIndex: 'due_date',
              render: (text, record) => (
                  <span style={{color: (record.filter !== 'expired' ? '#000000' : '#ff0000')}}>
                      {record.expired ? 'Verlopen' : (text ? moment(text).format('DD/MM/YYYY') : 'Niet opgegeven')}
                  </span>
              ),
              filters: [
                {
                  text: 'Verlopen',
                  value: 'expired',
                },
                {
                  text: 'Niet verlopen',
                  value: 'not-expired',
                },
                {
                  text: 'Niet ingesteld',
                  value: 'not-set',
                },
              ],
              onFilter: (value, record) => record.filter === value,
          },
          // {
          //     key: 'photos',
          //     title: 'Laatste foto',
          //     dataIndex: 'photos',
          //     width: 140,
          //     hideOnSmall: true,
          //     render: (text, record) => (
          //         <div class="photos">
          //             { record.photos.length > 0 ?
          //             record.photos.map((photo, index) => {
          //             return <div
          //                 class="photo"
          //                 id={'point-'+record.id+'-photo-'+(index + 1)}
          //                 onClick={() => { this.showPhoto(photo.url); }}
          //                 style={{backgroundImage: 'url('+photo.url+')', backgroundSize: 'cover', display: (index == 0 ? 'block' : 'none')}}
          //                 ></div>
          //             })
          //             : null }
          //             { record.photos.length > 1 ?
          //             <div class="buttons">
          //                 <a class="left" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, -1); }}}>{'<'}</a>
          //                 <a class="right" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, 1); }}}>{'>'}</a>
          //             </div>
          //             : null }
          //         </div>
          //     )
          // }
      ];

      /*const delivery_points_columns = [{
          key: 'description',
          title: 'Oplevering',
          dataIndex: 'delivery', // 'description',
          sorter: (a, b) => { return a.location_label.localeCompare(b.location_label)},
          render: (text, record) => (
              <span>
                  {text ? text : 'Niet opgegeven'}
              </span>
          ),
          filters: this.descriptions && this.descriptions.map((description) => {
              return {
                text: description,
                value: description,
              }
          }),
          filterMultiple: true,
          onFilter: (value, record) => this.descriptionsFilter(value, record),
      },
      {
          key: 'address_label',
          title: 'Adres',
          dataIndex: 'address_label',
          sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
          render: (text, record) => (
              <span>
                  {text}
              </span>
          )
      },
      {
          key: 'user_email_address',
          title: 'Verantwoordelijk',
          dataIndex: 'user_email_address',
          sorter: (a, b) => { return a.user_email_address.localeCompare(b.user_email_address)},
          render: (text, record) => (
              <span>
                  {text}
              </span>
          ),
          filters: this.props.delivery_users && this.props.delivery_users.map((elem) => {
            return {
              text: elem.full_name ? elem.full_name : elem.email_address,
              value: elem.id,
            }
          }),
          filterMultiple: true,
          onFilter: (value, record) => this.usersFilter(value, record),
      },
      {
          key: 'subject',
          title: 'Onderwerp',
          sorter: (a, b) => { return a.subject.localeCompare(b.subject)},
          dataIndex: 'subject',
          filters: this.subjects && this.subjects.map((subject) => {
              return {
                text: subject,
                value: subject,
              }
          }),
          filterMultiple: true,
          onFilter: (value, record) => this.subjectsFilter(value, record),
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => { return a.status.localeCompare(b.status)},
        render: (text, record) => (
          <div style={{height: 18, width: 18, borderRadius: 18, backgroundColor: this.getDeliveryPointStatusColor(text)}}></div>
        ),
        filters: [
          {
            text: 'Controleren',
            value: 'submitted',
          },
          {
            text: 'Afgehandeld',
            value: 'completed',
          },
          {
            text: 'Open',
            value: 'open',
          },
        ],
        onFilter: (value, record) => record.status === value,
      },
      {
          key: 'due_date',
          title: 'Uiterlijk gereed',
          dataIndex: 'due_date',
          sorter: (a, b) => { return a.due_date.localeCompare(b.due_date)},
          render: (text, record) => (
              <span style={{color: (record.filter !== 'expired'  ? '#000000' : '#ff0000')}}>
                  {record.expired ? 'Verlopen' : (text ? moment(text).format('D/MM/YYYY') : 'Niet opgegeven')}
              </span>
          ),
          filters: [
            {
              text: 'Verlopen',
              value: 'expired',
            },
            {
              text: 'Niet verlopen',
              value: 'not-expired',
            },
            {
              text: 'Niet ingesteld',
              value: 'not-set',
            },
          ],
          onFilter: (value, record) => record.filter === value,
      },
      {
          key: 'photos',
          title: 'Laatste foto',
          dataIndex: 'photos',
          width: 140,
          hideOnSmall: true,
          render: (text, record) => (
              <div className="photos">
                  { record.photos.length > 0 ?
                  record.photos.map((photo, index) => {
                  return <div
                      className="photo"
                      id={'point-'+record.id+'-photo-'+(index + 1)}
                      onClick={() => { this.showPhoto(photo.url); }}
                      style={{backgroundImage: 'url('+photo.url+')', backgroundSize: 'cover', display: (index == 0 ? 'block' : 'none')}}
                      ></div>
                  })
                  : null }
                  { record.photos.length > 1 ?
                  <div className="buttons">
                      <a className="left" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, -1); }}}>{'<'}</a>
                      <a className="right" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, 1); }}}>{'>'}</a>
                  </div>
                  : null }
              </div>
          )
      }];/**/

      const choice_form_columns = [{
          title: 'Adres',
          dataIndex: 'full_address',
          render: (text, record) => {
            var valueId = record.id;

            return <span>{text}</span>
          }
      },
      {
          title: 'Formulier',
          dataIndex: 'form_name',
          // sorter: (a, b) => { return ''+a.form_name.localeCompare(''+b.form_name)},
          render: (text, record) => (
            <span>
              {record.children && record.children.length > 1 ? 'Meerdere formulieren' : (record.form ? record.form.form_name : text)}
            </span>
          )
      },
      {
          title: 'Ingevuld formulier',
          dataIndex: 'submitted_form',
          // sorter: (a, b) => {
          //
          //     var a_has_form = (a.submitted_form || (a.form && (a.form.submitted_form || a.form.form_submitted || (a.form.form_status != null))) || a.filled_in_form) ? 'Ja' : 'Nee';
          //     var b_has_form = (b.submitted_form || (b.form && (b.form.submitted_form || b.form.form_submitted || (b.form.form_status != null))) || b.filled_in_form) ? 'Ja' : 'Nee';
          //
          //     return a_has_form.localeCompare(b_has_form);
          // },
          render: (text, record) => {

            var record_id = record.form ? record.form.id : record.id;
            var address_id = record.form ? record.form.address_id : record.address_id;

            var has_form = (text || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form);

            return <span>
            { record.form || record.choice_form_id ?
                (has_form ? 'Ja' : 'Nee')
                : '' }
            </span>
          }
      },
      {
          title: 'Openstaand',
          dataIndex: 'total_not_paid_formatted',
          // sorter: (a, b) => { return a.total_not_paid.localeCompare(b.total_not_paid)},
          render: (text, record) => (
            <span style={{color: record.total_not_paid > 0 ? 'red' : 'black'}}>{text}</span>
          )
      },
      {
          title: 'Voldaan',
          dataIndex: 'total_paid_formatted',
          // sorter: (a, b) => { return a.total_paid_formatted.localeCompare(b.total_paid_formatted)},
          render: (text, record) => (
            <span style={{color: record.total_not_paid > 0 ? 'orange' : 'black'}}>{text}</span>
          )
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups ? a.groups.join(',') : '';
            var groups_b = b.groups ? b.groups.join(',') : '';

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups && record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const choice_form_address_columns = [{
          title: 'Adres',
          dataIndex: 'full_address',
          render: (text, record) => {
            var valueId = record.id;

            return <span>{text}</span>
        }
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups ? a.groups.join(',') : '';
            var groups_b = b.groups ? b.groups.join(',') : '';

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups && record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const support_base_columns = [{
        title: 'Omschrijving',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
      },
      {
        title: 'Adressen',
        dataIndex: 'addresses',
        sorter: (a, b) => { return a.addresses - b.addresses},
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
      },
      {
        title: 'Verzonden',
        dataIndex: 'send',
        sorter: (a, b) => { return a.send - b.send },
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
      },
      {
        title: 'Retour',
        dataIndex: 'returned',
        sorter: (a, b) => { return a.returned - b.returned },
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
      },
      {
        title: 'Getekend',
        dataIndex: 'percentage_approved',
        sorter: (a, b) => { return parseFloat(a.percentage_approved) - parseFloat(b.percentage_approved) },
        render: (text, record) => (
          <span>
            {text}%
          </span>
        )
      }];

      const support_base_results_columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
      },/**/
      /*{
        title: 'Verzonden',
        dataIndex: 'send',
        render: (text, record) => (
          <span>
            {text ? moment(text).format('DD/MM/YYYY') : 'Nog niet verzonden'}
          </span>
        )
      },/**/
      /*{
        title: 'Verzendwijze',
        dataIndex: 'send_methods',
        render: (text, record) => (
          <span>
            {text && text.map((value, key) => {
                if(value == 'letter')
                {
                    return <div style={{float: 'left'}}><Icon type="mail" /></div>
                }
                else if(value == 'email')
                {
                    // return <Icon type="paper-clip" />
                    return <div style={{float: 'left', marginLeft: 5}}>@</div>
                }
                else
                {
                    //return value;
                }
            })}
          </span>
        )
      },/**/
      {
        title: 'Getekend formulier',
        dataIndex: 'signed_form_pdf',
        sorter: (a, b) => {
            var status_a = (a.status == 'signed' || a.status == 'confirmed') ? 'Ja' : 'Nee';
            var status_b = (b.status == 'signed' || b.status == 'confirmed') ? 'Ja' : 'Nee';

            return status_a.localeCompare(status_b);
        },
        render: (text, record) => (
          <span>
            { (record.status == 'signed' || record.status == 'confirmed') ? 'Ja' : 'Nee' }
          </span>
        ),
        filters: [
          {
            text: 'Ja',
            value: '1',
          },
          {
            text: 'Nee',
            value: '0',
          }
        ],
        onFilter: (value, record) => { if(value == '1'){ return (record.status == 'signed' || record.status == 'confirmed'); }else if(value == '0'){ return (record.status != 'signed' && record.status != 'confirmed'); }/*else{ return true; }*/ },
      },
      {
        title: 'Akkoord',
        dataIndex: 'agreed',
        sorter: (a, b) => {
            var status_a = (a.status == 'signed' || a.status == 'confirmed') ? 'Ja' : 'Nee';
            var status_b = (b.status == 'signed' || b.status == 'confirmed') ? 'Ja' : 'Nee';

            return status_a.localeCompare(status_b);
        },
        render: (text, record) => (
          <span>
            { (record.status != 'signed' && record.status != 'confirmed') ?
                    <CloseCircleOutlined style={{color: 'grey'}} />
                :
                    text === 1 || text == 'agree' || text === true ?
                        <CheckCircleOutlined style={{color: 'green'}} />
                    :
                        <CloseCircleOutlined style={{color: 'red'}} />
            }
          </span>
        ),
        filters: [
          {
            text: 'Ja',
            value: '1',
          },
          {
            text: 'Nee',
            value: '0',
          }
        ],
        onFilter: (value, record) => {
            if(value === '1')
            {
                return ((record.status == 'signed' || record.status == 'confirmed') && (record.agreed === 1 || record.agreed === 'agree' || record.agreed === true ));
            }
            else if(value === '0')
            {
                // return (record.status != 'signed' || record.status != 'confirmed') || ((record.status == 'signed' || record.status == 'confirmed') && record.agreed !== 1 && record.agreed !== 'agree' && record.agreed !== true);
                return ((record.status == 'signed' || record.status == 'confirmed') && (record.agreed === 1 || record.agreed === 'agree' || record.agreed === true )) ? false : true;
            }
            // else
            // {
            //     return true;
            // }
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => {
            var status_a = this.translateSupportBaseFormResultStatus(a.status);
            var status_b = this.translateSupportBaseFormResultStatus(b.status);

            return status_a.localeCompare(status_b);
        },
        render: (text, record) => (
          <span>
            {this.translateSupportBaseFormResultStatus(text)}
          </span>
        )
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups.join(',');
            var groups_b = b.groups.join(',');

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const fuse_box_list_columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>{text ? text : '-'}</span>
        )
      },
      {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => {
            var zipcode_a = a.zipcode ? a.zipcode : '';
            var zipcode_b = b.zipcode ? b.zipcode : '';

            return zipcode_a.localeCompare(zipcode_b);
        },
      },
      {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => {
            var city_a = a.city ? a.city : '';
            var city_b = b.city ? b.city : '';

            return city_a.localeCompare(city_b);
        },
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        sorter: (a, b) => {
            var groups_a = a.groups.join(',');
            var groups_b = b.groups.join(',');

            return groups_a.localeCompare(groups_b);
        },
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }];

      const deliver_form_columns =
      [
          {
              title: 'Omschrijving',
              dataIndex: 'description'
          },
          {
              title: 'Status',
              dataIndex: 'status',
              render: (text, record) => (
                <span>{ text == 'completed' ? 'Afgerond' : 'Open' }</span>
              ),
              filters: [
                  {
                      text: 'Open',
                      value: 'open'
                  },
                  {
                      text: 'Afgerond',
                      value: 'completed'
                  }
              ],
              filterMultiple: true,
              onFilter: (value, record) => record.status === value
          },
      ];

      // const { form } = this.props;
      // const { form } = this.formRef.props;

      // if(this.state.loading){ return null; }

      // return null;

      return (
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <Menu
                id="export_menu"
                mode="inline"
                style={{ width: 300}}
                selectedKeys={[this.state.module]}
                >

                <Menu.Item onClick={() => { this.export('addresses') }} key="addresses"><ContactsOutlined /> Adressen</Menu.Item>

                { (this.props.HaveAbility('address', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 17}) && _.findWhere(this.props.selectedCustomer.modules, {id: 17}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('dossier', 'read') || this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 17})} onClick={() => { this.export('dossier') }} key="dossier"><FolderOutlined /> Dossier</Menu.Item>
                : null }

                { (this.props.HaveAbility('appointment', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 3}) && _.findWhere(this.props.selectedCustomer.modules, {id: 3}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('appointment', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 3})} onClick={() => { this.export('appointments') }} key="appointments"><CalendarOutlined /> Afspraken</Menu.Item>
                : null }

                { (this.props.HaveAbility('support-base', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 14}) && _.findWhere(this.props.selectedCustomer.modules, {id: 14}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('support-base', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 14})} onClick={() => { this.export('support_base') }} key="support_base"><TeamOutlined /> Draagvlak</Menu.Item>
                : null }

                { (this.props.HaveAbility('form', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 19}) && _.findWhere(this.props.selectedCustomer.modules, {id: 19}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('form', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 19})} onClick={() => { this.export('form') }} key="form"><ContainerOutlined /> Formulieren</Menu.Item>
                : null }

                { (this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 16}) && _.findWhere(this.props.selectedCustomer.modules, {id: 16}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('choice', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 16})} onClick={() => { this.export('choice_forms') }} key="choice_forms"><ReconciliationOutlined /> Keuze</Menu.Item>
                : null }

                { (this.props.HaveAbility('form', 'read') || this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && (_.findWhere(this.props.project.modules, {id: 19}) || _.findWhere(this.props.project.modules, {id: 16})) && (_.findWhere(this.props.selectedCustomer.modules, {id: 19}) || _.findWhere(this.props.selectedCustomer.modules, {id: 16})) ?
                <Menu.Item disabled={(!this.props.HaveAbility('form', 'read') && !this.props.HaveAbility('choice', 'read')) || !this.props.project.user_role === 'admin' || (!_.findWhere(this.props.project.modules, {id: 16}) && !_.findWhere(this.props.project.modules, {id: 19}))} onClick={() => { this.export('fuse_box_list') }} key="fuse_box_list"><ExperimentOutlined /> Meterkastlijst</Menu.Item>
                : null }

                { (this.props.HaveAbility('scheduling', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 1}) && _.findWhere(this.props.selectedCustomer.modules, {id: 1}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('scheduling', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 1})} onClick={() => { this.export('schedule') }} key="schedule"><ExperimentOutlined /> Planning</Menu.Item>
                : null }

                { (this.props.HaveAbility('survey', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 9}) && _.findWhere(this.props.selectedCustomer.modules, {id: 9}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('survey', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 9})} onClick={() => { this.export('survey_forms') }} key="survey_forms"><ExperimentOutlined /> Enquête</Menu.Item>
                : null }

                { (this.props.HaveAbility('deliver', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 15}) && _.findWhere(this.props.selectedCustomer.modules, {id: 15}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('deliver', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 15})} onClick={() => { this.export('delivery_points') }} key="delivery_points"><AuditOutlined /> Opleveren</Menu.Item>
                : null }

              </Menu>

              <div style={{padding: 24, width: '100%'}}>

                <Form layout={'vertical'}>

                  { /* EXPORT: addresses */ }
                  <div style={{ display: (this.state.modalExportAddresses ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Adressen</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert een Excel bestand met daarin alle adressen van het project. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export">
                                  <Radio.Group value={'export'}>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'export'}>Adressen (Excel)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportAddresses(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false}>Exporteren</Button>
                          </Col>
                      </Row>
                  </div>

                  { /* EXPORT: dossier */ }
                  <div style={{ display: (this.state.modalExportDossier ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Dossier</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert op de achtergrond een zip bestand met de resultaten van de geselecteerde adressen. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export">
                                  <Radio.Group value={'export'}>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'export'}>Documenten (Zip)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              {/*<Form.Item required label="E-mailadres(sen)">
                                  <Select
                                    mode="tags"
                                    open={false}
                                    tokenSeparators={[',', '\n']}
                                    style={{ width: '100%' }}
                                    defaultValue={this.state.emailTo}
                                    onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                    onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                    onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                    ></Select>
                                    <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                              </Form.Item>*/}
                          </Col>
                      </Row>
                      <Row className="mbm">
                        <Col span={12}>
                            <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportDossier(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length > 0 ? false : true}>Exporteren</Button>
                            <div className="mls mrs" style={{ display: 'inline' }}>{ this.state.selectedRowKeys.length } / { this.props.addresses.length } adressen geselecteerd</div>
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Zoeken..."
                            allowClear
                            onSearch={(value) => { this.searchAddresses(value); }}
                            style={{ width: 200, float: 'right' }}
                            />
                        </Col>
                      </Row>
                      <Row className="mbm">
                          <Col span={24} >
                              <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                columns={address_columns}
                                dataSource={this.state.loading ? [] : this.props.addresses}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                                />
                          </Col>
                      </Row>
                  </div>

                  { /* EXPORT: surveys */ }
                  <div style={{ display: (this.state.modalExportSurvey ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Enquete</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Wanneer gekozen wordt voor het exporteren van de vragen dan genereert Cobee een Excel bestand met daarin de vragen van de enquete. Wordt er gekozen voor het exporteren van de resutlaten, dan genereert Cobee op de achtergrond een zip bestand met de resultaten van de geselecteerde adressen. Let op: Op basis van de hoeveelheid data kan dit even duren." />
                          </Col>
                      </Row>
                      <Row className="mbm">
                        <Col span={24}>
                            {/*<Form.Item required label="Type export">
                                <Select
                                  value={this.state.exportType}
                                  onChange={(type) => {
                                      this.setState({ exportType: type });
                                  }}
                                  >
                                  <Select.Option key='form'>Vragen (Excel)</Select.Option>
                                  <Select.Option key='results'>Antwoorden (Zip)</Select.Option>
                                </Select>
                            </Form.Item>*/}

                            <Form.Item required label="Type export">
                                <Radio.Group
                                  onChange={(e) => {
                                    this.setState({ exportType: e.target.value, selectedRowKeys: [] });
                                  }}
                                  value={this.state.exportType}
                                  >
                                    <Radio style={{display: 'block', lineHeight: '30px'}} value={'form'}>Vragen (Excel)</Radio>
                                    <Radio style={{display: 'block', lineHeight: '30px'}} value={'results'}>Antwoorden (Zip)</Radio>
                                </Radio.Group>
                            </Form.Item>

                        </Col>
                      </Row>
                      <Row className="mbm">
                          <Col span={24}>
                              <Form.Item required label="Enquete">
                                  <Select
                                    value={this.state.surveyID}
                                    onChange={(id) => {
                                        this.setState({ surveyID: id, selectedRowKeys: [] });

                                        if(id)
                                        {
                                            this.props.getSurveyAddressesAction(id);
                                        }
                                    }}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                    { this.props.surveys && this.props.surveys.map((element, index) => {
                                        return <Select.Option key={element.id}>{element.name}</Select.Option>
                                      })
                                    }
                                  </Select>
                              </Form.Item>
                          </Col>
                      </Row>
                      {/*<Row style={{ display: this.state.exportType != 'results' ? 'none' : 'block' }}>
                        <Col span={24}>
                            <Form.Item required label="E-mailadres(sen)">
                                <Select
                                  mode="tags"
                                  open={false}
                                  tokenSeparators={[',', '\n']}
                                  style={{ width: '100%' }}
                                  defaultValue={this.state.emailTo}
                                  onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                  onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                  onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                  ></Select>
                                  <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                            </Form.Item>
                        </Col>
                      </Row>*/}

                      <Row className="mbm">
                          <Col span={24}>
                              <Button
                                type="new"
                                loading={this.state.loading && !this.state.init}
                                onClick={() => { this.exportSurvey(); }}
                                disabled={(this.state.init || this.state.loading || !this.state.surveyID || !this.state.exportType || (this.state.exportType == 'results' && this.state.selectedRowKeys.length < 1)) ? true : false}
                                >Exporteren</Button>
                          </Col>
                      </Row>

                      <Row className="mbm" style={{display: !this.state.surveyID || this.state.exportType != 'results' ? 'none' : 'block'}}>
                          <Col span={24} >
                              <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                columns={survey_address_columns}
                                dataSource={this.state.loading ? [] : this.props.survey_addresses}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                                />
                          </Col>
                      </Row>
                  </div>


                  { /* EXPORT: appointments */ }
                  <div style={{ display: (this.state.modalExportAppointments ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Afspraken</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert een Excel bestand met de resultaten van de geselecteerde afspraak. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export">
                                  <Radio.Group value={'export'}>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'export'}>Overzicht (Excel)</Radio>
                                  </Radio.Group>
                              </Form.Item>
                          </Col>
                      </Row>
                      <Row className="mbm">
                          <Col span={24} >
                            <Form.Item required label="Afspraak">
                                <Select
                                  value={this.state.appointmentID}
                                  showSearch={true}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props && option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(id) => {
                                      this.setState({ appointmentID: id });
                                  }}
                                  >
                                  { this.props.appointments && this.props.appointments.length && this.props.appointments.map((element, index) => {
                                      return <Select.Option id={element.id} key={element.id}>{element.subject}</Select.Option>
                                  }) }
                                </Select>
                            </Form.Item>
                          </Col>
                      </Row>
                      <Row className="mbm">
                        <Col span={24}>
                            <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportAppointment(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.appointmentID ? false : true}>Exporteren</Button>
                      </Col>
                    </Row>
                  </div>

                  { /* EXPORT: appointments */ }
                  <div style={{ display: (this.state.modalExportSchedules ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                            <h2>Planning</h2>

                            <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert een zip bestand op basis van het type en de geselecteerde adressen. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                          </Col>
                      </Row>
                      <Row className="mbm">
                        <Col span={24}>
                            {/*<Form.Item required label="Type export">
                                <Select
                                  value={this.state.exportType}
                                  onChange={(type) => {
                                      this.setState({ exportType: type, selectedRowKeys: [] });
                                  }}
                                  >
                                  <Select.Option key='simple'>Eenvoudig (Zip)</Select.Option>
                                  <Select.Option key='advanced'>Uitgebreid (Zip)</Select.Option>
                                </Select>
                            </Form.Item>*/}

                            <Form.Item required label="Type export">
                                <Radio.Group
                                  onChange={(e) => {
                                    this.setState({ exportType: e.target.value, selectedRowKeys: [] });
                                  }}
                                  value={this.state.exportType}
                                  >
                                    <Radio style={{display: 'block', lineHeight: '30px'}} value={'simple'}>Eenvoudig (Zip)</Radio>
                                    <Radio style={{display: 'block', lineHeight: '30px'}} value={'advanced'}>Uitgebreid (Zip)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                      </Row>
                      <Row className="mbm">
                        <Col span={12}>
                            <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportSchedule(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || (this.state.exportType && this.state.selectedRowKeys.length >= 1 ? false : true)}>Exporteren</Button>
                            <div className="mls mrs" style={{ display: 'inline' }}>{ this.state.selectedRowKeys.length } / { this.props.schedule_addresses && this.props.schedule_addresses.length } adressen geselecteerd</div>
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Zoeken..."
                            allowClear
                            onSearch={(value) => { this.searchScheduleAddresses(value); }}
                            style={{ width: 200, float: 'right' }}
                            />
                        </Col>
                      </Row>
                      <Row className="mbm" style={{display: this.state.exportType == false ? 'none' : 'block'}}>
                          <Col span={24} >
                              <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                columns={schedule_columns}
                                dataSource={this.state.loading ? [] : this.props.schedule_addresses}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                                />
                          </Col>
                      </Row>
                  </div>

                  { /* EXPORT: form */ }
                  <div style={{ display: (this.state.modalExportForm ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Formulieren</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert op de achtergrond een bestand met resultaten op basis van de selectie. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export">
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({
                                          exportType: e.target.value,
                                          exportSubType: 'occupant',
                                          selectedRowKeys: []
                                      });
                                    }}
                                    value={this.state.exportType}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'form'}>Leeg formulier (Pdf)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'forms'}>Leeg formulier per adres (Pdf)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'excel'}>Resultaten (Excel)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'multiple'}>Resultaten (Zip)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              { (this.state.exportType == 'form' || this.state.exportType == 'forms') ?  <Form.Item required label="Voor wie">
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportSubType: e.target.value });
                                    }}
                                    value={this.state.exportSubType}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'occupant'}>Bewoners</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'user'}>Beheerders</Radio>
                                  </Radio.Group>
                              </Form.Item> : null }

                              <div style={{ display: (this.state.exportType == 'form' ? 'block' : 'none') }}>
                                  <Row className="mbm">
                                      <Col span={24} >
                                            <Form.Item required label="Formulier">
                                                <Select
                                                  value={this.state.formID}
                                                  showSearch={true}
                                                  optionFilterProp="children"
                                                  filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                  }
                                                  onChange={(id) => {
                                                      this.setState({ formID: id });
                                                  }}
                                                  >
                                                  { this.props.appointments && this.props.forms.map((element, index) => {
                                                      return <Select.Option key={element.id}>{element.name}</Select.Option>
                                                    })
                                                  }
                                                </Select>
                                            </Form.Item>
                                      </Col>
                                  </Row>
                                  <Row className="mbm">
                                    <Col span={24}>
                                        <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportForm(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.formID ? false : true}>Exporteren</Button>
                                    </Col>
                                  </Row>
                              </div>

                              { this.state.exportType == 'multiple' ?
                              <Form.Item required label="Opmaak" /*style={{display: this.state.deliveryPointsArchived ? 'block' : 'none'}}*/>
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportFormat: e.target.value, selectedRowKeys: [] });
                                    }}
                                    value={this.state.exportFormat ? this.state.exportFormat : 'zip'}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'pdf'}>Formulier (Pdf)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'zip'}>Formulier + foto's (Zip)</Radio>
                                  </Radio.Group>
                              </Form.Item> : null }

                              <div style={{ display: (this.state.exportType != 'form' ? 'block' : 'none') }} >

                                  <Row className="mbm">
                                      <Col span={24} >
                                          { this.state.exportType != 'multiple' ?
                                            <Form.Item required label="Formulier">
                                              <Select
                                                value={this.state.formID}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(id) => {
                                                    this.setState({ formID: id });

                                                    this.setState({ loading: true }, () => {

                                                        this.props.getFormAction(id, true).then(() => {

                                                            this.setState({ form_results: this.props.form.results }, () => {

                                                                this.setState({ loading: false });
                                                            });
                                                        });
                                                    });
                                                }}
                                                >
                                                { this.props.forms && this.props.forms.map((element, index) => {
                                                    return <Select.Option key={element.id}>{element.name}</Select.Option>
                                                  })
                                                }
                                              </Select>
                                            </Form.Item>
                                            :
                                            <Form.Item required label="Formulieren">
                                              <Select
                                                value={this.state.formIDs}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(ids) => {
                                                    this.setState({ formIDs: ids }, () => {
                                                        this.reloadFormsResults(ids);
                                                    });
                                                }}
                                                mode="multiple"
                                                >
                                                { this.props.forms && this.props.forms.map((element, index) => {
                                                    return <Select.Option key={element.id}>{element.name}</Select.Option>
                                                  })
                                                }
                                              </Select>
                                            </Form.Item>
                                          }

                                          { /*<Form.Item required label="E-mailadres(sen)">
                                              <Select
                                                mode="tags"
                                                open={false}
                                                tokenSeparators={[',', '\n']}
                                                style={{ width: '100%' }}
                                                defaultValue={this.state.emailTo}
                                                onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                                onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                                onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                                ></Select>
                                                <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                                          </Form.Item>*/ }

                                          <div style={{display: this.state.exportType != 'forms' && this.state.exportType != 'multiple' ? 'block' : 'none'}}>
                                              <Row className="mbm">
                                                <Col span={12}>
                                                    <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportForm(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length > 0 ? false : true}>Exporteren</Button>
                                                </Col>
                                                <Col span={12}>
                                                  <Search
                                                    placeholder="Zoeken..."
                                                    allowClear
                                                    onSearch={(value) => { this.searchFormResults(value); }}
                                                    style={{ width: 200, float: 'right' }}
                                                    />
                                                </Col>
                                              </Row>
                                              <Row className="mbm">
                                                  <Col span={24} >
                                                      {this.state.loading ? null : <CustomTable
                                                        rowKey='id'
                                                        // rowKey={(record, index) => {
                                                        //   if(record.id) {
                                                        //     return record.id;
                                                        // } else {
                                                        //     return 'custom_id-'+(index+1);
                                                        //   }
                                                        // }}
                                                        size="middle"
                                                        rowSelection={rowSelectionFormResults}
                                                        columns={form_results_columns}
                                                        dataSource={this.state.loading || !this.state.formID ? [] : this.props.form.results}
                                                        // dataSource={/*this.state.loading || !this.state.formID ? [] :*/ this.props.forms_results}
                                                        loading={this.state.loading}
                                                        onChange={this.handleTableChange}
                                                        />}
                                                  </Col>
                                              </Row>
                                          </div>

                                          <div style={{display: this.state.exportType == 'forms' ? 'block' : 'none'}}>
                                              <Row className="mbm">
                                                <Col span={12}>
                                                    <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportForm(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length > 0 ? false : true}>Exporteren</Button>
                                                </Col>
                                                <Col span={12}>
                                                  <Search
                                                    placeholder="Zoeken..."
                                                    allowClear
                                                    onSearch={(value) => { this.searchAddresses(value); }}
                                                    style={{ width: 200, float: 'right' }}
                                                    />
                                                </Col>
                                              </Row>
                                              <Row className="mbm">
                                                  <Col span={24} >
                                                      <CustomTable
                                                        rowKey='id'
                                                        size="middle"
                                                        rowSelection={rowSelectionFormResults}
                                                        columns={address_columns}
                                                        dataSource={this.props.addresses}
                                                        loading={this.state.loading}
                                                        onChange={this.handleTableChange}
                                                        />
                                                  </Col>
                                              </Row>
                                          </div>

                                          <div style={{display: this.state.exportType == 'multiple' && this.state.formIDs && this.state.formIDs.length > 0 ? 'block' : 'none'}}>
                                              <Row className="mbm">
                                                <Col span={12}>
                                                    <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportForms(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length > 0 ? false : true}>Exporteren</Button>
                                                </Col>
                                                <Col span={12}>
                                                  <Search
                                                    placeholder="Zoeken..."
                                                    allowClear
                                                    onSearch={(value) => { this.searchFormsResults(value); }}
                                                    style={{ width: 200, float: 'right' }}
                                                    />
                                                </Col>
                                              </Row>
                                              <Row className="mbm">
                                                  <Col span={24} >
                                                      <CustomTable
                                                          rowKey='id'
                                                          size="middle"
                                                          rowSelection={rowSelectionFormResults}
                                                          columns={form_results_columns}
                                                          dataSource={this.props.forms_results}
                                                          loading={this.state.loading}
                                                          onChange={this.handleTableChange}
                                                          />
                                                  </Col>
                                              </Row>
                                          </div>

                                      </Col>
                                  </Row>
                              </div>

                          </Col>
                      </Row>
                  </div>

                  { /* EXPORT: delivery points */ }
                  <div style={{ display: (this.state.modalExportDeliveryPoints ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Opleveren</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Wanneer gekozen is voor één van de pdf opties, genereert Cobee op de achtergrond een pdf bestand met de resultaten van de geselecteerde adressen. Vul een of meerdere e-mailadressen in naar waar het export bestand zal worden verstuurd. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export" /*style={{display: this.state.deliveryPointsArchived ? 'block' : 'none'}}*/>
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportType: e.target.value, exportFormat: false, deliveryPointsArchived: false, selectedRowKeys: [] });

                                      if(e.target.value == 'form')
                                      {
                                          this.setState({ exportFormat: 'zip' });
                                      }
                                    }}
                                    value={this.state.exportType}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'form'}>Opleverformulier</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'points'}>Opleverpunten</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              { this.state.exportType == 'points' ?
                              <Form.Item required label="Opmaak" /*style={{display: this.state.deliveryPointsArchived ? 'block' : 'none'}}*/>
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportFormat: e.target.value, selectedRowKeys: [] });
                                    }}
                                    value={this.state.exportFormat}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'simple'}>Compact (Pdf)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'extended'}>Uitgebreid (Pdf)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'excel'}>Overzicht (Excel)</Radio>
                                  </Radio.Group>
                              </Form.Item> : null }

                              { this.state.exportType == 'form' ?
                              <Form.Item required label="Opmaak" /*style={{display: this.state.deliveryPointsArchived ? 'block' : 'none'}}*/>
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportFormat: e.target.value, selectedRowKeys: [] });
                                    }}
                                    value={this.state.exportFormat ? this.state.exportFormat : 'zip'}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'pdf'}>Formulier (Zip)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'zip'}>Formulier + foto's (Zip)</Radio>
                                  </Radio.Group>
                              </Form.Item> : null }

                              { this.state.exportType == 'form' ?
                              <div>
                                  <Form.Item required label="Opleverformulier">
                                    <Select
                                      value={this.state.formID}
                                      showSearch={true}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      onChange={(id) => {
                                          // this.setState({ formID: id });
                                          this.selectDeliveryForm(id);
                                      }}
                                      //mode="multiple"
                                      >
                                      { this.props.delivery_forms && this.props.delivery_forms.map((element) => {
                                          // if(element.children)
                                          // {
                                          //     return element.children.map((sub_element) => {
                                          //
                                          //         // return <Select.Option key={element.id+'_'+sub_element.id}>{sub_element.address ? sub_element.description + ' | ' + sub_element.address : sub_element.description}</Select.Option>
                                          //         return <Select.Option key={sub_element.delivery_form_id+'_'+sub_element.id}>{sub_element.address ? sub_element.description + ' | ' + sub_element.address : sub_element.description}</Select.Option>
                                          //     });
                                          // }
                                          // else
                                          {
                                              return <Select.Option key={element.id}>{element.name}</Select.Option>
                                          }
                                        })
                                      }
                                    </Select>
                                  </Form.Item>

                                  <Row className="mbm">
                                      <Col span={24}>
                                          <Button
                                            type="new"
                                            loading={this.state.loading && !this.state.init}
                                            onClick={() => { this.exportDeliveryForm(); }}
                                            disabled={
                                                this.state.init
                                                ||
                                                !this.state.formID
                                                ||
                                                (this.state.showChildForms && (!this.state.selectedRowKeys || !this.state.selectedRowKeys.length))
                                                ?
                                                true : false
                                            }
                                            >
                                            Exporteren
                                            </Button>
                                      </Col>
                                  </Row>

                                  { this.state.showChildForms ? <Row className="mbm">
                                      <Col span={24}>
                                          <CustomTable
                                            rowKey='id'
                                            size="middle"
                                            rowSelection={rowSelectionDeliveryFormResults}
                                            columns={deliver_form_columns}
                                            dataSource={this.state.delivery_form_childs && this.state.delivery_form_childs.length ? this.state.delivery_form_childs : []}
                                            />
                                      </Col>
                                  </Row> : null }

                              </div> : null }

                              { this.state.exportType == 'points' ?
                              <Form.Item required label="Type punten">
                                  <Radio.Group
                                      value={this.state.deliveryPointsArchived}
                                      onChange={(e) => {
                                          var type = e.target.value;

                                          let archived = (type == 'archived' ? true : (type !== 'all' ? false : 'all'));

                                          this.setState({ deliveryPointsArchived: type });

                                          this.setState({ loading: true, loaded: false });

                                          this.props.getPointsAction(this.props.match.params.id, archived).then(() => {
                                              // this.setState({ loading: false });

                                              this.props.delivery_points.map((obj, index) => {
                                                  this.isDuplicateDescription(obj);
                                              });

                                              this.props.delivery_points.map((obj, index) => {
                                                  this.isDuplicateSubject(obj);
                                              });

                                              // this.setState({ loaded: true, loading: false });

                                              this.props.getUsersAction(this.props.match.params.id, 'contractor').then(() => {
                                                  this.setState({ loaded: true, loading: false });
                                              });
                                          });
                                      }}
                                      >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'open'}>Niet gearchiveerde punten</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'archived'}>Gearchiveerde punten</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'all'}>Alle punten</Radio>
                                  </Radio.Group>
                              </Form.Item> : null }

                          </Col>
                      </Row>

                      <div style={{display: this.state.deliveryPointsArchived ? 'block' : 'none'}}>
                          <Row className="mbm">
                            <Col span={12}>
                                <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportDeliveryPoints(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length > 0 ? false : true}>Exporteren</Button>
                            </Col>
                            <Col span={12}>
                              <Search
                                placeholder="Zoeken..."
                                allowClear
                                onSearch={(value) => { this.searchDeliveryPoints(value); }}
                                style={{ width: 200, float: 'right' }}
                                />
                            </Col>
                          </Row>
                          <Row className="mbm">
                              <Col span={24} >
                                  { !this.state.loaded ? null : <CustomTable
                                    rowKey='id'
                                    size="middle"
                                    rowSelection={rowSelection}
                                    columns={delivery_points_columns}
                                    dataSource={!this.state.loading && this.props.delivery_points ? this.props.delivery_points : []}
                                    loading={this.state.loading}
                                    //onChange={this.handleTableChange}
                                    />}
                              </Col>
                          </Row>
                      </div>

                  </div>


                  { /* EXPORT: choice form */ }
                  <div style={{ display: (this.state.modalExportChoiceFormResults ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Keuze</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert op de achtergrond een bestand met de resultaten van de geselecteerde adressen. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export">
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({
                                          exportType: e.target.value,
                                          exportFormat: e.target.value == 'forms' ? 'forms' : this.state.exportFormat,
                                          selectedRowKeys: []
                                      });
                                    }}
                                    value={this.state.exportType}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'forms'}>Lege in te vullen formulieren (Zip)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'document'}>Formulieren (Excel & Zip)</Radio>
                                      {/*<Radio style={{display: 'block', lineHeight: '30px'}} value={'list'}>Resultaten overzicht formulier (Excel)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'order'}>Status overzicht formulier (Excel)</Radio>*/}
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'status'}>Status overzicht alle adressen (Excel)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'paid'}>Status overzicht adressen die hebben betaald (Excel)</Radio>
                                  </Radio.Group>
                              </Form.Item>
                          </Col>
                      </Row>

                      <div style={{ display: (this.state.exportType == 'status' || this.state.exportType == 'paid' ? 'block' : 'none') }}>
                          <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportChoiceStatus(this.state.exportType); }}>Exporteren</Button>
                      </div>

                      <div style={{ display: (this.state.exportType == 'document' || this.state.exportType == 'list' || this.state.exportType == 'order' ? 'block' : 'none') }}>
                          <Row className="mbm">
                              <Col span={24} >
                                  <Form.Item required label="Formulier">
                                      <Select
                                        onChange={(value) => {
                                            this.setState({ choice_form_id: value, selectedRowKeys: [] }, () => {
                                                this.props.getChoiceFormStatusAction(this.props.match.params.id, value);
                                            });
                                        }}
                                        value={this.state.choice_form_id}
                                        filterOption={(inputValue, option) =>
                                          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                      >
                                        { this.props.choice_forms.map((element) => {
                                        return <Select.Option key={element.id}>{element.description}</Select.Option>
                                        } ) }
                                      </Select>
                                  </Form.Item>
                              </Col>
                          </Row>

                          { this.state.choice_form_id ?
                          <div>
                              { this.state.exportType != 'forms' ?
                              <Row className="mbm">
                                <Col span={24}>
                                    <Form.Item required label="Exporteren als">
                                        <Radio.Group
                                          value={this.state.exportFormat}
                                          onChange={(e) => {
                                              var type = e.target.value;
                                              this.setState({ exportFormat: type });
                                          }}
                                          >
                                            <Radio style={{display: 'block', lineHeight: '30px'}} value={'documents'}>Ingevulde formulieren (Zip + Excel)</Radio>
                                            <Radio style={{display: 'block', lineHeight: '30px'}} value={'excel'}>Antwoorden ingevulde formulieren (Excel)</Radio>
                                            <Radio style={{display: 'block', lineHeight: '30px'}} value={'order'}>Bestellijst ingevulde formulieren (Excel)</Radio>
                                            <Radio style={{display: 'block', lineHeight: '30px'}} value={'paid'}>Overzicht van alle adressen die hebben betaald (Excel)</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                              </Row> : null }

                              <Row className="mbm">
                                <Col span={24}>
                                    <Button
                                        type="new"
                                        loading={this.state.loading && !this.state.init}
                                        onClick={() => { this.exportChoiceForm(); }}
                                        // disabled={((this.state.init ? true : false) || (this.state.loading ? true : false) || (this.state.exportFormat ? false : true) || (!this.state.selectedRowKeys || this.state.selectedRowKeys.length < 1 ? true : false)) ? true : false}
                                        disabled={this.state.init || this.state.loading || !this.state.exportFormat || (this.state.exportFormat != 'paid' && (!this.state.selectedRowKeys || this.state.selectedRowKeys.length < 1)) ? true : false }
                                        >
                                        Exporteren
                                    </Button>
                                    { this.state.exportFormat != 'paid' ? <div className="mls mrs" style={{ display: 'inline' }}>{ this.state.selectedRowKeys.length } / { this.props.choice_form_addresses.length } adressen geselecteerd</div> : null }
                                </Col>
                              </Row>

                              { this.state.exportFormat != 'paid' ? <Row className="mbm">
                                  <Col span={24} >
                                      <CustomTable
                                        rowKey={(record) => {

                                          if(record.children)
                                          {
                                            record.children = [];
                                          }

                                          return record.id;
                                        }}
                                        size="middle"
                                        indentSize="0"
                                        rowSelection={rowSelectionChoiceResults}
                                        columns={choice_form_columns}
                                        dataSource={this.state.loading ? [] : this.props.choice_form_addresses}
                                        expandedRowRender={undefined}
                                        expandIcon={() => { return null; }}
                                        loading={this.state.loading}
                                        onChange={this.handleTableChange}
                                        />
                                  </Col>
                              </Row> : null }

                          </div> : null }
                      </div>

                      <div style={{ display: (this.state.exportType == 'forms' ? 'block' : 'none') }}>
                          <Row className="mbm">
                              <Col span={24} >
                                  <Form.Item required label="Formulier">
                                      <Select
                                        onChange={(value) => {
                                            this.setState({ choice_form_id: value, selectedRowKeys: [] }, () => {

                                                this.props.getChoiceFormStatusAction(this.props.match.params.id, value);
                                            });
                                        }}
                                        value={this.state.choice_form_id}
                                        filterOption={(inputValue, option) =>
                                          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                      >
                                        { this.props.choice_forms.map((element) => {
                                        return <Select.Option key={element.id}>{element.description}</Select.Option>
                                        } ) }
                                      </Select>
                                  </Form.Item>
                              </Col>
                          </Row>

                          { this.state.choice_form_id ?
                          <div>
                              <Row className="mbm">
                                <Col span={24}>
                                    <Button
                                        type="new"
                                        loading={this.state.loading && !this.state.init}
                                        onClick={() => { this.exportChoiceForm(); }}
                                        disabled={(
                                            (this.state.init ? true : false) ||
                                            (this.state.loading ? true : false) ||
                                            (!this.state.exportFormat ? true : false) ||
                                            ((!this.state.selectedRowKeys || this.state.selectedRowKeys.length < 1) ? true : false)
                                        ) ? true : false}
                                        >Exporteren</Button>
                                    <div className="mls mrs" style={{ display: 'inline' }}>{ this.state.selectedRowKeys.length } / { this.props.choice_form_addresses.length } adressen geselecteerd</div>
                                </Col>
                              </Row>

                              <Row className="mbm">
                                  <Col span={24} >
                                      <CustomTable
                                        rowKey={(record) => {

                                          if(record.children)
                                          {
                                            record.children = [];
                                          }

                                          return record.id;
                                        }}
                                        size="middle"
                                        indentSize="0"
                                        rowSelection={rowSelectionChoiceResults}
                                        columns={choice_form_address_columns}
                                        dataSource={this.state.loading ? [] : this.props.choice_form_addresses}
                                        expandedRowRender={undefined}
                                        expandIcon={() => { return null; }}
                                        loading={this.state.loading}
                                        onChange={this.handleTableChange}
                                        />
                                  </Col>
                              </Row>

                          </div> : null }
                      </div>
                  </div>

                  { /* EXPORT: support base */ }
                  <div style={{ display: (this.state.modalExportSupportBase ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >

                              <h2>Draagvlak</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert op de achtergrond een bestand met resultaten van de geselecteerde adressen. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              {/*<Form.Item required label="Type export">
                                  <Select
                                    value={this.state.exportType}
                                    onChange={(type) => {
                                        this.setState({ exportType: type, selectedRowKeys: [] });
                                    }}
                                    >
                                    <Select.Option key='form'>Formulier (Excel)</Select.Option>
                                    <Select.Option key='results_zip'>Resultaten (Zip)</Select.Option>
                                    <Select.Option key='results_excel'>Resultaten (Excel)</Select.Option>
                                  </Select>
                              </Form.Item>*/}

                              <Form.Item required label="Type export">
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportType: e.target.value, selectedRowKeys: [] });
                                    }}
                                    value={this.state.exportType}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'form'}>Formulier (Excel)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'results_zip'}>Resultaten (Zip)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'results_excel'}>Resultaten (Excel)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              <div style={{display: this.state.exportType == 'form' ? 'block' : 'none'}}>
                                  {/*<Form.Item required label="E-mailadres(sen)">
                                      <Select
                                        mode="tags"
                                        open={false}
                                        tokenSeparators={[',', '\n']}
                                        style={{ width: '100%' }}
                                        defaultValue={this.state.emailTo}
                                        onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                        onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                        onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                        ></Select>
                                        <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                                  </Form.Item>*/}

                                  <Row className="mbm">
                                    <Col span={12}>
                                        <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportSupportBaseForm(); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length == 1 ? false : true}>Exporteren</Button>
                                    </Col>
                                    <Col span={12}>
                                      <Search
                                        placeholder="Zoeken..."
                                        allowClear
                                        onSearch={(value) => { this.searchSupportBaseForms(value); }}
                                        style={{ width: 200, float: 'right' }}
                                        />
                                    </Col>
                                  </Row>
                                  <Row className="mbm">
                                      <Col span={24} >
                                          <CustomTable
                                            rowKey='id'
                                            size="middle"
                                            rowSelection={rowSelectionSupportBaseForms}
                                            columns={support_base_columns}
                                            dataSource={this.state.loading ? [] : this.props.support_base_forms}
                                            loading={this.state.loading}
                                            />
                                      </Col>
                                  </Row>
                              </div>

                              <div style={{display: this.state.exportType == 'results_zip' ? 'block' : 'none'}}>

                                  {/*<Form.Item required label="E-mailadres(sen)">
                                      <Select
                                        mode="tags"
                                        open={false}
                                        tokenSeparators={[',', '\n']}
                                        style={{ width: '100%' }}
                                        defaultValue={this.state.emailTo}
                                        onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                        onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                        onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                        ></Select>
                                        <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                                  </Form.Item>*/}

                                  <Row className="mbm">
                                    <Col span={12}>
                                        <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportSupportBaseFormResults(this.state.exportType); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length > 0 ? false : true}>Exporteren</Button>
                                    </Col>
                                  </Row>
                                  <Row className="mbm">
                                      <Col span={24} >
                                          <CustomTable
                                            rowKey='id'
                                            size="middle"
                                            rowSelection={rowSelectionSupportBaseResults}
                                            columns={support_base_results_columns}
                                            dataSource={this.state.loading ? [] : this.props.support_base_form_results}
                                            loading={this.state.loading}
                                            onChange={this.handleTableChange}
                                            />
                                      </Col>
                                  </Row>

                              </div>

                              <div style={{display: this.state.exportType == 'results_excel' ? 'block' : 'none'}}>

                                  {/*<Form.Item required label="E-mailadres(sen)">
                                      <Select
                                        mode="tags"
                                        open={false}
                                        tokenSeparators={[',', '\n']}
                                        style={{ width: '100%' }}
                                        defaultValue={this.state.emailTo}
                                        onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                        onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                        onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                        ></Select>
                                        <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                                  </Form.Item>*/}

                                  <Row className="mbm">
                                      <Col span={12}>
                                          <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportSupportBaseForm('results'); }} disabled={this.state.init ? true : false || this.state.loading ? true : false || this.state.selectedRowKeys.length == 1 ? false : true}>Exporteren</Button>
                                      </Col>
                                      <Col span={12}>
                                        <Search
                                            placeholder="Zoeken..."
                                            allowClear
                                            onSearch={(value) => { this.searchSupportBaseForms(value); }}
                                            style={{ width: 200, float: 'right' }}
                                            />
                                      </Col>
                                  </Row>
                                  <Row className="mbm">
                                      <Col span={24} >
                                          <CustomTable
                                            rowKey='id'
                                            size="middle"
                                            rowSelection={rowSelectionSupportBaseForms}
                                            columns={support_base_columns}
                                            dataSource={this.state.loading ? [] : this.props.support_base_forms}
                                            loading={this.state.loading}
                                            />
                                      </Col>
                                  </Row>

                              </div>

                          </Col>
                      </Row>
                  </div>

                  { /* EXPORT: fuse box list */ }
                  <div style={{ display: (this.state.modalExportFuseBoxList ? 'block' : 'none') }}>
                      <Row className="mbm">
                          <Col span={24} >
                              <h2>Meterkastlijst</h2>

                              <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert op de achtergrond een bestand met resultaten van de geselecteerde adressen. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                              <Form.Item required label="Type export">
                                  <Radio.Group
                                    onChange={(e) => {
                                      this.setState({ exportType: e.target.value, selectedRowKeys: [] });
                                    }}
                                    value={this.state.exportType}
                                    >
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'normal'}>Uitgebreid (Zip)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'compact'}>Compact zonder voorblad (Zip)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'compact-with-front'}>Compact met voorblad (Zip)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              <Form.Item required label="Selecteer één of meerdere onderdelen">
                                  <Checkbox.Group
                                      style={{ width: '100%', lineHeight: '30px' }}
                                      value={this.state.selectedFuseBoxListOptions}
                                      onChange={(values) => { this.onChangeFuseBoxListChecked(values); }}
                                      >
                                      {_.findWhere(this.props.project.modules, {id: 19}) && (this.props.HaveAbility('form', 'read') || this.props.project.user_role === 'admin') ? <Row>
                                        <Col span={8}>
                                          <Checkbox value="form">Formulieren</Checkbox>
                                        </Col>
                                      </Row> : null}
                                      {_.findWhere(this.props.project.modules, {id: 16}) && (this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') ? <Row>
                                        <Col span={8}>
                                          <Checkbox value="choice">Keuze</Checkbox>
                                        </Col>
                                      </Row> : null}
                                  </Checkbox.Group>
                              </Form.Item>

                              { this.state.selectedFuseBoxListOptions && this.state.selectedFuseBoxListOptions.includes('form') ?
                              <Form.Item required label="Selecteer één of meerdere formulieren">
                                  <Select
                                    mode="multiple"
                                    onChange={(values) => { this.setState({ forms: values }); }}
                                    filterOption={(inputValue, option) =>
                                      option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                  >
                                    { this.props.forms.map((element) => {
                                    return <Select.Option key={element.id}>{element.name}</Select.Option>
                                    } ) }
                                  </Select>
                              </Form.Item>
                              : null }

                              { this.state.selectedFuseBoxListOptions && this.state.selectedFuseBoxListOptions.includes('choice') ?
                              <Form.Item required label="Selecteer één of meerdere keuze formulieren">
                                  <Select
                                    mode="multiple"
                                    onChange={(values) => { this.setState({ choices: values }); }}
                                    filterOption={(inputValue, option) =>
                                      option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                  >
                                    { this.props.choice_forms.map((element) => {
                                    return <Select.Option key={element.id}>{element.description}</Select.Option>
                                    } ) }
                                  </Select>
                              </Form.Item>
                              : null }

                              {/*<Form.Item required label="Type export">
                                  <Select
                                    value={this.state.exportType}
                                    onChange={(type) => {
                                        this.setState({ exportType: type });
                                    }}
                                    >
                                    <Select.Option key='normal'>Uitgebreid (Zip)</Select.Option>
                                    <Select.Option key='compact'>Compact (Zip)</Select.Option>
                                  </Select>
                              </Form.Item>*/}

                              {/*<Form.Item required label="E-mailadres(sen)">
                                  <Select
                                    mode="tags"
                                    open={false}
                                    tokenSeparators={[',', '\n']}
                                    style={{ width: '100%' }}
                                    defaultValue={this.state.emailTo}
                                    onChange={(value) => { this.setState({ emailTo: value.join(',') }, () => { if(!this.validateEmail(this.state.emailTo)){} }); }}
                                    onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                    onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                                    ></Select>
                                    <div style={{color: 'red', display: (this.state.is_valid_email ? 'none' : 'block')}}>Geen een geldig e-mailadres op</div>
                              </Form.Item>*/}
                          </Col>
                      </Row>
                      <Row className="mbm">
                        <Col span={12}>
                                <Button type="new" loading={this.state.loading && !this.state.init} onClick={() => { this.exportFuseBoxList(); }} disabled={this.state.init ? true : false
                                ||
                                    this.state.loading ? true : false
                                ||
                                    (
                                        (this.state.selectedFuseBoxListOptions && this.state.selectedFuseBoxListOptions.length > 0)
                                        && this.state.exportType
                                        && (this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0)
                                        && ((this.state.selectedFuseBoxListOptions.includes('form') && this.state.forms.length > 0) || !this.state.selectedFuseBoxListOptions.includes('form'))
                                        && ((this.state.selectedFuseBoxListOptions.includes('choice') && this.state.choices.length > 0) || !this.state.selectedFuseBoxListOptions.includes('choice'))
                                        && (this.state.selectedFuseBoxListOptions.includes('form') || this.state.selectedFuseBoxListOptions.includes('choice'))
                                    ) ? false : true
                                }>Exporteren</Button>
                                <div className="mls mrs" style={{ display: 'inline' }}>{ this.state.selectedRowKeys.length } / { this.props.addresses.length } adressen geselecteerd</div>
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Zoeken..."
                            allowClear
                            onSearch={(value) => { this.searchAddresses(value); }}
                            style={{ width: 200, float: 'right' }}
                            />
                        </Col>
                      </Row>
                      <Row className="mbm">
                          <Col span={24} >
                              <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                columns={fuse_box_list_columns}
                                dataSource={this.state.loading ? [] : this.props.addresses}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                                />
                          </Col>
                      </Row>
                  </div>
                  {/**/}

                </Form>

              </div>
          </div>
      );
    }
  }

const ExportForm = Form.create({})(Export);

const mapStateToProps = (state, ownProps) => {
  return {
    project: state.project.project,
    selectedCustomer: state.auth.selectedCustomer,
    addresses: state.address.addresses,
    groups: state.group.groups,
    surveys : state.survey.surveys,
    survey_addresses : state.survey.addresses,
    appointments: state.appointment.appointments,
    forms : state.forms.forms,
    forms_results : state.forms.forms_results,
    form : state.forms.form,
    delivery_forms : state.deliver.forms,
    delivery_points : state.deliver.points,
    delivery_users : state.deliver.users,
    choice_form_addresses : state.choice.addresses,
    choice_forms : state.choice.forms,
    schedule_addresses : state.schedule.addresses,
    support_base_forms : state.support_base.forms,
    support_base_form_results : state.support_base.addresses,
    auth: state.auth.user,

    // delivery
    delivery_filters : state.deliver.delivery_filters,
    form_filters : state.deliver.form_filters,
    location_filters : state.deliver.location_filters,
    user_filters : state.deliver.user_filters
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getProjectAction : bindActionCreators(getProjectAction, dispatch),

    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),

    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),

    requestExportAddressesAction : bindActionCreators(requestExportAddressesAction, dispatch),

    requestDownloadDossier : bindActionCreators(requestDownloadDossier, dispatch),

    getSurveysAction : bindActionCreators(getSurveysAction, dispatch),
    searchSurveysAction : bindActionCreators(searchSurveysAction, dispatch),
    exportSurveyAction : bindActionCreators(exportSurveyAction, dispatch),
    exportSurveyFormAction : bindActionCreators(exportSurveyFormAction, dispatch),
    requestExportSurveyFormAction : bindActionCreators(requestExportSurveyFormAction, dispatch),
    getSurveyAddressesAction : bindActionCreators(getSurveyAddressesAction, dispatch),

    getAppointmentsAction : bindActionCreators(getAppointmentsAction, dispatch),
    requestExportAppointmentAction : bindActionCreators(requestExportAppointmentAction, dispatch),

    exportScheduleAction : bindActionCreators(exportScheduleAction, dispatch),

    getDeliveryFormsAction : bindActionCreators(getDeliveryFormsAction, dispatch),
    viewDeliveryFormPDFAction : bindActionCreators(viewDeliveryFormPDFAction, dispatch),
    getPointsAction : bindActionCreators(getPointsAction, dispatch),
    searchPointsAction : bindActionCreators(searchPointsAction, dispatch),
    exportPointsAction : bindActionCreators(exportPointsAction, dispatch),
    getUsersAction : bindActionCreators(getUsersAction, dispatch),
    // filter(s)
    getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
    getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
    getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
    getUserFilterAction : bindActionCreators(getUserFilterAction, dispatch),

    getChoiceFormsByProjectAction : bindActionCreators(getChoiceFormsByProjectAction, dispatch),
    getChoiceStatusAction : bindActionCreators(getChoiceStatusAction, dispatch),
    downloadDocumentsAction : bindActionCreators(downloadDocumentsAction, dispatch),
    downloadExcelAction : bindActionCreators(downloadExcelAction, dispatch),
    downloadStatusAction : bindActionCreators(downloadStatusAction, dispatch),
    requestExportChoiceStatusAction : bindActionCreators(requestExportChoiceStatusAction, dispatch),
    requestDownloadExcelAction : bindActionCreators(requestDownloadExcelAction, dispatch),
    getChoiceFormStatusAction : bindActionCreators(getChoiceFormStatusAction, dispatch),

    getScheduleAction : bindActionCreators(getScheduleAction, dispatch),
    getScheduledAddressesAction : bindActionCreators(getScheduledAddressesAction, dispatch),
    searchScheduledAddressesAction : bindActionCreators(searchScheduledAddressesAction, dispatch),

    requestExportScheduleAction : bindActionCreators(requestExportScheduleAction, dispatch),

    getSupportBaseFormsByProjectAction : bindActionCreators(getSupportBaseFormsByProjectAction, dispatch),
    searchSupportBaseFormsByProjectAction : bindActionCreators(searchSupportBaseFormsByProjectAction, dispatch),
    downloadSupportBaseFormExcelAction : bindActionCreators(downloadSupportBaseFormExcelAction, dispatch),
    getSupportBaseStatusAction : bindActionCreators(getSupportBaseStatusAction, dispatch),
    downloadSignedDocumentsAction : bindActionCreators(downloadSignedDocumentsAction, dispatch),
    downloadSupportBaseFormExcelResultsAction : bindActionCreators(downloadSupportBaseFormExcelResultsAction, dispatch),

    requestDownloadFuseBoxLists : bindActionCreators(requestDownloadFuseBoxLists, dispatch),

    getFormsAction : bindActionCreators(getFormsAction, dispatch),
    getFormAction : bindActionCreators(getFormAction, dispatch),
    getFormsResultsAction : bindActionCreators(getFormsResultsAction, dispatch),
    searchInFormAction : bindActionCreators(searchInFormAction, dispatch),
    searchInFormsAction : bindActionCreators(searchInFormsAction, dispatch),
    exportFormAction : bindActionCreators(exportFormAction, dispatch),
    exportFormsAction : bindActionCreators(exportFormsAction, dispatch),

    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportForm);
// export default connect(mapStateToProps, mapDispatchToProps)(Export);
