import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Card, Input, Checkbox, Popconfirm, Select, Collapse, Row, Col } from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { Editor } from '@tinymce/tinymce-react';

class SBAgreeInputForm extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
          <Form.Item style={{marginBottom: 0}}>
            {getFieldDecorator('requiredField', {
              initialValue: true,
              valuePropName: 'checked'
            })(
              <Checkbox disabled>Dit veld is verplicht</Checkbox>
            )}
          </Form.Item>
      </Form>
    );
  }
}

const WrappedSBAgreeInputForm = Form.create({ name: 'agree_input_form' })(SBAgreeInputForm);

class SBAgreeInput extends React.Component {

  constructor(props) {
    super(props);
    // console.log(this.props.parseData);
    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {post: true, digital: true, requiredField: true},
      openKeys: [],
      agree: this.props.parseData && this.props.parseData.agree ? this.props.parseData.agree : '',
      disagree: this.props.parseData && this.props.parseData.disagree ? this.props.parseData.disagree : '',
      post: ((this.props.parseData && this.props.parseData.post !== false) || !this.props.parseData) ? true : false,
      digital: ((this.props.parseData && this.props.parseData.digital !== false) || !this.props.parseData) ? true : false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  // removeElement = () => {
  //   if(this.props.removeElement) {
  //     this.props.removeElement();
  //   }
  // }

  saveFieldValues = () => {
    if(this.state.modus == 'edit') {
      //console.log(this.state.wysiwyg);
      var values = {agree: this.state.agree, disagree: this.state.disagree, post: this.state.post, digital: this.state.digital, requiredField: true};
      // this.form.props.form.validateFields((error, values) => {
      //   if(!error) {
          this.setState({formData: values, openKeys: []});
          this.props.onSaveData(this.props.id, values);
      //   }
      // })
    }
  }

  saveField = () => {
      var values = this.state.formData;
      values.post = this.state.post;
      values.digital = this.state.digital;

      this.setState({formData: values}); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  renderExtraButtons = () => {
    return (
    <div onClick={(event) => event.stopPropagation()} style={{ display: 'flex' }}>
      <Checkbox defaultChecked={this.state.post} onChange={(e) => { e.stopPropagation(); this.setState({post: e.target.checked}, function(){ this.saveField(); }); }}>Post</Checkbox>
      <Checkbox defaultChecked={this.state.digital} onChange={(e) => { e.stopPropagation(); this.setState({digital: e.target.checked}, function(){ this.saveField(); }); }}>Digitaal</Checkbox>
      <Button.Group>
        {/*<Popconfirm
          icon={(<Icon type="question-circle" />)}
          placement="top"
          onCancel={(event) => event.stopPropagation()}
          size="small"
          okType="danger"
          title={"Element verwijderen"}
          onConfirm={() => this.removeElement()}
          okText="Ja"
          cancelText="Nee"
           disabled={this.props.disabled}
        >
          <Icon
            type="delete"
            onClick={event => {
              event.stopPropagation();
            }}
          />
        </Popconfirm>*/}
        {/*<Icon
          style={{marginLeft: 5}}
          type="plus-square"
          onClick={(event) => {
            event.stopPropagation();
            this.duplicateElement(this.props.id);
          }}
        />*/}
      </Button.Group>
    </div>
    );
  };

  onChangeCollapse = (panel) => {
    if(panel) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.setState({modus: 'show', openKeys: []});
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Akkoord</b></div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              {/*<WrappedSBAgreeInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />*/}

              <span>Akkoord tekst:</span>
              <CustomEditor title="Akkoord" label="Akkoord" value={this.state.agree} onEditorChange={(text) => this.setState({agree: text})} />

              <br />

              <span>Niet akkoord tekst:</span>
              <CustomEditor value={this.state.disagree} onEditorChange={(text) => this.setState({disagree: text})} />

              <WrappedSBAgreeInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />

              <Button style={{ marginTop: 15 }} onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Titel
        </div>
      );
    }
  }
}

export default SBAgreeInput;
