import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, notification, Divider } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addPartnerLibraryAction, getPartnerLibraryAction, resetPartnerAction, patchPartnerLibraryAction } from '../../../actions/partnersActions';

// // import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';

import { showSuccess, showError } from '../../../utils/Notifications';

import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
import PatchPartner from '../Forms/PatchPartner';

class PartnerDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false,
      selected: [],
      text: '',
      tags: [],
    };
  }

  componentDidMount() {
    this.props.resetPartnerAction();
    if(this.props.match.params.partnerid) {
      this.props.getPartnerLibraryAction(this.props.match.params.partnerid);
    }
  }

  handleSubmit = (e, values) => {

    this.partnerForm.props.form.validateFields((err, values) => {
      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      if(this.props.match.params.partnerid) {
        this.props.patchPartnerLibraryAction(this.props.match.params.partnerid, values).then(() => this.props.history.push(`/library/partners/`));
      } else {
        this.props.addPartnerLibraryAction(values).then(() => this.props.history.push(`/library/partners/`));
      }

      showSuccess();
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Partners', 'link': '/library/partners'}, {'label': this.props.partner.name ? this.props.partner.name : 'Toevoegen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='2' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                    <Link to={`/library/partners`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Divider></Divider>
            <Row>
              <Col span={24}>
                  <PatchPartner wrappedComponentRef={formRef => { this.partnerForm = formRef }} {...this.props}/>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

const PartnerDetailForm = Form.create({ name: 'horizontal_login' })(PartnerDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.library.isFetching,
      isError : state.library.isError,
      partner: state.partner.partner,
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    addPartnerLibraryAction : bindActionCreators(addPartnerLibraryAction, dispatch),
    getPartnerLibraryAction : bindActionCreators(getPartnerLibraryAction, dispatch),
    resetPartnerAction : bindActionCreators(resetPartnerAction, dispatch),
    patchPartnerLibraryAction : bindActionCreators(patchPartnerLibraryAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetailForm);
