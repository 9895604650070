import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Modal,
    Input,
    Radio,
    DatePicker,
    Select,
    Row,
    Col,
    Table,
    Card,
    List,
    Tag,
} from 'antd';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import 'moment/locale/nl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAppointmentScheduleAction } from '../../../../../actions/appointmentsActions';


const { CheckableTag } = Tag;

class MyTag extends Component {

    state = { checked: false };

    handleChange = checked => {
        this.setState({ checked });
    };

    render() {
        return (
            <CheckableTag {...this.props} checked={this.state.checked} onChange={this.handleChange} />
        );
    }
}


let id = 0;

class DataForm extends Component {

    state = {
        clusters: [],
        available_times: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        },
        first_date: false
    }

    componentDidMount() {

        if(this.props.match.params.appointmentid)
        {
            this.props.getAppointmentScheduleAction(this.props.match.params.appointmentid).then(() => {

                this.setState({
                    first_date: this.props.first_date
                });

                this.setState({
                    available_times: this.props.available_times
                });

                this.setState({
                    clusters: this.props.clusters
                });
            });
        }
        // else
        // {
        //     this.setState({
        //         first_date: false
        //     });
        //
        //     this.setState({
        //         available_times: []
        //     });
        //
        //     this.setState({
        //         clusters: []
        //     });
        // }
    }

    onDatePickerChange(date, dateString) {
        // (re)load tags
        var day_of_week = date.day();
    }

    onSelectableTagChange = checked => {
        this.setState({ checked });
    }

    getDayAvailableTimes(date) {

        var $day = moment(date).day();

        if($day == 1){ return this.state.available_times.monday; }
        if($day == 2){ return this.state.available_times.tuesday; }
        if($day == 3){ return this.state.available_times.wednesday; }
        if($day == 4){ return this.state.available_times.thursday; }
        if($day == 5){ return this.state.available_times.friday; }
        if($day == 6){ return this.state.available_times.saturday; }
        if($day == 7){ return this.state.available_times.sunday; }

        return [];
    }

    render() {

        const { getFieldDecorator, getFieldValue } = this.props.form;

        const { clusters, available_times, first_date } = this.state;

        return (
            <Form onSubmit={this.props.onSubmit}>

                <Row gutter={24}>
                    <Col span={12}>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Card>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.props.clusters}
                                        renderItem={ cluster => (
                                          <List.Item>
                                              <div>
                                                  {cluster.name}<br />

                                                  <DatePicker
                                                    onChange={this.onDatePickerChange}
                                                    defaultValue={moment(this.props.first_date)}
                                                    />
                                                  {
                                                      this.getDayAvailableTimes(this.props.first_date).map((time, index) => {
                                                      return <MyTag key={(record) => 'clusters['+cluster.id+']['+time+']'}>{time}</MyTag>
                                                  } ) }
                                              </div>
                                          </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Form>
        );
    }
}

const DataScreen = Form.create({ name: 'data_form' })(DataForm);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.appointment.isFetching,
        isError : state.appointment.isError,
        first_date : state.appointment.first_date,
        clusters : state.appointment.clusters,
        available_times : state.appointment.available_times
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DataScreen);
