export default (state = {isError: false, isFetching: false, letterpapers: [], letterpaper:{}}, action) => {
    switch (action.type) {
    case 'LETTERPAPERS_ACTION_FETCH':
    return {
        ...state,
        letterpapers: [],
        isFetching: true,
        isError: false,
    }
    case 'LETTERPAPER_ACTION_FETCH':
    return {
        ...state,
        letterpaper: {},
        isFetching: true,
        isError: false,
    }
    case 'LETTERPAPERS_ACTION_SUCCESS':
    return {
        ...state,
        letterpapers: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LETTERPAPER_ACTION_SUCCESS':
    return {
        ...state,
        letterpaper: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_LETTERPAPERS_ACTION_SUCCESS':
    return {
        ...state,
        letterpapers: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'STORE_LETTERPAPER_ACTION_SUCCESS':
    return {
        ...state,
        letterpapers: [action.payload, ...state.letterpapers],
        letterpaper: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_CUSTOMER_ACTION_ERROR':
    return {
        ...state,
        letterpaper: {},
        isFetching: false,
        isError: false,
    }
    case 'DELETE_LETTERPAPERS_ACTION_SUCCESS':
    return {
        ...state,
        letterpapers: state.letterpapers.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'LETTERPAPERS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
