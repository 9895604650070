export default (state = {isError: false, isFetching: false, latest_correspondence: [], latest_correspondence_scheduled: [], latest_correspondence_send: [], stats: []}, action) => {
    switch (action.type) {
    case 'DASHBOARD_ACTION_FETCH':
    return {
        ...state,
        latest_correspondence: [],
        latest_correspondence_scheduled: [],
        latest_correspondence_send: [],
        stats: [],      
        isFetching: true,
        isError: false,
    }
    case 'DASHBOARD_ACTION_SUCCESS':
    return {
        ...state,
        latest_correspondence: action.payload.latest_correspondence,
        latest_correspondence_scheduled: action.payload.latest_correspondence_scheduled,
        latest_correspondence_send: action.payload.latest_correspondence_send,
        stats: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DASHBOARD_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
