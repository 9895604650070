import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
  Radio,
} from 'antd';

class AddQuestion extends Component {

  render() {
    const { getFieldDecorator } = this.props.form;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
        <Row gutter={24} >
          <Col span={24}>
            <Form.Item required hasFeedback label="Vraag">
              {getFieldDecorator('question', {
                initialValue: this.props.surveyquestion.question,
                rules: [{ required: true, message: 'Vul een vraag in' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item required label="Type">
              {getFieldDecorator('type', {
                initialValue: this.props.surveyquestion.type,
                rules: [{ required: true, message: 'Vul een type in' }],
              })(
                <Radio.Group>
                  <Radio style={radioStyle} value={'rating'}>
                    1 - 10 vraag
                  </Radio>
                  <Radio style={radioStyle} value={'open'}>
                    Open vraag
                  </Radio>
                  <Radio disabled style={radioStyle} value={'upload'}>
                    Upload <Tag>Binnenkort beschikbaar</Tag>
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'add_survey_form' })(AddQuestion);
