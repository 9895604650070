import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Upload,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLibraryCategoryAction, addLibraryCategoryAction, updateLibraryCategoryAction, resetLibraryCategoryAction } from '../../../actions/librariesActions';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
import moment from 'moment';
import InformationTextMenu from '../Menu/InformationTextMenu';

class InformationTextCategory extends Component {

  state = {
    selectedRowKeys: [],
  };

  constructor(props)
  {
    super(props);
  }

  componentDidMount()
  {
      this.props.resetLibraryCategoryAction();

      this.loadData();
  }

  loadData()
  {
      if(this.props.match.params.id && this.props.match.params.id != 'add')
      {
          this.props.getLibraryCategoryAction(this.props.match.params.id);
      }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {

      if(!err){

        if(this.props.match.params.id != 'add') {
          this.props.updateLibraryCategoryAction(this.props.match.params.id, values).then(() => {

            showSuccess();

            this.props.history.push(`/library/information-texts/categories`);
          });
        } else {
          this.props.addLibraryCategoryAction('information', values).then((data) => {

            showSuccess();

            this.props.history.push(`/library/information-texts/categories`);
          });
        }
      }
    });
  };

  render()
  {

    const { getFieldDecorator } = this.props.form;

    return (

        <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Informatie', 'link': '/library/information-texts'}, {'label': 'Categorieën', 'link': '/library/information-texts/categories'}, {'label': 'Categorie'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <LibrarySideMenu id={this.props.match.params.id} activeMenu='5' />

              <div style={{padding: 0, display: 'flex', width: '100%'}}>

                  <InformationTextMenu activeMenu='2' />

                  <div style={{padding: 24, width: '100%'}} colon={true}>

                    <Row className="mbm">
                        <Col span={12}>
                          <div>
                            <Button disabled={!this.props.HaveAbility('library', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                            <Link to={`/library/information-texts/categories`}>
                              <Button>Sluiten</Button>
                            </Link>
                          </div>
                        </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Form layout={'vertical'} colon={true} onSubmit={this.handleSubmit}>
                          <Row gutter={24}>
                            <Col span={24}>
                              <Form.Item required label="Naam" hasFeedback>
                                  {getFieldDecorator('name', {
                                    initialValue: this.props.category && this.props.category.name,
                                    rules: [{ required: true, message: 'Vul een naam in' }],
                                  })(
                                    <Input />
                                  )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>

                </div>

              </div>

          </div>

        </div>);
  }
}

const InformationTextCategoryForm = Form.create({ name: 'horizontal_login' })(InformationTextCategory);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.library.isFetching,
    isError : state.library.isError,
    category : state.library.category,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLibraryCategoryAction : bindActionCreators(getLibraryCategoryAction, dispatch),
    addLibraryCategoryAction : bindActionCreators(addLibraryCategoryAction, dispatch),
    updateLibraryCategoryAction : bindActionCreators(updateLibraryCategoryAction, dispatch),
    resetLibraryCategoryAction : bindActionCreators(resetLibraryCategoryAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationTextCategoryForm);
