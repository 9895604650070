import React, { Component } from 'react'

import {
    ControlOutlined,
    EuroOutlined,
    ExperimentOutlined,
    HomeOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class ProjectSidemenu extends Component {
    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 300}}
                defaultSelectedKeys={this.props.activeMenu}
            >
                    <Menu.Item key={"1"}>
                        <Link to={`/projects/${this.props.id}/general`}>
                            <HomeOutlined />
                            <span>Algemeen</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item disabled={!this.props.HaveAbility('project-personalisation', 'read')} key={"2"}>
                        <Link to={`/projects/${this.props.id}/personalize`}>
                            <ControlOutlined />
                            <span>Personalisatie</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item disabled={!this.props.HaveAbility('project-modules', 'read')} key={"3"}>
                        <Link to={`/projects/${this.props.id}/modules`}>
                            <ExperimentOutlined />
                            <span>Modules</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item disabled={!this.props.HaveAbility('project-users', 'read')} key={"4"}>
                        <Link to={`/projects/${this.props.id}/users`}>
                            <UserOutlined />
                            <span>Gebruikers</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item disabled={!this.props.HaveAbility('project-transactions', 'read')} key={"5"}>
                        <Link to={`/projects/${this.props.id}/transactions`}>
                            <EuroOutlined />
                            <span>Transacties</span>
                        </Link>
                    </Menu.Item>
                </Menu>
        );
    }
}
