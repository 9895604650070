import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Modal,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Table,
  Calendar,
  Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { scheduleAddressesAction, resetAppointmentAction, getAppointmentFreeSlotsAction, updateAppointmentScheduleAction, searchScheduleAddressesAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess } from '../../../../../utils/Notifications';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import PatchAppointmentForm from '../Form/PatchAppointment';
import ClusterFreeSlots from '../Component/ClusterFreeSlots';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'underscore';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class AppointmentOverview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedClusterId: null,
      selectedAddressId: null,
      dateModal: false,
      loading: false,
      free_slots: [],
    };
  }

  // saveClusters = () => {
  //   showSuccess();
  //   this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/agenda/`);
  // }

  componentDidMount() {
    // this.props.resetAppointmentAction();

    this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {
    });
  }

  openDateModal(clusterId, addressId) {
    this.props.getAppointmentFreeSlotsAction(clusterId).then(() => {
      this.setState({ free_slots: this.props.free_slots }, () => {
        this.setState({dateModal: true, selectedClusterId: clusterId, selectedAddressId: addressId});
      });
    });
  }

  updateAppointment() {
    this.setState({loading: true});
    this.clusterfreeslots.props.form.validateFields((error, values) => {

      // console.log(values);

      if(values.new_slot && typeof values.new_slot === 'object' && moment.isMoment(values.new_slot))
      {
          values.new_slot = values.new_slot.format('YYYY-MM-DD HH:mm');
      }

      if(values.new_slot && typeof values.new_slot === 'object' && dayjs.isDayjs(values.new_slot))
      {
          values.new_slot = values.new_slot.format('YYYY-MM-DD HH:mm');
      }

      if(!error) {
        this.props.updateAppointmentScheduleAction(values.slot_id, this.props.match.params.appointmentid, {...values, 'address_id': this.state.selectedAddressId}).then(() => {
          this.setState({selectedClusterId: null, selectedAddressId: null, dateModal: false});
          showSuccess('Succesvol opgeslagen');

          this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {
              this.setState({loading: false});
          });

          // this.setState({loading: false});
        });

      } else {
        this.setState({loading: false});
      }
    })
    //console.log(slotId, addressId)
    // this.props.updateAppointmentScheduleAction(slotId, {'address_id': addressId}).then(() => {
    //   this.setState({selectedClusterId: null, selectedAddressId: null, dateModal: false});
    //   showSuccess('Succesvol opgeslagen');
    // });
  }

  cancelAppointment(id)
  {
      //showConfirm = () => {
          Modal.confirm({
              title: 'Weet je zeker dat je dit item wilt annuleren?',
              content: 'Klik op JA om deze actie te bevestigen.',
              okText: 'Ja',
              okType: 'danger',
              cancelText: 'Nee',
              onOk: () => {
                  return new Promise((resolve, reject) => {
                      this.props.updateAppointmentScheduleAction(id, this.props.match.params.appointmentid, {'cancel': 1}).then(() => {
                        this.setState({selectedClusterId: null, selectedAddressId: null, dateModal: false});
                        showSuccess('Succesvol opgeslagen');
                        resolve();
                      });
                  }).catch(() => console.log('Oops errors!'));
              },
              onCancel() {},
          });
      //}
  }

  onSearch(value) {
    this.props.searchScheduleAddressesAction(this.props.match.params.appointmentid, value);
  }

  render() {

    const columns = [
      {
        title: 'Adres',
        dataIndex: 'full_address',
        key: 'name',
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster.name',
        key: 'cluster.name',
      },
      {
        title: 'Gepland',
        dataIndex: 'cluster_schedule.date',
        key: 'cluster_schedule',
        render: (index, record) => {
          return (
            record.cluster_schedule ?
              record.cluster_schedule.is_cancelled ? <span>Geannuleerd</span> :
              <span>{record.cluster_schedule && moment(record.cluster_schedule.date).format('DD/MM/YYYY')} - {record.cluster_schedule && record.cluster_schedule.time}</span>
              : null
          )
        },
        sorter: (a, b) => { return (a.cluster_schedule ? moment(a.cluster_schedule.date).unix() : 0) - (b.cluster_schedule ? moment(b.cluster_schedule.date).unix() : 0)},
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '',
        dataIndex: '',
        width: 260,
        key: 'cluster_schedule_action',
        render: (index, record) => {
          return (
            <span>
              <Button disabled={!this.props.HaveAbility('appointment', 'write')} onClick={() => record && record.cluster_schedule ? this.cancelAppointment(record.cluster_schedule.id) : ''} type='primary' danger style={{marginRight: 8}}>Annuleren</Button>
              <Button disabled={!this.props.HaveAbility('appointment', 'write')} onClick={() => record && record.cluster ? this.openDateModal(record.cluster.id, record.id) : ''} type='new'>Kies datum/tijd</Button>
            </span>
          )
        }
      },
    ];

    return (
      <div>
        <Modal
          title="Selecteer datum"
          visible={this.state.dateModal}
          destroyOnClose={true}
          footer={null}
          onOk={() => this.setState({ dateModal: false })}
          onCancel={() => this.setState({ dateModal: false })}
        >
          <ClusterFreeSlots wrappedComponentRef={formRef => { this.clusterfreeslots = formRef }} free_slots={this.state.free_slots} clusterId={this.state.selectedClusterId} addressId={this.state.selectedAddressId} onSelectSlot={(slotId, addressId) => this.updateAppointment(slotId, addressId)} />
          <Button loading={this.state.loading} onClick={() => this.updateAppointment()} type="new">Opslaan</Button>
        </Modal>

        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('appointment', 'write')} onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/agenda`)} type="new">Opslaan en volgende</Button>
              <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
            <AppointmentSteps current={3} {...this.props}/>
          </Card>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <Alert showIcon message="Let op: Vergeet niet bij stap 5 een correspondentie aan te maken om de bewoners op de hoogte te stellen van de afspraak." type="info" />
          </Col>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <Input.Search
              allowClear
              placeholder="Zoeken..."
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
              <Row gutter={24}>
                <Col span={24}>
                  <CustomTable
                    columns={columns}
                    dataSource={this.props.addresses}
                    loading={this.props.isFetching}
                  />
                </Col>
              </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    addresses: state.appointment.addresses,
    free_slots: state.appointment.free_slots,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleAddressesAction : bindActionCreators(scheduleAddressesAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    getAppointmentFreeSlotsAction : bindActionCreators(getAppointmentFreeSlotsAction, dispatch),
    updateAppointmentScheduleAction : bindActionCreators(updateAppointmentScheduleAction, dispatch),
    searchScheduleAddressesAction : bindActionCreators(searchScheduleAddressesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentOverview);
