import React, { Component } from 'react';
// import './Profile.css';

class Profile extends Component {
    render() {
        return (
            <div className="profile">

            </div>
        );
    }
}

export default Profile;
