import React, { Component } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressesAction, addAddressesAction, deleteAddressesAction, importAddressesAction, searchAddressesAction/*, exportAddressesAction*/, updateStatusAddressesAction, searchAddressesByZipcodeAction, importAddressesByZipcodeAction, resetAddressesZipcodeAction } from '../../../actions/addressesActions';
import { getGroupsAction } from '../../../actions/groupsActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Upload,
  message,
  Drawer,
  Transfer,
  Tag,
  InputNumber,
} from 'antd';
import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';
import { showSuccess, showExtraConfirm } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';

import { API_URL } from '../../../constants/settings'

const Search  = Input.Search;

const confirm = Modal.confirm;

class ProjectsAddressesZipcodeImport extends Component {
  state = {
    visible: false,
    //selectedRowKeys: [],
  };

  componentDidMount() {
    this.props.resetAddressesZipcodeAction();
  }


  searchAddressesByZipcode()
  {

    this.props.form.validateFields(['zipcode'], (err, values) =>
    {
      if(!err)
      {
        this.props.searchAddressesByZipcodeAction(this.props.match.params.id, values).then(() => {
          this.setState({ addressesbyzip: this.props.addressesbyzip });
        });
      }
    });
  }

  /**
  *
  */
  importAddressesByZipcode()
  {
    this.props.form.validateFields(['zipcode'], (err, values) =>
    {
        if(this.props.customer.license_nr_of_addresses > 0 && (this.props.customer.nr_of_addresses + this.state.selectedRowKeys.length) >= this.props.customer.license_nr_of_addresses)
        {
            showExtraConfirm((confirm) => {
                if(confirm)
                {
                  this.startImport(values.zipcode, this.state.selectedRowKeys);
                }
            }, 'Licentie', 'De limiet van het aantal adressen voor je licentie wordt hiermee overschreden. Extra kosten zullen dan in rekening worden gebracht. Weet je zeker dat je door wilt gaan?')
        }
        else
        {
            this.startImport(values.zipcode, this.state.selectedRowKeys);
        }

      // this.props.importAddressesByZipcodeAction(this.props.match.params.id, {zipcode: values.zipcode, row_nrs: this.state.selectedRowKeys}).then(() => {
      //   // this.setState({ address: this.props.address });
      //   // this.setState({ selectedRowKeysZipImport: [] });
      //   // this.setState({ addressesbyzip: [] });
      //   this.props.history.push(`/projects/${this.props.match.params.id}/addresses`);
      //   showSuccess('Succesvol toegevoegd');
      // });
    });
  }

  startImport(zipcode, selectedRowKeys)
  {
      this.props.importAddressesByZipcodeAction(this.props.match.params.id, {zipcode: zipcode, row_nrs: selectedRowKeys}).then(() => {
        // this.setState({ address: this.props.address });
        // this.setState({ selectedRowKeysZipImport: [] });
        // this.setState({ addressesbyzip: [] });
        this.props.history.push(`/projects/${this.props.match.params.id}/addresses`);
        showSuccess('Succesvol toegevoegd');
      });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  render() {
    const SubMenu = Menu.SubMenu;

    const { selectedRowKeys } = this.state;
    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };


    const columnsZipImport = [
      {
        title: 'Adres',
        dataIndex: 'address',
        render: (text, record) => (
          <span>
            {record.street + ' ' + record.house_number + (record.house_number_suffix ? ' ' + record.house_number_suffix : '')}
          </span>
        )
      },
      {
        title: 'Postcode',
        dataIndex: 'zipcode'
      },
      {
        title: 'Plaats',
        dataIndex: 'city'
      }
    ];

    return (
      <div>
        <Form layout={'vertical'}>
          <Row gutter={24}>
            <Col span={6}>
            <Form.Item required label="Postcode">
              {getFieldDecorator('zipcode', {
                rules: [{ required: true, message: 'Dit veld is verplicht' }, zipcodeValidation],
              })(
                <span>
                  <Search enterButton="Zoeken" placeholder="1234AB" maxLength={6} onSearch={(e) => this.searchAddressesByZipcode(e)} />
                </span>
              )}
            </Form.Item>
            </Col>
          </Row>

          <Row type="flex" className="mbm">
            <Col span={24}>
              <Button type="new" icon={<PlusOutlined />} onClick={() => this.importAddressesByZipcode()}>Toevoegen</Button>
              <Button style={{marginRight: 8}} onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/addresses`)}>Sluiten</Button>
            </Col>
          </Row>
          <CustomTable
            rowSelection={rowSelection}
            //onChange={onChangeZipImport}
            rowKey={(record) => { return record.id }} // record.street + ' ' + record.house_number + (record.house_number_suffix ? ' ' + record.house_number_suffix : '') }}
            columns={columnsZipImport}
            dataSource={this.props.addressesbyzip}
            loading={this.props.isFetchingZip}
            pagination={false}
            />
        </Form>
      </div>
    );
  }
}

const ProjectsAddressesZipcodeImportForm = Form.create({ name: 'customer_personalize' })(ProjectsAddressesZipcodeImport);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.address.isFetching,
    isFetchingZip: state.address.isFetchingZip,
    isError: state.address.isError,
    isErrorZip: state.address.isErrorZip,
    address: state.address.addresses,
    groups: state.group.groups,
    addressesbyzip: state.address.addressesbyzip,
    customer: state.customer.customer
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    addAddressesAction : bindActionCreators(addAddressesAction, dispatch),
    importAddressesAction : bindActionCreators(importAddressesAction, dispatch),
    deleteAddressesAction : bindActionCreators(deleteAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
    importAddressesByZipcodeAction : bindActionCreators(importAddressesByZipcodeAction, dispatch),
    updateStatusAddressesAction : bindActionCreators(updateStatusAddressesAction, dispatch),
    searchAddressesByZipcodeAction : bindActionCreators(searchAddressesByZipcodeAction, dispatch),
    resetAddressesZipcodeAction : bindActionCreators(resetAddressesZipcodeAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesZipcodeImportForm);
