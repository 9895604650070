import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { getIntakesByProjectAction, deleteIntakesByProjectAction, searchIntakesByProjectAction, duplicateIntakesByProjectAction, exportIntakeQuestionsAction } from '../../../actions/intakeActions';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import CustomTable from '../../../components/CustomTable/CustomTable';
import CloneIntakeForm from './Modals/CloneIntakeForm';

import { DownOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Upload,
  Tag,
  Button,
  Drawer,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
  Search,
} from 'antd';

import moment from 'moment';

class ProjectIntake extends Component {
  state = {
    drawerVisible: false,
    selectedRowKeys: [],
    can_write: false,
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('intake' ,'write')
      });

    // this.props.getIntakesByProjectAction(this.props.match.params.id).then(() => {
    //     this.setState({intake_forms: this.props.intake_forms});
    // });

    this.loadIntakes();
  }

  loadIntakes()
  {
    this.props.getIntakesByProjectAction(this.props.match.params.id).then(() => {
        this.setState({intake_forms: this.props.intake_forms});
    });
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  cloneSelected = () => {
    this.props.duplicateIntakesByProjectAction(this.props.match.params.id, this.state.selectedRowKeys[0]).then(() => {
      showSuccess('Succesvol gedupliceerd');
      this.setState({selectedRowKeys: []});
      this.loadIntakes();
    });
  }

  exportQuestionsSelected = () => {
    this.props.exportIntakeQuestionsAction(this.props.match.params.id, this.state.selectedRowKeys[0]).then(() => {
      this.setState({selectedRowKeys: []});
    });
  }

  showConfirm = () => {
      Modal.confirm({
          title: 'Weet je zeker dat je deze items wilt verwijderen? Hiermee kunnen de ingevulde formulieren niet meer bekeken worden.',
          content: 'Klik op JA om deze actie te bevestigen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                this.props.deleteIntakesByProjectAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
                    resolve();
                    this.setState({selectedRowKeys: []})
                    showSuccess('Succesvol verwijderd');
                    this.loadIntakes();
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  onSearch(value) {
      this.props.searchIntakesByProjectAction(this.props.match.params.id, {'search': value});
  }

  render() {

    const columns = [{
        title: 'Naam',
        dataIndex: 'name',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/intake/${record.id}`}>
              {text}
            </Link>
          </span>
        )
    },
    {
        title: 'Uit te voeren',
        dataIndex: 'nr_of_forms',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Voltooid',
        dataIndex: 'nr_of_completed_forms',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aanmaakdatum',
        dataIndex: 'created_at',
        render: (text, record) => (
          <span>
            { moment(text).format('DD/MM/YYYY') }
          </span>
        )
    },
    {
        title: 'Laatst bijgewerkt',
        dataIndex: 'updated_at',
        render: (text, record) => (
          <span>
            { moment(text).format('DD/MM/YYYY - HH:mm') }
          </span>
        )
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>

                <Dropdown disabled={!this.state.can_write} trigger={['click']} overlay={(
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={`/projects/${this.props.match.params.id}/intake/add`}>
                          Nieuw
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                          <CloneIntakeForm disabled={this.state.selectedRowKeys.length > 1} onClone={() => this.loadIntakes()} {...this.props} plain />
                      </Menu.Item>
                    </Menu>
                  )}>
                  <Button type="new">
                    Toevoegen <DownOutlined />
                  </Button>
              </Dropdown>


              <Dropdown trigger={['click']} disabled={(!this.state.can_write || (this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true)) } overlay={(
                  <Menu>
                    <Menu.Item disabled={this.state.selectedRowKeys.length != 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                    {/*<Menu.Divider></Menu.Divider>
                    <Menu.Item disabled={this.state.selectedRowKeys.length != 1} onClick={() => this.exportQuestionsSelected()} key="7">Vragen exporteren</Menu.Item>*/}
                    <Menu.Divider></Menu.Divider>
                    <Menu.Item disabled={!this.props.HaveAbility('intake' ,'delete')} onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary">
                  Acties <DownOutlined />
                </Button>
              </Dropdown>

          </Col>
          <Col span={12}>
              <Input.Search
                allowClear
                placeholder="Zoeken..."
                onSearch={ value => this.props.searchIntakesByProjectAction(this.props.match.params.id, value) }
                style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.intake_forms}
              loading={this.props.isFetching}
            />
          </Col>
        </Row>
      </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.intake.isFetching,
      isError : state.intake.isError,
      intake_forms : state.intake.intakes,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getIntakesByProjectAction : bindActionCreators(getIntakesByProjectAction, dispatch),
      searchIntakesByProjectAction : bindActionCreators(searchIntakesByProjectAction, dispatch),
      deleteIntakesByProjectAction : bindActionCreators(deleteIntakesByProjectAction, dispatch),
      duplicateIntakesByProjectAction : bindActionCreators(duplicateIntakesByProjectAction, dispatch),
      exportIntakeQuestionsAction : bindActionCreators(exportIntakeQuestionsAction, dispatch),
      HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntake);
