import React, { Component, cloneElement } from 'react';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import './Documents.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HaveAbility, HaveRole } from '../../../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
// import  AddDocumentForm from './Form/AddDocument';
import { getDocumentsAction, removeDocumentsAction, searchDocumentsAction, updateDocumentsOrderAction, resetDocumentsAction } from '../../../../actions/documentsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Select,
  Upload,
  Switch,
  Tag,
  Button,
  Drawer,
  Confirm,
  message,
  Steps,
  Slider,
  Menu,
  Dropdown,
  Modal,
} from 'antd';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }

// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     props.moveRow(dragIndex, hoverIndex);
//
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );

class Documents extends Component {
  state = {
      drawerVisible: false,
      selectedRowKeys: [],
      documents: [],
  };

  componentDidMount() {
    this.props.resetDocumentsAction();

    this.props.getDocumentsAction(this.props.match.params.id).then(() => {
      this.setState({documents: this.props.documents})
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  // components = {
  //   body: {
  //     row: DragableBodyRow,
  //   },
  // };
  //
  // moveRow = (dragIndex, hoverIndex) => {
  //   const { documents } = this.state;
  //   const dragRow = documents[dragIndex];
  //
  //   this.setState(
  //     update(this.state, {
  //       documents: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.documents.map((v,k) => v.id);
  //
  //   this.props.updateDocumentsOrderAction(this.props.match.params.id, {'documents' : sort}).then(() => {});
  // };

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.addDocumentform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addDocumentAction(this.props.match.params.id, values);
  //       this.addDocumentform.props.form.resetFields();
  //       this.setState({drawerVisible: false});
  //     }
  //   });
  // }

  deleteSelected = () => {
      Modal.confirm({
        title: 'Weet je het zeker dat je de geselecteerde documenten wilt verwijderen?',
        content: '',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {
              // console.log(this.state.selectedRowKeys);
              this.props.removeDocumentsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
                  resolve();
                  // this.setState({documents: this.props.documents, selectedRowKeys: []})
                  this.props.getDocumentsAction(this.props.match.params.id).then(() => {
                    this.setState({documents: this.props.documents})
                  });
                  showSuccess('Succesvol verwijderd');
              });
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          //console.log('Cancel');
        },
      });
  }

  onSearch(value) {
      this.props.searchDocumentsAction(this.props.match.params.id, {'search': value}).then(() => {
          this.setState({documents: this.props.documents, selectedRowKeys: []})
      });
  }

  render() {
    const columns = [
        {
          key: "sort",
          width: 20
        },
        {
            title: 'Naam',
            dataIndex: 'name',
            sorter: (a, b) => { return a.name.localeCompare(b.name)},
            render: (text, record) => (
              <span>
                <Link to={`/projects/${record.project_id}/modules/document/${record.id}/detail`}>
                  {text}
                </Link>
              </span>
            )
        },
        {
            title: 'Adressen',
            dataIndex: 'nr_of_addresses',
            render: (text, record) => {

              return (
                <span>
                    {record.is_public ? 'Openbaar' : text}
                </span>
            )
          },
        },
        {
            title: 'Openbaar',
            dataIndex: 'is_public',
            render: (text, record) => (
              <span>
                <Tag color={text == 0 ? "blue" : "green" }>
                  {text == 0 ? "Nee" : "Ja" }
                </Tag>
              </span>
            ),
        },
        {
            title: 'Dossier',
            dataIndex: 'hide_for_address',
            render: (text, record) => (
              <span>
                <Tag color={text == 1 ? "blue" : "green" }>
                  {text == 1 ? "Nee" : "Ja" }
                </Tag>
              </span>
            ),
        }
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var documents = this.state.documents;

        const activeIndex = documents.findIndex((i) => i.id === active.id);
        const overIndex = documents.findIndex((i) => i.id === over.id);

        this.setState({ documents: arrayMove(documents, activeIndex, overIndex) }, () => {

          var sort = this.state.documents.map((v,k) => v.id);

          this.props.updateDocumentsOrderAction(this.props.match.params.id, {'documents' : sort}).then(() => {});
        });
      }
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
              <Link to={`/projects/${this.props.match.params.id}/modules/documents/add`}>
                <Button disabled={!this.props.HaveAbility('document' ,'write')} type="new" icon={<PlusOutlined />}>Toevoegen</Button>
              </Link>


              <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('document', 'write') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                  <Menu>
                    <Menu.Item disabled={!this.props.HaveAbility('document' ,'delete')} style={{ color: this.props.HaveAbility('document', 'delete') ? '#f5222d' : '' }} onClick={() =>this.deleteSelected()} key="5" >Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary">
                  Acties <DownOutlined />
                </Button>
              </Dropdown>
          </Col>
          <Col span={12}>
            <Input.Search
              allowClear
              placeholder="Zoeken..."
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
              { this.state.documents && this.state.documents.length ?
              <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                  items={this.state.documents.map((i) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <CustomTable
                    rowKey='id'
                    columns={columns}
                    dataSource={this.state.documents}
                    components={!this.props.isFetching ? {
                        body: {
                            row: DraggableRow
                        }
                    } : {}}
                    size="middle"
                    rowSelection={rowSelection}
                    loading={this.props.isFetching}
                    />
                </SortableContext>
              </DndContext>
              :
              <CustomTable
                rowKey='id'
                columns={columns}
                dataSource={this.state.documents}
                size="middle"
                rowSelection={rowSelection}
                loading={this.props.isFetching}
                /> }
          </Col>
        </Row>
      </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.document.isFetching,
      isError : state.document.isError,
      documents: state.document.documents,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getDocumentsAction : bindActionCreators(getDocumentsAction, dispatch),
      removeDocumentsAction : bindActionCreators(removeDocumentsAction, dispatch),
      searchDocumentsAction : bindActionCreators(searchDocumentsAction, dispatch),
      updateDocumentsOrderAction : bindActionCreators(updateDocumentsOrderAction, dispatch),
      resetDocumentsAction : bindActionCreators(resetDocumentsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
      HaveRole : bindActionCreators(HaveRole, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
