import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess, showError } from '../../../utils/Notifications'
import FormSideMenu from './FormSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Typography,
    Button,
    Divider,
    Input,
    Select,
    Switch,
    InputNumber,
    Tag,
    Menu,
    Dropdown,
    Modal,
    Upload,
    Alert,
    Checkbox,
    Timeline,
    Radio,
    Badge,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import { API_URL, JOB_URL } from '../../../constants/settings';
import { getFormsAction, getFormsStatusAction, getFormsByProjectAction, searchInFormsStatusAction, unlockFormEntryAction, exportFormAction } from '../../../actions/formActions';
import { getGroupsAction } from '../../../actions/groupsActions';
import { getNrOfNewQueuedItems } from '../../../actions/queueActions';
import 'moment/locale/nl';

const { Title, Text } = Typography;

const Option = Select.Option;

const Search = Input.Search;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

const sanitizeString = (str) =>
{
    if(typeof str === 'string')
    {
        str = str.replace(/[^a-z0-9]/gim, "");

        return str.trim();
    }

    return str;
}

const { confirm } = Modal;

class FormsStatus extends Component {

  state = {
    disabled: true,
    edit: true,
    selectedRowKeys: [],
    selectedRowKeysAddresses: [],
    selectedRowKeysForms: [],
    selectedId: null,
    modalVisible: false,
    can_write: false,

    modalUploadFormVisible: false,
    selected_address_id: false,
    isFetching: false,
    modalShowTransactionLog: false,
    modalForm: false,

    amount_direction: 'paid',
    amount: null,

    expandedRowKeys: [],
    currentDataSource: [],

    addresses: [],

    filters: {},
    expandedRowKeys: [],
    all_open: false,
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('form' ,'write'),
          isFetching: true,
          addresses: [],
      });

      this.props.getGroupsAction({ 'projectId': this.props.match.params.id });

      this.props.getFormsAction(this.props.match.params.id).then(() => {

          this.props.getFormsStatusAction(this.props.match.params.id).then(() => {

              this.setState({ addresses: this.props.addresses }, () => {

                  // expand all
                  var expandedRowKeys = [];

                  this.state.addresses.map((record) => {
                      expandedRowKeys.push('address-'+record.id);
                  });

                  this.setState({ expandedRowKeys: expandedRowKeys }, () => {

                      this.setState({ expandedRowKeys: [] });
                  });

                  this.setState({ isFetching: false });
              });
          });
      });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  onSelectAddressChange = (value, record) => {
    if(this.state.selectedRowKeysAddresses.includes(value))
    { // deselect
        this.setState({ selectedRowKeysAddresses: this.state.selectedRowKeysAddresses.filter((v) => v !== value)}, () => {
            var filteredRowKeysForms = this.state.selectedRowKeysAddresses;

            record.children.map((form, index) => {
                filteredRowKeysForms = filteredRowKeysForms.filter((v) => v !== value+'-form-'+form.id);
            });

            this.setState({ selectedRowKeysForms: filteredRowKeysForms});
        });
    }
    else
    { // select
      this.setState({ selectedRowKeysAddresses: [...this.state.selectedRowKeysAddresses, value]});

      var selected_form_ids = [];

      record.children.map((form, index) => {
          selected_form_ids.push(value+'-form-'+form.id);
      });

      this.setState({ selectedRowKeysForms: this.state.selectedRowKeysForms.concat(selected_form_ids)});
    }
  }

  onSelectFormChange = (value, record) => {
    if(this.state.selectedRowKeysForms.includes(value))
    { // deselect
      this.setState({ selectedRowKeysForms: this.state.selectedRowKeysForms.filter((v) => v !== value)});

      // deselect address (no longer all address forms are selected)
      this.setState({ selectedRowKeysAddresses: this.state.selectedRowKeysAddresses.filter((v) => v !== 'address-'+record.address_id)});
    }
    else
    { // select
      this.setState({ selectedRowKeysForms: [...this.state.selectedRowKeysForms, value]});

      // TODO: check if all address forms are selected (if so: select address)
    }
  }

  onCheckAllAddresses = (value) => {

    if(value.target.checked)
    {
      var child_ids = [];

      this.state.addresses.map((v) => {
        v.children && v.children.map((child) => {
          child_ids.push('address-'+child.address_id+'-form-'+child.id);
        })
      });

      var ids = [];

      this.state.addresses.map((record) => {

          if(record.children)
          {
              ids.push('address-'+record.id);
          }
          else if(record.form)
          {
              child_ids.push('address-'+record.form.address_id+'-form-'+record.form.id);
          }
          else
          {
              child_ids.push('address-'+record.address_id+'-form-'+record.id);
          }
      }); //(record.form ? 'address-'+record.form.address_id+'-form-'+record.form.id : 'address-'+record.id)); ///'address-'+v.form.address_id+'-form-'+v.form.id);

      this.setState({ selectedRowKeysAddresses: ids});
      this.setState({ selectedRowKeysForms: child_ids});

      // console.log([ids, child_ids]);
    }
    else
    {
      this.setState({ selectedRowKeysAddresses: []});
      this.setState({ selectedRowKeysForms: []});
    }
  }

  onSearch = (value) => {

    this.props.searchInFormsStatusAction(this.props.match.params.id, value).then(() => {

        this.setState({ addresses: this.props.addresses });
    });
  }

  getRowClassName(record, index)
  {
      var class_name = (record.children && record.children.length > 1 ? 'address-row' : 'form-row');

      // group(s)
      if(record.groups) // record.children && record.children.length > 1)
      {
          record.groups.map((group, index) => {
              class_name = class_name + (class_name ? ' ' : '') + 'group-' + sanitizeString(group.name);
          })
      }
      // else if(record.form)
      // {
      //     // form
      //     // class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form.form_name);
      // }
      // else
      // {
      //     // form
      //     // class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form_name);
      // }

      // form(s)
      if(record.children && record.children.length > 1)
      {
          // record.children.map((child) => {
          //     class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(child.form_name);
          // });

          class_name += ' forms-combined';
      }
      else if(record.form && typeof record.form === 'object' && record.form.form_name)
      {
          // form
          class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form.form_name);
      }
      else
      {
          // form
          class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form_name);
      }

      // filled in form
      class_name = class_name + (class_name ? ' ' : '') + 'status' + (record.children && record.children.length > 1 ? '' : ' status-' + record.status);

      // filled in form
      // var has_form = ((record.form && (record.form.submitted_form || record.form.form_submitted || record.form.form_status != null)) || record.filled_in_form);
      //
      // class_name = class_name + (class_name ? ' ' : '') + 'filled-in' + (record.children && record.children.length > 1 ? '' : ((record.form || record.choice_form_id) && has_form) ? ' filled-yes' : ' filled-no');

      // transactions
      // class_name = class_name + (class_name ? ' ' : '') + 'transactions ' + (record.children && record.children.length > 1 ? '' : (record.total_not_paid == 0 ? 'all-paid' : (record.total_not_paid > 0 ? 'not-paid' : 'to-much-paid')));

      return class_name;
  }

  applyFilters()
  {
      var filters = this.state.filters;

      var combined_classes = [];

      if(filters.group_name && filters.group_name.length >= 1)
      {
          filters.group_name.map((value) => {
              combined_classes.push("group-"+sanitizeString(value));
          });
      }/**/

      if(filters.form_name && filters.form_name.length >= 1)
      {
          filters.form_name.map((value) => {
              combined_classes.push("form-"+sanitizeString(value));
          });
      }/**/

      if(filters.status && filters.status.length >= 1)
      {
          filters.status.map((value) => {
              combined_classes.push("status-"+value);
          });
      }/**/

      // if(filters.submitted_form && filters.submitted_form.length >= 1)
      // {
      //     filters.submitted_form.map((value) => {
      //         combined_classes.push("filled-"+value);
      //     });
      // }/**/

      // if(filters.total_not_paid_formatted && filters.total_not_paid_formatted.length >= 1)
      // {
      //     filters.total_not_paid_formatted.map((value) => {
      //         combined_classes.push(value);
      //     });
      // }/**/

      //
      if(combined_classes.length > 0)
      {
          setTimeout(() => {

              var expandedRowKeys = [];

              this.state.addresses.map((record) => {
                  expandedRowKeys.push('address-'+record.id);
              });

              this.setState({ expandedRowKeys: expandedRowKeys }, () => {

                  setTimeout(() => {

                      // hide all
                      var els = document.querySelectorAll('tr.address-row');

                      els.forEach((el) => {
                          if(el.style) el.style.display = 'none';
                      });

                      var els = document.querySelectorAll('tr.form-row');

                      els.forEach((el) => {
                          if(el.style) el.style.display = 'none';
                      });

                      // show rows of combined classes
                      var class_query = (combined_classes.length ? 'tr.' : '') + combined_classes.join('.');

                      if(class_query)
                      {
                          var els = document.querySelectorAll(class_query);

                          els.forEach((el) => {
                              if(el.style) el.style.display = 'table-row';
                          });
                      }

                  }, 100);
              });

          }, 1);
      }

      // no filter(s)
      if((!filters.form_name || !filters.form_name.length) && (!filters.submitted_form || !filters.submitted_form.length) && (!filters.total_not_paid_formatted || !filters.total_not_paid_formatted.length))
      {
          // close all
          this.setState({ expandedRowKeys: [] }, () => {

            // show all
            this.props.forms.map((form) => {

                var els = document.querySelectorAll('tr[class*="forms-combined"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });

                var els = document.querySelectorAll('tr[class*="form-'+sanitizeString(form.description)+'"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });
            });

            this.props.forms.map((form) => {

                var els = document.querySelectorAll('tr[class*="filled-in"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });
            });

            this.props.forms.map((form) => {

                var els = document.querySelectorAll('tr[class*="transactions"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });
            });
          });
      }
  }

  toggleAddresses()
  {
      var toggle_ids = [];

      if(!this.state.all_open)
      {
          this.state.addresses.map((obj) => {

              if(obj.choice_form_id){
                  toggle_ids.push('form-'+obj.id);
              }
              else if(obj.children && obj.children.length > 1){
                  toggle_ids.push('address-'+obj.id);
              }
          });

          this.setState({
              expandedRowKeys: toggle_ids,
              all_open: true
          });
      }
      else
      {
          this.setState({
              expandedRowKeys: [],
              all_open: false
          });
      }
  }

  /**
   * - Open
   * - Gestart
   * - *Ondertekend
   * - Afgerond
   */
  getFormStatus(status)
  {
      if(!status || status == ''){ return 'Open'; }
      else if(status == 'new'){ return 'Open'; }
      else if(status == 'open'){ return 'Open'; }
      else if(status == 'started'){ return 'Gestart'; }
      else if(status == 'answering'){ return 'Gestart'; }
      else if(status == 'answered'){ return 'Gestart'; }
      else if(status == 'summary'){ return 'Gestart'; }
      else if(status == 'locked'){ return 'Gestart'; }
      else if(status == 'nr-of-signatures'){ return 'Gestart'; }
      else if(status == 'signed'){ return 'Afgerond'; }
      else if(status == 'send'){ return 'Afgerond'; }
      else if(status == 'completed'){ return 'Afgerond'; }
      else { return status; }
  }

  getMappedFormStatus(status)
  {
           if(!status || status == '' || status == 'new'){ return 'open'; }
      else if(status == 'answering' || status == 'answered' || status == 'summary' || status == 'locked' || status == 'nr-of-signatures'){ return 'started'; }
      else if(status == 'signed'){ return 'signed'; }
      else if(status == 'send' || status == 'completed'){ return 'completed'; }
      else { return status; }
  }

  viewFormEntryHTML(form_id, id, title = 'PDF') {

      var w = window.open(JOB_URL + '/form/' + form_id + '/result/' + id  + '/html?token=' + localStorage.getItem('authToken'));

      w.document.title = title;
  }

  viewFormEntryPDF(form_id, id, title = 'PDF') {

      // var w = window.open(JOB_URL + '/form/' + form_id + '/result/' + id  + '/pdf?token=' + localStorage.getItem('authToken'));
      //
      // w.document.title = title;

      // exportFormAction = (form_id, type, subtype, ids) => (dispatch, getState) => {
      this.props.exportFormAction(form_id, 'pdf', false, [id]).then(() => {

          showSuccess('PDF succesvol aangevraagd');

          this.props.getNrOfNewQueuedItems(); // refresh topnav counter
      });
  }

  downloadFormEntry(form_id, id, title = 'Zip') {
      // this.props.downloadFormEntryAction(this.props.match.params.form_id, id);
      var w = window.open(JOB_URL + '/form/' + form_id + '/result/' + id  + '/zip?token=' + localStorage.getItem('authToken'));

      w.document.title = title;
  }

  unlockFormEntry(form_id, id)
  {
      var this_obj = this;

      confirm({
        content: <div>
            <h2>Formulier openzetten</h2>

            <p>
                Weet je zeker dat je het formulier open wilt zetten om antwoorden aan te kunnen passen?<br />
                Hiermee worden de handtekeningen die al zijn gezet verwijderd.
            </p>
        </div>,
        onOk() {

            // this_obj.props.unlockFormEntryAction(form_id, id).then(() => {
            //
            //     this_obj.props.getFormsStatusAction(this.props.match.params.id).then(() => {
            //
            //         this_obj.setState({ addresses: this.props.addresses }, () => {
            //
            //             this_obj.setState({ isFetching: false });
            //         });
            //     });
            // });

            //
            this_obj.unlockFormEntryConfirmed(form_id, id);
        },
        onCancel() { },
      });
  }

  unlockFormEntryConfirmed(form_id, id)
  {
      this.props.unlockFormEntryAction(form_id, id).then(() => {

          this.props.getFormsStatusAction(this.props.match.params.id).then(() => {

              this.setState({ addresses: this.props.addresses }, () => {

                  this.setState({ isFetching: false });
              });
          });
      });
  }

  render()
  {
      const columns = [{
          title: 'Onderwerp',
          dataIndex: 'full_address',
          render: (text, record) => {
            var label = record.description ? record.description : text;

            if(!record.children && record.address_id) // && !label)
            {
                this.state.addresses.map((address) => {
                    if(address.id == record.address_id)
                    {
                        label = address.full_address;
                    }
                });
            }

            return <span>{label}</span>
        },
        filters: this.state.addresses && this.state.addresses.map((elem) => {
          return {
            text: elem.full_address,
            value: 'address-'+elem.id,
          }
        }),
        filterMultiple: true,
        onFilter: (value, record) => 'address-'+record.id === value,
      },
      {
        title: 'Groep',
        dataIndex: 'group_name',
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        // onFilter: (value, record) => this.groupsFilter(value, record),
        // filteredValue: filteredInfo.groups || null,
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      },
      {
          title: 'Formulier naam',
          dataIndex: 'form_name',
          render: (text, record) => (
            <span>
              { record.children && record.children.length > 1 ? 'Meerdere formulieren' : (record.form && typeof record.form === 'object' ? record.form.form_name : record.form_name)}
            </span>
          ),
          filters: this.props.forms && this.props.forms.map((elem) => {
            return {
              text: elem.name,
              value: elem.name,
            }
          }),
          filterMultiple: false,
          // onFilter: (value, record) => {
          //     if(record.children && record.children.length > 1)
          //     {
          //         var found = false;
          //
          //         record.children.map((child) => {
          //             if(child.form_name === value)
          //             {
          //                 found = true;
          //             }
          //         })
          //
          //         return found;
          //     }
          //     else if(record.form){ return record.form.form_name === value; }
          //     else{ return record.form_name === value; }
          // },
      },
      {
          title: 'Status',
          dataIndex: 'status',
          render: (text, record) => {

            // var record_id = record.form ? record.form.id : record.id;
            // var address_id = record.form ? record.form.address_id : record.address_id;

            // var has_form = (text || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form);

            return <span>
                { record.children ? '' : this.getFormStatus(this.getMappedFormStatus(record.status)) }
            </span>
          },
          filterMultiple: false,
          filters: [
              {
                text: 'Open',
                value: 'new',
              },
              {
                text: 'Gestart',
                value: 'started',
              },
              {
                text: 'Ondertekend',
                value: 'signed',
              },
              {
                text: 'Afgerond',
                value: 'completed',
              },
          ],
          // onFilter: (value, record) => {
          //     if(record.children && record.children.length > 1)
          //     {
          //         var found = false;
          //
          //         record.children.map((child) => {
          //
          //             if((child.submitted_form || (child.form && (child.form.submitted_form || child.form.form_submitted || (child.form.form_status != null))) || child.filled_in_form))
          //             {
          //                 found = true;
          //             }
          //         })
          //
          //         return found;
          //     }
          //     else
          //     {
          //         return ((record.submitted_form || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form) ? true : false);
          //     }
          // }
      },
      {
          title: 'Verzonden',
          dataIndex: 'last_email_send',
          render: (text, record) => {
              return <span>{text}</span>
          }
      },
      {
          title: '',
          dataIndex: '',
          render: (text, record) => { return record.children ? null : <span>
                  <Dropdown
                      trigger={['click']}
                      overlay={(
                          <Menu>
                            <Menu.Item
                                onClick={() => {
                                    if(record.form && record.form.id)
                                    {
                                        this.viewFormEntryHTML(record.form.form_id, record.form.id, 'Formulier (HTML)');
                                    }
                                    else
                                    {
                                        this.viewFormEntryHTML(record.form_id, record.id, 'Formulier (HTML)');
                                    }
                                }}
                                >
                                Bekijken
                            </Menu.Item>
                            {this.props.HaveAbility('export', 'read') && this.getMappedFormStatus(record.status) == 'completed' ? <Menu.Divider /> : null}
                            {this.props.HaveAbility('export', 'read') && this.getMappedFormStatus(record.status) == 'completed' ? <Menu.Item
                                onClick={() => {
                                    if(record.form && record.form.id)
                                    {
                                        this.viewFormEntryPDF(record.form.form_id, record.form.id, 'Formulier (Pdf)');
                                    }
                                    else
                                    {
                                        this.viewFormEntryPDF(record.form_id, record.id, 'Formulier (Pdf)');
                                    }
                                }}
                                >
                                Bekijken (pdf)
                            </Menu.Item> : null }
                            {/*<Menu.Divider />
                            <Menu.Item
                                onClick={() => {
                                    if(record.form && record.form.id)
                                    {
                                        this.downloadFormEntry(record.form.form_id, record.form.id, 'Download (Zip)');
                                    }
                                    else
                                    {
                                        this.downloadFormEntry(record.form_id, record.id, 'Download (Zip)');
                                    }
                                }}
                                >
                                Downloaden (pdf)
                            </Menu.Item>/**/}
                            <Menu.Divider />
                            <Menu.Item
                                onClick={() => {
                                    if(record.form && record.form.id)
                                    {
                                        this.unlockFormEntry(record.form.form_id, record.form.id);
                                    }
                                    else
                                    {
                                        this.unlockFormEntry(record.form_id, record.id);
                                    }
                                }}
                                >
                                Openzetten om te bewerken
                            </Menu.Item>
                          </Menu>
                      )}
                      >
                      <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
                          Acties <DownOutlined />
                      </Button>
                  </Dropdown>
              </span>;
          }
      }
      // {
      //     title: 'Ingevuld formulier',
      //     dataIndex: 'submitted_form',
      //     render: (text, record) => {
      //
      //       var record_id = record.form ? record.form.id : record.id;
      //       var address_id = record.form ? record.form.address_id : record.address_id;
      //
      //       var has_form = (text || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form);
      //
      //       return <span>
      //       { record.form || record.choice_form_id ?
      //           has_form ?
      //           <Dropdown trigger={['click']} overlay={(
      //               <Menu>
      //                 <Menu.Item onClick={() => { this.setState({ modalShowTransactionLog: true, amount: null, modalForm: (record.choice_form_id ? record : record.form) }); }}>Transacties</Menu.Item>
      //                 <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
      //                 <Menu.Item style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/choice/address/' + record_id  + '/form/view?token=' + localStorage.getItem('authToken')}>Bekijk formulier</a></Menu.Item>
      //                 <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
      //                 <Menu.Item style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/choice/address/' + record_id  + '/form/download?token=' + localStorage.getItem('authToken')}>Download formulier</a></Menu.Item>
      //                 <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
      //                 <Menu.Item disabled={!this.state.can_write} onClick={() => { this.showUploadForm(address_id, record_id); }}>Formulier updaten</Menu.Item>
      //               </Menu>
      //             )}>
      //             <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
      //               Acties <Icon type="down" />
      //             </Button>
      //           </Dropdown>
      //           :
      //           <Button disabled={!this.state.can_write} onClick={() => { this.showUploadForm(address_id, record_id); }} style={this.state.can_write ? {color: 'red', borderColor: 'red'} : {}} >Toevoegen</Button>
      //         :
      //         null }
      //       </span>
      //     },
      //     filterMultiple: false,
      //     filters: [
      //       {
      //         text: 'Ingevuld',
      //         value: 'yes',
      //     },
      //     {
      //       text: 'Niet ingevuld',
      //       value: 'no',
      //     }],
      //     // onFilter: (value, record) => {
      //     //     if(record.children && record.children.length > 1)
      //     //     {
      //     //         var found = false;
      //     //
      //     //         record.children.map((child) => {
      //     //
      //     //             if((child.submitted_form || (child.form && (child.form.submitted_form || child.form.form_submitted || (child.form.form_status != null))) || child.filled_in_form))
      //     //             {
      //     //                 found = true;
      //     //             }
      //     //         })
      //     //
      //     //         return found;
      //     //     }
      //     //     else
      //     //     {
      //     //         return ((record.submitted_form || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form) ? true : false);
      //     //     }
      //     // }
      // },
      // {
      //     title: 'Openstaand',
      //     dataIndex: 'total_not_paid_formatted',
      //     render: (text, record) => (
      //       <span style={{color: record.total_not_paid > 0 ? 'red' : 'black'}}>{text}</span>
      //     ),
      //     filterMultiple: false,
      //     filters: [
      //       {
      //         text: 'Alles betaald',
      //         value: 'all-paid',
      //     },
      //     {
      //       text: 'Nog niet alles betaald',
      //       value: 'not-paid',
      //     },
      //     {
      //       text: 'Te veel betaald',
      //       value: 'to-much-paid',
      //     }],
      // },
      // {
      //     title: 'Voldaan',
      //     dataIndex: 'total_paid_formatted',
      //     render: (text, record) => (
      //       <span style={{color: record.total_not_paid > 0 ? 'orange' : 'black'}}>{text}</span>
      //     )
      // },
      /*{
          title: 'Transacties',
          dataIndex: 'transactions',
          render: (text, record) => {

            var choice_form_id = (record.choice_form_id ? record.choice_form_id : (record.form ? record.form.choice_form_id : false));

            return <span>
                { choice_form_id ?
                <Button onClick={() => { this.setState({ modalShowTransactionLog: true, amount: null, modalForm: (record.choice_form_id ? record : record.form) }); }}>Transacties</Button>
                :
                null }
            </span>
          }
      }/**/
      ];

      const { selectedRowKeys } = this.state;

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        getCheckboxProps: record => ({
          disabled: record.form_submitted ? false : true
        }),
      };

      const { getFieldDecorator } = this.props.form;

      return (
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <FormSideMenu id={this.props.match.params.id} activeMenu="3"/>

            <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
                <Col span={12}>
                    <Button onClick={(e) => { this.toggleAddresses(); }}>{this.state.all_open ? 'Alles dichtklappen' : 'Alles openklappen'}</Button>
                </Col>
                <Col span={12}>
                    <Search
                      placeholder="Zoeken..."
                      onSearch={value => this.onSearch(value)}
                      style={{ width: 200, float: 'right' }}
                    />
                </Col>
            </Row>

            <Row>
              <Col span={24}>

                  { !this.state.isFetching ?

                  <CustomTable
                    rowKey={(record) => {
                      if(record.choice_form_id) {
                        return 'form-'+record.id;
                      } else if(record.children && record.children.length > 1) {
                        return 'address-'+record.id;
                      } else {
                        return record.id;
                      }
                    }}
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.props.addresses}
                    indentSize="0"
                    loading={this.props.isFetching}
                    rowClassName={(record, index) => this.getRowClassName(record, index)}
                    onChange={(pagination, filters, sorter, extra) => {
                        this.setState({
                          filteredInfo: extra['currentDataSource']
                        });

                        this.setState({ filters: filters}, () => {
                            this.applyFilters();
                        });
                    }}
                    // onExpand={(expanded, record) => { this.applyFilters(); }}
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpandedRowsChange={(id) => {
                        this.setState({expandedRowKeys: id});
                        // this.applyFilters();
                    }}
                    />
                    :
                    <CustomTable
                      columns={columns}
                      dataSource={[]}
                      indentSize="0"
                      loading={true}
                      />
                    }

              </Col>
            </Row>

          </div>

        </div>
      );
    }
}

const FormsStatusForm = Form.create({ name: 'projects_forms' })(FormsStatus);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    addresses : state.forms.addresses,
    forms : state.forms.forms,
    groups: state.group.groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormsAction : bindActionCreators(getFormsAction, dispatch),
    getFormsStatusAction : bindActionCreators(getFormsStatusAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    getFormsAction: bindActionCreators(getFormsAction, dispatch),
    searchInFormsStatusAction: bindActionCreators(searchInFormsStatusAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    unlockFormEntryAction : bindActionCreators(unlockFormEntryAction, dispatch),
    exportFormAction : bindActionCreators(exportFormAction, dispatch),
    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsStatusForm);
