import React, { Ajax } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid'
import SavePesetModal from '../Modals/SavePresetModal'
import IntakeSteps from '../Steps/IntakeSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal } from 'antd';
import { Link } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getIntakeByIdAction, updateIntakeFormCompletedAction } from '../../../../actions/intakeActions';
import { showSuccess } from '../../../../utils/Notifications';

import { API_URL } from '../../../../constants/settings'

const { Option } = Select;

class ProjectIntakeResult extends React.Component {

  state = {
    intake_form: {},
    intake_form_html: '',
    result: {}
  };

  componentDidMount() {
    this.props.getIntakeByIdAction(this.props.match.params.intakeid).then(() => {
        this.setState({intake_form: this.props.intake_form});

        // load result form
        const url = API_URL + '/intake/form/'+ this.props.match.params.intakeid +'/result/' + this.props.match.params.resultid + '/?token=' + localStorage.getItem('authToken');

        var this_obj = this;

        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
           //document.getElementById("demo").innerHTML = this.responseText;
           this_obj.setState({ intake_form_html: this.responseText });
          }
        };

        xhttp.open("GET", url, true);

        xhttp.send();

        // get correct result from intake form
        this.props.intake_form.results.map((value, key) => {
            if(value.id == this.props.match.params.resultid)
            {
                this.setState({ result: value });
            }
        });
    });
  }

  viewFormResults = () => {
      window.open(API_URL + '/intake/form/'+ this.props.match.params.intakeid +'/result/' + this.props.match.params.resultid + '/pdf?token=' + localStorage.getItem('authToken'), '_blank');
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {

      if (!err){

        if(this.props.match.params.resultid) {
          this.props.updateIntakeFormCompletedAction(this.props.match.params.resultid, values).then(() => {
            this.props.history.push('/projects/' + this.props.match.params.id + '/intake/' + this.props.intake_form.id + '/results');
          });
        }

        showSuccess();
      }
    });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
          <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Link to={`/projects/${this.props.match.params.id}/intake/${this.props.match.params.intakeid}/results`}>
                      <Button>Sluiten</Button>
                    </Link>
                    <Button onClick={() => this.viewFormResults()} className="mls">Formulier bekijken</Button>
                  </div>
                </Col>
              </Row>

              {/* steps */}
              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <IntakeSteps current={2} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Card className="mbm">
                <Row gutter={24}>
                  <Col span={24}>
                    <div dangerouslySetInnerHTML={{__html: this.state.intake_form_html}}></div>
                  </Col>
                </Row>
              </Card>


          </Form>

        </div>
      );
    }

  }

const ProjectIntakeResultForm = Form.create({ name: 'intake_form' })(ProjectIntakeResult);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.intake.isFetching,
      isError : state.intake.isError,
      intake_form : state.intake.intake_form
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getIntakeByIdAction : bindActionCreators(getIntakeByIdAction, dispatch),
      updateIntakeFormCompletedAction : bindActionCreators(updateIntakeFormCompletedAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntakeResultForm);
