export default (state = {isError: false, isFetching: false, documents: [], document: {}}, action) => {
    switch (action.type) {
    case 'RESET_DOCUMENT_ACTION_FETCH':
    return {
        ...state,
        document: {},
        isFetching: false,
        isError: false,
    }
    case 'RESET_DOCUMENTS_ACTION_FETCH':
    return {
        ...state,
        documents: [],
        isFetching: false,
        isError: false,
    }
    case 'DOCUMENTS_ACTION_FETCH':
    return {
        ...state,
        documents: [],
        isFetching: true,
        isError: false,
    }
    case 'DOCUMENTS_ACTION_FETCH':
    return {
        ...state,
        document: {},
        isFetching: true,
        isError: false,
    }
    case 'DOCUMENTS_ACTION_SUCCESS':
    return {
        ...state,
        documents: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DOCUMENT_ACTION_SUCCESS':
    return {
        ...state,
        document: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DOCUMENT_ORDER_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'ADD_DOCUMENT_ACTION_SUCCESS':
    return {
        ...state,
        documents: [action.payload, ...state.documents],
        document: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DOCUMENT_REMOVE_SUCCESS':
    return {
        ...state,
        // documents: state.documents.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'DOCUMENTS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
