import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getDeliveriesAction, searchDeliveriesAction, deleteDeliveriesAction, archiveDeliveriesAction, addDeliveryAction, updateDeliveryAction, resetDeliveriesAction, getDeliverAddressesAction, getDrawingsAction, getLocationFilterAction } from '../../../actions/deliverActions';
import { getGroupsAction } from '../../../actions/groupsActions';
import DeliverSideMenu from './DeliverSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Divider,
    Modal,
    Select,
    Menu,
    Dropdown,
    Badge,
    Tag,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Option } = Select;

class Deliveries extends Component
{
    state = {
        disabled: true,
        edit: true,
        selectedRowKeys: [],
        expandedRowKeys: [],
        is_archive: false,
        can_write: false,
        can_delete: false,
        filtered: false,
        child_selected: false
    };

    deliveries = [];

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write'),
            can_delete: this.props.HaveAbility('deliver', 'delete')
        });

        this.props.resetDeliveriesAction();

        this.props.getLocationFilterAction(this.props.match.params.id);

        this.props.getGroupsAction({'projectId': this.props.match.params.id});

        this.setState({ is_archive: this.isArchive() }, () => {
            this.loadData();
        })
    }

    componentWillReceiveProps(nextprops)
    {
        if(this.isArchive() != this.state.is_archive)
        {
            this.setState({ is_archive: !this.state.is_archive }, () => {
                this.loadData();
            });
        }
    }

    isArchive()
    {
        var url = window.location.href;

        url = url.split('/');

        return (url[url.length - 1] == 'archive' ? true : false);
    }

    loadData()
    {
        this.props.getDeliveriesAction(this.props.match.params.id, this.state.is_archive).then(() => {

            this.deliveries = JSON.parse(JSON.stringify(this.props.deliveries)); // original

            var flattened_deliveries = [];

            // is sub point selected
            this.deliveries.map((record) => {

                if(record.children)
                {
                    var children = JSON.parse(JSON.stringify(record.children));

                    // delete record.children;
                    record.children = false;

                    flattened_deliveries.push(record);

                    children.map((child_record) => {

                        flattened_deliveries.push(child_record);
                    });
                }
                else
                {
                    flattened_deliveries.push(record);
                }
            });

            this.setState({ deliveries: flattened_deliveries });
        });

        this.props.getDeliverAddressesAction(this.props.match.params.id);

        // console.log(this.props.addresses);

        this.props.getDrawingsAction(this.props.match.params.id, 'all');
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });

        // child point selected?
        var child_selected = false;

        // this.state.deliveries.map((record) => {

        // console.log('selected keys', selectedRowKeys);

        selectedRowKeys.map((key) => {

            //expandedRowKeys.map((key) => {
            var key_found = false;

            this.state.deliveries.map((record) => {

                if(record.id == key && !record.parent_id)
                {
                    key_found = true;

                }

                // if(record.id != key)
                // {
                //     child_selected = true;
                // }
            });

            // console.log(key_found);

            if(!key_found){ child_selected = true; }
        });

        this.setState({ child_selected: child_selected });
    }

    toggleArchive(status)
    {
        this.props.resetDeliveriesAction();

        this.setState({ is_archive: status }, function(){

            window.history.pushState({}, null, '/projects/'+this.props.match.params.id+'/deliver/deliveries'+(this.state.is_archive ? '/archive' : ''));
            this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/deliveries'+(this.state.is_archive ? '/archive' : ''));

            this.loadData();
        });
    }

    addNewDelivery()
    {
        this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/delivery/add');

        /*this.props.form.resetFields();

        this.setState({
            delivery_id: false,
            show_delivery_modal: true,
            errors: {},
        });*/
    }

    updateDelivery(id)
    {
        this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/delivery/' + id);

        /*this.props.form.resetFields();

        this.props.deliveries.map((record, index) => {

            if(record.id == id)
            {
                this.props.form.setFieldsValue({
                    description: record.description,
                    address_id: record.address_id,
                    delivery_drawing_id: record.delivery_drawing_id,
                });
            }
        })

        this.setState({
            delivery_id: id,
            show_delivery_modal: true,
            errors: {},
        });*/
    }

    deleteDeliveries()
    {
        Modal.confirm({
          title: 'Wil je deze items verwijderen?',
          // content: 'Als je op JA klikt, wordt ook het gehele dossier verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

              this.props.deleteDeliveriesAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {

                  resolve();

                  showSuccess('Succesvol verwijderd');

                  this.setState({
                      selectedRowKeys: []
                  });

                  this.loadData();
              });
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
    }

    archiveDeliveries()
    {
        this.props.archiveDeliveriesAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
            showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));

            this.setState({
                selectedRowKeys: []
            });

            this.loadData();
        });
    }

    handleDelivery = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors)
            {   // ok
                if(this.state.delivery_id)
                {   // update
                    this.props.updateDeliveryAction(this.state.delivery_id, values).then(() => {

                        showSuccess('Succesvol opgeslagen');

                        this.loadData();
                    });
                }
                else
                {   // add
                    this.props.addDeliveryAction(this.props.match.params.id, values).then(() => {

                        showSuccess('Succesvol toegevoegd');

                        this.loadData();
                    });
                }

                // hide
                this.setState({ show_delivery_modal: false });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    getStatusText(status)
    {
        if(status == 'completed'){ return 'Afgehandeld'; }
        else { return 'Open'; }
    }

    getAddressFilters()
    {
        var filters = [];

        this.props.location_filters && this.props.location_filters.map((record) => {

            if(record.type == 'address')
            {
                filters.push({
                    text: record.full_address,
                    value: record.id
                });
            }
        });

        return filters;
    }

    applyFilters()
    {
        var filtered = false;

        var filter_keys = Object.keys(this.state.filters);

        filter_keys.map((key) => {
            if(this.state.filters[key].length)
            {
                filtered = true;

                return;
            }
        })

        this.setState({ filtered: filtered });
    }

    render()
    {
        const columns = [
            {
                title: 'Vooroplevering',
                dataIndex: 'description',
                render: (text, record) => (
                    <span>
                        { !record.address_label ?
                        <Link onClick={(event) => { event.preventDefault(); this.updateDelivery(record.id); }}>
                            { text ? text : record.address }
                        </Link>
                        :
                        <Link onClick={(event) => { event.preventDefault(); this.updateDelivery(record.parent_id); }}>
                            {record.parent_description}
                        </Link>
                        }
                    </span>
                )
            },
            {
                title: 'Adres',
                dataIndex: 'address_label',
                render: (text, record) => (
                    <span>
                        { text }
                    </span>
                ),
                sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
                // filters: this.getAddressFilters(),
                // onFilter: (value, record) => (record.address_id && record.address_id === value) || (record.address_ids && record.address_ids.includes(value))
            },
            {
              title: 'Groep',
              dataIndex: 'groups',
              filters: this.props.groups && this.props.groups.map((elem) => {
                return {
                  text: elem.name,
                  value: elem.name,
                }
              }),
              filterMultiple: true,
              // onFilter: (value, record) => (record.groups && record.groups === value) || (record.groups && record.groups.includes(value)),
              onFilter: (value, record) => (record.groups && record.groups === value) || (record.groups && record.groups.some(group => group['name'] === value)),
              //onFilter: (value, record) => record.groups && record.groups.filter((element) => element.name === value).length,
              //onFilter: (value, record) => this.groupsFilter(value, record),
              //filteredValue: filteredInfo.groups || null,
              render: (text, record) =>
                record.children && record.children.length ? null :
                record.groups && record.groups.map((v) => (
                    v.name ? <Tag color={v.color}>{v.name}</Tag> : null
                ))
            },
            /*{
              title: 'Status',
              dataIndex: 'status',
              sorter: (a, b) => { return a.status.localeCompare(b.status)},
              render: (text, record) => (
                this.getStatusText(text)
              ),
              filters: [
                {
                  text: 'Open',
                  value: 'open',
                },
                {
                  text: 'Controleren',
                  value: 'submitted',
                },
                {
                  text: 'Afgehandeld',
                  value: 'completed',
                }
              ],
              onFilter: (value, record) => (typeof record.status == 'array' ? record.status.includes(value) : record.status == value),
              width: 110
            },/**/
            {
              title: 'Punten',
              render: (text, record) => {
                return (
                  <div>
                    <Badge showZero count={record.nr_of_completed} style={{ opacity: (!record.nr_of_completed ? 0.33 : 1.0), backgroundColor: 'green', color: '#fff', marginRight: 4 }}></Badge>
                    <Badge showZero count={record.nr_of_submitted} style={{ opacity: (!record.nr_of_submitted ? 0.33 : 1.0),  backgroundColor: 'orange', color: '#fff', marginRight: 4 }}></Badge>
                    <Badge showZero count={record.nr_of_open} style={{ opacity: (!record.nr_of_open ? 0.33 : 1.0),  backgroundColor: 'red', color: '#fff', marginRight: 4 }}></Badge>
                  </div>)
              }
            }

            // {
            //     title: 'Adres',
            //     dataIndex: 'address',
            //     render: (text, record) => (
            //         <span>{ text  }</span>
            //     )
            // },
            // {
            //     title: 'Gekoppelde tekening',
            //     dataIndex: 'drawing',
            //     render: (text, record) => (
            //         <span>
            //             {text}
            //         </span>
            //     )
            // }
        ];

        const { selectedRowKeys } = this.state;
        const { expandedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected) => {
                if(selected)
                {
                    var expandedRowKeys = this.state.expandedRowKeys;

                    expandedRowKeys.push(record.id);

                    this.setState({ expandedRowKeys: expandedRowKeys});
                }
                // else
                // {
                //
                // }
            },
            hideDefaultSelections: true,
            getCheckboxProps: (record) => ({
                disabled: record.nr_of_points > 0 ? true : false
            }),
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="5"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Row className="mbm">
                        <Col span={12}>
                            <Button disabled={!this.state.can_write} type="new" style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.addNewDelivery(); }}>+ Toevoegen</Button>

                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item key="2" type="secondary" disabled={this.state.child_selected || !this.state.selectedRowKeys.length ? true : false} style={{display: (this.state.is_archive ? 'none' : 'flex')}} onClick={() => { this.archiveDeliveries(); }}>Archiveren</Menu.Item>
                                  <Menu.Item key="3" type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'flex' : 'none')}} onClick={() => { this.archiveDeliveries(); }}>Herstellen</Menu.Item>
                                  <Menu.Divider style={{display: (this.state.is_archive ? 'none' : 'flex')}}></Menu.Divider>
                                  <Menu.Item key="1" disabled={(this.state.can_delete && this.state.selectedRowKeys.length > 0 ? false : true)} style={{display: (this.state.is_archive ? 'none' : 'flex'), color: this.state.can_delete ? 'red' : 'grey'}} onClick={() => { this.deleteDeliveries(); }}>Verwijderen</Menu.Item>
                                </Menu>
                              )} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true }>
                              <Button type="secondary">
                                Acties <DownOutlined />
                              </Button>
                            </Dropdown>

                            {/*<Button disabled={!this.state.can_write} type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.deleteDeliveries(); }}>Verwijderen</Button>
                            <Button disabled={!this.state.can_write} type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.archiveDeliveries(); }}>Archiveren</Button>
                            <Button disabled={!this.state.can_write} type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.archiveDeliveries(); }}>Herstellen</Button>*/}
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchDeliveriesAction(this.props.match.params.id, value, this.state.is_archive); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                            />
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.toggleArchive(true); }}>Archief</Button>
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.toggleArchive(false); }}>Overzicht</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ padding: 6, paddingLeft: 0 }}>
                            Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {/*<CustomTable
                                //rowKey='id'
                                rowKey={(record) => {
                                    return record.id; // (record.parent_id ? record.parent_id + '_' + record.id : record.id)
                                }}
                                size="middle"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={this.props.deliveries}
                                loading={this.props.isFetching}
                            />*/}

                            { this.state.filtered == true ?
                            <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                // expandedRowKeys={this.state.expandedRowKeys}
                                // onExpandedRowsChange={(id) => {
                                //     this.setState({expandedRowKeys: id});
                                // }}
                                columns={columns}
                                dataSource={this.state.deliveries}
                                loading={this.props.isFetching}
                                onChange={(pagination, filters, sorter, extra) => {

                                    this.setState({ filters: filters}, () => {

                                        this.applyFilters();
                                    });
                                }}
                                indentSize={0}
                                // rowClassName={(record, index) => {
                                //     return this.filterRecord(record) ? 'display-none' : '';
                                // }}
                                />
                            :
                            <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                // expandedRowKeys={this.state.expandedRowKeys}
                                // onExpandedRowsChange={(id) => {
                                //     this.setState({expandedRowKeys: id});
                                // }}
                                columns={columns}
                                dataSource={this.props.deliveries}
                                loading={this.props.isFetching}
                                onChange={(pagination, filters, sorter, extra) => {

                                    this.setState({ filters: filters}, () => {

                                        this.applyFilters();
                                    });
                                }}
                                indentSize={0}
                                // rowClassName={(record, index) => {
                                //     return this.filterRecord(record) ? 'display-none' : '';
                                // }}
                                />
                            }

                        </Col>
                    </Row>

                </div>

                <Modal
                    title="Oplevering gegevens"
                    visible={this.state.show_delivery_modal}
                    onCancel={() => {
                        this.setState({ show_delivery_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'delivery',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="delivery"
                        onSubmit={this.handleDelivery}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Omschrijving"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('description', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Input />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Adres"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('address_id', {})(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een adres"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        mode={this.state.delivery_id ? 'single' : 'multiple'}
                                    >
                                        { this.props.addresses.map((record, index) => {
                                            return <Option value={record.id}>{record.full_address}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Tekening"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('delivery_drawing_id', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een tekening"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        { this.props.drawings.map((record, index) => {
                                            return <Option value={record.id}>{record.description}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

            </div>
        );
    }
}

const DeliveriesForm = Form.create({ name: 'delivery' })(Deliveries);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        deliveries : state.deliver.deliveries,
        addresses : state.deliver.addresses,
        drawings : state.deliver.drawings,
        location_filters : state.deliver.location_filters,
        groups : state.group.groups
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDeliveriesAction : bindActionCreators(getDeliveriesAction, dispatch),
        searchDeliveriesAction : bindActionCreators(searchDeliveriesAction, dispatch),
        deleteDeliveriesAction : bindActionCreators(deleteDeliveriesAction, dispatch),
        archiveDeliveriesAction : bindActionCreators(archiveDeliveriesAction, dispatch),
        addDeliveryAction : bindActionCreators(addDeliveryAction, dispatch),
        updateDeliveryAction : bindActionCreators(updateDeliveryAction, dispatch),
        resetDeliveriesAction : bindActionCreators(resetDeliveriesAction, dispatch),
        getDeliverAddressesAction : bindActionCreators(getDeliverAddressesAction, dispatch),
        getDrawingsAction : bindActionCreators(getDrawingsAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveriesForm);
