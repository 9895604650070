import api from '../utils/api'

export const getChoiceFormsByProjectAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_FORMS_ACTION_FETCH'
    })

    return api('get','/choice/forms/project/' + project_id, null, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchChoiceFormsByProjectAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_FORMS_ACTION_FETCH'
    })

    return api('post','/choice/forms/project/' + project_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneChoiceFormsByProjectAction = (id) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_FORMS_ACTION_FETCH'
    })

    return api('post','/choice/form/' + id + '/clone', null, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteChoiceFormsByProjectAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/project/' + project_id + '/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_DELETE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneChoiceFormFromCustomerToProject = (project_id, form_id) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/form/project/' + project_id + '/form/' + form_id + '/clone', null, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const getChoiceFormsFromLibraryAction = () => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CHOICE_FORMS_ACTION_FETCH'
    })

    return api('get','/choice/forms/customer/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_LIB_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const getAddressesAction = (project_id, form_id = false) => dispatch => {

  dispatch({
    type: 'CHOICE_FORM_ADDRESSES_ACTION_FETCH'
  })

  return api('get','choice/project/' + project_id + '/form/' + form_id + '/addresses', null, true).then((response) => {
    dispatch({
      type: 'CHOICE_FORM_ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CHOICE_ACTION_ERROR',
    })
    return false;
  });
}

export const searchAddressesAction = (project_id, form_id = false, search = '') => dispatch => {

  dispatch({
    type: 'CHOICE_ACTION_FETCH'
  })

  return api('post','choice/project/' + project_id + '/form/' + form_id + '/addresses/search', {search: search}, true).then((response) => {
    dispatch({
      type: 'CHOICE_FORM_ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CHOICE_ACTION_ERROR',
    })
    return false;
  });
}

export const getChoiceFormByIdAction = (form_id, price_calculation = true, statistics = false) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_FORM_ACTION_FETCH'
    })

    return api('get', '/choice/form/'+form_id+'/?price='+(price_calculation ? 1 : 0)+'&stats='+(statistics ? 1 : 0), null, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const addChoiceFormToProjectAction = (project_id, values) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/project/' + project_id + '/add', values, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateChoiceFormAction = (form_id, values) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/form/' + form_id + '/update', values, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_ACTION_SUCCESS',
            payload: response.data && response.data.choice_form ? response.data.choice_form : response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const addChoiceFormToCustomerAction = (values) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/customer/' + customer_id + '/add', values, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const checkChoiceFormAction = (form_id, type) => (dispatch, getState) => {

    // return api('get','/choice/form/' + form_id + '/check/form/' + type, null, true, null, true).then((response) => {
    //
    //     var reader = new FileReader();
    //
    //     var blob = new Blob([response.data], { type: type == 'digital' ? 'text/html' : 'application/pdf' });
    //
    //     if(type == 'digital')
    //     {
    //         reader.readAsText(blob);
    //     }
    //     else
    //     {
    //         reader.readAsDataURL(blob);
    //     }
    //
    //     reader.onloadend = function (e) {
    //
    //         var win = window.open();
    //
    //         if(type == 'digital')
    //         {
    //             win.document.write(reader.result);
    //         }
    //         else
    //         {
    //             win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    //         }
    //
    //         win.document.close();
    //     }
    //
    // }).catch((e) => {
    //     return false;
    // });
}

export const cloneChoiceFormsByCustomerAction = (ids) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/customer/' + customer_id + '/clone', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteChoiceFormsByCustomerAction = (ids) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/customer/' + customer_id + '/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_DELETE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const getChoiceFormsByCustomerAction = () => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('get','/choice/forms/customer/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const resetChoiceAction = () => dispatch => {

  dispatch({
    type: 'CHOICE_ACTION_RESET'
  })
}

export const searchChoiceFormsByCustomerAction = (search) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/customer/' + customer_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const saveChoiceSettingsAction = (project_id, settings) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/forms/project/' + project_id +'/settings', settings, true).then((response) => {
        dispatch({
            type: 'CHOICE_SETTINGS_ACTION_SUCCESS',
            //payload: response.data.choice_form
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const getChoiceStatusAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('get', '/choice/project/' + project_id + '/status', null, true).then((response) => {
        dispatch({
            type: 'CHOICE_STATUS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const getChoiceFormStatusAction = (project_id, form_id) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('get', '/choice/project/' + project_id + '/status/' + form_id, null, true).then((response) => {
        dispatch({
            type: 'CHOICE_STATUS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchChoiceStatusAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/project/' + project_id + '/status/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'CHOICE_STATUS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchChoiceFormStatusAction = (project_id, form_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/project/' + project_id + '/status/' + form_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'CHOICE_STATUS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const uploadFormAction = (choice_form_address_id, has_additional_work, is_paid, filelist, amount, amount_direction) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/address/' + choice_form_address_id + '/form/upload', { /*has_additional_work: has_additional_work, is_paid: is_paid,*/ amount: amount, type: amount_direction }, true, filelist).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_UPLOAD_ACTION_SUCCESS',
            //payload: response.data.choice_form
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const unlockAddressChoiceFormAction = (choice_form_address_id) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/address/' + choice_form_address_id + '/form/unlock', null, true).then((response) => {

        dispatch({
            type: 'CHOICE_FORM_UNLOCK_ACTION_SUCCESS',
            //payload: response.data.choice_form
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const downloadDocumentsAction = (project_id, params, format = 'normal') => (dispatch, getState) => {

    // dispatch({
    //     type: 'CHOICE_ACTION_FETCH'
    // })

    return api('post','/choice/project/' + project_id + '/status/form/download/documents/' + format, params, true).then((response) => {
        // dispatch({
        //     type: 'CHOICE_FORM_DOWNLOAD_ACTION_SUCCESS',
        //     //payload: response.data.choice_form
        // });
    }).catch((e) => {
        // dispatch({
        //     type: 'CHOICE_ACTION_ERROR',
        // })
        return false;
    });
}

export const requestDownloadExcelAction = (project_id, form_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/project/' + project_id + '/status/form/' + form_id + '/request/excel', params, true).then((response) => {

        dispatch({
            type: 'CHOICE_FORM_REQUEST_ACTION_SUCCESS'
        })

    }).catch((e) => {

        return false;
    });

    dispatch({
        type: 'CHOICE_ACTION_ERROR'
    })

}

export const downloadExcelAction = (project_id, form_id, params, filename = false) => (dispatch, getState) => {

    return api('post','/choice/project/' + project_id + '/status/form/' + form_id + '/download/excel', params, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const a = document.createElement('a');

        a.setAttribute('download', (filename ? filename : 'keuze-p'+project_id+'-f'+form_id+'-overzicht.xlsx'));

        a.setAttribute('href', window.URL.createObjectURL(blob));

        a.click();

    }).catch((e) => {

        return false;
    });
}

export const requestExportChoiceStatusAction = (project_id, params = null) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/project/'+project_id+'/status/request/excel', params, true).then((response) => {

        dispatch({
            type: 'CHOICE_FORM_REQUEST_ACTION_SUCCESS'
        })

    }).catch((e) => {

        return false;
    });

    dispatch({
        type: 'CHOICE_ACTION_ERROR'
    })

}

export const downloadStatusAction = (project_id, filename = false) => (dispatch, getState) => {

    return api('get','/choice/project/' + project_id + '/status/download/excel', false, true).then((response) => {

        // method 1
        // var reader = new FileReader();
        //
        // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //
        // const a = document.createElement('a');
        //
        // a.setAttribute('download', (filename ? filename : 'keuze-p'+project_id+'-status-overzicht.xlsx'));
        //
        // a.setAttribute('href', window.URL.createObjectURL(blob));
        //
        // a.click();

        // method 2
        // var reader = new FileReader();
        //
        // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //
        // reader.readAsDataURL(blob);
        //
        // reader.onloadend = function (e) {
        //     window.open(reader.result, 'Export resultaten');
        // }

        // method 3

    }).catch((e) => {

        return false;
    });
}

export const multiUpdateChoiceFormAddressesStatusAction = (project_id, ids, status_field, status_value) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    var values = {};
    values.ids = ids;
    values[status_field] = status_value;

    return api('post','/choice/project/' + project_id +'/addresses/forms/update', values, true).then((response) => {
        dispatch({
            type: 'CHOICE_UPDATE_STATUS_ACTION_SUCCESS',
            // payload: response.data.choice_form
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const addChoiceFormAddressTransactionAction = (id, values) => (dispatch, getState) => {

    dispatch({
        type: 'CHOICE_ACTION_FETCH'
    })

    return api('post','/choice/address/' + id + '/form/transaction', values, true).then((response) => {
        dispatch({
            type: 'CHOICE_FORM_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CHOICE_ACTION_ERROR',
        })
        return false;
    });
}
