import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder'

import { v1 as uuidv1 } from 'uuid'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getIntakeByIdAction, addIntakeCustomerAction, updateIntakeCustomerAction, resetIntakeAction, previewAction } from '../../../actions/intakeActions';

import PageHeader from '../../../components/PageHeader/PageHeader';

import LibrarySideMenu from '../LibrarySideMenu';
import { HaveAbility } from '../../../utils/RolesAndAbilities';


class IntakeDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      loading: true,
    };
  }

  componentDidMount()
  {

    this.props.resetIntakeAction();

    if(this.props.match.params.id) {

      this.props.getIntakeByIdAction(this.props.match.params.id).then(() => {

        if(this.IsJsonString(this.props.intake_form.form)){

          this.setState({formData: JSON.parse(this.props.intake_form.form)}, () => {

              this.refs.FormBuilder.parse(JSON.parse(this.props.intake_form.form));

              this.setState({ loading: false });
          })
        }
      });
    } else {
      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []}]);

      this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.form.validateFields((err, values) => {

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      values.form = JSON.stringify(this.state.formData);

      if (values.tags) {
        values.tags = values.tags.toString();
      }

      if (this.props.match.params.id) {
        this.props.updateIntakeCustomerAction(this.props.match.params.id, values).then(() => {
          if (redirect) {
            this.props.history.push(`/library/intake`);
          }
        });
      }
      else {
        this.props.addIntakeCustomerAction(values).then(() => {
          this.props.history.push('/library/intake/' + this.props.intake_form.id + '/detail');
        });
      }

      showSuccess();
    });
  }

  preview()
  {
      this.props.previewAction(this.props.match.params.id);
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Opname', 'link': '#'}, {'label': 'Formulieren'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='8' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={this.state.loading || !this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null, this.state.formData, true)}>Opslaan</Button>
                      <Link to={`/library/intake`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col span={12} align={'right'}>
                      <Button onClick={(event) => { this.preview(); }}>Voorbeeld</Button>
                  </Col>
                </Row>

                <Divider></Divider>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item required label="Naam">
                          {getFieldDecorator('name', {
                            initialValue: this.props.intake_form ? this.props.intake_form.name : '',
                            rules: [{ required: true, message: 'Vul een naam in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item className="mbn" label="Tags">
                          {getFieldDecorator('tags', {
                            initialValue: this.props.intake_form && this.props.intake_form.tags ? this.props.intake_form.tags.split(",") : [],
                            rules: [{ required: false, message: 'Vul een of meerdere tags in' }],
                          })(
                            <Select mode="tags" style={{ width: '100%' }} placeholder="Voeg een label toe"></Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>


                      <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                        <Col span={24}>
                          <FormBuilder
                              onChangeForm={(data) => this.setState({formData: data}, (e) => this.handleSubmit(e, data, false))}
                              ref={'FormBuilder'}
                              formId={this.props.match.params.id}
                              enabledElements={['select', 'textinput', 'title', 'textareainput', 'numberinput', 'timeinput', 'dateinput', 'signatureinput', 'contactinput', 'photoinput', 'drawinginput']} />
                        </Col>
                      </Row>


                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const IntakeDetailForm = Form.create({ name: 'intake_form' })(IntakeDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.intake.isFetching,
    isError : state.intake.isError,
    intake_form : state.intake.intake_form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIntakeByIdAction : bindActionCreators(getIntakeByIdAction, dispatch),
    addIntakeCustomerAction : bindActionCreators(addIntakeCustomerAction, dispatch),
    updateIntakeCustomerAction : bindActionCreators(updateIntakeCustomerAction, dispatch),

    // getLibraryAction : bindActionCreators(getLibraryAction, dispatch),
    // patchLibraryAction : bindActionCreators(patchLibraryAction, dispatch),
    resetIntakeAction : bindActionCreators(resetIntakeAction, dispatch),
    // storeLibraryAction : bindActionCreators(storeLibraryAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    previewAction: bindActionCreators(previewAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IntakeDetailForm);
