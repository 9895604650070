import React, { Component, cloneElement } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import  AddFormForm from './Form/AddForm';
import { getFormMenuAction, removeFormMenuAction, sortFormMenuAction } from '../../../actions/formActions';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { HaveAbility, HaveRole } from '../../../utils/RolesAndAbilities';
import FormSidemenu from './FormSideMenu';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Select,
  Upload,
  Switch,
  Tag,
  Button,
  Drawer,
  Confirm,
  message,
  Steps,
  Slider,
  Menu,
  Dropdown,
  Modal,
} from 'antd';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }

// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     props.moveRow(dragIndex, hoverIndex);
//
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );

class FormsMenu extends Component {
  state = {
    drawerVisible: false,
    selectedRowKeys: [],
    menus: [],
  };

  componentDidMount() {

    this.props.getFormMenuAction(this.props.match.params.id).then(() => {

      this.setState({menus: this.props.menus});
    });
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  showConfirm() {

    showMultiDelete((multidelete) => {

      if(multidelete) {

        this.props.removeFormMenuAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {

          // this.setState({selectedRowKeys: [], menus: this.props.menus});
          this.props.getFormMenuAction(this.props.match.params.id).then(() => {

            this.setState({menus: this.props.menus});
          });

          showSuccess('Succesvol verwijderd');
        });
      }
    });
  }

  // moveRow = (dragIndex, hoverIndex) => {
  //
  //   const { menus } = this.state;
  //   const dragRow = menus[dragIndex];
  //
  //   this.setState(
  //     update(this.state, {
  //       menus: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.menus.map((v,k) => v.id);
  //
  //   this.props.sortFormMenuAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
  // };

  // components = {
  //
  //   body: {
  //
  //     row: DragableBodyRow,
  //   },
  // };

  render() {

    const columns = [
    {
      key: "sort",
      width: 20
    },
    {
      title: 'Naam',
      dataIndex: 'name',
      render: (text, record) => (
        <span>
          <Link to={`/projects/${record.project_id}/form/menu/${record.id}`}>
            {text}
          </Link>
        </span>
      )
  },
  {
    title: 'Aantal items',
    dataIndex: 'nr_of_items'
  }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      getCheckboxProps: (record: DataType) => ({
          disabled: record.nr_of_items > 0 ? true : false
      })
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var menus = this.state.menus;

        const activeIndex = menus.findIndex((i) => i.id === active.id);
        const overIndex = menus.findIndex((i) => i.id === over.id);

        this.setState({ menus: arrayMove(menus, activeIndex, overIndex) }, () => {

          var sort = this.state.menus.map((v,k) => v.id);

          this.props.sortFormMenuAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
        });
      }
    };

    return (
      <div>
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <FormSidemenu id={this.props.match.params.id} activeMenu="2" {...this.props} />
            <div layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
                <Row className="mbm">
                  <Col span={24}>
                    <Link to={`/projects/${this.props.match.params.id}/form/menu/add`}>
                      <Button disabled={!this.props.HaveAbility('form' ,'write')} type="new" icon={<PlusOutlined />}>Toevoegen</Button>
                    </Link>
                    {/*<Dropdown disabled={!this.props.HaveAbility('form' ,'write')}  trigger={['click']} overlay={(
                        <Menu>
                          <Menu.Item key="1">
                              <Link to={`/projects/${this.props.match.params.id}/modules/form/menu/add`}>
                                  Nieuw
                              </Link>
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button type="new">
                          Toevoegen <Icon type="down" />
                      </Button>
                  </Dropdown>*/}
                    {/*<Link to={`/projects/${this.props.match.params.id}/modules/form/add`}>
                      <Button disabled={!this.props.HaveAbility(12 ,'update')} type="new" icon="plus">Toevoegen</Button>
                    </Link>
                    {this.props.HaveRole(['super-admin', 'admin', 'projectmanager']) ? (
                      <CloneForm onClone={() => this.setState({formTexts: this.props.form})} {...this.props} />
                    ) : null}*/}

                      <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('form' ,'write') && (this.state.selectedRowKeys.length > 0 ? false : true) } overlay={(
                        <Menu>
                          <Menu.Item onClick={() => this.showConfirm() } key="5" disabled={!this.props.HaveAbility('form' ,'delete')} style={{ color: this.props.HaveAbility('form', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                        </Menu>
                      )}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                      { this.state.menus && this.state.menus.length ?
                      <DndContext onDragEnd={onDragEnd}>
                        <SortableContext
                          items={this.state.menus.map((i) => i.id)}
                          strategy={verticalListSortingStrategy}
                        >
                          <CustomTable
                            rowKey='id'
                            columns={columns}
                            dataSource={this.state.menus}
                            components={!this.props.isFetching ? {
                                body: {
                                    row: DraggableRow
                                }
                            } : {}}
                            size="middle"
                            rowSelection={rowSelection}
                            loading={this.props.isFetching}
                            />
                        </SortableContext>
                      </DndContext>
                      :
                      <CustomTable
                        rowKey='id'
                        columns={columns}
                        dataSource={this.state.menus}
                        size="middle"
                        rowSelection={rowSelection}
                        loading={this.props.isFetching}
                        /> }
                  </Col>
                </Row>
              </div>
            </div>
        </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.forms.isFetching,
    isError: state.forms.isError,
    menus: state.forms.menus,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormMenuAction: bindActionCreators(getFormMenuAction, dispatch),
    removeFormMenuAction: bindActionCreators(removeFormMenuAction, dispatch),
    sortFormMenuAction:  bindActionCreators(sortFormMenuAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    HaveRole:  bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsMenu);
