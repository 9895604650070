import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../../utils/Notifications';
import { getInformationMenuItemAction, patchInformationMenuAction, addInformationMenuAction, resetInformationMenuAction } from '../../../../../actions/informationActions';
import { getPartnersAction } from '../../../../../actions/partnersActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import _ from 'underscore';
import InformationSidemenu from '../InformationSideMenu';

class InformationMenuDetail extends Component {

    state = {
        partners: []
    }

    componentDidMount() {

      this.props.resetInformationMenuAction();

      if(this.props.match.params.informationmenuitemid) {
          this.props.getInformationMenuItemAction(this.props.match.params.informationmenuitemid).then(() => {});
      }
    }

    patchInformationMenu = (e, values) => {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          if(this.props.match.params.informationmenuitemid) {
            this.props.patchInformationMenuAction(this.props.match.params.informationmenuitemid, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/information/menu`);
            });
          } else {
            this.props.addInformationMenuAction(this.props.match.params.id, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/information/menu`);
            });
          }
          showSuccess();
        }
      });
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
          <div>
              <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
                <InformationSidemenu id={this.props.match.params.id} activeMenu={["2"]} {...this.props}/>
                <div className="chatScreen" layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
                    <Row className="mbm">
                      <Col span={24}>
                        <Button disabled={!this.props.HaveAbility('information' ,'write')} type="new" onClick={() => this.patchInformationMenu(null)}>Opslaan</Button>
                        <Link to={`/projects/${this.props.match.params.id}/modules/information/menu`}>
                          <Button>Sluiten</Button>
                        </Link>

                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={24}>
                        <div>
                          <Form onSubmit={this.patchInformationMenu}>
                              <Row gutter={24}>
                                <Col span={18}>
                                  <Card>
                                    <Form.Item required hasFeedback label="Naam">
                                      {getFieldDecorator('name', {
                                        initialValue: this.props.information_menu_item && this.props.information_menu_item.name,
                                        rules: [{ required: true, message: 'Vul een naam in' }],
                                      })(
                                        <Input />
                                      )}
                                    </Form.Item>
                                  </Card>
                                </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
        );
    }
}


const InformationMenuForm = Form.create({ name: 'patch_information_menu_item_form' })(InformationMenuDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.information.isFetching,
      isError : state.information.isError,
      information_menu_item : state.information.information_menu_item,
      project: state.project.project
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInformationMenuItemAction : bindActionCreators(getInformationMenuItemAction, dispatch),
        patchInformationMenuAction : bindActionCreators(patchInformationMenuAction, dispatch),
        addInformationMenuAction : bindActionCreators(addInformationMenuAction, dispatch),
        resetInformationMenuAction : bindActionCreators(resetInformationMenuAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationMenuForm);
