import React, { Component } from 'react';
// import './ProjectsAddressesDossier.css';
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressSharedDataAction, addAddressNoteAction, getAddressNoteAction, resetAddressesNotesAction, patchAddressNoteAction } from '../../../../actions/addressesActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { showSuccess } from '../../../../utils/Notifications'
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { Link } from 'react-router-dom'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Row, Col, Dropdown, Menu, Switch, Button, Input, Modal, Drawer, Divider } from 'antd';

const Search = Input.Search;

const { TextArea } = Input;

const confirm = Modal.confirm;

class ProjectsAddressesDossierNote extends Component {
    state = {
    };

    componentDidMount() {
      this.props.resetAddressesNotesAction();

      if(this.props.match.params.noteid) {
        this.props.getAddressNoteAction(this.props.match.params.addressid, this.props.match.params.noteid);
      }
    }

    handleSubmit = () => {

      // console.log(this.props.form);

      this.props.form.validateFields((errors, values) => {

        // console.log(errors);

        if(!errors) {
          if(this.props.match.params.noteid) {
            this.props.patchAddressNoteAction(this.props.match.params.addressid, this.props.match.params.noteid, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/dossier`);
              showSuccess();
            });
          } else {
            this.props.addAddressNoteAction(this.props.match.params.addressid, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/dossier`);
              showSuccess();
            });
          }
        }
      })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="3" {...this.props}/>

              <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('dossier', 'write')} type="new" onClick={this.handleSubmit}>Opslaan</Button>
                      <Link to={`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/dossier`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>

                  </Col>
                </Row>
                <Divider></Divider>
                  <Row>
                    <Col span={24}>
                      <Form className="ant-form--custom" onSubmit={this.handleSubmit} colon={true}>
                          <Row gutter={24}>
                              <Col span={24}>
                                  <Form.Item hasFeedback label="Onderwerp">
                                      {getFieldDecorator('subject', {
                                        initialValue: this.props.note.subject,
                                         rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                      })(
                                          <Input />
                                       )}
                                  </Form.Item>
                              </Col>
                              <Col span={24}>
                                  <Form.Item required hasFeedback label="Beschrijving">
                                      {getFieldDecorator('note', {
                                        initialValue: this.props.note.note,
                                         rules: [{ required: true, message: 'Vul een beschrijving in'}],
                                      })(
                                         <TextArea rows={4} />
                                      )}
                                  </Form.Item>
                              </Col>
                              <Col span={12}>
                                  <Form.Item label="Tonen op bewonerspagina">
                                      {getFieldDecorator('visible_for_address', {
                                        initialValue: this.props.note.visible_for_address,
                                        valuePropName: 'checked'
                                      })(
                                          <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                          />
                                      )}
                                  </Form.Item>
                              </Col>
                          </Row>
                      </Form>
                    </Col>
                </Row>

          </div>
        </div>
        );
    }
}

const ProjectsAddressesDossierNoteForm = Form.create({ name: 'projects_addresses_dossier_notice' })(ProjectsAddressesDossierNote);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.address.isFetching,
      isError : state.address.isError,
      shareddata: state.address.shareddata,
      note: state.address.note,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getAddressSharedDataAction : bindActionCreators(getAddressSharedDataAction, dispatch),
      addAddressNoteAction : bindActionCreators(addAddressNoteAction, dispatch),
      getAddressNoteAction : bindActionCreators(getAddressNoteAction, dispatch),
      resetAddressesNotesAction : bindActionCreators(resetAddressesNotesAction, dispatch),
      patchAddressNoteAction : bindActionCreators(patchAddressNoteAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesDossierNoteForm);
