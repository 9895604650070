import React from 'react'
import { Steps } from 'antd';

class DeliveryFormSteps extends React.Component {

  onChange = current => {

    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/deliver/delivery-form/${this.props.match.params.form_id}`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/deliver/delivery-form/${this.props.match.params.form_id}/deliveries`);
    }

    if(current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/deliver/delivery-form/${this.props.match.params.form_id}/form`);
    }

    // if(current == 3) {
    //   this.props.history.push(`/projects/${this.props.match.params.id}/deliver/delivery-form/${this.props.match.params.form_id}/points`);
    // }
  }

  render () {
    return (
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Instellingen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Vooropleveringen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Formulier" />
        {/*<Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Opleverpunten" />*/}
      </Steps>
    );
  }
}

export default DeliveryFormSteps;
