export default (state = {isError: false, isFetching: false, partners: [], partner: {}, globalPartners: [], globalPartner: {}}, action) => {
    switch (action.type) {
    case 'PARTNERS_ACTION_FETCH':
    return {
        ...state,
        partners: [],
        globalPartners: [],
        isFetching: true,
        isError: false,
    }
    case 'PARTNER_ACTION_FETCH':
    return {
        ...state,
        partner: {},
        globalPartner: {},
        isFetching: true,
        isError: false,
    }
    case 'PARTNERS_ACTION_SUCCESS':
    return {
        ...state,
        partners: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'GLOBAL_PARTNERS_ACTION_SUCCESS':
    return {
        ...state,
        globalPartners: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SORT_PARTNERS_ACTION_SUCCESS':
    return {
        ...state,
        //partners: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PARTNER_ACTION_SUCCESS':
    return {
        ...state,
        partner: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CLONE_PARTNER_ACTION_SUCCESS':
    return {
        ...state,
        partners: [action.payload, ...state.partners],
        isFetching: false,
        isError: false,
    }
    case 'GET_PARTNER_LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        partner: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_PARTNER_ACTION_SUCCESS':
    return {
        ...state,
        partners: [action.payload, ...state.partners],
        partner: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_PARTNER_ACTION_SUCCESS':
    return {
        ...state,
        // partners: state.partners.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'RESET_PARTNER_ACTION_FETCH':
    return {
        ...state,
        partner: {},
        isFetching: false,
        isError: false,
    }
    case 'SORT_PARTNERS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'PARTNERS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
