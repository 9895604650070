import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, notification, Divider } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addClientAction, resetClientAction, getClientAction, patchClientAction } from '../../../actions/clientsActions';

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';

import { showSuccess, showError } from '../../../utils/Notifications';

import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
import PatchClient from '../Forms/PatchClient';

class ClientDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false,
      selected: [],
      text: '',
      tags: [],
    };
  }

  componentDidMount() {
    this.props.resetClientAction();

    if(this.props.match.params.clientid) {
      this.props.getClientAction(this.props.match.params.clientid);
    }
  }

  handleSubmit = (e, values) => {

    this.clientForm.props.form.validateFields((err, values) => {
      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }
      
      if(this.props.match.params.clientid) {
        this.props.patchClientAction(this.props.match.params.clientid, values).then(() => this.props.history.push(`/library/clients/`));
      } else {
        this.props.addClientAction(values).then(() => this.props.history.push(`/library/clients/`));
      }

      showSuccess();
    });
  }

  render() {

    return (
      <div>
        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': this.props.selectedCustomer.package_id == 3 ? 'Aannemers' : 'Opdrachtgevers', 'link': '/library/clients'}, {'label': this.props.client.name ? this.props.client.name : 'Toevoegen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='3' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                    <Link to={`/library/clients`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Divider></Divider>
            <Row>
              <Col span={24}>
                  <PatchClient wrappedComponentRef={formRef => { this.clientForm = formRef }} {...this.props}/>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.clients.isFetching,
      isError : state.clients.isError,
      client: state.clients.client,
      selectedCustomer: state.auth.selectedCustomer
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    addClientAction : bindActionCreators(addClientAction, dispatch),
    resetClientAction : bindActionCreators(resetClientAction, dispatch),
    getClientAction : bindActionCreators(getClientAction, dispatch),
    patchClientAction : bindActionCreators(patchClientAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
