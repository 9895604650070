import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
} from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';

class ComposeEmailForm extends Component {

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
        <Row gutter={24} >
          <Col span={24}>

            <Form.Item required hasFeedback label="Onderwerp">
              {getFieldDecorator('subject', {
                initialValue: this.props.selectedMethod && this.props.selectedMethod.subject ? this.props.selectedMethod.subject : '',
                rules: [{ required: true, message: 'Vul een onderwerp in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item required label="Bericht">
              { getFieldDecorator('message', {
                initialValue: this.props.selectedMethod && this.props.selectedMethod.message ? this.props.selectedMethod.message : '',
                rules: [{ required: true, message: 'Vul een bericht in' }],
                valuePropName: 'value',
                trigger: 'onEditorChange',
              })(
                <CustomEditor hideVideo placeholders={'correspondence'} project_id={this.props.match.params.id} />
              ) }
            </Form.Item>

          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'compose_mail_form' })(ComposeEmailForm);
