import React, { Component, cloneElement } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getWorkOrderAction, updateWorkOrderAction } from '../../../../actions/workorderActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import {showSuccess} from '../../../../utils/Notifications';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import WorkOrderSidemenu from './WorkOrderSidemenu'
// import './Workorder.css';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Avatar,
  Typography,
} from 'antd';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }

// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     props.moveRow(dragIndex, hoverIndex);
//
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );

class Workorder extends Component {
  state = {
    items:[],
    edit: false,
    // addresses: [],
  };

  componentDidMount() {
    this.props.getWorkOrderAction(this.props.match.params.id).then(() => {
      this.setState({addresses: this.props.addresses});
    });
  }

  fakeSave = () => {
    showSuccess();
  }

  // moveRow = (dragIndex, hoverIndex) => {
  //   const { addresses } = this.state;
  //   const dragRow = addresses[dragIndex];
  //
  //   if(!this.props.HaveAbility('work-order', 'write')) {
  //     showSuccess('U heeft geen rechten om de werkvolgorde te bewerken')
  //     return false;
  //   }
  //
  //
  //   this.setState(
  //     update(this.state, {
  //       addresses: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.addresses.map((v,k) => v.id);
  //
  //   this.props.updateWorkOrderAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
  // };

  // components = {
  //   body: {
  //     row: DragableBodyRow,
  //   },
  // };

  render() {
    const {items} = this.state;

    const columns = [
    {
      key: "sort",
      width: 20
    },
    {
      title: 'Adres',
      dataIndex: 'full_address',
      render: (text, record) => (
        <span>{text}</span>
      )
    }, {
      title: 'Postcode',
      dataIndex: 'zipcode',
      render: (text, record) => (
        <span>{text}</span>
      )
    }, {
      title: 'Plaats',
      dataIndex: 'city',
      render: (text, record) => (
        <span>{text}</span>
      ),
    }, {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        if(text != '0') {
          return (
            text
          );
        }
      }
    }, {
      title: 'Groep',
      dataIndex: 'groups',
      filters: this.props.groups && this.props.groups.map((elem) => {
        return {
          text: elem.name,
          value: elem.name,
        }
      }),
      filterMultiple: true,
      onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
      render: (text, record) =>
      record.groups && record.groups.map((v) => (
        <Tag color={v.color}>{v.name}</Tag>
      ))
    }];

    const onDragEnd = ({ active, over }) => {

      if (active.id !== over.id) {

        var addresses = this.state.addresses;

        const activeIndex = addresses.findIndex((i) => i.id === active.id);
        const overIndex = addresses.findIndex((i) => i.id === over.id);

        this.setState({ addresses: arrayMove(addresses, activeIndex, overIndex) }, () => {

          var sort = this.state.addresses.map((v,k) => v.id);

          this.props.updateWorkOrderAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
        });
      }
    };

    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <WorkOrderSidemenu activeMenu={'1'} id={this.props.match.params.id} {...this.props}/>

          <div gutter={24} type="flex" style={{padding: 24, width: '100%'}}>
            <Row>
            <Col span={24}>
              { // this.props.HaveAbility('work-order', 'write') && this.props.addresses && this.props.addresses.length ? (
                this.props.HaveAbility('work-order', 'write') && this.state.addresses && this.state.addresses.length ? (
                  <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                      // items={this.props.addresses.map((i) => i.id)}
                      items={this.state.addresses.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <CustomTable
                        rowKey='id'
                        columns={columns}
                        // dataSource={this.props.addresses}
                        dataSource={this.state.addresses}
                        components={!this.props.isFetching ? {
                            body: {
                                row: DraggableRow
                            }
                        } : {}}
                        size="middle"
                        loading={this.props.isFetching}
                        scroll={{ y: 600 }}
                        />
                    </SortableContext>
                  </DndContext>
              ) : (
                <CustomTable
                  rowKey='id'
                  size="middle"
                  columns={columns}
                  scroll={{ y: 600 }}
                  dataSource={this.state.addresses}
                  loading={this.props.isFetching}
                  />
              )}

            </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const WorkorderForm = Form.create({ name: 'workorder_settings' })(Workorder);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    addresses: state.workorder.workorder,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkOrderAction : bindActionCreators(getWorkOrderAction, dispatch),
    updateWorkOrderAction : bindActionCreators(updateWorkOrderAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkorderForm);
