import React, { Component } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDocumentAction, resetDocumentAction } from '../../../../../actions/documentsActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import DocumentSteps from '../Steps/DocumentSteps';
import { showSuccess } from '../../../../../utils/Notifications'
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class DocumentAddresses extends Component {

  state = {
    selectedAddressIds: []
  }

  constructor(props) {
    super(props);
    // this.selectaddresses = React.createRef();
  }

  componentDidMount() {

    this.props.resetDocumentAction();

    if(this.props.match.params.documentid) {
      this.props.getDocumentAction(this.props.match.params.documentid).then(() => {
      });
    }
  }

  patchAddresses() {

    // this.selectaddresses.current.share();

    this.myref.share().then(() => {

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/documents`);

        showSuccess('Succesvol opgeslagen');
    });
  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Button disabled={!this.props.HaveAbility('document' ,'write')} type="new" onClick={() => this.patchAddresses()}>Opslaan</Button>
                <Link to={`/projects/${this.props.match.params.id}/modules/documents`}>
                  <Button>Sluiten</Button>
                </Link>
            </div>
          </Col>
        </Row>
        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <DocumentSteps current={1} {...this.props}/>
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {this.props.document.is_public === 0 ? (
              this.props.document.addresses && (
                <SelectAddresses {...this.props} /*forwardedRef={this.selectaddresses}*/ childRef={ref => this.myref = ref} initialAddresses={this.props.document.addresses} objectId={this.props.match.params.documentid} objectType="document" onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds})}/>
              )
            ) : (
              !this.props.isFetching ? (
                <Result
                  icon={<CheckCircleFilled style={{ color: '#52c41a' }} />}
                  title="De informatie staat ingesteld op openbaar, dus iedereen kan het zien."
                />
              ) : null
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.document.isFetching,
    isError : state.document.isError,
    document : state.document.document,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentAction : bindActionCreators(getDocumentAction, dispatch),
    resetDocumentAction : bindActionCreators(resetDocumentAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAddresses);
