export default (state = {isError: false, isFetching: false, contacts: [], libraryContacts: [], contact: {}, libraryContact: {}}, action) => {
    switch (action.type) {
    case 'RESET_CONTACT_ACTION_FETCH':
    return {
        ...state,
        contact: {},
        libraryContact: {},
        isFetching: false,
        isError: false,
    }
    case 'CONTACTS_ACTION_FETCH':
    return {
        ...state,
        contacts: [],
        libraryContacts: [],
        isFetching: true,
        isError: false,
    }
    case 'CONTACTS_ACTION_FETCH':
    return {
        ...state,
        contact: {},
        libraryContact: {},
        isFetching: true,
        isError: false,
    }
    case 'CONTACTS_ACTION_SUCCESS':
    return {
        ...state,
        contacts: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CONTACTS_ACTION_SUCCESS':
    return {
        ...state,
        libraryContacts: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CONTACT_CLONE_SUCCESS':
    return {
        ...state,
        contacts: [action.payload, ...state.contacts],
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CONTACT_ACTION_SUCCESS':
    return {
        ...state,
        libraryContact: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CONTACT_ACTION_SUCCESS':
    return {
        ...state,
        contact: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_CONTACT_ACTION_SUCCESS':
    return {
        ...state,
        contacts: [action.payload, ...state.contacts],
        contact: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CONTACT_REMOVE_SUCCESS':
    return {
        ...state,
        // contacts: state.contacts.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CONTACT_REMOVE_SUCCESS':
    return {
        ...state,
        libraryContacts: state.libraryContacts.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'CONTACTS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
