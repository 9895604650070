export default (state = {
    isError: false,
    isFetching: false,
    forms: [],
    lib_forms: [],
    addresses: [],
    // choice_form: {
    //     attachments: [],
    //     pricing: []
    // },
    settings: {},
    // reminders: [],
    // reminder: {},
    // choice_form_attachment: {},
    // correspondences: [],
    // progress: '... %',
}, action) => {

    switch (action.type) {
    case 'CHOICE_FORMS_ACTION_FETCH':
    return {
        ...state,
        forms: [],
        lib_forms: [],
        isFetching: true,
        isError: false,
    }
    case 'CHOICE_FORM_ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: true,
        isError: false,
    }
    case 'CHOICE_FORM_ACTION_FETCH':
    return {
        ...state,
        form: {},
        choice_form: {},
        isFetching: true,
        isError: false,
    }
    case 'CHOICE_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
        choice_form: {}
    }
    case 'CHOICE_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        forms: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CHOICE_FORM_ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    // case 'CHOICE_FORM_CORRESPONDENCES_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     correspondences: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    case 'CHOICE_FORMS_LIB_ACTION_SUCCESS':
    return {
        ...state,
        lib_forms: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CHOICE_FORM_ACTION_SUCCESS':
    return {
        ...state,
        choice_form: action.payload,
        isFetching: false,
        isError: false,
    }
    // case 'CHOICE_REMINDERS_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     reminders: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    // case 'CHOICE_REMINDER_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     reminder: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    // case 'CHOICE_REMINDER_ADD_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     reminder: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    case 'CHOICE_STATUS_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        //progress: action.payload.progress,
        isFetching: false,
        isError: false,
    }
    // case 'CHOICE_RESEND_TO_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     choice_form: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    case 'CHOICE_ACTION_RESET':
    return {
        ...state,
        form: {},
        forms: [],
        choice_form: {},
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
