import React, { Component } from 'react';
import Media from "react-media";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getPointsAction, searchPointsAction, exportPointsAction, archivePointsAction, resetPointsAction, multiUpdatePointsAction, sendNotificationAction, getUsersAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, getUserFilterAction } from '../../../actions/deliverActions';
import DeliverSideMenu from './DeliverSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Modal, Select, Dropdown, Menu, Drawer, Tooltip } from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import './deliver.css';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import DeliverPoint from './DeliverPointModal';

const { Option } = Select;

class DeliverPointsNotifications extends Component
{
    state = {
        disabled: true,
        edit: true,
        selectedRowKeys: [],
        expandedRowKeys: [],
        is_archive: false,
        show_status_modal: false,
        show_user_modal: false,
        show_export_modal: false,
        can_write: false,
        filtered: false,
        window_width: window.innerWidth
    };

    points = [];

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver' ,'write')
        });

        this.props.resetPointsAction();

        this.setState({ is_archive: this.isArchive() }, () => {
            this.loadData();
        });

        var this_obj = this;

        window.addEventListener('resize', () => { this_obj.handleResize(this_obj); });
    }

    componentWillReceiveProps(nextprops)
    {
        if(this.isArchive() != this.state.is_archive)
        {
            this.setState({ is_archive: !this.state.is_archive }, () => {
                this.loadData();
            });
        }
    }

    handleResize(this_obj)
    {
        this_obj.setState({ window_width: window.innerWidth }, () => {});

        // clearTimeout(this_obj.resize_timer);
        //
        // this_obj.resize_timer = setTimeout(() => {
        //
        //     this_obj.setState({ window_width: window.innerWidth }, () => {
        //
        //         console.log('load data');
        //
        //         this_obj.loadData(false);
        //     });
        // }, 500);

        // this_obj.setState({ window_width: window.innerWidth }, () => { this.loadData(false); });
    }

    calculateSubStringLength(length)
    {
        // return length;

        var window_width = this.state.window_width; // window.innerWidth;
        var width = 1920;

        if(window_width < width)
        {
            if(window_width < 1600)
            {
                return 6;
            }
            else
            {
                return parseInt(length * window_width / width);
            }
        }
        else
        {
            return length;
        }
    }

    isArchive()
    {
        var url = window.location.href;

        url = url.split('/');

        return (url[url.length - 1] == 'archive' ? true : false);
    }

    loadData(reload = true)
    {
        this.props.getPointsAction(this.props.match.params.id, this.state.is_archive).then(() => {

            this.points = JSON.parse(JSON.stringify(this.props.points)); // original

            var flattened_points = [];

            // is sub point selected
            this.points.map((record) => {

                if(record.children)
                {
                    var children = JSON.parse(JSON.stringify(record.children));

                    // delete record.children;
                    record.children = false;

                    flattened_points.push(record);

                    children.map((child_record) => {

                        flattened_points.push(child_record);
                    });
                }
                else
                {
                    flattened_points.push(record);
                }
            });

            this.setState({ points: flattened_points });
        });

        if(reload)
        {
            // filters
            this.props.getUsersAction(this.props.match.params.id, 'all').then((data) => { this.setState({ users: data }); });
            this.props.getDeliveryFilterAction(this.props.match.params.id);
            this.props.getFormFilterAction(this.props.match.params.id);
            this.props.getLocationFilterAction(this.props.match.params.id);
            this.props.getUserFilterAction(this.props.match.params.id);
        }
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });
    }

    toggleArchive(status)
    {
        this.props.resetPointsAction();

        this.setState({ is_archive: status }, () => {

            window.history.pushState({}, null, '/projects/'+this.props.match.params.id+'/deliver/points'+(this.state.is_archive ? '/archive' : ''));

            this.loadData();
        });
    }

    addNewPoint()
    {

    }

    changeUser()
    {
        this.props.form.resetFields();

        this.setState({
            show_status_modal: false,
            show_user_modal: true,
            show_export_modal: false,
            errors: {},
        });
    }

    changeStatus()
    {
        this.props.form.resetFields();

        this.setState({
            show_status_modal: true,
            show_user_modal: false,
            show_export_modal: false,
            errors: {},
        });
    }

    chooseExportFormat()
    {
        this.props.form.resetFields();

        this.setState({
            show_status_modal: false,
            show_user_modal: false,
            show_export_modal: true,
            errors: {},
        });
    }

    sendNotificationTo = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            //if(!errors.user_id)
            {   // ok
                // this.props.sendNotificationAction(this.props.match.params.id, this.state.selectedRowKeys, this.state.user_id, this.state.user).then(() => {
                this.props.sendNotificationAction(this.props.match.params.id, this.state.selectedRowKeys, false, values.user_email).then(() => {

                    showSuccess('Succesvol ingediend');

                    this.loadData();
                });

                // hide & deselect
                this.setState({
                    show_send_notification_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    handleUser = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.user_id)
            {   // ok
                this.props.multiUpdatePointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, assigned_to_delivery_user_id: values.user_id}).then(() => {

                    showSuccess('Succesvol opgeslagen');

                    this.loadData();
                });

                // hide & deselect
                this.setState({
                    show_user_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    handleStatus = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.status)
            {   // ok
                this.props.multiUpdatePointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, status: values.status}).then(() => {

                    showSuccess('Succesvol opgeslagen');

                    this.loadData();
                });

                // hide & deselect
                this.setState({
                    show_status_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    handleExport = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.export_as)
            {   // ok
                this.props.exportPointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, email: values.email}, values.export_as).then(() => {});

                // hide & deselect
                this.setState({
                    show_export_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();

                //
                showSuccess('Succesvol aangevraagd');
            }
        });
    }

    archivePoints()
    {
        this.props.archivePointsAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
            showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));

            this.setState({
                selectedRowKeys: []
            });

            this.loadData();
        });
    }

    getStatusText(status)
    {
        if(status == 'submitted'){ return 'Controleren'; }
        else if(status == 'completed'){ return 'Afgehandeld'; }
        else { return 'Open'; }
    }

    getStatusColor(status)
    {
        // if(status == 'submitted'){ return '#ff8800'; }
        // else if(status == 'completed'){ return '#00ff00'; }
        // else { return '#ff0000'; }

        if(status == 'submitted'){ return 'orange'; }
        else if(status == 'completed'){ return 'green'; }
        else { return 'red'; }
    }

    slidePhoto(point_id, step)
    {
        // disable slider buttons
        this.setState({ disable_slider_click: true });

        // find point
        var point = this.props.points.filter(function(object){ return object.id === point_id })[0];

        // determine nr
        var nr = this.state['photo-nr-' + point_id];

        if(!nr){ nr = 1; } // nr net (yet) set

        var current_nr = nr;

        // direction(s)
        if(step > 0)
        {
            if((nr + step) > point.photos.length){ nr = 1; }
            else{ nr += step; }
        }
        else if(step < 0)
        {
            if((nr - (-1 * step)) < 1){ nr = point.photos.length; }
            else{ nr -= (-1 * step); }
        }

        // update state
        this.setState({ ['photo-nr-' + point_id]: nr });

        // show new photo
        document.getElementById('point-' + point_id + '-photo-' + nr).style.display = 'block';

        // hide old photo
        document.getElementById('point-' + point_id + '-photo-' + current_nr).style.display = 'none';

        // enable slider buttons
        this.setState({ disable_slider_click: false });
    }

    showPhoto(url)
    {
        this.setState({
            show_photo_modal: true,
            photo_url: url,
        });
    }

    sendNotification()
    {
        this.props.sendNotificationAction(this.props.match.params.id, this.state.selectedRowKeys/*, !this.state.is_archive*/).then(() => {
            // showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));
            showSuccess('Notificatie wordt verzonden');

            this.setState({
                selectedRowKeys: []
            });

            this.loadData();
        });

    }

    getDeliveryFilters()
    {
        var filters = [];

        this.props.delivery_filters.map((record) => {

            filters.push({
                text: record.description,
                value: record.id
            });
        });

        return filters;
    }

    getFormsFilters()
    {
        var filters = [];

        this.props.form_filters.map((record) => {

            filters.push({
                text: record.name,
                value: record.id
            });
        });

        return filters;
    }

    getAddressFilters()
    {
        var filters = [];

        this.props.location_filters.map((record) => {

            if(record.type == 'address')
            {
                filters.push({
                    text: record.full_address,
                    value: record.id
                });
            }
        });

        return filters;
    }

    getUserFilters()
    {
        var filters = [];

        this.props.user_filters.map((record) => {

            filters.push({
                text: record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address,
                value: record.id
            });
        });

        return filters;
    }

    showPoint(point_id = false, title = 'Opleverpunt')
    {
        this.setState({
            point_id: point_id,
            point_parent_id: false,
            modal_title: title
        }, () => {

            this.setState({
                point_modal: true
            }, () => {});
        });
    }

    closePoint = (refresh = false) =>
    {
        this.setState({
            point_modal: false
        });
    }

    somethingChanged = () => {}

    applyFilters(filters)
    {
        var filtered = false;

        var filter_keys = Object.keys(/*this.state.*/filters);

        filter_keys.map((key) => {

            if(/*this.state.*/filters[key].length)
            {
                filtered = true;

                return;
            }
        });

        // this.setState({ filtered: filtered });
        this.filtered = filtered;
    }

    render()
    {
        const getResponsiveColumns = smallScreen => columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

        const columns = [
        {
            key: 'subject',
            title: 'Onderwerp',
            fixed: 'left',
            width: 300,
            dataIndex: 'subject',
            render: (text, record) => (
                <span style={{ paddingLeft: record.parent_id ? 24 : 0, display: 'inline-block' }}>
                    <Link
                        onClick={() => { this.showPoint(record.id, record.subject); }}
                        // to={'/projects/'+this.props.match.params.id+'/deliver/modal-point/'+record.id+(record.parent_id ? '/'+record.parent_id : '')}
                        >
                        {text /*(record.parent_id ? '- ' : '')} {(text.length > this.calculateSubStringLength(30) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(30))+'...'}</Tooltip> : text)*/}
                    </Link>
                </span>
            ),
        },
        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   sorter: (a, b) => { return a.status.localeCompare(b.status)},
        //   render: (text, record) => (
        //     /*<div style={{ color: this.getStatusColor(text) }}>{this.getStatusText(text)}</div>*/
        //     <div style={{height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></div>
        //   ),
        //   filters: [
        //     // {
        //     //   text: 'Nieuw',
        //     //   value: 'new',
        //     // },
        //     {
        //       text: 'Open',
        //       value: 'open',
        //     },
        //     {
        //       text: 'Controleren',
        //       value: 'submitted',
        //     },
        //     {
        //       text: 'Afgehandeld',
        //       value: 'completed',
        //     }
        //   ],
        //   onFilter: (value, record) => (record.status === value || (record.sub_status && record.sub_status.includes(value))),
        //   width: 110
        // },
        {
            title: 'Opleverformulier',
            dataIndex: 'delivery_form_name',
            width: 200,
            sorter: (a, b) => { if(!a.delivery_form_name){ return 0; } else if(!b.delivery_form_name){ return 1; } else { return a.delivery_form_name.localeCompare(b.delivery_form_name); }},
            render: (text, record) => (
                <span style={{ display: 'inline-block' }}>
                    {text /*record.parent_id ? '' : (text.length > this.calculateSubStringLength(20) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(20))+'...'}</Tooltip> : text)*/}
                </span>
            ),
            filters: this.getFormsFilters(),
            onFilter: (value, record) => (record.delivery_form_id == value || (typeof record.delivery_form_id == 'array' && record.delivery_form_id.includes(''+value))),
        },
        {
            key: 'description',
            title: 'Vooroplevering',
            width: 300,
            dataIndex: 'delivery', // 'description',
            sorter: (a, b) => { if(!a.location_label){ return 0; } else if (!b.location_label){ return 1; } else { return a.location_label.localeCompare(b.location_label); }},
            render: (text, record) => (
                <span style={{ display: 'inline-block' }}>
                    {text ? (text.length > this.calculateSubStringLength(20) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(20))+'...'}</Tooltip> : text) : ''}
                </span>
            ),
            filters: this.getDeliveryFilters(),
            onFilter: (value, record) => record.delivery_id === value,
        },
        {
            key: 'address_label',
            title: 'Adres',
            width: 200,
            dataIndex: 'address_label',
            sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
            render: (text, record) => (
                <span style={{ display: 'inline-block' }}>
                    {text /*? (text.length > this.calculateSubStringLength(20) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(20))+'...'}</Tooltip> : text) : ''*/}
                </span>
            ),
            // filters: this.getAddressFilters(),
            // onFilter: (value, record) => record.address_id === value,
        },
        {
            title: 'Verantwoordelijke',
            dataIndex: 'user_name',
            width: 200,
            sorter: (a, b) => { if(!a.user_name || Array.isArray(a.user_name)){ return false; }else if(!b.user_name || Array.isArray(b.user_name)){ return true; }else{ return a.user_name.localeCompare(b.user_name); }},
            render: (text, record) => (
                <span style={{ display: 'inline-block' }}>
                    {text /*? (text.length > this.calculateSubStringLength(20) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(20))+'...'}</Tooltip> : text) : ''*/}
                </span>
            ),
            filters: this.getUserFilters(),
            onFilter: (value, record) => (record.assigned_to_delivery_user_id === value || (Array.isArray(record.sub_assigned_to_delivery_user_id) && record.sub_assigned_to_delivery_user_id.includes(value))),
        },
        {
            key: 'due_date',
            title: 'Uiterlijk gereed',
            width: 120,
            dataIndex: 'due_date',
            render: (text, record) => (
                <span style={{color: (record.filter !== 'expired'  ? '#000000' : '#ff0000')}}>
                    {record.expired ? 'Verlopen' : (text ? moment(text).format('DD/MM/YYYY') : 'Niet opgegeven')}
                </span>
            ),
            filters: [
              {
                text: 'Verlopen',
                value: 'expired',
              },
              {
                text: 'Niet verlopen',
                value: 'not-expired',
              },
              {
                text: 'Niet ingesteld',
                value: 'not-set',
              },
            ],
            onFilter: (value, record) => record.filter === value,
        },
        {
            key: 'last_notification_send',
            title: 'Laatste notificatie',
            width: 120,
            dataIndex: 'last_notification_send',
            render: (text, record) => (
                <span style={{color: '#000000'}}>
                    {(text ? moment(text).format('DD/MM/YYYY') : 'Niet handmatig verzonden')}
                </span>
            )
        },
        /*{
            key: 'photos',
            title: 'Laatste foto',
            dataIndex: 'photos',
            width: 140,
            hideOnSmall: true,
            render: (text, record) => (
                <div class="photos">
                    { record.photos.length > 0 ?
                    record.photos.map((photo, index) => {
                    return <div
                        class="photo"
                        id={'point-'+record.id+'-photo-'+(index + 1)}
                        onClick={() => { this.showPhoto(photo.url); }}
                        style={{backgroundImage: 'url('+photo.url+')', backgroundSize: 'cover', display: (index == 0 ? 'block' : 'none')}}
                        ></div>
                    })
                    : null }
                    { record.photos.length > 1 ?
                    <div class="buttons">
                        <a class="left" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, -1); }}}>{'<'}</a>
                        <a class="right" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, 1); }}}>{'>'}</a>
                    </div>
                    : null }
                </div>
            )
        }*/
        {
          title: 'Status',
          width: 110,
          fixed: 'right',
          dataIndex: 'status',
          //sorter: (a, b) => { return a.status.localeCompare(b.status) },
          sorter: (a, b) => { return this.mySorter(a.status, b.status); },
          render: (text, record) => (
            /*<div style={{ color: this.getStatusColor(text) }}>{this.getStatusText(text)}</div>*/
            <span style={{display: 'inline-block', height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></span>
          ),
          filters: [
            // {
            //   text: 'Nieuw',
            //   value: 'new',
            // },
            {
              text: 'Open',
              value: 'open',
            },
            {
              text: 'Controleren',
              value: 'submitted',
            },
            {
              text: 'Afgehandeld',
              value: 'completed',
            }
          ],
          onFilter: (value, record) => (record.status === value || (record.sub_status && record.sub_status.includes(value))),
          align: 'center'
          //width: 110,
          // sortOrder: 'descend',
          // sortDirections: ['ascend', 'descend']
        },
        ];

        const { selectedRowKeys } = this.state;
        const { expandedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected) => { if(selected){ var expandedRowKeys = this.state.expandedRowKeys; expandedRowKeys.push(record.id); this.setState({ expandedRowKeys: expandedRowKeys}); } },
            hideDefaultSelections: true,
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="3"/>

                <div style={{padding: 24, width: '100%'}}>

                    {/*<Row className="mbm" style={{display: (this.state.is_archive ? 'none' : 'block')}}>

                        <Col span={8} className="status-box status-box-open">
                            <div>
                                <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: '#f00', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                                <span class="label">Openstaand</span>
                                <span class="number">{this.props.isFetching ? <Icon type="loading" /> : this.props.nr_of_open}</span>
                            </div>
                        </Col>

                        <Col span={8} className="status-box status-box-submitted">
                            <div>
                                <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: '#f80', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                                <span class="label">Ter controle</span>
                                <span class="number">{this.props.isFetching ? <Icon type="loading" /> : this.props.nr_of_submitted}</span>
                            </div>
                        </Col>

                        <Col span={8} className="status-box status-box-completed">
                            <div>
                                <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: '#0f0', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                                <span class="label">Afgehandeld</span>
                                <span class="number">{this.props.isFetching ? <Icon type="loading" /> : this.props.nr_of_completed}</span>
                            </div>
                        </Col>

                    </Row>*/}

                    <Row className="mbm">
                        <Col span={12}>
                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item disabled={!this.state.can_write} key="1" onClick={() => { this.sendNotification(); }}>Verstuur notificatie direct</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={!this.state.can_write} key="1" onClick={() => { this.setState({ show_send_notification_modal: true }); }}>Verstuur notificatie naar</Menu.Item>
                                  {/*<Menu.Item disabled={!this.state.can_write} key="1" onClick={() => { this.changeUser(); }}>Verantwoordelijke wijzigen</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={!this.state.can_write} key="2" onClick={() => { this.changeStatus(); }}>Status wijzigen</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={!this.state.can_write} key="4" onClick={() => { this.archivePoints(); }}>{this.state.is_archive ? 'Herstellen' : 'Archiveren'}</Menu.Item>*/}
                                </Menu>
                              )} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true }>
                              <Button type="secondary">
                                Acties <DownOutlined />
                              </Button>
                            </Dropdown>
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchPointsAction(this.props.match.params.id, value, this.state.is_archive); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                            />
                            {/*<Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.toggleArchive(true); }}>Archief</Button>
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.toggleArchive(false); }}>Overzicht</Button>*/}

                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ padding: 6, paddingLeft: 0 }}>
                            Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>

                            {/*<Media query="(max-width: 1279px)">
                              {smallScreen => {
                                return (
                                  <CustomTable
                                      rowKey='id'
                                      size="middle"
                                      rowSelection={rowSelection}
                                      columns={getResponsiveColumns(smallScreen)}
                                      dataSource={this.props.points}
                                      loading={this.props.isFetching}
                                  />
                                );
                              }}
                            </Media>*/}

                            { this/*.state*/.filtered == true ?
                            <CustomTable
                                rowKey='id'
                                size="middle"
                                scroll={{ x: 1020 }}
                                style={{ width: (this.state.window_width - 480) }}
                                rowSelection={rowSelection}
                                // expandedRowKeys={this.state.expandedRowKeys}
                                // onExpandedRowsChange={(id) => {
                                //     this.setState({expandedRowKeys: id});
                                // }}
                                columns={columns}
                                dataSource={this.state.points}
                                loading={this.props.isFetching}
                                onChange={(pagination, filters, sorter, extra) => {

                                    // this.setState({ filters: filters}, () => {

                                        this.applyFilters(filters);
                                    // });
                                }}
                                indentSize={0}
                                // rowClassName={(record, index) => {
                                //     return this.filterRecord(record) ? 'display-none' : '';
                                // }}
                                expandedRowKeys={expandedRowKeys}
                                onExpand={(expanded, record) => { var expandedRowKeys = this.state.expandedRowKeys; if(expanded){ expandedRowKeys.push(record.id); }else{ expandedRowKeys = expandedRowKeys.filter(function(value, record){ return record.id === value }); } this.setState({ expandedRowKeys: expandedRowKeys}); }}
                                />
                            :
                            <CustomTable
                                rowKey='id'
                                size="middle"
                                scroll={{ x: 1020 }}
                                style={{ width: (this.state.window_width - 480) }}
                                rowSelection={rowSelection}
                                // expandedRowKeys={this.state.expandedRowKeys}
                                // onExpandedRowsChange={(id) => {
                                //     this.setState({expandedRowKeys: id});
                                // }}
                                columns={columns}
                                dataSource={this.props.points}
                                loading={this.props.isFetching}
                                onChange={(pagination, filters, sorter, extra) => {

                                    // this.setState({ filters: filters}, () => {

                                        this.applyFilters(filters);
                                    // });
                                }}
                                indentSize={0}
                                // rowClassName={(record, index) => {
                                //     return this.filterRecord(record) ? 'display-none' : '';
                                // }}
                                expandedRowKeys={expandedRowKeys}
                                onExpand={(expanded, record) => { var expandedRowKeys = this.state.expandedRowKeys; if(expanded){ expandedRowKeys.push(record.id); }else{ expandedRowKeys = expandedRowKeys.filter(function(value, record){ return record.id === value }); } this.setState({ expandedRowKeys: expandedRowKeys}); }}
                                />
                            }

                        </Col>
                    </Row>

                </div>

                <Modal
                    title="Verstuur notificatie naar"
                    visible={this.state.show_send_notification_modal}
                    onCancel={() => {
                        this.setState({ show_send_notification_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'notification',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Versturen"
                    >
                    <Form
                        id="notification"
                        onSubmit={this.sendNotificationTo}
                        layout="vertical"
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                            <Form.Item
                                label="Geef een e-mailadres op"
                                hasFeedback
                                layout="vertical"
                                >
                                {getFieldDecorator('user_email', {
                                    rules: [{ required: true, message: true }],
                                    initialValue: false,
                                })(
                                <Select
                                    showSearch
                                    //allowClear
                                    placeholder="Geef een e-mailadres op"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSearch={(value) => {
                                        // alert('Search');
                                        if(value)
                                        {
                                            this.setState({ user: value });
                                        }
                                    }}
                                    onBlur={() => {
                                        var users = this.state.users ? this.state.users : [];
                                        var user = this.state.user;

                                        this.setState({ user: '' });

                                        if(user)
                                        {
                                            users.push(user);

                                            this.setState({ users: users }, () => {
                                                this.props.form.setFieldsValue({ user_email: user });
                                            });
                                        }
                                    }}
                                >
                                    { this.state.users && this.state.users.length && this.state.users.map((record) => {
                                        return <Option value={record}>{record}</Option>
                                    }) }
                                    { this.props.users.map((record, index) => {
                                        return <Option value={''+record.email_address}>{record.email_address/*record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address*/}</Option>
                                    }) }
                                </Select>
                                )}
                            </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

                {/*<Modal
                    title="Verantwoordelijke wijzigen"
                    visible={this.state.show_user_modal}
                    onCancel={() => {
                        this.setState({ show_user_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'user',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="user"
                        onSubmit={this.handleUser}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Selecteer een contactpersoon"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('user_id', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een contactpersoon"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        { this.props.users.map((record, index) => {
                                            return <Option value={record.id}>{record.email_address}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>/**/}

                {/*<Modal
                    title="Status wijzigen"
                    visible={this.state.show_status_modal}
                    onCancel={() => {
                        this.setState({ show_status_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'status',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="status"
                        onSubmit={this.handleStatus}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Status"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('status', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een status"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="open">Open</Option>
                                        <Option value="submitted">Controleren</Option>
                                        <Option value="completed">Afgehandeld</Option>
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>/**/}

                {/*<Modal
                    title="Exporteren"
                    visible={this.state.show_export_modal}
                    onCancel={() => {
                        this.setState({ show_export_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'export',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="export"
                        onSubmit={this.handleExport}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Exporteren als"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('export_as', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Select
                                        allowClear
                                        placeholder="Selecteer een format"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="simple">Compacte PDF</Option>
                                        <Option value="extended">Uitgebreide PDF</Option>
                                        <Option value="excel">Excel</Option>
                                    </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Verstuur download link naar">
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Input
                                      placeholder={'e-mail adres'}
                                      value={this.state.export_email}
                                      onChange={(event) => { this.setState({ export_email: event.target.value }); }}
                                      />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>/**/}

                {/*<Modal
                    centered
                    className="large-photo"
                    width="650"
                    footer={null}
                    visible={this.state.show_photo_modal}
                    onCancel={() => { this.setState({ show_photo_modal: false }); }}
                    >
                    <div onClick={() => { this.setState({ show_photo_modal: false }); }} style={{width: '100%', textAlign: 'center'}}>
                        <img src={this.state.photo_url} style={{maxWidth: '600px', maxHeight: '600px'}} />
                    </div>
                </Modal>/**/}

                { this.state.point_modal ? <Drawer
                    title={this.state.modal_title}
                    placement={'right'}
                    className="point-details"
                    width={parseInt(window.width / 3)}
                    headerStyle={{ position: 'absolute', width: '100%', zIndex: 9999 }}
                    bodyStyle={{ paddingTop: 70 }}
                    visible={this.state.point_modal}
                    onClose={() => { this.closePoint(true); }}
                    >
                    <DeliverPoint
                        project_id={this.props.match.params.id}
                        point_id={this.state.point_id}
                        point_parent_id={this.state.point_parent_id}
                        leaflet={true}
                        subpoint={false}
                        closePoint={this.closePoint}
                        somethingChanged={this.somethingChanged}
                        refreshData={this.refreshData}
                        readonly={true}
                        />
                </Drawer> : null }

            </div>
        );
    }
}

const DeliverPointsNotificationsForm = Form.create({ name: 'point' })(DeliverPointsNotifications);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        points : state.deliver.points,
        nr_of_open : state.deliver.nr_of_open,
        nr_of_submitted : state.deliver.nr_of_submitted,
        nr_of_completed : state.deliver.nr_of_completed,
        users : state.deliver.users,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        location_filters : state.deliver.location_filters,
        user_filters : state.deliver.user_filters
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPointsAction : bindActionCreators(getPointsAction, dispatch),
        searchPointsAction : bindActionCreators(searchPointsAction, dispatch),
        exportPointsAction : bindActionCreators(exportPointsAction, dispatch),
        archivePointsAction : bindActionCreators(archivePointsAction, dispatch),
        resetPointsAction : bindActionCreators(resetPointsAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        getUserFilterAction : bindActionCreators(getUserFilterAction, dispatch),
        multiUpdatePointsAction : bindActionCreators(multiUpdatePointsAction, dispatch),
        sendNotificationAction : bindActionCreators(sendNotificationAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverPointsNotificationsForm);
