import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HaveRole } from '../../../utils/RolesAndAbilities'
import { getProjectsAction, searchProjectsAction, addProjectAction, deleteProjectsAction, statusProjectsAction } from '../../../actions/projectActions';
import { getCustomersAction } from '../../../actions/customerActions';
import { getAccountsAction } from '../../../actions/accountActions';
import HasRole from '../../../components/HasRole/HasRole';
import { getClientsAction } from '../../../actions/clientsActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Drawer,
  Select,
  message,
  Card,
  Tooltip,
  Switch,
} from 'antd';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader/PageHeader';

const Option = Select.Option;

class AddProject extends React.Component {

  componentDidMount()
  {
    this.props.getAccountsAction();

    this.props.getClientsAction();

    this.props.getCustomersAction();
  }

  handleSubmit = e => {

    this.props.form.validateFields((err, values) => {

      if (!err) {

        // merge e-mail(s)
        if(Array.isArray(values.mail_occupants_messages_to))
        {
          values.mail_occupants_messages_to = values.mail_occupants_messages_to.join(',');
        }

        this.props.addProjectAction(values).then(() => {

          this.props.history.push(`/projects/${this.props.project.id}/general`);

          showSuccess();
        });

      }

    });
  };

  render () {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>

      <PageHeader path={[{'label': 'Projecten', 'link': '/projects'}, {'label': 'Toevoegen'}]} />

      <Row className="mbm">
        <Col span={12}>
          <div>
            <Button type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
            <Link to={`/projects`}>
              <Button>Sluiten</Button>
            </Link>
          </div>
        </Col>
      </Row>

      <Card>

        <Form className="ant-form--custom" layout={'vertical'} colon={true}>

          <Row gutter={24}>

            <Col span={10}>
              <Form.Item hasFeedback label="Projectnaam">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Vul een projectnaam in' }],
                })(
                  <Input></Input>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item hasFeedback label="Interne naam">
                {getFieldDecorator('internal_name', {
                  // rules: [{ required: true, message: 'Vul een interne naam in' }],
                })(
                  <Input></Input>
                )}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item required hasFeedback label="Projectnummer">
                {getFieldDecorator('project_number', {
                  rules: [{ required: true, message: 'Vul een projectnummer in' }],
                })(
                  <Input></Input>
                )}
              </Form.Item>
            </Col>

            {/*<Col span={12}>
              { this.props.HaveRole(['super-admin']) ? (
                <Form.Item required label="Aangemaakt door bedrijf" hasFeedback>
                  {getFieldDecorator('customer_id', {
                    // initialValue: this.props.project.customer_id,
                    rules: [{ required: true, message: 'Selecteer een bedrijf' }],
                  })(
                    <Select
                      placeholder="Selecteer een bedrijf"
                      showSearch
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                      >
                      {this.props.customers.map((v, k) => (
                        <Option key={v.id} value={v.id}>{v.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : null }
            </Col>*/}

              {this.props.HaveRole(['super-admin']) ? (
              <Col span={12}>
                <Form.Item required label="Aangemaakt door" hasFeedback>
                  {getFieldDecorator('user_id', {
                    rules: [{ required: true, message: 'Selecteer een account' } ],
                  })(
                    <Select
                      // loading={this.props.isAccountsFetching}
                      showSearch
                      placeholder="Selecteer een account"
                      optionFilterProp="children"
                      >
                      {this.props.accounts.map((v, k) => (
                        <Select.Option key={v.id} value={v.id}>{v.email}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              ) : null}

              <Col span={12}>
                <Form.Item label={this.props.selectedCustomer.package_id == 3 ? 'Aannemers' : 'Opdrachtgever'} hasFeedback>
                  {getFieldDecorator('client_id', {
                    // initialValue: this.props.project.client_id,
                  })(
                    <Select
                      allowClear
                      showSearch
                      placeholder="Selecteer een opdrachtgever"
                      optionFilterProp="children"
                      >
                      {this.props.clients.map((v, k) => (
                        <Option key={v.id} value={v.id}>{v.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                  <Form.Item required label={(
                    <span>
                    Meldingen project mailen naar
                    <Tooltip className="mhs" title='Vul hier een of meerdere e-mailadressen in van degenen die een melding mogen ontvangen over activiteiten in het project. Activiteiten: nieuw bericht, enquete ingevuld, afspraak gemaakt of gewijzigd. Meerdere e-mailadressen scheiden met een komma (, ).'>
                      <InfoCircleTwoTone />
                    </Tooltip>
                    </span>
                  )} hasFeedback>
                    {getFieldDecorator('mail_occupants_messages_to', {
                      // initialValue: this.props.project.mail_occupants_messages_to.split(','),
                      rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                    })(
                      <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({mail_occupants_messages_to: value.join(',')}); }}></Select>
                    )}
                  </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item required label={(
                  <span>
                  Type project
                  <Tooltip className="mhs" title='Als een project op Privé ingesteld staat, krijgen alle adressen een persoonlijke inlog. Wanneer er wordt gekozen voor Openbaar, zal de informatie van het project openbaar zijn. Een project kan alleen openbaar gemaakt worden als er een eigen app gebruikt wordt en of een specifiek (sub)domein is ingesteld. Vraag bij Cobee naar de mogelijkheden hiervoor.'>
                    <InfoCircleTwoTone />
                  </Tooltip>
                  </span>
                )} hasFeedback>
                      {getFieldDecorator('is_public', {
                          initialValue: '0',
                      })(
                          <Select
                              placeholder="Type project"
                          >
                                <Select.Option value="0">Privé</Select.Option>
                                {/*<Select.Option disabled={(this.props.selectedCustomer.project_domain == null) ? true : false} value="1">Openbaar</Select.Option>*/}
                                <Select.Option disabled={(this.props.selectedCustomer.project_domain == null && !this.props.selectedCustomer.has_app) ? true : false } value="1">Openbaar</Select.Option>
                          </Select>
                      )}
                  </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Project verbergen voor bewoners:">
                    {getFieldDecorator('hide_project_for_occupants', {
                      valuePropName: 'checked',
                      // initialValue: this.props.project.hide_project_for_occupants
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    )}
                </Form.Item>
              </Col>

          </Row>

        </Form>

      </Card>

      </div>
    );
  }

}

const AddProjectForm = Form.create({ name: 'projects' })(AddProject);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isCustomersFetching : state.customer.isFetching,
    isAccountsFetching : state.account.isFetching,
    isError : state.project.isError,
    project: state.project.project,
    customers: state.customer.customers,
    accounts: state.account.accounts,
    clients: state.clients.clients,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectsAction : bindActionCreators(getProjectsAction, dispatch),
    addProjectAction : bindActionCreators(addProjectAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    deleteProjectsAction : bindActionCreators(deleteProjectsAction, dispatch),
    statusProjectsAction : bindActionCreators(statusProjectsAction, dispatch),
    searchProjectsAction : bindActionCreators(searchProjectsAction, dispatch),
    getClientsAction : bindActionCreators(getClientsAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectForm);
