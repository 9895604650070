import React, { Component } from 'react';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'

import { v1 as uuidv1 } from 'uuid'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Timeline } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../utils/Notifications';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageHeader from '../../../../components/PageHeader/PageHeader';
import DeliveryFormSteps from './Steps';

import DeliverSideMenu from '../DeliverSideMenu';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)


class DeliveryFormStatus extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount()
  {
  }

  handleSubmit = (e, values, redirect) => {

  }

  preview()
  {
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <DeliverSideMenu project_id={this.props.match.params.id} activeMenu='7' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Link to={`/projects/${this.props.match.params.id}/deliver/delivery-forms`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliveryFormSteps current={3} {...this.props} />
                    </Card>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Col span={24}>
                    <h2>Handtekening(en)</h2>

                    <div style={{ float: 'left', marginRight: 20 }}>
                        Omschrijving 1:<br />
                        <img src="" style={{ width: 200, height: 100 }} />
                    </div>

                    <div style={{ float: 'left', marginRight: 20 }}>
                        Omschrijving 2:<br />
                        <img src="" style={{ width: 200, height: 100 }} />
                    </div>
                  </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>

                        <h2 style={{marginTop: 24}}>Log</h2>

                        <Timeline style={{marginTop: 24}}>
                            <Timeline.Item><RenderHTML HTML={('01/01/2023 10:00 uur - Log bericht')} /></Timeline.Item>
                            <Timeline.Item><RenderHTML HTML={('01/01/2023 11:00 uur - Log bericht')} /></Timeline.Item>
                            <Timeline.Item><RenderHTML HTML={('01/01/2023 12:00 uur - Log bericht')} /></Timeline.Item>
                        </Timeline>

                        { /*this.props.log.length > 0 ?
                        <Timeline style={{marginTop: 24}}>
                          {
                              this.props.log.map((object, key) => {
                                  return (<Timeline.Item><RenderHTML HTML={(moment(object.created_at).format('DD/MM/YYYY HH:mm')  + ' uur - ' + object.message)} /></Timeline.Item>);
                              })
                          }
                        </Timeline> : null }

                        {
                            !this.props.log.length ?
                        <p>Er staat nog niets op de tijdlijn.</p>
                            : null/**/
                        }

                    </Col>
                </Row>

                <Row gutter={24}>

                  <Col span={12}>
                    <Form.Item label="Verstuur formulier per e-mail">
                        <Input
                          type="email"
                          placeholder={'E-mailadres'}
                          value={this.state.email_address}
                          onChange={(event) => { this.setState({email_address: event.target.value}); }}
                          style={{width: '100%'}}
                        />
                        <Button onClick={() => { /*this.emailForm();*/ }}>Verstuur formulier</Button>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Download formulier">
                      <Button onClick={() => { /*this.downloadForm();*/ }}>Download formulier</Button>
                    </Form.Item>
                  </Col>

                </Row>

            </div>

          </div>

      </div>
    );
  }
}


const DeliveryFormStatusForm = Form.create({ name: 'delivery_form_points' })(DeliveryFormStatus);

const mapStateToProps = (state, ownProps) => {

  return {

    isFetching : state.intake.isFetching,
    isError : state.intake.isError,
    intake_form : state.intake.intake_form,
  };
}

const mapDispatchToProps = (dispatch) => {

  return {

    HaveAbility:  bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormStatusForm);
