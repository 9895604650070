import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid'
import SavePesetModal from '../Modals/SavePresetModal'
import IntakeSteps from '../Steps/IntakeSteps'
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getIntakeByIdAction, exportIntakeResultsAsZip, changeStatusIntakeFromsAction, requestDownloadIntake, multiDeleteResults, searchIntakeFormsAddresses } from '../../../../actions/intakeActions';
import { showSuccess } from '../../../../utils/Notifications';

import moment from 'moment';
import 'moment/locale/nl';

import { API_URL } from '../../../../constants/settings'
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

const Search  = Input.Search;

class ProjectIntakeResults extends React.Component {

  state = {
    selectedRowKeys: [],
    downloadIntake: false,
    emailIntakeTo: '',
    can_write: false,
    form_results: []
  };

  status = {
      'new': 'Nog niet ingediend',
      'open': 'Open',
      'send': 'Ingediend',
      //'denied': 'Afgekeurd',
      //'approved': 'Goedgekeurd',
      'completed': 'Afgerond'
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('intake', 'write')
      });

    this.loadIntakeResults();
  }

  loadIntakeResults()
  {
      this.setState({selectedRowKeys: []});

      this.props.getIntakeByIdAction(this.props.match.params.intakeid, true).then(() => {
          this.setState({ form_results: this.props.intake_form.results });
      });
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  exportResults = () => {

      this.props.exportIntakeResultsAsZip(this.props.match.params.intakeid, this.state.selectedRowKeys);
      // window.open(API_URL + '/intake/form/'+ this.props.match.params.intakeid +'/export/?token=' + localStorage.getItem('authToken'), '_blank');
  }

  onSearch(value) {
    this.props.searchIntakeFormsAddresses(this.props.match.params.intakeid, value).then((data) => {
        // this.setState({ form_results: this.props.intake_form.results });
        this.setState({ form_results: [] });
        this.setState({ form_results: data });
        // console.log(data);
    });
  }

  multiDeleteResults()
  {
      // modal
      // this.props.multiDeleteResults(this.props.match.params.intakeid, { 'ids': this.state.selectedRowKeys }).then(() => {
      //     this.props.getIntakeByIdAction(this.props.match.params.intakeid);
      //
      //     showSuccess('Succesvol verwijderd');
      //
      //     this.setState({ selectedRowKeys: [] });
      // });



          Modal.confirm({
              title: 'Weet je zeker dat je deze items wilt verwijderen? Hiermee kunnen de ingevulde formulieren niet meer bekeken worden.',
              content: 'Klik op JA om deze actie te bevestigen.',
              okText: 'Ja',
              okType: 'danger',
              cancelText: 'Nee',
              onOk: () => {
                  return new Promise((resolve, reject) => {
                      this.props.multiDeleteResults(this.props.match.params.intakeid, { 'ids': this.state.selectedRowKeys }).then(() => {

                          // this.props.getIntakeByIdAction(this.props.match.params.intakeid);

                          this.loadIntakeResults();

                          showSuccess('Succesvol verwijderd');

                          // this.setState({ selectedRowKeys: [] });

                          resolve();
                      });
                  }).catch(() => console.log('Oops errors!'));
              },
              onCancel() {},
          });

  }

  downloadIntakeAsZip()
  {
      // direct download
      // this.props.downloadDossier(this.props.match.params.id,
      //   {
      //     ids: [this.props.match.params.addressid]
      //   }).then(() => {
      //
      //   });

      // modal
      this.props.requestDownloadIntake(this.props.match.params.intakeid, { 'email': this.state.emailIntakeTo, 'ids': this.state.selectedRowKeys });

      showSuccess('Succesvol verzonden');

      this.setState({ selectedRowKeys: [] });
  }

  //exportImageResults = () => {
      // window.open(API_URL + '/intake/form/'+ this.props.match.params.intakeid +'/export/images/?token=' + localStorage.getItem('authToken'), '_blank');
  //}

  changeStatus = (status) => {
    //if(this.state.selectedStatus) {
      this.props.changeStatusIntakeFromsAction(this.props.match.params.intakeid, status, this.state.selectedRowKeys).then(() => {
        showSuccess();
        this.loadIntakeResults();
        this.setState({selectedStatus: null, modalVisible: false});
      });
    //}
  }

  render () {

    const SubMenu = Menu.SubMenu;

    const columns = [{
        title: 'Adres',
        dataIndex: 'address_label',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${this.props.match.params.id}/intake/${this.props.match.params.intakeid}/result/${record.id}`}>
              {text}
            </Link>
          </span>
        ),
        sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Gebruiker',
        dataIndex: 'user_name',
        render: (text, record) => (
          <span>
              {text}
          </span>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => (
          <span style={{color: record.status == '' || (record.status != 'open' && record.status != 'send' && record.status != 'completed') ? '#ff0000' : (record.status != 'completed' ? '#ff8800' : '#00ff00')}}>
            {this.status[text] ? this.status[text] : 'Nog niet ingediend'}
          </span>
        ),
        sorter: (a, b) => {
          if(a.status == undefined) {
            a.status = '';
          }

          if(b.status == undefined) {
            b.status = '';
          }

          // console.log(a.status);
          // return null
          return a.status.length - b.status.length
          // return a.status.localeCompare(b.status)
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Datum ingediend',
      dataIndex: 'submitted_on',
      render: (text, record) => {
        return (text ?
          <span>
            { moment(text).format('DD/MM/YYYY - HH:mm') }
          </span> : 'onbekend'
        )
      },
      sorter: (a, b) => { return moment(a.submitted_on).unix() - moment(b.submitted_on).unix()},
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Datum goedgekeurd',
      dataIndex: 'approved_on',
      render: (text, record) => {
        return (text ?
          <span>
            { moment(text).format('DD/MM/YYYY - HH:mm') }
          </span> : 'onbekend'
        )
      },
      sorter: (a, b) => { return moment(a.approved_on).unix() - moment(b.approved_on).unix()},
      sortDirections: ['descend', 'ascend'],
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      getCheckboxProps: record => ({
        disabled: !record.id
      }),
      selections: [
        {
          key: 'all-data',
          text: 'Selecteer alles',
          onSelect: () => {
            // console.log(this.state.form_results);
            this.setState({
              selectedRowKeys: this.state.form_results.map(value => value.id),
            });
          },
        },
        {
          key: 'all-data',
          text: 'Deselecteer alles',
          onSelect: () => {
            this.setState({
              selectedRowKeys: [],
            });
          },
        },
      ],
    };

    return (
      <div>
          {/* buttons */}
          <Row className="mbm">
            <Col span={12}>
              <div>
                <Link to={`/projects/${this.props.match.params.id}/intake`}>
                  <Button>Sluiten</Button>
                </Link>

                <Dropdown trigger={['click']} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                    <Menu>
                        <SubMenu disabled={!this.state.can_write} title="Status wijzigen">
                          <Menu.Item key="1" onClick={() => this.changeStatus('open')}>Open</Menu.Item>
                          <Menu.Item key="2" onClick={() => this.changeStatus('send')}>Ingediend</Menu.Item>
                          <Menu.Item key="3" onClick={() => this.changeStatus('completed')}>Afgerond</Menu.Item>
                        </SubMenu>
                        {/*<Menu.Item key="2" onClick={() => { this.setState({ downloadIntake: true }); }}>
                            Exporteren
                        </Menu.Item>*/}
                        <Menu.Item disabled={!this.state.can_write} key="5" onClick={() => { this.multiDeleteResults(); }} style={{ color: '#f5222d' }}>
                            Verwijderen
                        </Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary" disabled={!this.state.can_write} style={{marginLeft: 8}}>
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>

                {/*<Button type="secondary" style={{marginLeft: 8}} onClick={() => { this.setState({ downloadIntake: true }); }}>Exporteren</Button>*/}

              </div>
            </Col>
            <Col span={12}>
              <Search
                placeholder="Zoeken..."
                allowClear
                onSearch={value => this.onSearch(value)}
                style={{ width: 200, float: 'right' }}
                />
            </Col>
          </Row>

          {/* steps */}
          <Row className="mbm">
            <Col span={24}>
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <IntakeSteps current={2} {...this.props}/>
              </Card>
            </Col>
          </Row>

          {/*<Row className="mbm">
            <Col span={24}>
              <Search
                placeholder="Zoeken..."
                allowClear
                onSearch={value => this.onSearch(value)}
                style={{ width: 200, float: 'right' }}
                />
            </Col>
          </Row>*/}

          {/* form */}
          <Row className="mbm">
            <Col span={24}>
              <CustomTable
                // rowKey='id'
                rowKey={(record) => {
                  if(record.id) {
                    return record.id;
                  } else {
                    return false;
                  }
                }}
                size="middle"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.state.form_results}
                loading={this.props.isFetching}
              />
            </Col>
          </Row>

          <Modal
            title="Exporteer resultaten"
            visible={this.state.downloadIntake}
            onOk={() => {
                this.downloadIntakeAsZip();
                this.setState({ downloadIntake: false });
            }}
            onCancel={() => this.setState({downloadIntake: false})}
            okText={'Exporteren'}
          >
            <Form layout={'vertical'}>
              <p>
              Cobee genereert op de achtergrond een ZIP bestand met de resultaten
van de geselecteerde adressen. Vul hier een of meerdere e-mailadressen naar
waar je de geëxporteerde opname resultaten wenst te versturen.
              </p>
              <Form.Item label="E-mailadres(sen)">
                {/*<Input onChange={(e) => this.setState({emailIntakeTo: e.target.value})} />*/}
                <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({emailIntakeTo: value.join(',')}); }}></Select>
              </Form.Item>
            </Form>
          </Modal>
        </div>
    );
    }

  }

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        intake_form : state.intake.intake_form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getIntakeByIdAction : bindActionCreators(getIntakeByIdAction, dispatch),
        exportIntakeResultsAsZip : bindActionCreators(exportIntakeResultsAsZip, dispatch),
        changeStatusIntakeFromsAction : bindActionCreators(changeStatusIntakeFromsAction, dispatch),
        requestDownloadIntake : bindActionCreators(requestDownloadIntake, dispatch),
        multiDeleteResults : bindActionCreators(multiDeleteResults, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
        searchIntakeFormsAddresses: bindActionCreators(searchIntakeFormsAddresses, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntakeResults);
