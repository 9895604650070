import React, { Component } from 'react';
// import '../Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerAction, syncCustomerModulesAction, getCustomerBilling } from '../../../actions/customerActions';
import { showSuccess } from '../../../utils/Notifications';
import CustomTable from '../../../components/CustomTable/CustomTable';
import CustomerSideMenu from './CustomerSideMenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, DatePicker } from 'antd';
import PageHeader from '../../../components/PageHeader/PageHeader';
import moment from 'moment';
const { Title } = Typography;

const columns = [
  {
    title: 'Datum',
    width: 150,
    dataIndex: 'submission_date',
    sorter: (a, b) => { return moment(a.submission_date).unix() - moment(b.submission_date).unix()},
    render: (index, record) => (moment(record.submission_date, 'YYYY-MM-DD').format('DD-MM-YYYY')),
    render: (text, index) => {
      return (
        <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>

      )
    }
  },{
    title: 'Project',
    dataIndex: 'project_name',
  },{
    title: 'Onderwerp',
    dataIndex: 'correspondence_subject',
  },{
  },{
    title: 'Brieven',
    dataIndex: 'nr_of_mails',
  },{
  },{
    title: 'Brief per mail',
    dataIndex: 'nr_of_email_mails',
  },{
  },{
    title: 'Email',
    dataIndex: 'nr_of_emails',
  },{
  },{
    title: 'Sms',
    dataIndex: 'nr_of_sms_messages',
  },{
  },{
    title: 'Push',
    dataIndex: 'nr_of_push_notifications',
  },{
    title: 'Prijs',
    width: 100,
    dataIndex: 'total_price',
    render: (text, index) => {
      return (
        <span>€ {text}</span>

      )
    }
  }
];

class CustomerBilling extends Component {
  state = {
    edit: true,
    billing: [],
    selectedData: null,
  };

  componentDidMount() {

  }

  getBilling = (month) => {
    //console.log();
    this.setState({selectedData: month});
    this.props.getCustomerBilling(moment(month).format('YYYY-MM')).then(() => {
      this.setState({billing: this.props.billing});
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <PageHeader path={[{'label': 'Klanten', 'link': '/customers'}, {'label': this.props.customer.name}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <CustomerSideMenu id={this.props.match.params.id} activeMenu='4' />
          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={12} >
                <Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={24}>
                <DatePicker mode={'month'} onPanelChange={(month) => this.getBilling(month)} style={{marginBottom: 30}} />
                <CustomTable
                  size="middle"
                  rowKey='id'
                  rowSelection={null}
                  columns={columns}
                  dataSource={this.state.billing.filter((element) => element.customer_id == this.props.match.params.id && element.total_price != null)}
                  />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const CustomerBillingForm = Form.create({ name: 'customer_modules' })(CustomerBilling);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer: state.customer.customer,
    modules: state.customer.modules,
    billing: state.customer.billing,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    getCustomerBilling : bindActionCreators(getCustomerBilling, dispatch),
    syncCustomerModulesAction : bindActionCreators(syncCustomerModulesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBillingForm);
