import React from 'react'
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'
import { v1 as uuidv1 } from 'uuid'
import FormSteps from './FormSteps'
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Switch, Tag, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../utils/Notifications';
import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormAction, resetFormAction, downloadFormEntryAction, unlockFormsAction } from '../../../../actions/formActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';
import { API_URL } from '../../../../constants/settings';
import FormSideMenu from '../FormSideMenu';

const confirm = Modal.confirm;

class FormResults extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      can_write: false,
      selectedRowKeys: [],
    };
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('form', 'write')
      });

      // this.props.getFormAction(this.props.match.params.form_id).then(() => {});
      this.loadData();
  }

  loadData()
  {
      this.props.getFormAction(this.props.match.params.form_id).then(() => {});
  }

  viewFormEntry(id) {
      window.open(API_URL + '/form/' + this.props.match.params.form_id + '/result/' + id  + '/pdf?token=' + localStorage.getItem('authToken'));
  }

  downloadFormEntry(id) {
      // this.props.downloadFormEntryAction(this.props.match.params.form_id, id);
      window.open(API_URL + '/form/' + this.props.match.params.form_id + '/result/' + id  + '/zip?token=' + localStorage.getItem('authToken'));
  }

  onSelectChange = (selectedRowKeys) => {

      this.setState({ selectedRowKeys });
  }

  unlockForms()
  {
      confirm({
        title: this.state.selectedRowKeys.length > 1 ?
            'Wil je deze formulieren openzetten om te bewerken?'
            :
            'Wil je dit formulier openzetten om te bewerken?',
        content: this.state.selectedRowKeys.length > 1 ?
            'Als je op JA klikt, worden de formulieren weer opgezet om te bewerken. Hou er rekening mee dat de formulieren opnieuw getekend moeten worden als deze al getekend zijn.'
            :
            'Als je op JA klikt, wordt het formulier weer opgezet om te bewerken. Hou er rekening mee dat het formulier opnieuw getekend moeten worden als deze al getekend is.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {

            return new Promise((resolve, reject) => {

                this.props.unlockFormsAction(this.props.match.params.form_id, this.state.selectedRowKeys).then(() => {

                    resolve();

                    showSuccess('Succesvol opengezet om te bewerken');

                    this.setState({selectedRowKeys:[]});

                    this.loadData();
                });
            }).catch(() => {});
        },
        onCancel() {},
      });

      if(this.state.selectedId)
      {
        this.props.duplicateCloneAction(this.state.selectedId, 'project', this.props.match.params.id).then(() => {

          this.setState({selectedId: null, modalVisible: false});

          this.props.getFormsAction(this.props.match.params.id);

          showSuccess('Succesvol toegevoegd');
        });
      }
  }

  render () {

    const { selectedRowKeys } = this.state;

    const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        // hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.onSelectChange(this.props.address.map(value => value.id));
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.onSelectChange([]);
            },
          },
        ],
    };

    const columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.address.full_address.localeCompare(b.address.full_address)},
        render: (text, record) => { return record.address ? record.address.full_address : 'Onbekend' }
    },
    {
        title: 'Ingediend',
        dataIndex: 'updated_at',
        render: (text, record) => { return (record.status == 'completed' || record.status == 'signed') ? moment(text).format('DD/MM/YYYY - HH:mm') : 'Nee' }
    },
    {
        title: 'Formulier',
        dataIndex: 'id',
        render: (text, record) => {
            return <div style={{float: 'right'}}>
                <Button type="primary" onClick={() => { this.viewFormEntry(record.id); }}>Bekijken</Button>
                &nbsp;
                <Button type="primary" onClick={() => { this.downloadFormEntry(record.id); }}>Downloaden</Button>
            </div>
        }
    }];

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <FormSideMenu id={this.props.match.params.id} activeMenu="1"/>

        <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">

              <Col span={12}>

                <div>
                  <Link to={`/projects/${this.props.match.params.id}/forms`}>
                    <Button>Sluiten</Button>
                  </Link>

                  <Dropdown trigger={['click']} disabled={ (this.state.selectedRowKeys.length > 0 ? false : true) || !this.props.HaveAbility('form', 'write') } overlay={(
                      <Menu>
                        <Menu.Item disabled={this.state.selectedRowKeys.length < 1} onClick={() => this.unlockForms()} key="6">Openzetten om te bewerken</Menu.Item>
                      </Menu>
                    )}
                    >
                    <Button style={{ marginLeft: 10 }} type="secondary">
                      Acties <DownOutlined />
                    </Button>
                  </Dropdown>

                </div>

              </Col>
            </Row>

            {/* steps */}
            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <FormSteps current={3} {...this.props}/>
                </Card>
              </Col>
            </Row>

            <Row gutter={24} className="mbm" style={{display: (this.props.match.params.form_id ? 'block' : 'none')}}>
              <Col span={24}>
                <CustomTable
                  rowKey='id'
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={this.props.form_details.results}
                  loading={this.props.isFetching}
                  />
              </Col>
            </Row>

        </div>
      </div>
    );
  }
}


const FormResultsForm = Form.create({ name: 'addresses_form' })(FormResults);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    form_details : state.forms.form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAction : bindActionCreators(getFormAction, dispatch),
    resetFormAction : bindActionCreators(resetFormAction, dispatch),
    downloadFormEntryAction : bindActionCreators(downloadFormEntryAction, dispatch),
    unlockFormsAction : bindActionCreators(unlockFormsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormResultsForm);
