import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { API_URL } from '../../constants/settings';

class Refresh extends Component {

  componentDidMount() {

      localStorage.clear();

      // document.location.href = '/login?v=' + Date.now(); // fix for not rebuilding correct local storage
      document.location.href = (this.props.selectedCustomer && this.props.selectedCustomer.login_url_admins ? 'http'+(API_URL.search('https://') != -1 ? 's': '')+'://'+this.props.selectedCustomer.login_url_admins : '/login') + '?v=' + Date.now(); // fix for not rebuilding correct local storage
  }

  render() {

      return null;
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedCustomer: state.auth.selectedCustomer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Refresh);
