import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, notification, Divider, Switch } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storeLibraryAction, getLibraryAction, patchLibraryAction, resetLibraryAction, getLibraryCategoriesAction } from '../../../actions/librariesActions';
// import './LibraryDetail.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import CustomEditor from '../../../components/CustomEditor/CustomEditor';
import LibrarySideMenu from '../LibrarySideMenu';
import LibraryMenu from '../Menu/LibraryMenu';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

class LibraryDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      tags: [],
    };
  }

  componentDidMount() {

    this.props.resetLibraryAction();

    this.props.getLibraryCategoriesAction('text');

    if(this.props.match.params.id)
    {
      this.props.getLibraryAction(this.props.match.params.id);
    }
  }

  getCategoryOptions()
  {
      var categories = [];

      this.props.categories && this.props.categories.map((category, index) => {

          categories.push({
              label: ''+category.name,
              value: ''+category.id
          });
      });

      return categories;
  }

  getSelectedCategoryOptions()
  {
      var categories = [];

      this.props.library && this.props.library.category_ids && this.props.library.category_ids.map((category_id, index) => {

          this.props.categories && this.props.categories.map((category, index) => {

              if(category.id == category_id)
              {
                  // categories.push(''+category.name);
                  categories.push(''+category.id);
              }
          });

      });

      return categories;
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {
      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      if(this.props.match.params.id) {
        this.props.patchLibraryAction(this.props.match.params.id, values).then(() => {
          showSuccess();
          this.props.history.push(`/library`);
        });
      } else {
        this.props.storeLibraryAction(values).then(() => {
          showSuccess();
          this.props.history.push(`/library`);
        });
      }

      // showSuccess();
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Losse teksten', 'link': '/library'}, {'label': this.props.library.title ? this.props.library.title : 'Toevoegen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='1' />

            <div style={{padding: 0, display: 'flex', width: '100%'}}>

                <LibraryMenu activeMenu='1' />

                <div style={{padding: 24, width: '100%'}} colon={true}>

                    <Row className="mbm">
                      <Col span={12}>
                        <div>
                          <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>

                          <Link to={`/library`}>
                            <Button>Sluiten</Button>
                          </Link>
                        </div>

                      </Col>
                    </Row>
                    <Divider></Divider>
                    <Row>
                      <Col span={24}>
                        <Form onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
                          <Form.Item required label="Titel">
                            {getFieldDecorator('title', {
                              initialValue: this.props.library.title,
                              rules: [{ required: true, message: 'Vul een titel in' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>

                          <Form.Item hasFeedback label="Categorieën">
                            {getFieldDecorator('categories', {
                                // initialValue: this.props.mediafile && this.props.mediafile.category_ids ? this.props.mediafile.category_ids : [],
                                initialValue: this.getSelectedCategoryOptions(),
                                validateTrigger: ["onChange"]
                            })(
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Geef één of meerdere categorieën op"
                                >
                              { this.getCategoryOptions().map((v, k) => (
                                  <Select.Option key={String(v.value)}>{v.label}</Select.Option>
                              )) }
                              </Select>
                            )}
                          </Form.Item>

                          <Form.Item required label={'Bericht'}>
                            { getFieldDecorator('content', {
                              initialValue: this.props.library.content,
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              rules: [{'required': true, 'message': 'Vul een bericht in'}]
                            })(
                              <CustomEditor hideLibraryText placeholders={'correspondence'} project_id={false} customer_id={localStorage.getItem('customerId')} />
                            ) }
                          </Form.Item>

                          {/*<Form.Item label="Categorie">
                            {getFieldDecorator('tags', {
                              initialValue: this.props.library.tags,
                            })(
                              <Select mode="multiple" style={{ width: '100%' }} placeholder="Selecteer een categorie">
                                <Select.Option key='Email'>Email</Select.Option>
                                <Select.Option key='Push notification'>Push notification</Select.Option>
                                <Select.Option key='Brief'>Brief</Select.Option>
                                <Select.Option key='Sms'>Sms</Select.Option>
                              </Select>
                            )}
                          </Form.Item>*/}

                          { this.props.selectedCustomer.id == 1 && this.props.HaveRole(['super-admin']) ?
                          <Form.Item label="Gebruik voor demo project">
                            {getFieldDecorator('use_for_demo', {
                                valuePropName: 'checked',
                                initialValue: this.props.library && this.props.library.use_for_demo ? true : false
                            })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    />
                            )}
                          </Form.Item> : null }

                        </Form>

                      </Col>
                    </Row>

                </div>

          </div>

        </div>

      </div>
    );
  }
}

const LibraryDetailForm = Form.create({ name: 'horizontal_login' })(LibraryDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.library.isFetching,
    isError : state.library.isError,
    library: state.library.library,
    categories : state.library.categories,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLibraryAction : bindActionCreators(getLibraryAction, dispatch),
    patchLibraryAction : bindActionCreators(patchLibraryAction, dispatch),
    resetLibraryAction : bindActionCreators(resetLibraryAction, dispatch),
    storeLibraryAction : bindActionCreators(storeLibraryAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryDetailForm);
