import React, { Component } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Button, Popconfirm, Tag, Typography } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Cluster extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: this.props.selected ? this.props.selected : [],

      filteredInfo: null,
    };

  }

  componentDidMount() {

  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys }, () => {
      this.props.onSelect(selectedRowKeys);
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  render() {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: 'Adres',
        dataIndex: 'full_address',
      },{
        title: 'Postcode',
        dataIndex: 'zipcode',
      },{
        title: 'Plaats',
        dataIndex: 'city',
      }, {
          title: 'Groep',
          dataIndex: 'groups',
          filters: this.props.groups && this.props.groups.map((elem) => {
            return {
              text: elem.name,
              value: elem.name,
            }
          }),
          filterMultiple: true,
          // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
          onFilter: (value, record) => this.groupsFilter(value, record),
          render: (text, record) =>
            record.groups.map((v) => (
                <Tag color={v.color} key={v.id}>{v.name}</Tag>
          ))
      }
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange : this.onSelectChange,
    };

    return (
      <Table
        rowKey='id'
        size='middle'
        title={() => (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <span style={{marginLeft: 24}}>
              <Typography.Title level={4} >Cluster {this.props.index + 1} </Typography.Title>
              {this.state.selectedRowKeys.length} / {this.props.addresses.length} adressen geselecteerd
            </span>
            <Popconfirm
              title="Weet je het zeker？"
            icon={<QuestionCircleOutlined style={{ color: '#f5222d' }} />}
              onConfirm={this.props.onDeleteCluster}
              okText="Ja"
              cancelText="Nee"
            >
              <Button type='link' style={{ color: '#f5222d' }}>verwijder</Button>
            </Popconfirm>
          </div>
        )}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={this.props.addresses.filter((element) => {

          if(this.state.selectedRowKeys.includes(element.id)) {
            return true;
          }

          return !this.props.exclude.includes(element.id)
        })}
        scroll={{ y: 240 }}
        pagination={false}
        style={{backgroundColor: 'white', borderRadius: '5px', border: '1px solid #e8e8e8', marginBottom: 24 }}
        onChange={(pagination, filters, sorter, extra) => { this.handleTableChange(pagination, filters, sorter); }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    address : state.address.addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cluster);
