import api from '../utils/api'

export const resetCorrespondencesAction = () => dispatch => {

  dispatch({
    type: 'CORRESPONDENCES_ACTION_RESET'
  })
}

export const getCorrespondencesAction = (projectid) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCES_ACTION_FETCH'
  })

  return api('get','/correspondences/'+projectid, null, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const duplicateCorrespondencesAction = (correspondenceid) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCES_ACTION_FETCH'
  })

  return api('post','/correspondence/'+correspondenceid+'/clone/', null, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCES_CLONE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const getCorrespondenceAction = (correspondenceid) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCES_ACTION_FETCH'
  })

  return api('get','/correspondence/'+correspondenceid, null, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const getCorrespondenceAddressesAction = (projectid, id, type) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ADDRESSES_ACTION_FETCH'
  })

  return api('get','/correspondences/'+projectid+'/addresses-and-occupants/'+ (id && type ? type + '/' + id : ''), null, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const searchCorrespondenceAddressesAction = (projectid, id, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ACTION_FETCH'
  })

  return api('post','/correspondences/'+projectid+'/addresses-and-occupants/search/' + id, params, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const addCorrespondenceAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ACTION_FETCH'
  })

  return api('post','/correspondences/'+projectid+'/new', params, true).then((response) => {
    dispatch({
      type: 'ADD_CORRESPONDENCE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const patchCorrespondenceAction = (correspondenceid, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ACTION_FETCH'
  })

  return api('post','/correspondence/'+correspondenceid, params, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_SUCCESS',
      payload: response.data.correspondence
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const patchOnStationeryAction = (correspondenceid, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_PATCH_ACTION_FETCH'
  })

  return api('post','/correspondence/'+correspondenceid+'/on-stationery', params, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_PATCH_ACTION_SUCCESS',
      payload: response.data.correspondence
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_PATCH_ACTION_ERROR',
    })
    return false;
  });
}

// for configuring send method (global and or for specific address and or occupant)
export const setupCorrespondenceMethodsAction = (correspondenceid, params, files = []) => dispatch => {

  // console.log(files);

  var files_remapped = {};

  files.map((file, index) => {
      files_remapped['pdf_attachment['+index+']'] = file;
  });

  dispatch({
    type: 'SET_CORRESPONDENCE_METHOD_ACTION_FETCH'
  })

  return api('post','/correspondence/'+correspondenceid+'/method', params, true, files_remapped).then((response) => {
    dispatch({
      type: 'SET_CORRESPONDENCE_METHOD_ACTION_SUCCESS',
      payload: response.data
    });
    return response;
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}
export const setCorrespondenceMethodAction = (method) => dispatch => {

  dispatch({
    type: 'SET_CORRESPONDENCE_METHOD_ACTION_SUCCESS',
    payload: method,
  })

}

// send / schedule correspondence to occupants and or addresses
export const sendCorrespondenceAction = (correspondenceid, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ACTION_FETCH'
  })

  return api('post','/correspondence/'+correspondenceid+'/send', params, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

// preview => /api/correspondence/message/$id/preview

// download => /api/correspondence/$id/download

export const removeCorrespondencesAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ACTION_FETCH'
  })

  return api('post','/correspondences/'+projectid+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_REMOVE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const previewCorrespondencesAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'CORRESPONDENCE_ACTION_FETCH'
  })

  return api('post','/correspondences/'+projectid+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CORRESPONDENCE_ACTION_ERROR',
    })
    return false;
  });
}

export const searchCorrespondenceAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_ACTION_FETCH'
    })

    return api('post','/correspondences/'+projectid+'/search', params, true).then((response) => {
        dispatch({
            type: 'CORRESPONDENCES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CORRESPONDENCE_ACTION_ERROR',
        })
        return false;
    });
}

export const requestDownloadMethod = (correspondence_id, method, sub_method = false, params) => dispatch => {

    // dispatch({
    //     type: 'CORRESPONDENCE_ACTION_FETCH'
    // })

    return api('post', '/correspondence/' + correspondence_id + '/request-download/' + method + (sub_method ? '/' + sub_method : ''), params, true).then((response) => {
        // dispatch({
        //     type: 'CORRESPONDENCE_ACTION_SUCCESS',
        //     payload: response.data
        // });

        return true;
    }).catch((e) => {
        // dispatch({
        //     type: 'CORRESPONDENCE_ACTION_ERROR',
        // })
        return false;
    });
}

export const requestDownloadLetters = (correspondence_id, params) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_DOWNLOAD_LETTERS_ACTION_FETCH'
    })

    return api('post','/correspondence/'+correspondence_id+'/request-download', params, true).then((response) => {
        dispatch({
            type: 'CORRESPONDENCE_DOWNLOAD_LETTERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CORRESPONDENCE_DOWNLOAD_LETTERS_ACTION_ERROR',
        })
        return false;
    });
}

export const getPriceCorrespondenceAction = (correspondenceid) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_ACTION_FETCH',
    })

    return api('get','/correspondence/' + correspondenceid + '/total_price', null, true).then((response) => {
        dispatch({
            type: 'CORRESPONDENCES_PRICE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CORRESPONDENCE_ACTION_ERROR',
        })
        return false;
    });
}

export const getCorrespondenceSummaryAction = (correspondenceid) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_ACTION_FETCH',
    })

    return api('get','/correspondence/' + correspondenceid + '/summary', null, true).then((response) => {
        dispatch({
            type: 'CORRESPONDENCES_SUMMARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CORRESPONDENCE_ACTION_ERROR',
        })
        return false;
    });
}

export const sendTestEmailAction = (correspondenceid, values) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_SEND_TEST_EMAIL_ACTION_FETCH',
    });

    return api('post','/correspondence/' + correspondenceid + '/preview/email', values, true).then((response) => {

        dispatch({
            type: 'CORRESPONDENCE_SEND_TEST_EMAIL_ACTION_SUCCESS',
            // payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'CORRESPONDENCE_SEND_TEST_EMAIL_ACTION_ERROR',
        });

        return false;
    });
}

export const resetCorrespondenceAction = () => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_RESET_ACTION_FETCH'
    })

}

export const cancelCorrespondenceAction = (correspondenceid, ids, action = false) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_ACTION_FETCH',
    })

    return api('post','/correspondence/' + correspondenceid + '/summary/cancel' + (action ? '/' + action : ''), {ids: ids}, true).then((response) => {
        dispatch({
            type: 'CORRESPONDENCE_ACTION_SUCCESS',
            // payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CORRESPONDENCE_ACTION_ERROR',
        })
        return false;
    });
}

export const unlockCorrespondenceAction = (correspondenceid) => dispatch => {

    dispatch({
        type: 'CORRESPONDENCE_ACTION_FETCH',
    })

    return api('post','/correspondence/' + correspondenceid + '/summary/unlock', null, true).then((response) => {
        dispatch({
            type: 'CORRESPONDENCE_UNLOCK_ACTION_SUCCESS',
            // payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CORRESPONDENCE_ACTION_ERROR',
        })

        return false;
    });
}

export const checkPlaceholderAction = (correspondenceid, method, address_id = null, placeholder, abortcontroller) => (dispatch) => {

    // dispatch({
    //     type: 'CORRESPONDENCE_ACTION_FETCH',
    // })

    return api('post','/correspondence/' + correspondenceid + (address_id ? '/' + address_id : '') + '/'+ method + '/check/placeholder', {
        placeholder: placeholder,
        signal: abortcontroller.signal
    }, true).then((response) => {
        // dispatch({
        //     type: 'CORRESPONDENCES_PRICE_ACTION_SUCCESS',
        //     payload: response.data
        // });

        return response.data;
    }).catch((e) => {
        // dispatch({
        //     type: 'CORRESPONDENCE_ACTION_ERROR',
        // })
        return false;
    });
}
