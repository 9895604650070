import api from '../utils/api'

export const getGroupsAction = (params) => dispatch => {

    dispatch({
        type: 'GROUPS_ACTION_FETCH'
    })

    return api('get','/groups/'+params.projectId, null, true).then((response) => {
        dispatch({
            type: 'GROUPS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'GROUPS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchGroupsAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'GROUPS_ACTION_FETCH'
    })

    return api('post','/groups/'+projectid+'/search/', params, true).then((response) => {
        dispatch({
            type: 'GROUPS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'GROUPS_ACTION_ERROR',
        })
        return false;
    });
}

export const getGroupAction = (id) => dispatch => {

    dispatch({
        type: 'GROUPS_ACTION_FETCH'
    })

    return api('get','/group/'+id, null, true).then((response) => {
        dispatch({
            type: 'GROUP_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'GROUPS_ACTION_ERROR',
        })
        return false;
    });
}

export const addGroupsAction = (projectId, params) => dispatch => {

    dispatch({
        type: 'GROUPS_ACTION_FETCH'
    })

    return api('post','/groups/'+projectId, params, true).then((response) => {
        dispatch({
            type: 'ADD_GROUP_ACTION_SUCCESS',
            payload: response.data.group
        });
    }).catch((e) => {
        dispatch({
            type: 'GROUPS_ACTION_ERROR',
        })
        return false;
    });
}

export const patchGroupsAction = (group_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'GROUPS_ACTION_FETCH'
    })

    return api('post','/group/'+group_id, params, true).then((response) => {
        dispatch({
            type: 'GROUP_ACTION_SUCCESS',
            payload: response.data.group
        });
    }).catch((e) => {
        dispatch({
            type: 'GROUPS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetGroupAction = () => (dispatch, getState) => {

    dispatch({
        type: 'RESET_GROUP_ACTION'
    })
}

export const syncAddressesGroupAction = (projectId, params) => dispatch => {

    dispatch({
        type: 'GROUPS_ACTION_FETCH'
    })

    return api('post','/addresses/'+projectId+'/syncaddresses', params, true).then((response) => {
        dispatch({
            type: 'SYNC_ADDRESSES_GROUP_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'GROUPS_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteGroupsAction = (id, params) => dispatch => {
    return api('post','/groups/'+ id +'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'DELETE_GROUPS_ACTION_SUCCESS',
            payload: response.data
        });
        return true;
    }).catch((e) => {
        // dispatch({
        //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
        // })
        return false;
    });
}
