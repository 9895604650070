import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Button,
    Drawer,
    Divider,
    Tag,
    Modal,
    Upload,
    Input,
    Avatar,
    Typography,
    Tooltip,
} from 'antd';

class PatchSurvey extends Component {

    render()
    {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form className={'ant-form--custom'} onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
                <Row gutter={24} >
                    <Col span={8}>
                        <Form.Item required hasFeedback label="Name">
                              {getFieldDecorator('name', {
                                  initialValue: this.props.survey.name,
                                  rules: [{ required: true, message: 'Vul een naam in.' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                </Row>
                {/**}<Row type="flex" justify="end" align="middle">
                    <Button style={{marginRight: 8}} onClick={() => this.setState({visible: false})}>Annuleren</Button> <Button htmlType="submit" type="primary">Opslaan <Icon type="plus" /></Button>
                </Row>**/}
            </Form>
        );
    }
}

export default Form.create({ name: 'patch_survey_form' })(PatchSurvey);
