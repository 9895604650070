import React, { Component } from 'react';
// import './AppLayout.css'
import { connect } from 'react-redux';
import Sidenav from '../../components/Sidenav/Sidenav';
import Topnav from '../../components/Topnav/Topnav';
import PageHeader from '../../components/PageHeader/PageHeader';
import { API_URL, JOB_URL, OCCUPANTS_URL } from '../../constants/settings';

import {
  Layout, Button
} from 'antd';

const { Content } = Layout;

class AppLayout extends Component {

  render() {

    var meta_tags = document.getElementsByTagName("meta");

    var csp_meta_tag_found = false;
    var robots_meta_tag_found = false;

    if(meta_tags)
    {
        for(let meta_tag of meta_tags)
        {
            if(meta_tag.getAttribute('http-equiv') == 'Content-Security-Policy')
            {
                csp_meta_tag_found = true;
            }

            if(meta_tag.getAttribute('name') == 'robots')
            {
                robots_meta_tag_found = true;
            }
        }
    }

    if(!csp_meta_tag_found)
    {
        var whitelisted_domains = [
            new URL(API_URL).hostname,
            new URL(JOB_URL).hostname,
            new URL(OCCUPANTS_URL).hostname,
            'localhost',
            'localhost:3000',
            '*.ngrok-free.app',
            '*.youtube.com',
            '*.cobee.nl',
            'ams3.digitaloceanspaces.com',
            'cobee.ams3.digitaloceanspaces.com',
            'cobeestorage.ams3.digitaloceanspaces.com',
            'staging.cobeestorage.ams3.digitaloceanspaces.com',
            '*.googleapis.com',
            '*.gstatic.com',
            '*.google-analytics.com',
            '*.googletagmanager.com',
            '*.hotjar.com',
            '*.hotjar.io',
            '*.helpscout.net',
            '*.cloudfront.net',
            '*.tiny.cloud',
            '*.tinymce.com',
            '*.dhtmlx.com',
            'overpass-api.de',
            'unpkg.com',
            '*.google.com',
            '*.sentry.io',
            '*.ingest.sentry.io',
            'sp.tinymce.com',
            'o1076784.ingest.sentry.io',
            'scribehow.com'
        ].join(' ');

        document.head.innerHTML += '<meta http-equiv="Content-Security-Policy" content="default-src \'none\'; script-src \'self\' \'unsafe-eval\' '+whitelisted_domains+'; connect-src \'self\' blob: '+whitelisted_domains+'; worker-src \'self\' '+whitelisted_domains+' data: blob:; img-src \'self\' '+whitelisted_domains+' data: blob:; style-src \'self\' \'unsafe-inline\' '+whitelisted_domains+'; font-src \'self\' '+whitelisted_domains+'; manifest-src \'self\' '+whitelisted_domains+'; frame-src \'self\' '+whitelisted_domains+';" />';
    }

    if(!robots_meta_tag_found)
    {
        document.head.innerHTML += '<meta name="robots" content="noindex,nofollow" />';
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Topnav sidebar={this.props.sidebar}></Topnav>
          <Layout style={{marginTop: 64}}>
            {this.props.sidebar ? (<Sidenav projectid={this.props.component.props.match.params.id} />) : null}
            <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>

              {this.props.sidebar ? (
                <PageHeader path={[{'label': 'Projecten', 'link': '/projects'}, {'label': this.props.project.name, 'link': ''}]} />
              ) : null}

              {this.props.component}

            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
