import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCorrespondencesAction, removeCorrespondencesAction, setupCorrespondenceMethodsAction, addCorrespondenceAction, searchCorrespondenceAction, duplicateCorrespondencesAction } from '../../../actions/correspondencesActions';
// import './ProjectsCorrespondence.css';
import CustomTable from '../../../components/CustomTable/CustomTable';
import AddCorrespondenceForm from './Form/AddCorrespondence';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Drawer,
  Steps,
  Radio,
  message,
  Typography,
  Switch,
  Divider,
} from 'antd';
import { Link } from 'react-router-dom';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { showSuccess } from '../../../utils/Notifications';

import moment from 'moment';
import 'moment/locale/nl';

const columns = [{
  title: 'Onderwerp',
  dataIndex: 'subject',
  sorter: (a, b) => { return a.subject.localeCompare(b.subject)},
  sortDirections: ['descend', 'ascend'],
  render: (text, record) => (
    <span>
      <Link to={`/projects/${record.project_id}/correspondence/${record.id}/${(record.status == 'configuring' ? 'setup' : 'status')}/`}>
        {record.subject}
      </Link>
    </span>
  )
}, {
  title: 'Laatst verstuurd',
  dataIndex: 'updated_at',
  render: (text, record) => {
    if(record.status == 'send' || record.status == 'sending') {
    return (
      <span>
        { moment(text).format('DD/MM/YYYY - HH:mm') }
      </span>
    ) }
  },
  sorter: (a, b) => { return moment(a.scheduled_for).unix() - moment(b.scheduled_for).unix()},
  sortDirections: ['descend', 'ascend'],
}, {
  title: 'Datum aangemaakt',
  dataIndex: 'created_at',
  render: (text, record) => {
    return (
      <span>
        { moment(text).format('DD/MM/YYYY - HH:mm') }
      </span>
    )
  },
  sorter: (a, b) => { return moment(a.created_at).unix() - moment(b.created_at).unix()},
  sortDirections: ['descend', 'ascend'],
}, {
  title: 'Status',
  dataIndex: 'status',
  render: (text, record) => {
    if(record.status == 'configuring') {
      return "Opmaken";
    }
    if(record.status == 'configured') {
      return "Ingepland";
    }
    if(record.status == 'sending') {
      return "Bezig met verzenden";
    }
    if(record.status == 'send') {
      return "Verzonden";
    }
    if(record.status == 'error') {
      return "Fout";
    }
    if(record.status == 'cancelled') {
      return "Geannuleerd";
    }
  }
}, {
  title: 'Verzendwijze',
  dataIndex: 'contactPreference',
}];

function onChange(pagination, sorter) {

}


const Search = Input.Search;

const { Title } = Typography;


class ProjectsCorrespondence extends Component {

  state = {
    drawerVisible: false,
    selectedRowKeys: [],
    disable_delete_selected: false,
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.getCorrespondencesAction(this.props.match.params.id);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  deleteSelected = () => {
    Modal.confirm({
      title: 'Weet je het zeker dat je de geselecteerde correspondentie wilt verwijderen?',
      content: '',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.props.removeCorrespondencesAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
            resolve();
            showSuccess('Succesvol verwijderd');
            this.setState({selectedRowKeys: []})
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {
      },
    });
  }

  cloneSelected = () => {
    this.props.duplicateCorrespondencesAction(this.state.selectedRowKeys[0]).then(() => {

      showSuccess('Succesvol gedupliceerd');

      this.setState({selectedRowKeys: []});

      this.props.getCorrespondencesAction(this.props.match.params.id);
    });
  }

  onSearch(value) {
    this.props.searchCorrespondenceAction(this.props.match.params.id, {'search': value});
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });

    // selected record.status == 'sending' || record.status == 'send' => disable delete
    var disable_delete_selected = false;

    this.props.correspondences && this.props.correspondences.map((correspondence) => {

        selectedRowKeys.map((correspondence_id) => {

            if(correspondence.id == correspondence_id && (correspondence.status == 'sending' || correspondence.status == 'send'))
            {
                disable_delete_selected = true;
            }

        });
    });

    this.setState({ disable_delete_selected: disable_delete_selected });
  }

  handleSubmit = (e) => {

    e.preventDefault();

    this.addCorrespondenceform.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.addCorrespondenceAction(this.props.match.params.id, values);
        this.addCorrespondenceform.props.form.resetFields();
        this.setState({drawerVisible: false});
      }
    });
  }

  render() {

    const { current } = this.state;

    const { selectedRowKeys } = this.state;

    const rowSelection = {

      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      // getCheckboxProps: (record) => ({
      //     disabled: record.status == 'sending' || record.status == 'send' ? true : false
      // }),
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12} >
            <Link to="correspondence/add">
              <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new" icon={<PlusOutlined />}>Toevoegen</Button>
            </Link>
            {this.props.HaveAbility('correspondence', 'write') ? (
              <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                  <Menu>
                    <Menu.Item disabled={this.state.selectedRowKeys.length > 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                    <Menu.Divider></Menu.Divider>
                    <Menu.Item disabled={!this.props.HaveAbility('correspondence', 'delete') || this.state.disable_delete_selected == true} onClick={() => this.deleteSelected()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button  type="secondary">
                  Acties <DownOutlined />
              </Button>
            </Dropdown>
          ) : null}
        </Col>
        <Col span={12}>
          <Search
            allowClear
            placeholder="Zoeken..."
            onSearch={value => this.onSearch(value)}
            style={{ width: 200, float: 'right' }}
            />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            rowKey="id"
            size="middle"
            rowSelection={rowSelection}
            onChange={onChange}
            columns={columns}
            loading={this.props.isFetching}
            dataSource={this.props.isFetching ? [] : this.props.correspondences}
            />
        </Col>
      </Row>
    </div>
    );
}
}

const CorrespondenceForm = Form.create({ name: 'correspondence_form' })(ProjectsCorrespondence);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondences: state.correspondence.correspondences
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondencesAction : bindActionCreators(getCorrespondencesAction, dispatch),
    removeCorrespondencesAction : bindActionCreators(removeCorrespondencesAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    searchCorrespondenceAction : bindActionCreators(searchCorrespondenceAction, dispatch),
    duplicateCorrespondencesAction : bindActionCreators(duplicateCorrespondencesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CorrespondenceForm);
