import React from 'react'
import { Card, Row, Col, Button, notification } from 'antd'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ComposeSMSForm from '../../Form/ComposeSMSForm';
import { setCorrespondenceMethodAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, resetCorrespondenceAction } from '../../../../../actions/correspondencesActions';


class ComposeSMS extends React.Component {

  state = {
    method: {},
    loading: true
  };

  componentDidMount() {

    this.props.resetCorrespondenceAction(); //.then(() => {

        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

            var selected_method = this.props.correspondence.methods.find((element) => {

              if(this.props.match.params.addressid) {

                if(element.type === 'sms' && element.address_id == this.props.match.params.addressid) {

                  return true;
                }
              } else {

                if(this.props.match.params.occupantid) {

                  if(element.type === 'sms' && element.occupant_id == this.props.match.params.occupantid) {

                    return true;
                  }
                } else {

                  if(element.type === 'sms' && element.address_id == null && element.occupant_id == null) {

                    return true;
                  }
                }

              }
            });

            this.props.setCorrespondenceMethodAction(selected_method); // .then(() => {

            this.setState({ loading: false });
            //});
        });
    //});
  }

  saveMethod = (redirect = true) => {

    this.composeMailForm.props.form.validateFields((errors, values) => {

      if(this.props.match.params.occupantid) {

        values = { ...values, 'occupant_id': this.props.match.params.occupantid };
      }

      if(!errors){ errors = []; }

      var message = values.message;
      message = message.replace(/(<([^>]+)>)/gi, "");

      if(!message){

          errors['message'] = 'Het SMS bericht is leeg. Geef een SMS bericht op.';
      }
      else if(message.length > 1200)
      {
          errors['message'] = 'Het SMS bericht is te lang. Deze mag maximaal 1200 tekens bevatten.';
      }

      if(!errors.length && !errors['message']) {

        this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {...values, 'type':'sms' }).then(() => {

          if(redirect)
          {
              this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/4`);
          }
        });
      }
      else
      {
          notification.error({
            message: 'Foutmelding',
            description: errors['message'],
            // icon: null,
            placement: 'topRight',
          });
      }
    })
  }

  render () {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>

              <Button loading={this.props.isFetching} type="new" onClick={this.saveMethod}>Opslaan</Button>

              <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/4`}>
                <Button>Sluiten</Button>
              </Link>
            </div>

          </Col>
        </Row>
        <Row>
          <Card>
            { !this.state.loading ? <ComposeSMSForm
                wrappedComponentRef={formRef => { this.composeMailForm = formRef }}
                onSave={() => this.saveMethod(false)}
                { ...this.props }
                /> : null }
          </Card>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    selectedMethod : state.correspondence.selectedMethod,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    setCorrespondenceMethodAction : bindActionCreators(setCorrespondenceMethodAction, dispatch),
    resetCorrespondenceAction : bindActionCreators(resetCorrespondenceAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeSMS);
