import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
  Switch,
} from 'antd';

class AddSurvey extends Component {

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
        <Row gutter={16} >
          <Col span={16}>
            <Form.Item required hasFeedback label="Naam">
              {getFieldDecorator('name', {
                initialValue: this.props.survey && this.props.survey.name,
                rules: [{ required: true, message: 'Vul een titel in' }],
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
              <Form.Item label="Openbaar">
                  {getFieldDecorator('is_public', {
                      valuePropName: 'checked',
                      initialValue: this.props.survey.is_public ? true : false
                  })(
                  <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                  />
                  )}
              </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'add_survey_form' })(AddSurvey);
