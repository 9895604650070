import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../SupportBaseSideMenu';
import SupportBaseReminderSteps from './SupportBaseReminderSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Upload,
    Input,
    Select,
    Switch,
    Button,
    Drawer,
    Popconfirm,
    message,
    Divider,
    Steps,
    Slider,
    PageHeader,
    Tag,
    Result,
    Modal,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { checkSupportBaseReminderLetterAction, checkSupportBaseReminderDocumentsAction, checkSupportBaseReminderEmailsAction, scheduleSupportBaseReminderToAction, getSupportBaseReminderAction } from '../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class SupportBaseReminderCheck extends React.Component {

  state = {
      emails_email: this.props.auth.user.email,
      can_write: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      this.props.getSupportBaseReminderAction(this.props.match.params.reminder_id).then(() => {});
  }

  patchInformationAddresses() {}

  scheduleReminder()
  {
      Modal.confirm({
        title: 'Weet je het zeker?',
        content: 'Als je op JA klikt, wordt je actie uitgevoerd.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            this.props.scheduleSupportBaseReminderToAction(this.props.match.params.reminder_id).then(() => {
                showSuccess('Succesvol ingepland');
                this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminders`);
            });
        },
        onCancel() {},
      });
  }

  checkDocument()
  {
      this.props.checkSupportBaseReminderLetterAction(this.props.match.params.reminder_id);
  }

  checkDocuments()
  {
      if(this.validateEmail(this.state.documents_email))
      {
          this.props.checkSupportBaseReminderDocumentsAction(this.props.match.params.reminder_id, this.state.documents_email).then(() => {

              showSuccess('Verzoek verzonden');

              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminders`);

              // this.setState({ documents_email: '' });
          });
      }
      else
      {
          showSuccess('Vul een geldig e-mailadres in', '', '', '#ff0000');
      }
  }

  checkEmails()
  {
      if(this.validateEmail(this.state.emails_email))
      {
          this.props.checkSupportBaseReminderEmailsAction(this.props.match.params.reminder_id, this.state.emails_email).then(() => {

              showSuccess('Verzoek verzonden');

              this.setState({ show_check_emails_modal: false });
          });
      }
      else
      {
          showSuccess('Vul een geldig e-mailadres in', '', '', '#ff0000');
      }
  }

  validateEmail(string)
  {
      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  render () {

    const columns = [{
        title: 'Type',
        dataIndex: 'type',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aantal',
        dataIndex: 'nr_of',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Prijs per stuk',
        dataIndex: 'price_per_item',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Totaal',
        dataIndex: 'total',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Facturatie',
        dataIndex: 'invoice',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    }];

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="3"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write} type="new" onClick={() => this.scheduleReminder()}>Opslaan</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/reminders`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseReminderSteps current={3} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Controle correspondentie per post">
                          <Row gutter={8}>
                              <Col span={24}>
                                <Button type="new" onClick={() => { this.checkDocument(); }}>Bekijk voorbeeld ter controle</Button>
                              </Col>
                          </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                        {/*<Modal
                            title="Controle correspondentie per e-mail"
                            visible={this.state.show_check_emails_modal}
                            onCancel={() => this.setState({ show_check_emails_modal: false })}
                            onOk={() => { this.checkEmails(); }}
                            okText="Verstuur"
                            >*/}
                          <Form.Item label="Verstuur controle correspondentie per e-mail naar">
                              <Row gutter={24}>
                                  <Col span={24}>
                                      {/*<Input
                                        type="email"
                                        placeholder={'e-mailadres'}
                                        value={this.state.emails_email}
                                        onChange={(event) => { this.setState({emails_email: event.target.value}); }}
                                        style={{width: '100%'}}
                                      />*/
                                      <Select
                                        mode="tags"
                                        style={{width: '100%'}}
                                        open={false}
                                        tokenSeparators={[',', '\n']}
                                        value={this.state.emails_email}
                                        onChange={(value) => { this.setState({ emails_email: value }, () => {}); }}
                                        ></Select>}
                                  </Col>
                              </Row>
                          </Form.Item>
                      {/*</Modal>*/}

                      <Form.Item /*label="Controle correspondentie per e-mail"*/>
                        <Button type="new" onClick={() => { this.checkEmails(); /*this.setState({ show_check_emails_modal: true });*/ }}>Verzend e-mail ter controle</Button>
                      </Form.Item>
                    </Col>
                  </Row>
              </Form>

              {/*<Row>
                <Col span={24}>
                  <CustomTable
                    rowKey='id'
                    size="middle"
                    //rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.props.support_base_reminder.pricing}
                    //loading={this.props.isFetching}
                  />
                </Col>
              </Row>*/}

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseReminderCheckForm = Form.create({ name: 'intake_form' })(SupportBaseReminderCheck);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_reminder : state.support_base.reminder,
        auth: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkSupportBaseReminderLetterAction : bindActionCreators(checkSupportBaseReminderLetterAction, dispatch),
        checkSupportBaseReminderDocumentsAction : bindActionCreators(checkSupportBaseReminderDocumentsAction, dispatch),
        checkSupportBaseReminderEmailsAction : bindActionCreators(checkSupportBaseReminderEmailsAction, dispatch),
        scheduleSupportBaseReminderToAction : bindActionCreators(scheduleSupportBaseReminderToAction, dispatch),
        getSupportBaseReminderAction : bindActionCreators(getSupportBaseReminderAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseReminderCheckForm);
