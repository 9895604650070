import api from '../utils/api'

export const resetDocumentAction = () => dispatch => {

    dispatch({
        type: 'RESET_DOCUMENT_ACTION_FETCH'
    })
}

export const resetDocumentsAction = () => dispatch => {

    dispatch({
        type: 'RESET_DOCUMENTS_ACTION_FETCH'
    })
}

export const getDocumentsAction = (projectid) => dispatch => {

    dispatch({
        type: 'DOCUMENTS_ACTION_FETCH'
    })

    return api('get','/documents/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchDocumentsAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'DOCUMENTS_ACTION_FETCH'
    })

    return api('post', '/documents/' + projectid + '/search', params, true).then((response) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getDocumentAction = (documentid) => dispatch => {

    dispatch({
        type: 'DOCUMENTS_ACTION_FETCH'
    })

    return api('get','/document/'+documentid, null, true).then((response) => {
        dispatch({
            type: 'DOCUMENT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const addDocumentAction = (projectid, params, files) => dispatch => {

    dispatch({
        type: 'DOCUMENT_ACTION_FETCH'
    })

    return api('post','/documents/'+projectid, params, true, files).then((response) => {
        dispatch({
            type: 'ADD_DOCUMENT_ACTION_SUCCESS',
            payload: response.data.document
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const patchDocumentAction = (documentid, params, file) => dispatch => {
    dispatch({
        type: 'DOCUMENTS_ACTION_FETCH'
    })

    return api('post','/document/'+documentid, params, true, file).then((response) => {
        dispatch({
            type: 'DOCUMENT_ACTION_SUCCESS',
            payload: response.data.document
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const updateDocumentsOrderAction = (id, params) => dispatch => {
    dispatch({
        type: 'DOCUMENTS_ACTION_FETCH'
    })

    return api('post','/documents/order/'+id, params, true).then((response) => {
        dispatch({
            type: 'DOCUMENT_ORDER_ACTION_SUCCESS',
            payload: response.data.document
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const removeDocumentsAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'DOCUMENTS_ACTION_REMOVE_FETCH'
    })

    return api('post','/documents/'+projectid+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'DOCUMENTS_REMOVE_SUCCESS',
            // payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DOCUMENTS_ACTION_REMOVE_ERROR',
        })
        return false;
    });
}
