import api from '../../../utils/api'
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressFieldAction, addAddressFieldAction, updateAddressFieldAction, resetAddressFieldAction } from '../../../actions/projectActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Dropdown,
  Menu,
  Drawer,
  Modal,
  message,
  Card,
  PageHeader,
  Checkbox,
  Alert,
  Tooltip,
} from 'antd';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';
import _ from 'underscore'

const { Title, Text } = Typography;
const Option = Select.Option;

class ProjectAddressField extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    this.props.resetAddressFieldAction();

    if(this.props.match.params.address_field_id && this.props.match.params.address_field_id != 'add')
    {
      this.props.getAddressFieldAction(this.props.match.params.address_field_id).then(() => {});
    }
  }

  patchProjectAddressField(e) {

    if(e) {
      e.preventDefault();
    }

    this.props.form.validateFields((error, values) => {
      if(!error)
      {
          if(this.props.match.params.address_field_id != 'add')
          {
              this.props.updateAddressFieldAction(this.props.match.params.address_field_id, values).then(() => this.props.history.push(`/projects/${this.props.match.params.id}/addresses/address-fields`));
          }
          else
          {
              this.props.addAddressFieldAction(this.props.match.params.id, values).then(() => this.props.history.push(`/projects/${this.props.match.params.id}/addresses/address-fields`));
          }

          showSuccess();
      }
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" onClick={() => this.patchProjectAddressField(null)}>Opslaan</Button>
              <Link to={`/projects/${this.props.match.params.id}/addresses/address-fields`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <div style={{padding: 24, width: '100%'}}>

              <Form onSubmit={this.patchProjectAddressField} layout={'vertical'} colon={true}>
                <Row gutter={24}>
                  <Col span={12}>

                      <Form.Item required hasFeedback label="Naam">
                        {getFieldDecorator('name', {
                          initialValue: this.props.address_field ? this.props.address_field.name : '',
                          rules: [{ required: true, message: 'Geef een naam op' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>

                  </Col>
                </Row>
              </Form>
            </div>
          </div>
      </div>
    );
  }
}


const ProjectAddressFieldForm = Form.create({ name: 'project_address_field', onFieldsChange: (props, changedValues, allValues) => console.log(allValues) })(ProjectAddressField);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isError : state.project.isError,
    address_field : state.project.address_field,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressFieldAction : bindActionCreators(getAddressFieldAction, dispatch),
    addAddressFieldAction : bindActionCreators(addAddressFieldAction, dispatch),
    updateAddressFieldAction : bindActionCreators(updateAddressFieldAction, dispatch),
    resetAddressFieldAction : bindActionCreators(resetAddressFieldAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddressFieldForm);
