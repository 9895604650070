export default (state = {
    isError: false,
    isFetching: false,
    libraries: [],
    library:{},
    list: [],
    categories: [],
    category: {}
}, action) => {
    switch (action.type) {
    case 'LIBRARIES_ACTION_FETCH':
    return {
        ...state,
        libraries: [],
        isFetching: true,
        isError: false,
    }
    case 'LIBRARY_ACTION_FETCH':
    return {
        ...state,
        library: {},
        isFetching: true,
        isError: false,
    }
    case 'LIBRARY_CATEGORIES_ACTION_FETCH':
    return {
        ...state,
        categories: [],
        isFetching: true,
        isError: false,
    }
    case 'LIBRARY_CATEGORY_ACTION_FETCH':
    return {
        ...state,
        category: {},
        isFetching: true,
        isError: false,
    }
    case 'LIBRARIES_ACTION_SUCCESS':
    return {
        ...state,
        libraries: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LIBRARIES_LIST_ACTION_SUCCESS':
    return {
        ...state,
        list: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        library: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_LIBRARIES_ACTION_SUCCESS':
    return {
        ...state,
        libraries: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'STORE_LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        libraries: [action.payload, ...state.libraries],
        library: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_CUSTOMER_ACTION_ERROR':
    return {
        ...state,
        library: {},
        isFetching: false,
        isError: false,
    }
    case 'DELETE_LIBRARIES_ACTION_SUCCESS':
    return {
        ...state,
        libraries: state.libraries.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'LIBRARIES_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }


    case 'LIBRARY_CATEOGORIES_ACTION_FETCH':
    return {
        ...state,
        categories: [],
        isFetching: true,
        isError: false,
    }
    case 'LIBRARY_CATEGORIES_ACTION_SUCCESS':
    return {
        ...state,
        categories: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CATEGORY_ACTION_SUCCESS':
    return {
        ...state,
        category: action.payload,
        isFetching: false,
        isError: false,
    }
    // case 'SEARCH_LIBRARIES_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     libraries: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    case 'STORE_LIBRARY_CATEGORY_ACTION_SUCCESS':
    return {
        ...state,
        //categories: [action.payload, ...state.categories],
        //category: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CATEGORIES_ACTION_RESET':
    return {
        ...state,
        categories: [],
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CATEGORY_ACTION_RESET':
    return {
        ...state,
        category: {},
        isFetching: false,
        isError: false,
    }
    case 'DELETE_LIBRARIES_ACTION_SUCCESS':
    return {
        ...state,
        //categories: state.categories.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'LIBRARY_CATEGORY_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'LIBRARY_CATEGORIES_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }


    default:
        return state
    }
}
