import React from 'react'
import { v1 as uuidv1 } from 'uuid';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Card, Input, Checkbox, Popconfirm, Select, Row, Col } from 'antd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result
}

class SBOptionList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: this.props.value || [],
    };

  }

  addOption = () => {
    this.setState({options: [...this.state.options, {'id': uuidv1() ,'label' : 'Optie'}]}, () => {
      this.props.onChange(this.state.options);
    });
  }

  removeOption = (id) => {
    this.setState({options: this.state.options.filter((element) => element.id != id)}, () => {
      this.props.onChange(this.state.options);
    });
  }

  reorderOption = (startIndex, endIndex) => {
    if(startIndex != endIndex && endIndex >= 0) {
      this.setState({options: reorder(this.state.options, startIndex, endIndex)},() => {
        this.props.onChange(this.state.options);
      });
    }
  }

  updateLabel = (id, label) => {
    var options = this.state.options.map((element) => {
      if(element.id == id) {
        element.label = label;
      }
      return element;
    })

    this.setState({options}, () => {
      this.props.onChange(this.state.options);
    });
  }

  render () {
    return (
      <div>
        {this.state.options.map((element, index) => {
          return (
            <Form.Item key={element.id} style={{marginBottom: 0}}>
              <Input onChange={(e) => this.updateLabel(element.id, e.target.value)} style={{width: 200, marginRight: 5}} defaultValue={element.label}></Input>

              <UpCircleOutlined
                onClick={() => this.reorderOption(index, (index - 1))}
                style={{marginRight: 5, fontSize: 20}} />
              <DownCircleOutlined
                onClick={() => this.reorderOption(index, (index + 1))}
                style={{marginRight: 5, fontSize: 20}} />

              <Button onClick={() => this.removeOption(element.id)} type="link danger" style={{ color: '#f5222d' }} >verwijder</Button>
            </Form.Item>
          );
        })}

        <Form.Item>
          <Button onClick={this.addOption} type="dashed" icon={<LegacyIcon type={'plus'} />}>Optie toevoegen</Button>
        </Form.Item>
      </div>
    );
  }
}

export default SBOptionList;
