import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../SupportBaseSideMenu';
import SupportBaseReminderSteps from './SupportBaseReminderSteps'
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseReminderAction, addSupportBaseReminderAction, updateSupportBaseReminderAction, checkSupportBaseReminderEmailAction } from '../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class SupportBaseReminder extends React.Component {

    state = {
        subject: '',
        reminder_date: null,
        saved: true,
        can_write: false,
    }

    constructor(props) {
      super(props);
    }

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('support-base' ,'write')
        });

        if(this.props.match.params.reminder_id != 'add')
        {
          this.props.getSupportBaseReminderAction(this.props.match.params.reminder_id).then(() => {
             this.setState({
                 subject : this.props.reminder.subject,
                 reminder_date : this.props.reminder.reminder_date && this.props.reminder.reminder_date != '0000-00-00 00:00:00' ? moment(this.props.reminder.reminder_date, 'YYYY-MM-DD HH:mm:ss') : null,
             });
          });
        }
    }

    allowNextStep = () =>
    {
        var status = true;

        this.props && this.props.form && this.props.form.validateFields((errors, values) => {

            if(errors){ this.setState({ saved: false }); }

            status = (errors || !this.state.saved ? false : true);

            if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
        });

        return status;
    }

    saveAndContinue(do_continue = true, preview = false)
    {
        this.props.form.validateFields((errors, values) => {

            if(!errors)
            {
                // add || update
                if(this.props.match.params.reminder_id == 'add')
                {   // add
                    this.props.addSupportBaseReminderAction(this.props.match.params.id).then(() => {

                        this.props.updateSupportBaseReminderAction(this.props.reminder.id, 'settings', {
                            subject : values.subject,
                            reminder_date : moment(this.state.reminder_date).format('YYYY-MM-DD HH:mm:ss'),
                        }).then(() => {

                            showSuccess('Succesvol opgeslagen');

                            if(do_continue)
                            {
                              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/letter/${this.props.reminder.id}`);
                            }

                            if(preview)
                            {
                                this.props.checkSupportBaseReminderLetterAction(this.props.reminder.id);
                            }
                        });

                    });
                }
                else
                {   // update
                    this.props.updateSupportBaseReminderAction(this.props.match.params.reminder_id, 'settings', {
                        subject : values.subject,
                        reminder_date : moment(this.state.reminder_date).format('YYYY-MM-DD HH:mm:ss'),
                    }).then(() => {
                        showSuccess('Succesvol opgeslagen');

                        if(do_continue)
                        {
                          this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/letter/${this.props.match.params.reminder_id}`);
                        }

                        if(preview)
                        {
                            this.props.checkSupportBaseReminderLetterAction(this.props.match.params.reminder_id);
                        }
                    });
                }
            }
        });
    }

  saveToggle(value, name) {
      this.setState({ [name]: value });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="3"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={24}>
                  <div>
                    <Button disabled={!this.state.can_write} type="new" onClick={() => { this.saveAndContinue(); }}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/reminders`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseReminderSteps validate={true} validator={() => { return this.allowNextStep(); }} current={0} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                <Row gutter={24}>
                    <Col span={16}>
                      <Form.Item label="Onderwerp">
                          {getFieldDecorator('subject', {
                            initialValue: this.state.subject,
                            rules: [{ required: true, message: 'Vul een onderwerp in' }],
                          })(
                            <Input />
                          )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Herinnering versturen op">
                          <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            placeholder="Direct"
                            format={'DD/MM/YYYY - HH:mm'}
                            // defaultValue={this.state.reminder_date}
                            value={this.state.reminder_date}
                            onChange={(value) => this.setState({ reminder_date: moment(value, 'YYYY-MM-DD HH:mm:ss') }, () => { console.log('set reminder date: ' + moment(this.state.reminder_date, 'YYYY-MM-DD HH:mm:ss')); })}
                            />
                            <Tooltip className="mhs" title=''>
                              <InfoCircleTwoTone />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>
              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseReminderForm = Form.create({ name: 'support_base_reminder_form' })(SupportBaseReminder);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        reminder : state.support_base.reminder,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseReminderAction : bindActionCreators(getSupportBaseReminderAction, dispatch),
        addSupportBaseReminderAction : bindActionCreators(addSupportBaseReminderAction, dispatch),
        updateSupportBaseReminderAction : bindActionCreators(updateSupportBaseReminderAction, dispatch),
        checkSupportBaseReminderEmailAction : bindActionCreators(checkSupportBaseReminderEmailAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseReminderForm);
