import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../../utils/Notifications';
import { getInformationItemAction, patchInformationAction, addInformationAction, resetInformationAction, addIndicatorAction, removeIndicatorAction, getInformationMenuAction } from '../../../../../actions/informationActions';
import { getPartnersAction } from '../../../../../actions/partnersActions';
import PatchInformationForm from '../Form/PatchInformation';
import InformationSteps from '../Steps/InformationSteps';
// import ReactQuill from 'react-quill';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import _ from 'underscore';
import InformationSidemenu from '../InformationSideMenu';

class InformationDetail extends Component {

    state = {
        partners: []
    }

    componentDidMount() {

      this.props.resetInformationAction();

      if(this.props.match.params.informationitemid) {

          this.props.getInformationItemAction(this.props.match.params.informationitemid).then(() => {

              this.props.getInformationMenuAction(this.props.match.params.id);
          });
      }

      if(this.props.HaveAbility('partner', 'read') && _.findWhere(this.props.project.modules, {id: 2})) {

        this.props.getPartnersAction(this.props.match.params.id);
      }

    }

    patchInformation = (e, values) => {

      this.props.form.validateFields((err, values) => {

        if (!err) {

          if(this.props.match.params.informationitemid) {

            this.props.patchInformationAction(this.props.match.params.informationitemid, values).then(() => {

              this.props.history.push(`/projects/${this.props.match.params.id}/modules/information/${this.props.match.params.informationitemid}/addresses`);
            });
          } else {

            this.props.addInformationAction(this.props.match.params.id, values).then(() => {

              this.props.history.push(`/projects/${this.props.match.params.id}/modules/information/${this.props.information.id}/addresses`);
            });
          }

          showSuccess();
        }
      });
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
          <div>
              <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
                <InformationSidemenu id={this.props.match.params.id} activeMenu={["1"]} {...this.props}/>
                <div className="chatScreen" layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
                    <Row className="mbm">
                      <Col span={12}>
                        <Button disabled={!this.props.HaveAbility('information' ,'write')} type="new" onClick={() => this.patchInformation(null)}>Opslaan en volgende</Button>
                        <Link to={`/projects/${this.props.match.params.id}/modules/information`}>
                          <Button>Sluiten</Button>
                        </Link>

                      </Col>
                        {/* <Col span={12} align="right">
                          <div>

                              <Button disabled type="primary" style={{marginRight: 10}}><Icon type="left" />Vorige</Button>

                            <Link to={`/projects/${this.props.match.params.id}/modules/information/${this.props.match.params.informationitemid}/addresses`}>
                              <Button type="primary">Volgende<Icon type="right" /></Button>
                            </Link>
                          </div>
                        </Col> */}
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
                          <InformationSteps current={0} {...this.props}/>
                        </Card>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={24}>
                          {/*{this.props.match.params.informationitemid ? (
                            <div>
                              <PageHeader
                                style={{ padding: 0 }}
                                title={this.props.information.title}
                              />
                              <Divider />
                            </div>
                          ) : null}*/}

                            <div>
                              <Form onSubmit={this.patchInformation}>
                                <PatchInformationForm {...this.props} />
                                {/* <Row gutter={24}>
                                  <Col span={12}>
                                      <Form.Item required label="Titel" hasFeedback>
                                          {getFieldDecorator('title', {
                                              initialValue: this.props.informationitem.title,
                                              rules: [{ required: true, message: 'Vul een titel in!' }],
                                          })(
                                          <Input />
                                          )}
                                      </Form.Item>

                                        <Form.Item required label="Inhoud">
                                          {getFieldDecorator('content', {
                                              initialValue: (this.props.informationitem.content ? this.props.informationitem.content : ''),
                                          })(
                                            <ReactQuill theme="snow" />
                                          )}
                                        </Form.Item>

                                      <Form.Item label="Upload">
                                        {getFieldDecorator('image_id', {
                                          initialValue: this.props.informationitem.image_id
                                        })(
                                          <AddFromMedia
                                            initialMediaId={this.props.informationitem.image_id}
                                            initialMediaUrl={this.props.informationitem.image_url}
                                            onMediaSelection={(item) => this.props.form.setFieldsValue({'image_id': item.id})}
                                            onMediaRemove={() => this.props.form.setFieldsValue({'image_id': ''})}
                                          />
                                        )}
                                      </Form.Item>
                                  </Col>
                                  <Col span={12}>

                                    <Form.Item label="Toegang tot woning vereist">
                                        {getFieldDecorator('access_to_home_required', {
                                            valuePropName: 'checked',
                                            initialValue: this.props.informationitem.access_to_home_required ? true : false
                                        })(
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                            />
                                        )}
                                    </Form.Item>

                                      <Form.Item label="Openbaar">
                                          {getFieldDecorator('is_public', {
                                              valuePropName: 'checked',
                                              initialValue: this.props.informationitem.is_public ? true : false
                                          })(
                                          <Switch
                                              checkedChildren={<Icon type="check" />}
                                              unCheckedChildren={<Icon type="close" />}
                                          />
                                          )}
                                      </Form.Item>

                                      <Form.Item label="Partners">
                                          {getFieldDecorator('partners', {
                                              initialValue: this.props.informationitem.partners && this.props.informationitem.partners.map((v) => String(v.partner_id))
                                          })(
                                            <Select
                                              mode="multiple"
                                              style={{ width: '100%' }}
                                              placeholder="Selecteer een partner"

                                            >
                                            {this.props.partners.map((v, k) => (
                                                <Select.Option key={String(v.id)}>{v.name}</Select.Option>
                                            ))}
                                            </Select>
                                          )}
                                      </Form.Item>

                                      {(this.props.informationitem.indicators) ? (this.props.informationitem.indicators.map((v, k) => (
                                      <Form.Item label="Indicator">
                                        <div>
                                          <Input />
                                          <Slider defaultValue={50} />
                                        </div>
                                      </Form.Item>
                                      ))) : null}

                                  </Col>
                                </Row> */}
                              </Form>
                            </div>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
        );
    }
}


const InformationForm = Form.create({ name: 'patch_information_form' })(InformationDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.information.isFetching,
      isError : state.information.isError,
      information : state.information.informationitem,
      partners : state.partner.partners,
      indicators : state.information.indicators,
      project: state.project.project,
      information_menus: state.information.information_menus
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInformationItemAction : bindActionCreators(getInformationItemAction, dispatch),
        patchInformationAction : bindActionCreators(patchInformationAction, dispatch),
        addInformationAction : bindActionCreators(addInformationAction, dispatch),
        resetInformationAction : bindActionCreators(resetInformationAction, dispatch),
        getPartnersAction : bindActionCreators(getPartnersAction, dispatch),
        addIndicatorAction : bindActionCreators(addIndicatorAction, dispatch),
        removeIndicatorAction : bindActionCreators(removeIndicatorAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
        getInformationMenuAction : bindActionCreators(getInformationMenuAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationForm);
