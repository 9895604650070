import React from 'react'
import { Card, Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ComposePushForm from '../../Form/ComposePushForm';
import { setCorrespondenceMethodAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, resetCorrespondenceAction } from '../../../../../actions/correspondencesActions';


class ComposePush extends React.Component {
  state = {
    method: {},
    loading: true
  };

  componentDidMount() {

    this.props.resetCorrespondenceAction(); // .then(() => {

        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

            var selected_method = this.props.correspondence.methods.find((element) => {
              if(this.props.match.params.addressid) {
                console.log(this.props.match.params.addressid, element.address_id == this.props.match.params.addressid);
                if(element.type === 'push' && element.address_id == this.props.match.params.addressid) {
                  return true;
                }
              } else {
                if(this.props.match.params.occupantid) {
                  if(element.type === 'push' && element.occupant_id == this.props.match.params.occupantid) {
                    return true;
                  }
                } else {
                  if(element.type === 'push' && element.address_id == null && element.occupant_id == null) {
                    return true;
                  }
                }

              }
            });

            this.props.setCorrespondenceMethodAction(selected_method); // .then(() => {
                this.setState({ loading: false });
            // });
        });
    //});
  }

  saveMethod = () => {

    this.composeMailForm.props.form.validateFields((errors, values) => {

      if(this.props.match.params.occupantid) {
        values = {...values, 'occupant_id': this.props.match.params.occupantid};
      }

      if(!errors) {
        this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {...values, 'type':'push'}).then(() => {
          this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/5`);
        });
      }
    })
  }

  render () {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Button loading={this.props.isFetching} type="new" onClick={this.saveMethod}>Opslaan</Button>
              <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/5`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Card>
            { !this.state.loading ? <ComposePushForm wrappedComponentRef={formRef => { this.composeMailForm = formRef }} {...this.props}/> : null }
          </Card>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    selectedMethod : state.correspondence.selectedMethod,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    setCorrespondenceMethodAction : bindActionCreators(setCorrespondenceMethodAction, dispatch),
    resetCorrespondenceAction : bindActionCreators(resetCorrespondenceAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposePush);
