export const zipcodeValidation = (rule, value, callback) => {

  var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

  if(!value) {
    callback();
  }

  if (!rege.test(value)) {
    callback('Vul een geldige postcode in');
  } else {
    callback();
  }

}

export const urlValidation = (rule, value, callback) => {

  // if(!value || value == '') {
  //   callback();
  // }
  //
  // var rege = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
  //
  // if (!rege.test(value)) {
  //   callback('Vul een geldige URL in');
  // } else {
  //   callback();
  // }

  // method 2
  if(!value || value == '') {
    callback();
  }

  const url = new URL('https://'+value);

  if (url.protocol === "https:" && url.host !== "" && url.pathname !== "") {
    callback();
  }

  callback('Vul een geldige URL in');
}

export const passwordValidation = (rule, value, callback) => {

  var validated = true;
  var error_message = '';

  var min_one_digit = /^(?=.*[0-9])/;
  var min_one_letter = /^(?=.*[a-z])/;
  var min_one_capital = /^(?=.*[A-Z])/;

  if(value) {
    if (value.length > 0 && value.length < 8) {
      validated = false;
      error_message += "minimaal 8 tekens, ";
    }
    if (!min_one_digit.test(value)) {
      validated = false;
      error_message += "minimaal 1 cijfer, ";
    }
    if(!min_one_letter.test(value)) {
      validated = false;
      error_message += "minimaal 1 letter, ";
    }
    if(!min_one_capital.test(value)) {
      validated = false;
      error_message += "minimaal 1 hoofdletter ";
    }
  }

  if(validated) {
    callback();
  } else {
    callback(error_message);
  }

}
