/*global gantt*/
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  DatePicker,
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  InputNumber,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/locale/locale_nl.js';

import '../Schedule.css';
import '../DaySchedule.css';

import { Link } from 'react-router-dom';
import { resetScheduleAction, getScheduleGroupDaysAction, getScheduleGroupAction, addDayScheduleAction, updateDayScheduleAction, patchDayScheduleGanttAction, updateSelectedGroupAddresses, getSelectedGroupAddresses, getScheduleGanttAction } from '../../../../../actions/scheduleActions';
import { getInformationAction, resetInformationAction } from '../../../../../actions/informationActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { showSuccess } from '../../../../../utils/Notifications';
import Settings from '../Form/Settings';
import BlockedDates from '../Form/BlockedDates';
import DaySchedule from '../Form/DaySchedule';
import Calendar from '../Form/Calendar';
import ScheduleSteps from '../Steps/ScheduleSteps';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import _ from 'underscore';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

var onBeforeTaskAdd = null;
var onBeforeTaskUpdate = null;

class ScheduleDayScheduleDetail extends Component
{
  constructor(props) {

    super(props);

    this.state = {
      targetKeys: [],
      address_ids: [],
      gantt_data: [],
      information: [],
      renderAddresses: false,
      disabledCloseButton: true,
      start_date: null,
      gantt_initialized: false
    }

    this.props.resetInformationAction();

    if(this.props.match.params.dayscheduleid) {

      this.props.getScheduleGroupDaysAction(this.props.match.params.dayscheduleid).then(() => {

          this.setState({ start_date: this.props.group.start_date });
      });
    }
  }

  setupGantt = (start_date = null) => {

    this.setState({ gantt_initialized: true });

    gantt.resetSkin();

    gantt.config.drag_progress = false;
    gantt.config.drag_links    = false;

    var date = new Date();

    var current_date = date.toISOString().split('T')[0]; // date.getFullYear()+'-'+date.getFullYear()+'-'+date.getFullYear();

    gantt.config.start_date = new Date((start_date ? start_date : current_date) + ' 00:00');
    // gantt.config.end_date = new Date('2020-01-01 00:00');
    // gantt.config.end_date = new Date('2021-01-01 00:00');
    // gantt.config.end_date = new Date('2022-12-31 00:00');
    gantt.config.end_date = new Date((new Date().getFullYear() + 2) + '-12-31 00:00'); // Todo: determine dynamically based on schedule

    gantt.config.xml_date = "%Y-%m-%d";
    // gantt.config.date_grid = "%d-%m-%Y";

    gantt.config.autosize = "y";

    var information = this.props.information.map((element) => {
      return { 'key': element.id, 'label': element.title };
    });

    gantt.config.lightbox.sections = [
      { name:"description", height:50, map_to:"activity_id", type:"select", options: information },
    ];

    gantt.locale.labels.section_description = "Werkzaamheden";

    onBeforeTaskAdd    = gantt.attachEvent("onBeforeTaskAdd", this.handleActivityToTask);
    onBeforeTaskUpdate = gantt.attachEvent("onBeforeTaskUpdate", this.handleActivityToTask);

    gantt.config.details_on_dblclick = true;
    gantt.config.work_time = false;
    gantt.config.scale_unit = "day";
    // gantt.config.date_scale = "%D, %d";
    // gantt.config.min_column_width = 60;
    gantt.config.duration_unit = "day";
    // gantt.config.scale_height = 20 * 3;
    // gantt.config.row_height = 30;

    gantt.config.drag_project = true;

    //gantt.config.work_time = true; //disable scheduling on non-work days


    gantt.init(this.ganttDayContainer);

    gantt.config.columns = [
      {name: "text", tree: true, width: 150, resize: true, label: 'Werkzaamheden'},
      {name: "start_date", align: "center", width: 150, hide: true, resize: true},
      {name: "duration", align: "center", width: 70, hide: true, resize: true},
      {name: "add", width: 44, resize: true, hide: false}
    ];

    gantt.clearAll();
    //console.log(this.state.gantt_data.data);
    // gantt.refreshData();
    if(this.state.gantt_data && this.state.gantt_data.data) {
      gantt.parse(this.state.gantt_data);

    }
    this.setState({
        disabledCloseButton: false,
        gantt_initialized: true
    });
    // gantt.render();
  }

  handleActivityToTask = (id, task) => {
    var name = _.where(this.props.information, {id: parseInt(task.activity_id)})[0].title;
    task.text = name;
    return true;
  }

  saveDaySchedule = () => {

    this.props.form.validateFields((errors, values) => {

      values.start_date = values.start_date.format('YYYY-MM-DD');

      if(!errors) {
        var tasks = gantt.serialize();

        if(this.props.match.params.dayscheduleid) {
          this.props.updateDayScheduleAction(this.props.match.params.dayscheduleid, values).then(() => {
            this.props.patchDayScheduleGanttAction(this.props.match.params.dayscheduleid, {data: tasks.data});
            this.props.updateSelectedGroupAddresses(this.props.match.params.dayscheduleid, {addresses: this.state.targetKeys}).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/day-schedule`)
            });
            showSuccess();
          });

        } else {
          this.props.addDayScheduleAction(this.props.match.params.id, values).then(() => {
            this.props.patchDayScheduleGanttAction(this.props.group.id, {data: tasks.data});
            this.props.updateSelectedGroupAddresses(this.props.group.id, {addresses: this.state.targetKeys}).then(() => {

              this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/day-schedule`)
            });
            showSuccess();
            //console.log(this.props.group.id);
          });

        }
        //console.log(tasks, this.state.targetKeys, this.props.form);
      }
    })

  }

  componentWillUnmount(){
    // gantt.detachEvent(onBeforeTaskAdd);
    // gantt.detachEvent(onBeforeTaskUpdate);
  }

  componentDidMount() {

    this.props.resetScheduleAction();

    if(this.props.match.params.dayscheduleid) {

      this.props.getSelectedGroupAddresses(this.props.match.params.dayscheduleid).then(() => {

        this.setState({renderAddresses: true, targetKeys: this.props.schedule.address_ids});
      });

      this.props.getScheduleGroupAction(this.props.match.params.dayscheduleid).then(() => {

          this.props.getScheduleGanttAction(this.props.match.params.dayscheduleid).then(() => {

            this.setState({"gantt_data": this.props.gantt_data}, () => {

              this.props.getInformationAction(this.props.match.params.id).then(() => {

                this.setupGantt(this.props.group.start_date);
              });
            })
          });
      });
    } else {

      this.props.getInformationAction(this.props.match.params.id).then(() => {

        this.setupGantt();

        this.setState({renderAddresses: true});

      });

      //this.setupGantt();

    }
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    const groupcolumns = [
      {
        title: 'Titel',
        dataIndex: 'title',
        render: (index, record) => (
          <span>
            <Link to={`/projects/${this.props.match.params.id}/modules/schedule/day-schedule/${record.id}/detail`}>
              {record.name}
            </Link>
          </span>
        )
      },
    ];

    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => {}}>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Card className="mbm" style={{ backgroundColor: '', borderRadius: '5px' }} bodyStyle={{padding: 0}}>
            <ScheduleSteps current={2} {...this.props} />
          </Card>
        </Row>
        <Card>
        <Row gutter={24}>
          <Col span={24}>
            <Row className="mbm">
              <Col span={24}>
                <Button disabled={this.state.disabledCloseButton || !this.props.HaveAbility('scheduling', 'write')} type="new" onClick={this.saveDaySchedule}>Opslaan</Button>

                {/*<Button type="new">(Opnieuw) inplannen</Button>*/}

                <Link to={`/projects/${this.props.match.params.id}/modules/schedule/day-schedule`}>
                  <Button disabled={this.state.disabledCloseButton}>Sluiten</Button>
                </Link>

              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Form layout={'vertical'}>
                  <Row gutter={24}>
                    <Col span={16}>
                      <Form.Item required label='Titel'>
                        {getFieldDecorator('name', {
                          initialValue: this.props.group.name,
                          rules: [{ required: true, message: 'Vul een titel in' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item required label='Adressen per dag'>
                        {getFieldDecorator('nr_of_addresses_per_day', {
                          initialValue: this.props.group.nr_of_addresses_per_day ? this.props.group.nr_of_addresses_per_day : 1,
                          rules: [{ required: true, message: 'Vul een naam in.' }],
                        })(
                          <InputNumber min={1}/>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label='Startdatum'>
                        {getFieldDecorator('start_date', {
                          initialValue: dayjs(this.props.group.start_date),
                          rules: [{ required: true, message: 'Vul een startdatum in' }],
                          // onChange: (value) => {
                          //     if(!this.state.gantt_initialized)
                          //     {
                          //         this.setupGantt(value);
                          //     }
                          // }
                        })(
                          <DatePicker format={'DD/MM/YYYY'}/>
                        )}

                      </Form.Item>
                    </Col>
                  </Row>
                  <Col span={24}>
                    <Form.Item>
                      <div id="dayschedule" key={'gant1'} ref={ (input) => { this.ganttDayContainer = input } } style={ { width: '100%', height: '100%' } }></div>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback required>
                      {/*<SelectAddresses initialAddresses={this.props.address_ids} onAddressSelection={(ids) => this.setState({targetKeys: ids})} {...this.props} />*/}
                      {(this.state.renderAddresses) ? (
                        <SelectAddresses initialAddresses={this.props.match.params.dayscheduleid ? this.props.schedule.address_ids: []} onAddressSelection={(ids) => this.setState({targetKeys: ids})} {...this.props} />
                      ) : null}
                  </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        </Card>
      </div>
    );
  }
}

const ScheduleDayScheduleDetailForm = Form.create({ name: 'add_account' })(ScheduleDayScheduleDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.schedule.isFetching,
    isError : state.schedule.isError,
    dayschedule : state.schedule.dayschedule,
    address_ids : state.schedule.address_ids,
    gantt_data : state.schedule.gantt_data,
    group : state.schedule.group,
    schedule : state.schedule,
    information: state.information.information,
    days : state.schedule.days
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScheduleGroupDaysAction : bindActionCreators(getScheduleGroupDaysAction, dispatch),
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    getScheduleGroupAction : bindActionCreators(getScheduleGroupAction, dispatch),
    addDayScheduleAction : bindActionCreators(addDayScheduleAction, dispatch),
    updateDayScheduleAction : bindActionCreators(updateDayScheduleAction, dispatch),
    patchDayScheduleGanttAction : bindActionCreators(patchDayScheduleGanttAction, dispatch),
    updateSelectedGroupAddresses : bindActionCreators(updateSelectedGroupAddresses, dispatch),
    getSelectedGroupAddresses : bindActionCreators(getSelectedGroupAddresses, dispatch),
    getScheduleGanttAction : bindActionCreators(getScheduleGanttAction, dispatch),
    resetScheduleAction : bindActionCreators(resetScheduleAction, dispatch),
    resetInformationAction : bindActionCreators(resetInformationAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDayScheduleDetailForm);
