import api from '../utils/api'

export const getNewsAction = (projectid) => dispatch => {

    dispatch({
        type: 'NEWS_ACTION_FETCH'
    })

    return api('get','/news/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'NEWS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'NEWS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchNewsAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'NEWS_ACTION_FETCH'
    })

    return api('post','/news/'+projectid+'/search', params, true).then((response) => {
        dispatch({
            type: 'NEWS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'NEWS_ACTION_ERROR',
        })
        return false;
    });
}

export const getNewsArticleAction = (newsarticleid) => dispatch => {

    dispatch({
        type: 'NEWS_ACTION_FETCH'
    })

    return api('get','/news/article/'+newsarticleid, null, true).then((response) => {
        dispatch({
            type: 'NEWSARTICLE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'NEWS_ACTION_ERROR',
        })
        return false;
    });
}

export const addNewsAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'NEWS_ACTION_FETCH'
    })

    return api('post','/news/'+projectid, params, true).then((response) => {
        dispatch( {
            type: 'ADD_NEWS_ACTION_SUCCESS',
            payload: response.data.news_article
        } );
        return response;
    }).catch((e) => {
        dispatch({
            type: 'NEWS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetNewsAction = () => dispatch => {
    dispatch({
        type: 'NEWS_ACTION_RESET'
    })
}

export const patchNewsAction = (newsarticleid, params) => dispatch => {
    dispatch({
        type: 'NEWS_ACTION_FETCH'
    })

    return api('post','/news/article/'+newsarticleid, params, true).then((response) => {
        dispatch({
            type: 'NEWSARTICLE_ACTION_SUCCESS',
            payload: response.data.news_article
        });
    }).catch((e) => {
        dispatch({
            type: 'NEWS_ACTION_ERROR',
        })
        return false;
    });
}

export const removeNewsAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'NEWS_ACTION_REMOVE_FETCH'
    })
    return api('post','/news/'+projectid+'/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'NEWS_ACTION_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'NEWS_ACTION_REMOVE_ERROR',
        })
        return false;
    });
}
