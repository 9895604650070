import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Select,
} from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerDeliveryFormsAction, searchCustomerDeliveryFormsAction, deleteCustomerDeliveryFormsAction, duplicateDeliveryFormAction } from '../../actions/deliverActions';
import { getLibraryListAction, cloneLibraryItemAction } from '../../actions/librariesActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';

import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';

class Delivery extends Component {

  constructor(props) {
    super(props);

    this.state = {
        selectedRowKeys: [],
        modalVisible: false,
        selectedId: [],
        list: [],
    };
  }

  componentDidMount()
  {
    this.props.getCustomerDeliveryFormsAction();

    if(this.props.HaveAbility('library', 'write')){

      this.props.getLibraryListAction('delivery-form').then(() => {

        this.setState({ list: this.props.list });
      });
    }
  }

  // handleSubmit = (e, values) => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.storeLibraryAction(values).then(() => {
  //         message.success('Succesvol opgeslagen');

  //         this.setState({drawerVisible: false});
  //         this.props.form.resetFields();
  //       });
  //     }
  //   });
  // }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  cloneSelected = () => {

    this.props.duplicateDeliveryFormAction(this.state.selectedRowKeys[0], 'customer').then(() => {

      showSuccess('Succesvol gedupliceerd');

      this.setState({selectedRowKeys: []});

      this.props.getCustomerDeliveryFormsAction();

    });
  }

  showConfirm() {

    showMultiDelete((multidelete) => {

      if(multidelete) {

        this.props.deleteCustomerDeliveryFormsAction(this.state.selectedRowKeys).then(() => {

          this.setState({selectedRowKeys: []});

          this.props.getCustomerDeliveryFormsAction();

          showSuccess('Succesvol verwijderd');
        });
      }
    });
  }

  showDuplicate() {
      this.setState({ modalVisible: true });
  }

  duplicate = () => {

    this.props.cloneLibraryItemAction('delivery-form', this.state.selectedId).then(() => {

      this.setState({selectedRowKeys: []});
      
      this.setState({selectedId: []});

      this.setState({modalVisible: false});

      showSuccess('Succesvol gedupliceerd');

      this.props.getCustomerDeliveryFormsAction();
    });
  }

  listItem = (element) => {
      return (
          <Select.OptGroup label={element.name}>
            {element.items.map((item) => (
                <Select.Option value={item.id}>{item.title}</Select.Option>
            ))}
          </Select.OptGroup>
      );
  }

  render() {

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
          <Link to={`/library/delivery/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },{
      title: 'Labels',
      dataIndex: 'tags'
    }];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Opleveren'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='20' />

            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12}>

                    <Dropdown disabled={!this.props.HaveAbility('library', 'write')} trigger={['click']} overlay={(
                      <Menu>
                        <Menu.Item key="1">
                          <Link to="/library/delivery/add">
                            Nieuw
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => this.showDuplicate()} disabled={!this.props.HaveAbility('library' ,'write')}>
                            Dupliceren
                        </Menu.Item>
                      </Menu>)}>
                      <Button type="new">
                          Toevoegen <DownOutlined />
                      </Button>
                    </Dropdown>

                    <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('library', 'write') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                        <Menu>
                          <Menu.Item disabled={this.state.selectedRowKeys && this.state.selectedRowKeys.length > 1} onClick={() => { this.cloneSelected(); }} key="6">Dupliceren</Menu.Item>
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('library' ,'delete')} style={{ color: this.props.HaveAbility('library', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                        </Menu>

                      )}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ value => this.props.searchCustomerDeliveryFormsAction(value) }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey="id"
                      rowSelection={rowSelection}
                      //onChange={() => console.log('test')}
                      columns={columns}
                      loading={false}
                      dataSource={this.props.forms}
                      pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25','50', '100' ]}}
                    />
                  </Col>
                </Row>
          </div>

        </div>

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: [] })}
          onOk={() => this.duplicate()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Opleverformulieren">
              <Select
                showSearch
                value={this.state.selectedId}
                placeholder={'Selecteer opleverformulier'}
                optionFilterProp="children"
                onChange={(selectedId) => this.setState({selectedId})}
                mode='multiple'
                >
                {this.state.list.map((element) => this.listItem(element))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

    </div>
    );
}
}

const DeliveryForm = Form.create({ name: 'horizontal_login' })(Delivery);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.deliver.isFetching,
    isError: state.deliver.isError,
    forms: state.deliver.customer_forms,
    list : state.library.list,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDeliveryFormsAction : bindActionCreators(getCustomerDeliveryFormsAction, dispatch),
    searchCustomerDeliveryFormsAction : bindActionCreators(searchCustomerDeliveryFormsAction, dispatch),
    deleteCustomerDeliveryFormsAction : bindActionCreators(deleteCustomerDeliveryFormsAction, dispatch),
    duplicateDeliveryFormAction : bindActionCreators(duplicateDeliveryFormAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    getLibraryListAction : bindActionCreators(getLibraryListAction, dispatch),
    cloneLibraryItemAction : bindActionCreators(cloneLibraryItemAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryForm);
