import React, { Component } from 'react'
import { ControlOutlined, EditOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class FormSideMenu extends Component {
    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 300}}
                defaultSelectedKeys={this.props.activeMenu}
            >
                <Menu.Item key={"1"}>
                    <Link to={`/projects/${this.props.id}/forms`}>
                        <EditOutlined />
                        <span>Formulieren</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"2"}>
                    <Link to={`/projects/${this.props.id}/forms/menu`}>
                        <ControlOutlined />
                        <span>Categorieën</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"3"}>
                    <Link to={`/projects/${this.props.id}/forms/status`}>
                        <ExclamationOutlined />
                        <span>Status</span>
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }
}
