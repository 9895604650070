export default (state = {
    isError: false,
    isFetching: false,
    forms: [],
    lib_forms: [],
    addresses: [],
    support_base_form: {
        attachments: [],
        pricing: []
    },
    settings: {},
    reminders: [],
    reminder: {},
    support_base_form_attachment: {},
    addresses: [],
    correspondences: [],
    progress: '... %',
}, action) => {

    switch (action.type) {
    case 'SUPPORT_BASE_ACTION_FETCH':
    return {
        ...state,
        support_base_form: {
            attachments: [],
            pricing: []
        },
        isFetching: true,
        isError: false,
    }
    case 'SUPPORT_BASE_FORMS_ACTION_FETCH':
    return {
        ...state,
        forms: [],
        lib_forms: [],
        isFetching: true,
        isError: false,
    }
    case 'SUPPORT_BASE_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        forms: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_FORM_ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_FORM_ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_FORM_CORRESPONDENCES_ACTION_FETCH':
    return {
        ...state,
        correspondences: [],
        isFetching: true,
        isError: false,
    }
    case 'SUPPORT_BASE_FORM_CORRESPONDENCES_ACTION_SUCCESS':
    return {
        ...state,
        correspondences: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_FORMS_LIB_ACTION_SUCCESS':
    return {
        ...state,
        lib_forms: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_FORM_ACTION_SUCCESS':
    return {
        ...state,
        support_base_form: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_REMINDERS_ACTION_SUCCESS':
    return {
        ...state,
        reminders: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_REMINDER_ACTION_SUCCESS':
    return {
        ...state,
        reminder: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_REMINDER_ADD_ACTION_SUCCESS':
    return {
        ...state,
        reminder: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_STATUS_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        progress: false,
        isFetching: true,
        isError: false,
    }
    case 'SUPPORT_BASE_STATUS_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload.addresses,
        progress: action.payload.progress,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_RESEND_TO_ACTION_SUCCESS':
    return {
        ...state,
        support_base_form: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SUPPORT_BASE_ACTION_RESET':
    return {
        ...state,
        forms: [],
        support_base_form: {},
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
