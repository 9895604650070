import api from '../utils/api'

export const resetContactAction = () => dispatch => {

    dispatch({
        type: 'RESET_CONTACT_ACTION_FETCH'
    })
}

export const getContactsAction = (projectid) => dispatch => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('get','/contacts/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'CONTACTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchCustomerContactsAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('post','/library/contacts/'+customer_id+'/search', params, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchContactsAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('post','/contacts/'+projectid+'/search', params, true).then((response) => {
        dispatch({
            type: 'CONTACTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getContactAction = (contactid) => dispatch => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('get','/contact/'+contactid, null, true).then((response) => {
        dispatch({
            type: 'CONTACT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACT_ACTION_ERROR',
        })
        return false;
    });
}

export const addContactAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('post','/contacts/'+projectid, params, true).then((response) => {
        dispatch({
            type: 'ADD_CONTACT_ACTION_SUCCESS',
            payload: response.data.contact
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACT_ACTION_ERROR',
        })
        return false;
    });
}

export const patchContactAction = (contactid, params) => dispatch => {
    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('post','/contact/'+contactid, params, true).then((response) => {
        dispatch({
            type: 'CONTACT_ACTION_SUCCESS',
            payload: response.data.contact
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACT_ACTION_ERROR',
        })
        return false;
    });
}

export const removeContactsAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'CONTACTS_ACTION_REMOVE_FETCH'
    })

    return api('post','/contacts/'+projectid+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'CONTACTS_REMOVE_SUCCESS',
            // payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_REMOVE_ERROR',
        })
        return false;
    });
}

export const updateContactsOrderAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'CONTACTS_ACTION_FETCH'
    })

    return api('POST','/contacts/order/'+projectid, params, true).then((response) => {
        dispatch({
            type: 'CONTACTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getLibraryContactsAction = (id) => (dispatch, getState) => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('get','/library/contact/' + id, null, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getLibrariesContactsAction = (projectid) => (dispatch, getState) => {

    dispatch({
        type: 'CONTACTS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/library/contacts/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const storeLibrariesContactsAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/contacts/' + customer_id, params, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACTS_STORE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const updateLibrariesContactsAction = (id, params) => (dispatch, getState) => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    });

    return api('post','/library/contact/' + id, params, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACTS_STORE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACTS_ACTION_ERROR',
        })
        return false;
    });
}

export const removeLibraryContactsAction = (params) => (dispatch, getState) => {
    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/contacts/' + customer_id + '/multidelete', params, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACT_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACT_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneLibraryContactsAction = (projectid, id) => (dispatch, getState) => {

    dispatch({
        type: 'CONTACT_ACTION_FETCH'
    })

    return api('post','/contacts/project/'+ projectid +'/contact/'+ id +'/clone' , null, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CONTACT_CLONE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONTACT_ACTION_ERROR',
        })
        return false;
    });
}
