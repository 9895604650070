import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { DashboardOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Modal, Select, Spin, Alert } from 'antd';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { getAddressesAction, loginAddressAction } from '../../actions/addressesActions';
import { OCCUPANTS_URL } from '../../constants/settings';

class PageHeader extends Component {

  state = {
    addressModal: false,
    selectedAddressId: null,
  }

  addressLogin = () => {
    if(this.state.selectedAddressId) {
      this.props.loginAddressAction(this.state.selectedAddressId).then((response) => {
        this.setState({addressModal: false, selectedAddressId: null})
        window.open(`${OCCUPANTS_URL}/setlogin/${response.token}/${response.logo ? encodeURIComponent(response.logo) : 'false'}/${response.website ? encodeURIComponent(response.website) : 'false'}/${response.color_primary ? encodeURIComponent(response.color_primary) : 'false'}/${response.color_secondary ? encodeURIComponent(response.color_secondary) : 'false'}/${response.admin_token ? encodeURIComponent(response.admin_token) : 'false'}/${response.address_id ? encodeURIComponent(response.address_id) : 'false'}/${response.notification ? encodeURIComponent(JSON.stringify(response.notification)) : '{}'}`)
      })
    }
  }

  componentDidMount() {
    if(this.props.match.params && this.props.match.params.id && !this.props.match.url.includes('library') && this.props.match.url.includes('project')) {
      this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => {
      });
    }
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 24}}>
        <div>
          <Breadcrumb style={{flex: 0}}>
             <Breadcrumb.Item key="1">
               <DashboardOutlined />
             </Breadcrumb.Item>
             {this.props.path.map((v,k) => v && (
                <Breadcrumb.Item key="2">
                  {v.link ? (
                    <Link to={v.link}>
                      <span style={v.link ? {} : {fontSize: 18, fontWeight: 'bold'}}>{v.label}</span>
                    </Link>
                  ) : (
                    <span style={v.link ? {} : {fontSize: 18, fontWeight: 'bold'}}>{v.label}</span>
                  )}
                </Breadcrumb.Item>
             ))}
          </Breadcrumb>
        </div>
        <div>

          { this.props.match.params && this.props.match.params.id && !this.props.match.url.includes('library') && this.props.match.url.includes('project') && (
          <Button onClick={() => this.setState({addressModal: true})} type="primary" style={{ marginRight: 12 }}>Bewonerspagina</Button>
          )}

          {/*<Button onClick={() => this.setState({helpModal: true})} type="primary">Help</Button>*/}

          <Modal
            title="Help"
            visible={this.state.helpModal}
            okButtonProps={{ style: { display: 'none' }}}
            width={740}
            cancelText={'Sluiten'}
            onCancel={() => this.setState({ helpModal: false })}
          >
              <div style={{ width: '700' }}>
                <iframe src="https://scribehow.com/page/Supportpagina__vEiuzVJkTYKx7qucZV7UEw?as=scrollable&removeLogo=true" width="100%" height="640" allowfullscreen frameborder="0"></iframe>
              </div>
          </Modal>

          <Modal
              title="Selecteer een adres"
              visible={this.state.addressModal}
              okText={'Inloggen'}
              onOk={() => this.addressLogin()}
              onCancel={() => this.setState({addressModal: false})}
            >
            <div>

              {this.props.address.length > 0 ? (
                <Select showSearch className="mbm" value={this.state.selectedAddressId} defaultValue={this.state.selectedAddressId} optionFilterProp="children" style={{width: '100%'}} onChange={(id) => this.setState({selectedAddressId: id})}>

                {this.props.address.map((element) => (
                  <Select.Option key={element.id} value={element.id}>{element.full_address}</Select.Option>
                ))}

              </Select>
            ) : (
              <div>
                <Alert showIcon type="info" description="Voeg eerst adressen toe" />
              </div>
            )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.address.isFetching,
      address : state.address.addresses,
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    loginAddressAction : bindActionCreators(loginAddressAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageHeader));
