import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../../SupportBaseSideMenu';
import SupportBaseReminderCorrespondenceSteps from './SupportBaseReminderCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../../../utils/Notifications';
import CustomEditor from '../../../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseFormByIdAction, getSupportBaseFormsByProjectAction, updateSupportBaseCorrespondenceAction, checkSupportBaseFormDocumentAction, getSupportBaseFormCorrespondencesAction } from '../../../../../../actions/supportBaseActions';

const { TabPane } = Tabs;
const { Option } = Select;

class SupportBaseReminderCorrespondenceLetter extends React.Component {

  state = {
    salutation_type: null,
    salutation: null,
    message_letter: '',
    message_email: '',
    show_letter: true,
    show_email: false,
    saved: true,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
       this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id).then(() => {
           this.setState({
               'salutation_type' : this.props.support_base_form.correspondences[0].salutation_type,
               'salutation' : this.props.support_base_form.correspondences[0].salutation ? this.props.support_base_form.correspondences[0].salutation : 'Aan de bewoner(s) van',
               'message_letter' : this.props.support_base_form.correspondences[0].message,
               'message_email' : this.props.support_base_form.correspondences[1].message,
           });
       });

       this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id);
  }

  allowNextStep = () =>
  {
      var status = true;

      this.props && this.props.form && this.props.form.validateFields((errors, values) => {

          if(errors){ this.setState({ saved: false }); }

          status = (errors || !this.state.saved ? false : true);

          if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
      });

      return status;
  }

  useTexts()
  {
      var form_id = this.state.selected_form_id;
      var copy_letter = this.state.copy_letter;
      var copy_email_mail = this.state.copy_email_mail;

      if(!form_id)
      {
          showError('Geef een formulier op');
      }
      else if(!copy_letter && !copy_email_mail)
      {
          showError('Geef minimaal één optie op');
      }
      else
      {
          this.props.support_base_forms.map((object, index) => {
              if(object.id == form_id)
              {
                  this.props.getSupportBaseFormCorrespondencesAction(form_id).then(() => {

                      this.props.correspondences.map((correspondence, index) => {
                          if(correspondence.type == 'letter' && copy_letter == true)
                          { // take over message from letter
                              this.props.form.setFieldsValue({['message_letter']: correspondence.message});
                          }
                          if(correspondence.type == 'email_letter' && copy_email_mail == true)
                          { // take over message from email letter
                              this.props.form.setFieldsValue({['message_email']: correspondence.message});
                          }
                      });

                      showSuccess('Succesvol geplaatst');

                      this.setState({ show_copy_text_modal: false });
                  })
              }
          });
      }
  }

  saveAndContinue(do_continue = true, preview = false)
  {
      this.props.form.validateFields((errors, values) => {

          if(errors)
          {
              this.setState({ saved: false });
          }

          if(!errors || !do_continue)
          {
              this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'letter', {
                      'salutation_type' : values.salutation_type,
                      'salutation' : values.salutation,
                      'message': values.message_letter,
              }).then(() => {

                  this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'email-letter', {
                      'salutation_type' : values.salutation_type,
                      'salutation' : values.salutation,
                      'message': values.message_email,
                  }).then(() => {

                      showSuccess('Succesvol opgeslagen');

                      if(do_continue)
                      {
                          this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/${this.props.match.params.form_id}/correspondence/` + (this.props.support_base_form.nr_of_emails ? 'email' : 'check'));
                      }
                      else if(preview)
                      {
                          if(preview == 'letter')
                          {
                              this.props.checkSupportBaseFormDocumentAction(this.props.match.params.form_id, false);
                          }

                          if(preview == 'email')
                          {
                              this.props.checkSupportBaseFormDocumentAction(this.props.match.params.form_id, true);
                          }
                      }
                  });
              });
          }
          else if(errors)
          {
              showError('Nog niet alle verplichte velden zijn ingevuld.');
          }
      });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/reminder/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseReminderCorrespondenceSteps validate={true} validator={() => { return this.allowNextStep(); }} current={0} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>

                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item required label="Aanhef">
                          { getFieldDecorator('salutation_type', {
                            initialValue: this.state.salutation_type ? this.state.salutation_type : 'anonymous',
                            rules: [{ required: true, message: 'Maak een keuze' }],

                          })(
                            <Select>
                              <Select.Option key={'personal'}>Gepersonaliseerde aanhef</Select.Option>
                              <Select.Option key={'anonymous'}>Aangepaste aanhef</Select.Option>
                            </Select>
                          ) }
                        </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item label="Aanhef indien contactpersoon onbekend">
                            {getFieldDecorator('salutation', {
                              initialValue: this.state.salutation,
                              rules: [{ required: true, message: 'Vul een aanhef in' }],
                            })(
                              <Input />
                            )}
                        </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>

                      {/*<div align={'right'} style={{position: 'absolute', right: 12, zIndex: 9999}}>
                        <Button style={{display: (this.state.show_email ? 'inline-block' : 'none')}} onClick={() => { this.props.form.setFieldsValue({['message_email']: this.state.message_letter}); }}>Tekst brief post overnemen</Button>
                        <Button style={{marginLeft: 10}} onClick={() => {
                            if(!this.state.show_email)
                            {
                                this.saveAndContinue(false, 'letter');
                            }
                            else if(this.state.show_email)
                            {
                                this.saveAndContinue(false, 'email');
                            }
                        }}>Voorbeeld</Button>
                      </div>*/}

                      <Tabs
                        defaultActiveKey="1"
                        onChange={(key) => {
                            this.setState({
                                show_letter: key == 1 ? true : false,
                                show_email: key == 2 ? true : false,
                            });
                        }}
                        tabBarExtraContent={
                            <div>
                              <Button style={{display: (this.state.show_email ? 'inline-block' : 'none')}} onClick={() => {
                                  this.props.form.setFieldsValue({['message_email']: this.state.message_letter});
                              }}>Tekst brief post overnemen</Button>

                              <Button style={{marginLeft: 4}} onClick={() => {
                                  this.setState({
                                      show_copy_text_modal: true,
                                      copy_letter: this.state.show_email == false ? true : false,
                                      copy_email_mail: this.state.show_email == true ? true : false,
                                  });
                              }}>Tekst overnemen uit</Button>

                              <Button style={{marginLeft: 4}} onClick={() => {
                                  if(!this.state.show_email)
                                  {
                                      this.saveAndContinue(false, 'letter');
                                  }
                                  else if(this.state.show_email)
                                  {
                                      this.saveAndContinue(false, 'email');
                                  }
                              }}>Voorbeeld</Button>
                            </div>
                        }
                        >

                        <TabPane tab="Brief per post *" key="1">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item required label="Tekst brief per post">
                                    { getFieldDecorator('message_letter', {
                                      initialValue: this.state.message_letter,
                                      rules: [{ required: true, message: 'Vul een tekst in' }],
                                      valuePropName: 'value',
                                      trigger: 'onEditorChange',
                                      // onEditorChange: (value, editor) => { this.setState({ message_letter: value }); }
                                    })(
                                          <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} editorWidth={696} messageChanged={(e) => { this.setState({ message_letter: e }); }} />
                                    ) }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>

                        { this.props.support_base_form.nr_of_emails ?
                        <TabPane tab="Brief als bijlage in de e-mail *" key="2">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item required label="Tekst brief als bijlage in de e-mail">
                                      { getFieldDecorator('message_email', {
                                        initialValue: this.state.message_email,
                                        rules: [{ required: true, message: 'Vul een tekst in' }],
                                        valuePropName: 'value',
                                        trigger: 'onEditorChange',
                                        // onEditorChange: (value, editor) => { this.setState({ message_email: value }); }
                                      })(
                                        <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} editorWidth={696} messageChanged={(e) => { this.setState({ message_email: e }); }} />
                                      ) }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane> : null }

                      </Tabs>
                    </Col>
                  </Row>

                  <Modal
                      title="Tekst overnemen uit"
                      visible={this.state.show_copy_text_modal}
                      onCancel={() => this.setState({ show_copy_text_modal: false, selected_form_id: false, copy_letter: this.state.show_letter, copy_email_mail: this.state.show_email })}
                      onOk={() => { this.useTexts(); }}
                      okText="Plaatsen"
                      >
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Formulier">
                                    <Select
                                        style={{ width: '100%' }}
                                        onChange={(value) => { this.setState({ selected_form_id: value }); }}
                                        value={this.state.selected_form_id}
                                        >
                                        { this.props.support_base_forms && this.props.support_base_forms.map((object, index) => {
                                            if(object.id != this.props.match.params.form_id && object.has_letter)
                                            {   // not this form
                                                return <Option value={object.id}>{object.name}</Option>
                                            }
                                        }) }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    checked={this.state.copy_letter}
                                    value="mail"
                                    onChange={(event) => { this.setState({ copy_letter: event.target.checked }); }}
                                    >
                                    Voor brief per post
                                </Checkbox>
                                <br />
                                <br />
                                <Checkbox
                                    checked={this.state.copy_email_mail}
                                    value="email_mail"
                                    onChange={(event) => { this.setState({ copy_email_mail: event.target.checked }); }}
                                    >
                                    Voor brief als bijlage in de e-mail
                                </Checkbox>
                            </Col>
                        </Row>
                  </Modal>

              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseReminderCorrespondenceLetterForm = Form.create({ name: 'support_base' })(SupportBaseReminderCorrespondenceLetter);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form : state.support_base.support_base_form,
        support_base_forms : state.support_base.forms,
        correspondences : state.support_base.correspondences,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        getSupportBaseFormsByProjectAction : bindActionCreators(getSupportBaseFormsByProjectAction, dispatch),
        updateSupportBaseCorrespondenceAction : bindActionCreators(updateSupportBaseCorrespondenceAction, dispatch),
        checkSupportBaseFormDocumentAction : bindActionCreators(checkSupportBaseFormDocumentAction, dispatch),
        getSupportBaseFormCorrespondencesAction : bindActionCreators(getSupportBaseFormCorrespondencesAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseReminderCorrespondenceLetterForm);
