import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess, showError } from '../../../utils/Notifications'
import { } from '../../../actions/choiceActions';
import ChoiceSideMenu from './ChoiceSideMenu';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Typography,
    Button,
    Divider,
    Input,
    Select,
    Switch,
    InputNumber,
    Tag,
    Menu,
    Dropdown,
    Modal,
    Upload,
    Alert,
    Checkbox,
    Timeline,
    Radio,
    Badge,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import { API_URL, JOB_URL } from '../../../constants/settings';
// import { getSupportBaseStatusAction, resendSupportBaseToAction, sendSupportBaseRemindersToAction, uploadSignedFormAction, downloadSignedPDFDocumentAction, downloadTransactionPDFAction, remindSupportBaseToAction, downloadSignedDocumentsAction } from '../../../actions/supportBaseActions';
import { getChoiceStatusAction, downloadDocumentsAction, uploadFormAction, multiUpdateChoiceFormAddressesStatusAction, addChoiceFormAddressTransactionAction, getChoiceFormsByProjectAction, searchChoiceStatusAction, unlockAddressChoiceFormAction } from '../../../actions/choiceActions';
import { getGroupsAction } from '../../../actions/groupsActions';
import 'moment/locale/nl';

const { Title, Text } = Typography;

const Option = Select.Option;

const Search = Input.Search;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>);

const { confirm } = Modal;

const sanitizeString = (str) =>
{
    str = str.replace(/[^a-z0-9]/gim, "");

    return str.trim();
}

class ChoiceFormsStatus extends Component {

  state = {
    disabled: true,
    edit: true,
    selectedRowKeys: [],
    selectedRowKeysAddresses: [],
    selectedRowKeysForms: [],
    selectedId: null,
    modalVisible: false,
    can_write: false,

    modalUploadFormVisible: false,
    selected_address_id: false,
    isFetching: false,
    modalShowTransactionLog: false,
    modalForm: false,

    amount_direction: 'paid',
    amount: null,

    expandedRowKeys: [],
    currentDataSource: [],

    addresses: [],

    filters: {},
    expandedRowKeys: [],
    all_open: false,
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write'),
          isFetching: true,
      });

      this.loadData();
  }

  loadData(init = true)
  {
      this.props.getGroupsAction({'projectId': this.props.match.params.id});

      this.props.getChoiceFormsByProjectAction(this.props.match.params.id).then(() => {

          this.props.getChoiceStatusAction(this.props.match.params.id).then(() => {

              this.setState({ addresses: this.props.addresses }, () => {

                  if(init)
                  {
                      // expand all
                      var expandedRowKeys = [];

                      this.state.addresses.map((record) => {
                          expandedRowKeys.push('address-'+record.id);
                      });

                      this.setState({ expandedRowKeys: expandedRowKeys }, () => {

                          this.setState({ expandedRowKeys: [] });
                      });
                  }

                  this.setState({ isFetching: false });
              });
          });
      });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  showUploadForm(address_id, id)
  {
      this.setState({
          modalUploadFormVisible: true,
          selected_address_id: address_id,
          selected_id: id
      });
  }

  validateEmail(string)
  {
      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  handleUploadForm()
  {
      var files = [];

      files['submitted_form'] = this.state.filelist[0];

      this.props.uploadFormAction(this.state.selected_id, this.state.has_additional_work, (this.state.is_paid ? 'paid' : 'not-paid'), files, this.state.amount, this.state.amount_direction).then(() => {
          this.props.getChoiceStatusAction(this.props.match.params.id).then(() => {

              this.setState({ addresses: this.props.addresses });

              showSuccess('Upload succesvol');

              this.setState({
                  modalUploadFormVisible: false,
                  modalUploadSignedFormVisible: false,
                  modalAdditionalWorkVisible: false,
                  modalIsPaidVisible: false,
                  selectedRowKeys: [],
                  has_additional_work: false,
                  modal_has_additional_work: false,
                  modal_is_paid: false,
                  is_paid: false,
                  isFetching: false,
                  filelist: [],
              });
          });
      });
  }

  handleDownloadForms()
  {
      if(!this.validateEmail(this.state.download_forms_email))
      {
          showError('Vul een geldig e-mailadres in');
      }
      else
      {
          this.props.downloadDocumentsAction(this.props.match.params.id, { ids: this.state.selectedRowKeysForms, email: this.state.download_forms_email }).then(() => {

              showSuccess('Verzoek verstuurd');

              this.setState({
                  modalDownloadFormsVisible: false,
                  selectedRowKeysForms: []
              });
          });
      }
  }

  handleUpdateAdditionalWorkStatus()
  {
      this.handleUpdateStatus('has_additional_work', this.state.modal_has_additional_work);
  }

  handleUpdateIsPaidStatus()
  {
      this.handleUpdateStatus('payment_status', (this.state.modal_is_paid ? 'paid' : 'not-paid'));
  }

  handleUpdateStatus(status_field, status_value)
  {
      this.props.multiUpdateChoiceFormAddressesStatusAction(this.props.match.params.id, this.state.selectedRowKeys, status_field, status_value).then(() => {

          showSuccess('Succesvol opgeslagen');

          this.props.getChoiceStatusAction(this.props.match.params.id).then(() => {
              this.setState({ addresses: this.props.addresses });
              this.setState({ isFetching: false });
          });

          this.setState({
              modalAdditionalWorkVisible: false,
              modalIsPaidVisible: false,
              selectedRowKeys: [],
              modal_has_additional_work: false,
              modal_is_paid: false,
              // isFetching: false
          });


      });
  }

  handleSubmit = (e) => { };

  addTransaction  = (e) => {

      this.setState({ isFetching: true });

      try
      {
          this.props.addChoiceFormAddressTransactionAction(this.state.modalForm.id, {type: this.state.amount_direction, amount: this.state.amount}).then(() => {

              this.setState({ modalShowTransactionLog: false });

              this.props.getChoiceStatusAction(this.props.match.params.id).then(() => {

                  this.setState({ addresses: this.props.addresses });
                  showSuccess('Transactie verwerkt');
                  this.setState({ isFetching: false });
              });
          });
      }
      catch(e)
      {
          this.setState({ isFetching: false });
      }
  };

  onSelectAddressChange = (value, record) => {
    if(this.state.selectedRowKeysAddresses.includes(value))
    { // deselect
        this.setState({ selectedRowKeysAddresses: this.state.selectedRowKeysAddresses.filter((v) => v !== value)}, () => {
            var filteredRowKeysForms = this.state.selectedRowKeysAddresses;

            record.children.map((form, index) => {
                filteredRowKeysForms = filteredRowKeysForms.filter((v) => v !== value+'-form-'+form.id);
            });

            this.setState({ selectedRowKeysForms: filteredRowKeysForms});
        });
    }
    else
    { // select
      this.setState({ selectedRowKeysAddresses: [...this.state.selectedRowKeysAddresses, value]});

      var selected_form_ids = [];

      record.children.map((form, index) => {
          selected_form_ids.push(value+'-form-'+form.id);
      });

      this.setState({ selectedRowKeysForms: this.state.selectedRowKeysForms.concat(selected_form_ids)});
    }
  }

  onSelectFormChange = (value, record) => {
    if(this.state.selectedRowKeysForms.includes(value))
    { // deselect
      this.setState({ selectedRowKeysForms: this.state.selectedRowKeysForms.filter((v) => v !== value)});

      // deselect address (no longer all address forms are selected)
      this.setState({ selectedRowKeysAddresses: this.state.selectedRowKeysAddresses.filter((v) => v !== 'address-'+record.address_id)});
    }
    else
    { // select
      this.setState({ selectedRowKeysForms: [...this.state.selectedRowKeysForms, value]});

      // TODO: check if all address forms are selected (if so: select address)
    }
  }

  onCheckAllAddresses = (value) => {

    if(value.target.checked)
    {
      var child_ids = [];
      this.state.addresses.map((v) => {
        v.children && v.children.map((child) => {
          child_ids.push('address-'+child.address_id+'-form-'+child.id);
        })
      });

      var ids = [];

      this.state.addresses.map((record) => {
          if(record.children)
          {
              ids.push('address-'+record.id);
          }
          else if(record.form)
          {
              child_ids.push('address-'+record.form.address_id+'-form-'+record.form.id);
          }
          else
          {
              child_ids.push('address-'+record.address_id+'-form-'+record.id);
          }
      }); //(record.form ? 'address-'+record.form.address_id+'-form-'+record.form.id : 'address-'+record.id)); ///'address-'+v.form.address_id+'-form-'+v.form.id);

      this.setState({ selectedRowKeysAddresses: ids});
      this.setState({ selectedRowKeysForms: child_ids});

      // console.log([ids, child_ids]);
    }
    else
    {
      this.setState({ selectedRowKeysAddresses: []});
      this.setState({ selectedRowKeysForms: []});
    }
  }

  onSearch = (value) => {

    this.props.searchChoiceStatusAction(this.props.match.params.id, value).then(() => {

        this.setState({ addresses: this.props.addresses });
    });
  }

  getRowClassName(record, index)
  {
      var class_name = (record.children && record.children.length > 1 ? 'address-row' : 'form-row');

      // group(s)
      if(record.groups) // record.children && record.children.length > 1)
      {
          record.groups.map((group, index) => {
              class_name = class_name + (class_name ? ' ' : '') + 'group-' + sanitizeString(group.name);
          })
      }
      // else if(record.form)
      // {
      //     // form
      //     // class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form.form_name);
      // }
      // else
      // {
      //     // form
      //     // class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form_name);
      // }

      // form(s)
      if(record.children && record.children.length > 1)
      {
          // record.children.map((child) => {
          //     class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(child.form_name);
          // });

          class_name += ' forms-combined';
      }
      else if(record.form)
      {
          // form
          class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form.form_name);
      }
      else
      {
          // form
          class_name = class_name + (class_name ? ' ' : '') + 'form-' + sanitizeString(record.form_name);
      }

      // filled in form
      var has_form = ((record.form && (record.form.submitted_form || record.form.form_submitted || record.form.form_status != null)) || record.filled_in_form);

      class_name = class_name + (class_name ? ' ' : '') + 'filled-in' + (record.children && record.children.length > 1 ? '' : ((record.form || record.choice_form_id) && has_form) ? ' filled-yes' : ' filled-no');

      // locked
      var is_locked = record.locked ? true : false;

      class_name = class_name + (class_name ? ' ' : '') + 'locked' + (record.children && record.children.length > 1 ? '' : (record.locked ? ' locked-yes' : ' locked-no'));

      // transactions
      class_name = class_name + (class_name ? ' ' : '') + 'transactions ' + (record.children && record.children.length > 1 ? '' : (record.total_not_paid == 0 ? 'all-paid' : (record.total_not_paid > 0 ? 'not-paid' : 'to-much-paid')));

      return class_name;
  }

  applyFilters()
  {
      var filters = this.state.filters;

      var combined_classes = [];

      if(filters.locked && filters.locked.length >= 1)
      {
          filters.locked.map((value) => {
              combined_classes.push("locked-"+sanitizeString(value));
          });
      }/**/

      if(filters.group_name && filters.group_name.length >= 1)
      {
          filters.group_name.map((value) => {
              combined_classes.push("group-"+sanitizeString(value));
          });
      }/**/

      if(filters.form_name && filters.form_name.length >= 1)
      {
          filters.form_name.map((value) => {
              combined_classes.push("form-"+sanitizeString(value));
          });
      }/**/

      if(filters.submitted_form && filters.submitted_form.length >= 1)
      {
          filters.submitted_form.map((value) => {
              combined_classes.push("filled-"+value);
          });
      }/**/

      if(filters.total_not_paid_formatted && filters.total_not_paid_formatted.length >= 1)
      {
          filters.total_not_paid_formatted.map((value) => {
              combined_classes.push(value);
          });
      }/**/

      //
      if(combined_classes.length > 0)
      {
          setTimeout(() => {

              var expandedRowKeys = [];

              this.state.addresses.map((record) => {
                  expandedRowKeys.push('address-'+record.id);
              });

              this.setState({ expandedRowKeys: expandedRowKeys }, () => {

                  setTimeout(() => {

                      // hide all
                      var els = document.querySelectorAll('tr.address-row');

                      els.forEach((el) => {
                          if(el.style) el.style.display = 'none';
                      });

                      var els = document.querySelectorAll('tr.form-row');

                      els.forEach((el) => {
                          if(el.style) el.style.display = 'none';
                      });

                      // show rows of combined classes
                      var class_query = (combined_classes.length ? 'tr.' : '') + combined_classes.join('.');

                      if(class_query)
                      {
                          var els = document.querySelectorAll(class_query);

                          els.forEach((el) => {
                              if(el.style) el.style.display = 'table-row';
                          });
                      }

                  }, 100);
              });

          }, 1);
      }

      // no filter(s)
      if((!filters.form_name || !filters.form_name.length) && (!filters.submitted_form || !filters.submitted_form.length) && (!filters.total_not_paid_formatted || !filters.total_not_paid_formatted.length))
      {
          // close all
          this.setState({ expandedRowKeys: [] }, () => {

            // show all
            this.props.choice_forms.map((form) => {

                var els = document.querySelectorAll('tr[class*="forms-combined"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });

                var els = document.querySelectorAll('tr[class*="form-'+sanitizeString(form.description)+'"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });
            });

            this.props.choice_forms.map((form) => {

                var els = document.querySelectorAll('tr[class*="filled-in"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });
            });

            this.props.choice_forms.map((form) => {

                var els = document.querySelectorAll('tr[class*="transactions"]');

                els.forEach((el) => {
                    if(el.style) el.style.display = 'table-row';
                });
            });
          });
      }
  }

  toggleAddresses()
  {
      var toggle_ids = [];

      if(!this.state.all_open)
      {
          this.state.addresses.map((obj) => {

              if(obj.choice_form_id){
                  toggle_ids.push('form-'+obj.id);
              }
              else if(obj.children && obj.children.length > 1){
                  toggle_ids.push('address-'+obj.id);
              }
          });

          this.setState({
              expandedRowKeys: toggle_ids,
              all_open: true
          });
      }
      else
      {
          this.setState({
              expandedRowKeys: [],
              all_open: false
          });
      }
  }

  unlockAddressChoiceForm(id)
  {
      var this_obj = this;

      confirm({
        content: <div>
            <h2>Formulier openzetten</h2>

            <p>
                Weet je zeker dat je het formulier open wilt zetten om antwoorden aan te kunnen passen?<br />
                Hiermee worden de handtekeningen die al zijn gezet verwijderd en moet er mogelijk de betaalstatus worden gecontroleerd.
            </p>
        </div>,
        onOk() {

            // this_obj.props.unlockFormEntryAction(form_id, id).then(() => {
            //
            //     this_obj.props.getFormsStatusAction(this.props.match.params.id).then(() => {
            //
            //         this_obj.setState({ addresses: this.props.addresses }, () => {
            //
            //             this_obj.setState({ isFetching: false });
            //         });
            //     });
            // });

            //
            this_obj.unlockAddressChoiceFormConfirmed(id);
        },
        onCancel() { },
      });
  }

  unlockAddressChoiceFormConfirmed(id)
  {
      this.props.unlockAddressChoiceFormAction(id).then(() => {

          this.loadData(false);
      });
  }

  render()
  {
      const columns = [{
          title: 'Adres',
          dataIndex: 'full_address',
          render: (text, record) => {
            var label = text;

            if(!record.children && record.address_id) // && !label)
            {
                this.state.addresses.map((address) => {
                    if(address.id == record.address_id)
                    {
                        label = address.full_address;
                    }
                });
            }

            return <span>{label}</span>
        },
        filters: this.state.addresses && this.state.addresses.map((elem) => {
          return {
            text: elem.full_address,
            value: 'address-'+elem.id,
          }
        }),
        filterMultiple: true,
        onFilter: (value, record) => 'address-'+record.id === value,
      },
      {
        title: 'Groep',
        dataIndex: 'group_name',
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        // onFilter: (value, record) => this.groupsFilter(value, record),
        // filteredValue: filteredInfo.groups || null,
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      },
      {
          title: 'Formulier',
          dataIndex: 'form_name',
          render: (text, record) => (
            <span>
              {record.children && record.children.length > 1 ? 'Meerdere formulieren' : (record.form ? record.form.form_name : record.form_name)}
            </span>
          ),
          filters: this.props.choice_forms && this.props.choice_forms.map((elem) => {
            return {
              text: elem.description,
              value: elem.description,
            }
          }),
          filterMultiple: false,
          // onFilter: (value, record) => {
          //     if(record.children && record.children.length > 1)
          //     {
          //         var found = false;
          //
          //         record.children.map((child) => {
          //             if(child.form_name === value)
          //             {
          //                 found = true;
          //             }
          //         })
          //
          //         return found;
          //     }
          //     else if(record.form){ return record.form.form_name === value; }
          //     else{ return record.form_name === value; }
          // },
      },
      {
          title: 'Ingevuld formulier',
          dataIndex: 'locked',
          render: (text, record) => {

            var record_id = record.form ? record.form.id : record.id;
            var address_id = record.form ? record.form.address_id : record.address_id;

            var has_form = (record.submitted_form || record.form_status != null || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) /*|| record.filled_in_form*/);

            return (
                <span>
                { record.form || record.choice_form_id ?
                    has_form ?
                    <Dropdown trigger={['click']} overlay={(
                        <Menu>
                          <Menu.Item onClick={() => { this.setState({ modalShowTransactionLog: true, amount: null, modalForm: (record.choice_form_id ? record : record.form) }); }}>Transacties</Menu.Item>
                          <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
                          <Menu.Item style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}><a target="_blank" href={JOB_URL + '/choice/address/' + record_id  + '/form/view/'+record.filename+'?token=' + localStorage.getItem('authToken')}>Bekijk formulier</a></Menu.Item>
                          {/*<Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
                          <Menu.Item style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}><a target="_blank" href={JOB_URL + '/choice/address/' + record_id  + '/form/download?token=' + localStorage.getItem('authToken')}>Download formulier</a></Menu.Item>*/}
                          <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
                          <Menu.Item disabled={!this.state.can_write} onClick={() => { this.showUploadForm(address_id, record_id); }}>Formulier updaten</Menu.Item>
                          <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) ? 'list-item' : 'none'}}></Menu.Divider>
                          <Menu.Item disabled={!this.state.can_write} onClick={() => { this.unlockAddressChoiceForm(record_id); }}>Formulier ontgrendelen</Menu.Item>
                        </Menu>
                      )}>
                      <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                    :
                    <Button disabled={!this.state.can_write} onClick={() => { this.showUploadForm(address_id, record_id); }} style={this.state.can_write ? {color: 'red', borderColor: 'red'} : {}} >Toevoegen</Button>
                  :
                  null }
                </span>
            );
          },
          filterMultiple: false,
          filters: [
            {
              text: 'Volledig ingevuld',
              value: 'yes',
            },
            {
              text: 'Niet (volledig) ingevuld',
              value: 'no',
            }
          ]
      },
      {
          title: 'Openstaand',
          dataIndex: 'total_not_paid_formatted',
          render: (text, record) => (
            <span style={{color: record.total_not_paid > 0 ? 'red' : 'black'}}>{text}</span>
          ),
          filterMultiple: false,
          filters: [
            {
              text: 'Alles betaald',
              value: 'all-paid',
          },
          {
            text: 'Nog niet alles betaald',
            value: 'not-paid',
          },
          {
            text: 'Te veel betaald',
            value: 'to-much-paid',
          }],
      },
      {
          title: 'Voldaan',
          dataIndex: 'total_paid_formatted',
          render: (text, record) => (
            <span style={{color: record.total_not_paid > 0 ? 'orange' : 'black'}}>{text}</span>
          )
      },
      /*{
          title: 'Transacties',
          dataIndex: 'transactions',
          render: (text, record) => {

            var choice_form_id = (record.choice_form_id ? record.choice_form_id : (record.form ? record.form.choice_form_id : false));

            return <span>
                { choice_form_id ?
                <Button onClick={() => { this.setState({ modalShowTransactionLog: true, amount: null, modalForm: (record.choice_form_id ? record : record.form) }); }}>Transacties</Button>
                :
                null }
            </span>
          }
      }/**/
      ];

      const { selectedRowKeys } = this.state;

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        getCheckboxProps: record => ({
          disabled: record.form_submitted ? false : true
        }),
      };

      const { getFieldDecorator } = this.props.form;

      return (
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="3"/>

            <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
                <Col span={12}>
                    <Button onClick={(e) => { this.toggleAddresses(); }}>{this.state.all_open ? 'Alles dichtklappen' : 'Alles openklappen'}</Button>
                </Col>
                <Col span={12}>
                    <Search
                      placeholder="Zoeken..."
                      onSearch={value => this.onSearch(value)}
                      style={{ width: 200, float: 'right' }}
                    />
                </Col>
            </Row>

            <Row>
              <Col span={24}>

                  { !this.state.isFetching ?

                  <CustomTable
                    rowKey={(record) => {
                      if(record.choice_form_id) {
                        return 'form-'+record.id;
                      }
                      else if(record.children && record.children.length && record.children.length > 1) {
                        return 'address-'+record.id;
                      }
                      // else
                      // {
                      //   return false;
                      // }
                    }}
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.state.addresses}
                    indentSize="0"
                    loading={this.props.isFetching}
                    rowClassName={(record, index) => this.getRowClassName(record, index)}
                    onChange={(pagination, filters, sorter, extra) => {
                        this.setState({
                          filteredInfo: extra['currentDataSource']
                        });

                        this.setState({ filters: filters}, () => {
                            this.applyFilters();
                        });
                    }}
                    // onExpand={(expanded, record) => { this.applyFilters(); }}
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpandedRowsChange={(id) => {
                        this.setState({expandedRowKeys: id});
                        // this.applyFilters();
                    }}
                    />
                    :
                    <CustomTable
                      columns={columns}
                      dataSource={[]}
                      indentSize="0"
                      loading={true}
                      />
                    }

              </Col>
            </Row>

          </div>


          <Modal
            title='Upload formulier'
            visible={this.state.modalUploadFormVisible}
            onCancel={() => this.setState({ modalUploadFormVisible: false })}
            onOk={() => this.handleUploadForm()}
            okText="Opslaan"
          >
              <Form className={'ant-form--custom'} onSubmit={() => { this.handleUploadForm(); }} layout={'vertical'} colon={true}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item multiple={false} label="Formulier">
                        <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="Wanneer een nieuw formulier wordt opgegeven, wordt het bestaande formulier hiermee overschreven." />

                        <Upload
                          accept="application/pdf"
                          showUploadList={true}
                          fileList={this.state.filelist}
                          defaultFileList={this.state.filelist}
                          beforeUpload={(file, filelist) => {
                            if(file.size < 8000000) {
                              if (file.type !== 'application/pdf') {
                                  this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                              }
                              else if(file.size <= 0 && this.state.modalAddAttachmentVisible)
                              {
                                  this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                              }
                              else if(file.size > 0)
                              {
                                  filelist = [];
                                  filelist.push(file);

                                  this.setState({filelist: filelist});

                                  this.setState({ errorDocumentFile: false, errorDocumentSizeFile: false });
                              }
                            } else {
                                this.setState({ errorDocumentFile: false, errorDocumentSizeFile: true });
                            }

                            return false;
                          }} onRemove={() =>{
                            this.setState({filelist: null});
                          }}>
                          <Button >
                            <UploadOutlined /> Selecteer een bestand
                          </Button>
                        </Upload>

                        {this.state.errorDocumentFile ? (<Alert message="Upload een pdf bestand" type="error" showIcon />) : null}
                        {this.state.errorDocumentSizeFile ? (<Alert message="Bestand mag niet groter zijn dan 8MB" type="error" showIcon />) : null}

                      </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Nieuwe transactie">
                            <Radio.Group value={this.state.amount_direction} onChange={(event) => { this.setState({ amount_direction: event.target.value }); }}>
                                <Radio value={'paid'}>Betaald door klant</Radio><br />
                                <Radio value={'refunded'}>Teruggestort aan klant</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Bedrag">
                            <Input placeholder="bedrag" type="number" min="0" step="0.01" value={this.state.amount} onChange={(event) => { var value = event.target.value; if(value <= 0 && value != '0' && value != '0.' && value != '0.0' && value != '0.00'){ value = ''; } this.setState({ amount: value }); }} />
                        </Form.Item>
                    </Col>
                </Row>
              </Form>
            </Modal>


            <Modal
              title='Download formulieren'
              visible={this.state.modalDownloadFormsVisible}
              onCancel={() => this.setState({ modalDownloadFormsVisible: false })}
              onOk={() => this.handleDownloadForms()}
              okText="Verstuur"
            >
                <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Verstuur download link naar">
                                <Input
                                  placeholder={'e-mail adres'}
                                  value={this.state.download_forms_email}
                                  onChange={(event) => { this.setState({ download_forms_email: event.target.value }); }}
                                  />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>


            <Modal
                title='Verander meerwerk status'
                visible={this.state.modalAdditionalWorkVisible}
                onCancel={() => this.setState({ modalAdditionalWorkVisible: false })}
                onOk={() => this.handleUpdateAdditionalWorkStatus()}
                okText="Opslaan"
            >
                  <Form className={'ant-form--custom'} onSubmit={() => { this.handleUpdateAdditionalWorkStatus(); }} layout={'vertical'} colon={true}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item hasFeedback label="Meerwerk">
                              <Checkbox
                                  name="paid"
                                  checked={this.state.modal_has_additional_work ? true : false}
                                  onChange={(e) => { this.setState({ modal_has_additional_work: e.target.checked }); }}
                              />
                            </Form.Item>
                        </Col>
                      </Row>
                    </Form>
            </Modal>


            <Modal
                title='Verander betaald status'
                visible={this.state.modalIsPaidVisible}
                onCancel={() => this.setState({ modalIsPaidVisible: false })}
                onOk={() => this.handleUpdateIsPaidStatus()}
                okText="Opslaan"
            >
                    <Form className={'ant-form--custom'} onSubmit={() => { this.handleUpdateIsPaidStatus(); }} layout={'vertical'} colon={true}>
                      <Row gutter={24}>
                          <Col span={12}>
                              <Form.Item hasFeedback label="Is betaald">
                                <Checkbox
                                    name="paid"
                                    checked={this.state.modal_is_paid ? true : false}
                                    onChange={(e) => { this.setState({ modal_is_paid: e.target.checked }); }}
                                />
                              </Form.Item>
                          </Col>
                        </Row>
                      </Form>
            </Modal>

            <Modal
              title='Tijdlijn'
              visible={this.state.modalShowTransactionLog}
              // okText={'Ok'}
              // onOk={() => this.setState({ modalShowTransactionLog: false })}
              okButtonProps={{ style: { display: 'none' } }}
              cancelText={'Sluiten'}
              onCancel={() => this.setState({ modalShowTransactionLog: false })}
              style={{ width: 800 }}
              width={800}
            >
              <div>

              <Form className={'ant-form--custom'} onSubmit={() => {}} layout={'vertical'} colon={true}>

                <Row>
                    <Col span={9}>
                        <Form.Item hasFeedback label="Nieuwe transactie">
                            <Radio.Group value={this.state.amount_direction} disabled={!this.state.can_write} onChange={(event) => { this.setState({ amount_direction: event.target.value }); }}>
                                <Radio value={'paid'}>Betaald door klant</Radio><br />
                                <Radio value={'refunded'}>Teruggestort aan klant</Radio>
                                <Radio value={'add_costs'}>Kosten toevoegen</Radio>
                                <Radio value={'deduct_costs'}>Kosten in mindering</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item hasFeedback label="Bedrag">
                            <Input placeholder="bedrag" disabled={!this.state.can_write} type="number" min="0" step="0.01" value={this.state.amount} onChange={(event) => { var value = event.target.value; if(value <= 0 && value != '0' && value != '0.' && value != '0.0' && value != '0.00'){ value = ''; } this.setState({ amount: value }); }} />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{paddingTop: 28}}>
                        &nbsp;<Button type={'new'} disabled={!this.state.can_write} onClick={(event) => { this.addTransaction(); }}>Verwerk</Button>
                    </Col>
                  </Row>
                </Form>

                <Divider />

                <Row>
                    <Col span={24}>
                        <h3>Openstaand bedrag: {this.state.modalForm.total_not_paid_formatted}</h3>
                    </Col>
                </Row>

                  <Row>
                    <Col span={24}>
                        <Timeline>
                          { this.state.modalForm && this.state.modalForm.transactions && this.state.modalForm.transactions.map((transaction, index) => {
                            return (<Timeline.Item>{moment(transaction.created_at).format('DD/MM/YYYY - HH:mm') + ' - ' + transaction.description}{transaction.ticket_url ? ' - ' : ''}{ (transaction.ticket_url ? <Link onClick={() => { window.open(transaction.ticket_url); }}>Transactie bewijs</Link> : '')}</Timeline.Item>)
                          }) }
                        </Timeline>
                    </Col>
                  </Row>
              </div>
            </Modal>

        </div>
      );
    }
}

const ChoiceFormsStatusForm = Form.create({ name: 'projects_choice_forms' })(ChoiceFormsStatus);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.choice.isFetching,
    isError : state.choice.isError,
    addresses : state.choice.addresses,
    choice_forms : state.choice.forms,
    groups: state.group.groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChoiceStatusAction : bindActionCreators(getChoiceStatusAction, dispatch),
    downloadDocumentsAction : bindActionCreators(downloadDocumentsAction, dispatch),
    uploadFormAction : bindActionCreators(uploadFormAction, dispatch),
    multiUpdateChoiceFormAddressesStatusAction : bindActionCreators(multiUpdateChoiceFormAddressesStatusAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    addChoiceFormAddressTransactionAction: bindActionCreators(addChoiceFormAddressTransactionAction, dispatch),
    getChoiceFormsByProjectAction: bindActionCreators(getChoiceFormsByProjectAction, dispatch),
    searchChoiceStatusAction: bindActionCreators(searchChoiceStatusAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    unlockAddressChoiceFormAction : bindActionCreators(unlockAddressChoiceFormAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormsStatusForm);
