import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';

class SurveySteps extends React.Component {

  onChange = current => {
    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/detail`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/questions` + (this.props.version > 1 ? '-v' + this.props.version : ''));
    }

    if(/*this.props.project.is_public == false &&*/ current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/addresses`);
    }

    if(/*(this.props.project.is_public == true && current == 2) ||*/ current == 3) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/results` + (this.props.version > 1 ? '-v' + this.props.version : ''));
    }
  }

  render () {
    return (
      <Steps type="navigation" size="small" version={this.props.version ? this.props.version : 2} current={this.props.current} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Opmaken" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.surveyid ? false : true} title="Vragen" />
        { /*this.props.project.is_public == false ?*/ <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.surveyid ? false : true} title="Adressen" /> /*: null*/}
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.surveyid ? false : true} title="Resultaten" />
      </Steps>
    );
  }
}

export default SurveySteps;
