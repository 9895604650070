import React, { Component } from 'react';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Row,
  Col,
  Select,
  Card,
  Button,
  message,
  Upload,
  Divider,
  Typography,
  InputNumber,
  Alert,
  Radio,
} from 'antd';
import { API_URL } from '../../../constants/settings'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storeLetterPaperAction, getLetterPaperAction, patchLetterPaperAction, resetLetterPaperAction } from '../../../actions/letterPapersActions';
import { showSuccess, showError } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
// import './CorporateIdentityDetail.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';
const { Title, Text } = Typography;

class CorporateIdentityDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
        letter_cover: [],
        continuation_sheet: [],
        errorlettercover: false,
        errorcontinuationsheet: false,
        saving: false
    };
  }

  componentDidMount() {
    this.props.resetLetterPaperAction();

    if(this.props.match.params.id) {
      this.props.getLetterPaperAction(this.props.match.params.id);
    }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {

      if(this.state.letter_cover == '' && !this.props.letterpaper.letterpaper_letter_cover_url) {
        this.setState({errorlettercover: true});
        err = true;
      } else {
        this.setState({errorlettercover: false});
      }

      if(this.state.continuation_sheet == '' && !this.props.letterpaper.letterpaper_continuation_sheet_url) {
        err = true;
        this.setState({errorcontinuationsheet: true});
      } else {
        this.setState({errorcontinuationsheet: false});
      }

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }
      else
      {
        this.setState({ saving: true });
      }

      // remap address position
      values.margin_address_top = 50;

      if(values.address_position == 'right')
      {
          values.margin_address_left = 110;
      }
      else
      {
          values.margin_address_left = 25;
      }

      if(this.props.match.params.id)
      {
        this.props.patchLetterPaperAction(this.props.match.params.id, values, {'letterpaper_letter_cover': this.state.letter_cover, 'letterpaper_continuation_sheet': this.state.continuation_sheet}).then(() => {

          this.props.history.push(`/library/corporate-identity/`);

          this.setState({ saving: false });

          showSuccess();
        });
      }
      else
      {
        this.props.storeLetterPaperAction(values, {'letterpaper_letter_cover': this.state.letter_cover, 'letterpaper_continuation_sheet': this.state.continuation_sheet}).then(() => {

          this.props.history.push(`/library/corporate-identity/`);

          this.setState({ saving: false });

          showSuccess();
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Briefpapier', 'link': '/library/corporate-identity/'}, {'label': this.props.letterpaper.name ? this.props.letterpaper.name : 'Toevoegen'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='4' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.props.HaveAbility('library' ,'write') || this.state.saving} type="new" onClick={() => this.handleSubmit(null)}>
                        { this.state.saving ? <LoadingOutlined style={{ marginRight: 8 }} /> : null }
                        Opslaan
                    </Button>
                    <Link to={`/library/corporate-identity/`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Divider></Divider>
            <Row>
              <Col span={24}>
                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
                    <Row gutter={24}></Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item required label="Naam">
                          {getFieldDecorator('name', {
                            initialValue: this.props.letterpaper.name,
                            rules: [{ required: true, message: 'Vul een naam in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        {/*<Col span={12}>
                        <Form.Item required label="Type">
                        {getFieldDecorator('type', {
                          initialValue: this.props.letterpaper.type,
                          rules: [{ required: true, message: 'Vul een type in' }],
                        })(
                          <Input />
                        )}
                        </Form.Item>
                        </Col>*/}

                        <Col span={12}>
                            <Form.Item required label="Voorblad">
                                {this.props.letterpaper && this.props.letterpaper.letterpaper_letter_cover_url ? (
                                    <img style={{border: '1px solid #e8e8e8', marginRight: 15}} width="200" src={this.props.letterpaper ? this.props.letterpaper.letterpaper_letter_cover_url : ''} />
                                ) :null}
                                  <Upload accept=".jpg,.pdf" beforeUpload={(file) => {
                                      this.setState({letter_cover: file});
                                      return false;
                                    }} onRemove={() =>{
                                        this.setState({letter_cover: null});
                                    }}>
                                  <Button type="dashed"><UploadOutlined /> Upload een JPG of PDF bestand</Button>
                                </Upload>
                                {this.state.errorlettercover ? (<Alert message="Upload een bestand" type="error" showIcon />) : null}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item required label="Vervolgblad">
                                {this.props.letterpaper && this.props.letterpaper.letterpaper_continuation_sheet_url ? (
                                <img style={{border: '1px solid #e8e8e8', marginRight: 15}} width="200" src={this.props.letterpaper ? this.props.letterpaper.letterpaper_continuation_sheet_url : ''} />
                                ) : null}
                                <Upload accept=".jpg,.pdf" beforeUpload={(file) => {
                                        this.setState({continuation_sheet: file});
                                        return false;
                                    }} onRemove={() =>{
                                        this.setState({continuation_sheet: null});
                                    }}>
                                  <Button type="dashed"><UploadOutlined /> Upload een JPG of PDF bestand</Button>
                                </Upload>
                                {this.state.errorcontinuationsheet ? (<Alert message="Upload een bestand" type="error" showIcon />) : null}
                            </Form.Item>
                        </Col>

                        <Divider />

                          <div>
                            <Col span={24}>
                              <Title level={4}>Adresvak</Title>
                            </Col>
                            <Col span={24}>
                              <Form.Item required label="Positie adres">
                                {getFieldDecorator('address_position', {
                                  initialValue: ((this.props.letterpaper && this.props.letterpaper.margin_address_left) || !this.props.letterpaper || !this.props.letterpaper.margin_address_left) <= 65 ? 'left' : 'right',
                                  rules: [{ required: true, message: 'Vul een type in' }],
                                })(
                                <Radio.Group>
                                  <Radio value={'left'}>Links</Radio>
                                  <Radio value={'right'}>Rechts</Radio>
                                </Radio.Group>
                                )}
                              </Form.Item>
                            </Col>

                            {/*<Col span={24}>
                              <Alert style={{marginBottom: 15}} message="Let op: verstuur je correspondentie via Cobee? De ingevulde marges zijn de benodigde marges om het adres passend in het enveloppe venster zichtbaar te krijgen." type="error" showIcon />
                            </Col>
                            <Col span={12}>
                                <Form.Item required label="Marge adresvak bovenzijde (mm)">
                                    {getFieldDecorator('margin_address_top', {
                                      initialValue: this.props.letterpaper && this.props.letterpaper.margin_address_top ? this.props.letterpaper.margin_address_top : 50,
                                      rules: [{ required: true, message: 'Vul een marge in' }],
                                    })(
                                      <InputNumber min={0} />
                                    )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item required label="Marge adresvak linkerzijde (mm)">
                                    {getFieldDecorator('margin_address_left', {
                                      initialValue: this.props.letterpaper && this.props.letterpaper.margin_address_left ? this.props.letterpaper.margin_address_left : 25,
                                      rules: [{ required: true, message: 'Vul een marge in' }],
                                    })(
                                      <InputNumber min={0} />
                                    )}
                                </Form.Item>
                            </Col>*/}

                            <Divider />
                          </div>

                        <Col span={24}>
                          <Title level={4}>Briefpapier</Title>
                        </Col>

                        <Col span={12}>
                        <Form.Item required label="Marge brief boven (mm)">
                        {getFieldDecorator('margin_letter_top', {
                          initialValue: this.props.letterpaper && this.props.letterpaper.margin_letter_top ? this.props.letterpaper.margin_letter_top : 20,
                          rules: [{ required: true, message: 'Vul een marge in' }],
                        })(
                          <InputNumber min={0} />
                        )}
                        </Form.Item>
                        </Col>

                        <Col span={12}>
                        <Form.Item required label="Marge brief onder (mm)">
                        {getFieldDecorator('margin_letter_bottom', {
                          initialValue: this.props.letterpaper && this.props.letterpaper.margin_letter_bottom ? this.props.letterpaper.margin_letter_bottom : 20,
                          rules: [{ required: true, message: 'Vul een marge in' }],
                        })(
                          <InputNumber min={0} />
                        )}
                        </Form.Item>
                        </Col>

                        <Col span={12}>
                        <Form.Item required label="Marge brief links (mm)">
                        {getFieldDecorator('margin_letter_left', {
                          initialValue: this.props.letterpaper && this.props.letterpaper.margin_letter_left ? this.props.letterpaper.margin_letter_left : 20,
                          rules: [{ required: true, message: 'Vul een marge in' }],
                        })(
                          <InputNumber min={0} />
                        )}
                        </Form.Item>
                        </Col>

                        <Col span={12}>
                        <Form.Item required label="Marge brief rechts (mm)">
                        {getFieldDecorator('margin_letter_right', {
                          initialValue: this.props.letterpaper && this.props.letterpaper.margin_letter_right ? this.props.letterpaper.margin_letter_right : 20,
                          rules: [{ required: true, message: 'Vul een marge in' }],
                        })(
                          <InputNumber min={0} />
                        )}
                        </Form.Item>
                        </Col>

                        <Col span={12}>
                        <Form.Item required label="Extra marge bovenzijde vervolgpagina (mm)">
                        {getFieldDecorator('extra_margin_continuation_top', {
                          initialValue: this.props.letterpaper && this.props.letterpaper.extra_margin_continuation_top ? this.props.letterpaper.extra_margin_continuation_top : 20,
                          rules: [{ required: true, message: 'Vul een marge in' }],
                        })(
                          <InputNumber min={0} />
                        )}
                        </Form.Item>
                        </Col>
                        </Row>
                      </Form>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

const CorporateIdentityDetailForm = Form.create({ name: 'horizontal_login' })(CorporateIdentityDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.letterpaper.isFetching,
      isError : state.letterpaper.isError,
      letterpaper: state.letterpaper.letterpaper,
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    getLetterPaperAction : bindActionCreators(getLetterPaperAction, dispatch),
    patchLetterPaperAction : bindActionCreators(patchLetterPaperAction, dispatch),
    resetLetterPaperAction : bindActionCreators(resetLetterPaperAction, dispatch),
    storeLetterPaperAction : bindActionCreators(storeLetterPaperAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateIdentityDetailForm);
