import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSurveyAction, getSurveyQuestionsAction, patchSurveyAction, addSurveyAction, addSurveyQuestionAction, getSurveyQuestionAction, resetSurveyAction, updateSurveyQuestionAction } from '../../../../../actions/surveysActions';
import { getLibrariesAction } from '../../../../../actions/librariesActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import AddQuestion from '../Form/AddQuestion';
import SurveySteps from '../Steps/SurveySteps';
import { showSuccess } from '../../../../../utils/Notifications';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class SurveyQuestion extends Component {

    constructor(props) {
      super(props);
    }
    componentDidMount() {
      this.props.resetSurveyAction();
      // //
      if(this.props.match.params.surveyid && this.props.match.params.questionid) {
          this.props.getSurveyQuestionAction(this.props.match.params.surveyid, this.props.match.params.questionid);
      }
    }

    patchSurvey = () => {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          if(this.props.match.params.questionid) {
            this.props.updateSurveyQuestionAction(this.props.match.params.surveyid, this.props.match.params.questionid, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/questions`);
            });
          } else {
            this.props.addSurveyQuestionAction(this.props.match.params.surveyid, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/questions`);
            });
          }
          showSuccess('Succesvol opgeslagen')
        }
      })
    }

    render() {
        return (
          <div>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button type="new" onClick={(e) => {}}>Opslaan en volgende</Button>
                </div>
              </Col>
            </Row>
            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <SurveySteps version={this.props.survey ? this.props.survey.version : 2} current={1} {...this.props}/>
                </Card>
              </Col>
            </Row>
          <Card>
            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button disabled={!this.props.HaveAbility('survey' ,'write')} type="new" onClick={() => this.patchSurvey()}>Opslaan</Button>
                  <Link to={`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/questions`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col span={24}>
                  <AddQuestion wrappedComponentRef={(form) => this.form = form} {...this.props}/>
              </Col>
            </Row>
          </Card>
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.survey.isFetching,
      isError : state.survey.isError,
      survey: state.survey.survey,
      surveyquestion: state.survey.surveyquestion,
      project: state.project.project,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
      addSurveyAction : bindActionCreators(addSurveyAction, dispatch),
      patchSurveyAction : bindActionCreators(patchSurveyAction, dispatch),
      resetSurveyAction : bindActionCreators(resetSurveyAction, dispatch),
      addSurveyQuestionAction : bindActionCreators(addSurveyQuestionAction, dispatch),
      getSurveyQuestionAction : bindActionCreators(getSurveyQuestionAction, dispatch),
      updateSurveyQuestionAction : bindActionCreators(updateSurveyQuestionAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestion);
