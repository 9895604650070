import api from '../utils/api'

export const getProjectsAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  var customer_id = false;

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  if(customer_id)
  {
      return api('get','/customer/'+customer_id+'/projects', null, true).then((response) => {

        dispatch({
          type: 'PROJECTS_ACTION_SUCCESS',
          payload: response.data
        });

        return true;
      }).catch((e) => {
        dispatch({
          type: 'PROJECTS_ACTION_ERROR',
        })
        return false;
      });
  }
  else
  {
      return false;
  }
}

export const searchProjectsAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  var customer_id = false;

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  if(customer_id)
  {
      return api('post','/projects/'+customer_id+'/search', {'search': search}, true).then((response) => {
        dispatch({
          type: 'SEARCH_PROJECTS_ACTION_SUCCESS',
          payload: response.data
        });
        return true;
      }).catch((e) => {
        dispatch({
          type: 'SEARCH_PROJECTS_ACTION_ERROR',
        })
        return false;
      });
  }
  else
  {
      return false;
  }
}

export const getProjectTransactions = (project_id, period) => dispatch => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  return api('post','/project/' + project_id + '/transactions', {'date': period}, true).then((response) => {
    dispatch({
      type: 'PROJECT_BILLING_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTS_ACTION_ERROR',
    })
    return false;
  });
}

export const cloneProjectAction = (id) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  return api('get','/project/'+id+'/clone', null, true).then((response) => {
    dispatch({
      type: 'CLONE_PROJECT_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_PROJECTS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchProjectUsersAction = (projectid, params) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  return api('post','/project/'+projectid+'/users/search', params, true).then((response) => {
    dispatch({
      type: 'PROJECTUSERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'PROJECTS_ACTION_ERROR',
    })
    return false;
  });
}

export const getProjectAction = (id) => dispatch => {

  dispatch({
    type: 'PROJECT_ACTION_FETCH'
  })

  return api('get','/project/'+id, null, true).then((response) => {
    dispatch({
      type: 'PROJECT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTS_ACTION_ERROR',
    })
    return false;
  });
}

export const getPersonalizationAction = (id) => dispatch => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  return api('post','/project/' + id + '/personalization', null, true).then((response) => {
    dispatch({
      type: 'PROJECT_ACTION_SUCCESS',
      payload: response.data.project
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTS_ACTION_ERROR',
    })
    return false;
  });
}

export const addProjectAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ADD_PROJECT_ACTION_FETCH'
  });

  if(!params.customer_id) {
      params.customer_id = localStorage.getItem('customerId');
  }

  if(!params.customer_id && getState().auth.selectedCustomer) {
    params.customer_id = getState().auth.selectedCustomer.id;
  }

  if(!params.user_id) {
    params.user_id = getState().auth.user.user.id;
  }

  return api('post','/projects/'+params.customer_id, params, true).then((response) => {
    dispatch({
      type: 'ADD_PROJECT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADD_PROJECT_ACTION_ERROR',
    })
    return false;
  });
}

export const patchProjectAction = (id, values) => dispatch => {

  dispatch({
    type: 'PROJECT_ACTION_FETCH'
  })

  return api('post','/project/'+id, values, true).then((response) => {
    dispatch({
      type: 'PROJECT_ACTION_SUCCESS',
      payload: response.data.project
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECT_ACTION_ERROR',
    })
    return false;
  });
}

export const patchPersonalizationProjectAction = (id, values) => dispatch => {

  dispatch({
    type: 'PROJECT_ACTION_FETCH'
  })

  return api('post','/project/'+id+'/personalization', values, true).then((response) => {
    dispatch({
      type: 'PROJECT_ACTION_SUCCESS',
      payload: response.data.project
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECT_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteProjectsAction = (params) => (dispatch, getState) => {

  var customer_id = false;

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/projects/'+customer_id+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_PROJECTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const statusProjectsAction = (params) => (dispatch, getState) => {

  var customer_id = false;

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/projects/'+customer_id+'/multistatus', params, true).then((response) => {
    dispatch({
      type: 'STATUS_PROJECTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const syncProjectModulesAction = (id, params) => dispatch => {
  return api('post','/project/'+ id +'/syncmodules', params, true).then((response) => {
    dispatch({
      type: 'SYNC_MODULES_PROJECTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const getProjectUsersAction = (projectid, params) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  // return api('get','/project/'+projectid+'/users', null, true).then((response) => {
  //
  //   dispatch({
  //     type: 'PROJECTUSERS_ACTION_SUCCESS',
  //     payload: response.data
  //   });
  // }).catch((e) => {
  //   dispatch({
  //     type: 'PROJECTUSERS_ACTION_ERROR',
  //   })
  //   return false;
  // });
  return api('get','/userrights/project/'+projectid, null, true).then((response) => {

    dispatch({
      type: 'PROJECTUSERS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTUSERS_ACTION_ERROR',
    })
    return false;
  });
}

export const getProjectUserAction = (projectid, userid) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTUSER_ACTION_FETCH'
  })

  //return api('get','/project/'+projectid+'/user/'+userid, null, true).then((response) => {
  return api('get','/userrights/user/'+userid+'/project/'+projectid, null, true).then((response) => {

    dispatch({
      type: 'PROJECTUSER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTUSER_ACTION_ERROR',
    })
    return false;
  });
}

export const getUnassignedProjectUsersAction = (projectid) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTUSER_ACTION_FETCH'
  })

  return api('get','/userrights/project/'+projectid+'/unassigned/', null, true).then((response) => {

    dispatch({
      type: 'UNASSIGNED_PROJECTUSER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTUSER_ACTION_ERROR',
    })
    return false;
  });
}

export const addProjectUserAction = (projectid, params) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  return api('post','/project/'+projectid+'/users', params, true).then((response) => {

    dispatch({
      type: 'PROJECTUSER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTUSERS_ACTION_ERROR',
    })
    return false;
  });
}

export const updateProjectUserAction = (projectid, userid, params) => (dispatch, getState) => {

  dispatch({
    type: 'PROJECTUSER_ACTION_FETCH'
  })

  // return api('post','/project/'+projectid+'/user/'+userid, params, true).then((response) => {
  return api('post','/userrights/assign/user/'+userid+'/project/'+projectid, params, true).then((response) => {

    dispatch({
      type: 'PROJECTUSER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTUSER_ACTION_ERROR',
    })
    return false;
  });
}

export const removeProjectUsersAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'PROJECTS_ACTION_FETCH'
  })

  return api('post','/project/'+projectid+'/users/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_PROJECTUSERS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PROJECTUSERS_ACTION_ERROR',
    })
    return false;
  });
}

export const resetProjectUserAction = () => dispatch => {
  dispatch({
    type: 'RESET_PROJECT_USER_ACTION'
  })
}

/* address field(s) */

export const getAddressFieldsAction = (project_id) => dispatch => {

    dispatch({
      type: 'PROJECT_FIELDS_ACTION_FETCH'
    })

    return api('get','/address-fields/'+project_id, null, true).then((response) => {

      dispatch({
        type: 'PROJECT_FIELDS_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'PROJECT_FIELDS_ACTION_ERROR',
      })
      return false;
    });
}

export const removeAddressFieldsAction = (project_id, params) => dispatch => {

    return api('post','/address-fields/'+project_id+'/multidelete', params, true).then((response) => {
      dispatch({
        type: 'DELETE_ADDRESS_FIELDS_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'PROJECT_FIELDS_ACTION_ERROR',
      });
      return false;
    });
}

export const sortAddressFieldsAction = (project_id, params) => dispatch => {
    return api('post','/address-fields/'+project_id+'/sortorder', params, true).then((response) => {
      dispatch({
        type: 'MOVE_ADDRESS_FIELDS_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'PROJECT_FIELDS_ACTION_ERROR',
      });
      return false;
    });
}

export const getAddressFieldAction = (id) => dispatch => {
    dispatch({
      type: 'PROJECT_FIELDS_ACTION_FETCH'
    })

    return api('get','/address-field/'+id, null, true).then((response) => {

      dispatch({
        type: 'PROJECT_FIELD_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'PROJECT_FIELDS_ACTION_ERROR',
      })
      return false;
    });
}

export const addAddressFieldAction = (project_id, params) => dispatch => {
    dispatch({
      type: 'PROJECT_FIELDS_ACTION_FETCH'
    })

    return api('post','/address-fields/'+project_id, params, true).then((response) => {

      dispatch({
        type: 'PROJECT_FIELD_ADD_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'PROJECT_FIELDS_ACTION_ERROR',
      })
      return false;
    });
}

export const updateAddressFieldAction = (id, params) => dispatch => {
    dispatch({
      type: 'PROJECT_FIELDS_ACTION_FETCH'
    })

    return api('post','/address-field/'+id, params, true).then((response) => {

      dispatch({
        type: 'PROJECT_FIELD_UPDATE_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'PROJECT_FIELDS_ACTION_ERROR',
      })
      return false;
    });
}

export const resetAddressFieldAction = () => dispatch => {
  dispatch({
    type: 'RESET_ADDRESS_FIELD_ACTION'
  })
}
