import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Popconfirm, Col, Card, Button, Drawer, Divider, Tag, Modal, Upload, Input, Typography, Tooltip, Select, Slider, Switch } from 'antd';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';

import CustomEditor from '../../../components/CustomEditor/CustomEditor';
const ButtonGroup = Button.Group;

const Option = Select.Option;

class PatchInformation extends Component {

  add = () => {
    this.props.addIndicatorAction();
  }

  remove = (index) => {
    this.props.removeIndicatorAction(index);
  }

  getCategoryOptions()
  {
      var categories = [];

      this.props.categories && this.props.categories.map((category, index) => {

          categories.push({
              label: ''+category.name,
              value: ''+category.id
          });
      });

      return categories;
  }

  getSelectedCategoryOptions()
  {
      var categories = [];

      this.props.library && this.props.library.category_ids && this.props.library.category_ids.map((category_id, index) => {

          this.props.categories && this.props.categories.map((category, index) => {

              if(category.id == category_id)
              {
                  // categories.push(''+category.name);
                  categories.push(''+category.id);
              }
          });

      });

      return categories;
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Afbeelding">
              {getFieldDecorator('image_id', {
                initialValue: this.props.information && this.props.information.image_id,
              })(
                <AddFromMedia
                  hideProjectBrowser={true}
                  initialMediaId={this.props.information && this.props.information.image_id}
                  initialMediaUrl={this.props.information && this.props.information.image_url}
                  onMediaSelection={(item) => this.props.form.setFieldsValue({'image_id': item.id})}
                  onMediaRemove={() => this.props.form.setFieldsValue({'image_id': ''})}
                  />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} >
          <Col span={12}>
            <Form.Item required hasFeedback label="Titel">
              {getFieldDecorator('title', {
                initialValue: this.props.information && this.props.information.title,
                rules: [{ required: true, message: 'Vul een titel in' }],
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Icoon">
              {getFieldDecorator('icons', {
                initialValue: this.props.information && this.props.information.icons && this.props.information.icons.map(icon => String(icon))
              })(
                <Select mode="multiple" placeholder="Selecteer een icoon">
                  <Option value="elektra">Elektra</Option>
                  <Option value="gas">Gas</Option>
                  <Option value="kou">Kou</Option>
                  <Option value="thuis_blijven">Thuis blijven</Option>
                  <Option value="tocht">Tocht</Option>
                  <Option value="toegang_noodzakelijk">Toegang noodzakelijk</Option>
                  <Option value="verwarming">Verwarming</Option>
                  <Option value="voorbereiden">Voorbereiden</Option>
                  <Option value="warm_water">Warm water</Option>
                  <Option value="water">Water</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          {this.props.HaveRole(['super-admin']) ? (
            <Col span={12}>
              <Form.Item required hasFeedback label="Sector">
                {getFieldDecorator('sector_id', {
                  initialValue: this.props.information && this.props.information.sector_id ? String(this.props.information.sector_id) : '1',
                  rules: [{ required: true, message: 'Selecteer een sector'}],
                })(
                  <Select placeholder="Selecteer een sector">
                    <Option value="1">Cobee</Option>
                    <Option value="2">Onderhoud / Renovatie</Option>
                    <Option value="3">Infra</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            ) : null}

          {/*<Col span={12}>
            <Form.Item required hasFeedback label="Weergeven onder menu-item">
              {getFieldDecorator('menuitem_id', {
                initialValue: this.props.information && this.props.information.menuitem_id ? String(this.props.information.menuitem_id) : '1',
                rules: [{ required: true, message: 'Selecteer een menu-item'}],
              })(
                <Select
                placeholder="Selecteer een menu-item"
                >
                  <Option value="1">Werk binnen</Option>
                  <Option value="2">Werk buiten</Option>
                  <Option value="3">Overige informatie</Option>
                </Select>
              )}
            </Form.Item>
          </Col>*/}
          <Col span={12}>
              <Form.Item hasFeedback label="Categorieën">
                {getFieldDecorator('categories', {
                    // initialValue: this.props.mediafile && this.props.mediafile.category_ids ? this.props.mediafile.category_ids : [],
                    initialValue: this.getSelectedCategoryOptions(),
                    validateTrigger: ["onChange"]
                })(
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Geef één of meerdere categorieën op"
                    >
                  { this.getCategoryOptions().map((v, k) => (
                      <Select.Option key={String(v.value)}>{v.label}</Select.Option>
                  )) }
                  </Select>
                )}
              </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Toegang tot woning vereist">
                {getFieldDecorator('access_to_home_required', {
                    valuePropName: 'checked',
                    initialValue: this.props.information && this.props.information.access_to_home_required ? true : false
                })(
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                )}
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={24}>
          <Col span={24}>
            <Typography.Title level={4}>Indicatoren</Typography.Title>
          </Col>
        </Row>
          { this.props.indicators && this.props.indicators.map((element, index) => {
            return (
              <Row>
                <Col span={12}>
                  <Form.Item>
                    { getFieldDecorator(`indicator_values[${index}]`, {
                      initialValue: element.value,
                    })(
                      <Slider />
                    ) }
                  </Form.Item>

                </Col>
                <Col span={10}>
                  <Form.Item>
                    { getFieldDecorator(`indicator_names[${index}]`, {
                      initialValue: element.name,
                      rules: [{ required: true, message: 'Vul een omschrijving in'}],
                    })(
                      <Input placeholder='Omschrijving indicator' />
                    ) }
                  </Form.Item>

                </Col>
                <Col span={2}>
                  <Form.Item>
                    <Popconfirm
                      title="Weet je het zeker?"
                      onConfirm={() => this.remove(index)}
                      onCancel={() => {}}
                      icon={<QuestionCircleOutlined style={{ color: '#f5222d' }} />}
                      okText="Ja"
                      cancelText="Nee"
                    >
                      <Button type={'link'} style={{ width: '100%', color: '#f5222d' }}>
                        Verwijderen
                      </Button>
                    </Popconfirm>
                    </Form.Item>
                </Col>
              </Row>
            );

            })}

          <Row>
            <Col span={24}>
              <Form.Item>
                <Button type="dashed" onClick={this.add} style={{ }}>
                  <PlusOutlined /> Indicator toevoegen
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item required label="Bericht">
                  { getFieldDecorator('content', {
                    initialValue: this.props.information && this.props.information.content,
                    valuePropName: 'value',
                    trigger: 'onEditorChange',
                    rules: [{ required: true, message: 'Vul een bericht in' }],
                  })(
                    <CustomEditor placeholders={'info'} project_id={false} customer_id={localStorage.getItem('customerId')} />
                  ) }
                </Form.Item>
              </Col>
            </Row>

            { this.props.selectedCustomer && this.props.selectedCustomer.id == 1 && this.props.HaveRole(['super-admin']) ?
            <Row>
              <Col span={24}>
                  <Form.Item label="Gebruik voor demo project">
                    {getFieldDecorator('use_for_demo', {
                        valuePropName: 'checked',
                        initialValue: this.props.information && this.props.information.use_for_demo ? true : false
                    })(
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            />
                    )}
                  </Form.Item>
              </Col>
            </Row> : null }

          </Form>
    );
      }
    }

export default Form.create({ name: 'patch_partner_form' })(PatchInformation);
