import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import ChoiceSideMenu from '../ChoiceSideMenu';
import ChoiceFormSteps from './ChoiceFormSteps'
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
  InputNumber,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getChoiceFormByIdAction, addChoiceFormToProjectAction, updateChoiceFormAction } from '../../../../actions/choiceActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { getEnvelopesAction } from '../../../../actions/envelopesActions';
import { getLetterPapersAction } from '../../../../actions/letterPapersActions';
import 'dayjs/locale/nl';
dayjs.locale('nl');

class ChoiceFormSettings extends React.Component {

  state = {
      disabled: true,
      edit: true,
      loading: false,

      description: '',
      allow_address_to_submit_entry: false,
      address_deadline: null,

      c_enable_mollie: false,
      mollie_account_id: false,
      allow_user_to_add_payments: false,
      show_transaction_tickets_in_dossier: false,

      // user_needs_to_sign_form: false,
      // occupant_needs_to_sign_form: false,
      nr_of_signatures: 1,

      c_letter_paper_id: 0,
      c_form_letter_paper_id: 0,
      c_envelope_id: 0,
      c_return_envelope_id: 0,
      c_allow_change_order: false,
      c_allow_change_till: null,
      c_force_direct_payment: false,
      c_allow_postpay: false,
      c_cart_additional_work_direct_payment: 'Onderstaand treft u een overzicht aan van de door u gekozen opties.<br />De gekozen meerwerkopties kunt u betalen in de volgende stap of na het ondertekenen van het formulier.',
      c_cart_additional_work_no_direct_payment: 'Onderstaand treft u een overzicht aan van de door u gekozen opties.<br />Wij verzoeken u vriendelijk om het openstaande bedrag binnen 14 dagen over te maken op (rekeningnummer).',
      c_status_direct_payment: 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.',
      c_status_postpay: 'Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.',
      c_email_subject_direct_payment: 'Keuzeformulier ontvangen',
      c_email_message_direct_payment: 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
<br />\
Wij hebben het door u ingevulde keuzeformulier en de betaling in goede orde ontvangen. U heeft gekozen voor de volgende opties:<br />\
<br />\
<placeholder id="current" class="cobee-tag">keuzeformulier: lijst gekozen opties</placeholder><br />\
<br />\
Het keuzeformulier kunt u ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
<br />\
Met vriendelijke groet,<br />\
<placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
      c_email_subject_postpay: 'Keuzeformulier ontvangen',
      c_email_message_postpay: 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
<br />\
Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. U heeft gekozen voor de volgende opties:<br />\
<br />\
<placeholder id="current" class="cobee-tag">keuzeformulier: lijst gekozen opties</placeholder><br />\
<br />\
De gekozen meerwerkopties bedragen totaal <placeholder id="current" class="cobee-tag">keuzeformulier: bedrag meerwerkopties</placeholder>.<br />\
<br />\
De gekozen meerwerkopties dienen nog te worden betaald. Wij verzoeken u vriendelijk om het openstaande bedrag binnen 14 dagen over te maken op (rekeningnummer).<br />\
<br />\
Het keuzeformulier kunt u ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
<br />\
Met vriendelijke groet,<br />\
<placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
      c_email_subject_no_additional_work: 'Keuzeformulier ontvangen',
      c_email_message_no_additional_work: 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
<br />\
Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. U heeft gekozen voor de volgende opties:<br />\
<br />\
<placeholder id="current" class="cobee-tag">keuzeformulier: lijst gekozen opties</placeholder><br />\
<br />\
Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
<br />\
Met vriendelijke groet,<br />\
<placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
      disable_prices: false,

      can_write: false,
  }

  constructor(props) {
    super(props);
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  componentDidMount()
  {
    this.setState({
        can_write: this.props.HaveAbility('choice' ,'write'),
        loading: true
    });

    // get choice form
    if(this.props.match.params.form_id && this.props.match.params.form_id != 'add')
    {
        this.props.getChoiceFormByIdAction(this.props.match.params.form_id, false).then(() => {

            this.setState({
                description: this.props.choice_form.description,
                allow_address_to_submit_entry: this.props.choice_form.allow_address_to_submit_entry ? true : false,
                address_deadline: this.props.choice_form.address_deadline,

                c_enable_mollie: this.props.choice_form.c_enable_mollie,
                mollie_account_id: this.props.choice_form.mollie_account_id,
                allow_user_to_add_payments: this.props.choice_form.allow_user_to_add_payments ? true : false,
                show_transaction_tickets_in_dossier: this.props.choice_form.show_transaction_tickets_in_dossier ? true : false,

                // user_needs_to_sign_form: this.props.choice_form.user_needs_to_sign_form,
                // occupant_needs_to_sign_form: this.props.choice_form.occupant_needs_to_sign_form,

                c_letter_paper_id: this.props.choice_form.c_letter_paper_id ? this.props.choice_form.c_letter_paper_id : 0,
                c_form_letter_paper_id: this.props.choice_form.c_form_letter_paper_id ? this.props.choice_form.c_form_letter_paper_id : 0,
                c_envelope_id: this.props.choice_form.c_envelope_id ? this.props.choice_form.c_envelope_id : 0,
                c_return_envelope_id: this.props.choice_form.c_return_envelope_id ? this.props.choice_form.c_return_envelope_id : 0,
                c_allow_change_order: this.props.choice_form.c_allow_change_order,
                c_allow_change_till: this.props.choice_form.c_allow_change_till ? dayjs(this.props.choice_form.c_allow_change_till, 'YYYY-MM-DD HH:mm:ss') : false,
                c_force_direct_payment: this.props.choice_form.c_force_direct_payment,
                c_allow_postpay: this.props.choice_form.c_allow_postpay,
            });

            // this.setState({ occupant_nr_of_signatures: this.props.choice_form.occupant_nr_of_signatures ? this.props.choice_form.occupant_nr_of_signatures : 1 });
            // this.setState({ user_nr_of_signatures: this.props.choice_form.user_nr_of_signatures ? this.props.choice_form.user_nr_of_signatures : 1 });
            this.setState({ nr_of_signatures: this.props.choice_form.nr_of_signatures ? this.props.choice_form.nr_of_signatures : 1 });
            this.setState({ require_to_sign_form: this.props.choice_form.require_to_sign_form ? true : false });
            this.setState({ signature_required: this.props.choice_form.signature_not_required ? false : true });
            this.setState({ signature_not_required: this.props.choice_form.signature_not_required ? true : false });
            this.setState({ require_to_sign_form_legally: this.props.choice_form.require_to_sign_form_legally ? true : false });
            this.setState({ use_sms_authentication_for_signing: this.props.choice_form.use_sms_authentication_for_signing ? true : false });
            this.setState({ disable_prices: this.props.choice_form.disable_prices ? true : false });

            try
            {
                var signature_description = JSON.parse(this.props.choice_form.signature_description);

                // for(let nr = 1; nr <= this.props.choice_form.occupant_nr_of_signatures; nr++)
                // {
                //     this.setState({ ['signature_occupant_'+nr+'_description']: signature_description['occupant_'+nr] });
                // }
                //
                // for(let nr = 1; nr <= this.props.choice_form.user_nr_of_signatures; nr++)
                // {
                //     this.setState({ ['signature_user_'+nr+'_description']: signature_description['user_'+nr] });
                // }

                for(let nr = 1; nr <= this.props.choice_form.nr_of_signatures; nr++)
                {
                    this.setState({ ['signature_'+nr+'_description']: signature_description[nr] });
                }
            }
            catch(e)
            {
                console.log('init signature err', e);
            }

            if(this.props.choice_form.c_cart_additional_work_direct_payment){
                this.setState({ c_cart_additional_work_direct_payment : this.props.choice_form.c_cart_additional_work_direct_payment });
            }

            if(this.props.choice_form.c_cart_additional_work_no_direct_payment){
                this.setState({ c_cart_additional_work_no_direct_payment : this.props.choice_form.c_cart_additional_work_no_direct_payment });
            }

            if(this.props.choice_form.c_cart_no_additional_work){
                this.setState({ c_cart_no_additional_work : this.props.choice_form.c_cart_no_additional_work });
            }

            if(this.props.choice_form.c_status_direct_payment){
                this.setState({ c_status_direct_payment : this.props.choice_form.c_status_direct_payment });
            }

            if(this.props.choice_form.c_status_postpay){
                this.setState({ c_status_postpay : this.props.choice_form.c_status_postpay });
            }

            if(this.props.choice_form.c_email_subject_direct_payment){
                this.setState({ c_email_subject_direct_payment : this.props.choice_form.c_email_subject_direct_payment });
            }

            if(this.props.choice_form.c_email_message_direct_payment){
                this.setState({ c_email_message_direct_payment : this.props.choice_form.c_email_message_direct_payment });
            }

            if(this.props.choice_form.c_email_subject_postpay){
                this.setState({ c_email_subject_postpay : this.props.choice_form.c_email_subject_postpay });
            }

            if(this.props.choice_form.c_email_message_postpay){
                this.setState({ c_email_message_postpay : this.props.choice_form.c_email_message_postpay });
            }

            if(this.props.choice_form.c_email_subject_no_additional_work){
                this.setState({ c_email_subject_no_additional_work : this.props.choice_form.c_email_subject_no_additional_work });
            }

            if(this.props.choice_form.c_email_message_no_additional_work){
                this.setState({ c_email_message_no_additional_work : this.props.choice_form.c_email_message_no_additional_work });
            }

            this.setState({ loading: false });

            // get envelopes
            this.props.getEnvelopesAction(this.props.choice_form.customer_id);

            // get letters
            this.props.getLetterPapersAction(this.props.choice_form.customer_id);
        });

        // // get envelopes
        // this.props.getEnvelopesAction(this.props.choice_form.customer_id);
        //
        // // get letters
        // this.props.getLetterPapersAction(this.props.choice_form.customer_id);
    }
    else
    {   // default(s)
        this.setState({

            description: '',
            allow_address_to_submit_entry: false,
            address_deadline: null,

            c_enable_mollie: null,
            allow_user_to_add_payments: false,

            // user_needs_to_sign_form: 0,
            // occupant_needs_to_sign_form: 0,
            nr_of_signatures: 1,

            c_letter_paper_id: 0,
            c_form_letter_paper_id: 0,
            c_envelope_id: 0,
            c_return_envelope_id: 0,
            c_allow_change_order: null,
            c_allow_change_till: false,
            c_force_direct_payment: null,
            c_allow_postpay: null,
            disable_prices: false,

            loading: false
        });
    }
  }

  saveToggle(value, name) {

      this.setState({ [name]: value ? true : false });
  }

  saveStep(url = false)
  {
      this.saveAndContinue(url);
  }

  saveAndContinue(url = false)
  {
      var settings = {
          description: this.state.description,
          allow_address_to_submit_entry: this.state.allow_address_to_submit_entry,
          address_deadline: this.state.address_deadline,

          c_enable_mollie: this.state.c_enable_mollie ? true : false,
          mollie_account_id: this.state.mollie_account_id,
          allow_user_to_add_payments: this.state.allow_user_to_add_payments ? true : false,
          show_transaction_tickets_in_dossier: this.state.show_transaction_tickets_in_dossier ? true : false,

          // user_needs_to_sign_form: this.state.user_needs_to_sign_form,
          // occupant_needs_to_sign_form: this.state.occupant_needs_to_sign_form,

          // occupant_nr_of_signatures: this.state.occupant_nr_of_signatures,
          // user_nr_of_signatures: this.state.user_nr_of_signatures,
          nr_of_signatures: this.state.nr_of_signatures,
          require_to_sign_form: this.state.require_to_sign_form ? true : false,
          signature_not_required: this.state.signature_required ? false : true,
          require_to_sign_form_legally: this.state.require_to_sign_form_legally ? true : false,
          use_sms_authentication_for_signing: this.state.use_sms_authentication_for_signing ? true : false,

          c_letter_paper_id: this.state.c_letter_paper_id,
          c_form_letter_paper_id: this.state.c_form_letter_paper_id,
          c_envelope_id: this.state.c_envelope_id,
          c_return_envelope_id: this.state.c_return_envelope_id,
          c_allow_change_order: this.state.c_allow_change_order,
          c_allow_change_till: this.state.c_allow_change_till,

          disable_prices: this.state.disable_prices,
          c_force_direct_payment: this.state.c_force_direct_payment,
          c_allow_postpay: this.state.c_allow_postpay,

          c_cart_additional_work_direct_payment: this.state.c_cart_additional_work_direct_payment,

          c_cart_additional_work_no_direct_payment: this.state.c_cart_additional_work_no_direct_payment,

          c_cart_no_additional_work: this.state.c_cart_no_additional_work,

          c_status_direct_payment: this.state.c_status_direct_payment,

          c_status_postpay: this.state.c_status_postpay,

          c_email_subject_direct_payment: this.state.c_email_subject_direct_payment,
          c_email_message_direct_payment: this.state.c_email_message_direct_payment,

          c_email_subject_postpay: this.state.c_email_subject_postpay,
          c_email_message_postpay: this.state.c_email_message_postpay,

          c_email_subject_no_additional_work: this.state.c_email_subject_no_additional_work,
          c_email_message_no_additional_work: this.state.c_email_message_no_additional_work,
      };

      try
      {
          for(let nr = 1; nr <= this.state.nr_of_signatures; nr++)
          {
              settings['signature_'+nr+'_description'] = this.state['signature_'+nr+'_description'];
          }
      }
      catch(e){ console.log('err (save)', e); }

      if(!settings.description)
      {
          showError('Geef een omschrijving op');
      }
      else if(!settings.disable_prices && settings.c_enable_mollie && !settings.mollie_account_id)
      {
          showError('Geef een Mollie account op');
      }
      else
      {
          if(this.props.match.params.form_id == 'add')
          {
              this.props.addChoiceFormToProjectAction(this.props.match.params.id, settings).then(() => {

                  showSuccess('Succesvol opgeslagen');

                  if(!url){ url = `/projects/${this.props.match.params.id}/choice/form/compose/${this.props.choice_form.id}`; }

                  this.props.history.push(url);
              });
          }
          else
          {
              this.props.updateChoiceFormAction(this.props.match.params.form_id, settings).then(() => {

                  showSuccess('Succesvol opgeslagen');

                  if(!url){ url = `/projects/${this.props.match.params.id}/choice/form/compose/${this.props.match.params.form_id}`; }

                  this.props.history.push(url);
              });
          }
      }
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    if(this.state.loading){ return null; }

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

        <div style={{padding: 24, width: '100%'}}>

            {/* buttons */}
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.state.can_write || this.state.loading} type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/choice/forms`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <ChoiceFormSteps current={0} saveStep={(url) => { this.saveStep(url) }} {...this.props} />
                </Card>
              </Col>
            </Row>

            { this.state.loading ? null :
            <div style={{padding: 0, width: '100%'}}>

                <Form layout={'vertical'}>

                    <Row gutter={24}>
                        <Col lg={{span:12}} xl={{span: 12}}>
                          <Form.Item hasFeedback className="mbn" required label="Omschrijving">
                            {getFieldDecorator('description', {
                              //initialValue: this.props.choice_form ? this.props.choice_form.name : '',
                              initialValue: this.state.description,
                              rules: [{ required: true, message: 'Vul een omschrijving in' }],
                              onChange: (e) => { this.setState({ description: e.target.value }); }
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>
                        <Col lg={{span:12}} xl={{span: 6}}>
                          <Form.Item className="mbn" colon={false} label={(
                            <span>
                              Bewoner mag formulier invullen
                              <Tooltip className="mhs" title='Als deze optie aan staat betekent dat, dat de bewoner het formulier zelf in kan vullen.'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                            </span>
                          )}>
                            {getFieldDecorator('allow_address_to_submit_entry', {
                              initialValue: this.state.allow_address_to_submit_entry ? true : false,
                              valuePropName: 'checked',
                              onChange: (checked) => { this.setState({ allow_address_to_submit_entry: (checked ? true : false) }); }
                            })(
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                              />
                              )}
                          </Form.Item>
                        </Col>
                        { this.state.allow_address_to_submit_entry ?
                        <Col lg={{span:12}} xl={{span: 6}}>
                            <Form.Item label={
                                <span>
                                    Formulier in te vullen tot:
                                    <Tooltip className="mhs" title='Dit is de datum tot en met wanneer een adres het formulier in kan vullen.'>
                                      <InfoCircleTwoTone />
                                    </Tooltip>
                                </span>}
                            >
                                { getFieldDecorator('address_deadline', {
                                  initialValue: this.state.address_deadline ? dayjs(this.state.address_deadline) : null,
                                  onChange: (value) => { this.setState({ address_deadline: value ? value.format('YYYY-MM-DD') : null }); }
                                })(
                                <DatePicker
                                  format={'DD/MM/YYYY'}
                                  disabledDate={this.disabledDate}
                                  />
                                )}
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    <h2 style={{ marginTop: 24 }}>Betalingen</h2>

                    <Divider />

                    <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item label={(
                            <span>
                              Prijzen gebruiken:
                              <Tooltip className="mhs" title='Schakel deze optie uit om geen gebruik te maken van prijzen (en worden daarmee ook de betalingsmogelijkheden uitgeschakeld).'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                            </span>
                          )}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={this.state.disable_prices ? false : true}
                                onChange={(value) => this.saveToggle(!value, 'disable_prices')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      { this.state.disable_prices == false ? <div>
                          <Row gutter={24}>
                            <Col span={12}>
                              <Form.Item label={(
                                <span>
                                  Meerwerkopties betalen via handmatige actie:
                                  <Tooltip className="mhs" title='Schakel deze optie in om betalingen van meerwerkopties via bijvoorbeeld pin of bankoverschrijving handmatig te verwerken door het bedrag en een foto van het betaalbewijs toe te voegen aan het formulier in de app.'>
                                    <InfoCircleTwoTone />
                                  </Tooltip>
                                </span>
                              )}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={this.state.allow_user_to_add_payments}
                                    onChange={(value) => this.saveToggle(value, 'allow_user_to_add_payments')}
                                />
                              </Form.Item>
                            </Col>
                            {this.state.allow_user_to_add_payments ? <Col span={12}>
                              <Form.Item label={(
                                <span>
                                  Toon betaalbewijzen in dossier:
                                  <Tooltip className="mhs" title='Schakel deze optie in om de betaalbewijzen op te nemen in het dossier van een bewoner.'>
                                    <InfoCircleTwoTone />
                                  </Tooltip>
                                </span>
                              )}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={this.state.show_transaction_tickets_in_dossier}
                                    onChange={(value) => this.saveToggle(value, 'show_transaction_tickets_in_dossier')}
                                />
                              </Form.Item>
                            </Col> : null}
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                              <Form.Item label={(
                                <span>
                                  Meerwerkopties betalen via Mollie:
                                  <Tooltip className="mhs" title='Schakel deze optie in om bewoners te laten betalen via Mollie als er gekozen is voor meerwerk. Deze optie is alleen beschikbaar als voor je bedrijf een Mollie account is ingesteld. Neem hiervoor contact op met Cobee om dit in te stellen.'>
                                    <InfoCircleTwoTone />
                                  </Tooltip>
                                </span>
                              )}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={this.state.c_enable_mollie && this.props.project && this.props.project.mollie_accounts && this.props.project.mollie_accounts.length}
                                    onChange={(value) => { this.saveToggle(value, 'c_enable_mollie'); this.setState({ c_enable_mollie: value ? true : false }); }}
                                    disabled={!this.props.project || !this.props.project.mollie_accounts || !this.props.project.mollie_accounts.length}
                                />
                              </Form.Item>
                            </Col>

                            { this.state.c_enable_mollie ?
                            <Col span={12}>
                              <Form.Item label={(
                                <span>
                                  Mollie account
                                </span>
                              )}>
                                {getFieldDecorator('mollie_account_id', {
                                    initialValue: this.state.mollie_account_id ? String(this.state.mollie_account_id) : null,
                                    onChange: (value) => { this.setState({ mollie_account_id: value }); },
                                    rules: [{ required: true, message: 'Geef een account op' }],
                                  })(
                                    <Select>
                                      { this.props.project && this.props.project.mollie_accounts && this.props.project.mollie_accounts.map((obj, index) => {
                                          return <Select.Option key={obj.id}>{ obj.name }</Select.Option>
                                      }) }
                                    </Select>
                                )}
                              </Form.Item>
                            </Col> : null }
                        </Row>
                    </div> : null }

                    <h2>Ondertekenen</h2>

                    <Divider />

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item className="mbn" label="Digitaal ondertekenen">
                              {getFieldDecorator('require_to_sign_form', {
                                  initialValue: this.state.require_to_sign_form ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form: checked }) }
                              })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    disabled={this.state.locked}
                                    />
                              )}
                            </Form.Item>
                        </Col>

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Handtekening verplicht
                                <Tooltip className="mhs" title='Als deze optie aan staat is het verplicht om een handtekening te zetten om het formulier af te ronden.'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('signature_required', {
                                  initialValue: this.state.signature_required ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ signature_required: checked ? true : false, signature_not_required: checked ? false : true }) }
                              })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    disabled={this.state.locked}
                                    />
                              )}
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    <Row gutter={24}>
                        { this.state.require_to_sign_form && !this.state.signature_not_required ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Rechtsgeldig ondertekenen
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per formulier is dit €1,00'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('require_to_sign_form_legally', {
                                  initialValue: this.state.require_to_sign_form_legally ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form_legally: checked }) }
                              })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    disabled={this.state.locked}
                                    />
                              )}
                            </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && !this.state.signature_not_required && this.state.require_to_sign_form_legally ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                SMS authenticatie gebruiken
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per verstuurde SMS is dit €0,25'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('use_sms_authentication_for_signing', {
                                  initialValue: this.state.use_sms_authentication_for_signing ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ use_sms_authentication_for_signing: checked }) }
                              })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    disabled={this.state.locked}
                                    />
                              )}
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    <Row gutter={24}>

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label={(
                            <span>
                              Hoeveel handtekeningen
                              <Tooltip className="mhs" title='Deze instelling kan later nog worden veranderd in de app of www bij het invullen van het formulier.'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                            </span>
                          )}>
                            {getFieldDecorator('nr_of_signatures', {
                              initialValue: this.state.nr_of_signatures,
                              onChange: (value) => { this.setState({ nr_of_signatures: value }); },
                              rules: [{ required: true, message: 'Vul een aantal in. Dit kan later in de app of www nog worden aangepast per in te vullen formulier.' }],
                            })(
                              <InputNumber disabled={this.state.locked} />
                            )}
                          </Form.Item>
                        </Col> : null }

                      { this.state.require_to_sign_form && this.state.nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.nr_of_signatures))].map((i, s) => {

                      var nr = s + 1;

                      return <Col span={12} lg={{span:12}} xl={{span: 12}}>
                        <Form.Item hasFeedback className="mbn" label={"Handtekening "+nr+" omschrijving"}>
                          {getFieldDecorator('signature_'+nr+'_description', {
                            initialValue: this.state['signature_'+nr+'_description'],
                            onChange: (e) => { this.setState({ ['signature_'+nr+'_description']: e.target.value }); },
                            rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app of www nog worden aangepast per in te vullen formulier.' }],
                          })(
                            <Input disabled={this.state.locked} />
                          )}
                        </Form.Item>
                      </Col>
                      })}</Col> : null }
                    </Row>

                    {/*<Row gutter={24}>

                      { this.state.allow_address_to_submit_entry && this.state.require_to_sign_form ?
                      <Col span={12}>
                        <Form.Item label={(
                          <span>
                            Bewoner moet een handtekening zetten:
                            <Tooltip className="mhs" title='Schakel deze optie in als de bewoner in de eigen bewonersomgeving (in de app of op de www) het ingevulde formulier moet ondertekenen. Let op: als de bewoner en de gebruiker een handtekening moeten zetten is het formulier pas afgerond als beide personen in hun eigen omgevingen een handtekening hebben gezet.'>
                              <Icon type="info-circle" theme="twoTone" />
                            </Tooltip>
                          </span>
                        )}>
                          <Switch
                              checkedChildren={<Icon type="check" />}
                              unCheckedChildren={<Icon type="close" />}
                              checked={this.state.occupant_needs_to_sign_form}
                              onChange={(value) => this.saveToggle(value, 'occupant_needs_to_sign_form')}
                          />
                        </Form.Item>
                      </Col> : null }

                      { this.state.require_to_sign_form ?
                      <Col span={12}>
                        <Form.Item label={(
                          <span>
                            Gebruiker moet een handtekening zetten:
                            <Tooltip className="mhs" title='Schakel deze optie in als de gebruiker (en of bewoner) in de eigen beheerdersomgeving (in de app) het ingevulde formulieren moet ondertekenen. Let op: als de bewoner en de gebruiker een handtekening moeten zetten is het formulier pas afgerond als beide personen in hun eigen omgevingen een handtekening hebben gezet.'>
                              <Icon type="info-circle" theme="twoTone" />
                            </Tooltip>
                          </span>
                        )}>
                          <Switch
                              checkedChildren={<Icon type="check" />}
                              unCheckedChildren={<Icon type="close" />}
                              checked={this.state.user_needs_to_sign_form}
                              onChange={(value) => this.saveToggle(value, 'user_needs_to_sign_form')}
                          />
                        </Form.Item>
                      </Col> : null }
                    </Row>/**/}

                    {/*<Row gutter={24}>
                        { this.state.allow_address_to_submit_entry && this.state.require_to_sign_form ?
                        <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label={(
                            <span>
                              Hoeveel handtekeningen bewoner(omgeving)
                              <Tooltip className="mhs" title='Deze instelling kan later nog worden veranderd in de app of www bij het invullen van het formulier op de bewonersomgeving.'>
                                <Icon type="info-circle" theme="twoTone" />
                              </Tooltip>
                            </span>
                          )}>
                            {getFieldDecorator('occupant_nr_of_signatures', {
                              initialValue: this.state.occupant_nr_of_signatures,
                              onChange: (value) => { this.setState({ occupant_nr_of_signatures: value }); },
                              rules: [{ required: true, message: 'Vul een aantal in. Dit kan later in de app of www nog worden aangepast per in te vullen formulier.' }],
                            })(
                              <InputNumber disabled={this.state.locked} />
                            )}
                          </Form.Item>
                        </Col> : null }

                      </Row>

                      <Row gutter={24}>
                        { this.state.allow_address_to_submit_entry && this.state.require_to_sign_form && this.state.occupant_nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.occupant_nr_of_signatures))].map((i, s) => {

                        var nr = s + 1;

                        return <Col span={12} lg={{span:12}} xl={{span: 12}}>
                          <Form.Item hasFeedback className="mbn" label={"Handtekening bewoner(omgeving) "+nr+" omschrijving"}>
                            {getFieldDecorator('signature_occupant_'+nr+'_description', {
                              initialValue: this.state['signature_occupant_'+nr+'_description'],
                              onChange: (e) => { this.setState({ ['signature_occupant_'+nr+'_description']: e.target.value }); },
                              rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app of www nog worden aangepast per in te vullen formulier.' }],
                            })(
                              <Input disabled={this.state.locked} />
                            )}
                          </Form.Item>
                        </Col>
                        })}</Col> : null }
                      </Row>/**/}

                      {/*<Row gutter={24}>

                          { this.state.require_to_sign_form ?
                          <Col span={12}>
                            <Form.Item hasFeedback className="mbn" label={(
                              <span>
                                Hoeveel handtekeningen gebruiker(omgeving)
                                <Tooltip className="mhs" title='Deze instelling kan later nog worden veranderd in de app of www bij het invullen van het formulier op de gebruikersomgeving.'>
                                  <Icon type="info-circle" theme="twoTone" />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('user_nr_of_signatures', {
                                initialValue: this.state.user_nr_of_signatures,
                                onChange: (value) => { this.setState({ user_nr_of_signatures: value }); },
                                rules: [{ required: true, message: 'Vul een aantal in. Dit kan later in de app of www nog worden aangepast per in te vullen formulier.' }],
                              })(
                                <InputNumber disabled={this.state.locked} />
                              )}
                            </Form.Item>
                          </Col> : null }

                        { this.state.require_to_sign_form && this.state.user_nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.user_nr_of_signatures))].map((i, s) => {

                        var nr = s + 1;

                        return <Col span={12} lg={{span:12}} xl={{span: 12}}>
                          <Form.Item hasFeedback className="mbn" label={"Handtekening gebruiker(omgeving) "+nr+" omschrijving"}>
                            {getFieldDecorator('signature_user_'+nr+'_description', {
                              initialValue: this.state['signature_user_'+nr+'_description'],
                              onChange: (e) => { this.setState({ ['signature_user_'+nr+'_description']: e.target.value }); },
                              rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app of www nog worden aangepast per in te vullen formulier.' }],
                            })(
                              <Input disabled={this.state.locked} />
                            )}
                          </Form.Item>
                        </Col>
                        })}</Col> : null }
                      </Row>/**/}

                      <h2 style={{ marginTop: 24 }}>Teksten en berichten</h2>

                      <Divider></Divider>

                      { this.state.c_enable_mollie ?
                      <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Standaardtekst pagina samenvatting">
                            { getFieldDecorator('c_cart_additional_work_direct_payment', {
                              initialValue: this.state.c_cart_additional_work_direct_payment, // ? this.state.c_cart_additional_work_direct_payment : 'Onderstaand treft u een overzicht aan van de door u gekozen opties.<br />De gekozen meerwerkopties kunt u betalen in de volgende stap of na het ondertekenen van het formulier.',
                              // rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_cart_additional_work_direct_payment: value }); },
                            })(
                              <CustomEditor
                                  height={300}
                                  placeholders={'choice'}
                                  project_id={this.props.match.params.id}
                                  messageChanged={(e) =>
                                  {
                                      this.setState({ c_cart_additional_work_direct_payment: e });
                                  }}
                                  />
                            )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    { !this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Standaardtekst pagina samenvatting">
                            { getFieldDecorator('c_cart_additional_work_no_direct_payment', {
                              initialValue: this.state.c_cart_additional_work_no_direct_payment, // ? this.state.c_cart_additional_work_direct_payment : 'Onderstaand treft u een overzicht aan van de door u gekozen opties.<br />Wij verzoeken u vriendelijk om het openstaande bedrag binnen 14 dagen over te maken op (rekeningnummer).',
                              // rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_cart_additional_work_no_direct_payment: value }); },
                            })(
                              <CustomEditor
                                  height={300}
                                  placeholders={'choice'}
                                  project_id={this.props.match.params.id}
                                  messageChanged={(e) =>
                                  {
                                      this.setState({ c_cart_additional_work_no_direct_payment: e });
                                  }}
                                  />
                            )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    {/*<Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Winkelwagen met meerwerkopties bij geen meerwerkkosten">
                            { getFieldDecorator('c_cart_no_additional_work', {
                              initialValue: this.state.c_cart_no_additional_work, // ? this.state.c_cart_no_additional_work : 'Onderstaand treft u een overzicht van de door u gekozen opties.',
                              // rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              onEditorChange: (value, editor) => { this.setState({ c_cart_no_additional_work: value }); }
                            })(
                              <CustomEditor height={300} placeholders={'choice'} project_id={this.props.match.params.id} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>*/}

                    { this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Standaardtekst bevestigingspagina">
                            { getFieldDecorator('c_status_direct_payment', {
                              initialValue: this.state.c_status_direct_payment, // ? this.state.c_status_direct_payment : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_status_direct_payment: value }); }
                            })(
                                <CustomEditor
                                    height={300}
                                    placeholders={'choice'}
                                    project_id={this.props.match.params.id}
                                    messageChanged={(e) =>
                                    {
                                        this.setState({ c_status_direct_payment: e });
                                    }}
                                    />
                            )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    { !this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Standaardtekst bevestigingspagina">
                            { getFieldDecorator('c_status_postpay', {
                              initialValue: this.state.c_status_postpay, // ? this.state.c_status_postpay : 'Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_status_postpay: value }); }
                            })(
                                <CustomEditor
                                    height={300}
                                    placeholders={'choice'}
                                    project_id={this.props.match.params.id}
                                    messageChanged={(e) =>
                                    {
                                        this.setState({ c_status_postpay: e });
                                    }}
                                    />
                            )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    <Divider></Divider>

                    { this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Bevestigingsmail bij directe betaling e-mail onderwerp">
                              {getFieldDecorator('c_email_subject_direct_payment', {
                                initialValue: this.state.c_email_subject_direct_payment, // ? this.state.c_email_subject_direct_payment : 'Keuzeformulier ontvangen',
                                rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                onEditorChange: (value, editor) => { this.setState({ c_status_postpay: value }); }
                              })(
                                <Input />
                              )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    { this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingsmail bij directe betaling e-mail bericht">
                            { getFieldDecorator('c_email_message_direct_payment', {
                              initialValue: this.state.c_email_message_direct_payment, // ? this.state.c_email_message_direct_payment : 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
// <br />\
// Wij hebben het door u ingevulde keuzeformulier en de betaling in goede orde ontvangen. U heeft gekozen voor de volgende opties:<br />\
// <br />\
// <placeholder id="current" class="cobee-tag">keuzeformulier: lijst gekozen opties</placeholder><br />\
// <br />\
// Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
// <br />\
// Heeft u vragen of opmerkingen, neem contact met ons op via <placeholder id="current" class="cobee-tag">project: telefoonnummer</placeholder> of <placeholder id="current" class="cobee-tag">project: e-mailadres</placeholder>.<br />\
// <br />\
// Met vriendelijke groet,<br />\
// <placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_email_message_direct_payment: value }); }
                            })(
                                <CustomEditor
                                    height={300}
                                    placeholders={'choice'}
                                    project_id={this.props.match.params.id}
                                    messageChanged={(e) =>
                                    {
                                        this.setState({ c_email_message_direct_payment: e });
                                    }}
                                    />
                            )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    { this.state.c_enable_mollie ?
                    <Divider></Divider> : null }

                    { !this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Bevestigingsmail bij betaling achteraf e-mail onderwerp">
                              {getFieldDecorator('c_email_subject_postpay', {
                                initialValue: this.state.c_email_subject_postpay ? this.state.c_email_subject_postpay : 'Keuzeformulier ontvangen',
                                rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                onChange: (source) => { this.setState({c_email_subject_postpay: source.target.value}); }
                              })(
                                <Input />
                              )}
                          </Form.Item>
                        </Col>
                    </Row> : null }

                    { !this.state.c_enable_mollie ?
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingsmail bij betaling achteraf e-mail bericht">
                            { getFieldDecorator('c_email_message_postpay', {
                              initialValue: this.state.c_email_message_postpay, // ? this.state.c_email_message_postpay : 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
// <br />\
// Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. U heeft gekozen voor de volgende opties:<br />\
// <br />\
// <placeholder id="current" class="cobee-tag">keuzeformulier: lijst gekozen opties</placeholder><br />\
// <br />\
// De gekozen meerwerkopties bedragen totaal <placeholder id="current" class="cobee-tag">keuzeformulier: bedrag meerwerkopties</placeholder>.<br />\
// <br />\
// De gekozen meerwerkopties dienen nog te worden betaald. Wij verzoeken u vriendelijk om het openstaande bedrag binnen 14 dagen over te maken op (rekeningnummer).<br />\
// <br />\
// Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
// <br />\
// Heeft u vragen of opmerkingen, neem contact met ons op via <placeholder id="current" class="cobee-tag">project: telefoonnummer</placeholder> of <placeholder id="current" class="cobee-tag">project: e-mailadres</placeholder>.<br />\
// <br />\
// Met vriendelijke groet,<br />\
// <placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_email_message_postpay: value }); }
                            })(
                                <CustomEditor
                                    height={300}
                                    placeholders={'choice'}
                                    project_id={this.props.match.params.id}
                                    messageChanged={(e) =>
                                    {
                                        this.setState({ c_email_message_postpay: e });
                                    }}
                                    />
                            )}
                            </Form.Item>
                        </Col>
                    </Row> : null }

                    {/*{ !this.state.c_enable_mollie ?
                    <Divider></Divider> : null }

                    <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Bevestigingsmail bij geen meerwerkopties e-mail onderwerp">
                              {getFieldDecorator('c_email_subject_no_additional_work', {
                                initialValue: this.state.c_email_subject_no_additional_work ? this.state.c_email_subject_no_additional_work : 'Keuzeformulier ontvangen',
                                rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                onChange: (source) => { this.setState({c_email_subject_no_additional_work: source.target.value}); }
                              })(
                                <Input />
                              )}
                          </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingsmail bij geen meerwerkopties e-mail bericht">
                            { getFieldDecorator('c_email_message_no_additional_work', {
                              initialValue: this.state.c_email_message_no_additional_work, // ? this.state.c_email_message_no_additional_work : 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
// <br />\
// Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. U heeft gekozen voor de volgende opties:<br />\
// <br />\
// <placeholder id="current" class="cobee-tag">keuzeformulier: lijst gekozen opties</placeholder><br />\
// <br />\
// Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
// <br />\
// Heeft u vragen of opmerkingen, neem contact met ons op via <placeholder id="current" class="cobee-tag">project: telefoonnummer</placeholder> of <placeholder id="current" class="cobee-tag">project: e-mailadres</placeholder>.<br />\
// <br />\
// Met vriendelijke groet,<br />\
// <placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              onEditorChange: (value, editor) => { this.setState({ c_email_message_no_additional_work: value }); }
                            })(
                              <CustomEditor height={300} placeholders={'choice'} project_id={this.props.match.params.id} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>*/}

                </Form>

          </div> }

        </div>

    </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const ChoiceFormSettingsForm = Form.create({ name: 'choice_form' })(ChoiceFormSettings);

const mapStateToProps = (state, ownProps) => {
    return {
        // isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        choice_form : state.choice.choice_form,
        envelopes : state.envelope.envelopes,
        letterpapers: state.letterpaper.letterpapers,
        project: state.project.project
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChoiceFormByIdAction : bindActionCreators(getChoiceFormByIdAction, dispatch),
        addChoiceFormToProjectAction : bindActionCreators(addChoiceFormToProjectAction, dispatch),
        updateChoiceFormAction : bindActionCreators(updateChoiceFormAction, dispatch),
        getEnvelopesAction : bindActionCreators(getEnvelopesAction, dispatch),
        getLetterPapersAction : bindActionCreators(getLetterPapersAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormSettingsForm);
