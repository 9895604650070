import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, notification, Divider } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { addClientAction, resetClientAction, getClientAction, patchClientAction } from '../../../actions/clientsActions';
import { addInformationAction, getInformationAction, resetInformationAction, patchInformationAction, addIndicatorAction, removeIndicatorAction } from '../../../actions/globalInformationActions';
import { getLibraryCategoriesAction } from '../../../actions/librariesActions';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
import PatchInformation from '../Forms/PatchInformation';
import InformationTextMenu from '../Menu/InformationTextMenu';

class ClientDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false,
      selected: [],
      text: '',
      tags: [],
    };
  }

  componentDidMount() {
    this.props.resetInformationAction();

    this.props.getLibraryCategoriesAction('information');

    if(this.props.match.params.informationid) {
      this.props.getInformationAction(this.props.match.params.informationid);
    }

  }

  handleSubmit = (e, values) => {
    this.clientForm.props.form.validateFields((err, values) => {
      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      if(this.props.match.params.informationid) {
        this.props.patchInformationAction(this.props.match.params.informationid, values).then(() => this.props.history.push(`/library/information-texts/`));
      } else {
        this.props.addInformationAction(values).then(() => this.props.history.push(`/library/information-texts/`));
      }

      showSuccess();
    });
  }

  render() {
    return (

    <div>

      <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Informatie', 'link': '/library/information-texts'}, {'label': this.props.information.title ? this.props.information.title : 'Toevoegen'}]} />

      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <LibrarySideMenu id={this.props.match.params.id} activeMenu='5' />

          <div style={{padding: 0, display: 'flex', width: '100%'}}>

              <InformationTextMenu activeMenu='1' />

              <div style={{padding: 24, width: '100%'}} colon={true}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                      <Link to={`/library/information-texts`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Divider></Divider>
                <Row>
                  <Col span={24}>

                      <PatchInformation wrappedComponentRef={formRef => { this.clientForm = formRef }} {...this.props} />

                  </Col>
                </Row>
            </div>

          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.information.isFetching,
    isError : state.information.isError,
    information: state.information.globalInformationitem,
    indicators: state.information.indicators,
    categories : state.library.categories,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addInformationAction : bindActionCreators(addInformationAction, dispatch),
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    resetInformationAction : bindActionCreators(resetInformationAction, dispatch),
    patchInformationAction : bindActionCreators(patchInformationAction, dispatch),
    addIndicatorAction : bindActionCreators(addIndicatorAction, dispatch),
    removeIndicatorAction : bindActionCreators(removeIndicatorAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
