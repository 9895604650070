import React from 'react'
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'
import { v1 as uuidv1 } from 'uuid'
import FormSteps from './FormSteps'
import { CheckCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Switch, Tag, Result } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../utils/Notifications';
import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAddressesAction, searchAddressesAction } from '../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { getFormAction, addFormAction, updateFormAction, resetFormAction, previewFormAction } from '../../../../actions/formActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import FormSideMenu from '../FormSideMenu';

class FormAddresses extends React.Component {

  form_data = null;

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      can_write: false,
      selectedRowKeys: [],

      filteredInfo: null,
      loaded: false,
    };
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('form', 'write')
      });

      this.props.getFormAction(this.props.match.params.form_id, true).then(() => {

          this.props.getAddressAction({ projectId: this.props.form_details.project_id }).then(() => {

              this.setState({ selectedRowKeys: this.props.form_details.address_ids }, () => { this.setState({ loaded: true }); });

              this.props.getGroupsAction({ projectId: this.props.form_details.project_id });

          });
      });
  }

  handleSubmit = (e, values, redirect) => {

      if(this.props.form_details.is_public || (!this.props.form_details.is_public && !this.props.form_details.address_required) || this.state.selectedRowKeys.length > 0)
      {
          this.props.updateFormAction(this.props.match.params.form_id, { address_ids: this.state.selectedRowKeys && this.state.selectedRowKeys.length ? this.state.selectedRowKeys : [] }).then(() => {

            showSuccess();

            if(redirect === true)
            {
              this.props.history.push('/projects/' + this.props.match.params.id + '/forms');
            }
          });
      }
      else if(!this.props.form_details.is_public)
      {
          showError('Geef minimaal 1 adres op');
      }
      /*else
      {
          this.props.history.push('/projects/' + this.props.match.params.id + '/form/' + this.props.form_details.id + '/results');
      }*/
  }

  onSelectChange = (selectedRowKeys) => {

      if(selectedRowKeys && selectedRowKeys.length < 1){

        this.props.addresses.map((address) => {

          if(this.isRecordIDBlocked(address.id))
          {
            selectedRowKeys.push(address.id);
          }
        });
      }

      this.setState({ selectedRowKeys });
  }

  onSearch(value) {

      this.props.searchAddressesAction(this.props.match.params.id, {'search': value});
  }

  handleTableChange = (pagination, filters, sorter) => {

      this.setState({
          filteredInfo: filters,
          sortedInfo: sorter,
      });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  isRecordIDBlocked(id)
  {
      return this.props.form_details 
        && this.props.form_details.address_ids_with_filled_in_forms 
        && this.props.form_details.address_ids_with_filled_in_forms.includes 
        && this.props.form_details.address_ids_with_filled_in_forms.includes(id) 
        ? true : false;
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    const { selectedRowKeys } = this.state;

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const rowSelection = {
        selectedRowKeys,
        getCheckboxProps: record => ({
            disabled: this.isRecordIDBlocked(record.id)
        }),
        onChange: this.onSelectChange,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.onSelectChange(this.props.addresses.map(value => value.id));
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.onSelectChange([]);
            },
          },
        ],
    };

    const columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
    }, {
      title: 'Postcode',
      dataIndex: 'zipcode',
      sorter: (a, b) => { return a.zipcode.localeCompare(b.zipcode)},
    },{
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => { return a.city.localeCompare(b.city)},
    }, {
        title: 'Groep',
        dataIndex: 'groups',
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
          record.groups.map((v) => (
              <Tag color={v.color} key={v.id}>{v.name}</Tag>
        ))
    }];

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <FormSideMenu id={this.props.match.params.id} activeMenu="1"/>

        <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button disabled={!this.state.can_write || !this.state.loaded} type="new" onClick={() => this.handleSubmit(null, null, true)}>Opslaan</Button>
                  <Link to={`/projects/${this.props.match.params.id}/forms`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            {/* steps */}
            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <FormSteps current={2} {...this.props}/>
                </Card>
              </Col>
            </Row>

          <Row gutter={24} className="mbm" style={{display: (this.props.match.params.form_id && this.props.form_details ? 'block' : 'none')}}>
            <Col span={24}>
              { !this.props.form_details.is_public ?
              <>
                <Row className="mbm">
                  <Col span={12} style={{ height: 32, padding: 5 }}>
                    <div>{ this.state.selectedRowKeys ? this.state.selectedRowKeys.length : 0 } / { this.props.addresses ? this.props.addresses.length : 0 } adressen geselecteerd</div>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      placeholder="Zoeken..."
                      allowClear
                      onSearch={value => this.onSearch(value)}
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <CustomTable
                  rowKey='id'
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={this.props.addresses}
                  loading={this.props.isFetching}
                  onChange={(pagination, filters, sorter, extra) => { this.handleTableChange(pagination, filters, sorter); }}
                />
              </>
              :
              <Result
                icon={<CheckCircleFilled style={{ color: '#52c41a' }} />}
                title="De informatie staat ingesteld op openbaar, dus iedereen kan het zien."
              /> }
            </Col>
          </Row>

        </div>

      </div>
    );
  }
}


const FormAddressesForm = Form.create({ name: 'addresses_form' })(FormAddresses);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    form_details : state.forms.form,
    addresses : state.address.addresses,
    groups : state.group.groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAction : bindActionCreators(getFormAction, dispatch),
    updateFormAction : bindActionCreators(updateFormAction, dispatch),
    resetFormAction : bindActionCreators(resetFormAction, dispatch),
    getAddressAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewFormAction : bindActionCreators(previewFormAction, dispatch),
    searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddressesForm);
