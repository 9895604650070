import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction, addDaypart } from '../../../../../actions/appointmentsActions';
import PatchAppointmentForm from '../Form/PatchAppointment';
import ClustersForm from '../Form/Clusters';
import DataForm from '../Form/Data';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

class AppointmentDetail extends Component {

  state = {
      loading: true
  }

  componentDidMount()
  {
    this.setState({ loading: true }, () => {

        if(this.props.match.params.appointmentid) {
          this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {
              this.setState({ loading: false });
          });
        }
        else
        {
          this.props.resetAppointmentAction(); //.then(() => {
              this.setState({ loading: false });
          // });
        }
    });
  }

  saveAppointmentForm(e)
  {
    this.appointmentForm.props.form.validateFields((err, values) => {

      if (!err) {

        if(values.schedule_from_date)
        {
            values.schedule_from_date = values.schedule_from_date.format('YYYY-MM-DD');
        }

        if(values.allow_appointments_to_be_made_till)
        {
            values.allow_appointments_to_be_made_till = values.allow_appointments_to_be_made_till.format('YYYY-MM-DD');
        }

        values.monday_from = values.monday_from && values.monday_from.format('HH:mm');
        values.monday_till = values.monday_till && values.monday_till.format('HH:mm');
        values.tuesday_from = values.tuesday_from && values.tuesday_from.format('HH:mm');
        values.tuesday_till = values.tuesday_till && values.tuesday_till.format('HH:mm');
        values.wednesday_from = values.wednesday_from && values.wednesday_from.format('HH:mm');
        values.wednesday_till = values.wednesday_till && values.wednesday_till.format('HH:mm');
        values.thursday_from = values.thursday_from && values.thursday_from.format('HH:mm');
        values.thursday_till = values.thursday_till && values.thursday_till.format('HH:mm');
        values.friday_from = values.friday_from && values.friday_from.format('HH:mm');
        values.friday_till = values.friday_till && values.friday_till.format('HH:mm');
        values.saturday_from = values.saturday_from && values.saturday_from.format('HH:mm');
        values.saturday_till = values.saturday_till && values.saturday_till.format('HH:mm');
        values.sunday_from = values.sunday_from && values.sunday_from.format('HH:mm');
        values.sunday_till = values.sunday_till && values.sunday_till.format('HH:mm');
        values.break_1_from = values.break_1_from && values.break_1_from.format('HH:mm');
        values.break_1_till = values.break_1_till && values.break_1_till.format('HH:mm');
        values.break_2_from = values.break_2_from && values.break_2_from.format('HH:mm');
        values.break_2_till = values.break_2_till && values.break_2_till.format('HH:mm');
        values.break_3_from = values.break_3_from && values.break_3_from.format('HH:mm');
        values.break_3_till = values.break_3_till && values.break_3_till.format('HH:mm');

        // values.dayparts = [];
        // values.from_time = [];
        // values.till_time = [];

        values.from_time && values.from_time.map((obj, index) => {
            values.from_time[index] = obj.format('HH:mm');
        });

        values.till_time && values.till_time.map((obj, index) => {
            values.till_time[index] = obj.format('HH:mm');
        });

        values.max_nr_of_appointments && values.max_nr_of_appointments.map((obj, index) => {
            values.max_nr_of_appointments[index] = obj;
        });

        if(this.props.match.params.appointmentid) {

          this.props.patchAppointmentAction(this.props.match.params.appointmentid, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/clusters/`);
          });

        } else {
          this.props.addAppointmentAction(this.props.match.params.id, values).then((response) => {
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${response.data.appointment.id}/clusters/`);
          });
        }

        showSuccess('Succesvol opgeslagen');
      }
    });
  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={(e) => this.saveAppointmentForm(e)}>Opslaan en volgende</Button>
              <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
            <AppointmentSteps {...this.props}/>
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>

            { this.state.loading ? null :
            <PatchAppointmentForm
              wrappedComponentRef={formRef => { this.appointmentForm = formRef }}
              onSubmit={(e) => this.saveAppointmentForm(e)}
              {...this.props}
              />
            }
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    appointment: state.appointment.appointment,
    dayparts: state.appointment.dayparts,
    clusters: state.appointment.clusters
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    addDaypart : bindActionCreators(addDaypart, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail);
