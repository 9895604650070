export default (state = {isError: false, isFetching: false, conversations: [], conversation:[]}, action) => {
    switch (action.type) {
    case 'CONVERSATIONS_ACTION_FETCH':
    return {
        ...state,
        conversations: [],
        isFetching: true,
        isError: false,
    }
    case 'CONVERSATION_ACTION_FETCH':
    return {
        ...state,
        conversation: [],
        isFetching: true,
        isError: false,
    }
    case 'CONVERSATIONS_ACTION_SUCCESS':
    return {
        ...state,
        conversations: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CONVERSATION_ACTION_SUCCESS':
    return {
        ...state,
        conversation: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_CONVERSATIONS_ACTION_SUCCESS':
    return {
        ...state,
        conversation: [action.payload, ...state.conversation],
        conversations: [action.payload, ...state.conversations],
        isFetching: false,
        isError: false,
    }
    case 'CONVERSATIONS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
