import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { getFormsAction, addFormAction, deleteFormsAction, searchFormsAction, duplicateCloneAction, getCustomerFormsAction, getFormMenuAction } from '../../../actions/formActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Button,
    Checkbox,
    Dropdown,
    Menu,
    Input,
    Row,
    Col,
    Upload,
    message,
    Drawer,
    Transfer,
    Select,
} from 'antd';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import FormSideMenu from './FormSideMenu';
import moment from 'moment';

function onChange(pagination, sorter) {
}

const Search = Input.Search;

const confirm = Modal.confirm;

const Dragger = Upload.Dragger;

class ProjectForms extends Component {

    state = {
        selectedRowKeys: [], // Check here to configure the default column
        // loading: false
    };

    deleteForms = () => {
      confirm({
        title: 'Wil je deze items verwijderen?',
        content: 'Als je op JA klikt, worden de items verwijderd.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {
                this.props.deleteFormsAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {

                    resolve();

                    showSuccess('Succesvol verwijderd');

                    this.setState({selectedRowKeys:[]});

                    this.loadData();
                });
            }).catch(() => {});
        },
        onCancel() {},
      });
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
    }

    loadData() {

        // this.setState({ loading: true });

        // this.props.getFormMenuAction(this.props.match.params.id);

        this.props.getCustomerFormsAction();

        this.props.getFormsAction(this.props.match.params.id).then(() => {

            // this.setState({ loading: false });
        });

        // this.props.getCustomerFormsAction();
    }

    onSearch = (value) => {
      this.props.searchFormsAction(this.props.match.params.id, value);
    }

    cloneForm = () => {
      if(this.state.selectedId)
      {
        this.props.duplicateCloneAction(this.state.selectedId, 'project', this.props.match.params.id).then(() => {

          this.setState({selectedId: null, modalVisible: false});

          this.props.getFormsAction(this.props.match.params.id);

          showSuccess('Succesvol toegevoegd');
        });
      }
    }

    cloneSelected = () => {
      this.props.duplicateCloneAction(this.state.selectedRowKeys[0], 'project', this.props.match.params.id).then(() => {

        showSuccess('Succesvol gedupliceerd');

        this.setState({selectedRowKeys: []});

        this.props.getFormsAction(this.props.match.params.id);
      });
    }

    getMenuItemName(id)
    {
        var name = '';

        this.props.menus.map((menu) => {

            if(menu.id == id)
            {
                name = menu.name;
            }
        });

        return name;
    }

    render() {

        const columns = [
        {
            title: 'Naam',
            dataIndex: 'name',
            render: (text, record) => (
                <span>
                  <Link to={`/projects/${this.props.match.params.id}/form/${record.id}`}>
                    {text}
                  </Link>
                </span>
            )
        },
        {
            title: 'Categorie',
            dataIndex: 'form_menu_item_id',
            render: (id, record) => (
                <span>{this.getMenuItemName(id)}</span>
            )
        },
        {
            title: 'Aantal adressen',
            dataIndex: 'nr_of_forms',
            render: (text, record) => (
              <span>
                {text}
              </span>
            )
        },
        {
            title: 'Ingevuld',
            dataIndex: 'nr_of_completed_forms',
            render: (text, record) => (
              <span>
                {text}
              </span>
            )
        },
        // {
        //     title: 'Aanmaakdatum',
        //     dataIndex: 'created_at',
        //     render: (text, record) => (
        //       <span>
        //         { moment(text).format('DD/MM/YYYY') }
        //       </span>
        //     )
        // },
        // {
        //     title: 'Laatst bijgewerkt',
        //     dataIndex: 'updated_at',
        //     render: (text, record) => (
        //       <span>
        //         { moment(text).format('DD/MM/YYYY - HH:mm') }
        //       </span>
        //     )
        // }
        ];

        const SubMenu = Menu.SubMenu;

        const { selectedRowKeys, targetKeys, disabled } = this.state;
        const { getFieldDecorator } = this.props.form;

        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          hideDefaultSelections: true,
          onSelection: this.onSelection,
          getCheckboxProps: record => ({
              disabled: record.locked ? true : false
          }),
        };

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <FormSideMenu id={this.props.match.params.id} activeMenu="1" {...this.props} />

              <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                    <Col span={12} >

                        <Dropdown disabled={!this.props.HaveAbility('form' ,'write')} trigger={['click']} overlay={(
                            <Menu>
                                <Menu.Item key="1">
                                    <Link to={`form/add`}>
                                        Nieuw
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Link to={null} onClick={() => { this.setState({ modalVisible: true }); }}>
                                        Bibliotheek
                                    </Link>
                                </Menu.Item>
                            </Menu>
                            )}>
                            <Button type="new">
                                Toevoegen <DownOutlined />
                            </Button>
                        </Dropdown>


                        <Dropdown trigger={['click']} disabled={ (this.state.selectedRowKeys.length > 0 ? false : true) || !this.props.HaveAbility('form', 'write') } overlay={(
                            <Menu>
                              <Menu.Item disabled={this.state.selectedRowKeys.length != 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                              <Menu.Item disabled={!this.props.HaveAbility('form' ,'delete')} onClick={this.deleteForms} key="7" style={{ color: this.props.HaveAbility('form', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                            </Menu>
                        )}>
                            <Button type="secondary">
                                Acties <DownOutlined />
                            </Button>
                        </Dropdown>

                    </Col>
                    <Col span={12}>
                        <Search
                          placeholder="Zoeken..."
                          onSearch={value => this.onSearch(value)}
                          style={{ width: 200, float: 'right' }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomTable
                            rowKey='id'
                            size="middle"
                            rowSelection={rowSelection}
                            onChange={onChange}
                            columns={columns}
                            dataSource={this.props.isFetching ? [] : this.props.forms}
                            loading={this.props.isFetching}
                            // loading={this.state.loading}
                        />
                    </Col>
                </Row>

                <Modal
                  title='Bibliotheek'
                  visible={this.state.modalVisible}
                  onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
                  onOk={() => this.cloneForm()}
                  okText="Toevoegen"
                >
                  <Form layout={'vertical'}>
                    <Form.Item required hasFeedback label="Formulier">
                      <Select showSearch value={this.state.selectedId} placeholder={'Selecteer formulier'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                        {this.props.customer_forms && this.props.customer_forms.map((element) => (
                          <Select.Option id={element.id} value={element.id}>{element.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </Modal>
            </div>
        </div>
        );
    }
}

const ProjectFormsForm = Form.create({ name: 'form_forms' })(ProjectForms);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.forms.isFetching,
        isError : state.forms.isError,
        forms : state.forms.forms,
        customer_forms : state.forms.customer_forms,
        menus : state.forms.menus,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFormsAction : bindActionCreators(getFormsAction, dispatch),
        deleteFormsAction : bindActionCreators(deleteFormsAction, dispatch),
        searchFormsAction : bindActionCreators(searchFormsAction, dispatch),
        duplicateCloneAction : bindActionCreators(duplicateCloneAction, dispatch),
        getCustomerFormsAction : bindActionCreators(getCustomerFormsAction, dispatch),
        getFormMenuAction : bindActionCreators(getFormMenuAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFormsForm);
