import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, notification, Divider } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addDeliveryUserLibraryAction, getDeliveryUserLibraryAction, resetDeliveryUserAction, patchDeliveryUserLibraryAction } from '../../../actions/deliveryusersActions';

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';

import { showSuccess, showError } from '../../../utils/Notifications';

import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
// import PatchDeliveryUser from '../Forms/PatchDeliveryUser';

class DeliveryUserDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false,
      selected: [],
      text: '',
      tags: [],
    };
  }

  componentDidMount() {
    this.props.resetDeliveryUserAction();
    if(this.props.match.params.id) {
      this.props.getDeliveryUserLibraryAction(this.props.match.params.id);
    }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {
      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      if(this.props.match.params.id) {
        this.props.patchDeliveryUserLibraryAction(this.props.match.params.id, values).then(() => this.props.history.push(`/library/delivery-users/`));
      } else {
        this.props.addDeliveryUserLibraryAction(values).then(() => this.props.history.push(`/library/delivery-users/`));
      }

      showSuccess();
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Verantwoordelijken', 'link': '/library/delivery-users'}, {'label': this.props.delivery_user && this.props.delivery_user.email_address ? this.props.delivery_user.email_address : 'Toevoegen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='18' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                    <Link to={`/library/delivery-users/`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Divider></Divider>
            <Row>
              <Col span={24}>
                  <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                      <Row gutter={24} >

                          <Col span={12}>
                              <Form.Item  hasFeedback label="E-mail">
                                    {getFieldDecorator('email_address', {
                                        initialValue: this.props.delivery_user && this.props.delivery_user.email_address,
                                        rules: [{ required: true, message: 'Vul een e-mailadres in' },{type: 'email', message: 'Vul een geldig e-mailadres in'}],
                                    })(
                                        <Input type={'email'} />
                                    )}
                              </Form.Item>
                          </Col>

                      </Row>

                      <Row gutter={24}>
                          <Col span={24}>

                              <Form.Item
                                  label="Naam"
                                  hasFeedback
                                  >
                                  {getFieldDecorator('full_name', {
                                      initialValue: this.props.delivery_user && this.props.delivery_user.full_name,
                                      // rules: [{ required: true, message: true }],
                                  })(
                                  <Input type="text" />
                                  )}
                              </Form.Item>

                          </Col>
                      </Row>

                      <Row gutter={24}>
                          <Col span={24}>

                              <Form.Item
                                  label="Bedrijfsnaam"
                                  hasFeedback
                                  >
                                  {getFieldDecorator('company_name', {
                                      initialValue: this.props.delivery_user && this.props.delivery_user.company_name,
                                      // rules: [{ required: true, message: true }],
                                  })(
                                  <Input type="text" />
                                  )}
                              </Form.Item>

                          </Col>
                      </Row>

                  </Form>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

const DeliveryUserDetailForm = Form.create({ name: 'horizontal_login' })(DeliveryUserDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.delivery_users.isFetching,
      isError : state.delivery_users.isError,
      delivery_user: state.delivery_users.delivery_user,
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    addDeliveryUserLibraryAction : bindActionCreators(addDeliveryUserLibraryAction, dispatch),
    getDeliveryUserLibraryAction : bindActionCreators(getDeliveryUserLibraryAction, dispatch),
    resetDeliveryUserAction : bindActionCreators(resetDeliveryUserAction, dispatch),
    patchDeliveryUserLibraryAction : bindActionCreators(patchDeliveryUserLibraryAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryUserDetailForm);
