import React from 'react'
import PropTypes from 'prop-types'

import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  Collapse,
  Tooltip,
  Select,
} from 'antd';

const ButtonGroup = Button.Group;

class CCalculatorInputForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      price_per_unit: this.props.formData && this.props.formData.price_per_unit ? this.props.formData.price_per_unit : '0,00',
    };
  }

  getConvertedPrice = (price, force = true, decimals = 2) => {

      if(!price){ price = '0,00'; }

      price = price.replace(/[^0-9.,-]/gim, '');

      price = price.replace('.', ',');

      price = price.replace(',', '.');

      if(force)
      {   // round 2 decimals
          price = parseFloat(price);

          price = price.toFixed(decimals);
      }
      else
      {   // round max 2 decimals
          var tmp_price = parseFloat(price);

          tmp_price = Math.round(parseFloat(tmp_price) * 10000 + Number.EPSILON) / 10000;

          tmp_price = Math.abs(tmp_price);

          var decimal = tmp_price - Math.floor(tmp_price);

          decimal = Math.round(decimal * 10000) / 10000;

          if(('' + decimal).length > 4)
          {   // round if decimals > 2
              price = Math.round(parseFloat(price) * 100 + Number.EPSILON) / 100;

              price = '' + price.toFixed(2);
          }
          else
          {
              price = '' + price;

              var splitted = (''+price).split('.');

              if(splitted[1] && splitted[1].length > 2)
              {
                  price = price.substring(0, price.length - splitted[1].length + 2);
              }
          }
      }

      price = price.replace('.', ',');

      return price == 'NaN' ? '0,00' : price;
  }

  updateField(field, value)
  {
    this.props.formData[field] = value;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>

        <Form.Item required hasFeedback label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
            onChange: (event) => { this.updateField('label', event.target.value); }
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item required hasFeedback label={(
          <span>
            Eenheid
            <Tooltip className="mhs" title='Definieer de rekeneenheid van het formule veld. Bijvoorbeeld: het aantal vierkante meters (m2) of de lengte in centimeters (cm).'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>)} style={{marginBottom: 0}}>
            {getFieldDecorator('unit', {
              initialValue: this.props.formData && this.props.formData.unit,
              rules: [{ required: true, message: 'Vul een waarde in' }],
              onChange: (event) => { this.updateField('unit', event.target.value); }
            })(
              <Input />
            )}
        </Form.Item>

        { !this.props.disablePrices ? <Form.Item label={'BTW'} style={{marginBottom: 0}}>
          {getFieldDecorator('vat', {
            initialValue: this.props.formData && this.props.formData.vat,
            onChange: (value) => { this.updateField('vat', value); }
          })(
              <Select>
                <Select.Option value='9%'>9%</Select.Option>
                <Select.Option value='21%'>21%</Select.Option>
              </Select>
          )}
        </Form.Item> : null }

        { !this.props.disablePrices ? <Form.Item required hasFeedback label='Prijs (incl. BTW) per eenheid' style={{marginBottom: 0}}>
            {getFieldDecorator('price_per_unit', {
              initialValue: (''+(!this.state.price_per_unit ? '0,00' : parseFloat(this.state.price_per_unit.replace(',', '.')).toFixed(2))).replace('.', ','), // this.props.formData && this.props.formData.price_per_unit && this.props.formData.price_per_unit > 0 ? this.props.formData.price_per_unit : '0,00',
              rules: [{ required: true, message: 'Vul een waarde in' }],
              onChange: (e) => {

                  var converted_value = this.getConvertedPrice(e.target.value, false);

                  this.setState({price_per_unit: converted_value}, () => {

                      this.props.form.setFieldsValue({ ['price_per_unit']: converted_value });

                      this.updateField('price_per_unit', converted_value);
                  });

                  e.target.value = converted_value;
              }
            })(
            <Input
                prefix="€"
                // type="number"
                precision={2}
                value={this.state.price_per_unit}
                onBlur={() => {

                    var converted_value = this.getConvertedPrice(this.state.price_per_unit, true);

                    this.setState({price_per_unit: converted_value}, () => {

                        this.props.form.setFieldsValue({ ['price_per_unit']: converted_value });

                        this.updateField('price_per_unit', converted_value);
                    });
                }}
                />
            )}
        </Form.Item> : null }

        <Form.Item label={(
          <span>
            Placeholder
            <Tooltip className="mhs" title='Met een placeholder wordt een voorbeeld invulling voor dit vak weergegeven.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('placeholder', {
            initialValue: this.props.formData && this.props.formData.placeholder,
            onChange: (event) => { this.updateField('placeholder', event.target.value); }
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Min. waarde
            <Tooltip className="mhs" title='Bepaal de minimale waarde.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('minValue', {
            initialValue: this.props.formData && this.props.formData.minValue,
            onChange: (value) => { this.updateField('minValue', /*event.target.*/value); }
          })(
            <InputNumber type={'number'} min={0} />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Max. waarde
            <Tooltip className="mhs" title='Bepaal de maximale waarde.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('maxValue', {
            initialValue: this.props.formData && this.props.formData.maxValue,
            onChange: (value) => { this.updateField('maxValue', /*event.target.*/value); }
          })(
            <InputNumber type={'number'} min={0} />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Standaardwaarde
            <Tooltip className="mhs" title='Definieer een standaardwaarde. Deze standaardwaarde staat als standaard ingevuld in het veld. De standaardwaarde is bij invulling van het keuze formulier te overschrijven.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('defaultValue', {
            initialValue: this.props.formData && this.props.formData.defaultValue,
            onChange: (value) => { this.updateField('defaultValue', /*event.target.*/value); }
          })(
            <InputNumber
                type={'number'}
            />
          )}
        </Form.Item>

        {/*<Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld is verplicht</Checkbox>
          )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('listField', {
            initialValue: this.props.formData && this.props.formData.listField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op de meterkastlijst</Checkbox>
          )}
        </Form.Item>/**/}
      </Form>
    );
  }
}

const WrappedCCalculatorInputForm = Form.create({ name: 'text_input_form' })(CCalculatorInputForm);

class CCalculatorInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {},
      requiredField: ((this.props.parseData && this.props.parseData.requiredField != undefined && this.props.parseData.requiredField != false)) ? true : false,
      listField: ((this.props.parseData && this.props.parseData.listField != undefined && this.props.parseData.listField != false) || !this.props.parseData) ? true : false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  saveFieldValues = (silent = false) => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          values.requiredField = this.state.requiredField;
          values.listField = this.state.listField;
          this.setState({ formData: values }); //, openKeys: [] });
          if(!silent){ this.setState({ openKeys: [] }); }
          this.props.onSaveData(this.props.id, values);
        }
      })
    }
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  // renderExtraButtons = () => {
  //   return (
  //     <Button.Group>
  //       <Popconfirm
  //         icon={(<Icon type="question-circle" />)}
  //         placement="top"
  //         onCancel={(event) => event.stopPropagation()}
  //         size="small"
  //         okType="danger"
  //         title={"Element verwijderen"}
  //         onConfirm={() => this.removeElement()}
  //         okText="Ja"
  //         cancelText="Nee"
  //         disabled={this.props.disabled}
  //       >
  //         <Icon
  //           type="delete"
  //           onClick={event => {
  //             event.stopPropagation();
  //           }}
  //         />
  //       </Popconfirm>
  //       <Icon
  //         style={{marginLeft: 5}}
  //         type="plus-square"
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           this.duplicateElement(this.props.id);
  //         }}
  //       />
  //     </Button.Group>
  //   );
  // };

  toggleOption = (option) => {

      var checked = (this.state[option] == true ? false : true);

      this.setState({ [option]: checked }, () => {

          this.saveField();
      });
  };

  saveField = () => {

      var values = this.state.formData;

      // values.hide_for_occupant = this.state.hide_for_occupant;
      values.requiredField = this.state.requiredField;
      values.listField = this.state.listField;

      this.setState({ formData: values }); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  renderExtraButtons = () => {
    return (
      <div
          onClick={(event) => event.stopPropagation()}
          style={{ display: 'flex', marginTop: -1, marginRight: -13 }}
          >

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Vraag verplicht">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('requiredField');
                      }}
                      style={{ backgroundColor: (this.state.requiredField ? '#1890ff' : '#ffffff'), color: (this.state.requiredField ? '#ffffff' : '#000000') }}
                      >
                      <CheckOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

              <Tooltip title="Tonen op meterkastlijst">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('listField');
                      }}
                      style={{ backgroundColor: (this.state.listField ? '#1890ff' : '#ffffff'), color: (this.state.listField ? '#ffffff' : '#000000') }}
                      >
                      <ProfileOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Element verwijderen">
                  <Popconfirm
                    icon={(<QuestionCircleOutlined />)}
                    placement="bottom"
                    onCancel={(event) => event.stopPropagation()}
                    size="small"
                    okType="danger"
                    title={"Element verwijderen"}
                    onConfirm={() => this.removeElement()}
                    okText="Ja"
                    cancelText="Nee"
                    disabled={this.props.disabled}
                  >
                      <Button disabled={this.props.disabled}
                          size="small">
                          <DeleteOutlined
                            onClick={event => {
                                // event.stopPropagation();
                            }} />
                      </Button>
                  </Popconfirm>
              </Tooltip>

              <Tooltip title="Element dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.duplicateElement(this.props.id);
                      }}
                      >
                      <PlusSquareOutlined style={{marginLeft: 0}} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

      </div>
    );
  };

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Formule</b> - {this.state.formData && this.state.formData.label || 'label'}</div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              <WrappedCCalculatorInputForm disablePrices={this.props.disablePrices} wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />
              <Button onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Nummer invoer
        </div>
      );
    }
  }
}

export default CCalculatorInput;
