import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { HaveRole, CustomerCan } from '../../utils/RolesAndAbilities';
import { getLoginUserAction, switchCustomerAction, logoutAction } from '../../actions/authAction';
import { getQueuedItems, getNrOfNewQueuedItems, downloadJobFileAction, deleteQueuedItems } from '../../actions/queueActions';
import { globalSearchAction } from '../../actions/customerActions';
import HasRole from '../../components/HasRole/HasRole';
import configureStore from "../../store";
import './Topnav.css';
import {
  BellOutlined,
  ClusterOutlined,
  DownOutlined,
  FileImageOutlined,
  InboxOutlined,
  LoadingOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
  RightCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  SearchOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import {
  Layout,
  Avatar,
  Badge,
  Col,
  Row,
  Menu,
  Dropdown,
  Tooltip,
  Button,
  Divider,
  List,
  Select,
  Modal,
  Alert,
  Input,
} from 'antd';
import CustomTable from '../../components/CustomTable/CustomTable';
import { Link, withRouter } from "react-router-dom";
import Logo from '../../assets/img/logo.svg';
import moment from 'moment';
import { showSuccess } from '../../utils/Notifications';
import api from '../../utils/api';
import { API_URL } from '../../constants/settings';
import { useHotkeys } from 'react-hotkeys-hook';

const { XMLParser, XMLBuilder, XMLValidator} = require("fast-xml-parser");

const { SubMenu } = Menu;
const { Search } = Input;

const {
  Header
} = Layout;

function withHotKeys(WrappedComponent)
{
    return function(props) {

        var child = null;

        useHotkeys("/", () => { child.showSearch(); });

        return <WrappedComponent {...props} ref={(ref) => { child = ref; }} />
    }
}

class Topnav extends Component {

  refresh_queue_ref = null;
  refresh_queue_counter_ref = null;

  searchRef = null;

  constructor(props) {

    super(props);

    this.state = {
      activeMenu: [],
      news: [],
      nr_of_new_queued_items: null,
      showQueue: false,
      queueFetching: false,
      global_search: '',
      searched: false,
      searching: false
    };
  }

  componentDidMount() {

    axios({
      method: 'get',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: 'https://www.cobee.nl/feed/?post_type=news',
    }).then((response) => {

      var parser = new XMLParser(); //require('fast-xml-parser');
      var jsonObj = parser.parse(response.data);

      this.setState({ news: jsonObj.rss.channel.item });
    });

    //
    this.refreshQueueCounter(false, true);

    //
    if(this.props.match.url.includes('project')) {
      this.setState({activeMenu: ['project']})
    }
    if(this.props.match.url.includes('library')) {
      this.setState({activeMenu: ['library']})
    }
    if(this.props.match.url.includes('media')) {
      this.setState({activeMenu: ['media']})
    }
    if(this.props.match.url.includes('accounts')) {
      this.setState({activeMenu: ['accounts']})
    }
    if(this.props.match.url.includes('customers')) {
      this.setState({activeMenu: ['customers']})
    }

    // this.props.getLoginUserAction().then(() => {
        if(this.props.customers.length > 0) {

          if(this.props.selectedCustomer) {

            this.props.customers.map((customer) => {

                if(customer.id == this.props.selectedCustomer.id) {

                  this.props.switchCustomerAction(customer);
                }
            })
          } else {

            // has Cobee in customer list? Select Cobee
            var has_preselected_customer = false;

            this.props.customers.map((customer) => {
                if(customer.id == 1) {
                    has_preselected_customer = true;

                    this.props.switchCustomerAction(customer);
                }
                else if(customer.id == this.props.auth.user.customer_id)
                {
                    has_preselected_customer = true;

                    this.props.switchCustomerAction(customer);
                }
            });

            // select first match
            if(!has_preselected_customer)
            {
              this.props.switchCustomerAction(this.props.customers[0]);
            }
          }
        }
    // });
  }

  refreshQueueCounter(reload = false, repeat = false)
  {
      if(reload)
      {
          this.props.getQueuedItems();
      }

      //
      this.props.getNrOfNewQueuedItems().then(() => {

          this.setState({

              nr_of_new_queued_items: this.props.nr_of_new_queued_items,
              nr_of_completed_queued_items: this.props.nr_of_completed_queued_items
          }, () => {

              if(repeat)
              {
                  var that = this;

                  if(this.refresh_queue_counter_ref)
                  {
                      clearTimeout(this.refresh_queue_counter_ref);
                  }

                  this.refresh_queue_counter_ref = setTimeout(() => {

                      that.refreshQueueCounter(false, true);

                  }, 10000);
              }

          });
      });
  }

  showQueue()
  {
      this.setState({ showQueue: true });

      this.setState({ queueFetching: true });

      this.refreshQueueCounter();

      this.refreshQueue();
  }

  refreshQueue(refresh_counter = false)
  {
      /*if(refresh_counter)
      {
          this.refreshQueueCounter(false, true);
      }
      else*/
      // {
          this.props.getQueuedItems().then(() => {

              this.setState({ queueFetching: false });

              var that = this;

              if(this.refresh_queue_ref)
              {
                  clearTimeout(this.refresh_queue_ref);
              }

              this.refresh_queue_ref = setTimeout(() => {

                  that.refreshQueue();

              }, 10000);

          });
      // }
  }

  switchCustomer = (customer) => {

    this.props.switchCustomerAction(customer);

    this.props.history.push('/projects');
  }

  logout = () => {

    if(localStorage.getItem('idpName'))
    {
        var goto = '/' + (localStorage.getItem('idpName') ? '/' + localStorage.getItem('idpName') : '') + '?v=' + Date.now();

        // clean up cache
        localStorage.clear();

        document.location.href = goto;
    }

    // clean up cache
    localStorage.clear();
  }

  showSearch(show = true) {

      var this_obj = this;

      this.setState({ show_global_search: show, global_search: '', searched: false }, () => {

         if(show && this_obj.searchRef) {

             setTimeout(() => {

                 this_obj.searchRef.focus({
                     cursor: 'end',
                 });

             }, 400);
         }

      });
  }

  globalSearch()
  {
      this.setState({ searched: true, searching: true }, () => {

          this.props.globalSearchAction(this.state.global_search).then(() => {

              this.setState({ searching: false });
          });
      });
  }

  showHelpScout() {

      if(window.Beacon)
      {
          window.Beacon('toggle');
      }
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys }); }

  downloadFile(record)
  {
      this.props.downloadJobFileAction(record, this);
  }

  deleteItems()
  {
      Modal.confirm({
          title: 'Weet je zeker dat je deze items wilt verwijderen?',
          content: 'Klik op JA om deze actie te bevestigen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                // console.log(this.state.selectedRowKeys);
                this.props.deleteQueuedItems({'ids': this.state.selectedRowKeys}).then(() => {

                    resolve();

                    this.refreshQueueCounter(true);

                    // this.refreshQueue();

                    // this.showQueue();

                    this.setState({selectedRowKeys: []})

                    showSuccess('Succesvol verwijderd');
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  refresh() {

      var goto = '/';

      // if(localStorage.getItem('idpName'))
      // {
      //     goto = '/login/'+localStorage.getItem('idpName');
      // }

      // clean up cache
      localStorage.clear();

      // this.props.history.push(goto);
      // document.location.href = goto + '?v=' + Date.now();
  }

  render() {

    const { selectedRowKeys } = this.state;

    const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
    };

    const columns = [
        {
            title: 'Naam',
            dataIndex: 'name',
            render: (text, record) => (
              <span style={{ fontWeight: record.downloaded ? 'normal' : 'bold' }}>{text}</span>
            )
        },
        {
            title: 'Project',
            dataIndex: 'project_name',
            render: (text, record) => (
              <span>{text}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: false,
            render: (text, record) => (
              <span>{record.failed ? 'Niet geslaagd' : record.completed ? 'Voltooid' : ((record.current_record_nr ? record.current_record_nr : 0) + ' / ' + record.total_nr_of_records)}</span>
            )
        },
        {
            title: 'Aangevraagd op',
            dataIndex: 'created_at',
            render: (text, record) => (
              <span>{moment(text).format('DD/MM/YYYY')}</span>
            )
        },
        {
            title: 'Type',
            dataIndex: 'extension',
            render: (text, record) => (
              <span>{text}</span>
            )
        },
        {
            title: 'Download',
            dataIndex: 'created_at',
            render: (text, record) => (
              record.completed ?
                  <a target="_blank" onClick={() => { setTimeout(() => { this.refreshQueueCounter(true, true); }, 1000); return true; }} href={API_URL + '/job/' + record.id +'/download?token=' + localStorage.getItem('authToken')}><Button type='new'>Download</Button></a>
                  :
                  <LoadingOutlined />
            )
        },
    ];

    const global_search_columns = [
        {
            title: 'Bewoner',
            dataIndex: 'full_name',
            render: (text, record) => (
              <Link onClick={() => { this.showSearch(false); }} to={'/projects/'+record.project_id+'/addresses/'+record.address_id+'/contacts/'+record.occupant_id+'/detail'}>{text}</Link>
            )
        },
        {
            title: 'Adres',
            dataIndex: 'full_address',
            render: (text, record) => (
              <Link onClick={() => { this.showSearch(false); }} to={'/projects/'+record.project_id+'/addresses/'+record.address_id+'/general'}>{text}</Link>
            )
        },
        {
            title: 'Project',
            dataIndex: 'project_name',
            render: (text, record) => (
              <Link onClick={() => { this.showSearch(false); }} to={'/projects/'+record.project_id+'/dashboard'}>{text}</Link>
            )
        },
    ];

    //
    return (
      <Header style={{ background: '#1e1e27', paddingLeft: 24, paddingRight: 24, position: 'fixed', zIndex: 1000, width: '100%' }}>

        <Row gutter={24} type="flex" justify="space-between" style={{ flexDirection: 'row' }}>

          {/*<Col xs={16} sm={16} md={16} lg={12} xl={12} className="topNav--left">*/}
          <div style={{ float: 'left', display: 'flex', height: '64px', flex: 1 }} className="topNav--left">

            <img className="mrm" width="32" height="32" src={Logo} alt="logo" style={{ marginLeft: 12 }}></img>

            <Menu mode="horizontal" selectedKeys={this.state.activeMenu} theme="dark" style={{ background: '#1e1e27', flex: 1 }} onSelect={(e) => this.setState({activeMenu: e.selectedKeys})}>
              {this.props.CustomerCan('projects') ? (
                <Menu.Item key={'project'}>
                  <Link to="/projects">
                    <ProjectOutlined style={{fontSize: '16px'}} />
                    <span>Projecten</span>
                  </Link>
                </Menu.Item>
              ) : null}

              {this.props.CustomerCan('library') ? (
                <Menu.Item key={'library'}>
                  <Link to="/library">
                    <InboxOutlined style={{fontSize: '16px'}} />
                    <span>Bibliotheek</span>
                  </Link>
                </Menu.Item>
              ) : null}

              {this.props.CustomerCan('media') ? (
                <Menu.Item key={'media'}>
                  <Link to="/media">
                    <FileImageOutlined style={{fontSize: '16px'}} />
                    <span>Mediatheek</span>
                  </Link>
                </Menu.Item>
              ) : null}

              {this.props.CustomerCan('transactions') ? (
                <Menu.Item key={'transactions'}>
                  <Link to="/administration">
                    <FileImageOutlined style={{fontSize: '16px'}} />
                    <span>Administratie</span>
                  </Link>
                </Menu.Item>
              ) : null}

              {this.props.CustomerCan('users') ? (
                <Menu.Item key={'accounts'}>
                  <Link to="/accounts">
                    <TeamOutlined style={{fontSize: '16px'}} />
                    <span>Accounts</span>
                  </Link>
                </Menu.Item>
              ) : null}

              {this.props.HaveRole(['super-admin']) ? (
                <Menu.Item key={'customers'}>
                  <Link to="/customers">
                    <ClusterOutlined style={{fontSize: '16px'}} />
                    <span>Klanten</span>
                  </Link>
                </Menu.Item>
              ) : null}
            </Menu>

          {/*</Col>*/}
          </div>

          {/*<Col xs={8} sm={8} md={8} lg={12} xl={12} className="topNav--right" style={{textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>*/}
          <div style={{ float: 'right', display: 'flex', height: '64px' }} className="topNav--right">

            { /* customers */ }
            { this.props.HaveRole(['admin', 'projectmanager', 'guest', 'super-admin', 'user']) && this.props.selectedCustomer && this.props.selectedCustomer.id > 0 ? (
              <Select
                size="small"
                defaultActiveFirstOption={true}
                optionFilterProp="children"
                showSearch
                defaultValue={this.props.selectedCustomer.id}
                style={{ marginRight: 12, marginLeft: 12, minWidth: 150, marginTop: 20 }}
                onChange={(id) => this.switchCustomer(this.props.customers.find((elem) => elem.id === id ))}
                dropdownClassName="customer-selector"
                >
                { this.props.customers.map((v, k) => (
                  <Select.Option key={'' + v.id} value={v.id}>{v.name}</Select.Option>
                )) }
              </Select>
            ) : null }

            <Menu id="icons" mode="horizontal" theme="light" style={{ background: '#1e1e27', lineHeight: '62px', marginRight: '-10px', width: '136px' }}>

                { this.props.CustomerCan('projects') ?
                <Menu.Item key={'search'} onClick={(e) => { this.showSearch(); }}>
                    <SearchOutlined style={{ color: '#a6a6a6', fontSize: '16px' }} />
                </Menu.Item> : null }

                <SubMenu title={<BellOutlined style={{ color: '#a6a6a6', fontSize: '16px', width: 'auto' }} />}>
                    { this.state.news && this.state.news.map((item, index) => {
                      if(index < 5) {
                        return (
                          <Menu.Item key={'alert-'+(index + 1)} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <a href={item.link} target="_blank" style={{textOverflow: 'ellipsis', overflow: 'hidden', width: '100%'}}>{item.title.replace(/&#8211;/g, '-')}</a>
                            <RightCircleOutlined style={{marginLeft: 5}} />
                          </Menu.Item>
                        );
                      }
                    }) }
                </SubMenu>

                <Menu.Item key={'help'} onClick={(e) => { this.showHelpScout(); }}>
                    <QuestionCircleOutlined style={{ color: '#a6a6a6', fontSize: '16px' }} />
                </Menu.Item>

                <Menu.Item key={'export'} onClick={(e) => { this.showQueue(); }} style={{ position: 'relative' }}>
                    <ProfileOutlined style={{ color: '#a6a6a6', fontSize: '16px' }} />
                    {/*<div style={{ position: 'absolute', top: -16, right: -2 }}><Badge count={this.props.nr_of_new_queued_items} /></div>*/}
                    { this.props.nr_of_completed_queued_items > 0 ?
                    <div style={{ position: 'absolute', top: -16, right: -2 }}><Badge count={this.props.nr_of_completed_queued_items} /></div>
                    :
                    (
                        this.props.nr_of_new_queued_items > 0 ?
                    <div style={{ position: 'absolute', top: -15, right: -3 }}><HourglassOutlined style={{ paddingLeft: 3, height: 22, width: 22, borderRadius: 11, border: '1px solid #ffffff', backgroundColor: 'rgb(255, 77, 79)', color: '#ffffff', fontSize: '13px' }} /></div>
                        :
                        null
                    )}
                </Menu.Item>


                <SubMenu theme="light" title={<SettingOutlined style={{ color: '#a6a6a6', fontSize: '16px', width: 'auto' }} />}>

                  { this.props.HaveRole(['admin']) ? (
                  <Menu.Item theme="light" key="company-profile">
                      <Link to={`/customers/${ this.props.selectedCustomer && this.props.selectedCustomer.id}/general`}>
                        <ShopOutlined />
                        <span>Bedrijfsprofiel</span>
                      </Link>
                  </Menu.Item>
                  ) : null}

                  { this.props.HaveRole(['admin']) ? (
                  <Menu.Divider />
                  ) : null}

                  <Menu.Item theme="light" key="my-profile">
                      <Link to={`/accounts/${ this.props.auth && this.props.auth.user && this.props.auth.user.id}/profile`}>
                        <UserOutlined />
                        <span>Mijn account</span>
                      </Link>
                  </Menu.Item>

                  <Menu.Divider />

                  <Menu.Item theme="light" key="refresh" onClick={() => this.refresh()}>
                      <Link to="#" onClick={() => { this.refresh(); return false; }}>
                        <RetweetOutlined />
                        <span>Refreshen</span>
                      </Link>
                  </Menu.Item>

                  <Menu.Divider />

                  <Menu.Item theme="light" key="logout" onClick={() => this.logout()}>
                      <Link to="/">
                        <LogoutOutlined />
                        <span>Uitloggen</span>
                      </Link>
                  </Menu.Item>

                </SubMenu>

            </Menu>

            { /* export modal */ }
            <Modal
              title='Export opdrachten'
              width={960}
              visible={this.state.showQueue}
              okButtonProps={{ style: {display: 'none'} }}
              cancelText={'Sluiten'}
              onCancel={() => {

                  this.setState({ showQueue: false });

                  clearTimeout(this.refresh_queue_ref);
              }}
            >
                <div>
                  <Row className="mbm">
                    <Col span={24}>
                        <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="De te download bestanden zijn maximaal 48 uur te downloaden. Na het downloaden verdwijnen ze automatisch uit de lijst." />
                    </Col>
                  </Row>
                  <Row className="mbm">
                    <Col span={24}>
                        <Dropdown trigger={['click']} overlay={(
                            <Menu>
                              <Menu.Item onClick={() => { this.deleteItems(); }} key="1" style={{ color: '#f5222d' }}>
                                <span>Verwijderen</span>
                              </Menu.Item>
                            </Menu>
                          )}>
                          <Button type="secondary">
                            Acties <DownOutlined />
                          </Button>
                        </Dropdown>
                    </Col>
                  </Row>
                  <CustomTable
                    rowKey='id'
                    size="middle"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.props.queue}
                    // loading={this.state.queueFetching}
                  />
                </div>
              </Modal>

              { /* global search modal */ }
              <Modal
                title='Zoeken'
                width={960}
                visible={this.state.show_global_search}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={'Sluiten'}
                onCancel={() => {
                    this.showSearch(false);
                }}
                forceRender={true}
              >
                  <div>

                    <Row className="mbm">
                      <Col span={24}>
                          <Search
                            placeholder="Zoek naar bewoner en of adres"
                            onSearch={(value) => { this.globalSearch(value); }}
                            allowClear
                            onChange={(e) => { this.setState({ global_search: e.target.value }); }}
                            value={this.state.global_search}
                            ref={(ref) => { this.searchRef = ref; }}
                            loading={this.state.searching}
                            />
                      </Col>
                    </Row>

                    { /*this.state.global_search &&*/ !this.state.searching && this.state.searched ?
                    <CustomTable
                      rowKey='id'
                      size="middle"
                      columns={global_search_columns}
                      dataSource={this.props.global_search_results}
                      loading={this.state.searching}
                      /> : null }

                  </div>
                </Modal>

            {/*</Col>*/}
            </div>

          </Row>

        </Header>
    );
    }
  }

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.auth.isFetching,
    isError: state.auth.isError,
    auth: state.auth.user,
    customers: state.auth.customers,
    selectedCustomer: state.auth.selectedCustomer,
    queue: state.queue.queue,
    nr_of_new_queued_items: state.queue.nr_of_new_queued_items,
    nr_of_completed_queued_items: state.queue.nr_of_completed_queued_items,
    global_search_results: state.customer.global_search_results,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserAction : bindActionCreators(getLoginUserAction, dispatch),
    switchCustomerAction : bindActionCreators(switchCustomerAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    CustomerCan : bindActionCreators(CustomerCan, dispatch),
    logoutAction : bindActionCreators(logoutAction, dispatch),
    getQueuedItems : bindActionCreators(getQueuedItems, dispatch),
    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
    downloadJobFileAction : bindActionCreators(downloadJobFileAction, dispatch),
    deleteQueuedItems : bindActionCreators(deleteQueuedItems, dispatch),
    globalSearchAction : bindActionCreators(globalSearchAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withHotKeys(Topnav)));
