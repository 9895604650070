import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseCorrespondenceSteps from './SupportBaseCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess } from '../../../../../utils/Notifications';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import { checkSupportBaseFormDocumentsAction, checkSupportBaseFormEmailsAction } from '../../../../../actions/supportBaseActions';
import { getNrOfNewQueuedItems } from '../../../../../actions/queueActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class SupportBaseCorrespondenceCheck extends React.Component {

  state = {
      documents_email: this.props.auth.user.email,
      emails_email: this.props.auth.user.email,

      show_check_documents_modal: false,
      show_check_emails_modal: false,
      can_write: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });
  }

  patchInformationAddresses() {}

  checkDocuments()
  {
      // if(this.validateEmail(this.state.documents_email))
      // {
          this.setState({ documents_loading: true });

          this.props.checkSupportBaseFormDocumentsAction(this.props.match.params.form_id, (typeof this.state.documents_email == 'object' ? this.state.documents_email.join(',') : this.state.documents_email)).then(() => {

              showSuccess('Verzoek verzonden');

              this.setState({ show_check_documents_modal: false, documents_loading: false });

              this.props.getNrOfNewQueuedItems();
          });
      // }
      // else
      // {
      //     showSuccess('Vul een geldig e-mailadres in', '', '', '#ff0000');
      // }
  }

  checkEmails()
  {
      if(this.validateEmail(this.state.emails_email))
      {
          this.setState({ email_loading: true });

          this.props.checkSupportBaseFormEmailsAction(this.props.match.params.form_id, (typeof this.state.emails_email == 'object' ? this.state.emails_email.join(',') : this.state.emails_email)).then(() => {

              showSuccess('Verzoek verzonden');

              this.setState({ /*show_check_emails_modal: false,*/ email_loading: false });
          });
      }
      else
      {
          showSuccess('Vul een geldig e-mailadres in', '', '', '#ff0000');
      }
  }

  validateEmail(string)
  {
      var valid = true;

      if(!string){ return false; }

      var mails = string;

      if(typeof string == 'string')
      {
          mails = string.split(',');
      }

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    {/*<Button type="new">Opslaan en volgende</Button>*/}
                    <Link to={`/projects/${this.props.match.params.id}/support-base/form/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseCorrespondenceSteps current={4} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Row gutter={24} style={{display: (this.props.support_base_form.nr_of_letters > 0 || this.props.support_base_form.send_by_customer > 0) ? 'flex' : 'none'}}>
                <Col span={24}>
                      {/* // <Form.Item label="Verstuur het controle document voor de correspondentie per post naar">
                      //     <Row gutter={24}>
                      //         <Col span={24}>
                      //             <Select
                      //               mode="tags"
                      //               style={{width: '100%'}}
                      //               open={false}
                      //               tokenSeparators={[',', '\n']}
                      //               value={this.state.documents_email}
                      //               onChange={(value) => { this.setState({ documents_email: value }, () => {}); }}
                      //               ></Select>
                      //         </Col>
                      //     </Row>
                      // </Form.Item>*/ }

                  <Form.Item /*label="Controle correspondentie per post"*/>
                    <Button
                        type="new"
                        loading={this.state.documents_loading}
                        onClick={() => { this.checkDocuments(); }}
                        >Download documenten ter controle</Button>
                  </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} style={{display: this.props.support_base_form.nr_of_emails > 0 ? 'flex' : 'none'}}>
                <Col span={24}>
                    {/*<Modal
                        title="Controle correspondentie per e-mail"
                        visible={this.state.show_check_emails_modal}
                        onCancel={() => this.setState({ show_check_emails_modal: false })}
                        onOk={() => { this.checkEmails(); }}
                        okText="Verstuur"
                        >*/}
                      <Form.Item /*label="Verstuur controle correspondentie per e-mail naar "*/ style={{ marginTop: 24, width: '100%' }}>
                          <span>Verstuur controle correspondentie per e-mail naar</span><br />
                          <Select
                            mode="tags"
                            style={{ width: '100%', marginTop: 12 }}
                            // style={{}}
                            open={false}
                            tokenSeparators={[',', '\n']}
                            value={this.state.emails_email}
                            onChange={(value) => { this.setState({ emails_email: value }, () => {}); }}
                            ></Select>
                      </Form.Item>
                  {/*</Modal>*/}

                  <Form.Item /*label="Controle correspondentie per e-mail"*/>
                    <Button type="new" style={{ marginTop: 12 }} loading={this.state.email_loading} onClick={() => { this.checkEmails(); /*this.setState({ show_check_emails_modal: true });*/ }}>Verzend e-mail ter controle</Button>
                  </Form.Item>
                </Col>
              </Row>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseCorrespondenceCheckForm = Form.create({ name: 'intake_form' })(SupportBaseCorrespondenceCheck);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        support_base_form: state.support_base.support_base_form,
        auth: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkSupportBaseFormDocumentsAction : bindActionCreators(checkSupportBaseFormDocumentsAction, dispatch),
        checkSupportBaseFormEmailsAction : bindActionCreators(checkSupportBaseFormEmailsAction, dispatch),
        getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseCorrespondenceCheckForm);
