import React, { Component } from 'react';
// import './ProjectsSettings.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProjectAction, getProjectTransactions } from '../../../actions/projectActions';
import { showSuccess } from '../../../utils/Notifications';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import ProjectSidemenu from './ProjectSidemenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, DatePicker, Collapse, Table } from 'antd';
import PageHeader from '../../../components/PageHeader/PageHeader';
import moment from 'moment';
const { Title } = Typography;
// const { Panel } = Collapse;

const columns = [
  {
    title: 'Datum',
    width: 150,
    dataIndex: 'date',
    sorter: (a, b) => { return moment(a.date).unix() - moment(b.date).unix()},
    render: (text, index) => {
      return (
        <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>

      )
    }
  },{
    title: 'Type',
    sorter: (a, b) => { return a.type.localeCompare(b.type)},
    dataIndex: 'type',
  },{
    title: 'Aantal',
    sorter: (a, b) => { return a.nr_of - b.nr_of},
    dataIndex: 'nr_of',
  },{
    title: 'Prijs',
    sorter: (a, b) => { return a.total_price.localeCompare(b.total_price)},
    width: 100,
    dataIndex: 'total_price',
    render: (text, index) => {
      return (
        <span>{text}</span>

      )
    }
  }
];

class ProjectTransaction extends Component {
  state = {
    edit: true,
    billing: {},
    selectedDate: false, // moment(),
    transactions: [],
  };

  componentDidMount() {
      this.props.getProjectTransactions(this.props.match.params.id, null /*moment().format('YYYY-MM')*/).then(() => {
          this.setState({ billing: this.props.billing, transactions: this.props.billing.transactions });
      });
  }

  getTransactions = (month) => {
    this.setState({selectedDate: month});
    this.setState({ billing: {} });
    this.props.getProjectTransactions(this.props.match.params.id, month ? moment(month).format('YYYY-MM') : null).then(() => {
        this.setState({ billing: this.props.billing, transactions: this.props.billing.transactions });
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <ProjectSidemenu id={this.props.match.params.id} activeMenu='5' {...this.props} />

          <div layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
              <Form onSubmit={this.handleSubmit}>

                <Row className="mbm">
                  <Col span={12} >
                    <DatePicker
                        allowClear={true}
                        placeholder={'Filter op maand'}
                        mode={'month'}
                        format={'YYYY-MM'}
                        onPanelChange={(month) => this.getTransactions(month)}
                        onChange={(date, string) => this.getTransactions(string)}
                        value={this.state.selectedDate ? this.state.selectedDate : null}
                        style={{marginLeft: 8, marginBottom: 30}}
                        />
                  </Col>
                </Row>

                <Divider></Divider>

                <Row className="mbm">
                  <Col span={12} >
                    <span style={{fontWeight: 'bold'}}>Totale prijs: { this.state.billing.total_price }</span>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey='id'
                      rowSelection={null}
                      columns={columns}
                      dataSource={this.state.transactions}
                      pagination={false}
                      />
                  </Col>
                </Row>

              </Form>
          </div>

        </div>
      </div>
    );
  }
}

const ProjectTransactionForm = Form.create({ name: 'customer_modules' })(ProjectTransaction);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isError : state.project.isError,
    project: state.project.project,
    //modules: state.project.modules,
    billing: state.project.billing,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    getProjectTransactions : bindActionCreators(getProjectTransactions, dispatch),
    //syncProjectModulesAction : bindActionCreators(syncProjectModulesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTransactionForm);
