export default (state = {isError: false, isFetching: false, accounts:[], account:{ projects: [] }, /*accountroles: [], accountrole: {}*/}, action) => {
    switch (action.type) {
    case 'ACCOUNTS_ACTION_FETCH':
    return {
        ...state,
        accounts: [],
        isFetching: true,
        isError: false,
    }
    case 'ACCOUNT_ACTION_FETCH':
    return {
        ...state,
        account: { projects: [] },
        isFetching: true,
        isError: false,
    }
    case 'ACCOUNTS_ACTION_SUCCESS':
    return {
        ...state,
        accounts: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ACCOUNT_ACTION_SUCCESS':
    return {
        ...state,
        account: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_ACCOUNTS_ACTION_SUCCESS':
    return {
        ...state,
        accounts: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PATCH_ACCOUNT_ACTION_SUCCESS':
    return {
        ...state,
        account: action.payload.user,
        isFetching: false,
        isError: false,
    }
    case 'RESET_ACCOUNTS_ACTION_SUCCESS':
    return {
        ...state,
        account: {},
        isFetching: false,
        isError: false,
    }
    case 'ADD_ACCOUNTS_ACTION_SUCCESS':
    return {
        ...state,
        accounts: [action.payload.user, ...state.accounts],
        account: action.payload.user,
        isFetching: false,
        isError: false,
    }
    case 'DELETE_ACCOUNTS_ACTION_SUCCESS':
    return {
        ...state,
        accounts: state.accounts.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'ACCOUNTS_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    case 'ACCOUNT_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }


    case 'ACCOUNTROLES_ACTION_SUCCESS':
    return {
        ...state,
        accountroles: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ACCOUNTROLE_ACTION_SUCCESS':
    return {
        ...state,
        accountrole: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PATCH_ACCOUNTROLE_ACTION_SUCCESS':
    return {
        ...state,
        accountrole: action.payload.user_role,
        isFetching: false,
        isError: false,
    }
    case 'ADD_ACCOUNTROLES_ACTION_SUCCESS':
    return {
        ...state,
        accountroles: [action.payload.user_role, ...state.accountroles],
        accountrole: action.payload.user_role,
        isFetching: false,
        isError: false,
    }
    case 'DELETE_ACCOUNTROLES_ACTION_SUCCESS':
    return {
        ...state,
        accountroles: state.accountroles.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'ACCOUNTROLES_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    case 'ACCOUNTROLE_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    case 'RESET_ACCOUNTROLE_ACTION_SUCCESS':
    return {
        ...state,
        accountrole: {},
        isFetching: false,
        isError: false,
    }
    case 'RESET_ACCOUNTROLES_ACTION_SUCCESS':
    return {
        ...state,
        accountroles: [],
        isFetching: false,
        isError: false,
    }
    case 'ACCOUNTROLES_ACTION_FETCH':
    return {
        ...state,
        accountroles: [],
        isFetching: true,
        isError: false,
    }


    default:
        return state
    }
}
