import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import '../Schedule.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Checkbox,
    DatePicker,
    Input,
    Button,
    Dropdown,
    Menu,
    Table,
    Card,
    Modal,
    message,
    List,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import moment from 'moment';
import { getScheduleAction, getDayScheduleAction, removeDayScheduleAction, getScheduleGroupDaysAction, getSelectedGroupAddresses, updateSelectedGroupAddresses } from '../../../../../actions/scheduleActions';
import { getInformationAction } from '../../../../../actions/informationActions';

const { TextArea } = Input;
const { Option } = Select;

// let item_id = 1;

const EditableContext = React.createContext();

class DaySchedule extends Component
{
    state = {
        schedule: {},
        addresses: [],
        group_id: false,
        group: {},
        selectedAddressRowKeys: [],
        dayschedule: [],
        showDaySchedule: 'block',
        addDaySchedule: 'none',
        showGroupAddresses: 'none',
        days: [],
        information: []
    }

    componentDidMount()
    {
        this.props.getScheduleAction(this.props.match.params.id).then(() => {
            this.setState({
                schedule: this.props.schedule,
                addresses: this.props.addresses
            });
        });

        this.props.getDayScheduleAction(this.props.match.params.id).then(() => {
            this.setState({
                dayschedule: this.props.dayschedule
            });
        });

        this.props.getInformationAction(this.props.match.params.id).then(() => {
            this.setState({
                information: this.props.information
            });
        });
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    showConfirm = () => {
        Modal.confirm({
            title: 'Weet je zeker dat je deze items wilt verwijderen?',
            content: 'Klik op JA om deze actie te bevestigen.',
            okText: 'Ja',
            okType: 'danger',
            cancelText: 'Nee',
            onOk: () => {
                return new Promise((resolve, reject) => {
                  this.props.removeDayScheduleAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
                      resolve();
                      this.setState({selectedRowKeys: []})
                      showSuccess('Succesvol verwijderd');
                  });
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {},
        });
    }

    addDaySchedule()
    {
        this.setState({
            addDaySchedule: 'block',
            showDaySchedule: 'none',
            showGroupAddresses: 'none',
            group_id: false,
            group: {},
            days: [],
            added_group: {}
        });
    }

    updateDaySchedule(e)
    {
        e.preventDefault();

        this.props.form.validateFields((err, values) =>
        {
            this.setState({
                addBlockedDate: 'none',
                showBlockedDates: 'block',
                showGroupAddresses: 'none'
            });
        });
    }

    editGroup(record)
    {
        console.log(record);

        this.setState({
            addDaySchedule: 'block',
            showDaySchedule: 'none',
            showGroupAddresses: 'none',
            group: record,
            group_id: record.id
        });

        this.props.getScheduleGroupDaysAction(record.id).then(() => {

            this.setState({
                group_id: record.id,
                group: record,
                days: this.props.days
            });
        });
    }

    deleteGroup(record)
    {
        this.props.removeDayScheduleAction(this.props.match.params.id, {'ids': [record.id]}).then(() => {
            this.setState({
                dayschedule: this.props.dayschedule
            });
        });
    }

    selectAddresses()
    {
        // determine selected addresses for this group
        this.props.getSelectedGroupAddresses(this.state.group_id).then(() => {
            // preselect addresses
            var selectedaddresses = [];

            this.props.selectedaddresses.forEach(function(record){
                selectedaddresses.push(record.address_id);
            });

            this.setState({
                selectedAddressRowKeys: selectedaddresses
            });

            // show screen
            this.setState({
                addDaySchedule: 'none',
                showDaySchedule: 'none',
                showGroupAddresses: 'block'
            });
        });
    }

    saveAddressSelection()
    {
        // save address selection for group
        this.props.updateSelectedGroupAddresses(this.state.group_id, {'addresses': this.state.selectedAddressRowKeys}).then(() => {
            // success
            showSuccess('Succesvol opgeslagen');
        });
    }

    getDayItems(day)
    {
        const dayitems = this.state.days;

        if(dayitems)
        {
            dayitems.forEach(function(dayitem)
            {
                if(dayitem.id == day.id)
                {
                    return dayitem.items;
                }
            });
        }

        return [];
    }

    addDay(e) {

        // add day
        var days = this.state.days;
        const day_number = days.length + 1;

        days.push({
            id: day_number,
            number: day_number,
            items: []
        });

        this.setState({
            days: days,
            //group_id: false
        });
    }

    deleteDay(e, number)
    {
        var new_days = [];

        this.state.days.forEach(function(day){
            if(day.number != number)
            {
                new_days.push(day);
            }
        });

        this.setState({
            days: new_days
        });
    }

    addItem(e, day_number) {

        var days = this.state.days; // props

        var items = days[day_number - 1].items;

        var item_id = 0;

        items.forEach(function(item){
            if(item_id < item.id)
            {
                item_id = item.id;
            }
        });

        var newitem = {
            id: (++item_id),
            schedule_day_id: days[day_number - 1].id
        };

        items.push(newitem);

        days[(day_number - 1)].items = items;

        this.setState({
            days: days
        });
    }

    deleteItem(e, delete_item)
    {
        var days = [];

        this.state.days.forEach(function(day){
            if(day.id == delete_item.schedule_day_id)
            {
                var new_items = [];

                day.items.forEach(function(item){
                    if(delete_item.id != item.id)
                    {
                        new_items.push(item);
                    }
                });

                day.items = new_items;
            }

            days.push(day);
        });

        this.setState({
            days: days
        });
    }

    getDayNumber(day_id)
    {
        var day_nr = day_id;

        this.state.days.forEach(function(day) // props
        {
            if(day.id == day_id)
            {
                day_nr = day.number;
            }
        });

        return day_nr;
    }

    onAddressSelectChange = (selectedAddressRowKeys) => {
        this.setState({
            selectedAddressRowKeys
        });
    }

    render()
    {
        const groupcolumns = [
            {
              title: 'Naam',
              dataIndex: 'name',
            },
            {
                render: (text, record) => (
                    <span>
                        <Button type="edit" icon={<EditOutlined />} onClick={() => this.editGroup(record)}>Wijzigen</Button>
                        <Button type="delete" icon={<DeleteOutlined />} onClick={() => this.deleteGroup(record)}>Verwijderen</Button>
                    </span>
                )
            }
        ];

        const itemcolumns = [
            {
              title: 'Omschrijving',
              dataIndex: 'description',
              render: (text, record) => (
                  <Form.Item>
                      {getFieldDecorator('description['+(this.getDayNumber(record.schedule_day_id) - 1)+'][id_'+record.id+']', {
                          initialValue: text
                      })(
                        <Input />
                      )}
                  </Form.Item>
              )
            },
            {
              title: 'Item',
              dataIndex: 'item_name',
              render: (text, record) => (
                  <Form.Item>
                    {getFieldDecorator('information_item_id['+(this.getDayNumber(record.schedule_day_id) - 1)+'][id_'+record.id+']', {
                        initialValue: String(record.information_item_id)
                    })(
                      <Select optionFilterProp="children">
                        {
                            this.state.information.map(item => (
                              <Option key={item.id}>{item.title}</Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
              )
          },
          {
              title: '',
              render: (text, record) => (
                  <span>
                    <Button onClick={(e) => this.deleteItem(e, record)}>Item verwijderen</Button>
                  </span>
              )
          }
        ];

        const addresscolumns = [
            {
              title: 'Adres',
              dataIndex: 'full_address',
            }
        ];

        const { selectedAddressRowKeys } = this.state;

        const addressSelection = {
          selectedRowKeys: selectedAddressRowKeys,
          onChange: this.onAddressSelectChange
        };

        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <Form onSubmit={this.props.onSubmit}>
                <div>

                  <Card style={{display: this.state.showDaySchedule}}>
                      <Row className="mbm">
                        <Col span={24}>
                          <Button type="new" icon={<PlusOutlined />} onClick={() => this.addDaySchedule()}>Groep toevoegen</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Table
                            rowKey='id'
                            size="middle"
                            columns={groupcolumns}
                            dataSource={this.state.dayschedule}
                            loading={this.props.isFetching}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                  </Card>


                  <Card style={{display: this.state.addDaySchedule}}>

                      <Row className="mbm">
                          <Col span={24}>
                            {this.state.group_id > 0 && (
                            <span>
                                <Button type="new" icon={<PlusOutlined />} onClick={(e) => this.addDay(e)}>Dag toevoegen</Button>
                                <Button type="new" icon={<PlusOutlined />} onClick={(e) => this.selectAddresses(e)}>Adressen selecteren</Button>
                            </span>
                            )}
                            <Button type="cancel" onClick={() => this.setState({addDaySchedule: 'none', showDaySchedule: 'block', showGroupAddresses: 'none'})}>Annuleren</Button>
                          </Col>
                      </Row>

                      <Row className="mbm">
                          <Col span={12}>
                              <Form.Item label="Naam">
                                  {getFieldDecorator('name', {
                                      initialValue: this.state.group_id > 0 ? this.state.group.name : ''
                                  })(
                                  <Input />
                                  )}
                              </Form.Item>
                          </Col>
                          <Col span={12}>
                              <Form.Item label="Aantal adressen per dag">
                                  {getFieldDecorator('nr_of_addresses_per_day', {
                                      initialValue: this.state.group_id > 0 ? this.state.group.nr_of_addresses_per_day : ''
                                  })(
                                  <Input />
                                  )}
                              </Form.Item>
                          </Col>
                      </Row>


                      <List
                          itemLayout="horizontal"
                          dataSource={this.state.days}
                          renderItem={day => (
                              <Table
                                  bordered
                                  title={() => 'Dag ' + day.number}
                                  columns={itemcolumns}
                                  dataSource={day.items}
                                  pagination={false}
                                  key={'day[' + day.id + ']'}
                                  rowKey={(record, row) => 'dayitems[' + day.day_number + '][' + row + ']'}
                                  footer={() =>
                                      <span>
                                          <Button onClick={(e) => this.addItem(e, day.number)}>Item toevoegen</Button>
                                          <Button onClick={(e) => this.deleteDay(e, day.number)}>Dag verwijderen</Button>
                                      </span>

                                  }
                                  />
                          )}
                      />

                  </Card>


                  <Card style={{display: this.state.showGroupAddresses}}>

                      <Row className="mbm">
                          <Col span={24}>
                            <Button type="new" icon={<PlusOutlined />} onClick={(e) => this.saveAddressSelection(e)}>Selectie opslaan</Button>
                            <Button type="cancel" onClick={() => this.setState({addDaySchedule: 'block', showDaySchedule: 'none', showGroupAddresses: 'none'})}>Ga terug</Button>
                          </Col>
                      </Row>

                      <Table
                          bordered
                          rowSelection={addressSelection}
                          columns={addresscolumns}
                          dataSource={this.state.addresses ? this.state.addresses : []}
                          pagination={false}
                          rowKey={(record, row) => record.id}
                          />

                  </Card>

                </div>

            </Form>
        );
    }
}

const DaySchedulePage = Form.create({ name: 'data_form' })(DaySchedule);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.schedule.isFetching,
        isError: state.schedule.isError,
        schedule: state.schedule.schedule,
        dayschedule: state.schedule.dayschedule, // groups
        addresses: state.schedule.addresses, // addresses
        days: state.schedule.days, // days
        information: state.information.information, // information items
        dayitems: state.schedule.dayitems, // items per day
        selectedaddresses: state.schedule.selectedaddresses, // selected addresses for group
        added_group: state.schedule.added_group // added group
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScheduleAction : bindActionCreators(getScheduleAction, dispatch),
        getDayScheduleAction : bindActionCreators(getDayScheduleAction, dispatch),
        removeDayScheduleAction : bindActionCreators(removeDayScheduleAction, dispatch),
        getScheduleGroupDaysAction : bindActionCreators(getScheduleGroupDaysAction, dispatch),
        getInformationAction : bindActionCreators(getInformationAction, dispatch),
        getSelectedGroupAddresses : bindActionCreators(getSelectedGroupAddresses, dispatch),
        updateSelectedGroupAddresses : bindActionCreators(updateSelectedGroupAddresses, dispatch),
        removeDayScheduleAction : bindActionCreators(removeDayScheduleAction, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DaySchedulePage);
