export default (state = {

    isError: false,
    isFetching: false,
    isFetchingZip: false,
    addresses: [],
    address:{
        exclude: false
    },
    addressesbyzip: [],
    shareddata: [],
    note: {},
    document: {},
    address_field_values: [],

    message: '',
    fatal_errors: [],
    warnings: [],
    info: [],
    skipped_line_nrs: [],
    added_line_nrs: [],
    updated_line_nrs: []

}, action) => {

    switch (action.type) {

    case 'ADDRESS_FIELD_VALUES_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false
    }
    case 'ADDRESS_FIELD_VALUES_ACTION_SUCCESS':
    return {
        ...state,
        address_field_values: action.payload,
        isFetching: true,
        isError: false
    }
    case 'ADDRESS_FIELD_VALUES_ACTION_ERROR':
    return {
        ...state,
        isFetching: true,
        isError: false
    }
    case 'ADDRESS_FIELD_VALUES_ACTION_RESET':
    return {
        ...state,
        isFetching: true,
        isError: false,
        address_field_values: []
    }

    case 'IMPORT_ADDRESSES_ACTION_RESET':
    return {
        ...state,
        message: '',
        fatal_errors: [],
        warnings: [],
        info: [],
        skipped_line_nrs: [],
        added_line_nrs: [],
        updated_line_nrs: []
    }
    case 'ADDRESSES_IMPORT_ACTION_DRYRUN_SUCCESS':
    return {
        ...state,
        isFetching: true,
        isError: false,
        // addresses: action.payload.addresses,
        message: action.payload.message,
        fatal_errors: action.payload.fatal_errors,
        warnings: action.payload.warnings,
        info: action.payload.info,
        skipped_line_nrs: action.payload.skipped_line_nrs,
        added_line_nrs: action.payload.added_line_nrs,
        updated_line_nrs: action.payload.updated_line_nrs
    }
    case 'ADDRESSES_IMPORT_ACTION_REAL_SUCCESS':
    return {
        ...state,
        isFetching: true,
        isError: false,
        addresses: action.payload.addresses,
        message: action.payload.message,
        fatal_errors: action.payload.fatal_errors,
        warnings: action.payload.warnings,
        info: action.payload.info,
        skipped_line_nrs: action.payload.skipped_line_nrs,
        added_line_nrs: action.payload.added_line_nrs,
        updated_line_nrs: action.payload.updated_line_nrs
    }
    /*case 'ADDRESSES_IMPORT_ACTION_ERROR':
    return {
        ...state,
        isFetching: true,
        isError: false,
        // addresses: action.payload.addresses,
        message: action.payload.message,
        fatal_errors: action.payload.fatal_errors,
        warnings: action.payload.warnings,
        info: action.payload.info,
        skipped_line_nrs: action.payload.skipped_line_nrs,
        added_line_nrs: action.payload.added_line_nrs,
        updated_line_nrs: action.payload.updated_line_nrs
    }*/

    case 'ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: true,
        isError: false,
    }
    case 'ADDRESSES_ZIP_ACTION_FETCH':
    return {
        ...state,
        isFetchingZip: true,
        isError: false,
    }
    case 'ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESSES_SYNC_WITH_WOCO_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESSES_IMPORT_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESSES_BYZIPCODE_ACTION_SUCCESS':
    return {
        ...state,
        addressesbyzip: action.payload,
        isFetchingZip: false,
        isError: false,
    }
    case 'ADDRESSES_STATUS_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
    }
    case 'ADD_ADDRESS_ACTION_SUCCESS':
    return {
        ...state,
        addresses: [action.payload.address, ...state.addresses],
        address: action.payload.address,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESSES_SEARCH_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'IMPORT_ADDRESS_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESS_ACTION_SUCCESS':
    return {
        ...state,
        address: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SHARE_ADDRESS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESS_SHARED_DATA_ACTION_SUCCESS':
    return {
        ...state,
        shareddata: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADDRESS_GET_NOTE_ACTION_SUCCESS':
    return {
        ...state,
        note: action.payload,
        isFetching: false,
        isError: false,
    }
    /*case 'DELETE_ADDRESS_NOTE_ACTION_SUCCESS':
    return {
        ...state,
        shareddata: state.shareddata.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }*/
    case 'ADDRESS_GET_DOCUMENT_ACTION_SUCCESS':
    return {
        ...state,
        document: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DELETE_ADDRESS_DOSSIER_FILES_ACTION_SUCCESS':
    return {
        ...state,
        shareddata: state.shareddata.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'DELETE_ADDRESS_ACTION_SUCCESS':
    return {
        ...state,
        // addresses: state.addresses.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'ADDRESSES_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false,
        message: action.payload,
    }
    case 'ADDRESSES_ZIP_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetchingZip: false
    }
    case 'RESET_ADDRESS_ACTION_FETCH':
    return {
        ...state,
        address: {
            exclude: false
        },
        isError: false,
        isFetchingZip: false
    }
    case 'RESET_ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isError: false,
        isFetchingZip: false
    }
    case 'RESET_DOSSIER_ACTION_FETCH':
    return {
        ...state,
        note: {},
        document: {}
    }
    case 'RESET_ADDRESSES_ZIPCODE_ACTION':
    return {
        ...state,
        addressesbyzip:[],
        isError: false,
        isFetchingZip: false
    }
    case 'RESET_ADDRESSES_NOTES_ACTION':
    return {
        ...state,
        note: {},
        isError: false,
        isFetchingZip: false
    }
    case 'RESET_ADDRESSES_DOSSIER_ACTION':
    return {
        ...state,
        shareddata: [],
        isError: false,
        isFetchingZip: false
    }
    case 'RESET_PASSWORD_ADDRESS_ACTION_SUCCESS':
    return {
        ...state,
        isError: false,
        isFetching: false
    }
    default:
        return state
    }
}
