import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
} from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';

class SetupMailCorrespondence extends Component {

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
        <Row gutter={24} >
          <Col span={24}>
            <Form.Item label="Onderwerp">
              { getFieldDecorator('message', {
                initialValue: this.props.correspondence.methods.find((elem) => elem.type === 'mail').message,
                valuePropName: 'value',
                trigger: 'onEditorChange',
              })(
                <CustomEditor placeholders={'correspondence'} project_id={this.props.match.params.id} />
              ) }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'add_correspondence_form' })(SetupMailCorrespondence);
