import React from 'react'
import { ControlOutlined, RocketOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

class WorkOrderSidemenu extends React.Component {
  render () {
    return (
      <Menu
      mode="inline"
      style={{ width: 300}}
      defaultSelectedKeys={this.props.activeMenu}
      >
        <Menu.Item key={"1"}>
          <Link to={`/projects/${this.props.id}/modules/workorder`}>
            <RocketOutlined />
            <span>Werkvolgorde</span>
          </Link>
        </Menu.Item>
        <Menu.Item disabled={!this.props.HaveAbility('work-order', 'write')} key={"2"}>
          <Link to={`/projects/${this.props.id}/modules/workorder/settings`}>
            <ControlOutlined />
            <span>Instellingen</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

export default WorkOrderSidemenu;
