import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseCorrespondenceSteps from './SupportBaseCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../../utils/Notifications';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseFormByIdAction, updateSupportBaseCorrespondenceAction, checkSupportBaseFormDocumentAction } from '../../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { API_URL } from '../../../../../constants/settings';

const { TabPane } = Tabs;

class SupportBaseCorrespondenceLetter extends React.Component {

  state = {
    salutation_type: null,
    salutation: null,
    message_letter: '',
    message_email: '',
    show_letter: true,
    show_email: false,
    saved: true,
    can_write: false,
    iframeKey: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

       this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false).then(() => {
           this.setState({
               'salutation_type' : this.props.support_base_form.correspondences[0].salutation_type,
               'salutation' : this.props.support_base_form.correspondences[0].salutation ? this.props.support_base_form.correspondences[0].salutation : 'Aan de bewoner(s) van',
               'message_letter' : this.props.support_base_form.correspondences[0].message,
               'message_email' : this.props.support_base_form.correspondences[1].message,
           });
       });
  }

  allowNextStep = () =>
  {
      var status = true;

      this.props && this.props.form && this.props.form.validateFields((errors, values) => {

          if(errors){ this.setState({ saved: false }); }

          status = (errors || !this.state.saved ? false : true);

          if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
      });

      return status;
  }

  saveAndContinue(do_continue = true, preview = false)
  {
      this.props.form.validateFields((errors, values) => {

          if(errors)
          {
              this.setState({ saved: false });
          }

          if(!errors || !do_continue)
          {
              this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'letter', {
                      'salutation_type' : values.salutation_type,
                      'salutation' : values.salutation,
                      'message': values.message_letter,
              }).then(() => {

                  this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'email-letter', {
                      'salutation_type' : values.salutation_type,
                      'salutation' : values.salutation,
                      'message': values.message_email,
                  }).then(() => {

                      showSuccess('Succesvol opgeslagen');

                      this.setState({ saved: true });

                      if(do_continue)
                      {
                          this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/` + (this.props.support_base_form.nr_of_emails ? 'email' : 'attachments'));
                      }
                      else if(preview)
                      {
                          if(preview == 'letter')
                          {
                              // this.props.checkSupportBaseFormDocumentAction(this.props.match.params.form_id, false);
                              this.setState({iframeKey: (this.state.iframeKey + 1)});
                          }

                          if(preview == 'email')
                          {
                              this.props.checkSupportBaseFormDocumentAction(this.props.match.params.form_id, true);
                          }
                      }
                  });
              });
          }
          else if(errors)
          {
              showError('Nog niet alle verplichte velden zijn ingevuld.');
          }
      });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write} type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/form/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseCorrespondenceSteps validate={true} validator={() => { return this.allowNextStep(); }} current={1} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>

                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item required label="Aanhef">
                          { getFieldDecorator('salutation_type', {
                            initialValue: this.state.salutation_type ? this.state.salutation_type : 'anonymous',
                            rules: [{ required: true, message: 'Maak een keuze' }],

                          })(
                            <Select>
                              <Select.Option key={'personal'}>Gepersonaliseerde aanhef</Select.Option>
                              <Select.Option key={'anonymous'}>Aangepaste aanhef</Select.Option>
                            </Select>
                          ) }
                        </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item label="Aanhef indien contactpersoon onbekend">
                            {getFieldDecorator('salutation', {
                              initialValue: this.state.salutation,
                              rules: [{ required: true, message: 'Vul een aanhef in' }],
                            })(
                              <Input />
                            )}
                        </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>

                      <div align={'right'} style={{position: 'absolute', right: 12, zIndex: 9999}}>
                        <Button style={{display: (this.state.show_email ? 'inline-block' : 'none')}} onClick={() => { this.props.form.setFieldsValue({['message_email']: this.state.message_letter}); }}>Tekst brief post overnemen</Button>
                        <Button style={{marginLeft: 10}} onClick={() => {
                            if(!this.state.show_email)
                            {
                                this.saveAndContinue(false, 'letter');
                            }
                            else if(this.state.show_email)
                            {
                                this.saveAndContinue(false, 'email');
                            }
                        }}>Voorbeeld</Button>
                      </div>

                      <Tabs defaultActiveKey="1" onChange={(key) => { this.setState({show_email: key == 2}); }}>

                        <TabPane tab="Brief per post" key="1">
                            {/*<Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item required label="Tekst brief per post *">
                                    { getFieldDecorator('message_letter', {
                                      initialValue: this.state.message_letter,
                                      rules: [{ required: true, message: 'Vul een tekst in' }],
                                      valuePropName: 'value',
                                      trigger: 'onEditorChange',
                                      onEditorChange: (value, editor) => { this.setState({ message_letter: value }); }
                                    })(
                                      <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} editorWidth={696} />
                                    ) }
                                    </Form.Item>
                                </Col>
                            </Row>*/}

                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Form.Item required label="Tekst brief per post *">
                                    { getFieldDecorator('message_letter', {
                                      initialValue: this.state.message_letter,
                                      rules: [{ required: true, message: 'Vul een tekst in' }],
                                      valuePropName: 'value',
                                      trigger: 'onEditorChange',
                                      // onEditorChange: (value, editor) => { this.setState({ message_letter: value }); }
                                    })(
                                      <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} editorWidth={696} messageChanged={(e) => { this.setState({ message_letter: e }); }} />
                                    ) }
                                    </Form.Item>
                                </Col>
                                <Col xs={0} sm={0} md={0} lg={0} xl={12}>
                                    <Form.Item label="Voorbeeld">
                                        <div>
                                          <Button type="new" onClick={() => {
                                            this.saveAndContinue(false, 'letter');
                                          }} style={{margin: 0}}>Ververs voorbeeld</Button>
                                        </div>
                                        <iframe key={this.state.iframeKey} style={{border: '1px solid #f0f0f0', width: '100%', height: 'auto', minHeight: 910}} src={API_URL + '/support-base/form/' + this.props.match.params.form_id + '/check/document?token=' + localStorage.getItem('authToken')} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </TabPane>

                        { this.props.support_base_form.nr_of_emails ?
                        <TabPane tab="Brief als bijlage in de e-mail *" key="2">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item required label="Tekst brief als bijlage in de e-mail">
                                      { getFieldDecorator('message_email', {
                                        initialValue: this.state.message_email,
                                        rules: [{ required: true, message: 'Vul een tekst in' }],
                                        valuePropName: 'value',
                                        trigger: 'onEditorChange',
                                        // onEditorChange: (value, editor) => { this.setState({ message_email: value }); }
                                      })(
                                        <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ message_email: e }); }} />
                                      ) }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane> : null }

                      </Tabs>
                    </Col>
                  </Row>

              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseCorrespondenceLetterForm = Form.create({ name: 'support_base' })(SupportBaseCorrespondenceLetter);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form : state.support_base.support_base_form,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        updateSupportBaseCorrespondenceAction : bindActionCreators(updateSupportBaseCorrespondenceAction, dispatch),
        checkSupportBaseFormDocumentAction: bindActionCreators(checkSupportBaseFormDocumentAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseCorrespondenceLetterForm);
