import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import '../Schedule.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Checkbox,
  DatePicker,
  Input,
  Button,
  Dropdown,
  Menu,
  Table,
  Card,
  Modal,
  message,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import moment from 'moment';
import { getScheduleAction, getScheduleBlockedDatesAction, removeBlockedDatesAction, addScheduleBlockedDateAction } from '../../../../../actions/scheduleActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const { TextArea }    = Input;
const { RangePicker } = DatePicker;


class BlockedDates extends Component
{
  state = {
    schedule: {},
    selectedRowKeys: [],
    showBlockedDates: 'block',
    addBlockedDate: 'none',
    blockeddates: [],
    visible: false,
  }

  componentDidMount()
  {
    this.props.getScheduleBlockedDatesAction(this.props.match.params.id).then(() => {

      this.setState({
        blockeddates: this.props.blockeddates
      });

    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showConfirm = () => {
    Modal.confirm({
      title: 'Weet je zeker dat je deze items wilt verwijderen?',
      content: 'Klik op JA om deze actie te bevestigen.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.props.removeBlockedDatesAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
            this.setState({selectedRowKeys:[]});
            resolve();
            showSuccess('Succesvol verwijderd');
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  addBlockedDate(e) {
    e.preventDefault();

    this.props.form.validateFields((err, values) =>
    {


      values.start_date = values.date_range[0].format('YYYY-MM-DD');
      values.end_date   = values.date_range[1].format('YYYY-MM-DD');
      // console.log(values);
      this.props.addScheduleBlockedDateAction(this.props.match.params.id, values);

      this.setState({visible: false})
    });
  }

  render()
  {
    const columns = [{
      title: 'Datum',
      dataIndex: 'start_date',
      render: (index, record) => (
        <span>{ moment(record.start_date).format('DD/MM/YYYY') }</span>
      )
    }];

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };

    return (
      <div>
      <Card>
        <Row className="mbm">
          <Col span={24}>
            <Button disabled={!this.props.HaveAbility('scheduling', 'write')} icon={<PlusOutlined />} type="new" onClick={() => this.setState({visible: true})}>Toevoegen</Button>
            <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('scheduling', 'write') ? false : true } overlay={(
                <Menu>
                  <Menu.Item onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                </Menu>
              )}>
              <Button type="secondary">
                Acties <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.blockeddates}
              loading={this.props.isFetching}
              pagination={false}
              />
          </Col>
        </Row>
      </Card>

      <Modal
        title="Vrije dagen"
        visible={this.state.visible}
        onOk={(e) => this.addBlockedDate(e)}
        okText={'Selecteer'}
        onCancel={() => this.setState({visible: false})}
        >
        <Row>
          <Col span={24}>
            <Form layout={'vertical'}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item required label="Datum" >
                    {getFieldDecorator('date_range', {
                      initialValue: [dayjs(), dayjs()],
                      rules: [{ required: true, message: 'Vul een datum in' }],
                    })(
                      <RangePicker format={['DD/MM/YYYY', 'DD/MM/YY']} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
    );
}
}

const BlockedDatesPage = Form.create({ name: 'data_form' })(BlockedDates);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.schedule.isFetching,
    isError : state.schedule.isError,
    schedule: state.schedule.schedule,
    blockeddates: state.schedule.blockeddates,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScheduleAction : bindActionCreators(getScheduleAction, dispatch),
    getScheduleBlockedDatesAction : bindActionCreators(getScheduleBlockedDatesAction, dispatch),
    addScheduleBlockedDateAction : bindActionCreators(addScheduleBlockedDateAction, dispatch),
    removeBlockedDatesAction : bindActionCreators(removeBlockedDatesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedDatesPage);
