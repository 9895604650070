import React, { Component, cloneElement } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Modal,
  Dropdown,
  Menu,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSurveyAction, getSurveyQuestionsAction, patchSurveyAction, updateSurveyQuestionsOrderAction, removeSurveyQuestionsAction, previewAction } from '../../../../../actions/surveysActions';
import PatchSurveyForm from '../Form/PatchSurvey';
import AddSurveyQuestionForm from '../Form/AddSurvey';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import SurveySteps from '../Steps/SurveySteps';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

const columns = [
{
  key: "sort",
  width: 20
},
{
  title: 'Vraag',
  dataIndex: 'question',
  render: (text, record) => (
    <span>
      <Link to={`question/${record.id}`}>
        {text}
      </Link>
    </span>
  )
},{
  title: 'Type',
  dataIndex: 'type',
  render: (text, record) => (
    <span>
      {record.type == 'rating' ? '1 tot 10' : 'Open vraag'}
    </span>
  )
},{
  title: 'Aantal keer ingevuld',
  dataIndex: 'nr_of_answers',
  render: (text, record) => (
    <span>
      {text}
    </span>
  )
},{
  title: 'Gemiddelde score',
  dataIndex: 'avg_score',
  render: (text, record) => (
    <span>
      {text}
    </span>
  )
}];

const confirm = Modal.confirm;

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }

// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     // Don't replace items with themselves
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     // Time to actually perform the action
//     props.moveRow(dragIndex, hoverIndex);
//
//     // Note: we're mutating the monitor item here!
//     // Generally it's better to avoid mutations,
//     // but it's good here for the sake of performance
//     // to avoid expensive index searches.
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );

class SurveyQuestions extends Component {

  state = {
    selectedRowKeys: []
  };

  deleteSurveyQuestions = () => {
    confirm({
      title: 'Wil je deze items verwijderen?',
      content: 'Als je op JA klikt, worden de items verwijderd.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.props.removeSurveyQuestionsAction(this.props.match.params.surveyid, {'ids': this.state.selectedRowKeys}).then(() => {
            resolve();
            showSuccess('Succesvol verwijderd');
            // console.log(this.props.surveyquestions);
            //this.setState({ data: []});
            this.setState({data: this.props.surveyquestions, selectedRowKeys: []})
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  componentDidMount() {
    this.props.getSurveyQuestionsAction(this.props.match.params.surveyid).then(() => {
      this.setState({data: this.props.surveyquestions})
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  // components = {
  //   body: {
  //     row: DragableBodyRow,
  //   },
  // };
  //
  // moveRow = (dragIndex, hoverIndex) => {
  //   const { data } = this.state;
  //   const dragRow = data[dragIndex];
  //
  //   this.setState(
  //     update(this.state, {
  //       data: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.data.map((v,k) => v.id);
  //
  //   this.props.updateSurveyQuestionsOrderAction(this.props.match.params.surveyid, {'surveyquestions' : sort}).then(() => {
  //
  //   });
  // };

  render() {

    const { survey } = this.state;
    const { surveyquestions } = this.state;

    const rowSelection = {
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var data = this.state.data;

        const activeIndex = data.findIndex((i) => i.id === active.id);
        const overIndex = data.findIndex((i) => i.id === over.id);

        this.setState({ data: arrayMove(data, activeIndex, overIndex) }, () => {

          var sort = this.state.data && this.state.data.map && this.state.data.map((v,k) => v.id);

          this.props.updateSurveyQuestionsOrderAction(this.props.match.params.surveyid, {'surveyquestions' : sort}).then(() => {});
        });
      }
    };

    if(!this.state.data || !this.state.data.map || this.props.isFetching){ return null; }

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Link to={`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/addresses`}>
                <Button disabled={!this.props.HaveAbility('survey' ,'write')} type="new">Opslaan en volgende</Button>
              </Link>
              <Link to={`/projects/${this.props.match.params.id}/modules/enquete`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
          <Col span={12} align={'right'}>
              <Button onClick={() => { this.props.previewAction(this.props.match.params.surveyid); }}>Voorbeeld</Button>
          </Col>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <SurveySteps version={this.props.survey ? this.props.survey.version : 2} current={1} {...this.props}/>
            </Card>
          </Col>
        </Row>

        <Card>
        <Row className="mbm">
          <Col span={12} >
            <Link to="question/add">
              <Button disabled={!this.props.HaveAbility('survey' ,'write')} type="new" icon={<PlusOutlined />}>Toevoegen</Button>
            </Link>
            {this.props.HaveAbility(9, 'update') ? (
              <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                  <Menu>
                    <Menu.Item onClick={this.deleteSurveyQuestions} key="7" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary">
                  Acties <DownOutlined />
              </Button>
            </Dropdown>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <DndContext onDragEnd={onDragEnd}>
            <SortableContext
              items={this.state.data && this.state.data.map && this.state.data.map((i) => i.id)}
              strategy={verticalListSortingStrategy}
            >
              <CustomTable
                rowKey='id'
                columns={columns}
                dataSource={this.state.data}
                components={{
                    body: {
                        row: DraggableRow
                    }
                }}
                size="middle"
                rowSelection={rowSelection}
                loading={this.props.isFetching}
                />
            </SortableContext>
          </DndContext>
        </Col>
      </Row>
      </Card>
    </div>
    );
}
}

const SurveyQuestionForm = Form.create({ name: 'add_survey_form' })(SurveyQuestions);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.survey.isFetching,
    isError : state.survey.isError,
    survey: state.survey.survey,
    surveyquestions: state.survey.surveyquestions,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
    getSurveyQuestionsAction : bindActionCreators(getSurveyQuestionsAction, dispatch),
    patchSurveyAction : bindActionCreators(patchSurveyAction, dispatch),
    updateSurveyQuestionsOrderAction : bindActionCreators(updateSurveyQuestionsOrderAction, dispatch),
    removeSurveyQuestionsAction : bindActionCreators(removeSurveyQuestionsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewAction : bindActionCreators(previewAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionForm);
