import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Radio,
  DatePicker,
  notification,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNewsArticleAction, patchNewsAction, addNewsAction, resetNewsAction } from '../../../../../actions/newsActions';
import { getLibrariesAction } from '../../../../../actions/librariesActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import PatchNewsForm from '../Form/PatchNews';
import NewsSteps from '../Steps/NewsSteps';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import { showSuccess } from '../../../../../utils/Notifications';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

class NewsDetail extends Component {

    state = {
      imageMediaId: this.props.newsarticle.image_id || null
    }

    componentDidMount() {
      this.props.resetNewsAction();

      if(this.props.match.params.newsid) {
          this.props.getNewsArticleAction(this.props.match.params.newsid).then(() => {

          });
      }
    }

    patchNews(e) {
      if(e) {
        e.preventDefault();
      }

      this.patchNewsform.props.form.validateFields((err, values) => {

        if(values.date)
        {
            values.date = dayjs(values.date).format('YYYY-MM-DD');
        }

        if (!err) {
          if(this.props.match.params.newsid) {
              this.props.patchNewsAction(this.props.match.params.newsid, values).then(() => {
                this.props.history.push(`/projects/${this.props.match.params.id}/modules/news/${this.props.match.params.newsid}/addresses/`);
              });
          } else {
              this.props.addNewsAction(this.props.match.params.id, values).then((response) => {
                this.props.history.push(`/projects/${this.props.match.params.id}/modules/news/${response.data.news_article.id}/addresses/`);
              });
          }

          showSuccess();
        }
      });
    }

    render() {
        return (
          <div>

            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button type="new" disabled={!this.props.HaveAbility('news', 'write')} onClick={() => this.patchNews(null)}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/modules/news`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
              {/*<Col span={12} align="right">
                <div>
                  <Button disabled type="primary" style={{marginRight:10}}><Icon type="left" /> Vorige</Button>
                  <Button type="primary" onClick={() => this.patchNews().then(() => this.props.history.push(`/projects/${this.props.match.params.id}/modules/news/${this.props.match.params.newsid}/addresses/`)) }>Volgende<Icon type="right" /></Button>
                </div>
              </Col>*/}
            </Row>
            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <NewsSteps current={0} {...this.props}/>
                </Card>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <PatchNewsForm key="newsform" wrappedComponentRef={formRef => { this.patchNewsform = formRef }} {...this.props} />
              </Col>
            </Row>
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.news.isFetching,
      isError : state.news.isError,
      newsarticle: state.news.newsarticle,
      library: state.library.libraries,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getNewsArticleAction : bindActionCreators(getNewsArticleAction, dispatch),
      patchNewsAction : bindActionCreators(patchNewsAction, dispatch),
      getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
      addNewsAction : bindActionCreators(addNewsAction, dispatch),
      resetNewsAction : bindActionCreators(resetNewsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
