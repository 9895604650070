import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SavePesetModal from '../Modals/SavePresetModal'
import IntakeSteps from '../Steps/IntakeSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../utils/Notifications';
import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { getIntakeByIdAction, resetIntakeAction } from '../../../../actions/intakeActions';
import { shareAddressesAction } from '../../../../actions/addressesActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class ProjectIntakeAddresses extends React.Component {

  state = {
    selectedAddressIds: [],
    can_write: false,
    isLoading: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('intake' ,'write')
      });


    this.props.resetIntakeAction();

    if(this.props.match.params.intakeid) {
      this.props.getIntakeByIdAction(this.props.match.params.intakeid, true).then(() => {
          this.setState({selectedAddressIds: this.props.intake_form.addresses});
      });
    }
  }

  patchInformationAddresses() {
    // this.selectaddresses.current.share(); // <- error
    this.setState({isLoading: true});
    this.props.shareAddressesAction(this.props.match.params.id, {'object_type':'form', 'object_id':this.props.match.params.intakeid, 'address_ids': this.state.selectedAddressIds}).then((content) => {
      this.props.history.push(`/projects/${this.props.match.params.id}/intake/${this.props.match.params.intakeid}/results`);
      showSuccess();
      this.setState({isLoading: false});
    });
  }

  render () {
    return (
      <div>

          {/* buttons */}
          <Row className="mbm">
            <Col span={12}>
              <div>
                <Button loading={this.state.isLoading} disabled={!this.state.can_write} type="new" onClick={() => this.patchInformationAddresses()}>Opslaan en volgende</Button>
                <Link to={`/projects/${this.props.match.params.id}/intake`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>
            </Col>
          </Row>

          {/* steps */}
          <Row className="mbm">
            <Col span={24}>
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <IntakeSteps current={1} {...this.props}/>
              </Card>
            </Col>
          </Row>

          {/* form */}
          { !this.props.intake_form.addresses ? null :
          <Row gutter={24}>
            <Col span={24}>
              <SelectAddresses {...this.props} initialAddresses={this.props.intake_form.addresses} objectId={this.props.match.params.intakeid} objectType="form" onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds: selectedAddressIds})}/>
            </Col>
          </Row>
          }

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const ProjectIntakeAddressesForm = Form.create({ name: 'intake_form' })(ProjectIntakeAddresses);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        intake_form : state.intake.intake_form,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIntakeByIdAction : bindActionCreators(getIntakeByIdAction, dispatch),
        resetIntakeAction : bindActionCreators(resetIntakeAction, dispatch),
        shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntakeAddressesForm);
