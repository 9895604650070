import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder'
import { v1 as uuidv1 } from 'uuid';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch } from 'antd'
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSupportBaseFormByIdAction, addSupportBaseFormToCustomerAction, updateSupportBaseFormAction, resetSupportBaseAction, checkSupportBaseFormAction } from '../../../actions/supportBaseActions';

import PageHeader from '../../../components/PageHeader/PageHeader';

import LibrarySideMenu from '../LibrarySideMenu';
import { HaveAbility, HaveRole } from '../../../utils/RolesAndAbilities';


class SupportBaseDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      show_preview_buttons: (this.props.match.params.id ? true : false),
      can_write: false,
      show_form: false,
      loading: true,
    };
  }

  componentDidMount()
  {
    this.setState({
      can_write: this.props.HaveAbility('library' ,'write')
    });

    this.props.resetSupportBaseAction();

    if(this.props.match.params.id) {

      this.props.getSupportBaseFormByIdAction(this.props.match.params.id).then(() => {

        this.setState({formData: JSON.parse(this.props.support_base_form.form)}, () => {

            this.refs.FormBuilder.parse(this.state.formData);

            this.setState({ show_form: true });

            this.setState({ loading: false });
        });
      });
    }
    else
    {
        this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.form.validateFields((err, values) => {

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      values.form = JSON.stringify(this.state.formData);

      if (this.props.match.params.id) {
        this.props.updateSupportBaseFormAction(this.props.match.params.id, values).then(() => {
          if (redirect) {
            this.props.history.push(`/library/support-base`);
          }
          else {
            //showSuccess('Succesvol opgeslagen');
          }

          this.setState({ show_preview_buttons: true });
        });
      }
      else {
        this.props.addSupportBaseFormToCustomerAction(values).then(() => {

          this.props.history.push('/library/support-base/' + this.props.support_base_form.id + '/detail');

          this.props.getSupportBaseFormByIdAction(this.props.support_base_form.id).then(() => {

            this.setState({ formData: JSON.parse(this.props.support_base_form.form) }, () => {

              this.refs.FormBuilder.parse(this.state.formData);

              this.setState({ show_form: true });
            });
          });
        });
      }

      showSuccess();
    });
  }

  previewMail()
  {
      this.props.checkSupportBaseFormAction(this.props.match.params.id, 'mail');
  }

  previewDigital()
  {
      this.props.checkSupportBaseFormAction(this.props.match.params.id, 'digital');
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Draagvlakken', 'link': '#'}, {'label': 'Draagvlak'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='7' activeSubMenu='subSupportBase1' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={this.state.loading || !this.state.can_write} type="new" onClick={() => this.handleSubmit(null, this.state.formData, true)}>Opslaan</Button>
                      <Link to={`/library/support-base`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col span={12} align={'right'} style={{display: (this.state.show_preview_buttons ? 'block' : 'none')}}>
                      <Button onClick={() => { this.previewMail(); }}>Voorbeeld post</Button>
                      &nbsp;
                      <Button onClick={() => { this.previewDigital(); }}>Voorbeeld digitaal</Button>
                  </Col>
                </Row>

                <Divider></Divider>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>
                      <Col span={16}>
                        <Form.Item required label="Naam">
                          {getFieldDecorator('name', {
                            initialValue: this.props.support_base_form ? this.props.support_base_form.name : '',
                            rules: [{ required: true, message: 'Vul een naam in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>

                      { this.props.selectedCustomer.id == 1 &&  this.props.HaveRole(['super-admin']) ?
                      <Col span={8}>
                          <Form.Item label="Gebruik voor demo project">
                            {getFieldDecorator('use_for_demo', {
                                valuePropName: 'checked',
                                initialValue: this.props.support_base_form && this.props.support_base_form.use_for_demo ? true : false
                            })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    />
                            )}
                          </Form.Item>
                      </Col> : null }

                    </Row>

                      <Row gutter={24} className="mbm" style={{display: (this.state.show_form ? 'block' : 'none')}}>
                        <Col span={24}>
                          <FormBuilder
                              onChangeForm={(data) => { if(this.state.can_write){ this.setState({formData: data}, (e) => this.handleSubmit(e, data, false))}}}
                              ref={'FormBuilder'}
                              formId={this.props.match.params.id}
                              sections={1}
                              enabledElements={[
                                  'sb_select',
                                  'sb_textinput',
                                  'sb_title',
                                  'sb_textareainput',
                                  'sb_numberinput',
                                  'sb_timeinput',
                                  'sb_dateinput',
                                  'sb_agreeinput',
                                  // 'sb_signatureinput',
                                  'sb_contactinput'
                              ]}
                              />
                        </Col>
                      </Row>

                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const SupportBaseDetailForm = Form.create({ name: 'intake_form' })(SupportBaseDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.support_base.isFetching,
    isError : state.support_base.isError,
    support_base_form : state.support_base.support_base_form,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
    addSupportBaseFormToCustomerAction : bindActionCreators(addSupportBaseFormToCustomerAction, dispatch),
    updateSupportBaseFormAction : bindActionCreators(updateSupportBaseFormAction, dispatch),
    resetSupportBaseAction : bindActionCreators(resetSupportBaseAction, dispatch),
    checkSupportBaseFormAction : bindActionCreators(checkSupportBaseFormAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseDetailForm);
