import React, { Component } from 'react'
import { EditOutlined, ExclamationOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class SupportBaseSideMenu extends Component {
    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 300}}
                defaultSelectedKeys={this.props.activeMenu}
            >
                <Menu.Item key={"2"}>
                    <Link to={`/projects/${this.props.projectid}/support-base/settings`}>
                        <SettingOutlined />
                        <span>Instellingen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"1"}>
                    <Link to={`/projects/${this.props.projectid}/support-base/forms`}>
                        <EditOutlined />
                        <span>Formulieren</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"3"}>
                    <Link to={`/projects/${this.props.projectid}/support-base/reminders`}>
                        <ExclamationOutlined />
                        <span>Herinneringen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"4"}>
                    <Link to={`/projects/${this.props.projectid}/support-base/status`}>
                        <ExclamationOutlined />
                        <span>Status</span>
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }
}
