import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'
import { DeleteOutlined, PlusSquareOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Card, Input, Checkbox, Popconfirm, Select, Collapse, Row, Col } from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { Editor } from '@tinymce/tinymce-react';

class SBTitleInputForm extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        <Form.Item required label='Inhoud' style={{}}>
          {getFieldDecorator('wysiwyg', {
            initialValue: this.props.formData && this.props.formData.wysiwyg,
            valuePropName: 'value',
            trigger: 'onEditorChange',
            rules: [{ required: true, message: 'Vul inhoud in' }],
          })(
            <CustomEditor key={uuidv1()} ref={editor => this.editor = editor} id={'editor_'+uuidv1()} placeholders />
          )}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedSBTitleInputForm = Form.create({ name: 'title_input_form' })(SBTitleInputForm);

class SBTitleInput extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {post: true, digital: true},
      openKeys: [],
      wysiwyg: this.props.parseData && this.props.parseData.wysiwyg,
      post: ((this.props.parseData && this.props.parseData.post !== false) || !this.props.parseData) ? true : false,
      digital: ((this.props.parseData && this.props.parseData.digital !== false) || !this.props.parseData) ? true : false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  saveFieldValues = () => {
    if(this.state.modus == 'edit') {
      //console.log(this.state.wysiwyg);
      var values = {wysiwyg: this.state.wysiwyg, post: this.state.post, digital: this.state.digital};
      // this.form.props.form.validateFields((error, values) => {
      //   if(!error) {
          this.setState({formData: values, openKeys: []});
          this.props.onSaveData(this.props.id, values);
      //   }
      // })
    }
  }

  saveField = () => {
      var values = this.state.formData;
      values.post = this.state.post;
      values.digital = this.state.digital;

      this.setState({formData: values}); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  renderExtraButtons = () => {
    return (
      <div onClick={(event) => event.stopPropagation()} style={{ display: 'flex' }}>
        <Checkbox defaultChecked={this.state.post} onChange={(e) => { e.stopPropagation(); this.setState({post: e.target.checked}, function(){ this.saveField(); }); }}>Post</Checkbox>
        <Checkbox defaultChecked={this.state.digital} onChange={(e) => { e.stopPropagation(); this.setState({digital: e.target.checked}, function(){ this.saveField(); }); }}>Digitaal</Checkbox>
        <Button.Group>
          <Popconfirm
            icon={(<QuestionCircleOutlined />)}
            placement="top"
            onCancel={(event) => event.stopPropagation()}
            size="small"
            okType="danger"
            title={"Element verwijderen"}
            onConfirm={() => this.removeElement()}
            okText="Ja"
            cancelText="Nee"
            disabled={this.props.disabled}
          >
            <DeleteOutlined
              onClick={event => {
                // event.stopPropagation();
              }} />
          </Popconfirm>
          <PlusSquareOutlined
            style={{marginLeft: 5}}
            onClick={(event) => {
              event.stopPropagation();
              this.duplicateElement(this.props.id);
            }} />
        </Button.Group>
      </div>
    );
  };

  onChangeCollapse = (panel) => {
    if(panel) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.setState({modus: 'show', openKeys: []});
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Toelichting</b></div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              {/*<WrappedSBTitleInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />*/}
              {this.state.openKeys.length > 0 && (
                <CustomEditor value={this.state.wysiwyg} onEditorChange={(text) => this.setState({wysiwyg: text})} placeholders />
              )}
              <Button style={{ marginTop: 15 }} onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Titel
        </div>
      );
    }
  }
}

export default SBTitleInput;
