export default (state = {isError: false, isFetching: false, activities: [], activity:{}}, action) => {
    switch (action.type) {
    case 'ACTIVITY_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'ACTIVITY_ACTION_SUCCESS':
    return {
        ...state,
        activities: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ACTIVITY_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
