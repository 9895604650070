import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess, showError } from '../../../utils/Notifications'
import { getSupportBaseRemindersAction } from '../../../actions/supportBaseActions';
import SupportBaseSideMenu from './SupportBaseSideMenu';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  MailOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Menu,
  Dropdown,
  Tooltip,
  Modal,
  Upload,
  Alert,
  Checkbox,
  Timeline,
  message,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { getSupportBaseStatusAction, resendSupportBaseToAction, sendSupportBaseRemindersToAction, uploadSignedFormAction, downloadSignedPDFDocumentAction, downloadTransactionPDFAction, remindSupportBaseToAction, downloadSignedDocumentsAction, searchSupportBaseFormsAddressesAction } from '../../../actions/supportBaseActions';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { API_URL } from '../../../constants/settings'

const { Title, Text } = Typography;

const Option = Select.Option;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

const Search  = Input.Search;

class ProjectSupportBaseStatus extends Component {
  state = {
    disabled: true,
    edit: true,
    selectedRowKeys: [],
    showDownloadForms: false,
    showUploadSignedForm: false,
    showLog: false,
    modalUploadSignedFormVisible: false,
    modalSignedFormVisible: false,
    modalShowAddressLog: false,
    modalDownloadSignedFormsVisible: false,
    download_forms_email: '', // this.props.auth.user.email,
    modalAddressLog: [],
    selected_address_id: null,
    signed_form: false,
    agreed: false,
    errorDocumentFile: false,
    errorDocumentSizeFile: false,
    filelist: [],
    can_write: false,
  };

  // example data
  examples = [{id: 1, name: 'Demo herinnering'}];

  componentDidMount()
  {
      this.setState({
          download_forms_email: (this.props.auth && this.props.auth.user ? this.props.auth.user.email : ''),
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      this.props.getSupportBaseStatusAction(this.props.match.params.id).then(() => {

      });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = (e) => { };

  translateStatus(status)
  {
      if(status == 'scheduled'){ return 'Ingepland'; }
      if(status == 'send'){ return 'Verzonden'; }
      if(status == 'resend'){ return 'Opnieuw verstuurd'; }
      if(status == 'reminder'){ return 'Herinnering verstuurd'; }
      if(status == 'answered'){ return 'Vragen beantwoord'; }
      if(status == 'signed'){ return 'Getekend'; }
      if(status == 'confirmed'){ return 'Bevestigd'; }

      return 'Onbekend';
  }

  handleResendForms()
  {   //
      this.props.resendSupportBaseToAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
          this.props.history.push(`/projects/${this.props.match.params.id}/support-base/resend/addresses/${this.props.support_base_form.id}`);
      });
  }

  handleSendReminders()
  {   //
      // this.props.resendSupportBaseToAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
      //     showSuccess('Verzoek verzenden herinneringen ingediend');
      //     this.setState({ selectedRowKeys: [] });
      //     this.props.getSupportBaseStatusAction(this.props.match.params.id).then(() => {});
      // });
      //

      this.props.remindSupportBaseToAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
          this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/addresses/${this.props.support_base_form.id}`);
      });
  }

  showUploadSignedForm(address_id, record)
  {
      this.setState({
          modalUploadSignedFormVisible: true,
          selected_address_id: address_id,
          agreed: record.agreed ? true : false,
          signed_form: record.signed_form ? true : false,
      });
  }

  showSignedForm(address_id, is_signed, agreed)
  {
      this.setState({
          modalSignedFormVisible: true,
          signed_form: is_signed ? true : false,
          agreed: agreed ? true : false,
          selected_address_id: address_id
      });
  }

  handleUploadForm()
  {
      var files = [];
      files['signed_pdf_document'] = this.state.filelist[0];

      this.props.uploadSignedFormAction(this.props.match.params.id, this.state.selected_address_id, this.state.signed_form, this.state.agreed, files).then(() => {
          this.props.getSupportBaseStatusAction(this.props.match.params.id).then(() => {
              showSuccess('Upload succesvol');

              this.setState({
                  modalUploadSignedFormVisible: false,
                  signed_form: false,
                  agreed: false,
                  filelist: [],
              });
          });
      });
  }

  handleSignedForm()
  {
      var files = [];
      files['signed_pdf_document'] = this.state.filelist[0];

      this.props.uploadSignedFormAction(this.props.match.params.id, this.state.selected_address_id, this.state.signed_form, this.state.agreed, files).then(() => {
          this.props.getSupportBaseStatusAction(this.props.match.params.id).then(() => {
              showSuccess('Succesvol opgeslagen');

              this.setState({
                  modalSignedFormVisible: false,
                  signed_form: false,
                  agreed: false,
                  filelist: [],
              });
          });
      });
  }

  showTimeline(id)
  { // TODO (modal)
      this.setState({ modalAddressLog: [] });

      this.setState({ modalShowAddressLog: true });

      this.props.addresses.map((object, key) => {
         if(object.id == id)
         {
             this.setState({ modalAddressLog: object.log });
         }
      });
  }

  validateEmail(string)
  {
      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  handleDownloadForms()
  {
      if(!this.validateEmail(this.state.download_forms_email))
      {
          showError('Vul een geldig e-mailadres in');
      }
      else
      {
          this.props.downloadSignedDocumentsAction(this.props.match.params.id, { ids: this.state.selectedRowKeys, email: this.state.download_forms_email }).then(() => {

              showSuccess('Verzoek verstuurd');

              this.setState({
                  modalDownloadSignedFormsVisible: false,
                  selectedRowKeys: []
              });
          });
      }
  }

  onSearch(value) {
    this.props.searchSupportBaseFormsAddressesAction(this.props.match.params.id, value);
  }

  render() {

    const columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Verzonden',
        dataIndex: 'send',
        render: (text, record) => (
          <span>
            {text ? moment(text).format('DD/MM/YYYY') : 'Nog niet verzonden'}
          </span>
        )
    },
    {
        title: 'Verzendwijze',
        dataIndex: 'send_methods',
        render: (text, record) => (
          <span>
            {text && text.map((value, key) => {
                if(value == 'letter')
                {
                    return <div style={{float: 'left'}}><MailOutlined /></div>;
                }
                else if(value == 'email')
                {
                    // return <Icon type="paper-clip" />
                    return <div style={{float: 'left', marginLeft: 5}}>@</div>
                }
                else
                {
                    //return value;
                }
            })}
          </span>
        )
    },
    {
        title: 'Getekend formulier',
        dataIndex: 'signed_form_pdf',
        render: (text, record) => (
          <span>
            { text ?
                record.postbode_id && !record.sign_request ?
              <Dropdown trigger={['click']} overlay={(
                  <Menu>
                    <Menu.Item style={{display: record.signed_document ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/support-base/project/' + this.props.match.params.id + '/status/form/view/signed-document/' + record.address_id  + '?token=' + localStorage.getItem('authToken')}>Bekijk formulier</a></Menu.Item>
                    <Menu.Divider style={{display: record.signed_document ? 'list-item' : 'none'}}></Menu.Divider>
                    <Menu.Item style={{display: record.signed_document ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/support-base/project/' + this.props.match.params.id + '/status/form/download/signed-document/' + record.address_id  + '?token=' + localStorage.getItem('authToken')}>Download formulier</a></Menu.Item>
                    <Menu.Divider style={{display: record.signed_document ? 'list-item' : 'none'}}></Menu.Divider>
                    <Menu.Item disabled={!this.state.can_write} onClick={() => { this.showSignedForm(record.address_id, record.signed_form, record.agreed); }}>Verwerk formulier</Menu.Item>
                    <Menu.Divider style={{display: record.transaction_log ? 'list-item' : 'none'}}></Menu.Divider>
                    <Menu.Item disabled={!this.state.can_write} onClick={() => { this.showUploadSignedForm(record.address_id, record); }}>Nieuwe toevoegen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary" style={{color: (record.status == 'signed' || record.status == 'confirmed') ? 'green' : 'orange', borderColor: (record.status == 'signed' || record.status == 'confirmed') ? 'green' : 'orange'}}>
                  Acties <DownOutlined />
                </Button>
              </Dropdown>
                :
              <Dropdown trigger={['click']} overlay={(
                  <Menu>
                    <Menu.Item style={{display: record.signed_document ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/support-base/project/' + this.props.match.params.id + '/status/form/view/signed-document/' + record.address_id  + '?token=' + localStorage.getItem('authToken')}>Bekijk formulier</a></Menu.Item>
                    <Menu.Divider style={{display: record.signed_document ? 'list-item' : 'none'}}></Menu.Divider>
                    <Menu.Item style={{display: record.signed_document ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/support-base/project/' + this.props.match.params.id + '/status/form/download/signed-document/' + record.address_id  + '?token=' + localStorage.getItem('authToken')}>Download formulier</a></Menu.Item>
                    <Menu.Divider style={{display: record.signed_document ? 'list-item' : 'none'}}></Menu.Divider>
                    <Menu.Item style={{display: record.transaction_log ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/support-base/project/' + this.props.match.params.id + '/status/form/download/transaction/' + record.address_id  + '?token=' + localStorage.getItem('authToken')}>Download transactie bon</a></Menu.Item>
                    <Menu.Divider style={{display: record.transaction_log ? 'list-item' : 'none'}}></Menu.Divider>
                    <Menu.Item disabled={!this.state.can_write} onClick={() => { this.showUploadSignedForm(record.address_id, record); }}>Nieuwe toevoegen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary" style={{color: (record.status == 'signed' || record.status == 'confirmed') ? 'green' : 'orange', borderColor: (record.status == 'signed' || record.status == 'confirmed') ? 'green' : 'orange'}}>
                  Acties <DownOutlined />
                </Button>
              </Dropdown>
              :
              <Button disabled={!this.state.can_write} onClick={() => { this.showUploadSignedForm(record.address_id, record); }} style={this.state.can_write ? {color: 'red', borderColor: 'red'} : {}} >Toevoegen</Button>
            }
          </span>
        )
    },
    {
        title: 'Akkoord',
        dataIndex: 'agreed',
        render: (text, record) => (
          <span>
            { (record.status != 'signed' && record.status != 'confirmed') ?
                    <CloseCircleOutlined style={{color: 'grey'}} />
                :
                    text === 1 || text === '1' || text == 'agreed' || text === true ?
                        <CheckCircleOutlined style={{color: 'green'}} />
                    :
                        <CloseCircleOutlined style={{color: 'red'}} />
            }
          </span>
        )
    },
    {
        title: 'Vraag | Opmerking',
        dataIndex: 'comment',
        render: (text, record) => (
            text ? <Button onClick={() => { this.setState({ comment: text, modalShowComments: true }); }}>Bekijken</Button> : null
            /*<Button
            style={{ color: (record.status != 'signed' && record.status != 'confirmed' ? 'grey' : (text ? 'green' : 'red')), borderColor: (record.status != 'signed' && record.status != 'confirmed' ? 'grey' : (text ? 'green' : 'red')) }}
            onClick={() => { if(text){ this.setState({ comment: text, modalShowComments: true }); } }}
            disabled={((record.status == 'signed' || record.status == 'confirmed') && text) ? false : true}
            >Bekijken</Button>*/
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => (
          <span>
            {this.translateStatus(text)}
          </span>
        )
    },
    {
        title: 'Tijdlijn',
        dataIndex: 'log',
        render: (text, record) => (
          <span>
            {<Button onClick={() => this.showTimeline(record.id)}>Bekijken</Button>}
          </span>
        )
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      getCheckboxProps: record => ({
        disabled: record.status ? false : true
      }),
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={12}>
              <div>
                <Dropdown trigger={['click']} overlay={(
                    <Menu>
                      {/*<Menu.Item key="6" onClick={() => { this.setState({ modalDownloadSignedFormsVisible: true }); }}>Download ingevulde formulieren</Menu.Item>
                      <Menu.Divider></Menu.Divider>*/}
                      <Menu.Item key="7" disabled={!this.state.can_write} onClick={() => { this.handleResendForms(); }}>Verstuur opnieuw</Menu.Item>
                      <Menu.Divider></Menu.Divider>
                      <Menu.Item key="8" disabled={!this.state.can_write} onClick={() => { this.handleSendReminders(); }}>Verstuur herinnering</Menu.Item>
                    </Menu>
                )} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true }>
                  <Button type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
                <span style={{marginLeft: 20}}>Tussenstand melding: {this.props.progress}</span>
              </div>
          </Col>
          <Col span={12}>
            <Search
              placeholder="Zoeken..."
              allowClear
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.addresses}
              // dataSource={this.examples}
              loading={this.props.isFetching}
            />
          </Col>
        </Row>

      </div>


      <Modal
        title='Upload getekend formulier'
        visible={this.state.modalUploadSignedFormVisible}
        onCancel={() => this.setState({ modalUploadSignedFormVisible: false })}
        onOk={() => this.handleUploadForm()}
        okText="Opslaan"
      >
          <Form className={'ant-form--custom'} onSubmit={() => { this.handleUploadForm(); }} layout={'vertical'} colon={true}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item multiple={false} label="Formulier">

                    <Upload
                      accept="application/pdf"
                      showUploadList={true}
                      fileList={this.state.filelist}
                      defaultFileList={this.state.filelist}
                      beforeUpload={(file, filelist) => {
                        if(file.size < 8000000) {
                          if (file.type !== 'application/pdf') {
                              this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                          }
                          else if(file.size <= 0 && this.state.modalAddAttachmentVisible)
                          {
                              this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                          }
                          else if(file.size > 0)
                          {
                              filelist = [];
                              filelist.push(file);

                              this.setState({filelist: filelist});

                              this.setState({ errorDocumentFile: false, errorDocumentSizeFile: false });
                          }
                        } else {
                            this.setState({ errorDocumentFile: false, errorDocumentSizeFile: true });
                        }

                        return false;
                      }} onRemove={() =>{
                        this.setState({filelist: null});
                      }}>
                      <Button >
                        <UploadOutlined /> Selecteer een bestand
                      </Button>
                    </Upload>

                    {this.state.errorDocumentFile ? (<Alert message="Upload een pdf bestand" type="error" showIcon />) : null}
                    {this.state.errorDocumentSizeFile ? (<Alert message="Bestand mag niet groter zijn dan 8MB" type="error" showIcon />) : null}
                    {/*this.props.document.file ? (
                      <Tag>{this.props.document.file}</Tag>
                    ) : null*/}

                  </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item hasFeedback label="Is akkoord">
                      <Checkbox
                          name="agreed"
                          checked={this.state.agreed && (this.state.agreed == true || this.state.agreed == '1' || this.state.agreed == 'agree') ? true : false}
                          onChange={(e) => { this.setState({ agreed: e.target.checked ? true : false }); }}
                      />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item hasFeedback label="Is getekend">
                      <Checkbox
                          name="signed_form"
                          checked={this.state.signed_form ? true : false}
                          onChange={(e) => { this.setState({ signed_form: e.target.checked ? true : false }); }}
                      />
                    </Form.Item>
                </Col>
              </Row>
            </Form>
        </Modal>

        <Modal
          title='Upload getekend formulier'
          visible={this.state.modalSignedFormVisible}
          onCancel={() => this.setState({ modalSignedFormVisible: false })}
          onOk={() => this.handleSignedForm()}
          okText="Opslaan"
        >
            <Form className={'ant-form--custom'} onSubmit={() => { this.handleUploadForm(); }} layout={'vertical'} colon={true}>
              <Row gutter={24}>
                  <Col span={12}>
                      <Form.Item hasFeedback label="Is akkoord">
                        <Checkbox
                            name="signed_form"
                            checked={this.state.signed_form ? true : false}
                            onChange={(e) => { this.setState({ signed_form: e.target.checked ? true : false }); }}
                        />
                      </Form.Item>
                  </Col>
              </Row>
            </Form>
        </Modal>

        <Modal
          title='Opmerkingen'
          visible={this.state.modalShowComments}
          okText={'Ok'}
          onOk={() => this.setState({ modalShowComments: false })}
          cancelText={'Sluiten'}
          onCancel={() => this.setState({ modalShowComments: false })}
        >
          <div>

              <Row>
                <Col span={24}>
                    <span>{ this.state.comment }</span>
                </Col>
              </Row>
          </div>
        </Modal>

        <Modal
          title='Tijdlijn'
          visible={this.state.modalShowAddressLog}
          okText={'Ok'}
          onOk={() => this.setState({ modalShowAddressLog: false })}
          cancelText={'Sluiten'}
          onCancel={() => this.setState({ modalShowAddressLog: false })}
        >
          <div>

              <Row>
                <Col span={24}>
                    <Timeline>
                      {
                          this.state.modalAddressLog.map((object, key) => {
                              return (<Timeline.Item><RenderHTML HTML={(moment(object.created_at).format('DD/MM/YYYY HH:mm')  + ' uur - ' + object.description)} /></Timeline.Item>);
                          })
                      }
                    </Timeline>
                </Col>
              </Row>
          </div>
        </Modal>

        <Modal
          title='Download formulieren'
          visible={this.state.modalDownloadSignedFormsVisible}
          onCancel={() => this.setState({ modalDownloadSignedFormsVisible: false })}
          onOk={() => this.handleDownloadForms()}
          okText="Verstuur"
        >
            <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Verstuur download link naar">
                            <Input
                              placeholder={'e-mail adres'}
                              value={this.state.download_forms_email}
                              onChange={(event) => { this.setState({ download_forms_email: event.target.value }); }}
                              />
                        </Form.Item>
                    </Col>
                </Row>
              </Form>
          </Modal>

    </div>
    );
  }
}

const ProjectSupportBaseStatusForm = Form.create({ name: 'projects_support_base_forms' })(ProjectSupportBaseStatus);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.support_base.isFetching,
    isError : state.support_base.isError,
    support_base_form : state.support_base.support_base_form,
    addresses : state.support_base.addresses,
    progress : state.support_base.progress,
    auth: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportBaseStatusAction : bindActionCreators(getSupportBaseStatusAction, dispatch),
    resendSupportBaseToAction : bindActionCreators(resendSupportBaseToAction, dispatch),
    remindSupportBaseToAction : bindActionCreators(remindSupportBaseToAction, dispatch),
    sendSupportBaseRemindersToAction : bindActionCreators(sendSupportBaseRemindersToAction, dispatch),
    uploadSignedFormAction : bindActionCreators(uploadSignedFormAction, dispatch),
    downloadSignedPDFDocumentAction : bindActionCreators(downloadSignedPDFDocumentAction, dispatch),
    downloadTransactionPDFAction  : bindActionCreators(downloadTransactionPDFAction, dispatch),
    downloadSignedDocumentsAction : bindActionCreators(downloadSignedDocumentsAction, dispatch),
    searchSupportBaseFormsAddressesAction : bindActionCreators(searchSupportBaseFormsAddressesAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSupportBaseStatusForm);
