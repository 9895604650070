import React from 'react'
import PropTypes from 'prop-types'
import { Result, Button } from 'antd'

class ErrorPage extends React.Component {
  render () {
    return(
      <div>
      <Result
        status="500"
        title="Foutmelding"
        subTitle="Sorry, er is iets fout gegaan."
        extra={<Button onClick={() => window.history.go(-2)} type="primary">Ga terug</Button>}
      />
      </div>
    );
  }
}

export default ErrorPage;
