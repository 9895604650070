import React, { Component } from 'react';
import { InboxOutlined, LoadingOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Select,
  List,
  Card,
  Divider,
  Upload,
  Tabs,
  Input,
  Checkbox,
  Switch,
  Tag,
  Row,
  Col,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMediaLibraryAction, addMediaFileAction, addMediaProjectFileAction, searchMediaLibraryAction, getMediaCategoriesAction } from '../../actions/medialibraryActions';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './AddFromMedia.css';
import { showError } from '../../utils/Notifications';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import CustomTable from '../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import { HaveRole, CustomerCan } from '../../utils/RolesAndAbilities';
import Compressor from 'compressorjs';

class AddFromMediaModal extends Component {

  state = {
    visible: this.props.visible,
    activeTab: '1', // (this.props.activeTab ? this.props.activeTab : "2"),
    filelist: [],
    selectedMediaItem: null,
    selectedMediaImage: '',
    medialibrary: [],
    selectedMediaId: null,
    searchText: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
    croppedImage: null,
    tempImage: null,
    cropVisible: false,
    tempImageName: '',

    hideBrowser: false,
    uploading: false,
    maxFileSize: 8000000,
    ratio: false, // false = free (4 / 3 initial)
    fixedRatio: false, // false = all

    group_filter: [],
    group_filter_and: true,

    loading: false,
    // crop_ready: true,
  }

  componentDidMount() {

      this.myRef = React.createRef();

      this.setState({selectedMediaImage: this.props.initialMediaUrl, selectedMediaId: this.props.initialMediaId, selectedMediaItem: this.props.initialMediaId});

      // this.showMediaLibrary(this.props.projectId); // project
      //
      // this.showMediaLibrary(); // customer

      this.setState({ activeTab: (this.props.activeTab ? this.props.activeTab : '1') });

      this.setState({ hideBrowser: (this.props.hideBrowser ? this.props.hideBrowser : false) });

      if(this.props.fixedRatio)
      {
          this.setState({ fixedRatio: true, ratio: this.props.fixedRatio });
      }
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.initialMediaUrl !== this.props.initialMediaUrl || prevProps.initialMediaId !== this.props.initialMediaId){

      this.setState({selectedMediaImage: this.props.initialMediaUrl, selectedMediaId: this.props.initialMediaId, selectedMediaItem: this.props.initialMediaId});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){

     if(prevState.selectedMediaImage === null) {

       return { selectedMediaImage: nextProps.initialMediaUrl, selectedMediaId: nextProps.initialMediaId};
    }
    else return null;
  }

  showMediaLibrary = (project_id = null) => {

    this.setState({ loading: true });

    this.props.getMediaCategoriesAction().then(() => {

        this.setState({visible: true});
    });

    this.props.getMediaLibraryAction(null, project_id /*this.props.projectId*/).then(() => {

        if(project_id)
        {
            this.setState({project_medialibrary: this.props.project_medialibrary}, () => { this.setState({ loading: false }); });
        }
        else
        {
            this.setState({customer_medialibrary: this.props.customer_medialibrary}, () => { this.setState({ loading: false }); });
        }
    });
  }

  onSelectMediaItem = (item) => {

    this.setState({selectedMediaId: item.id, visible: false, selectedMediaImage: item.original_image});
     
    this.props.onMediaSelection(item);
  }

  onActiveMediaItem = (item) => {

    this.setState({selectedMediaItem: item.id, selectedMediaId: item.id, selectedMediaImage: item.original_image});
  }

  onRemoveMediaItem = () => {

    this.setState({selectedMediaId: null, visible: false, selectedMediaImage: ""});

    this.props.onMediaRemove();
  }

  handleMediaUpload = (file) => {

    if(this.props.projectId) {

      this.props.addMediaProjectFileAction(this.props.projectId, null, {'image': file}).then((response) => {

        this.setState({visible: false, selectedMediaItem: response.data.medialibrary.id, selectedMediaId: response.data.medialibrary.id, selectedMediaImage: response.data.medialibrary.original_image});

        this.props.onMediaSelection({'id': response.data.medialibrary.id});
      });
    } else {

      this.props.addMediaFileAction(null, {'image': file}).then((response) => {

        this.setState({visible: false, selectedMediaItem: response.data.medialibrary.id, selectedMediaId: response.data.medialibrary.id, selectedMediaImage: response.data.medialibrary.original_image})
        this.props.onMediaSelection({'id': response.data.medialibrary.id});
      });
    }

    return false;
  }

  dataURLtoFile(dataurl, filename) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
  }

  handleCroppedMediaUpload = (file) => {

    // console.log('handleCroppedMediaUpload', file);

    this.setState({ uploading: true });

    if(this.props.projectId)
    {
      this.props.addMediaProjectFileAction(this.props.projectId, null, { 'image': file }).then((response) => {

        this.setState({visible: false, selectedMediaItem: response.data.medialibrary.id, selectedMediaId: response.data.medialibrary.id, selectedMediaImage: response.data.medialibrary.original_image});

        this.props.onMediaSelection({'image': response.data.medialibrary.original_image});

        this.setState({ uploading: false });

        if(this.props.parent && this.props.parent.loadData)
        {
            this.props.parent.loadData();
        }
      });
    }
    else
    {
      this.props.addMediaFileAction(null, {'image': file}).then((response) => {

        if(response.data && response.data.medialibrary)
        {
            this.setState({
                visible: false,
                selectedMediaItem: response.data.medialibrary.id,
                selectedMediaId: response.data.medialibrary.id,
                selectedMediaImage: response.data.medialibrary.original_image
            });

            this.props.onMediaSelection({'image': response.data.medialibrary.original_image});

            this.setState({ uploading: false });
        }
        else
        {
            showError('De afbeelding kon niet worden geupload');

            this.setState({ uploading: false });
        }
      });
    }

    return false;
  }

  searchMedia = (text) => {
    if(text == '') {
      this.setState({medialibrary: this.props.medialibrary});
    } else {
      this.setState({medialibrary: this.props.medialibrary.filter((element) => {
        return element.search.toLowerCase().includes(text.toLowerCase());
      })});
    }
  }

  async getCroppedImage() {

      var cropper = this.myRef.current.cropper;

      var croppedImage = cropper.getCroppedCanvas().toDataURL();

      croppedImage = this.dataURLtoFile(croppedImage, this.state.tempImageName);

      var this_obj = this;

      new Compressor(croppedImage, {

        quality: 0.8,

        success(compressedImage)
        {
          this_obj.handleCroppedMediaUpload(new File([compressedImage], this_obj.state.tempImageName));

          this_obj.setState({ cropVisible: false, tempImageName: '', tempImage: '', croppedImage: '' })

          this_obj.setState({ croppedImage: compressedImage });
        },
        error(err) {},
      });
  }

  onCrop = () => {

    const imageElement = this.myRef.current;

    const cropper = imageElement.cropper;

    this.setState({croppedImage: cropper}, () => {});
  };

  setRatio(ratio)
  {
      if(ratio === false)
      {
          this.setState({ ratio: false, lockedRatio: false });
      }
      else
      {
          this.setState({ ratio: ratio, lockedRatio: true });
      }
  }

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = false;

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = false;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  render()
  {
      const columns = [{
        title: 'Afbeelding',
        width: 100,
        dataIndex: 'image',
        render: (text, record) => (
          <a onClick={() => {

              this.onActiveMediaItem(record);

              this.props.onMediaSelection({ image: record.original_image, clone_id: record.id });

              this.setState({visible: false});
            }}>
            <div style={{ width: 40, height: 40, textAlign: 'center', verticalAlign: 'middle', lineHeight: '40px' }}><LazyLoadImage src={record.image} style={{maxWidth: 40, maxHeight: 40}}/></div>
          </a>
        )
      },
      {
        title: 'Naam',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: (text, record) => (
          <span>
            <Link
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
                to={'#'}
                onClick={() => {
                this.onActiveMediaItem(record);

                this.props.onMediaSelection({image: record.original_image, clone_id: record.id});

                this.setState({visible: false});
              }}>
              {text}
            </Link>
          </span>
        )
      },
      {
          title: 'Categorieen',
          dataIndex: '',
          key: 'categories',
          width: 160,
          filterMultiple: true,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 0 }}>

                <div>
                    {this.props.mediacategories && this.props.mediacategories.map((obj) => {
                        return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                            <Checkbox
                                onChange={(e) => {
                                    var my_group_filter = this.state.group_filter;

                                    if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                    else{ this.removeItem(my_group_filter, obj.name); }

                                    this.setState({ group_filter: my_group_filter });

                                    setSelectedKeys(my_group_filter);
                                }}
                                checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                                >
                                {obj.name}
                            </Checkbox>
                        </div>
                    })}
                </div>

                <div style={{padding: 8, width: '100%', clear: 'both'}}>
                    <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                    <div style={{float: 'left', marginLeft: 8}}>filter</div>
                </div>

                <div style={{width: '100%', clear: 'both'}}>
                    <Button
                      type="link"
                      onClick={() => { confirm(); }}
                      style={{marginLeft: -8, marginRight: 8 }}
                      >OK</Button>

                    <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
                </div>

              </div>
          ),
          onFilter: (value, record) => this.groupsFilter(value, record),
          render: (text, record) =>
          record.categories && record.categories.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
          )
      },
      // {
      //   title: 'Aangemaakt',
      //   dataIndex: 'created_at',
      //   sorter: (a, b) => { return moment(a.created_at).unix() - moment(b.created_at).unix()},
      //   render: (index, record) => (moment(record.created_at).format('DD/MM/YYYY - HH:mm uur'))
      // }
    ];

    return (
      <div>
        <Modal
          title="Media"
          width={'80%'}
          style={{ minWidth: 980 }}
          okText="Selecteer"
          visible={this.props.visible}
          // okButtonProps={{ crop_ready: this.state.crop_ready }}
          onOk={() => {
            this.props.onMediaSelection({ image: this.state.selectedMediaImage, clone_id: this.state.selectedMediaImageId });
            this.setState({visible: false})
          }}
          onCancel={this.props.cancel}
          maskClosable={false}
        >
          <Tabs
            activeKey={this.state.activeTab}
            onChange={(activeTab) => {

                this.setState({ activeTab: activeTab }, () => {

                    if(this.state.activeTab == 2 || this.state.activeTab == '2')
                    {
                        this.showMediaLibrary(this.props.projectId);
                    }
                    else if(this.state.activeTab == 3 || this.state.activeTab == '3')
                    {
                        this.showMediaLibrary();
                    }
                });
            }}
            type="card"
            >

            <Tabs.TabPane tab="Uploaden" key="1">
              <Upload.Dragger
                accept="image/jpeg,image/jpg,image/png"
                customRequest={(file) => {

                  if(file.file.size < this.state.maxFileSize)
                  {
                      this.setState({tempImage: URL.createObjectURL(file.file), tempImageName: file.file.name, cropVisible: true, visible: false});
                  }
                  else
                  {
                      showError('Er is een te groot bestand geselecteerd.');
                  }
                }}
                multiple={false}
                showUploadList={false}
                >
                { !this.state.uploading ?
                    <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Klik of sleep een afbeelding hierheen om te uploaden.</p>
                    <p className="ant-upload-hint">
                      Maximale bestandsgrootte van upload: 8 MB.
                    </p>
                </div>
                :
                <div>
                    <LoadingOutlined style={{ fontSize: 128 }} />
                </div> }
              </Upload.Dragger>
            </Tabs.TabPane>

            { this.state.hideBrowser != true && this.state.hideBrowser != 'project' ?
            <Tabs.TabPane tab="Project media" key="2">

              {/*<Input allowClear placeholder={'Zoeken in media'} onChange={(text) => this.searchMedia(text.target.value)} />
              <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={this.state.medialibrary}
              loading={this.props.isFetching}
              rowKey={'id'}
              pagination={{ defaultPageSize: 12, size:"small", showSizeChanger: true, pageSizeOptions: ['12', '24','48', '72' ]}}
              renderItem={item => (
                <List.Item>
                  <Card className={this.state.selectedMediaItem == item.id ? 'activemedia' : '' }>
                    <a onClick={() => this.onActiveMediaItem(item)}>
                      <img style={{ width: '100%', height: 100, objectFit: 'contain'}} src={item.image} />
                    </a>
                  </Card>
                </List.Item>
              )}
              />*/}

                <div style={{padding: 0, width: '100%'}}>

                  <Row className="mbm">

                    <Col span={24}>
                      <Input.Search
                        allowClear
                        placeholder="Zoeken..."
                        onSearch={(value) => { this.props.searchMediaLibraryAction(value, this.props.projectId).then((data) => { this.setState({ project_medialibrary: this.props.project_medialibrary }); }); }}
                        style={{ width: 200, float: 'right' } }
                        />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <CustomTable
                        size="middle"
                        rowKey="id"
                        columns={columns}
                        loading={this.state.loading}
                        dataSource={this.state.project_medialibrary}
                        pagination={{
                            defaultPageSize: 8,
                            showSizeChanger: false
                        }}
                        />
                    </Col>
                  </Row>

                </div>

            </Tabs.TabPane> : null }

            { this.props.CustomerCan('media') && this.state.hideBrowser != true && this.state.hideBrowser != 'customer' ?
            <Tabs.TabPane tab="Mediatheek" key="3">

                <div style={{padding: 0, width: '100%'}}>

                  <Row className="mbm">

                    <Col span={24}>
                      <Input.Search
                        allowClear
                        placeholder="Zoeken..."
                        onSearch={(value) => { this.props.searchMediaLibraryAction(value/*, this.props.projectId*/).then((data) => { this.setState({ customer_medialibrary: this.props.customer_medialibrary }); }); }}
                        style={{ width: 200, float: 'right' } }
                        />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <CustomTable
                        size="middle"
                        rowKey="id"
                        columns={columns}
                        loading={this.state.loading}
                        dataSource={this.state.customer_medialibrary}
                        pagination={{
                            defaultPageSize: 8,
                            showSizeChanger: false
                        }}
                        />
                    </Col>
                  </Row>

                </div>


            </Tabs.TabPane> : null }

          </Tabs>

        </Modal>

        <Modal
          title="Uitsnijden"
          width={800}
          okText="Opslaan"
          visible={this.state.cropVisible}
          onOk={() => {
            this.getCroppedImage()
          }}
          onCancel={() => this.setState({cropVisible: false})}
          maskClosable={false}
          >
          <div>
            <div style={{marginBottom: '10px'}}>
              <p style={{marginBottom: 0}}>Bestandsnaam:</p>
              <Input value={this.state.tempImageName} placeholder={'Bestandsnaam'} onChange={(text) => this.setState({tempImageName: text.target.value})} />
            </div>
            <div style={{position: 'relative', width: '100%'}}>

              { this.state.ratio === false ?
              <Cropper
                src={this.state.tempImage}
                style={{ height: 400, width: "100%" }}
                viewMode={0}
                autoCropArea={1}
                guides={true}
                crop={this.onCrop}
                dragMode='move'
                ref={this.myRef}
              /> : null }

              { this.state.ratio === (4 / 3) ?
              <Cropper
                src={this.state.tempImage}
                style={{ height: 400, width: "100%" }}
                viewMode={0}
                autoCropArea={1}
                aspectRatio={4 / 3}
                guides={true}
                crop={this.onCrop}
                dragMode='move'
                ref={this.myRef}
                /> : null }

              { this.state.ratio === (1 / 1) ?
              <Cropper
                src={this.state.tempImage}
                style={{ height: 400, width: "100%" }}
                viewMode={0}
                autoCropArea={1}
                aspectRatio={1 / 1}
                guides={true}
                crop={this.onCrop}
                dragMode='move'
                ref={this.myRef}
                /> : null }

              { this.state.ratio === (16 / 9) ?
              <Cropper
                src={this.state.tempImage}
                style={{ height: 400, width: "100%" }}
                viewMode={0}
                autoCropArea={1}
                aspectRatio={16 / 9}
                guides={true}
                crop={this.onCrop}
                dragMode='move'
                ref={this.myRef}
                /> : null }

              { !this.state.fixedRatio ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                <Button style={{marginRight: 5}} onClick={() => { this.setRatio(false); }}>Vrije vorm</Button>
                <Button style={{marginRight: 5}} onClick={() => { this.setRatio(4 / 3); }}>4 : 3</Button>
                <Button style={{marginRight: 5}} onClick={() => { this.setRatio(1 / 1); }}>Vierkant</Button>
                <Button style={{marginRight: 5}} onClick={() => { this.setRatio(16 / 9); }}>16 : 9</Button>
                <Button style={{marginRight: 5}} type="default" icon={<MinusOutlined />} shape="circle" onClick={() => {
                  const imageElement = this.myRef.current;
                  const cropper = imageElement.cropper;
                  cropper.zoom(-0.1);
                }}></Button>
                <Button style={{marginleft: 5}} type="default" shape="circle" icon={<PlusOutlined />} onClick={() => {
                  const imageElement = this.myRef.current;
                  const cropper = imageElement.cropper;
                  cropper.zoom(0.1);
                }}></Button>
              </div>
              :
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                <Button style={{marginRight: 5}} type="default" icon={<MinusOutlined />} shape="circle" onClick={() => {
                  const imageElement = this.myRef.current;
                  const cropper = imageElement.cropper;
                  cropper.zoom(-0.1);
                }}></Button>
                <Button style={{marginleft: 5}} type="default" shape="circle" icon={<PlusOutlined />} onClick={() => {
                  const imageElement = this.myRef.current;
                  const cropper = imageElement.cropper;
                  cropper.zoom(0.1);
                }}></Button>
              </div>
              }
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.medialibrary.isFetching,
      isError : state.medialibrary.isError,
      project_medialibrary : state.medialibrary.medialibrary,
      customer_medialibrary : state.medialibrary.medialibrary,
      mediacategories : state.medialibrary.categories
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    HaveRole : bindActionCreators(HaveRole, dispatch),
    CustomerCan : bindActionCreators(CustomerCan, dispatch),
    getMediaLibraryAction : bindActionCreators(getMediaLibraryAction, dispatch),
    addMediaFileAction : bindActionCreators(addMediaFileAction, dispatch),
    addMediaProjectFileAction : bindActionCreators(addMediaProjectFileAction, dispatch),
    getMediaCategoriesAction : bindActionCreators(getMediaCategoriesAction, dispatch),
    searchMediaLibraryAction : bindActionCreators(searchMediaLibraryAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFromMediaModal);
