import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTransactionsAction, exportTransactionsAction, updateTransactionStatusesAction, searchTransactionsAction } from '../../actions/transactionActions';
import { getNrOfNewQueuedItems } from '../../actions/queueActions';
import { HaveRole, HaveAbility, CustomerCan } from '../../utils/RolesAndAbilities';
import CustomTable from '../../components/CustomTable/CustomTable';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Drawer,
  Select,
  message,
  Divider,
  DatePicker,
} from 'antd';
import { showMultiDelete, showSuccess, showExtraConfirm } from '../../utils/Notifications';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PageHeader from '../../components/PageHeader/PageHeader';
import { getState } from 'redux';
import AdministrationSideMenu from './AdministrationSideMenu';
import { API_URL } from '../../constants/settings';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const SubMenu = Menu.SubMenu;

const { RangePicker } = DatePicker;

class Transactions extends Component {

  state = {
    selectedRowKeys: [],
    expandedRowKeys: [],
    visible: false,
    drawerVisible: false,
    projects: [],
    loading: true,
    customer_id: false,
    selectedDate: [dayjs(moment().startOf('month')), dayjs(moment().endOf('month'))],
    search: '',
    toggleAll: false,
  };

  componentDidMount() {

      this.loadData(true);
  }

  componentDidUpdate(prevProps, prevState) {

      if(!this.state.loading && this.props.selectedCustomer && prevProps.selectedCustomer && this.props.selectedCustomer.id != prevProps.selectedCustomer.id)
      {
          this.loadData(true);
      }
  }

  loadData(force = false)
  {
      if(!this.state.loading || force)
      {
          this.setState({ loading: true }, () => {

              this.props.getTransactionsAction(this.state.selectedDate[0].format('YYYY-MM-DD'), this.state.selectedDate[1].format('YYYY-MM-DD')).then(() => {

                  this.setState({ loading: false });
              });
          });
      }
  }

  onSelectChange = (selectedRowKeys) => {

      this.setState({ selectedRowKeys });
  }

  getFilters(name)
  {
      var filters = [];

      if(this.props.filters && this.props.filters[name] && this.props.filters[name].map)
      {
          this.props.filters[name].map((filter) => {
             filters.push({
                 text: filter,
                 value: filter
             });
          });
      }

      return filters;
  }

  getTransactions = (from_date, untill_date) => {

    // this.setState({selectedDate: month});

    this.setState({ billing: [] });

    // this.props.getTransactionsAction(from_date.format('YYYY-MM-DD'), untill_date.format('YYYY-MM-DD')).then(() => {
    this.props.searchTransactionsAction(this.state.search, this.state.selectedDate[0].format('YYYY-MM-DD'), this.state.selectedDate[1].format('YYYY-MM-DD')).then(() => {

        this.setState({ billing: this.props.billing });
    });
  }

  changeTransActionStatus(status)
  {
      this.props.updateTransactionStatusesAction(status, this.state.selectedRowKeys).then(() => {

          this.setState({ selectedRowKeys: [] });

          this.loadData(true);
      });
  }

  requestExport(ids, type)
  {
      this.props.exportTransactionsAction(ids, type).then(() => {

          showSuccess('Export succesvol aangevraagd');

          this.props.getNrOfNewQueuedItems(); // refresh topnav counter

          this.setState({ selectedRowKeys: [] });
      });
  }

  viewChoiceForm(record)
  {
      window.open(API_URL + '/transactions/choice/form/address/' + record.id  + '/html?token=' + localStorage.getItem('authToken'));
  }

  exportChoiceForm(record)
  {
      // alert('Todo: exportChoiceForm');
      this.requestExport([record.id], 'pdf');
  }

  toggleAll()
  {
      var keys = [];

      if(this.state.toggleAll)
      {   // close all
          this.setState({
              toggleAll: false,
              expandedRowKeys: keys
          });
      }
      else
      {   // open all
          this.props.transactions.map((record) => {

              if((record.children && record.children.length))
              {
                  keys.push(record.id);
              }
          });

          this.setState({
              toggleAll: true,
              expandedRowKeys: keys
          });
      }
  }

  searchChoiceTransactions(value)
  {
      this.setState({ search: value }, () => {

          this.setState({ billing: this.props.billing });

          this.props.searchTransactionsAction(value, this.state.selectedDate[0].format('YYYY-MM-DD'), this.state.selectedDate[1].format('YYYY-MM-DD')).then(() => {

              this.setState({ billing: this.props.billing });
          });
      });
  }

  render() {

    const columns = [
    {
        width: 24
    },
    {
      title: 'Projectnummer',
      dataIndex: 'project_number',
      sorter: (a, b) => { return a.project_number.localeCompare(b.project_number)},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text}</span>
      ),
      filters: this.getFilters('project_numbers'),
      onFilter: (value, record) => record.project_number === value,
    },
    {
      title: 'Projectnaam',
      dataIndex: 'project_name',
      sorter: (a, b) => { return a.project_name.localeCompare(b.project_name)},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text}</span>
      ),
      filters: this.getFilters('projects'),
      onFilter: (value, record) => record.project_name === value,
    },
    {
      title: 'Keuzeformulier',
      dataIndex: 'choice_form',
      sorter: (a, b) => { return a.choice_form.localeCompare(b.choice_form)},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text}</span>
      ),
      filters: this.getFilters('choice_forms'),
      onFilter: (value, record) => record.choice_form === value,
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      sorter: (a, b) => { return a.address.localeCompare(b.address)},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text}</span>
      ),
      filters: this.getFilters('addresses'),
      onFilter: (value, record) => record.address === value,
    },
    {
      title: 'Betalingskenmerk',
      dataIndex: 'payment_provider_id',
      sorter: (a, b) => { return a.payment_provider_id.localeCompare(b.payment_provider_id)},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text ? record.payment_provider + ': ' + text : ''}</span>
      ),
    },
    {
      title: 'Tijdstip',
      dataIndex: 'created_at',
      sorter: (a, b) => { return a.mollie_id.localeCompare(b.mollie_id)},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>
          <span>{text ? moment(text, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm') : ''}</span>
        </span>
      )
    },
    {
      title: 'Openstaand bedrag',
      dataIndex: 'amount_to_be_paid',
      sorter: (a, b) => { return a.amount_to_be_paid_raw - b.amount_to_be_paid_raw },
      sortDirections: ['descend', 'ascend'],
      align: 'right',
      render: (text, record) => (
        <span>{text}</span>
      )
    },
    {
      title: 'Totaal bedrag',
      dataIndex: 'total_amount',
      sorter: (a, b) => { return a.total_amount_raw - b.total_amount_raw },
      sortDirections: ['descend', 'ascend'],
      align: 'right',
      render: (text, record) => (
        <span>{text}</span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (
        <span>
          {record.children ? '' : text == 'processed' ? 'Verwerkt' : text == 'not_processed' ? 'Niet verwerkt' : ''}
        </span>
      ),
      filters: [
        {
          text: 'Verwerkt',
          value: 'processed',
        },
        {
          text: 'Niet verwerkt',
          value: 'not_processed',
        },
      ],
      // onFilter: (value, record) => record.status === value,
      onFilter: (value, record) => record.status.includes(value),
    },
    {
        title: '',
        render: (text, record) => (
            record.children !== undefined
            ?
            <Dropdown trigger={['click']} overlay={(
                <Menu>
                  <Menu.Item onClick={() => { this.viewChoiceForm(record); }} key="3">Bekijken</Menu.Item>
                  <Menu.Item onClick={() => { this.exportChoiceForm(record); }} key="3">Exporteren (pdf)</Menu.Item>
                </Menu>
              )}>
              <Button type="secondary">
                Acties <DownOutlined />
              </Button>
            </Dropdown>
            :
            null
        )
    }
    ];

    const { selectedRowKeys } = this.state;
    const { expandedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    return (
      <div>

        <PageHeader path={[{'label': 'Administratie', 'link': '/administration'}, {'label': 'Transacties'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <AdministrationSideMenu activeMenu='2' />

          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>

            <Row>
              <Col span={18}>

                  <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                      <Menu>
                        <SubMenu title="Status wijzigen">
                          <Menu.Item onClick={() => { this.changeTransActionStatus('processed'); }} key="1">Verwerkt</Menu.Item>
                          <Menu.Item onClick={() => { this.changeTransActionStatus(''); }} key="2">Niet verwerkt</Menu.Item>
                        </SubMenu>
                        <Menu.Item onClick={() => { this.requestExport(this.state.selectedRowKeys, 'xls'); }} key="3">Exporteren (xls)</Menu.Item>
                        <Menu.Item onClick={() => { this.requestExport(this.state.selectedRowKeys, 'pdf'); }} key="3">Exporteren (pdf)</Menu.Item>
                      </Menu>
                    )}>
                    <Button type="secondary">
                      Acties <DownOutlined />
                    </Button>
                  </Dropdown>

                  <RangePicker
                    defaultValue={this.state.selectedDate}
                    onChange={(date, dateString) => {

                        this.setState({ selectedDate: date }, () => {

                            if(dateString[0] && dateString[1])
                            {
                                this.getTransactions(date[0], date[1]);
                            }
                        });
                    }}
                    style={{marginLeft: 8, marginBottom: 30}}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                    />

                  <Button style={{ marginLeft: 12 }} onClick={() => { this.toggleAll(); }}>Alles { !this.state.toggleAll ? 'open' : 'dicht' }klappen</Button>

              </Col>
              <Col span={6}>
                <Input.Search
                  allowClear
                  placeholder="Zoeken..."
                  onSearch={(value) => { this.searchChoiceTransactions(value); }}
                  style={{ width: 200, float: 'right' }}
                  />
              </Col>
            </Row>

            <Row>
              <Col span={24}>

                <CustomTable
                  size="middle"
                  //rowKey="id"
                  rowKey={(record) => {
                      if(record.choice_form_address_id)
                      {
                          return 't-'+record.id;
                      }
                      else
                      {
                          return record.id;
                      }
                  }}
                  rowSelection={rowSelection}
                  expandedRowKeys={this.state.expandedRowKeys}
                  onExpandedRowsChange={(id) => {

                      this.setState({expandedRowKeys: id});
                  }}
                  columns={columns}
                  loading={this.props.isFetching}
                  dataSource={this.props.transactions}
                  />

              </Col>
            </Row>

          </Form>
        </div>
      </div>
    );
}
}


const TransactionsForm = Form.create({ name: 'projects' })(Transactions);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.transaction.isFetching,
    transactions : state.transaction.transactions,
    filters : state.transaction.filters
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionsAction : bindActionCreators(getTransactionsAction, dispatch),
    exportTransactionsAction : bindActionCreators(exportTransactionsAction, dispatch),
    updateTransactionStatusesAction : bindActionCreators(updateTransactionStatusesAction, dispatch),
    searchTransactionsAction : bindActionCreators(searchTransactionsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    CustomerCan : bindActionCreators(CustomerCan, dispatch),
    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsForm);
