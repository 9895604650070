import React from 'react'
import { Steps } from 'antd';

class FormSteps extends React.Component {

  onChange = current => {
    if(current == 0) {
        this.props.history.push(`/projects/${this.props.match.params.id}/form/${this.props.match.params.form_id}`);
    }
    if(current == 1) {
        this.props.history.push(`/projects/${this.props.match.params.id}/form/${this.props.match.params.form_id}/compose`);
    }
    if(current == 2) {
        this.props.history.push(`/projects/${this.props.match.params.id}/form/${this.props.match.params.form_id}/addresses`);
    }
    if(current == 3) {
        this.props.history.push(`/projects/${this.props.match.params.id}/form/${this.props.match.params.form_id}/results`);
    }
  }

  render () {
    return(
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Instellingen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Opmaken" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Adressen" />
        {/*<Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Resultaten" />*/}
      </Steps>
    );
  }
}

export default FormSteps;
