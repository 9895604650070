import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid';

import {
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  DatePicker,
  Collapse,
  Select,
  Tooltip,
  Row,
  Col,
} from 'antd';

import SBOptionList from './SBOptionList';

class SBSelectInputForm extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        <Form.Item label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={(
          <span>
            Type selectie
            <Tooltip className="mhs" title='Bepaal via welk type selectie deze vraag in het opname formulier beantwoord mag worden. Via de selecties Radio en Dropdown kan een van de optie antwoorden worden gekozen. Via de selectie Checkbox kunnen er meerdere opties antwoorden worden geselecteerd.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('type', {
            initialValue: (this.props.formData && this.props.formData.type) || 'radio',
          })(
            <Select>
              {/*<Select.Option value="dropdown">Dropdown</Select.Option>*/}
              <Select.Option value="radio">Radio</Select.Option>
              <Select.Option value="check">Checkbox</Select.Option>
           </Select>
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Opties
            <Tooltip className="mhs" title='Definieer hier welke opties er in de selectie gekozen kunnen worden.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('options', {
            initialValue: this.props.formData && this.props.formData.options ? this.props.formData.options : [{'id': uuidv1() ,'label' : 'Optie 1'}, {'id': uuidv1() ,'label' : 'Optie 2'}, {'id': uuidv1() ,'label' : 'Optie 3'}],
          })(
            <SBOptionList />
          )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld is verplicht</Checkbox>
          )}
        </Form.Item>

        {/*<Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('listField', {
            initialValue: this.props.formData && this.props.formData.listField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op de meterkastlijst</Checkbox>
          )}
        </Form.Item>*/}
      </Form>
    );
  }
}

const WrappedSBSelectInputForm = Form.create({ name: 'text_input_form' })(SBSelectInputForm);

class SBSelectInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {post: true, digital: true},
      post: ((this.props.parseData && this.props.parseData.post !== false) || !this.props.parseData) ? true : false,
      digital: ((this.props.parseData && this.props.parseData.digital !== false) || !this.props.parseData) ? true : false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  renderExtraButtons = () => {
    return (
      <div onClick={(event) => event.stopPropagation()} style={{ display: 'flex' }}>
        <Checkbox defaultChecked={this.state.post} onChange={(e) => { e.stopPropagation(); this.setState({post: e.target.checked}, function(){ this.saveField(); }); }}>Post</Checkbox>
        <Checkbox defaultChecked={this.state.digital} onChange={(e) => { e.stopPropagation(); this.setState({digital: e.target.checked}, function(){ this.saveField(); }); }}>Digitaal</Checkbox>
        <Button.Group>
          <Popconfirm
            icon={(<QuestionCircleOutlined />)}
            placement="top"
            onCancel={(event) => event.stopPropagation()}
            size="small"
            okType="danger"
            title={"Element verwijderen"}
            onConfirm={() => this.removeElement()}
            okText="Ja"
            cancelText="Nee"
            disabled={this.props.disabled}
          >
            <DeleteOutlined
              onClick={event => {
                // event.stopPropagation();
              }} />
          </Popconfirm>
          <PlusSquareOutlined
            style={{marginLeft: 5}}
            onClick={(event) => {
              event.stopPropagation();
              this.duplicateElement(this.props.id);
            }} />
        </Button.Group>
      </div>
    );
  };

  saveFieldValues = () => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
            values.post = this.state.post;
            values.digital = this.state.digital;
            this.setState({formData: values, openKeys: []});
            this.props.onSaveData(this.props.id, values);
        }
      })
    }
  }

  saveField = () => {
      var values = this.state.formData;
      values.post = this.state.post;
      values.digital = this.state.digital;

      this.setState({formData: values}); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Selectie</b> - {this.state.formData && this.state.formData.label || 'label'}</div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              <WrappedSBSelectInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />
              <Button onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Foto
        </div>
      );
    }
  }
}

export default SBSelectInput;
