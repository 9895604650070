import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './Sidenav.css';
import { getProjectAction } from '../../actions/projectActions'
import { withRouter } from "react-router";
import { HaveAbility, CustomerCan } from '../../utils/RolesAndAbilities';
import { Link } from "react-router-dom";
import _ from 'underscore'

import {
  AuditOutlined,
  CalendarOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DashboardOutlined,
  ExperimentOutlined,
  FileImageOutlined,
  FileMarkdownOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Tag } from 'antd';

const {
  Sider,
} = Layout;

class Sidenav extends Component {
  state = {
    collapsed: false,
    openMenu: [],
    selectedMenu: [],
  };

  componentDidMount() {
    //console.log(this.props.project.user_role);

    if(this.props.match.url.includes('dashboard')) {
      this.setState({selectedMenu: ['dashboard']})
    }
    if(this.props.match.url.includes('general') || this.props.match.url.includes('personalize') || this.props.match.url.includes('modules') || this.props.match.url.includes('users') || this.props.match.url.includes('user')) {
      this.setState({selectedMenu: ['general']})
    }
    if(this.props.match.url.includes('media')) {
      this.setState({selectedMenu: ['media']})
    }
    if(this.props.match.url.includes('addresses')) {
      this.setState({selectedMenu: ['addresses'], openMenu: ['sub1']})
    }
    if(this.props.match.url.includes('address-field')) {
      this.setState({selectedMenu: ['address-fields'], openMenu: ['sub1']})
    }
    if(this.props.match.url.includes('groups')) {
      this.setState({selectedMenu: ['groups'], openMenu: ['sub1']})
    }
    if(this.props.match.url.includes('correspondence')) {
      this.setState({selectedMenu: ['correspondence']})
    }
    if(this.props.match.url.includes('pushnotification')) {
      this.setState({selectedMenu: ['pushnotification']})
    }
    if(this.props.match.url.includes('intake')) {
      this.setState({selectedMenu: ['intake']})
    }
    if(this.props.match.url.includes('support-base')) {
      this.setState({selectedMenu: ['support-base']})
    }
    if(this.props.match.url.includes('deliver')) {
      this.setState({selectedMenu: ['deliver']})
    }
    if(this.props.match.url.includes('choice')) {
      this.setState({selectedMenu: ['choice']})
    }
    if(this.props.match.url.includes('chat')) {
      this.setState({selectedMenu: ['chat']})
    }
    if(this.props.match.url.includes('information')) {
      this.setState({selectedMenu: ['information']})
    }
    if(this.props.match.url.includes('workorder')) {
      this.setState({selectedMenu: ['workorder']})
    }
    if(this.props.match.url.includes('schedule')) {
      this.setState({selectedMenu: ['schedule']})
    }

    if(this.props.match.url.includes('contacts')) {
      this.setState({selectedMenu: ['contacts']})
    }

    if(this.props.match.url.includes('news')) {
      this.setState({selectedMenu: ['news']})
    }

    if(this.props.match.url.includes('complaints')) {
      this.setState({selectedMenu: ['complaints']})
    }

    if(this.props.match.url.includes('partners')) {
      this.setState({selectedMenu: ['partners']})
    }

    if(this.props.match.url.includes('enquete')) {
      this.setState({selectedMenu: ['enquete']})
    }

    if(this.props.match.url.includes('appointments')) {
      this.setState({selectedMenu: ['appointments']})
    }

    if(this.props.match.url.includes('documents')) {
      this.setState({selectedMenu: ['documents']})
    }

    if(this.props.match.url.includes('fuseboxlist')) {
      this.setState({selectedMenu: ['fuseboxlist']})
    }

    if(this.props.match.url.includes('forms')) {
      this.setState({selectedMenu: ['forms']})
    }

    if(this.props.match.url.includes('forms-new')) {
      this.setState({selectedMenu: ['forms-new']})
    }

    if(this.props.match.url.includes('modules')) {
      this.setState({openMenu: ['sub2']})
    }


    this.props.getProjectAction(this.props.projectid);
  }

  componentDidUpdate(prevProps, prevState) {

      if(!prevState.selectedMenu.includes('correspondence') && this.props.match.url.includes('correspondence'))
      {
          this.setState({selectedMenu: ['correspondence']});
      }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        >
        <Menu selectedKeys={this.state.selectedMenu} openKeys={this.state.openMenu} theme="light" mode="inline" style={{border: 'none'}} onSelect={(e) => this.setState({selectedMenu: e.selectedKeys})} onOpenChange={(e) => this.setState({openMenu: e})}>
          <Menu.Item key={"dashboard"}>
            <Link to={`/projects/${this.props.projectid}/dashboard`}>
              <DashboardOutlined />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>

          {this.props.HaveAbility('project-settings', 'read') ? (
            <Menu.Item key={"general"}><Link to={`/projects/${this.props.projectid}/general`}><SettingOutlined /><span>Instellingen</span></Link></Menu.Item>
          ) : null}

          {this.props.CustomerCan('media') ? (
            <Menu.Item key={"media"}><Link to={`/projects/${this.props.projectid}/media`}><FileImageOutlined /><span>Media</span></Link></Menu.Item>
          ) : null}

          {this.props.HaveAbility('export', 'read') && _.findWhere(this.props.project.modules, {id: 20}) && _.findWhere(this.props.selectedCustomer.modules, {id: 20})  || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 20}) && _.findWhere(this.props.selectedCustomer.modules, {id: 20})) ? (
            <Menu.Item key="exports"><Link to={`/projects/${this.props.projectid}/exports/addresses`}><FileMarkdownOutlined /><span>Exporteren</span></Link></Menu.Item>
          ) : null}

          {this.props.HaveAbility('address', 'read') ? (
            <Menu.SubMenu
              key="sub1"
              title={<span><ContactsOutlined /><span>Adressen</span></span>}
              >
              <Menu.Item key={"addresses"}><Link to={`/projects/${this.props.projectid}/addresses`}><span>Overzicht</span></Link></Menu.Item>
              <Menu.Item key={"groups"}><Link to={`/projects/${this.props.projectid}/groups`}><span>Groepen</span></Link></Menu.Item>
              <Menu.Item key={"address-fields"}><Link to={`/projects/${this.props.projectid}/addresses/address-fields`}><span>Vrije velden</span></Link></Menu.Item>
            </Menu.SubMenu>
          ) : null}

          {this.props.project.user_role != 'guest' ? (
            this.props.HaveAbility('correspondence', 'read') && _.findWhere(this.props.project.modules, {id: 5}) && _.findWhere(this.props.selectedCustomer.modules, {id: 5}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 5}) && _.findWhere(this.props.selectedCustomer.modules, {id: 5})) ? (
              <Menu.Item key="correspondence"><Link to={`/projects/${this.props.projectid}/correspondence`}><SolutionOutlined /><span>Correspondentie</span></Link></Menu.Item>
            ) : null
          ) : null}

          {this.props.project.user_role != 'guest' ? (
            this.props.HaveAbility('correspondence', 'read') && _.findWhere(this.props.project.modules, {id: 5}) && _.findWhere(this.props.selectedCustomer.modules, {id: 5}) && this.props.project.is_public === 1 ? (
              <Menu.Item key="pushnotification"><Link to={`/projects/${this.props.projectid}/pushnotification`}><SolutionOutlined /><span>Notificaties</span></Link></Menu.Item>
            ) : null
          ) : null}

          {this.props.HaveAbility('appointment', 'read') && _.findWhere(this.props.project.modules, {id: 3}) && _.findWhere(this.props.selectedCustomer.modules, {id: 3})  || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 3}) && _.findWhere(this.props.selectedCustomer.modules, {id: 3})) ? (
            <Menu.Item key="appointments"><Link to={`/projects/${this.props.projectid}/modules/appointments`}><CalendarOutlined /><span>Afspraken</span></Link></Menu.Item>
          ) : null}

          {
            this.props.HaveAbility('chat', 'read') ||
            this.props.HaveAbility('information', 'read') ||
            this.props.HaveAbility('work-order', 'read') ||
            this.props.HaveAbility('scheduling', 'read') ||
            this.props.HaveAbility('contact', 'read') ||
            this.props.HaveAbility('news', 'read') ||
            this.props.HaveAbility('complaints', 'read') ||
            this.props.HaveAbility('partner', 'read') ||
            this.props.HaveAbility('survey', 'read') ||
            this.props.HaveAbility('document', 'read') ? (
            <Menu.SubMenu
              key="sub2"
              title={<span><ExperimentOutlined /><span>Communicatie</span></span>}
              >

              {this.props.HaveAbility('chat', 'read') && _.findWhere(this.props.project.modules, {id: 4}) && _.findWhere(this.props.selectedCustomer.modules, {id: 4}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 4}) && _.findWhere(this.props.selectedCustomer.modules, {id: 4})) ? (
                <Menu.Item key="chat"><Link to={`/projects/${this.props.projectid}/modules/chat`}>Berichten</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('information', 'read') && _.findWhere(this.props.project.modules, {id: 12}) && _.findWhere(this.props.selectedCustomer.modules, {id: 12}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 12}) && _.findWhere(this.props.selectedCustomer.modules, {id: 12})) ? (
                <Menu.Item key="information"><Link to={`/projects/${this.props.projectid}/modules/information`}>Informatie</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('work-order', 'read') && _.findWhere(this.props.project.modules, {id: 11}) && _.findWhere(this.props.selectedCustomer.modules, {id: 11}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 11}) && _.findWhere(this.props.selectedCustomer.modules, {id: 11})) ? (
                <Menu.Item key="workorder"><Link to={`/projects/${this.props.projectid}/modules/workorder`}>Werkvolgorde</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('scheduling', 'read') && _.findWhere(this.props.project.modules, {id: 1}) && _.findWhere(this.props.selectedCustomer.modules, {id: 1}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 1}) && _.findWhere(this.props.selectedCustomer.modules, {id: 1})) ? (
                <Menu.Item key="schedule"><Link to={`/projects/${this.props.projectid}/modules/schedule`}>Planning</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('contact', 'read') && _.findWhere(this.props.project.modules, {id: 6}) && _.findWhere(this.props.selectedCustomer.modules, {id: 6}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 6}) && _.findWhere(this.props.selectedCustomer.modules, {id: 6})) ? (
                <Menu.Item key="contacts"><Link to={`/projects/${this.props.projectid}/modules/contacts`}>Contactpersonen</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('news', 'read') && _.findWhere(this.props.project.modules, {id: 7}) && _.findWhere(this.props.selectedCustomer.modules, {id: 7}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 7}) && _.findWhere(this.props.selectedCustomer.modules, {id: 7})) ? (
                <Menu.Item key="news"><Link to={`/projects/${this.props.projectid}/modules/news`}>Nieuws</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('complaints', 'read') && _.findWhere(this.props.project.modules, {id: 18}) && _.findWhere(this.props.selectedCustomer.modules, {id: 18}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 18}) && _.findWhere(this.props.selectedCustomer.modules, {id: 18})) ? (
                <Menu.Item key="complaints"><Link to={`/projects/${this.props.projectid}/modules/complaints`}>{this.props.project.module_18_name ? this.props.project.module_18_name : 'Eigen module'}</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('partner', 'read') && _.findWhere(this.props.project.modules, {id: 2}) && _.findWhere(this.props.selectedCustomer.modules, {id: 2}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 2}) && _.findWhere(this.props.selectedCustomer.modules, {id: 2})) ? (
                <Menu.Item key="partners"><Link to={`/projects/${this.props.projectid}/modules/partners`}>Partners</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('survey', 'read') && _.findWhere(this.props.project.modules, {id: 9}) && _.findWhere(this.props.selectedCustomer.modules, {id: 9}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 9}) && _.findWhere(this.props.selectedCustomer.modules, {id: 9})) ? (
                <Menu.Item key="enquete"><Link to={`/projects/${this.props.projectid}/modules/enquete`}>Enquête</Link></Menu.Item>
              ) : null}

              {this.props.HaveAbility('document', 'read') && _.findWhere(this.props.project.modules, {id: 8}) && _.findWhere(this.props.selectedCustomer.modules, {id: 8}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 8}) && _.findWhere(this.props.selectedCustomer.modules, {id: 8})) ? (
                <Menu.Item key="documents"><Link to={`/projects/${this.props.projectid}/modules/documents`}>Documenten</Link></Menu.Item>
              ) : null}
            </Menu.SubMenu>
          ) : null}


          {/*this.props.HaveAbility('form', 'read') && _.findWhere(this.props.project.modules, {id: 19}) && _.findWhere(this.props.selectedCustomer.modules, {id: 19}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 19}) && _.findWhere(this.props.selectedCustomer.modules, {id: 19})) ? (
            <Menu.Item key="forms"><Link to={`/projects/${this.props.projectid}/modules/forms`}><Icon type="container" />Formulieren</Link></Menu.Item>
          ) : null/**/}


          {this.props.HaveAbility('form', 'read') && _.findWhere(this.props.project.modules, {id: 19}) && _.findWhere(this.props.selectedCustomer.modules, {id: 19}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 19}) && _.findWhere(this.props.selectedCustomer.modules, {id: 19})) ? (
            <Menu.Item key="forms"><Link to={`/projects/${this.props.projectid}/forms`}><ContainerOutlined /><span>Formulieren</span></Link></Menu.Item>
          ) : null}

          { this.props.HaveAbility('support-base', 'read') && _.findWhere(this.props.project.modules, {id: 14}) && _.findWhere(this.props.selectedCustomer.modules, {id: 14}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 14}) && _.findWhere(this.props.selectedCustomer.modules, {id: 14})) ? (
            <Menu.Item key="support-base"><Link to={`/projects/${this.props.projectid}/support-base/settings`}><TeamOutlined /><span>Draagvlak</span></Link></Menu.Item>
            ) : null
          }

          { this.props.HaveAbility('choice', 'read') && _.findWhere(this.props.project.modules, {id: 16}) && _.findWhere(this.props.selectedCustomer.modules, {id: 16}) || (this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 16}) && _.findWhere(this.props.selectedCustomer.modules, {id: 16})) ? (
            <Menu.Item key="choice"><Link to={`/projects/${this.props.projectid}/choice/forms`}><ReconciliationOutlined /><span>Keuze</span></Link></Menu.Item>
            ) : null
          }

          { (this.props.HaveAbility('deliver', 'read') && _.findWhere(this.props.project.modules, {id: 15}) && _.findWhere(this.props.selectedCustomer.modules, {id: 15})) || ( this.props.project.user_role === 'admin' && _.findWhere(this.props.project.modules, {id: 15}) && _.findWhere(this.props.selectedCustomer.modules, {id: 15}))  ? (
              <Menu.Item key="deliver"><Link to={`/projects/${this.props.projectid}/deliver/points`}><AuditOutlined /><span>Opleveren</span></Link></Menu.Item>
            ) : null
          }
        </Menu>

      </Sider>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    project: state.project.project,
    selectedCustomer: state.auth.selectedCustomer,
    user: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    CustomerCan : bindActionCreators(CustomerCan, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidenav));
