import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Badge,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Table,
  Calendar,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess } from '../../../../../utils/Notifications';
import PatchAppointmentForm from '../Form/PatchAppointment';
import ClusterData from '../Component/ClusterData';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import { API_URL } from '../../../../../constants/settings'
import AppointmentSteps from '../Steps/AppointmentSteps';
import _ from 'underscore';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';

class AppointmentAgenda extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clusters: [],
      activeDays:[],
      selectedSlots: [],
    };
  }

  downloadICS = () => {
    console.log('DOWNLOAD: ');
  }

  componentDidMount() {
    this.props.resetAppointmentAction();
    this.props.getAppointmentAction(this.props.match.params.appointmentid);
    // this.props.getAppointmentScheduleAction(this.props.match.params.appointmentid).then(() => {
    // });
  }

  renderDateCell(date) {
    var dateString = date.format('YYYY-MM-DD');
    var appoinments = this.props.scheduled && this.props.scheduled.filter((element) => (element.date == dateString && element.address_id != null));

    if(appoinments && appoinments.length) {
      return (
        <ul className="events">
          {appoinments.map(item => (
            <li key={item.content}>
              {item.time} {item.address_label}
            </li>
          ))}
        </ul>
      );
    }
  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              {/*<Button type="new" onClick={() => this.saveClusters()}>Opslaan en volgende</Button>*/}
              <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
            <AppointmentSteps current={4} {...this.props} />
          </Card>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button type="new" onClick={() => {
                  window.open( API_URL + '/appointment/'+ this.props.match.params.appointmentid +'/download-ics?token=' + localStorage.getItem('authToken'), '_blank')
                }}>Download .ics</Button>
              <Button disabled={!this.props.HaveAbility('correspondence', 'write')} onClick={() => this.props.history.push("/projects/" + this.props.match.params.id + "/correspondence/add/" /*"appointment/" + this.props.match.params.appointmentid + "/" + encodeURIComponent(this.props.appointment.subject)*/)} icon={<PlusOutlined />} type="primary ant-btn-new">
                Maak correspondentie
              </Button>
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Card>
              <Row gutter={24}>
                <Calendar dateCellRender={(date) => this.renderDateCell(date)}/>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    appointment: state.appointment.appointment,
    clusters: state.appointment.clusters,
    work_days: state.appointment.work_days,
    scheduled: state.appointment.scheduled,
    available_times: state.appointment.available_times,
    addresses: state.address.addresses,
    groups: state.group.groups
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentAgenda);
