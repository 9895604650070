import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess, showError } from '../../../utils/Notifications'
import SupportBaseSideMenu from './SupportBaseSideMenu';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { getProjectAction } from '../../../actions/projectActions';
import { getEnvelopesAction } from '../../../actions/envelopesActions';
import { getLetterPapersAction } from '../../../actions/letterPapersActions';
import { saveSupportBaseSettingsAction } from '../../../actions/supportBaseActions';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Title, Text } = Typography;

const Option = Select.Option;

class ProjectSupportBaseSettings extends Component {
  state = {
    disabled: true,
    edit: true,
    s_b_show_score: false,
    s_b_send_notifications: false,
    s_b_send_notifications_to: '',
    s_b_comments: false,
    s_b_letter_paper_id: 0,
    s_b_form_letter_paper_id: 0,
    s_b_form_envelope_id: 0,
    s_b_form_return_envelope_id: 0,
    s_b_print_double_sided: false,
    can_write: false,
    s_b_progress_notification: '',
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      // get project
      this.props.getProjectAction(this.props.match.params.id).then(() => {
          this.setState({
              s_b_show_score: this.props.project.s_b_show_score,
              s_b_send_notifications: this.props.project.s_b_send_notifications,
              s_b_send_notifications_to: this.props.project.s_b_send_notifications_to ? this.props.project.s_b_send_notifications_to.split(',') : this.props.auth.user.email,
              s_b_comments: this.props.project.s_b_comments,
              s_b_letter_paper_id: this.props.project.s_b_letter_paper_id ? this.props.project.s_b_letter_paper_id : 0,
              s_b_form_letter_paper_id: this.props.project.s_b_form_letter_paper_id ? this.props.project.s_b_form_letter_paper_id : 0,
              s_b_form_envelope_id: this.props.project.s_b_form_envelope_id ? this.props.project.s_b_form_envelope_id : 0,
              s_b_form_return_envelope_id: this.props.project.s_b_form_return_envelope_id ? this.props.project.s_b_form_return_envelope_id : 0,
              s_b_print_double_sided: this.props.project.s_b_print_double_sided,
              s_b_use_sms_auth: this.props.project.s_b_use_sms_auth,
              s_b_progress_notification: this.props.project.s_b_progress_notification,
          });

          // get envelopes
          this.props.getEnvelopesAction(this.props.project.customer_id);

          // get letters
          this.props.getLetterPapersAction(this.props.project.customer_id);
      });
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = (e) => { };

  saveShowScore(value) {
      this.saveToggle(value, 's_b_show_score');
  }

  saveSendNotification(value) {
      this.saveToggle(value, 's_b_send_notifications');
  }

  saveAllowComment(value) {
      this.saveToggle(value, 's_b_comments');
  }

  savePrintDoubleSided(value) {
      this.saveToggle(value, 's_b_print_double_sided');
  }

  saveSMSAuth(value) {
      this.saveToggle(value, 's_b_use_sms_auth');
  }

  saveToggle(value, name) {
      this.setState({ [name]: value });
  }

  saveSettings()
  {
      // this.setState({ email_error: false });

      var settings = {
          s_b_show_score: this.state.s_b_show_score,
          s_b_send_notifications: this.state.s_b_send_notifications,
          s_b_send_notifications_to: typeof this.state.s_b_send_notifications_to === 'object' ? this.state.s_b_send_notifications_to.join(',') : this.state.s_b_send_notifications_to,
          s_b_comments: this.state.s_b_comments,
          s_b_letter_paper_id: this.state.s_b_letter_paper_id,
          s_b_form_letter_paper_id: this.state.s_b_form_letter_paper_id,
          s_b_form_envelope_id: this.state.s_b_form_envelope_id,
          s_b_form_return_envelope_id: this.state.s_b_form_return_envelope_id,
          s_b_print_double_sided: this.state.s_b_print_double_sided,
          s_b_use_sms_auth: this.state.s_b_use_sms_auth,
          s_b_progress_notification: this.state.s_b_progress_notification,
      };

      this.props.form.validateFields((error, values) => {

          var email_error = false;

          if(settings.s_b_send_notifications_to && !this.validateEmail(settings.s_b_send_notifications_to))
          {
              this.setState({ email_error: true });
              email_error = true;
          }
          else
          {
              this.setState({ email_error: false });
          }

          if(!error && !email_error)
          {
              this.props.saveSupportBaseSettingsAction(this.props.match.params.id, settings).then(() => {
                  showSuccess('Instellingen opgeslagen');
              });
          }
          else
          {
              showError('Instellingen niet opgeslagen');
          }
      });
  }

  validateEmail(string)
  {
      var valid = true;

      if(!string){ return false; }

      var mails = string;

      if(typeof string == 'string')
      {
          mails = string.split(',');
      }

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={24}>
              <div>
                <Button disabled={!this.state.can_write} type="new" onClick={() => { this.saveSettings(); }}>Opslaan</Button>
              </div>
            </Col>
          </Row>

          <Divider></Divider>

          <Form layout={'vertical'}>
              <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label={(
                      <span>
                        Tussenstand weergeven voor bewoners
                        <Tooltip className="mhs" title='Bewoners zien een metertje met de actuele stand (% akkoord)'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={this.state.s_b_show_score}
                          onChange={(value) => this.saveShowScore(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={(
                      <span>
                        Melding bij ingevuld formulier
                        <Tooltip className="mhs" title='Er wordt een melding per mail verstuurd (inclusief het formulier in de bijlage) naar het mailadres in het volgende veld'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={this.state.s_b_send_notifications}
                          onChange={(value) => this.saveSendNotification(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={(
                          <span>
                              Mailadres(sen) t.b.v. meldingen
                              <Tooltip className="mhs" title='Vul hier een of meerdere e-mailadressen in van degenen die een melding mogen ontvangen over activiteiten in het project. Activiteiten: nieuw bericht, enquete ingevuld, afspraak gemaakt of gewijzigd. Meerdere e-mailadressen scheiden met een komma (, ).'>
                                  <InfoCircleTwoTone />
                              </Tooltip>
                          </span>
                      )} style={{display: (this.state.s_b_send_notifications ? 'block' : 'none')}}>
                      {/*<Input
                      value={this.state.s_b_send_notifications_to}
                      onChange={(event) => { this.setState({ s_b_send_notifications_to: event.target.value }); }}
                      />*/}
                      <Select
                        mode="tags"
                        open={false}
                        tokenSeparators={[',', '\n']}
                        /*style={{ width: '100%' }}*/
                        // defaultValue={this.state.emailTo}
                        value={this.state.s_b_send_notifications_to}
                        onChange={(value) => { this.setState({ s_b_send_notifications_to: value }, () => { /*if(!this.validateEmail(this.state.emailTo)){}*/ }); }}
                        // onBlur={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                        // onDeselect={() => { if(!this.validateEmail(this.state.emailTo)){} }}
                        style={this.state.email_error ? { border: '1px solid #ff0000', borderRadius: 5 } : null}
                        ></Select>
                        { this.state.email_error ? <div class="ant-form-explain" style={{ color: 'red' }}>Geef een geldig e-mailadres op</div> : null }
                    </Form.Item>
                  </Col>
              </Row>

              <Row gutter={24} style={{display: this.state.s_b_show_score ? 'flex' : 'none'}}>
                <Col span={24}>
                  <Form.Item className={'mbn'} style={{ paddingBottom: 24 }} required={this.state.s_b_show_score ? true : false} label={(
                        <span>
                          Tussenstand melding
                          <Tooltip className="mhs" title='Met % kan aangegeven worden waar het draagvlak percentage in de tekst moet komen te staan.'>
                            <InfoCircleTwoTone />
                          </Tooltip>
                        </span>
                  )}>
                    { getFieldDecorator('s_b_progress_notification', {
                      initialValue: this.state.s_b_progress_notification ? this.state.s_b_progress_notification : '% is al akkoord.',
                      rules: [{ required: this.state.s_b_show_score, message: 'Geef een tekst op' }],
                    })(
                      <Input.TextArea
                          rows={1}
                          showCount
                          maxLength={100}
                          onChange={(event) => { this.setState({ s_b_progress_notification: event.target.value }); }}
                          />
                    ) }
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label="SMS authenticatie inschakelen bij digitaal ondertekenen">
                      <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={this.state.s_b_use_sms_auth}
                          onChange={(value) => this.saveSMSAuth(value)}
                      />
                    </Form.Item>
                  </Col>
              </Row>

              <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label={(
                      <span>
                        Vragen / opmerkingen bij laatste stap
                        <Tooltip className="mhs" title='Na het verzenden van het formulier heeft de bewoner de mogelijkheid om een vraag of opmerking toe te voegen. Dit wordt per mail naar bovenstaand mailadres verzonden'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={this.state.s_b_comments}
                          onChange={(value) => this.saveAllowComment(value)}
                      />
                    </Form.Item>
                  </Col>
              </Row>

              <Row gutter={24}>
                  <Col span={8}>
                      <Form.Item label="Briefpapier brief">
                        <Select
                            name="s_b_letter_paper_id"
                            value={String(this.state.s_b_letter_paper_id)}
                            onChange={(value) => { this.setState({ s_b_letter_paper_id: value }); }}
                            >
                            <Option key={0}>Blanco briefpapier</Option>
                            {this.props.letterpapers.map((v, k) => (
                              <Option key={String(v.id)}>{v.name}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                  </Col>
                  <Col span={8}>
                      <Form.Item label="Briefpapier formulier">
                        <Select
                            name="s_b_form_letter_paper_id"
                            value={String(this.state.s_b_form_letter_paper_id)}
                            onChange={(value) => { this.setState({ s_b_form_letter_paper_id: value }); }}
                            >
                            <Option key={0}>Blanco briefpapier</Option>
                            {this.props.letterpapers.map((v, k) => (
                              <Option key={String(v.id)}>{v.name}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                  </Col>
              </Row>

              <Row gutter={24}>
                  <Col span={8}>
                      <Form.Item label="Enveloppe">
                        <Select
                            name="s_b_form_envelope_id"
                            value={String(this.state.s_b_form_envelope_id)}
                            onChange={(value) => { this.setState({ s_b_form_envelope_id: value }); }}
                            >
                            <Option key={0}>Blanco enveloppe</Option>
                            {this.props.envelopes.map((v, k) => ( v.type == 'normal' ?
                              <Option key={String(v.id)}>{v.name}</Option> : null
                            ))}
                        </Select>
                      </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={(
                      <span>
                        Retourenveloppe
                        <Tooltip className="mhs" title='Op de retourenvelop staat een antwoordnummer van Cobee. Cobee scant ontvangen formulieren in en voegt deze aan het betreffende adres toe.'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                        <Select
                            name="s_b_form_return_envelope_id"
                            value={String(this.state.s_b_form_return_envelope_id)}
                            onChange={(value) => { this.setState({ s_b_form_return_envelope_id: value }); }}
                            >
                            {/*<Option key={0}>Blanco enveloppe</Option>*/}
                            {this.props.envelopes.map((v, k) => ( v.type == 'return' ?
                              <Option key={String(v.id)}>{v.name}</Option> : null
                            ))}
                        </Select>
                    </Form.Item>
                  </Col>
              </Row>

              <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label={(
                      <span>
                        Documenten per post dubbelzijdig afdrukken
                        <Tooltip className="mhs" title='Laat je de correspondentie dubbelzijdig afdrukken? Het formulier begint dan op een nieuw A4.'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={this.state.s_b_print_double_sided}
                          onChange={(value) => this.savePrintDoubleSided(value)}
                          />
                    </Form.Item>
                  </Col>
              </Row>
          </Form>

        </div>
      </div>
    );
  }
}

const ProjectSupportBaseSettingsForm = Form.create({ name: 'projects_support_base_forms' })(ProjectSupportBaseSettings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    project : state.project.project,
    envelopes : state.envelope.envelopes,
    letterpapers: state.letterpaper.letterpapers,
    auth: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    getEnvelopesAction : bindActionCreators(getEnvelopesAction, dispatch),
    getLetterPapersAction : bindActionCreators(getLetterPapersAction, dispatch),
    saveSupportBaseSettingsAction : bindActionCreators(saveSupportBaseSettingsAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSupportBaseSettingsForm);
