import React, { Component } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../../utils/Notifications';
import { getInformationItemAction, resetInformationAction } from '../../../../../actions/informationActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import InformationSteps from '../Steps/InformationSteps';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import InformationSidemenu from '../InformationSideMenu';

// const selectaddresses = React.useRef();

class InformationAddresses extends Component {

  state = {
    selectedAddressIds: [],

    // filteredInfo: null,
    loading: false
  }

  // selectaddresses = React.createRef();

  constructor(props) {

    super(props);

    // this.myref = React.createRef();

    // this.selectaddresses = React.useRef();
  }

  componentDidMount() {

    // this.selectaddresses = React.createRef();

    this.props.resetInformationAction();

    if(this.props.match.params.informationitemid) {

      this.setState({ loading: true });

      this.props.getInformationItemAction(this.props.match.params.informationitemid).then(() => {

          this.setState({ loading: false });
      });
    }
  }

  patchInformationAddresses() {

    // console.log('ref', this.selectaddresses);

    this.myref.share().then(() => {

    // this.selectaddresses.share();

      showSuccess();

      this.props.getInformationItemAction(this.props.match.params.informationitemid).then(() => {

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/information`);

      });
    });
  }

  // handleTableChange = (pagination, filters, sorter) => {
  //   this.setState({
  //     filteredInfo: filters,
  //       sortedInfo: sorter,
  //   });
  // };
  //
  // groupsFilter(value, record)
  // {
  //     var all_groups_found = true;
  //
  //     if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
  //     {
  //         this.state.filteredInfo.groups.map((group_name) => {
  //             var group_found = false;
  //
  //             record.groups.map((record_group) => {
  //                 if(record_group.name == group_name)
  //                 {
  //                     group_found = true;
  //                 }
  //             });
  //
  //             if(!group_found)
  //             {
  //                 all_groups_found = false;
  //             }
  //         });
  //     }
  //
  //     return all_groups_found;
  // }

  render() {

    // let { filteredInfo } = this.state;
    // filteredInfo = filteredInfo || {};

    // const selectaddresses = React.useRef();

    if(this.state.loading){ return null; }

    return (
      <div>
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <InformationSidemenu id={this.props.match.params.id} activeMenu={["1"]} {...this.props}/>
            <div className="chatScreen" layout={'vertical'} style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('information' ,'write')} type="new" onClick={() => this.patchInformationAddresses()}>Opslaan</Button>
                        <Link to={`/projects/${this.props.match.params.id}/modules/information`}>
                          <Button>Sluiten</Button>
                        </Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
                      <InformationSteps current={1} {...this.props}/>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    {this.props.informationitem.is_public === 0 ? (
                      this.props.informationitem.addresses ?
                        <SelectAddresses
                            {...this.props}
                            // forwardedRef={this.selectaddresses}
                            childRef={ref => this.myref = ref}
                            initialAddresses={this.props.informationitem.addresses}
                            objectId={this.props.match.params.informationitemid}
                            objectType="information_item"
                            onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds})}
                            />
                        :
                        <Result
                            icon={<CloseCircleFilled style={{ color: 'red' }} />}
                            title="Dit project heeft nog geen adressen."
                            />

                    ) : (
                      !this.props.isFetching ? (
                        <Result
                          icon={<CheckCircleFilled style={{ color: '#52c41a' }} />}
                          title="De informatie staat ingesteld op openbaar, dus iedereen kan het zien."
                        />
                      ) : null
                    )}
                  </Col>
                </Row>
              </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.information.isFetching,
    isError : state.information.isError,
    informationitem : state.information.informationitem,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInformationItemAction : bindActionCreators(getInformationItemAction, dispatch),
    resetInformationAction : bindActionCreators(resetInformationAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationAddresses);
