import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import { FolderOutlined, HomeOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import { CustomerHas } from '../../../../utils/RolesAndAbilities';

class CustomerSideMenu extends Component {

    componentDidMount()
    {
        console.log('customer = ', this.props.selectedCustomer);
    }

    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 300}}
                defaultSelectedKeys={this.props.activeMenu}
                >
                <Menu.Item key={"1"}>
                    <Link to={!this.props.addressid ? false : `/projects/${this.props.projectid}/addresses/${this.props.addressid}/general`}>
                        <HomeOutlined />
                        <span>Algemeen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"4"}>
                    <Link to={!this.props.addressid ? false : `/projects/${this.props.projectid}/addresses/${this.props.addressid}/fields`}>
                        <UnorderedListOutlined />
                        <span>Vrije velden</span>
                    </Link>
                </Menu.Item>
                <Menu.Item disabled={!this.props.addressid} key={"2"}>
                    <Link to={`/projects/${this.props.projectid}/addresses/${this.props.addressid}/contacts`}>
                        <TeamOutlined />
                        <span>Contactpersonen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item disabled={!this.props.addressid || !this.props.HaveAbility('dossier', 'read')} key={"3"}>
                    <Link to={`/projects/${this.props.projectid}/addresses/${this.props.addressid}/dossier`}>
                        <FolderOutlined />
                        <span>Dossier</span>
                    </Link>
                </Menu.Item>
                { this.props.CustomerHas('bag') ? <Menu.Item key={"5"}>
                    <Link to={`/projects/${this.props.projectid}/addresses/${this.props.addressid}/bag-data`}>
                        <FolderOutlined />
                        <span>BAG data</span>
                    </Link>
                </Menu.Item> : null }
            </Menu>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      selectedCustomer: state.auth.selectedCustomer,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      CustomerHas : bindActionCreators(CustomerHas, dispatch),
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerSideMenu));