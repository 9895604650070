import React, { Component } from 'react';
// import './Settings.css';


import { BookOutlined, HomeOutlined, NotificationOutlined, SmileOutlined } from '@ant-design/icons';


import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';


import { Row, Col, Menu, Card, Input, Typography, Select, Button } from 'antd';

function handleChange(value) {
    console.log(`selected ${value}`);
}

function handleBlur() {
    console.log('blur');
}

function handleFocus() {
    console.log('focus');
}

const Option = Select.Option;
const { Title } = Typography;

class Settings extends Component {

    state = {
      loading: false,
      iconLoading: false,
    }

    enterLoading = () => {
      this.setState({ loading: true });
    }

    enterIconLoading = () => {
      this.setState({ iconLoading: true });
    }

    render() {
        return (
            <div className="settingsScreen">
                    <Card>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={4}>
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={[1]}
                                    defaultOpenKeys={['sub1']}
                                >
                                    <Menu.Item key="1">
                                        <HomeOutlined />
                                        Algemeen
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <SmileOutlined />
                                        Opdrachtgever
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                    <BookOutlined />
                                        Huisstijl
                                    </Menu.Item>
                                    <Menu.Item key="4">
                                        <NotificationOutlined />
                                        Boodschap
                                    </Menu.Item>
                                </Menu>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={9} xl={9} xxl={10}>
                                <Title style={{ fontSize: '24px'}}>Algemene info</Title>
                                <Form layout="vertical">
                                    <Form.Item
                                    label="Projectnaam"
                                    style={{ marginBottom: '8px' }}
                                    >
                                        <Input placeholder="Projectnaam" />
                                    </Form.Item>
                                    <Form.Item
                                    label="Projectnummer"
                                    style={{ marginBottom: '8px' }}
                                    >
                                        <Input placeholder="Projectnummer" />
                                    </Form.Item>
                                    <Form.Item
                                    label="Gemaakt door bedrijf"
                                    style={{ marginBottom: '8px' }}
                                    >
                                        <Select
                                        showSearch
                                        placeholder="Selecteer bedrijf"
                                        optionFilterProp="children"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Option value="jack">Rendon Onderhoudsgroep</Option>
                                            <Option value="lucy">Lucy</Option>
                                            <Option value="tom">Tom</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                    label="Aangemaakt door"
                                    >
                                        <Select
                                        showSearch
                                        placeholder="Selecteer persoon"
                                        optionFilterProp="children"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Option value="jack">Jack</Option>
                                            <Option value="lucy">Lucy</Option>
                                            <Option value="tom">Tom</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" loading={this.state.loading} onClick={this.enterLoading}>
                                          Opslaan
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
            </div>
        );
    }
}

export default Settings;
