import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, notification, Divider } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storeLibrariesContactsAction, updateLibrariesContactsAction, getLibraryContactsAction, resetContactAction } from '../../../actions/contactsActions';

// import './LibraryDetail.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';

import { showSuccess, showError } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import CustomEditor from '../../../components/CustomEditor/CustomEditor';
import LibrarySideMenu from '../LibrarySideMenu';
import NumberFormat from 'react-number-format';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';

class LibraryContactsDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      tags: [],
    };
  }

  componentDidMount() {
    this.props.resetContactAction();

    if(this.props.match.params.id) {
      this.props.getLibraryContactsAction(this.props.match.params.id);
    }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      if(this.props.match.params.id) {
        this.props.updateLibrariesContactsAction(this.props.match.params.id, values).then(() => {
          this.props.history.push(`/library/contacts`);
        });
      } else {
        this.props.storeLibrariesContactsAction(values).then(() => {
          this.props.history.push(`/library/contacts`);
        });
      }

      showSuccess();
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Contactpersonen', 'link': '/library'}, {'label': this.props.contact ? 'Aanpassen' : 'Toevoegen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <LibrarySideMenu id={this.props.match.params.id} activeMenu='17' />

          <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>

                  <Link to={`/library/contacts`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>

              </Col>
            </Row>
            <Divider></Divider>
            <Row>
              <Col span={24}>
                <Form onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
                  <Row gutter={24} >
                      <Col span={8}>
                          <Form.Item required hasFeedback label="Voornaam">
                                {getFieldDecorator('first_name', {
                                    initialValue: this.props.contact && this.props.contact.first_name,
                                    rules: [{ required: true, message: 'Vul een voornaam in' }],
                                })(
                                    <Input />
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item hasFeedback label="Tussenvoegsel">
                                {getFieldDecorator('affix', {
                                    initialValue: this.props.contact && this.props.contact.affix,
                                    rules: [{ required: false, message: 'Vul een tussenvoegsel in' }],
                                })(
                                    <Input />
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item required hasFeedback label="Achternaam">
                                {getFieldDecorator('last_name', {
                                    initialValue: this.props.contact && this.props.contact.last_name,
                                    rules: [{ required: true, message: 'Vul een achternaam in' }],
                                })(
                                    <Input />
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item hasFeedback label="Telefoon">
                                {getFieldDecorator('phone_number', {
                                    initialValue: this.props.contact && this.props.contact.phone_number ? this.props.contact.phone_number : '',
                                    rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                                })(
                                    <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item hasFeedback label="Mobiel">
                                {getFieldDecorator('mobile_number', {
                                    initialValue: this.props.contact && this.props.contact.mobile_number ? this.props.contact.mobile_number : '',
                                    rules: [{ required: false, message: 'Vul een mobiel telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                                })(
                                    <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
                                )}
                          </Form.Item>
                      </Col>

                      <Col span={12}>
                          <Form.Item hasFeedback label="E-mailadres">
                                {getFieldDecorator('email', {
                                    initialValue: this.props.contact && this.props.contact.email,
                                    rules: [
                                        { required: false, message: 'Vul een e-mailadres in' },
                                        { type: 'email', message: 'Vul een geldig e-mailadres in' }
                                    ],
                                })(
                                    <Input type={'email'} />
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item hasFeedback label="Functie">
                                {getFieldDecorator('function', {
                                    initialValue: this.props.contact && this.props.contact.function,
                                    rules: [{ required: false, message: 'Vul een functie in' }],
                                })(
                                    <Input type={'phone'} />
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={24}>
                          <Form.Item hasFeedback label="Omschrijving">
                                {getFieldDecorator('description', {
                                    initialValue: this.props.contact && this.props.contact.description,
                                    rules: [{ required: false, message: 'Vul een omschrijving in' }],
                                })(
                                    <Input type={'phone'} />
                                )}
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item label="Avatar">
                            {getFieldDecorator('avatar_id', {
                              initialValue: this.props.contact && this.props.contact.avatar_id,
                            })(
                              <AddFromMedia
                                projectId={null}
                                hideProjectBrowser={true}
                                initialMediaId={this.props.contact && this.props.contact.avatar_id}
                                initialMediaUrl={this.props.contact && this.props.contact.avatar_url}
                                onMediaSelection={(item) => this.props.form.setFieldsValue({'avatar_id': item.id})}
                                onMediaRemove={() => this.props.form.setFieldsValue({'avatar_id': ''})}
                              />
                            )}
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item label="Logo">
                            {getFieldDecorator('logo_id', {
                              initialValue: this.props.contact && this.props.contact.logo_id,
                            })(
                              <AddFromMedia
                                projectId={null}
                                hideProjectBrowser={true}
                                initialMediaId={this.props.contact && this.props.contact.logo_id}
                                initialMediaUrl={this.props.contact && this.props.contact.logo_url}
                                onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
                                onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
                              />
                            )}
                          </Form.Item>
                      </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

const LibraryContactsDetailForm = Form.create({ name: 'horizontal_login' })(LibraryContactsDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.contact.isFetching,
    isError : state.contact.isError,
    contact : state.contact.libraryContact
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeLibrariesContactsAction : bindActionCreators(storeLibrariesContactsAction, dispatch),
    getLibraryContactsAction : bindActionCreators(getLibraryContactsAction, dispatch),
    updateLibrariesContactsAction : bindActionCreators(updateLibrariesContactsAction, dispatch),
    resetContactAction : bindActionCreators(resetContactAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryContactsDetailForm);
