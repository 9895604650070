import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getUsersAction, searchUsersAction, archiveUsersAction, deleteUsersAction, addUserAction, updateUserAction, resetUsersAction } from '../../../actions/deliverActions';
import DeliverSideMenu from './DeliverSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Divider, Modal, Dropdown, Menu } from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import CloneDeliveryUser from './CloneDeliveryUser';

class DeliverUsers extends Component
{
    state = {
        disabled: true,
        edit: true,
        selectedRowKeys: [],
        is_archive: false,
        show_user_modal: false,
        can_write: false,
        can_delete: false,
    };

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write'),
            can_delete: this.props.HaveAbility('deliver', 'delete')
        });

        this.props.resetUsersAction();

        this.setState({ is_archive: this.isArchive() }, () => {
            this.loadData();
        })
    }

    componentWillReceiveProps(nextprops)
    {
        if(this.isArchive() != this.state.is_archive)
        {
            this.setState({ is_archive: !this.state.is_archive }, () => {
                this.loadData();
            });
        }
    }

    isArchive()
    {
        var url = window.location.href;

        url = url.split('/');

        return (url[url.length - 1] == 'archive' ? true : false);
    }

    loadData()
    {
        this.props.getUsersAction(this.props.match.params.id, 'contractor'); // .then(() => {});
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });
    }

    toggleArchive(status)
    {
        this.props.resetUsersAction();

        this.setState({ is_archive: status }, function(){

            window.history.pushState({}, null, '/projects/'+this.props.match.params.id+'/deliver/users'+(this.state.is_archive ? '/archive' : ''));

            this.loadData();
        });
    }

    addNewUser()
    {
        this.props.form.resetFields();

        this.setState({
            user_id: false,
            show_user_modal: true,
            errors: {},
        });
    }

    updateUser(id)
    {
        this.props.form.resetFields();

        this.props.users.map((record, index) => {

            if(record.id == id)
            {
                this.props.form.setFieldsValue({
                    email_address: record.email_address,
                    full_name: record.full_name,
                    company_name: record.company_name,
                });
            }
        })

        this.setState({
            user_id: id,
            show_user_modal: true,
            errors: {},
        });
    }

    /*archiveUsers()
    {
        this.props.archiveUsersAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
            showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));

            this.setState({
                selectedRowKeys: []
            });

            this.loadData();
        });
    }*/

    deleteUsers()
    {
        // this.props.deleteUsersAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
        //     showSuccess('Succesvol verwijderd');
        //
        //     this.setState({
        //         selectedRowKeys: []
        //     });
        //
        //     this.loadData();
        // });

        Modal.confirm({
          title: 'Wil je deze items verwijderen?',
          // content: 'Als je op JA klikt, wordt ook het gehele dossier verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {
              this.props.deleteUsersAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
                  showSuccess('Succesvol verwijderd');

                  resolve();

                  this.setState({
                      selectedRowKeys: []
                  });

                  this.loadData();
              });
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
    }

    handleUser = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors)
            {   // ok
                if(this.state.user_id)
                {   // update
                    this.props.updateUserAction(this.state.user_id, values).then(() => {

                        showSuccess('Succesvol opgeslagen');

                        this.loadData();
                    });
                }
                else
                {   // add
                    this.props.addUserAction(this.props.match.params.id, values).then(() => {

                        showSuccess('Succesvol toegevoegd');

                        this.loadData();
                    });
                }

                // hide
                this.setState({ show_user_modal: false });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    render()
    {
        const columns = [{
            title: 'E-mailadres',
            dataIndex: 'email_address',
            render: (text, record) => (
                <span>
                    <Link onClick={(event) => { event.preventDefault(); this.updateUser(record.id); }}>
                        {text}
                    </Link>
                </span>
            )
        },
        {
            title: 'Naam',
            dataIndex: 'full_name',

        },
        {
            title: 'Bedrijf',
            dataIndex: 'company_name',

        }];

        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            getCheckboxProps: record => ({
              disabled: record.nr_of_assigned_points > 0 ? true : false
            }),
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="8"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Row className="mbm">
                        <Col span={12}>
                            {/*<Button disabled={!this.state.can_write} type="new" style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.addNewUser(); }}>+ Toevoegen</Button>*/}

                            <Dropdown disabled={!this.props.HaveAbility('deliver' ,'write')}  trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item key="1">
                                      <Link onClick={() => { this.addNewUser(); }}>
                                          Nieuw
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="2">
                                      <CloneDeliveryUser onClone={(item) => {
                                          this.loadData();
                                      }} {...this.props} plain />
                                  </Menu.Item>
                                </Menu>
                              )}>
                              <Button type="new">
                                  Toevoegen <DownOutlined />
                              </Button>
                          </Dropdown>

                            <Dropdown trigger={['click']} disabled={ this.state.can_delete && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                                <Menu>
                                    <Menu.Item type="secondary" disabled={(this.state.selectedRowKeys.length ? false : true) || !this.props.HaveAbility('deliver' ,'delete')} onClick={() => { this.deleteUsers(); }} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                                </Menu>
                                )}>
                                <Button disabled={!this.state.can_write} type="secondary">
                                  Acties <DownOutlined />
                                </Button>
                            </Dropdown>

                            {/*<Button disabled={!this.state.can_write} type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} onClick={() => { this.deleteUsers(); }}>Verwijderen</Button>*/}
                            {/*<Button type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.archiveUsers(); }}>Archiveren</Button>
                            <Button type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.archiveUsers(); }}>Herstellen</Button>*/}
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchUsersAction(this.props.match.params.id, value, this.state.is_archive, 'contractor'); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                            />
                            {/*<Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.toggleArchive(true); }}>Archief</Button>
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.toggleArchive(false); }}>Overzicht</Button>*/}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ padding: 6, paddingLeft: 0 }}>
                            Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={this.props.users}
                                loading={this.props.isFetching}
                            />
                        </Col>
                    </Row>

                </div>

                <Modal
                    title="Verantwoordelijke"
                    visible={this.state.show_user_modal}
                    onCancel={() => {
                        this.setState({ show_user_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'user',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="user"
                        onSubmit={this.handleUser}
                        layout={'vertical'}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="E-mailadres"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('email_address', {
                                        // rules: [{ required: true, message: true }],
                                        rules: [{ required: true, message: 'Vul een e-mailadres in' },{type: 'email', message: 'Vul een geldig e-mailadres in'}],
                                    })(
                                    <Input type="email" />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Naam"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('full_name', {
                                        // rules: [{ required: true, message: true }],
                                    })(
                                    <Input type="text" />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Bedrijfsnaam"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('company_name', {
                                        // rules: [{ required: true, message: true }],
                                    })(
                                    <Input type="text" />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

            </div>
        );
    }
}

const DeliverUsersForm = Form.create({ name: 'user' })(DeliverUsers);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        users : state.deliver.users,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        searchUsersAction : bindActionCreators(searchUsersAction, dispatch),
        archiveUsersAction : bindActionCreators(archiveUsersAction, dispatch),
        deleteUsersAction : bindActionCreators(deleteUsersAction, dispatch),
        addUserAction : bindActionCreators(addUserAction, dispatch),
        updateUserAction : bindActionCreators(updateUserAction, dispatch),
        resetUsersAction : bindActionCreators(resetUsersAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverUsersForm);
