import React, { Component, cloneElement, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressFieldsAction, removeAddressFieldsAction, sortAddressFieldsAction } from '../../../actions/projectActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Dropdown,
  Menu,
  Drawer,
  Modal,
  message,
  Table
} from 'antd';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { Link } from 'react-router-dom';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

class ProjectAddressFields extends Component {

    state = {
        selectedRowKeys: [],
        address_fields: [],
        loaded: false
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    componentDidMount() {
      this.props.getAddressFieldsAction(this.props.match.params.id).then(() => {
          this.setState({ address_fields: this.props.address_fields, loaded: true });
      });
    }

    showConfirm() {
      showMultiDelete((multidelete) => {
        if(multidelete) {
          this.props.removeAddressFieldsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
            this.props.getAddressFieldsAction(this.props.match.params.id).then(() => {
              this.setState({selectedRowKeys: [], address_fields: this.props.address_fields});
              showSuccess('Succesvol verwijderd');
            });
          });
        }
      });
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        const columns = [
            {
              key: "sort",
              width: 20
            },
            {
              title: 'Naam',
              key: 'name',
              render: (text, record) => (
                <Link to={`/projects/${this.props.match.params.id}/addresses/address-field/${record.id}`}>
                    {record.name}
                </Link>
              )
            }
        ];

        const { selectedRowKeys } = this.state;

        // const [ dataSource, setDataSource ] = useState(''); // this.state;

        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          hideDefaultSelections: true,
        };

        const onDragEnd = ({ active, over }) => {
          if (active.id !== over.id) {

            var address_fields = this.state.address_fields;

            const activeIndex = address_fields.findIndex((i) => i.id === active.id);
            const overIndex = address_fields.findIndex((i) => i.id === over.id);

            this.setState({ address_fields: arrayMove(address_fields, activeIndex, overIndex) }, () => {

              var sort = this.state.address_fields.map((v,k) => v.id);

              this.props.sortAddressFieldsAction(this.props.match.params.id, {'ids' : sort});
            });
          }
        };

        // if(/*!this.state.loaded ||*/ !this.state.address_fields || !this.state.address_fields.length){ return null; }

        return (
          <div>
            <Row className="mbm">
              <Col span={24}>
                <Link to={`/projects/${this.props.match.params.id}/addresses/address-field/add`}>
                  <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" icon={<PlusOutlined />}>Toevoegen</Button>
                </Link>

                <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('address', 'write') ? false : true } overlay={(
                    <Menu>
                      <Menu.Item disabled={!this.props.HaveAbility('address', 'delete')} onClick={() => this.showConfirm()} key="6" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>

              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div gutter={24} type="flex" style={{padding: 0, width: '100%'}}>
                    {this.props.HaveAbility('address', 'write') && this.state.address_fields && this.state.address_fields.length ? (
                    <DndContext onDragEnd={onDragEnd}>
                      <SortableContext
                        items={this.state.address_fields.map((i) => i.id)}
                        strategy={verticalListSortingStrategy}
                      >
                        <CustomTable
                          rowKey='id'
                          size="middle"
                          rowSelection={rowSelection}
                          columns={columns}
                          scroll={{ y: 600 }}
                          dataSource={this.state.address_fields}
                          components={{
                              body: {
                                  row: DraggableRow
                              }
                          }}
                          />
                      </SortableContext>
                    </DndContext>
                    ) : (
                      <CustomTable
                        rowKey='id'
                        size="middle"
                        rowSelection={rowSelection}
                        columns={columns}
                        scroll={{ y: 600 }}
                        dataSource={this.state.address_fields}
                        loading={this.props.isFetching}
                        />
                    )}
                </div>

              </Col>
            </Row>
          </div>
        );
    }
}


const ProjectAddressFieldsForm = Form.create({ name: 'project_address_fields' })(ProjectAddressFields);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.project.isFetching,
        isError : state.project.isError,
        address_fields: state.project.address_fields,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAddressFieldsAction : bindActionCreators(getAddressFieldsAction, dispatch),
        removeAddressFieldsAction : bindActionCreators(removeAddressFieldsAction, dispatch),
        sortAddressFieldsAction : bindActionCreators(sortAddressFieldsAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddressFieldsForm);
