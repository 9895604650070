import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSurveyAction, addCustomerSurveyAction, updateCustomerSurveyAction, resetSurveyAction, previewAction } from '../../../actions/surveysActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import PageHeader from '../../../components/PageHeader/PageHeader';
import LibrarySideMenu from '../LibrarySideMenu';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


class SurveyDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      show_preview_buttons: (this.props.match.params.id ? true : false),
      loading: true,
    };
  }

  componentDidMount() {
    this.props.resetSurveyAction();

    if(this.props.match.params.id) {

      this.props.getSurveyAction(this.props.match.params.id).then(() => {
        if(this.IsJsonString(this.props.survey.form))
        {
          this.setState({formData: JSON.parse(this.props.survey.form)}, () => {

              this.refs.FormBuilder.parse(JSON.parse(this.props.survey.form));

              this.setState({ loading: false });
          });
        }
      });
    }
    else
    {
      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': 'survey', 'label': 'Vragen', elements: []}]);

      this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.form.validateFields((err, values) => {

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      values.form = JSON.stringify(this.state.formData);

      if (this.props.match.params.id) {
        this.props.updateCustomerSurveyAction(this.props.match.params.id, values).then(() => {
          if (redirect) {
            this.props.history.push(`/library/survey`);
          }
          else {
            //showSuccess('Succesvol opgeslagen');
          }

          this.setState({ show_preview_buttons: true });
        });
      }
      else {
        this.props.addCustomerSurveyAction(values).then(() => {
          this.props.history.push('/library/survey/' + this.props.survey.id + '/detail');
        });
      }

      showSuccess();
    });
  }

  preview()
  {
      this.props.previewAction(this.props.match.params.id);
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Enquetes', 'link': '#'}, {'label': 'Vragen'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='10' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={this.state.loading || !this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                      <Link to={`/library/surveys`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col span={12} align={'right'}>
                      <Button onClick={(event) => { this.preview(); }}>Voorbeeld</Button>
                  </Col>
                </Row>

                <Divider></Divider>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>
                      <Col span={16}>
                        <Form.Item required label="Naam">
                          {getFieldDecorator('name', {
                            initialValue: this.props.survey ? this.props.survey.name : '',
                            rules: [{ required: true, message: 'Vul een naam in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>

                      { this.props.selectedCustomer.id == 1 && this.props.HaveRole(['super-admin']) ?
                      <Col span={8}>
                          <Form.Item label="Gebruik voor demo project">
                            {getFieldDecorator('use_for_demo', {
                                valuePropName: 'checked',
                                initialValue: this.props.survey && this.props.survey.use_for_demo ? true : false
                            })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    />
                            )}
                          </Form.Item>
                      </Col> : null }

                    </Row>


                      <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                        <Col span={24}>
                          <FormBuilder
                              onChangeForm={(data) => this.setState({formData: data}, (e) => this.handleSubmit(e, data, false))}
                              ref={'FormBuilder'}
                              formId={this.props.match.params.id}
                              enabledElements={['s_select', 's_title', 's_textinput', 's_textareainput', /*'s_numberinput'*/, 's_smileyinput', /*'timeinput'*/, /*'dateinput'*/, /*'signatureinput',*/ /*'contactinput'*/, 's_photoinput', /*'drawinginput'*/]}
                              sections={1}
                            />
                        </Col>
                      </Row>


                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const SurveyDetailForm = Form.create({ name: 'survey_form' })(SurveyDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.survey.isFetching,
    isError : state.survey.isError,
    survey : state.survey.survey,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
    addCustomerSurveyAction : bindActionCreators(addCustomerSurveyAction, dispatch),
    updateCustomerSurveyAction : bindActionCreators(updateCustomerSurveyAction, dispatch),
    resetSurveyAction : bindActionCreators(resetSurveyAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewAction : bindActionCreators(previewAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailForm);
