import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../SupportBaseSideMenu';
import SupportBaseReminderSteps from './SupportBaseReminderSteps'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseReminderAction, updateSupportBaseReminderAction, addSupportBaseReminderAction, checkSupportBaseReminderLetterAction } from '../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class SupportBaseReminderLetter extends React.Component {

  state = {
      salutation_type: 'personal',
      salutation: 'Aan de bewoner(s) van',
      // reminder_date: null,
      letter_include_attachments: false,
      letter_message: '',
      saved: true,
      can_write: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      if(this.props.match.params.reminder_id != 'add')
      {
        this.props.getSupportBaseReminderAction(this.props.match.params.reminder_id).then(() => {
           this.setState({
               salutation_type : this.props.reminder.salutation_type,
               salutation : this.props.reminder.salutation ? this.props.reminder.salutation : 'Aan de bewoner(s) van',
               // reminder_date : this.props.reminder.reminder_date && this.props.reminder.reminder_date != '0000-00-00 00:00:00' ? moment(this.props.reminder.reminder_date, 'YYYY-MM-DD HH:mm:ss') : null,
               letter_include_attachments : this.props.reminder.letter_include_attachments ? true : false,
               letter_message : this.props.reminder.letter_message,
           });
        });
      }
  }

  allowNextStep = () =>
  {
      var status = true;

      this.props && this.props.form && this.props.form.validateFields((errors, values) => {

          if(errors){ this.setState({ saved: false }); }

          status = (errors || !this.state.saved ? false : true);

          if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
      });

      return status;
  }

  saveAndContinue(do_continue = true, preview = false)
  {
      this.props.form.validateFields((errors, values) => {

          if(!errors)
          {
              // add || update
              /*if(this.props.match.params.reminder_id == 'add')
              {   // add
                  this.props.addSupportBaseReminderAction(this.props.match.params.id).then(() => {

                      this.props.updateSupportBaseReminderAction(this.props.reminder.id, 'letter', {
                          salutation_type : values.salutation_type,
                          salutation : values.salutation,
                          // reminder_date : moment(this.state.reminder_date, 'YYYY-MM-DD HH:mm:ss'),
                          letter_include_attachments : this.state.letter_include_attachments,
                          letter_message: values.letter_message,
                      }).then(() => {

                          showSuccess('Succesvol opgeslagen');

                          if(do_continue)
                          {
                            this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/email/${this.props.reminder.id}`);
                          }

                          if(preview)
                          {
                              this.props.checkSupportBaseReminderLetterAction(this.props.reminder.id);
                          }
                      });

                  });
              }
              else*/
              {   // update
                  this.props.updateSupportBaseReminderAction(this.props.match.params.reminder_id, 'letter', {
                      salutation_type : values.salutation_type,
                      salutation : values.salutation,
                      // reminder_date : moment(this.state.reminder_date, 'YYYY-MM-DD HH:mm:ss'),
                      letter_include_attachments : this.state.letter_include_attachments,
                      letter_message: values.letter_message,
                  }).then(() => {
                      showSuccess('Succesvol opgeslagen');

                      if(do_continue)
                      {
                        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/email/${this.props.match.params.reminder_id}`);
                      }

                      if(preview)
                      {
                          this.props.checkSupportBaseReminderLetterAction(this.props.match.params.reminder_id);
                      }
                  });
              }
          }
      });
  }

  saveToggle(value, name) {
      this.setState({ [name]: value });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="3"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write} type="new" onClick={() => { this.saveAndContinue(); }}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/reminders`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
                <Col align={'right'} span={12}>
                  <Button onClick={() => { this.saveAndContinue(false, true); }}>Voorbeeld</Button>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseReminderSteps validate={true} validator={() => { return this.allowNextStep(); }} current={1} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                {/*<Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Herinnering versturen op">
                          <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            placeholder="Direct"
                            format={'DD/MM/YYYY - HH:mm'}
                            defaultValue={this.state.reminder_date}
                            value={this.state.reminder_date}
                            onChange={(value) => this.setState({'reminder_date': value})}
                            />
                            <Tooltip className="mhs" title=''>
                              <Icon type="info-circle" theme="twoTone" />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>*/}
                <Row gutter={24}>
                    <Col span={16}>
                      <Form.Item required label="Aanhef">
                        { getFieldDecorator('salutation_type', {
                          initialValue: this.state.salutation_type ? this.state.salutation_type : 'anonymous',
                          rules: [{ required: true, message: 'Maak een keuze' }],

                        })(
                          <Select>
                            <Select.Option key={'personal'}>Gepersonaliseerde aanhef</Select.Option>
                            <Select.Option key={'anonymous'}>Aangepaste aanhef</Select.Option>
                          </Select>
                        ) }
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Bijlage(n) als origineel meesturen">
                        <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.letter_include_attachments}
                        onChange={(value) => this.saveToggle(value, 'letter_include_attachments')}
                        />
                      </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Aanhef indien contactpersoon onbekend">
                            {getFieldDecorator('salutation', {
                              initialValue: this.state.salutation,
                              rules: [{ required: true, message: 'Vul een aanhef in' }],
                            })(
                              <Input />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item required label="Tekst brief per post">
                          { getFieldDecorator('letter_message', {
                            initialValue: this.state.letter_message,
                            rules: [{ required: true, message: 'Vul een tekst in' }],
                            valuePropName: 'value',
                            trigger: 'onEditorChange',
                            // onChange: (value) => { this.setState({ letter_message: value }); }
                          })(
                            <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} editorWidth={696} messageChanged={(e) => { this.setState({ letter_message: e }); }} />
                          ) }
                        </Form.Item>
                    </Col>
                </Row>
              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseReminderLetterForm = Form.create({ name: 'support_base_reminder_form' })(SupportBaseReminderLetter);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        reminder : state.support_base.reminder,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseReminderAction : bindActionCreators(getSupportBaseReminderAction, dispatch),
        updateSupportBaseReminderAction : bindActionCreators(updateSupportBaseReminderAction, dispatch),
        addSupportBaseReminderAction : bindActionCreators(addSupportBaseReminderAction, dispatch),
        checkSupportBaseReminderLetterAction : bindActionCreators(checkSupportBaseReminderLetterAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseReminderLetterForm);
