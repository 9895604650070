import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Button,
    Drawer,
    Divider,
    Tag,
    Modal,
    Upload,
    Input,
    Avatar,
    Typography,
    Tooltip,
} from 'antd';
import NumberFormat from 'react-number-format';
import api from '../../../../../utils/api'
import { API_URL } from '../../../../../constants/settings';

class UpdateContact extends Component {

    constructor(props) {
      super(props);

      this.state = {
          avatar: null,
          logo: null
      };
    }

    selectAvatar = (e) =>
    {
        this.setState({
            avatar : e.target.files[0]
        });
    }

    selectLogo = (e) =>
    {
        this.setState({
            logo : e.target.files[0]
        });
    }

    render()
    {

        const { getFieldDecorator } = this.props.form;

        return (
            <Form className={'ant-form--custom'} onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
                <Row gutter={24} >
                    <Col span={8}>
                        <Form.Item required hasFeedback label="Voornaam">
                              {getFieldDecorator('first_name', {
                                  initialValue: this.props.contact.first_name,
                                  rules: [{ required: true, message: 'Vul een voornaam in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item hasFeedback label="Tussenvoegsel">
                              {getFieldDecorator('affix', {
                                  initialValue: this.props.contact.affix,
                                  rules: [{ required: false, message: 'Vul een tussenvoegsel in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item required hasFeedback label="Achternaam">
                              {getFieldDecorator('last_name', {
                                  initialValue: this.props.contact.last_name,
                                  rules: [{ required: true, message: 'Vul een achternaam in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Telefoon">
                              {getFieldDecorator('phonenumber', {
                                  initialValue: this.props.contact.phonenumber,
                                  rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                              })(
                                  <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input" />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Mobiel">
                              {getFieldDecorator('mobile', {
                                  initialValue: this.props.contact.mobile,
                                  rules: [{ required: false, message: 'Vul een mobiel telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                              })(
                                  <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input" />
                              )}
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item hasFeedback label="E-mailadres">
                              {getFieldDecorator('email', {
                                  initialValue: this.props.contact.email,
                                  rules: [
                                      { required: false, message: 'Vul een e-mailadres in' },
                                      { type: 'email', message: 'Vul een geldig e-mailadres in' }
                                  ],
                              })(
                                  <Input type={'email'} />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Functie">
                              {getFieldDecorator('function', {
                                  initialValue: this.props.contact.function,
                                  rules: [{ required: false, message: 'Vul een functie in' }],
                              })(
                                  <Input type={'phone'} />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Avatar">
                             {getFieldDecorator('avatar', {
                                //initialValue: "upload",
                                //rules: [{ required: true, message: 'Vul een afbeelding in.' }],
                             })(
                                <div className="clearfix">
                                  <Input type={'file'} onChange={this.selectAvatar} />
                                  { this.props.contact.avatar ? <img src={this.props.contact.avatar} alt="avatar" style={{ width: '120px' }} /> : '' }
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Logo">
                            {getFieldDecorator('logo', {
                                //initialValue: "upload",
                                //rules: [{ required: true, message: 'Vul een afbeelding in.' }],
                            })(
                                <div className="clearfix">
                                  <Input type={'file'} onChange={this.selectLogo} />
                                  { this.props.contact.logo ? <img src={this.props.contact.logo} alt="logo" style={{ width: '120px' }} /> : '' }
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item hasFeedback label="Functie">
                              {getFieldDecorator('function', {
                                  initialValue: this.props.contact.function,
                                  rules: [{ required: false, message: 'Vul een functie in' }],
                              })(
                                  <Input type={'phone'} />
                              )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default Form.create({ name: 'add_contact_form' })(UpdateContact);
