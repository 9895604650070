import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Typography,
  Tooltip,
  Select,
} from 'antd';
import NumberFormat from 'react-number-format';
import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';

import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';

const ButtonGroup = Button.Group;

const Option = Select.Option;

class PatchPartner extends Component {

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
      <Row gutter={24} >
      <Col span={12}>
      <Form.Item required hasFeedback label="Bedrijfsnaam">
      {getFieldDecorator('name', {
        initialValue: this.props.client && this.props.client.name,
        rules: [{ required: true, message: 'Vul een bedrijfsnaam in' }],
      })(
        <Input />
      )}
      </Form.Item>
      </Col>

      {/*<Col span={12}>
      <Form.Item hasFeedback label="KvK">
      {getFieldDecorator('kvk', {
        initialValue: this.props.client && this.props.client.kvk,
        rules: [{ required: false, message: 'Vul een KvK-nummer in' }],
      })(
        <NumberFormat allowEmptyFormatting={true} format="########" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
      )}
      </Form.Item>
      </Col>*/}

      <Col span={12}>
      <Form.Item hasFeedback label="Adres">
      {getFieldDecorator('address', {
        initialValue: this.props.client && this.props.client.address,
        rules: [{ required: false, message: 'Vul een adres in' }],
      })(
        <Input />
      )}
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item hasFeedback label="Postcode">
      {getFieldDecorator('zipcode', {
        initialValue: this.props.client && this.props.client.zipcode,
        rules: [{ validator: zipcodeValidation }],
      })(
        <Input maxLength={6} />
      )}
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item hasFeedback label="Plaats">
      {getFieldDecorator('city', {
        initialValue: this.props.client && this.props.client.city,
        rules: [{ required: false, message: 'Vul een plaatsnaam in' }],
      })(
        <Input />
      )}
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item hasFeedback label="Telefoon">
      {getFieldDecorator('telephone', {
        initialValue: this.props.client && this.props.client.telephone,
        rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
      })(
        <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
      )}
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item  hasFeedback label="E-mail">
      {getFieldDecorator('email_address', {
        initialValue: this.props.client && this.props.client.email_address,
        rules: [{ required: false, message: 'Vul een e-mailadres in' },{type: 'email', message: 'Vul een geldig e-mailadres in'}],
      })(
        <Input type={'email'} />
      )}
      </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item hasFeedback label="Website">
      {getFieldDecorator('website', {
        initialValue: this.props.client && this.props.client.website,
        rules: [{ validator: urlValidation }],
      })(
        <Input addonBefore="https://" />
      )}
      </Form.Item>
      </Col>

      <Col span={12}>
      <Form.Item label="Logo">
      {getFieldDecorator('logo_id', {
        initialValue: this.props.client && this.props.client.logo_id,
      })(
        <AddFromMedia
          hideProjectBrowser={true}
          initialMediaId={this.props.client.logo_id}
          initialMediaUrl={this.props.client.logo_url}
          onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
          onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
          />
      )}
      </Form.Item>
      </Col>

      </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'patch_client_form' })(PatchPartner);
