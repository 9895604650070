import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getDeliverSettingsAction, updateDeliverSettingsAction } from '../../../actions/deliverActions';
import DeliverSideMenu from './DeliverSideMenu';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    Select,
    Dropdown,
    Menu,
    DatePicker,
    Tag,
    Tooltip,
    Timeline,
    Switch,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import './deliver.css';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Option } = Select;
const { TextArea } = Input;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

class DeliverSettings extends Component
{
    state = {
        can_write: false,

        d_allow_occupant_to_view_points: false
    };

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write')
        });

        this.loadData();
    }

    loadData()
    {
        this.props.getDeliverSettingsAction(this.props.match.params.id).then(() => {

            this.setState({ d_allow_occupant_to_view_points: this.props.deliver_settings.d_allow_occupant_to_view_points ? true : false });
        });
    }

    handleSave(redirect = true)
    {
        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors)
            {   // ok
                this.props.updateDeliverSettingsAction(this.props.match.params.id, values).then(() => {

                    showSuccess('Succesvol opgeslagen');
                });
            }
        });
    }

    render()
    {
        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="2"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Form layout={'vertical'}>

                        <Row className="mbm">
                            <Col span={24}>
                                <div>
                                    <Button disabled={!this.state.can_write} type={'new'} onClick={() => { this.handleSave(); }}>Opslaan</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Mag een bewoner punten zien"
                                    >
                                    { getFieldDecorator('d_allow_occupant_to_view_points', {
                                      initialValue: this.props.deliver_settings.d_allow_occupant_to_view_points ? true : false,
                                      valuePropName: 'checked',
                                      onChange: (checked) => { this.setState({ d_allow_occupant_to_view_points: checked }); }
                                    })(
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        { this.state.d_allow_occupant_to_view_points ? <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Mag een bewoner punten toevoegen"
                                    >
                                    { getFieldDecorator('d_allow_occupant_to_add_points', {
                                      initialValue: this.props.deliver_settings.d_allow_occupant_to_add_points ? true : false,
                                      valuePropName: 'checked',
                                    })(
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row> : null }

                    </Form>

                </div>

            </div>
        );
    }
}

const DeliverSettingsForm = Form.create({ name: 'deliver_settings' })(DeliverSettings);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        deliver_settings : state.deliver.deliver_settings
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDeliverSettingsAction : bindActionCreators(getDeliverSettingsAction, dispatch),
        updateDeliverSettingsAction : bindActionCreators(updateDeliverSettingsAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverSettingsForm);
