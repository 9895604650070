import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../SupportBaseSideMenu';
import SupportBaseReminderSteps from './SupportBaseReminderSteps'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
  Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseReminderAction, updateSupportBaseReminderAction, checkSupportBaseReminderEmailAction } from '../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class SupportBaseReminderEmail extends React.Component {

    state = {
        email_include_attachments: false,
        email_subject: '',
        email_message: '',
        saved: true,
        can_write: false,
    }

    constructor(props) {
      super(props);
    }

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('support-base' ,'write')
        });

      this.props.getSupportBaseReminderAction(this.props.match.params.reminder_id).then(() => {
         this.setState({
             email_include_attachments : this.props.reminder.email_include_attachments ? true : false,
             email_subject : this.props.reminder.email_subject,
             email_message : this.props.reminder.email_message,
         });
      });
    }

    allowNextStep = () =>
    {
        var status = true;

        this.props && this.props.form && this.props.form.validateFields((errors, values) => {

            if(errors){ this.setState({ saved: false }); }

            status = (errors || !this.state.saved ? false : true);

            if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
        });

        return status;
    }

  saveAndContinue(do_continue = true, preview = false)
  {
      this.props.form.validateFields((errors, values) => {

          if(!errors)
          {
              this.props.updateSupportBaseReminderAction(this.props.match.params.reminder_id, 'email', {
                  email_include_attachments : this.state.email_include_attachments,
                  email_subject : values.email_subject,
                  email_message: values.email_message,
              }).then(() => {

                  showSuccess('Succesvol opgeslagen');

                  if(do_continue)
                  {
                      this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/check/${this.props.match.params.reminder_id}`);
                  }

                  if(preview)
                  {
                      this.props.checkSupportBaseReminderEmailAction(this.props.reminder.id);
                  }
              });
          }
      });
  }

  saveToggle(value, name) {
      this.setState({ [name]: value });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="3"/>

        <div style={{padding: 24, width: '100%'}}>

            {/* buttons */}
            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button disabled={!this.state.can_write} type="new" onClick={() => { this.saveAndContinue(); }}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/support-base/reminders`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
              <Col align={'right'} span={12}>
                <Button onClick={() => { this.saveAndContinue(false, true); }}>Voorbeeld</Button>
              </Col>
            </Row>

            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <SupportBaseReminderSteps validate={true} validator={() => { return this.allowNextStep(); }} current={2} {...this.props}/>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="In de e-mail kan de placeholder [formulier ondertekenen knop] gebruikt worden om een knop te plaatsen naar het online formulier." />
              </Col>
            </Row>

            <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
              <Row gutter={24}>
                  <Col span={16}>
                    <Form.Item label="Onderwerp e-mail">
                        {getFieldDecorator('email_subject', {
                          initialValue: this.state.email_subject,
                          rules: [{ required: true, message: 'Vul een onderwerp in' }],
                        })(
                          <Input />
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Bijlage(n) als origineel meesturen">
                      <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={this.state.email_include_attachments}
                      onChange={(value) => this.saveToggle(value, 'email_include_attachments')}
                      />
                    </Form.Item>
                  </Col>
              </Row>
              <Row gutter={24}>
                  <Col span={24}>

                    <div align={'right'} style={{position: 'absolute', right: 12, top: -18, zIndex: 9999}}>
                      <Button style={{display: 'inline-block'}} onClick={() => { this.setState({email_message: this.props.reminder.letter_message}); this.props.form.setFieldsValue({ email_message: this.props.reminder.letter_message }); }}>Tekst brief post overnemen</Button>
                    </div>

                      <Form.Item required label="Tekst e-mail">
                        { getFieldDecorator('email_message', {
                          initialValue: this.state.email_message,
                          rules: [{ required: true, message: 'Vul een tekst in' }],
                          valuePropName: 'value',
                          trigger: 'onEditorChange',
                          // onChange: (value) => { this.setState({ email_message: value }); }
                        })(
                          <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ email_message: e }); }} />
                        ) }
                      </Form.Item>
                  </Col>
              </Row>
            </Form>

        </div>

    </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseReminderEmailForm = Form.create({ name: 'support_base_reminder_form' })(SupportBaseReminderEmail);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        reminder : state.support_base.reminder,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseReminderAction : bindActionCreators(getSupportBaseReminderAction, dispatch),
        updateSupportBaseReminderAction : bindActionCreators(updateSupportBaseReminderAction, dispatch),
        checkSupportBaseReminderEmailAction : bindActionCreators(checkSupportBaseReminderEmailAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseReminderEmailForm);
