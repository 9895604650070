export default (state = {
    message: '',
    warnings: '',
    nr_of_pages: '',
    price_error: false,
    isError: false,
    isFetching: false,
    correspondences: [],
    correspondence: {},
    addresses: [],
    selectedMethod: {},
    price: 0,
    summary: { addresses: { 'mail': [], 'sms': [], 'push': [], 'email': [], 'email_mail': [] } }
  }, action) => {

    switch (action.type) {
    case 'CORRESPONDENCES_ACTION_FETCH':
    return {
        ...state,
        correspondences: [],
        isFetching: true,
        isError: false,
    }
    case 'CORRESPONDENCE_ACTION_FETCH':
    return {
        ...state,
        correspondence: {},
        isFetching: true,
        isError: false,
    }
    case 'CORRESPONDENCE_ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: true,
        isError: false,
    }
    case 'CORRESPONDENCES_ACTION_SUCCESS':
    return {
        ...state,
        correspondences: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCES_CLONE_ACTION_SUCCESS':
    return {
        ...state,
        // correspondences: [action.payload, ...state.correspondences],
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCE_ACTION_SUCCESS':
    return {
        ...state,
        correspondence: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCE_ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_CORRESPONDENCE_ACTION_SUCCESS':
    return {
        ...state,
        //correspondences: [action.payload, ...state.correspondences],
        correspondence: action.payload.correspondence,
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCE_REMOVE_ACTION_SUCCESS':
    return {
        ...state,
        correspondences: state.correspondences.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SET_CORRESPONDENCE_METHOD_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'SET_CORRESPONDENCE_METHOD_ACTION_SUCCESS':
    return {
        ...state,
        selectedMethod: action.payload && action.payload.correspondence_method ? action.payload.correspondence_method : action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCES_PRICE_ACTION_SUCCESS':
    return {
        ...state,
        price: action.payload.total_price,
        nr_of_pages: action.payload.nr_of_pages,
        price_error: action.payload.error,
        message: action.payload.message,
        warnings: action.payload.warnings,
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCES_SUMMARY_ACTION_SUCCESS':
    return {
        ...state,
        summary: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCE_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'CORRESPONDENCE_RESET_ACTION_FETCH':
    return {
        ...state,
        correspondence: [],
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCES_ACTION_RESET':
    return {
        ...state,
        message: '',
        warnings: '',
        nr_of_pages: '',
        price_error: false,
        isError: false,
        isFetching: false,
        correspondences: [],
        correspondence: {},
        addresses: [],
        selectedMethod: {},
        price: 0,
        summary: { addresses: { 'mail': [], 'sms': [], 'push': [], 'email': [], 'email_mail': [] } },
        isFetching: false,
        isError: false,
    }
    case 'CORRESPONDENCE_UNLOCK_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
