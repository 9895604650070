import React, { Component, cloneElement } from 'react';
// import './Contacts.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import  AddContactForm from './Form/AddContact';
import { getContactsAction, updateContactsOrderAction, removeContactsAction, searchContactsAction } from '../../../../actions/contactsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import CloneContact from './Modal/CloneContact';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
//import TouchBackend from 'react-dnd-touch-backend';
//import { DragDropContext } from 'react-dnd';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { DownOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Upload,
  Tag,
  Button,
  Drawer,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
} from 'antd';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }

// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     props.moveRow(dragIndex, hoverIndex);
//
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );



class Contacts extends Component {

  state = {
      drawerVisible: false,
      selectedRowKeys: [],
      contacts: []
  };

  componentDidMount() {
    this.props.getContactsAction(this.props.match.params.id).then(() => {
      this.setState({contacts: this.props.contacts});
    });
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.addContactform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addContactAction(this.props.match.params.id, values);
  //       this.addContactform.props.form.resetFields();
  //       this.setState({drawerVisible: false});
  //     }
  //   });
  // }

  // components = {
  //   body: {
  //     row: DragableBodyRow,
  //   },
  // };

  // moveRow = (dragIndex, hoverIndex) => {
  //   const { contacts } = this.state;
  //   const dragRow = contacts[dragIndex];
  //
  //   this.setState(
  //     update(this.state, {
  //       contacts: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.contacts.map((v,k) => v.id);
  //
  //   this.props.updateContactsOrderAction(this.props.match.params.id, {'contacts' : sort});
  // };

  deleteSelected = () => {
      Modal.confirm({
        title: 'Weet je het zeker dat je de geselecteerde contactpersonen wilt verwijderen?',
        content: '',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {
              // console.log(this.state.selectedRowKeys);
              this.props.removeContactsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
                  // this.setState({contacts: this.props.contacts, selectedRowKeys: []})
                  resolve();

                  this.props.getContactsAction(this.props.match.params.id).then(() => {
                    this.setState({contacts: this.props.contacts});
                  });

                  showSuccess('Succesvol verwijderd');
              });
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          //console.log('Cancel');
        },
      });
  }

  onSearch(value) {
    this.props.searchContactsAction(this.props.match.params.id, {'search': value}).then(() => {
      this.setState({contacts: this.props.contacts});
    });
  }

  render() {
    // const { contacts } = this.state;

    const columns = [
    {
      key: "sort",
      width: 20
    },
    {
        title: 'Naam',
        dataIndex: 'name',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/modules/contacts/${record.id}/detail`}>
              {record.first_name + (record.affix ? ' ' + record.affix : '') + ' ' + record.last_name}
            </Link>
          </span>
        )
    }, {
        title: 'Functie',
        dataIndex: 'function',
        render: (text, record) => (
          <span>{text}</span>
        ),
    }, {
        title: 'E-mail',
        dataIndex: 'email',
        render: (text, record) => (
          <span>{text}</span>
        ),
    },{
        title: 'Telefoon',
        dataIndex: 'phone_number',
        render: (text, record) => (
          <span>{text}</span>
        ),
    }, {
        title: 'Mobiel',
        dataIndex: 'mobile_number',
        render: (text, record) => (
          <span>{text}</span>
        ),
    }
  ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var contacts = this.state.contacts;

        const activeIndex = contacts.findIndex((i) => i.id === active.id);
        const overIndex = contacts.findIndex((i) => i.id === over.id);

        this.setState({ contacts: arrayMove(contacts, activeIndex, overIndex) }, () => {

            var sort = this.state.contacts.map((v,k) => v.id);

            this.props.updateContactsOrderAction(this.props.match.params.id, {'contacts' : sort});
        });
      }
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <Dropdown  disabled={!this.props.HaveAbility('partner' ,'write')}  trigger={['click']} overlay={(
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/projects/${this.props.match.params.id}/modules/contacts/add`}>
                      Nieuw
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                      <CloneContact onClone={() => this.setState({contacts: this.props.contacts})} {...this.props} plain />
                  </Menu.Item>
                </Menu>
              )}>
              <Button type="new">
                Toevoegen <DownOutlined />
              </Button>
          </Dropdown>
          {/*<Link to={`/projects/${this.props.match.params.id}/modules/contacts/add`}>
            <Button disabled={!this.props.HaveAbility('contact' ,'write')} type="new" icon="plus">Toevoegen</Button>
          </Link>*/}

            <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('contact' ,'write') && (this.state.selectedRowKeys.length > 0 ? false : true) } overlay={(
                <Menu>
                  <Menu.Item onClick={() =>this.deleteSelected()} key="5" disabled={!this.props.HaveAbility('contact' ,'delete')} style={{ color: this.props.HaveAbility('contact', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                </Menu>
              )}>
              <Button type="secondary">
                Acties <DownOutlined />
              </Button>
            </Dropdown>

          </Col>
          <Col span={12}>
            <Input.Search
              allowClear
              placeholder="Zoeken..."
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
              { this.props.HaveAbility('contact', 'write') && this.state.contacts && this.state.contacts.length ?
                  <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                      items={this.state.contacts.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <CustomTable
                        rowKey='id'
                        columns={columns}
                        dataSource={this.state.contacts}
                        components={!this.props.isFetching ? {
                            body: {
                                row: DraggableRow
                            }
                        } : {}}
                        size="middle"
                        rowSelection={rowSelection}
                        loading={this.props.isFetching}
                        />
                    </SortableContext>
                  </DndContext>
                  :
                  <CustomTable
                    rowKey='id'
                    size="middle"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.props.isFetching ? [] : this.state.contacts}
                    loading={this.props.isFetching}
                    // components={this.components}
                    // onRow={(record, index) => ({
                    //   index,
                    //   moveRow: this.moveRow,
                    // })}
                  />
              }
          </Col>
        </Row>
      </div>
    );

  }
}

//const ContactsForm = Form.create({ name: 'contacts_settings' })(Contacts);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.contact.isFetching,
      isError : state.contact.isError,
      contacts: state.contact.contacts,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getContactsAction : bindActionCreators(getContactsAction, dispatch),
      // addContactAction : bindActionCreators(addContactAction, dispatch),
      updateContactsOrderAction : bindActionCreators(updateContactsOrderAction, dispatch),
      removeContactsAction : bindActionCreators(removeContactsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
      searchContactsAction : bindActionCreators(searchContactsAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
