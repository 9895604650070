import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseFormReminderSteps from './SupportBaseFormReminderSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
  Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess } from '../../../../../utils/Notifications';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { getSupportBaseFormByIdAction, checkSupportBaseFormDocumentsAction, checkSupportBaseFormEmailsAction, sendSupportBaseFormAction } from '../../../../../actions/supportBaseActions';

const { Title, Text } = Typography;

class SupportBaseFormReminder extends React.Component {

  state = {
      documents_email: this.props.auth.user.email,
      send_by_customer: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id);
  }

  patchInformationAddresses() {}

  checkDocuments(){
      this.props.checkSupportBaseFormDocumentsAction(this.props.match.params.form_id, this.state.documents_email).then(() => {
          showSuccess('Verzoek verzonden');
          // this.setState({ documents_email: '' });
          this.setState({ show_check_documents_modal: false });
      });
  }

  send()
  {
      Modal.confirm({
        title: 'Weet je het zeker?',
        content: 'Als je op JA klikt, wordt je actie uitgevoerd.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
          this.props.sendSupportBaseFormAction(this.props.match.params.form_id, 'reminder').then(() => {
            showSuccess('Formulier verzonden');
            this.props.history.push(`/projects/${this.props.match.params.id}/support-base/status`);
          });
        },
        onCancel() {},
      });

      // old
      // this.props.sendSupportBaseFormAction(this.props.match.params.form_id, 'reminder').then(() => {
      //     showSuccess('Formulier verzonden');
      //     this.props.history.push(`/projects/${this.props.match.params.id}/support-base/status`);
      // });
  }

  render () {

    const columns = [{
        title: 'Type',
        dataIndex: 'type',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aantal',
        dataIndex: 'nr_of',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Prijs per stuk',
        dataIndex: 'price_per_item',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Totaal',
        dataIndex: 'total',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Facturatie',
        dataIndex: 'invoice',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    }];

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>

          <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">

                <Col span={12}>
                  <div>
                    <Button type="new" style={{display: this.props.isFetching ? 'none' : 'inline-block'}} onClick={() => { this.send(); }}>{this.state.send_by_customer ? 'Afronden' : 'Verzenden'}</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/status`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>

                <Col span={12} align={'right'}>
                    <Row style={{display: (this.props.support_base_form.nr_of_letters || this.props.support_base_form.send_by_customer) ? 'flex' : 'none'}}>
                        <Col span={24}>

                          <Modal
                                title="Download alle document"
                                visible={this.state.show_check_documents_modal}
                                onCancel={() => this.setState({ show_check_documents_modal: false })}
                                onOk={() => { this.checkDocuments(); }}
                                okText="Verstuur"
                                >
                              <Form.Item label="Verstuur download link naar">
                                  <Row gutter={24}>
                                      <Col span={24}>
                                          <Input
                                            type="email"
                                            placeholder={'e-mailadres'}
                                            value={this.state.documents_email}
                                            onChange={(event) => { this.setState({documents_email: event.target.value}); }}
                                            style={{width: '100%'}}
                                          />
                                      </Col>
                                  </Row>
                              </Form.Item>
                          </Modal>

                          <Button onClick={() => { this.setState({ show_check_documents_modal: true }); }}>Download alle documenten</Button>

                        </Col>
                    </Row>
                </Col>

              </Row>

              <Row style={{ display: (this.props.support_base_form.send_by_customer ? 'flex' : 'none') }}>
                <Col span={24}>
                    <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="U heeft aangegeven om de brieven zelf te versturen. Klik hierboven om de overige correspondentie te versturen en toe te voegen aan het dossier." />
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseFormReminderSteps current={2} {...this.props}/>
                  </Card>
                </Col>
              </Row>

            <Row>
                <Col span={24}>
                    <Title level={4}>Versturen</Title>
                </Col>
                {/*<Col span={12} align={'right'}>
                    <Row>
                        <Col span={16}>
                            <Input placeholder={'e-mailadres'} value={this.state.documents_email} onChange={(event) => { this.setState({ documents_email: event.target.value }); }} />
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => { this.checkDocuments(); }}>Download alle documenten</Button>
                        </Col>
                    </Row>
                </Col>*/}
            </Row>

              <Row>
                <Col span={24}>
                  <CustomTable
                    rowKey='id'
                    size="middle"
                    //rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.props.support_base_form.pricing}
                    //loading={this.props.isFetching}
                  />
                </Col>
              </Row>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseFormReminderForm = Form.create({ name: 'support_base_form_form' })(SupportBaseFormReminder);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        support_base_form : state.support_base.support_base_form,
        auth: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        checkSupportBaseFormDocumentsAction : bindActionCreators(checkSupportBaseFormDocumentsAction, dispatch),
        sendSupportBaseFormAction : bindActionCreators(sendSupportBaseFormAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseFormReminderForm);
