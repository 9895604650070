import api from '../utils/api'

export const getInformationsAction = () => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_GLOBAL_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/information-texts/'+customer_id+'/own', null, true).then((response) => {
    dispatch({
      type: 'INFORMATION_GLOBAL_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const getCobeeInformationsAction = () => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_COBEE_GLOBAL_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/information-texts/'+customer_id+'/cobee', null, true).then((response) => {
    dispatch({
      type: 'INFORMATION_COBEE_GLOBAL_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_COBEE_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const addInformationAction = (values) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_GLOBAL_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/information-texts/'+customer_id, values, true).then((response) => {
    dispatch({
      type: 'ADD_INFORMATION_GLOBAL_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const patchInformationAction = (informationid, values) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_GLOBAL_ACTION_FETCH'
  })

  return api('post','/library/information-text/'+informationid, values, true).then((response) => {
    dispatch({
      type: 'PATCH_INFORMATION_GLOBAL_ACTION_SUCCESS',
      payload: response.data.informationtext
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const getInformationAction = (informationid) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_GLOBAL_ACTION_FETCH'
  })

  return api('get','/library/information-text/'+informationid, null, true).then((response) => {
    dispatch({
      type: 'GET_INFORMATION_GLOBAL_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const removeInformationsAction = (values) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_GLOBAL_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/information-texts/'+customer_id+'/multidelete', values, true).then((response) => {
    dispatch({
      type: 'REMOVE_INFORMATION_GLOBAL_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const resetInformationAction = () => (dispatch, getState) => {
  dispatch({
    type: 'RESET_INFORMATION_GLOBAL_ACTION_FETCH'
  })
}

export const searchInformationAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/information-texts/'+customer_id+'/search', {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_INFORMATION_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}
export const cloneCobeeInformationAction = (informationid) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  // alert(customer_id);
  return api('post','/library/information-text/'+informationid+'/clone', {'customer_id': customer_id}, true).then((response) => {
    dispatch({
      type: 'CLONE_INFORMATION_ACTION_SUCCESS',
      payload: response.data
    });
    return response.data;
  }).catch((e) => {
    dispatch({
      type: 'CLONE_INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const cloneInformationAction = (projectid, informationid) => (dispatch, getState) => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/item/'+informationid+'/clone', {'project_id': projectid}, true).then((response) => {

    dispatch({
      type: 'CLONE_INFORMATION_ACTION_SUCCESS',
      payload: response.data
    });

    return response.data;

  }).catch((e) => {
    dispatch({
      type: 'CLONE_INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const addIndicatorAction = () => (dispatch, getState) => {
  dispatch({
    type: 'ADD_INDICATOR_ACTION'
  })
}

export const removeIndicatorAction = (index) => (dispatch, getState) => {
  dispatch({
    type: 'REMOVE_INDICATOR_ACTION',
    payload: index
  })
}
