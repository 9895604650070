import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getDeliveryFormsAction, searchDeliveryFormsAction, deleteDeliveryFormsAction, duplicateDeliveryFormAction, getCustomerDeliveryFormsAction, unlockFormAction } from '../../../actions/deliverActions';
import { getStatusAction, searchStatusAction, getUsersAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, viewDeliveryFormPDFAction, viewDeliveryFormLogAction, emailDeliveryFormPDFAction } from '../../../actions/deliverActions';
import { getNrOfNewQueuedItems } from '../../../actions/queueActions';
import DeliverSideMenu from './DeliverSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Divider,
    Modal,
    Select,
    Menu,
    Dropdown,
    Badge,
    Timeline,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';

const { Option } = Select;

class DeliveryForms extends Component
{
    state = {
        can_write: false,
        can_delete: false,
        selectedRowKeys: [],
        toggleAll: false,
        expandedRowKeys: [],

        duplicateSelectedDisabled: true,
        deleteSelectedDisabled: true,
        filtered: false,
    };

    forms = [];

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write'),
            can_delete: this.props.HaveAbility('deliver', 'delete')
        });

        this.loadData();
    }

    loadData()
    {
        this.props.getDeliveryFormsAction(this.props.match.params.id).then(() => {

            this.forms = JSON.parse(JSON.stringify(this.props.forms)); // original

            var flattened_forms = [];

            // is sub point selected
            this.forms.map((record) => {

                if(record.children)
                {
                    var children = JSON.parse(JSON.stringify(record.children));

                    // delete record.children;
                    record.children = false;

                    flattened_forms.push(record);

                    children.map((child_record) => {

                        flattened_forms.push(child_record);
                    });
                }
                else
                {
                    flattened_forms.push(record);
                }
            });

            this.setState({ forms: flattened_forms });
        });

        this.props.getCustomerDeliveryFormsAction();
    }

    addNewDeliveryForm()
    {
        this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/delivery-form/add');
    }

    duplicateFromLibrary = () => {
      if(this.state.selectedId)
      {
        this.props.duplicateDeliveryFormAction(this.state.selectedId, 'project', this.props.match.params.id).then(() => {

          this.setState({selectedId: null, modalVisible: false});

          this.props.getDeliveryFormsAction(this.props.match.params.id);

          showSuccess('Succesvol toegevoegd');
        });
      }
    }

    duplicateSelected = () => {
      this.props.duplicateDeliveryFormAction(this.state.selectedRowKeys[0], 'project', this.props.match.params.id).then(() => {

        showSuccess('Succesvol gedupliceerd');

        this.setState({selectedRowKeys: []});

        this.props.getDeliveryFormsAction(this.props.match.params.id);
      });
    }

    isDeliveryFormCompleted(form_id)
    {
        var completed = false;

        this.props.forms.map((form) => {

            if(form.id == form_id && form.completed)
            {
                completed = true;
            }
            else if(form.children)
            {
                form.children.map((child_form) => {

                    if(child_form.id == form_id && (child_form.completed || child_form.status == 'completed'))
                    {
                        completed = true;
                    }
                });
            }
        });

        return completed;
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({
            duplicateSelectedDisabled: true,
            deleteSelectedDisabled: true
        });

        this.setState({ selectedRowKeys });

        //
        var duplicateSelectedDisabled = false;
        var deleteSelectedDisabled = false;

        selectedRowKeys.map((key) => {

            key = ''+key;

            if(key.search('_') > 0)
            {
                duplicateSelectedDisabled = true;

                var child_id = key.substring(key.search('_') + 1);

                if(this.isDeliveryFormCompleted(child_id))
                {
                    deleteSelectedDisabled = true;
                }
            }
            else if(this.isDeliveryFormCompleted(key))
            {
                deleteSelectedDisabled = true;
            }
        });

        if(selectedRowKeys.length > 1)
        {
            duplicateSelectedDisabled = true;
        }

        this.setState({
            duplicateSelectedDisabled: duplicateSelectedDisabled,
            deleteSelectedDisabled: deleteSelectedDisabled
        });
    }

    deleteDeliveryForms()
    {
        Modal.confirm({
          title: 'Wil je deze items verwijderen?',
          // content: 'Als je op JA klikt, wordt ook het gehele dossier verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

              this.props.deleteDeliveryFormsAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {

                  resolve();

                  showSuccess('Succesvol verwijderd');

                  this.setState({
                      selectedRowKeys: []
                  });

                  this.loadData();
              });
            }).catch(() => {})
          },
          onCancel() {},
        });
    }

    showDuplicate() {
        this.setState({ modalVisible: true });
    }

    getStatusText(status)
    {
        if(status == 'completed'){ return 'Afgerond'; }
        if(status == 'signed'){ return 'Afgerond'; }
        else { return 'Open'; }
    }

    getStatusColor(status)
    {
        if(status == 'completed'){ return 'green'; }
        if(status == 'signed'){ return 'green'; }
        else { return 'red'; }
    }

    toggleAll()
    {
        var keys = [];

        if(this.state.toggleAll)
        {   // close all
            this.setState({
                toggleAll: false,
                expandedRowKeys: keys
            });
        }
        else
        {   // open all
            this.props.forms.map((record) => {

                if((record.children && record.children.length))
                {
                    keys.push(record.id);
                }
            });

            this.setState({
                toggleAll: true,
                expandedRowKeys: keys
            });
        }
    }

    viewDeliveryFormPDF(form_id, delivery_id = false)
    {
        this.props.viewDeliveryFormPDFAction(form_id, delivery_id).then(() => {

            showSuccess('Export succesvol aangevraagd');

            this.props.getNrOfNewQueuedItems(); // refresh topnav counter
        });
    }

    viewDeliveryFormLog(record)
    {
        // export
        // this.props.viewDeliveryFormLogAction(form_id).then(() => {
        //
        //     showSuccess('Export succesvol aangevraagd');
        //
        //     this.props.getNrOfNewQueuedItems(); // refresh topnav counter
        // });

        this.setState({
            timeline: record.log,
            showTimeline: true
        });
    }

    showEmailForm(form_id, delivery_id = false)
    {
        this.setState({ show_email_form: form_id, show_email_delivery_id: delivery_id });
    }

    emailDeliveryFormPDF(form_id, delivery_id = false)
    {
        this.props.emailDeliveryFormPDFAction(form_id, this.state.email, delivery_id).then(() => {

            showSuccess('E-mail wordt verzonden');

            this.setState({ email: '', show_email_form: false });
        });
    }

    unlockForm(form_id, delivery_id)
    {
        Modal.confirm({
          title: 'Wil je dit formulier ontgrendelen?',
          content: 'Als je op JA klikt, wordt het formulier ontgrendeld en worden de handtekeningen die eventueel zijn gezet, verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

              this.props.unlockFormAction(form_id, delivery_id).then(() => {

                  resolve();

                  showSuccess('Succesvol ontgrendeld');

                  this.loadData();
              });
            }).catch(() => {})
          },
          onCancel() {},
        });
    }

    applyFilters(filters)
    {
        var filtered = false;

        var filter_keys = Object.keys(/*this.state.*/filters);

        filter_keys.map((key) => {

            if(/*this.state.*/filters[key].length)
            {
                filtered = true;

                return;
            }
        });

        // this.setState({ filtered: filtered });
        this.filtered = filtered;
    }

    mySorter(a, b)
    {
        // console.log('my sorter', a, b);
        //
        // return a.localeCompare(b);

        // if(a > b){ return true; }
        // else{ return false; }

        if(a < b){ return -1; }
        else if(a > b){ return 1; }
        else{ return 0; }
    }

    render()
    {
        const columns = [
            {
                title: 'Naam',
                dataIndex: 'name',
                render: (text, record) => (
                    <span>
                        { !record.form_name ? <Link to={'/projects/'+this.props.match.params.id+'/deliver/delivery-form/' + record.id}>
                            {text}
                        </Link>
                        :
                        <Link to={'/projects/'+this.props.match.params.id+'/deliver/delivery-form/' + record.parent_delivery_form_id}>
                            - {record.form_name}
                        </Link>}
                    </span>
                )
            },
            // {
            //     title: 'Aantal opleveringen',
            //     dataIndex: 'deliveries_count',
            //     render: (text, record) => (
            //         <span>{text}</span>
            //     )
            // },
            // {
            //     title: 'Formulier per oplevering',
            //     dataIndex: 'deliveries_combined',
            //     render: (text, record) => (
            //         <span>{text == '1' ? 'Nee' : 'Ja'}</span>
            //     )
            // },
            {
                title: 'Omschrijving',
                dataIndex: 'description',
                render: (text, record) => (
                    <span>
                        { record.address ? (text ? text + ' | ' : '') + record.address : text }
                    </span>
                )
            },
            {
              title: 'Status',
              dataIndex: 'status',
              // sorter: (a, b) => { return a.status.localeCompare(b.status)},
              sorter: (a, b) => { return this.mySorter(a.status, b.status); },
              render: (text, record) => (
                this.getStatusText(text)
              ),
              filters: [
                {
                  text: 'Open',
                  value: 'open',
                },
                {
                  text: 'Afgerond',
                  value: 'completed',
                }
              ],
              onFilter: (value, record) => (Array.isArray(record.status) ? record.status.includes(value) : record.status == value),
              width: 110
            },
            {
              title: 'Punten',
              render: (text, record) => {
                return (
                  <div>
                      <Badge showZero count={record.nr_of_completed} style={{ opacity: (!record.nr_of_completed ? 0.33 : 1.0), backgroundColor: 'green', color: '#fff', marginRight: 4 }}></Badge>
                      <Badge showZero count={record.nr_of_submitted} style={{ opacity: (!record.nr_of_submitted ? 0.33 : 1.0),  backgroundColor: 'orange', color: '#fff', marginRight: 4 }}></Badge>
                      <Badge showZero count={record.nr_of_open} style={{ opacity: (!record.nr_of_open ? 0.33 : 1.0),  backgroundColor: 'red', color: '#fff', marginRight: 4 }}></Badge>
                  </div>)
              }
            },
            // {
            //     title: 'Adres',
            //     dataIndex: 'address',
            //     render: (text, record) => (
            //         <span>{ text  }</span>
            //     )
            // },
            // {
            //     title: 'Gekoppelde tekening',
            //     dataIndex: 'drawing',
            //     render: (text, record) => (
            //         <span>
            //             {text}
            //         </span>
            //     )
            // },
            {
                title: 'Uiterlijk gereed',
                dataIndex: 'due_date',
                render: (text, record) => {
                  return (
                    <span>
                      { text ? moment(text).format('DD/MM/YYYY') : 'Niet ingesteld' }
                    </span>
                  )
                }
            },
            {
                title: 'Acties',
                dataIndex: 'actions',
                render: (text, record) => {

                    const show_actions = record.children && record.children.length > 0 ? false : true;

                    return (
                        <span>
                        { show_actions && (record.status == 'completed' || record.status == 'signed') ?
                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item onClick={() => { if(record.delivery_form_id){ this.unlockForm(record.delivery_form_id, record.id); }else{ this.unlockForm(record.id); }}}>Openzetten om te bewerken</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item onClick={() => { if(record.delivery_form_id){ this.viewDeliveryFormPDF(record.delivery_form_id, record.id); }else{ this.viewDeliveryFormPDF(record.id); }}}>Bekijk formulier</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  {/*<Menu.Item onClick={() => { if(record.delivery_form_id){ this.viewDeliveryFormLog(record.delivery_form_id, record.id); }else{ this.viewDeliveryFormLog(record.id); }}}>Bekijk log</Menu.Item>*/}
                                  <Menu.Item onClick={() => { this.viewDeliveryFormLog(record); }}>Bekijk log</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item onClick={() => { if(record.delivery_form_id){ this.showEmailForm(record.delivery_form_id, record.id); }else{ this.showEmailForm(record.id); } }}>E-mail formulier</Menu.Item>
                                </Menu>
                            )}>
                                <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
                                    Acties <DownOutlined />
                                </Button>
                            </Dropdown>
                            :
                            null }
                        </span>
                    );
                }
            }
        ];

        const { selectedRowKeys } = this.state;
        const { expandedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected) => { if(selected){ var expandedRowKeys = this.state.expandedRowKeys; expandedRowKeys.push(record.id); this.setState({ expandedRowKeys: expandedRowKeys}); } },
            hideDefaultSelections: true,
            // getCheckboxProps: (record: DataType) => ({
            //     disabled: record.completed || record.locked || record.nr_of_points ? true : false
            // }),
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="7"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Row className="mbm">
                        <Col span={12}>
                            {/*<Button disabled={!this.state.can_write} type="new" onClick={() => { this.addNewDeliveryForm(); }}>+ Toevoegen</Button>*/}
                            <Dropdown disabled={!this.state.can_write} trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item key="1">
                                    <Link key="1" onClick={() => { this.addNewDeliveryForm(); }} disabled={!this.state.can_write}>
                                      Nieuw
                                    </Link>
                                  </Menu.Item>
                                  <Menu.Item key="2" onClick={() => { this.showDuplicate(); }} disabled={!this.props.HaveAbility('library', 'read')}>
                                      Bibliotheek
                                  </Menu.Item>
                                </Menu>)}>
                                <Button type="new">
                                    Toevoegen <DownOutlined />
                                </Button>
                              </Dropdown>

                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item disabled={this.state.duplicateSelectedDisabled} onClick={() => this.duplicateSelected()} key="1">Dupliceren</Menu.Item>
                                  <Menu.Item key="2" disabled={this.state.deleteSelectedDisabled} style={{display: (this.state.is_archive ? 'none' : 'flex'), color: this.state.can_delete ? 'red' : 'grey'}} onClick={() => { this.deleteDeliveryForms(); }}>Verwijderen</Menu.Item>
                                </Menu>
                              )} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true }>
                              <Button type="secondary">
                                Acties <DownOutlined />
                              </Button>
                            </Dropdown>

                            <Button style={{ marginLeft: 10 }} onClick={() => { this.toggleAll(); }}>Alles { !this.state.toggleAll ? 'open' : 'dicht' }klappen</Button>
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchDeliveryFormsAction(this.props.match.params.id, value); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                                />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ padding: 6, paddingLeft: 0 }}>
                            Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {/*<CustomTable
                                //rowKey='id'
                                rowKey={(record) => {
                                    return (record.delivery_form_id ? record.delivery_form_id + '_' + record.id : record.id)
                                }}
                                size="middle"
                                rowSelection={rowSelection}
                                expandedRowKeys={this.state.expandedRowKeys}
                                onExpandedRowsChange={(id) => {
                                    this.setState({expandedRowKeys: id});
                                }}
                                columns={columns}
                                dataSource={this.props.forms}
                                loading={this.props.isFetching}
                            />*/}

                            { this./*state.*/filtered == true ?
                            <CustomTable
                                rowKey={(record) => {
                                    return (record.delivery_form_id ? record.delivery_form_id + '_' + record.id : record.id)
                                }}
                                size="middle"
                                rowSelection={rowSelection}
                                expandedRowKeys={this.state.expandedRowKeys}
                                onExpandedRowsChange={(id) => {
                                    this.setState({expandedRowKeys: id});
                                }}
                                columns={columns}
                                dataSource={this.state.forms}
                                loading={this.props.isFetching}
                                onChange={(pagination, filters, sorter, extra) => {

                                    // this.setState({ filters: filters}, () => {

                                        this.applyFilters(filters);
                                    // });
                                }}
                                indentSize={0}
                                // rowClassName={(record, index) => {
                                //     return this.filterRecord(record) ? 'display-none' : '';
                                // }}
                                />
                            :
                            <CustomTable
                                rowKey={(record) => {
                                    return (record.delivery_form_id ? record.delivery_form_id + '_' + record.id : record.id)
                                }}
                                size="middle"
                                rowSelection={rowSelection}
                                expandedRowKeys={this.state.expandedRowKeys}
                                onExpandedRowsChange={(id) => {
                                    this.setState({expandedRowKeys: id});
                                }}
                                columns={columns}
                                dataSource={this.props.forms}
                                loading={this.props.isFetching}
                                onChange={(pagination, filters, sorter, extra) => {

                                    // this.setState({ filters: filters}, () => {

                                        this.applyFilters(filters);
                                    // });
                                }}
                                indentSize={0}
                                // rowClassName={(record, index) => {
                                //     return this.filterRecord(record) ? 'display-none' : '';
                                // }}
                                />
                            }
                        </Col>
                    </Row>

                </div>

                <Modal
                  title='Bibliotheek'
                  visible={this.state.modalVisible}
                  onCancel={() => { this.setState({ modalVisible:false, selectedId: null }); }}
                  onOk={() => { this.duplicateFromLibrary(); }}
                  okText="Toevoegen"
                >
                  <Form layout={'vertical'}>
                    <Form.Item required hasFeedback label="Formulier">
                      <Select showSearch value={this.state.selectedId} placeholder={'Selecteer opleverformulier'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                        {this.props.customer_forms && this.props.customer_forms.map((element) => (
                          <Select.Option value={element.id}>{element.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </Modal>

                <Modal
                      title="E-mail formulier"
                      visible={this.state.show_email_form ? true : false}
                      onCancel={() => this.setState({ show_email_form: false })}
                      onOk={() => { this.emailDeliveryFormPDF(this.state.show_email_form, this.state.show_email_delivery_id); }}
                      okText="Verstuur"
                      >
                    <Form.Item label="Verstuur download link naar">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item required label="E-mail">
                                  { getFieldDecorator('email', {
                                    initialValue: [],
                                    rules: [{ required: true, message: 'Vul minimaal één e-mailadres in'}],
                                  })(
                                    <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({email: value.join(',')}); }}></Select>
                                  ) }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Modal>

                <Modal
                  title='Tijdlijn'
                  visible={this.state.showTimeline}
                  // okText={'Ok'}
                  // onOk={() => this.setState({ modalShowTransactionLog: false })}
                  okButtonProps={{ style: { display: 'none' } }}
                  cancelText={'Sluiten'}
                  onCancel={() => this.setState({ showTimeline: false })}
                >
                  <div>
                    <Timeline>
                      { this.state.timeline && this.state.timeline.map((record, index) => {
                        return (<Timeline.Item>{moment(record.created_at).format('DD/MM/YYYY - HH:mm') + ' - ' + record.message}</Timeline.Item>)
                      }) }
                    </Timeline>
                  </div>
                </Modal>

            </div>
        );
    }
}

const DeliveryFormsForm = Form.create({ name: 'delivery_forms' })(DeliveryForms);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        status : state.deliver.status,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        location_filters : state.deliver.location_filters,
        forms : state.deliver.forms,
        customer_forms : state.deliver.customer_forms
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStatusAction : bindActionCreators(getStatusAction, dispatch),
        searchStatusAction : bindActionCreators(searchStatusAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        viewDeliveryFormPDFAction : bindActionCreators(viewDeliveryFormPDFAction, dispatch),
        viewDeliveryFormLogAction : bindActionCreators(viewDeliveryFormLogAction, dispatch),
        emailDeliveryFormPDFAction : bindActionCreators(emailDeliveryFormPDFAction, dispatch),
        getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
        getDeliveryFormsAction : bindActionCreators(getDeliveryFormsAction, dispatch),
        searchDeliveryFormsAction : bindActionCreators(searchDeliveryFormsAction, dispatch),
        deleteDeliveryFormsAction : bindActionCreators(deleteDeliveryFormsAction, dispatch),
        duplicateDeliveryFormAction : bindActionCreators(duplicateDeliveryFormAction, dispatch),
        getCustomerDeliveryFormsAction : bindActionCreators(getCustomerDeliveryFormsAction, dispatch),
        unlockFormAction : bindActionCreators(unlockFormAction, dispatch),
        HaveAbility :  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormsForm);
