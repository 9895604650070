export default (state = {isError: false, isFetching: false, user:{}, customers:[], selectedCustomer:{}}, action) => {
    switch (action.type) {
    case 'LOGIN_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'LOGIN_ACTION_SUCCESS':

    var selectedCustomer = (action.payload.customers.filter(customer => customer.id == 1).length
        ?
        action.payload.customers.filter(customer => customer.id == 1)[0]
        :
        action.payload.customers.length ?
            action.payload.customers[0]
            :
            {}
        );

    localStorage.setItem('customerId', (selectedCustomer ? selectedCustomer.id : false));

    return {
        ...state,
        user: action.payload,
        customers: action.payload.customers,
        /*selectedCustomer: (action.payload.customers.filter(customer => customer.id == 1).length
            ?
            action.payload.customers.filter(customer => customer.id == 1)[0]
            :
            action.payload.customers.length ?
                action.payload.customers[0]
                :
                {}
        ),/**/
        selectedCustomer: selectedCustomer,
        isFetching: false,
        isError: false,
    }
    case 'LOGIN_USER_ACTION_SUCCESS':

    var selectedCustomer = (action.payload.customers.filter(customer => customer.id == 1).length
        ?
        action.payload.customers.filter(customer => customer.id == 1)[0]
        :
        action.payload.customers.length ?
            action.payload.customers[0]
            :
            {}
        );

    localStorage.setItem('customerId', (selectedCustomer ? selectedCustomer.id : false));

    return {
        ...state,
        user: action.payload,
        customers: action.payload.customers,
        /*selectedCustomer: (action.payload.customers.filter(customer => customer.id == 1).length
            ?
            action.payload.customers.filter(customer => customer.id == 1)[0]
            :
            action.payload.customers.length ?
                action.payload.customers[0]
                :
                {}
        ),/**/
        selectedCustomer: selectedCustomer,
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_ACTION_SWITCH':
    return {
        ...state,
        selectedCustomer: action.payload,
    }
    case 'LOGIN_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    // case 'CUSTOMERS_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     customers: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    default:
        return state
    }
}
