import React, { Component } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import './Dashboard.css';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Table, TimePicker } from 'antd';

import { getCustomerDashboard } from '../../actions/dashboardActions';

const columns = [
  {
    title: 'Onderwerp',
    dataIndex: 'subject',
    key: 'subject',
    render: (text, object) => <Link to={`/projects/${object.project_id}/correspondence/${object.id}/setup`}>{text}</Link>,
  }, {
    title: 'Project',
    dataIndex: 'project_name',
    key: 'subject',
    //render: (text, object) => <a href={`/projects/${object.project_id}/correspondence/${object.id}/setup`}>{text}</a>,
  }

]
const column_news = [
  {
    title: 'Onderwerp',
    dataIndex: 'title',
    key: 'title',
    render: (text, object) => <a href={object.link} target='blank'>{text.replace(/&#8211;/g, '-')}</a>,
  }

]

class Dashboard extends Component {

    state = {
      news: [],
    }

    componentDidMount()
    {
      this.props.history.push('/projects');
        //https://www.cobee.nl/feed/?post_type=blog

        // axios.get('https://www.cobee.nl/feed/?post_type=blog');

        // axios({
        //     method: 'get',
        //     headers: { 'Content-Type': 'multipart/form-data' },
        //     url: 'https://www.cobee.nl/feed/?post_type=blog',
        //   }).then((response) => {
        //
        //     var parser = require('fast-xml-parser');
        //     var jsonObj = parser.parse(response.data);
        //     console.log(jsonObj.rss.channel.item);
        //     this.setState({ news: jsonObj.rss.channel.item })
        //   });



        // this.props.getCustomerDashboard().then(() => {
        //     this.setState({latest_correspondence: this.props.latest_correspondence});
        // });
    }

    render() {
        return (
            <div className="dashboard">
                {/*<Row gutter={24} type="flex">
                    <Col span={8}>
                        <Card style={{ height: '100%' }} cover title={(
                            <div>
                                Laatste correspondentie
                            </div>
                            )} bordered={false}>
                            <Table
                                columns={columns}
                                dataSource={this.props.latest_correspondence}
                                pagination={false}
                                showHeader={true} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ height: '100%' }} cover title={(
                            <div>
                                Eerst volgende ingeplande correspondentie
                            </div>
                            )} bordered={false}>
                            <Table
                                columns={columns}
                                dataSource={this.props.latest_correspondence_scheduled}
                                pagination={false}
                                showHeader={true} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ height: '100%' }} cover title={(
                            <div>
                                Laatst verstuurde correspondentie
                            </div>
                            )} bordered={false}>
                            <Table
                                columns={columns}
                                dataSource={this.props.latest_correspondence_send}
                                pagination={false}
                                showHeader={true} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ marginTop: 24 }} cover title={(
                            <div>
                                Laatst updates
                            </div>
                            )} bordered={false}>
                            <Table
                                columns={column_news}
                                dataSource={this.state.news}
                                pagination={false}
                                showHeader={false} />
                        </Card>
                    </Col>
                </Row>*/}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        latest_correspondence: state.dashboard.latest_correspondence,
        latest_correspondence_scheduled: state.dashboard.latest_correspondence_scheduled,
        latest_correspondence_send: state.dashboard.latest_correspondence_send,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerDashboard: bindActionCreators(getCustomerDashboard, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
