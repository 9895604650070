import React, { Component } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Select,
  Checkbox,
  Switch,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInformationsAction, removeInformationsAction, searchInformationAction, getCobeeInformationsAction } from '../../actions/globalInformationActions';
import { getLibraryCategoriesAction } from '../../actions/librariesActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';
import CloneInformation from './Modal/CloneInformation';
import InformationTextMenu from './Menu/InformationTextMenu';

class GlobalInformation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],

      filteredInfo: null,

      group_filter: [],
      group_filter_and: true,
    };
  }

  componentDidMount() {

    this.props.getLibraryCategoriesAction('information');

    this.props.getInformationsAction();
    // this.props.getCobeeInformationsAction();
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.removeInformationsAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  listItem = (element) => {
      return (
          <Select.OptGroup label={element.name}>
            {element.items.map((item) => (
                <Select.Option value={item.id}>{item.title}</Select.Option>
            ))}
          </Select.OptGroup>
      );
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = false;

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = false;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  removeItem(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  render() {

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Titel',
      dataIndex: 'title',
      sorter: (a, b) => { return a.title.localeCompare(b.title)},
      render: (text, record) => (
        <span>
          <Link to={`/library/information-text/${record.id}/detail`}>
            {record.title}
          </Link>
        </span>
      )
    },
    {
        title: 'Categorieen',
        dataIndex: '',
        key: 'categories',
        width: 160,
        filterMultiple: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 0 }}>

              <div>
                  {this.props.categories && this.props.categories.map((obj) => {
                      return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                          <Checkbox
                              onChange={(e) => {
                                  var my_group_filter = this.state.group_filter;

                                  if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                  else{ this.removeItem(my_group_filter, obj.name); }

                                  this.setState({ group_filter: my_group_filter });

                                  setSelectedKeys(my_group_filter);
                              }}
                              checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                              >
                              {obj.name}
                          </Checkbox>
                      </div>
                  })}
              </div>

              <div style={{padding: 8, width: '100%', clear: 'both'}}>
                  <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                  <div style={{float: 'left', marginLeft: 8}}>filter</div>
              </div>

              <div style={{width: '100%', clear: 'both'}}>
                  <Button
                    type="link"
                    onClick={() => { confirm(); }}
                    style={{marginLeft: -8, marginRight: 8 }}
                    >OK</Button>

                  <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
              </div>

            </div>
        ),
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.categories && record.categories.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
        )
    },
    {
      title: 'Aangemaakt door',
      dataIndex: 'customer_id',
      render: (text, record) => (
        <span>
            {!record.customer_id ? 'Cobee' : 'Eigen tekst'}
        </span>
      ),
      filters: [
        {
          text: 'Cobee',
          value: 'cobee',
        },
        {
          text: 'Alleen eigen teksten tonen',
          value: 'own',
        },
      ],
      onFilter: (value, record) => {
        if(value === 'cobee') {
          return record.customer_id === null;
        }
        if(value === 'own') {
          return record.customer_id !== null;
        }
      },
    }];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Informatie'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <LibrarySideMenu id={this.props.match.params.id} activeMenu='5' />

        <div style={{padding: 0, display: 'flex', width: '100%'}}>

            <InformationTextMenu activeMenu='1' />

            <div style={{padding: 24, width: '100%'}} colon={true}>

                <Row className="mbm">
                  <Col span={12}>
                    <Button disabled={!this.props.HaveAbility('library', 'write')} onClick={() => this.props.history.push('/library/information-text/add')} icon={<PlusOutlined />} type="primary ant-btn-new">
                      Toevoegen
                    </Button>
                    { this.props.HaveAbility('library', 'write') && (
                      <CloneInformation
                        onClone={(response) => {
                            this.props.history.push('/library/information-text/'+response.id+'/detail');
                            showSuccess('Succesvol gedupliceerd');
                            this.props.getInformationsAction();
                        }}
                        />
                    )}
                    <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('library', 'delete') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                      <Menu>
                        <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('library' ,'delete')} style={{ color: this.props.HaveAbility('library', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                      </Menu>)}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ value => this.props.searchInformationAction(value) }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey="id"
                      rowSelection={rowSelection}
                      columns={columns}
                      loading={false}
                      dataSource={this.props.information}
                      />
                  </Col>
                </Row>
            </div>

          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.information.isFetching,
    isError : state.information.isError,
    information : state.information.globalInformation,
    categories : state.library.categories
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInformationsAction : bindActionCreators(getInformationsAction, dispatch),
    removeInformationsAction : bindActionCreators(removeInformationsAction, dispatch),
    searchInformationAction : bindActionCreators(searchInformationAction, dispatch),
    getCobeeInformationsAction : bindActionCreators(getCobeeInformationsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalInformation);
