import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseCorrespondenceSteps from './SupportBaseCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess } from '../../../../../utils/Notifications';
import FormBuilder from '../../../../../components/FormBuilder/FormBuilder'
import { getSupportBaseFormByIdAction, updateSupportBaseFormAction, checkSupportBaseFormAction } from '../../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class SupportBaseCorrespondenceForm extends React.Component {

  state = {
      formData: null,
      can_write: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

    this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false).then(() => {
        if(this.IsJsonString(this.props.support_base_form.form)){
          this.refs.FormBuilder.parse(JSON.parse(this.props.support_base_form.form));
          this.setState({formData: JSON.parse(this.props.support_base_form.form)})
        }
    });
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  patchInformationAddresses() {}

  save()
  {
      var formData = JSON.stringify(this.state.formData);

      // sconsole.log(formData);

      this.props.updateSupportBaseFormAction(this.props.match.params.form_id, {form: formData}).then(() => {
          // showSuccess('Succesvol opgeslagen');
          // this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/letter`);
      });
  }

  saveAndContinue()
  {
      var formData = JSON.stringify(this.state.formData);

      this.props.updateSupportBaseFormAction(this.props.match.params.form_id, {form: formData}).then(() => {
          showSuccess('Succesvol opgeslagen');

          if(this.props.support_base_form.nr_of_letters > 0 || this.props.support_base_form.send_by_customer > 0)
          {
              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/letter`);
          }
          else if(this.props.support_base_form.nr_of_emails > 0)
          {
              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/email`);
          }
          else
          {
              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/attachments`);
          }
      });
  }

  previewMail()
  {
      this.props.checkSupportBaseFormAction(this.props.match.params.form_id, 'mail');
  }

  previewDigital()
  {
      this.props.checkSupportBaseFormAction(this.props.match.params.form_id, 'digital');
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write} type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/form/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
                <Col span={12} align={'right'}>
                    <Button onClick={() => { this.previewMail(); }}>Voorbeeld post</Button>
                    &nbsp;
                    <Button onClick={() => { this.previewDigital(); }}>Voorbeeld digitaal</Button>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseCorrespondenceSteps current={0} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <FormBuilder
                    onChangeForm={(data) => this.setState({formData: data}, () => this.save())}
                    ref={'FormBuilder'}
                    formId={this.props.match.params.form_id}
                    sections={1}
                    enabledElements={[
                        'sb_select',
                        'sb_textinput',
                        'sb_title',
                        'sb_textareainput',
                        'sb_numberinput',
                        'sb_timeinput',
                        'sb_dateinput',
                        'sb_agreeinput',
                        // 'sb_signatureinput',
                        'sb_contactinput'
                    ]}
                    disabled={!this.props.HaveAbility('support-base' ,'write')}
                    />
                </Col>
              </Row>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseCorrespondenceFormForm = Form.create({ name: 'intake_form' })(SupportBaseCorrespondenceForm);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        support_base_form : state.support_base.support_base_form,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        updateSupportBaseFormAction : bindActionCreators(updateSupportBaseFormAction, dispatch),
        checkSupportBaseFormAction : bindActionCreators(checkSupportBaseFormAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseCorrespondenceFormForm);
