import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormAction, addCustomerDeliveryFormAction, updateDeliveryFormAction, resetDeliveryFormAction } from '../../../actions/deliverActions';
import PageHeader from '../../../components/PageHeader/PageHeader';
import DeliverySteps from '../Steps/DeliverySteps';
import LibrarySideMenu from '../LibrarySideMenu';
import { HaveAbility, HaveRole } from '../../../utils/RolesAndAbilities';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


class DeliveryDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      loading: true,
    };
  }

  componentDidMount()
  {
    this.props.resetDeliveryFormAction();

    if(this.props.match.params.id) {

        this.loadData();
    } else {
      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []}]);

      this.setState({ loading: false });
    }
  }

  loadData()
  {
      this.props.resetDeliveryFormAction();

      this.props.getDeliveryFormAction(this.props.match.params.id).then(() => {

        if(this.IsJsonString(this.props.delivery_form.form)){

          var formData = JSON.parse(this.props.delivery_form.form);

          this.setState({formData: formData}, () => {

              this.refs.FormBuilder.parse(formData);

              this.setState({ loading: false });
          })
        }
        else
        {
            this.setState({ loading: false });
        }

        // this.setState({ loading: false });
      });
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getEnabledElements()
  {
      var elements = [];

      elements = [
          'd_select',
          'd_textinput',
          'd_title',
          'd_textareainput',
          'd_numberinput',
          'd_timeinput',
          'd_dateinput',
          'd_photoinput',
          'd_drawinginput',
          'd_image',
          'd_deliverypointsinput'
      ];

      return elements;
  }

  handleSubmit = (e, values, redirect = false) => {

      this.props.form.validateFields((err, values) => {

        values.form = JSON.stringify(this.state.formData);

        if (!err){

          if(this.props.match.params.id && this.props.match.params.id != 'add') {

            this.props.updateDeliveryFormAction(this.props.match.params.id, values).then(() => {

              if(redirect)
              {
                  this.props.history.push('/library/delivery/' + this.props.match.params.id + '/settings');
              }
            });
          }
          else {

            this.props.addCustomerDeliveryFormAction(values).then(() => {

              this.props.history.push('/library/delivery/' + this.props.delivery_form.id + '/detail');

                this.loadData();
            });
          }

          showSuccess();
        }
      });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Opname', 'link': '#'}, {'label': 'Formulieren'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='20' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={this.state.loading || !this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null, this.state.formData, true)}>Opslaan en verder</Button>
                      <Link to={`/library/delivery`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col span={12} align={'right'}>
                      {/*<Button onClick={(event) => { this.preview(); }}>Voorbeeld</Button>*/}
                  </Col>
                </Row>

                {/* steps */}
                <Row className="mbm" gutter={24}>
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliverySteps current={0} locked={this.props.delivery_form.locked} {...this.props}/>
                    </Card>
                  </Col>
                </Row>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24} style={{ marginBottom: 24 }}>
                        <Col span={16}>
                          <Form.Item hasFeedback className="mbn" required label="Naam">
                            {getFieldDecorator('name', {
                              initialValue: this.props.delivery_form.name,
                              rules: [{ required: true, message: 'Vul een omschrijving in' }],
                              onChange: (e) => { this.setState({ description: e.target.value }); }
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item className="mbn" label={'Vergrendeld'}>
                              {getFieldDecorator('locked', {
                                  initialValue: this.props.delivery_form.locked ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                        { this.props.selectedCustomer.id == 1 && this.props.HaveRole(['super-admin']) ?
                        <Col span={4}>
                            <Form.Item label="Gebruik voor demo project">
                              {getFieldDecorator('use_for_demo', {
                                  valuePropName: 'checked',
                                  initialValue: this.props.delivery_form && this.props.delivery_form.use_for_demo ? true : false
                              })(
                                  <Switch
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      />
                              )}
                            </Form.Item>
                        </Col> : null }

                    </Row>

                    <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                      <Col span={24}>
                          <FormBuilder
                            onChangeForm={(data) => { this.setState({ formData: data }, (e) => { this.handleSubmit(e, { form: JSON.stringify(data) }, false); }); } }
                            //onChangeForm={(data) => this.setState({formData: data}, (e) => this.handleSubmit(e, data, false))}
                            ref={'FormBuilder'}
                            type={'deliver_form'}
                            formId={this.props.match.params.id}
                            enabledElements={this.getEnabledElements()}
                            //sections={0}
                            disabled={!this.props.HaveAbility('form' ,'write')}
                            cloneSections={true}
                            //addressFields={this.props.address_fields}
                            />
                      </Col>
                    </Row>

                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const DeliveryDetailForm = Form.create({ name: 'delivery_form' })(DeliveryDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.deliver.isFetching,
    isError : state.deliver.isError,
    delivery_form : state.deliver.form,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryFormAction : bindActionCreators(getDeliveryFormAction, dispatch),
    addCustomerDeliveryFormAction : bindActionCreators(addCustomerDeliveryFormAction, dispatch),
    updateDeliveryFormAction : bindActionCreators(updateDeliveryFormAction, dispatch),
    resetDeliveryFormAction : bindActionCreators(resetDeliveryFormAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    //previewAction: bindActionCreators(previewAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailForm);
