export default (state = {isError: false, isFetching: false, news: [], newsarticle: {}}, action) => {
    switch (action.type) {
    case 'NEWS_ACTION_FETCH':
    return {
        ...state,
        news: [],
        isFetching: true,
        isError: false,
    }
    case 'NEWSARTICLE_ACTION_FETCH':
    return {
        ...state,
        newsarticle: {},
        isFetching: true,
        isError: false,
    }
    case 'NEWS_ACTION_SUCCESS':
    return {
        ...state,
        news: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'NEWSARTICLE_ACTION_SUCCESS':
    return {
        ...state,
        newsarticle: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_NEWS_ACTION_SUCCESS':
    return {
        ...state,
        news: [action.payload, ...state.news],
        newsarticle: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'NEWSARTICLE_REMOVE_SUCCESS':
    return {
        ...state,
        // news: state.news.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'NEWS_ACTION_RESET':
    return {
        ...state,
        newsarticle: {},
        isFetching: false,
        isError: false,
    }
    case 'NEWS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
