import React from 'react'
import { Steps } from 'antd';

class CorrespondenceSteps extends React.Component {

  onChange = current => {
    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/setup`);
    }
    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose`);
    }
    if(current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/send`);
    }
  }

  render () {
    return(
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Adressen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.correspondenceid} title="Opmaken" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={!this.props.match.params.correspondenceid} title="Versturen" />
      </Steps>
    );
  }
}

export default CorrespondenceSteps;
