export default (state = {
    isError: false,
    isFetching: false,
    customer_surveys: [],
    surveys: [],
    survey: {},
    surveyquestions: [],
    surveyquestion: {},
    addresses: [],
}, action) => {

    switch (action.type) {
    case 'SURVEYS_ACTION_FETCH':
    return {
        ...state,
        surveys: [],
        customer_surveys: [],
        isFetching: true,
        isError: false,
    }
    case 'SURVEY_ACTION_FETCH':
    return {
        ...state,
        survey: {},
        isFetching: true,
        isError: false,
    }
    case 'SURVEY_ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: true,
        isError: false,
    }
    case 'SURVEY_QUESTIONS_ACTION_FETCH':
    return {
        ...state,
        surveyquestions: [],
        isFetching: true,
        isError: false,
    }
    case 'SURVEY_QUESTION_ACTION_FETCH':
    return {
        ...state,
        surveyquestion: {},
        isFetching: true,
        isError: false,
    }
    case 'CUSTOMER_SURVEYS_ACTION_SUCCESS':
    return {
        ...state,
        customer_surveys: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SURVEYS_ACTION_SUCCESS':
    return {
        ...state,
        surveys: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SURVEY_ACTION_SUCCESS':
    return {
        ...state,
        survey: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SURVEY_ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload.surveyaddresses,
        // addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_SURVEY_ACTION_SUCCESS':
    return {
        ...state,
        // surveys: state.surveys.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        // customer_surveys: state.customer_surveys.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SURVEYQUESTIONS_ACTION_SUCCESS':
    return {
        ...state,
        surveyquestions: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SURVEYQUESTION_ACTION_SUCCESS':
    return {
        ...state,
        surveyquestion: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SURVEYADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        surveyaddresses: action.payload.surveyaddresses,
        addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
    }
    case 'ADD_SURVEY_ACTION_SUCCESS':
    return {
        ...state,
        surveys: [action.payload, ...state.surveys],
        survey: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_SURVEYQUESTION_ACTION_SUCCESS':
    return {
        ...state,
        surveyquestion: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'UPDATE_SURVEYQUESTION_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_SURVEYQUESTIONS_ACTION_SUCCESS':
    return {
        ...state,
        surveyquestions: state.surveyquestions.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SURVEYS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'RESET_SURVEY_ACTION':
    return {
        ...state,
        survey: {},
        surveyquestion: {},
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
