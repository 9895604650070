import api from '../utils/api'

export const getInformationAction = (projectid) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('get','/information/'+projectid, null, true).then((response) => {
    dispatch({
      type: 'INFORMATION_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const searchInformationAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/'+projectid+'/search', params, true).then((response) => {
    dispatch({
      type: 'INFORMATION_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const getInformationItemAction = (informationitemid) => dispatch => {

  dispatch({
    type: 'INFORMATION_ITEM_ACTION_FETCH'
  })

  return api('get','/information/item/'+informationitemid, null, true).then((response) => {
    dispatch({
      type: 'INFORMATIONITEM_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const addInformationAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })


  return api('post','/information/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'ADD_INFORMATION_ACTION_SUCCESS',
      payload: response.data.information_item
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}
export const resetInformationAction = () => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_RESET'
  })
}

export const patchInformationAction = (informationitemid, params) => dispatch => {
  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/item/'+informationitemid, params, true).then((response) => {
    dispatch({
      type: 'INFORMATIONITEM_ACTION_SUCCESS',
      payload: response.data.information_item
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const removeInformationAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/'+projectid+'/multidelete/', params, true).then((response) => {

    console.log(response);
    dispatch({
      type: 'INFORMATIONITEM_ACTION_REMOVE',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const sortInformationAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/order/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'SORT_INFORMATION_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const duplicateInformationAction = (projectid, ids) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/items/clone', {'project_id': projectid, 'ids': ids}, true).then((response) => {
    dispatch({
      type: 'DUPLICATE_INFORMATION_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const addIndicatorAction = () => (dispatch, getState) => {
  dispatch({
    type: 'ADD_INDICATOR_ACTION'
  })
}

export const removeIndicatorAction = (index) => (dispatch, getState) => {
  dispatch({
    type: 'REMOVE_INDICATOR_ACTION',
    payload: index
  })
}


/* menu */

export const getInformationMenuAction = (projectid) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('get','/information/menu/'+projectid, null, true).then((response) => {
    dispatch({
      type: 'INFORMATION_MENU_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const getInformationMenuItemAction = (informationitemid) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('get','/information/menu/item/'+informationitemid, null, true).then((response) => {
    dispatch({
      type: 'INFORMATION_MENU_ITEM_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const addInformationMenuAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/menu/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'ADD_INFORMATION_MENU_ACTION_SUCCESS',
      payload: response.data.information_item_menu_item
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const resetInformationMenuAction = () => dispatch => {

  dispatch({
    type: 'INFORMATION_MENU_ACTION_RESET'
  })
}

export const patchInformationMenuAction = (informationitemid, params) => dispatch => {
  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/menu/item/'+informationitemid, params, true).then((response) => {
    dispatch({
      type: 'INFORMATION_MENU_ITEM_ACTION_SUCCESS',
      payload: response.data.information_item_menu_item
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const removeInformationMenuAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/menu/'+projectid+'/multidelete/', params, true).then((response) => {

    console.log(response);
    dispatch({
      type: 'INFORMATION_MENU_ITEM_ACTION_REMOVE',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}

export const sortInformationMenuAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'INFORMATION_ACTION_FETCH'
  })

  return api('post','/information/menu/order/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'SORT_INFORMATION_MENU_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}
