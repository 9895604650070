import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { HaveRole } from '../../utils/RolesAndAbilities';

import {
    AuditOutlined,
    BarsOutlined,
    ContactsOutlined,
    ContainerOutlined,
    FileImageOutlined,
    FormOutlined,
    MailOutlined,
    ProfileOutlined,
    SolutionOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import { Menu } from 'antd';
import { Link } from "react-router-dom";
import { HaveAbility } from '../../utils/RolesAndAbilities';
import _ from 'underscore'

class LibrarySideMenu extends Component {
   render() {
       return (
           <Menu
               mode="inline"
               style={{ width: 300}}
               defaultOpenKeys={[this.props.activeSubMenu]}
               defaultSelectedKeys={[this.props.activeMenu]}
           >
               <Menu.Item key={'1'}>
                   <Link to={`/library/`}>
                       <BarsOutlined />
                       <span>Losse teksten</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'2'}>
                   <Link to={`/library/partners/`}>
                       <ContactsOutlined />
                       <span>Partners</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'3'}>
                   <Link to={`/library/clients/`}>
                       <SolutionOutlined />
                       <span>{this.props.selectedCustomer.package_id == 3 ? 'Aannemers' : 'Opdrachtgevers'}</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'4'}>
                   <Link to={`/library/corporate-identity/`}>
                       <FileImageOutlined />
                       <span>Briefpapier</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'9'}>
                   <Link to={`/library/envelopes/`}>
                       <MailOutlined />
                       <span>Enveloppen</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'5'}>
                   <Link to={`/library/information-texts/`}>
                       <ProfileOutlined />
                       <span>Informatie</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'17'}>
                   <Link to={`/library/contacts`}>
                       <ContactsOutlined />
                       <span>Contactpersonen</span>
                   </Link>
               </Menu.Item>

               {_.findWhere(this.props.selectedCustomer.modules, {id: 9}) ? (
               <Menu.Item key={'10'}>
                   <Link to={`/library/surveys/`}>
                       <AuditOutlined />
                       <span>Enquetes</span>
                   </Link>
               </Menu.Item>
               ) : null}

               {_.findWhere(this.props.selectedCustomer.modules, {id: 13}) ? (
               <Menu.Item key={'8'}>
                   <Link to={`/library/intake/`}>
                       <FormOutlined />
                       <span>Opname</span>
                   </Link>
               </Menu.Item>
               ) : null}

               {_.findWhere(this.props.selectedCustomer.modules, {id: 14}) ? (
               <Menu.Item key={'7'}>
                   <Link to={`/library/support-base/`}>
                       <TeamOutlined />
                       <span>Draagvlak</span>
                   </Link>
               </Menu.Item>
               ) : null}

               {_.findWhere(this.props.selectedCustomer.modules, {id: 16}) ? (
               <Menu.Item key={'11'}>
                   <Link to={`/library/choice/`}>
                       <TeamOutlined />
                       <span>Keuze</span>
                   </Link>
               </Menu.Item>
               ) : null}

               {_.findWhere(this.props.selectedCustomer.modules, {id: 15}) ? (
               <Menu.Item key={'20'}>
                   <Link to={`/library/delivery/`}>
                       <TeamOutlined />
                       <span>Opleverformulieren</span>
                   </Link>
               </Menu.Item>
               ) : null}

               {_.findWhere(this.props.selectedCustomer.modules, {id: 19}) ? (
               <Menu.Item key={'19'}>
                   <Link to={`/library/forms/`}>
                       <ContainerOutlined />
                       <span>Formulieren</span>
                   </Link>
               </Menu.Item>
               ) : null}
           </Menu>
       );
   }
}

const mapStateToProps = (state, ownProps) => {
    return {
      selectedCustomer: state.auth.selectedCustomer
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
      HaveRole : bindActionCreators(HaveRole, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibrarySideMenu);
