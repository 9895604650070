import React from 'react'
import { Steps } from 'antd';

class FormSteps extends React.Component {

  onChange = current => {

    if(current == 0) {
        this.props.history.push(`/library/delivery/${this.props.match.params.id}/detail`);
    }
    if(current == 1) {
        this.props.history.push(`/library/delivery/${this.props.match.params.id}/settings`);
    }
    // if(current == 2) {
    //     this.props.history.push(`/library/delivery/${this.props.match.params.id}/projects`);
    // }
  }

  render()
  {
    return(
      <Steps
        type="navigation"
        size="small"
        current={ this.props.current ? this.props.current : 0 }
        onChange={this.onChange} style={{display: 'block'}}
        >
        <Steps.Step style={{ padding: "10px 30px", marginRight: 10 }} title="Opmaken" />
        <Steps.Step style={{ padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.id} title="Instellingen" />
        {/*<Steps.Step style={{ padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.id || this.props.locked == false} title="Projecten" />*/}
      </Steps>
    );
  }
}

export default FormSteps;
