import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess, showError } from '../../../utils/Notifications'
import { getSupportBaseFormsByProjectAction, searchSupportBaseFormsByProjectAction, cloneSupportBaseFormsByProjectAction, deleteSupportBaseFormsByProjectAction, cloneSupportBaseFormFromCustomerToProject, getSupportBaseFormsFromLibraryAction, downloadSupportBaseFormExcelAction } from '../../../actions/supportBaseActions';
import SupportBaseSideMenu from './SupportBaseSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Menu,
  Dropdown,
  Modal,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Title, Text } = Typography;

const Option = Select.Option;

class ProjectSupportBase extends Component {

  state = {
    disabled: true,
    edit: true,
    selectedRowKeys: [],
    selectedId: null,
    modalVisible: false,
    can_write: false,
    modalDownloadExcelVisible: false,
    download_excel_email: '', //this.props.auth.user.email,
  };

  // data example
  examples = [{ id: 1, project_id: 1, name: 'Test', addresses: 1, send: 0, returned: 0, approved: 0, disapproved: 0 }];

  componentDidMount()
  {
      this.setState({
          download_excel_email: (this.props.auth && this.props.auth.user ? this.props.auth.user.email : ''),
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id).then({});

      //this.props.getSupportBaseFormsFromLibraryAction().then({});
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  cloneSelected = () => {

    this.props.cloneSupportBaseFormsByProjectAction(this.props.match.params.id, this.state.selectedRowKeys[0]).then(() => {
      this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id);
      showSuccess('Succesvol gedupliceerd');
      this.setState({selectedRowKeys: []})
    });
  }

  handleDownloadExcel()
  {
      if(!this.validateEmail(this.state.download_excel_email))
      {
          showError('Vul een geldig e-mailadres in');
      }
      else
      {
          this.props.downloadSupportBaseFormExcelAction(this.props.match.params.id, { ids: this.state.selectedRowKeys, email: this.state.download_excel_email }).then(() => {

              showSuccess('Verzoek verstuurd');

              this.setState({
                  modalDownloadExcelVisible: false,
                  selectedRowKeys: []
              });
          });
      }
  }

  validateEmail(string)
  {
      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteSupportBaseFormsByProjectAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
          this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id);
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = (e) => { };

  cloneForm = () => {
    if(this.state.selectedId) {
      this.props.cloneSupportBaseFormFromCustomerToProject(this.props.match.params.id, this.state.selectedId).then(() => {
        //this.props.onClone();
        this.setState({selectedId: null, modalVisible: false});
        this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id);
        showSuccess('Succesvol toegevoegd')
      });
    }
  }

  openModal = () => {
    this.setState({modalVisible: true});
    this.props.getSupportBaseFormsFromLibraryAction().then({});
  }

  render() {

    const columns = [{
        title: 'Omschrijving',
        dataIndex: 'name',
        render: (text, record) => (
          <span>
            <Link to={record.send ? `/projects/${record.project_id}/support-base/form/${record.id}/correspondence/status` : `/projects/${record.project_id}/support-base/form/addresses/${record.id}`}>
              {text}
            </Link>
          </span>
        )
    },
    {
        title: 'Adressen',
        dataIndex: 'addresses',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Verzonden',
        dataIndex: 'send',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Retour',
        dataIndex: 'returned',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Akkoord',
        dataIndex: 'percentage_approved',
        render: (text, record) => (
          <span>
            {text}%
          </span>
        )
    },
    /*{
        title: 'Akkoord',
        dataIndex: 'approved',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Niet akkoord',
        dataIndex: 'disapproved',
        render: (text, record) => (
          <span>
            { text }
          </span>
        )
    }*/];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={12}>
              <div>
                <Dropdown disabled={!this.state.can_write} trigger={['click']} overlay={(
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={`/projects/${this.props.match.params.id}/support-base/form/addresses/add`}>
                          Nieuw
                        </Link>
                      </Menu.Item>
                      <Menu.Item onClick={() => this.openModal()} key="2">Bibliotheek</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="new">
                    Toevoegen <DownOutlined />
                  </Button>
              </Dropdown>

                <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? (this.state.can_write ? false : true) : true } overlay={(
                    <Menu>
                      <Menu.Item disabled={this.state.selectedRowKeys.length != 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                      <Menu.Divider></Menu.Divider>
                      <Menu.Item disabled={!this.props.HaveAbility('support-base' ,'delete')} onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                      {/*<Menu.Divider></Menu.Divider>
                      <Menu.Item disabled={this.state.selectedRowKeys.length != 1} onClick={() => this.setState({modalDownloadExcelVisible: true})} key="7">Exporteren</Menu.Item>*/}
                    </Menu>
                  )}>
                  <Button disabled={!this.state.can_write} type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Col>
            <Col span={12}>
                <Input.Search
                  allowClear
                  placeholder="Zoeken..."
                  onSearch={ (value) => this.props.searchSupportBaseFormsByProjectAction(this.props.match.params.id, value) }
                  style={{ width: 200, float: 'right' }}
                />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomTable
                rowKey='id'
                size="middle"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.props.support_base_forms}
                //dataSource={this.examples}
                loading={this.props.isFetching}
              />
            </Col>
          </Row>

        </div>

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          onOk={() => this.cloneForm()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Draagvlak formulier">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer draagvlak formulier'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                {this.props.lib_forms && this.props.lib_forms.map((element) => (
                  <Select.Option value={element.id}>{element.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title='Download Excel'
          visible={this.state.modalDownloadExcelVisible}
          onCancel={() => this.setState({ modalDownloadExcelVisible: false })}
          onOk={() => this.handleDownloadExcel()}
          okText="Verstuur"
        >
            <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                <Row>
                    <Col span={24}>
                        <Form.Item label="">
                            <div style={{lineHeight: '1.5', marginBottom: '8'}}>
                                Cobee genereert op de achtergrond een ZIP bestand met de resultaten van
                                het geselecteerde draagvlak formulier. Vul hier een of meerdere
                                e-mailadressen, gescheiden met een komma, naar waar je de geëxporteerde
                                formulieren in Excel wenst te versturen:
                            </div>
                            <Input
                              placeholder={'e-mail adres'}
                              value={this.state.download_excel_email}
                              onChange={(event) => { this.setState({ download_excel_email: event.target.value }); }}
                              />
                        </Form.Item>
                    </Col>
                </Row>
              </Form>
          </Modal>

      </div>
    );
  }
}

const ProjectSupportBaseForm = Form.create({ name: 'projects_support_base_forms' })(ProjectSupportBase);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.support_base.isFetching,
    isError : state.support_base.isError,
    support_base_forms : state.support_base.forms,
    lib_forms : state.support_base.lib_forms,
    auth: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportBaseFormsByProjectAction : bindActionCreators(getSupportBaseFormsByProjectAction, dispatch),
    searchSupportBaseFormsByProjectAction : bindActionCreators(searchSupportBaseFormsByProjectAction, dispatch),
    cloneSupportBaseFormsByProjectAction : bindActionCreators(cloneSupportBaseFormsByProjectAction, dispatch),
    deleteSupportBaseFormsByProjectAction : bindActionCreators(deleteSupportBaseFormsByProjectAction, dispatch),
    cloneSupportBaseFormFromCustomerToProject : bindActionCreators(cloneSupportBaseFormFromCustomerToProject, dispatch),
    getSupportBaseFormsFromLibraryAction : bindActionCreators(getSupportBaseFormsFromLibraryAction, dispatch),
    downloadSupportBaseFormExcelAction : bindActionCreators(downloadSupportBaseFormExcelAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSupportBaseForm);
