import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid';

import {
  CheckOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  DatePicker,
  Collapse,
  Tooltip,
  Upload,
  message,
  Avatar,
  Select,
} from 'antd';
import { API_URL } from '../../../../constants/settings';
import OptionList from '../OptionList';

const ButtonGroup = Button.Group;

class FDrawingInputForm extends React.Component {

  state = {

      imageUrl: '',
      options: [],
      loading: true
  };

  constructor(props)
  {
      super(props);
  }

  componentDidMount()
  {
      this.setState({ options: (this.props.formData && this.props.formData.options) }, () => {

          this.setState({ loading: false });
      });
  }

  getOptions(type = false)
  {
      //
      var options = [];

      if(type != 'radio')
      {
          options.push(<Select.Option value="">Geen optie geselecteerd</Select.Option>);
      }

      //
      this.state.options.forEach((option) => {

          options.push(<Select.Option value={option.id}>{option.label}</Select.Option>);
      });

      //
      return options;
  }

  updateOptions = (options) =>
  {
      this.setState({ options: options });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    if(this.state.loading){ return null; }

    return (
      <Form layout={'vertical'}>

        <Form.Item label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
            onChange: (event) => { this.props.form.setFieldsValue({ label: event.target.value }); this.props.saveFieldValues(true); }
          })(
            <Input disabled={this.props.disabled} />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Tekening
            <Tooltip className="mhs" title='Upload hier de tekening. De tekening is te uploaden als JPEG, PNG of PDF bestand.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>

        {getFieldDecorator('drawing', {
          initialValue: this.props.formData && this.props.formData.drawing,
        })(
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Avatar shape="square" style={{width: 200, height: 200}} src={this.props.form.getFieldValue('drawing')} />
            <Upload disabled={this.props.disabled}
              accept="image/png,image/jpeg,application/pdf"
              showUploadList={false}
              name="drawing"
              action={`${API_URL}/form/${this.props.formId}/drawing`}
              headers={{'Authorization': `Bearer ${localStorage.authToken}`}}
              onChange={(info) => {
                if(info.file.status == 'done') {
                  // console.log(info.file.response.drawing_url);
                  this.props.form.setFieldsValue({'drawing': info.file.response.drawing_url});

                  this.props.saveFieldValues(true);
                }
              }}
            >
              <Button disabled={this.props.disabled}>Upload een tekening</Button>
            </Upload>
          </div>
        )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('drawingEnabled', {
            initialValue: this.props.formData && this.props.formData.drawingEnabled,
            valuePropName: 'checked',
            onChange: (event) => { this.props.form.setFieldsValue({ drawingEnabled: event.target.checked }); this.props.saveFieldValues(true); }
          })(
            <Checkbox disabled={this.props.disabled}>
              <span>
                Tekenen toestaan
                <Tooltip className="mhs" title='Er kan op de foto worden getekend.'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
            </Checkbox>
          )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('pinpointsEnabled', {
            initialValue: this.props.formData && this.props.formData.pinpointsEnabled,
            valuePropName: 'checked',
            onChange: (event) => { this.props.form.setFieldsValue({ pinpointsEnabled: event.target.checked }); this.props.saveFieldValues(true); }
          })(
            <Checkbox disabled={this.props.disabled}>
              <span>
                Onderdelen pinnen
                <Tooltip className="mhs" title='Op de tekening kunnen bepaalde onderdelen worden aangeduid (worden gepind).'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
            </Checkbox>
          )}
        </Form.Item>

        {this.props.form.getFieldValue('pinpointsEnabled') === true ? (
          <Form.Item label={(
            <span>
              Opties
              <Tooltip className="mhs" title='Definieer welke opties er op de tekening mogen worden aangeduid (worden gepind).'>
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          )} style={{marginBottom: 0}}>
            {getFieldDecorator('options', {
              initialValue: this.props.formData && this.props.formData.options ? this.props.formData.options : [{'id': uuidv1() ,'label' : 'Optie 1'}, {'id': uuidv1() ,'label' : 'Optie 2'}, {'id': uuidv1() ,'label' : 'Optie 3'}],
              onChange: (event) => { this.props.saveFieldValues(true); }
            })(
              <OptionList disabled={this.props.disabled} updateOptions={this.updateOptions} />
            )}
          </Form.Item>
        ) : null}

        {/*<Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld is verplicht</Checkbox>
          )}
        </Form.Item>*/}

        {/*<Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('listField', {
            initialValue: this.props.formData && this.props.formData.listField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op de meterkastlijst</Checkbox>
          )}
        </Form.Item>*/}

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('commentsEnabled', {
            initialValue: this.props.formData && this.props.formData.commentsEnabled,
            valuePropName: 'checked',
            onChange: (event) => { this.props.form.setFieldsValue({ commentsEnabled: event.target.checked }); this.props.saveFieldValues(true); }
          })(
            <Checkbox disabled={this.props.disabled}
              onChange={(event) => { if(event.target.checked){ this.props.form.setFieldsValue({'pinpointsEnabled': true}); } }}
              >
              <span>
                Opmerking toestaan
                <Tooltip className="mhs" title='Er kan een opmerking bij de tekening worden geplaatst.'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
            </Checkbox>
          )}
        </Form.Item>

      </Form>
    );
  }
}

const WrappedFDrawingInputForm = Form.create({ name: 'text_input_form' })(FDrawingInputForm);

class FDrawingInput extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        modus: this.props.modus || '',
        formData: this.props.parseData || {},
        hide_for_occupant: ((this.props.parseData && this.props.parseData.hide_for_occupant != undefined && this.props.parseData.hide_for_occupant != false)) ? true : false,
        requiredField: ((this.props.parseData && this.props.parseData.requiredField != undefined && this.props.parseData.requiredField != false)) ? true : false,
        // listField: ((this.props.parseData && this.props.parseData.listField != undefined && this.props.parseData.listField != false) || !this.props.parseData) ? true : false,
      };
    }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  toggleOption = (option) => {

      var checked = (this.state[option] == true ? false : true);

      this.setState({ [option]: checked }, () => {

          this.saveField();
      });
  };

  renderExtraButtons = () => {
    return (
      <div
          onClick={(event) => event.stopPropagation()}
          style={{ display: 'flex', marginTop: -1, marginRight: -13 }}
          >

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Element verbergen voor bewoner">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('hide_for_occupant');
                          // this.setState({ hide_for_occupant: event.target.checked }, function(){
                          //     this.saveField();
                          // });
                      }}
                      style={{ backgroundColor: (this.state.hide_for_occupant ? '#1890ff' : '#ffffff'), color: (this.state.hide_for_occupant ? '#ffffff' : '#000000') }}
                      >
                      <EyeInvisibleOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

              <Tooltip title="Vraag verplicht">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('requiredField');
                          // this.setState({ requiredField: event.target.checked }, function(){
                          //     this.saveField();
                          // });
                      }}
                      style={{ backgroundColor: (this.state.requiredField ? '#1890ff' : '#ffffff'), color: (this.state.requiredField ? '#ffffff' : '#000000') }}
                      >
                      <CheckOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

              <Tooltip title="Tonen op meterkastlijst">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('listField');
                          // this.setState({ listField: event.target.checked }, function(){
                          //     this.saveField();
                          // });
                      }}
                      style={{ backgroundColor: (this.state.listField ? '#1890ff' : '#ffffff'), color: (this.state.listField ? '#ffffff' : '#000000') }}
                      disabled={true}
                      >
                      <ProfileOutlined
                        onClick={(event) => { /*event.stopPropagation();*/ }}
                        style={{ color: '#bbbbbb' }} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Element verwijderen">
                  <Popconfirm
                    icon={(<QuestionCircleOutlined />)}
                    placement="bottom"
                    onCancel={(event) => event.stopPropagation()}
                    size="small"
                    okType="danger"
                    title={"Element verwijderen"}
                    onConfirm={() => this.removeElement()}
                    okText="Ja"
                    cancelText="Nee"
                    disabled={this.props.disabled}
                  >
                      <Button disabled={this.props.disabled}
                          size="small">
                          <DeleteOutlined
                            onClick={event => {
                                /*event.stopPropagation();*/
                            }} />
                      </Button>
                  </Popconfirm>
              </Tooltip>

              <Tooltip title="Element dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {
                        /*event.stopPropagation();*/
                        this.duplicateElement(this.props.id);
                      }}
                      >
                      <PlusSquareOutlined style={{marginLeft: 0}} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

      </div>
    );
  };

  saveFieldValues = (silent = false) => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          values.hide_for_occupant = this.state.hide_for_occupant;
          values.requiredField = this.state.requiredField;
          values.listField = this.state.listField;

          if(silent)
          {
              this.setState({formData: values});
          }
          else
          {
              this.setState({formData: values, openKeys: []});
          }

          this.props.onSaveData(this.props.id, values, silent);
        }
      })
    }
  }

  saveField = () => {
      var values = this.state.formData;
      values.hide_for_occupant = this.state.hide_for_occupant;
      values.requiredField = this.state.requiredField;
      values.listField = this.state.listField;

      this.setState({formData: values}); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
            <Collapse.Panel
              key={this.props.id}
              header={(
                  <div
                      style={{
                          display: 'inline-block',
                          height: 20,
                          width: 'auto',
                          // maxWidth: '67%',
                          // whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                      }}>
                      <b>Tekening</b> - {this.state.formData && this.state.formData.label || 'label'}
                  </div>
              )}
              extra={this.renderExtraButtons()}
              >
            <div style={{padding: 15}}>

              <WrappedFDrawingInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} {...this.props} saveFieldValues={this.saveFieldValues} disabled={this.props.disabled} />
              <Button disabled={this.props.disabled} onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Foto
        </div>
      );
    }
  }
}

export default FDrawingInput;
