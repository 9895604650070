import React, { Component } from 'react';
//import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import  AddSurveyForm from './Form/AddSurvey';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { getSurveysAction, addSurveyAction, removeSurveysAction, searchSurveysAction, getCustomerSurveysAction, duplicateSurveyAction, exportSurveyAction } from '../../../../actions/surveysActions';
// import './Survey.css';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Button,
    Checkbox,
    Dropdown,
    Menu,
    Input,
    Row,
    Col,
    Upload,
    message,
    Drawer,
    Transfer,
    Select,
} from 'antd';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import { API_URL } from '../../../../constants/settings';

const columns = [{
    title: 'Titel',
    dataIndex: 'name',
    render: (text, record) => (
        <span>
          <Link to={`/projects/${record.project_id}/modules/enquete/${record.id}/detail`}>
            {text}
          </Link>
        </span>
    )
}, {
    title: 'Aantal vragen',
    dataIndex: 'nr_of_questions',
    // render: (text, record) => (
    //     <span>
    //         {record.surveyquestions && record.surveyquestions.length}
    //     </span>
    // )
}, {
    title: 'Openbaar',
    dataIndex: 'is_public',
    render: (text, record) => (
        <span>
            {text ? 'Ja' : 'Nee'}
        </span>
    )
}];

function onChange(pagination, sorter) {
  // console.log('params', pagination, sorter);
}

const Search = Input.Search;

const confirm = Modal.confirm;

// function onChangeCheckbox(e) {
//   // console.log(`checked = ${e.target.checked}`);
// }

const Dragger = Upload.Dragger;

class ProjectSurveys extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        modalVisible: false,
        downloadSurvey: false,
    };

    deleteSurveys = () => {
      confirm({
        title: 'Wil je deze items verwijderen?',
        content: 'Als je op JA klikt, worden de items verwijderd.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {
                this.props.removeSurveysAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {

                    resolve();

                    this.props.getSurveysAction(this.props.match.params.id);

                    showSuccess('Succesvol verwijderd');

                    this.setState({selectedRowKeys:[]});
                });
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
          if (!err) {
              this.props.addSurveyAction({'project_id': this.props.match.params.id, ...values}).then(() => {
                  this.setState({manualVisible: false});
                  this.props.form.resetFields();
                  showSuccess('Succesvol opgeslagen');
              });
          }
        });
    };

    // showDrawer = () => {
    //     this.setState({
    //       visible: true,
    //     });
    // };

    // openManual = () => {
    //     this.setState({
    //       openManual: true,
    //     });
    // };

    // onClose = () => {
    //     this.setState({
    //       visible: false,
    //     });
    // };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    componentDidMount() {

        this.props.getSurveysAction(this.props.match.params.id);

        this.props.getCustomerSurveysAction();
    }

    componentWillUnmount() {
    }

    // handleChange = (nextTargetKeys, direction, moveKeys) => {
    //     this.setState({ targetKeys: nextTargetKeys });
    // };
    //
    // handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    //     this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
    // };

    onSearch = (value) => {
      this.props.searchSurveysAction(this.props.match.params.id, {'search': value});
    }

    cloneSurvey = (source = false) => {
      var selected_id = false;

      if(source == 'library')
      {
          selected_id = this.state.selectedId;
      }
      else if(this.state.selectedRowKeys[0])
      {
          selected_id = this.state.selectedRowKeys[0]
      }

      if(selected_id)
      {
        this.props.duplicateSurveyAction(selected_id, 'project', this.props.match.params.id).then(() => {

          this.setState({selectedId: null, modalVisible: false});
          this.setState({ selectedRowKeys: [] });

          this.props.getSurveysAction(this.props.match.params.id);

          showSuccess('Succesvol toegevoegd');
        });
      }
    }

    downloadSurveyAsZip()
    {
        // modal
        this.props.exportSurveyAction(this.state.selectedRowKeys[0], { 'email': this.state.emailSurveyTo }).then(() => {

            showSuccess('Succesvol verzonden');

            this.setState({ selectedRowKeys: [] });
        });
    }

    /*exportSurvey = () => {
        // this.props.exportSurveyAction(this.state.selectedRowKeys[0]).then(() => {
        //     this.setState({selectedRowKeys: []});
        // });

        window.open(API_URL+'/survey/'+this.state.selectedRowKeys[0]+'/export?token='+ localStorage.getItem('authToken'), '_blank');

        this.setState({selectedRowKeys: []});
    }*/

    render() {
        const SubMenu = Menu.SubMenu;

        const { targetKeys, disabled } = this.state;
        const { getFieldDecorator } = this.props.form;
        // const rowSelection = {
        //     onChange: this.onSelectChange,
        //     hideDefaultSelections: true,
        //     onSelection: this.onSelection,
        // };

        const { selectedRowKeys } = this.state;

        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          hideDefaultSelections: true,
          onSelection: this.onSelection,
        };

        return (
            <div>
                <Row className="mbm">
                    <Col span={12} >

                        <Dropdown disabled={!this.props.HaveAbility('survey' ,'write')} trigger={['click']} overlay={(
                            <Menu>
                                <Menu.Item key="1">
                                    <Link to={`enquete/add`}>
                                        Nieuw
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Link onClick={() => { this.setState({ modalVisible: true }); }}>
                                        Bibliotheek
                                    </Link>
                                </Menu.Item>
                            </Menu>
                            )}>
                            <Button type="new">
                                Toevoegen <DownOutlined />
                            </Button>
                        </Dropdown>


                        <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('survey', 'write') && (this.state.selectedRowKeys.length > 0) ? false : true }  overlay={(
                            <Menu>

                              {/*<Menu.Item disabled={this.state.selectedRowKeys.length != 1 ? true : false} onClick={() => { this.setState({downloadSurvey: true}); }} key="8">Exporteren</Menu.Item>*/}
                              <Menu.Item disabled={this.state.selectedRowKeys.length != 1 ? true : false || !this.props.HaveAbility('survey' ,'write')} onClick={() => { this.cloneSurvey(); }} key="9">Dupliceren</Menu.Item>
                              <Menu.Item disabled={!this.props.HaveAbility('survey' ,'delete')} style={{ color: this.props.HaveAbility('survey', 'delete') ? '#f5222d' : '' }} onClick={this.deleteSurveys}>Verwijderen</Menu.Item>
                            </Menu>
                        )}>
                            <Button type="secondary">
                                Acties <DownOutlined />
                            </Button>
                        </Dropdown>

                    </Col>
                    <Col span={12}>
                        <Search
                          placeholder="Zoeken..."
                          onSearch={value => this.onSearch(value)}
                          style={{ width: 200, float: 'right' }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomTable
                            rowKey='id'
                            size="middle"
                            rowSelection={rowSelection}
                            onChange={onChange}
                            columns={columns}
                            dataSource={this.props.isFetching ? [] : this.props.surveys}
                            loading={this.props.isFetching}
                        />
                    </Col>
                </Row>

                <Modal
                  title='Bibliotheek'
                  visible={this.state.modalVisible}
                  onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
                  onOk={() => this.cloneSurvey('library')}
                  okText="Toevoegen"
                >
                  <Form layout={'vertical'}>
                    <Form.Item required hasFeedback label="Enquete">
                      <Select showSearch value={this.state.selectedId} placeholder={'Selecteer enquete'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId: selectedId})}>
                        {this.props.customer_surveys && this.props.customer_surveys.map((element) => (
                          <Select.Option value={element.id}>{element.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </Modal>

                <Modal
                  title="Exporteer resultaten"
                  visible={this.state.downloadSurvey}
                  onOk={() => {
                      this.downloadSurveyAsZip();
                      this.setState({ downloadSurvey: false });
                  }}
                  onCancel={() => this.setState({downloadSurvey: false})}
                  okText={'Exporteren'}
                >
                  <Form layout={'vertical'}>
                    <p>
                    Cobee genereert op de achtergrond een ZIP bestand met de resultaten
      van de geselecteerde adressen. Vul hier een of meerdere e-mailadressen naar
      waar je de geëxporteerde enquete resultaten wenst te versturen.
                    </p>
                    <Form.Item label="E-mailadres(sen)">
                      {/*<Input onChange={(e) => this.setState({emailSurveyTo: e.target.value})} />*/}
                      <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({emailSurveyTo: value.join(',')}); }}></Select>
                    </Form.Item>
                  </Form>
                </Modal>

            </div>
        );
    }
}

const ProjectSurveyForm = Form.create({ name: 'add_survey_form' })(ProjectSurveys);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.survey.isFetching,
        isError : state.survey.isError,
        surveys : state.survey.surveys,
        customer_surveys : state.survey.customer_surveys,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSurveysAction : bindActionCreators(getSurveysAction, dispatch),
        removeSurveysAction : bindActionCreators(removeSurveysAction, dispatch),
        searchSurveysAction : bindActionCreators(searchSurveysAction, dispatch),
        getCustomerSurveysAction : bindActionCreators(getCustomerSurveysAction, dispatch),
        duplicateSurveyAction : bindActionCreators(duplicateSurveyAction, dispatch),
        exportSurveyAction : bindActionCreators(exportSurveyAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSurveyForm);
