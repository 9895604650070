import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Select } from 'antd';
import { bindActionCreators } from 'redux';
//import { getPartnersAction, clonePartnerAction } from '../../../../../actions/globalpartnersActions'
import { getLibrariesContactsAction, cloneLibraryContactsAction  } from '../../../../../actions/contactsActions'
import { connect } from 'react-redux';

class CloneContact extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      selectedId: null,
    };
  }

  cloneInformation = () => {
    if(this.state.selectedId) {
      this.props.cloneLibraryContactsAction(this.props.match.params.id, this.state.selectedId).then(() => {
        this.props.onClone();
        this.setState({selectedId: null, modalVisible: false});
      });
    }
  }

  openModal = () => {
    this.setState({modalVisible: true});
    this.props.getLibrariesContactsAction();
  }

  render () {
    return (
      <span>
      {this.props.plain ? (
        <span style={{display:'block'}}onClick={this.openModal}>
          {this.props.buttonLabel ? this.props.buttonLabel : 'Bibliotheek'}
        </span>
      ) : (
        <Button onClick={this.openModal}>Selecteer uit bibliotheek</Button>
      )}

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          onOk={() => this.cloneInformation()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Contactpersonen">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer een partner'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                { this.props.contacts.map((element) => (
                  <Select.Option value={element.id}>{element.first_name} {element.affix ? element.affix+ ' ' : ''}{element.last_name}</Select.Option>
                )) }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contacts: state.contact.libraryContacts,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    //getPartnersAction:  bindActionCreators(getPartnersAction, dispatch),
    //clonePartnerAction:  bindActionCreators(clonePartnerAction, dispatch),
    cloneLibraryContactsAction:  bindActionCreators(cloneLibraryContactsAction, dispatch),
    getLibrariesContactsAction:  bindActionCreators(getLibrariesContactsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneContact);
