import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import ChoiceSideMenu from '../ChoiceSideMenu';
import ChoiceFormSteps from './ChoiceFormSteps'
import { DownOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
  Menu,
  Alert,
  Upload,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';
// import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { getAddressesAction, getChoiceFormStatusAction, searchChoiceFormStatusAction, uploadFormAction } from '../../../../actions/choiceActions';
// import { shareAddressesAction, searchAddressesAction } from '../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import { API_URL } from '../../../../constants/settings';

class ChoiceFormStatus extends React.Component {

  state = {
    // current: 0,
    // smsModule: false,
    // letterVisible: false,
    // letterMailVisible: false,
    // emailVisible: false,
    // smsVisible: false,
    // pushVisible: false,

    expandedRowKeys: [],
    expandButton: true,

    // description: '',

    selectedLetterIds: [],
    //selectedLetterEmailIds: [],
    //selectedEmailIds: [],
    // selectedSmsIds: [],
    // selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,
    // expandedRowKeys: [],

    // sendDate: null,
    // sendTime: null,

    can_write: false,

    filteredInfo: null,

    modalUploadFormVisible: false,

  }

  showUploadForm(address_id, id)
  {
      this.setState({
          modalUploadFormVisible: true,
          selected_address_id: address_id,
          selected_id: id
      });
  }

  handleUploadForm()
  {
      var files = [];

      files['submitted_form'] = this.state.filelist[0];

      this.props.uploadFormAction(this.state.selected_id, this.state.has_additional_work, (this.state.is_paid ? 'paid' : 'not-paid'), files).then(() => {

          this.props.getChoiceFormStatusAction(this.props.match.params.id, this.props.match.params.form_id, false).then(() => {

              this.setState({ addresses: this.props.addresses });

              showSuccess('Upload succesvol');

              this.setState({
                  modalUploadFormVisible: false,
                  modalUploadSignedFormVisible: false,
                  modalAdditionalWorkVisible: false,
                  modalIsPaidVisible: false,
                  selectedRowKeys: [],
                  has_additional_work: false,
                  modal_has_additional_work: false,
                  modal_is_paid: false,
                  is_paid: false,
                  isFetching: false,
                  filelist: [],
              });
          });
      });
  }

  // example data
  examples = [{id: 1, full_address: 'Straat 1', groups: '', occupants: [{id: 1, address_id: 1, email_address: 'email@adres.nl', full_name: 'Voornaam Achternaam', description: 'Bewoner' }]}];

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write')
      });

      this.setState({ isFetching: true });

    // this.props.getAddressesAction(this.props.match.params.id, this.props.match.params.form_id ? this.props.match.params.form_id : 'new').then(() => {

      this.props.getGroupsAction({'projectId': this.props.match.params.id});

      /*this.setState({currentDataSource: this.props.addresses},() => {
        var expandIds = [];
        this.state.currentDataSource.map((v) => {
          if(v.occupants) {
            expandIds.push('address-'+v.id)
          }
        });
        this.setState({expandedRowKeys: expandIds, expandButton: true});
      });/**/
    // });

    /*if(this.props.match.params.form_id && this.props.match.params.form_id != 'add')
    {*/
      this.props.getChoiceFormStatusAction(this.props.match.params.id, this.props.match.params.form_id, false).then(() => {

        this.setState({ isFetching: false });
      });
    /*}
    else{ this.setState({ isFetching: false }); }*/

      this.setState({ isFetching: false });
  }

  patchInformationAddresses() {}

  saveAndContinue()
  {

  }

  onSearch(value) {
    this.props.searchChoiceFormStatusAction(this.props.match.params.id, this.props.match.params.form_id, {'search': value});
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  render () {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: <div>Adressen</div>,
        dataIndex: 'full_address',
        key: 'street',
        render: (text, record) => {

          var valueId = record.id;
          //var disabledCheck = false;

          if(!record.address_id && record.email != ''){
            //disabledCheck = true;
          }

          if(record.email_address == undefined) {
            //disabledCheck = true;
          }

          if(record.address_id) {
            valueId = 'occ-'+record.id
          } else {
            //disabledCheck = true;
          }

          return record.full_address ? (
                <span>{record.full_address}</span>
              ) : (
                <span><UserOutlined size={30} style={{fontSize: 15}} /> {record.full_name} <Tag >{record.description ? record.description : 'Bewoner'}</Tag></span>
          );
        }
    },
    {
      title: 'Postcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
      width: 100
    },
    {
      title: 'Plaats',
      dataIndex: 'city',
      key: 'city',
      width: 200
    },
    {
      title: 'Groep',
      dataIndex: 'groups',
      filters: this.props.groups && this.props.groups.map((elem) => {
        return {
          text: elem.name,
          value: elem.name,
        }
      }),
      filterMultiple: true,
      // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
      onFilter: (value, record) => this.groupsFilter(value, record),
      filteredValue: filteredInfo.groups || null,
      render: (text, record) =>
      record.groups && record.groups.map((v) => (
        <Tag color={v.color}>{v.name}</Tag>
      ))
    },
    {
        title: 'Ingevuld formulier',
        dataIndex: 'submitted_form',
        render: (text, record) => {

          var record_id = record.form ? record.form.id : record.id;
          var address_id = record.form ? record.form.address_id : record.address_id;

          var has_form = (text || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form);

          return (
            <span>
            { record.form || record.choice_form_id ?
                has_form ?
                <Dropdown trigger={['click']} overlay={(
                    <Menu>
                      <Menu.Item style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) || (record.form && record.form.submitted_form) ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/choice/address/' + record_id  + '/form/view?token=' + localStorage.getItem('authToken')}>Bekijk formulier</a></Menu.Item>
                      <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) || (record.form && record.form.submitted_form) ? 'list-item' : 'none'}}></Menu.Divider>
                      <Menu.Item style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) || (record.form && record.form.submitted_form) ? 'list-item' : 'none'}}><a target="_blank" href={API_URL + '/choice/address/' + record_id  + '/form/download?token=' + localStorage.getItem('authToken')}>Download formulier</a></Menu.Item>
                      <Menu.Divider style={{display: record.filled_in_form || (record.form && record.form.filled_in_form) || (record.form && record.form.submitted_form) ? 'list-item' : 'none'}}></Menu.Divider>
                      <Menu.Item disabled={!this.state.can_write} onClick={() => { this.showUploadForm(address_id, record_id); }}>Formulier updaten</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
                :
                <Button disabled={!this.state.can_write} onClick={() => { this.showUploadForm(address_id, record_id); }} style={this.state.can_write ? {color: 'red', borderColor: 'red'} : {}} >Toevoegen</Button>
              :
              null }
            </span>
          );
        },
        filterMultiple: false,
        filters: [
          {
            text: 'Ingevuld',
            value: 'yes',
        },
        {
          text: 'Niet ingevuld',
          value: 'no',
        }],
        // onFilter: (value, record) => {
        //     if(record.children && record.children.length > 1)
        //     {
        //         var found = false;
        //
        //         record.children.map((child) => {
        //
        //             if((child.submitted_form || (child.form && (child.form.submitted_form || child.form.form_submitted || (child.form.form_status != null))) || child.filled_in_form))
        //             {
        //                 found = true;
        //             }
        //         })
        //
        //         return found;
        //     }
        //     else
        //     {
        //         return ((record.submitted_form || (record.form && (record.form.submitted_form || record.form.form_submitted || (record.form.form_status != null))) || record.filled_in_form) ? true : false);
        //     }
        // }
    }];

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

        <div style={{padding: 24, width: '100%'}}>

            {/* buttons */}
            <Row className="mbm">
              <Col span={12}>
                <div>
                  {/*<Button disabled={!this.state.can_write} onClick={() => { this.saveAndContinue(); }}>Maak correspondentie</Button>*/}
                  {/*<Link to={"/projects/" + this.props.match.params.id + "/correspondence/add/choice/" + this.props.match.params.form_id + "/" + encodeURIComponent(this.props.choice_form ? this.props.choice_form.description : '')}
                    disabled={!this.props.HaveAbility('correspondece' ,'write')}
                    >
                    <Button disabled={!this.props.HaveAbility('correspondece' ,'write')}>Maak correspondentie</Button>
                  </Link>
                  &nbsp;*/}
                  <Link to={`/projects/${this.props.match.params.id}/choice/forms`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <ChoiceFormSteps current={3} {...this.props}/>
                </Card>
              </Col>
            </Row>

              <div>
                <CustomTable
                  rowKey={(record) => {
                    if(record.address_id) {
                      return record.address_id + "-" + record.id;
                    } else {
                      return 'address-' + record.id;
                    }
                  }}
                  size="middle"
                  columns={columns}
                  dataSource={this.props.addresses}
                  loading={this.state.isFetching}
                  // onChange={(pagination, filters, sorter, extra) => {
                  //   this.setState({currentDataSource: extra.currentDataSource})
                  // }}
                  onChange={(pagination, filters, sorter, extra) => {
                    this.setState({currentDataSource: extra.currentDataSource});
                    this.handleTableChange(pagination, filters, sorter);
                  }}
                  />
              </div>

        </div>


        <Modal
          title='Upload formulier'
          visible={this.state.modalUploadFormVisible}
          onCancel={() => this.setState({ modalUploadFormVisible: false })}
          onOk={() => this.handleUploadForm()}
          okText="Opslaan"
        >
            <Form className={'ant-form--custom'} onSubmit={() => { this.handleUploadForm(); }} layout={'vertical'} colon={true}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item multiple={false} label="Formulier">
                      <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="Wanneer een nieuw formulier wordt opgegeven, wordt het bestaande formulier hiermee overschreven." />

                      <Upload
                        accept="application/pdf"
                        showUploadList={true}
                        fileList={this.state.filelist}
                        defaultFileList={this.state.filelist}
                        beforeUpload={(file, filelist) => {
                          if(file.size < 8000000) {
                            if (file.type !== 'application/pdf') {
                                this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                            }
                            else if(file.size <= 0 && this.state.modalAddAttachmentVisible)
                            {
                                this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                            }
                            else if(file.size > 0)
                            {
                                filelist = [];
                                filelist.push(file);

                                this.setState({filelist: filelist});

                                this.setState({ errorDocumentFile: false, errorDocumentSizeFile: false });
                            }
                          } else {
                              this.setState({ errorDocumentFile: false, errorDocumentSizeFile: true });
                          }

                          return false;
                        }} onRemove={() =>{
                          this.setState({filelist: null});
                        }}>
                        <Button >
                          <UploadOutlined /> Selecteer een bestand
                        </Button>
                      </Upload>

                      {this.state.errorDocumentFile ? (<Alert message="Upload een pdf bestand" type="error" showIcon />) : null}
                      {this.state.errorDocumentSizeFile ? (<Alert message="Bestand mag niet groter zijn dan 8MB" type="error" showIcon />) : null}

                    </Form.Item>
                  </Col>
              </Row>
            </Form>
          </Modal>

    </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const ChoiceFormStatusForm = Form.create({ name: 'choice_form' })(ChoiceFormStatus);

const mapStateToProps = (state, ownProps) => {
    return {
        // isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        choice_form : state.choice.choice_form,
        addresses : state.choice.addresses,
        groups : state.group.groups,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChoiceFormStatusAction : bindActionCreators(getChoiceFormStatusAction, dispatch),
        searchChoiceFormStatusAction : bindActionCreators(searchChoiceFormStatusAction, dispatch),
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        // shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
        // searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
        uploadFormAction : bindActionCreators(uploadFormAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormStatusForm);
