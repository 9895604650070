import React from 'react';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import FormSteps from './FormSteps';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Select,
  Modal,
  Switch,
  Tag,
  Divider,
  Tooltip,
  Alert,
  InputNumber,
} from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../utils/Notifications';
import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormAction, addFormAction, updateFormAction, resetFormAction, downloadFormEntryAction, getFormMenuAction } from '../../../../actions/formActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';
import { API_URL } from '../../../../constants/settings';
import FormSideMenu from '../FormSideMenu';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';

class FormSettings extends React.Component {

  form_data = null;

  constructor(props) {
    super(props);

    this.state = {

        loading: true,
        formData: null,
        can_write: false,

        locked: false,

        nr_of_signatures: 1,

        status_message: false,
        status_email_subject: 'Formulier ontvangen',
        status_email_message: 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
<br />\
Wij hebben het door u ingevulde formulier in goede orde ontvangen.<br />\
<br />\
Het formulier kunt u terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
<br />\
Heeft u vragen of opmerkingen, neem contact met ons op via <placeholder id="current" class="cobee-tag">project: telefoonnummer</placeholder> of <placeholder id="current" class="cobee-tag">project: e-mailadres</placeholder>.<br />\
<br />\
Bekijk het volledige formulier hier: <placeholder>Link naar formulier (PDF)</placeholder><br />\
<br />\
Met vriendelijke groet,<br />\
<placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
    };
  }

  componentDidMount()
  {
      this.setState({
          loading: true,
          can_write: this.props.HaveAbility('form' ,'write'),
          allow_form_to_be_emailed: false
      });

      this.props.resetFormAction();

      this.props.getFormMenuAction(this.props.match.params.id).then(() => {});

      if(this.props.match.params.form_id)
      {
          this.props.getFormAction(this.props.match.params.form_id).then(() => {

              this.setState({ locked: this.props.form_details.locked });

              this.setState({ nr_of_signatures: this.props.form_details.nr_of_signatures ? this.props.form_details.nr_of_signatures : 1 });

              this.setState({ require_to_sign_form: this.props.form_details.require_to_sign_form ? true : false });
              this.setState({ signature_not_required: this.props.form_details.signature_not_required ? true : false });
              this.setState({ require_to_sign_form_legally: this.props.form_details.require_to_sign_form_legally ? true : false });
              this.setState({ allow_form_to_be_emailed: this.props.form_details.allow_form_to_be_emailed ? true : false });

              try
              {
                  var signature_description = JSON.parse(this.props.form_details.signature_description);

                  for(let nr = 1; nr <= this.props.form_details.nr_of_signatures; nr++)
                  {
                      this.setState({ ['signature_'+nr+'_description']: signature_description[nr] });
                  }
              }
              catch(e){}

              if(this.props.form_details.status_message){ this.setState({ status_message: this.props.form_details.status_message }); }
              if(this.props.form_details.status_email_subject){ this.setState({ status_email_subject: this.props.form_details.status_email_subject }); }
              if(this.props.form_details.status_email_message){ this.setState({ status_email_message: this.props.form_details.status_email_message }); }

              if(this.props.form_details.send_filled_in_form_to){ this.setState({ send_filled_in_form_to: this.props.form_details.send_filled_in_form_to }); }

              this.setState({ loading: false });
          });
      }
      else
      {
          this.setState({ loading: false });
      }
  }

  getMenuItemName(id)
  {
      var name = id;

      this.props.menus.map((menu) => {
          if(menu.id == id){
              name = menu.name;
          }
      })

      return name;
  }

  handleSubmit = (e, values, redirect = false) => {

    this.saveForm(redirect);
  }

  saveForm(redirect = true, preview = false) {

      this.props.form.validateFields((err, values) => {

        if (!err){

          values.hide_for_address = values.hide_for_address ? false : true;
          values.address_required = values.address_required ? false : true;

          if(Array.isArray(values.send_filled_in_form_to))
          {
              values.send_filled_in_form_to = values.send_filled_in_form_to.join(',');
          }

          values.status_message = this.state.status_message;

          if(this.props.match.params.form_id) {

            this.props.updateFormAction(this.props.match.params.form_id, values).then(() => {
              // if(redirect === true)
              // {
                this.props.history.push('/projects/' + this.props.match.params.id + '/form/' + this.props.form_details.id + '/compose');
              // }
            });
          }
          else {
            this.props.addFormAction(this.props.match.params.id, values).then((form) => {
              this.props.history.push('/projects/' + this.props.match.params.id + '/form/' + form.id /*this.props.form_details.id*/ + '/compose');
            });
          }

          showSuccess();

          if(preview){
              this.props.previewFormAction(this.props.match.params.form_id);
          }
        }
      });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <FormSideMenu id={this.props.match.params.id} activeMenu="1"/>

        <div style={{padding: 24, width: '100%'}}>

          <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
            {/* buttons */}
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.state.can_write} type="new" onClick={() => this.handleSubmit(null, null, true)}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/forms`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            { this.props.form_details.locked ?
            <Alert style={{ marginBottom: 24 }} showIcon type="info" message="Let op" description="Dit formulier is vergrendeld en kan daarom niet worden aangepast."
              />
            : null }

            {/* steps */}
            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <FormSteps current={0} { ...this.props }/>
                </Card>
              </Col>
            </Row>

            { this.state.loading ? null :
            <Card className="mbm">

              <Row gutter={24}>

                <Col span={12}>
                  <Form.Item hasFeedback className="mbn" required label="Naam">
                    {getFieldDecorator('name', {
                      initialValue: this.props.form_details ? this.props.form_details.name : '',
                      rules: [{ required: true, message: 'Vul een naam in' }],
                    })(
                      <Input disabled={this.state.locked} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item hasFeedback className="mbn" label="Categorie">
                        {getFieldDecorator('form_menu_item_id', {
                            placeholder: 'Geen categorie opgegeven',
                            initialValue: this.props.form_details && parseInt(this.props.form_details.form_menu_item_id) >= 1 ? ''+this.props.form_details.form_menu_item_id : 'null',
                            validateTrigger: ["onChange"],
                            rules: [{ message: 'Geef een categorie op'}],
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Geef een categorie op"
                          >
                          <Select.Option key={'null'}>Geen categorie opgegeven</Select.Option>
                          {this.props.menus.map((menu) => (
                              <Select.Option value={String(menu.id)}>{menu.name}</Select.Option>
                          ))}
                          </Select>
                        )}
                    </Form.Item>
                </Col>

              </Row>

              <Divider />

              <Row gutter={24}>

                <Col span={12}>
                  <Form.Item className="mbn" label="Is openbaar">
                    {getFieldDecorator('is_public', {
                        initialValue: this.props.form_details && this.props.form_details.is_public ? true : false,
                        valuePropName: 'checked'
                    })(
                      <Switch disabled={this.state.locked} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item className="mbn" label="Mag meerdere keren worden ingevuld">
                      {getFieldDecorator('allow_multiple_entries', {
                          initialValue: this.props.form_details && this.props.form_details.allow_multiple_entries ? true : false,
                          valuePropName: 'checked'
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item className="mbn" label={(
                    <span>
                      In te vullen zonder koppeling met adres
                      <Tooltip className="mhs" title='Als deze optie aan staat kunnen beheerders het formulier ook invullen zonder dat deze aan een adres is gekoppeld. In dat geval kan een beheerder een omschrijving opgeven bij het formulier.'>
                        <InfoCircleTwoTone />
                      </Tooltip>
                    </span>
                  )}>
                    {getFieldDecorator('address_required', {
                        initialValue: this.props.form_details && this.props.form_details.address_required ? false : true,
                        valuePropName: 'checked'
                    })(
                      <Switch disabled={this.state.locked} />
                    )}
                  </Form.Item>
                </Col>

                { !this.props.project.is_public ?
                <Col span={12}>
                    <Form.Item className="mbn" label="Bewoner mag formulier invullen">
                      {getFieldDecorator('allow_address_to_submit_entry', {
                          initialValue: this.props.form_details && this.props.form_details.allow_address_to_submit_entry ? true : false,
                          valuePropName: 'checked'
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col> : null}

                { !this.props.project.is_public ?
                <Col span={24}>
                    <Form.Item className="mbn" label="Tonen in dossier voor bewoner">
                      {getFieldDecorator('hide_for_address', {
                          initialValue: this.props.form_details && this.props.form_details.hide_for_address == false ? true : false,
                          valuePropName: 'checked'
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col> : null }

                <Col span={this.state.require_to_sign_form ? 12 : 24}>
                    <Form.Item className="mbn" label="Digitaal ondertekenen">
                      {getFieldDecorator('require_to_sign_form', {
                          initialValue: this.props.form_details && this.props.form_details.require_to_sign_form ? true : false,
                          valuePropName: 'checked',
                          onChange: (checked) => { this.setState({ require_to_sign_form: checked }) }
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col>

                { this.state.require_to_sign_form ?
                <Col span={12}>
                  <Form.Item hasFeedback className="mbn" label="Hoeveel handtekeningen">
                    {getFieldDecorator('nr_of_signatures', {
                      initialValue: this.props.form_details && this.props.form_details.nr_of_signatures ? this.props.form_details.nr_of_signatures : '1',
                      onChange: (value) => { this.setState({ nr_of_signatures: value }); },
                      rules: [{ required: true, message: 'Vul een aantal in. Dit kan later in de app nog worden aangepast per formulier.' }],
                    })(
                      <InputNumber disabled={this.state.locked} />
                    )}
                  </Form.Item>
                </Col> : null }

                { this.state.require_to_sign_form && this.state.nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.nr_of_signatures))].map((i, s) => {

                var nr = s + 1;

                return <Col span={12}>
                  <Form.Item hasFeedback className="mbn" label={"Handtekening "+nr+" omschrijving"}>
                    {getFieldDecorator('signature_'+nr+'_description', {
                      initialValue: this.state['signature_'+nr+'_description'],
                      onChange: (value) => { this.setState({ ['signature_'+nr+'_description']: value }); },
                      rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app nog worden aangepast per formulier.' }],
                    })(
                      <Input disabled={this.state.locked} />
                    )}
                  </Form.Item>
                </Col>
                })}</Col> : null }

                { this.state.require_to_sign_form ?
                <Col span={24}>
                    <Form.Item className="mbn" label={(
                      <span>
                        Handtekening niet verplicht
                        <Tooltip className="mhs" title='Als deze optie aan staat is het niet verplicht om een handtekening te zetten om het formulier af te ronden.'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      {getFieldDecorator('signature_not_required', {
                          initialValue: this.props.form_details && this.props.form_details.signature_not_required ? true : false,
                          valuePropName: 'checked',
                          onChange: (checked) => { this.setState({ signature_not_required: checked }) }
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col> : null }

                { this.state.require_to_sign_form && !this.state.signature_not_required ?
                <Col span={12}>
                    <Form.Item className="mbn" label={(
                      <span>
                        Rechtsgeldig ondertekenen
                        <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per formulier is dit €1,00'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      {getFieldDecorator('require_to_sign_form_legally', {
                          initialValue: this.props.form_details && this.props.form_details.require_to_sign_form_legally ? true : false,
                          valuePropName: 'checked',
                          onChange: (checked) => { this.setState({ require_to_sign_form_legally: checked }) }
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col> : null }

                { this.state.require_to_sign_form && !this.state.signature_not_required && this.state.require_to_sign_form_legally ?
                <Col span={12}>
                    <Form.Item className="mbn" label={(
                      <span>
                        SMS authenticatie gebruiken
                        <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per verstuurde SMS is dit €0,25'>
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                    )}>
                      {getFieldDecorator('use_sms_authentication_for_signing', {
                          initialValue: this.props.form_details && this.props.form_details.use_sms_authentication_for_signing ? true : false,
                          valuePropName: 'checked'
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col> : null }
            </Row>

            <Row>
                <Col span={12}>
                    <Form.Item className="mbn" label="Formulier mag per e-mail doorgestuurd worden">
                      {getFieldDecorator('allow_form_to_be_emailed', {
                          initialValue: this.props.form_details && this.props.form_details.allow_form_to_be_emailed ? true : false,
                          valuePropName: 'checked',
                          onChange: (checked) => {
                              this.setState({ allow_form_to_be_emailed: checked });

                              var status_message = false;

                              if(checked)
                              {
                                  status_message = 'Wij hebben het door u ingevulde formulier in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.'
                              }
                              else
                              {
                                  status_message = 'Wij hebben het door u ingevulde formulier in goede orde ontvangen!';
                              }

                              this.setState({status_message: status_message});
                          }
                      })(
                        <Switch disabled={this.state.locked} />
                      )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={(
                      <span>
                      Ingevulde formulieren automatisch e-mailen naar
                      <Tooltip className="mhs" title='Vul hier een of meerdere e-mailadressen in van degenen die een ingevuld formulier mogen ontvangen. Meerdere e-mailadressen scheiden met een komma (, ).'>
                        <InfoCircleTwoTone />
                      </Tooltip>
                      </span>
                    )} hasFeedback>
                      {getFieldDecorator('send_filled_in_form_to', {
                        initialValue: this.props.form_details.send_filled_in_form_to ? this.props.form_details.send_filled_in_form_to.split(',') : null,
                        // rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                      })(
                        <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({send_filled_in_form_to: value.join(',')}); }}></Select>
                      )}
                    </Form.Item>
                </Col>

              </Row>

              <Divider></Divider>

              { this.state.allow_form_to_be_emailed ?
              <Row gutter={24}>
                  <Col span={24}>
                      <Form.Item required label="Standaardtekst bevestigingspagina">
                      { getFieldDecorator('status_message_on', {
                        initialValue: this.state.status_message ? this.state.status_message : 'Wij hebben het door u ingevulde formulier in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.',
                        rules: [{ required: true, message: 'Vul een tekst in' }],
                        valuePropName: 'value',
                        trigger: 'onEditorChange',
                        // onEditorChange: (value, editor) => { this.setState({ status_message: value }); }
                      })(
                        <CustomEditor disabled={this.state.locked} height={300} placeholders={'form'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ status_message: e }); }} />
                      )}
                      </Form.Item>
                  </Col>
              </Row> : null }

              { !this.state.allow_form_to_be_emailed ?
              <Row gutter={24}>
                  <Col span={24}>
                      <Form.Item required label="Standaardtekst bevestigingspagina">
                      { getFieldDecorator('status_message_off', {
                        initialValue: this.state.status_message ? this.state.status_message : 'Wij hebben het door u ingevulde formulier in goede orde ontvangen!',
                        rules: [{ required: true, message: 'Vul een tekst in' }],
                        valuePropName: 'value',
                        trigger: 'onEditorChange',
                        // onEditorChange: (value, editor) => { this.setState({ status_message: value }); }
                      })(
                        <CustomEditor disabled={this.state.locked} height={300} placeholders={'form'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ status_message: e }); }} />
                      )}
                      </Form.Item>
                  </Col>
              </Row> : null }

              { this.state.allow_form_to_be_emailed ?
              <div>
                  <Divider></Divider>

                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item required label="Bevestigingsmail e-mail onderwerp">
                            {getFieldDecorator('status_email_subject', {
                              initialValue: this.state.status_email_subject,
                              rules: [{ required: true, message: 'Vul een onderwerp in' }],
                              // valuePropName: 'value',
                              // trigger: 'onEditorChange',
                              // onChange: (value) => { this.setState({ status_email_subject: value }); }
                            })(
                              <Input disabled={this.state.locked} />
                            )}
                          </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col span={24}>
                          <Form.Item required label="Bevestigingsmail e-mail bericht">
                          { getFieldDecorator('status_email_message', {
                            initialValue: this.state.status_email_message,
                            rules: [{ required: true, message: 'Vul een tekst in' }],
                            valuePropName: 'value',
                            trigger: 'onEditorChange',
                            // onEditorChange: (value, editor) => { this.setState({ status_email_message: value }); }
                          })(
                            <CustomEditor disabled={this.state.locked} height={300} placeholders={'form-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ status_email_message: e }); }} />
                          )}
                          </Form.Item>
                      </Col>
                  </Row>
              </div> : null }

            </Card>}

          </Form>

        </div>
    </div>
    );
  }
}


const FormSettingsForm = Form.create({ name: 'addresses_form' })(FormSettings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    form_details : state.forms.form,
    menus : state.forms.menus,
    project : state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAction : bindActionCreators(getFormAction, dispatch),
    addFormAction : bindActionCreators(addFormAction, dispatch),
    updateFormAction : bindActionCreators(updateFormAction, dispatch),
    resetFormAction : bindActionCreators(resetFormAction, dispatch),
    getFormMenuAction : bindActionCreators(getFormMenuAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingsForm);
