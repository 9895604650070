import React from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Select,
  Checkbox,
  Switch,
} from 'antd';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getInformationsAction, cloneInformationAction, searchInformationAction } from '../../../../../actions/globalInformationActions';
import { getLibraryCategoriesAction } from '../../../../../actions/librariesActions';
import { connect } from 'react-redux';
import CustomTable from '../../../../../components/CustomTable/CustomTable';

class CloneInformation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      selectedId: null,
      group_filter: []
    };
  }

  cloneInformation = () => {

    if(this.state.selectedId) {

      this.props.cloneInformationAction(this.props.match.params.id, this.state.selectedId).then((response) => {

        this.props.onClone(response);
        this.setState({selectedId: null, modalVisible: false});
      });
    }
  }

  openModal = () => {

    this.setState({modalVisible: true});

    this.props.getLibraryCategoriesAction('information');

    this.props.getInformationsAction();
  }

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = false;

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = false;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  render () {

      const columns = [{
        title: 'Titel',
        dataIndex: 'title',
        sorter: (a, b) => { return a.title.localeCompare(b.title)},
        render: (text, record) => (
          <span>
            <Link onClick={() => {
                this.setState({ selectedId: record.id }, () => {

                  this.cloneInformation();
                });
              }}>
              {record.title}
            </Link>
          </span>
        )
      },
      {
          title: 'Categorieen',
          dataIndex: '',
          key: 'categories',
          width: 160,
          filterMultiple: true,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 0 }}>

                <div>
                    {this.props.categories && this.props.categories.map((obj) => {
                        return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                            <Checkbox
                                onChange={(e) => {
                                    var my_group_filter = this.state.group_filter;

                                    if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                    else{ this.removeItem(my_group_filter, obj.name); }

                                    this.setState({ group_filter: my_group_filter });

                                    setSelectedKeys(my_group_filter);
                                }}
                                checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                                >
                                {obj.name}
                            </Checkbox>
                        </div>
                    })}
                </div>

                <div style={{padding: 8, width: '100%', clear: 'both'}}>
                    <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                    <div style={{float: 'left', marginLeft: 8}}>filter</div>
                </div>

                <div style={{width: '100%', clear: 'both'}}>
                    <Button
                      type="link"
                      onClick={() => { confirm(); }}
                      style={{marginLeft: -8, marginRight: 8 }}
                      >OK</Button>

                    <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
                </div>

              </div>
          ),
          onFilter: (value, record) => this.groupsFilter(value, record),
          render: (text, record) =>
          record.categories && record.categories.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
          )
      } //,
      // {
      //   title: 'Aangemaakt door',
      //   dataIndex: 'customer_id',
      //   render: (text, record) => (
      //     <span>
      //         {!record.customer_id ? 'Cobee' : 'Eigen tekst'}
      //     </span>
      //   ),
      //   filters: [
      //     {
      //       text: 'Cobee',
      //       value: 'cobee',
      //     },
      //     {
      //       text: 'Alleen eigen teksten tonen',
      //       value: 'own',
      //     },
      //   ],
      //   onFilter: (value, record) => {
      //     if(value === 'cobee') {
      //       return record.customer_id === null;
      //     }
      //     if(value === 'own') {
      //       return record.customer_id !== null;
      //     }
      //   },
      // }
    ];

    return (
      <span>
        {this.props.plain ? (
          <span style={{display:'block'}}onClick={this.openModal}>
            {this.props.buttonLabel ? this.props.buttonLabel : 'Bibliotheek'}
          </span>
        ) : (
          <Button onClick={this.openModal}>Selecteer uit bibliotheek</Button>
        )}

        <Modal
          title='Bibliotheek'
          width={980}
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          // onOk={() => this.cloneInformation()}
          //okText="Selecteer"
          okButtonProps={{ style: { display: 'none' } }}
        >
          <Form layout={'vertical'}>

            { /* OLD */ }
            {/*<Form.Item required hasFeedback label="Informatie">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer informatie item'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                {this.props.information.map((element) => (
                  <Select.Option value={element.id}><Tag>{element.customer_id ? 'Eigen tekst' : 'Cobee tekst'}</Tag> {element.title}</Select.Option>
                ))}
              </Select>
            </Form.Item>*/}

            { /* NEW */ }
            <div>

                <Row className="mbm">
                  <Col span={24}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={(value, event) => { event.preventDefault(); this.props.searchInformationAction(value); return false; }}
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey="id"
                      // rowSelection={rowSelection}
                      columns={columns}
                      loading={false}
                      dataSource={this.props.information}
                      />
                  </Col>
                </Row>
            </div>

          </Form>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    information: state.information.globalInformation,
    categories : state.library.categories
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInformationsAction : bindActionCreators(getInformationsAction, dispatch),
    cloneInformationAction : bindActionCreators(cloneInformationAction, dispatch),
    searchInformationAction : bindActionCreators(searchInformationAction, dispatch),
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneInformation);
