import React, { Component } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Row,
  Col,
  Select,
  Card,
  Button,
  message,
  Upload,
  Divider,
  Typography,
  InputNumber,
  Alert,
  Radio,
} from 'antd';
import { API_URL } from '../../../constants/settings'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storeEnvelopeAction, getEnvelopeAction, patchEnvelopeAction, resetEnvelopeAction } from '../../../actions/envelopesActions';
import { showSuccess, showError } from '../../../utils/Notifications';
import { HaveRole } from '../../../utils/RolesAndAbilities';
import LibrarySideMenu from '../LibrarySideMenu';
// import './EnvelopeDetail.css';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';
const { Title, Text } = Typography;

class EnvelopeDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
        envelope: null,
        errorenvelope: false,
    };
  }

  componentDidMount() {
    this.props.resetEnvelopeAction();

    if(this.props.match.params.id) {
      this.props.getEnvelopeAction(this.props.match.params.id);
    }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {

      // if(this.state.envelope == null && !this.props.envelope.envelope_url) {
      //   err = true;
      //   this.setState({errorenvelope: true});
      // } else {
      //   this.setState({errorenvelope: false});
      // }

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      if(this.props.match.params.id) {
        this.props.patchEnvelopeAction(this.props.match.params.id, values, {envelope: this.state.envelope}).then(() => {
          showSuccess();
          this.props.history.push(`/library/envelopes/`);
        });
      } else {
        this.props.storeEnvelopeAction(values, {envelope: this.state.envelope}).then(() => {
          showSuccess();
          this.props.history.push(`/library/envelopes/`);
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Enveloppen', 'link': '/library/envelopes/'}, {'label': this.props.letterpaper && this.props.letterpaper.name ? this.props.letterpaper.name : 'Toevoegen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='9' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">
                <Col span={12}>
                  <div>
                    {(this.props.HaveRole(['super-admin'])) ?
                    <Button type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                    : null }
                    <Link to={`/library/envelopes/`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Divider></Divider>
            <Row>
              <Col span={24}>
                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
                  {(this.props.HaveRole(['super-admin'])) ?
                  <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item required label="Omschrijving">
                          {getFieldDecorator('name', {
                            initialValue: this.props.envelope && this.props.envelope.name,
                            rules: [{ required: true, message: 'Vul een omschrijving in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                    </Col>
                      <Col span={12}>
                          <Form.Item required label="Postbode enveloppe ID">
                            {getFieldDecorator('postbode_envelope_id', {
                              initialValue: this.props.envelope && this.props.envelope.postbode_envelope_id,
                              rules: [{ required: true, message: 'Vul een ID in' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item required label="Adres venster positie">
                          {getFieldDecorator('address_window_position', {
                            initialValue: this.props.envelope && this.props.envelope.address_window_position,
                            rules: [{ required: true, message: 'Geef een adres venster positie op' }],
                          })(
                          <Radio.Group>
                            <Radio value={null}>Niet opgegeven</Radio>
                            <Radio value={'left'}>Links</Radio>
                            <Radio value={'right'}>Rechts</Radio>
                          </Radio.Group>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item required label="Type">
                          {getFieldDecorator('type', {
                            initialValue: this.props.envelope && this.props.envelope.type,
                            rules: [{ required: true, message: 'Vul een type in' }],
                          })(
                          <Radio.Group>
                            <Radio value={'normal'}>Normaal</Radio>
                            <Radio value={'return'}>Retour</Radio>
                          </Radio.Group>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item required label="Retour adres">
                            {getFieldDecorator('return_to', {
                              initialValue: this.props.envelope && this.props.envelope.return_to,
                              rules: [{ required: true, message: 'Vul een retour adres in' }],
                            })(
                            <Radio.Group>
                              <Radio value={'cobee'}>Cobee</Radio>
                              <Radio value={'customer'}>Klant</Radio>
                              <Radio value={'blank'}>Blanco</Radio>
                            </Radio.Group>
                            )}
                          </Form.Item>
                      </Col>
                  </Row> : null }
                  <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Enveloppe">
                            {this.props.envelope && this.props.envelope.envelope_url ? (
                            <div style={{flex: 1}}><img style={{border: '1px solid #e8e8e8'}} width="400" src={this.props.envelope ? this.props.envelope.envelope_url : ''} /></div>
                            ) : null}
                            {(this.props.HaveRole(['super-admin'])) ?
                            <Upload accept=".jpg,.pdf" beforeUpload={(file) => {
                                this.setState({envelope: file});
                                return false;
                              }}
                              onRemove={() =>{
                                this.setState({envelope: null});
                              }}>
                              <Button type="dashed"><UploadOutlined /> Upload een JPG of PDF bestand</Button>
                            </Upload> : null }
                            {this.state.errorenvelope ? (<Alert message="Upload een bestand" type="error" showIcon />) : null}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item required label="Afmeting">
                        {getFieldDecorator('size', {
                          initialValue: this.props.envelope && this.props.envelope.size ? this.props.envelope.size : 'A5',
                          rules: [{ required: true, message: 'Vul een type in' }],
                        })(
                        <Radio.Group>
                          <Radio value={'A5'}>A5 (standaard, halve A4)</Radio>
                          <Radio value={'A4'}>A4</Radio>
                        </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

const EnvelopeDetailForm = Form.create({ name: 'horizontal_login' })(EnvelopeDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.envelope.isFetching,
      isError : state.envelope.isError,
      envelope : state.envelope.envelope,
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    getEnvelopeAction : bindActionCreators(getEnvelopeAction, dispatch),
    patchEnvelopeAction : bindActionCreators(patchEnvelopeAction, dispatch),
    resetEnvelopeAction : bindActionCreators(resetEnvelopeAction, dispatch),
    storeEnvelopeAction : bindActionCreators(storeEnvelopeAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeDetailForm);
