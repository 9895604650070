import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateScheduleSettingsAction, updateScheduleBlockedDatesAction, addDayScheduleAction, updateDayScheduleAction, updateScheduleGanttAction } from '../../../../../actions/scheduleActions';
import Settings from '../Form/Settings';
import BlockedDates from '../Form/BlockedDates';
import DaySchedule from '../Form/DaySchedule';
import Calendar from '../Form/Calendar';
import ScheduleSteps from '../Steps/ScheduleSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';

class ScheduleBlockedDates extends Component
{
  render()
  {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/day-schedule`)}>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleSteps current={1} {...this.props} />
          </Card>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <BlockedDates
              wrappedComponentRef={formRef => { this.blockeddatesForm = formRef }}
              {...this.props}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    updateScheduleBlockedDatesAction : bindActionCreators(updateScheduleBlockedDatesAction, dispatch),
    addDayScheduleAction : bindActionCreators(addDayScheduleAction, dispatch),
    updateDayScheduleAction : bindActionCreators(updateDayScheduleAction, dispatch),
    updateScheduleGanttAction : bindActionCreators(updateScheduleGanttAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleBlockedDates);
