import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';

class InformationSteps extends React.Component {

  onChange = current => {
    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/information/${this.props.match.params.informationitemid}/detail`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/information/${this.props.match.params.informationitemid}/addresses`);
    }
  }

  render () {
    return (
      <Steps type="navigation" size="small" current={this.props.current} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Opmaken" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.informationitemid ? false : true} title="Adressen" />
      </Steps>
    );
  }
}

export default InformationSteps;
