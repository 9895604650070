import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getWorkOrderSettingsAction, updateWorkOrderSettingsAction } from '../../../../actions/workorderActions';
import WorkOrderSidemenu from './WorkOrderSidemenu';
import {showSuccess} from '../../../../utils/Notifications';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor'
import AddFromMedia from '../../../../components/AddFromMedia/AddFromMedia'
// import './Workorder.css';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Avatar,
  Typography,
  Switch,
} from 'antd';

class Workorder extends Component {
  state = {
    items:[],
    edit: false,
    addresses: [],
  };

  componentDidMount() {
    this.props.getWorkOrderSettingsAction(this.props.match.params.id).then(() => {

    });
  }

  handleSubmit = () => {
    this.props.form.validateFields((error, values) => {
      this.props.updateWorkOrderSettingsAction(this.props.match.params.id, values).then(() => {
        showSuccess();
      })
    });
  }

  render() {
    const {items} = this.state;

    const columns = [{
      title: 'Adres',
      dataIndex: 'full_address',
      render: (text, record) => (
        <span>{text}</span>
      )
    }, {
      title: 'Postcode',
      dataIndex: 'zipcode',
      render: (text, record) => (
        <span>{text}</span>
      )
    }, {
      title: 'Plaats',
      dataIndex: 'city',
      render: (text, record) => (
        <span>{text}</span>
      ),
    }];
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <WorkOrderSidemenu activeMenu={'2'} id={this.props.match.params.id} {...this.props}/>
          <Form gutter={24} layout={'vertical'} type="flex" style={{padding: 24, width: '100%'}}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button type="new" onClick={this.handleSubmit}>Opslaan</Button>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <Form.Item label="Afbeelding tonen i.p.v. Google Maps:">
                  {getFieldDecorator('work_order_show_image', {
                    valuePropName: 'checked',
                    initialValue: this.props.settings && this.props.settings.work_order_show_image == 1 ? true : false,
                  })(
                    <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {this.props.form.getFieldValue('work_order_show_image') ? (
                <Form.Item>
                  {getFieldDecorator('work_order_image_id', {
                    initialValue: this.props.settings && this.props.settings.work_order_image_id,
                  })(
                    <AddFromMedia
                      projectId={this.props.match.params.id}
                      initialMediaId={this.props.settings.work_order_image_id}
                      initialMediaUrl={this.props.settings.work_order_image_url}
                      onMediaSelection={(item) => this.props.form.setFieldsValue({'work_order_image_id': item.id})}
                      onMediaRemove={() => this.props.form.setFieldsValue({'work_order_image_id': ''})}
                    />
                  )}
                </Form.Item>) : null}

              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Onderwerp">
                  { getFieldDecorator('work_order_message', {
                    initialValue: this.props.settings && this.props.settings.work_order_message,
                    valuePropName: 'value',
                    trigger: 'onEditorChange',
                  })(
                    <CustomEditor placeholders={'work_order'} project_id={this.props.match.params.id} />
                  ) }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const WorkorderForm = Form.create({ name: 'workorder_settings' })(Workorder);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    settings: state.workorder.settings,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkOrderSettingsAction : bindActionCreators(getWorkOrderSettingsAction, dispatch),
    updateWorkOrderSettingsAction : bindActionCreators(updateWorkOrderSettingsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkorderForm);
