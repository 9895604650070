import React from 'react'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Card, Row, Col, Button, Upload, Tag, Alert, notification, Collapse, Modal } from 'antd';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import './Collapse.css';
import { API_URL, JOB_URL } from '../../../../../constants/settings';
import ComposeMailForm from '../../Form/ComposeMailForm';
import { setCorrespondenceMethodAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, checkPlaceholderAction, resetCorrespondenceAction } from '../../../../../actions/correspondencesActions';

const { Panel } = Collapse;
const { error } = Modal;

class ComposeMail extends React.Component {

  state = {
    method: {},
    file: null,
    filelist: [],
    filenames: [],
    removeFile: false,
    iframeKey: 1,
    pdf_attachments: [],
    removeFiles: [],
    tags_checking: [],
    loading: true,
    uploading: false,
  };

  // iframe_ref = null;

  componentDidMount() {

    var mailType = this.props.match.params.type ? this.props.match.params.type : 'mail';

    this.props.resetCorrespondenceAction(); //.then(() => {

        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

            // fetch method
            var selected_method = this.props.correspondence.methods.find((element) => {

              if(this.props.match.params.addressid) {
                if(element.type === mailType && element.address_id == this.props.match.params.addressid) {
                  return true;
                }
              } else {
                if(this.props.match.params.occupantid) {
                  if(element.type === mailType && element.occupant_id == this.props.match.params.occupantid) {
                    return true;
                  }
                } else {
                  if(element.type === mailType && element.address_id == null && element.occupant_id == null) {
                    return true;
                  }
                }
              }
            });

            this.props.setCorrespondenceMethodAction(selected_method);

            // this.setState({ pdf_attachments: selected_method ? selected_method.pdf_attachments : [], loading: false });

            //
            this.setState({
                pdf_attachments: selected_method ? selected_method.pdf_attachments : []
            }, () => {

                var filenames = [];

                this.state.pdf_attachments && this.state.pdf_attachments.map && this.state.pdf_attachments.map((record, index) => {

                    if(record.filename != undefined)
                    {
                        filenames[index] = record.filename;
                    }
                    else
                    {
                        filenames[index] = '';
                    }

                });

                this.setState({ filenames: filenames, loading: false });
            });

            // console.log('method', selected_method);

            // if(this.props.selectedMethod.pdf_attachment)
            // {
            //     this.setState({ filelist: {'pdf_attachment': 'remove'} });
            // }

        });
    //});
  }

  /* check */
  tags_checking = [];

  checkPlaceholders(message)
  {
      var temp = document.createElement("div");

      temp.innerHTML = message;

      var all = temp.getElementsByTagName("placeholder");

      var tags = [];

      // check for new tags
      for(var i = 0, max = all.length; i < max; i++)
      {
          var tag = all[i].innerHTML;

          if(tags.indexOf(tag) == -1)
          {
              tags.push(tag);

              if(this.tags_checking[tag] === undefined)
              {   // add new tag to start checking
                  this.startCheckingPlaceholder(tag);
              }
          }
      }

      // remove no longer used tags
      Object.keys(this.tags_checking).map((tag) => {

          if(tags.indexOf(tag) == -1)
          {
              this.stopCheckingPlaceholder(tag);
          }
      });
  }

  startCheckingPlaceholder(tag)
  {
      // console.log('startCheckingPlaceholder', tag);

      this.tags_checking[tag] = {
          status : null,
          call : this.checkPlaceholder(tag)
      };

      this.setState({ tags_checking: this.tags_checking });
  }

  checkPlaceholder(tag)
  {   // do API call + posibility to cancel this call
      const abortcontroller = new AbortController();

      this.props.checkPlaceholderAction(this.props.match.params.correspondenceid, this.props.match.params.type, this.props.match.params.addressid, tag, abortcontroller).then((status) => {

          if(status === true){
              this.tags_checking[tag].status = true;
          }
          else if(this.tags_checking[tag])
          {
              this.tags_checking[tag].status = status;
          }
          else
          {
              delete this.tags_checking[tag];
          }

          this.setState({ tags_checking: this.tags_checking });
      });

      return abortcontroller;
  }

  stopCheckingPlaceholder(tag)
  {
      // console.log('stopCheckingPlaceholder', tag);

      this.tags_checking[tag].call.abort();

      delete this.tags_checking[tag];

      this.setState({ tags_checking: this.tags_checking });
  }

  /* save */
  saveMethod = (action, ref = null) => {

    this.setState({ uploading: true });

    var mailType = this.props.match.params.type ? this.props.match.params.type : 'mail';

    this.composeMailForm && this.composeMailForm.props.form.validateFields((errors, values) => {

      if(this.props.match.params.addressid) {
        values = { ...values, 'address_id': this.props.match.params.addressid };
      }

      if(this.props.match.params.occupantid) {
        values = { ...values, 'occupant_id': this.props.match.params.occupantid };
      }

      values.filenames = this.state.filenames;

      // if(values.message === '' && ((!this.props.selectedMethod.pdf_attachment && Array.isArray(this.state.filelist) && this.state.filelist.length < 1) || (this.state.filelist && this.state.filelist.pdf_attachment == 'remove')))
      if(values.message === '' && ((!this.props.selectedMethod.pdf_attachment && Array.isArray(this.state.filelist) && this.state.filelist.length < 1) /*|| (this.state.filelist && this.state.filelist.pdf_attachment == 'remove')*/))
      // if(values.message === '' && ((!this.props.selectedMethod.pdf_attachment && Array.isArray(this.state.pdf_attachments) && this.state.pdf_attachments.length < 1) /*|| (this.state.filelist && this.state.filelist.pdf_attachment == 'remove')*/))
      {
          // notification.open({
          //   duration: 10,
          //   maxCount: 1,
          //   message: 'Brief is leeg',
          //   type: 'error',
          //   description:
          //     "De brief bevat geen inhoud en of bijlage.",
          //   onClick: () => {
          //   },
          // });

          error({
              title: 'Brief is leeg',
              content: 'De brief bevat geen inhoud en of bijlage.',
              centered: true,
              // footer: ''
          });

          this.setState({ uploading: false });

          // return false;
      }
      else
      {
          if(!errors)
          {
            this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {...values, 'type': mailType, 'remove_attachments': this.state.removeFiles}, this.state.filelist).then((response) => {

              if(response.data.success == false) {

                // notification.open({
                //   duration: 10,
                //   maxCount: 1,
                //   message: 'Bijlage ongeldig',
                //   type: 'error',
                //   description:
                //     response.data.nice_error
                //     ?
                //     response.data.nice_error
                //     :
                //     "De bijlage mag uit maximaal 4 pagina's (bij enkelzijdig versturen) of 8 pagina's (bij dubbelzijdig versturen) bestaan in staand / portret A4 formaat en niet groter zijn dan 8MB.",
                //   onClick: () => {
                //     // console.log('Notification Clicked!');
                //   },
                // });

                error({
                    title: 'Bijlage ongeldig',
                    content:
                        response.data.nice_error
                        ?
                        response.data.nice_error
                        :
                        "De bijlage mag uit maximaal 4 pagina's (bij enkelzijdig versturen) of 8 pagina's (bij dubbelzijdig versturen) bestaan in staand / portret A4 formaat en niet groter zijn dan 8MB.",
                    centered: true,
                    // footer: ''
                });

                this.setState({ uploading: false });

                // return false;
              }
              else
              {
                  this.setState({ filelist: [] });
                  this.setState({ pdf_attachments: response.data.correspondence_method && response.data.correspondence_method.pdf_attachments && response.data.correspondence_method.pdf_attachments.map ? response.data.correspondence_method.pdf_attachments : [] });
                  // this.setState({ pdf_attachments:  })
                  this.setState({ uploading: false });
              }

              if(action == 'go-back')
              {
                this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/${this.props.match.params.type == 'mail' ? 1 : 2}`);
              }
              else if(action == 'preview')
              {
                window.open(JOB_URL + '/correspondence/'+ this.props.match.params.correspondenceid +'/view/'+mailType+(this.props.match.params.addressid ? '/'+this.props.match.params.addressid : '')+'/Voorbeeld brief.pdf?dpi=72&token=' + localStorage.getItem('authToken'), '_blank');
              }
              else if(action == 'reload')
              {
                // reload iframe
                this.setState({ iframeKey: (this.state.iframeKey + 1) });
              }
              else if(action == 'iframe' && ref)
              {
                  ref.setState({ iframeKey: (ref.state.iframeKey + 1) });
                  // return true;
              }
              else if(action == 'modal' && ref)
              {
                  ref.setState({ iframeKey: (ref.state.iframeKey + 1), previewModalVisible: true });
                  // return true;
              }
              else if(action == 'iframe')
              {
                  this.setState({ iframeKey: (this.state.iframeKey + 1) }, () => {
                      // console.log('iframe key = ', this.state.iframeKey);

                      if(this.composeMailForm)
                      {
                          this.composeMailForm.refreshIframe();
                      }
                  });
                  // return true;
              }
            }).catch((e) => {
              this.setState({ uploading: false });
            });
          }
          // else
          // {
          //     return false;
          // }
      }
    });
  }

  render () {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div style={{display:'flex', flex:1, flexDirection: 'row', justifyContent: 'space-between'}}>

              <div>
                {/*<Button loading={this.props.isFetching} type="new" onClick={() => { this.saveMethod('reload'); }}>Opslaan</Button>*/}

                <Button loading={this.props.isFetching} type="new" onClick={() => { this.saveMethod('go-back'); }}>Opslaan en terug</Button>

                <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/${this.props.match.params.type == 'mail' ? 1 : 2}`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>
              <div>
                {(this.props.match.params.type == 'mail' || this.props.match.params.type == 'email_mail') && (
                  <Button onClick={() => { this.saveMethod('preview'); }} style={{marginLeft: 10}}>Voorbeeld pdf</Button>
                )}
              </div>

            </div>

          </Col>
        </Row>

        <Row>
          <Card>

            {/*<div style={{ display: (this.state.tags_checking && Object.keys(this.state.tags_checking).length > 0 ? 'block' : 'none'), marginBottom: 24, padding: 10, backgroundColor: 'rgba(255, 0, 0, 0.1)', width: '100%' }}>
                <h4>Placeholder(s) controleren</h4>
                { this.state.tags_checking && Object.keys(this.state.tags_checking).map((tag) => {
                return (<div id={'placeholder-'+tag}>

                    { this.state.tags_checking[tag].status === null ?
                    <span>Bezig met het controleren van de placeholder: {tag}.</span> : null }

                    { this.state.tags_checking[tag].status === true ?
                    <span>De placeholder {tag} is voor iedereen gevuld.</span> : null }

                    { typeof this.state.tags_checking[tag].status === 'string' ?
                    <span>De placeholder {tag} is niet voor iedereen ingevuld: {this.state.tags_checking[tag].status}</span> : null }
                </div>);
                }) }
            </div>*/}

            <Alert
                style={{
                    display: (this.state.tags_checking && Object.keys(this.state.tags_checking).length > 0 ? 'block' : 'none'),
                    marginBottom: 24
                }}
                showIcon
                type="info"
                message="Controleren van placeholders"
                description={ <div id="cobee-alert">{this.state.tags_checking && Object.keys(this.state.tags_checking).map((tag) => {
                    return (<div id={'placeholder-'+tag}>

                        { this.state.tags_checking[tag].status === null ?
                        <span>Bezig met het controleren van de placeholder: {tag}.</span> : null }

                        { /*this.state.tags_checking[tag].status === true ?
                        <span>De placeholder {tag} is voor iedereen gevuld.</span> : null/**/ }

                        { typeof this.state.tags_checking[tag].status === 'string' ?
                        <Collapse style={{ flex: 1, flexDirection: 'row', border: 0, backgroundColor: 'transparent', margin: 0, marginLeft: -18, padding: 0, marginBottom: 6 }}>
                            <Panel header={<div>De placeholder {tag} is niet voor iedereen ingevuld:</div>} style={{ background: 'none', backgroundColor: 'transparent', border: 0, borderTop: 0, margin: 0, padding: 0 }}>
                                <p style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 0, paddingBottom: 0 }}>
                                    {
                                        this.state.tags_checking[tag].status.split('\r\n').map((line) => {
                                            return <div style={{ width: '100%', clear: 'both' }}>- {line}.</div>
                                        })
                                    }
                                </p>
                            </Panel>
                        </Collapse> : null }
                    </div>);
                }) }</div>}
                />

            { !this.state.loading ? <ComposeMailForm
                // ref={(ref) => { this.iframe_ref = ref; }}
                iframeKey={this.state.iframeKey}
                wrappedComponentRef={formRef => { this.composeMailForm = formRef }}
                onSave={(action, ref) => { return this.saveMethod(action, ref); }}
                messageChanged={(e) => this.checkPlaceholders(e) }
                {...this.props}
                /> : null }

                <Form.Item label="Bijlage">

                    <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="Voor een A5 (standaard) enveloppe mag de brief mag uit maximaal 8 pagina's (bij enkelzijdig versturen) of 16 pagina's (bij dubbelzijdig versturen) bestaan.
                    Voor een A4 enveloppe mag de brief mag uit maximaal 16 pagina's (bij enkelzijdig versturen) of 25 pagina's (bij dubbelzijdig versturen) bestaan.
                    De brief met eventueel de bijlages moeten staand / portret A4 formaat zijn en de bijlage(s) mogen niet groter zijn dan 8MB.
                    Laat je de correspondentie dubbelzijdig afdrukken, dan begint de bijlage op een nieuw A4."
                    />

                    { this.state.pdf_attachments && this.state.pdf_attachments.map && this.state.pdf_attachments.map((obj, file_index) => {

                        // if(obj.filename)
                        // {
                        //     this.state.filenames[file_index] = obj.filename;
                        //
                        //     this.setState({ filenames: this.state.filenames });
                        // }

                        return <div>
                            <Row className="mbm">
                                <Col span={22}>
                                    <Tag>{obj && obj.file ? obj.file : obj}</Tag>
                                </Col>
                                {/*<Col span={11}>
                                    <Input
                                        value={this.state.filenames[file_index]}
                                        onChange={(e) => {

                                            this.state.filenames[file_index] = e.target.value;

                                            this.setState({ filenames: this.state.filenames });
                                        }}
                                        placeholder="Bestandsnaam (i.p.v. Bijlage X)"
                                        />
                                </Col>*/}
                                <Col span={2}>
                                    <Button onClick={() => {

                                        var files = this.state.pdf_attachments;

                                        if(files && files[file_index])
                                        {
                                            delete files[file_index];
                                        }

                                        var filenames = this.state.filenames;

                                        if(filenames && filenames[file_index])
                                        {
                                            delete filenames[file_index];
                                        }

                                        this.setState({
                                            pdf_attachments: files,
                                            filenames: filenames
                                        });

                                        var remove = this.state.removeFiles;

                                        remove.push(obj);

                                        this.setState({
                                            removeFiles: remove
                                        });

                                        this.saveMethod('iframe');
                                      }}
                                      type={'link'}
                                      style={{ color: '#f5222d' }}
                                      >
                                      Verwijderen
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    }) }

                    <Upload
                        accept="application/pdf"
                        beforeUpload={(file) => {

                            if(file.size > 8000000)
                            {   // file too large
                                // notification.open({
                                //   message: 'Bijlage te groot',
                                //   type: 'error',
                                //   description:
                                //     "De bijlage mag niet groter zijn dan 8MB",
                                //   onClick: () => {},
                                // });

                                error({
                                    title: 'Bijlage te groot',
                                    content: 'De bijlage mag niet groter zijn dan 8MB',
                                    centered: true
                                });
                            }
                            else
                            {
                                var filelist = this.state.filelist;

                                filelist.push(file);

                                this.setState({ filelist: filelist });

                                this.saveMethod('iframe');

                                return false;
                            }

                            // return false;
                        }}
                        onRemove={(file) => {

                          this.setState((state) => {

                            const index = state.filelist.indexOf(file);
                            const newfilelist = state.filelist.slice();
                            newfilelist.splice(index, 1);

                            return {
                              filelist: newfilelist
                            };
                          });
                        }}
                        disabled={this.state.uploading}
                        fileList={this.state.filelist}
                        >
                        <Button
                            style={{ marginLeft: 12 }}
                            disabled={this.state.uploading}
                            >
                            { !this.state.uploading ? <UploadOutlined /> : <LoadingOutlined />}
                            Upload een bijlage
                        </Button>
                    </Upload>

              </Form.Item>

          </Card>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    selectedMethod : state.correspondence.selectedMethod,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    setCorrespondenceMethodAction : bindActionCreators(setCorrespondenceMethodAction, dispatch),
    checkPlaceholderAction : bindActionCreators(checkPlaceholderAction, dispatch),
    resetCorrespondenceAction : bindActionCreators(resetCorrespondenceAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMail);
