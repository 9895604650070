import React from 'react'
import { Steps } from 'antd';
// import '../../steps.css';

class SupportBaseCorrespondenceSteps extends React.Component {

  onChange = current => {

    if(this.props.validate && this.props.current < current)
    {
        if(!this.props.validator())
        {
            return;
        }
    }

    if(current == 0) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/form`);
    }
    if(current == 1) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/letter`);
    }
    if(current == 2) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/email`);
    }
    if(current == 3) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/attachments`);
    }
    if(current == 4) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/check`);
    }
  }

  render () {
    return(
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Formulier" />
        <Steps.Step disabled={this.props.support_base_form.nr_of_letters > 0 || this.props.support_base_form.send_by_customer > 0 ? false : true} style={{padding: "10px 30px", marginRight: 10 }} title="Brief" />
        <Steps.Step disabled={this.props.support_base_form.nr_of_emails > 0 ? false : true} style={{padding: "10px 30px", marginRight: 10 }} title="E-mail" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Bijlage(n)" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Controleren" />
      </Steps>
    );
  }
}

export default SupportBaseCorrespondenceSteps;
