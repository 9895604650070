import api from '../utils/api'

export const getCustomerDeliveryUsersAction = (customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    return api('get','/delivery-users/customer/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const searchCustomerDeliveryUsersAction = (search, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    return api('post','/delivery-users/customer/'+customer_id+'/search', { search: search }, true).then((response) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const getDeliveryUserAction = (delivery_user_id) => dispatch => {

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    return api('get','/delivery-user/'+delivery_user_id, null, true).then((response) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const addCustomerDeliveryUserAction = (params, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERYUSER_ACTION_FETCH'
    })

    return api('post','/delivery-users/customer/'+customer_id+'/add', params, true).then((response) => {
        dispatch({
            type: 'ADD_DELIVERYUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const updateDeliveryUserAction = (id, params) => dispatch => {

    dispatch({
        type: 'DELIVERYUSER_ACTION_FETCH'
    })

    return api('post','/delivery-user/'+id+'/update', params, true).then((response) => {
        dispatch({
            type: 'UPDATE_DELIVERYUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const resetDeliveryUsersAction = (delivery_user_id = false) => dispatch => {

    dispatch({
        type: 'RESET_DELIVERYUSERS_ACTION_FETCH'
    })
}

export const resetDeliveryUserAction = (delivery_user_id = false) => dispatch => {

    dispatch({
        type: 'RESET_DELIVERYUSER_ACTION_FETCH'
    })
}

export const getDeliveryUsersLibraryAction = () => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/library/delivery-users/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}

export const getDeliveryUserLibraryAction = (delivery_user_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSER_ACTION_FETCH'
    })

    return api('get','/library/delivery-user/'+delivery_user_id, params, true).then((response) => {
        dispatch({
          type: 'GET_DELIVERYUSER_LIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_ERROR',
        })
        return false;
    });
}

export const searchDeliveryUsersLibraryAction = (search) => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/delivery-users/'+customer_id+'/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}

export const addDeliveryUserLibraryAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSER_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/delivery-users/'+customer_id, params, true).then((response) => {
        dispatch({
            type: 'ADD_DELIVERYUSER_LIBRARY_ACTION_SUCCESS',
            payload: response.data.delivery_user
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_ERROR',
        })
        return false;
    });
}

export const patchDeliveryUserLibraryAction = (delivery_user_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSER_ACTION_FETCH'
    })

    return api('post','/library/delivery-user/'+delivery_user_id, params, true).then((response) => {
        dispatch({
            type: 'PATCH_DELIVERYUSER_LIBRARY_ACTION_SUCCESS',
            payload: response.data.delivery_user
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_ERROR',
        })
        return false;
    });
}

export const removeDeliveryUsersLibraryAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/delivery-users/'+customer_id+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'REMOVE_DELIVERYUSERS_LIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}

export const duplicateDeliveryUserLibraryAction = (project_id, delivery_user_id) => (dispatch, getState) => {

    dispatch({
        type: 'DELIVERYUSER_ACTION_FETCH'
    })

    return api('post','/library/delivery-user/'+delivery_user_id+'/clone', {project_id: project_id}, true).then((response) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_SUCCESS',
            // payload: response.data.delivery_user
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_ERROR',
        })
        return false;
    });
}

/*export const patchDeliveryUserAction = (delivery_user_id, params, file) => dispatch => {
    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    return api('post','/delivery-user/'+delivery_user_id, params, true, file).then((response) => {
        dispatch({
            type: 'DELIVERYUSER_ACTION_SUCCESS',
            payload: response.data.delivery_user
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

/*export const removeDeliveryUsersAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    return api('post','/delivery-users/'+projectid+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'REMOVE_DELIVERYUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

/*export const updateDeliveryUsersOrderAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'DELIVERYUSERS_ACTION_FETCH'
    })

    return api('post','/delivery-users/order/'+projectid, params, true).then((response) => {
        dispatch({
            type: 'SORT_DELIVERYUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DELIVERYUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/
