import api from '../utils/api'


export const multiDeleteResults = (intake_form_id, params) => (dispatch, getState) => {

  return api('post','/intake/form/completed/' + intake_form_id + '/delete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_INTAKE_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const getIntakesByCustomerAction = () => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/intake/forms/customer/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'INTAKES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const getIntakesByCustomerForCloningAction = () => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/intake/forms/customer/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'CLONING_INTAKES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchIntakesByCustomerAction = (search) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/intake/forms/customer/' + customer_id + '/search', {'search': search}, true).then((response) => {
        dispatch({
            type: 'INTAKES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteIntakesByCustomerAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/intake/forms/customer/' + customer_id + '/delete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_INTAKES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const duplicateIntakesByCustomerAction = (intake_form_id) => (dispatch, getState) => {

  dispatch({
    type: 'INTAKE_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/intake/forms/customer/' + customer_id + '/clone/', {'ids': [intake_form_id]}, true).then((response) => {
    dispatch({
      type: 'INTAKE_CLONE_ACTION_CUSTOMER_SUCCESS',
      payload: response.data[0]
    });
  }).catch((e) => {
    dispatch({
      type: 'INTAKE_ACTION_ERROR',
    })
    return false;
  });
}

export const cloneIntakeFromCustomerAction = (project_id, intake_form_id) => (dispatch, getState) => {

  dispatch({
    type: 'INTAKE_ACTION_FETCH'
  })

  return api('post','/intake/form/project/' + project_id + '/form/' + intake_form_id + '/clone', null, true).then((response) => {
    dispatch({
      type: 'INTAKE_CLONE_ACTION_SUCCESS',
      payload: response.data[0]
    });
  }).catch((e) => {
    dispatch({
      type: 'INTAKE_ACTION_ERROR',
    })
    return false;
  });
}

export const addIntakeCustomerAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/intake/forms/customer/' + customer_id + '/add', params, true).then((response) => {
        dispatch({
            type: 'ADD_INTAKE_ACTION_SUCCESS',
            payload: response.data.form
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const getIntakeByIdAction = (intake_form_id, filter = false) => dispatch => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    });

    return api('get','/intake/form/'+intake_form_id+(filter ? '?filter=' + filter : ''), null, true).then((response) => {
        dispatch({
            type: 'INTAKE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchIntakeFormsAddresses = (intake_form_id, search, filter = false) => dispatch => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    var params = {search: search};

    if(filter)
    {
        params.filter = true;
    }

    return api('post','/intake/form/'+intake_form_id+'/search', params, true).then((response) => {
        dispatch({
            type: 'INTAKE_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data.results;
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateIntakeCustomerAction = (intake_form_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('post','/intake/form/' + intake_form_id + '/update', params, true).then((response) => {
        dispatch({
            type: 'UPDATE_INTAKE_ACTION_SUCCESS',
            payload: response.data.form
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}


export const getIntakesByProjectAction = (project_id) => dispatch => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('get','/intake/forms/project/' + project_id, null, true).then((response) => {
        dispatch({
            type: 'INTAKES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}


export const searchIntakesByProjectAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('post','/intake/forms/project/' + project_id + '/search', {'search': search}, true).then((response) => {
        dispatch({
            type: 'INTAKES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteIntakesByProjectAction = (project_id, params) => (dispatch, getState) => {

  return api('post','/intake/forms/project/' + project_id + '/delete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_INTAKES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const duplicateIntakesByProjectAction = (project_id, intake_form_id) => (dispatch, getState) => {

  dispatch({
    type: 'INTAKE_ACTION_FETCH'
  })

  return api('post','/intake/forms/project/' + project_id + '/clone', {'ids': [intake_form_id]}, true).then((response) => {
    dispatch({
      type: 'INTAKE_CLONE_ACTION_SUCCESS',
      payload: response.data[0]
    });
  }).catch((e) => {
    dispatch({
      type: 'INTAKE_ACTION_ERROR',
    })
    return false;
  });
}

export const addIntakeProjectAction = (project_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('post','/intake/forms/project/' + project_id + '/add', params, true).then((response) => {
        dispatch({
            type: 'ADD_INTAKE_ACTION_SUCCESS',
            payload: response.data.form
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateIntakeProjectAction = (intake_form_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('post','/intake/form/' + intake_form_id + '/update', params, true).then((response) => {
        dispatch({
            type: 'UPDATE_INTAKE_ACTION_SUCCESS',
            payload: response.data.form
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const exportIntakeResults = (intake_id, selectedRowKeys) => (dispatch, getState) => {

  return api('post','/intake/form/' + intake_id + '/export', {ids: selectedRowKeys}, true, null, true).then((response) => {
    // dispatch({
    //   type: 'DELETE_INTAKES_ACTION_SUCCESS',
    //   payload: response.data
    // });

    var reader = new FileReader();

    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    reader.readAsDataURL(blob);

    reader.onloadend = function (e) {
        window.open(reader.result, 'Export resultaten');
    }
  }).catch((e) => {
    return false;
  });
}

export const exportIntakeResultsAsZip = (intake_id, selectedRowKeys) => (dispatch, getState) => {

  return api('post','/intake/form/' + intake_id + '/export/zip', {ids: selectedRowKeys}, true, null, true).then((response) => {
    // dispatch({
    //   type: 'DELETE_INTAKES_ACTION_SUCCESS',
    //   payload: response.data
    // });

    var reader = new FileReader();

    var blob = new Blob([response.data], { type: 'application/zip' });

    reader.readAsDataURL(blob);

    reader.onloadend = function (e) {
        window.open(reader.result, 'Export resultaten');
    }
  }).catch((e) => {
    return false;
  });
}

export const exportIntakeQuestionsAction = (project_id, intake_id, filename = false) => (dispatch, getState) => {

  return api('post','/intake/form/' + intake_id + '/export/questions', null, true, null, true).then((response) => {

    // method 1
    // var reader = new FileReader();
    //
    // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //
    // reader.readAsDataURL(blob);
    //
    // reader.onloadend = function (e) {
    //     window.open(reader.result, 'Export resultaten');
    // }

    // method 2
    var reader = new FileReader();

    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const a = document.createElement('a');

    a.setAttribute('download', (filename ? filename : 'opname_formulier-'+intake_id+'.xlsx'));

    a.setAttribute('href', window.URL.createObjectURL(blob));

    a.click();

  }).catch((e) => {
    return false;
  });
}

export const requestExportIntakeAction = (intake_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    });

  return api('post', '/intake/form/'+intake_id+'/request/export', params, true, null, true).then((response) => {
      dispatch({
          type: 'REQUEST_INTAKE_ACTION_SUCCESS'
      });
  }).catch((e) => {
      dispatch({
          type: 'INTAKE_ACTION_ERROR',
      })
      return false;
  });
}

export const updateIntakeFormCompletedAction = (form_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('post','/intake/form/completed/' + form_id + '/update', params, true).then((response) => {
        dispatch({
            type: 'UPDATE_INTAKE_ACTION_SUCCESS',
            payload: response.data.form
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const resetIntakeAction = () => dispatch => {

  dispatch({
    type: 'INTAKE_ACTION_RESET'
  })
}

export const changeStatusIntakeFromsAction = (intake_form_id, status, ids) => dispatch => {

    dispatch({
        type: 'INTAKE_ACTION_FETCH'
    })

    return api('post','/intake/form/' + intake_form_id + '/results/status/update', {status: status, ids: ids}, true).then((response) => {
        dispatch({
            type: 'UPDATE_INTAKE_ACTIONS_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'INTAKE_ACTION_ERROR',
        })
        return false;
    });
}

export const requestDownloadIntake = (intake_form_id, params) => dispatch => {

    return api('post','intake/form/'+intake_form_id+'/request-download', params, true, null, true).then((response) => {

    }).catch((e) => {
      return false;
    });

}

export const previewAction = (form_id) => (dispatch, getState) => {

    return api('get', '/intake/form/' + form_id + '/preview', null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'text/html' });

        reader.readAsText(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write(reader.result);

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}
