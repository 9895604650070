import api from '../utils/api'

export const getComplaintsAction = (projectid) => dispatch => {

    dispatch({
        type: 'COMPLAINTS_ACTION_FETCH'
    })

    return api('get','/complaints/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchComplaintsAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'COMPLAINTS_ACTION_FETCH'
    })

    return api('post','/complaints/'+projectid+'/search', params, true).then((response) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getComplaintAction = (complaintid) => dispatch => {

    dispatch({
        type: 'COMPLAINTS_ACTION_FETCH'
    })

    return api('get','/complaint/'+complaintid, null, true).then((response) => {
        dispatch({
            type: 'COMPLAINT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_ERROR',
        })
        return false;
    });
}

export const addComplaintAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'COMPLAINTS_ACTION_FETCH'
    })

    return api('post','/complaints/'+projectid, params, true).then((response) => {
        dispatch( {
            type: 'ADD_COMPLAINT_ACTION_SUCCESS',
            payload: response.data.complaint
        } );
        return response;
    }).catch((e) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetComplaintsAction = () => dispatch => {
    dispatch({
        type: 'COMPLAINTS_ACTION_RESET'
    })
}

export const patchComplaintsAction = (complaintid, params) => dispatch => {
    dispatch({
        type: 'COMPLAINTS_ACTION_FETCH'
    })

    return api('post','/complaint/'+complaintid, params, true).then((response) => {
        dispatch({
            type: 'COMPLAINT_ACTION_SUCCESS',
            payload: response.data.complaint
        });
    }).catch((e) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_ERROR',
        })
        return false;
    });
}

export const removeComplaintsAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'COMPLAINTS_ACTION_FETCH'
    })
    return api('post','/complaints/'+projectid+'/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'COMPLAINT_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'COMPLAINTS_ACTION_ERROR',
        })
        return false;
    });
}
