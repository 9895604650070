import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Button,
    Drawer,
    Divider,
    Tag,
    Modal,
    Upload,
    Input,
    Typography,
    Tooltip,
    Select,
} from 'antd';
import NumberFormat from 'react-number-format';
import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';

const ButtonGroup = Button.Group;

const Option = Select.Option;

class PatchPartner extends Component {

    render()
    {
        const { getFieldDecorator } = this.props.form;

        //const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        );

        return (
            <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
                <Row gutter={24} >
                    <Col span={12}>
                        <Form.Item required hasFeedback label="Bedrijfsnaam">
                              {getFieldDecorator('name', {
                                  initialValue: this.props.partner && this.props.partner.name,
                                  rules: [{ required: true, message: 'Vul een bedrijfsnaam in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>

                    {/*<Col span={12}>
                        <Form.Item hasFeedback label="KvK">
                              {getFieldDecorator('kvk', {
                                  initialValue: this.props.partner && this.props.partner.kvk,
                                  rules: [{ required: false, message: 'Vul een KvK-nummer in' }],
                              })(
                                  <NumberFormat allowEmptyFormatting={true} format="########" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
                              )}
                        </Form.Item>
                    </Col>*/}

                    <Col span={12}>
                        <Form.Item hasFeedback label="Adres">
                              {getFieldDecorator('address', {
                                  initialValue: this.props.partner && this.props.partner.address,
                                  rules: [{ required: false, message: 'Vul een adres in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Postcode">
                              {getFieldDecorator('zipcode', {
                                  initialValue: this.props.partner && this.props.partner.zipcode,
                                  rules: [{ validator: zipcodeValidation }],
                              })(
                                  <Input maxLength={6} />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Plaats">
                              {getFieldDecorator('city', {
                                  initialValue: this.props.partner && this.props.partner.city,
                                  rules: [{ required: false, message: 'Vul een plaatsnaam in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Telefoon">
                              {getFieldDecorator('phone_number', {
                                  initialValue: this.props.partner && this.props.partner.phone_number,
                                  rules: [{ required: false, message: 'Vul een telefoonnummer in.' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                              })(
                                  <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item  hasFeedback label="E-mail">
                              {getFieldDecorator('email', {
                                  initialValue: this.props.partner && this.props.partner.email,
                                  rules: [{ required: false, message: 'Vul een e-mailadres in' },{type: 'email', message: 'Vul een geldig e-mailadres in'}],
                              })(
                                  <Input type={'email'} />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Website">
                              {getFieldDecorator('website', {
                                  initialValue: this.props.partner && this.props.partner.website,
                                  rules: [{ validator: urlValidation }],
                              })(
                                  <Input addonBefore="https://" />
                              )}
                        </Form.Item>
                    </Col>

                      <Col span={12}>
                        <Form.Item label="Logo">
                            {getFieldDecorator('logo_id', {
                              initialValue: this.props.partner && this.props.partner.logo_id,
                            })(
                              <AddFromMedia
                                hideProjectBrowser={true}
                                initialMediaId={this.props.partner.logo_id}
                                initialMediaUrl={this.props.partner.logo_url}
                                onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
                                onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
                              />
                            )}
                        </Form.Item>
                      </Col>

                </Row>
            </Form>
        );
    }
}

export default Form.create({ name: 'patch_partner_form' })(PatchPartner);
