import api from '../utils/api'

export const getLicensesAction = (customer_id) => dispatch => {

    dispatch({
        type: 'LICENSES_ACTION_FETCH'
    })

    return api('get', '/licenses', null, true).then((response) => {
        dispatch({
            type: 'LICENSES_ACTION_SUCCESS',
            payload: response.data.customers
        });
    }).catch((e) => {
        dispatch({
            type: 'LICENSE_ACTION_ERROR',
        })
        return false;
    });
}

export const getLicenseAction = (customer_id) => dispatch => {

    dispatch({
        type: 'LICENSE_ACTION_FETCH'
    })

    return api('get', '/license/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'LICENSE_ACTION_SUCCESS',
            payload: response.data.customer
        });
    }).catch((e) => {
        dispatch({
            type: 'LICENSE_ACTION_ERROR',
        })
        return false;
    });
}

export const resetLicenseAction = () => dispatch => {
    dispatch({
        type: 'LICENSE_ACTION_RESET'
    })
}
