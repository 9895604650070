import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressAction, getAddressFieldValuesAction, updateAddressFieldValuesAction, resetAddressFieldValuesAction } from '../../../../actions/addressesActions';
import { showSuccess } from '../../../../utils/Notifications'
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
} from 'antd';
import {Link} from 'react-router-dom';

const { Title, Text } = Typography;

class ProjectAddressFieldValues extends Component {
  state = {
    disabled: true,
    edit: true
  };

  componentDidMount() {
    this.props.resetAddressFieldValuesAction();

    if(this.props.match.params.addressid) {

      this.props.getAddressAction(this.props.match.params.addressid).then(() => {
          this.props.getAddressFieldValuesAction(this.props.match.params.addressid); //.then(() => {});
      });
    }

  }

  handleSubmit = e => {

    this.props.form.validateFields((err, values) => {
      if(!err)
      {
        this.props.updateAddressFieldValuesAction(this.props.match.params.addressid, values).then(()=>{
          showSuccess();
        });
      }
    });
  };

  render()
  {
    const { getFieldDecorator } = this.props.form;
    
    return (
    <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

      <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="4" {...this.props}/>

      <div style={{padding: 24, width: '100%'}}>

        <Row className="mbm">
          <Col span={24}>
            <div>
                <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                <Link to={`/projects/${this.props.match.params.id}/addresses`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>

            </Col>
          </Row>
          <Divider></Divider>

          <Title level={4}>{this.props.address.full_address}</Title>

          <Form onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
            <Row gutter={24}>

              { this.props.address_field_values && this.props.address_field_values.map((field, index) => {
                  return <Col span={24}>
                    {this.state.edit ? (
                      <Form.Item label={field.name} hasFeedback>
                        {getFieldDecorator('field-'+field.id, {
                          initialValue: field.value,
                          rules: [{ required: false }],
                        })(
                          <Input></Input>
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>{field.name}</Text><br />
                        <Text type="secondary">{field.value}</Text>
                      </div>
                    )}
                  </Col>
              }) }

            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const ProjectAddressFieldValuesForm = Form.create({ name: 'projects_address_field_values' })(ProjectAddressFieldValues);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.address.isFetching,
    isError: state.address.isError,
    address: state.address.address,
    address_field_values: state.address.address_field_values
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressAction: bindActionCreators(getAddressAction, dispatch),
    getAddressFieldValuesAction: bindActionCreators(getAddressFieldValuesAction, dispatch),
    updateAddressFieldValuesAction: bindActionCreators(updateAddressFieldValuesAction, dispatch),
    resetAddressFieldValuesAction: bindActionCreators(resetAddressFieldValuesAction, dispatch),
    HaveAbility: bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddressFieldValuesForm);
