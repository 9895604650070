import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid';

import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  DatePicker,
  Collapse,
  Select,
  Tooltip,
  Row,
  Col,
} from 'antd';
import CChoiceList from './CChoiceList';

const ButtonGroup = Button.Group;

class CChoiceInputForm extends React.Component {

  updateField(field, value)
  {
    if(!this.props.formData)
    {
        this.props.formData = [];
    }

    this.props.formData[field] = value;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        <Form.Item label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
            onChange: (event) => { this.updateField('label', event.target.value); }
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={(
          <span>
            Type keuze
            <Tooltip className="mhs" title='Bepaal via welk type selectie deze vraag in het formulier beantwoord mag worden. Via de selectie Radio kan een van de optie antwoorden worden gekozen. Via de selectie Checkbox kunnen er meerdere opties antwoorden worden geselecteerd.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('type', {
            initialValue: (this.props.formData && this.props.formData.type) || 'radio',
            onChange: (value) => { this.updateField('type', value); }
          })(
            <Select>
              {/*<Select.Option value="dropdown">Dropdown</Select.Option>*/}
              <Select.Option value="radio">Radio</Select.Option>
              <Select.Option value="check">Checkbox</Select.Option>
           </Select>
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Keuzelijst opties
            <Tooltip className="mhs" title='Definieer hier welke opties er in de selectie gekozen kunnen worden.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('options', {
            initialValue: this.props.formData && this.props.formData.options ? this.props.formData.options : [{'id': uuidv1() ,'label' : 'Optie 1', 'vat': '21%', 'price' : '0,00'}, {'id': uuidv1() ,'label' : 'Optie 2', 'vat': '21%', 'price' : '0,00'}, {'id': uuidv1() ,'label' : 'Optie 3', 'vat': '21%', 'price' : '0,00'}],
          })(
            <CChoiceList disablePrices={this.props.disablePrices} form={this.props.form} formId={this.props.formId} elementId={this.props.formData ? this.props.formData.id : null} />
          )}
        </Form.Item>

        {/*<Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld is verplicht</Checkbox>
          )}
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('listField', {
            initialValue: this.props.formData && this.props.formData.listField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op de meterkastlijst</Checkbox>
          )}
        </Form.Item>/**/}
      </Form>
    );
  }
}

const WrappedCChoiceInputForm = Form.create({ name: 'c_select_input_form' })(CChoiceInputForm);

class CChoiceInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {}, // || {post: true, digital: true},
      requiredField: ((this.props.parseData && this.props.parseData.requiredField != undefined && this.props.parseData.requiredField != false)) ? true : false,
      listField: ((this.props.parseData && this.props.parseData.listField != undefined && this.props.parseData.listField != false) || !this.props.parseData) ? true : false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  // renderExtraButtons = () => {
  //   return (
  //   <div onClick={(event) => event.stopPropagation()}>
  //     <Button.Group>
  //       <Popconfirm
  //         icon={(<Icon type="question-circle" />)}
  //         placement="top"
  //         onCancel={(event) => event.stopPropagation()}
  //         size="small"
  //         okType="danger"
  //         title={"Element verwijderen"}
  //         onConfirm={() => this.removeElement()}
  //         okText="Ja"
  //         cancelText="Nee"
  //         disabled={this.props.disabled}
  //       >
  //         <Icon
  //           type="delete"
  //           onClick={event => {
  //             event.stopPropagation();
  //           }}
  //         />
  //       </Popconfirm>
  //       <Icon
  //         style={{marginLeft: 5}}
  //         type="plus-square"
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           this.duplicateElement(this.props.id);
  //         }}
  //       />
  //     </Button.Group>
  //   </div>
  //   );
  // };

  toggleOption = (option) => {

      var checked = (this.state[option] == true ? false : true);

      this.setState({ [option]: checked }, () => {

          this.saveField();
      });
  };

  saveField = () => {

      var values = this.state.formData;

      // values.hide_for_occupant = this.state.hide_for_occupant;
      values.requiredField = this.state.requiredField;
      values.listField = this.state.listField;

      this.setState({ formData: values }); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  renderExtraButtons = () => {
    return (
      <div
          onClick={(event) => event.stopPropagation()}
          style={{ display: 'flex', marginTop: -1, marginRight: -13 }}
          >

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Vraag verplicht">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('requiredField');
                      }}
                      style={{ backgroundColor: (this.state.requiredField ? '#1890ff' : '#ffffff'), color: (this.state.requiredField ? '#ffffff' : '#000000') }}
                      >
                      <CheckOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

              <Tooltip title="Tonen op meterkastlijst">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('listField');
                      }}
                      style={{ backgroundColor: (this.state.listField ? '#1890ff' : '#ffffff'), color: (this.state.listField ? '#ffffff' : '#000000') }}
                      >
                      <ProfileOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Element verwijderen">
                  <Popconfirm
                    icon={(<QuestionCircleOutlined />)}
                    placement="bottom"
                    onCancel={(event) => event.stopPropagation()}
                    size="small"
                    okType="danger"
                    title={"Element verwijderen"}
                    onConfirm={() => this.removeElement()}
                    okText="Ja"
                    cancelText="Nee"
                    disabled={this.props.disabled}
                  >
                      <Button disabled={this.props.disabled}
                          size="small">
                          <DeleteOutlined
                            onClick={event => {
                                // event.stopPropagation();
                            }} />
                      </Button>
                  </Popconfirm>
              </Tooltip>

              <Tooltip title="Element dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.duplicateElement(this.props.id);
                      }}
                      >
                      <PlusSquareOutlined style={{marginLeft: 0}} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

      </div>
    );
  };

  saveFieldValues = (silent = false) => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          values.requiredField = this.state.requiredField;
          values.listField = this.state.listField;
          this.setState({ formData: values }); //, openKeys: [] });
          if(!silent){ this.setState({ openKeys: [] }); }
          this.props.onSaveData(this.props.id, values);
        }
      })
    }
  }

  saveField = () => {
      var values = this.state.formData;
      // values.post = this.state.post;
      // values.digital = this.state.digital;
      values.requiredField = this.state.requiredField;
      values.listField = this.state.listField;

      this.setState({formData: values}); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Keuze</b> - {this.state.formData && this.state.formData.label ? this.state.formData.label : 'label'}</div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              <WrappedCChoiceInputForm disablePrices={this.props.disablePrices} wrappedComponentRef={(form) => this.form = form} formId={this.props.formId} elementId={this.props.id} formData={this.state.formData} />
              <Button onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Keuze
        </div>
      );
    }
  }
}

export default CChoiceInput;
