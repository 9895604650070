import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { HaveRole } from '../../../utils/RolesAndAbilities';

import {
    ControlOutlined,
    EuroOutlined,
    ExperimentOutlined,
    HomeOutlined,
    LinkOutlined,
} from '@ant-design/icons';

import { Menu, Tag } from 'antd';
import { Link } from "react-router-dom";

class CustomerSideMenu extends Component {
   render() {
       return (
           <Menu
               mode="inline"
               style={{ width: 300}}
               defaultSelectedKeys={[this.props.activeMenu]}
           >
               <Menu.Item key={'1'}>
                   <Link to={`/customers/${this.props.id}/general`}>
                       <HomeOutlined />
                       <span>Algemeen</span>
                   </Link>
               </Menu.Item>
               {this.props.HaveRole(['super-admin']) ? (
                 <Menu.Item disabled={!this.props.id} key={'2'}>
                     <Link to={`/customers/${this.props.id}/personalize`}>
                         <ControlOutlined />
                         <span>Personalisatie</span>
                     </Link>
                 </Menu.Item>
               ) : null}

               {this.props.HaveRole(['super-admin']) ? (
                 <Menu.Item disabled={!this.props.id} key={'6'}>
                     <Link to={`/customers/${this.props.id}/connections`}>
                         <LinkOutlined />
                         <span>Integraties</span>
                     </Link>
                 </Menu.Item>
               ) : null}

               {this.props.HaveRole(['super-admin']) ? (
                 <Menu.Item disabled={!this.props.id} key={'3'}>
                     <Link to={`/customers/${this.props.id}/modules`}>
                         <ExperimentOutlined />
                         <span>Licentie</span>
                     </Link>
                 </Menu.Item>
               ) : null}

               {this.props.HaveRole(['super-admin']) ? (
                 <Menu.Item disabled={!this.props.id} key={'5'}>
                     <Link to={`/customers/${this.props.id}/transactions`}>
                         <EuroOutlined />
                         <span>Transacties</span>
                     </Link>
                 </Menu.Item>
               ) : null}

           </Menu>
       );
   }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
      HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSideMenu);
