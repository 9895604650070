import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './AccountsDetail.css';
import { bindActionCreators } from 'redux';
import _ from 'underscore'
import { connect } from 'react-redux';
import HasRole from '../../../components/HasRole/HasRole';
import { passwordValidation } from '../../../utils/validationRules'
import PageHeader from '../../../components/PageHeader/PageHeader';
import { getCustomersAction } from '../../../actions/customerActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { getAccountCustomerAction, patchAccountCustomerAction, resetAccountsAction, addAccountToCustomerAction, getAccountRolesAction, getAccountRoleAction } from '../../../actions/accountActions';
import { getProjectsAction, searchProjectsAction } from '../../../actions/projectActions';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Tooltip,
  Menu,
  Dropdown,
  Typography,
  Button,
  Divider,
  Input,
  Avatar,
  Select,
  Upload,
  message,
  Alert,
  Checkbox,
} from 'antd';
import NumberFormat from 'react-number-format';
import CustomTable from '../../../components/CustomTable/CustomTable';
import api from '../../../utils/api';
import { API_URL } from '../../../constants/settings';
import AccountsSideMenu from '../AccountsSideMenu';
const { Title, Text } = Typography;


const Option = Select.Option;

class AccountsDetail extends Component {
  state = {
    permissions: [],

    global_role: null,
    projects: null,
    user_projects: [],
    library: null,
    media: null,
    users: null,
    user_role: null,

    projects: '',
    library: '',
    media: '',
    accounts: '',
    all_projects: true,
    sorted_projects: [],
    loading: true,
  };

  componentDidMount() {

    var sorted_projects = [];

    this.props.resetAccountsAction();

    this.props.getProjectsAction().then(() => {

        this.setState({ projects: this.props.projects });

        if(this.props.match.params.id) {

          this.props.getAccountCustomerAction(this.props.match.params.id).then(() => {

            this.setState({ user_projects: this.props.account.projects }, () => {

                // console.log(this.props.account.customer_rights);

                var selectedRowKeys = [];

                this.props.account.projects && this.props.account.projects.map((project) => {
                    if(project.assigned)
                    {
                        selectedRowKeys.push(project.id);

                        this.props.projects.map((obj) => {
                            if(obj.id == project.id)
                            {
                                sorted_projects.push(obj);
                            }
                        });
                    }
                });

                this.props.projects.map((obj) => {
                    if(!selectedRowKeys.includes(obj.id))
                    {
                        sorted_projects.push(obj);
                    }
                })

                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    sorted_projects: sorted_projects
                }, () => {

                    this.loadRolesAndRights();
                });

                // this.props.getAccountRolesAction().then(() => {
                //
                //     var permissions = this.props.account && this.props.account.customer_rights.map((element) => {
                //
                //       this.setState({ [element.object]: element.type });
                //
                //       return element;
                //     });
                //
                //     this.setState({
                //         permissions: permissions,
                //         global_role: this.props.account && this.props.account.global_user_role,
                //         user_role: this.props.account && this.props.account.user_role,
                //     }, () => { /*this.selectGlobalRole(this.state.global_role)*/ });
                // });

                // this.loadRolesAndRights();
            });
          });
        }
        else
        {
            this.loadRolesAndRights();
        }
    });
  }

  loadRolesAndRights(){

      this.props.getAccountRolesAction().then(() => {

          var all_projects = false;

          var permissions = this.props.account && this.props.account.customer_rights && this.props.account.customer_rights.map((element) => {

            this.setState({ [element.object]: element.allowed ? element.type : '' });

            if(element.object == 'project_role' && element.type == 'admin'){ all_projects = true; }

            return element;
          });

          this.setState({
              permissions: permissions,
              global_role: this.props.account && this.props.account.global_user_role,
              user_role: this.props.account && this.props.account.user_role,
              all_projects: all_projects
          }, () => { this.setState({ loading: false }); });
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      values.user_role = values.global_role; // mapping
      values.project_ids = this.state.selectedRowKeys;

      if (!err) {
        if(this.props.match.params.id) {
          this.props.patchAccountCustomerAction(this.props.match.params.id, values).then(() => {
            this.props.history.push(`/accounts`);
          });
        } else {
          this.props.addAccountToCustomerAction(values).then(() => {
            this.props.history.push(`/accounts`);
          });
        }

        showSuccess();
      }
    });

  };

  sendPassword() {
    api('post', 'auth/forget', {"email":this.props.account.email}, false).then(() => {
      showSuccess('Wachtwoord verzonden');
    });
  }

  getInitialValue(object, default_value = 'read') {

    if(this.state.permissions) {

      var index = _.findKey(this.state.permissions, { 'object': object });

      if(index && this.state.permissions[index].allowed == 1) {

        return this.state.permissions[index].type;
      }
      else if(index && this.state.permissions[index].allowed == 0)
      {
          return ''; // no rights
      }
    }

    return default_value;
  }

  selectGlobalRole(role, overrule = true)
  {
      if(role == 'admin'){
          this.setState({ global_role: 'admin', user_role: 'admin', all_projects: true });

          if(overrule)
          {
              this.setState({
                  projects: this.getInitialValue('projects', 'delete'), // 'write',
                  library: this.getInitialValue('library', 'delete'), // 'write',
                  media: this.getInitialValue('media', 'delete'), // 'write',
                  users: this.getInitialValue('users', 'delete'), // 'write',
              });
          }
      }
      else if(role == 'user')
      {
          this.setState({ global_role: 'user', user_role: 'user', all_projects: false });

          if(overrule)
          {
              this.setState({
                  projects: this.getInitialValue('projects', 'read'), // 'read',
                  library: this.getInitialValue('library', 'read'), // 'read',
                  media: this.getInitialValue('media', 'read'), // 'read',
                  users: this.getInitialValue('users', 'read'), // 'read',
              });
          }
      }
      else
      {
          var project_role = this.getRoleValue(role, 'project_role');

          this.setState({ global_role: project_role, user_role: project_role });

          if(overrule)
          {
              this.setState({
                  projects: this.getRoleValue(role, 'projects'),
                  library: this.getRoleValue(role, 'library'),
                  media: this.getRoleValue(role, 'media'),
                  users: this.getRoleValue(role, 'users'),
                  all_projects: project_role == 'admin' ? true : false
              });
          }
      }
  }

  getRoleValue = (role_id, right) => {

      var access = '';

      this.props.accountroles.map((role) => {
          if(role.id == role_id)
          {
              role.rights.map((role_right) => {
                 if(role_right.object == right)
                 {
                     access = role_right.access;
                 }
              });
          }
      });

      return access;
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  hasDifferentRights(project)
  {
      var status = false;

      // this.state.user_projects.map((user_project) => {
      this.props.account && this.props.account.projects && this.props.account.projects.map((user_project) => {
          if(user_project.id == project.id && user_project.different_rights)
          {
              status = true;
          }
      });

      return status;
  }

  render() {

    // if(this.state.loading){ return null; }

    const { getFieldDecorator } = this.props.form;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
    };

    return (
      <div>

        <PageHeader path={[{'label': 'Accounts', 'link': '/accounts'}, {'label': this.props.match.params.id && this.props.account ? this.props.account.email : 'Toevoegen'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <AccountsSideMenu activeMenu='1' />
            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12} >
                    <Button disabled={!this.props.HaveAbility('users' ,'write')} type="new" onClick={this.handleSubmit}>
                      Opslaan
                    </Button>
                    <Button onClick={() => this.props.history.push('/accounts')} style={{marginRight: 10}}>Sluiten</Button>
                  </Col>
                </Row>

                <Card style={{backgroundColor: 'white', borderRadius: '5px' }}>

                {!this.props.match.params.id ? (
                  <Alert
                    message="Let op!"
                    description="Na het toevoegen van een account ontvangt deze persoon automatisch een e-mail om het account te activeren. Het account dient nog wel als gebruiker toegevoegd te worden aan een project."
                    type="info"
                    showIcon
                    style={{marginBottom: 24}}
                  />
                ): null}
                <Form layout={'vertical'} onSubmit={this.handleSubmit} colon={true}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item required label="E-mail" hasFeedback>
                        {getFieldDecorator('email', {
                          initialValue: this.props.account && this.props.account.email,
                          rules: [{ required: true, message: 'Vul een e-mailadres in'}, {type: 'email', message: 'Vul een geldig e-mailadres in'}],
                        })(
                          <Input disabled={this.props.match.params.id}></Input>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col span={8}>
                          <Form.Item className="mrs" required label="Voornaam" hasFeedback>
                            {getFieldDecorator('first_name', {
                              initialValue: this.props.account && this.props.account.first_name,
                              rules: [{ required: true, message: 'Vul een voornaam in' }],
                            })(
                              <Input></Input>
                            )}
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item className="mrs" label="Tussenvoegsel" hasFeedback>
                            {getFieldDecorator('affix', {
                              initialValue: this.props.account && this.props.account.affix,
                              rules: [{ required: false }],
                            })(
                              <Input></Input>
                            )}
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item className="mrs" required label="Achternaam" hasFeedback>
                            {getFieldDecorator('last_name', {
                              initialValue: this.props.account && this.props.account.last_name,
                              rules: [{ required: true, message: 'Vul een achternaam in' }],
                            })(
                              <Input></Input>
                            )}
                          </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item required label="Rol" hasFeedback>
                        {getFieldDecorator('global_role', {
                          initialValue: this.props.account && this.props.account.global_user_role,
                          rules: [{ required: true, message: 'Geef een rol op'}],
                          onChange: (value) => this.selectGlobalRole(value)
                        })(
                          <Select>
                              { this.props.account.global_user_role == 'admin' ? <Option key={0} value={'admin'}>Beheerder (oude rol, vervang en niet meer gebruiken)</Option> : null }
                              { this.props.account.global_user_role == 'user' ? <Option key={1} value={'user'}>Gebruiker (oude rol, vervang en niet meer gebruiken)</Option> : null }
                              { this.props.accountroles && this.props.accountroles.map((element, index) => {
                                  // return <Option value={element.id}>{element.name} ({ element.project_role == 'admin' ? 'mag bij alle projecten' : 'mag alleen bij opgegeven projecten' })</Option>
                                  return <Option key={index+2} value={element.id}>{element.name}</Option>
                              }) }
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  { this.state.loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : null }

                  { this.state.global_role ?
                  <div>
                  {/*<Row gutter={24}>
                    <Col span={24}>
                      <Alert
                        message={(
                          <div>
                            Bepaal welke rechten dit account mag hebben op de menu-items Projecten, Bibliotheek, Media en Accounts binnen Cobee. De rechten die horen bij de geselecteerde rol kunnen hier nog worden aangepast.
                            <br /><br />
                            <b>Bewerken</b>: <br />je geeft dit account toegang tot het werken in dit menu-item. Het account kan in dit menu-item Toevoegen, Wijzigingen aanbrengen en Verwijderen.<br /><br />
                            <b>Alleen bekijken</b>: <br />je geeft het account toegang tot het menu-item, maar de gebruiker kan in dit menu-item niets Toevoegen, Wijzigen of Verwijderen. Alle knoppen in de module zijn disabled.<br /><br />
                            <b>Geen toegang</b>: <br />je geeft het account geen toegang tot dit menu-item. Het menu-item wordt voor het account niet getoond.
                          </div>
                        )}
                        type="info"
                        showIcon
                        style={{marginBottom: 24}}
                        />
                    </Col>
                    <Col span={12}>

                        <Form.Item label={"Projecten"}>
                          {getFieldDecorator("projects", {
                            // initialValue: this.getInitialValue('projects'),
                            initialValue: this.state.projects,
                            onChange: (value) => this.setState({ projects: value })
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Bibliotheek"}>
                          {getFieldDecorator("library", {
                            // initialValue: this.getInitialValue('library'),
                            initialValue: this.state.library
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Media"}>
                          {getFieldDecorator("media", {
                            // initialValue: this.getInitialValue('media'),
                            initialValue: this.state.media
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={"Accounts"}>
                          {getFieldDecorator("users", {
                            // initialValue: this.getInitialValue('users'),
                            initialValue: this.state.users
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>*/}

                    { !this.state.all_projects && this.props.projects ?
                    <div>
                        <Row>
                            <Col span={12}>
                                <h2>Projecten van deze gebruiker</h2>
                            </Col>
                            <Col span={12}>
                              <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={ (value) => { this.props.searchProjectsAction(value).then(() => { this.setState({ sorted_projects: this.props.projects }); }); } }
                                style={{ width: 200, float: 'right' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                          <Col span={24}>

                            <CustomTable
                              size="middle"
                              rowKey='id'
                              rowSelection={rowSelection}
                              loading={this.state.loading}
                              columns={
                                  [{
                                    title: 'Project',
                                    dataIndex: 'name',
                                    sorter: (a, b) => { return a.name.localeCompare(b.name)},
                                    render: (text, record) => (
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Link to={`/projects/${record.id}/dashboard`}>{text}</Link>
                                        { this.hasDifferentRights(record) ?
                                          <Tooltip className="mhs" title='Let op: dit project heeft afwijkende rechten ingesteld.'>
                                            <ExclamationCircleOutlined style={{ color: "rgb(250, 173, 20)" }} />
                                          </Tooltip> : null
                                        }
                                      </div>
                                    )
                                  },
                                  {
                                    title: 'Nummer',
                                    dataIndex: 'project_number',
                                    sorter: (a, b) => { return a.project_number.localeCompare(b.project_number)},
                                    sortDirections: ['descend', 'ascend'],
                                    render: (text, record) => (
                                      <span>{text}</span>
                                    )
                                  }]
                              }
                              dataSource={this.state.sorted_projects}
                              />
                          </Col>
                        </Row>
                    </div>
                    : null }
                    </div>
                    : null }
                  </Form>
                </Card>
            </div>
        </div>
      </div>
    );
  }
}

const AccountDetailForm = Form.create({ name: 'account_detail' })(AccountsDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.account.isFetching,
    isError : state.account.isError,
    account: state.account.account,
    customers: state.customer.customers,
    accountroles: state.account.accountroles,
    accountrole: state.account.accountrole,
    projects: state.project.projects,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountCustomerAction : bindActionCreators(getAccountCustomerAction, dispatch),
    patchAccountCustomerAction : bindActionCreators(patchAccountCustomerAction, dispatch),
    resetAccountsAction : bindActionCreators(resetAccountsAction, dispatch),
    addAccountToCustomerAction : bindActionCreators(addAccountToCustomerAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    getAccountRolesAction : bindActionCreators(getAccountRolesAction, dispatch),
    getAccountRoleAction : bindActionCreators(getAccountRoleAction, dispatch),
    getProjectsAction : bindActionCreators(getProjectsAction, dispatch),
    searchProjectsAction : bindActionCreators(searchProjectsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailForm);
