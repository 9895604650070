import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import {
  CheckOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Button, Card, Input, Checkbox, Popconfirm, Select, Collapse, Tooltip } from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { Editor } from '@tinymce/tinymce-react';

const ButtonGroup = Button.Group;

class CTitleInputForm extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        {/*<Form.Item required label='Inhoud' style={{}}>
          {getFieldDecorator('wysiwyg', {
            initialValue: this.props.formData && this.props.formData.wysiwyg,
            valuePropName: 'value',
            trigger: 'onEditorChange',
            rules: [{ required: true, message: 'Vul inhoud in' }],
          })(
            <Editor key={uuidv1()} ref={editor => this.editor = editor} id={'editor_'+uuidv1()} />
          )}
        </Form.Item>*/}
        <CustomEditor value={this.props.formData && this.props.formData.wysiwyg} /*onEditorChange={(text) => this.setState({wysiwyg: text})}*/ />

        {/*<Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('formField', {
            initialValue: this.props.formData && this.props.formData.formField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op het PDF formulier</Checkbox>
          )}
        </Form.Item>*/}
      </Form>
    );
  }
}

const WrappedCTitleInputForm = Form.create({ name: 'title_input_form' })(CTitleInputForm);

class CTitleInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {},
      openKeys: [],
      wysiwyg: '',
      formField: false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }

    this.setState({
        wysiwyg: this.props.parseData && this.props.parseData.wysiwyg,
        formField: (this.props.parseData ? (this.props.parseData.formField === true || this.props.parseData.formField === undefined ? true : false) : true),
    });
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  saveFieldValues = (silent = false) => {
    if(this.state.modus == 'edit') {

      var values = { wysiwyg: this.state.wysiwyg, formField: this.state.formField ? true : false };

      values.requiredField = this.state.requiredField;
      values.listField = this.state.listField;

      this.setState({ formData: values });

      if(!silent){ this.setState({ openKeys: [] }); }

      this.props.onSaveData(this.props.id, {wysiwyg: this.state.wysiwyg, formField: this.state.formField ? true : false}, silent);
    }
  }

  // saveFieldValues = (silent = false) => {
  //   if(this.state.modus == 'edit') {
  //     this.form.props.form.validateFields((error, values) => {
  //       if(!error) {
  //         values.requiredField = this.state.requiredField;
  //         values.listField = this.state.listField;
  //         this.setState({ formData: values }); //, openKeys: [] });
  //         if(!silent){ this.setState({ openKeys: [] }); }
  //         this.props.onSaveData(this.props.id, values);
  //       }
  //     })
  //   }
  // }

  // saveField = () => {
  //
  //     var values = this.state.formData;
  //
  //     values.requiredField = this.state.requiredField;
  //     values.listField = this.state.listField;
  //
  //     this.setState({formData: values}); // , openKeys: []});
  //
  //     this.props.onSaveData(this.props.id, values);
  // }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  // renderExtraButtons = () => {
  //   return (
  //     <Button.Group>
  //       <Popconfirm
  //         icon={(<Icon type="question-circle" />)}
  //         placement="top"
  //         onCancel={(event) => event.stopPropagation()}
  //         size="small"
  //         okType="danger"
  //         title={"Element verwijderen"}
  //         onConfirm={() => this.removeElement()}
  //         okText="Ja"
  //         cancelText="Nee"
  //         disabled={this.props.disabled}
  //       >
  //         <Icon
  //           type="delete"
  //           onClick={event => {
  //             event.stopPropagation();
  //           }}
  //         />
  //       </Popconfirm>
  //       <Icon
  //         style={{marginLeft: 5}}
  //         type="plus-square"
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           this.duplicateElement(this.props.id);
  //         }}
  //       />
  //     </Button.Group>
  //   );
  // };

  renderExtraButtons = () => {
    return (
      <div
          onClick={(event) => event.stopPropagation()}
          style={{ display: 'flex', marginTop: -1, marginRight: -13 }}
          >



          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Vraag verplicht">
                  <Button disabled={true}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('requiredField');
                      }}
                      style={{ backgroundColor: (this.state.requiredField ? '#1890ff' : '#ffffff'), color: (this.state.requiredField ? '#ffffff' : '#888888') }}
                      >
                      <CheckOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

              <Tooltip title="Tonen op meterkastlijst">
                  <Button disabled={true}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('listField');
                      }}
                      style={{ backgroundColor: (this.state.listField ? '#1890ff' : '#ffffff'), color: (this.state.listField ? '#ffffff' : '#888888') }}
                      >
                      <ProfileOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Element verwijderen">
                  <Popconfirm
                    icon={(<QuestionCircleOutlined />)}
                    placement="bottom"
                    onCancel={(event) => event.stopPropagation()}
                    size="small"
                    okType="danger"
                    title={"Element verwijderen"}
                    onConfirm={() => this.removeElement()}
                    okText="Ja"
                    cancelText="Nee"
                    disabled={this.props.disabled}
                  >
                      <Button disabled={this.props.disabled}
                          size="small">
                          <DeleteOutlined
                            onClick={event => {
                                // event.stopPropagation();
                            }} />
                      </Button>
                  </Popconfirm>
              </Tooltip>

              <Tooltip title="Element dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.duplicateElement(this.props.id);
                      }}
                      >
                      <PlusSquareOutlined style={{marginLeft: 0}} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

      </div>
    );
  };

  onChangeCollapse = (panel) => {
    if(panel) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.setState({modus: 'show', openKeys: []});
    }
  }

  render (){

    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Toelichting</b></div>)} extra={this.renderExtraButtons()} >
            {this.state.openKeys.length > 0 && (
            <div style={{padding: 15}}>
              {/*<WrappedCTitleInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />*/}

                <CustomEditor value={this.state.wysiwyg} onEditorChange={(text) => { this.setState({wysiwyg: text}); this.saveFieldValues(true); }} />

                <Form.Item style={{marginBottom: 0}}>
                  <Checkbox checked={this.state.formField} onChange={(e) => { this.setState({ formField: e.target.checked }); }}>Dit veld tonen op het PDF formulier</Checkbox>
                </Form.Item>

              <Button style={{ marginTop: 15 }} onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
            )}
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Titel
        </div>
      );
    }
  }
}

export default CTitleInput;
