import api from '../utils/api'

export const getLetterPapersAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'LETTERPAPERS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/letterpapers/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'LETTERPAPERS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'LETTERPAPERS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchLetterPapersAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'LETTERPAPERS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/letterpapers/'+customer_id+'/search', {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_LETTERPAPERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_LETTERPAPERS_ACTION_ERROR',
    })
    return false;
  });
}

export const getLetterPaperAction = (id) => dispatch => {

  dispatch({
    type: 'LETTERPAPER_ACTION_FETCH'
  })

  return api('get','/library/letterpaper/'+id, null, true).then((response) => {
    dispatch({
      type: 'LETTERPAPER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'LETTERPAPERS_ACTION_ERROR',
    })
    return false;
  });
}

export const storeLetterPaperAction = (params, files) => (dispatch, getState) => {

  dispatch({
    type: 'LETTERPAPERS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/letterpapers/'+customer_id, params, true, files).then((response) => {
    dispatch({
      type: 'STORE_LETTERPAPER_ACTION_SUCCESS',
      payload: response.data.letterpaper
    });
  }).catch((e) => {
    dispatch({
      type: 'LETTERPAPERS_ACTION_ERROR',
    })
    return false;
  });
}

export const patchLetterPaperAction = (id, params, files) => dispatch => {

  dispatch({
    type: 'LETTERPAPERS_ACTION_FETCH'
  })

  return api('post','/library/letterpaper/'+id, params, true, files).then((response) => {
    dispatch({
      type: 'LETTERPAPER_ACTION_SUCCESS',
      payload: response.data.letterpaper
    });
  }).catch((e) => {
    dispatch({
      type: 'LETTERPAPERS_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteLetterPapersAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/library/letterpapers/' + customer_id + '/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_LETTERPAPERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const resetLetterPaperAction = () => dispatch => {

  dispatch({
    type: 'RESET_CUSTOMER_ACTION_ERROR',
  })

}
