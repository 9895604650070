import React, { Component } from 'react';
import Media from "react-media";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getStatusAction, searchStatusAction, getUsersAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, viewDeliveryFormPDFAction, viewDeliveryFormLogAction, emailDeliveryFormPDFAction } from '../../../actions/deliverActions';
import { getNrOfNewQueuedItems } from '../../../actions/queueActions';
import DeliverSideMenu from './DeliverSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Modal, Select, Dropdown, Menu } from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import './deliver.css';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Option } = Select;

class DeliverStatus extends Component
{
    state = {
        disabled: true,
        edit: true,
        selectedRowKeys: [],
        is_archive: false,
        show_status_modal: false,
        show_user_modal: false,
        show_export_modal: false,
        can_write: false,
        can_delete: false,
        expandedRowKeys: [],
        toggleAll: false
    };

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write'),
            can_delete: this.props.HaveAbility('deliver', 'delete'),
        });

        this.loadData();
    }

    loadData()
    {
        // filters
        this.props.getDeliveryFilterAction(this.props.match.params.id);
        this.props.getFormFilterAction(this.props.match.params.id);
        this.props.getLocationFilterAction(this.props.match.params.id);

        // points
        this.props.getStatusAction(this.props.match.params.id); // .then(() => {});
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });
    }

    handleStatus = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.status)
            {   // ok
                this.props.multiUpdatePointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, status: values.status}).then(() => {

                    showSuccess('Succesvol opgeslagen');

                    this.loadData();
                });

                // hide & deselect
                this.setState({
                    show_status_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    getStatusText(status)
    {
        if(status == 'completed'){ return 'Afgehandeld'; }
        else { return 'Open'; }
    }

    getStatusColor(status)
    {
        if(status == 'completed'){ return '#00ff00'; }
        else { return '#ff0000'; }
    }

    getDeliveryFilters()
    {
        var filters = [];

        this.props.delivery_filters && this.props.delivery_filters.map((record) => {

            filters.push({
                text: record.description,
                value: record.id
            });
        });

        return filters;
    }

    getFormsFilters()
    {
        var filters = [];

        this.props.form_filters && this.props.form_filters.map((record) => {

            filters.push({
                text: record.name,
                value: record.id
            });
        });

        return filters;
    }

    getAddressFilters()
    {
        var filters = [];

        this.props.location_filters && this.props.location_filters.map((record) => {

            if(record.type == 'address')
            {
                filters.push({
                    text: record.full_address,
                    value: record.id
                });
            }
        });

        return filters;
    }

    viewDeliveryFormPDF(form_id, delivery_id = false)
    {
        this.props.viewDeliveryFormPDFAction(form_id, delivery_id).then(() => {

            showSuccess('Export succesvol aangevraagd');

            this.props.getNrOfNewQueuedItems(); // refresh topnav counter
        });
    }

    viewDeliveryFormLog(form_id)
    {
        this.props.viewDeliveryFormLogAction(form_id).then(() => {

            showSuccess('Export succesvol aangevraagd');

            this.props.getNrOfNewQueuedItems(); // refresh topnav counter
        });
    }

    showEmailForm(form_id, delivery_id = false)
    {
        this.setState({ show_email_form: form_id, show_email_delivery_id: delivery_id });
    }

    emailDeliveryFormPDF(form_id, delivery_id = false)
    {
        this.props.emailDeliveryFormPDFAction(form_id, this.state.email, delivery_id).then(() => {

            showSuccess('E-mail wordt verzonden');

            this.setState({ email: '', show_email_form: false });
        });
    }

    toggleAll()
    {
        var keys = [];

        if(this.state.toggleAll)
        {   // close all
            this.setState({
                toggleAll: false,
                expandedRowKeys: keys
            });
        }
        else
        {   // open all
            this.props.status.map((record) => {

                if((record.children && record.children.length) || record.deliveries_combined)
                {
                    keys.push('parent-'+record.id);
                }
            });

            this.setState({
                toggleAll: true,
                expandedRowKeys: keys
            });
        }
    }

    render()
    {
        const getResponsiveColumns = smallScreen => columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

        const columns = [
            {
                title: 'Formulier',
                dataIndex: 'name',
                sorter: (a, b) => { return a.name.localeCompare(b.name)},
                render: (text, record) => (
                    <span>
                        {text}
                    </span>
                ),
                filters: this.getFormsFilters(),
                onFilter: (value, record) => record.id === value,
            },
            {
                title: 'Oplevering(en)',
                dataIndex: 'description',
                sorter: (a, b) => { return a.description.localeCompare(b.description)},
                render: (text, record) => (
                    <span>{text}</span>
                ),
                filters: this.getDeliveryFilters(),
                onFilter: (value, record) => ((record.delivery_ids && record.delivery_ids.includes(value)) /*|| record.delivery_id === value*/),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              sorter: (a, b) => { return a.status.localeCompare(b.status)},
              render: (text, record) => (
                this.getStatusText(text)
              ),
              filters: [
                {
                  text: 'Open',
                  value: 'open',
                },
                {
                  text: 'Afgerond',
                  value: 'signed',
                }
              ],
              onFilter: (value, record) => (typeof record.status == 'array' ? record.status.includes(value) : record.status == value),
              width: 110
            },
            {
                title: 'Adres',
                dataIndex: 'address',
                sorter: (a, b) => { return a.address.localeCompare(b.address)},
                render: (text, record) => (
                    <span>
                        {text}
                    </span>
                ),
                filters: this.getAddressFilters(),
                onFilter: (value, record) => (typeof record.address_id == 'array' ? record.address_id.includes(value) : record.address_id == value),
            },
            {
                title: 'Uiterlijk gereed',
                dataIndex: 'due_date',
                sorter: (a, b) => { return a.due_date.localeCompare(b.due_date)},
                render: (text, record) => (
                    !record.delivery_form_id
                    ?
                    <span style={{color: (record.filter !== 'expired' ? '#000000' : '#ff0000')}}>
                        {record.expired ? 'Verlopen' : (text ? moment(text).format('DD/MM/YYYY') : 'Niet ingesteld')}
                    </span>
                    :
                    null
                ),
                filters: [
                  {
                    text: 'Verlopen',
                    value: 'expired',
                  },
                  {
                    text: 'Niet verlopen',
                    value: 'not-expired',
                  },
                  {
                    text: 'Niet ingesteld',
                    value: 'not-set',
                  },
                ],
                onFilter: (value, record) => record.due_date_filter === value,
            },
            {
                title: 'Acties',
                dataIndex: 'actions',
                render: (text, record) => {

                    const show_actions = record.children && record.children.length > 0 ? false : true;

                    return (
                        <span>
                        { show_actions ?
                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item onClick={() => { if(record.delivery_form_id){ this.viewDeliveryFormPDF(record.delivery_form_id, record.id); }else{ this.viewDeliveryFormPDF(record.id); } }}>Bekijk formulier</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item onClick={() => { if(record.delivery_form_id){ this.viewDeliveryFormLog(record.delivery_form_id, record.id); }else{ this.viewDeliveryFormLog(record.id); } }}>Bekijk log</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item onClick={() => { if(record.delivery_form_id){ this.showEmailForm(record.delivery_form_id, record.id); }else{ this.showEmailForm(record.id); } /*this.showEmailForm(record.id);*/ }}>E-mail formulier</Menu.Item>
                                </Menu>
                            )}>
                                <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
                                    Acties <DownOutlined />
                                </Button>
                            </Dropdown>
                            :
                            null }
                        </span>
                    );
                }
            }
        ];

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu={['10']} />

                <div style={{padding: 24, width: '100%'}}>

                    <Row className="mbm">
                        <Col span={12}>
                            <Button onClick={() => { this.toggleAll(); }}>Alles { !this.state.toggleAll ? 'open' : 'dicht' }klappen</Button>
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchStatusAction(this.props.match.params.id, value); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>

                          <CustomTable
                              rowKey={(record) => {
                                  if((record.children && record.children.length > 1) || record.deliveries_combined) {
                                      return 'parent-'+record.id;
                                  } else {
                                      return record.id;
                                  }
                              }}
                              size="middle"
                              columns={columns}
                              dataSource={this.props.status}
                              loading={this.props.isFetching}
                              expandedRowKeys={this.state.expandedRowKeys}
                              onExpandedRowsChange={(id) => {
                                  this.setState({expandedRowKeys: id});
                              }}
                              />

                        </Col>
                    </Row>

                </div>

                <Modal
                      title="E-mail formulier"
                      visible={this.state.show_email_form ? true : false}
                      onCancel={() => this.setState({ show_email_form: false })}
                      onOk={() => { this.emailDeliveryFormPDF(this.state.show_email_form, this.state.show_email_delivery_id); }}
                      okText="Verstuur"
                      >
                    <Form.Item label="Verstuur download link naar">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item required label="E-mail">
                                  { getFieldDecorator('email', {
                                    initialValue: [],
                                    rules: [{ required: true, message: 'Vul minimaal één e-mailadres in'}],
                                  })(
                                    <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({email: value.join(',')}); }}></Select>
                                  ) }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Modal>

            </div>
        );
    }
}

const DeliverStatusForm = Form.create({ name: 'points' })(DeliverStatus);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        status : state.deliver.status,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        location_filters : state.deliver.location_filters
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStatusAction : bindActionCreators(getStatusAction, dispatch),
        searchStatusAction : bindActionCreators(searchStatusAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        viewDeliveryFormPDFAction : bindActionCreators(viewDeliveryFormPDFAction, dispatch),
        viewDeliveryFormLogAction : bindActionCreators(viewDeliveryFormLogAction, dispatch),
        emailDeliveryFormPDFAction : bindActionCreators(emailDeliveryFormPDFAction, dispatch),
        getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
        HaveAbility :  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverStatusForm);
