import api from '../utils/api';


export const getCustomerFormsAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'FORMS_CUSTOMER_ACTION_FETCH'
  });

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/forms/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_FORMS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORMS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchCustomerFormsAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'FORMS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/forms/'+customer_id+'/search', {search: search}, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_FORMS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORMS_ACTION_ERROR',
    })
    return false;
  });
}

export const addCustomerFormAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/forms/'+customer_id, params, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_FORM_ACTION_SUCCESS',
      payload: response.data.form
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const updateCustomerFormAction = (form_id, params) => dispatch => {
  dispatch({
    type: 'FORMS_ACTION_FETCH'
  })

  return api('post','/library/form/'+form_id, params, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_FORM_ACTION_SUCCESS',
      payload: response.data.form
    });
  }).catch((e) => {
    dispatch({
      type: 'FORMS_ACTION_ERROR',
    })
    return false;
  });
}

export const removeCustomerFormsAction = (params) => (dispatch, getState) => {
  dispatch({
    type: 'FORMS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/forms/'+customer_id+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'REMOVE_CUSTOMER_FORMS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORMS_ACTION_ERROR',
    })
    return false;
  });
}

export const getFormAction = (form_id, /*price_calculation = true, statistics = false,*/ enriched = false) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    return api('get','/form/'+form_id+(enriched ? '/enriched' : ''), null, true).then((response) => {
        dispatch({
            type: 'FORM_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const getFormsResultsAction = (project_id, form_ids) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    return api('post','/forms/project/' + project_id + '/results', { form_ids: form_ids }, true).then((response) => {
        dispatch({
            type: 'FORMS_RESULTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const addFormAction = (project_id, values) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ADD_ACTION_FETCH'
    })

    return api('post','/forms/project/' + project_id + '/add', values, true).then((response) => {
        dispatch({
            type: 'FORM_ADD_ACTION_SUCCESS',
            payload: response.data.form
        });

        return response.data.form;
    }).catch((e) => {
        dispatch({
            type: 'FORM_ADD_ACTION_ERROR',
        })
        return false;
    });
}

export const updateFormAction = (form_id, values) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_UPDATE_ACTION_FETCH'
    })

    return api('post','/form/' + form_id + '/update', values, true).then((response) => {
        dispatch({
            type: 'FORM_UPDATE_ACTION_SUCCESS',
            payload: response.data.form
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_UPDATE_ACTION_ERROR',
        })
        return false;
    });
}

export const getFormsAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'FORMS_ACTION_FETCH'
    })

    return api('get','/forms/project/' + project_id, null, true).then((response) => {
        dispatch({
            type: 'FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const searchFormsAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    return api('post','/forms/project/' + project_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneFormsAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    return api('post','/forms/project/' + project_id + '/clone', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'FORMS_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteFormsAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    return api('post','/forms/project/' + project_id + '/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'FORMS_DELETE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const unlockFormsAction = (form_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    });

    return api('post','/form/' + form_id + '/entries/unlock', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'FORMS_UNLOCK_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'FORM_ACTION_ERROR',
        })
        return false;
    });
}

export const previewFormAction = (form_id) => (dispatch, getState) => {

    return api('get', '/form/' + form_id + '/preview', null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'text/html' });

        reader.readAsText(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write(reader.result);

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const downloadFormEntryAction = (form_id, id) => (dispatch, getState) => {

    return api('post','/form/' + form_id + '/export/zip', {ids: [id]}, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'application/zip' });

        reader.readAsDataURL(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const duplicateCloneAction = (id, type, type_id) => dispatch => {

  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  var params = {};

  if(type == 'customer'){ params.customer_id = type_id; }
  else if(type == 'project'){ params.project_id = type_id; }

  return api('post','/form/'+id+'/clone', params, true).then((response) => {
    dispatch({
      type: 'DUPLICATE_FORM_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const resetFormAction = () => dispatch => {

  dispatch({
    type: 'FORM_ACTION_RESET'
  })
}


/* status */

export const getFormsStatusAction = (projectid) => dispatch => {

  dispatch({
    type: 'FORMS_STATUS_ACTION_FETCH'
  })

  return api('get','/forms/'+projectid+'/status', null, true).then((response) => {
    dispatch({
      type: 'FORMS_STATUS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const searchInFormsStatusAction = (project_id, search) => dispatch => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    var params = {search: search};

    return api('post', '/forms/' + project_id + '/status/search', params, true).then((response) => {

        dispatch({
            type: 'FORMS_STATUS_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data.results;

    }).catch((e) => {

        dispatch({
            type: 'FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const unlockFormEntryAction = (form_id, form_entry_id) => dispatch => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    return api('post', '/form/' + form_id + '/entry/' + form_entry_id + '/unlock', null, true).then((response) => {

        dispatch({
            type: 'FORM_UNLOCK_STATUS_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data.results;

    }).catch((e) => {

        dispatch({
            type: 'FORM_ACTION_ERROR',
        });

        return false;
    });
}


/* menu */

export const getFormMenuAction = (projectid) => dispatch => {

  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  return api('get','/form/menu/'+projectid, null, true).then((response) => {
    dispatch({
      type: 'FORM_MENU_ITEMS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const getFormMenuItemAction = (menu_item_id) => dispatch => {

  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  return api('get','/form/menu/item/'+menu_item_id, null, true).then((response) => {
    dispatch({
      type: 'FORM_MENU_ITEM_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const addFormMenuAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  return api('post','/form/menu/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'ADD_FORM_MENU_ACTION_SUCCESS',
      payload: response.data.form_menu_item
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const resetFormMenuAction = () => dispatch => {

  dispatch({
    type: 'FORM_MENU_ACTION_RESET'
  })
}

export const patchFormMenuAction = (menu_item_id, params) => dispatch => {
  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  return api('post','/form/menu/item/'+menu_item_id, params, true).then((response) => {
    dispatch({
      type: 'FORM_MENU_ITEM_ACTION_SUCCESS',
      payload: response.data.form_menu_item
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const removeFormMenuAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  return api('post','/form/menu/'+projectid+'/multidelete/', params, true).then((response) => {

    console.log(response);
    dispatch({
      type: 'FORM_MENU_ITEM_ACTION_REMOVE',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const sortFormMenuAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'FORM_ACTION_FETCH'
  })

  return api('post','/form/menu/order/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'SORT_FORM_MENU_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'FORM_ACTION_ERROR',
    })
    return false;
  });
}

export const searchInFormsAction = (project_id, form_ids, search) => dispatch => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    });

    return api('post', '/forms/project/' + project_id + '/results/search', { form_ids: form_ids, search: search }, true).then((response) => {

        dispatch({
            type: 'FORMS_RESULTS_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data.results;

    }).catch((e) => {

        dispatch({
            type: 'FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const searchInFormAction = (form_id, search, filter = false) => dispatch => {

    dispatch({
        type: 'FORM_ACTION_FETCH'
    })

    var params = {search: search};

    if(filter)
    {
        params.filter = true;
    }

    return api('post', '/form/' + form_id + '/search', params, true).then((response) => {

        dispatch({
            type: 'FORM_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data.results;

    }).catch((e) => {

        dispatch({
            type: 'FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const exportFormAction = (form_id, type, subtype, ids) => (dispatch, getState) => {

  return api('post', '/form/' + form_id + '/export', {type: type, subtype: subtype, ids: ids}, true, null, true).then((response) => {
    dispatch({
      type: 'EXPORT_FORM_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    return false;
  });
}

export const exportFormsAction = (project_id, form_ids, subtype, result_ids, format = false) => (dispatch, getState) => {

  return api('post', '/forms/' + project_id + '/export', {form_ids: form_ids, type: 'zip', subtype: subtype, ids: result_ids, format: format}, true, null, true).then((response) => {

    dispatch({

      type: 'EXPORT_FORMS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {

    return false;
  });
}
