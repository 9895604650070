export default (state = {isError: false, isFetching: false, intakes: [], intake: {}, intake_form: { results: [] }, cloning_intakes: []}, action) => {
    switch (action.type) {
    case 'INTAKE_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'INTAKE_ACTION_SUCCESS':
    return {
        ...state,
        intake_form: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_INTAKE_ACTION_SUCCESS':
    return {
        ...state,
        intake_form: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INTAKES_ACTION_SUCCESS':
    return {
        ...state,
        intakes: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CLONING_INTAKES_ACTION_SUCCESS':
    return {
        ...state,
        cloning_intakes: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DELETE_INTAKES_ACTION_SUCCESS':
    return {
        ...state,
        intakes: state.intakes.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'INTAKE_CLONE_ACTION_SUCCESS':
    return {
        ...state,
        // intakes: [action.payload, ...state.intakes],
        isFetching: false,
        isError: false,
    }
    case 'INTAKE_CLONE_ACTION_CUSTOMER_SUCCESS':
    return {
        ...state,
        intakes: [action.payload, ...state.intakes],
        isFetching: false,
        isError: false,
    }
    case 'INTAKE_ACTION_RESET':
    return {
        ...state,
        intakes: [],
        intake_form: {},
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
