import React, { Component } from 'react';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder';

import { v1 as uuidv1 } from 'uuid';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Select,
  Modal,
  Divider,
  Switch,
  InputNumber,
  Tooltip,
  DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../utils/Notifications';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormAction, addDeliveryFormAction, updateDeliveryFormAction, resetDeliveryFormAction } from '../../../../actions/deliverActions';

import PageHeader from '../../../../components/PageHeader/PageHeader';

import DeliverSideMenu from '../DeliverSideMenu';
import DeliveryFormSteps from './Steps';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

import moment from 'moment';
import 'moment/locale/nl';


class DeliveryFormSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      loading: true,
      readonly: false
    };
  }

  componentDidMount()
  {
      this.props.resetDeliveryFormAction();

      if(this.props.match.params.form_id)
      {
          if(!isNaN(this.props.match.params.form_id))
          {
              this.props.getDeliveryFormAction(this.props.match.params.form_id).then(() => {

                  this.setState({ locked: this.props.delivery_form.locked });
                  this.setState({ readonly: this.props.delivery_form.completed });

                  this.setState({ nr_of_signatures: this.props.delivery_form.nr_of_signatures ? this.props.delivery_form.nr_of_signatures : 1 });

                  this.setState({ deliveries_combined: this.props.delivery_form.deliveries_combined ? true : false });
                  this.setState({ require_to_sign_form: this.props.delivery_form.require_to_sign_form ? true : false });
                  this.setState({ signature_not_required: this.props.delivery_form.signature_not_required ? true : false });
                  this.setState({ require_to_sign_form_legally: this.props.delivery_form.require_to_sign_form_legally ? true : false });

                  try
                  {
                      var signature_description = JSON.parse(this.props.delivery_form.signature_description);

                      for(let nr = 1; nr <= this.props.delivery_form.nr_of_signatures; nr++)
                      {
                          this.setState({ ['signature_'+nr+'_description']: signature_description[nr] });
                      }
                  }
                  catch(e){}

                  this.setState({ due_date: this.props.delivery_form.due_date ? this.props.delivery_form.due_date : null });

                  this.setState({ loading: false });
              });
          }
          else
          {   // default value(s)
              this.setState({ deliveries_combined: true });
              this.setState({ signature_not_required: false });
              this.setState({ nr_of_signatures: 1 });

              this.setState({ loading: false });
          }
      }
      else
      {
          this.setState({ loading: false });
      }
  }

  handleSubmit = (e, values, redirect) => {

      this.props.form.validateFields((err, values) => {

        values.nr_of_signatures = isNaN(parseInt(this.state.nr_of_signatures)) ? 1 : parseInt(this.state.nr_of_signatures);

        if(values.require_to_sign_form && values.nr_of_signatures < 1)
        {
            values.nr_of_signatures = 1;
        }

        values.signature_not_required = values.signature_not_required ? false : true;

        if(values.signature_not_required)
        {
            values.require_to_sign_form_legally = false;
            values.use_sms_authentication_for_signing = false;
        }

        values.deliveries_combined = values.deliveries_combined ? false : true;
        values.due_date = this.state.due_date;

        if (!err){

          if(this.props.match.params.form_id && this.props.match.params.form_id != 'add') {

            this.props.updateDeliveryFormAction(this.props.match.params.form_id, values).then(() => {

              this.props.history.push('/projects/' + this.props.match.params.id + '/deliver/delivery-form/' + this.props.delivery_form.id + '/deliveries');
            });
          }
          else {

            this.props.addDeliveryFormAction(this.props.match.params.id, values).then((data) => {

              this.props.history.push('/projects/' + this.props.match.params.id + '/deliver/delivery-form/' + data.id + '/deliveries');
            });
          }

          showSuccess();
        }
      });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <DeliverSideMenu project_id={this.props.match.params.id} activeMenu='7' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('deliver' ,'write') || this.state.readonly} type="new" onClick={() => this.handleSubmit(null, this.state.formData, true)}>Opslaan en volgende</Button>
                      <Link to={`/projects/${this.props.match.params.id}/deliver/delivery-forms`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliveryFormSteps current={0} {...this.props} />
                    </Card>
                  </Col>
                </Row>

                { this.state.loading ? null :
                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item hasFeedback className="mbn" required label="Naam">
                            {getFieldDecorator('name', {
                              //initialValue: this.props.choice_form ? this.props.choice_form.name : '',
                              initialValue: this.props.delivery_form.name,
                              rules: [{ required: true, message: 'Vul een omschrijving in' }],
                              onChange: (e) => { this.setState({ description: e.target.value }); }
                            })(
                              <Input disabled={this.state.locked || this.state.readonly} />
                            )}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Formulier per vooroplevering
                                <Tooltip className="mhs" title='Geef op of per gekoppelde vooroplevering een apart in te vullen formulier getoond moet worden.'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('deliveries_combined', {
                                  initialValue: this.state.deliveries_combined /*this.props.delivery_form.deliveries_combined*/ ? false : true,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ deliveries_combined: checked ? false : true }) }
                              })(
                                <Switch disabled={this.state.locked || this.state.readonly} />
                              )}
                            </Form.Item>
                        </Col>

                        { !this.state.deliveries_combined ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={'Tonen in dossier voor bewoner'}>
                              {getFieldDecorator('add_to_dossier', {
                                  initialValue: this.props.delivery_form.add_to_dossier ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch disabled={this.state.locked || this.state.readonly} />
                              )}
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    <Row gutter={24}>

                        <Col span={this.state.require_to_sign_form ? 12 : 24}>
                            <Form.Item className="mbn" label="Digitaal ondertekenen">
                              {getFieldDecorator('require_to_sign_form', {
                                  initialValue: this.props.delivery_form.require_to_sign_form ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => {
                                      this.setState({ require_to_sign_form: checked });

                                      if(!checked)
                                      {
                                          this.setState({ signature_not_required: false });
                                          this.setState({ require_to_sign_form_legally: false });
                                      }
                                  }
                              })(
                                <Switch disabled={this.state.locked || this.state.readonly} />
                              )}
                            </Form.Item>
                        </Col>

                        { this.state.require_to_sign_form ?
                        <Col span={this.state.require_to_sign_form ? 12 : 24}>
                            <Form.Item className="mbn" label="Handtekening verplicht">
                              {getFieldDecorator('signature_not_required', {
                                  initialValue: this.props.delivery_form.signature_not_required ? false : true,
                                  valuePropName: 'checked',
                                  onChange: (checked) => {
                                      this.setState({ signature_not_required: checked ? false : true });

                                      if(!checked)
                                      {
                                          this.setState({ require_to_sign_form_legally: false });
                                      }
                                  }
                              })(
                                <Switch disabled={this.state.locked || this.state.readonly} />
                              )}
                            </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label="Hoeveel handtekeningen">
                            <Input
                                disabled={this.state.locked || this.state.readonly}
                                onChange={(event) => {

                                    var value = event.target.value;

                                    value = isNaN(parseInt(value)) ? (!value ? '' : 1) : parseInt(value);

                                    var new_value = !value ? '' : (value > 10 ? '10' : (value < 1 ? '1' : value));

                                    this.setState({ nr_of_signatures: new_value });
                                }}
                                //defaultValue={this.state.nr_of_signatures}
                                value={this.state.nr_of_signatures}
                                style={{ maxWidth: 60 }}
                                />
                          </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && this.state.nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.nr_of_signatures))].map((i, s) => {

                        var nr = s + 1;

                        return <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label={"Handtekening "+nr+" omschrijving"}>
                            {getFieldDecorator('signature_'+nr+'_description', {
                              initialValue: this.state['signature_'+nr+'_description'],
                              onChange: (value) => { this.setState({ ['signature_'+nr+'_description']: value }); },
                              rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app nog worden aangepast per formulier.' }],
                            })(
                              <Input disabled={this.state.locked || this.state.readonly} />
                            )}
                          </Form.Item>
                        </Col>
                        })}</Col> : null }

                        { this.state.require_to_sign_form && !this.state.signature_not_required ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Rechtsgeldig ondertekenen
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per formulier is dit €1,00'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('require_to_sign_form_legally', {
                                  initialValue: this.props.delivery_form && this.props.delivery_form.require_to_sign_form_legally ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form_legally: checked }) }
                              })(
                                <Switch disabled={this.state.locked || this.state.readonly} />
                              )}
                            </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && !this.state.signature_not_required && this.state.require_to_sign_form_legally ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                SMS authenticatie gebruiken
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per verstuurde SMS is dit €0,25'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('use_sms_authentication_for_signing', {
                                  initialValue: this.props.delivery_form && this.props.delivery_form.use_sms_authentication_for_signing ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch disabled={this.state.locked || this.state.readonly} />
                              )}
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    <Row gutter={24}>
                        <Col lg={{span:12}} xl={{span: 6}}>
                            <Form.Item label={
                                <span>
                                    Verloopdatum:
                                    <Tooltip className="mhs" title='Dit is de uiterlijke datum waarop het formulier ingevuld moet zijn.'>
                                      <InfoCircleTwoTone />
                                    </Tooltip>
                                </span>}
                            >
                                { getFieldDecorator('due_date', {
                                  initialValue: this.state.due_date ? moment(this.state.due_date) : null,
                                  onChange: (value) => { this.setState({ due_date: value ? value.format('YYYY-MM-DD') : null }); }
                                })(
                                <DatePicker
                                  format={'DD/MM/YYYY'}
                                  //disabled={this.state.locked || this.state.readonly}
                                  />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>}

            </div>

          </div>

      </div>
    );
  }
}


const DeliveryFormSettingsForm = Form.create({ name: 'intake_form' })(DeliveryFormSettings);

const mapStateToProps = (state, ownProps) => {

  return {

    isFetching : state.deliver.isFetching,
    isError : state.deliver.isError,
    delivery_form : state.deliver.form
  };
}

const mapDispatchToProps = (dispatch) => {

  return {

    getDeliveryFormAction : bindActionCreators(getDeliveryFormAction, dispatch),
    addDeliveryFormAction : bindActionCreators(addDeliveryFormAction, dispatch),
    updateDeliveryFormAction : bindActionCreators(updateDeliveryFormAction, dispatch),
    resetDeliveryFormAction : bindActionCreators(resetDeliveryFormAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormSettingsForm);
