import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
} from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import AddFromLibrary from '../../../../components/AddFromLibrary/AddFromLibrary';
import { API_URL, JOB_URL } from '../../../../constants/settings';


class ComposeMailForm extends Component {

  state = {
    filelist: [],
    deleteImage: false,
    iframeKey: 1
  }

  showCharacterCounter()
  {
      var message = this.props.form.getFieldValue('message');
      message = message.replace(/(<([^>]+)>)/gi, "");

      var nr_of_messages = Math.ceil(message.length / 160);

      return message.length + ' tekens / ' + nr_of_messages + ' bericht' + (nr_of_messages > 1 ? 'en' : '');
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'} colon={true}>
        <Row>
          <Col span={24}>
            <AddFromLibrary onSelectLibrary={(library) => this.props.form.setFieldsValue({'message':library.content.replace(/(<([^>]+)>)/gi, "")})}/>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item /*required*/ label="SMS bericht">
              { getFieldDecorator('message', {
                initialValue: this.props.selectedMethod.message ? this.props.selectedMethod.message : '',
                rules: [{ required: true, message: 'Vul een SMS bericht in.' }],
                valuePropName: 'value',
                trigger: 'onEditorChange',
              })(
                <CustomEditor placeholders={'sms'} project_id={this.props.match.params.id} width={'100%'} height={'320'} />
              ) }
            </Form.Item>

            { this.showCharacterCounter() }
            { /*this.props.form.getFieldValue('message').length} tekens / { Math.ceil(this.props.form.getFieldValue('message').length / 160) } bericht{ Math.ceil(this.props.form.getFieldValue('message').length / 160) > 1 ? 'en': ''*/ } (max. 1200 tekens)
          </Col>
          <Col span={12}>
              <Form.Item label="Voorbeeld">
                  <div>
                    <Button type="new" onClick={() => {
                      this.props.onSave();
                      setTimeout(() => {
                        this.setState({iframeKey: (this.state.iframeKey + 1)});
                      }, 500);
                    }} style={{margin: 0}}>Ververs voorbeeld</Button>
                  </div>
                  <iframe key={this.state.iframeKey} style={{border: '1px solid #f0f0f0', width: '100%', height: 'auto', minHeight: 320}} src={JOB_URL + '/correspondence/'+ this.props.match.params.correspondenceid +'/preview/sms'+(this.props.match.params.addressid ? '/'+this.props.match.params.addressid : '')+'?token=' + localStorage.getItem('authToken')} />
              </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'compose_mail_form' })(ComposeMailForm);
