import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Modal,
  Input,
  Radio,
  DatePicker,
  Select,
  Row,
  Col,
  Card,
  Switch,
  Tooltip,
} from 'antd';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import AddFromLibrary from '../../../../../components/AddFromLibrary/AddFromLibrary';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'moment/locale/nl';
dayjs.locale('nl');

class PatchNews extends Component {
  state = {
    libraryText: ''
  }

  componentDidMount() {
  }

  setContentvar = (content) => {
    this.props.form.setFieldsValue({'content': content});
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        <Row gutter={24}>
          <Col span={18}>
            <Card>
              <Form.Item required label="Titel" hasFeedback>
              {getFieldDecorator('title', {
                initialValue: this.props.newsarticle.title,
                rules: [{ required: true, message: 'Vul een titel in' }],
              })(
                <Input />
              )}
              </Form.Item>

              <Form.Item required label="Bericht">
                {getFieldDecorator('content', {
                  valuePropName: 'value',
                  trigger: 'onEditorChange',
                  initialValue: this.props.isFetching ? this.props.newsarticle.content : this.props.newsarticle.content,
                  rules: [{ required: true, message: 'Vul een bericht in' }],
                })(
                  <CustomEditor placeholders={'news'} project_id={this.props.match.params.id} />
                )}
              </Form.Item>


            </Card>
          </Col>

          <Col span={6}>
            <Card>
              <Form.Item label="Zichtbaarheid">
              {getFieldDecorator('is_published', {
                initialValue: this.props.newsarticle.is_published || 0
              })(
                <Radio.Group buttonStyle="solid">
                <Radio.Button value={0}>Concept</Radio.Button>
                <Radio.Button value={1}>Gepubliceerd</Radio.Button>
                </Radio.Group>
              )}
              </Form.Item>
              <Form.Item label="Openbaar">
              {getFieldDecorator('is_public', {
                valuePropName: 'checked',
                initialValue: this.props.newsarticle.is_public  ? true : false
              })(
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                />
              )}
              </Form.Item>
              <Form.Item label={
                  <span>
                      Datum
                      <Tooltip className="mhs" title='Dit is tevens de datum vanaf wanneer het nieuwsbericht zichtbaar is voor bewoners.'>
                        <InfoCircleTwoTone />
                      </Tooltip>
                  </span>}>
              {getFieldDecorator('date', {
                initialValue: this.props.newsarticle.date ? dayjs(moment(this.props.newsarticle.date)) : dayjs(moment()),
                rules: [{ required: true, message: 'Vul een datum in' }],
              })(
                <DatePicker format={'DD/MM/YYYY'} />
              )}
              </Form.Item>

              <Form.Item label="Afbeelding">
              {getFieldDecorator('image_id', {
                initialValue: this.props.newsarticle.image_id,
              })(
                <AddFromMedia
                  projectId={this.props.match.params.id}
                  initialMediaId={this.props.newsarticle.image_id}
                  initialMediaUrl={this.props.newsarticle.image_url}
                  onMediaSelection={(item) => this.props.form.setFieldsValue({'image_id': item.id})}
                  onMediaRemove={() => this.props.form.setFieldsValue({'image_id': ''})}
                />
              )}
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'patch_news_form' })(PatchNews);
