import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications'
import { } from '../../../actions/choiceActions';
import ChoiceSideMenu from './ChoiceSideMenu';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Menu,
  Dropdown,
  Modal,
  Tooltip,
  DatePicker,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import CustomEditor from '../../../components/CustomEditor/CustomEditor';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import { getProjectAction } from '../../../actions/projectActions';
import { getEnvelopesAction } from '../../../actions/envelopesActions';
import { getLetterPapersAction } from '../../../actions/letterPapersActions';
import { saveChoiceSettingsAction } from '../../../actions/choiceActions';

const { Title, Text } = Typography;

const Option = Select.Option;

class ChoiceSettings extends Component {

  state = {
    disabled: true,
    edit: true,

    c_letter_paper_id: 0,
    c_form_letter_paper_id: 0,
    c_envelope_id: 0,
    c_return_envelope_id: 0,
    c_enable_mollie: false,
    c_allow_change_order: false,
    c_allow_change_till: null,
    c_force_direct_payment: false,
    c_allow_postpay: false,
    c_cart_additional_work_direct_payment: '',
    c_status_direct_payment: '',
    c_status_postpay: '',
    c_email_subject_direct_payment: '',
    c_email_message_direct_payment: '',
    c_email_subject_postpay: '',
    c_email_message_postpay: '',
    // c_email_subject_no_additional_work: '',
    // c_email_message_no_additional_work: '',

    can_write: false,
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write')
      });

      // get project
      this.props.getProjectAction(this.props.match.params.id).then(() => {
          this.setState({
              c_letter_paper_id: this.props.project.c_letter_paper_id ? this.props.project.c_letter_paper_id : 0,
              c_form_letter_paper_id: this.props.project.c_form_letter_paper_id ? this.props.project.c_form_letter_paper_id : 0,
              c_envelope_id: this.props.project.c_envelope_id ? this.props.project.c_envelope_id : 0,
              c_return_envelope_id: this.props.project.c_return_envelope_id ? this.props.project.c_return_envelope_id : 0,
              c_enable_mollie: this.props.project.c_enable_mollie,
              c_allow_change_order: this.props.project.c_allow_change_order,
              c_allow_change_till: this.props.project.c_allow_change_till ? moment(this.props.project.c_allow_change_till, 'YYYY-MM-DD HH:mm:ss') : false, //this.props.project.c_allow_change_till,
              c_force_direct_payment: this.props.project.c_force_direct_payment,
              c_allow_postpay: this.props.project.c_allow_postpay,

              // old
              // c_cart_additional_work_direct_payment: this.props.project.c_cart_additional_work_direct_payment,
              // c_status_direct_payment: this.props.project.c_status_direct_payment,
              // c_status_postpay: this.props.project.c_status_postpay,
              // c_email_subject_direct_payment: this.props.project.c_email_subject_direct_payment,
              // c_email_message_direct_payment: this.props.project.c_email_message_direct_payment,
              // c_email_subject_postpay: this.props.project.c_email_subject_postpay,
              // c_email_message_postpay: this.props.project.c_email_message_postpay,
              // c_email_subject_no_additional_work: this.props.project.c_email_subject_no_additional_work,
              // c_email_message_no_additional_work: this.props.project.c_email_message_no_additional_work,

              // default(s)
              c_cart_additional_work_direct_payment: this.props.project.c_cart_additional_work_direct_payment ? this.props.project.c_cart_additional_work_direct_payment : 'Onderstaand treft u een overzicht van de door u gekozen opties.<br />De gekozen meerwerkopties kunt u betalen in de volgende stap.',

              c_cart_no_additional_work: this.props.project.c_cart_no_additional_work ? this.props.project.c_cart_no_additional_work : 'Onderstaand treft u een overzicht van de door u gekozen opties.',

              c_status_direct_payment: this.props.project.c_status_direct_payment ? this.props.project.c_status_direct_payment : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! U ontvangt een bevestiging per e-mail.',

              c_status_postpay: this.props.project.c_status_postpay ? this.props.project.c_status_postpay : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! U ontvangt een bevestiging per e-mail. Heeft u gekozen voor meerwerkopties? In de e-mail treft u instructies omtrent de betaling voor de door u gekozen opties.',

              c_email_subject_direct_payment: this.props.project.c_email_subject_direct_payment ? this.props.project.c_email_subject_direct_payment : 'Keuzeformulier en betaling ontvangen',
              c_email_message_direct_payment: this.props.project.c_email_message_direct_payment ? this.props.project.c_email_message_direct_payment : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier en de bijbehorende betaling voor de meerwerkopties in goede orde ontvangen. Bijgaand ontvangt u het door u ingevulde keuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />De door u gekozen meerwerkopties bedragen totaal <placeholder class="cobee-tag">Bedrag meerwerkopties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',

              c_email_subject_postpay: this.props.project.c_email_subject_postpay ? this.props.project.c_email_subject_postpay : 'Keuzeformulier ontvangen',
              c_email_message_postpay: this.props.project.c_email_message_postpay ? this.props.project.c_email_message_postpay : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. Bijgaand ontvangt u het door u ingevulde keuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br /><br />De gekozen meerwerkopties dienen nog te worden betaald. Wij verzoeken u vriendelijk om <placeholder class="cobee-tag">Bedrag meerwerkopties</placeholder> voor (datum) over te maken op (rekeningnummer) onder vermelding van referentie (referentie).<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',

              // c_email_subject_no_additional_work: this.props.project.c_email_subject_no_additional_work ? this.props.project.c_email_subject_no_additional_work : 'Keuzeformulier ontvangen',
              // c_email_message_no_additional_work: this.props.project.c_email_message_no_additional_work ? this.props.project.c_email_message_no_additional_work : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. Bijgaand ontvangt u het door u ingevulde keuzeformulier.<br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegel “Formulieren”.<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
          });

          // get envelopes
          this.props.getEnvelopesAction(this.props.project.customer_id);

          // get letters
          this.props.getLetterPapersAction(this.props.project.customer_id);
      });
  }

  saveToggle(value, name) {
      this.setState({ [name]: value });
  }

  saveSettings()
  {
      var settings = {
          c_letter_paper_id: this.state.c_letter_paper_id,
          c_form_letter_paper_id: this.state.c_form_letter_paper_id,
          c_envelope_id: this.state.c_envelope_id,
          c_return_envelope_id: this.state.c_return_envelope_id,

          c_enable_mollie: this.state.c_enable_mollie,
          c_allow_change_order: this.state.c_allow_change_order,
          c_allow_change_till: this.state.c_allow_change_till,

          c_force_direct_payment: this.state.c_force_direct_payment,
          c_allow_postpay: this.state.c_allow_postpay,

          c_cart_additional_work_direct_payment: this.state.c_cart_additional_work_direct_payment, // ? this.state.c_cart_additional_work_direct_payment : 'Onderstaand treft u een overzicht van de door u gekozen opties.<br />De gekozen meerwerkopties kunt u betalen in de volgende stap.',

          c_cart_no_additional_work: this.state.c_cart_no_additional_work, // ? this.state.c_cart_no_additional_work : 'Onderstaand treft u een overzicht van de door u gekozen opties.',

          c_status_direct_payment: this.state.c_status_direct_payment, // ? this.state.c_status_direct_payment : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! U ontvangt een bevestiging per e-mail.',

          c_status_postpay: this.state.c_status_postpay, // ? this.state.c_status_postpay : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! U ontvangt een bevestiging per e-mail. Heeft u gekozen voor meerwerkopties? In de e-mail treft u instructies omtrent de betaling voor de door u gekozen opties.',

          c_email_subject_direct_payment: this.state.c_email_subject_direct_payment, // ? this.state.c_email_subject_direct_payment : 'Keuzeformulier en betaling ontvangen',
          c_email_message_direct_payment: this.state.c_email_message_direct_payment, // ? this.state.c_email_message_direct_payment : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier en de bijbehorende betaling voor de meerwerkopties in goede orde ontvangen. Bijgaand ontvangt u het door u ingevulde keuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegel “Formulieren”.<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',

          c_email_subject_postpay: this.state.c_email_subject_postpay, // ? this.state.c_email_subject_postpay : 'Keuzeformulier ontvangen',
          c_email_message_postpay: this.state.c_email_message_postpay, // ? this.state.c_email_message_postpay : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. Bijgaand ontvangt u het door u ingevuldekeuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegel “Formulieren”.<br /><br />De gekozen meerwerkopties dienen nog te worden betaald. Wij verzoeken u vriendelijk om <placeholder class="cobee-tag">Bedrag meerwerkopties</placeholder>voor (datum) over te maken op (rekeningnummer) onder vermelding van referentie (referentie).<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',

          // c_email_subject_no_additional_work: this.state.c_email_subject_no_additional_work, // ? this.state.c_email_subject_no_additional_work : 'Keuzeformulier ontvangen',
          // c_email_message_no_additional_work: this.state.c_email_message_no_additional_work, // ? this.state.c_email_message_no_additional_work : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. Bijgaand ontvangt u het door u ingevuldekeuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegel “Formulieren”.<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
      };

      this.props.saveChoiceSettingsAction(this.props.match.params.id, settings).then(() => {
          showSuccess('Instellingen opgeslagen');
      });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="1"/>

            <div style={{padding: 24, width: '100%'}}>

                <Form layout={'vertical'}>

                      <Row className="mbm">
                        <Col span={24}>
                          <div>
                            <Button disabled={!this.state.can_write} type="new" onClick={() => { this.saveSettings(); }}>Opslaan</Button>
                          </div>
                        </Col>
                      </Row>

                    <Divider></Divider>

                    <Row gutter={24}>
                        <Col span={8}>
                          <Form.Item label={(
                            <span>
                              Meerwerkopties betalen via Mollie:
                              <Tooltip className="mhs" title='De optie inschakelen om gebruikers wel of niet te laten betalen via Mollie als er gekozen is voor meerwerk.'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                            </span>
                          )}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={this.state.c_enable_mollie}
                                onChange={(value) => this.saveToggle(value, 'c_enable_mollie')}
                            />
                          </Form.Item>
                        </Col>
                    </Row>

                    <Divider></Divider>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Winkelwagen met meerwerkopties bij direct betalen">
                            { getFieldDecorator('c_cart_additional_work_direct_payment', {
                              initialValue: this.state.c_cart_additional_work_direct_payment, // ? this.state.c_cart_additional_work_direct_payment : 'Onderstaand treft u een overzicht van de door u gekozen opties.<br />De gekozen meerwerkopties kunt u betalen in de volgende stap.',
                              // rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_cart_additional_work_direct_payment: value }); },
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ c_cart_additional_work_direct_payment: e }); }} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Winkelwagen met meerwerkopties bij geen meerwerkkosten">
                            { getFieldDecorator('c_cart_no_additional_work', {
                              initialValue: this.state.c_cart_no_additional_work, // ? this.state.c_cart_no_additional_work : 'Onderstaand treft u een overzicht van de door u gekozen opties.',
                              // rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_cart_no_additional_work: value }); }
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ c_cart_no_additional_work: e }); }} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingspagina na directe betaling">
                            { getFieldDecorator('c_status_direct_payment', {
                              initialValue: this.state.c_status_direct_payment, // ? this.state.c_status_direct_payment : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! U ontvangt een bevestiging per e-mail.',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_status_direct_payment: value }); }
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ c_status_direct_payment: e }); }} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingspagina bij betaling achteraf">
                            { getFieldDecorator('c_status_postpay', {
                              initialValue: this.state.c_status_postpay, // ? this.state.c_status_postpay : 'Wij hebben het door u ingevulde keuzeformulier en de betaling hiervan in goede orde ontvangen! U ontvangt een bevestiging per e-mail. Heeft u gekozen voor meerwerkopties? In de e-mail treft u instructies omtrent de betaling voor de door u gekozen opties.',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_status_postpay: value }); }
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ c_status_postpay: e }); }} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider></Divider>

                    <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Bevestigingsmail bij directe betaling e-mail onderwerp">
                              {getFieldDecorator('c_email_subject_direct_payment', {
                                initialValue: this.state.c_email_subject_direct_payment, // ? this.state.c_email_subject_direct_payment : 'Keuzeformulier en betaling ontvangen',
                                rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                onChange: (source) => { this.setState({c_email_subject_direct_payment: source.target.value}); }
                              })(
                                <Input />
                              )}
                          </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingsmail bij directe betaling e-mail bericht">
                            { getFieldDecorator('c_email_message_direct_payment', {
                              initialValue: this.state.c_email_message_direct_payment, // ? this.state.c_email_message_direct_payment : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier en de bijbehorende betaling voor de meerwerkopties in goede orde ontvangen. Bijgaand ontvangt u het door u ingevulde keuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegel “Formulieren”.<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_email_message_direct_payment: value }); }
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ c_email_message_direct_payment: e }); }} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider></Divider>

                    <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Bevestigingsmail bij betaling achteraf e-mail onderwerp">
                              {getFieldDecorator('c_email_subject_postpay', {
                                initialValue: this.state.c_email_subject_postpay, // ? this.state.c_email_subject_postpay : 'Keuzeformulier ontvangen',
                                rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                onChange: (source) => { this.setState({c_email_subject_postpay: source.target.value}); }
                              })(
                                <Input />
                              )}
                          </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingsmail bij betaling achteraf e-mail bericht">
                            { getFieldDecorator('c_email_message_postpay', {
                              initialValue: this.state.c_email_message_postpay, // ? this.state.c_email_message_postpay : 'Beste heer/mevrouw <placeholder class="cobee-tag">achternaam</placeholder>,<br /><br />Wij hebben het door u ingevulde keuzeformulier in goede orde ontvangen. Bijgaand ontvangt u het door u ingevuldekeuzeformulier. U heeft gekozen voor de volgende opties:<br /><br /><placeholder class="cobee-tag">Lijst gekozen opties</placeholder><br /><br />Het keuzeformulier kunt ook terugvinden in de app of op de bewonerspagina onder de tegel “Formulieren”.<br /><br />De gekozen meerwerkopties dienen nog te worden betaald. Wij verzoeken u vriendelijk om <placeholder class="cobee-tag">Bedrag meerwerkopties</placeholder>voor (datum) over te maken op (rekeningnummer) onder vermelding van referentie (referentie).<br /><br />Heeft u vragen en/of opmerkingen, neem contact met ons op via (telefoonnummer) of (e-mailadres).<br /><br />Met vriendelijke groet,<br /><placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              // onEditorChange: (value, editor) => { this.setState({ c_email_message_postpay: value }); }
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ c_email_message_postpay: e }); }} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider></Divider>

                    {/*<Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Bevestigingsmail bij geen meerwerkopties e-mail onderwerp">
                              {getFieldDecorator('c_email_subject_no_additional_work', {
                                initialValue: this.state.c_email_subject_no_additional_work,
                                rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                onChange: (source) => { this.setState({c_email_subject_no_additional_work: source.target.value}); }
                              })(
                                <Input />
                              )}
                          </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item required label="Bevestigingsmail bij geen meerwerkopties e-mail bericht">
                            { getFieldDecorator('c_email_message_no_additional_work', {
                              initialValue: this.state.c_email_message_no_additional_work,
                              rules: [{ required: true, message: 'Vul een tekst in' }],
                              valuePropName: 'value',
                              trigger: 'onEditorChange',
                              onEditorChange: (value, editor) => { this.setState({ c_email_message_no_additional_work: value }); }
                            })(
                              <CustomEditor placeholders={'choice'} project_id={this.props.match.params.id} />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>*/}

                </Form>

          </div>

      </div>
    );
  }
}

const ChoiceSettingsForm = Form.create({ name: 'projects_choice_forms' })(ChoiceSettings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    project : state.project.project,
    envelopes : state.envelope.envelopes,
    letterpapers: state.letterpaper.letterpapers,
    auth: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    getEnvelopesAction : bindActionCreators(getEnvelopesAction, dispatch),
    getLetterPapersAction : bindActionCreators(getLetterPapersAction, dispatch),
    saveChoiceSettingsAction : bindActionCreators(saveChoiceSettingsAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceSettingsForm);
