import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo_zwart.svg';
import BgImage from '../../assets/img/login_new.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginAction, getLoginUserAction, logoutAction, setTokenAndIDP, setToken } from '../../actions/authAction';
import { CustomerCan } from '../../utils/RolesAndAbilities';
import './Login.css';
import queryString from 'query-string';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Layout, Col, Row, Input, Button, Alert, Typography } from 'antd';

class Login extends Component {

  state = {
    loading: false,
    loginError: false,
    hideLogin: false
  }

  componentDidMount() {

      if(this.props.location.search)
      {
          var params = queryString.parse(this.props.location.search);

          if(params.v)
          {
              localStorage.clear();

              setTimeout(() => {
                  document.location.href = '/login';
              }, 100);

              // document.location.href = '/login';
          }
      }

      localStorage.clear();

      //
      if(this.props.match.params.token && this.props.match.params.idp)
      {
          this.setState({ hideLogin: true });

          this.props.setTokenAndIDP(this.props.match.params.token, this.props.match.params.idp); // .then(() => {

              this.props.getLoginUserAction(this.props.match.params.token).then(() => {

                  var goto = '/projects';

                  if(!this.props.CustomerCan('projects'))
                  {   // redirect
                      if(this.props.CustomerCan('library')){ goto = '/library'; }
                      else if(this.props.CustomerCan('media')){ goto = '/media'; }
                      else if(this.props.CustomerCan('accounts')){ goto = '/accounts'; }
                      else { goto = `/accounts/${ this.props.auth && this.props.auth.user && this.props.auth.user.id}/profile`; }
                  }

                  setTimeout(() => {
                      document.location.href = goto + '?v=' + Date.now(); // fix for not rebuilding correct local storage
                  }, 1000);

                  // document.location.href = goto + '?v=' + Date.now(); // fix for not rebuilding correct local storage
              });
          // });
      }

      else if(this.props.match.params.token)
      {
          this.setState({ hideLogin: true });

          this.props.setToken(this.props.match.params.token, null); // .then(() => {

              this.props.getLoginUserAction(this.props.match.params.token).then(() => {

                  var goto = '/projects';

                  if(!this.props.CustomerCan('projects'))
                  {   // redirect
                      if(this.props.CustomerCan('library')){ goto = '/library'; }
                      else if(this.props.CustomerCan('media')){ goto = '/media'; }
                      else if(this.props.CustomerCan('accounts')){ goto = '/accounts'; }
                      else { goto = `/accounts/${ this.props.auth && this.props.auth.user && this.props.auth.user.id}/profile`; }
                  }

                  setTimeout(() => {
                      document.location.href = goto + '?v=' + Date.now(); // fix for not rebuilding correct local storage
                  }, 1000);

                  // document.location.href = goto + '?v=' + Date.now(); // fix for not rebuilding correct local storage
              });
          // });
      }
  }

  handleSubmit = (e) => {

    e.preventDefault();

    this.setState({ loginMessage: false });

    // clean up cache
    localStorage.clear();

    this.props.form.validateFields((err, values) => {

      if(!err)
      {
          this.props.loginAction(values).then((data) => {

              if(data.success)
              {
                  if(data.message)
                  {
                      this.setState({ loginMessage: data.message });
                  }
                  else
                  {
                      var goto = '/projects';

                      if(!this.props.CustomerCan('projects'))
                      {   // redirect
                          if(this.props.CustomerCan('library')){ goto = '/library'; }
                          else if(this.props.CustomerCan('media')){ goto = '/media'; }
                          else if(this.props.CustomerCan('accounts')){ goto = '/accounts'; }
                          else { goto = `/accounts/${ this.props.auth && this.props.auth.user && this.props.auth.user.id}/profile`; }
                      }

                      setTimeout(() => {
                          document.location.href = goto + '?v=' + Date.now(); // fix for not rebuilding correct local storage
                      }, 1000);

                      // document.location.href = goto + '?v=' + Date.now(); // fix for not rebuilding correct local storage
                  }
              }
              else
              {
                this.setState({loginError: true});
              }
          });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Title } = Typography;
    const { Paragraph } = Typography;

    if(this.state.hideLogin == true){ return null; }

    return (
      <div className="loginScreen" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>
        <Layout style={{ backgroundColor: 'white' }}>
          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

            <Col xs={0} sm={0} md={10} lg={8} xl={10} xxl={10}>
              <div
                className="loginScreen__image"
                style={{
                    backgroundImage: `url(${BgImage})`,
                    backgroundPosition: 'top right',
                    backgroundSize: 'cover'
                }}>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={16} xl={14} xxl={14} type="flex" align="middle">
              <div style={{ maxWidth: '400px', padding:24 }}>

              <img width="200px" height="auto" alt="Cobee" src={Logo} style={{ width: 200, marginBottom: 50 }}></img>

                <Form onSubmit={this.handleSubmit} className="login-form" style={{ width: '100%', textAlign: 'middle' }}>

                  {/*<Alert style={{marginBottom: 10}} message="Let op!" type="error" description="Het Cobee systeem zal zaterdag 2 december door onderhoudswerkzaamheden niet beschikbaar zijn." />*/}

                  { this.state.loginError ?
                    <Alert style={{marginBottom: 10}} message="Inloggen mislukt" type="error" description="Gebruikersnaam en/of wachtwoord onjuist" closable afterClose={() => this.setState({loginError: false})} />
                    :
                    (this.state.loginMessage ?
                    <Alert style={{marginBottom: 10}} message="Inloggen mislukt" type="error" description={this.state.loginMessage} closable afterClose={() => this.setState({ loginMessage: false }) } />
                    :
                    null)
                  }

                  <Title level={2}>Inloggen</Title>
                  <Paragraph>Log in met een gebruikersnaam en wachtwoord.</Paragraph>
                  <Form.Item style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('email', {
                      rules: [{ required: true, message: 'Vul een gebruikersnaam in' }],
                    })(
                      <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Gebruikersnaam" />
                    )}
                  </Form.Item>

                  <Form.Item style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Vul een wachtwoord in' }],
                    })(
                      <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Wachtwoord" />
                    )}
                  </Form.Item>
                  <Paragraph style={{ fontSize: '14px', }}>Door in te loggen ga je akkoord met onze <a target="_blank" href="https://www.cobee.nl/algemene-voorwaarden/">algemene voorwaarden</a>, <a target="_blank" href="https://www.cobee.nl/privacy/">privacybeleid</a> en <a target="_blank" href="https://www.cobee.nl/cookiebeleid/">cookiebeleid</a>.</Paragraph>
                  <Button style={{ width: '100%', margin: '16px 0 0 0' }} type="primary" htmlType="submit" className="login-form-button" loading={this.props.isFetching}>
                    Inloggen
                  </Button>
                  <Link to="/password-forget"><span style={{ display: 'block', margin: '16px 0 0 0'}} className="login-form-forgot">Wachtwoord vergeten</span></Link>
                </Form>
              </div>
            </Col>
          </Row>
        </Layout>
      </div>
    );
  }
}

const LoginForm = Form.create({ name: 'normal_login' })(Login);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.auth.isFetching,
    isError : state.auth.isError,
    auth : state.auth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction : bindActionCreators(loginAction, dispatch),
    getLoginUserAction : bindActionCreators(getLoginUserAction, dispatch),
    logoutAction : bindActionCreators(logoutAction, dispatch),
    setTokenAndIDP : bindActionCreators(setTokenAndIDP, dispatch),
    setToken : bindActionCreators(setToken, dispatch),
    CustomerCan : bindActionCreators(CustomerCan, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
