import _ from 'lodash';

export const HaveRole = (roles) => (dispatch, getState) => {
  return roles.includes(getState().auth.selectedCustomer && getState().auth.selectedCustomer.user_role);
}

export const CustomerCan = (type) => (dispatch, getState) => {

  var rights = getState().auth.selectedCustomer && getState().auth.selectedCustomer.user_rights
  var find   = _.find(rights, { 'object': type });

  if(find) {
    return find.allowed ? true : false;
  } else {
    return false;
  }

}

export const CustomerHas = (type) => (dispatch, getState) => {

  var modules = getState().auth.selectedCustomer && getState().auth.selectedCustomer.modules
  var find   = _.find(modules, { 'name': type });

  if(find) {
    return true;
  } else {
    return false;
  }

}


export const HaveAbility = (module, ability) => (dispatch, getState) => {

  var customer_rights = getState().auth.selectedCustomer && getState().auth.selectedCustomer.user_rights || [];
  var customer_role   = getState().auth.selectedCustomer && getState().auth.selectedCustomer.user_role;
  var project_rights  = getState().project.project && getState().project.project.user_rights || [];
  var project_role    = getState().project.project && getState().project.project.user_role || null;

  if(project_role === 'super-admin' || customer_role === 'super-admin') {
     return true;
  }

  if(project_role === 'admin' && _.find(project_rights, { 'object': module })){
    return true;
  }

  var rights = [...project_rights, ...customer_rights];
  var find   = _.find(rights, { 'object': module });

  if(find) {
    if(find.type == ability) {
      return find.allowed;
    } else {
      if(find.type == 'write' && ability == 'read') {
        return find.allowed;
      }
      if(find.type == 'delete' && (ability == 'read' || ability == 'write')) {
        return find.allowed;
      }
      return false;
    }

  } else {
    return false;
  }

}
