import React from 'react'
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'

import { v1 as uuidv1 } from 'uuid'
import SavePesetModal from '../Modals/SavePresetModal'
import IntakeSteps from '../Steps/IntakeSteps'
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Switch, Tooltip, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showExtraConfirm } from '../../../../utils/Notifications';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getIntakeByIdAction, addIntakeProjectAction, updateIntakeProjectAction, resetIntakeAction, previewAction } from '../../../../actions/intakeActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class ProjectIntakeDetail extends React.Component {

  form_data = null;

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      can_write: false,
      loading: true
    };
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('intake' ,'write')
      });

    this.props.resetIntakeAction();

    if(this.props.match.params.intakeid && this.props.match.params.intakeid != 'new') {

      this.props.getIntakeByIdAction(this.props.match.params.intakeid).then(() => {

        if(this.IsJsonString(this.props.intake_form.form)){

          // this.refs.FormBuilder.parse(JSON.parse(this.props.intake_form.form));

          this.setState({formData: JSON.parse(this.props.intake_form.form)}, () => {

              this.refs.FormBuilder.parse(JSON.parse(this.props.intake_form.form));

              this.setState({ loading: false });
          });
        }
      });
    } else {

      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []}]);

      this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      return JSON.parse(str) ? true : false;
    } catch (e) {
      return false;
    }
    return true;
  }

  hasResults = () => {
    if(this.props.intake_form && this.props.intake_form.results && this.props.intake_form.results.length > 0) {
    }


    return false;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.form.validateFields((err, values) => {

      if (!err){

        values.form = JSON.stringify(this.state.formData);

        if(values.tags) {
          values.tags = values.tags.toString();
        }

        if(values.hide_for_address === 1 || values.hide_for_address === '1' || values.hide_for_address === true)
        {
            values.hide_for_address = 0;
        }
        else
        {
            values.hide_for_address = 1;
        }

        // console.log(values, this.state.formData);

        if(this.props.match.params.intakeid) {
          if(this.props.intake_form && this.props.intake_form.results && this.props.intake_form.results.length > 0) {
            showExtraConfirm((confirm) => {
              if(confirm){
                this.props.updateIntakeProjectAction(this.props.match.params.intakeid, values).then(() => {
                  if(redirect === true) {
                    this.props.history.push('/projects/' + this.props.match.params.id + '/intake/' + this.props.intake_form.id + '/addresses');
                    showSuccess();
                  }
                });
              }
            }, 'Let op!', 'Reeds ingevulde formulieren worden met aanpassingen overschreven.');
          } else {
            this.props.updateIntakeProjectAction(this.props.match.params.intakeid, values).then(() => {
              if(redirect === true) {
                this.props.history.push('/projects/' + this.props.match.params.id + '/intake/' + this.props.intake_form.id + '/addresses');
                showSuccess();
              }
            });
          }
        }
        else {
          this.props.addIntakeProjectAction(this.props.match.params.id, values).then(() => {
            this.props.history.push('/projects/' + this.props.match.params.id + '/intake/' + this.props.intake_form.id);
          });
        }

      }
    });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
          {/* buttons */}
          <Row className="mbm">
            <Col span={12}>
              <div>
                <Button disabled={this.state.loading || !this.state.can_write} type="new" onClick={() => this.handleSubmit(null, null, true)}>Opslaan en volgende</Button>
                <Link to={`/projects/${this.props.match.params.id}/intake`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>
            </Col>
            <Col span={12} align={'right'}>
                <Button onClick={(event) => { this.props.previewAction(this.props.match.params.intakeid); }}>Voorbeeld</Button>
            </Col>
          </Row>

          {/* steps */}
          <Row className="mbm">
            <Col span={24}>
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <IntakeSteps current={0} {...this.props}/>
              </Card>
            </Col>
          </Row>

          <Card className="mbm">
            <Row gutter={24}>
              <Col lg={{span:12}} xl={{span: 8}}>
                <Form.Item hasFeedback className="mbn" required label="Naam">
                  {getFieldDecorator('name', {
                    initialValue: this.props.intake_form ? this.props.intake_form.name : '',
                    rules: [{ required: true, message: 'Vul een naam in' }],
                  })(
                    <Input disabled={!this.props.HaveAbility('intake' ,'write')} />
                  )}
                </Form.Item>
              </Col>
              <Col lg={{span:12}} xl={{span: 8}}>
                <Form.Item className="mbn" label="Tags">
                  {getFieldDecorator('tags', {
                    initialValue: this.props.intake_form && this.props.intake_form.tags ? this.props.intake_form.tags.split(",") : [],
                    rules: [{ required: false, message: 'Vul een of meerdere tags in' }],
                  })(
                    <Select disabled={!this.props.HaveAbility('intake' ,'write')} mode="tags" style={{ width: '100%' }} placeholder="Voeg een label toe"></Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={{span:12}} xl={{span: 4}}>
                <Form.Item className="mbn" colon={false} label={(
                  <span>
                    Bewoner mag formulier invullen
                    <Tooltip className="mhs" title='Als deze optie aan staat betekent dat, dat de bewoner het formulier zelf in kan vullen.'>
                      <InfoCircleTwoTone />
                    </Tooltip>
                  </span>
                )}>
                  {getFieldDecorator('allow_address', {
                    initialValue: this.props.intake_form && this.props.intake_form.allow_address ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      disabled={!this.props.HaveAbility('intake' ,'write')}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                    )}
                </Form.Item>
              </Col>
              <Col lg={{span:12}} xl={{span: 4}}>
                <Form.Item className="mbn" colon={false} label={(
                    <span>
                      {/* Verbergen voor bewoner */}
                      Tonen op bewonerspagina
                      <Tooltip className="mhs" title='Als deze optie uit staat betekent dat, dat de bewoner het ingevulde formulier niet als resultaat in het dossier opgenomen wordt voor de bewoner zelf.'>
                        <InfoCircleTwoTone />
                      </Tooltip>
                    </span>
                  )}>
                  {getFieldDecorator('hide_for_address', {
                    initialValue: this.props.intake_form && this.props.intake_form.hide_for_address ? (this.props.intake_form.hide_for_address === 1 || this.props.intake_form.hide_for_address === '1' ? false : true) : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      disabled={!this.props.HaveAbility('intake' ,'write')}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                    )}
                </Form.Item>
              </Col>
            </Row>
          </Card>

        </Form>

        <Row gutter={24} className="mbm" style={{display: (this.props.match.params.intakeid ? 'block' : 'none')}}>
          <Col span={24}>
            <FormBuilder
              disabled={!this.props.HaveAbility('intake' ,'write')}
              onChangeForm={(data) => { if(this.state.can_write){ this.setState({formData: data}, () => this.handleSubmit()) } }}
              ref={'FormBuilder'}
              formId={this.props.match.params.intakeid}
              enabledElements={['select', 'textinput', 'title', 'textareainput', 'numberinput', 'timeinput', 'dateinput', 'signatureinput', 'contactinput', 'photoinput', 'drawinginput', 'image']}
              />
          </Col>
        </Row>

      </div>
    );
  }
}



//export default ProjectIntakeDetail;

const ProjectIntakeDetailForm = Form.create({ name: 'intake_form' })(ProjectIntakeDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.intake.isFetching,
    isError : state.intake.isError,
    intake_form : state.intake.intake_form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIntakeByIdAction : bindActionCreators(getIntakeByIdAction, dispatch),
    addIntakeProjectAction : bindActionCreators(addIntakeProjectAction, dispatch),
    updateIntakeProjectAction : bindActionCreators(updateIntakeProjectAction, dispatch),
    resetIntakeAction : bindActionCreators(resetIntakeAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewAction : bindActionCreators(previewAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntakeDetailForm);
