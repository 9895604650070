import React from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Select,
  Modal,
  Switch,
  Tag,
  Divider,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../utils/Notifications';
import SelectAddresses from '../../../components/SelectAddresses/SelectAddresses';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormAction, addFormAction, updateFormAction, resetFormAction, downloadFormEntryAction, getFormMenuAction } from '../../../actions/formActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import PageHeader from '../../../components/PageHeader/PageHeader';
import LibrarySideMenu from '../LibrarySideMenu';
import FormSteps from '../Steps/FormSteps';
import CustomEditor from '../../../components/CustomEditor/CustomEditor';

class LibraryFormSettings extends React.Component {

  form_data = null;

  constructor(props) {
    super(props);

    this.state = {

        loading: true,
        formData: null,
        can_write: false,

        status_message: false,
        status_email_subject: 'Formulier ontvangen',
        status_email_message: 'Beste <placeholder class=\"cobee-tag\">aanhef</placeholder> <placeholder class=\"cobee-tag\">achternaam</placeholder>,<br />\
<br />\
Wij hebben het door u ingevulde formulier in goede orde ontvangen.<br />\
<br />\
Het formulier kunt u terugvinden in de app of op de bewonerspagina onder de tegels “Formulieren” en "Dossier".<br />\
<br />\
Heeft u vragen of opmerkingen, neem contact met ons op via <placeholder id="current" class="cobee-tag">project: telefoonnummer</placeholder> of <placeholder id="current" class="cobee-tag">project: e-mailadres</placeholder>.<br />\
<br />\
Bekijk het volledige formulier hier: <placeholder>Link naar formulier (PDF)</placeholder><br />\
<br />\
Met vriendelijke groet,<br />\
<placeholder class=\"cobee-tag\">bedrijfsnaam</placeholder>',
    };
  }

  componentDidMount()
  {
      this.setState({
          loading: true,
          can_write: this.props.HaveAbility('form' ,'write'),
          allow_form_to_be_emailed: false
      });

      this.props.resetFormAction();

      // this.props.getFormMenuAction(this.props.match.params.id).then(() => {});

      if(this.props.match.params.id)
      {
          this.props.getFormAction(this.props.match.params.id).then(() => {

              this.setState({ require_to_sign_form: this.props.form_details.require_to_sign_form ? true : false });
              this.setState({ require_to_sign_form_legally: this.props.form_details.require_to_sign_form_legally ? true : false });
              this.setState({ allow_form_to_be_emailed: this.props.form_details.allow_form_to_be_emailed ? true : false });
              this.setState({ nr_of_signatures: this.props.form_details.nr_of_signatures });

              try
              {
                  var signature_description = JSON.parse(this.props.form_details.signature_description);

                  for(let nr = 1; nr <= this.props.form_details.nr_of_signatures; nr++)
                  {
                      this.setState({ ['signature_'+nr+'_description']: signature_description[nr] });
                  }
              }
              catch(e){}

              if(this.props.form_details.status_message){ this.setState({ status_message: this.props.form_details.status_message }); }
              if(this.props.form_details.status_email_subject){ this.setState({ status_email_subject: this.props.form_details.status_email_subject }); }
              if(this.props.form_details.status_email_message){ this.setState({ status_email_message: this.props.form_details.status_email_message }); }

              this.setState({ loading: false });
          });
      }
      else
      {
          this.setState({ loading: false });
      }
  }

  saveForm(redirect) {

      this.props.form.validateFields((err, values) => {

        if (!err){

          values.hide_for_address = values.hide_for_address ? false : true;
          values.address_required = values.address_required ? false : true;
          values.signature_not_required = values.signature_not_required ? false : true;

          values.status_message = this.state.status_message;

          this.props.updateFormAction(this.props.match.params.id, values).then(() => {

            showSuccess();

            if(this.props.customer_form.locked)
            {
              this.props.history.push('/library/form/' + this.props.match.params.id + '/projects');
            }
            else
            {
              this.props.history.push('/library/forms');
            }
          });

        }

      });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Formulieren', 'link': '#'}, {'label': 'Formulier'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='19' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">

                  <Col span={12}>

                    <div>
                      <Button disabled={this.state.loading || !this.props.HaveAbility('library' ,'write')} type="new" onClick={() => { this.saveForm(); }}>
                        { this.props.customer_form.locked ? 'Opslaan en volgende' : 'Opslaan' }
                      </Button>

                      <Link to={`/library/forms`}>
                        <Button>Sluiten</Button>
                      </Link>

                    </div>
                  </Col>

                  <Col span={12} align={'right'}>
                      <Button disabled={this.props.match.params.id ? false : true} onClick={(event) => { this.preview(); }}>Voorbeeld</Button>
                  </Col>

                </Row>

                {/* steps */}
                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <FormSteps current={1} locked={this.props.customer_form ? this.props.customer_form.locked : false} {...this.props}/>
                    </Card>
                  </Col>
                </Row>

                  <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    { this.state.loading ? null :
                    <Card className="mbm">

                      <Row gutter={24}>

                        <Col span={12}>
                            <Form.Item className="mbn" label="Is openbaar">
                              {getFieldDecorator('is_public', {
                                  initialValue: this.props.form_details && this.props.form_details.is_public ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item className="mbn" label={(
                            <span>
                              In te vullen zonder koppeling met adres
                              <Tooltip className="mhs" title='Als deze optie uit staat kunnen beheerders het formulier ook invullen zonder dat deze aan een adres is gekoppeld. In dat geval kan een beheerder een omschrijving opgeven bij het formulier.'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                            </span>
                          )}>
                            {getFieldDecorator('address_required', {
                                initialValue: this.props.form_details && this.props.form_details.address_required ? false : true,
                                valuePropName: 'checked'
                            })(
                              <Switch />
                            )}
                          </Form.Item>
                        </Col>

                        { !this.props.project.is_public ?
                        <Col span={12}>
                            <Form.Item className="mbn" label="Bewoner mag formulier invullen">
                              {getFieldDecorator('allow_address_to_submit_entry', {
                                  initialValue: this.props.form_details && this.props.form_details.allow_address_to_submit_entry ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null}

                        <Col span={12}>
                            <Form.Item className="mbn" label="Meerdere keren in te vullen">
                              {getFieldDecorator('allow_multiple_entries', {
                                  initialValue: this.props.form_details && this.props.form_details.allow_multiple_entries ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                        { !this.props.project.is_public ?
                        <Col span={24}>
                            <Form.Item className="mbn" label="Tonen in dossier voor bewoner">
                              {getFieldDecorator('hide_for_address', {
                                  initialValue: this.props.form_details && this.props.form_details.hide_for_address == false ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null }

                        <Col span={this.state.require_to_sign_form ? 12 : 24}>
                            <Form.Item className="mbn" label="Digitaal ondertekenen">
                              {getFieldDecorator('require_to_sign_form', {
                                  initialValue: this.props.form_details && this.props.form_details.require_to_sign_form ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form: checked }) }
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                          <Form.Item className="mbn" label="Handtekening verplicht?">
                            {getFieldDecorator('signature_not_required', {
                              initialValue: this.props.form_details && this.props.form_details.signature_not_required ? false : true,
                              valuePropName: 'checked'
                            })(
                              <Switch />
                            )}
                          </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label="Hoeveel handtekeningen">
                            {getFieldDecorator('nr_of_signatures', {
                              initialValue: this.props.form_details && this.props.form_details.nr_of_signatures ? this.props.form_details.nr_of_signatures : '1',
                              onChange: (e) => { this.setState({ nr_of_signatures: e.target.value }); },
                              rules: [{ required: true, message: 'Vul een aantal in. Dit kan later in de app nog worden aangepast per formulier.' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && this.state.nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.nr_of_signatures))].map((i, s) => {

                        var nr = s + 1;

                        return <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label={"Handtekening "+nr+" omschrijving"}>
                            {getFieldDecorator('signature_'+nr+'_description', {
                              initialValue: this.state['signature_'+nr+'_description'],
                              onChange: (value) => { this.setState({ ['signature_'+nr+'_description']: value }); },
                              rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app nog worden aangepast per formulier.' }],
                            })(
                              <Input disabled={this.state.locked} />
                            )}
                          </Form.Item>
                        </Col>
                        })}</Col> : null }

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Rechtsgeldig ondertekenen
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per formulier is dit €1,00'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('require_to_sign_form_legally', {
                                  initialValue: this.props.form_details && this.props.form_details.require_to_sign_form_legally ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form_legally: checked }) }
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && this.state.require_to_sign_form_legally ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                SMS authenticatie gebruiken
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per verstuurde SMS is dit €0,25'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('use_sms_authentication_for_signing', {
                                  initialValue: this.props.form_details && this.props.form_details.use_sms_authentication_for_signing ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null }

                        <Col span={24}>
                            <Form.Item className="mbn" label="Formulier mag per e-mail doorgestuurd worden">
                              {getFieldDecorator('allow_form_to_be_emailed', {
                                  initialValue: this.props.form_details && this.props.form_details.allow_form_to_be_emailed ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => {
                                      this.setState({ allow_form_to_be_emailed: checked });

                                      var status_message = false;

                                      if(checked)
                                      {
                                          status_message = 'Wij hebben het door u ingevulde formulier in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.'
                                      }
                                      else
                                      {
                                          status_message = 'Wij hebben het door u ingevulde formulier in goede orde ontvangen!';
                                      }

                                      this.setState({status_message: status_message});
                                  }
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                      </Row>

                      <Divider></Divider>

                      { this.state.allow_form_to_be_emailed ?
                      <Row gutter={24}>
                          <Col span={24}>
                              <Form.Item required label="Standaardtekst bevestigingspagina">
                              { getFieldDecorator('status_message_on', {
                                initialValue: this.state.status_message ? this.state.status_message : 'Wij hebben het door u ingevulde formulier in goede orde ontvangen! Vul hieronder uw e-mailadres in om de bevestiging per e-mail te versturen.',
                                rules: [{ required: true, message: 'Vul een tekst in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                // onEditorChange: (value, editor) => { this.setState({ status_message: value }); }
                              })(
                                <CustomEditor height={300} placeholders={'form'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ status_message: e }); }} />
                              )}
                              </Form.Item>
                          </Col>
                      </Row> : null }

                      { !this.state.allow_form_to_be_emailed ?
                      <Row gutter={24}>
                          <Col span={24}>
                              <Form.Item required label="Standaardtekst bevestigingspagina">
                              { getFieldDecorator('status_message_off', {
                                initialValue: this.state.status_message ? this.state.status_message : 'Wij hebben het door u ingevulde formulier in goede orde ontvangen!',
                                rules: [{ required: true, message: 'Vul een tekst in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                // onEditorChange: (value, editor) => { this.setState({ status_message: value }); }
                              })(
                                <CustomEditor height={300} placeholders={'form'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ status_message: e }); }} />
                              )}
                              </Form.Item>
                          </Col>
                      </Row> : null }

                      { this.state.allow_form_to_be_emailed ?
                      <div>
                          <Divider></Divider>

                          <Row gutter={24}>
                              <Col span={24}>
                                <Form.Item required label="Bevestigingsmail e-mail onderwerp">
                                    {getFieldDecorator('status_email_subject', {
                                      initialValue: this.state.status_email_subject,
                                      rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                      // valuePropName: 'value',
                                      // trigger: 'onEditorChange',
                                      // onChange: (value) => { this.setState({ status_email_subject: value }); }
                                    })(
                                      <Input />
                                    )}
                                  </Form.Item>
                              </Col>
                          </Row>

                          <Row gutter={24}>
                              <Col span={24}>
                                  <Form.Item required label="Bevestigingsmail e-mail bericht">
                                  { getFieldDecorator('status_email_message', {
                                    initialValue: this.state.status_email_message,
                                    rules: [{ required: true, message: 'Vul een tekst in' }],
                                    valuePropName: 'value',
                                    trigger: 'onEditorChange',
                                    // onEditorChange: (value, editor) => { this.setState({ status_email_message: value }); }
                                  })(
                                    <CustomEditor height={300} placeholders={'form-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ status_email_message: e }); }} />
                                  )}
                                  </Form.Item>
                              </Col>
                          </Row>
                      </div> : null }

                    </Card>}

                  </Form>

              </div>

          </div>

      </div>
    );
  }
}


const LibraryFormSettingsForm = Form.create({ name: 'addresses_form' })(LibraryFormSettings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    customer_form : state.forms.form,
    form_details : state.forms.form,
    menus : state.forms.menus,
    project : state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAction : bindActionCreators(getFormAction, dispatch),
    addFormAction : bindActionCreators(addFormAction, dispatch),
    updateFormAction : bindActionCreators(updateFormAction, dispatch),
    resetFormAction : bindActionCreators(resetFormAction, dispatch),
    getFormMenuAction : bindActionCreators(getFormMenuAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryFormSettingsForm);
