export default (state = {
    isError: false,
    isFetching: false,
    information: [],
    globalInformation: [],
    globalCobeeInformation: [],
    informationitem: {},
    globalInformationitem: {},
    indicators: [],
    information_menu: [],
    information_menu_item: {}
}, action) => {

    switch (action.type) {
    case 'INFORMATION_ACTION_FETCH':
    return {
        ...state,
        globalInformation: [],
        globalCobeeInformation: [],
        information: [],
        isFetching: true,
        isError: false,
    }
    case 'INFORMATION_ITEM_ACTION_FETCH':
    return {
        ...state,
        informationitem: {},
        globalInformationitem: {},
        information_menu_item: {},
        isFetching: true,
        isError: false,
    }
    case 'INFORMATION_ACTION_SUCCESS':
    return {
        ...state,
        information: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INFORMATION_ACTION_RESET':
    return {
        ...state,
        informationitem: {},
        indicators: [],
        isFetching: false,
        isError: false,
    }
    case 'INFORMATIONITEM_ACTION_SUCCESS':
    return {
        ...state,
        informationitem: action.payload,
        partners: action.payload.partners,
        indicators: action.payload.indicators ? action.payload.indicators : [],
        isFetching: false,
        isError: false,
    }
    case 'ADD_INFORMATION_ACTION_SUCCESS':
    return {
        ...state,
        information: [action.payload, ...state.information],
        informationitem: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INFORMATIONITEM_ACTION_REMOVE':
    return {
        ...state,
        information: state.information.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'CLONE_INFORMATION_ACTION_SUCCESS':
    return {
        ...state,
        information: [action.payload, ...state.information],
        isFetching: false,
        isError: false,
    }
    case 'DUPLICATE_INFORMATION_ACTION':
    return {
        ...state,
        information: [...action.payload, ...state.information],
        isFetching: false,
        isError: false,
    }
    case 'INFORMATION_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }

    case 'INFORMATION_GLOBAL_ACTION_SUCCESS':
    return {
        ...state,
        globalInformation: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INFORMATION_COBEE_GLOBAL_ACTION_SUCCESS':
    return {
        ...state,
        globalCobeeInformation: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PATCH_INFORMATION_GLOBAL_ACTION_SUCCESS':
    return {
        ...state,
        globalInformationitem: action.payload,
        indicators: action.payload.indicators ? action.payload.indicators : [],
        isFetching: false,
        isError: false,
    }
    case 'GET_INFORMATION_GLOBAL_ACTION_SUCCESS':
    return {
        ...state,
        globalInformationitem: action.payload,
        indicators: action.payload.indicators ? action.payload.indicators : [],
        isFetching: false,
        isError: false,
    }

    case 'RESET_INFORMATION_GLOBAL_ACTION_FETCH':
    return {
        ...state,
        globalInformationitem: {},
        indicators: [],
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_INFORMATION_ACTION_SUCCESS':
    return {
        ...state,
        globalInformation: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_INFORMATION_GLOBAL_ACTION_SUCCESS':
    return {
        ...state,
        globalInformation: state.globalInformation.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SORT_INFORMATION_ACTION':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'ADD_INDICATOR_ACTION':
    return {
        ...state,
        indicators: [...state.indicators, {'id': state.indicators.length + 1, 'label': '', 'percent': 50, 'value': 50}].filter(Boolean),
    }
    case 'REMOVE_INDICATOR_ACTION':
    return {
        ...state,
        indicators: state.indicators.filter((v, k) => k != action.payload),
    }

    case 'INFORMATION_MENU_ACTION_SUCCESS':
    return {
        ...state,
        information_menus: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INFORMATION_MENU_ACTION_RESET':
    return {
        ...state,
        information_menu_item: {},
        indicators: [],
        isFetching: false,
        isError: false,
    }
    case 'INFORMATION_MENU_ITEM_ACTION_SUCCESS':
    return {
        ...state,
        information_menu_item: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_INFORMATION_MENU_ACTION_SUCCESS':
    return {
        ...state,
        information_menus: [action.payload, ...state.information_menus],
        information_menu_item: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INFORMATION_MENU_ITEM_ACTION_REMOVE':
    return {
        ...state,
        information_menus: state.information_menus.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'SORT_INFORMATION_MENU_ACTION':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }

    default:
        return state
    }
}
