import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseFormResendSteps from './SupportBaseFormResendSteps'
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../../utils/Notifications';
import CustomTable from '../../../../../components/CustomTable/CustomTable';

// import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { getAddressesAction, getSupportBaseFormByIdAction, updateSupportBaseFormAction } from '../../../../../actions/supportBaseActions';
import { shareAddressesAction, searchAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';

class SupportBaseResendAddresses extends React.Component {

  state = {
    // current: 0,
    // smsModule: false,
    // letterVisible: false,
    // letterMailVisible: false,
    // emailVisible: false,
    // smsVisible: false,
    // pushVisible: false,

    expandedRowKeys: [],
    expandButton: true,

    //subject: '',

    selectedLetterIds: [],
    //selectedLetterEmailIds: [],
    selectedEmailIds: [],
    // selectedSmsIds: [],
    // selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,
    // expandedRowKeys: [],

    // sendDate: null,
    // sendTime: null,

    filteredInfo: null,

  }

  // example data
  examples = [{id: 1, full_address: 'Straat 1', groups: '', occupants: [{id: 1, address_id: 1, email_address: 'email@adres.nl', full_name: 'Voornaam Achternaam', description: 'Bewoner' }]}];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getAddressesAction(this.props.match.params.id, (this.props.match.params.form_id ? this.props.match.params.form_id : 'new'), {'type': 'reminder'}).then(() => {
      this.props.getGroupsAction({'projectId': this.props.match.params.id});

      this.setState({currentDataSource: this.props.addresses},() => {
        var expandIds = [];
        this.state.currentDataSource.map((v) => {
          if(v.occupants) {
            expandIds.push('address-'+v.id)
          }
        });
        this.setState({expandedRowKeys: expandIds, expandButton: true});
      });
    });

    if(this.props.match.params.form_id) {
      this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false, false).then(() => {
        this.setState({
          selectedLetterIds: this.props.support_base_form.selection && this.props.support_base_form.selection.letter,
          selectedEmailIds: this.props.support_base_form.selection && this.props.support_base_form.selection.email,
        })
      });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  patchInformationAddresses() {}

  saveAndContinue()
  {
      if(!this.state.selectedLetterIds.length && !this.state.selectedEmailIds.length)
      {
          showError('Er zijn geen adressen geselecteerd voor post en of e-mail');
      }
      else
      {
          this.props.updateSupportBaseFormAction(this.props.match.params.form_id, {
              name: this.state.name,
              letterIds: this.state.selectedLetterIds,
              emailIds : this.state.selectedEmailIds
          }).then(() => {
              showSuccess('Succesvol opgeslagen');
              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/resend/compose/${this.props.match.params.form_id}`); // step 2
              // this.props.history.push(`/projects/${this.props.match.params.id}/support-base/resend/send/${this.props.match.params.form_id}`); // step 2
          });
      }
  }

  onSelectLetterChange = (value) => {
    if(this.state.selectedLetterIds.includes(value.target.value)) {
      this.setState({ selectedLetterIds: this.state.selectedLetterIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedLetterIds: [...this.state.selectedLetterIds, value.target.value]});
    }
  }

  onSelectEmailChange = (value) => {
    if(this.state.selectedEmailIds.includes(value.target.value)) {
      this.setState({ selectedEmailIds: this.state.selectedEmailIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedEmailIds: [...this.state.selectedEmailIds, value.target.value]});
    }
  }

  onCheckLetterAll = (value) => {

    if(value.target.checked) {

      var occ_ids = [];
      this.state.currentDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.different_address) {
            occ_ids.push('occ-'+elem.id);
          }
        })
      });

      var ids = this.state.currentDataSource.map((v) => v.id);

      this.setState({ selectedLetterIds: [...ids, ...occ_ids]});
    } else {
      this.setState({ selectedLetterIds: []});
    }

  }

  onCheckEmailAll = (value) => {
    if(value.target.checked) {

      var ids = [];
      this.state.currentDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.email_address) {
            ids.push('occ-'+elem.id);
          }
        })
      });

      this.setState({ selectedEmailIds: ids});
    } else {
      this.setState({ selectedEmailIds: []});
    }
  }

  onSearch(value) {
    // this.props.searchCorrespondenceAddressesAction(this.props.match.params.id, {'search': value});
  }

  render () {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        // title: 'Adressen',
        dataIndex: 'full_address',
        key: 'street',
        render: (text, record) =>
        record.full_address ? (
          record.full_address
        ) : (
          <span style={{fontSize:12}}>
            <UserOutlined size={30} style={{fontSize: 15}} /> {record.full_name} <Tag >{record.description ? record.description : 'Bewoner'}</Tag>
        </span>
      )
    },{
      title: 'Groepen',
      dataIndex: '',
      key: 'groups',
      width: 250,
      filters: this.props.groups && this.props.groups.map((elem) => {
        return {
          text: elem.name,
          value: elem.name,
        }
      }),
      filterMultiple: true,
      // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
      onFilter: (value, record) => this.groupsFilter(value, record),
      render: (text, record) =>
      record.groups && record.groups.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
    )
  },{
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckLetterAll}>Post</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedLetterIds && this.state.selectedLetterIds.length}></Badge></div>
    ),
    key: 'letter',
    width: 120,
    render: (value, index) => {
      var valueId       = value.id;
      var disabledCheck = false;

      if(value.address_id) {
        valueId = 'occ-'+value.id
      }

      if(value.address_id && !value.different_address) {
        disabledCheck = true;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'letter-'+valueId} checked={this.state.selectedLetterIds && this.state.selectedLetterIds.includes(valueId)} value={valueId} onChange={this.onSelectLetterChange} />
      )
    }
  },{
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckEmailAll}>E-mail</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedEmailIds && this.state.selectedEmailIds.length}></Badge></div>
    ),
    key: 'email',
    width: 120,
    render: (value, index) => {
      var valueId = value.id;
      var disabledCheck = false;

      if(!value.address_id && value.email != ''){
        disabledCheck = true;
      }

      if(value.email_address == undefined) {
        disabledCheck = true;
      }

      if(value.address_id) {
        valueId = 'occ-'+value.id
      } else {
        disabledCheck = true;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'email-'+valueId} checked={this.state.selectedEmailIds && this.state.selectedEmailIds.includes(valueId)} value={valueId} onChange={this.onSelectEmailChange} />
      )
    }
  }];

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button type="new" onClick={() => { this.saveAndContinue(); }}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/status`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseFormResendSteps current={0} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item hasFeedback className="mbn" required label="Omschrijving">
                        {getFieldDecorator('name', {
                          initialValue: this.props.support_base_form ? this.props.support_base_form.name : '',
                          rules: [{ required: true, message: 'Vul een omschrijving in' }],
                        })(
                          <Input disabled={true} />
                        )}
                      </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>

                </Row>
              </Form>

                <div>
                    <Row className="mbm">
                      <Col span={4}>
                        {/*<Button onClick={() => {

                          var expandIds = [];
                          if(!this.state.expandButton) {
                            this.state.currentDataSource.map((v) => {
                              if(v.occupants) {
                                expandIds.push('address-'+v.id)
                              }
                            });
                          }
                          this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
                        }}>Alles {this.state.expandButton ? 'inklappen' : 'uitklappen'}</Button>*/}
                      </Col>
                      <Col span={20}>
                        {/*<Input.Search
                          allowClear
                          placeholder="Zoeken..."
                          onSearch={value => this.onSearch(value)}
                          style={{ width: 200, float: 'right' }}
                          />*/}
                      </Col>
                    </Row>

                  <CustomTable
                    rowKey={(record) => {
                      if(record.address_id) {
                        return record.address_id + "-" + record.id;
                      } else {
                        return 'address-' + record.id;
                      }
                    }}
                    columns={columns}
                    dataSource={this.props.addresses.map((element) => {
                      if(element.occupants && element.occupants.length == 0) {
                        element.occupants = null;
                      }
                      return element;
                    })}
                    // dataSource={this.examples.map((element) => {
                    //   if(element.occupants && element.occupants.length == 0) {
                    //     element.occupants = null;
                    //   }
                    //   return element;
                    // })}
                    childrenColumnName="occupants"
                    indentSize="0"
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpandedRowsChange={(id) => this.setState({expandedRowKeys: id})}
                    loading={this.props.isFetching}
                    onChange={(pagination, filters, sorter, extra) => {
                      this.setState({currentDataSource: extra.currentDataSource});                      
                      this.handleTableChange(pagination, filters, sorter);
                    }}
                    />
                </div>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseResendAddressesForm = Form.create({ name: 'support_base_form' })(SupportBaseResendAddresses);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        support_base_form : state.support_base.support_base_form,
        addresses : state.support_base.addresses,
        groups : state.group.groups,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        updateSupportBaseFormAction : bindActionCreators(updateSupportBaseFormAction, dispatch),
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
        searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseResendAddressesForm);
