import React, { Component } from 'react';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'

import { v1 as uuidv1 } from 'uuid'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormAction, updateDeliveryFormAction, resetDeliveryFormAction } from '../../../../actions/deliverActions';
import { getAddressFieldsAction } from '../../../../actions/projectActions';

import PageHeader from '../../../../components/PageHeader/PageHeader';
import DeliveryFormSteps from './Steps';

import DeliverSideMenu from '../DeliverSideMenu';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';


class DeliveryForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      loading: true,
      readonly: false
    };
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('form' ,'write')
      });

      //this.props.resetDeliveryFormAction();

      //
      this.props.getAddressFieldsAction(this.props.match.params.id).then(() => {

          //
          //if(!isNaN(this.props.match.params.form_id))
          {
              this.props.getDeliveryFormAction(this.props.match.params.form_id).then(() => {

                  this.setState({ readonly: this.props.delivery_form.completed });

                  //
                  if(this.IsJsonString(this.props.delivery_form.form))
                  {
                      var json_data = JSON.parse(this.props.delivery_form.form);

                      this.refs.FormBuilder.parse(json_data);

                      this.setState({ formData: json_data, loading: false });
                  }
                  else
                  {
                      this.refs.FormBuilder.parse([{ 'id': uuidv1(), 'label': 'Opleverpunten', elements: [] }]);

                      this.setState({ loading: false });
                  }
              });
          }
      });
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getEnabledElements()
  {
      var elements = [];

      if(this.props.project.is_public)
      {   // public project
          elements = [
              'd_select',
              'd_textinput',
              'd_title',
              'd_textareainput',
              'd_numberinput',
              'd_timeinput',
              'd_dateinput',
              'd_photoinput',
              'd_drawinginput',
              'd_image',
              'd_deliverypointsinput'
          ];
      }
      else
      {   // private project
          elements = [
              'd_select',
              'd_textinput',
              'd_title',
              'd_textareainput',
              'd_numberinput',
              'd_timeinput',
              'd_dateinput',
              //'d_contactinput', // is only available for addresses
              'd_photoinput',
              'd_drawinginput',
              'd_image',
              'd_deliverypointsinput'
          ];
      }

      return elements;
  }

  handleSubmit = (e, values, redirect) => {

      var data = {
          form: JSON.stringify(this.state.formData)
      };

      // values.form = JSON.stringify(this.state.formData);

      this.props.updateDeliveryFormAction(this.props.match.params.form_id, data).then(() => {

          if(redirect === true)
          {
              this.props.history.push('/projects/' + this.props.match.params.id + '/deliver/delivery-forms');
          }
      });

      showSuccess();
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <DeliverSideMenu project_id={this.props.match.params.id} activeMenu='7' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('deliver' ,'write') || this.state.readonly} type="new" onClick={() => this.handleSubmit(null, this.state.formData, true)}>Opslaan</Button>
                      <Link to={`/projects/${this.props.match.params.id}/deliver/delivery-forms`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliveryFormSteps current={2} {...this.props} />
                    </Card>
                  </Col>
                </Row>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                  <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                    <Col span={24}>
                        <FormBuilder
                          onChangeForm={(data) => { if(this.state.can_write && !this.props.delivery_form.locked){ this.setState({ formData: data }, () => { this.handleSubmit(); }); } } }
                          ref={'FormBuilder'}
                          type={'deliver_form'}
                          formId={this.props.match.params.form_id}
                          enabledElements={this.getEnabledElements()}
                          sections={!this.props.project.is_public ? 0 : 1}
                          disabled={!this.props.HaveAbility('form' ,'write') || this.props.delivery_form.locked || this.state.readonly}
                          cloneSections={false}
                          addressFields={this.props.address_fields}
                          />
                    </Col>
                  </Row>

                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const DeliveryFormForm = Form.create({ name: 'intake_form' })(DeliveryForm);

const mapStateToProps = (state, ownProps) => {

  return {

    isFetching : state.deliver.isFetching,
    isError : state.deliver.isError,
    project : state.project.project,
    delivery_form : state.deliver.form,
    address_fields: state.project.address_fields
  };
}

const mapDispatchToProps = (dispatch) => {

  return {

    getDeliveryFormAction : bindActionCreators(getDeliveryFormAction, dispatch),
    updateDeliveryFormAction : bindActionCreators(updateDeliveryFormAction, dispatch),
    getAddressFieldsAction : bindActionCreators(getAddressFieldsAction, dispatch),
    resetDeliveryForm : bindActionCreators(resetDeliveryFormAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormForm);
