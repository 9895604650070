import React, { Component } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Alert,
} from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEnvelopesAction, searchEnvelopesAction, deleteEnvelopesAction } from '../../actions/envelopesActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';

import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';

import { HaveRole } from '../../utils/RolesAndAbilities';


class Envelope extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.props.getEnvelopesAction();
  }

  handleSubmit = (e, values) => {
    e.preventDefault();
    // this.props.form.validateFields((err, values) => {
    //   if (!err) {
    //     this.props.storeLibraryAction(values).then(() => {
    //       message.success('Succesvol opgeslagen');
    //
    //       this.setState({drawerVisible: false});
    //       this.props.form.resetFields();
    //     });
    //   }
    // });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  // cloneSelected = () => {
  //   this.props.duplicateEnvelopesByCustomerAction(this.state.selectedRowKeys[0]).then(() => {
  //     showSuccess('Succesvol gedupliceerd');
  //     this.setState({selectedRowKeys: []})
  //   });
  // }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteEnvelopesAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  render() {

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [
        {
          title: 'Naam',
          dataIndex: 'name',
          sorter: (a, b) => { return a.name.localeCompare(b.name)},
          render: (text, record) => (
            <span>
              <Link to={`/library/envelope/${record.id}/detail`}>
                {text}
              </Link>
            </span>
          )
        },
        {
          title: 'Type',
          dataIndex: 'type',
          sorter: (a, b) => { return a.type.localeCompare(b.type)},
          render: (text, record) => (
            <span>
                {record.type == 'return' ? 'Retour' : 'Normaal'}
            </span>
          )
        },
        {
          title: 'Retour adres',
          dataIndex: 'return_to',
          sorter: (a, b) => { return a.name.localeCompare(b.name)},
          render: (text, record) => (
            <span>
                {text == 'customer' ? 'Klant' : text == 'blank' ? 'Blanco' : 'Cobee'}
            </span>
          )
        }
    ];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Enveloppen'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='9' />

            <div style={{padding: 24, width: '100%'}}>
              <Alert message="LET OP: Toevoegen van enveloppen kan alleen door Cobee worden uitgevoerd. Wens je een nieuwe enveloppe toe te voegen aan jullie bibliotheek? Neem contact op met Cobee!" type="info" showIcon style={{marginBottom: 24}} />
                <Row className="mbm">
                  <Col span={12}>
                    <Button disabled={!this.props.HaveRole(['super-admin'])} onClick={() => this.props.history.push('/library/envelope/add')} icon={<PlusOutlined />} type="primary ant-btn-new">
                      Toevoegen
                    </Button>
                    <Dropdown trigger={['click']} disabled={ this.props.HaveRole(['super-admin']) && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                      <Menu>
                        {/*<Menu.Item disabled={this.state.selectedRowKeys.length > 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                          <Menu.Divider></Menu.Divider>*/}
                          <Menu.Item onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                      </Menu>)}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ value => this.props.searchEnvelopesAction(value) }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey="id"
                      rowSelection={(this.props.HaveRole(['super-admin'])) ? rowSelection : false}
                      //onChange={() => console.log('test')}
                      columns={columns}
                      loading={false}
                      dataSource={this.props.envelopes}
                      pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25','50', '100' ]}}
                    />
                  </Col>
                </Row>
          </div>

        </div>

    </div>
    );
}
}

const EnvelopeForm = Form.create({ name: 'horizontal_login' })(Envelope);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.envelope.isFetching,
    isError: state.envelope.isError,
    envelopes: state.envelope.envelopes,
    auth: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEnvelopesAction : bindActionCreators(getEnvelopesAction, dispatch),
    searchEnvelopesAction : bindActionCreators(searchEnvelopesAction, dispatch),
    deleteEnvelopesAction : bindActionCreators(deleteEnvelopesAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeForm);
