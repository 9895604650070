import React, { Component } from 'react';
// import './ProjectsCorrespondenceDetail.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_URL, JOB_URL } from '../../../../constants/settings';
import { showSuccess, showExtraConfirm } from '../../../../utils/Notifications';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { getAddressesAction } from '../../../../actions/addressesActions';
import { getPriceCorrespondenceAction, sendCorrespondenceAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, requestDownloadLetters, resetCorrespondencesAction } from '../../../../actions/correspondencesActions';
import { getNrOfNewQueuedItems } from '../../../../actions/queueActions';
import SetupMailCorrespondence from '../Form/SetupMailCorrespondence';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import CorrespondenceSteps from '../Steps/CorrespondenceSteps';
import EmailGeneratedLetters from '../Form/EmailGeneratedLetters';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Alert,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
} from 'antd';
import moment from 'moment';
import _ from 'underscore';

class ProjectsCorrespondenceSend extends Component {
  state = {
    current: 2,

    letterVisible: false,
    letterMailVisible: false,
    emailVisible: false,
    smsVisible: false,
    pushVisible: false,

    subject: '',

    selectedLetterIds: [],
    selectedLetterPages: '',
    selectedLetterEmailIds: [],
    selectedEmailIds: [],
    selectedSmsIds: [],
    selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,

    sendDate: null,
    sendTime: null,
    sendByCustomer: false,

    emailTo: '',
    downloadLetters: false,
    priceFetching: true,

    has_letter_method: false,
    show_letter_preview: false,
    letter_previewed: false
  };

  componentDidMount() {

    this.props.resetCorrespondencesAction();

    this.props.getCorrespondenceAddressesAction(this.props.match.params.id).then(() => {

      this.props.getGroupsAction({"projectId": this.props.match.params.id});

      if(this.props.match.params.correspondenceid) {

        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

          this.setState({
            subject: this.props.correspondence.subject,
            selectedLetterIds: this.props.correspondence.selection.mail,
            selectedLetterEmailIds: this.props.correspondence.selection.email_mail,
            selectedEmailIds: this.props.correspondence.selection.email,
            selectedSmsIds: this.props.correspondence.selection.sms,
            selectedPushIds: this.props.correspondence.selection.push,
          })

          if(this.props.correspondence.selection.mail.length > 0) {

            var letter = _.find(this.props.correspondence.methods, { "type": "mail", "address_id": null, "occupant_id": null });

            if(letter) {

              this.setState({
                  sendByCustomer: letter.send_by_customer,
                  has_letter_method: true
              });
            }
          }
        });
      }
      this.setState({currentDataSource: this.props.addresses});
    });

    this.props.getPriceCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

      this.setState({priceFetching: false});
    });
  }

  downloadLetters = () => {

    // modal
    this.props.requestDownloadLetters(this.props.match.params.correspondenceid, { /*'email': this.state.emailTo,*/ 'dpi': 72 }).then(() => {

      this.setState({emailTo: ''});

      showSuccess('Download verzoek ingediend');

      this.props.getNrOfNewQueuedItems();
    });
  }

  sendCorrespondence = () => {

      this.setState({
          show_letter_preview: false,
          letter_previewed: true
      });

      Modal.confirm({
        title: 'Weet je zeker dat je de correspondentie wilt versturen?',
        content: 'Als je op JA klikt, wordt de correspondentie op het gekozen tijdstip verstuurd.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {

                this.props.sendCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

                  this.props.history.push(`/projects/${this.props.match.params.id}/correspondence`);

                  showSuccess();
                });

                resolve();
            }).catch(() => {});
        },
        onCancel() {},
      });

    // showExtraConfirm((confirm) => {
    //   if(confirm) {
    //     this.props.sendCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {
    //       this.props.history.push(`/projects/${this.props.match.params.id}/correspondence`);
    //       showSuccess();
    //     });
    //   }
    // });
  }

  ignoreWarning()
  {
      Modal.confirm({
        title: 'Weet je zeker dat je de waarschuwing wilt negeren?',
        content: 'Als je op JA klikt, kan je de correspondentie, ongeacht de waarschuwing, verzenden door op de verzenden knop te klikken.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {

                this.setState({ ignore_warnings: true });

                resolve();
            }).catch(() => {});
        },
        onCancel() {},
      });
  }

  render() {

    return (
      <div>

          { this.props.warning_message ?
          <Row type="flex" align="middle">
              <Col span={24}>
                  <span>
                      <Alert
                          message="Waarschuwing"
                          description={
                              <div>
                                <span dangerouslySetInnerHTML={{__html: this.props.warning_message}} />
                                { !this.state.ignore_warnings ? <Button type="primary" danger style={{ marginTop: 16 }} onClick={() => { this.ignoreWarning(); }}>Waarschuwing negeren</Button> : null }
                              </div>
                          }
                          type="warning"
                          small
                          />
                  </span>
                  <br />
              </Col>
          </Row>
          : null }

        <Row className="mbm">
          <Col span={12}>
            <div>
              {!this.state.priceFetching ? (
              <Button
                disabled={!this.props.HaveAbility('correspondence', 'write') || this.props.price === false || (this.props.warning_message && !this.state.ignore_warnings) ? true : false}
                loading={this.props.isFetching}
                type="new"
                onClick={(e) => {
                    if(this.state.has_letter_method && !this.state.letter_previewed)
                    {
                        this.setState({ show_letter_preview: true });
                    }
                    else
                    {
                        this.sendCorrespondence();
                    }

                    e.preventDefault();

                    e.target.blur();
                }}
                >
                { (this.state.has_letter_method && !this.state.letter_previewed) ? 'Controleren voor verzenden' : 'Verzenden' }
              </Button>
              ) : null}

              <Link to={`/projects/${this.props.match.params.id}/correspondence`}>
                <Button>Sluiten</Button>
              </Link>

            </div>
          </Col>

        </Row>
        <Card style={{backgroundColor: '', borderRadius: '5px', marginBottom: 24}} bodyStyle={{padding:0}}>
          <Row>
            <CorrespondenceSteps current={this.state.current} {...this.props}/>
          </Row>
        </Card>

        <div>
          <Card loading={this.state.priceFetching} style={{backgroundColor: 'white', borderRadius: '5px', marginBottom: 24 }}>
            { this.state.sendByCustomer ? (
              <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="U heeft aangegeven om de brieven zelf te versturen. Klik hierboven om de overige correspondentie te versturen en toe te voegen aan het dossier." />
            ) : null }

            <Row type="flex">
              <Col span={14}>
                <Typography.Title level={4}>Samenvatting</Typography.Title>
                { this.state.sendByCustomer ? (
                  <Typography.Text type="secondary">Downloaden <strong>{this.state.selectedLetterIds.length}</strong> brie{this.state.selectedLetterIds.length > 1 ? 'ven' : 'f'}</Typography.Text>
                ) : (
                  <Typography.Text type="secondary">Versturen naar <strong>{this.state.selectedLetterIds.length}</strong> adres{this.state.selectedLetterIds.length > 1 && 'sen'}, aantal pagina's: {this.props.nr_of_pages}</Typography.Text>
                )}
                <br />
                <Typography.Text type="secondary">Versturen naar <strong>{this.state.selectedLetterEmailIds.length}</strong> e-mailadres{this.state.selectedLetterEmailIds.length > 1 && 'sen'}</Typography.Text>
                <br />
                <Typography.Text type="secondary">Versturen naar <strong>{this.state.selectedEmailIds.length}</strong> e-mailadres{this.state.selectedEmailIds.length > 1 && 'sen'}</Typography.Text>
                <br />
                <Typography.Text type="secondary">Versturen naar <strong>{this.state.selectedSmsIds.length}</strong> nummer{this.state.selectedSmsIds.length > 1 && 's'}</Typography.Text>
                <br />
                <Typography.Text type="secondary">Versturen naar <strong>{this.state.selectedPushIds.length}</strong> device{this.state.selectedPushIds.length > 1 && 's'}</Typography.Text>
              </Col>
              <Col span={10}>
                {this.props.price !== false ? (
                  <span>
                      <Typography.Title level={4}>Prijs</Typography.Title>
                      <span>€{this.props.price ? this.props.price.toFixed(2).replace('.', ',') : '-'} excl. btw</span>
                  </span>
                ) : (
                  <span>
                      <Alert
                          message="Kan niet versturen"
                          //description="Er kunnen tot en met 8 A4-tjes worden verstuurd. Dit betekent een brief met bijlage tot 8 zijden bij enkelzijdige bedrukking. En bij dubbelzijdige bedrukking kan een brief met bijlage worden opgemaakt tot maximaal 16 zijden."
                          description={this.props.error_message}
                          type="error"
                          small
                      />
                  </span>
                )}
              </Col>

              {this.state.selectedLetterIds.length > 0 ? (
                <Button style={{marginTop: 10}} onClick={() => { this.downloadLetters(); }}>Download alle brieven</Button>
              ) : null }

            </Row>
          </Card>

        </div>


        <Modal
          title="Download brieven"
          visible={this.state.downloadLetters}
          onOk={() => {

              this.emailGeneratedLetters.props.form.validateFields((error, values) => {

                if(!error) {
                  this.props.requestDownloadLetters(this.props.match.params.correspondenceid, { 'email': values.email }).then(() => {});

                  this.setState({ downloadLetters: false });

                this.emailGeneratedLetters.props.form.resetFields();
              }
            });
        }}
        onCancel={() => this.setState({downloadLetters: false})}
        okText={'Versturen'}
        >
            <EmailGeneratedLetters wrappedComponentRef={formRef => { this.emailGeneratedLetters = formRef }} />
        </Modal>


        <Modal
            title="Voorbeeld brief"
            visible={this.state.show_letter_preview}
            width={890}
            height={'100%'}
            onOk={() => {
                this.setState({
                    show_letter_preview: false,
                    letter_previewed: true
                });

                // this.sendCorrespondence();
            }}
            onCancel={() => {
                this.setState({
                    show_letter_preview: false
                });
            }}
            okText={'Goedkeuren'}
            >
            <iframe
                src={API_URL+'/correspondence/'+this.props.match.params.correspondenceid+'/view/mail/Voorbeeld brief.pdf?token='+localStorage.getItem('authToken')}
                style={{ width: 840, height: 600, border: 0, backgroundColor: '#606060' }}
                />
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    addresses : state.correspondence.addresses,
    groups : state.group.groups,
    price : state.correspondence.price,
    nr_of_pages : state.correspondence.nr_of_pages,
    price_error : state.correspondence.price_error,
    error_message : state.correspondence.message,
    warning_message : state.correspondence.warnings,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCorrespondencesAction : bindActionCreators(resetCorrespondencesAction, dispatch),
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    sendCorrespondenceAction : bindActionCreators(sendCorrespondenceAction, dispatch),
    getPriceCorrespondenceAction : bindActionCreators(getPriceCorrespondenceAction, dispatch),
    requestDownloadLetters : bindActionCreators(requestDownloadLetters, dispatch),
    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
}
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCorrespondenceSend);
