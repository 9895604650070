import api from '../utils/api'

export const getCustomerSurveysAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/surveys/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_SURVEYS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const getSurveysAction = (project_id) => dispatch => {

  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  return api('get', '/surveys/'+project_id, null, true).then((response) => {
    dispatch({
      type: 'SURVEYS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchCustomerSurveysAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/surveys/'+customer_id+'/search', {search: search}, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_SURVEYS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchSurveysAction = (projectid, values) => dispatch => {

  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  return api('post','/surveys/'+projectid+'/search', values, true).then((response) => {
    dispatch({
      type: 'SURVEYS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const getSurveyAction = (surveyid) => dispatch => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  return api('get','/survey/'+surveyid, null, true).then((response) => {
    dispatch({
      type: 'SURVEY_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const getSurveyAddressesAction = (survey_id) => dispatch => {

  dispatch({
    type: 'SURVEY_ADDRESSES_ACTION_FETCH'
  })

  return api('get','/survey/'+survey_id+'/addresses', null, true).then((response) => {
    dispatch({
      type: 'SURVEY_ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const addCustomerSurveyAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/surveys/'+customer_id, params, true).then((response) => {
    dispatch({
      type: 'ADD_SURVEY_ACTION_SUCCESS',
      payload: response.data.survey
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_ACTION_ERROR',
    })
    return false;
  });
}

export const addSurveyAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  return api('post','/surveys/'+projectid, params, true).then((response) => {
    dispatch({
      type: 'ADD_SURVEY_ACTION_SUCCESS',
      payload: response.data.survey
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_ACTION_ERROR',
    })
    return false;
  });
}

export const updateCustomerSurveyAction = (survey_id, params) => dispatch => {
  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  return api('post','/library/survey/'+survey_id, params, true).then((response) => {
    dispatch({
      type: 'SURVEY_ACTION_SUCCESS',
      payload: response.data.survey
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const patchSurveyAction = (surveyid, params) => dispatch => {
  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  return api('post','/survey/'+surveyid, params, true).then((response) => {
    dispatch({
      type: 'SURVEY_ACTION_SUCCESS',
      payload: response.data.survey
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const removeCustomerSurveysAction = (params) => (dispatch, getState) => {
  dispatch({
    type: 'SURVEYS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/surveys/'+customer_id+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'REMOVE_SURVEY_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const removeSurveysAction = (projectid, params) => dispatch => {
  dispatch({
    type: 'SURVEYS_ACTION_REMOVE_FETCH'
  })

  return api('post','/surveys/'+projectid+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'SURVEYS_ACTION_REMOVE_SUCCESS',
      // payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_REMOVE_ERROR',
    })
    return false;
  });
}

export const getSurveyQuestionsAction = (surveyid) => dispatch => {

  dispatch({
    type: 'SURVEY_QUESTIONS_ACTION_FETCH'
  })

  return api('get','/survey/'+surveyid+'/questions', null, true).then((response) => {
    dispatch({
      type: 'SURVEYQUESTIONS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const getSurveyQuestionAction = (surveyid, questionid) => dispatch => {

  dispatch({
    type: 'SURVEY_QUESTION_ACTION_FETCH'
  })

  return api('get','/survey/'+surveyid+'/question/'+questionid, null, true).then((response) => {
    dispatch({
      type: 'SURVEYQUESTION_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYS_ACTION_ERROR',
    })
    return false;
  });
}

export const addSurveyQuestionAction = (surveyid, params) => dispatch => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  return api('post','/survey/'+surveyid+'/question/', params, true).then((response) => {
    dispatch({
      type: 'ADD_SURVEYQUESTION_ACTION_SUCCESS',
      payload: response.data.surveyquestion
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_ACTION_ERROR',
    })
    return false;
  });
}

export const updateSurveyQuestionAction = (surveyid, surveyquestionid, params) => dispatch => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  return api('post','/survey/'+surveyid+'/question/'+surveyquestionid, params, true).then((response) => {
    dispatch({
      type: 'UPDATE_SURVEYQUESTION_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_ACTION_ERROR',
    })
    return false;
  });
}

export const updateSurveyQuestionsOrderAction = (surveyid, params) => dispatch => {

  dispatch({
    type: 'SURVEYQUESTIONS_ACTION_FETCH'
  })

  return api('POST','/survey/'+surveyid+'/questions/order/', params, true).then((response) => {
    dispatch({
      type: 'ORDER_SURVEYQUESTIONS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYQUESTIONS_ACTION_ERROR',
    })
    return false;
  });
}

export const syncSurveyQuestionsAction = (surveyid, params) => dispatch => {

  dispatch({
    type: 'SURVEYQUESTIONS_ACTION_FETCH'
  })

  return api('POST','/survey/'+surveyid+'/addresses/', params, true).then((response) => {
    dispatch({
      type: 'SURVEYQUESTIONS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYQUESTIONS_ACTION_ERROR',
    })
    return false;
  });
}

export const removeSurveyQuestionsAction = (surveyid, params) => dispatch => {

  dispatch({
    type: 'SURVEYQUESTIONS_ACTION_FETCH'
  })

  return api('post','/survey/'+surveyid+'/questions/multidelete/', params, true).then((response) => {
    dispatch({
      type: 'REMOVE_SURVEYQUESTIONS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEYQUESTIONS_ACTION_ERROR',
    })
    return false;
  });
}

export const resetSurveyAction = () => dispatch => {
  dispatch({
    type: 'RESET_SURVEY_ACTION'
  })
}

export const duplicateSurveyAction = (id, type, type_id) => dispatch => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  var params = {};

  if(type == 'customer'){ params.customer_id = type_id; }
  else if(type == 'project'){ params.project_id = type_id; }

  return api('post','/survey/'+id+'/clone', params, true).then((response) => {
    dispatch({
      type: 'DUPLICATE_SURVEY_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_ACTION_ERROR',
    })
    return false;
  });
}

export const exportSurveyAction = (id, params) => dispatch => {

  dispatch({
    type: 'SURVEY_ACTION_FETCH'
  })

  return api('post','/survey/'+id+'/export', params, true).then((response) => {
    dispatch({
      type: 'EXPORT_SURVEY_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_ACTION_ERROR',
    })
    return false;
  });
}

export const requestExportSurveyFormAction = (id) => dispatch => {

  dispatch({
    type: 'SURVEY_EXPORT_ACTION_FETCH'
  })

  return api('get', '/survey/'+id+'/request/export/form', null, true, false, true).then((response) => {
    dispatch({
      type: 'SURVEY_EXPORT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SURVEY_EXPORT_ACTION_ERROR',
    });
    return false;
  });
}

export const exportSurveyFormAction = (id, filename = '') => dispatch => {

  // dispatch({
  //   type: 'SURVEY_ACTION_FETCH'
  // })

  return api('get', '/survey/'+id+'/export/form', null, true, false, true).then((response) => {

      var reader = new FileReader();

      var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const a = document.createElement('a');

      a.setAttribute('download', (filename ? filename : 'enquete_formulier-'+id+'.xlsx'));

      a.setAttribute('href', window.URL.createObjectURL(blob));

      a.click();

    // dispatch({
    //   type: 'EXPORT_SURVEY_ACTION',
    //   payload: response.data
    // });
  }).catch((e) => {
    // dispatch({
    //   type: 'SURVEY_ACTION_ERROR',
    // })
    return false;
  });
}

export const previewAction = (form_id) => (dispatch, getState) => {

    return api('get','/survey/' + form_id + '/preview', null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'text/html' });

        reader.readAsText(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write(reader.result);

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}
