import React, { Component } from 'react';
import { SmileTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSurveyAction, resetSurveyAction } from '../../../../../actions/surveysActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import SurveySteps from '../Steps/SurveySteps';
import { showSuccess } from '../../../../../utils/Notifications';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class SurveyAddresses extends Component {

  state = {
    selectedAddressIds: []
  }

  constructor(props) {
    super(props);
    // this.selectaddresses = React.createRef();
  }

  componentDidMount() {

    this.props.resetSurveyAction();

    if(this.props.match.params.surveyid) {
      this.props.getSurveyAction(this.props.match.params.surveyid).then(() => {
      });
    }
  }

  patchAddresses() {

    // this.selectaddresses.current.share();

    this.myref.share().then(() => {

        showSuccess('Succesvol opgeslagen');

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete`);
    });
  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
                {this.props.survey.is_public === 0 ?
                <Button disabled={!this.props.HaveAbility('survey' ,'write')} type="new" onClick={() => this.patchAddresses()}>Opslaan</Button>
                :
                <Link to={`/projects/${this.props.match.params.id}/modules/enquete/${this.props.survey.id}/results${this.props.survey.version > 1 ? '-v2' : ''}`}>
                  <Button type="new">Volgende</Button>
                </Link>
                }
                <Link to={`/projects/${this.props.match.params.id}/modules/enquete`}>
                  <Button>Sluiten</Button>
                </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <SurveySteps version={this.props.survey ? this.props.survey.version : 2} current={2} {...this.props}/>
            </Card>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {this.props.survey.is_public === 0 ? (
              this.props.survey.addresses && (
                <SelectAddresses {...this.props} /*forwardedRef={this.selectaddresses}*/ childRef={ref => this.myref = ref} initialAddresses={this.props.survey.addresses} objectId={this.props.match.params.surveyid} objectType="survey" onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds})} />
              )
            ) : (
              !this.props.isFetching ? (
                <Result
                  icon={<SmileTwoTone />}
                  title="De enquete staat ingesteld op openbaar dus iedereen kan het zien."
                />
              ) : null
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.survey.isFetching,
    isError : state.survey.isError,
    survey : state.survey.survey,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
    resetSurveyAction : bindActionCreators(resetSurveyAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAddresses);
