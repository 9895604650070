import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Button,
  Popconfirm,
  Tag,
  Card,
  Calendar,
  Row,
  Col,
  Checkbox,
  Select,
  Alert,
  Input,
  DatePicker,
} from 'antd';
import {getAppointmentFreeSlotsAction} from '../../../../../actions/appointmentsActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'underscore';
import 'dayjs/locale/nl';
dayjs.locale('nl');

class ClusterFreeSlots extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedSlotId: null,
      selectedAddress: {},
      selectedEmail: '',
      selectedMobile: '',
      occupants: [],
    };
  }

  componentDidMount() {

    var selectedAddress = _.findWhere(this.props.address, {id: this.props.addressId});

    if(selectedAddress && selectedAddress.occupants) {

      this.setState({occupants: selectedAddress.occupants});

      // selectedAddress.occupants.map((occupant) => {
      //   if(occupant.email_address != '' && this.state.selectedEmail == '') {
      //     this.setState({selectedEmail: occupant.email_address });
      //   }
      //
      //   if(occupant.mobile != ''  && occupant.mobile != null ) {
      //     this.setState({selectedMobile: occupant.mobile });
      //   }
      // })
    }

    this.setState({selectedAddress });

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        <Row gutter={24}>
          <Col span={24}>
            { //this.props.free_slots.length > 0 ? (
             Object.keys(this.props.free_slots).length > 0 ? (
              <Row gutter={24}>
                <Col span={24}>

                  <Form.Item required label={'Tijd slot selecteren'}>
                    {getFieldDecorator('slot_id', {
                      initialValue: '',
                      rules: [{ required: true, message: 'Selecteer tijd' }],
                    })(
                      <Select onChange={(selectedSlotId) => this.setState({selectedSlotId, selectedSlotId: selectedSlotId})} style={{width: '100%', marginBottom: 0}}>
                        {/*<Select.Option value='cancel' key='cancel'>Afspraak annuleren</Select.Option>*/}
                        <Select.Option value='other' key='other'>Anders, namelijk:</Select.Option>
                        { //this.props.free_slots.map((element) => (
                          Object.values(this.props.free_slots).map((element) => (
                          <Select.Option value={element.id} key={element.id}>{moment(element.date).format('DD/MM/YYYY')} - { element.appointment_daypart_id ?element.time : moment(element.time, 'HH:mm').format('HH:mm')}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>

                  { this.state.selectedSlotId == 'other' ?
                  <Form.Item required={this.state.selectedSlotId == 'other' ? true : false } label={'Tijd slot selecteren'}>
                    {getFieldDecorator('new_slot', {
                      initialValue: '',
                      rules: [{ required: true, message: 'Selecteer datum en tijd' }],
                    })(
                      <DatePicker
                        showTime
                        format="DD-MM-YYYY HH:mm"
                        placeholder="Selecteer datum en tijd"
                        // onChange={(value) => { this.setState({ new_slot: value }); }}
                        onOk={(value) => { this.setState({ new_slot: value }); }}
                        />
                    )}
                  </Form.Item> : null }

                  <Form.Item label={'Neem e-mailadres en mobiel over van contactpersoon'}>
                      <Select onChange={(result) => {
                            if(this.state.occupants[result].email_address != '' ) {
                              this.setState({selectedEmail: this.state.occupants[result].email_address });
                            }

                            if(this.state.occupants[result].mobile != ''  && this.state.occupants[result].mobile != null ) {
                              this.setState({selectedMobile: this.state.occupants[result].mobile });
                            } else {
                              this.setState({selectedMobile: '' });
                            }

                        }} style={{width: '100%', marginBottom: 0}}>
                        {/*<Select.Option value='cancel' key='cancel'>Afspraak annuleren</Select.Option>*/}
                        {this.state.occupants.map((element, index) => (
                          <Select.Option value={index} key={element.id}>{element.full_name}</Select.Option>
                        ))}
                      </Select>
                  </Form.Item>

                  <div>
                    <div>
                      {getFieldDecorator('send_confirmation_email', {
                        initialValue: false,
                        valuePropName: 'checked',
                        rules: [{ required: false, message: 'Vul je voornaam in.' }],
                      })(
                        <Checkbox>E-mail versturen</Checkbox>
                      )}

                      {this.props.form.getFieldValue('send_confirmation_email') ? (
                        <div>
                          <Form.Item required label={'E-mailadres'}>
                            {getFieldDecorator('email_address', {
                              initialValue: this.state.selectedEmail,
                              rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>
                      ) : null}
                    </div>

                    <div>
                    {getFieldDecorator('send_confirmation_sms', {
                      initialValue: false,
                      valuePropName: 'checked',
                      rules: [{ required: false, message: 'Vul je voornaam in.' }],
                    })(
                      <Checkbox style={{marginBottom: 10}}>Sms versturen</Checkbox>
                    )}
                    {this.props.form.getFieldValue('send_confirmation_sms') ? (
                      <Form.Item required label={'Mobiel'}>
                        {getFieldDecorator('mobile_number', {
                          initialValue: this.state.selectedMobile,
                          rules: [{ required: true, message: 'Vul een nummer in' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    ) : null}
                    </div>
                  </div>
                {/*<Button onClick={() => this.props.onSelectSlot(this.state.selectedSlotId, this.props.addressId)} type="new">Opslaan</Button>*/}

                </Col>
              </Row>
            ) : (
              <Alert message="Geen beschikbare data/tijden meer" type="error" />
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}
const ClusterFreeSlotsForm = Form.create({ name: 'clusters_form' })(ClusterFreeSlots);
const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    address : state.address.addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentFreeSlotsAction: bindActionCreators(getAppointmentFreeSlotsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterFreeSlotsForm);
