import React, { Component } from 'react';
import api from '../../../utils/api';
import Logo from '../../../assets/img/logo1.svg';
// import BgImage from '../../../assets/img/login.jpg';
import BgImage from '../../../assets/img/login_new.png';
import { passwordValidation } from '../../../utils/validationRules';
import { showSuccess } from '../../../utils/Notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginAction } from '../../../actions/authAction';
import '../Login.css';

import { LockOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Layout, Col, Row, Input, Button, Alert, Typography, message } from 'antd';

class Login extends Component {
  state = {
    loading: false,
    loginError: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {

          var params = {
            ...this.props.match.params,
            ...values,
          }

          api('post', 'auth/reset', params, false).then(() => {
            showSuccess('Wachtwoord gewijzigd');
            this.props.history.push('/login');
          }).catch(e => showSuccess('Sorry, er is iets mis gegaan', null, 'close-circle', '#f5222d'));
      }
    });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Wachtwoorden komen niet overeen');
    } else {
      callback();
    }
  };

  render() {
      const { getFieldDecorator } = this.props.form;
      const { Title } = Typography;
      const { Paragraph } = Typography;

      return (
        <div className="loginScreen" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>
          <Layout style={{ backgroundColor: 'white' }}>
              <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

                  <Col xs={0} sm={0} md={10} lg={8} xl={10} xxl={10}>
                    <div
                      className="loginScreen__image"
                      style={{
                          backgroundImage: `url(${BgImage})`,
                          backgroundPosition: 'top right',
                          backgroundSize: 'cover'
                      }}>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={16} xl={14} xxl={14} type="flex" align="middle">
                      <div style={{ maxWidth: '400px', padding:24 }}>
                      <img width="200px" height="auto" alt="Cobee" src={Logo} style={{ width: 200, marginBottom: 50 }}></img>

                          <Form onSubmit={this.handleSubmit} className="login-form" style={{ width: '100%', textAlign: 'middle', }}>

                              <Title level={2}>Wachtwoord wijzigen</Title>
                              <Paragraph>Stel een nieuw wachtwoord in.</Paragraph>
                              <Form.Item style={{ marginBottom: '8px' }}>
                              { getFieldDecorator('password', {
                                rules: [{ validator: passwordValidation }],
                                })(
                                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Wachtwoord" />
                                ) }
                              </Form.Item>

                              <Form.Item style={{ marginBottom: '8px' }}>
                              {getFieldDecorator('password_confirmation', {
                                rules: [
                                  { required: true, message: 'Vul een wachtwoord in' },
                                  {
                                    validator: this.compareToFirstPassword,
                                  },
                                ],
                                })(
                                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Herhaal wachtwoord" />
                                )}
                              </Form.Item>

                              <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button" loading={this.props.isFetching}>
                              Wachtwoord wijzigen
                              </Button>
                          </Form>
                      </div>
                  </Col>
              </Row>
          </Layout>
        </div>
      );
  }
}

const LoginForm = Form.create({ name: 'normal_login' })(Login);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.auth.isFetching,
    isError : state.auth.isError
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction : bindActionCreators(loginAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
