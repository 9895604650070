import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Upload,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMediaCategoryAction, addMediaCategoryAction, updateMediaCategoryAction, resetMediaCategoryAction } from '../../../../actions/medialibraryActions';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import ProjectMediaSideMenu from '../ProjectMediaSideMenu';
import moment from 'moment';

class ProjectMediaCategory extends Component {

  state = {
    selectedRowKeys: [],
  };

  constructor(props)
  {
    super(props);
  }

  componentDidMount()
  {
      this.props.resetMediaCategoryAction();

      this.loadData();
  }

  loadData()
  {
      if(this.props.match.params.category_id && this.props.match.params.category_id != 'add')
      {
          this.props.getMediaCategoryAction(this.props.match.params.category_id);
      }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {

      if(!err){

        if(this.props.match.params.category_id != 'add') {

          this.props.updateMediaCategoryAction(this.props.match.params.category_id, values).then(() => {

            showSuccess();

            this.props.history.push(`/projects/${this.props.match.params.id}/media/categories`);
          });
        } else {
          this.props.addMediaCategoryAction(values).then((data) => {

            showSuccess();

            this.props.history.push(`/projects/${this.props.match.params.id}/media/categories`);
          });
        }
      }
    });
  };

  render()
  {

    const { getFieldDecorator } = this.props.form;

    return (

        <div>

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <ProjectMediaSideMenu id={this.props.match.params.id} activeMenu='2' />

              <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                    <Col span={12}>
                      <div>
                        <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                        <Link to={`/projects/${this.props.match.params.id}/media/categories`}>
                          <Button>Sluiten</Button>
                        </Link>
                      </div>
                    </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form layout='vertical' colon={true} onSubmit={this.handleSubmit}>
                      <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item required label="Naam" hasFeedback>
                              {getFieldDecorator('name', {
                                initialValue: this.props.mediacategory && this.props.mediacategory.name,
                                rules: [{ required: true, message: 'Vul een naam in' }],
                              })(
                                <Input />
                              )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>

              </div>

          </div>

        </div>);
  }
}

const ProjectMediaCategoryForm = Form.create({ name: 'horizontal_login' })(ProjectMediaCategory);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.medialibrary.isFetching,
    isError : state.medialibrary.isError,
    mediacategory : state.medialibrary.category,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMediaCategoryAction : bindActionCreators(getMediaCategoryAction, dispatch),
    addMediaCategoryAction : bindActionCreators(addMediaCategoryAction, dispatch),
    updateMediaCategoryAction : bindActionCreators(updateMediaCategoryAction, dispatch),
    resetMediaCategoryAction : bindActionCreators(resetMediaCategoryAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMediaCategoryForm);
