import React, { Component, cloneElement } from 'react';
// import './Information.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import  AddInformationForm from './Form/AddInformation';
import { getInformationAction, removeInformationAction, searchInformationAction, sortInformationAction, duplicateInformationAction } from '../../../../actions/informationActions';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import CloneInformation from './Modal/CloneInformation'
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveAbility, HaveRole } from '../../../../utils/RolesAndAbilities';
import InformationSidemenu from './InformationSideMenu';

import { DownOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Select,
  Upload,
  Switch,
  Tag,
  Button,
  Drawer,
  Confirm,
  message,
  Steps,
  Slider,
  Menu,
  Dropdown,
  Modal,
  Alert,
} from 'antd';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }

// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     props.moveRow(dragIndex, hoverIndex);
//
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );

class Information extends Component {
  state = {
    drawerVisible: false,
    selectedRowKeys: [],
    informationTexts: [],
  };

  componentDidMount() {
    this.props.getInformationAction(this.props.match.params.id).then(() => {
      this.setState({informationTexts: this.props.information});
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.addInformationform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addInformationAction(this.props.match.params.id, values);
  //       this.addInformationform.props.form.resetFields();
  //       this.setState({drawerVisible: false});
  //     }
  //   });
  // }

  showConfirm() {

    showMultiDelete((multidelete) => {

      if(multidelete) {

        this.props.removeInformationAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
          // this.setState({selectedRowKeys: [], informationTexts: this.props.information})

          this.setState({ selectedRowKeys: [] });

          this.props.getInformationAction(this.props.match.params.id).then(() => {
            this.setState({informationTexts: this.props.information});
          });

          showSuccess('Succesvol verwijderd')
        });

      }

    });
  }

  duplicate = () => {
    this.props.duplicateInformationAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
      this.setState({selectedRowKeys: [], informationTexts: this.props.information})
    });
  }

  onSearch(value) {
    this.props.searchInformationAction(this.props.match.params.id, {'search': value}).then(() => {
      this.setState({informationTexts: this.props.information});
    });
  }

  // moveRow = (dragIndex, hoverIndex) => {
  //   const { informationTexts } = this.state;
  //   const dragRow = informationTexts[dragIndex];
  //
  //   this.setState(
  //     update(this.state, {
  //       informationTexts: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.informationTexts.map((v,k) => v.id);
  //
  //   this.props.sortInformationAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
  // };
  //
  // components = {
  //   body: {
  //     row: DragableBodyRow,
  //   },
  // };

  render() {
    const columns = [
    {
      key: "sort",
      width: 20
    },
    {
      title: 'Titel',
      dataIndex: 'title',
      render: (text, record) => (
        <span>
          <Link to={`/projects/${record.project_id}/modules/information/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    }, {
      title: 'Openbaar',
      dataIndex: 'is_public',
      render: (text, record) => (
        <span>
          <Tag color={text == 0 ? "blue" : "green" }>
            {text == 0 ? "Nee" : "Ja" }
          </Tag>
        </span>
      ),
    }, {
      title: 'Aantal adressen',
      dataIndex: 'nr_of_addresses'
    }, {
      title: 'Categorie',
      dataIndex: 'menuitem_name',
      render: (name, record) => {
        return (
          <div>{name}</div>
        )
      },
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var informationTexts = this.state.informationTexts;

        const activeIndex = informationTexts.findIndex((i) => i.id === active.id);
        const overIndex = informationTexts.findIndex((i) => i.id === over.id);

        this.setState({ informationTexts: arrayMove(informationTexts, activeIndex, overIndex) }, () => {

          var sort = this.state.informationTexts.map((v,k) => v.id);

          this.props.sortInformationAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
        });
      }
    };

    return (
      <div>
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <InformationSidemenu id={this.props.match.params.id} activeMenu={["1"]} {...this.props}/>
            <div className="chatScreen" layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
                <Row className="mbm">
                  <Col span={12}>
                    <Dropdown disabled={!this.props.HaveAbility('information' ,'write')}  trigger={['click']} overlay={(
                        <Menu>
                          <Menu.Item key="1">
                              <Link to={`/projects/${this.props.match.params.id}/modules/information/add`}>
                                  Nieuw
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="2">
                              <CloneInformation onClone={(item) => {
                                  this.props.history.push('information/' + item.id + '/detail');
                                  this.setState({ informationTexts: this.props.information });
                              }} {...this.props} plain />
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button type="new">
                          Toevoegen <DownOutlined />
                      </Button>
                  </Dropdown>
                    {/*<Link to={`/projects/${this.props.match.params.id}/modules/information/add`}>
                      <Button disabled={!this.props.HaveAbility(12 ,'update')} type="new" icon="plus">Toevoegen</Button>
                    </Link>
                    {this.props.HaveRole(['super-admin', 'admin', 'projectmanager']) ? (
                      <CloneInformation onClone={() => this.setState({informationTexts: this.props.information})} {...this.props} />
                    ) : null}*/}

                      <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('information' ,'write') && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                        <Menu>
                          <Menu.Item onClick={() => this.duplicate() }>Dupliceren</Menu.Item>
                          <Menu.Divider />
                          <Menu.Item onClick={() => this.showConfirm() } key="5" disabled={!this.props.HaveAbility('information' ,'delete')} style={{ color: this.props.HaveAbility('information', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                        </Menu>
                      )}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>

                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={value => this.onSearch(value)}
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                {/*<Row>
                    <Col span={24}>
                        <Alert style={{marginBottom: 18}} showIcon type="info" message="Tip" description="Maak eerst een of meerdere categorieën aan, zodat de informatietekst aan deze categorie gekoppeld kan worden." />
                    </Col>
                </Row>*/}
                <Row>
                  <Col span={24}>
                    { this.props.HaveAbility('information', 'write') ?
                        <DndContext onDragEnd={onDragEnd}>
                          <SortableContext
                            items={this.state.informationTexts.map((i) => i.id)}
                            strategy={verticalListSortingStrategy}
                          >
                            <CustomTable
                              rowKey='id'
                              columns={columns}
                              dataSource={this.state.informationTexts}
                              components={!this.props.isFetching ? {
                                  body: {
                                      row: DraggableRow
                                  }
                              } : {}}
                              size="middle"
                              rowSelection={rowSelection}
                              loading={this.props.isFetching}
                              />
                          </SortableContext>
                        </DndContext>
                        :
                        <CustomTable
                            rowKey='id'
                            size="middle"
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={this.state.informationTexts}
                            loading={this.props.isFetching}
                            pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: false
                            }}
                            // components={this.components}
                            // onRow={(record, index) => ({
                            //   index,
                            //   moveRow: this.moveRow,
                            // })}
                            />
                    }
                  </Col>
                </Row>
              </div>
            </div>
        </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.information.isFetching,
    isError : state.information.isError,
    information: state.information.information,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    removeInformationAction : bindActionCreators(removeInformationAction, dispatch),
    searchInformationAction:  bindActionCreators(searchInformationAction, dispatch),
    sortInformationAction:  bindActionCreators(sortInformationAction, dispatch),
    duplicateInformationAction:  bindActionCreators(duplicateInformationAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    HaveRole:  bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
