import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../../SupportBaseSideMenu';
import SupportBaseResendCorrespondenceSteps from './SupportBaseResendCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess } from '../../../../../../utils/Notifications';
import CustomEditor from '../../../../../../components/CustomEditor/CustomEditor';
import { checkSupportBaseFormDocumentsAction, checkSupportBaseFormEmailsAction } from '../../../../../../actions/supportBaseActions';

class SupportBaseResendCorrespondenceCheck extends React.Component {

  state = {
      documents_email: this.props.auth.user.email,
      emails_email: this.props.auth.user.email,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  patchInformationAddresses() {}

  checkDocuments()
  {
      if(this.validateEmail(this.state.documents_email))
      {
          this.props.checkSupportBaseFormDocumentsAction(this.props.match.params.form_id, this.state.documents_email).then(() => {

              showSuccess('Verzoek verzonden');

              // this.setState({ documents_email: '' });
          });
      }
      else
      {
          showSuccess('Vul een geldig e-mailadres in', '', '', '#ff0000');
      }
  }

  checkEmails()
  {
      if(this.validateEmail(this.state.emails_email))
      {
          this.props.checkSupportBaseFormEmailsAction(this.props.match.params.form_id, this.state.emails_email).then(() => {

              showSuccess('Verzoek verzonden');

              // this.setState({ emails_email: '' });
          });
      }
      else
      {
          showSuccess('Vul een geldig e-mailadres in', '', '', '#ff0000');
      }
  }

  validateEmail(string)
  {
      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    {/*<Button type="new">Opslaan en volgende</Button>*/}
                    <Link to={`/projects/${this.props.match.params.id}/support-base/resend/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseResendCorrespondenceSteps current={2} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
              <Row>
                <Col span={24}>
                  <Form.Item label="Controle correspondentie per post">
                      <Row gutter={8}>
                          <Col span={8}>
                              <Input
                                type="email"
                                placeholder={'e-mailadres'}
                                value={this.state.documents_email}
                                onChange={(event) => { this.setState({documents_email: event.target.value}); }}
                                style={{width: '100%'}}
                              />
                          </Col>
                          <Col span={16}>
                              <Button onClick={() => { this.checkDocuments(); }}>Verzend documenten ter controle</Button>
                          </Col>
                      </Row>
                  </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Controle correspondentie per e-mail">
                        <Row gutter={8}>
                            <Col span={8}>
                                <Input
                                  type="email"
                                  placeholder={'e-mailadres'}
                                  value={this.state.emails_email}
                                  onChange={(event) => { this.setState({emails_email: event.target.value}); }}
                                  style={{width: '100%'}}
                                />
                            </Col>
                            <Col span={16}>
                                <Button onClick={() => { this.checkEmails(); }}>Verzend per mail ter controle</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
              </Row>
              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseResendCorrespondenceCheckForm = Form.create({ name: 'intake_form' })(SupportBaseResendCorrespondenceCheck);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        support_base_form: state.support_base.support_base_form,
        auth: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkSupportBaseFormDocumentsAction : bindActionCreators(checkSupportBaseFormDocumentsAction, dispatch),
        checkSupportBaseFormEmailsAction : bindActionCreators(checkSupportBaseFormEmailsAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseResendCorrespondenceCheckForm);
