import React, { Component } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { importAddressesByMapAction } from '../../../actions/addressesActions';
// import { getGroupsAction } from '../../../actions/groupsActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Upload,
  message,
  Drawer,
  Transfer,
  Tag,
  InputNumber,
  Steps,
  Card,
} from 'antd';
// import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';
import { showSuccess, showExtraConfirm, showError } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';
import { Loader } from '@googlemaps/js-api-loader';
import { API_URL, GOOGLE_MAPS_API_KEY } from '../../../constants/settings';
// import './map.css';

const Search  = Input.Search;

const confirm = Modal.confirm;

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY ,
  version: "weekly",
  libraries: ["drawing","places"]
});

const NL_BOUNDS = {
  north: 54,
  south: 50.5,
  west: 3,
  east: 8,
};

const mapOptions = {
  center: {
    lat: 52,
    lng: 6
  },
  zoom: 7,
  mapTypeControl: false,
  streetViewControl: false,
  restriction: {
    latLngBounds: NL_BOUNDS,
    strictBounds: false,
  }
};

class ProjectsAddressesMapImport extends Component {

  state = {
    visible: false,
    importEnabled: false,
    shapes: [],
    shape: null,
    search_url: null,
    current_step: 1,
    found_addresses: [],
    selectedRowKeys: []
  };

  componentDidMount() {

    var this_obj = this;

    loader
      .load()
      .then((google) => {

          const google_import_map = new google.maps.Map(document.getElementById("google-map"), mapOptions);

          // drawing
          const drawingManager = new google.maps.drawing.DrawingManager({
            // drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                google.maps.drawing.OverlayType.CIRCLE,
                google.maps.drawing.OverlayType.POLYGON
              ]
            },
            circleOptions: {
              fillColor: "#888800",
              fillOpacity: 0.5,
              strokeWeight: 2,
              clickable: false,
              editable: false,
              zIndex: 1,
            }
          });

          google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {

            // single shape
            if(this_obj.state.shape)
            {   // remove previous shape
                this_obj.state.shape.overlay.setMap(null);
            }

            this_obj.setState({ shape: event });

            // multiple shapes
            // var shapes = this_obj.state.shapes;
            //
            // shapes.push(event.overlay);
            //
            // this_obj.setState({ shapes: shapes });
            //
            // console.log('add shape', event.overlay);
          });

          drawingManager.setMap(google_import_map);


          // search
          // Create the search box and link it to the UI element.
          const input = document.getElementById("pac-input");
          const searchBox = new google.maps.places.SearchBox(input);

          google_import_map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          google_import_map.addListener("bounds_changed", () => {
            searchBox.setBounds(google_import_map.getBounds());
          });

          let markers: google.maps.Marker[] = [];

          // Listen for the event fired when the user selects a prediction and retrieve
          // more details for that place.
          searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
              return;
            }

            // Clear out the old markers.
            markers.forEach((marker) => {
              marker.setMap(null);
            });

            markers = [];

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();

            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
              }

              const icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
              };

              // Create a marker for each place.
              markers.push(
                new google.maps.Marker({
                  google_import_map,
                  icon,
                  title: place.name,
                  position: place.geometry.location,
                })
              );

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });

            google_import_map.fitBounds(bounds);

            google_import_map.fitBounds(bounds);
          });

      })
      .catch(e => {
        // do something
      });

  }

  // searchAddressesByMap()
  // {
  //
  //   this.props.form.validateFields(['zipcode'], (err, values) =>
  //   {
  //     if(!err)
  //     {
  //       this.props.searchAddressesByMapAction(this.props.match.params.id, values).then(() => {
  //         this.setState({ addressesbyzip: this.props.addressesbyzip });
  //       });
  //     }
  //   });
  // }

  /**
   *
   */
  convertShapeToAddresses()
  {
      // IE polygon: https://overpass-api.de/api/interpreter?data=[out:json];(node["addr:street"](poly:"52.02605277798078 5.569960743588478 52.02599336456673 5.570797592801124 52.0257293039938 5.570765406292946 52.02535301498351 5.569992930096657 52.02491730796369 5.569853455227882 52.02476546967155 5.572889715832741 52.02244822133921 5.569542318982155 52.022501037452976 5.568490893048317 52.023220650789455 5.568329960507423 52.02409209364201 5.568501621884376 52.025201178170605 5.56865182558921");way["addr:street"](poly:"52.02605277798078 5.569960743588478 52.02599336456673 5.570797592801124 52.0257293039938 5.570765406292946 52.02535301498351 5.569992930096657 52.02491730796369 5.569853455227882 52.02476546967155 5.572889715832741 52.02244822133921 5.569542318982155 52.022501037452976 5.568490893048317 52.023220650789455 5.568329960507423 52.02409209364201 5.568501621884376 52.025201178170605 5.56865182558921");relation["addr:street"](poly:"52.02605277798078 5.569960743588478 52.02599336456673 5.570797592801124 52.0257293039938 5.570765406292946 52.02535301498351 5.569992930096657 52.02491730796369 5.569853455227882 52.02476546967155 5.572889715832741 52.02244822133921 5.569542318982155 52.022501037452976 5.568490893048317 52.023220650789455 5.568329960507423 52.02409209364201 5.568501621884376 52.025201178170605 5.56865182558921"););out;

      // IE circle: https://overpass-api.de/api/interpreter?data=[out:json];(node["addr:street"](around:100,52.03223130529528,5.574939250946045);way["addr:street"](around:100,52.03223130529528,5.574939250946045);relation["addr:street"](around:100,52.03223130529528,5.574939250946045););out;

      var search = false;
      var search_query = '';

      if(this.state.shape.type == 'circle')
      {
        var radius = this.state.shape.overlay.radius;
        var center = this.state.shape.overlay.getCenter();
        var lat = center.lat();
        var lng = center.lng();

        search_query = 'around:'+radius+','+lat+','+lng;

        search = true;
      }
      else if(this.state.shape.type == 'polygon')
      {
        var bounds = [];

        // old
        // if(this.state.shape.overlay.latLngs.cd){ bounds = this.state.shape.overlay.latLngs.cd[0].cd; }
        // else if(this.state.shape.overlay.latLngs.Wc){ bounds = this.state.shape.overlay.latLngs.Wc[0].Wc; }
        // else if(this.state.shape.overlay.latLngs.Vc){ bounds = this.state.shape.overlay.latLngs.Vc[0].Vc; }
        //
        // console.log(this.state.shape.overlay.latLngs);

        // dynamic
        var obj_keys = Object.keys(this.state.shape.overlay.latLngs);
        var first_key = obj_keys[0];

        bounds = this.state.shape.overlay.latLngs[first_key][0][first_key];

        bounds.map((poly, index) => {
            search_query += ((search_query ? ' ' : 'poly:"')+poly.lat()+' '+poly.lng());
        });

        search_query += '"';

        search = true;
      }

      if(search)
      {
          // overpass api
          var search_url = 'https://overpass-api.de/api/interpreter?data=[out:json];(node["addr:street"]('+search_query+');way["addr:street"]('+search_query+');relation["addr:street"]('+search_query+'););out;';

          // start searching
          this.setState({
              loading: true,
              search_url: search_url,
              current_step: 2
          });

          // call search url & parse data
          try
          {
              fetch(search_url).then(response => response.json()).then((data) => {

                  var found_addresses = [];

                  data.elements.map((element, index) => {

                      if(element.tags)
                      {
                          var tags = element.tags;

                          if(tags['addr:postcode'])
                          {
                              var address = {
                                  id: (index + 1),
                                  street: tags['addr:street'],
                                  house_number: tags['addr:housenumber'],
                                  address: tags['addr:street']+' ' + tags['addr:housenumber'],
                                  zipcode: tags['addr:postcode'],
                                  city: tags['addr:city'],
                                  lat: element.lat,
                                  lng: element.lon
                              };

                              found_addresses.push(address);
                          }
                      }
                  });

                  this.setState({
                      found_addresses: found_addresses,
                      loading: false
                  });
              });
          }
          catch(e)
          {
              showError('Er is iets mis gegaan bij het zoeken naar de adressen. Probeer het later nog eens.');

              this.setState({
                  loading: false,
                  current_step: 1
              });
          }
      }
  }

  /**
   *
   */
  importAddressesByMap()
  {
    if(this.props.customer.license_nr_of_addresses > 0 && (this.props.customer.nr_of_addresses + this.state.selectedRowKeys.length) >= this.props.customer.license_nr_of_addresses)
    {
        showExtraConfirm((confirm) => {
            if(confirm)
            {
              this.startImport(this.state.found_addresses, this.state.selectedRowKeys);
            }
        }, 'Licentie', 'De limiet van het aantal adressen voor je licentie wordt hiermee overschreden. Extra kosten zullen dan in rekening worden gebracht. Weet je zeker dat je door wilt gaan?')
    }
    else
    {
        this.startImport(this.state.found_addresses, this.state.selectedRowKeys);
    }
  }

  startImport(addresses, selection)
  {
      var selected_addresses = [];

      selection.map((id) => {
          selected_addresses.push(addresses[(id - 1)]);
      });

      this.props.importAddressesByMapAction(this.props.match.params.id, {addresses: JSON.stringify(selected_addresses)}).then(() => {

        this.props.history.push(`/projects/${this.props.match.params.id}/addresses`);

        showSuccess('Succesvol toegevoegd');
      });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  render() {
    const SubMenu = Menu.SubMenu;

    const { selectedRowKeys } = this.state;
    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };


    const columnsMapImport = [
      {
        title: 'Adres',
        dataIndex: 'address',
        render: (text, record) => (
          <span>
            {record.street + ' ' + record.house_number + (record.house_number_suffix ? ' ' + record.house_number_suffix : '')}
          </span>
        ),
        sorter: (a, b) => { return a.address.localeCompare(b.address)},
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend'
      },
      {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => { return a.zipcode.localeCompare(b.zipcode)},
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => { return a.city.localeCompare(b.city)},
        sortDirections: ['descend', 'ascend']
      }
    ];

    return (
      <div>
        <Form layout={'vertical'}>

            <Row type="flex" className="mbm">
              <Col span={24}>
                {this.state.current_step == 1 ? <Button disabled={this.state.shape ? false : true} type="new" onClick={() => this.convertShapeToAddresses()}>Volgende</Button> : null}
                {this.state.current_step == 2 ? <Button disabled={this.state.selectedRowKeys.length > 0 ? false : true} type="new" icon={<PlusOutlined />} onClick={() => this.importAddressesByMap()}>Toevoegen</Button> : null}
                <Button style={{marginRight: 8}} onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/addresses`)}>Sluiten</Button>
              </Col>
            </Row>

            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <Steps type="navigation" size="small" current={(this.state.current_step - 1)} style={{display: 'block'}}>
                      <Steps.Step onClick={() => { this.setState({ current_step: 1 }); }} style={{padding: "10px 30px", marginRight: 10 }} title="Selecteer gebied" />
                      <Steps.Step onClick={() => { this.setState({ current_step: 2 }); }} style={{padding: 10, marginLeft: 25 }} disabled={this.state.shape ? false : true} title="Selecteer adressen" />
                    </Steps>
                </Card>
              </Col>
            </Row>

          <Row gutter={24} style={{ display: (this.state.current_step == 1 ? 'block' : 'none') }}>
            <Col span={24}>
              { /* map */ }
              <input
                id="pac-input"
                className="controls"
                type="text"
                placeholder="Zoeken"
              />
              <div
                id="google-map"
                style={{width: '100%', height: (window.innerHeight - 300)}}
                >
              </div>
            </Col>
          </Row>

        <Row gutter={24} style={{ display: (this.state.current_step == 2 ? 'block' : 'none') }}>
          <Col span={24}>

              <CustomTable
                rowSelection={rowSelection}
                columns={columnsMapImport}
                dataSource={this.state.found_addresses}
                loading={this.state.loading}
                // pagination={false}
                rowKey={'id'}
                />

          </Col>
        </Row>

        </Form>
      </div>
    );
  }
}

const ProjectsAddressesMapImportForm = Form.create({ name: 'customer_personalize' })(ProjectsAddressesMapImport);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.address.isFetching,
    isError: state.address.isError,
    address: state.address.addresses,
    groups: state.group.groups,
    customer: state.customer.customer
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
      importAddressesByMapAction: bindActionCreators(importAddressesByMapAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesMapImportForm);
