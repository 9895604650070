import api from '../utils/api'

export const getPartnersAction = () => (dispatch, getState) => {

    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/library/partners/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'GLOBAL_PARTNERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchPartnersAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/partners/'+customer_id+'/search', {'search': params}, true).then((response) => {
        dispatch({
            type: 'GLOBAL_PARTNERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const getPartnerAction = (partnerid) => dispatch => {

    dispatch({
        type: 'PARTNER_ACTION_FETCH'
    })

    return api('get','/library/partner/'+partnerid, null, true).then((response) => {
        dispatch({
            type: 'PARTNER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetPartnerAction = (partnerid) => dispatch => {

    dispatch({
        type: 'RESET_PARTNER_ACTION_FETCH'
    })
}

export const addPartnerAction = (customerid, params) => dispatch => {

    dispatch({
        type: 'PARTNER_ACTION_FETCH'
    })

    return api('post','/library/partners/'+customerid, params, true).then((response) => {
        dispatch({
            type: 'ADD_PARTNER_ACTION_SUCCESS',
            payload: response.data.partner
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNER_ACTION_ERROR',
        })
        return false;
    });
}

export const patchPartnerAction = (partnerid, params, file) => dispatch => {
    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('post','/library/partner/'+partnerid, params, true, file).then((response) => {
        dispatch({
            type: 'PARTNER_ACTION_SUCCESS',
            payload: response.data.partner
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const clonePartnerAction = (project_id, partnerid) => dispatch => {
    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('post','/partner/'+partnerid+'/clone', { 'project_id' : project_id }, true, null).then((response) => {
        dispatch({
            type: 'CLONE_PARTNER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const removePartnersAction = (params) => (dispatch, getState) => {
    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/partners/'+customer_id+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'REMOVE_PARTNER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

// export const updatePartnersOrderAction = (customerid, params) => dispatch => {
//
//     dispatch({
//         type: 'PARTNERS_ACTION_FETCH'
//     })
//
//     return api('post','/partners/order/'+customerid, params, true).then((response) => {
//         dispatch({
//             type: 'SORT_PARTNERS_ACTION_SUCCESS',
//             payload: response.data
//         });
//     }).catch((e) => {
//         dispatch({
//             type: 'PARTNERS_ACTION_ERROR',
//         })
//         return false;
//     });
// }
