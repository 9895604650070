import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Alert,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Table,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess } from '../../../../../utils/Notifications';
import PatchAppointmentForm from '../Form/PatchAppointment';
import Cluster from '../Component/Cluster';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import _ from 'underscore';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';

class AppointmentClusters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clusters: [],
      excludedIds:[],
    };
  }

  saveClusters = () => {
    this.props.clustersAppointmentAction(this.props.match.params.appointmentid, {'clusters' : this.state.clusters.filter((element) => element != null)}).then(() => {
      showSuccess();
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/data/`);
    });
  }

  componentDidMount() {
    this.props.resetAppointmentAction();

    if(this.props.match.params.appointmentid) {
      this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {
        this.setState({clusters: this.props.clusters, excludedIds: _.flatten(this.props.clusters)});
      });
    }

    this.props.getAddressesAction({'projectId':this.props.match.params.id}).then(() => {
      this.props.getGroupsAction({'projectId': this.props.match.params.id});
    });
  }

  updateExcludedIds = () => {
    var selectedIds           = _.flatten(this.state.clusters);
    var differenceExcludedIds = _.difference(this.state.excludedIds, selectedIds);
    var updateExcludedIds     = _.without(this.state.excludedIds, ...differenceExcludedIds)
    this.setState({excludedIds: updateExcludedIds});
  }

  deleteCluster = (myindex) => {
    var updatedClusters = this.state.clusters;
    delete updatedClusters[myindex];

    this.setState({
      clusters: updatedClusters,
    }, () => {
      this.updateExcludedIds();
    })
  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => this.saveClusters()}>Opslaan en volgende</Button>
              <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
            <AppointmentSteps current={1} {...this.props}/>
          </Card>
        </Row>


        <Row gutter={24}>
          <Col span={24}>
            <Card>
              <Row className="mbm">
                <Col span={24}>
                  <div>
                    <Button icon={<PlusOutlined />} disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => this.setState({clusters: [...this.state.clusters, []]})}>Toevoegen</Button>
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>

                {!this.state.isFetching && this.state.clusters.length == 0 ? (
                  <Col span={24}>
                    <Alert showIcon description={"Voeg minimaal één cluster toe"} message={'Let op'}></Alert>
                  </Col>
                ) : null}

                {this.state.clusters.map((element, index) => {
                  if(!Array.isArray(element))
                    return false;

                  return (
                    <Col key={index} span={24}>

                      <Cluster key={index} index={index} exclude={this.state.excludedIds} selected={this.state.clusters[index]} addresses={this.props.addresses} groups={this.props.groups} onSelect={(ids) => {
                          var updatedClusters = this.state.clusters.map((element, clusterindex) => {

                            if(index == clusterindex) {
                              element = ids;
                              return element
                            }
                            return element;
                          })

                          this.setState({
                            excludedIds: [...ids, ...this.state.excludedIds],
                            clusters: updatedClusters,
                          }, () => {
                            this.updateExcludedIds();
                          })

                        }}
                        onDeleteCluster={() => this.deleteCluster(index)}
                        />
                    </Col>
                  );
                })}

              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    appointment: state.appointment.appointment,
    clusters: state.appointment.clusters,
    addresses: state.address.addresses,
    groups: state.group.groups
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentClusters);
