import React from 'react'
import { Modal } from 'antd'

class SavePresetModal extends React.Component {
  
  state = {
    showModal: false || this.props.visible
  }

  render () {
    return(
        <Modal
          title="Basic Modal"
          visible={this.state.showModal}
          onOk={() => {}}
          onCancel={() => this.setState({showModal: false})}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
    );
  }
}

export default SavePresetModal;
