import React, { Component } from 'react';
import './CustomTable.css';
import { Table } from 'antd';

class CustomTable extends Component {
  render() {

    var pageSizeOptions = ['25','50', '100', '250', '500'];

    if(this.props.dataSource && this.props.dataSource.length > 500){
        pageSizeOptions.push(''+this.props.dataSource.length);
    }

    return (
      <Table
        className="ant-table-custom"
        style={{backgroundColor: 'white', borderRadius: '5px', border: '1px solid #e8e8e8' }}
        pagination={{
            defaultPageSize: localStorage.getItem('pagesize') ? (localStorage.getItem('pagesize') > 500 && this.props.dataSource && this.props.dataSource.length > 500 ? this.props.dataSource.length : 500) : 25,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions // ['25','50', '100', '250', '500']
        }}
        size='middle'
        { ...this.props }
        onChange={(pagination, filters, sorter, extra) => {
          localStorage.setItem('pagesize', pagination.pageSize);
          if(this.props.onChange) {
            this.props.onChange(pagination, filters, sorter, extra);
          }

          }
        }
      />
    );
  }
}

export default CustomTable;
