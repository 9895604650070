import React, { Component } from 'react';
// import './ProjectsAddressesContactpersons.css';
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getOccupantsAction, storeOccupantAction, removeOccupantAction } from '../../../../actions/occupantsActions';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { Link } from "react-router-dom";
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Modal,
  Card,
  Avatar,
  Typography,
  Button,
  Drawer,
  Divider,
  Input,
  Radio,
  Switch,
  Dropdown,
  Menu,
  message,
} from 'antd';

const { Title, Text } = Typography;

const { Meta } = Card;

const confirm = Modal.confirm;

class ProjectsAddressesContactpersons extends Component {
  state = {
    disabled: true,
    edit: false,
    visible: false,
    selectedRowKeys: [],
    different_address: false,
  };

  componentDidMount() {
    this.props.getOccupantsAction(this.props.match.params.addressid);
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.removeOccupantAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({edit:!this.state.edit});
    if (this.state.edit) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.storeOccupantAction(this.props.match.params.addressid, values);
        }
      });
    }
  };

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  render() {

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const columns = [{
      title: 'Naam',
      dataIndex: 'full_name',
      sorter: (a, b) => { return a.full_name.localeCompare(b.full_name)},
      render:(text, record) => {
        console.log(text);
        return (
        <Link to={`contacts/${record.id}/detail`}>
          {text == null || text == '' ? "-" : text}
        </Link>
      )}
    },{
      title: 'Omschrijving',
      dataIndex: 'description',
    },{
      title: 'Afwijkend adres',
      dataIndex: 'different_address',
      render:(text, record) => (
        <span>
          {text ? 'Ja' : 'Nee'}
        </span>
      )
    }];

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="2" {...this.props}/>

          <div style={{padding: 24, width: '100%'}}>
            <Row className="mbm">
              <Col span={24}>
                <Link to="contacts/add">
                  <Button disabled={!this.props.HaveAbility('address', 'write')} icon={<PlusOutlined />}  type="new">
                    Toevoegen
                  </Button>
                </Link>
                <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('address', 'write') ? false : true } overlay={(
                    <Menu>
                      <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('address' ,'delete')} style={{ color: this.props.HaveAbility('address', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
          </Row>

          <Divider></Divider>

          <Title level={4}>{this.props.address.full_address}</Title>

          <Row>
            <Col span={24}>
              <CustomTable
                size="middle"
                rowKey="id"
                rowSelection={rowSelection}
                onChange={() => {}}
                columns={columns}
                loading={this.props.isFetching}
                dataSource={this.props.occupants}
                />
            </Col>
          </Row>
        </div>
    </div>
    );
}
}

const ProjectsAddressesContactpersonsForm = Form.create({ name: 'projects_addresses_contactpersons' })(ProjectsAddressesContactpersons);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.occupant.isFetching,
    isError: state.occupant.isError,
    occupants: state.occupant.occupants,
    address: state.address.address,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOccupantsAction: bindActionCreators(getOccupantsAction, dispatch),
    storeOccupantAction: bindActionCreators(storeOccupantAction, dispatch),
    removeOccupantAction: bindActionCreators(removeOccupantAction, dispatch),
    HaveAbility: bindActionCreators(HaveAbility, dispatch),

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesContactpersonsForm);
