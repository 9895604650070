import React, { Component } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getComplaintAction, patchComplaintsAction, addComplaintAction, resetComplaintsAction } from '../../../../../actions/complaintsActions';
import { getLibrariesAction } from '../../../../../actions/librariesActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import PatchComplaintsForm from '../Form/PatchComplaints';
import ComplaintsSteps from '../Steps/ComplaintsSteps';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import { showSuccess } from '../../../../../utils/Notifications';

class ComplaintsAddresses extends Component {

  state = {
    selectedAddressIds: []
  }

  constructor(props) {
    super(props);
    // this.selectaddresses = React.createRef();
  }

  componentDidMount() {

    this.props.resetComplaintsAction();

    if(this.props.match.params.complaintid) {
      this.props.getComplaintAction(this.props.match.params.complaintid).then(() => {
      });
    }
  }

  patchComplaintsAddresses() {

    // this.selectaddresses.current.share();
    this.myref.share().then(() => {

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/complaints`);

        showSuccess();
    });
  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
                <Button disabled={!this.props.HaveAbility('complaints', 'write')} type="new" onClick={() => this.patchComplaintsAddresses()}>Opslaan</Button>
              <Link to={`/projects/${this.props.match.params.id}/modules/complaints`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <ComplaintsSteps current={1} {...this.props} />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            { this.props.complaint.is_public === 0 ? (
              this.props.complaint.addresses && (
                <SelectAddresses {...this.props} /*forwardedRef={this.selectaddresses}*/ childRef={ref => this.myref = ref} initialAddresses={this.props.complaint.addresses} objectId={this.props.match.params.complaintid} objectType="complaint" onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds})}/>
              )
            ) : (
              !this.props.isFetching ? (
                <Result
                  icon={<CheckCircleFilled style={{ color: '#52c41a' }} />}
                  title="De informatie staat ingesteld op openbaar, dus iedereen kan het zien."
                />
              ) : null
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.complaints.isFetching,
    isError : state.complaints.isError,
    complaint: state.complaints.complaint,
    library: state.library.libraries,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getComplaintAction : bindActionCreators(getComplaintAction, dispatch),
    patchComplaintsAction : bindActionCreators(patchComplaintsAction, dispatch),
    getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
    addComplaintAction : bindActionCreators(addComplaintAction, dispatch),
    resetComplaintsAction : bindActionCreators(resetComplaintsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsAddresses);
