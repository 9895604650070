import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Button,
    Drawer,
    Divider,
    Tag,
    Modal,
    Upload,
    Input,
    Typography,
    Tooltip,
    Select,
} from 'antd';
import NumberFormat from 'react-number-format';
const ButtonGroup = Button.Group;

const Option = Select.Option;

class PatchPartner extends Component {

    render()
    {
        const { getFieldDecorator } = this.props.form;

        //const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        );

        return (
            <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
                <Row gutter={24} >
                    <Col span={12}>
                        <Form.Item required hasFeedback label="Bedrijfsnaam">
                              {getFieldDecorator('name', {
                                  initialValue: this.props.partner.name,
                                  rules: [{ required: true, message: 'Vul een bedrijfsnaam in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="KvK">
                              {getFieldDecorator('kvk', {
                                  initialValue: this.props.partner.kvk,
                                  rules: [{ required: false, message: 'Vul een Kvk-nummer in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Adres">
                              {getFieldDecorator('address', {
                                  initialValue: this.props.partner.address,
                                  rules: [{ required: false, message: 'Vul een adres in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Postcode">
                              {getFieldDecorator('zipcode', {
                                  initialValue: this.props.partner.zipcode,
                                  rules: [{ required: false, message: 'Vul een geldige postcode in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Plaats">
                              {getFieldDecorator('city', {
                                  initialValue: this.props.partner.city,
                                  rules: [{ required: false, message: 'Vul een plaatsnaam in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Telefoon">
                              {getFieldDecorator('phone_number', {
                                  initialValue: this.props.partner.phone_number,
                                  rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                              })(
                                  <NumberFormat allowEmptyFormatting={true} format="##########" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item  hasFeedback label="E-mail">
                              {getFieldDecorator('email', {
                                  initialValue: this.props.partner.email,
                                  rules: [{ required: false, message: 'Vul een e-mailadres in' },{type: 'email', message: 'Vul een geldig e-mailadres in'}],
                              })(
                                  <Input type={'email'} />
                              )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback label="Website">
                              {getFieldDecorator('website', {
                                  initialValue: this.props.partner.website,
                                  rules: [{ required: false, message: 'Vul een geldige URL in' }],
                              })(
                                  <Input />
                              )}
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>
                        <Form.Item label="Werkt aan werkzaamheden">
                            {getFieldDecorator('avatar', {
                                rules: [{ required: false, message: 'Werkt aan werkzaamheden' }],
                            })(
                                <Select
                                    placeholder="Werkt aan werkzaamheden"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="1">Afdekken vloeren</Option>
                                    <Option value="2">Leidingwerk</Option>
                                    <Option value="3">Stukadoorswerk</Option>
                                    <Option value="4">Tegelwerk</Option>
                                    <Option value="5">Afmonteren sanitair</Option>
                                    <Option value="6">Sloopwerk</Option>
                                    <Option value="7">Schoon opleveren</Option>
                                    <Option value="8">Kitwerk en schilderwerk</Option>
                                    <Option value="9">Bouwplaatsvoorzieningen in uw wijk</Option>
                                    <Option value="10">Afleveren materialen</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Logo">
                            {getFieldDecorator('logo', {
                                rules: [{ required: false, message: 'Vul een afbeelding in.' }],
                            })(
                                <div className="clearfix">
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                    >
                                    </Upload>
                                    <Modal footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} />
                                    </Modal>
                                </div>
                            )}
                        </Form.Item>
                    </Col>*/}
                </Row>
            </Form>
        );
    }
}

export default Form.create({ name: 'patch_partner_form' })(PatchPartner);
