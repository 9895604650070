import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Modal,
  Card,
  Avatar,
  Typography,
  Button,
  Drawer,
  Divider,
  Input,
  Radio,
  Switch,
  message,
} from 'antd';
import { Link } from "react-router-dom";
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import PatchContactsForm from '../Form/PatchContactsForm';
import { showSuccess } from '../../../../utils/Notifications'
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { getOccupantAction, storeOccupantAction, patchOccupantAction, resetOccupantAction } from '../../../../actions/occupantsActions';
import { getAddressAction } from '../../../../actions/addressesActions';
const { Title, Text } = Typography;

class ProjectsAddressesContactpersonsDetail extends Component {
  state = {

  };

  componentDidMount() {

    if(this.props.match.params.contactsid) {

      this.props.getOccupantAction(this.props.match.params.contactsid).then(() => {

          this.props.getAddressAction(this.props.occupant.address_id);
      });
    }
    else
    {
      this.props.resetOccupantAction();
    }
  }

  handleSubmit = (e, values) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(this.props.match.params.contactsid) {
          this.props.patchOccupantAction(this.props.match.params.contactsid, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/contacts`);
          });
        } else {
          this.props.storeOccupantAction(this.props.match.params.addressid, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/contacts`);
          });
        }
        showSuccess();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="2" {...this.props}/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={24}>

              <div>
                <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" onClick={this.handleSubmit}>Opslaan</Button>
                <Link to={`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/contacts`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>

            </Col>
          </Row>

          <Divider></Divider>

          <Title level={4}>{ this.props.address.full_address + (this.props.occupant.full_name ? ' / '+this.props.occupant.full_name : '')}</Title>

          <Row gutter={24}>
              <Col span={24}>
                <PatchContactsForm ref={formRef => { this.patchContactsForm = formRef }}  {...this.props} />
              </Col>
            </Row>
        </div>
      </div>
    );
  }
}

const ProjectsAddressesContactpersonsDetailForm = Form.create({ name: 'projects_addresses_contactpersons' })(ProjectsAddressesContactpersonsDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.occupant.isFetching,
    isError : state.occupant.isError,
    occupant: state.occupant.occupant,
    address: state.address.address,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOccupantAction : bindActionCreators(getOccupantAction, dispatch),
    getAddressAction : bindActionCreators(getAddressAction, dispatch),
    storeOccupantAction : bindActionCreators(storeOccupantAction, dispatch),
    patchOccupantAction : bindActionCreators(patchOccupantAction, dispatch),
    resetOccupantAction : bindActionCreators(resetOccupantAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesContactpersonsDetailForm);
