import React from 'react'
import PropTypes from 'prop-types'
import { DeleteOutlined, PlusSquareOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  DatePicker,
  Collapse,
  Row,
  Col,
} from 'antd';

const cp_fields = {
    name: 'Naam',
    initials: 'Initialen',
    gender: 'Geslacht',
    description: 'Omschrijving',
    address: 'Adres',
    zipcode: 'Postcode',
    city: 'Woonplaats',
    telephone: 'Telefoon',
    mobile: 'Mobiel',
    email_address: 'E-mailadres',
    prefer_email: 'Voorkeur om e-mail te ontvangen',
    different_address: 'Afwijkend adres'
};

class ContactInputForm extends React.Component {

    getCPFields(getFieldDecorator)
    {
        var form_cp_fields = [];

        //
        Object.entries(cp_fields).forEach(entry => {

            const [cp_field, label] = entry;

            if(cp_field == 'name')
            {
                form_cp_fields.push(
                <Col span={12}>
                    <Form.Item style={{marginBottom: 0}}>
                        {getFieldDecorator(cp_field, {
                          initialValue: true,
                          valuePropName: 'checked',
                          readonly: true
                        })(
                        <Checkbox disabled={true}>{label}</Checkbox>
                        )}
                    </Form.Item>
                </Col>);
            }
            else
            {
                form_cp_fields.push(
                <Col span={12}>
                    <Form.Item style={{marginBottom: 0}}>
                        {getFieldDecorator(cp_field, {
                          initialValue: this.props.formData && (this.props.formData[cp_field] || this.props.formData[cp_field] === undefined),
                          valuePropName: 'checked',
                          onChange: (event) => { this.props.form.setFieldsValue({ [cp_field]: event.target.checked }); /*this.props.saveFieldValues(true);*/ }
                        })(
                        <Checkbox>{label}</Checkbox>
                        )}
                    </Form.Item>
                </Col>);
            }
        });

        //
        return form_cp_fields;
    }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>

        <Form.Item required hasFeedback label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
          })(
            <Input />
          )}
        </Form.Item>

        <br />
        <span>Toon de volgende velden:</span><br />

        <Row style={{ marginBottom: 24 }}>
            { this.getCPFields(getFieldDecorator) }
        </Row>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld is verplicht</Checkbox>
          )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('listField', {
            initialValue: this.props.formData && this.props.formData.listField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op de meterkastlijst</Checkbox>
          )}
        </Form.Item>

      </Form>
    );
  }
}

const WrappedContactInputForm = Form.create({ name: 'text_input_form' })(ContactInputForm);

class ContactInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {},
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  renderExtraButtons = () => {
    return (
      <Button.Group>
        <Popconfirm
          icon={(<QuestionCircleOutlined />)}
          placement="top"
          onCancel={(event) => event.stopPropagation()}
          size="small"
          okType="danger"
          title={"Element verwijderen"}
          onConfirm={() => this.removeElement()}
          okText="Ja"
          cancelText="Nee"
          disabled={this.props.disabled}
        >
          <DeleteOutlined
            onClick={event => {
              // event.stopPropagation();
            }} />
        </Popconfirm>
        <PlusSquareOutlined
          style={{marginLeft: 5}}
          onClick={(event) => {
            event.stopPropagation();
            this.duplicateElement(this.props.id);
          }} />
      </Button.Group>
    );
  };

  saveFieldValues = () => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({formData: values, openKeys: []});
          this.props.onSaveData(this.props.id, values);
        }
      })
    }
  }

  // saveFieldValues = (silent = false) => {
  //   if(this.state.modus == 'edit') {
  //     this.form.props.form.validateFields((error, values) => {
  //       if(!error) {
  //         // values.hide_for_occupant = this.state.hide_for_occupant;
  //         // values.requiredField = this.state.requiredField;
  //         // values.listField = this.state.listField;
  //
  //         if(silent)
  //         {
  //             this.setState({formData: values});
  //         }
  //         else
  //         {
  //             this.setState({formData: values, openKeys: []});
  //         }
  //
  //         this.props.onSaveData(this.props.id, values, silent);
  //       }
  //     })
  //   }
  // }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (
        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Contactpersoon</b> - {this.state.formData && this.state.formData.label || 'label'}</div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              <WrappedContactInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} /*saveFieldValues={this.saveFieldValues} disabled={this.props.disabled}*/ />
              <Button onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Contactpersoon
        </div>
      );
    }
  }
}

export default ContactInput;
