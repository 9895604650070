export default (state = {isError: false, isFetching: false, groups: [], group:{}}, action) => {
    switch (action.type) {
    case 'GROUPS_ACTION_FETCH':
    return {
        ...state,
        groups: [],
        isFetching: true,
        isError: false,
    }
    case 'GROUPS_ACTION_SUCCESS':
    return {
        ...state,
        groups: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'GROUP_ACTION_SUCCESS':
    return {
        ...state,
        group: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_GROUP_ACTION':
    return {
        ...state,
        group: {},
        isFetching: false,
        isError: false,
    }
    case 'ADD_GROUP_ACTION_SUCCESS':
    return {
        ...state,
        groups: [action.payload, ...state.groups],
        group: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SYNC_ADDRESSES_GROUP_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'DELETE_GROUPS_ACTION_SUCCESS':
    return {
        ...state,
        groups: state.groups.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'GROUPS_ACTION_ERROR':
    return {
        isError: true,
        isFetching: false
    }
    default:
        return state
    }
}
