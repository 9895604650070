export default (state = {

    isError: false,
    isFetching: false,
    medialibrary: [],
    mediafile: {},
    categories: [],
    category: {}

}, action) => {
    switch (action.type) {
    case 'RESET_MEDIA_ACTION_FETCH':
    return {
        ...state,
        medialibrary: [],
        mediafile: {},
        categories: [],
        category: {},
        isFetching: false,
        isError: false,
    }
    case 'RESET_MEDIAFILE_ACTION_FETCH':
    return {
        ...state,
        mediafile: {},
        isFetching: false,
        isError: false,
    }
    case 'MEDIAFILE_ACTION_FETCH':
    return {
        ...state,
        mediafile: {},
        isFetching: true,
        isError: false,
    }
    case 'MEDIAFILE_ACTION_FETCH':
    return {
        ...state,
        category: {},
        isFetching: true,
        isError: false,
    }
    case 'MEDIALIBRARY_ACTION_FETCH':
    return {
        ...state,
        medialibrary: [],
        isFetching: true,
        isError: false,
    }
    case 'MEDIA_CATEGORIES_ACTION_FETCH':
    return {
        ...state,
        categories: [],
        isFetching: true,
        isError: false,
    }
    case 'MEDIALIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        medialibrary: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'MEDIA_CATEGORIES_ACTION_SUCCESS':
    return {
        ...state,
        categories: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'MEDIAFILE_ACTION_SUCCESS':
    return {
        ...state,
        mediafile: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'MEDIA_CATEGORY_ACTION_SUCCESS':
    return {
        ...state,
        category: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PATCH_MEDIAFILE_ACTION_SUCCESS':
    return {
        ...state,
        mediafile: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_MEDIALIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        medialibrary: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'STORE_MEDIAFILE_ACTION_SUCCESS':
    return {
        ...state,
        medialibrary: [action.payload, ...state.medialibrary],
        isFetching: false,
        isError: false,
    }
    case 'DELETE_MEDIALIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        medialibrary: state.medialibrary.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'MEDIALIBRARY_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'MEDIAFILE_ACTION_RESET':
    return {
        ...state,
        mediafile:{},
        isFetching: false,
        isError: true,
    }
    case 'MEDIA_CATEGORY_ACTION_RESET':
    return {
        ...state,
        category:{},
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
