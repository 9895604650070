import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseFormResendSteps from './SupportBaseFormResendSteps'
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess } from '../../../../../utils/Notifications';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { getSupportBaseFormByIdAction, updateSupportBaseCorrespondenceAction } from '../../../../../actions/supportBaseActions';

class SupportBaseResendCompose extends React.Component {

  state = {
      //selectedLetterIds: [1],
      //selectedEmailIds: [1],
      on_stationery: true, // default
      send_by_customer: false, //default
      scheduled_for: null, //default
  }

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
       this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false).then(() => {
           this.setState({
               'on_stationery' : this.props.support_base_form.correspondences[0].on_stationery ? true : false,
               'send_by_customer' : this.props.support_base_form.correspondences[0].send_by_customer ? true : false,
               'scheduled_for' : this.props.support_base_form.correspondences[0].scheduled_for ? moment(this.props.support_base_form.correspondences[0].scheduled_for, 'YYYY-MM-DD HH:mm:ss') : null,
           });
       });
  }

  patchInformationAddresses() {}

  saveAndContinue(redirect = true)
  {
      this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'dates', {
          'on_stationery' : this.state.on_stationery,
          'send_by_customer' : this.state.send_by_customer,
          'scheduled_for' : this.state.scheduled_for ? this.state.scheduled_for.format('YYYY-MM-DD HH:mm:00') : null,
      }).then(() => {
          showSuccess('Succesvol opgeslagen');

          if(redirect)
          {
              this.props.history.push(`/projects/${this.props.match.params.id}/support-base/resend/send/${this.props.match.params.form_id}`);
          }
      });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>

        <div style={{padding: 24, width: '100%'}}>

            {/* buttons */}
            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/support-base/status`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <SupportBaseFormResendSteps current={1} {...this.props}/>
                </Card>
              </Col>
            </Row>
            <Row>
                <Col span={20}>
                    <Form layout='inline'>
                      <Form.Item label="Op eigen briefpapier">
                        <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.on_stationery ? true : false}
                        onChange={(value) => { this.setState({on_stationery: value}, function(){ this.saveAndContinue(false); }); }}
                        />
                      </Form.Item>

                      <Form.Item label="Zelf versturen">
                        <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state.send_by_customer ? true : false}
                        onChange={(value) => { this.setState({send_by_customer: value}, function(){ this.saveAndContinue(false); }); }}
                        />
                      </Form.Item>

                      <Form.Item label="Verzenden op">
                        <DatePicker
                          showTime={{ format: 'HH:mm' }}
                          placeholder="Direct"
                          format={'DD-MM-YYYY - HH:mm'}
                          defaultValue={this.state.scheduled_for}
                          value={this.state.scheduled_for}
                          onChange={(value) => { this.setState({scheduled_for: value}, function(){ this.saveAndContinue(false); }); }}
                          />
                          <Tooltip className="mhs" title='Stel hiermee de standaard verzenddatum in. U kunt dit in het overzicht per adres aanpassen'>
                            <InfoCircleTwoTone />
                          </Tooltip>
                      </Form.Item>
                    </Form>
                    <br />
                </Col>
                <Col align={'right'} span={4}>
                    {/*<Button type="new" onClick={() => { this.props.history.push(`/projects/${this.props.match.params.id}/support-base/resend/${this.props.match.params.form_id}/correspondence/` + (this.props.support_base_form.nr_of_letters ? 'letter' : 'email')); }}>Samenstellen</Button>*/}
                </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Collapse bordered={true} defaultActiveKey={[]} style={{marginTop: 16}}>
                  <Collapse.Panel header={`${this.props.support_base_form.addresses ? this.props.support_base_form.addresses.length : ''} formulier${this.props.support_base_form.addresses && this.props.support_base_form.addresses.length > 1 ? 'en' : ''}`} key="1">
                    <CustomTable columns={[
                      {
                        title: 'Adres',
                        dataIndex: 'full_address',
                        key: 'full_address',
                        render: (text, record) =>
                        record.full_address && record.full_address ? (
                          record.full_address
                        ) : (
                          <span>
                            {record.full_name} <Tag>{record.only_occupants}{record.description ? record.description : 'Bewoner'}</Tag>
                        </span>
                      )
                    },
                    {
                      title: 'Verzenden op',
                      key: 'sent_date',
                      render: (record, index) => (
                        <span>
                          Zoals algemene brief
                          {/*<DatePicker format={'DD/MM/YYYY - HH:mm'} showTime={{ format: 'HH:mm' }} placeholder="Zoals algemene brief" defaultValue={null}
                            onChange={(value) => this.saveMethodDateTime(value, 'mail', record.address_id ? null : record.id, record.address_id ? record.id : null)
                        } onOk={(value)   => this.saveMethodDateTime(value, 'mail', record.address_id ? null : record.id, record.address_id ? record.id : null)} />*/}
                          </span>
                        )
                      }
                      ]}
                      rowKey="id"
                      childrenColumnName="occupants"
                      dataSource={this.props.support_base_form.addresses}
                      //dataSource={this.examples}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>

        </div>

    </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseResendComposeForm = Form.create({ name: 'intake_form' })(SupportBaseResendCompose);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form : state.support_base.support_base_form,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        updateSupportBaseCorrespondenceAction : bindActionCreators(updateSupportBaseCorrespondenceAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseResendComposeForm);
