import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
  Select,
  Alert,
} from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import { API_URL, JOB_URL } from '../../../../constants/settings';

class ComposeMailForm extends Component {

  state = {
    filelist: [],
    deleteImage: false,
    type: 'mail',
    content: '',
    iframeKey: 0,
    previewModalVisible: false,
  }

  componentDidMount() {

  }

  refreshIframe()
  {
      this.setState({ iframeKey: (this.state.iframeKey + 1) });
  }

  copyText = () => {
    //console.log(this.props.correspondence.methods.filter((element) => element.type === "mail")[0].message);
    Modal.confirm({
        title: 'Weet je het zeker?',
        content: 'De huidige content zal worden vervangen door de inhoud van de brief',
      onOk:() => {
        // this.props.form.setFieldsValue({[this.props.match.params.type == "email_mail" ? 'letter_attachment' : 'message']: this.props.correspondence.methods.filter((element) => element.type === this.props.match.params.type /*"mail"*/)[0][this.props.match.params.type == "email_mail" && !this.props.match.params.occupantid ? 'letter_attachment' : 'message']/*.message*/});

        console.log('type', this.props.match.params.type, 'address', this.props.match.params.addressid, 'occupant', this.props.match.params.occupantid);

        var new_value = '';

        if(this.props.match.params.type == 'email_mail')
        {
            if(this.props.match.params.occupantid)
            {
                new_value = this.props.correspondence.methods.filter((element) => element.type === 'email_mail')[0]['letter_attachment'];
            }
            else
            {
                new_value = this.props.correspondence.methods.filter((element) => element.type === 'mail')[0]['message'];
            }

            //
            this.props.form.setFieldsValue({ letter_attachment: new_value });
        }
        else if(this.props.match.params.type == 'mail')
        {
            if(this.props.match.params.addressid)
            {
                new_value = this.props.correspondence.methods.filter((element) => element.type === 'mail')[0]['message'];
            }
            // else
            // {
            //     new_value = this.props.correspondence.methods.filter((element) => element.type === 'mail')[0]['message'];
            // }

            //
            this.props.form.setFieldsValue({ message: new_value });
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
        <Row gutter={24}>
          <Col span={24}>
            {this.props.match.params.addressid && (
              <div style={{marginBottom: 15}}>
                <b>{this.props.correspondence && this.props.correspondence.addresses && this.props.correspondence.addresses.filter && this.props.correspondence.addresses.filter((element) => element.id == this.props.match.params.addressid)[0].full_address}</b>
              </div>
            )}

            {(this.props.match.params.type == "email_mail" || this.props.match.params.addressid || this.props.match.params.occupantid) && this.props.correspondence.selection && this.props.correspondence.selection.mail.length > 0 && (
                <Button loading={this.props.isFetching} style={{marginBottom: 15}} onClick={this.copyText}>Tekst overnemen van algemene brief</Button>
            )}

            { (this.props.match.params.type && (this.props.match.params.type == 'email_mail' || this.props.match.params.type == 'email')) ? (
              <span>
                <Form.Item required label="Aanhef">
                  { getFieldDecorator('salutation_type', {
                    initialValue: this.props.selectedMethod && this.props.selectedMethod.salutation_type,
                    rules: [{ required: true, message: 'Maak een keuze' }],

                  })(
                    <Select>
                      <Select.Option key={'personal'}>Gepersonaliseerde aanhef</Select.Option>
                      <Select.Option key={'anonymous'}>Aangepaste aanhef</Select.Option>
                    </Select>
                  ) }
                </Form.Item>

                {this.props.form.getFieldValue('salutation_type') ? (
                  <Form.Item required label={this.props.form.getFieldValue('salutation_type') == 'personal' ? "Aanhef indien contactpersoon onbekend" : "Aangepaste aanhef"}>
                    { getFieldDecorator('salutation', {
                      initialValue: this.props.selectedMethod && this.props.selectedMethod.salutation || "Aan de bewoner(s) van",
                      rules: [{ required: true, message: 'Vul een aanhef in' }],
                    })(
                      <Input></Input>
                    ) }
                  </Form.Item>
                ) : null}

                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={0} >
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                      <Button type="new" onClick={() => {

                          if(this.props.onSave('modal', this))
                          {
                              //setTimeout( () => {
                                // this.setState({iframeKey: (this.state.iframeKey + 1), previewModalVisible: true});
                              //}, 500);
                          }

                        }} style={{margin: 0}}>Voorbeeld brief</Button>
                      </div>
                  </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Form.Item /*required*/ label="Brief voor in de e-mail">
                          { getFieldDecorator(this.props.match.params.type == "email_mail" ? 'letter_attachment' : 'message'/*'message'/*'letter'*/, {
                            // initialValue: this.props.selectedMethod && this.props.selectedMethod.letter_attachment,
                            initialValue: this.props.selectedMethod && this.props.selectedMethod[this.props.match.params.type == "email_mail" ? 'letter_attachment' : 'message'],
                            // rules: [{ required: true, message: 'Vul een bericht in.' }],
                            valuePropName: 'value',
                            trigger: 'onEditorChange',
                            // onEditorChange: (event) => { this.props.messageChanged(); }
                          })(
                            <CustomEditor
                                showFonts
                                document
                                hideVideo
                                placeholders={'correspondence'}
                                project_id={this.props.match.params.id}
                                editorWidth={'100%'}
                                // initialValue={this.props.selectedMethod && this.props.selectedMethod.message}
                                messageChanged={(e) =>
                                {
                                    this.props.messageChanged(e);

                                    // this.props.form.setFieldsValue({ letter: e });
                                    this.props.form.setFieldsValue({ message: e });
                                }}
                                />
                          ) }
                        </Form.Item>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={0} xl={12}>
                        <Form.Item label="Voorbeeld">
                            <div>
                              <Button type="new" onClick={() => {
                                this.props.onSave('iframe', this);
                                // setTimeout( () => {
                                //   this.setState({iframeKey: (this.state.iframeKey + 1)});
                                // }, 500);
                              }} style={{margin: 0}}>Ververs voorbeeld</Button>
                            </div>
                            <iframe
                                key={this.state.iframeKey}
                                style={{border: '1px solid #f0f0f0', width: '100%', height: 'auto', minHeight: 910}}
                                src={
                                    JOB_URL + '/correspondence/' + this.props.match.params.correspondenceid +
                                    '/view/' + this.props.match.params.type +
                                    (this.props.match.params.addressid ? '/' + this.props.match.params.addressid : '') +
                                    (this.props.match.params.occupantid ? '/occupant/'+this.props.match.params.occupantid : '') +
                                    '/Voorbeed brief.pdf?dpi=72&token=' + localStorage.getItem('authToken')
                                }
                                />
                        </Form.Item>
                    </Col>
                </Row>

              </span>
            ) : (
              <span>
                <Form.Item required label="Aanhef">
                  { getFieldDecorator('salutation_type', {
                    initialValue: this.props.selectedMethod && this.props.selectedMethod.salutation_type,
                    rules: [{ required: true, message: 'Maak een keuze' }],

                  })(
                    <Select>
                      <Select.Option key={'personal'}>Gepersonaliseerde aanhef</Select.Option>
                      <Select.Option key={'anonymous'}>Aangepaste aanhef</Select.Option>
                    </Select>
                  ) }
                </Form.Item>

                { this.props.form.getFieldValue('salutation_type') ? (
                  <Form.Item required label={this.props.form.getFieldValue('salutation_type') == 'personal' ? "Aanhef indien contactpersoon onbekend" : "Aangepaste aanhef"}>
                    { getFieldDecorator('salutation', {
                      initialValue: this.props.selectedMethod && this.props.selectedMethod.salutation || "Aan de bewoner(s) van",
                      rules: [{ required: true, message: 'Vul een aanhef in' }],
                    })(
                      <Input></Input>
                    ) }
                  </Form.Item>
                ) : null }

                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={0} >
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                      <Button type="new" onClick={() => {

                          this.props.onSave('modal', this);

                          // setTimeout( () => {
                          //   this.setState({iframeKey: (this.state.iframeKey + 1), previewModalVisible: true});
                          // }, 500);

                        }} style={{margin: 0}}>Voorbeeld brief</Button>
                      </div>
                  </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Form.Item /*required*/ label="Brief">
                          { getFieldDecorator('message', {
                            initialValue: this.props.selectedMethod && this.props.selectedMethod.message,
                            // rules: [{ required: true, message: 'Vul een bericht in.' }],
                            valuePropName: 'value',
                            trigger: 'onEditorChange',
                          })(
                            <CustomEditor
                                showFonts
                                document
                                hideVideo
                                placeholders={'correspondence'}
                                project_id={this.props.match.params.id}
                                editorWidth={'100%'}
                                // initialValue={this.props.selectedMethod && this.props.selectedMethod.message}
                                messageChanged={(e) =>
                                {
                                    this.props.messageChanged(e);

                                    this.props.form.setFieldsValue({ message: e });
                                }}
                                />
                          ) }
                        </Form.Item>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={0} xl={12}>
                        <Form.Item label="Voorbeeld">
                            <div>
                              <Button type="new" onClick={() => {
                                this.props.onSave('iframe', this);

                                // setTimeout( () => {
                                //   this.setState({iframeKey: (this.state.iframeKey + 1)});
                                // }, 500);
                              }} style={{margin: 0}}>Ververs voorbeeld</Button>
                            </div>
                            <iframe
                                key={this.state.iframeKey}
                                style={{border: '1px solid #f0f0f0', width: '100%', height: 'auto', minHeight: 910}}
                                src={
                                    JOB_URL + '/correspondence/'+ this.props.match.params.correspondenceid +
                                    '/view/'+ this.props.match.params.type +
                                    (this.props.match.params.addressid ? '/'+this.props.match.params.addressid : '') +
                                    (this.props.match.params.occupantid ? '/occupant/'+this.props.match.params.occupantid : '') +
                                    '/Voorbeeld brief.pdf?dpi=72&token=' + localStorage.getItem('authToken')
                                }
                                />
                        </Form.Item>
                    </Col>
                </Row>
              </span>
            )}
            <Modal
              title="Voorbeeld"
              width={1000}
              visible={this.state.previewModalVisible}
              onOk={() => this.setState({previewModalVisible: false})}
              onCancel={() => this.setState({previewModalVisible: false})}
            >
              <iframe
                key={this.state.iframeKey}
                style={{border: '1px solid #f0f0f0', width: '100%', height: 'auto', minHeight: 400}}
                src={
                    JOB_URL + '/correspondence/'+ this.props.match.params.correspondenceid +
                    '/view/'+ (this.props.match.params.type == 'email' ? 'email_mail' : this.props.match.params.type) +
                    (this.props.match.params.addressid ? '/'+this.props.match.params.addressid : '') +
                    (this.props.match.params.occupantid ? '/occupant/'+this.props.match.params.occupantid : '') +
                    '/Voorbeeld brief.pdf?dpi=72&token=' + localStorage.getItem('authToken')
                }
                />
            </Modal>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'compose_mail_form' })(ComposeMailForm);
