import React, { Component } from 'react';
import { Button, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLibrariesAction, getLibraryAction } from '../../actions/librariesActions';

class AddFromLibrary extends Component {

  state = {
    visible: false,
  }

  componentDidMount() {
    this.props.getLibrariesAction();
  }

  render() {
    return (
      <div>
        <Button onClick={() => this.setState({visible: true})}>Selecteer Losse teksten</Button>
        <Modal
          title="Tekst uit bibliotheek toevoegen"
          visible={this.state.visible}
          onOk={() => {
            this.props.onSelectLibrary(this.props.library);
            this.setState({visible: false});
          }}
          onCancel={() => this.setState({visible: false})}
        >
          <Select
            showSearch
            style={{ width: '100%'}}
            className="mbm"
            placeholder="Selecteer een tekst uit de bibliotheek"
            optionFilterProp="children"
            onChange={(id) => {
              this.props.getLibraryAction(id).then(() => {
                console.log(this.props.library.content);
              })
            }}
          >
          {this.props.libraries.map((value) => (
            <Select.Option value={value.id}>{value.title}</Select.Option>
          ))}
        </Select>
      </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.library.isFetching,
      isError : state.library.isError,
      libraries: state.library.libraries,
      library: state.library.library,
    };
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
      getLibraryAction : bindActionCreators(getLibraryAction, dispatch),
    }
  };

export default connect(mapStateToProps, mapDispatchToProps)(AddFromLibrary);
