import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Checkbox,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPartnerAction, patchPartnerAction, addPartnerAction, resetPartnerAction } from '../../../../../actions/partnersActions';
import { getInformationAction } from '../../../../../actions/informationActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import PatchPartnerForm from '../Form/PatchPartner';
import { showSuccess } from '../../../../../utils/Notifications';
import { HaveAbility, HaveRole } from '../../../../../utils/RolesAndAbilities';
import { zipcodeValidation } from '../../../../../utils/validationRules';
import NumberFormat from 'react-number-format';
// import '../Partners.css';

const CheckboxGroup = Checkbox.Group;

class PartnerDetail extends Component {

  state = {
    mediaId: null,
    filelist: [],
    informationitems: [],
    informationitemschecked: []
  }

  onInformationItemChange = (checkeditems) =>
  {
    this.setState({
      informationitemschecked: checkeditems
    });
  }

  componentDidMount() {

    this.props.resetPartnerAction();

    if(this.props.match.params.partnerid)
    {
      this.props.getPartnerAction(this.props.match.params.partnerid).then(() => {
        const checkeditems = [];

        this.props.partner.information.map((record, index) => {
          checkeditems.push(record.information_item_id);
        });
        //this.setState({mediaId: this.props.information.id})
        this.setState({informationitemschecked: checkeditems}, () => {
            // console.log('informationitemschecked', checkeditems);
        });
      });
    }

    const items = [];
    if(this.props.HaveAbility('information' ,'read')) {
      this.props.getInformationAction(this.props.match.params.id).then(() => {
        this.props.information.map((record, index) => {
          items[index] = { label: record.title, value: record.id };
        });

        this.setState({
          informationitems: items
        });
      });
    }
  }

  patchPartner(e) {
    if(e) {
      e.preventDefault();
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {

        values.information_items = this.state.informationitemschecked;

        if(this.props.match.params.partnerid) {
          this.props.patchPartnerAction(this.props.match.params.partnerid, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/partners`);
          });
        } else {
          this.props.addPartnerAction(this.props.match.params.id, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/partners`);
          });
        }
        showSuccess('Succesvol opgeslagen')
      }
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Row className="mbm">
          <Button disabled={!this.props.HaveAbility('partner' ,'write')} type="new" onClick={() => this.patchPartner(null)}>Opslaan</Button>
          <Link to={'/projects/'+this.props.match.params.id+'/modules/partners'}>
            <Button>Sluiten</Button>
          </Link>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card>

              <Form className={'ant-form--custom'} onSubmit={this.patchPartner} layout={'vertical'} colon={true}>
                <Row gutter={24} >
                  <Col span={12}>
                    <Form.Item required hasFeedback label="Bedrijfsnaam">
                      {getFieldDecorator('name', {
                        initialValue: this.props.partner.name,
                        rules: [{ required: true, message: 'Vul een bedrijfsnaam in' }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback label="KvK">
                      {getFieldDecorator('kvk', {
                        initialValue: this.props.partner.kvk,
                        rules: [{ required: false, message: 'Vul een KvK-nummer in' }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback label="Adres">
                      {getFieldDecorator('address', {
                        initialValue: this.props.partner.address,
                        rules: [{ required: false, message: 'Vul een adres in' }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback label="Postcode">
                      {getFieldDecorator('zipcode', {
                        initialValue: this.props.partner.zipcode,
                        rules: [{ required: false, message: 'Vul een postcode in' }, { validator: zipcodeValidation }],
                      })(
                        <Input maxLength={6} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback label="Plaats">
                      {getFieldDecorator('city', {
                        initialValue: this.props.partner.city,
                        rules: [{ required: false, message: 'Vul een plaatsnaam in' }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback label="Telefoon">
                          {getFieldDecorator('phone_number', {
                              initialValue: this.props.partner.phone_number ? this.props.partner.phone_number : '',
                              rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                          })(
                              <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
                          )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item  hasFeedback label="E-mail">
                      {getFieldDecorator('email', {
                        initialValue: this.props.partner.email,
                        rules: [{ required: false, message: 'Vul een e-mailadres in' },{type: 'email', message: 'Vul een geldig e-mailadres in'}],
                      })(
                        <Input type={'email'} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback label="Website">
                      {getFieldDecorator('website', {
                        initialValue: this.props.partner.website,
                        rules: [{ required: false, message: 'Vul een geldige URL in' }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Logo">
                      {getFieldDecorator('logo_id', {
                        initialValue: this.props.partner.logo_id
                      })(
                        <AddFromMedia
                          projectId={this.props.match.params.id}
                          initialMediaId={this.props.partner.logo_id}
                          initialMediaUrl={this.props.partner.logo_url}
                          onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
                          onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {/*<Form.Item layout={'horizontal'} label="Koppelen aan de volgende informatie" labelCol={{ float: 'left', display: 'inline' }} style={{ display: 'inline-block' }}>
                      {getFieldDecorator('information_items', {
                        initialValue: this.state.informationitemschecked
                      })(
                        <Checkbox.Group
                          // style={{ display: "inline-block" }}
                          // style={{ display: 'inline', float: 'left' }}
                          options={this.state.informationitems}
                          onChange={this.onInformationItemChange}
                          />
                      )}
                    </Form.Item>/**/}
                      <Form.Item label="Koppelen aan de volgende informatie">
                          <CheckboxGroup
                            options={this.state.informationitems}
                            onChange={this.onInformationItemChange}
                            value={this.state.informationitemschecked}
                            />
                      </Form.Item>
                  </Col>
                </Row>
              </Form>

            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const PartnerForm = Form.create({ name: 'contact_form' })(PartnerDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.partner.isFetching,
    isError : state.partner.isError,
    partner: state.partner.partner,
    information : state.information.information
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPartnerAction : bindActionCreators(getPartnerAction, dispatch),
    patchPartnerAction : bindActionCreators(patchPartnerAction, dispatch),
    addPartnerAction : bindActionCreators(addPartnerAction, dispatch),
    resetPartnerAction : bindActionCreators(resetPartnerAction, dispatch),
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm);
