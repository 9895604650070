import React from 'react'
import { v1 as uuidv1 } from 'uuid';

import {
  DownCircleOutlined,
  InfoCircleTwoTone,
  MinusOutlined,
  PictureOutlined,
  PlusOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  DatePicker,
  Collapse,
  Tooltip,
  Upload,
  message,
  Avatar,
  Modal,
  Select,
} from 'antd';
import { API_URL } from '../../../../constants/settings';
import api from '../../../../utils/api';
import { showError } from '../../../../utils/Notifications';
import getCroppedImg from '../../../../utils/cropImage';
import Cropper from "react-cropper";
import AddFromMediaModal from '../../../../components/AddFromMedia/AddFromMediaModal';
import Compressor from 'compressorjs';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result
}

class CChoiceList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: this.props.value || [],
    };

  }

  componentDidMount() {
    this.myRef = React.createRef();
  }

  addOption = () => {
    this.setState({options: [...this.state.options, {'id': uuidv1(), 'label' : 'Optie', 'code' : 'Artikelcode', 'vat' : '21%', 'price' : '0,00'}]}, () => {
      this.props.onChange(this.state.options);
    });
  }

  removeOption = (id) => {
    this.setState({options: this.state.options.filter((element) => element.id != id)}, () => {
      this.props.onChange(this.state.options);
    });
  }

  reorderOption = (startIndex, endIndex) => {
    if(startIndex != endIndex && endIndex >= 0) {
      this.setState({options: reorder(this.state.options, startIndex, endIndex)},() => {
        this.props.onChange(this.state.options);
      });
    }
  }

  updateLabel = (id, label) => {
    var options = this.state.options.map((element) => {
      if(element.id == id) {
        element.label = label.trim();
      }
      return element;
    })

    this.setState({options}, () => {
      this.props.onChange(this.state.options);
    });
  }

  updateCode = (id, code) => {
    var options = this.state.options.map((element) => {
      if(element.id == id) {
        element.code = code;
      }
      return element;
    })

    this.setState({options}, () => {
      this.props.onChange(this.state.options);
    });
  }

  updateVat = (id, vat) => {
    var options = this.state.options.map((element) => {
      if(element.id == id) {
        element.vat = vat;
      }
      return element;
    })

    this.setState({options}, () => {
      this.props.onChange(this.state.options);
    });
  }

  getConvertedPrice = (price, force = true, decimals = 2) => {

      if(!price){ price = '0,00' }

      price = price.replace(/[^0-9.,-]/gim, '');

      price = price.replace('.', ',');

      price = price.replace(',', '.');

      if(force)
      {   // round 2 decimals
          price = parseFloat(price);

          price = price.toFixed(decimals);
      }
      else
      {   // round max 2 decimals
          var tmp_price = parseFloat(price);

          tmp_price = Math.round(parseFloat(tmp_price) * 10000 + Number.EPSILON) / 10000;

          tmp_price = Math.abs(tmp_price);

          var decimal = tmp_price - Math.floor(tmp_price);

          decimal = Math.round(decimal * 10000) / 10000;

          if(('' + decimal).length > 4)
          {   // round if decimals > 2
              price = Math.round(parseFloat(price) * 100 + Number.EPSILON) / 100;

              price = '' + price.toFixed(2);
          }
          else
          {
              price = '' + price;

              var splitted = (''+price).split('.');

              if(splitted[1] && splitted[1].length > 2)
              {
                  price = price.substring(0, price.length - splitted[1].length + 2);
              }
          }
      }

      price = price.replace('.', ',');

      return price == 'NaN' ? '0,00' : price;
  }

  getPrice = (id) => {

    var price = false;

    var options = this.state.options.map((element) => {

      if(element.id == id) {
        price = element.price;
      }
    });

    return price;
  }

  updatePrice = (id, price, convert = false) => {

    if(convert)
    {
        price = this.getConvertedPrice(price, false);
    }

    var options = this.state.options.map((element) => {

      if(element.id == id) {
        element.price = price;
      }

      return element;
    });

    this.setState({options}, () => {

      this.props.onChange(this.state.options);
    });
  }

  updateImage = (id, image) => {

    var options = this.state.options.map((element) => {
      if(element.id == id) {
        element.image = image;
      }
        return element;
    })

    this.setState({options}, () => {
      this.props.onChange(this.state.options);
    });
  }

  removeImage = (id) => {

    var options = this.state.options.map((element) => {
      if(element.id == id) {
        delete element.image;
      }
        return element;
    })

    this.setState({options}, () => {
      this.props.onChange(this.state.options);
    });
  }

  async saveCroppedImage() {

      var cropper = this.myRef.current.cropper;

      var croppedImage = cropper.getCroppedCanvas().toDataURL();

      croppedImage = this.dataURLtoFile(croppedImage, this.state.tempImageName);

      var this_obj = this;

      new Compressor(croppedImage, {

        quality: 0.8,

        success(compressedImage)
        {
          this_obj.handleCroppedMediaUpload(compressedImage);

          this_obj.setState({showCropper: false, original_image_name: '', original_image: '', cropped_image: ''})

          // this_obj.setState({ croppedImage: compressedImage });
        },
        error(err) {

          console.log('getCroppedImage', 'Compressor err:', err.message);
        },
      });

      // this.handleCroppedMediaUpload(this.state.cropped_image);
      //
      // this.setState({showCropper: false, original_image_name: '', original_image: '', cropped_image: ''})
      //
      // // this.setState({cropped_image: this.state.cropped_image}); // ?
  }

  onCrop = () => {
    const imageElement = this.myRef.current;

    const cropper = imageElement.cropper;

    this.setState({cropped_image: cropper});
  };

  dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
  }

  handleCroppedMediaUpload = (file) => {

    // old
    // file = file.getCroppedCanvas().toDataURL();
    //
    // file = this.dataURLtoFile(file, this.state.original_image_name);

    var url = `${API_URL}/choice/form/${this.props.formId}/element/${this.props.elementId}/option/${this.state.option_id}/image`;

    api('POST', url, {}, true, {'image': file}).then((response) => {
        if(response.data.success)
        {
            this.updateImage(this.state.option_id, response.data.image_url);

            this.setState({ showCropper: false });
        }
        else
        {
            showError('De afbeelding kon niet worden opgeslagen');
        }
    });

    // if(this.props.projectId) {
    //   this.props.addMediaProjectFileAction(this.props.projectId, null, {'image': file}).then((response) => {
    //     this.setState({visible: false, selectedMediaItem: response.data.medialibrary.id, selectedMediaId: response.data.medialibrary.id, selectedMediaImage: response.data.medialibrary.image})
    //     this.props.onMediaSelection({'id': response.data.medialibrary.id});
    //   });
    // } else {
    //   this.props.addMediaFileAction(null, {'image': file}).then((response) => {
    //     this.setState({visible: false, selectedMediaItem: response.data.medialibrary.id, selectedMediaId: response.data.medialibrary.id, selectedMediaImage: response.data.medialibrary.image})
    //     this.props.onMediaSelection({'id': response.data.medialibrary.id});
    //   });
    // }

    return false;
  }

  getPrice(id)
  {
    var price = false;

    this.state.options.map((element) => {
        if(element.id == id) {
            price = this.getConvertedPrice(element.price, false);
        }
    });

    return price;
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        {this.state.options.map((element, index) => {

          return (
            <div style={{paddingBottom: 10, marginBottom: 10, borderBottom: '1px dashed #cccccc'}}>

              <Form.Item key={element.id} style={{marginBottom: 0}}>

                <Input
                  onChange={(e) => this.updateLabel(element.id, e.target.value)}
                  style={{width: 200, marginRight: 5}}
                  defaultValue={element.label}
                  placeholder={'Optie'}
                  />

                <Input
                  onChange={(e) => this.updateCode(element.id, e.target.value)}
                  style={{width: 140, marginRight: 5}}
                  defaultValue={element.code}
                  placeholder={'Artikelnummer'}
                  />

                { !this.props.disablePrices ? <Select
                  onChange={(e) => { this.updateVat(element.id, e); }}
                  style={{width: 80, marginRight: 5}}
                  defaultValue={element.vat}
                  placeholder={'BTW'}
                  >
                  <Select.Option value='9%'>9%</Select.Option>
                  <Select.Option value='21%'>21%</Select.Option>
                </Select> : null }

                { !this.props.disablePrices ? getFieldDecorator('price-'+element.id, {
                    initialValue: (''+(!element.price ? '0,00' : parseFloat(element.price.replace(',', '.')).toFixed(2))).replace('.', ','),
                    rules: [{ required: true, message: 'Vul een bedrag in' }],
                    onChange: (e) => {

                        var converted_value = this.getConvertedPrice(e.target.value, false);

                        this.updatePrice(element.id, converted_value); // e.target.value)

                        e.target.value = converted_value;

                        element.price = converted_value;
                    },
                    // value: element.price
                })( //)}
                <Input
                  // onChange={(e) => this.updatePrice(element.id, e.target.value)}
                  style={{width: 160, marginRight: 5}}
                  // defaultValue={element.price}
                  // initialValue={(''+(parseFloat(element.price).toFixed(2))).replace('.', ',')}
                  placeholder={'Bedrag (incl. BTW)'}
                  // type="number"
                  required={true}
                  prefix="€"
                  precision={2}
                  onBlur={() => {

                      var converted_value = this.getConvertedPrice(this.getPrice(element.id), true);

                      this.updatePrice(element.id, converted_value);

                      this.props.form.setFieldsValue({ ['price-'+element.id]: converted_value });
                  }}
                  // value={this.getPrice(element.id, false)}
                  // value={element.price}
                  />
                ) : null}

                <UpCircleOutlined
                  onClick={() => this.reorderOption(index, (index - 1))}
                  style={{marginRight: 5, fontSize: 20}} />
                <DownCircleOutlined
                  onClick={() => this.reorderOption(index, (index + 1))}
                  style={{marginRight: 5, fontSize: 20}} />

                <Button onClick={() => this.removeOption(element.id)} type="link danger" style={{ color: '#f5222d' }} >verwijder</Button>

              </Form.Item>

              <Form.Item label={(
                <span>
                  Afbeelding
                  <Tooltip className="mhs" title='Upload hier de afbeelding. De afbeelding is te uploaden als JPEG of PNG bestand.'>
                    <InfoCircleTwoTone />
                  </Tooltip>
                </span>
                )}
                style={{marginBottom: 0}}
                >

                { element.image ?
                  <div>
                      <div onClick={() => { this.setState({ option_id: element.id, addImage: true }); }} style={{height: 200, width: 200, backgroundImage: 'url('+element.image+')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'top left'}}></div>
                      <div style={{clear: 'both', float: 'left'}}><Button onClick={(e) => { e.preventDefault(); this.removeImage(element.id); return false; }} type="link danger" style={{ color: '#f5222d' }} >verwijder afbeelding</Button></div>
                  </div>
                  :
                  <Button onClick={() => { this.setState({ option_id: element.id, addImage: true }); }} type="dashed" style={{width: 200, height: 200, backgroundColor: '#fafafa'}}><PictureOutlined style={{fontSize: 32}} /><div style={{textAlign: 'center'}}>Selecteer<br/>afbeelding</div></Button>
                }
              </Form.Item>

              <AddFromMediaModal
                customerId={null}
                projectId={null}
                activeTab={"1"}
                hideBrowser={true}
                fixedRatio={1}
                onMediaSelection={(object) => {

                  this.updateImage(this.state.option_id, object.image);

                  this.setState({addImage: false});
                }}
                visible={this.state.addImage}
                cancel={() => this.setState({addImage: false})}
                />

            </div>
          );
        })}

        <Form.Item>
          <Button onClick={this.addOption} type="dashed" icon={<LegacyIcon type={'plus'} />}>Keuzelijst optie toevoegen</Button>
        </Form.Item>

        <Modal
          title="Uitsnijden"
          width={800}
          okText="Opslaan"
          visible={this.state.showCropper}
          onOk={() => { this.saveCroppedImage(); }}
          onCancel={() => this.setState({showCropper: false})}>
          <div>
            <div style={{position: 'relative', width: '100%'}}>
              <Cropper
                src={this.state.original_image}
                style={{ height: 400, width: "100%" }}
                // initialAspectRatio={(4 / 4)}
                aspectRatio={(4 / 4)}
                guides={true}
                crop={this.onCrop}
                dragMode='move'
                ref={this.myRef}
              />
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                <Button style={{marginRight: 5}} type="default" icon={<MinusOutlined />} shape="circle" onClick={() => {
                  const imageElement = this.myRef.current;
                  const cropper = imageElement.cropper;
                  cropper.zoom(-0.1);
                }}></Button>
                <Button style={{marginleft: 5}} type="default" shape="circle" icon={<PlusOutlined />} onClick={() => {
                  const imageElement = this.myRef.current;
                  const cropper = imageElement.cropper;
                  cropper.zoom(0.1);
                  }}></Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CChoiceList;
