export default (state = {
    isError: false,
    isFetching: false,
    deliveries: [],
    delivery: {},
    drawings: [],
    drawing: {},
    points: [],
    delivery_filters: [],
    form_filters: [],
    location_filters: [],
    user_filters: [],
    point: {},
    photos: [],
    users: [],
    user: {},
    nr_of_new: 0,
    nr_of_open: 0,
    nr_of_submitted: 0,
    nr_of_completed: 0,
    nr_of_sub_open: 0,
    nr_of_sub_submitted: 0,
    nr_of_sub_completed: 0,
    deliver_settings: {},
    addresses: [],
    log: [],
    comments: [],
    form: {
        disabled_delivery_ids: []
    },
    form_points: [],
    forms: [],
    customer_forms: [],
    status: [],
    points_deliveries: []
}, action) => {

    switch (action.type) {
    case 'DELIVERIES_ACTION_FETCH':
    return {
        ...state,
        deliveries: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_POINTS_ACTION_FETCH':
    return {
        ...state,
        points: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_ACTION_FETCH':
    return {
        ...state,
        delivery: {},
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_FORM_POINTS_ACTION_FETCH':
    return {
        ...state,
        form_points: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_POINT_ACTION_FETCH':
    return {
        ...state,
        point: {},
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_USERS_ACTION_FETCH':
    return {
        ...state,
        users: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_USER_ACTION_FETCH':
    return {
        ...state,
        user: {},
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_SETTINGS_ACTION_FETCH':
    return {
        ...state,
        settings: {},
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_FORMS_ACTION_FETCH':
    return {
        ...state,
        forms: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_FORM_ACTION_FETCH':
    return {
        ...state,
        form: {},
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_STATUS_ACTION_FETCH':
    return {
        ...state,
        status: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_FORM_LOG_ACTION_FETCH':
    return {
        ...state,
        log: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERY_ADDRESSES_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: true,
        isError: false,
    }

    case 'DELIVER_ACTION_FETCH':
    case 'DRAWING_ACTION_FETCH':
    case 'POINT_ACTION_FETCH':
    case 'USER_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'DELIVERIES_ACTION_SUCCESS':
    return {
        ...state,
        deliveries: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DELIVER_ACTION_SUCCESS':
    return {
        ...state,
        delivery: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DELIVERIES_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: false,
        deliveries: [],
    }
    case 'DELIVER_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: false,
        delivery: {},
    }
    case 'DELIVER_FORM_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: false,
        forms: [],
        form: {
            disabled_delivery_ids: []
        },
    }


    case 'POINTS_DELIVERIES_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        points_deliveries: action.payload,
    }


    case 'DRAWINGS_ACTION_SUCCESS':
    return {
        ...state,
        drawings: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DRAWING_ACTION_SUCCESS':
    return {
        ...state,
        drawing: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_DRAWING_ACTION_SUCCESS':
    return {
        ...state,
        // drawing: action.payload,
        // isFetching: false,
        isError: false,
    }
    case 'UPDATE_DRAWING_ACTION_SUCCESS':
    return {
        ...state,
        // drawing: action.payload,
        // isFetching: false,
        isError: false,
    }
    case 'DRAWINGS_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: true,
        drawings: [],
        //drawing: {},
    }
    case 'DRAWING_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: true,
        //drawings: [],
        drawing: {},
    }


    case 'POINTS_ACTION_SUCCESS':
    return {
        ...state,
        points: action.payload.points,
        //nr_of_new: action.payload.nr_of_new,
        nr_of_open: action.payload.nr_of_open,
        nr_of_submitted: action.payload.nr_of_submitted,
        nr_of_completed: action.payload.nr_of_completed,

        nr_of_sub_open: action.payload.nr_of_sub_open,
        nr_of_sub_submitted: action.payload.nr_of_sub_submitted,
        nr_of_sub_completed: action.payload.nr_of_sub_completed,

        isFetching: false,
        isError: false,
    }
    case 'FORM_POINTS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        form_points: action.payload.points,

        //nr_of_new: action.payload.nr_of_new,
        nr_of_open: action.payload.nr_of_open,
        nr_of_submitted: action.payload.nr_of_submitted,
        nr_of_completed: action.payload.nr_of_completed,

        nr_of_sub_open: action.payload.nr_of_sub_open,
        nr_of_sub_submitted: action.payload.nr_of_sub_submitted,
        nr_of_sub_completed: action.payload.nr_of_sub_completed,
    }
    case 'UPDATE_POINTS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'POINT_ACTION_SUCCESS':
    return {
        ...state,
        point: action.payload,
        photos: action.payload.photos,
        log: action.payload.log,
        comments: action.payload.comments,
        isFetching: false,
        isError: false,
    }
    case 'POINTS_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: true,
        points: [],
        //point: {},
        nr_of_open: 0,
        nr_of_submitted: 0,
        nr_of_completed: 0,
    }
    case 'POINT_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: true,
        //points: [],
        point: {
            photos: []
        },
        comments: [],
        log: []
    }


    case 'DELIVER_USERS_ACTION_SUCCESS':
    return {
        ...state,
        users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'USER_ACTION_SUCCESS':
    return {
        ...state,
        user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_USER_ACTION_SUCCESS':
    return {
        ...state,
        // isFetching: false,
        isError: false,
    }
    case 'UPDATE_USER_ACTION_SUCCESS':
    return {
        ...state,
        // isFetching: false,
        isError: false,
    }
    case 'USERS_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: true,
        users: [],
        //user: {},
    }
    case 'USER_ACTION_RESET':
    return {
        ...state,
        isFetching: false,
        isError: true,
        //users: [],
        user: {},
    }


    case 'DELIVER_SETTINGS_ACTION_SUCCESS':
    return {
        ...state,
        deliver_settings: action.payload,
        isFetching: false,
        isError: false,
    }


    case 'DELIVER_ADDRESSES_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,

        isFetching: false,
        isError: false,
    }


    case 'DELIVER_CUSTOMER_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        customer_forms: action.payload,

        isFetching: false,
        isError: false,
    }
    case 'DELIVER_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        forms: action.payload,

        isFetching: false,
        isError: false,
    }
    case 'DELIVER_FORM_ACTION_SUCCESS':
    return {
        ...state,
        form: action.payload,

        isFetching: false,
        isError: false,
    }


    case 'DELIVER_POINTS_FILTER_DELIVERIES_ACTION_SUCCESS':
    return {
        ...state,
        delivery_filters: action.payload,

        isFetching: false,
        isError: false,
    }
    case 'DELIVER_POINTS_FILTER_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        form_filters: action.payload,

        isFetching: false,
        isError: false,
    }
    case 'DELIVER_POINTS_FILTER_LOCATIONS_ACTION_SUCCESS':
    return {
        ...state,
        location_filters: action.payload,

        isFetching: false,
        isError: false,
    }
    case 'DELIVER_POINTS_FILTER_USERS_ACTION_SUCCESS':
    return {
        ...state,
        user_filters: action.payload,

        isFetching: false,
        isError: false,
    }

    case 'DELIVER_STATUS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        status: action.payload,
    }
    case 'DELIVER_STATUS_POST_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }


    default:
        return state
    }
}
