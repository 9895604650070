import React, { Component } from 'react';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'

import { v1 as uuidv1 } from 'uuid'
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../utils/Notifications';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormPointsAction, /*searchPointsAction,*/ getUsersAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, getUserFilterAction } from '../../../../actions/deliverActions';

import PageHeader from '../../../../components/PageHeader/PageHeader';
import DeliveryFormSteps from './Steps';

import DeliverSideMenu from '../DeliverSideMenu';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

import CustomTable from '../../../../components/CustomTable/CustomTable';
import moment from 'moment';
import 'moment/locale/nl';


class DeliveryFormPoints extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      expandedRowKeys: []
    };
  }

  componentDidMount()
  {
      this.loadData();
  }

  loadData()
  {
      // points
      this.props.getDeliveryFormPointsAction(this.props.match.params.form_id).then(() => {
          this.setState({ loading: false });
      });

      // filters
      this.props.getUsersAction(this.props.match.params.id, false, 'contractor');
      this.props.getDeliveryFilterAction(this.props.match.params.id);
      //this.props.getFormFilterAction(this.props.match.params.id);
      this.props.getLocationFilterAction(this.props.match.params.id);
      this.props.getUserFilterAction(this.props.match.params.id);
  }

  handleSubmit = (e, values, redirect) => {

  }

  getStatusText(status)
  {
      if(status == 'new'){ return 'Nieuw'; }
      else if(status == 'submitted'){ return 'Controleren'; }
      else if(status == 'completed'){ return 'Afgehandeld'; }
      else { return 'Open'; }
  }

  getStatusColor(status)
  {
      if(status == 'new'){ return '#888888'; }
      else if(status == 'submitted'){ return '#ff8800'; }
      else if(status == 'completed'){ return '#00ff00'; }
      else { return '#ff0000'; }
  }

  getDeliveryFilters()
  {
      var filters = [];

      this.props.delivery_filters.map((record) => {

          filters.push({
              text: record.description,
              value: record.id
          });
      });

      return filters;
  }

  getFormsFilters()
  {
      var filters = [];

      this.props.form_filters.map((record) => {

          filters.push({
              text: record.name,
              value: record.id
          });
      });

      return filters;
  }

  getAddressFilters()
  {
      var filters = [];

      this.props.location_filters.map((record) => {

          if(record.type == 'address')
          {
              filters.push({
                  text: record.full_address,
                  value: record.id
              });
          }
      });

      return filters;
  }

  getUserFilters()
  {
      var filters = [];

      this.props.user_filters.map((record) => {

          filters.push({
              text: record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address,
              value: record.id
          });
      });

      return filters;
  }

  render() {

      const columns = [
          {
              title: '',
              dataIndex: false,
              width: 64
          },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => { return a.status.localeCompare(b.status)},
            render: (text, record) => (
              /*<div style={{ color: this.getStatusColor(text) }}>{this.getStatusText(text)}</div>*/
              <div style={{height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></div>
            ),
            filters: [
              // {
              //   text: 'Nieuw',
              //   value: 'new',
              // },
              {
                text: 'Open',
                value: 'open',
              },
              {
                text: 'Controleren',
                value: 'submitted',
              },
              {
                text: 'Afgehandeld',
                value: 'completed',
              }
            ],
            onFilter: (value, record) => record.status === value,
            width: 110
          },
          {
              title: 'Onderwerp',
              dataIndex: 'subject',
              render: (text, record) => (
                  <span>
                      <Link
                          onClick={() => { this.showPoint(record.id, record.parent_id); }}
                          //to={'/projects/'+record.project_id+'/deliver/point/'+record.id}
                          >
                          {(record.parent_id ? '- ' : '') + text}
                      </Link>
                  </span>
              )
          },
          {
              title: 'Vooroplevering',
              dataIndex: 'description', // 'description',
              //sorter: (a, b) => { return a.description && b.description ? a.description.localeCompare(b.description) : (a.description ? 1 : (b.description ? -1 : 0))},
              sorter: (a, b) => { return a.description.localeCompare(b.description) },
              render: (text, record) => (
                  <span>
                      {text ? text : record.parent_id ? 'N.v.t.' : 'Niet opgegeven'}
                  </span>
              ),
              filters: this.getDeliveryFilters(),
              onFilter: (value, record) => record.delivery_id === value,
          },
          {
              title: 'Adres',
              dataIndex: 'address_label',
              //sorter: (a, b) => { return a.address_label && b.address_label ? a.address_label.localeCompare(b.address_label) : (a.address_label ? 1 : (b.address_label ? -1 : 0))},
              sorter: (a, b) => { return a.address_label.localeCompare(b.address_label) },
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              filters: this.getAddressFilters(),
              onFilter: (value, record) => record.address_id === value,
          },
          // {
          //     title: 'Formulier(en)',
          //     dataIndex: 'deliver_form_name',
          //     sorter: (a, b) => { return a.deliver_form_name.localeCompare(b.deliver_form_name)},
          //     render: (text, record) => (
          //         <span>
          //             {text}
          //         </span>
          //     ),
          //     filters: this.getFormsFilters(),
          //     onFilter: (value, record) => record.deliver_form_id.includes(value),
          // },
          {
              title: 'Verantwoordelijke',
              dataIndex: 'user_name',
              sorter: (a, b) => { return a.user_name.localeCompare(b.user_name)},
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              filters: this.getUserFilters(),
              onFilter: (value, record) => record.assigned_to_delivery_user_id === value,
          },
          {
              title: 'Uiterlijk gereed',
              dataIndex: 'due_date',
              render: (text, record) => (
                  <span style={{color: (record.filter !== 'expired' ? '#000000' : '#ff0000')}}>
                      {record.expired ? 'Verlopen' : (text ? moment(text).format('DD/MM/YYYY') : 'Niet opgegeven')}
                  </span>
              ),
              filters: [
                {
                  text: 'Verlopen',
                  value: 'expired',
                },
                {
                  text: 'Niet verlopen',
                  value: 'not-expired',
                },
                {
                  text: 'Niet ingesteld',
                  value: 'not-set',
                },
              ],
              onFilter: (value, record) => record.filter === value,
          },
          // {
          //     key: 'photos',
          //     title: 'Laatste foto',
          //     dataIndex: 'photos',
          //     width: 140,
          //     hideOnSmall: true,
          //     render: (text, record) => (
          //         <div class="photos">
          //             { record.photos.length > 0 ?
          //             record.photos.map((photo, index) => {
          //             return <div
          //                 class="photo"
          //                 id={'point-'+record.id+'-photo-'+(index + 1)}
          //                 onClick={() => { this.showPhoto(photo.url); }}
          //                 style={{backgroundImage: 'url('+photo.url+')', backgroundSize: 'cover', display: (index == 0 ? 'block' : 'none')}}
          //                 ></div>
          //             })
          //             : null }
          //             { record.photos.length > 1 ?
          //             <div class="buttons">
          //                 <a class="left" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, -1); }}}>{'<'}</a>
          //                 <a class="right" onClick={(event) => { if(!this.state.disable_slider_click){ this.slidePhoto(record.id, 1); }}}>{'>'}</a>
          //             </div>
          //             : null }
          //         </div>
          //     )
          // }
      ];

      // const { selectedRowKeys } = this.state;
      //
      // const rowSelection = {
      //     selectedRowKeys,
      //     onChange: this.onSelectChange,
      //     hideDefaultSelections: true,
      // };

    // const { getFieldDecorator } = this.props.form;

    return (
        <div>

            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <DeliverSideMenu project_id={this.props.match.params.id} activeMenu='7' />

              <div style={{padding: 24, width: '100%'}}>

                  <Row className="mbm">
                    <Col span={24}>
                      <div>
                        <Link to={`/projects/${this.props.match.params.id}/deliver/delivery-forms`}>
                          <Button>Sluiten</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mbm">
                    <Col span={24}>
                      <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                        <DeliveryFormSteps current={3} {...this.props} />
                      </Card>
                    </Col>
                  </Row>

                  <Row className="mbm" style={{display: (this.state.is_archive ? 'none' : 'block')}}>

                      <Col span={8} className="status-box status-box-open">
                          <div>
                              {/*<Icon type={'close-circle'} />*/}
                              <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: '#f00', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                              <span class="label">Openstaand</span>
                              <span class="number">{this.props.isFetching ? <LoadingOutlined /> : this.props.nr_of_open}</span>
                          </div>
                      </Col>

                      <Col span={8} className="status-box status-box-submitted">
                          <div>
                              {/*<Icon type={'question-circle'} />*/}
                              <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: '#f80', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                              <span class="label">Ter controle</span>
                              <span class="number">{this.props.isFetching ? <LoadingOutlined /> : this.props.nr_of_submitted}</span>
                          </div>
                      </Col>

                      <Col span={8} className="status-box status-box-completed">
                          <div>
                              {/*<Icon type={'check-circle'} />*/}
                              <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: '#0f0', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                              <span class="label">Afgehandeld</span>
                              <span class="number">{this.props.isFetching ? <LoadingOutlined /> : this.props.nr_of_completed}</span>
                          </div>
                      </Col>

                  </Row>

                  <Row>
                      <Col span={24}>
                          <CustomTable
                              rowKey='id'
                              size="middle"
                              columns={columns}
                              dataSource={this.props.points}
                              expandedRowKeys={this.state.expandedRowKeys}
                              onExpandedRowsChange={(id) => {
                                  this.setState({expandedRowKeys: id});
                              }}
                              />
                      </Col>
                  </Row>

              </div>

            </div>

        </div>
    );
  }
}


const DeliveryFormPointsForm = Form.create({ name: 'delivery_form_points' })(DeliveryFormPoints);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        points : state.deliver.form_points,
        nr_of_open : state.deliver.nr_of_open,
        nr_of_submitted : state.deliver.nr_of_submitted,
        nr_of_completed : state.deliver.nr_of_completed,
        users : state.deliver.users,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        location_filters : state.deliver.location_filters,
        user_filters : state.deliver.user_filters
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDeliveryFormPointsAction : bindActionCreators(getDeliveryFormPointsAction, dispatch),
        // searchPointsAction : bindActionCreators(searchPointsAction, dispatch),
        // exportPointsAction : bindActionCreators(exportPointsAction, dispatch),
        // archivePointsAction : bindActionCreators(archivePointsAction, dispatch),
        // deletePointsAction: bindActionCreators(deletePointsAction, dispatch),
        // resetPointsAction : bindActionCreators(resetPointsAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        // multiUpdatePointsAction : bindActionCreators(multiUpdatePointsAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        getUserFilterAction : bindActionCreators(getUserFilterAction, dispatch),
        // addPointsToDeliveryFormAction : bindActionCreators(addPointsToDeliveryFormAction, dispatch),
        HaveAbility :  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormPointsForm);
