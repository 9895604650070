import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChoiceFormByIdAction, addChoiceFormToCustomerAction, updateChoiceFormAction, resetChoiceAction, checkChoiceFormAction } from '../../../actions/choiceActions';
import PageHeader from '../../../components/PageHeader/PageHeader';
import LibrarySideMenu from '../LibrarySideMenu';
import { HaveAbility, HaveRole } from '../../../utils/RolesAndAbilities';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

class ChoiceDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      show_preview_buttons: (this.props.match.params.id ? true : false),
      can_write: false,
      loading: true,
    };
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('library', 'write')
      });

    this.props.resetChoiceAction();

    if(this.props.match.params.id) {

      this.props.getChoiceFormByIdAction(this.props.match.params.id).then(() => {

        if(this.IsJsonString(this.props.choice_form.form) && JSON.parse(this.props.choice_form.form))
        {
          this.refs.FormBuilder.parse(JSON.parse(this.props.choice_form.form));
          this.setState({formData: JSON.parse(this.props.choice_form.form)});
        }
        else
        {
            //console.log(this.props.choice_form.form, JSON.parse(this.props.choice_form.form));
            //alert('Test!');
            // this.setState({formData: [{'id': 'choice', 'label': 'Vragen', elements: []}]});
            this.refs.FormBuilder.parse([{'id': 'choice', 'label': 'Vragen', elements: []}]);

            this.setState({ loading: false });
        }
      });
    }
    else
    {
      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': 'choice', 'label': 'Vragen', elements: []}]);

      this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.form.validateFields((err, values) => {

      if (err) {
        return showError('Niet alle velden zijn ingevuld.');
      }

      values.form = JSON.stringify(this.state.formData);

      if (this.props.match.params.id) {
        this.props.updateChoiceFormAction(this.props.match.params.id, values).then(() => {
          if (redirect) {
            this.props.history.push(`/library/choice`);
          }
          else {
            //showSuccess('Succesvol opgeslagen');
          }

          this.setState({ show_preview_buttons: true });
        });
      }
      else {
        this.props.addChoiceFormToCustomerAction(values).then(() => {
          this.props.history.push('/library/choice/' + this.props.choice_form.id + '/detail');
        });
      }

      showSuccess();
    });
  }

  previewMail()
  {
      this.props.checkChoiceFormAction(this.props.match.params.id, 'mail');
  }

  previewDigital()
  {
      this.props.checkChoiceFormAction(this.props.match.params.id, 'digital');
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Keuze', 'link': '#'}, {'label': 'Formulier'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='11' activeSubMenu='subChoice1' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={!this.state.can_write} type="new" onClick={() => this.handleSubmit(null, this.state.formData, true)}>Opslaan</Button>
                      <Link to={`/library/choice`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                  {/*<Col span={12} align={'right'} style={{display: (this.state.show_preview_buttons ? 'block' : 'none')}}>
                      <Button onClick={() => { this.previewMail(); }}>Voorbeeld post</Button>
                      &nbsp;
                      <Button onClick={() => { this.previewDigital(); }}>Voorbeeld digitaal</Button>
                  </Col>*/}
                </Row>

                <Divider></Divider>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>

                      <Col span={16}>
                        <Form.Item required label="Omschrijving">
                          {getFieldDecorator('description', {
                            initialValue: this.props.choice_form ? this.props.choice_form.description : '',
                            rules: [{ required: true, message: 'Vul een omschrijving in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>

                      { this.props.selectedCustomer.id == 1 && this.props.HaveRole(['super-admin']) ?
                      <Col span={8}>
                          <Form.Item label="Gebruik voor demo project">
                            {getFieldDecorator('use_for_demo', {
                                valuePropName: 'checked',
                                initialValue: this.props.choice_form && this.props.choice_form.use_for_demo ? true : false
                            })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            )}
                          </Form.Item>
                      </Col> : null }

                    </Row>


                      <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                        <Col span={24}>
                          <FormBuilder
                              onChangeForm={(data) => { if(this.state.can_write){ this.setState({formData: data}, (e) => this.handleSubmit(e, data, false))}}}
                              ref={'FormBuilder'}
                              formId={this.props.match.params.id}
                              // sections={1}
                              enabledElements={['c_title', 'c_textinput', 'c_textareainput', 'c_numberinput', 'c_calculatorinput', 'c_timeinput', 'c_dateinput', 'c_choice', /*'c_contactinput',*/ 'c_select', 'c_addressinput']}
                              />
                        </Col>
                      </Row>


                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const ChoiceDetailForm = Form.create({ name: 'intake_form' })(ChoiceDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.choice.isFetching,
    isError : state.choice.isError,
    choice_form : state.choice.choice_form,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChoiceFormByIdAction : bindActionCreators(getChoiceFormByIdAction, dispatch),
    addChoiceFormToCustomerAction : bindActionCreators(addChoiceFormToCustomerAction, dispatch),
    updateChoiceFormAction : bindActionCreators(updateChoiceFormAction, dispatch),
    resetChoiceAction : bindActionCreators(resetChoiceAction, dispatch),
    checkChoiceFormAction : bindActionCreators(checkChoiceFormAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceDetailForm);
