import React, { Component } from 'react';
import { DownOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Upload,
  Checkbox,
  Switch,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMediaLibraryAction, searchMediaLibraryAction, deleteMediaLibraryAction, addMediaFileAction, getMediaCategoriesAction, copyImageToProject } from '../../../actions/medialibraryActions';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import CustomTable from '../../../components/CustomTable/CustomTable';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import ProjectMediaSideMenu from './ProjectMediaSideMenu';
import moment from 'moment';
import { LazyLoadImage } from "react-lazy-load-image-component";
import AddFromMediaModal from '../../../components/AddFromMedia/AddFromMediaModal';

function showImage(src)
{
  Modal.info({
    centered: true,
    width: 500,
    content: (
      <div>
        <img src={src} style={{width: 300}} />
      </div>
    ),
    icon: <LegacyIcon type={''} />,
    okText: 'X',
    onOk() {}
  });
}

class ProjectMedia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      uploadToggle: false,
      modalVisible: false,

      filteredInfo: null,

      group_filter: [],
      group_filter_and: true,
      addNewMedia: false,

      isFetching: false,
      media: []
    };
  }

  componentDidMount() {

      this.loadData();
  }

  loadData()
  {
      this.setState({ isFetching: true });

      this.props.getMediaCategoriesAction();

      this.props.getMediaLibraryAction(null, this.props.match.params.id).then(() => {

          this.setState({ media: this.props.medialibrary });

          this.setState({ isFetching: false });
      });
  }

  handleSubmit = (e, values) => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      if (!err) {

        this.props.storeMediaFileAction(values).then(() => {

          showSuccess('Succesvol opgeslagen');

          this.loadData();

          // this.setState({drawerVisible: false});
          // this.props.form.resetFields();
        });
      }
    });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  showConfirm() {

    showMultiDelete((multidelete) => {

      if(multidelete) {

        this.props.deleteMediaLibraryAction({'ids': this.state.selectedRowKeys}).then(() => {

          this.setState({selectedRowKeys: []});

          showSuccess('Succesvol verwijderd');

          this.loadData();
        });
      }
    });
  }

  handleMediaUpload = (file) => {

    // this.props.addMediaFileAction(null, { 'image': file } ).then(() => {
    //     showSuccess('Succesvol toegevoegd');
    //     this.setState({uploadToggle: false});
    // });

    var reader = new FileReader();

    reader.onload = (readerEvent) => {

        var image = new Image();

        image.onload = (imageEvent) => {

          var canvas       = document.createElement("canvas"),
              ctx          = canvas.getContext('2d'),
              newWidth     = image.width,
              newHeight    = image.height,
              maxDimension = 1600;

          ctx.fillStyle = "rgb(255, 255, 255)";
          ctx.fillRect(0, 0, 0, 0);

          if(image.width > maxDimension || image.height > maxDimension) {

              // check landscape or portrait
              if(image.width > image.height) {
                // landscape
                newWidth = maxDimension;
                newHeight = (maxDimension / image.width ) * image.height;

              } else {
                //portrait
                newHeight = maxDimension;
                newWidth  = (maxDimension / image.height ) * image.width;
              }
          }

          canvas.width  = newWidth;
          canvas.height = newHeight;

          ctx.webkitImageSmoothingEnabled = true;
          ctx.mozImageSmoothingEnabled = true;
          ctx.imageSmoothingEnabled = true;

          ctx.drawImage(image, 0, 0, newWidth, newHeight);

          var dataUrl = canvas.toDataURL(file.type);

          this.props.addMediaFileAction(null, { 'image': this.dataURLtoFile(dataUrl, file.name)} ).then(() => {

              showSuccess('Succesvol toegevoegd');

              this.setState({uploadToggle: false});

              this.loadData();
          });
        }

        image.src = readerEvent.target.result;
    }

    reader.readAsDataURL(file);

    return false;
  }

  dataURLtoFile(dataurl, filename) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
  }

  handleTableChange = (pagination, filters, sorter, extra) => {

    this.setState({

      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = false;

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = false;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  removeItem(arr, value) {

    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  render() {

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Afbeelding',
      width: 100,
      dataIndex: 'image',
      render: (text, record) => (
        <a onClick={() => {
            this.setState({modalVisible: true, image: record.original_image, title: record.name});
          }}>
          <div style={{ width: 40, height: 40, textAlign: 'center', verticalAlign: 'middle', lineHeight: '40px' }}><LazyLoadImage src={record.image} style={{maxWidth: 40, maxHeight: 40}}/></div>
        </a>
      )
    },
    {
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
          <Link to={`/projects/${this.props.match.params.id}/media/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },
    /*{
      title: 'Project',
      dataIndex: 'project',
      //sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
            {text != null ? text : '-'}
        </span>
      )
    },/**/
    /*{
      title: 'Categorie',
      dataIndex: 'category',
      //sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <div>
            { record.categories && record.categories.map((category, index) => {
                return <span>{index > 0 ? ', ' : ''}{category.name}</span>
            }) }
        </div>
      )
    },/**/

    {
        title: 'Categorieen',
        dataIndex: '',
        key: 'categories',
        width: 160,
        filterMultiple: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 0 }}>

              <div>
                  {this.props.mediacategories && this.props.mediacategories.map((obj) => {
                      return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                          <Checkbox
                              onChange={(e) => {
                                  var my_group_filter = this.state.group_filter;

                                  if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                  else{ this.removeItem(my_group_filter, obj.name); }

                                  this.setState({ group_filter: my_group_filter });

                                  setSelectedKeys(my_group_filter);
                              }}
                              checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                              >
                              {obj.name}
                          </Checkbox>
                      </div>
                  })}
              </div>

              <div style={{padding: 8, width: '100%', clear: 'both'}}>
                  <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                  <div style={{float: 'left', marginLeft: 8}}>filter</div>
              </div>

              <div style={{width: '100%', clear: 'both'}}>
                  <Button
                    type="link"
                    onClick={() => { confirm(); }}
                    style={{marginLeft: -8, marginRight: 8 }}
                    >OK</Button>

                  <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
              </div>

            </div>
        ),
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.categories && record.categories.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
        )
    },
    {
      title: 'Aangemaakt',
      dataIndex: 'created_at',
      sorter: (a, b) => { return moment(a.created_at).unix() - moment(b.created_at).unix()},
      render: (index, record) => (moment(record.created_at).format('DD/MM/YYYY - HH:mm uur'))
    }];

    return (
      <div>

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <ProjectMediaSideMenu id={this.props.match.params.id} activeMenu='1' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">

                <Col span={12}>

                  {/*<Button disabled={!this.props.HaveAbility('media', 'write')} onClick={() => this.setState({uploadToggle: !this.state.uploadToggle})} type="new" icon="plus">Toevoegen</Button>*/}
                  <Button
                      disabled={!this.props.HaveAbility('media', 'write')}
                      onClick={() => { this.setState({ visible: true }) }}
                      type="new"
                      icon={<PlusOutlined />}>Toevoegen</Button>

                  <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('media', 'delete') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                    <Menu>
                      <Menu.Item onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                    </Menu>)}>

                    <Button type="secondary">
                      Acties <DownOutlined />
                    </Button>

                  </Dropdown>
              </Col>
              <Col span={12}>
                <Input.Search
                  allowClear
                  placeholder="Zoeken..."
                  onSearch={(value) => { this.props.searchMediaLibraryAction(value, this.props.match.params.id).then((data) => { this.setState({ media: this.props.medialibrary }); }); }}
                  style={{ width: 200, float: 'right' } }
                  />
              </Col>
            </Row>
            {this.state.uploadToggle ? (
              <Row style={{marginBottom: 16}}>
                <Upload.Dragger
                  style={{ backgroundColor: '#fff'}}
                  accept="image/*"
                  multiple
                  showUploadList={false}
                  beforeUpload={this.handleMediaUpload}
                  >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Klik of sleep een afbeelding hierheen om te uploaden.</p>
                  <p className="ant-upload-hint">Maximale bestandsgrootte van upload: 8 MB.</p>
                </Upload.Dragger>

              </Row>
            ) : null}
            <Row>
              <Col span={24}>
                <CustomTable
                  size="middle"
                  rowKey="id"
                  rowSelection={rowSelection}
                  // onChange={() => console.log('test')}
                  columns={columns}
                  // loading={this.props.isFetching}
                  // dataSource={this.props.isFetching ? [] : this.props.medialibrary}
                  loading={this.state.isFetching}
                  dataSource={this.state.media}
                  />
              </Col>
            </Row>

            <Modal
              title={this.state.title}
              visible={this.state.modalVisible}
              footer={null}
              onCancel={() => this.setState({modalVisible: false})}
              >
              <img style={{width: '100%'}} src={this.state.image} />
            </Modal>

            <AddFromMediaModal
              customerId={this.props.customer.id}
              projectId={this.props.match.params.id}
              parent={this}
              activeTab={"1"}
              hideBrowser={'project'}
              onMediaSelection={(image) => {

                this.setState({visible: false});

                if(image.clone_id)
                {
                  this.props.copyImageToProject(image.clone_id, this.props.match.params.id).then(() => {

                      this.loadData();

                      showSuccess();
                  });
                }
              }}
              visible={this.state.visible}
              cancel={() => this.setState({visible: false})}
              />

        </div>

      </div>

  </div>
    );
}
}

//const MediaForm = Form.create({ name: 'medialibrary' })(Media);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.medialibrary.isFetching,
    isError : state.medialibrary.isError,
    customer : state.customer.customer,
    medialibrary : state.medialibrary.medialibrary,
    mediacategories : state.medialibrary.categories
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMediaLibraryAction : bindActionCreators(getMediaLibraryAction, dispatch),
    getMediaCategoriesAction : bindActionCreators(getMediaCategoriesAction, dispatch),
    deleteMediaLibraryAction : bindActionCreators(deleteMediaLibraryAction, dispatch),
    searchMediaLibraryAction : bindActionCreators(searchMediaLibraryAction, dispatch),
    addMediaFileAction : bindActionCreators(addMediaFileAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    copyImageToProject : bindActionCreators(copyImageToProject, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMedia);
