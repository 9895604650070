import React, { Component } from 'react';
import { DownOutlined, InteractionOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Modal,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  Dropdown,
  Menu,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDayScheduleAction, removeDayScheduleAction, duplicateDayScheduleAction, regenerateScheduleAction } from '../../../../../actions/scheduleActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { showSuccess } from '../../../../../utils/Notifications';
import Settings from '../Form/Settings';
import BlockedDates from '../Form/BlockedDates';
import DaySchedule from '../Form/DaySchedule';
import Calendar from '../Form/Calendar';
import ScheduleSteps from '../Steps/ScheduleSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';

class ScheduleDaySchedule extends Component
{
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
    };
  }

  componentDidMount()
  {
      // this.props.getScheduleAction(this.props.match.params.id).then(() => {
      //     this.setState({
      //         schedule: this.props.schedule,
      //         addresses: this.props.addresses
      //     });
      // });

      this.props.getDayScheduleAction(this.props.match.params.id).then(() => {

      });

      // this.props.getInformationAction(this.props.match.params.id).then(() => {
      //     this.setState({
      //         information: this.props.information
      //     });
      // });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showConfirm = () => {
      Modal.confirm({
          title: 'Weet je zeker dat je deze items wilt verwijderen?',
          content: 'Klik op JA om deze actie te bevestigen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                // console.log(this.state.selectedRowKeys);
                this.props.removeDayScheduleAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
                    resolve();
                    showSuccess('Succesvol verwijderd');
                    this.setState({selectedRowKeys: []});
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  showGenerateConfirm = () => {
      Modal.confirm({
          title: 'Weet je zeker dat je deze planning opnieuw wil genereren?',
          content: 'Handmatige wijzigingen worden verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                this.props.regenerateScheduleAction(this.props.match.params.id).then(() => {
                    resolve();
                    showSuccess('Planning succesvol gegenereerd');
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  duplicate = () => {
    this.props.duplicateDayScheduleAction(this.state.selectedRowKeys[0]).then(() => {
      this.setState({selectedRowKeys: []});
    });
  }

  render()
  {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const groupcolumns = [
        {
          title: 'Titel',
          dataIndex: 'name',
          render: (index, record) => (
            <span>
              <Link to={`/projects/${this.props.match.params.id}/modules/schedule/day-schedule/${record.id}/detail`}>
                {record.name}
              </Link>
            </span>
          )
        },
    ];

    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/calendar`)}>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleSteps current={2} {...this.props} />
          </Card>
        </Row>
        <Card>
        <Row gutter={24}>
          <Col span={24}>
            <Row className="mbm">
              <Col span={24}>
                <Link to={`/projects/${this.props.match.params.id}/modules/schedule/day-schedule/add`}>
                  <Button disabled={!this.props.HaveAbility('scheduling', 'write')} icon={<PlusOutlined />} type="new">Toevoegen</Button>
                </Link>
                <Button onClick={() => this.showGenerateConfirm()} style={{marginRight: 10}} disabled={!this.props.HaveAbility('scheduling', 'write')} icon={<InteractionOutlined />} >Regenereer planning</Button>
                <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('scheduling', 'write') ? false : true } overlay={(
                    <Menu>
                      <Menu.Item disabled={this.state.selectedRowKeys.length == 1 ? false : true} onClick={() => this.duplicate()} key="4">Dupliceren</Menu.Item>
                      <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('scheduling' ,'delete')} style={{ color: this.props.HaveAbility('scheduling', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary">
                    Acties <DownOutlined />
                </Button>
              </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomTable
                  rowSelection={rowSelection}
                  rowKey='id'
                  size="middle"
                  columns={groupcolumns}
                  dataSource={this.props.dayschedule}
                  loading={this.props.isFetching}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.schedule.isFetching,
    isError : state.schedule.isError,
    dayschedule : state.schedule.dayschedule,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDayScheduleAction : bindActionCreators(getDayScheduleAction, dispatch),
    removeDayScheduleAction : bindActionCreators(removeDayScheduleAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    duplicateDayScheduleAction : bindActionCreators(duplicateDayScheduleAction, dispatch),
    regenerateScheduleAction : bindActionCreators(regenerateScheduleAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDaySchedule);
