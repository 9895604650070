export default (state = {isError: false, isFetching: false, envelopes: [], envelope:{}}, action) => {
    switch (action.type) {
    case 'ENVELOPES_ACTION_FETCH':
    return {
        ...state,
        envelopes: [],
        isFetching: true,
        isError: false,
    }
    case 'ENVELOPE_ACTION_FETCH':
    return {
        ...state,
        envelope: {},
        isFetching: true,
        isError: false,
    }
    case 'ENVELOPES_ACTION_SUCCESS':
    return {
        ...state,
        envelopes: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ENVELOPE_ACTION_SUCCESS':
    return {
        ...state,
        envelope: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_ENVELOPES_ACTION_SUCCESS':
    return {
        ...state,
        envelopes: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'STORE_ENVELOPE_ACTION_SUCCESS':
    return {
        ...state,
        envelopes: [action.payload, ...state.envelopes],
        envelope: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_ENVELOPE_ACTION_ERROR':
    return {
        ...state,
        envelope: {},
        isFetching: false,
        isError: false,
    }
    case 'DELETE_ENVELOPES_ACTION_SUCCESS':
    return {
        ...state,
        envelopes: state.envelopes.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'ENVELOPES_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
