import React, { Component } from 'react'
import { ControlOutlined, HomeOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class ChatSidemenu extends Component {
    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 300}}
                defaultSelectedKeys={this.props.activeMenu}
            >
                    <Menu.Item key={"1"}>
                        <Link to={`/projects/${this.props.id}/modules/chat`}>
                            <HomeOutlined />
                            <span>Berichten</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={"2"}>
                        <Link to={`/projects/${this.props.id}/modules/chat/settings`}>
                            <ControlOutlined />
                            <span>Instellingen</span>
                        </Link>
                    </Menu.Item>
                </Menu>
        );
    }
}
