import api from '../utils/api'

export const addDaypart = () => dispatch => {
    dispatch({
      type: 'ADD_DAYPART_ACTION'
    })
}

export const getAppointmentsAction = (projectid) => dispatch => {

    dispatch({
        type: 'APPOINTMENTS_ACTION_FETCH'
    })

    return api('get','/appointments/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentAction = (appointmentid) => dispatch => {

    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('get','/appointment/'+appointmentid, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const addAppointmentAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointments/'+projectid, params, true).then((response) => {
        dispatch( {
            type: 'ADD_APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        } );
        return response;
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const resetAppointmentAction = () => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_RESET'
    })
}

export const patchAppointmentAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointment/'+appointmentid, params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const clustersAppointmentAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointment/'+appointmentid+'/clusters', params, true).then((response) => {
        dispatch({
            type: 'CLUSTERS_APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentScheduleAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('get','/appointment/' + appointmentid + '/schedule', params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentFreeSlotsAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_FREESLOTS_ACTION_FETCH'
    })
    return api('get','/appointment/' + appointmentid + '/free-slots', params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_FREESLOTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const applyAppointmentScheduleAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointment/'+appointmentid+'/schedule', params, true).then((response) => {
        dispatch({
            type: 'APPLY_APPOINTMENT_SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const updateAppointmentScheduleAction = (scheduleid, appointment_id, params) => dispatch => {
    dispatch({
        type: 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_FETCH'
    })

    return api('post','/appointment/schedule/' + scheduleid + (scheduleid == 'other' ? '/' + appointment_id : ''), params, true).then((response) => {
        dispatch({
            type: 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const removeAppointmentsAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENTS_ACTION_FETCH'
    })
    return api('post','/appointments/'+projectid+'/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const scheduleAddressesAction = (appointmentid) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })
    return api('get','/appointment/'+appointmentid+'/schedule-addresses', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_APPOINTMENT_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const searchScheduleAddressesAction = (appointmentid, search) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })
    return api('post','/appointment/'+appointmentid+'/schedule-addresses/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_APPOINTMENT_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const downloadAppointmentAction = (appointmentid) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })
    return api('get','/appointment/'+appointmentid+'/download-ics', null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_DOWNLOAD_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const requestExportAppointmentAction = (id) => dispatch => {

  dispatch({
    type: 'APPOINTMENT_ACTION_FETCH'
  })

  return api('get', 'appointment/'+id+'/request/export', null, true).then((response) => {
    dispatch({
      type: 'APPOINTMENT_EXPORT_REQUEST_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'APPOINTMENT_ACTION_ERROR',
    })
    return false;
  });
}
