import React, { Component } from 'react';
// import './ProjectsSettings.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProjectUsersAction, removeProjectUsersAction, searchProjectUsersAction } from '../../../actions/projectActions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import ProjectSidemenu from './ProjectSidemenu';
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Dropdown,
  Menu,
  Drawer,
  Modal,
  message,
  Tooltip,
} from 'antd';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;
const Option = Select.Option;

class ProjectsUsers extends Component {
    state = {
        //disabled: true,
        //edit: false,
        selectedRowKeys: []
    };

    // componentDidMount() {
    //     this.props.getProjectAction(this.props.match.params.id).then(() => {
    //         this.props.getCustomersAction().then(() => {
    //             this.props.getAccountsAction();
    //         });
    //     });
    // }
    //
    // toggle = () => {
    //     this.setState({
    //       disabled: !this.state.disabled,
    //     });
    // };
    //
    // handleSubmit = e => {
    //     e.preventDefault();
    //
    //     if (this.state.edit) {
    //         this.props.form.validateFields((err, values) => {
    //         if (!err) {
    //             this.setState({edit:!this.state.edit});
    //             this.props.patchProjectAction(this.props.match.params.id, values);
    //         }
    //         });
    //     } else {
    //         this.setState({edit:!this.state.edit});
    //     }
    // };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    componentDidMount() {
      this.props.getProjectUsersAction(this.props.match.params.id).then(() => {
        // console.log(this)
      });
    }

    showConfirm() {
      showMultiDelete((multidelete) => {
        if(multidelete) {
          this.props.removeProjectUsersAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
            this.setState({selectedRowKeys: []})
            showSuccess('Succesvol verwijderd')
          });
        }
      });
    }

    onSearch(value) {
      this.props.searchProjectUsersAction(this.props.match.params.id, {'search': value});
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const columns = [{
          title: 'E-mail',
          key: 'email',
          width: 400,
          //dataIndex: ['first_name','affix','last_name'],
          render: (text, record) => (
            <Link to={`/projects/${this.props.match.params.id}/user/${record.id}`}>
              {record.email}
              { record.different_rights ?
                <Tooltip className="mhs" title='Let op: deze gebruiker heeft afwijkende rechten ingesteld.'>
                  <ExclamationCircleOutlined style={{ color: "rgb(250, 173, 20)" }} />
                </Tooltip> : null
              }
            </Link>
          )
        },{
          title: 'Naam',
          key: 'name',
          //dataIndex: ['first_name','affix','last_name'],
          render: (text, record) => (
            <span>{record.first_name} {record.affix} {record.last_name}</span>
          )
        }];

        const { selectedRowKeys } = this.state;

        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          hideDefaultSelections: true,
        };

        return (
          <div>
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <ProjectSidemenu id={this.props.match.params.id} activeMenu={["4"]} {...this.props}/>

                <div layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
                    <Row className="mbm">
                      <Col span={12}>
                        <Link to={`/projects/${this.props.match.params.id}/user/add`}>
                          <Button disabled={!this.props.HaveAbility('project-users', 'write')} type="new" icon={<PlusOutlined />}>Toevoegen</Button>
                        </Link>

                        <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('project-users', 'write') ? false : true } overlay={(
                            <Menu>
                              <Menu.Item disabled={!this.props.HaveAbility('project-users', 'delete')} onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                            </Menu>
                          )}>
                          <Button type="secondary">
                            Acties <DownOutlined />
                          </Button>
                        </Dropdown>

                      </Col>
                      <Col span={12}>
                          <Input.Search
                            allowClear
                            placeholder="Zoeken..."
                            onSearch={value => this.onSearch(value)}
                            style={{ width: 200, float: 'right' }}
                          />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <CustomTable
                          rowKey='id'
                          size="middle"
                          rowSelection={rowSelection}
                          columns={columns}
                          dataSource={this.props.users}
                          loading={this.props.isFetching}
                        />
                      </Col>
                    </Row>
                </div>

            </div>
          </div>
        );
    }
}


const ProjectsUsersForm = Form.create({ name: 'projects_users' })(ProjectsUsers);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.project.isFetching,
        isError : state.project.isError,
        users: state.project.users,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectUsersAction : bindActionCreators(getProjectUsersAction, dispatch),
        removeProjectUsersAction : bindActionCreators(removeProjectUsersAction, dispatch),
        searchProjectUsersAction : bindActionCreators(searchProjectUsersAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsUsersForm);
