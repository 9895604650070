import React from 'react'
import { Steps } from 'antd';

class SupportBaseReminderSteps extends React.Component {

  onChange = current => {

    if(this.props.validate && this.props.current < current)
    {
        if(!this.props.validator())
        {
            return;
        }
    }

    if(current == 0) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/settings/${this.props.match.params.reminder_id}`);
    }
    if(current == 1) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/letter/${this.props.match.params.reminder_id}`);
    }
    if(current == 2) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/email/${this.props.match.params.reminder_id}`);
    }
    if(current == 3) {
        this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/check/${this.props.match.params.reminder_id}`);
    }
  }

  render () {
    return(
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Instellingen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Brief" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.reminder_id || this.props.match.params.reminder_id == 'add'} title="E-mail" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.reminder_id || this.props.match.params.reminder_id == 'add'} title="Controleren" />
      </Steps>
    );
  }
}

export default SupportBaseReminderSteps;
