import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Modal,
  Dropdown,
  Menu,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSurveyAction, getSurveyQuestionsAction, patchSurveyAction, updateSurveyQuestionsOrderAction, removeSurveyQuestionsAction, previewAction } from '../../../../../actions/surveysActions';
import PatchSurveyForm from '../Form/PatchSurvey';
import AddSurveyQuestionForm from '../Form/AddSurvey';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import update from 'immutability-helper';
import SurveySteps from '../Steps/SurveySteps';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { v1 as uuidv1 } from 'uuid';
import FormBuilder from '../../../../../components/FormBuilder/FormBuilder'
import _ from 'underscore';
// import { HolderOutlined } from "@ant-design/icons";
// import { DndContext } from "@dnd-kit/core";
// import {
//   arrayMove,
//   SortableContext,
//   useSortable,
//   verticalListSortingStrategy
// } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";

// const DraggableRow = ({ children, ...props }) => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     setActivatorNodeRef,
//     transform,
//     transition,
//     isDragging
//   } = useSortable({
//     id: props["data-row-key"]
//   });
//
//   const style = {
//     ...props.style,
//     transform: CSS.Transform.toString(
//       transform && {
//         ...transform,
//         scaleY: 1
//       }
//     )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
//     transition,
//     ...(isDragging
//       ? {
//           position: "relative",
//           zIndex: 9999
//         }
//       : {})
//   };
//
//   return (
//     <tr {...props} ref={setNodeRef} style={style} {...attributes}>
//       {children.map && children.map((child) => {
//         if (child.key === "sort") {
//           return cloneElement(child, {
//             children: (
//               <HolderOutlined
//                 ref={setActivatorNodeRef}
//                 style={{
//                   touchAction: "none",
//                   cursor: "pointer"
//                 }}
//                 {...listeners}
//               />
//             )
//           });
//         }
//         return child;
//       })}
//     </tr>
//   );
// };

class SurveyQuestionsV2 extends Component {

  form_data = null;

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      loading: true,
    };
  }

  componentDidMount() {

    // this.props.getSurveyQuestionsAction(this.props.match.params.surveyid).then(() => {
    //   this.setState({data: this.props.surveyquestions})
    // });

    if(this.props.match.params.surveyid) {

      this.props.getSurveyAction(this.props.match.params.surveyid).then(() => {

        if(this.IsJsonString(this.props.survey.form)){

          var json_data = JSON.parse(this.props.survey.form);

          this.refs.FormBuilder.parse(json_data);

          this.setState({formData: json_data}, () => {

              this.setState({ loading: false });
          });
        }
      });
    } else {

      // set empty section for first boot
      this.refs.FormBuilder.parse([{'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []}]);

      this.setState({ loading: false });
    }
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {
    if(this.props.HaveAbility('survey' ,'write')) {

      this.props.form.validateFields((err, values) => {

        if(!err){

          try
          {
              values.form = JSON.stringify(this.state.formData);

              this.props.patchSurveyAction(this.props.match.params.surveyid, values).then(() => {

                  if(redirect === true)
                  {
                      this.props.history.push('/projects/' + this.props.match.params.id + '/modules/enquete/' + this.props.survey.id + '/addresses');
                  }
              });

              showSuccess();
          }
          catch(e){ /*alert('Error bij het opslaan');*/ console.log('err:', e); }
        }
      });
    }
  }

  preview()
  {
      this.props.previewAction(this.props.match.params.surveyid);
  }

  render() {

    // const { survey } = this.state;

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Link to={`/projects/${this.props.match.params.id}/modules/enquete/${this.props.match.params.surveyid}/` + (this.props.project.is_public == false ? 'addresses' : 'results-v2')}>
                <Button disabled={this.state.loading || !this.props.HaveAbility('survey' ,'write')} type="new">Opslaan en volgende</Button>
              </Link>
              <Link to={`/projects/${this.props.match.params.id}/modules/enquete`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
          <Col span={12} align={'right'}>
              <Button onClick={(event) => { this.preview(); }}>Voorbeeld</Button>
          </Col>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <SurveySteps version={this.props.survey ? this.props.survey.version : 2} current={1} {...this.props}/>
            </Card>
          </Col>
        </Row>

        <Row gutter={24} className="mbm" style={{display: (this.props.match.params.surveyid ? 'block' : 'none')}}>
          <Col span={24}>
            <FormBuilder
              onChangeForm={(data) => this.setState({formData: data}, () => this.handleSubmit())}
              ref={'FormBuilder'}
              formId={this.props.match.params.surveyid}
              enabledElements={['s_select', 's_title', 's_textinput', 's_textareainput', /*'s_numberinput'*/, 's_smileyinput', /*'timeinput'*/, /*'dateinput'*/, /*'signatureinput',*/ /*'contactinput'*/, 's_photoinput', /*'drawinginput'*/]}
              sections={1}
              disabled={!this.props.HaveAbility('survey' ,'write')}
              />
          </Col>
        </Row>

      </div>
    );
  }
}

const SurveyQuestionV2Form = Form.create({ name: 'add_survey_form' })(SurveyQuestionsV2);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.survey.isFetching,
    isError : state.survey.isError,
    survey: state.survey.survey,
    //surveyquestions: state.survey.surveyquestions,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
    // getSurveyQuestionsAction : bindActionCreators(getSurveyQuestionsAction, dispatch),
    patchSurveyAction : bindActionCreators(patchSurveyAction, dispatch),
    // updateSurveyQuestionsOrderAction : bindActionCreators(updateSurveyQuestionsOrderAction, dispatch),
    // removeSurveyQuestionsAction : bindActionCreators(removeSurveyQuestionsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewAction : bindActionCreators(previewAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionV2Form);
