import React, { Component } from 'react';
import { Tag, Row, Col, Input } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAddressesAction, shareAddressesAction, searchAddressesAction } from '../../actions/addressesActions';
import { getGroupsAction } from '../../actions/groupsActions';
import CustomTable from '../CustomTable/CustomTable';
import _ from 'underscore';

class SelectAddresses extends Component {


  constructor(props) {
    super(props);

    const { childRef } = this.props;

    if(childRef)
    {
        childRef(this);
    }

    this.state = {
      selectedRowKeys: props.initialAddresses || [],

      filteredInfo: null,

      disabledIds: []
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log("RENDER SelectAddresses", nextProps.initialAddresses, this.state.selectedRowKeys);
  //
  //   if (_.isEmpty(_.difference(nextProps.initialAddresses, this.state.selectedRowKeys))) {
  //     this.setState({ selectedRowKeys: nextProps.initialAddresses });
  //     //this.setState({ selectedRowKeys: nextProps.initialAddresses });
  //
  //   }
  // }


  componentDidMount() {

    if(this.props.disabledIds)
    {
      this.setState({ disabledIds: this.props.disabledIds });
    }

    this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => {
      this.props.getGroupsAction({'projectId': this.props.match.params.id});
    });

  }

  share() {
    return this.props.shareAddressesAction(this.props.match.params.id, {'object_type':this.props.objectType, 'object_id':this.props.objectId, 'address_ids': this.state.selectedRowKeys});
  }

  onSelectChange = (selectedRowKeys) => {

    var allSelectedAddresses = this.state.selectedRowKeys;
    var addAddresses = [];
    var removeAddresses = [];
    var newAddressesSelection = [];

    this.props.address.map((record) => {

        if(!selectedRowKeys.includes(record.id))
        {
            if(!this.state.disabledIds || !this.state.disabledIds.includes || (this.state.disabledIds && this.state.disabledIds.includes && !this.state.disabledIds.includes(record.id)))
            {
                removeAddresses.push(record.id);
            }
        }
        else if(!allSelectedAddresses.includes(record.id))
        {
            newAddressesSelection.push(record.id);
        }
    });

    //
    allSelectedAddresses.map((id) => {

        if(removeAddresses.includes(id))
        {
            // do nothing
        }
        // else if(addAddresses.includes(id) && !newAddressesSelection.includes(id))
        // {
        //     newAddressesSelection.push(id);
        // }
        else if(!newAddressesSelection.includes(id))
        {
            newAddressesSelection.push(id);
        }
    });

    //
    this.setState({ selectedRowKeys: newAddressesSelection });

    //
    this.props.onAddressSelection(newAddressesSelection);
  }

  onSearch(value) {
    // this.setState({
    //   selectedRowKeys: [],
    // });

    this.props.searchAddressesAction(this.props.match.params.id, {'search': value});
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  render() {

    const { selectedRowKeys } = this.state;

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              // this.setState({
              //   selectedRowKeys: this.props.address.map(value => value.id),
              // });

              this.onSelectChange(this.props.address.map(value => value.id));
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              // this.setState({
              //   selectedRowKeys: [], // 0...45
              // });
              this.onSelectChange([]);
            },
          },
        ],
        getCheckboxProps: (record) => ({
            disabled: this.state.disabledIds.includes(record.id)
        }),
    };

    const columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
    }, {
      title: 'Postcode',
      dataIndex: 'zipcode',
      sorter: (a, b) => { return a.zipcode.localeCompare(b.zipcode)},
    },{
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => { return a.city.localeCompare(b.city)},
    }, {
        title: 'Groep',
        dataIndex: 'groups',
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
          record.groups && record.groups.map((v) => (
              <Tag color={v.color} key={v.id}>{v.name}</Tag>
        ))
    }];

    return (
      <div>
        <Row className="mbm">
          <Col span={12} style={{ height: 32, padding: 5 }}>
            <div>{ this.state.selectedRowKeys.length } / { this.props.address.length } adressen geselecteerd</div>
          </Col>
          <Col span={12}>
            <Input.Search
              placeholder="Zoeken..."
              allowClear
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>
        <CustomTable
          rowKey='id'
          rowSelection={rowSelection}
          columns={columns}
          dataSource={this.props.address}
          loading={this.props.isFetching}
          onChange={(pagination, filters, sorter, extra) => { this.handleTableChange(pagination, filters, sorter); }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    address : state.address.addresses,
    groups : state.group.groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
    searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAddresses);
