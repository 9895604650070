import React, { Component } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Upload,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLibraryCategoriesAction, deleteLibraryCategoriesAction } from '../../actions/librariesActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import LibrarySideMenu from './LibrarySideMenu';
import LibraryMenu from './Menu/LibraryMenu';
import moment from 'moment';

class LibraryCategories extends Component {

  state = {
    selectedRowKeys: [],
  };

  constructor(props)
  {
    super(props);
  }

  componentDidMount()
  {
      this.loadData();
  }

  loadData()
  {
      this.props.getLibraryCategoriesAction('text');
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  showConfirm = () => {

    showMultiDelete((multidelete) => {

      if(multidelete) {

        this.props.deleteLibraryCategoriesAction('text', {'ids': this.state.selectedRowKeys}).then(() => {

          this.setState({selectedRowKeys: []});

          showSuccess('Succesvol verwijderd');

          this.loadData();
        });
      }
    });
  }

  render()
  {
      const rowSelection = {
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      };

      const columns = [{
        title: 'Naam',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: (text, record) => (
          <span>
            <Link to={`/library/category/${record.id}`}>
              {text}
            </Link>
          </span>
        )
      }];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Losse teksten', 'link' : '/library'}, {'label': 'Categorieën'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='1' />

            <div style={{padding: 0, display: 'flex', width: '100%'}}>

                <LibraryMenu activeMenu='2' />

                <div style={{padding: 24, width: '100%'}} colon={true}>

                  <Row className="mbm">
                      <Col span={24} >
                          <Link to={`/library/category/add`}>
                              <Button disabled={!this.props.HaveAbility('library', 'write')} type="new">
                                  <PlusOutlined /> Toevoegen
                              </Button>
                          </Link>

                          <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('library', 'delete') ? false : true } overlay={(
                              <Menu>
                                  <Menu.Item disabled={!this.props.HaveAbility('address', 'delete')} onClick={this.showConfirm} key="1" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                              </Menu>
                              )}
                              >
                              <Button type="secondary">
                                  Acties <DownOutlined />
                              </Button>
                          </Dropdown>
                      </Col>
                  </Row>
                  <Row>
                      <Col span={24}>
                          <CustomTable
                              rowKey='id'
                              size="middle"
                              rowSelection={rowSelection}
                              columns={columns}
                              dataSource={this.props.isFetching ? [] : this.props.categories}
                              loading={this.props.isFetching}
                              />
                      </Col>
                  </Row>

              </div>

            </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.library.isFetching,
    isError : state.library.isError,
    categories : state.library.categories,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch),
    deleteLibraryCategoriesAction : bindActionCreators(deleteLibraryCategoriesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryCategories);
