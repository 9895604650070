export default (state = {isError: false, isFetching: false, notifications: [], notification:{}}, action) => {
    switch (action.type) {
    case 'PUSH_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'PUSHES_ACTION_SUCCESS':
    return {
        ...state,
        notifications: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'STORE_PUSH_ACTION_SUCCESS':
    return {
        ...state,
        notifications: [ action.payload, ...state.notifications ],
        isFetching: false,
        isError: false,
    }
    case 'PUSH_ACTION_SUCCESS':
    return {
        ...state,
        notification: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_PUSH_ACTION_SUCCESS':
    return {
        ...state,
        notifications: action.payload,
        isFetching: false,
        isError: false,
    }
    // case 'STORE_ENVELOPE_ACTION_SUCCESS':
    // return {
    //     ...state,
    //     envelopes: [action.payload, ...state.envelopes],
    //     envelope: action.payload,
    //     isFetching: false,
    //     isError: false,
    // }
    case 'RESET_PUSH_ACTION_ERROR':
    return {
        ...state,
        notification: {},
        isFetching: false,
        isError: false,
    }
    case 'DELETE_PUSH_ACTION_SUCCESS':
    return {
        ...state,
        notifications: state.notifications.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    // case 'ENVELOPES_ACTION_ERROR':
    // return {
    //     ...state,
    //     isFetching: false,
    //     isError: true,
    // }
    default:
        return state
    }
}
