import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Popconfirm,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Typography,
  Tooltip,
  Select,
  Slider,
  Switch,
  AutoComplete,
} from 'antd';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';

const ButtonGroup = Button.Group;

const Option = Select.Option;

class PatchInformation extends Component {

  add = () => {
    this.props.addIndicatorAction();
  }

  remove = (index) => {
    this.props.removeIndicatorAction(index);
  }

  getMenuOptions()
  {
      var options = [];

      this.props.information_menus && this.props.information_menus.map((menu, index) => {

          options.push({
              label: menu.name,
              value: menu.id
          });

          // options.push(menu.name);
      });

      return options;
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
        <Row gutter={24}>
          <Col span={18}>
            <Card>
              <Form.Item required hasFeedback label="Titel">
                {getFieldDecorator('title', {
                  initialValue: this.props.information && this.props.information.title,
                  rules: [{ required: true, message: 'Vul een titel in' }],
                })(
                  <Input />
                )}
              </Form.Item>

              <Row gutter={24}>
                <Col span={24}>
                  <Typography.Title level={4}>Indicatoren</Typography.Title>
                </Col>
              </Row>
                { this.props.indicators && this.props.indicators.map((element, index) => {
                  return (
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item>
                          { getFieldDecorator(`indicator_values[${index}]`, {
                            initialValue: element.value,
                          })(
                            <Slider tipFormatter={(percentage) => {
                              var color = '#DC2626';
                              if(percentage <= 25){ color = '#10B981'; }
                              if(percentage > 25 && percentage <=  50){ color = '#FCD34D'; }
                              if(percentage > 50 && percentage < 75){ color = '#F59E0B'; }
                                return (
                                  <div style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <span>{percentage}%</span><span style={{marginTop: 4, float: 'left', display: 'flex', background: color, width:14, height: 14, borderRadius: 7, marginRight: 5}}></span>
                                  </div>
                                );
                              }} />
                          ) }
                        </Form.Item>

                      </Col>
                      <Col span={6}>
                        <Form.Item>
                          { getFieldDecorator(`indicator_names[${index}]`, {
                            initialValue: element.name,
                            rules: [{ required: true, message: 'Vul een omschrijving in'}],
                          })(
                            <Input placeholder='Omschrijving indicator' />
                          ) }
                        </Form.Item>

                      </Col>
                      <Col span={2}>
                        <Form.Item>
                          <Popconfirm
                            title="Weet je het zeker?"
                            onConfirm={() => this.remove(index)}
                            icon={<QuestionCircleOutlined style={{ color: '#f5222d' }} />}
                            onCancel={() => {}}
                            okText="Ja"
                            cancelText="Nee"
                          >
                            <Button type={'link'} style={{ width: '100%', color: '#f5222d' }}>
                              Verwijderen
                            </Button>
                          </Popconfirm>
                          </Form.Item>
                        </Col>
                      </Row>
                  );

                  })}
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button type="dashed" onClick={this.add} style={{ }}>
                        <PlusOutlined />
                        Indicator toevoegen
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>

              <Form.Item required label="Bericht">
                { getFieldDecorator('content', {
                  initialValue: this.props.information && this.props.information.content,
                  valuePropName: 'value',
                  trigger: 'onEditorChange',
                  rules: [{ required: true, message: 'Vul een bericht in' }],
                })(
                  <CustomEditor placeholders={'info'} project_id={this.props.match.params.id} />
                ) }
              </Form.Item>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Form.Item required hasFeedback label="Weergeven onder categorie">
                { /*getFieldDecorator('menuitem_name', {
                  initialValue: this.props.information && this.props.information.menuitem_name ? this.props.information.menuitem_name : '',
                  validateTrigger: ["onChange"],
                  rules: [{ required: true, message: 'Geef een categorie op'}],
                })(
                  <AutoComplete
                    placeholder="Geef een categorie op"
                    dataSource={this.getMenuOptions()}
                    filterOption={(inputValue, option) =>
                      option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                )*/ }
                {getFieldDecorator('menuitem_name', {
                    initialValue: this.props.information && this.props.information.menuitem_name ? this.props.information.menuitem_name : 'Ongecategoriseerd',
                    validateTrigger: ["onChange"],
                    rules: [{ required: true, message: 'Geef een categorie op'}],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Geef een categorie op"

                  >
                  {this.getMenuOptions().length < 1 ? <Select.Option key={'Ongecategoriseerd'}>Ongecategoriseerd</Select.Option> : null}
                  {this.getMenuOptions().map((v, k) => (
                      <Select.Option key={String(v.label)}>{v.label}</Select.Option>
                  ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Openbaar">
                  {getFieldDecorator('is_public', {
                      valuePropName: 'checked',
                      initialValue: this.props.information.is_public ? true : false
                  })(
                  <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                  />
                  )}
              </Form.Item>

              <Form.Item label="Toegang tot woning vereist">
                  {getFieldDecorator('access_to_home_required', {
                      valuePropName: 'checked',
                      initialValue: this.props.information && this.props.information.access_to_home_required ? true : false
                  })(
                      <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                      />
                  )}
              </Form.Item>

              <Form.Item label="Partners">
                  {getFieldDecorator('partners', {
                      initialValue: this.props.information && this.props.information.partners && this.props.information.partners.map((v) => String(v ? v.partner_id : ''))
                  })(
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Selecteer een partner"

                    >
                    {this.props.partners.map((v, k) => (
                        <Select.Option key={String(v.id)}>{v.name}</Select.Option>
                    ))}
                    </Select>
                  )}
              </Form.Item>

              <Form.Item label="Afbeelding">
                {getFieldDecorator('image_id', {
                  initialValue: this.props.information && this.props.information.image_id,
                })(
                  <AddFromMedia
                    projectId={this.props.match.params.id}
                    initialMediaId={this.props.information && this.props.information.image_id}
                    initialMediaUrl={this.props.information && this.props.information.image_url}
                    onMediaSelection={(item) => this.props.form.setFieldsValue({'image_id': item.id})}
                    onMediaRemove={() => this.props.form.setFieldsValue({'image_id': ''})}
                    />
                )}
              </Form.Item>

              <Form.Item label="Icoon">
                {getFieldDecorator('icons', {
                  initialValue: this.props.information && this.props.information.icons && this.props.information.icons.map(icon => String(icon))
                })(
                  <Select mode="multiple" placeholder="Selecteer een icoon">
                    <Option value="elektra">Elektra</Option>
                    <Option value="gas">Gas</Option>
                    <Option value="kou">Kou</Option>
                    <Option value="thuis_blijven">Thuis blijven</Option>
                    <Option value="tocht">Tocht</Option>
                    <Option value="toegang_noodzakelijk">Toegang noodzakelijk</Option>
                    <Option value="verwarming">Verwarming</Option>
                    <Option value="voorbereiden">Voorbereiden</Option>
                    <Option value="warm_water">Warm water</Option>
                    <Option value="water">Water</Option>
                  </Select>
                )}
              </Form.Item>
            </Card>
          </Col>
          </Row>
          </Form>
    );
      }
    }

export default Form.create({ name: 'patch_partner_form' })(PatchInformation);
