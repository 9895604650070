export default (state = {
    isError: false,
    isFetching: false,
    forms: [],
    form: {
        is_public: null
    },
    customer_forms: [],
    menus: [],
    menu: {},
    addresses: [],
    // customer_form: {}
}, action) => {

    switch (action.type) {
    case 'FORMS_ACTION_FETCH':
    return {
        ...state,
        customer_forms: [],
        forms: [],
        isFetching: true,
        isError: false,
    }
    case 'FORM_ACTION_FETCH':
    return {
        ...state,
        form: {},
        isFetching: true,
        isError: false,
    }
    case 'FORMS_ACTION_SUCCESS':
    return {
        ...state,
        forms: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'FORMS_STATUS_ACTION_FETCH':
    return {
        ...state,
        addresses: [],
        isFetching: false,
        isError: false,
    }
    case 'FORMS_STATUS_ACTION_SUCCESS':
    return {
        ...state,
        addresses: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        customer_forms: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'FORM_ACTION_SUCCESS':
    return {
        ...state,
        form: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'FORMS_RESULTS_ACTION_SUCCESS':
    return {
        ...state,
        forms_results: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_FORM_ACTION_SUCCESS':
    return {
        ...state,
        form: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'FORM_ACTION_RESET':
    return {
        ...state,
        forms: [],
        form: {
            is_public: null
        },
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_CUSTOMER_FORMS_ACTION_SUCCESS':
    return {
        ...state,
        customer_forms: state.customer_forms.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'FORM_MENU_ITEMS_ACTION_SUCCESS':
    return {
        ...state,
        menus: action.payload,
        isFetching: false,
        isError: false
    }
    case 'FORM_MENU_ITEM_ACTION_SUCCESS':
    return {
        ...state,
        menu: action.payload,
        isFetching: false,
        isError: false
    }
    default:
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
  }
}
