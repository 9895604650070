import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder'

import { v1 as uuidv1 } from 'uuid'
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormAction, addCustomerDeliveryFormAction, updateDeliveryFormAction, resetDeliveryFormAction } from '../../../actions/deliverActions';

import PageHeader from '../../../components/PageHeader/PageHeader';
import DeliverySteps from '../Steps/DeliverySteps';

import LibrarySideMenu from '../LibrarySideMenu';
import { HaveAbility } from '../../../utils/RolesAndAbilities';


class DeliverySettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      loading: true,
    };
  }

  componentDidMount()
  {
    this.props.resetDeliveryFormAction();

    //if(this.props.match.params.id) {

      this.props.getDeliveryFormAction(this.props.match.params.id).then(() => {

          this.setState({ require_to_sign_form: this.props.delivery_form.require_to_sign_form ? true : false });
          this.setState({ signature_not_required: this.props.delivery_form.signature_not_required ? true : false });
          this.setState({ require_to_sign_form_legally: this.props.delivery_form.require_to_sign_form_legally ? true : false });
          this.setState({ nr_of_signatures: this.props.delivery_form.nr_of_signatures });

          try
          {
              var signature_description = JSON.parse(this.props.delivery_form.signature_description);

              for(let nr = 1; nr <= this.props.delivery_form.nr_of_signatures; nr++)
              {
                  this.setState({ ['signature_'+nr+'_description']: signature_description[nr] });
              }
          }
          catch(e){}

        // if(this.IsJsonString(this.props.form.form)){
        //
        //   this.setState({formData: JSON.parse(this.props.form.form)}, () => {
        //
        //       this.refs.FormBuilder.parse(JSON.parse(this.props.form.form));
        //
        //       this.setState({ loading: false });
        //   })
        // }

        this.setState({ loading: false });
      });
    //} else {
      // set empty section for first boot
      // this.refs.FormBuilder.parse([{'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []}]);

    //  this.setState({ loading: false });
    //}
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // handleSubmit = (e, values, redirect) => {
  //
  //   this.props.form.validateFields((err, values) => {
  //
  //     if (err) {
  //       return showError('Niet alle velden zijn ingevuld.');
  //     }
  //
  //     values.form = JSON.stringify(this.state.formData);
  //
  //     if (values.tags) {
  //       values.tags = values.tags.toString();
  //     }
  //
  //     if (this.props.match.params.id) {
  //       this.props.updateDeliveryFormAction(this.props.match.params.id, values).then(() => {
  //         if (redirect) {
  //           this.props.history.push(`/library/delivery`);
  //         }
  //       });
  //     }
  //     else {
  //       this.props.addCustomerDeliveryFormAction(values).then(() => {
  //         this.props.history.push('/library/delivery/' + this.props.form.id + '/detail');
  //       });
  //     }
  //
  //     showSuccess();
  //   });
  // }

  handleSubmit = () => {

      this.props.form.validateFields((err, values) => {

        values.nr_of_signatures = isNaN(parseInt(this.state.nr_of_signatures)) ? 1 : parseInt(this.state.nr_of_signatures);

        if(values.require_to_sign_form && values.nr_of_signatures < 1)
        {
            values.nr_of_signatures = 1;
        }

        values.signature_not_required = values.signature_not_required ? false : true;

        if(values.signature_not_required)
        {
            values.require_to_sign_form_legally = false;
            values.use_sms_authentication_for_signing = false;
        }

        values.deliveries_combined = values.deliveries_combined ? false : true;

        if (!err){

          //if(this.props.match.params.id && this.props.match.params.id != 'add') {

            this.props.updateDeliveryFormAction(this.props.match.params.id, values).then(() => {

                // if(this.props.delivery_form.locked)
                // {
                //     this.props.history.push('/library/delivery/' + this.props.match.params.id + '/projects');
                // }
                // else
                // {
                    this.props.history.push('/library/delivery');
                // }

              //this.props.history.push('/projects/' + this.props.match.params.id + '/deliver/delivery-form/' + this.props.delivery_form.id + '/deliveries');
            });
          // }
          // else {
          //
          //   this.props.addCustomerDeliveryFormAction(values).then(() => {
          //
          //     //this.props.history.push('/projects/' + this.props.match.params.id + '/deliver/delivery-form/' + this.props.delivery_form.id + '/deliveries');
          //   });
          // }

          showSuccess();
        }
      });
  }

  // preview()
  // {
  //     this.props.previewAction(this.props.match.params.id);
  // }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Opname', 'link': '#'}, {'label': 'Formulieren'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='20' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit()}>
                        { /*this.props.delivery_form.locked ? 'Opslaan en verder' : 'Opslaan'*/ }
                        Opslaan
                      </Button>
                      <Link to={`/library/delivery`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col span={12} align={'right'}>
                      {/*<Button onClick={(event) => { this.preview(); }}>Voorbeeld</Button>*/}
                  </Col>
                </Row>

                {/* steps */}
                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliverySteps current={1} locked={this.props.delivery_form.locked} {...this.props}/>
                    </Card>
                  </Col>
                </Row>

                { this.state.loading ? null :
                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Formulier per vooroplevering
                                <Tooltip className="mhs" title='Geef op of per gekoppelde vooroplevering een apart in te vullen formulier getoond moet worden.'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('deliveries_combined', {
                                  initialValue: this.props.delivery_form.deliveries_combined ? false : true,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item className="mbn" label={'Tonen in dossier voor bewoner'}>
                              {getFieldDecorator('add_to_dossier', {
                                  initialValue: this.props.delivery_form.add_to_dossier ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch disabled={this.state.locked} />
                              )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>

                        <Col span={this.state.require_to_sign_form ? 12 : 24}>
                            <Form.Item className="mbn" label="Digitaal ondertekenen">
                              {getFieldDecorator('require_to_sign_form', {
                                  initialValue: this.props.delivery_form.require_to_sign_form ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form: checked }) }
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col>

                        { this.state.require_to_sign_form ?
                        <Col span={this.state.require_to_sign_form ? 12 : 24}>
                            <Form.Item className="mbn" label="Handtekening verplicht">
                              {getFieldDecorator('signature_not_required', {
                                  initialValue: this.props.delivery_form.signature_not_required ? false : true,
                                  valuePropName: 'checked',
                                  onChange: (checked) => {
                                      this.setState({ signature_not_required: checked ? false : true });
                                  }
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form ?
                        <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label="Hoeveel handtekeningen">
                            <Input

                                onChange={(event) => {

                                    var value = event.target.value;

                                    value = isNaN(parseInt(value)) ? (!value ? '' : 1) : parseInt(value);

                                    var new_value = !value ? '' : (value > 10 ? '10' : (value < 1 ? '1' : value));

                                    this.setState({ nr_of_signatures: new_value });
                                }}
                                //defaultValue={this.state.nr_of_signatures}
                                value={this.state.nr_of_signatures}
                                style={{ maxWidth: 60 }}
                                />
                          </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && this.state.nr_of_signatures > 0 ? <Col span={24} style={{ padding: 0, margin: 0 }}>{[...Array(parseInt(this.state.nr_of_signatures))].map((i, s) => {

                        var nr = s + 1;

                        return <Col span={12}>
                          <Form.Item hasFeedback className="mbn" label={"Handtekening "+nr+" omschrijving"}>
                            {getFieldDecorator('signature_'+nr+'_description', {
                              initialValue: this.state['signature_'+nr+'_description'],
                              onChange: (value) => { this.setState({ ['signature_'+nr+'_description']: value }); },
                              rules: [{ required: false, message: 'Vul een omschrijving in. Dit kan later in de app nog worden aangepast per formulier.' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>
                        })}</Col> : null }

                        { this.state.require_to_sign_form && !this.state.signature_not_required ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                Rechtsgeldig ondertekenen
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per formulier is dit €1,00'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('require_to_sign_form_legally', {
                                  initialValue: this.props.delivery_form && this.props.delivery_form.require_to_sign_form_legally ? true : false,
                                  valuePropName: 'checked',
                                  onChange: (checked) => { this.setState({ require_to_sign_form_legally: checked }) }
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null }

                        { this.state.require_to_sign_form && !this.state.signature_not_required && this.state.require_to_sign_form_legally ?
                        <Col span={12}>
                            <Form.Item className="mbn" label={(
                              <span>
                                SMS authenticatie gebruiken
                                <Tooltip className="mhs" title='Aan deze optie zijn extra kosten verbonden. Per verstuurde SMS is dit €0,25'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                            )}>
                              {getFieldDecorator('use_sms_authentication_for_signing', {
                                  initialValue: this.props.delivery_form && this.props.delivery_form.use_sms_authentication_for_signing ? true : false,
                                  valuePropName: 'checked'
                              })(
                                <Switch />
                              )}
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    {/*<Row gutter={24}>
                        <Col lg={{span:12}} xl={{span: 6}}>
                            <Form.Item label={
                                <span>
                                    Verloopdatum:
                                    <Tooltip className="mhs" title='Dit is de uiterlijke datum waarop het formulier ingevuld moet zijn.'>
                                      <Icon type="info-circle" theme="twoTone" />
                                    </Tooltip>
                                </span>}
                            >
                                { getFieldDecorator('due_date', {
                                  initialValue: this.state.due_date ? moment(this.state.due_date) : null,
                                  onChange: (value) => { this.setState({ due_date: value ? value.format('YYYY-MM-DD') : null }); }
                                })(
                                <DatePicker
                                  format={'DD/MM/YYYY'}

                                  />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>*/}

                </Form>}

            </div>

          </div>

      </div>
    );
  }
}


const DeliverySettingsForm = Form.create({ name: 'delivery_form' })(DeliverySettings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.deliver.isFetching,
    isError : state.deliver.isError,
    delivery_form : state.deliver.form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryFormAction : bindActionCreators(getDeliveryFormAction, dispatch),
    addCustomerDeliveryFormAction : bindActionCreators(addCustomerDeliveryFormAction, dispatch),
    updateDeliveryFormAction : bindActionCreators(updateDeliveryFormAction, dispatch),
    resetDeliveryFormAction : bindActionCreators(resetDeliveryFormAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    //previewAction: bindActionCreators(previewAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySettingsForm);
