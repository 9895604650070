import React, { Component } from 'react';
// import './News.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
// import  AddNewsForm from './Form/AddNews';
import { getNewsAction, removeNewsAction, searchNewsAction } from '../../../../actions/newsActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import CustomTable from '../../../../components/CustomTable/CustomTable';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Upload,
  Tag,
  Button,
  Drawer,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
  Search,
} from 'antd';

import moment from 'moment';
import 'moment/locale/nl';

class News extends Component {
  state = {
      drawerVisible: false,
      selectedRowKeys: [],
  };

  componentDidMount() {
    this.props.getNewsAction(this.props.match.params.id);
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.addNewsform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addNewsAction(this.props.match.params.id, values);
  //       this.addNewsform.props.form.resetFields();
  //     }
  //   });
  // }

  showConfirm = () => {
      Modal.confirm({
          title: 'Weet je zeker dat je deze items wilt verwijderen?',
          content: 'Klik op JA om deze actie te bevestigen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                // console.log(this.state.selectedRowKeys);
                this.props.removeNewsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {

                    resolve();

                    this.setState({selectedRowKeys: []});

                    this.props.getNewsAction(this.props.match.params.id);

                    showSuccess('Succesvol verwijderd');
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  onSearch(value) {
      this.props.searchNewsAction(this.props.match.params.id, {'search': value});
  }

  render() {

    const columns = [{
        title: 'Titel',
        dataIndex: 'title',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/modules/news/${record.id}/detail`}>
              {text}
            </Link>
          </span>
        )
    }, {
        title: 'Datum',
        dataIndex: 'date',
        render: (text, record) => {
          return (
            <span>
              { moment(text).format('DD/MM/YYYY') }
            </span>
          )
        },
    }, {
        title: '',
        dataIndex: 'is_published',
        render: (text, record) => (
          <span>
            <Tag color={text == 0 ? "blue" : "green" }>
              {text == 0 ? "Concept" : "Gepubliceerd" }
            </Tag>
          </span>
        ),
    }];
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>

              <Link to={`/projects/${this.props.match.params.id}/modules/news/add`}>
                <Button disabled={!this.props.HaveAbility('news', 'write')} type="new" icon={<PlusOutlined />} onClick={() => this.setState({drawerVisible: true})}>Toevoegen</Button>
              </Link>


              <Dropdown trigger={['click']} disabled={this.props.HaveAbility('news', 'write') && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                  <Menu>
                    <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('news' ,'delete')} style={{ color: this.props.HaveAbility('news', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary">
                  Acties <DownOutlined />
                </Button>
              </Dropdown>

          </Col>
          <Col span={12}>
              <Input.Search
                allowClear
                placeholder="Zoeken..."
                onSearch={value => this.onSearch(value)}
                style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.isFetching ? [] : this.props.news}
              loading={this.props.isFetching}
              />
          </Col>
        </Row>
      </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.news.isFetching,
      isError : state.news.isError,
      news: state.news.news,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getNewsAction : bindActionCreators(getNewsAction, dispatch),
      removeNewsAction : bindActionCreators(removeNewsAction, dispatch),
      searchNewsAction : bindActionCreators(searchNewsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
