import React, { Component } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNewsArticleAction, patchNewsAction, addNewsAction, resetNewsAction } from '../../../../../actions/newsActions';
import { getLibrariesAction } from '../../../../../actions/librariesActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import PatchNewsForm from '../Form/PatchNews';
import NewsSteps from '../Steps/NewsSteps';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import { showSuccess } from '../../../../../utils/Notifications';

class NewsAddresses extends Component {

  state = {
    selectedAddressIds: []
  }

  constructor(props) {
    super(props);
    // this.selectaddresses = React.createRef();
  }

  componentDidMount() {

    this.props.resetNewsAction();

    if(this.props.match.params.newsid) {
      this.props.getNewsArticleAction(this.props.match.params.newsid).then(() => {
      });
    }
  }

  patchNewsAddresses() {

    // this.selectaddresses.current.share();

    this.myref.share().then(() => {

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/news`);

        showSuccess();
    });

  }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
                <Button disabled={!this.props.HaveAbility('news', 'write')} type="new" onClick={() => this.patchNewsAddresses()}>Opslaan</Button>
              <Link to={`/projects/${this.props.match.params.id}/modules/news`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <NewsSteps current={1} {...this.props} />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {this.props.newsarticle.is_public === 0 ? (
              this.props.newsarticle.addresses && (
                <SelectAddresses {...this.props} /*forwardedRef={this.selectaddresses}*/ childRef={ref => this.myref = ref} initialAddresses={this.props.newsarticle.addresses} objectId={this.props.match.params.newsid} objectType="news_article" onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds})}/>
              )
            ) : (
              !this.props.isFetching ? (
                <Result
                  icon={<CheckCircleFilled style={{ color: '#52c41a' }} />}
                  title="De informatie staat ingesteld op openbaar, dus iedereen kan het zien."
                />
              ) : null
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.news.isFetching,
    isError : state.news.isError,
    newsarticle: state.news.newsarticle,
    library: state.library.libraries,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNewsArticleAction : bindActionCreators(getNewsArticleAction, dispatch),
    patchNewsAction : bindActionCreators(patchNewsAction, dispatch),
    getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
    addNewsAction : bindActionCreators(addNewsAction, dispatch),
    resetNewsAction : bindActionCreators(resetNewsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsAddresses);
