import React, { Component } from 'react';
import { DownOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Select,
  Checkbox,
  Switch,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLibrariesAction, searchLibrariesAction, storeLibraryAction, deleteLibrariesAction, getLibraryListAction, cloneLibraryItemAction, getLibraryCategoriesAction } from '../../actions/librariesActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';
import LibraryMenu from './Menu/LibraryMenu';

class Library extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      modalVisible: false,
      selectedId: [],
      list: [],

      filteredInfo: null,

      group_filter: [],
      group_filter_and: true,
    };
  }

  componentDidMount() {

    this.props.getLibraryCategoriesAction('text');

    this.props.getLibrariesAction();

    if(this.props.HaveAbility('library', 'write')){
      this.props.getLibraryListAction('text').then(() => {
        this.setState({ list: this.props.list });
      });
    }
  }

  handleSubmit = (e, values) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.storeLibraryAction(values).then(() => {
          showSuccess('Succesvol opgeslagen');

          this.setState({drawerVisible: false});
          this.props.form.resetFields();
        });
      }
    });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  cloneSelected = () => {
    this.props.cloneLibraryItemAction('text', this.state.selectedRowKeys[0]).then(() => {
      showSuccess('Succesvol gedupliceerd');
      this.props.getLibrariesAction();
      this.setState({selectedRowKeys: []})
    });
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteLibrariesAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({
              selectedRowKeys: [],
              modalVisible: false
          })
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  showDuplicate() {
      this.setState({ modalVisible: true });
  }

  duplicate = () => {
    this.props.cloneLibraryItemAction('text', this.state.selectedId).then(() => {
      this.setState({selectedRowKeys: []});
      this.setState({modalVisible: false});
      this.setState({selectedId: []});
      showSuccess('Succesvol gedupliceerd');
      this.props.getLibrariesAction();
    });
  }

  listItem = (element) => {
      return (
          <Select.OptGroup label={element.name}>
            {element.items.map((item) => (
                <Select.Option value={item.id}>{item.title}</Select.Option>
            ))}
          </Select.OptGroup>
      );
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = false;

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = false;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  removeItem(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  render() {

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Titel',
      dataIndex: 'title',
      sorter: (a, b) => { return a.title.localeCompare(b.title)},
      render: (text, record) => (
        <span>
          <Link to={`/library/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },
    {
        title: 'Categorieen',
        dataIndex: '',
        key: 'categories',
        width: 160,
        filterMultiple: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 0 }}>

              <div>
                  {this.props.categories && this.props.categories.map((obj) => {
                      return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                          <Checkbox
                              onChange={(e) => {
                                  var my_group_filter = this.state.group_filter;

                                  if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                  else{ this.removeItem(my_group_filter, obj.name); }

                                  this.setState({ group_filter: my_group_filter });

                                  setSelectedKeys(my_group_filter);
                              }}
                              checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                              >
                              {obj.name}
                          </Checkbox>
                      </div>
                  })}
              </div>

              <div style={{padding: 8, width: '100%', clear: 'both'}}>
                  <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                  <div style={{float: 'left', marginLeft: 8}}>filter</div>
              </div>

              <div style={{width: '100%', clear: 'both'}}>
                  <Button
                    type="link"
                    onClick={() => { confirm(); }}
                    style={{marginLeft: -8, marginRight: 8 }}
                    >OK</Button>

                  <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
              </div>

            </div>
        ),
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.categories && record.categories.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
        )
    }];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Losse teksten'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='1' />

            <div style={{padding: 0, display: 'flex', width: '100%'}}>

                <LibraryMenu activeMenu='1' />

                <div style={{padding: 24, width: '100%'}} colon={true}>

                    <Row className="mbm">
                      <Col span={12} >
                        {/*<Link to="/library/add">
                          <Button disabled={!this.props.HaveAbility('library' ,'write')} icon="plus" type="primary ant-btn-new">
                            Toevoegen
                          </Button>
                        </Link>*/}
                        <Dropdown disabled={!this.props.HaveAbility('library', 'write')} trigger={['click']} overlay={(
                            <Menu>
                              <Menu.Item key="1">
                                  <Link to="/library/add" disabled={!this.props.HaveAbility('library' ,'write')}>
                                      Nieuw
                                  </Link>
                              </Menu.Item>
                              <Menu.Item key="2" onClick={() => this.showDuplicate()} disabled={!this.props.HaveAbility('library' ,'write')}>
                                  Dupliceren
                              </Menu.Item>
                            </Menu>
                          )}>
                          <Button type="new" style={{marginLeft: 0}}>
                              Toevoegen <DownOutlined />
                          </Button>
                        </Dropdown>

                        <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('library', 'write') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                            <Menu>
                              <Menu.Item onClick={() => this.cloneSelected()} key="6" disabled={this.state.selectedRowKeys.length > 1}>Dupliceren</Menu.Item>
                              <Menu.Divider/>
                              <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('library' ,'delete')} style={{ color: this.props.HaveAbility('library', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                            </Menu>

                          )}>
                          <Button type="secondary">
                            Acties <DownOutlined />
                          </Button>
                        </Dropdown>

                      </Col>
                      <Col span={12}>
                        <Input.Search
                          allowClear
                          placeholder="Zoeken..."
                          onSearch={ value => this.props.searchLibrariesAction(value) }
                          style={{ width: 200, float: 'right' }}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CustomTable
                          size="middle"
                          rowKey="id"
                          rowSelection={rowSelection}
                          onChange={() => console.log('test')}
                          columns={columns}
                          loading={this.props.isFetching}
                          dataSource={this.props.isFetching ? [] : this.props.libraries}
                          />
                      </Col>
                    </Row>

                    <Drawer
                      title="Nieuw bibliotheek item"
                      width={720}
                      onClose={() => {
                        this.props.form.resetFields();
                        this.setState({ drawerVisible: false });
                      }
                    }
                    visible={this.state.drawerVisible}
                    >
                    <Form layout='vertical' onSubmit={this.handleSubmit}>

                      <Form.Item required label="Algemene titel">
                        {getFieldDecorator('title', {
                          initialValue: "",
                          rules: [{ required: true, message: 'Dit veld is verplicht' }],
                        })(
                          <Input placeholder="Titel" />
                        )}
                      </Form.Item>
                      <Button loading={this.props.isFetching} htmlType="submit" type="primary" icon={<SaveOutlined />}>Opslaan</Button>
                    </Form>

                  </Drawer>

                </div>
          </div>

        </div>

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: [] })}
          onOk={() => this.duplicate()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Losse tekst">
              <Select
                showSearch
                value={this.state.selectedId}
                placeholder={'Selecteer losse tekst'}
                optionFilterProp="children"
                onChange={(selectedId) => this.setState({selectedId})}
                mode='multiple'
                >
                {this.state.list.map((element) => this.listItem(element))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

    </div>
    );
}
}

const LibraryForm = Form.create({ name: 'horizontal_login' })(Library);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.library.isFetching,
    isError : state.library.isError,
    libraries : state.library.libraries,
    list : state.library.list,
    categories : state.library.categories
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
    storeLibraryAction : bindActionCreators(storeLibraryAction, dispatch),
    deleteLibrariesAction : bindActionCreators(deleteLibrariesAction, dispatch),
    searchLibrariesAction : bindActionCreators(searchLibrariesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    getLibraryListAction : bindActionCreators(getLibraryListAction, dispatch),
    cloneLibraryItemAction : bindActionCreators(cloneLibraryItemAction, dispatch),
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryForm);
