import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAccountRoleAction, addAccountRoleAction, patchAccountRoleAction, resetAccountRoleAction } from '../../actions/accountActions';
import { getCustomerAction } from '../../actions/customerActions';
import PageHeader from '../../components/PageHeader/PageHeader';
import CustomTable from '../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess, showExtraConfirm } from '../../utils/Notifications';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  message,
  Drawer,
  Select,
  Alert,
  Divider,
  Typography,
  Spin,
  Radio,
  Checkbox,
} from 'antd';
import { Link } from 'react-router-dom';
import AccountsSideMenu from './AccountsSideMenu';

const Option = Select.Option;
const { Title, Text } = Typography;

class AccountRole extends Component {
  state = {
      permissions: [],
      loading: true,
      projects: '',
  };

  componentDidMount() {

    this.setState({ loading: true });

    this.props.resetAccountRoleAction();

    this.props.getCustomerAction().then(() => {

        this.props.getAccountRoleAction(!this.props.match.params.id ? 'user' : this.props.match.params.id).then(() => {

            this.props.accountrole.rights.map((right) => {
                if(right.object == 'projects'){
                    this.setState({ projects: right.access });
                }
                else
                {
                    this.setState({ [right.object]: right.access });
                }
            });

            this.setState({ loading: false });
        });
    });
  }

  getInitialValue(field, default_access = 'read') {

    var access = default_access;

    if(this.props.accountrole && this.props.accountrole.rights) {
      this.props.accountrole.rights.map((element, index) => {
        if(element.object == field) {
          access = element.access;
        }
      });
    }

    if(field == 'project_role' && access != 'admin')
    {
        access = 'user';
    }

    return access;
  }

  setValues = () => {
    // element.object
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      if (!err) {
        if(this.props.match.params.id) {
          if(this.props.accountrole && this.props.accountrole.nr_of_users > 0) {
            return showExtraConfirm((confirm) => {
              if(confirm){
                this.props.patchAccountRoleAction(this.props.match.params.id, values).then(() => {
                  showSuccess();
                  this.props.history.push(`/account-roles`);
                });
              }
          }, 'Weet je het zeker?', 'Alle rechten die gewijzigd zijn worden doorgevoerd voor de accounts die aan deze rol gekoppeld zijn, met als uitzondering, dat afwijkende rechten op projectniveau van gebruiker(s) niet aangepast zullen worden.');
          }
          else
          {
            this.props.patchAccountRoleAction(this.props.match.params.id, values).then(() => {
              showSuccess();
              this.props.history.push(`/account-roles`);
            });
          }
        } else {
          this.props.addAccountRoleAction(values).then(() => {
            showSuccess();
            this.props.history.push(`/account-roles`);
          });
        }

        showSuccess();
      }
    });

  };

  selectAll(access)
  {
      this.props.customer && this.props.customer.user_right_modules && this.props.customer.user_right_modules.map((element, index) => {

         var new_access = access;

         if(element.object == 'address' && !access){ new_access = 'read'; }

         this.props.form.setFieldsValue({ [element.object]: new_access });

      });
  }

  render() {

    //
    const { getFieldDecorator } = this.props.form;

    //
    return (
      <div>
        <PageHeader path={[{'label': 'Rollen', 'link': '/account-roles'}, {'label': this.props.match.params.id && this.props.accountrole ? this.props.accountrole.name : 'Toevoegen'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <AccountsSideMenu activeMenu='2' />
            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12} >
                    <Button disabled={!this.props.HaveAbility('users' ,'write')} type="new" onClick={this.handleSubmit}>
                      Opslaan
                    </Button>
                    <Button onClick={() => this.props.history.push('/account-roles')} style={{marginRight: 10}}>Sluiten</Button>
                  </Col>
                </Row>

                { this.state.loading ? <Spin size="small" style={{marginLeft: 10}} /> :
                <Form layout={'vertical'} onSubmit={this.handleSubmit} colon={true}>
                  <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={"Naam"}>
                          {getFieldDecorator("name", {
                            initialValue: this.props.match.params.id && this.props.accountrole ? this.props.accountrole.name : '',
                            rules: [{ required: true, message: 'Vul een naam in'}],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                    </Col>
                  </Row>

                  { this.state.projects && this.state.projects != '' && (
                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item required label="Toegang tot projecten" hasFeedback>
                          {getFieldDecorator('project_role', {
                            initialValue: this.getInitialValue("project_role"),
                            rules: [{ required: true, message: 'Geef een rol op'}],
                            onChange: (value) => this.setState({ project_role: value })
                          })(
                            <Select>
                                <Option value={'admin'}>Mag bij alle projecten</Option>
                                <Option value={'user'}>Mag alleen bij opgegeven projecten</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={24}>
                    <Col span={24}>
                      <Alert
                        message={(
                          <div>
                            Bepaal welke rechten deze rol standaard mag hebben op de menu-items Projecten, Bibliotheek, Mediatheek en Accounts binnen Cobee.
                            <br /><br />
                            <strong>Geen toegang</strong>: <br />je geeft het account geen toegang tot dit menu-item. Het menu-item wordt voor het account niet getoond.<br /><br />
                            <strong>Alleen bekijken</strong>: <br />je geeft het account toegang tot het menu-item, maar de gebruiker kan in dit menu-item niets Toevoegen, Wijzigen of Verwijderen. Alle knoppen in de module zijn disabled.<br /><br />
                            <strong>Bewerken</strong>: <br />je geeft dit account toegang tot het werken in dit menu-item. Het account kan in dit menu-item Toevoegen en Wijzigingen aanbrengen.<br /><br />
                            <strong>Verwijderen</strong>: <br />je geeft dit account toegang tot het werken in dit menu-item. Het account kan in dit menu-item Toevoegen, Wijzigingen aanbrengen en Verwijderen.<br /><br />
                          </div>
                        )}
                        type="info"
                        showIcon
                        style={{marginBottom: 24}}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Projecten"}>
                          {getFieldDecorator("projects", {
                            initialValue: this.getInitialValue("projects"),
                            onChange: (value) => this.setState({ projects: value })
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#ffe58f', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                              <Option value={'delete'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Verwijderen</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Bibliotheek"}>
                          {getFieldDecorator("library", {
                            initialValue: this.getInitialValue("library"),
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#ffe58f', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                              <Option value={'delete'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Verwijderen</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Mediatheek"}>
                          {getFieldDecorator("media", {
                            initialValue: this.getInitialValue("media"),
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#ffe58f', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                              <Option value={'delete'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Verwijderen</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Accounts"}>
                          {getFieldDecorator("users", {
                            initialValue: this.getInitialValue("users"),
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#ffe58f', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                              <Option value={'delete'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Verwijderen</span></div></Option>
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Administratie"}>
                          {getFieldDecorator("transactions", {
                            initialValue: this.getInitialValue("transactions"),
                          })(
                            <Select>
                              <Option value={''}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginRight: 5}}></span><span>Geen toegang</span></div></Option>
                              <Option value={'read'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: 'orange', marginRight: 5}}></span><span>Alleen bekijken</span></div></Option>
                              <Option value={'write'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#ffe58f', marginRight: 5}}></span><span>Bewerken</span></div></Option>
                              {/*<Option value={'delete'}><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{display: 'block', flexDirection: 'column', width: 10, height: 10, borderRadius: 5, backgroundColor: '#44c575', marginRight: 5}}></span><span>Verwijderen</span></div></Option>*/}
                            </Select>
                          )}
                        </Form.Item>
                    </Col>
                  </Row>

                  <Row style={{ padding: '6px 0' }}>
                    <Col span={24}>
                      <Title level={4}>Project rechten</Title>
                    </Col>
                  </Row>
                  { this.state.projects != '' ?
                    <>
                      <table style={{width: '100%'}}>
                        <thead>
                          <tr style={{ border: '1px solid #f0f2f5', backgroundColor: '#f0f2f5'}}>
                            <th style={{ padding: '4px 8px'}}>Rechten</th>
                            <th colspan="3" style={{ padding: '4px 8px'}}>
                              <td style={{ width: 140, cursor: 'pointer' }} onClick={() => { this.selectAll(''); }}>Geen toegang</td>
                              <td style={{ width: 100, cursor: 'pointer' }} onClick={() => { this.selectAll('read'); }}>Lezen</td>
                              <td style={{ width: 100, cursor: 'pointer' }} onClick={() => { this.selectAll('write'); }}>Bewerken</td>
                              <td style={{ width: 200, cursor: 'pointer' }} onClick={() => { this.selectAll('delete'); }}>
                                <div style={{ display: 'flex' }}>
                                  <span style={{ marginRight: 8 }}>Bewerken en verwijderen</span>
                                </div>
                              </td>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        { this.props.customer && this.props.customer.user_right_modules && this.props.customer.user_right_modules.map((element, index) => {
                          return (<>
                            <tr >
                              <td style={{ padding: '4px 8px'}}>{element.label}</td>
                              <td colspan="3" style={{ padding: '4px 8px'}}>
                                <Form.Item style={{ margin: 0}}>
                                  { getFieldDecorator(element.object, {
                                    initialValue: this.state[element.object],
                                    onChange: (e) => {

                                        this.props.form.setFieldsValue({ [element.object]: e.target.value });
                                    }
                                  })(
                                    <Radio.Group style={{ width: '100%' }}>
                                      <td style={{width: 142}}>
                                        {element.object == 'address' ? null : <Radio disabled={element.object == 'address' ? true : false} style={{ width: 140, marginRight: 0 }} value={''}></Radio>}
                                      </td>
                                      <td><Radio style={{ width: 100, marginRight: 0 }} value={'read'}></Radio></td>
                                      <td><Radio style={{ width: 100, marginRight: 0 }} value={'write'}></Radio></td>
                                      <td><Radio style={{ width: 200, marginRight: 0 }} value={'delete'}></Radio></td>
                                    </Radio.Group>
                                  )}
                                </Form.Item>
                              </td>
                            </tr>
                          </>);
                        })}
                        </tbody>
                      </table>
                    </>
                  : <Alert type="info" showIcon style={{marginBottom: 24}} message={(
                      <div>Pas de rechten tot "Projecten" aan om project rechten toe te passen op deze rol.</div>
                    )}
                  /> }

                </Form> }

              </div>
          </div>
      </div>
    );
  }
}


const AccountRoleForm = Form.create({ name: 'account_role' })(AccountRole);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.account.isFetching,
    isError : state.account.isError,
    accountrole : state.account.accountrole,
    customer : state.customer.customer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountRoleAction : bindActionCreators(getAccountRoleAction, dispatch),
    addAccountRoleAction : bindActionCreators(addAccountRoleAction, dispatch),
    patchAccountRoleAction : bindActionCreators(patchAccountRoleAction, dispatch),
    resetAccountRoleAction : bindActionCreators(resetAccountRoleAction, dispatch),
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRoleForm);
