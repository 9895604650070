import React, { Component } from 'react';
// import './Chat.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getConversationsAction, storeConversationMessageAction, showConversationAction } from '../../../../actions/chatsActions';
import { getAddressesAction } from '../../../../actions/addressesActions';
import { MessageOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  List,
  Avatar,
  //Comment,
  Card,
  Typography,
  Tooltip,
  PageHeader,
  Input,
  Badge,
  Empty,
  Button,
  Modal,
  Select,
  Divider,
  message,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import ChatSidemenu from './ChatSidemenu';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import moment from 'moment';
import 'moment/locale/nl';

const { Paragraph, Title, Text } = Typography;

const { TextArea } = Input;


const SendMessageForm = Form.create({ name: 'send_message_form' })(
  class extends React.Component {

    render() {
      const { onCreate, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Form required hasFeedback onSubmit={this.props.onSubmit}>
          <Form.Item required label="Bericht" hasFeedback>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Vul een bericht in' }],
            })(
              <TextArea required rows={3}/>
            )}
            </Form.Item>
            <Button type="primary" htmlType="submit">Verstuur</Button>
        </Form>
      )
    }
  },
);

class Chat extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        loading: true,
        data: [],
        chatModal: false,
        chatMessage: '',
        conversations: [],
        conversation: {},
        isConversationsFetching: false,
        addressFetching: false,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {

        this.setState({isConversationsFetching: true})
        this.props.getConversationsAction(this.props.match.params.id).then(() => {

          this.setState({conversations: this.props.conversations/*.sort(this.sortMessageReadFunction)*/, isConversationsFetching: false});

          if(this.messagesEnd) {
            //this.messagesEnd.scrollIntoView({ behavior: "auto" });
          }

        });
    }


    sendMessage = (e) => {
      e.preventDefault();

      this.formRef.props.form.validateFields((err, values) => {
        if (!err) {
          values = {'to_project_id': this.props.match.params.id, 'from_address_id': this.props.conversation[0].from_address_id, 'from_unique': this.props.conversation[0].from_unique,  ...values}
          this.props.storeConversationMessageAction(this.props.match.params.id, values).then(() => {

            showSuccess('Bericht verzonden');

            // if(this.messagesEnd) {
            //   this.messagesEnd.scrollIntoView({ behavior: "auto" });
            // }

            this.setState({isConversationsFetching: true});

            //
            this.props.getConversationsAction(this.props.match.params.id).then(() => {

                this.setState({conversations: this.props.conversations, isConversationsFetching: false}, () => {

                    this.openConversation(this.props.conversation[0]);

                    // if(this.messagesEnd) {
                    //   this.messagesEnd.scrollIntoView({ behavior: "auto" });
                    // }
                });
            });
          });

          this.formRef.props.form.resetFields();
        }
      });
    }

    createConversation = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          values = {'to_project_id': this.props.match.params.id, ...values}

          this.props.storeConversationMessageAction(this.props.match.params.id, values).then(() => {

            this.setState({chatModal: false});

            showSuccess('Bericht verzonden');

            this.props.form.resetFields();

            if(values.from_address_id !== 'all' && !Array.isArray(values.from_address_id))
            {
                this.setState({conversations: this.props.conversations});

                this.props.getConversationsAction(this.props.match.params.id);
            }
            else
            {
                this.loadData();

                this.props.form.resetFields();
            }
          });
        }
      });

    }

    openConversation = (item) => {

      this.setState({conversation: {}})

      // this.props.showConversationAction((item.from_address_id ? item.from_address_id : item.to_address_id)).then(() => {

      this.props.showConversationAction(item).then(() => {

        this.props.getConversationsAction(this.props.match.params.id).then(() => {

          this.setState({ conversations: this.props.conversations, conversation: item}, () => {

            // if(this.messagesEnd) {
            //   this.messagesEnd.scrollIntoView({ behavior: "auto" });
            // }

            document.body.querySelector(".chat-messages").scrollTo(0, 9999);

          })
        })
      })

    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    sortDateFunction = (a,b) => {
      var dateA = new Date(a.created_at).getTime();
      var dateB = new Date(b.created_at).getTime();
      return dateA > dateB ? 1 : -1;
    };

    sortMessageReadFunction = (a,b) => {
      var dateA = a.chatmessages[0].created_at;
      var dateB = b.chatmessages[0].created_at;

      // if(a.nr_of_new_messages > 0) {
      //   return -1;
      // }

      return dateA < dateB ? 1 : -1;
    };


    render() {
      const { getFieldDecorator } = this.props.form;
        return (
          <div>
              <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
                  <ChatSidemenu id={this.props.match.params.id} activeMenu={["1"]} {...this.props}/>
                  <div className="chatScreen" layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
                    <Row gutter={24} type="flex" className="mbm">
                      <Col span={24}>
                          <Button disabled={!this.props.HaveAbility('chat', 'write')} type="new" onClick={() => {
                                  this.setState({chatModal: true, addressFetching: true});
                                  this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => this.setState({addressFetching: false}));
                              }} icon={<MessageOutlined />}>Start conversatie</Button>
                      </Col>
                    </Row>
                    <Row gutter={24} type="flex">
                          <Col span={8} className={"chatList"}>
                              <Card>
                              <Title level={4}>Laatste berichten</Title>
                              <Divider style={{marginBottom:0}}></Divider>
                              <List
                                itemLayout="horizontal"
                                loading={this.state.isConversationsFetching}
                                dataSource={this.state.conversations}
                                style={{overflow: 'scroll', maxHeight: 'calc(100vh - 465px)', cursor: 'pointer'}}
                                renderItem={ item => (
                                  <List.Item className={item.chat} onClick={() => this.openConversation(item)}>
                                    <List.Item.Meta

                                      title={
                                        <div>
                                          <a>{item.from_full_address ? item.from_full_address : '-'}</a>
                                          <Badge style={{marginLeft: 5}} count={item.nr_of_new_messages} />
                                        </div>
                                      }
                                      description={
                                        <Paragraph style={{color:"#9e9e9e"}} ellipsis={{ rows: 2}}>
                                          {item.hasOwnProperty('chatmessages') ? moment(item.chatmessages[0].created_at).format('DD-MM-YYYY HH:mm') : ''}: {item.hasOwnProperty('chatmessages') ? item.chatmessages[0].message : ''}
                                        </Paragraph>
                                      }

                                    />
                                  </List.Item>
                                )}
                              />
                              </Card>
                          </Col>

                          {Object.keys(this.state.conversation).length > 0 && (
                          <Col span={16} className={"chatScreen"}>
                            <Card title={`Berichten: ${this.state.conversation.from_full_address}`}>
                              <div>
                                <List
                                  itemLayout="horizontal"
                                  dataSource={this.props.conversation.sort(this.sortDateFunction)}
                                  style={{overflow: 'scroll', maxHeight: 'calc(100vh - 465px)'}}
                                  loading={this.props.isFetching}
                                  footer={(
                                    <div style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}></div>
                                  )}
                                  renderItem={item => (
                                    <List.Item
                                      style={{border: '0px', justifyContent: !item.from_user_name ? 'flex-start' : 'flex-end'}}
                                      className={item.type}
                                      >
                                      <Comment
                                        style={{ maxWidth: '50%', backgroundColor: !item.from_user_name ? '' : '#fafafa', padding: '0 12px', border: "1px solid #e8e8e8", borderRadius:4}}
                                        author={<p style={{ paddingTop: 6 }}>{item.from_user_name ? item.from_user_name : item.from_full_address}</p>}
                                        content={(
                                          <Paragraph style={{ whiteSpace: "pre-line" }}>{item.message}</Paragraph>
                                        )}
                                        datetime={(
                                          <span>{moment(item.created_at).format('DD/MM/YYYY - HH:mm')}</span>
                                          // <Tooltip title={moment(item.created_at).format('DD/MM/YYYY - HH:mm')}>
                                          //   <span>{moment(item.created_at).fromNow()}</span>
                                          // </Tooltip>
                                        )}
                                      />
                                    </List.Item>
                                  )}
                                  className={'chat-messages'}
                                  />
                                {this.props.HaveAbility('chat', 'write') != false ? (
                                  <SendMessageForm wrappedComponentRef={this.saveFormRef} onSubmit={this.sendMessage} />
                                ) : null}
                              </div>
                            </Card>
                          </Col>
                          )}
                      </Row>

                      <Modal
                        visible={this.state.chatModal}
                        title="Start nieuwe conversatie"
                        okText="Verzenden"
                        onCancel={() => this.setState({chatModal: false})}
                        onOk={(e) => this.createConversation(e)}
                      >
                      <Form layout="vertical">
                        <Form.Item required label="Ontvanger">
                        {getFieldDecorator('from_address_id', {
                          rules: [{ required: true, message: 'Selecteer één of meerdere ontvangers' }],
                        })(
                          <Select
                              showSearch
                              mode={'multiple'}
                              loading={this.state.addressFetching}
                              disabled={this.state.addressFetching}
                              placeholder="Selecteer één of meerdere adressen"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                            <Select.Option value='all' key='all'>Naar iedereen in deze lijst</Select.Option>
                          { this.props.addresses.map((v,k) => {
                            if(v.has_app) {
                              return (
                                <Select.Option value={ v.id } key={ v.id }>{v.full_address}</Select.Option>
                              );
                            }
                          }) }

                          </Select>
                        )}
                        </Form.Item>
                        <Form.Item label="Bericht">
                          { getFieldDecorator('message', {
                            rules: [{ required: true, message: 'Vul een bericht in' }],
                          })( <TextArea rows={4} /> )}
                        </Form.Item>
                      </Form>
                      </Modal>
                  </div>
              </div>
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.chat.isFetching,
      isError : state.chat.isError,
      conversations: state.chat.conversations,
      conversation: state.chat.conversation,
      addresses: state.address.addresses,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getConversationsAction : bindActionCreators(getConversationsAction, dispatch),
      storeConversationMessageAction : bindActionCreators(storeConversationMessageAction, dispatch),
      showConversationAction : bindActionCreators(showConversationAction, dispatch),
      getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

const WrappedChatForm = Form.create({ name: 'startconverstation' })(Chat);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedChatForm);
