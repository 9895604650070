import React, { Component } from 'react'

import {
    FormOutlined,
    HomeOutlined,
    PictureOutlined,
    SettingOutlined,
    TeamOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';

import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class DeliverSideMenu extends Component {

    state = {
        openDeliverMenu: []
    }

    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 240}}
                selectedKeys={[...this.props.activeMenu, (this.props.activeMenu == '8' || this.props.activeMenu == '9' ? '2' : null)]}
                onOpenChange={(e) => { this.setState({ openDeliverMenu: e}); }}
                openKeys={[...this.state.openDeliverMenu, (this.props.activeMenu == '8' || this.props.activeMenu == '9' ? '2' : null)]}
            >
                <Menu.Item key={"1"}>
                    <Link to={`/projects/${this.props.project_id}/deliver/points`}>
                        <HomeOutlined />
                        <span>Opleverpunten</span>
                    </Link>
                </Menu.Item>
                <Menu.SubMenu key={"2"} title={<span><SettingOutlined /><span>Instellingen</span></span>}>
                    <Menu.Item key={"8"}>
                        <Link to={`/projects/${this.props.project_id}/deliver/users`}>
                            <TeamOutlined />
                            <span>Verantwoordelijken</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={"9"}>
                        <Link to={`/projects/${this.props.project_id}/deliver/informed-users`}>
                            <TeamOutlined />
                            <span>Geinformeerden</span>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key={"3"}>
                    <Link to={`/projects/${this.props.project_id}/deliver/notifications`}>
                        <TeamOutlined />
                        <span>Notificaties</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"4"}>
                    <Link to={`/projects/${this.props.project_id}/deliver/drawings`}>
                        <PictureOutlined />
                        <span>Tekeningen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"5"}>
                    <Link to={`/projects/${this.props.project_id}/deliver/deliveries`}>
                        <UnorderedListOutlined />
                        <span>Vooropleveringen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"7"}>
                    <Link to={`/projects/${this.props.project_id}/deliver/delivery-forms`}>
                        <FormOutlined />
                        <span>Opleverformulieren</span>
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }
}
