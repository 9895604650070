import React, { Component } from 'react';
// import './ProjectsSettings.css';
import ProjectSidemenu from './ProjectSidemenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProjectAction, patchProjectAction, syncProjectModulesAction } from '../../../actions/projectActions';
import { showSuccess } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, Input, Tooltip } from 'antd';
import _ from 'underscore';

const { Title, Text } = Typography;

class ProjectModules extends Component {

  state = {
    edit: true,
    module_18: null
  };

  componentDidMount() {

    this.props.getProjectAction(this.props.match.params.id).then(() => {});
  }

  handleSubmit = e => {

    this.props.form.validateFields((err, values) => {

      if (!err) {
        var result = Object.keys(values).filter((key) => {

          if(values[key] === true) {

            return key;
          }

          return null;
        });

        this.props.syncProjectModulesAction(this.props.match.params.id, {'ids': result, module_18_name: values.module_18_name }).then(() => {

            this.props.getProjectAction(this.props.match.params.id).then(() => {

                showSuccess();
            });
        });
      }
    });

  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ProjectSidemenu id={this.props.match.params.id} activeMenu={["3"]} {...this.props}/>

          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('project-modules', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                </div>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              { this.props.project.available_modules.map((element) => {
                return (
                element.name != 'dossier'
                ?
                <Col span={12}>
                  <Form.Item>
                    <div>
                      <Text strong>{element.display_name}</Text>
                      { element.name == 'export' ?
                      <Tooltip class="mhs" title="Alleen van toepassing op de modules waar de gebruiker toegang tot heeft en welke een export mogelijkheid bieden.">
                          &nbsp;<InfoCircleTwoTone />
                      </Tooltip> : null }
                      <br />
                      {getFieldDecorator(String(element.id), {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(element.id) > -1,
                        onChange: (data) => { this.setState({ ['module_'+element.id]: data });  }
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      )}
                    </div>
                  </Form.Item>
                </Col>
                :
                <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item>
                          <div>
                            <Text strong>{element.display_name}</Text>
                            { element.name == 'export' ?
                            <Tooltip class="mhs" title="Alleen van toepassing op de modules waar de gebruiker toegang tot heeft en welke een export mogelijkheid bieden.">
                                &nbsp;<InfoCircleTwoTone />
                            </Tooltip> : null }
                            <br />
                            {getFieldDecorator(String(element.id), {
                              valuePropName: 'checked',
                              initialValue: this.props.modules.indexOf(element.id) > -1,
                              onChange: (data) => { this.setState({ ['module_'+element.id]: data });  }
                            })(
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                              />
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    { (this.state['module_'+element.id] === undefined && this.props.modules.indexOf(element.id) > -1) || this.state['module_'+element.id] ?
                    <Row>
                      <Col span={24}>
                        <Form.Item>
                          <div>
                            <Text strong>Dossier verbergen voor bewoners</Text>
                            <br />
                            {getFieldDecorator(String(21), {
                              valuePropName: 'checked',
                              initialValue: this.props.modules.indexOf(21) > -1,
                              onChange: (data) => { this.setState({ ['module_21']: data });  }
                            })(
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                />
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row> : null }
                </Col>
                );
              })}
            </Row>

            { ((_.findWhere(this.props.project.available_modules, {id: 18}) && this.state['module_18'] == null) || this.state['module_18']) ?
            <div>
                <Divider></Divider>
                <Row gutter={24}>
                  <Col span={24}>
                    <Title level={4}>Eigen module</Title>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Naam" hasFeedback>
                      {getFieldDecorator('module_18_name', {
                        initialValue: String(this.props.project.module_18_name ? this.props.project.module_18_name : 'Eigen module'),
                      })(
                        <Input maxLength={24} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
            </div> : null }

          </Form>
        </div>
      </div>
    );
  }
}

const ProjectModulesForm = Form.create({ name: 'project_modules' })(ProjectModules);
const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isError : state.project.isError,
    project: state.project.project,
    modules: state.project.modules,
    customer : state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    patchProjectAction : bindActionCreators(patchProjectAction, dispatch),
    syncProjectModulesAction : bindActionCreators(syncProjectModulesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModulesForm);
