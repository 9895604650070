import api from '../utils/api'

export const getPushNotificationAction = (id) => (dispatch, getState) => {

  dispatch({
    type: 'PUSH_ACTION_FETCH'
  })

  return api('get','/push_messages/'+id, null, true).then((response) => {
    dispatch({
      type: 'PUSHES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PUSH_ACTION_ERROR',
    })
    return false;
  });
}

export const storePushNotificationAction = (id, params) => (dispatch, getState) => {

  dispatch({
    type: 'PUSH_ACTION_FETCH'
  })

  return api('post','/push_messages/'+id, params, true).then((response) => {
    dispatch({
      type: 'STORE_PUSH_ACTION_SUCCESS',
      payload: response.data
    });

    return response.data;

  }).catch((e) => {
    dispatch({
      type: 'PUSH_ACTION_ERROR',
    })
    return false;
  });
}

export const searchPushnotificationAction = (id, search) => (dispatch, getState) => {

  dispatch({
    type: 'PUSH_ACTION_FETCH'
  })

  return api('post','/push_messages/'+id+'/search', search, true).then((response) => {
    dispatch({
      type: 'SEARCH_PUSH_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_PUSH_ACTION_ERROR',
    })
    return false;
  });
}

export const getPushNotificationDetailAction = (id) => dispatch => {

  dispatch({
    type: 'PUSH_ACTION_FETCH'
  })

  return api('get','/push_message/'+id, null, true).then((response) => {
    dispatch({
      type: 'PUSH_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'PUSH_ACTION_ERROR',
    })
    return false;
  });
}

export const patchPushnotificationAction = (id, params) => dispatch => {

  dispatch({
    type: 'PUSH_ACTION_FETCH'
  })

  return api('post','/push_message/'+id, params, true).then((response) => {
    dispatch({
      type: 'PUSH_PATCH_ACTION_SUCCESS',
      payload: response.data.envelope
    });
  }).catch((e) => {
    dispatch({
      type: 'PUSH_ACTION_ERROR',
    })
    return false;
  });
}

export const deletePushnotificationsAction = (project_id, params) => (dispatch, getState) => {
  return api('post','/push_messages/' + project_id + '/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_PUSH_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const sendPushnotificationAction = (id) => (dispatch, getState) => {

  return api('post','/push_message/send/' + id, {'test': 'test'}, true).then((response) => {
    dispatch({
      type: 'SEND_PUSH_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const resetPushnotificationAction = () => dispatch => {

  dispatch({
    type: 'RESET_PUSH_ACTION_ERROR',
  })

}
