import api from '../utils/api'

export const getLibrariesAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'LIBRARIES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'LIBRARIES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}

export const searchLibrariesAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'LIBRARIES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/'+customer_id+'/search', {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_LIBRARIES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}

export const getLibraryAction = (id) => dispatch => {

  dispatch({
    type: 'LIBRARY_ACTION_FETCH'
  })

  return api('get','/library/document/'+id, null, true).then((response) => {
    dispatch({
      type: 'LIBRARY_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}

export const storeLibraryAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'LIBRARIES_ACTION_FETCH'
  })

  var customer_id = false;

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/'+customer_id, params, true).then((response) => {
    dispatch({
      type: 'STORE_LIBRARY_ACTION_SUCCESS',
      payload: response.data.library
    });
  }).catch((e) => {
    dispatch({
      type: 'LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}

export const patchLibraryAction = (id, params) => dispatch => {

  dispatch({
    type: 'LIBRARIES_ACTION_FETCH'
  })

  return api('post','/library/document/'+id, params, true).then((response) => {
    dispatch({
      type: 'LIBRARY_ACTION_SUCCESS',
      payload: response.data.library
    });
  }).catch((e) => {
    dispatch({
      type: 'LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteLibrariesAction = (params) => dispatch => {
  return api('post','/library/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_LIBRARIES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const resetLibraryAction = () => dispatch => {

  dispatch({
    type: 'RESET_CUSTOMER_ACTION_ERROR',
  })

}

export const getLibraryListAction = (type) => (dispatch, getState) => {

  dispatch({
    type: 'LIBRARIES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/list/'+type+'/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'LIBRARIES_LIST_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}

export const cloneLibraryItemAction = (type, id) => (dispatch, getState) => {

  dispatch({
    type: 'LIBRARIES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  var params = null;

  if(id && id.length)
  {
      params = { ids: id };
  }

  return api((!params ? 'get' : 'post'),'/library/'+customer_id+'/clone/'+type+'/'+(params ? 'multi' : id), params, true).then((response) => {
    dispatch({
      type: 'LIBRARY_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'LIBRARIES_ACTION_ERROR',
    })
    return false;
  });
}



/* CATEGORIES */

export const getLibraryCategoriesAction = (object_type) => (dispatch, getState) => {

    dispatch({
        type: 'LIBRARY_CATEGORIES_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get', '/library/'+customer_id+'/categories/'+object_type, null, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CATEGORIES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'LIBRARY_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteLibraryCategoriesAction = (object_type, params) => (dispatch, getState) => {

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post', '/library/'+customer_id+'/category/multidelete/'+object_type, params, true).then((response) => {
        dispatch({
            type: 'DELETE_LIBRARY_CATEGORIES_ACTION_SUCCESS',
            payload: response.data
        });
        return true;
    }).catch((e) => {
        // dispatch({
        //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
        // })
        return false;
    });
}

export const getLibraryCategoryAction = (id) => dispatch => {

    dispatch({
        type: 'LIBRARY_CATEGORY_ACTION_FETCH'
    })

    return api('get', '/library/category/'+id, null, true).then((response) => {
        dispatch({
            type: 'LIBRARY_CATEGORY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'LIBRARY_CATEGORY_ACTION_ERROR',
        })
        return false;
    });
}

export const addLibraryCategoryAction = (object_type, params) => (dispatch, getState) => {

    dispatch({
        type: 'LIBRARY_CATEGORY_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post', '/library/'+customer_id+'/category/'+object_type, params, true).then((response) => {
        dispatch({
            type: 'STORE_LIBRARY_CATEGORY_ACTION_SUCCESS',
            payload: response.data.media_category
        });
        return response;

    }).catch((e) => {
        dispatch({
            type: 'LIBRARY_CATEGORY_ACTION_ERROR',
        })
        return false;
    });
}

export const updateLibraryCategoryAction = (id, params) => dispatch => {

    dispatch({
        type: 'LIBRARY_CATEGORY_ACTION_FETCH'
    })

    return api('post', '/library/category/'+id, params, true).then((response) => {
        dispatch({
            type: 'PATCH_LIBRARY_CATEGORY_ACTION_SUCCESS',
            payload: response.data.medialibrary
        });
    }).catch((e) => {
        dispatch({
            type: 'LIBRARY_CATEGORY_ACTION_ERROR',
        })
        return false;
    });
}

export const resetLibraryCategoryAction = () => (dispatch, getState) => {
    dispatch({
        type: 'LIBRARY_CATEGORY_ACTION_RESET'
    })
}
