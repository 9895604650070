import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
  Collapse,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSurveyAction, resetSurveyAction } from '../../../../../actions/surveysActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import SelectAddresses from '../../../../../components/SelectAddresses/SelectAddresses';
import SurveySteps from '../Steps/SurveySteps';
import { showSuccess } from '../../../../../utils/Notifications';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import _ from 'underscore';

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

class SurveyResultsV2 extends Component {

  state = {
    selectedAddressIds: [],
    questions: []
  }

  constructor(props) {
    super(props);
    this.selectaddresses = React.createRef();
  }

  componentDidMount() {

    this.props.resetSurveyAction();

    if(this.props.match.params.surveyid) {
      this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => {
        this.props.getSurveyAction(this.props.match.params.surveyid).then(() => {

          var questions = this.props.survey.questions && this.props.survey.questions.map((element) => {
            var avarage = 0;
            var answers = this.props.survey.answers && this.props.survey.answers.filter((filterelement) => filterelement.id == element.id );

            if(element.type == 'rating') {
              answers.map((answer) => {
                avarage += Number(answer.answer);
              });
              avarage = avarage / answers.length;
            }

            return questions = {
              'id': element.id,
              'question': element.data ? element.data.label : 'Lege vraag',
              'avarage': avarage,
              'type': element.type,
              'answers': answers,
              'nr_of_answers': element.nr_of_answers,
            };
          });
          this.setState({questions: questions});
        });
      });

    }
  }

  // patchAddresses() {
  //   this.selectaddresses.current.share();
  //   showSuccess('Succesvol opgeslagen');
  //   this.props.history.push(`/projects/${this.props.match.params.id}/modules/enquete`)
  // }

  render() {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
                <Link to={`/projects/${this.props.match.params.id}/modules/enquete`}>
                  <Button>Sluiten</Button>
                </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <SurveySteps version={this.props.survey ? this.props.survey.version : 2} current={(/*this.props.project.is_public == true ? 2 :*/ 3)} {...this.props}/>
            </Card>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Card>
              <Collapse bordered={true} defaultActiveKey={['1']}>

                { this.state.questions.map((element) => {

                  // var answers = this.props.survey.answers.filter((filterelement) => filterelement.survey_question_id == element.id );

                  return (
                    <Collapse.Panel
                        header={element.question}
                        key={element.id}
                        extra={<div>Beantwoord: {element.nr_of_answers ? element.nr_of_answers : 0}x</div>}
                        >
                      {element.answers.map((answer, index) => {

                        if(!answer.value) {
                          return;
                        }

                        var address = _.where(this.props.address, {id: answer.address_id})[0] ? _.where(this.props.address, {id: answer.address_id})[0] : '';

                        return (
                          <div key={`answer_${index}`} style={{margin: 30}}>
                            <b>{address.full_address}</b>
                              {answer.photo ? <p><RenderHTML HTML={(answer.value)} /></p> : <pre style={{fontFamily: 'inherit'}}>{answer.value}</pre>}
                          </div>
                        )
                      })}

                    </Collapse.Panel>
                  )
                })}

              </Collapse>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.survey.isFetching,
    isError : state.survey.isError,
    survey : state.survey.survey,
    address: state.address.addresses,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
    resetSurveyAction : bindActionCreators(resetSurveyAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResultsV2);
