import React, { Component } from 'react';
// import '../Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerAction, getCustomerTransactions, exportCustomerTransactions } from '../../../actions/customerActions';
import { showSuccess } from '../../../utils/Notifications';
import CustomTable from '../../../components/CustomTable/CustomTable';
import CustomerSideMenu from './CustomerSideMenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, DatePicker, Collapse, Table } from 'antd';
import PageHeader from '../../../components/PageHeader/PageHeader';
import moment from 'moment';
import dayjs from 'dayjs';
import { API_URL } from '../../../constants/settings'
import 'dayjs/locale/nl';
dayjs.locale('nl');
const { Title } = Typography;
const { Panel } = Collapse;


const columns = [
  {
    title: 'Datum',
    width: 150,
    dataIndex: 'date',
    sorter: (a, b) => { return dayjs(a.date).unix() - dayjs(b.date).unix()},
    render: (text, index) => {
      return (
        <span>{dayjs(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>

      )
    }
  },{
    title: 'Type',
    sorter: (a, b) => { return a.type.localeCompare(b.type)},
    dataIndex: 'type',
  },{
    title: 'Aantal',
    sorter: (a, b) => { return a.nr_of - b.nr_of},
    dataIndex: 'nr_of',
  },{
    title: 'Prijs',
    sorter: (a, b) => { return a.total_price.localeCompare(b.total_price)},
    width: 100,
    dataIndex: 'total_price',
    render: (text, index) => {
      return (
        <span>{text}</span>

      )
    }
  }
];

class CustomerTransaction extends Component {
  state = {
    edit: true,
    billing: [],
    selectedDate: dayjs(),
  };

  componentDidMount() {
      this.props.getCustomerTransactions(this.props.match.params.id, dayjs().format('YYYY-MM')).then(() => {
          this.setState({ billing: this.props.billing });
      });
  }

  getTransactions = (month) => {
    this.setState({selectedDate: month});
    this.setState({ billing: [] });
    this.props.getCustomerTransactions(this.props.match.params.id, dayjs(month).format('YYYY-MM')).then(() => {
        this.setState({ billing: this.props.billing });
    });
  }

  downloadExport(month)
  {
      // this.props.exportCustomerTransactions(this.props.match.params.id,dayjs(month).format('YYYY-MM'));

      window.open(API_URL + '/customer/' + this.props.match.params.id + '/transactions/export?date=' + dayjs(month).format('YYYY-MM') +'&token=' + localStorage.getItem('authToken'), '_blank');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <PageHeader path={[{'label': 'Klanten', 'link': '/customers'}, {'label': this.props.customer.name}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <CustomerSideMenu id={this.props.match.params.id} activeMenu='5' />
          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={12} >
                <Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>
                <DatePicker mode={'month'} format={'YYYY-MM'} onPanelChange={(month) => this.getTransactions(month)} value={this.state.selectedDate} style={{marginLeft: 8, marginBottom: 30}} />
                <Button onClick={() => this.downloadExport(this.state.selectedDate)} style={{marginLeft: '8px'}}>Exporteren</Button>
              </Col>
            </Row>
            <Divider></Divider>
            <Row className="mbm">
              <Col span={12} >
                <span style={{fontWeight: 'bold'}}>Totale prijs: { this.state.billing.total_price }</span>
              </Col>
            </Row>
            <Collapse>
            {
                this.state.billing.projects && this.state.billing.projects.map((object, key) => (
                    <Panel header={'[nummer: '+object.project.project_number+'] '+object.project.name} extra={object.total_price} key={key}>
                        <Table
                          size="middle"
                          rowKey='id'
                          rowSelection={null}
                          columns={columns}
                          dataSource={object.transactions}
                          pagination={false}
                          />
                      </Panel>
                ))
            }
            </Collapse>

          </Form>
        </div>
      </div>
    );
    }
}

const CustomerTransactionForm = Form.create({ name: 'customer_modules' })(CustomerTransaction);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer: state.customer.customer,
    //modules: state.customer.modules,
    billing: state.customer.billing,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    getCustomerTransactions : bindActionCreators(getCustomerTransactions, dispatch),
    // exportCustomerTransactions : bindActionCreators(exportCustomerTransactions, dispatch),
    //syncCustomerModulesAction : bindActionCreators(syncCustomerModulesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTransactionForm);
