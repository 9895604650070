import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from "./store";
import './index.css';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider } from 'antd';
import nl_NL from 'antd/lib/locale/nl_NL';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://37f03705a8bb1fdcac6303e0be6f48b8@o1076784.ingest.sentry.io/4506152165048320",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/api.staging.cobee.nl\/api/, /^https:\/\/api.cobee.nl\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { store, persistor } = configureStore();

ReactDOM.render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider theme={{
            components: {
                Form: {
                    layout: 'vertical',
                    algorithm: true
                    // flexDirection: 'column',
                    // flexFlow: 'row wrap',
                }
            }
        }} locale={nl_NL}>
            <App />
        </ConfigProvider>
    </PersistGate>
</Provider>, document.getElementById('root'));
