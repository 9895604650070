import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Select } from 'antd';
import { bindActionCreators } from 'redux';
import { getPartnersAction, clonePartnerAction } from '../../../../../actions/globalpartnersActions'
import { connect } from 'react-redux';

class ClonePartner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      selectedId: null,
    };
  }

  cloneInformation = () => {
    if(this.state.selectedId) {
      this.props.clonePartnerAction(this.props.match.params.id, this.state.selectedId).then(() => {
        this.props.onClone();
        this.setState({selectedId: null, modalVisible: false});
      });
    }
  }

  openModal = () => {
    this.setState({modalVisible: true});
    this.props.getPartnersAction();
  }

  render () {
    return (
      <span>
      {this.props.plain ? (
        <span style={{display:'block'}}onClick={this.openModal}>
          {this.props.buttonLabel ? this.props.buttonLabel : 'Bibliotheek'}
        </span>
      ) : (
        <Button onClick={this.openModal}>Selecteer uit bibliotheek</Button>
      )}

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          onOk={() => this.cloneInformation()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Partners">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer een partner'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                {this.props.partners.map((element) => (
                  <Select.Option value={element.id}>{element.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    partners: state.partner.globalPartners,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPartnersAction:  bindActionCreators(getPartnersAction, dispatch),
    clonePartnerAction:  bindActionCreators(clonePartnerAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClonePartner);
