import React from 'react'
import FormBuilder from '../../../../components/FormBuilder/FormBuilder'
import { v1 as uuidv1 } from 'uuid'
import FormSteps from './FormSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Switch, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../utils/Notifications';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormAction, updateFormAction, resetFormAction, previewFormAction } from '../../../../actions/formActions';
import { getAddressFieldsAction } from '../../../../actions/projectActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import FormSideMenu from '../FormSideMenu';

class FormCompose extends React.Component {

  form_data = null;

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      can_write: false,
      loading: true,
    };
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('form' ,'write')
      });

      //
      this.props.resetFormAction();

      //
      this.props.getAddressFieldsAction(this.props.match.params.id).then(() => {

          //
          this.props.getFormAction(this.props.match.params.form_id).then(() => {

              //
              if(this.IsJsonString(this.props.form_details.form))
              {
                  var json_data = JSON.parse(this.props.form_details.form);

                  this.refs.FormBuilder.parse(json_data);

                  this.setState({ formData: json_data, loading: false });
              }
              else
              {
                  this.refs.FormBuilder.parse([{ 'id': uuidv1(), 'label': 'Nieuwe sectie', elements: [] }]);

                  this.setState({ loading: false });
              }
          });
      });
  }

  IsJsonString = (str) => {
    try {
      return JSON.parse(str) ? true : false;
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect = false) => {

    this.saveForm(redirect);
  }

  saveForm(redirect = true, preview = false)
  {
      this.props.form.validateFields((err, values) => {

          if(!err)
          {
              values.form = JSON.stringify(this.state.formData);

              this.props.updateFormAction(this.props.match.params.form_id, values).then(() => {

                  showSuccess();

                  if(redirect === true)
                  {
                      this.props.history.push('/projects/' + this.props.match.params.id + '/form/' + this.props.form_details.id + '/addresses');
                  }

                  if(preview)
                  {
                      this.props.previewFormAction(this.props.match.params.form_id);
                  }
              });
          }
      });
  }

  getEnabledElements()
  {
      var elements = [];

      if(this.props.project.is_public)
      {   // public project
          elements = [
              'f_select',
              'f_textinput',
              'f_title',
              'f_textareainput',
              'f_numberinput',
              'f_timeinput',
              'f_dateinput',
              'f_photoinput',
              'f_drawinginput',
              'f_image'
          ];
      }
      else
      {   // private project
          elements = [
              'f_select',
              'f_textinput',
              'f_title',
              'f_textareainput',
              'f_numberinput',
              'f_timeinput',
              'f_dateinput',
              'f_contactinput', // is only available for addresses
              'f_photoinput',
              'f_drawinginput',
              'f_image'
          ];
      }

      return elements;
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <FormSideMenu id={this.props.match.params.id} activeMenu="1"/>

          <div style={{padding: 24, width: '100%'}}>
            <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    { this.state.can_write && !this.props.form_details.locked ?
                    <Button disabled={!this.state.can_write || this.state.loading} type="new" onClick={() => this.handleSubmit(null, null, true)}>Opslaan en volgende</Button>
                    :
                    <Link to={`/projects/${this.props.match.params.id}/form/${this.props.match.params.form_id}/addresses`}>
                        <Button type="new">Volgende</Button>
                    </Link>
                    }
                    <Link to={`/projects/${this.props.match.params.id}/forms`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
                <Col span={12} align={'right'}>
                    <Button disabled={this.state.loading} onClick={(event) => { if(this.state.can_write && !this.props.form_details.locked){ this.saveForm(false, true); }else{ this.props.previewFormAction(this.props.match.params.form_id); } }}>Voorbeeld</Button>
                </Col>
              </Row>

              { this.props.form_details.locked ?
              <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="Dit formulier is vergrendeld en kan daarom niet worden aangepast."
                />
              : null }

              {/* steps */}
              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <FormSteps current={1} {...this.props} />
                  </Card>
                </Col>
              </Row>

            </Form>

            <Row gutter={24} className="mbm" style={{display: (this.props.match.params.form_id ? 'block' : 'none')}}>
              <Col span={24}>
                <FormBuilder
                  onChangeForm={(data) => { if(this.state.can_write && !this.props.form_details.locked){ this.setState({ formData: data }, () => { this.handleSubmit(); }); } } }
                  ref={'FormBuilder'}
                  type={'form'}
                  formId={this.props.match.params.form_id}
                  enabledElements={this.getEnabledElements()}
                  //sections={!this.props.project.is_public ? 0 : 1}
                  disabled={!this.props.HaveAbility('form' ,'write') || this.props.form_details.locked}
                  cloneSections={true}
                  addressFields={this.props.address_fields}
                  />
              </Col>
            </Row>

          </div>
      </div>
    );
  }
}


const FormComposeForm = Form.create({ name: 'form' })(FormCompose);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.forms.isFetching,
    isError : state.forms.isError,
    form_details : state.forms.form,
    project : state.project.project,
    address_fields: state.project.address_fields
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAction : bindActionCreators(getFormAction, dispatch),
    updateFormAction : bindActionCreators(updateFormAction, dispatch),
    resetFormAction : bindActionCreators(resetFormAction, dispatch),
    getAddressFieldsAction : bindActionCreators(getAddressFieldsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    previewFormAction : bindActionCreators(previewFormAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormComposeForm);
