import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAccountRolesAction, searchAccountRolesAction, addAccountRoleAction, deleteAccountRolesAction, updateAccountRolesAction } from '../../actions/accountActions';
import { getCustomersAction } from '../../actions/customerActions';
import PageHeader from '../../components/PageHeader/PageHeader';
import CustomTable from '../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Row, Col, message, Drawer, Select } from 'antd';
import { Link } from 'react-router-dom';
import AccountsSideMenu from './AccountsSideMenu';

const columns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
          <Link to={`/account-roles/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },
    {
      title: 'Aantal gebruikers',
      dataIndex: 'nr_of_users'
}
];

function onChange(pagination, sorter) {
}

const { Option } = Select;

const confirm = Modal.confirm;


class AccountRoles extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    visible: false,
    drawer: false,
  };

  componentDidMount() {
    this.props.getAccountRolesAction();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteAccountRolesAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
      getCheckboxProps: record => ({
        disabled: record.nr_of_users > 0 ? true : false
      }),
    };

    return (
      <div>
        <PageHeader path={[{'label': 'Rollen'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <AccountsSideMenu activeMenu='2' />
            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12} >
                    <Button disabled={!this.props.HaveAbility('users' ,'write')} icon={<PlusOutlined />} type="primary ant-btn-new" onClick={() => this.props.history.push('/account-roles/add')}>
                      Toevoegen
                    </Button>
                    <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('users', 'delete') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                        <Menu>
                          <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={this.props.HaveAbility('users', 'delete') ? this.state.selectedRowKeys.length > 1 : true } style={{ color: this.props.HaveAbility('users' ,'delete') ? (this.state.selectedRowKeys.length > 1 ? '' : '#f5222d') : '' }}>Verwijderen</Menu.Item>
                        </Menu>
                      )}>

                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ value => this.props.searchAccountRolesAction(value) }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey='id'
                      rowSelection={rowSelection}
                      onChange={onChange}
                      columns={columns}
                      loading={this.props.isFetching}
                      dataSource={this.props.isFetching ? [] : this.props.accountroles}
                      />
                  </Col>
                </Row>
            </div>
        </div>
      </div>
    );
  }
}
const AccountRolesAdd = Form.create({ name: 'add_account' })(AccountRoles);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.account.isFetching,
    isCustomerFetching : state.customer.isFetching,
    isError : state.account.isError,
    accountroles: state.account.accountroles,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountRolesAction : bindActionCreators(getAccountRolesAction, dispatch),
    searchAccountRolesAction : bindActionCreators(searchAccountRolesAction, dispatch),
    addAccountRoleAction : bindActionCreators(addAccountRoleAction, dispatch),
    deleteAccountRolesAction : bindActionCreators(deleteAccountRolesAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRolesAdd);
