import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../SupportBaseSideMenu';
import SupportBaseFormSteps from './SupportBaseFormSteps'
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';

// import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { getAddressesAction, getSupportBaseFormByIdAction, addSupportBaseFormToProjectAction, updateSupportBaseFormAction } from '../../../../actions/supportBaseActions';
import { shareAddressesAction, searchAddressesAction } from '../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class SupportBaseFormAddresses extends React.Component {

  state = {
    // current: 0,
    // smsModule: false,
    // letterVisible: false,
    // letterMailVisible: false,
    // emailVisible: false,
    // smsVisible: false,
    // pushVisible: false,

    expandedRowKeys: [],
    expandButton: true,

    name: '',

    selectedLetterIds: [],
    //selectedLetterEmailIds: [],
    selectedEmailIds: [],
    // selectedSmsIds: [],
    // selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,
    // expandedRowKeys: [],

    // sendDate: null,
    // sendTime: null,

    can_write: false,

    filteredInfo: null,

    loaded: false,

    group_filter: [],
    group_filter_and: true

  }

  // example data
  examples = [{id: 1, full_address: 'Straat 1', groups: '', occupants: [{id: 1, address_id: 1, email_address: 'email@adres.nl', full_name: 'Voornaam Achternaam', description: 'Bewoner' }]}];

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

    this.setState({ isFetching: true });

    this.props.getAddressesAction(this.props.match.params.id, this.props.match.params.form_id ? this.props.match.params.form_id : 'new').then(() => {

      this.props.getGroupsAction({'projectId': this.props.match.params.id});

      this.setState({currentDataSource: this.props.addresses},() => {
        var expandIds = [];
        this.state.currentDataSource.map((v) => {
          if(v.occupants) {
            expandIds.push('address-'+v.id)
          }
        });
        this.setState({expandedRowKeys: expandIds, expandButton: true});
      });
    });

    if(this.props.match.params.form_id && this.props.match.params.form_id != 'add')
    {
      this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false).then(() => {

        this.setState({
          name: this.props.support_base_form.name,
          selectedLetterIds: this.props.support_base_form.selection && this.props.support_base_form.selection.letter,
          selectedEmailIds: this.props.support_base_form.selection && this.props.support_base_form.selection.email,
        });

        this.setState({ isFetching: false, loaded: true });
      });
    }
    else{ this.setState({ isFetching: false, loaded: true }); }
  }

  // handleTableChange = (pagination, filters, sorter) => {
  //   this.setState({
  //     filteredInfo: filters,
  //       sortedInfo: sorter,
  //   });
  // };

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      // currentDataSource: extra.currentDataSource,
      // filteredDataSource: extra.filteredDataSource
    });
  };

  // groupsFilter(value, record)
  // {
  //     var all_groups_found = true;

  //     if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
  //     {
  //         this.state.filteredInfo.groups.map((group_name) => {
  //             var group_found = false;

  //             record.groups && record.groups.map && record.groups.map((record_group) => {
  //                 if(record_group.name == group_name)
  //                 {
  //                     group_found = true;
  //                 }
  //             });

  //             if(!group_found)
  //             {
  //                 all_groups_found = false;
  //             }
  //         });
  //     }

  //     return all_groups_found;
  // }

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = (record.groups === undefined ? true : false);

                  record.groups && record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = (record.groups === undefined ? true : false);

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.groups && record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  patchInformationAddresses() {}

  saveAndContinue()
  {
      if(!this.state.selectedLetterIds.length && !this.state.selectedEmailIds.length)
      {
          showError('Er zijn geen adressen geselecteerd voor post en of e-mail');
      }
      else
      {
          if(this.props.match.params.form_id == 'add')
          {
              this.props.addSupportBaseFormToProjectAction(this.props.match.params.id, {
                  name: this.state.name,
                  letterIds: this.state.selectedLetterIds,
                  emailIds : this.state.selectedEmailIds
              }).then(() => {
                  showSuccess('Succesvol opgeslagen');
                  this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/compose/${this.props.support_base_form.id}`);
              });
          }
          else
          {
              this.props.updateSupportBaseFormAction(this.props.match.params.form_id, {
                  name: this.state.name,
                  letterIds: this.state.selectedLetterIds,
                  emailIds : this.state.selectedEmailIds
              }).then(() => {
                  showSuccess('Succesvol opgeslagen');
                  this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/compose/${this.props.match.params.form_id}`);
              });
          }
      }
  }

  onSelectLetterChange = (value) => {
    if(this.state.selectedLetterIds.includes(value.target.value)) {
      this.setState({ selectedLetterIds: this.state.selectedLetterIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedLetterIds: [...this.state.selectedLetterIds, value.target.value]});
    }
  }

  onSelectEmailChange = (value) => {
    if(this.state.selectedEmailIds.includes(value.target.value)) {
      this.setState({ selectedEmailIds: this.state.selectedEmailIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedEmailIds: [...this.state.selectedEmailIds, value.target.value]});
    }
  }

  // onCheckLetterAll = (value) => {

  //   if(value.target.checked) {

  //     var occ_ids = [];
  //     // this.state.currentDataSource.map((v) => {
  //     this.filteredDataSource.map((v) => {
  //       v.occupants && v.occupants.map((elem) => {
  //         if(elem.different_address) {
  //           occ_ids.push('occ-'+elem.id);
  //         }
  //       })
  //     });

  //     var ids = this.state.currentDataSource.map((v) => v.id);

  //     this.setState({ selectedLetterIds: [...ids, ...occ_ids]});
  //   } else {
  //     this.setState({ selectedLetterIds: []});
  //   }

  // }

  onCheckLetterAll = (value) => {

    if(value.target.checked) {

      // var ids = [];
      //
      // this.props.addresses.map((record) => {
      //
      //   ids.push(record.id);
      // });
      //
      // this.setState({ selectedLetterIds: ids});

      var occ_ids = [];

      this.filteredDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.different_address) {
            occ_ids.push('occ-'+elem.id);
          }
        })
      });

      var ids = this.filteredDataSource.map((v) => v.id);

      this.setState({ selectedLetterIds: [...ids, ...occ_ids]});

    }
    else
    {
      this.setState({ selectedLetterIds: []});
    }
  }
  
  onCheckEmailAll = (value) => {

    if(value.target.checked) {

      var ids = [];
      // this.state.currentDataSource.map((v) => {
        this.filteredDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.email_address) {
            ids.push('occ-'+elem.id);
          }
        })
      });

      this.setState({ selectedEmailIds: ids});
    } else {
      this.setState({ selectedEmailIds: []});
    }
  }

  onSearch(value) {
    // this.props.searchCorrespondenceAddressesAction(this.props.match.params.id, {'search': value});
  }

  removeItem(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  render () {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        // title: 'Adressen',
        title: (
          <div style={{display: 'flex', flexDirection: 'row'}}>
              <Button style={{width: 17, height: 17, margin: 0, padding: 0, paddingTop: -5, marginRight: 10, marginTop: 2}} onClick={() => {

                var expandIds = [];
                if(!this.state.expandButton) {
                  this.state.currentDataSource.map((v) => {
                    if(v.occupants) {
                      expandIds.push('address-'+v.id)
                    }
                  });
                }
                this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
              }}><span style={{ display: 'flex', justifyContent: 'center', marginTop: -4}}>{this.state.expandButton ? '-' : '+'}</span></Button>
            <span>Adressen</span>
          </div>
        ),
        dataIndex: 'full_address',
        key: 'street',
        render: (text, record) =>
        record.full_address ? (
          record.full_address
        ) : (
          <span style={{fontSize:12}}>
            <UserOutlined size={30} style={{fontSize: 15}} /> {record.full_name} <Tag >{record.description ? record.description : 'Bewoner'}</Tag>
        </span>
      )
    },
  //   {
  //     title: 'Groepen',
  //     dataIndex: '',
  //     key: 'groups',
  //     width: 250,
  //     filters: this.props.groups && this.props.groups.map((elem) => {
  //       return {
  //         text: elem.name,
  //         value: elem.name,
  //       }
  //     }),
  //     filterMultiple: true,
  //     // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
  //     onFilter: (value, record) => this.groupsFilter(value, record),
  //     render: (text, record) => record.groups && record.groups.map((v) => (<Tag key={v.id} color={v.color}>{v.name}</Tag>)
  //   )
  // },
  {
    title: 'Groepen',
    dataIndex: '',
    key: 'groups',
    // width: 250,
    width: 250,
    // filters: this.props.groups && this.props.groups.map((elem) => {
    //   return {
    //     text: elem.name,
    //     value: elem.name,
    //   }
    // }),
    filterMultiple: true,
    // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 0 }}>

          <div>
              {this.props.groups && this.props.groups.map((obj) => {
                  return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                      <Checkbox
                          onChange={(e) => {
                              var my_group_filter = this.state.group_filter;

                              if(e.target.checked == true){ my_group_filter.push(obj.name); }
                              else{ this.removeItem(my_group_filter, obj.name); }

                              this.setState({ group_filter: my_group_filter });

                              setSelectedKeys(my_group_filter);
                          }}
                          checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                          >
                          {obj.name}
                      </Checkbox>
                  </div>
              })}
          </div>

          <div style={{padding: 8, width: '100%', clear: 'both'}}>
              <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
              <div style={{float: 'left', marginLeft: 8}}>filter</div>
          </div>

          <div style={{width: '100%', clear: 'both'}}>
              <Button
                type="link"
                onClick={() => { confirm(); }}
                style={{marginLeft: -8, marginRight: 8 }}
                >OK</Button>

              <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
          </div>

        </div>
    ),
    onFilter: (value, record) => this.groupsFilter(value, record),
    render: (text, record) =>
      record.groups && record.groups.map((v) => (<Tag color={v.color} key={v.id}>{v.name}</Tag>)
    )
  },
  {
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckLetterAll}>Post</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedLetterIds && this.state.selectedLetterIds.length}></Badge></div>
    ),
    key: 'letter',
    width: 120,
    render: (value, index) => {
      var valueId       = value.id;
      var disabledCheck = false;

      if(value.address_id) {
        valueId = 'occ-'+value.id
      }

      if(value.address_id && !value.different_address) {
        disabledCheck = true;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'letter-'+valueId} checked={this.state.selectedLetterIds && this.state.selectedLetterIds.includes(valueId)} value={valueId} onChange={this.onSelectLetterChange} />
      )
    }
  },{
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckEmailAll}>Mail</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedEmailIds && this.state.selectedEmailIds.length}></Badge></div>
    ),
    key: 'email',
    width: 120,
    render: (value, index) => {
      var valueId = value.id;
      var disabledCheck = false;

      if(!value.address_id && value.email != ''){
        disabledCheck = true;
      }

      if(value.email_address == undefined) {
        disabledCheck = true;
      }

      if(value.address_id) {
        valueId = 'occ-'+value.id
      } else {
        disabledCheck = true;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'email-'+valueId} checked={this.state.selectedEmailIds && this.state.selectedEmailIds.includes(valueId)} value={valueId} onChange={this.onSelectEmailChange} />
      )
    }
  }];

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write || !this.state.loaded} type="new" onClick={() => { this.saveAndContinue(); }}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/forms`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseFormSteps current={0} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
                <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item hasFeedback className="mbn" required label="Omschrijving">
                        {getFieldDecorator('name', {
                          //initialValue: this.props.support_base_form ? this.props.support_base_form.name : '',
                          initialValue: this.state.name,
                          rules: [{ required: true, message: 'Vul een omschrijving in' }],
                          onChange: (e) => { this.setState({ name: e.target.value }); }
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>

                </Row>
              </Form>

                <div>
                    <Row className="mbm">
                      <Col span={4}>
                        {/*<Button onClick={() => {

                          var expandIds = [];
                          if(!this.state.expandButton) {
                            this.state.currentDataSource.map((v) => {
                              if(v.occupants) {
                                expandIds.push('address-'+v.id)
                              }
                            });
                          }
                          this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
                        }}>Alles {this.state.expandButton ? 'inklappen' : 'uitklappen'}</Button>*/}
                      </Col>
                      <Col span={20}>
                        {/*<Input.Search
                          allowClear
                          placeholder="Zoeken..."
                          onSearch={value => this.onSearch(value)}
                          style={{ width: 200, float: 'right' }}
                          />*/}
                      </Col>
                    </Row>

                  <CustomTable
                    rowKey={(record) => {
                      if(record.address_id) {
                        return record.address_id + "-" + record.id;
                      } else {
                        return 'address-' + record.id;
                      }
                    }}
                    columns={columns}
                    dataSource={this.props.addresses.map((element) => {
                      if(element.occupants && element.occupants.length == 0) {
                        element.occupants = null;
                      }
                      return element;
                    })}
                    // dataSource={this.examples.map((element) => {
                    //   if(element.occupants && element.occupants.length == 0) {
                    //     element.occupants = null;
                    //   }
                    //   return element;
                    // })}
                    childrenColumnName="occupants"
                    indentSize="0"
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpandedRowsChange={(id) => this.setState({expandedRowKeys: id})}
                    loading={this.state.isFetching}
                    // onChange={(pagination, filters, sorter, extra) => {
                    //   this.setState({currentDataSource: extra.currentDataSource});
                    //   this.handleTableChange(pagination, filters, sorter);
                    // }}
                    onChange={(pagination, filters, sorter, extra) => {

                        this.handleTableChange(pagination, filters, sorter, extra);
                    }}
                    footer={(currentPageData) => { this.filteredDataSource = currentPageData; }}
                    />
                </div>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseFormAddressesForm = Form.create({ name: 'support_base_form' })(SupportBaseFormAddresses);

const mapStateToProps = (state, ownProps) => {
    return {
        // isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        support_base_form : state.support_base.support_base_form,
        addresses : state.support_base.addresses,
        groups : state.group.groups,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        addSupportBaseFormToProjectAction : bindActionCreators(addSupportBaseFormToProjectAction, dispatch),
        updateSupportBaseFormAction : bindActionCreators(updateSupportBaseFormAction, dispatch),
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
        searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseFormAddressesForm);
