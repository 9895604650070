import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Alert,
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDocumentAction, patchDocumentAction, addDocumentAction, resetDocumentAction } from '../../../../../actions/documentsActions';
import DocumentSteps from '../Steps/DocumentSteps'
import { showSuccess } from '../../../../../utils/Notifications'
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class DocumentDetail extends Component {

  state = {
    fileList: [],
    fileAlreadyUploaded: false,
    errorDocumentFile: false,
    errorDocumentSizeFile: false,
  }

  componentDidMount() {
    this.props.resetDocumentAction();

    if(this.props.match.params.documentid)
    {
      this.props.getDocumentAction(this.props.match.params.documentid).then(() => {
        this.setState({fileAlreadyUploaded: true});
      }); //.then(() => this.props.getLibrariesAction());
    }
  }

  patchDocument(e, redirect = false) {
    if(e) {
      e.preventDefault();
    }

    this.props.form.validateFields((err, values) => {

      values.hide_for_address = !values.hide_for_address;

      if(this.state.fileList.length == 0 && !this.state.fileAlreadyUploaded) {
        //showSuccess('Selecteer een document', null, 'close-circle', '#f5222d');
        this.setState({errorDocumentFile: true});
        return;
      } else {
        this.setState({errorDocumentFile: false});
      }

      if (!err) {

        if(this.props.match.params.documentid) {
          this.props.patchDocumentAction(this.props.match.params.documentid, values, this.state.fileList).then(() => {
            showSuccess('Succesvol opgeslagen')
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/document/${this.props.document.id}/addresses`);
          });
        } else {
          this.props.addDocumentAction(this.props.match.params.id, values, this.state.fileList).then(() => {
            showSuccess('Succesvol opgeslagen')
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/document/${this.props.document.id}/addresses`);
          });
        }

      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <Button disabled={!this.props.HaveAbility('document' ,'write')} type="new" onClick={() => this.patchDocument(null)}>Opslaan en volgende</Button>
            <Link to={`/projects/${this.props.match.params.id}/modules/documents`}>
              <Button>Sluiten</Button>
            </Link>
          </Col>
          {/*<Col span={12} align="right">
          <div>
          <Button disabled type="primary" style={{marginRight:10}}><Icon type="left" /> Vorige</Button>
          <Button type="primary" onClick={() => this.patchDocument(null, true)}>Volgende<Icon type="right" /></Button>
          </div>
          </Col>*/}
        </Row>
        <Row className="mbm">
          <Col span={24}>
            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
              <DocumentSteps {...this.props}/>
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card>

              <Form className={'ant-form--custom'} onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
                <Row gutter={24} >
                  <Col span={24}>
                    <Form.Item required hasFeedback label="Naam">
                      { getFieldDecorator('name', {
                        initialValue: this.props.document.name,
                        rules: [{ required: true, message: 'Vul een naam in' }],
                      })(
                        <Input />
                      ) }
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Openbaar">
                      { getFieldDecorator('is_public', {
                        // valuePropName: 'checked',
                        // defaultChecked: this.props.document.is_public == 1 ? true : false,
                        valuePropName: 'checked',
                        initialValue: this.props.document.is_public ? true : false
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      ) }
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Niet tonen op bewoners pagina">
                      { getFieldDecorator('do_not_show_in_front', {
                        // defaultChecked: this.props.document.do_not_show_in_front == 1 ? true : false,
                        valuePropName: 'checked',
                        initialValue: this.props.document.do_not_show_in_front ? true : false
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      ) }
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Tonen in dossier voor bewoner">
                      { getFieldDecorator('hide_for_address', {
                        // defaultChecked: this.props.document.do_not_show_in_front == 1 ? true : false,
                        valuePropName: 'checked',
                        initialValue: this.props.document.hide_for_address ? false : true
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      ) }
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item multiple={false} required label="Document">
                      <Upload
                        showUploadList={true}
                        accept=".pdf"
                        beforeUpload={(file) => {
                          if(file.size < 16000000) {
                            this.setState({fileList: {'file': file}});
                            this.setState({errorDocumentFile: false, errorDocumentSizeFile: false});
                          } else {
                            this.setState({fileList: null})
                            this.setState({errorDocumentFile: false, errorDocumentSizeFile: true});
                          }
                          return false;
                        }} onRemove={() =>{
                          this.setState({fileList: null});
                        }}>

                        <Button >
                          <UploadOutlined /> Upload een document
                          </Button>

                        </Upload>
                        {this.state.errorDocumentFile ? (<Alert message="Upload een bestand" type="error" showIcon />) : null}
                        {this.state.errorDocumentSizeFile ? (<Alert message="Bestand mag niet groter zijn dan 16mb" type="error" showIcon />) : null}
                        {this.props.document.file ? (
                          <Tag>{this.props.document.file}</Tag>
                        ) : null}

                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

              </Card>
            </Col>
          </Row>
        </div>
    );
    }
  }

const DocumentForm = Form.create({ name: 'document_form' })(DocumentDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.information.isFetching,
    isError : state.information.isError,
    document : state.document.document
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentAction : bindActionCreators(getDocumentAction, dispatch),
    patchDocumentAction : bindActionCreators(patchDocumentAction, dispatch),
    addDocumentAction : bindActionCreators(addDocumentAction, dispatch),
    resetDocumentAction : bindActionCreators(resetDocumentAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
