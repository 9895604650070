import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../../SupportBaseSideMenu';
import SupportBaseResendCorrespondenceSteps from './SupportBaseResendCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
    Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../../../utils/Notifications';
import CustomEditor from '../../../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseFormByIdAction, updateSupportBaseCorrespondenceAction, checkSupportBaseFormEmailAction } from '../../../../../../actions/supportBaseActions';

class SupportBaseResendCorrespondenceEmail extends React.Component {

    state = {
      subject: '',
      message: '',
      saved: true,
    }

    constructor(props) {
      super(props);
    }

    componentDidMount()
    {
         this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id).then(() => {
             this.setState({
                 'subject' : this.props.support_base_form.correspondences[2].subject,
                 'message' : this.props.support_base_form.correspondences[2].message
             });
         });
    }

    allowNextStep = () =>
    {
        var status = true;

        this.props && this.props.form && this.props.form.validateFields((errors, values) => {

            if(errors){ this.setState({ saved: false }); }

            status = (errors || !this.state.saved ? false : true);

            if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
        });

        return status;
    }

  saveAndContinue(do_continue = true, preview = false)
  {
      this.props.form.validateFields((errors, values) => {

          if(!errors || !do_continue)
          {
              this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'email', {
                  'subject' : values.subject,
                  'message': values.message,
              }).then(() => {

                  showSuccess('Succesvol opgeslagen');

                  if(do_continue)
                  {
                      this.props.history.push(`/projects/${this.props.match.params.id}/support-base/resend/${this.props.match.params.form_id}/correspondence/check`);
                  }

                  if(preview)
                  {
                      this.props.checkSupportBaseFormEmailAction(this.props.match.params.form_id);
                  }
              });
          }
      });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/resend/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseResendCorrespondenceSteps validate={true} validator={() => { return this.allowNextStep(); }} current={1} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="In de e-mail kan de placeholder [formulier ondertekenen knop] gebruikt worden om een knop te plaatsen naar het online formulier." />
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>

                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item label="Onderwerp e-mail">
                            {getFieldDecorator('subject', {
                              initialValue: this.state.subject,
                              rules: [{ required: true, message: 'Vul een onderwerp in' }],
                            })(
                              <Input />
                            )}
                        </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col align={'right'} span={24}>
                        <Button onClick={() => { this.saveAndContinue(false, true); }}>Voorbeeld</Button>
                      </Col>
                  </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item required label="Tekst e-mail">
                          { getFieldDecorator('message', {
                            initialValue: this.state.message,
                            rules: [{ required: true, message: 'Vul een tekst in' }],
                            valuePropName: 'value',
                            trigger: 'onEditorChange',
                          })(
                            <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} />
                          ) }
                        </Form.Item>
                    </Col>
                </Row>
              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseResendCorrespondenceEmailForm = Form.create({ name: 'intake_form' })(SupportBaseResendCorrespondenceEmail);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form : state.support_base.support_base_form,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        updateSupportBaseCorrespondenceAction : bindActionCreators(updateSupportBaseCorrespondenceAction, dispatch),
        checkSupportBaseFormEmailAction : bindActionCreators(checkSupportBaseFormEmailAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseResendCorrespondenceEmailForm);
