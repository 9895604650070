import api from '../utils/api'

export const getAddressesAction = (project_id, form_id = false, params = false) => dispatch => {

  dispatch({
    type: 'SUPPORT_BASE_FORM_ADDRESSES_ACTION_FETCH'
  })

  return api('post','support-base/project/' + project_id + '/form/' + form_id + '/addresses', params, true).then((response) => {
    dispatch({
      type: 'SUPPORT_BASE_FORM_ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'SUPPORT_BASE_ACTION_ERROR',
    })
    return false;
  });
}

export const getSupportBaseFormsByCustomerAction = () => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_FORMS_ACTION_FETCH'
    })

    return api('get','/support-base/forms/customer/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseFormsFromLibraryAction = () => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_FORMS_ACTION_FETCH'
    })

    return api('get','/support-base/forms/customer/' + customer_id, null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_LIB_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseFormByIdAction = (form_id, price_calculation = true, statistics = false) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('get','/support-base/form/'+form_id+'?price='+(price_calculation ? 1 : 0)+'&stats='+(statistics ? 1 : 0), null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseFormCorrespondencesAction = (form_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_FORM_CORRESPONDENCES_ACTION_FETCH'
    })

    return api('get','/support-base/form/' + form_id + '/correspondences', null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CORRESPONDENCES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchSupportBaseFormsByCustomerAction = (search) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_FORMS_ACTION_FETCH'
    })

    return api('post','/support-base/forms/customer/' + customer_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneSupportBaseFormsByCustomerAction = (ids) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_FORMS_ACTION_FETCH'
    })

    return api('post','/support-base/forms/customer/' + customer_id + '/clone', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteSupportBaseFormsByCustomerAction = (ids) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/forms/customer/' + customer_id + '/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_DELETE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const addSupportBaseFormToCustomerAction = (values) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/forms/customer/' + customer_id + '/add', values, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_ACTION_SUCCESS',
            payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const addSupportBaseFormToProjectAction = (project_id, values) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/forms/project/' + project_id + '/add', values, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_ACTION_SUCCESS',
            payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateSupportBaseFormAction = (form_id, values) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id + '/update', values, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_ACTION_SUCCESS',
            payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateSupportBaseCorrespondenceAction = (form_id, type, values) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id + '/correspondence/' + type, values, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_ACTION_SUCCESS',
            payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseFormsByProjectAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_FORMS_ACTION_FETCH'
    })

    return api('get','/support-base/forms/project/' + project_id, null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchSupportBaseFormsByProjectAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_FORMS_ACTION_FETCH'
    })

    return api('post','/support-base/forms/project/' + project_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchSupportBaseFormsAddressesAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_STATUS_ACTION_FETCH'
    })

    return api('post','/support-base/project/' + project_id + '/status/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_STATUS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneSupportBaseFormsByProjectAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/forms/project/' + project_id + '/clone', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneSupportBaseFormFromCustomerToProject = (project_id, form_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/project/' + project_id + '/form/' + form_id + '/clone', null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CLONE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteSupportBaseFormsByProjectAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/forms/project/' + project_id + '/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_DELETE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteSupportBaseFormAttachmentsAction = (form_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id + '/correspondence/attachments/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORMS_DELETE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const addSupportBaseFormAttachmentAction = (form_id, values, filelist) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id + '/correspondence/attachments/add', values, true, filelist).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_UPLOAD_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateSupportBaseFormAttachmentAction = (form_id, attachment_id, values, filelist) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id +'/correspondence/attachment/' + attachment_id + '/update', values, true, filelist).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_UPLOAD_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const checkSupportBaseFormAction = (form_id, type) => (dispatch, getState) => {

    return api('get','/support-base/form/' + form_id + '/check/form/' + type, null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: type == 'digital' ? 'text/html' : 'application/pdf' });

        if(type == 'digital')
        {
            reader.readAsText(blob);
        }
        else
        {
            reader.readAsDataURL(blob);
        }

        reader.onloadend = function (e) {

            var win = window.open();

            if(type == 'digital')
            {
                win.document.write(reader.result);
            }
            else
            {
                win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            }

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const checkSupportBaseReminderLetterAction = (reminder_id) => (dispatch, getState) => {

    return api('get','/support-base/reminder/' + reminder_id + '/check/letter', null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'application/pdf' });

        reader.readAsDataURL(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const checkSupportBaseFormDocumentAction = (form_id, attachment = false) => (dispatch, getState) => {

    return api('get','/support-base/form/' + form_id + '/check/document' + (attachment ? '/attachment' : ''), null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'application/pdf' });

        reader.readAsDataURL(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const checkSupportBaseReminderEmailAction = (reminder_id) => (dispatch, getState) => {

    return api('get','/support-base/reminder/' + reminder_id + '/check/email', null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'text/html' });

        reader.readAsText(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write(reader.result);

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const checkSupportBaseFormEmailAction = (form_id) => (dispatch, getState) => {

    return api('get','/support-base/form/' + form_id + '/check/email', null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'text/html' });

        reader.readAsText(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write(reader.result);

            win.document.close();
        }

    }).catch((e) => {
        return false;
    });
}

export const checkSupportBaseFormDocumentsAction = (form_id, email) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id + '/check/documents', {email: email}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CHECK_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const checkSupportBaseFormEmailsAction = (form_id, email) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_FORM_CHECK_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id + '/check/emails', {email: email}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CHECK_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CHECK_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseRemindersAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('get','/support-base/reminders/project/' + project_id, null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchSupportBaseRemindersAction = (project_id, search) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminders/project/' + project_id + '/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const cloneSupportBaseRemindersAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminders/project/' + project_id + '/clone', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDERS_CLONE_ACTION_SUCCESS',
            //payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteSupportBaseRemindersAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminders/project/' + project_id + '/delete', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDERS_DELETE_ACTION_SUCCESS',
            //payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const addSupportBaseReminderAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminders/project/' + project_id + '/add', null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDER_ADD_ACTION_SUCCESS',
            payload: response.data.reminder
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseReminderAction = (reminder_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('get','/support-base/reminder/' + reminder_id, null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateSupportBaseReminderAction = (reminder_id, type, values) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminder/' + reminder_id + '/' + type, values, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_REMINDER_ACTION_SUCCESS',
            payload: response.data.reminder
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const checkSupportBaseReminderDocumentsAction = (reminder_id, email) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminder/' + reminder_id + '/check/documents', {email: email}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CHECK_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const checkSupportBaseReminderEmailsAction = (reminder_id, email) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminder/' + reminder_id + '/check/emails', {email: email}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_CHECK_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const sendSupportBaseFormAction = (form_id, action = 'send') => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/form/' + form_id +'/send', { action: action }, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_SEND_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const scheduleSupportBaseReminderToAction = (reminder_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/reminder/' + reminder_id + '/schedule', null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_SCHEDULE_REMINDER_TO_ACTION_SUCCESS',
            // payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const saveSupportBaseSettingsAction = (project_id, settings) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/forms/project/' + project_id +'/settings', settings, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_SETTINGS_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const getSupportBaseStatusAction = (project_id) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_STATUS_ACTION_FETCH'
    })

    return api('get', '/support-base/project/' + project_id + '/status', null, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_STATUS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const resendSupportBaseToAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/project/' + project_id +'/resend-to', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_RESEND_TO_ACTION_SUCCESS',
            payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const remindSupportBaseToAction = (project_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/project/' + project_id +'/remind-to', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_RESEND_TO_ACTION_SUCCESS',
            payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const sendSupportBaseRemindersToAction = (project_id, reminder_id, ids) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/project/' + project_id + '/reminder/' + reminder_id + '/send', {ids: ids}, true).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_SEND_REMINDERS_TO_ACTION_SUCCESS',
            // payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const uploadSignedFormAction = (project_id, address_id, signed_form, agree, filelist) => (dispatch, getState) => {

    dispatch({
        type: 'SUPPORT_BASE_ACTION_FETCH'
    })

    return api('post','/support-base/project/' + project_id + '/status/form/upload', { address_id: address_id, signed_form: signed_form ? true : false, agree: agree ? 'agree' : 'disagree' }, true, filelist).then((response) => {
        dispatch({
            type: 'SUPPORT_BASE_FORM_UPLOAD_ACTION_SUCCESS',
            //payload: response.data.support_base_form
        });
    }).catch((e) => {
        dispatch({
            type: 'SUPPORT_BASE_ACTION_ERROR',
        })
        return false;
    });
}

export const downloadSignedPDFDocumentAction = (project_id, address_id) => (dispatch, getState) => {

    return api('get','/support-base/project/' + project_id + '/status/form/download/signed-document/' + address_id, null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'application/pdf' });

        reader.readAsDataURL(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

            win.document.close();
        }
    });
}

export const downloadTransactionPDFAction = (project_id, address_id) => (dispatch, getState) => {

    return api('get','/support-base/project/' + project_id + '/status/form/download/transaction/' + address_id, null, true, null, true).then((response) => {

        var reader = new FileReader();

        var blob = new Blob([response.data], { type: 'application/pdf' });

        reader.readAsDataURL(blob);

        reader.onloadend = function (e) {

            var win = window.open();

            win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

            win.document.close();
        }
    });
}

export const downloadSupportBaseFormExcelAction = (project_id, params) => (dispatch, getState) => {

    // dispatch({
    //     type: 'SUPPORT_BASE_ACTION_FETCH'
    // })

    return api('post','/support-base/project/' + project_id + '/status/form/download/excel', params, true).then((response) => {
        // dispatch({
        //     type: 'SUPPORT_BASE_FORM_DOWNLOAD_ACTION_SUCCESS',
        //     //payload: response.data.support_base_form
        // });
    }).catch((e) => {
        // dispatch({
        //     type: 'SUPPORT_BASE_ACTION_ERROR',
        // })
        return false;
    });
}

export const downloadSupportBaseFormExcelResultsAction = (project_id, params) => (dispatch, getState) => {

    // dispatch({
    //     type: 'SUPPORT_BASE_ACTION_FETCH'
    // })

    return api('post','/support-base/project/' + project_id + '/status/form/download/excel/results', params, true).then((response) => {
        // dispatch({
        //     type: 'SUPPORT_BASE_FORM_DOWNLOAD_ACTION_SUCCESS',
        //     //payload: response.data.support_base_form
        // });
    }).catch((e) => {
        // dispatch({
        //     type: 'SUPPORT_BASE_ACTION_ERROR',
        // })
        return false;
    });
}

export const downloadSignedDocumentsAction = (project_id, params) => (dispatch, getState) => {

    // dispatch({
    //     type: 'SUPPORT_BASE_ACTION_FETCH'
    // })

    return api('post','/support-base/project/' + project_id + '/status/form/download/signed-documents', params, true).then((response) => {
        // dispatch({
        //     type: 'SUPPORT_BASE_FORM_DOWNLOAD_ACTION_SUCCESS',
        //     //payload: response.data.support_base_form
        // });
    }).catch((e) => {
        // dispatch({
        //     type: 'SUPPORT_BASE_ACTION_ERROR',
        // })
        return false;
    });
}

export const resetSupportBaseAction = () => dispatch => {

  dispatch({
    type: 'SUPPORT_BASE_ACTION_RESET'
  })
}
