import axios from 'axios';
import { API_URL } from '../constants/settings';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const performRequest = (method, url, params, auth, files, download) => {

    const body = method === 'get' || method === 'delete' ? 'params' : 'data';
    var config = {};

    // new
    var formData = toFormData(params);

    if(files)
    {
        if(files) {
          Object.keys(files).map((k) => {
            formData.append(k, files[k]);
          });
        }

        config = {
            method,
            url,
            baseURL: API_URL,
            [body]: formData
        }

        if (auth) {
            config.headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.authToken}`
            }
        }
    }
    else
    {
        config = {
            method,
            url,
            baseURL: API_URL,
            [body]: (params ? formData : null)
            // [body]: params
        }

        if (auth) {
            config.headers = {
                'Authorization': `Bearer ${localStorage.authToken}`
            }
        }
    }

    if(download)
    {
        config.responseType = 'arraybuffer'; //blob
    }

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        if(error.response.status == 401 || error.response.status == 403)
        {
          if(window.location.pathname != '/403')
          {
              window.location = '/403';
          }
          else
          {
              return Promise.reject(error);
          }
        }
        else if(error.response.status == 500)
        {
          if(window.location.pathname != '/500')
          {
              window.location = '/500';
          }
          else
          {
              return Promise.reject(error);
          }
        }
        else
        {
          if(window.location.pathname != '/error')
          {
              window.location = '/error';
          }
          else
          {
              return Promise.reject(error);
          }
        }
    });

    return axios.request(config)
  }

  //
  function buildFormData(formData, data, parentKey = false, parentType = null)
  {
    if(data && ((typeof data === 'array') || ((typeof data === 'object') && !(data instanceof Date) && !(data instanceof File) && !moment.isMoment(data) && !dayjs.isDayjs(data)))) {

      if(Object.keys(data).length)
      {
          Object.keys(data).forEach((key) => {

            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key, typeof data);
          });
      }
      else
      {   // new: add empty array
          formData.append(parentKey, []);
      }
    }
    else if(parentKey)
    {
      if(typeof data === 'object' && moment.isMoment(data))
      { // moment to yyyy-mm-dd
        formData.append(parentKey, data.format('YYYY-MM-DD'));
      }
      if(typeof data === 'object' && dayjs.isDayjs(data))
      { // moment to yyyy-mm-dd
        formData.append(parentKey, data.format('YYYY-MM-DD'));
      }
      else if(typeof data === 'object' && data instanceof Date)
      { // moment to yyyy-mm-dd
        formData.append(parentKey, (data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate()));
      }
      else
      {
        var value = ((data === undefined) ? '' : (data ? ''+data : data));

        if(data === null || data === 'null'){ value = ''; }
        if(data === false){ value = '0'; }
        if(data === true){ value = '1'; }

        formData.append(parentKey, value);
      }
    }
  }

  //
  function toFormData(data)
  {
    var formData = new FormData();

    buildFormData(formData, data);

    return formData;
  }

  export default performRequest;
