import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  message,
  Drawer,
  Select,
} from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryUsersLibraryAction, searchDeliveryUsersLibraryAction, removeDeliveryUsersLibraryAction } from '../../actions/deliveryusersActions';
import { getLibraryListAction, cloneLibraryItemAction } from '../../actions/librariesActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';

class DeliveryUsersLibrary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      modalVisible: false,
      selectedId: null,
      list: [],
    };
  }

  componentDidMount() {
    this.props.getDeliveryUsersLibraryAction();

    if(this.props.HaveAbility('library', 'write')){
      this.props.getLibraryListAction('delivery-user').then(() => {
        this.setState({ list: this.props.list });
      });
    }
  }

  // handleSubmit = (e, values) => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.storeLibraryAction(values).then(() => {
  //         showSuccess('Succesvol opgeslagen');
  //
  //         this.setState({drawerVisible: false});
  //         this.props.form.resetFields();
  //       });
  //     }
  //   });
  // }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  cloneSelected = () => {
    this.props.cloneLibraryItemAction('delivery-user', this.state.selectedRowKeys[0]).then(() => {
      showSuccess('Succesvol gedupliceerd');
      this.props.getDeliveryUsersLibraryAction();
      this.setState({selectedRowKeys: []})
    });
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.removeDeliveryUsersLibraryAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({
              selectedRowKeys: [],
              modalVisible: false
          })
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  showDuplicate() {
      this.setState({ selectedId: null, modalVisible: true });
  }

  duplicate = () => {
    this.props.cloneLibraryItemAction('delivery-user', this.state.selectedId).then(() => {
      this.setState({selectedRowKeys: []});
      this.setState({modalVisible: false});
      showSuccess('Succesvol gedupliceerd');
      // this.props.getLibrariesAction();
      this.props.getDeliveryUsersLibraryAction();
    });
  }

  listItem = (element) => {
      // return (
      //     <Select.Option value={element.id}>{element.email_address}</Select.Option>
      // );

      return (
          <Select.OptGroup label={element.name}>
            {element.items.map((item) => (
                <Select.Option value={item.id}>{item.title}</Select.Option>
            ))}
          </Select.OptGroup>
      );
  }

  render() {

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'E-mailadres',
      sorter: (a, b) => { return a.email_address.localeCompare(b.email_address)},
      render: (text, record) => (
        <span>
          <Link to={`/library/delivery-user/${record.id}/detail`}>
            {text.email_address}
          </Link>
        </span>
      )
    },
    {
        title: 'Naam',
        dataIndex: 'full_name',

    },
    {
        title: 'Bedrijf',
        dataIndex: 'company_name',

    }];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Verantwoordelijken'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='18' />

            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12}>

                    <Dropdown disabled={!this.props.HaveAbility('library', 'write')} trigger={['click']} overlay={(
                      <Menu>
                        <Menu.Item key="1">
                          <Link to="/library/delivery-user/add">
                            Nieuw
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => this.showDuplicate()} disabled={!this.props.HaveAbility('library' ,'write')}>
                            Dupliceren
                        </Menu.Item>
                      </Menu>)}>
                      <Button type="new">
                          Toevoegen <DownOutlined />
                      </Button>
                    </Dropdown>

                    <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('library', 'write') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                        <Menu>
                          <Menu.Item disabled={this.state.selectedRowKeys.length > 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('library' ,'delete')} style={{ color: this.props.HaveAbility('library', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                        </Menu>

                      )}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>

                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ value => this.props.searchDeliveryUsersLibraryAction(value) }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey="id"
                      rowSelection={rowSelection}
                      // onChange={() => console.log('test')}
                      columns={columns}
                      loading={this.props.isFetching}
                      dataSource={this.props.isFetching ? [] : this.props.delivery_users}
                      />
                  </Col>
                </Row>
            </div>
        </div>

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          onOk={() => this.duplicate()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Verantwoordelijke">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer verantwoordelijke'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                {this.props.list && this.props.list.map((element) => this.listItem(element))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

    </div>
    );
  }
}

const DeliveryUsersLibraryForm = Form.create({ name: 'horizontal_login' })(DeliveryUsersLibrary);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.delivery_users.isFetching,
    isError : state.delivery_users.isError,
    delivery_users : state.delivery_users.delivery_users,
    list : state.library.list,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryUsersLibraryAction : bindActionCreators(getDeliveryUsersLibraryAction, dispatch),
    removeDeliveryUsersLibraryAction : bindActionCreators(removeDeliveryUsersLibraryAction, dispatch),
    searchDeliveryUsersLibraryAction : bindActionCreators(searchDeliveryUsersLibraryAction, dispatch),
    getLibraryListAction : bindActionCreators(getLibraryListAction, dispatch),
    cloneLibraryItemAction : bindActionCreators(cloneLibraryItemAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryUsersLibraryForm);
