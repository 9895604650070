import React, { Component } from 'react';
// import './AccountsDetail.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import { passwordValidation } from '../../../utils/validationRules'
import PageHeader from '../../../components/PageHeader/PageHeader';
import { getCustomersAction } from '../../../actions/customerActions';
import { deleteAccountsAction, getAccountAction, patchAccountAction, resetAccountsAction, addAccountAction } from '../../../actions/accountActions';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { PictureOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Menu,
  Dropdown,
  Typography,
  Button,
  Divider,
  Input,
  Avatar,
  Select,
  Upload,
  message,
} from 'antd';
import NumberFormat from 'react-number-format';
import api from '../../../utils/api';
import { API_URL } from '../../../constants/settings';
const { Title, Text } = Typography;

const Option = Select.Option;

class AccountsDetail extends Component {
  state = {
    disabled: true,
    edit: true,
    previewVisible: false,
    previewImage: '',
    deleteImage: false,
    filelist: []
  };

  componentDidMount() {
    this.props.resetAccountsAction();

    if(this.props.match.params.id) {
      this.props.getAccountAction(this.props.match.params.id);
    }

    if(this.props.HaveAbility('customer', 'write')) {
      //this.props.getCustomersAction();
    }

  }

  handleSubmit = e => {
    e.preventDefault();


    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(this.props.match.params.id) {
          this.props.patchAccountAction(this.props.match.params.id, values, this.state.filelist).then(() => {
            showSuccess();
          });
        }

      }
    });

  };

  showConfirm = () => {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteAccountsAction({'ids': [this.props.match.params.id]}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Wachtwoorden komen niet overeen');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  sendPassword() {
    api('post', 'auth/forget', {"email":this.props.account.email}, false).then(() => {
      showSuccess('Wachtwoord verzonden');
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': (this.props.account && this.props.account.first_name) ? this.props.account.first_name + (this.props.account.affix ? ' ' + this.props.account.affix + ' ' : ' ') + this.props.account.last_name : 'Toevoegen'}]} />
        <Row className="mbm">
          <Col span={12} >
            <Button type="new" onClick={this.handleSubmit}>
              Opslaan
            </Button>
          </Col>
        </Row>
        <Card style={{backgroundColor: 'white', borderRadius: '5px' }}>
          <Form layout={'vertical'} onSubmit={this.handleSubmit} colon={true}>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Persoonlijke gegevens</Title>
              </Col>
              <Col span={12}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Item label="Profielfoto">
                      <Form.Item className="mrs">
                        <Upload accept="image/*" beforeUpload={(file) => {
                            this.setState({filelist: {'avatar': file}});
                            return false;
                          }} onRemove={() =>{
                            this.setState({filelist: null});
                          }}>
                          {this.props.account && this.props.account.avatar && !this.state.deleteImage ? (
                            <span>
                              <Avatar shape="square" style={{width: 150, height: 150}} src={this.props.account ? this.props.account.avatar : ''} />
                            </span>
                          ) : (
                              <Button type="dashed" style={{width: 150, height: 150, backgroundColor: '#fafafa'}}><PictureOutlined style={{fontSize: 32}} /><div style={{textAlign: 'center'}}>Selecteer<br/>afbeelding</div></Button>
                          ) }

                        </Upload>
                        {this.props.account && this.props.account.avatar && !this.state.deleteImage ? (
                        <Button type="link" style={{ color: '#f5222d', width: 150 }} onClick={() => this.setState({filelist: {'avatar': 'remove'}, deleteImage: true})}>Verwijder</Button>
                        ) : null }
                      </Form.Item>
                  </Form.Item>

                  <Form.Item className="mrs" required label="Voornaam" hasFeedback>
                    {getFieldDecorator('first_name', {
                      initialValue: this.props.account && this.props.account.first_name,
                      rules: [{ required: true, message: 'Vul een voornaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                  <Form.Item className="mrs" label="Tussenvoegsel" hasFeedback>
                    {getFieldDecorator('affix', {
                      initialValue: this.props.account && this.props.account.affix,
                      rules: [{ required: false }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                  <Form.Item className="mrs" required label="Achternaam" hasFeedback>
                    {getFieldDecorator('last_name', {
                      initialValue: this.props.account && this.props.account.last_name,
                      rules: [{ required: true, message: 'Vul een achternaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </div>

              </Col>
              <Col span={12} style={{justifyContent: 'flex-end', display: 'flex'}}>

                {/*<Dropdown trigger={['click']} overlay={(
                  <Menu>
                  <Menu.Item key="1">Inloggen als deze gebruiker</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.sendPassword()}>Verzend wachtwoord</Menu.Item>
                  <Menu.Divider />
                  <Menu.Item key="3" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                  </Menu>
                  )}>
                  <Button type="secondary" className="mrs">
                  Acties <Icon type="down" />
                  </Button>
                  </Dropdown>*/}
                </Col>
              </Row>
              <Divider></Divider>
              <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>Contactgegevens</Title>
                </Col>

                <Col span={12}>

                  <Form.Item label="Telefoon" hasFeedback>
                    {getFieldDecorator('telephone', {
                      initialValue: this.props.account && this.props.account.telephone,
                      rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                    })(
                      <NumberFormat allowEmptyFormatting={true} format="##########" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
                    )}
                  </Form.Item>

                </Col>

                <Col span={12}>

                  <Form.Item label="Mobiel" hasFeedback>
                    {getFieldDecorator('mobile', {
                      initialValue: this.props.account && this.props.account.mobile,
                      rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                    })(
                      <NumberFormat allowEmptyFormatting={true} format="##########" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
                    )}
                  </Form.Item>

                </Col>

                <Col span={12}>

                  <Form.Item required label="E-mail" hasFeedback>
                    {getFieldDecorator('email', {
                      initialValue: this.props.account && this.props.account.email,
                      rules: [{ required: true, message: 'Vul een e-mailadres in'}, {type: 'email', message: 'Vul een geldig e-mailadres in'}],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>

                  <Form.Item label="Functie" hasFeedback>
                    {getFieldDecorator('function', {
                      initialValue: this.props.account && this.props.account && this.props.account.function,
                      rules: [{ required: false, message: 'Vul een functie in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>

                </Col>
              </Row>
              <Divider></Divider>
              <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>Wachtwoord</Title>
                </Col>
                <Col span={12}>

                  <Form.Item label="Nieuw wachtwoord" hasFeedback>
                    {getFieldDecorator('password', {
                      rules: [
                        { validator: passwordValidation },
                      ],
                    })(
                      <Input.Password />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>

                  <Form.Item label="Herhaal wachtwoord" hasFeedback>
                    {getFieldDecorator('password_confirmation', {
                      rules: [
                        {
                          message: 'Please confirm your password!',
                        },
                        {
                          validator: this.compareToFirstPassword,
                        },
                      ],
                    })(
                      <Input.Password onBlur={this.handleConfirmBlur}/>
                    )}
                  </Form.Item>

                </Col>
                <Divider></Divider>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>Overeenkomsten</Title>
                </Col>
                <Col span={12}>
                  <div>
                    <Text strong>Verwerkingsovereenkomst</Text>
                    <br />
                    <Text type="secondary">
                      Versie 1 akkoord op 2018-03-01 13:58:05
                    </Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Text strong>Privacybeleid</Text>
                    <br />
                    <Text type="secondary">
                      Versie 1 akkoord op 2018-03-01 13:58:05
                    </Text>
                  </div>
                </Col>

              </Row>
              <Divider />
              <Row gutter={24}>
                <Col span={12}>
                  <Button onClick={this.showConfirm} type='danger'>Verwijder account</Button>
                </Col>
              </Row>

            </Form>
          </Card>
        </div>
    );
    }
  }

const AccountDetailForm = Form.create({ name: 'account_detail' })(AccountsDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.account.isFetching,
    isError : state.account.isError,
    account: state.account.account,
    customers: state.customer.customers,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountAction : bindActionCreators(getAccountAction, dispatch),
    patchAccountAction : bindActionCreators(patchAccountAction, dispatch),
    resetAccountsAction : bindActionCreators(resetAccountsAction, dispatch),
    addAccountAction : bindActionCreators(addAccountAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    deleteAccountsAction : bindActionCreators(deleteAccountsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailForm);
