import React from 'react'
import { Steps } from 'antd';

class IntakeSteps extends React.Component {

  onChange = current => {
    // if(current == 0) {
    //   this.props.history.push(`/projects/${this.props.match.params.id}/intake/add`);
    // }
    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/intake/${this.props.match.params.intakeid}`);
    }
    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/intake/${this.props.match.params.intakeid}/addresses`);
    }
    if(current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/intake/${this.props.match.params.intakeid}/results`);
    }
  }

  render () {
    return(
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Formulier" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.intakeid} title="Adressen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.intakeid} title="Resultaten" />
      </Steps>
    );
  }
}

export default IntakeSteps;
