import api from '../utils/api';
import { API_URL } from '../constants/settings';

export const getQueuedItems = () => dispatch => {

  dispatch({
    type: 'QUEUE_ACTION_FETCH'
  })

  return api('get', '/jobs', null, true).then((response) => {
    // if(response.data.success) {
      dispatch({
        type: 'QUEUE_ACTION_SUCCESS',
        payload: response.data
      });

      return true;
    // } else {
    //   dispatch({
    //     type: 'QUEUE_ACTION_ERROR',
    //   })
    //   return false;
    // }
  }).catch((e) => {
    dispatch({
      type: 'QUEUE_ACTION_ERROR',
    })
    return false;
  });
}

export const getNrOfNewQueuedItems = () => dispatch => {

  dispatch({
    type: 'QUEUE_ACTION_FETCH'
  })

  return api('get', '/jobs-counter', null, true).then((response) => {
    // if(response.data.success) {
      dispatch({
        type: 'QUEUE_COUNTER_ACTION_SUCCESS',
        payload: response.data
      });

      return true;
    // } else {
    //   dispatch({
    //     type: 'QUEUE_ACTION_ERROR',
    //   })
    //   return false;
    // }
  }).catch((e) => {
    dispatch({
      type: 'QUEUE_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteQueuedItems = (params) => (dispatch, getState) => {

    dispatch({
        type: 'QUEUE_ACTION_FETCH'
    })

    return api('post', '/jobs/multidelete', params, true).then((response) => {

        dispatch({
          type: 'QUEUE_DELETE_ACTION_SUCCESS',
        });

        return true;
    }).catch((e) => {

      dispatch({
          type: 'QUEUE_ACTION_ERROR',
      });

      return false;
    });
}

export const downloadJobFileAction = (record, controller) => (dispatch, getState) => {

    var url = API_URL + '/job/'+ record.id +'/download?token=' + localStorage.getItem('authToken');

    var method = 'GET';

    let xhr = new XMLHttpRequest();

    xhr.open(method, url, true);

    if(method == 'POST')
    {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    }

    xhr.responseType = "blob";

    xhr.onload = function(e) {

      if (this.status == 200) {

        const blob = this.response;

        const a = document.createElement("a");

        document.body.appendChild(a);

        const blobUrl = window.URL.createObjectURL(blob);

        a.href = blobUrl;

        a.download = record.filename;

        a.click();

        setTimeout(() => {

          window.URL.revokeObjectURL(blobUrl);

          document.body.removeChild(a);

          // controller.refreshQueueCounter(true);

        }, 0);

        controller.refreshQueueCounter(true);
      }
    };

    // format params
    var formatted_params = '';

    xhr.send(formatted_params);
}
