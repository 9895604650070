import React, { Component } from 'react';
// import './Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomersAction, addCustomerAction, deleteCustomersAction, searchCustomerAction, resetCustomersAction } from '../../actions/customerActions';
import { getMyCustomersAction } from '../../actions/authAction';
import CustomTable from '../../components/CustomTable/CustomTable';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Row, Drawer, Col, Select, message } from 'antd';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';

function getPackageName(id)
{
    if(id == 0){ return 'Potentiële klant'; }
    else if(id == 1){ return '30 dagen'; }
    else if(id == 2){ return 'Aannemer'; }
    else if(id == 3){ return 'Woco'; }

    //
    return '';
}

const columns = [{
  title: 'Bedrijfsnaam',
  dataIndex: 'name',
  sorter: (a, b) => { return a.name.localeCompare(b.name)},
  render: (text, record) => (
    <Link to={`/customers/${record.id}/general`}>
      {text}
    </Link>
      )
    },
    {
      title: 'Projecten',
      render : (index, record) => (
          <div>{record.nr_of_projects} / {record.license_nr_of_projects}</div>
      )
    },
    {
      title: 'Gebruikers',
      render : (index, record) => (
          <div>{record.nr_of_users} / {record.license_nr_of_users}</div>
      )
    },
    {
      title: 'Plaats',
      dataIndex: 'city',
    },
    // {
    //   title: 'Type vestiging',
    //   dataIndex: 'parent_id',
    //   render: (index, record) => (
    //     <div>{record.parent_id ? 'Subvestiging' : 'Hoofdvestiging'}</div>
    //   ),
    //   filters: [
    //     {
    //       text: 'Hoofdvestiging',
    //       value: 'head',
    //     },
    //     {
    //       text: 'Subvestiging',
    //       value: 'sub',
    //     },
    //   ],
    //   onFilter: (value, record) => {
    //
    //     if(value === 'head' && record.parent_id != null){
    //       return record;
    //     }
    //
    //     if(value === 'sub' && record.parent_id == null){
    //       return record;
    //     }
    //
    //   },
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (index, record) => (
        <div>{record.status === 1 ? 'Actief' : 'Inactief'}</div>
      ),
      filters: [
        {
          text: 'Actief',
          value: 1,
        },
        {
          text: 'Inactief',
          value: 2,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Type account',
      dataIndex: 'package_id',
      render: (index, record) => (
        <div>{getPackageName(record.package_id)}</div>
      ),
      filters: [
        {
          text: 'Potentiële klant',
          value: '0',
        },
        {
          text: '30 dagen',
          value: '1',
        },
        {
          text: 'Aannemer',
          value: '2',
        },
        {
          text: 'Woco',
          value: '3',
        },
      ],
      onFilter: (value, record) => {
        if(value == record.package_id){
          return record;
        }
      },
    },
    {
      title: 'Facturatie',
      dataIndex: 'send_invoice',
      render: (index, record) => (
        <div>{record.send_invoice ? 'Ja' : 'Nee'}</div>
      ),
      filters: [
        {
          text: 'Ja',
          value: true,
        },
        {
          text: 'Nee',
          value: false,
        },
      ],
      onFilter: (value, record) => record.send_invoice == value,
      sorter: (a, b) => { return (Number(a.send_invoice) - Number(b.send_invoice)) === 0 ? 0 : (Number(a.send_invoice) - Number(b.send_invoice)) },
    }
];

const { Option } = Select;

class Customers extends Component {
  state = {
    selectedRowKeys: [],
    visible: false,
    drawer: false,
    search: false,
    filteredCustomer: [],
  };

  // deleteItems() {
  //   Modal.confirm({
  //     title: 'Weet je zeker dat je deze items wilt verwijderen?',
  //     content: 'Klik op JA om deze actie te bevestigen.',
  //     okText: 'Ja',
  //     okType: 'danger',
  //     cancelText: 'Nee',
  //     onOk: () => {
  //       return new Promise((resolve, reject) => {
  //         this.props.deleteCustomersAction({'ids': this.state.selectedRowKeys}).then(() => {
  //           resolve();
  //           showSuccess('Succesvol verwijderd');
  //         });
  //       }).catch(() => console.log('Oops errors!'));
  //     },
  //     onCancel() {},
  //   });
  // }

  showConfirm() {

    showMultiDelete((multidelete) => {

      if(multidelete) {

        this.props.deleteCustomersAction({'ids': this.state.selectedRowKeys}).then(() => {

          this.setState({selectedRowKeys: []});

          this.props.getMyCustomersAction().then(() => {

            showSuccess('Succesvol verwijderd');
          });
        });
      }
    });
  }

  componentDidMount() {

    this.props.resetCustomersAction();

    this.props.getCustomersAction();
  }

  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      drawer: false,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.addCustomerAction(values).then(() => {
          this.onClose();
        });
      }
    });
  };

  onSearch(value) {
    this.props.searchCustomerAction(value);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedRowKeys }   = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    return (
      <div>
        <PageHeader path={[{'label': 'Klanten'}]} />

        <Row className="mbm">
          <Col span={12} >
            <Link to={'customers/add'}>
              <Button icon={<PlusOutlined />} type="primary ant-btn-new" >
                Toevoegen
              </Button>
            </Link>
            <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                <Menu>
                  {/*<Menu.SubMenu title="Status wijzigen">
                    <Menu.Item key="1">Activeren</Menu.Item>
                    <Menu.Item key="2">Deactiveren</Menu.Item>
                  </Menu.SubMenu>
                  <Menu.Divider />*/}
                  <Menu.Item onClick={() => this.showConfirm()} key="3" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                </Menu>
              )}>

              <Button type="secondary">
                Acties <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col span={12}>
            <Input.Search
              allowClear
              placeholder="Zoeken..."
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>
        <Row>
          <Col span={24}>

            <CustomTable
              size="middle"
              rowKey='id'
              rowSelection={rowSelection}
              loading={this.props.isFetching}
              columns={columns}
              dataSource={this.state.search ? this.state.filteredCustomer : (this.props.isFetching ? [] : this.props.customers)}
              />

          </Col>
        </Row>
            </div>
    );
        }
      }

const CustomersAdd = Form.create({ name: 'add_customer' })(Customers);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customers: state.customer.customers,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCustomersAction : bindActionCreators(resetCustomersAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    addCustomerAction : bindActionCreators(addCustomerAction, dispatch),
    deleteCustomersAction : bindActionCreators(deleteCustomersAction, dispatch),
    searchCustomerAction : bindActionCreators(searchCustomerAction, dispatch),
    getMyCustomersAction : bindActionCreators(getMyCustomersAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersAdd);
