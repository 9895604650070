import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSurveyAction, getSurveyQuestionsAction, patchSurveyAction, addSurveyAction, resetSurveyAction, updateSurveyQuestionsOrderAction } from '../../../../../actions/surveysActions';
import { getLibrariesAction } from '../../../../../actions/librariesActions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { showSuccess } from '../../../../../utils/Notifications';
import AddSurvey from '../Form/AddSurvey';
import SurveySteps from '../Steps/SurveySteps';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class SurveyDetail extends Component {

    constructor(props) {
      super(props);
    }
    componentDidMount() {
      this.props.resetSurveyAction();
      //
      if(this.props.match.params.surveyid) {
          this.props.getSurveyAction(this.props.match.params.surveyid);
      }
    }

    patchSurvey = () => {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          if(this.props.match.params.surveyid) {
            this.props.patchSurveyAction(this.props.match.params.surveyid, values).then(() => {
              this.props.history.push('questions' + (this.props.survey.version > 1 ? '-v' + this.props.survey.version : ''));
            });
          } else {
            this.props.addSurveyAction(this.props.match.params.id, values).then(() => {
              this.props.history.push(this.props.survey.id + '/questions' + (this.props.survey.version > 1 ? '-v' + this.props.survey.version : ''));
            });
          }
          showSuccess('Succesvol opgeslagen')
        }
      })
    }

    render() {
        return (
          <div>
            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button disabled={!this.props.HaveAbility('survey' ,'write')} type="new" onClick={() => this.patchSurvey()}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/modules/enquete`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <SurveySteps version={this.props.survey ? this.props.survey.version : 2} {...this.props}/>
                </Card>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Card>
                  <AddSurvey wrappedComponentRef={(form) => this.form = form} {...this.props}/>
                </Card>
              </Col>
            </Row>

            {/*<Row className="mbm">
              <Button type="new" icon="plus" onClick={() => {}}>Vraag toevoegen</Button>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <CustomTable />
              </Col>
            </Row>*/}
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.survey.isFetching,
      isError : state.survey.isError,
      survey: state.survey.survey,
      project: state.project.project,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getSurveyAction : bindActionCreators(getSurveyAction, dispatch),
      addSurveyAction : bindActionCreators(addSurveyAction, dispatch),
      patchSurveyAction : bindActionCreators(patchSurveyAction, dispatch),
      resetSurveyAction : bindActionCreators(resetSurveyAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetail);
