import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showSuccess } from '../../utils/Notifications';
import { getCustomersAction } from '../../actions/customerActions';
import { getLicensesAction, getLicenseAction, resetLicenseAction } from '../../actions/licenseActions';
import { getCobeeOptionAction, updateCobeeOptionAction, resetCobeeOptionAction } from '../../actions/cobeeoptionActions';
import CustomTable from '../../components/CustomTable/CustomTable';
import AdministrationSideMenu from './AdministrationSideMenu';
import { Form } from '@ant-design/compatible';
import { Row, Col, Typography, Button, Switch, Divider, DatePicker, Collapse, Table, Select, InputNumber, Input, Checkbox } from 'antd';
import PageHeader from '../../components/PageHeader/PageHeader';
import moment from 'moment';
import { API_URL } from '../../constants/settings';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const { Title } = Typography;
const { Panel } = Collapse;

// amount = per year
const customers_paying = [
    // {
    //     id: 1,
    //     amount: 5000,
    // },
    {
        id: 66,
        amount: 5025,
    },
    {
        id: 34,
        amount: 4200,
    },
    {
        id: 60,
        amount: 12450,
    },
    {
        id: 45,
        amount: 7380,
    },
    {
        id: 73,
        amount: 8745,
    },
    {
        id: 70,
        amount: 5025,
    },
    {
        id: 44,
        amount: 10950,
    },
    {
        id: 90,
        amount: 6300,
    },
    {
        id: 75,
        amount: 3900,
    },
    {
        id: 67,
        amount: 35006,
    },
    {
        id: 91,
        amount: 6525,
    },
    {
        id: 58,
        amount: 5025,
    },
    {
        id: 82,
        amount: 3575,
    },
    {
        id: 87,
        amount: 2310,
    },
    {
        id: 17,
        amount: 8580,
    },
    {
        id: 88,
        amount: 2700,
    },
    {
        id: 78,
        amount: 25500,
    },
    {
        id: 77,
        amount: 13740,
    },
    {
        id: 39,
        amount: 6208,
    },
    {
        id: 22,
        amount: 5790,
    },
    {
        id: 30,
        amount: 7995,
    },
    {
        id: 15,
        amount: 5790,
    },
    {
        id: 80,
        amount: 8250,
    },
    {
        id: 2,
        amount: 28260,
    },
    {
        id: 85,
        amount: 2070,
    },
    {
        id: 69,
        amount: 9150,
    },
    {
        id: 81,
        amount: 28820,
    },
    {
        id: 89,
        amount: 10500,
    },
    {
        id: 64,
        amount: 6300,
    },
    {
        id: 24,
        amount: 2640,
    },
    {
        id: 61,
        amount: 9000,
    },
    {
        id: 79,
        amount: 1800,
    },
    {
        id: 83,
        amount: 6300,
    },
    {
        id: 68,
        amount: 5850,
    },
    {
        id: 65,
        amount: 10175,
    },
    {
        id: 63,
        amount: 5100,
    },
    {
        id: 53,
        amount: 8370,
    },
    {
        id: 71,
        amount: 14520,
    },
    {
        id: 38,
        amount: 2880,
    },
    {
        id: 47,
        amount: 5980,
    },
    {
        id: 84,
        amount: 5995,
    },
    {
        id: 55,
        amount: 8250,
    },
    {
        id: 27,
        amount: 12840,
    },
    {
        id: 29,
        amount: 8100,
    },
    {
        id: 40,
        amount: 9000,
    }
  ];

/**
 *
 */
class PriceCalculator extends Component {

  state = {
      loading: true,
  };

  componentDidMount()
  {
    //
    this.loadSettings();
  }

  loadSettings()
  {
    this.props.getCobeeOptionAction('price-calculation').then((data) => {

        if(!data || typeof data != 'object')
        {   // default data
            data = {
          
                customer_id: null,
          
                basic_price: 200,
                price_per_project: 50,
                price_per_user: 50,
          
                price_module_appointment: 10,
                price_module_communication: 10,
                price_module_support_base: 10,
                price_module_form: 10,
                price_module_choice: 10,
                price_module_deliver: 10,
          
                nr_of_appointment_projects: 0,
                nr_of_communication_projects: 0,
                nr_of_support_base_projects: 0,
                nr_of_form_projects: 0,
                nr_of_choice_projects: 0,
                nr_of_deliver_projects: 0,
          
                rates: [
                    {
                        min_nr_of_projects: 0,
                        max_nr_of_projects: 5,
                        min_nr_of_users: 0,
                        max_nr_of_users: 10,
                        price_per_month: 200,
                        discount_percentage: 0,
                    },
                    {
                        min_nr_of_projects: 6,
                        max_nr_of_projects: 10,
                        min_nr_of_users: 11,
                        max_nr_of_users: 20,
                        price_per_month: 300,
                        discount_percentage: 5,
                    },
                    {
                        min_nr_of_projects: 11,
                        max_nr_of_projects: 25,
                        min_nr_of_users: 21,
                        max_nr_of_users: 50,
                        price_per_month: 400,
                        discount_percentage: 10,
                    },
                    {
                        min_nr_of_projects: 51,
                        max_nr_of_projects: 100,
                        min_nr_of_users: 101,
                        max_nr_of_users: 200,
                        price_per_month: 500,
                        discount_percentage: 15,
                    },
                    {
                        min_nr_of_projects: 101,
                        max_nr_of_projects: null,
                        min_nr_of_users: 201,
                        max_nr_of_users: null,
                        price_per_month: 600,
                        discount_percentage: 20,
                    }
                ],
          
                customers: [],
          
                customers_new_totals: [],
          
                exclude_customer_ids: [],
            };
        }

        data.loading = true;

        this.setState(data, () => {

            this.props.getCustomersAction().then(() => {

                if(!data.customers || data.customers.length < 1)
                {
                    data.customers = [];

                    this.props.licenses.map((customer) => {

                        data.customers.push(customer);
                    });

                    this.setState({ customers: data.customers });
                }

                this.props.resetLicenseAction();
        
                this.props.getLicensesAction().then(() => {

                    this.props.licenses.map((customer) => {
        
                        this.calculateCustomerTotalAmount(customer.id);
                    });

                    this.setState({ loading: false });
                });
            });
        });
    });
  }

  saveSettings()
  {
    if(!this.loading)
    {
        this.props.updateCobeeOptionAction('price-calculation', JSON.stringify(this.state));
    }
  }

  selectCustomer(id)
  {
      this.setState({ customer_id: id }, () => {

        if(id)
        {
          this.props.getLicenseAction(this.state.customer_id).then(() => {

              this.setState({
                  nr_of_projects: this.props.license.nr_of_projects,
                  nr_of_users: this.props.license.nr_of_users,
                  nr_of_appointment_projects: this.props.license.nr_of_appointment_projects,
                  nr_of_communication_projects: this.props.license.nr_of_communication_projects,
                  nr_of_support_base_projects: this.props.license.nr_of_support_base_projects,
                  nr_of_form_projects: this.props.license.nr_of_form_projects,
                  nr_of_choice_projects: this.props.license.nr_of_choice_projects,
                  nr_of_deliver_projects: this.props.license.nr_of_deliver_projects,
              }, () => { this.updateCalculation(); });
          });
        }
        else
        {
            this.setState({
                nr_of_projects: null,
                nr_of_users: null,
                nr_of_appointment_projects: null,
                nr_of_communication_projects: null,
                nr_of_support_base_projects: null,
                nr_of_form_projects: null,
                nr_of_choice_projects: null,
                nr_of_deliver_projects: null,
            }, () => { this.updateCalculation(); });
        }
      });
  }

  getModulePrice(name)
  {
      if(name == 'Afspraken')
      {
          return this.state.price_module_appointment;
      }
      else if(name == 'Communicatie')
      {
          return this.state.price_module_communication;
      }
      else if(name == 'Draagvlak')
      {
          return this.state.price_module_support_base;
      }
      else if(name == 'Formulieren')
      {
          return this.state.price_module_form;
      }
      else if(name == 'Keuze')
      {
          return this.state.price_module_choice;
      }
      else if(name == 'Oplevering')
      {
          return this.state.price_module_deliver;
      }

      //
      return 0;
  }

  updateModulePrice(name, price)
  {
      if(name == 'Afspraken')
      {
          this.setState({ price_module_appointment: price }, () => { this.updateCalculation(); });
      }
      else if(name == 'Communicatie')
      {
          this.setState({ price_module_communication: price }, () => { this.updateCalculation(); });
      }
      else if(name == 'Draagvlak')
      {
          this.setState({ price_module_support_base: price }, () => { this.updateCalculation(); });
      }
      else if(name == 'Formulieren')
      {
          this.setState({ price_module_form: price }, () => { this.updateCalculation(); });
      }
      else if(name == 'Keuze')
      {
          this.setState({ price_module_choice: price }, () => { this.updateCalculation(); });
      }
      else if(name == 'Oplevering')
      {
          this.setState({ price_module_deliver: price }, () => { this.updateCalculation(); });
      }
  }

  updateNrOfModuleProjects(name, nr)
  {
      if(name == 'Afspraken')
      {
          this.setState({ nr_of_appointment_projects: nr }, () => { this.updateCalculation(); });
      }
      else if(name == 'Communicatie')
      {
          this.setState({ nr_of_communication_projects: nr }, () => { this.updateCalculation(); });
      }
      else if(name == 'Draagvlak')
      {
          this.setState({ nr_of_support_base_projects: nr }, () => { this.updateCalculation(); });
      }
      else if(name == 'Formulieren')
      {
          this.setState({ nr_of_form_projects: nr }, () => { this.updateCalculation(); });
      }
      else if(name == 'Keuze')
      {
          this.setState({ nr_of_choice_projects: nr }, () => { this.updateCalculation(); });
      }
      else if(name == 'Oplevering')
      {
          this.setState({ nr_of_deliver_projects: nr }, () => { this.updateCalculation(); });
      }
  }

  getNrOfProjectsForModule(name)
  {
      if(name == 'Afspraken')
      {
          return this.state.nr_of_appointment_projects;
      }
      else if(name == 'Communicatie')
      {
          return this.state.nr_of_communication_projects;
      }
      else if(name == 'Draagvlak')
      {
          return this.state.nr_of_support_base_projects;
      }
      else if(name == 'Formulieren')
      {
          return this.state.nr_of_form_projects;
      }
      else if(name == 'Keuze')
      {
          return this.state.nr_of_choice_projects;
      }
      else if(name == 'Oplevering')
      {
          return this.state.nr_of_deliver_projects;
      }

      //
      return '';
  }

  updateCalculation()
  {
      var totals = this.getTotals(this.state.nr_of_projects, this.state.nr_of_users);

      //
      this.setState({
          calculated_basic_price: totals.calculated_basic_price,
          calculated_projects_price: totals.calculated_projects_price,
          calculated_users_price: totals.calculated_users_price,
          calculated_total_price: totals.calculated_total_price,
      });

      //
      this.updateCustomersTotal();
  }

  getTotals(nr_of_projects, nr_of_users, discount_percentage = null, customer = null)
  {
    var total_price = 0;
    var basic_price = 0;
    var projects_price = 0;
    var users_price = 0;

    // basic price
    basic_price = this.state.basic_price;

    // projects price
    projects_price = nr_of_projects * this.state.price_per_project;
    
    if(!customer)
    {
        projects_price += this.state.nr_of_appointment_projects * this.state.price_module_appointment;
        projects_price += this.state.nr_of_communication_projects * this.state.price_module_communication;
        projects_price += this.state.nr_of_support_base_projects * this.state.price_module_support_base;
        projects_price += this.state.nr_of_form_projects * this.state.price_module_form;
        projects_price += this.state.nr_of_choice_projects * this.state.price_module_choice;
        projects_price += this.state.nr_of_deliver_projects * this.state.price_module_deliver;
    }
    else
    {
        var ratio = (nr_of_projects / customer.nr_of_projects); //  (customer.custom_nr_of_projects > 0 ? customer.custom_nr_of_projects : customer.nr_of_projects));

        if(Math.round(ratio) > 0)
        {
            if(parseInt(Math.round(customer.nr_of_appointment_projects * ratio)) > 0)
            {
                projects_price += parseInt(Math.round(customer.nr_of_appointment_projects * ratio)) * this.state.price_module_appointment;
            }
            
            if(parseInt(Math.round(customer.nr_of_communication_projects * ratio)) > 0)
            {
                projects_price += parseInt(Math.round(customer.nr_of_communication_projects * ratio)) * this.state.price_module_communication;
            }

            if(parseInt(Math.round(customer.nr_of_support_base_projects * ratio)) > 0)
            {
                projects_price += parseInt(Math.round(customer.nr_of_support_base_projects * ratio)) * this.state.price_module_support_base;
            }

            if(parseInt(Math.round(customer.nr_of_form_projects * ratio)) > 0)
            {
                projects_price += parseInt(Math.round(customer.nr_of_form_projects * ratio)) * this.state.price_module_form;
            }

            if(parseInt(Math.round(customer.nr_of_choice_projects * ratio)) > 0)
                {
                projects_price += parseInt(Math.round(customer.nr_of_choice_projects * ratio)) * this.state.price_module_choice;
            }
            
            if(parseInt(Math.round(customer.nr_of_deliver_projects * ratio)) > 0)
            {
                projects_price += parseInt(Math.round(customer.nr_of_deliver_projects * ratio)) * this.state.price_module_deliver;
            }
        }
    }

    // users price
    users_price = nr_of_users * this.state.price_per_user;

    // apply rate?
    if(this.state.use_rates)
    {
        var discount_percentage = 0;

        this.state.rates.map((rate) => {

           if(rate.min_nr_of_projects <= nr_of_projects || rate.min_nr_of_users <= nr_of_users)
           {
               discount_percentage = rate.discount_percentage;
               basic_price = rate.price_per_month;
           }
        });

        projects_price = (projects_price * ((100 - discount_percentage) / 100));
        users_price = (users_price * ((100 - discount_percentage) / 100));
    }
    else
    {
        if(!customer)
        {
            var discount_percentage = 0;

            if(!discount_percentage)
            {
                discount_percentage = this.state.global_discount >= 0 ? this.state.global_discount : 0;
            }

            basic_price = (basic_price * ((100 - discount_percentage) / 100));
            projects_price = (projects_price * ((100 - discount_percentage) / 100));
            users_price = (users_price * ((100 - discount_percentage) / 100));
        }
        else
        {
            var discount_percentage = customer.custom_discount_percentage ? customer.custom_discount_percentage : 0;

            basic_price = (basic_price * ((100 - discount_percentage) / 100));
            projects_price = (projects_price * ((100 - discount_percentage) / 100));
            users_price = (users_price * ((100 - discount_percentage) / 100));
        }
    }

    //
    total_price = basic_price + projects_price + users_price;

    //
    return {
        calculated_basic_price: basic_price,
        calculated_projects_price: projects_price,
        calculated_users_price: users_price,
        calculated_total_price: total_price,
    };
  }

  updateRate(index, attribute, value)
  {
      var rates = this.state.rates;

      rates[index][attribute] = value;

      this.setState({ rates: rates }, () => { this.updateCalculation(); });
  }

  formatPrice(price)
  {
      if(!price && price !== 0){ return ''; }

      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
  }

  getCustomerTotalAmount(customer_id)
  {
    var amount = 0;

    customers_paying.map((customer) => {

        if(customer.id == customer_id)
        {
            amount = Math.round(customer.amount / 12, 0); // convert to month

            return;
        }
    });

    return amount;
  }

  updateCustomerValue(customer_id, value, field)
  {
    var customers = this.state.customers;

    customers.map((customer, i) => {

        if(customer && customer.id == customer_id)
        {
            customers[i][field] = value;

            return;
        }
    });

    this.setState({ customers: customers }, () => {

        this.calculateCustomerTotalAmount(customer_id);
    });
  }

  calculateCustomerTotalAmount(customer_id)
  {
    //
    var customers_new_totals = this.state.customers_new_totals;

    if(this.state.exclude_customer_ids[customer_id] === true)
    {
        customers_new_totals[customer_id] = this.getCustomerTotalAmount(customer_id);
    }
    else
    {
        var customer = null;
        var index = false;

        // this.props.licenses.map((record) => {
        this.state.customers.map((record, i) => {

            if(record.id == customer_id)
            {
                customer = record;
                index = i;

                return;
            }
        });

        if(!customer){ return null; }
        if(customer.id == 1 || customer.id == 72){ return null; }
        if(customer.status != '1'){ return null; }

        var nr_of_projects = customer.custom_nr_of_projects ? customer.custom_nr_of_projects : customer.nr_of_projects;
        var nr_of_users = customer.custom_nr_of_users ? customer.custom_nr_of_users : customer.nr_of_users;
        var discount_percentage = this.state.global_discount;

        if(this.state.customers && this.state.customers[index] && this.state.customers[index]['custom_nr_of_projects'] >= 0)
        {
            nr_of_projects = this.state.customers[index]['custom_nr_of_projects'];
        }
        else if(this.state.customers && this.state.customers[index] && this.state.customers[index]['nr_of_projects'] >= 0)
        {
            nr_of_projects = this.state.customers[index]['nr_of_projects'];
        }

        if(this.state.customers && this.state.customers[index] && this.state.customers[index]['custom_nr_of_users'] >= 0)
        {
            nr_of_users = this.state.customers[index]['custom_nr_of_users'];
        }
        else if(this.state.customers && this.state.customers[index] && this.state.customers[index]['nr_of_users'] >= 0)
        {
            nr_of_users = this.state.customers[index]['nr_of_users'];
        }

        if(this.state.customers && this.state.customers[index] && this.state.customers[index]['custom_discount_percentage'] >= 0)
        {
            discount_percentage = this.state.customers[index]['custom_discount_percentage'];
        }
        if(this.state.customers && this.state.customers[index] && this.state.customers[index]['discount_percentage'] >= 0)
        {
            discount_percentage = this.state.customers[index]['discount_percentage'];
        }

        var totals = this.getTotals(nr_of_projects, nr_of_users, discount_percentage, customer);

        customers_new_totals[customer_id] = totals.calculated_total_price;
    }

    //
    this.setState({ customers_new_totals: customers_new_totals }, () => { 
        
        this.saveSettings(); 
    });

    //
    return customers_new_totals[customer_id];
  }

  updateCustomersTotal()
  {
    this.state.customers.map((customer) => {

        if(!customer){}
        else if(customer.id == 1 || customer.id == 72){}
        else if(customer.status != '1'){}
        else
        {
            this.calculateCustomerTotalAmount(customer.id);
        }
    });
  }

  getCustomersNewTotal()
  {
    var total = 0;

    this.state.customers_new_totals.map((customer_total) => {

        total += customer_total;
    });

    //
    return total;
  }

  getCustomersTotal()
  {
    var total = 0;

    this.state.customers.map((customer) => {

        if(!customer){}
        else if(customer.id == 1 || customer.id == 72){}
        else if(customer.status != '1'){}
        else
        {      
            total += this.getCustomerTotalAmount(customer.id);
        }
    });

    //
    return total;
  }

  toggleCustomerInclusion(customer_id, status)
  {
    this.setState({ exclude_customer_ids: { ...this.state.exclude_customer_ids, [customer_id]: status }}, () => {

        this.calculateCustomerTotalAmount(customer_id);
    });
  }

  customerExceedsLicense(customer)
  {
    if(customer.license_nr_of_projects >= 1 && customer.license_nr_of_projects < customer.nr_of_projects)
    {
        return true;
    }

    if(customer.license_nr_of_users >= 1 && customer.license_nr_of_users < customer.nr_of_users)
    {
        return true;
    }

    return false;
  }

  render()
  {
    const optional_modules = [
        {
            name: 'Afspraken',
        },
        {
            name: 'Communicatie',
        },
        {
            name: 'Draagvlak',
        },
        {
            name: 'Formulieren',
        },
        {
            name: 'Keuze',
        },
        {
            name: 'Oplevering',
        }
    ];

    var index = 0;

    if(this.state.loading){ return null; }

    return (
      <div>

        <PageHeader path={[{'label': 'Administratie'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <AdministrationSideMenu activeMenu='3' />

          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, paddingTop: 0, width: '100%'}} colon={true}>

            <Title level={3}>Prijzen calculator</Title>

            <Row gutter={24}>
              <Col span={24} >

                <Form.Item label="Selecteer een klant">
                    <Select
                        value={this.state.customer_id ? this.state.customer_id : null}
                        onChange={(value) => { this.selectCustomer(value); }}
                        allowClear={true}
                        >
                        { this.props.licenses.map((customer) => {
                            return <Select.Option value={customer.id}>{customer.name}</Select.Option>
                        }) }
                    </Select>
                </Form.Item>

              </Col>
            </Row>

            <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Aantal projecten">
                      <InputNumber
                        min={0}
                        value={this.state.nr_of_projects}
                        onChange={(value) => { this.setState({ nr_of_projects: value }, () => { this.updateCalculation(); }); }}
                        style={{ marginRight: 8 }}
                        />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Aantal gebruikers">
                      <InputNumber
                        min={0}
                        value={this.state.nr_of_users}
                        onChange={(value) => { this.setState({ nr_of_users: value }, () => { this.updateCalculation(); }); }}
                        style={{ marginRight: 12 }}
                        />
                  </Form.Item>
                </Col>
            </Row>

            <Title level={4}>Cobee prijzen (per maand)</Title>

            <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Basis prijs">
                      <InputNumber
                        min={0}
                        onChange={(value) => { this.setState({ basic_price: value }, () => { this.updateCalculation(); }) }}
                        value={this.state.basic_price}
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Prijs per project">
                      <InputNumber
                        min={0}
                        onChange={(value) => { this.setState({ price_per_project: value }, () => { this.updateCalculation(); }) }}
                        value={this.state.price_per_project}
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Prijs per gebruiker">
                      <InputNumber
                        min={0}
                        onChange={(value) => { this.setState({ price_per_user: value }, () => { this.updateCalculation(); }) }}
                        value={this.state.price_per_user}
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        />
                  </Form.Item>
                </Col>
            </Row>

            <Divider />

            <Row gutter={24}>
                { optional_modules.map((optional_module) => { return (<Col span={8}>
                    <Form.Item label={'Prijs module: ' + optional_module.name}>
                        <InputNumber
                            min={0}
                            onChange={(value) => { this.updateModulePrice(optional_module.name, value); }}
                            defaultValue={this.getModulePrice(optional_module.name)}
                            style={{ marginRight: 8 }}
                            formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            />
                        <InputNumber
                            min={0}
                            onChange={(value) => { this.updateNrOfModuleProjects(optional_module.name, value); }}
                            value={this.getNrOfProjectsForModule(optional_module.name)}
                            style={{ marginRight: 8 }}
                            formatter={(value) => `x ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            />
                        (projecten)
                    </Form.Item>
                </Col>);
                }) }
            </Row>

            <Divider />

            <Title level={4}>Totalen (per maand)</Title>

            <Row gutter={24}>
                <Col span={8}>
                    Basis prijs:<br />
                    <strong>{ this.formatPrice(this.state.calculated_basic_price) }</strong>
                </Col>
                <Col span={8}>
                    Prijs voor projecten:<br />
                    <strong>{ this.formatPrice(this.state.calculated_projects_price) }</strong>
                </Col>
                <Col span={8}>
                    Prijs voor gebruikers:<br />
                    <strong>{ this.formatPrice(this.state.calculated_users_price) }</strong>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Title level={5}>
                        Totaal prijs:<br />
                        <strong>{ this.formatPrice(this.state.calculated_total_price) }</strong>
                    </Title>
                </Col>
            </Row>

            <Divider />

            <Title level={4}>Kortingen</Title>

            <Row>
                <Col span={8}>
                    <Form.Item label="Maak gebruik van staffels (hiermee veranderen de totalen hierboven)">
                        <Switch
                            onChange={(value) => { this.setState({ use_rates: value }, () => { this.updateCalculation(); }); }}
                            />
                    </Form.Item>
                </Col>
            </Row>

            { !this.state.use_rates ? <div>
                <Form.Item label="Vast korting percentage (%)">
                    <InputNumber
                        min={0}
                        max={100}
                        defaultValue={0}
                        onChange={(value) => { this.setState({ global_discount: value }, () => { this.updateCalculation(); }); }}
                        style={{ marginRight: 8 }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')+' %'}
                        />
                </Form.Item>                
            </div>
            :
            <div>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        Staffel
                    </Col>
                    <Col span={3}>
                        Basis prijs
                    </Col>
                    <Col span={3}>
                        Korting %
                    </Col>
                    <Col span={3}>
                        Vanaf aantal proj.
                    </Col>
                    <Col span={3}>
                        Tot en met aantal proj.
                    </Col>
                    <Col span={3}>
                        Vanaf aantal gebr.
                    </Col>
                    <Col span={3}>
                        Tot en met aantal gebr.
                    </Col>
                </Row>

                <Divider />

                { this.state.rates.map((rate, index) => { return (<div>
                    <Row gutter={[12, 12]}>
                        <Col span={6}>
                            { index + 1 + (index == 4 ? ' (onbeperkt / maatwerk)' : '') }
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                value={rate.price_per_month}
                                onChange={(value) => { this.updateRate(index, 'price_per_month', value); }}
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                max={100}
                                value={rate.discount_percentage}
                                onChange={(value) => { this.updateRate(index, 'discount_percentage', value); }}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')+' %'}
                                />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                value={rate.min_nr_of_projects}
                                onChange={(value) => { this.updateRate(index, 'min_nr_of_projects', value); }}
                                />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                value={rate.max_nr_of_projects}
                                onChange={(value) => { this.updateRate(index, 'max_nr_of_projects', value); }}
                                />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                value={rate.min_nr_of_users}
                                onChange={(value) => { this.updateRate(index, 'min_nr_of_users', value); }}
                                />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                value={rate.max_nr_of_users}
                                onChange={(value) => { this.updateRate(index, 'max_nr_of_users', value); }}
                                />
                        </Col>
                    </Row>

                    <Divider />
                </div>); }) }
            </div> }

            <Divider />

            <Title level={4}>Klanten totalen (per maand)</Title>

            <br />

            <Row style={{ marginBottom: 8, padding: 4 }}>
                <Col span={4} style={{ fontWeight: 'bold' }}>Klant</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Licentie projecten</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Licentie gebruikers</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Aantal projecten</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Aantal gebruikers</Col>
                <Col span={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>{!this.state.use_rates ? 'Korting %' : ''}</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'right' }}>Totaal bedrag (nieuw)</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'right' }}>Totaal bedrag (huidig)</Col>
            </Row>
            
            { this.state.customers.map((customer) => { 
                
                if(!customer){ return null; }
                if(customer.id == 1 || customer.id == 72){ return null; }
                if(customer.status != '1'){ return null; }

                var original_customer = {
                    license_nr_of_projects: null,
                    license_nr_of_users: null,
                    nr_of_projects: 0,
                    nr_of_users: 0
                };

                this.props.licenses && this.props.licenses.map((obj) => {
                    if(obj.id == customer.id)
                    {
                        original_customer = obj;

                        return;
                    }
                });

                index++;

                return (<Row 
                    style={{ 
                        marginBottom: 8, 
                        backgroundColor: (index % 2 === 0 ? '#ffffff' : '#f0f0f0'),
                        color: this.state.exclude_customer_ids && this.state.exclude_customer_ids[customer.id] === true ? '#888888' : '#000000',
                        padding: 4 
                    }}
                    >
                    <Col span={4} style={{ color: !this.customerExceedsLicense(customer) ? 'inherit' : '#ff0000' }}>
                        <Checkbox 
                            style={{ marginRight: 6 }}
                            checked={this.state.exclude_customer_ids && this.state.exclude_customer_ids[customer.id] === true ? true : false}
                            onChange={(e) => { this.toggleCustomerInclusion(customer.id, e.target.checked); }}
                            />
                        {original_customer.name}
                    </Col>
                    <Col span={3}>
                        {original_customer.license_nr_of_projects ? original_customer.license_nr_of_projects : 'N.v.t.'}
                    </Col>
                    <Col span={3}>
                        {original_customer.license_nr_of_users ? original_customer.license_nr_of_users : 'N.v.t.'}
                    </Col>
                    <Col span={3} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            defaultValue={customer && customer.custom_nr_of_projects ? customer.custom_nr_of_projects : original_customer.nr_of_projects}
                            style={{ marginRight: 8 }}
                            onChange={(value) => { this.updateCustomerValue(customer.id, value, 'custom_nr_of_projects'); }}
                            disabled={this.state.exclude_customer_ids && this.state.exclude_customer_ids[customer.id] === true ? true : false}
                            />
                        ({customer.nr_of_projects})
                    </Col>
                    <Col span={3} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            defaultValue={customer && customer.custom_nr_of_users ? customer.custom_nr_of_users : original_customer.nr_of_users}
                            style={{ marginRight: 8 }}
                            onChange={(value) => { this.updateCustomerValue(customer.id, value, 'custom_nr_of_users'); }}
                            disabled={this.state.exclude_customer_ids && this.state.exclude_customer_ids[customer.id] === true ? true : false}
                            />
                        ({customer.nr_of_users})
                    </Col>
                    <Col span={2} style={{ textAlign: 'left' }}>
                        { !this.state.use_rates ? <InputNumber
                            min={0}
                            max={100}
                            defaultValue={customer && customer.custom_discount_percentage ? customer.custom_discount_percentage : 0 }
                            onChange={(value) => { this.updateCustomerValue(customer.id, value, 'custom_discount_percentage'); }}
                            disabled={this.state.exclude_customer_ids && this.state.exclude_customer_ids[customer.id] === true ? true : false}
                            /> : null }
                    </Col>
                    <Col span={3} style={{ textAlign: 'right', color: '#000000' }}>
                    { 
                        this.state.exclude_customer_ids && this.state.exclude_customer_ids[customer.id] !== true
                        ?
                        this.formatPrice(this.state.customers_new_totals[customer.id]) 
                        :
                        this.formatPrice(this.getCustomerTotalAmount(customer.id))
                    }
                    </Col>
                    <Col span={3} style={{ textAlign: 'right', color: '#000000' }}>
                        {this.formatPrice(this.getCustomerTotalAmount(customer.id))}
                    </Col>
                </Row>)
            }) }

            <Divider />

            <Row style={{ marginBottom: 8 }}>
                <Col span={18} style={{ fontWeight: 'bold' }}></Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'right' }}>{ this.formatPrice(this.getCustomersNewTotal()) }</Col>
                <Col span={3} style={{ fontWeight: 'bold', textAlign: 'right' }}>{ this.formatPrice(this.getCustomersTotal()) }</Col>
            </Row>

          </Form>

        </div>

      </div>
    ); }
}

const PriceCalculatorForm = Form.create({ name: 'customer_modules' })(PriceCalculator);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customers: state.customer.customers,
    licenses: state.license.licenses,
    license: state.license.license,
    cobeeoption: state.license.cobeeoption,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    getLicensesAction : bindActionCreators(getLicensesAction, dispatch),
    getLicenseAction : bindActionCreators(getLicenseAction, dispatch),
    resetLicenseAction : bindActionCreators(resetLicenseAction, dispatch),
    getCobeeOptionAction : bindActionCreators(getCobeeOptionAction, dispatch),
    updateCobeeOptionAction : bindActionCreators(updateCobeeOptionAction, dispatch),
    resetCobeeOptionAction : bindActionCreators(resetCobeeOptionAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculatorForm);