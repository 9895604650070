import React, { Component } from 'react';
// import './ProjectsCorrespondenceDetail.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_URL, JOB_URL } from '../../../../constants/settings';
import { showSuccess, showExtraConfirm } from '../../../../utils/Notifications';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { getAddressesAction } from '../../../../actions/addressesActions';
import { getPriceCorrespondenceAction, sendCorrespondenceAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, requestDownloadLetters, getCorrespondenceSummaryAction, cancelCorrespondenceAction, unlockCorrespondenceAction } from '../../../../actions/correspondencesActions';
import { getNrOfNewQueuedItems } from '../../../../actions/queueActions';
import SetupMailCorrespondence from '../Form/SetupMailCorrespondence';
import EmailGeneratedLetters from '../Form/EmailGeneratedLetters';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import CorrespondenceSteps from '../Steps/CorrespondenceSteps';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Alert,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Spin,
  Tooltip,
  Menu
} from 'antd';
import { InfoCircleTwoTone, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'underscore';
import { showError } from '../../../../utils/Notifications';

const { TabPane } = Tabs;

class ProjectsCorrespondenceSend extends Component {
  state = {
    current: 2,

    letterVisible: false,
    letterMailVisible: false,
    emailVisible: false,
    smsVisible: false,
    pushVisible: false,

    subject: '',

    selectedLetterIds: [],
    selectedLetterEmailIds: [],
    selectedEmailIds: [],
    selectedSmsIds: [],
    selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,

    sendDate: null,
    sendTime: null,
    sendByCustomer: false,
    downloadLetters: false,

    selectedRowKeys: [],
    disabledRowKeys: [],

    summary: { addresses: { 'mail': [], 'sms': [], 'push': [], 'email': [], 'email_mail': [] } },

    isFetching: false,

    activeTab: this.props.match.params.tab ? this.props.match.params.tab : '1'
  };

  index = 0;

  componentDidMount() {

    if(this.props.match.params.correspondenceid) {
        this.load();
    }
  }

  load(show_success = false) {

      this.setState({ isFetching: true });

      this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {
        this.setState({
          subject: this.props.correspondence.subject,
          selectedLetterIds: this.props.correspondence.selection.mail,
          selectedLetterEmailIds: this.props.correspondence.selection.email_mail,
          selectedEmailIds: this.props.correspondence.selection.email,
          selectedSmsIds: this.props.correspondence.selection.sms,
          selectedPushIds: this.props.correspondence.selection.push,
        }, () => { this.props.getCorrespondenceSummaryAction(this.props.match.params.correspondenceid).then(() => {
          this.setState({ summary: this.props.summary });

          if(show_success)
          {
            showSuccess();
          }

          this.setState({ isFetching: false });
        }); });
      });
  }

  // downloadLetters = () => {
  //     if(this.state.selectedLetterIds.length < 100)
  //     {
  //       window.open(API_URL + '/correspondence/'+ this.props.match.params.correspondenceid +'/download?dpi=72&token=' + localStorage.getItem('authToken'), '_blank');
  //     }
  //     else
  //     {
  //         this.setState({ downloadLetters: true });
  //     }
  // }



    downloadLetters = () => {
      // window.open(API_URL + '/correspondence/'+ this.props.match.params.correspondenceid +'/download?token=' + localStorage.getItem('authToken'), '_blank');

      // modal
      this.props.requestDownloadLetters(this.props.match.params.correspondenceid, { /*'email': this.state.emailTo,*/ 'dpi': 72 }).then(() => {

        this.setState({emailTo: ''});

        showSuccess('Download verzoek ingediend');

        this.props.getNrOfNewQueuedItems();
      });
    }

  sendCorrespondence = () => {
    showExtraConfirm((confirm) => {
      if(confirm) {
        this.props.sendCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {
          this.props.history.push(`/projects/${this.props.match.params.id}/correspondence`);
          showSuccess();
        });
      }
    });
  }

    cancelCorrespondence = () => {

      showExtraConfirm((confirm) => {

        if(confirm) {

          this.props.unlockCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

            showSuccess();

            var this_obj = this;

            this_obj.props.history.push(`/projects/${this.props.match.params.id}/correspondence`);

          });
        }
      });
    }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  //
  deleteSelected = (method) => {

      // check selected record(s)
      var records = this.state.summary.addresses[method];

      var error = false;

      records.map((record) => {

         if(this.state.selectedRowKeys.includes(record.id) && record.is_send == '1')
         {
             error = true;
         }
      });

      if(error)
      {
          showError('De geselecteerde correspondenties kunnen niet worden verwijderd omdat 1 of meerdere geselecteerde correspondenties al reeds verzonden zijn. Pas de selectie aan en probeer het opnieuw.');
      }
      else
      {
          showExtraConfirm((confirm) => {

            if(confirm) {

              this.props.cancelCorrespondenceAction(this.props.match.params.correspondenceid, this.state.selectedRowKeys).then(() => {

                this.setState({ selectedRowKeys: [] });

                this.load(true);

              });

            }

          });
      }
  }

  //
  cancelSelected = (method) => {

      // check selected record(s)
      var records = this.state.summary.addresses[method];

      var error = false;

      records.map((record) => {

         if(this.state.selectedRowKeys.includes(record.id) && record.status == 'sent')
         {
             error = true;
         }
      });

      if(error)
      {
          showError('De geselecteerde correspondenties kunnen niet worden geannuleerd omdat 1 of meerdere geselecteerde correspondenties al reeds verzonden zijn. Pas de selectie aan en probeer het opnieuw.');
      }
      else
      {

          showExtraConfirm((confirm) => {

            if(confirm) {

              this.props.cancelCorrespondenceAction(this.props.match.params.correspondenceid, this.state.selectedRowKeys, 'postbode').then(() => {

                this.setState({ selectedRowKeys: [] });

                this.load();

                showSuccess('Verzoek ingediend');

              });

            }

          });
      }
  }

  methodName(method)
  {
      if(method == 'mail'){ return 'Brief'; }
      if(method == 'sms'){ return 'SMS'; }
      if(method == 'push'){ return 'Push'; }
      if(method == 'email'){ return 'E-mail'; }
      if(method == 'email_mail'){ return 'Brief per e-mail'; }

      return method;
  }

  statusName(status)
  {
      if(!status){ return ''; }
      if(status == 'sent'){ return 'Verzonden'; }
      if(status == 'cancelling'){ return 'Annuleringsverzoek ingediend'; }
      if(status == 'cancelled'){ return 'Geannuleerd'; }

      return 'In behandeling';
  }

  setActiveTab(tab)
  {
      var tab_nr = tab;

           if(tab == 'mail')      { tab_nr = '1'; }
      else if(tab == 'email_mail'){ tab_nr = '2'; }
      else if(tab == 'email')     { tab_nr = '3'; }
      else if(tab == 'sms')       { tab_nr = '4'; }
      else if(tab == 'push')      { tab_nr = '5'; }

      this.setState({ activeTab: tab_nr }, () => {

          window.history.pushState('Correspondentie', 'Correspondentie', '/projects/'+this.props.match.params.id+'/correspondence/'+this.props.match.params.correspondenceid+'/status/tab/'+tab);
      });
  }

  render() {

    const columns = [
      {
        title: 'Naam',
        dataIndex: 'name'
      },
      {
        title: 'Aantal',
        dataIndex: 'nr_of'
      },
      {
        title: 'Prijs per stuk',
        dataIndex: 'price_per_item'
      },
      {
        title: 'Totaal',
        dataIndex: 'total_price',
        render: (text, index) => {
          return text + " excl. btw";
        }
      },
      {
        title: 'Facturatie',
        dataIndex: 'invoice'
      },
    ];

    const AddressesColumns = [
      {
        title: 'Adres',
        dataIndex: 'address_name'
      },
      /*{
        title: 'Type',
        dataIndex: 'type',
        render: (text, index) => {
          var type = '';

          if(text == 'mail') text = "Brief";
          if(text == 'sms') text = "Sms";
          if(text == 'push') text = "Push";
          if(text == 'email') text = "E-mail";
          if(text == 'email_mail') text = "Brief per e-mail";

          return (
            <span>{text}</span>
          )
        }
      },/**/
      {
        title: 'Verzonden',
        dataIndex: 'is_send',
        render: (text, index) => {
          if(text == 1) {
            return <CheckCircleOutlined style={{color: 'green'}} />;
          }
          else if(index.scheduled_for || text == 0)
          {
            return <ClockCircleOutlined style={{color: 'orange'}} />;
          } else {
            return <CloseCircleOutlined style={{color: 'red'}} />;
          }

        }

      },
      {
        title: 'Ingepland op',
        dataIndex: 'scheduled_for',
        render: (text, index) => {
          return (
            text ? <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> : 'n.v.t.'
          )
        }
      },
      {
        title: 'Verzenddatum',
        dataIndex: 'send_date',
        render: (text, index) => {
          return (
            text ? <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> : ''
          )
        }
      },
      {
        title: 'Bekijken',
        dataIndex: 'download_url',
        render: (text, index) => {
            if(index.type == 'email_mail')
            {
                return (
                  <span>
                      <a href={text+'?token='+localStorage.authToken} target="_blank">Download e-mail</a>
                      &nbsp;|&nbsp;
                      <a href={text+'/../true?token='+localStorage.authToken} target="_blank">Download brief</a>
                  </span>
                )
            }
            else
            {
                return (
                  <a href={text+'?token='+localStorage.authToken} target="_blank">Download</a>
                )
            }
        }
      }
    ];

    const AddressesMailColumns = [
      {
        title: 'Adres',
        dataIndex: 'address_name'
      },
      {
        title: <Tooltip title='Dit veld geeft aan of de brief bij de bezorgdienst is afgeleverd om uiteindelijk behandeld en verzonden te worden.'>
            Ingediend <InfoCircleTwoTone />
        </Tooltip>,
        dataIndex: 'is_send',
        render: (text, index) => {
          if(index.status == 'undeliverable')
          {
            return <CloseCircleOutlined style={{color: 'red'}} />;
          }
          else if(text == 1) {
            return <CheckCircleOutlined style={{color: 'green'}} />;
          }
          else if(index.scheduled_for || text == 0)
          {
            return <ClockCircleOutlined style={{color: 'orange'}} />;
          } else {
            return <CloseCircleOutlined style={{color: 'red'}} />;
          }
        }
      },
      {
        title: <Tooltip title='Dit veld geeft de status van de brief bij de bezorgdienst aan wanneer dit van toepassing is. Dit kan zijn: in behandeling, verzonden of geannuleerd. Zo lang een brief door de bezorgdienst nog niet verzonden is, kan deze worden geannuleerd. Let op: deze status kan achterlopen op de daadwerkelijke status bij de bezorgdienst.'>
            Bezorgdienst status <InfoCircleTwoTone />
        </Tooltip>,
        dataIndex: 'status',
        render: (text, index) => {
          return this.statusName(text);
        }
      },
      {
        title: 'Ingepland op',
        dataIndex: 'scheduled_for',
        render: (text, index) => {
          return (
            text ? <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> : 'n.v.t.'
          )
        }
      },
      {
        title: 'Verzenddatum',
        dataIndex: 'send_date',
        render: (text, index) => {
          return (
            text ? <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> : ''
          )
        }
      },
      {
        title: 'Bekijken',
        dataIndex: 'download_url',
        render: (text, index) => {
            if(index.type == 'email_mail')
            {
                return (
                  <span>
                      <a href={text+'?token='+localStorage.authToken} target="_blank">Download e-mail</a>
                      &nbsp;|&nbsp;
                      <a href={text+'/../true?token='+localStorage.authToken} target="_blank">Download brief</a>
                  </span>
                )
            }
            else
            {
                return (
                  <a href={text+'?token='+localStorage.authToken} target="_blank">Download</a>
                )
            }
        }
      }
    ];

    const AddressesEmailColumns = [
      {
        title: 'Adres',
        dataIndex: 'address_name'
      },
      // {
      //   title: 'Type',
      //   dataIndex: 'type',
      //   render: (text, index) => {
      //     var type = '';
      //
      //     if(text == 'mail') text = "Brief";
      //     if(text == 'sms') text = "Sms";
      //     if(text == 'push') text = "Push";
      //     if(text == 'email') text = "E-mail";
      //     if(text == 'email_mail') text = "Brief per e-mail";
      //
      //     return (
      //       <span>{text}</span>
      //     )
      //   }
      // },
      {
        title: 'Verzonden',
        dataIndex: 'is_send',
        render: (text, index) => {
          if(text == 1) {
            return <CheckCircleOutlined style={{color: 'green'}} />;
          }
          else if(index.scheduled_for || text == 0)
          {
            return <ClockCircleOutlined style={{color: 'orange'}} />;
          } else {
            return <CloseCircleOutlined style={{color: 'red'}} />;
          }

        }

      },
      {
        title: 'Ingepland op',
        dataIndex: 'scheduled_for',
        render: (text, index) => {
          return (
            text ? <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> : 'n.v.t.'
          )
        }
      },
      {
        title: 'Verzenddatum',
        dataIndex: 'send_date',
        render: (text, index) => {
          return (
            text ? <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> : ''
          )
        }
      },
      {
        title: 'Afgeleverd',
        dataIndex: 'is_received',
        render: (text, index) => {
          return (
            text == 1 ? 'Ja' : (text == 0 ? 'Nee / Onbekend' : '(Nog) niet te bepalen')
          )
        }
      },
      {
        title: 'Bekijken',
        dataIndex: 'download_url',
        render: (text, index) => {
          if(index.type == 'email_mail')
          {
              return (
                <span>
                    <a href={text+'?token='+localStorage.authToken} target="_blank">Download e-mail</a>
                    <a href={text+'../true?token='+localStorage.authToken} target="_blank">Download brief</a>
                </span>
              )
          }
          else
          {
              return (
                <a href={text+'?token='+localStorage.authToken} target="_blank">Download</a>
              )
          }
        }
      }
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      getCheckboxProps: record => ({
        // disabled: record.is_send == 0 ? false : true
      }),
    };

    if(this.state.isFetching) {
      return (
        <div style={{paddingTop: 100, flex:1, height: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
          <Spin />
        </div>
      );
    }

    var index = 0;

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Link to={`/projects/${this.props.match.params.id}/correspondence`}>
                <Button>Sluiten</Button>
              </Link>

              {this.state.selectedLetterIds.length > 0 ? (
                  <Button style={{marginTop: 10, marginLeft: 10}} onClick={() => { this.downloadLetters(); }}>Download alle brieven</Button>
              ) : null }

              { this.props.correspondence && this.props.correspondence.status == 'configured' ? (
                  <Button style={{marginTop: 10, marginLeft: 10}} type='danger' onClick={() => { this.cancelCorrespondence(); }}>Annuleren</Button>
              ) : null }

            </div>
          </Col>
        </Row>
        <div>
          { this.props.summary && this.props.summary.methods && this.props.summary.methods.length && this.props.summary.addresses ?
          <div><Card style={{backgroundColor: 'white', borderRadius: '5px', marginBottom: 24 }}>
              <h3>Samenvatting</h3>
              <CustomTable
                rowKey='id'
                size="middle"
                columns={columns}
                dataSource={this.state.summary && this.state.summary.methods && this.state.summary.methods.length ? this.state.summary.methods : []}
                loading={this.state.isFetching}
                />
          </Card>

            { /*this.props.correspondence.status != 'send' ? <Row className="mbm">
              <Col span={24}>
                  <Button disabled={!this.props.HaveAbility('correspondence','delete')} style={{marginTop: 10, marginLeft: 0}} onClick={() => { this.deleteSelected(); }}>Geselecteerde adressen verwijderen</Button>
              </Col>
            </Row> : null*/ }

          <Card style={{backgroundColor: 'white', borderRadius: '5px', marginBottom: '24px' }}>
              <Tabs
                activeKey={this.state.activeTab}
                type="card"
                onChange={(key) => { this.setActiveTab(key); }}
                tabBarStyle={{ marginBottom: 0 }}
                tabBarGutter={10}
                >
                  { [
                      'mail',
                      'sms',
                      'push',
                      'email',
                      'email_mail'
                  ].map((method) => {

                      if(this.state.summary && this.state.summary.addresses && this.state.summary.addresses[method] && this.state.summary.addresses[method].length > 0)
                      {
                          index++;
                      }

                      return this.state.summary && this.state.summary.addresses && this.state.summary.addresses[method] && this.state.summary.addresses[method].length > 0 ?
                          <TabPane tab={this.methodName(method)} key={(index)}>
                            <Card style={{ backgroundColor: 'white', borderRadius: '5px', borderTop: 0, padding: 0, margin: 0 /*marginBottom: 24*/ }}>

                              <Row className="mbm">

                                {/*<Col span={24}>
                                  { this.props.correspondence.status != 'send' ? <Button disabled={!this.props.HaveAbility('correspondence', 'delete')} style={{ marginTop: 10, marginLeft: 0 }} onClick={() => { this.deleteSelected(); }}>Geselecteerde adressen verwijderen</Button> : null }
                                  { method == 'mail' ? <Button danger disabled={!this.props.HaveAbility('correspondence','delete')} style={{ marginTop: 10, marginLeft: 10 }} onClick={() => { this.deleteSelected(); }}>Annuleer geselecteerde brieven</Button> : null }
                                </Col>*/}

                                <Col span={24}>

                                    <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('correspondence', 'delete') && (this.state.selectedRowKeys.length > 0 ? false : true) } overlay={(
                                        <Menu>
                                          <Menu.Item onClick={() => { this.deleteSelected(method); }} key="1" disabled={!this.props.HaveAbility('correspondence', 'delete')} style={{ color: this.props.HaveAbility('correspondence', 'delete') ? '#f5222d' : '' }}>Geselecteerde correspondentie verwijderen</Menu.Item>
                                          <Menu.Item onClick={() => { this.cancelSelected(method); }} key="2" disabled={!this.props.HaveAbility('correspondence', 'delete')} style={{ color: this.props.HaveAbility('correspondence', 'delete') ? '#f5222d' : '' }}>Geselecteerde correspondentie annuleren</Menu.Item>
                                        </Menu>
                                      )}>
                                      <Button type="secondary">
                                        Acties <DownOutlined />
                                      </Button>
                                    </Dropdown>

                                </Col>
                              </Row>

                              <div>
                                <CustomTable
                                    rowKey='id'
                                    size="middle"
                                    columns={
                                        method == 'mail' ? AddressesMailColumns
                                        :
                                        method == 'email' ? AddressesEmailColumns
                                        :
                                        AddressesColumns}
                                    dataSource={this.state.summary.addresses[method] ? this.state.summary.addresses[method] : []}
                                    loading={this.state.isFetching}
                                    rowSelection={rowSelection}
                                    style={{ border: 0 }}
                                />
                              </div>
                            </Card>
                          </TabPane> : null
                  })/**/ }
              </Tabs>
          </Card></div>
          :
          <div>
            <Card style={{backgroundColor: 'white', borderRadius: '5px', marginBottom: 24 }}>
              <h3>Geannuleerd</h3>
              Deze correspondentie is volledig geannuleerd{this.props.cancelled_at ? ' op '+moment(this.props.cancelled_at).format('DD-MM-YYYY - HH:mm')+' uur' : ''}.
            </Card>
          </div>
          }
        </div>
        <Modal
          title="Download brieven"
          visible={this.state.downloadLetters}
          onOk={() => {

              //this.setState({ downloadLetters: false });
              //this.downloadLetters();

              this.emailGeneratedLetters.props.form.validateFields((error, values) => {

                if(!error) {
                  this.props.requestDownloadLetters(this.props.match.params.correspondenceid, { 'email': values.email, 'dpi': 72 }).then(() => {});

                  this.setState({ downloadLetters: false });

                this.emailGeneratedLetters.props.form.resetFields();
              }
            });
        }}

        onCancel={() => this.setState({downloadLetters: false})}
        okText={'Versturen'}
        >
        <EmailGeneratedLetters wrappedComponentRef={formRef => { this.emailGeneratedLetters = formRef }} />
      </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    addresses : state.correspondence.addresses,
    groups : state.group.groups,
    price : state.correspondence.price,
    summary : state.correspondence.summary,
    cancelled_at : state.correspondence.summary.cancelled_at
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    sendCorrespondenceAction : bindActionCreators(sendCorrespondenceAction, dispatch),
    getPriceCorrespondenceAction : bindActionCreators(getPriceCorrespondenceAction, dispatch),
    requestDownloadLetters : bindActionCreators(requestDownloadLetters, dispatch),
    getCorrespondenceSummaryAction : bindActionCreators(getCorrespondenceSummaryAction, dispatch),
    cancelCorrespondenceAction : bindActionCreators(cancelCorrespondenceAction, dispatch),
    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
    unlockCorrespondenceAction : bindActionCreators(unlockCorrespondenceAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCorrespondenceSend);
