import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications'
import { getChoiceFormsByProjectAction, searchChoiceFormsByProjectAction, cloneChoiceFormsByProjectAction, deleteChoiceFormsByProjectAction, cloneChoiceFormFromCustomerToProject, getChoiceFormsFromLibraryAction } from '../../../actions/choiceActions';
import ChoiceSideMenu from './ChoiceSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Menu,
  Dropdown,
  Modal,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Title, Text } = Typography;

const Option = Select.Option;

class ChoiceForms extends Component {

  state = {
    disabled: true,
    edit: true,
    selectedRowKeys: [],
    selectedId: null,
    modalVisible: false,
    can_write: false,
  };

  // data example
  // examples = [{ id: 1, project_id: 1, description: 'Test', nr_of_addresses: 1, nr_of_forms_submitted: 0, nr_of_forms_with_additional_work: 0, nr_of_forms_paid: 0 }];

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write')
      });

      this.props.getChoiceFormsByProjectAction(this.props.match.params.id).then({});

      // this.props.getChoiceFormsFromLibraryAction().then({});
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  cloneSelected = () => {

    this.props.cloneChoiceFormsByProjectAction(this.state.selectedRowKeys[0]).then(() => {
      this.props.getChoiceFormsByProjectAction(this.props.match.params.id);
      showSuccess('Succesvol gedupliceerd');
      this.setState({selectedRowKeys: []})
    });
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteChoiceFormsByProjectAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
          this.props.getChoiceFormsByProjectAction(this.props.match.params.id);
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = (e) => { };

  cloneForm = () => {
    if(this.state.selectedId) {
      this.props.cloneChoiceFormFromCustomerToProject(this.props.match.params.id, this.state.selectedId).then(() => {
        //this.props.onClone();
        this.setState({selectedId: null, modalVisible: false});
        this.props.getChoiceFormsByProjectAction(this.props.match.params.id);
        showSuccess('Succesvol toegevoegd')
      });
    }
  }

  openModal = () => {
    this.setState({modalVisible: true});
    this.props.getChoiceFormsFromLibraryAction().then({});
  }

  render() {

    const columns = [{
        title: 'Omschrijving',
        dataIndex: 'description',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/choice/form/settings/${record.id}`}>
              {text}
            </Link>
          </span>
        )
    },
    {
        title: 'Adressen',
        dataIndex: 'nr_of_addresses',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aantal ingediend',
        dataIndex: 'nr_of_forms_submitted',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aantal met meerwerk',
        dataIndex: 'nr_of_forms_with_additional_work',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aantal betalingen voldaan',
        dataIndex: 'nr_of_forms_paid',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={12}>
              <div>
                <Dropdown disabled={!this.state.can_write} trigger={['click']} overlay={(
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={`/projects/${this.props.match.params.id}/choice/form/settings/add`}>
                          Nieuw
                        </Link>
                      </Menu.Item>
                      <Menu.Item onClick={() => this.openModal()} key="2">Bibliotheek</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="new">
                    Toevoegen <DownOutlined />
                  </Button>
              </Dropdown>

                <Dropdown trigger={['click']} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                    <Menu>
                      <Menu.Item disabled={this.state.selectedRowKeys.length > 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                      <Menu.Divider></Menu.Divider>
                      <Menu.Item disabled={!this.props.HaveAbility('choice', 'delete')} onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button disabled={!this.state.can_write} type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Col>
            <Col span={12}>
                <Input.Search
                  allowClear
                  placeholder="Zoeken..."
                  onSearch={ (value) => this.props.searchChoiceFormsByProjectAction(this.props.match.params.id, value) }
                  style={{ width: 200, float: 'right' }}
                />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomTable
                rowKey='id'
                size="middle"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.props.choice_forms}
                // dataSource={this.examples}
                loading={this.props.isFetching}
              />
            </Col>
          </Row>

        </div>

        <Modal
          title='Bibliotheek'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          onOk={() => this.cloneForm()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Keuze formulier">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer keuze formulier'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                {this.props.lib_forms && this.props.lib_forms.map((element) => (
                  <Select.Option value={element.id}>{element.description}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

      </div>
    );
  }
}

const ChoiceFormsForm = Form.create({ name: 'projects_choice_forms' })(ChoiceForms);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.choice.isFetching,
    isError : state.choice.isError,
    choice_forms : state.choice.forms,
    lib_forms : state.choice.lib_forms,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChoiceFormsByProjectAction : bindActionCreators(getChoiceFormsByProjectAction, dispatch),
    searchChoiceFormsByProjectAction : bindActionCreators(searchChoiceFormsByProjectAction, dispatch),
    cloneChoiceFormsByProjectAction : bindActionCreators(cloneChoiceFormsByProjectAction, dispatch),
    deleteChoiceFormsByProjectAction : bindActionCreators(deleteChoiceFormsByProjectAction, dispatch),
    cloneChoiceFormFromCustomerToProject : bindActionCreators(cloneChoiceFormFromCustomerToProject, dispatch),
    getChoiceFormsFromLibraryAction : bindActionCreators(getChoiceFormsFromLibraryAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormsForm);
