import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { HaveRole } from '../../utils/RolesAndAbilities';
import { ControlOutlined, FileOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import { HaveAbility } from '../../utils/RolesAndAbilities';
import _ from 'underscore'

class MediaSideMenu extends Component {
   render() {
       return (
           <Menu
               mode="inline"
               style={{ width: 300}}
               defaultOpenKeys={[this.props.activeSubMenu]}
               defaultSelectedKeys={[this.props.activeMenu]}
           >
               <Menu.Item key={'1'}>
                   <Link to={`/media`}>
                       <FileOutlined />
                       <span>Bestanden</span>
                   </Link>
               </Menu.Item>
               <Menu.Item key={'2'}>
                   <Link to={`/media/categories`}>
                       <ControlOutlined />
                       <span>Categorieën</span>
                   </Link>
               </Menu.Item>
           </Menu>
       );
   }
}

const mapStateToProps = (state, ownProps) => {
    return {
      selectedCustomer: state.auth.selectedCustomer
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
      HaveRole : bindActionCreators(HaveRole, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSideMenu);
