import React, { Component, cloneElement } from 'react';
// import './Partners.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import  AddPartnerForm from './Form/AddPartner';
import { getPartnersAction, addPartnerAction, updatePartnersOrderAction, removePartnersAction, searchPartnersAction } from '../../../../actions/partnersActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
//import TouchBackend from 'react-dnd-touch-backend';
//import { DragDropContext } from 'react-dnd';

import ClonePartner from './Modal/ClonePartner';
import { DownOutlined, DragOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Upload,
  Tag,
  Button,
  Drawer,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
} from 'antd';
import { HaveAbility, HaveRole } from '../../../../utils/RolesAndAbilities';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

// let dragingIndex = -1;
//
// class BodyRow extends React.Component {
//
//   render() {
//     const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
//     const style = { ...restProps.style, cursor: 'move' };
//
//     let { className } = restProps;
//     if (isOver) {
//       if (restProps.index > dragingIndex) {
//         className += ' drop-over-downward';
//       }
//       if (restProps.index < dragingIndex) {
//         className += ' drop-over-upward';
//       }
//     }
//
//     return connectDragSource(
//       connectDropTarget(<tr {...restProps} className={className} style={style} />),
//     );
//   }
// }



// const rowSource = {
//   beginDrag(props) {
//     dragingIndex = props.index;
//     return {
//       index: props.index,
//     };
//   },
// };
//
// const rowTarget = {
//   drop(props, monitor) {
//     const dragIndex = monitor.getItem().index;
//     const hoverIndex = props.index;
//
//     if (dragIndex === hoverIndex) {
//       return;
//     }
//
//     props.moveRow(dragIndex, hoverIndex);
//
//     monitor.getItem().index = hoverIndex;
//   },
// };
//
// const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(BodyRow),
// );



class Partners extends Component {

  state = {
      drawerVisible: false,
      selectedRowKeys: [],
      partners: []
  };

  componentDidMount() {
    this.props.getPartnersAction(this.props.match.params.id).then(() => {
      this.setState({partners: this.props.partners});
    });
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //
  //   this.addPartnerform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addPartnerAction(this.props.match.params.id, values);
  //       this.addPartnerform.props.form.resetFields();
  //       this.setState({drawerVisible: false});
  //     }
  //   });
  // }

  // components = {
  //   body: {
  //     row: DragableBodyRow,
  //   },
  // };
  //
  // moveRow = (dragIndex, hoverIndex) => {
  //   const { partners } = this.state;
  //   const dragRow = partners[dragIndex];
  //
  //   this.setState(
  //     update(this.state, {
  //       partners: {
  //         $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
  //       },
  //     }),
  //   );
  //
  //   var sort = this.state.partners.map((v,k) => v.id);
  //
  //   this.props.updatePartnersOrderAction(this.props.match.params.id, {'partners' : sort}).then(() => {
  //   });
  // };

  deleteSelected = () => {
      Modal.confirm({
        title: 'Weet je het zeker dat je de geselecteerde partners wilt verwijderen?',
        content: '',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {
              this.props.removePartnersAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
                  // this.setState({ partners: this.props.partners, selectedRowKeys: [] });
                  resolve();

                  this.setState({ selectedRowKeys: [] });

                  this.props.getPartnersAction(this.props.match.params.id).then(() => {
                    this.setState({partners: this.props.partners});
                  });

                  showSuccess('Succesvol verwijderd');
              });
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          //console.log('Cancel');
        }
      });
  }

  onSearch(value) {
      this.props.searchPartnersAction(this.props.match.params.id, {'search': value}).then(() => {
        this.setState({partners: this.props.partners});
      });
  }

  render() {

    //const { partners } = this.state;

    const columns = [
    {
      key: "sort",
      width: 20
    },
    {
        title: 'Naam',
        dataIndex: 'name',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/modules/partner/${record.id}/detail`}>
              {record.name}
            </Link>
          </span>
        )
    }, {
        title: 'Plaats',
        dataIndex: 'city',
        render: (text, record) => (
          <span>{text}</span>
        ),
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var partners = this.state.partners;

        const activeIndex = partners.findIndex((i) => i.id === active.id);
        const overIndex = partners.findIndex((i) => i.id === over.id);

        this.setState({ partners: arrayMove(partners, activeIndex, overIndex) }, () => {

          var sort = this.state.partners.map((v,k) => v.id);

          this.props.updatePartnersOrderAction(this.props.match.params.id, {'partners' : sort}).then(() => {});
        });
      }
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <Dropdown disabled={!this.props.HaveAbility('partner' ,'write')}  trigger={['click']} overlay={(
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/projects/${this.props.match.params.id}/modules/partners/add`}>
                      Nieuw
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                      <ClonePartner onClone={() => this.setState({partners: this.props.partners})} {...this.props} plain />
                  </Menu.Item>
                </Menu>
              )}>
              <Button type="new">
                Toevoegen <DownOutlined />
              </Button>
          </Dropdown>
              {/*<Link to={`/projects/${this.props.match.params.id}/modules/partners/add`}>
                <Button disabled={!this.props.HaveAbility(2 ,'update')} type="new" icon="plus">Toevoegen</Button>
              </Link>

              {this.props.HaveRole(['super-admin', 'admin', 'projectmanager']) ? (
                <ClonePartner onClone={() => this.setState({partners: this.props.partners})} {...this.props} />
              ) : null }*/}

                <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('partner' ,'write') && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                  <Menu>
                    <Menu.Item onClick={() =>this.deleteSelected()} key="5" disabled={!this.props.HaveAbility('partner' ,'delete')} style={{ color: this.props.HaveAbility('partner', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary">
                  Acties <DownOutlined />
                </Button>
              </Dropdown>
          </Col>
          <Col span={12}>
            <Input.Search
              allowClear
              placeholder="Zoeken..."
              onSearch={value => this.onSearch(value)}
              style={{ width: 200, float: 'right' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
              { this.state.partners && this.state.partners.length ?
              <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                  items={this.state.partners.map((i) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <CustomTable
                    rowKey='id'
                    columns={columns}
                    dataSource={this.state.partners}
                    components={!this.props.isFetching ? {
                        body: {
                            row: DraggableRow
                        }
                    } : {}}
                    size="middle"
                    rowSelection={rowSelection}
                    loading={this.props.isFetching}
                    />
                </SortableContext>
              </DndContext>
              :
              <CustomTable
                rowKey='id'
                columns={columns}
                dataSource={this.state.partners}
                size="middle"
                rowSelection={rowSelection}
                loading={this.props.isFetching}
                /> }
          </Col>
        </Row>
      </div>
    );

  }
}

const PartnersForm = Form.create({ name: 'partners_settings' })(Partners);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.partner.isFetching,
      isError : state.partner.isError,
      partners : state.partner.partners,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getPartnersAction : bindActionCreators(getPartnersAction, dispatch),
      updatePartnersOrderAction : bindActionCreators(updatePartnersOrderAction, dispatch),
      removePartnersAction : bindActionCreators(removePartnersAction, dispatch),
      searchPartnersAction : bindActionCreators(searchPartnersAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
      HaveRole : bindActionCreators(HaveRole, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersForm);
