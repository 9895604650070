export default (state = {isError: false, isFetching: false, complaints: [], complaint: {}}, action) => {
    switch (action.type) {
    case 'COMPLAINTS_ACTION_FETCH':
    return {
        ...state,
        complaints: [],
        isFetching: true,
        isError: false,
    }
    case 'COMPLAINT_ACTION_FETCH':
    return {
        ...state,
        complaint: {},
        isFetching: true,
        isError: false,
    }
    case 'COMPLAINTS_ACTION_SUCCESS':
    return {
        ...state,
        complaints: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'COMPLAINT_ACTION_SUCCESS':
    return {
        ...state,
        complaint: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_COMPLAINT_ACTION_SUCCESS':
    return {
        ...state,
        complaints: [action.payload, ...state.complaints],
        complaint: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'COMPLAINT_REMOVE_SUCCESS':
    return {
        ...state,
        complaints: state.complaints.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'COMPLAINTS_ACTION_RESET':
    return {
        ...state,
        complaint: {},
        isFetching: false,
        isError: false,
    }
    case 'COMPLAINT_ACTION_RESET':
    return {
        ...state,
        complaint: {},
        isFetching: false,
        isError: false,
    }
    case 'COMPLAINT_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
