import api from '../utils/api'

export const addMollieAccount = () => dispatch => {
    dispatch({
      type: 'ADD_MOLLIE_ACCOUNT_ACTION'
    })
}

export const getCustomersAction = (params) => dispatch => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('get','/customers', null, true).then((response) => {
    dispatch({
      type: 'CUSTOMERS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const getCustomerAction = (id = false) => (dispatch, getState) => {

    if(!id) {
        id = localStorage.getItem('customerId');
    }

    if(!id && getState().auth.selectedCustomer) {
      id = getState().auth.selectedCustomer.id;
    }

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('get','/customer/'+id, null, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const getCustomerBilling = (month) => dispatch => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('post','/correspondences/billing/', {'period': month}, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_BILLING_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const getCustomerTransactions = (customer_id, period) => dispatch => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('post','/customer/' + customer_id + '/transactions', {'date': period}, true).then((response) => {
    dispatch({
      type: 'CUSTOMER_BILLING_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const exportCustomerTransactions = (customer_id, period) => dispatch => {

    // dispatch({
    //   type: 'CUSTOMERS_ACTION_FETCH'
    // })
    //
    // return api('post','/customer/' + customer_id + '/transactions/export', {'date': period}, true).then((response) => {
    //   dispatch({
    //     type: 'CUSTOMER_BILLING_ACTION_SUCCESS',
    //     payload: response.data
    //   });
    // }).catch((e) => {
    //   dispatch({
    //     type: 'CUSTOMERS_ACTION_ERROR',
    //   })
    //   return false;
    // });

  // return api('post','/customer/' + customer_id + '/transactions/export', {'date': period}, true, null, true).then((response) => {
  //
  //     // 1
  //     // var reader = new FileReader();
  //     //
  //     // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     //
  //     // var new_window = window.open('');
  //     //
  //     // new_window.location = URL.createObjectURL(blob);
  //
  //     // 2
  //     var reader = new FileReader();
  //
  //     var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //
  //     reader.readAsDataURL(blob);
  //
  //     reader.onloadend = function (e) {
  //
  //         var win = window.open();
  //
  //         win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  //     }
  //
  // }).catch((e) => {
  //   return false;
  // });
}

export const patchCustomerAction = (id, values, files) => dispatch => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('post','/customer/'+id, values, true, files).then((response) => {
    dispatch({
      type: 'CUSTOMER_ACTION_SUCCESS',
      payload: response.data.customer
    });
  }).catch((e) => {
    dispatch({
      type: 'CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const addCustomerAction = (params) => dispatch => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('post','/customer', params, true).then((response) => {
    dispatch({
      type: 'ADD_CUSTOMERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'ADD_CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchCustomerAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('post','/customers/search', {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_CUSTOMERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_CUSTOMERS_ACTION_ERROR',
    })
    return false;
  });
}

export const globalSearchAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'SEARCH_GLOBAL_ACTION_FETCH'
  })

  var customer_id = false;

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/customer/'+customer_id+'/search', {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_GLOBAL_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_GLOBAL_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteCustomersAction = (params) => dispatch => {
  return api('post','/customers/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_CUSTOMERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const syncCustomerModulesAction = (id, params) => (dispatch, getState) => {

  dispatch({
    type: 'CUSTOMERS_ACTION_FETCH'
  })

  return api('post',`/customer/${id}/syncmodules`, params, true).then((response) => {

    if(id == getState().auth.selectedCustomer.id) {
      // dispatch({
      //   type: 'CUSTOMER_ACTION_SWITCH',
      //   payload: response.data.customer
      // });
    }

    dispatch({
      type: 'SYNC_CUSTOMERS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const resetCustomersAction = () => dispatch => {
  dispatch({
    type: 'RESET_CUSTOMERS_ACTION_FETCH'
  })
}

export const resetCustomerAction = () => dispatch => {
  dispatch({
    type: 'RESET_CUSTOMERS_ACTION_FETCH'
  })
}
