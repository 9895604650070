import React from 'react'
import { Steps } from 'antd';

class ChoiceFormSteps extends React.Component {

  onChange = (current) => {

    if(this.props.saveStep)
    {
        this.props.saveStep(this.getStepURL(current));
    }
    else
    {
        this.goToStep(current);
    }
  }

  getStepURL(nr)
  {
      if(nr == 0)
      {
          return `/projects/${this.props.match.params.id}/choice/form/settings/${this.props.match.params.form_id}`;
      }
      else if(nr == 1)
      {
          return `/projects/${this.props.match.params.id}/choice/form/compose/${this.props.match.params.form_id}`;
      }
      else if(nr == 2)
      {
          return `/projects/${this.props.match.params.id}/choice/form/addresses/${this.props.match.params.form_id}`;
      }
      else if(nr == 3)
      {
          return `/projects/${this.props.match.params.id}/choice/form/status/${this.props.match.params.form_id}`;
      }
  }

  goToStep(nr)
  {
      this.props.history.push(this.getStepURL(nr));
  }

  render () {
    return(
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Instellingen" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Opmaken" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Adressen" />
        {/*<Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.form_id || this.props.match.params.form_id == 'add'} title="Status" />*/}
      </Steps>
    );
  }
}

export default ChoiceFormSteps;
