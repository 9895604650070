import React from 'react'
import { Steps } from 'antd';

class DeliverySteps extends React.Component {

  onChange = current => {

    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/deliver/delivery/${this.props.match.params.delivery_id}`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/deliver/delivery/${this.props.match.params.delivery_id}/addresses`);
    }
  }

  render () {
    return (
      <Steps type="navigation" size="small" current={this.props.current ? this.props.current : 0} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Gegevens" />
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} disabled={!this.props.match.params.delivery_id || this.props.match.params.delivery_id == 'add'} title="Adressen" />
      </Steps>
    );
  }
}

export default DeliverySteps;
