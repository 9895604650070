import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  blacklist: ['chat', 'news'],
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default function configureStore() {
//  return createStore(
//   rootReducer,
//   applyMiddleware(thunk)
//  );
// }

export default () => {
  //let store = createStore(rootReducer, applyMiddleware(thunk));
  let store = createStore(persistedReducer, applyMiddleware(thunk));
  let persistor = persistStore(store)
  return { store, persistor }
}
