import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
} from 'antd';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import AddFromLibrary from '../../../../components/AddFromLibrary/AddFromLibrary';

class ComposeMailForm extends Component {

  state = {
    filelist: [],
    deleteImage: false,
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'} colon={true}>
        <Row gutter={24} >
          <Col span={24}>
            <AddFromLibrary onSelectLibrary={(library) => this.props.form.setFieldsValue({'message':library.content.replace(/(<([^>]+)>)/gi, "")})}/>

            <Form.Item label="Push bericht">
              { getFieldDecorator('message', {
                initialValue: this.props.selectedMethod && this.props.selectedMethod.message,
              })(
                <Input.TextArea rows={4} />
              ) }
            </Form.Item>

          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'compose_mail_form' })(ComposeMailForm);
