import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Result,
} from 'antd';

class ScheduleSteps extends React.Component {

  onChange = current => {
    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/blocked-dates`);
    }

    if(current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/day-schedule`);
    }

    if(current == 3) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/calendar`);
    }

    // if(current == 4) {
    //   this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/export`);
    // }
  }

  render () {
    return (
      <Steps type="navigation" size="small" current={this.props.current} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: "10px 30px", marginRight: 10 }} title="Instellingen" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} title="Vrije dagen" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} title="Dag planning" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} title="Planning" />
        {/*<Steps.Step style={{padding: 10, marginLeft: 25 }} title="Exporteren" />*/}
      </Steps>
    );
  }
}

export default ScheduleSteps;
