import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Modal,
    Input,
    Radio,
    DatePicker,
    Select,
    Row,
    Col,
    Table,
    Card,
    List,
} from 'antd';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import 'moment/locale/nl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getAppointmentScheduleAction } from '../../../../../actions/appointmentsActions';

const columns = [
    {
        title: 'Address',
        dataIndex: 'full_address',
        key: 'id'
    }
];

let id = 0;

class ClustersForm extends Component {

    state = {
        clusters: [],
        cluster_addresses: [],
        selectedRowKeys: [],
        disabledRowKeys: []
    };

    // selectAddress(record) {
    //
    //     var selection = this.state.selectedRowKeys;
    //     var disabled = this.state.disabledRowKeys;
    //
    //     // add selected address
    //     selection.push('cluster_addresses[' + record.cluster_id + '][' + record.address_id + ']');
    //
    //     // disable address for other cluster(s)
    //     this.props.clusters.forEach((cluster) => {
    //         if(cluster.id != record.cluster_id){
    //             disabled.push('cluster_addresses[' + cluster.id + '][' + record.address_id + ']');
    //         }
    //     });
    //
    //     this.setState({
    //        selectedRowKeys: selection,
    //        disabledRowKeys: disabled
    //     });
    // }

    isDisabledAddress(record){

        this.state.disabledRowKeys.forEach((key) => {
            this.props.clusters.forEach((cluster) => {
                if(key == 'cluster_addresses[' + cluster.id + '][' + record.id + ']'){
                    return true;
                }
            });
        });

        return false;
    }

    componentDidMount() {

        this.props.getAddressesAction({'projectId' : this.props.match.params.id}); // .then(() => {});

        if(this.props.match.params.appointmentid)
        {
            this.props.getAppointmentScheduleAction(this.props.match.params.appointmentid).then(() => {

                // var selection = [];
                //
                // this.props.cluster_addresses.forEach((record) => {
                //     //this.selectAddress(record);
                //     selection.push('cluster_addresses[' + record.cluster_id + '][' + record.address_id + ']');
                // });
                //
                // this.setState({
                //     clusters: this.props.clusters,
                //     selectedRowKeys: selection
                // });
            });
        }
    }

    addCluster(e) {
        const { form } = this.props;

        const clusters = form.getFieldValue('clusters');
        const nextCluster = clusters.concat(id++);

        form.setFieldsValue({
            clusters: nextCluster,
        });
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    render() {

        const { getFieldDecorator, getFieldValue } = this.props.form;

        getFieldDecorator('clusters', { initialValue: [] });

        const clusters = getFieldValue('clusters');

        const { selectedRowKeys, disabledRowKeys, cluster_addresses } = this.state;

        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange : this.onSelectChange,
            getCheckboxProps : record => ({
                disabled : this.isDisabledAddress(record),
                name : record.full_address
            })
        };

        const clusterItems = clusters.map((key, cluster) => (
            <Table
                bordered
                title={() => 'Cluster'}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.props.addresses}
                pagination={false}
                key={'new_cluster_addresses[' + key + ']'}
                rowKey={(record) => 'new_cluster_addresses[' + key + '][' + record.id + ']'}
                />
        ));

        return (
            <div>
              <Row className="mbm">
                <Col span={24}>
                  <div>
                    <Button icon={<PlusOutlined />} onClick={(e) => this.addCluster(e)}>Nieuw cluster</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Form onSubmit={this.props.onSubmit}>
                    <Row gutter={24}>
                        <Col span={24}>

                            <Card>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.props.clusters}
                                    renderItem={cluster => (
                                        <Table
                                            bordered
                                            title={() => cluster.name}
                                            rowSelection={rowSelection}
                                            columns={columns}
                                            dataSource={this.props.addresses}
                                            pagination={false}
                                            key={'cluster[' + cluster.id + ']'}
                                            rowKey={(record) => 'cluster_addresses[' + cluster.id + '][' + record.id + ']'}
                                            />
                                    )}
                                />
                                {clusterItems}
                            </Card>

                        </Col>
                    </Row>
                </Form>
              </Row>
            </div>
        );
    }
}

const ClustersScreen = Form.create({ name: 'clusters_form' })(ClustersForm);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.address.isFetching,
        isError : state.address.isError,
        addresses : state.address.addresses,
        clusters : state.appointment.clusters,
        cluster_addresses : state.appointment.cluster_addresses
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClustersScreen);
