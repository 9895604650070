import axios from 'axios';
import api from '../utils/api';
import { API_URL } from '../constants/settings';

export const getAddressesAction = (params, filter = null) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('get','/addresses/'+params.projectId+(filter ? '/' + filter : ''), null, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const searchAddressesAction = (projectid, params, filter) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','/addresses/'+projectid+'/search'+(filter ? '/' + filter : ''), params, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_SEARCH_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const getAddressAction = (id) => dispatch => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  return api('get','address/'+id, null, true).then((response) => {
    dispatch({
      type: 'ADDRESS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

// export const loginAddressAction = (id) => dispatch => {
//
//   dispatch({
//     type: 'ADDRESSES_ACTION_FETCH'
//   })
//
//   return api('get','address/'+id+'/login', null, true).then((response) => {
//     return response.data;
//   }).catch((e) => {
//     dispatch({
//       type: 'ADDRESSES_ACTION_ERROR',
//     })
//     return false;
//   });
// }

export const loginAddressAction = (id) => dispatch => {

  dispatch({
    type: 'ADDRESS_LOGIN_ACTION_FETCH'
  });

  return api('get','address/'+id+'/login', null, true).then((response) => {

      dispatch({
        type: 'ADDRESS_LOGIN_ACTION_SUCCESS',
        payload: response.data
      });

    return response.data;
  }).catch((e) => {

    dispatch({
      type: 'ADDRESS_LOGIN_ACTION_ERROR',
    });

    return false;
  });
}

export const getAddressSharedDataAction = (id) => dispatch => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  return api('get','address/'+id+'/shared-data', null, true).then((response) => {
    dispatch({
      type: 'ADDRESS_SHARED_DATA_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const getAddressNoteAction = (addressid, noteid, params) => dispatch => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  return api('get','address/'+addressid+'/note/'+noteid, params, true).then((response) => {
    dispatch({
      type: 'ADDRESS_GET_NOTE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const addAddressNoteAction = (addressid, params) => dispatch => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  return api('post','address/'+addressid+'/note', params, true).then((response) => {
    dispatch({
      type: 'ADDRESS_ADD_NOTE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const patchAddressNoteAction = (addressid, noteid, params) => dispatch => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  return api('post','address/'+addressid+'/note/'+noteid, params, true).then((response) => {
    dispatch({
      type: 'ADDRESS_PATCH_NOTE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const addAddressesAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  return api('post','addresses/'+projectid, params, true).then((response) => {
      if(response.data.success)
      {
          dispatch({
            type: 'ADDRESS_ACTION_SUCCESS',
            payload: response.data.address
          });

          return true;
      }
      else
      {
        dispatch({
          type: 'ADDRESSES_ACTION_ERROR',
          payload: response.data.message
        });

        return false;
      }
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });

  return false;
}

export const importAddressesAction = (projectid, params, dry_run = true) => dispatch => {

  // dispatch({
  //   type: 'ADDRESSES_ACTION_FETCH'
  // })

  dispatch({
    type: 'IMPORT_ADDRESSES_ACTION_RESET'
  })

  var formData = new FormData();

  formData.append("excel_sheet", params.excel_sheet);

  // formData.append("clean_up", params.clean_up);

  return axios.post(API_URL+'/addresses/'+projectid+'/import-excel'+(dry_run ? '/dry-run' : '')+'?token='+localStorage.authToken, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((response) => {
    dispatch({
      type: 'ADDRESSES_IMPORT_ACTION_'+(dry_run ? 'DRYRUN' : 'REAL')+'_SUCCESS',
      // payload: response.data.addresses
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_IMPORT_ACTION_ERROR',
      // payload: response.data
    })
    return false;
  });
}

export const importAddressesByMapAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'IMPORT_ADDRESSES_ACTION_RESET'
  })

  return api('post', 'addresses/'+projectid+'/import-json', params, true).then((response) => {
      if(response.data.success)
      {
          dispatch({
            type: 'IMPORT_ADDRESSES_ACTION_SUCCESS',
            payload: response.data
          });
      }
      else
      {
        dispatch({
          type: 'IMPORT_ADDRESSES_ACTION_ERROR',
          payload: response.data.message
        })
        return false;
      }
    }).catch((e) => {
      dispatch({
        type: 'IMPORT_ADDRESSES_ACTION_ERROR',
      })
      return false;
  });
}

export const patchAddressesAction = (addressid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','address/'+addressid, params, true).then((response) => {
    if(response.data.success)
    {
        dispatch({
          type: 'ADDRESS_ACTION_SUCCESS',
          payload: response.data.address
        });
    }
    else
    {
      dispatch({
        type: 'ADDRESSES_ACTION_ERROR',
        payload: response.data.message
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteAddressesAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'DELETE_ADDRESS_ACTION_FETCH'
  })

  return api('post','addresses/'+projectid+'/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_ADDRESS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'DELETE_ADDRESS_ACTION_ERROR',
    })
    return false;
  });
}

export const resetPasswordAddressAction = (projectid, address_id) => (dispatch, getState) => {

  dispatch({
    type: 'ADDRESS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','addresses/' + projectid + '/reset/' + address_id, null, true).then((response) => {
    dispatch({
      type: 'RESET_PASSWORD_ADDRESS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESS_ACTION_ERROR',
    })
    return false;
  });
}

export const resetPasswordAddressesAction = (projectid, params) => (dispatch, getState) => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','addresses/' + projectid + '/multireset', params, true).then((response) => {
    dispatch({
      type: 'RESET_PASSWORD_ADDRESS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const shareAddressesAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','addresses/'+projectid+'/share', params, true).then((response) => {
    dispatch({
      type: 'SHARE_ADDRESS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const requestExportAddressesAction = (project_id) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('get', 'addresses/'+project_id+'/request/xls', null, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_EXPORT_REQUEST_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const exportAddressesAction = (projectid) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','addresses/'+projectid+'/xls', null, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const updateStatusAddressesAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','addresses/'+projectid+'/multistatus', params, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_STATUS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const requestDownloadDossier = (projectid, params) => dispatch => {

    return api('post','addresses/'+projectid+'/request-dossier', params, true, null, true).then((response) => {

    }).catch((e) => {
      return false;
    });

}

export const downloadDossier = (projectid, params) => dispatch => {

  return api('post','addresses/'+projectid+'/dossier', params, true, null, true).then((response) => {

    // 1
    var reader = new FileReader();

    var blob = new Blob([response.data], { type: 'application/zip' });

    var new_window = window.open('');

    new_window.location = URL.createObjectURL(blob);

    // old method (1)
    // reader.readAsDataURL(blob);
    //
    // reader.onloadend = function (e) {
    //
    //     window.open(reader.result, 'Export resultaten');
    // }

    // 2
    // const url = new Blob([response.data],{type:'application/zip'});
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'dossier.zip');
    // document.body.appendChild(link);
    // link.click();

  }).catch((e) => {
    return false;
  });
}

/*export const deleteAddressesDossierNoteAction = (addressid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','/address/' + addressid + '/notes/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_ADDRESS_NOTE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'DELETE_ADDRESS_NOTE_ACTION_ERROR',
    })
    return false;
  });
}*/

export const deleteAddressDossierFilesAction = (addressid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('post','/address/' + addressid + '/dossier/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_ADDRESS_DOSSIER_FILES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'DELETE_ADDRESS_DOSSIER_FILES_ACTION_ERROR',
    })
    return false;
  });
}

export const searchAddressesByZipcodeAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ZIP_ACTION_FETCH'
  })

  return api('post','/addresses/'+projectid+'/get-by-zipcode', params, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_BYZIPCODE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ZIP_ACTION_ERROR',
    })
    return false;
  });
}

export const importAddressesByZipcodeAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ZIP_ACTION_FETCH'
  })

  return api('post','/addresses/'+projectid+'/import-zipcode', params, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_IMPORT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const syncWithWocoAction = (projectid) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('get','/addresses/'+projectid+'/sync-with-woco', null, true).then((response) => {
    dispatch({
      type: 'ADDRESSES_SYNC_WITH_WOCO_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const storeDossierFileAction = (address_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'DOSSIER_FILE_ACTION_FETCH'
    })

    return api('post','/address/'+address_id+'/dossier/upload', params, true).then((response) => {
      dispatch({
          type: 'DOSSIER_FILE_ACTION_SUCCESS',
          payload: response.data
      });
    }).catch((e) => {
        dispatch({
            type: 'DOSSIER_FILE_ACTION_ERROR',
        })
        return false;
    });
}

export const exportDossierAction = (projectid, values) => dispatch => {

  // dispatch({
  //   type: 'ADDRESSES_ACTION_FETCH'
  // })

  return api('post','/addresses/'+projectid+'/request-project-dossier', values, true).then((response) => {
    // dispatch({
    //   type: 'ADDRESSES_SYNC_WITH_WOCO_ACTION_SUCCESS',
    //   payload: response.data
    // });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const resetAddressAction = () => dispatch => {
  dispatch({
    type: 'RESET_ADDRESS_ACTION_FETCH'
  })
}
export const resetAddressesAction = () => dispatch => {
  dispatch({
    type: 'RESET_ADDRESSES_ACTION_FETCH'
  })
}

export const resetAddressesZipcodeAction = () => dispatch => {
  dispatch({
    type: 'RESET_ADDRESSES_ZIPCODE_ACTION'
  })
}

export const resetAddressesNotesAction = () => dispatch => {
  dispatch({
    type: 'RESET_ADDRESSES_NOTES_ACTION'
  })
}
export const resetAddressesDossierAction = () => dispatch => {
  dispatch({
    type: 'RESET_ADDRESSES_DOSSIER_ACTION'
  })
}
export const resetDossierAction = () => dispatch => {
  dispatch({
    type: 'RESET_DOSSIER_ACTION_FETCH'
  })
}

export const requestExportScheduleAction = (project_id, type, selectedRowKeys) => (dispatch, getState) => {

    dispatch({
        type: 'REQUEST_EXPORT_ACTION_FETCH'
    });

    return api('post','/schedule/' + project_id + '/request/export/' + type, {ids: selectedRowKeys}, true, null, true).then((response) => { // response.blob()).then((blob) => {
        dispatch({
            type: 'REQUEST_EXPORT_ACTION_SUCCESS'
        });
    }).catch((e) => {
        dispatch({
            type: 'REQUEST_EXPORT_ACTION_ERROR',
        });

        return false;
    });
}

export const exportScheduleAction = (project_id, type, selectedRowKeys, filename = false) => (dispatch, getState) => {

  return api('post','/schedule/' + project_id + '/export/' + type, {ids: selectedRowKeys}, true, null, true).then((response) => { // response.blob()).then((blob) => {
    // dispatch({
    //   type: 'DELETE_INTAKES_ACTION_SUCCESS',
    //   payload: response.data
    // });

    // method 1
    // var reader = new FileReader();
    //
    // var blob = new Blob([response.data], { type: 'application/zip' });
    //
    // reader.readAsDataURL(blob);
    //
    // reader.onloadend = function (e) {
    //     window.open(reader.result, 'Export resultaten');
    // }


    // method 2
    // 2. Create blob link to download
    // const url = window.URL.createObjectURL(new Blob([response.data]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `sample.${this.state.file}`);
    //
    // // 3. Append to html page
    // document.body.appendChild(link);
    //
    // // 4. Force download
    // link.click();
    //
    // // 5. Clean up and remove the link
    // link.parentNode.removeChild(link);


    // method 3
    var reader = new FileReader();

    var blob = new Blob([response.data], { type: 'application/zip' });

    const a = document.createElement('a');

    a.setAttribute('download', (filename ? filename : 'planning-'+project_id+'-'+type+'-export.zip'));

    a.setAttribute('href', window.URL.createObjectURL(blob));

    a.click();

  }).catch((e) => {
    return false;
  });
}

export const getAddressFieldValuesAction = (address_id) => dispatch => {
    dispatch({
      type: 'ADDRESS_FIELD_VALUES_ACTION_FETCH'
    })

    return api('get','/address/'+address_id+'/fields', null, true).then((response) => {
      dispatch({
        type: 'ADDRESS_FIELD_VALUES_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'ADDRESS_FIELD_VALUES_ACTION_ERROR',
      })
      return false;
    });
}

export const updateAddressFieldValuesAction = (address_id, values) => dispatch => {
    dispatch({
      type: 'ADDRESS_FIELD_VALUES_ACTION_FETCH'
    })

    return api('post','/address/'+address_id+'/fields', values, true).then((response) => {
      dispatch({
        type: 'ADDRESS_FIELD_VALUES_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'ADDRESS_FIELD_VALUES_ACTION_ERROR',
      })
      return false;
    });
}

export const resetAddressFieldValuesAction = () => dispatch => {
  dispatch({
    type: 'ADDRESS_FIELD_VALUES_ACTION_RESET',
  })
}

export const requestDownloadFuseBoxLists = (project_id, params) => dispatch => {

    return api('post','addresses/'+project_id+'/fuse-box-list', params, true, null, true).then((response) => {

    }).catch((e) => {
      return false;
    });

}

export const getAddressDocumentAction = (addressid, document_id, params) => dispatch => {

  dispatch({
    type: 'ADDRESSES_ACTION_FETCH'
  })

  return api('get','address/'+addressid+'/document/'+document_id, params, true).then((response) => {
    dispatch({
      type: 'ADDRESS_GET_DOCUMENT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADDRESSES_ACTION_ERROR',
    })
    return false;
  });
}

export const addDocumentToAddressDossierAction = (address_id, values, file) => (dispatch, getState) => {

    dispatch({
        type: 'ADDRESS_ACTION_FETCH'
    })

    return api('post', 'address/'+address_id+'/dossier/upload', values, true, file).then((response) => {
        dispatch({
            type: 'ADDRESS_UPLOAD_FILE_ACTION_SUCCESS',
            payload: response.data
        });
        return response;

    }).catch((e) => {
        dispatch({
            type: 'ADDRESS_ACTION_ERROR',
        })
        return false;
    });
}

export const updateDocumentForAddressDossierAction = (address_id, id, values, file) => (dispatch, getState) => {

    dispatch({
        type: 'ADDRESS_ACTION_FETCH'
    })

    return api('post', 'address/'+address_id+'/dossier/'+id+'/upload', values, true, file).then((response) => {
        dispatch({
            type: 'ADDRESS_UPLOAD_FILE_ACTION_SUCCESS',
            payload: response.data
        });
        return response;

    }).catch((e) => {
        dispatch({
            type: 'ADDRESS_ACTION_ERROR',
        })
        return false;
    });
}

export const newOccupantAction = (address_id) => (dispatch, getState) => {

    dispatch({
        type: 'ADDRESS_ACTION_FETCH'
    })

    return api('post', 'address/'+address_id+'/new-occupant', null, true).then((response) => {
        dispatch({
            type: 'ADDRESS_UPLOAD_FILE_ACTION_SUCCESS',
            payload: response.data
        });
        return response.data;

    }).catch((e) => {
        dispatch({
            type: 'ADDRESS_ACTION_ERROR',
        })
        return false;
    });
}
