import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getGroupsAction, addGroupsAction, deleteGroupsAction, searchGroupsAction } from '../../../actions/groupsActions';
// import './ProjectsGroups.css';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Drawer, Input, Row, Col, message } from 'antd';
import { Link } from 'react-router-dom';

// function onChange(pagination, sorter) {
//   console.log('params', pagination, sorter);
// }

const Search = Input.Search;

class ProjectsGroups extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    visible: false,
    windowHeight: window.innerHeight,
    drawer: false,
    mockData: [],
    targetKeys: [],
  };

  componentDidMount() {
    this.props.getGroupsAction({'projectId': this.props.match.params.id});
  }

  showConfirm = () => {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteGroupsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.addGroupsAction(this.props.match.params.id, values).then(() => {
          this.onClose();
          showSuccess('Succesvol opgeslagen');
        });
      }
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showDrawer = () => {
    this.setState({
      drawer: true,
    });
  };

  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      drawer: false,
    });
  };

  onSearch(value) {
    this.props.searchGroupsAction(this.props.match.params.id, {'search': value});
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
          <Link to={`/projects/${this.props.match.params.id}/groups/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    }, {
      title: 'Omschrijving',
      dataIndex: 'description',
    }, {
      title: 'Aantal gekoppelde adressen',
      dataIndex: 'nr_of_addresses',
    }];

    return (
      <div>
        <Row className="mbm">
          <Col span={12} >
            <Link to={`/projects/${this.props.match.params.id}/groups/add`}>
              <Button disabled={!this.props.HaveAbility('address', 'write')} type="new">
                <PlusOutlined /> Toevoegen
              </Button>
            </Link>

          <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('address', 'write') ? false : true } overlay={(
              <Menu>
                <Menu.Item disabled={!this.props.HaveAbility('address', 'delete')} onClick={this.showConfirm} key="1" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
              </Menu>
            )}>
            <Button type="secondary">
              Acties <DownOutlined />
          </Button>
        </Dropdown>
      </Col>
      <Col span={12}>
        <Search
          allowClear
          placeholder="Zoeken..."
          onSearch={value => this.onSearch(value)}
          style={{ width: 200, float: 'right' }}
          />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <CustomTable
          rowKey='id'
          size="middle"
          rowSelection={rowSelection}
          // onChange={onChange}
          columns={columns}
          dataSource={this.props.isFetching ? [] : this.props.groups}
          loading={this.props.isFetching}
          // dataSource={this.props.accounts}
          />
      </Col>
    </Row>
    <Drawer
      title="Nieuwe groep toevoegen"
      width={720}
      onClose={this.onClose}
      visible={this.state.drawer}
      className={'formWrapper'}
      >
      <Form className="ant-form--custom" onSubmit={this.handleSubmit} colon={true}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item hasFeedback label="Naam">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Vul een algemene naam in!' }],
              })(
                <Input></Input>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item required hasFeedback label="Beschrijving">
              {getFieldDecorator('description', {
                rules: [{ required: false, message: 'Vul een beschrijving in.'}],
              })(
                <Input></Input>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="end" align="middle" className="mtm">
            <Button disabled={this.props.isFetching} style={{marginRight: 8}} onClick={this.onClose}>Annuleren</Button> <Button loading={this.props.isFetching} htmlType="submit" type="primary ant-btn-new">Toevoegen <PlusOutlined /></Button>
          </Row>
        </Form>
      </Drawer>
    </div>
    );
}
}

const ProjectsGroupsAdd = Form.create({ name: 'add_projectsgroup' })(ProjectsGroups);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.group.isFetching,
    isError : state.group.isError,
    groups: state.group.groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    addGroupsAction : bindActionCreators(addGroupsAction, dispatch),
    deleteGroupsAction : bindActionCreators(deleteGroupsAction, dispatch),
    searchGroupsAction : bindActionCreators(searchGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsGroupsAdd);
