import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, Radio, DatePicker, Select, Row, Col, Table, Tag } from 'antd';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import 'moment/locale/nl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getAppointmentScheduleAction } from '../../../../../actions/appointmentsActions';

class AppointmentForm extends Component {

    state = {
        visible: false,
        clusters: [],
        available_times: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        },
        first_date: false,
        selected_addresses: []
    }

    componentDidMount() {
        this.props.getAppointmentScheduleAction(this.props.match.params.appointmentid).then(() => {

            this.setState({
                first_date: this.props.first_date
            });


            this.setState({
                available_times: this.props.available_times
            });


            this.setState({
                clusters: this.props.clusters
            });


            var selected_addresses = [];

            this.props.addresses.forEach((address) => {

                this.props.cluster_addresses.forEach((cluster_address) => {

                    if(address.id == cluster_address.address_id){

                        // add cluster to address
                        this.props.clusters.forEach((cluster) => {
                            if(cluster_address.cluster_id == cluster.id){
                                address.cluster = cluster.name;
                            }
                        });

                        // scheduled date and time
                        this.props.scheduled.forEach((schedule) => {
                            if(schedule.address_id == address.id){
                                address.scheduled = schedule.date + ' ' + schedule.time
                            }
                        });

                        selected_addresses.push(address);
                    }
                });
            });

            this.setState({
                selected_addresses: selected_addresses
            });
        });
    }

    columns = [
        {
            title: 'Address',
            dataIndex: 'full_address',
            key: 'id'
        },
        {
            title: 'Cluster',
            dataIndex: 'cluster'
        },
        {
            title: 'E-mail',
            dataIndex: 'email'
        },
        {
            title: 'Brief',
            dataIndex: 'mail'
        },
        {
            title: 'Gepland',
            dataIndex: 'scheduled'
        },
        {
            title: '',
            dataIndex: '',
            render: (text, record) => {
                return <Button type="primary" onClick={() => this.showModal(record.id)}>Kies datum/tijd</Button>
            }
        }
    ];

    showModal = () => {
      this.setState({
        visible: true,
      });
    };

    handleOk = e => {
      this.setState({
        visible: false,
      });
    };

    handleCancel = e => {
      this.setState({
        visible: false,
      });
    };

    getDayAvailableTimes(date) {

        var $day = moment(date).day();

        if($day == 1){ return this.state.available_times.monday; }
        if($day == 2){ return this.state.available_times.tuesday; }
        if($day == 3){ return this.state.available_times.wednesday; }
        if($day == 4){ return this.state.available_times.thursday; }
        if($day == 5){ return this.state.available_times.friday; }
        if($day == 6){ return this.state.available_times.saturday; }
        if($day == 7){ return this.state.available_times.sunday; }

        return [];
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        const { clusters, available_times, first_date } = this.state;

        return (
            <Form onSubmit={this.props.onSubmit}>
                <Row gutter={24}>
                    <Col span={24}>

                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={this.state.selected_addresses}
                            pagination={false}
                            />

                        <Modal
                          title="Kies datum / tijd"
                          visible={this.state.visible}
                          onOk={this.handleOk}
                          onCancel={this.handleCancel}
                        >
                            <DatePicker
                              onChange={this.onDatePickerChange}
                              defaultValue={moment()}
                              />
                              <select>
                                {
                                    this.getDayAvailableTimes().map((time, index) => {
                                    return <option>{time}</option>
                                } ) }
                              </select>
                        </Modal>

                    </Col>
                </Row>
            </Form>
        );
    }

}

const AppointmentsScreen = Form.create({ name: 'clusters_form' })(AppointmentForm);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.address.isFetching,
        isError : state.address.isError,
        appointment : state.appointment,
        addresses : state.address.addresses,
        first_date : state.appointment.first_date,
        clusters : state.appointment.clusters,
        available_times : state.appointment.available_times,
        scheduled : state.appointment.scheduled,
        cluster_addresses : state.appointment.cluster_addresses
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsScreen);
