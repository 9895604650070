import api from '../utils/api'

export const resetMediaAction = () => dispatch => {

    dispatch({
        type: 'RESET_MEDIA_ACTION_FETCH'
    })
}

export const resetMediaFileAction = () => dispatch => {

    dispatch({
        type: 'RESET_MEDIAFILE_ACTION_FETCH'
    })
}

export const getMediaLibraryAction = (params = null, project_id = false, include_customer = false) => (dispatch, getState) => {

    dispatch({
        type: 'MEDIALIBRARY_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get', '/media/' + customer_id + (project_id ? '/project/' + project_id + (include_customer ? '/include-customer' : '') : ''), null, true).then((response) => {
        dispatch({
            type: 'MEDIALIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'MEDIALIBRARY_ACTION_ERROR',
        })
        return false;
    });
}

export const searchMediaLibraryAction = (search, project_id = false) => (dispatch, getState) => {

    dispatch({
        type: 'MEDIALIBRARY_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/media/' + customer_id + (project_id ? '/project/' + project_id : '') + '/search', {'search': search}, true).then((response) => {
        dispatch({
            type: 'SEARCH_MEDIALIBRARY_ACTION_SUCCESS',
            payload: response.data
        });

        return true;
    }).catch((e) => {
        dispatch({
            type: 'SEARCH_MEDIALIBRARY_ACTION_ERROR',
        })
        return false;
    });
}

export const getMediaFileAction = (id) => dispatch => {

    dispatch({
        type: 'MEDIAFILE_ACTION_FETCH'
    })

    return api('get','/media/file/'+id, null, true).then((response) => {
        dispatch({
            type: 'MEDIAFILE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'MEDIAFILE_ACTION_ERROR',
        })
        return false;
    });
}

export const storeMediaFileAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'MEDIAFILE_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/media/'+customer_id, params, true).then((response) => {
      dispatch({
          type: 'STORE_MEDIAFILE_ACTION_SUCCESS',
          payload: response.data.medialibrary
      });
    }).catch((e) => {
        dispatch({
            type: 'MEDIAFILE_ACTION_ERROR',
        })
        return false;
    });
}

export const addMediaFileAction = (params, filelist) => (dispatch, getState) => {

    dispatch({
        type: 'MEDIAFILE_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post', '/media/' + customer_id, params, true, filelist).then((response) => {
        dispatch({
            type: 'STORE_MEDIAFILE_ACTION_SUCCESS',
            payload: response.data.medialibrary
        });
        return response;

    }).catch((e) => {
        dispatch({
            type: 'MEDIAFILE_ACTION_ERROR',
        })
        return false;
    });
}

export const addMediaProjectFileAction = (project_id, params, filelist) => (dispatch, getState) => {

    dispatch({
        type: 'MEDIAFILE_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post', '/media/' + customer_id + '/project/' + project_id, params, true, filelist).then((response) => {
        dispatch({
            type: 'STORE_MEDIAFILE_ACTION_SUCCESS',
            payload: response.data.medialibrary
        });
        return response;

    }).catch((e) => {
        dispatch({
            type: 'MEDIAFILE_ACTION_ERROR',
        })
        return false;
    });
}

export const patchMediaFileAction = (id, params, filelist) => dispatch => {

    dispatch({
        type: 'MEDIAFILE_ACTION_FETCH'
    })

    return api('post', '/media/file/'+id, params, true, filelist).then((response) => {
        dispatch({
            type: 'PATCH_MEDIAFILE_ACTION_SUCCESS',
            payload: response.data.medialibrary
        });
    }).catch((e) => {
        dispatch({
            type: 'MEDIAFILE_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteMediaLibraryAction = (params) => (dispatch, getState) => {

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/media/' + customer_id + '/multidelete', params, true).then((response) => {
        dispatch({
            type: 'DELETE_MEDIALIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
        return true;
    }).catch((e) => {
        // dispatch({
        //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
        // })
        return false;
    });
}

export const resetMediaLibraryAction = () => (dispatch, getState) => {
    dispatch({
        type: 'MEDIAFILE_ACTION_RESET'
    })
}



/* CATEGORIES */

export const getMediaCategoriesAction = () => (dispatch, getState) => {

    dispatch({
        type: 'MEDIA_CATEGORIES_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get', '/media/'+customer_id+'/categories', null, true).then((response) => {
        dispatch({
            type: 'MEDIA_CATEGORIES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'MEDIALIBRARY_ACTION_ERROR',
        })
        return false;
    });
}

export const deleteMediaCategoriesAction = (params) => (dispatch, getState) => {

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/media/' + customer_id + '/category/multidelete', params, true).then((response) => {
        dispatch({
            type: 'DELETE_MEDIALIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
        return true;
    }).catch((e) => {
        // dispatch({
        //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
        // })
        return false;
    });
}

export const getMediaCategoryAction = (id) => dispatch => {

    dispatch({
        type: 'MEDIA_CATEGORY_ACTION_FETCH'
    })

    return api('get', '/media/category/'+id, null, true).then((response) => {
        dispatch({
            type: 'MEDIA_CATEGORY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'MEDIA_CATEGORY_ACTION_ERROR',
        })
        return false;
    });
}

export const addMediaCategoryAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'MEDIA_CATEGORY_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post', '/media/' + customer_id + '/category', params, true).then((response) => {
        dispatch({
            type: 'STORE_MEDIA_CATEGORY_ACTION_SUCCESS',
            payload: response.data.media_category
        });
        return response;

    }).catch((e) => {
        dispatch({
            type: 'MEDIA_CATEGORY_ACTION_ERROR',
        })
        return false;
    });
}

export const updateMediaCategoryAction = (id, params) => dispatch => {

    dispatch({
        type: 'MEDIA_CATEGORY_ACTION_FETCH'
    })

    return api('post', '/media/category/'+id, params, true).then((response) => {
        dispatch({
            type: 'PATCH_MEDIA_CATEGORY_ACTION_SUCCESS',
            payload: response.data.medialibrary
        });
    }).catch((e) => {
        dispatch({
            type: 'MEDIA_CATEGORY_ACTION_ERROR',
        })
        return false;
    });
}

export const copyImageToProject = (id, project_id) => dispatch => {

    dispatch({
        type: 'MEDIA_CATEGORY_ACTION_FETCH'
    });

    return api('post', '/media/file/' + id + '/copy-to/' + project_id, null, true).then((response) => {

        dispatch({
            type: 'COPY_MEDIA_TO_PROJECT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'MEDIA_CATEGORY_ACTION_ERROR',
        });

        return false;
    });
}

export const resetMediaCategoryAction = () => (dispatch, getState) => {
    dispatch({
        type: 'MEDIA_CATEGORY_ACTION_RESET'
    })
}
