import React, { Component } from 'react';
// import './SettingsClient.css';


import { BookOutlined, HomeOutlined, NotificationOutlined, SmileOutlined } from '@ant-design/icons';


import { Row, Col, Alert, Menu, Card, Typography, Select } from 'antd';

function handleChange(value) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log('blur');
}

function handleFocus() {
  console.log('focus');
}

const Option = Select.Option;
const { Title } = Typography;

class SettingsClient extends Component {
    render() {
        return (
            <div className="settings">
                <Card>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={4}>
                            <Menu
                                defaultSelectedKeys={[2]}
                            >
                                <Menu.Item key="1">
                                    <HomeOutlined />
                                    Algemeen
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <SmileOutlined />
                                    Opdrachtgever
                                </Menu.Item>
                                <Menu.Item key="3">
                                <BookOutlined />
                                    Huisstijl
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <NotificationOutlined />
                                    Boodschap
                                </Menu.Item>
                            </Menu>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={18} xl={18} xxl={20}>
                            <Title style={{ fontSize: '24px'}}>Opdrachtgever</Title>
                              <Alert
                              message="Staat deze er niet bij? Maak deze dan aan bij stamgegevens."
                              type="warning"
                              showIcon
                              />
                            <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Gemaakt door bedrijf"
                            optionFilterProp="children"
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="tom">Tom</Option>
                            </Select>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default SettingsClient;
