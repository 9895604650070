import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class HasRole extends Component {

  render() {
    if(this.props.roleName.includes(this.props.role)) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      role: state.auth.user.user.role,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HasRole);
