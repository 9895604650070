import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateScheduleSettingsAction, updateScheduleBlockedDatesAction, addDayScheduleAction, updateDayScheduleAction, updateScheduleGanttAction } from '../../../../actions/scheduleActions';
import Settings from './Form/Settings';
import BlockedDates from './Form/BlockedDates';
import DaySchedule from './Form/DaySchedule';
import Calendar from './Form/Calendar';
import ScheduleSteps from './Steps/ScheduleSteps';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
class Schedule extends Component
{
  state = {
    current: 0 // 0 - 3
  }

  saveSchedule(e) {
    if(this.state.current == 0) {
      this.saveSettings(e);
    }
    else if(this.state.current == 2) {
      this.saveDaySchedule(e);
    }
    else if(this.state.current == 3) {
      this.saveCalendar(e);
    }
  }

  saveSettings(e) {
    this.settingsForm.props.form.validateFields((err, values) =>
    {
      this.props.updateScheduleSettingsAction(this.props.match.params.id, {...values, "image_id": this.settingsForm.state.image_id}).then(() => {
        showSuccess('Succesvol opgeslagen');
      });
    });
  }

  saveDaySchedule(e) {
    this.dayscheduleForm.props.form.validateFields((err, values) =>
    {
      if(this.dayscheduleForm.state.group_id)
      {
        this.props.updateDayScheduleAction(this.dayscheduleForm.state.group_id, values).then(() => {

          this.dayscheduleForm.props.getDayScheduleAction(this.props.match.params.id).then(() => {
            this.dayscheduleForm.setState({
              dayschedule: this.dayscheduleForm.props.dayschedule
            });
          });

          showSuccess('Succesvol opgeslagen');
        });
      }
      else
      {
        this.props.addDayScheduleAction(this.props.match.params.id, values).then(() => {

          this.dayscheduleForm.props.getDayScheduleAction(this.props.match.params.id).then(() => {
            this.dayscheduleForm.setState({
              dayschedule: this.dayscheduleForm.props.dayschedule
            });
          });

          this.dayscheduleForm.editGroup(this.dayscheduleForm.props.added_group);

          showSuccess('Succesvol toegevoegd');
        });
      }
    });
  }

  saveCalendar(e)
  {
    this.props.updateScheduleGanttAction(this.props.match.params.id, this.scheduleForm.state.gantt.serialize()).then(() => {
      showSuccess('Succesvol opgeslagen');
    });
  }

  render()
  {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => this.saveSchedule(e) }>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleSteps {...this.props} />
          </Card>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card>

              {this.state.current == 0 && (
                <Settings
                  wrappedComponentRef={formRef => { this.settingsForm = formRef }}
                  onSubmit={(e) => this.saveSettings(e)} {...this.props}
                  />
              )}

              {this.state.current == 1 && (
                <BlockedDates
                  wrappedComponentRef={formRef => { this.blockeddatesForm = formRef }}
                  {...this.props}
                  />
              )}

              {this.state.current == 2 && (
                <DaySchedule
                  wrappedComponentRef={formRef => { this.dayscheduleForm = formRef }}
                  onSubmit={(e) => this.saveDaySchedule(e)} {...this.props}
                  />
              )}

              {this.state.current == 3 && (
                <Calendar
                  wrappedComponentRef={formRef => { this.scheduleForm = formRef }}
                  onSubmit={(e) => this.saveCalendar(e)} {...this.props}
                  />
              )}

            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    updateScheduleBlockedDatesAction : bindActionCreators(updateScheduleBlockedDatesAction, dispatch),
    addDayScheduleAction : bindActionCreators(addDayScheduleAction, dispatch),
    updateDayScheduleAction : bindActionCreators(updateDayScheduleAction, dispatch),
    updateScheduleGanttAction : bindActionCreators(updateScheduleGanttAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
