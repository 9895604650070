import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Button,
    Drawer,
    Divider,
    Tag,
    Modal,
    Upload,
    Input,
    Avatar,
    Typography,
    Tooltip,
    Radio,
    Switch,
} from 'antd';
import { zipcodeValidation } from '../../../../utils/validationRules';
import NumberFormat from 'react-number-format';
class PatchContactsForm extends Component {


    constructor(props) {
      super(props);

      // console.log(this.props.form.getFieldValue('different_address'));

      this.state = {
        different_address: this.props.occupant.different_address,
      }
    }

    render()
    {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>
              <Row className="mbm" gutter={24}>
                <Col span={12}>
                    <Form.Item required label="Geslacht">
                        {getFieldDecorator('gender', {
                          initialValue: this.props.occupant.gender || 'u'
                        })(
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value="m">Man</Radio.Button>
                            <Radio.Button value="f">Vrouw</Radio.Button>
                            <Radio.Button value="u">Onbekend</Radio.Button>
                          </Radio.Group>
                         )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item hasFeedback label="Omschrijving">
                        { getFieldDecorator('description', {
                          initialValue: this.props.occupant.description ? this.props.occupant.description : 'Bewoner',
                          rules: [{ required: false, message: 'Vul een omschrijving in' }],
                        })(
                            <Input></Input>
                         ) }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item hasFeedback label="Voorletter(s)">
                        {getFieldDecorator('initials', {
                          initialValue: this.props.occupant.initials,
                          rules: [{ required: false, message: 'Vul voorletter(s) in' }],
                        })(
                            <Input></Input>
                         )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item hasFeedback label="Voornaam">
                        {getFieldDecorator('first_name', {
                          initialValue: this.props.occupant.first_name,
                          rules: [{ required: false, message: 'Vul een voornaam in' }],
                        })(
                            <Input></Input>
                         )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item hasFeedback label="Tussenvoegel">
                        {getFieldDecorator('affix', {
                          initialValue: this.props.occupant.affix,
                          rules: [{ required: false, message: 'Vul een tussenvoegsel in' }],
                        })(
                            <Input></Input>
                         )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item hasFeedback label="Achternaam">
                        {getFieldDecorator('last_name', {
                          initialValue: this.props.occupant.last_name,
                          rules: [{ required: true, message: 'Vul een achternaam in' }],
                        })(
                            <Input></Input>
                         )}
                    </Form.Item>
                </Col>
                  <Col span={12}>
                      <Form.Item hasFeedback label="E-mailadres">
                          {getFieldDecorator('email_address', {
                            initialValue: this.props.occupant.email_address,
                            rules: [{ required: false, type: 'email', message: 'Vul een geldig e-mailadres in'}],
                          })(
                             <Input></Input>,
                          )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item hasFeedback label={
                          <span>
                              Mobiel
                              <Tooltip className="mhs" title='Het mobiele nummer wordt gebruikt voor de SMS functie van correspondentie.'>
                                  <InfoCircleTwoTone />
                              </Tooltip>
                          </span>}>
                          {getFieldDecorator('mobile', {
                            initialValue: this.props.occupant.mobile ? this.props.occupant.mobile : '',
                            rules: [{ required: false, message: 'Vul een mobiel telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                          })(
                              <NumberFormat allowEmptyFormatting={true} format="################" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
                           )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item hasFeedback label="Telefoon">
                          { getFieldDecorator('telephone', {
                            initialValue: this.props.occupant.telephone ? this.props.occupant.telephone : '',
                            rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                          })(
                              <NumberFormat allowEmptyFormatting={true} format="################" mask="" className="ant-input" style={{ width: '100%', padding: 7, paddingLeft: 10, paddingRight: 10, borderRadius: 5, borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }} />
                           ) }
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item label="Voorkeur om e-mail te ontvangen">
                          {getFieldDecorator('prefer_email', {
                            initialValue: this.props.occupant.prefer_email,
                            valuePropName: 'checked'
                          })(
                            <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            />
                           )}
                      </Form.Item>
                  </Col>
                  <Divider />
                  <Col span={24}>
                      <Form.Item label="Afwijkend adres">
                          { getFieldDecorator('different_address', {
                            initialValue: this.props.occupant.different_address,
                            valuePropName: 'checked',
                            onChange: (value) => this.setState({different_address: value})
                          })(
                            <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            />
                           )}
                      </Form.Item>
                  </Col>
                  {this.props.form.getFieldValue('different_address') ? (
                  <Row className="mbm" gutter={24}>
                  <Col span={12}>
                      <Form.Item required label="Straat" hasFeedback>
                          {getFieldDecorator('street', {
                            rules: [{ required: true, message: 'Vul een straatnaam in' }],
                            initialValue: this.props.occupant.street,
                          })(
                            <Input></Input>
                           )}
                      </Form.Item>
                  </Col>
                  <Col span={6}>
                      <Form.Item required label="Huisnummer" hasFeedback>
                          {getFieldDecorator('house_number', {
                            rules: [{ required: true, message: 'Vul een huisnummer in' }],
                            initialValue: this.props.occupant.house_number,
                          })(
                            <Input></Input>
                           )}
                      </Form.Item>
                  </Col>
                  <Col span={6}>
                      <Form.Item label="Toevoeging" hasFeedback>
                          {getFieldDecorator('house_number_suffix', {
                            rules: [{ required: false, message: 'Vul een toevoeging in' }],
                            initialValue: this.props.occupant.house_number_suffix,
                          })(
                            <Input></Input>
                           )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item required label="Postcode" hasFeedback>
                          {getFieldDecorator('zipcode', {
                            rules: [{ required: true, message: 'Vul een postcode in' }, { validator: zipcodeValidation }],
                            initialValue: this.props.occupant.zipcode,
                          })(
                            <Input maxLength={6}></Input>
                           )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item required label="Plaats" hasFeedback>
                          {getFieldDecorator('city', {
                            rules: [{ required: true, message: 'Vul een plaatsnaam in' }],
                            initialValue: this.props.occupant.city,
                          })(
                            <Input></Input>
                           )}
                      </Form.Item>
                  </Col>
                </Row>
                  ) : null }

              </Row>
            </Form>
        );
    }
}

export default Form.create({ name: 'patch_contacts_form' })(PatchContactsForm);
