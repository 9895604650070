import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProjectsAction, searchProjectsAction, addProjectAction, deleteProjectsAction, statusProjectsAction, cloneProjectAction } from '../../actions/projectActions';
import { getCustomersAction, getCustomerAction } from '../../actions/customerActions';
import { getAccountsAction } from '../../actions/accountActions';
import { HaveRole, HaveAbility, CustomerCan } from '../../utils/RolesAndAbilities';
// import './Projects.css';
import CustomTable from '../../components/CustomTable/CustomTable';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Row, Col, Drawer, Select, message } from 'antd';
import { showMultiDelete, showSuccess, showExtraConfirm } from '../../utils/Notifications';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PageHeader from '../../components/PageHeader/PageHeader';
import { getState } from 'redux'

const columns = [{
  title: 'Naam',
  dataIndex: 'name',
  sorter: (a, b) => { return a.name.localeCompare(b.name)},
  sortDirections: ['descend', 'ascend'],
  render: (text, record) => (
    <span>
      <Link to={`/projects/${record.id}/dashboard`}>
        {text}
      </Link>
    </span>
  )
},
{
  title: 'Interne naam',
  dataIndex: 'internal_name',
  sorter: (a, b) => { return (a.internal_name ? a.internal_name : '').localeCompare(b.internal_name ? b.internal_name : '')},
  sortDirections: ['descend', 'ascend'],
  render: (text, record) => (
    <span>{text}</span>
  )
},
{
  title: 'Nummer',
  dataIndex: 'project_number',
  sorter: (a, b) => { return a.project_number.localeCompare(b.project_number)},
  sortDirections: ['descend', 'ascend'],
  render: (text, record) => (
    <span>{text}</span>
  )
},
{
  title: 'Adressen',
  dataIndex: 'nr_of_addresses',
}, {
  title: 'Status',
  dataIndex: 'status',
  render: (text, record) => (
    <span>
      {text === 1 ? "Open" : "Afgerond"}
    </span>
  ),
  filters: [
    {
      text: 'Open',
      value: 1,
    },
    {
      text: 'Afgerond',
      value: 2,
    },
  ],
  onFilter: (value, record) => record.status === value,
}, {
  title: 'Aangemaakt door',
  dataIndex: 'user_name',
  sorter: (a, b) => { return a.user_name.localeCompare(b.user_name)},
}, {
  title: 'Zichtbaarheid',
  dataIndex: 'is_public',
  render: (text, record) => (
    <span>
      {text === 1 ? "Openbaar" : "Prive"}
    </span>
  ),
  sorter: (a, b) => { return a.is_public - b.is_public},
}, {
  title: 'Datum',
  dataIndex: 'created_at',
  sorter: (a, b) => { return moment(a.created_at).unix() - moment(b.created_at).unix()},
  render: (index, record) => (moment(record.created_at).format('DD/MM/YYYY - HH:mm uur'))
}];

function onChange(pagination, sorter) {
  console.log('params', pagination, sorter);
}

const confirm = Modal.confirm;

class Projects extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    visible: false,
    drawerVisible: false,
    projects: [],
    loading: true,
    customer_id: false,
  };

  componentDidMount() {

    // if(!this.props.CustomerCan('projects'))
    // {   // redirect
    //     if(this.props.CustomerCan('library')){ this.props.history.push('/library'); }
    //     else if(this.props.CustomerCan('media')){ this.props.history.push('/media'); }
    //     else if(this.props.CustomerCan('accounts')){ this.props.history.push('/accounts'); }
    //     else { this.props.history.push(`/accounts/${ this.props.auth && this.props.auth.user && this.props.auth.user.id}/profile`); }
    // }
    // else
    {
        this.loadData(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if(!this.state.loading && this.props.selectedCustomer && prevProps.selectedCustomer && this.props.selectedCustomer.id != prevProps.selectedCustomer.id)
    {
        this.loadData(true);
    }
  }

  loadData(force = false)
  {
      if(!this.state.loading || force)
      {
          this.setState({ loading: true }, () => {
              var customer_id = this.props.getProjectsAction().then(() => {
                  this.setState({
                      projects: this.props.projects
                  }, () => { this.setState({ loading: false }); });
              });

              var this_obj = this;

              if(!customer_id)
              {
                  // if(this_obj.clear_timeout)
                  // {
                  //     clearTimeout(this_obj.clear_timeout);
                  // }
                  //
                  // this_obj.clear_timeout = setTimeout(() => { this_obj.loadData(true); }, 1000);

                  this_obj.clear_timeout = setTimeout(() => {
                      console.log('refresh window, customer id not detected (yet)');
                      window.location.href = window.location.href;
                  }, 1000);
              }
          });
      }
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteProjectsAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          this.props.getProjectsAction().then(() => {
            this.setState({projects: this.props.projects});
          });
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  showDrawer = () => {

    this.props.getCustomersAction().then(() => {
      this.props.getAccountsAction();
    });

    this.setState({
      showDrawer: true,
    });
  };

  onClose = () => {

    this.props.form.resetFields();
    this.setState({
      showDrawer: false,
    });
  };

  openModal = (e) => {
    this.setState({
      visible: true,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {
    console.log(e);

    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  addProject() {
      if(this.props.customer.license_nr_of_projects > 0 && this.props.customer.nr_of_projects >= this.props.customer.license_nr_of_projects)
      {
          showExtraConfirm((confirm) => {
              if(confirm)
              {
                this.props.history.push('/projects/add');
              }
          }, 'Licentie', 'De limiet van het aantal projecten voor je licentie wordt hiermee overschreden. Extra kosten zullen dan in rekening worden gebracht. Weet je zeker dat je door wilt gaan?')
      }
      else
      {
          this.props.history.push('/projects/add');
      }
  }

  render() {
    const SubMenu = Menu.SubMenu;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    return (
      <div>
        <PageHeader path={[{'label': 'Projecten'}]} />
        <Modal
          title="Basic Modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          >
        </Modal>
        <Row className="mbm">
          <Col span={12} >

            <span>
              {/*<Link to="projects/add">
                  <Button disabled={!this.props.HaveAbility('projects' ,'write')} icon="plus" type="new" >
                    Toevoegen
                  </Button>
              </Link>*/}
                  <Button disabled={!this.props.HaveAbility('projects' ,'write')} onClick={() => { this.addProject(); }} icon={<PlusOutlined />} type="new" >
                    Toevoegen
                  </Button>

              {this.props.HaveAbility('projects' ,'write') && (
                <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                    <Menu>
                      {/*<SubMenu title="Status wijzigen">
                        <Menu.Item onClick={() => this.props.statusProjectsAction({'ids': this.state.selectedRowKeys, 'status': 1}).then(() => {
                            this.props.getProjectsAction().then(() => {
                              this.setState({projects: this.props.projects});
                            });
                            this.setState({selectedRowKeys: []});
                            showSuccess('Succesvol bijgewerkt');
                          })} key="1">Open</Menu.Item>
                        <Menu.Item onClick={() => this.props.statusProjectsAction({'ids': this.state.selectedRowKeys, 'status': 2}).then(() => {
                            this.props.getProjectsAction().then(() => {
                              this.setState({projects: this.props.projects});
                            });
                            this.setState({selectedRowKeys: []});
                            showSuccess('Succesvol bijgewerkt');
                          })} key="2">Afgerond</Menu.Item>
                      </SubMenu>*/}
                      <Menu.Item disabled={(this.state.selectedRowKeys.length == 1 ? false : true)} onClick={() => this.props.cloneProjectAction(this.state.selectedRowKeys[0]).then(() => {
                        this.props.getProjectsAction().then(() => {
                          this.setState({projects: this.props.projects});
                        });
                            showSuccess('Succesvol gedupliceerd');
                          this.setState({selectedRowKeys: []});
                        } )} key="3">Dupliceren</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => this.showConfirm()} key="4" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary">
                    Acties <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </span>

        </Col>

        <Col span={12}>
          <Input.Search
            allowClear
            placeholder="Zoeken..."
            onSearch={ value => this.props.searchProjectsAction(value).then(() => {
              this.setState({projects: this.props.projects})
            }) }
            style={{ width: 200, float: 'right' }}
            />
          <div style={{float: 'right', marginRight: '20px', paddingTop: '6px'}}>{ this.props.project.nr_of_projects + (this.props.project.license_nr_of_projects ? ' / ' + this.props.project.license_nr_of_projects : '') + ' projecten -  ' + this.props.project.nr_of_addresses + (this.props.project.license_nr_of_addresses ? ' / ' + this.props.project.license_nr_of_addresses : '') + ' adressen' }</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            size="middle"
            rowKey="id"
            rowSelection={rowSelection}
            onChange={onChange}
            columns={columns}
            loading={this.props.isFetching}
            dataSource={this.state.projects}
            />
        </Col>
      </Row>
    </div>
    );
}
}


const ProjectsForm = Form.create({ name: 'projects' })(Projects);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isCustomersFetching : state.customer.isFetching,
    isAccountsFetching : state.account.isFetching,
    isError : state.project.isError,
    project: state.project,
    projects: state.project.projects,
    customers: state.customer.customers,
    customer: state.customer.customer,
    selectedCustomer: state.auth.selectedCustomer,
    accounts: state.account.accounts,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectsAction : bindActionCreators(getProjectsAction, dispatch),
    addProjectAction : bindActionCreators(addProjectAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    //getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    deleteProjectsAction : bindActionCreators(deleteProjectsAction, dispatch),
    statusProjectsAction : bindActionCreators(statusProjectsAction, dispatch),
    searchProjectsAction : bindActionCreators(searchProjectsAction, dispatch),
    cloneProjectAction : bindActionCreators(cloneProjectAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    CustomerCan : bindActionCreators(CustomerCan, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsForm);
