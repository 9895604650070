import api from '../utils/api'

export const getOccupantsAction = (addressid) => dispatch => {

    dispatch({
        type: 'OCCUPANTS_ACTION_FETCH'
    })

    return api('get','/address/'+addressid+'/occupants/', null, true).then((response) => {
        dispatch({
            type: 'OCCUPANTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'OCCUPANTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getOccupantAction = (occupantid) => dispatch => {

    dispatch({
        type: 'OCCUPANTS_ACTION_FETCH'
    })

    return api('get','/occupant/'+occupantid, null, true).then((response) => {
        dispatch({
            type: 'OCCUPANT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'OCCUPANTS_ACTION_ERROR',
        })
        return false;
    });
}

export const storeOccupantAction = (addressid, params) => dispatch => {

    dispatch({
        type: 'OCCUPANTS_ACTION_FETCH'
    })

    return api('post','/occupants/'+addressid, params, true).then((response) => {
        dispatch({
            type: 'ADD_OCCUPANTS_ACTION_SUCCESS',
            payload: response.data.occupant
        });
    }).catch((e) => {
        dispatch({
            type: 'OCCUPANTS_ACTION_ERROR',
        })
        return false;
    });
}

export const patchOccupantAction = (occupantid, params) => dispatch => {

    dispatch({
        type: 'OCCUPANTS_ACTION_FETCH'
    })

    return api('post','/occupant/'+occupantid, params, true).then((response) => {
        dispatch({
            type: 'PATCH_OCCUPANTS_ACTION_SUCCESS',
            payload: response.data.occupant
        });
    }).catch((e) => {
        dispatch({
            type: 'OCCUPANTS_ACTION_ERROR',
        })
        return false;
    });
}

export const removeOccupantAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'OCCUPANTS_ACTION_FETCH'
    })
    return api('post','/occupants/'+projectid+'/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'OCCUPANT_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'OCCUPANTS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetOccupantAction = () => dispatch => {

    dispatch({
        type: 'RESET_OCCUPANTS_ACTION'
    })
}
