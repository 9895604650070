import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressAction, patchAddressesAction, addAddressesAction, loginAddressAction, resetAddressAction, downloadDossier, resetPasswordAddressAction, newOccupantAction } from '../../../../actions/addressesActions';
import { zipcodeValidation } from '../../../../utils/validationRules';
import { showSuccess, showError } from '../../../../utils/Notifications'
import { OCCUPANTS_URL } from '../../../../constants/settings';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
// import './ProjectsAddressesGeneral.css';
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Modal,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const Option = Select.Option;

const confirm = Modal.confirm;

class ProjectsAddressesGeneral extends Component {
  state = {
    disabled: true,
    edit: true
  };

  componentDidMount() {

    if(this.props.match.params.addressid) {
      this.loadData();
    }
    else
    {
      this.props.resetAddressAction();
    }
  }

  loadData()
  {
      this.props.getAddressAction(this.props.match.params.addressid).then(() => console.log(this));
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleReset()
  {

      confirm({
        title: 'Weet je zeker dat het adres gereset moet worden?',
        content: 'Als je op JA klikt, wordt het adres gereset.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
          return new Promise((resolve, reject) => {

              this.props.resetPasswordAddressAction(this.props.match.params.id, this.props.match.params.addressid).then(() => {

                  showSuccess('Adres succesvol gereset');

                  this.loadData();

                  resolve();
              });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel(){},
      });
  }

  handleSubmit = e => {

    this.props.form.validateFields((err, values) => {

      if (!err) {

        if(this.props.match.params.addressid) {

          this.props.patchAddressesAction(this.props.match.params.addressid, values).then(() => {

              if(this.props.isError)
              {
                  showError(this.props.message);
              }
              else
              {
                  showSuccess();
              }
          });
        }
        else
        {
          this.props.addAddressesAction(this.props.match.params.id, values).then(() => {
              if(this.props.isError)
              {
                  showError(this.props.message);
              }
              else
              {
                  this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.address.id}/general`);

                  showSuccess();
              }
          });

          // if(this.props.addAddressesAction(this.props.match.params.id, values))
          // {
          //   this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.address.id}/general`);
          //
          //   showSuccess();
          // }
          // else
          // {
          //     showError(this.props.message);
          // }
        }
      }
    });
  };

handlezipCodeChange = (rule, value, callback) => {

};

handleNewOccupant()
{
    confirm({
      title: 'Nieuwe bewoner',
      content: 'Weet je zeker dat je een nieuwe bewoner wilt registreren voor dit adres? De oude bewoner kan dan niet meer inloggen.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
        return new Promise((resolve, reject) => {

            this.props.newOccupantAction(this.props.match.params.addressid).then((data) => {

                console.log('data', data);

                if(data && data.success && data.address.id)
                {
                    showSuccess();

                    this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${data.address.id}/contacts`);

                    resolve();
                }
                else
                {
                    showError('Er is iets mis gegaan bij het registreren van de nieuwe bewoner');

                    resolve();
                }
            });

        }).catch(() => console.log('Oops errors!'));
      },
      onCancel(){},
    });
}

// downloadDossierAsZip()
// {
//  this.props.downloadDossier(this.props.match.params.id,
//    {
//      ids: [this.props.match.params.addressid]
//    }).then(() => {
//
//    });
// }

render() {
  const { getFieldDecorator } = this.props.form;
  return (
    <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

      <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="1" {...this.props}/>

      <div style={{padding: 24, width: '100%'}}>

        <Row className="mbm">
          <Col span={12}>
            <div>
              <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>

                <Link to={`/projects/${this.props.match.params.id}/addresses`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>

            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
                { this.props.address && this.props.address.parent_id == null ? <Button disabled={!this.props.HaveAbility('address', 'write')} onClick={() => { this.handleNewOccupant(); }}>Nieuwe bewoner</Button> : null }
            </Col>
          </Row>

          <Divider></Divider>

          <Title level={4}>{this.props.address.full_address}</Title>

          <Form onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
            <Row gutter={24}>
              <Col span={12}>
                { this.props.match.params.addressid ? (
                  <Form.Item label="Gebruikersnaam">
                    {getFieldDecorator('username', {
                      initialValue: this.props.address.username,
                      rules: [{ required: true, message: 'Vul een gebruikersnaam in' }],
                    })(
                      <Input disabled></Input>
                    )}
                  </Form.Item>
                ) : null }
              </Col>
              <Col span={12}>
                {this.props.match.params.addressid  ? (
                  <Row>
                    <Col span={20}>
                          <Form.Item label="Inlogcode" hasFeedback>
                            {getFieldDecorator('login_code', {
                              initialValue: this.props.address.login_code == "" ? 'Gebruiker heeft een eigen wachtwoord ingesteld' : this.props.address.login_code,
                              rules: [{ required: false, message: 'Vul een inlogcode in' }],
                            })(
                              <Input disabled></Input>
                            )}
                          </Form.Item>
                    </Col>
                    <Col span={4} style={{textAlign: 'right'}}>
                        <Button disabled={!this.props.HaveAbility('address', 'write')} style={{flex: 1, marginTop: 33}} type="primary" danger onClick={() => this.handleReset()}>Reset</Button>
                    </Col>
                  </Row>
                ) : null }
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Straat" hasFeedback>
                    {getFieldDecorator('street', {
                      initialValue: this.props.address.street,
                      rules: [{ required: true, message: 'Vul een straatnaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Straat</Text>
                    <br />
                    <Text type="secondary">{this.props.address.street}</Text>
                  </div>
                )}
              </Col>
              <Col span={6}>
                {this.state.edit ? (
                  <Form.Item required label="Huisnummer" hasFeedback>
                    {getFieldDecorator('house_number', {
                      initialValue: this.props.address.house_number,
                      rules: [{ required: true, message: 'Vul een huisnummer in'}],
                    })(
                      <InputNumber />
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Huisnummer</Text>
                    <br />
                    <Text type="secondary">{this.props.address.house_number}</Text>
                  </div>
                )}
              </Col>
              <Col span={6}>
                {this.state.edit ? (
                  <Form.Item label="Toevoeging" hasFeedback>
                    {getFieldDecorator('house_number_suffix', {
                      initialValue: this.props.address.house_number_suffix ? this.props.address.house_number_suffix : '',
                      rules: [{ required: false, message: 'Vul een toevoeging in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Toevoeging</Text>
                    <br />
                    <Text type="secondary">{this.props.address.house_number_suffix ? this.props.address.house_number_suffix : '-'}</Text>
                  </div>
                )}
              </Col>

              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Postcode" hasFeedback>
                    {getFieldDecorator('zipcode', {
                      initialValue: this.props.address.zipcode,
                      rules: [{ required: true, message: 'Vul een postcode in' }, { validator: zipcodeValidation }],
                    })(
                      <Input maxLength={6}></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Postcode</Text>
                    <br />
                    <Text type="secondary">{this.props.address.zipcode}</Text>
                  </div>
                )}
              </Col>

              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Plaats" hasFeedback>
                    {getFieldDecorator('city', {
                      initialValue: this.props.address.city,
                      rules: [{ required: true, message: 'Vul een plaatsnaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Plaats</Text>
                    <br />
                    <Text type="secondary">{this.props.address.city}</Text>
                  </div>
                )}
              </Col>

              {/*<Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Extra 1" hasFeedback>
                    {getFieldDecorator('extra_1', {
                      initialValue: this.props.address.extra_1,
                      rules: [{ required: false, message: 'Extra informatie' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Extra 1</Text>
                    <br />
                    <Text type="secondary">{this.props.address.extra_1}</Text>
                  </div>
                )}
              </Col>

              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Extra 2" hasFeedback>
                    {getFieldDecorator('extra_2', {
                      initialValue: this.props.address.extra_2,
                      rules: [{ required: false, message: 'Extra informatie' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Extra 2</Text>
                    <br />
                    <Text type="secondary">{this.props.address.extra_2}</Text>
                  </div>
                )}
              </Col>

              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Extra 3" hasFeedback>
                    {getFieldDecorator('extra_3', {
                      initialValue: this.props.address.extra_3,
                      rules: [{ required: false, message: 'Extra informatie' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Extra 3</Text>
                      <br />
                    <Text type="secondary">{this.props.address.extra_3}</Text>
                  </div>
                )}
              </Col>*/}

              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Uitsluiten voor werkvolgorde">
                    {getFieldDecorator('exclude', {
                      initialValue: this.props.address && this.props.address.exclude != false ? true : false,
                      valuePropName: 'checked',
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Uitsluiten voor werkvolgorde</Text>
                    <br />
                    <Text type="secondary">{this.props.address.exclude != false ? 'Ja' : 'Nee'}</Text>
                  </div>
                )}
              </Col>

              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Taal">
                    {getFieldDecorator('language', {
                      initialValue: this.props.address.language ? this.props.address.language : 'nl',
                    })(
                      <Select>
                        <Option value='nl'>Nederlands</Option>
                        <Option value='en'>Engels</Option>
                        <Option value='tr'>Turks</Option>
                        <Option value='de'>Duits</Option>
                        <Option value='es'>Spaans</Option>
                        <Option value='fr'>Frans</Option>
                        <Option value='ar'>Arabisch</Option>
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Uitsluiten voor werkvolgorde</Text>
                    <br />
                    <Text type="secondary">{this.props.address.exclude ? 'Ja' : 'Nee'}</Text>
                  </div>
                )}
              </Col>

            </Row>
          </Form>
          <Divider style={{marginTop:0}}></Divider>
          <Row className="mbm">
            <Col span={12}>
              <Title level={4}>Laatst ingelogd</Title>
              {this.props.address.last_logged_in ? moment(this.props.address.last_logged_in, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm') : '-'}
            </Col>
            <Col span={12}>
              <Title level={4}>Groepen</Title> { this.props.address.groups && this.props.address.groups.map((element) => (<Tag>{element.name}</Tag>))}
            </Col>
          </Row>
        </div>
      </div>
  );
  }
}

const ProjectsAddressesGeneralForm = Form.create({ name: 'projects_addresses_general' })(ProjectsAddressesGeneral);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    address: state.address.address,
    message: state.address.message
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressAction : bindActionCreators(getAddressAction, dispatch),
    patchAddressesAction : bindActionCreators(patchAddressesAction, dispatch),
    loginAddressAction : bindActionCreators(loginAddressAction, dispatch),
    resetAddressAction : bindActionCreators(resetAddressAction, dispatch),
    addAddressesAction : bindActionCreators(addAddressesAction, dispatch),
    resetPasswordAddressAction : bindActionCreators(resetPasswordAddressAction, dispatch),
    newOccupantAction : bindActionCreators(newOccupantAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    // downloadDossier : bindActionCreators(downloadDossier, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesGeneralForm);
