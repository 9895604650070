import React, { Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { notification, Modal } from 'antd';

export const showSuccess = (title, description, icon, color) => {
  notification.open({
    message: title ? title : 'Succesvol opgeslagen',
    icon: <LegacyIcon type={icon ? icon : "check-circle"} theme="filled" style={{ color: color ? color : '#52c41a' }} />,
    description: description ? description : '',
    placement: 'topRight',
  });
}

export const showError = (title, description, icon, color) => {
  notification.open({
    message: title ? title : 'Error',
    icon: <LegacyIcon type={icon ? icon : "close-circle"} theme="filled" style={{ color: color ? color : '#ff0000' }} />,
    description: description ? description : '',
    placement: 'topRight',
  });
}

export const showMultiDelete = (callback) => {
  Modal.confirm({
    title: 'Wil je deze items verwijderen?',
    content: 'Als je op JA klikt, worden de items verwijderd.',
    okText: 'Ja',
    okType: 'danger',
    cancelText: 'Nee',
    onOk: () => {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
  });
}

export const showExtraConfirm = (callback, title, description) => {
  Modal.confirm({
    title: title ? title : 'Weet je het zeker?',
    content: description ? description : 'Als je op JA klikt, wordt uw actie uitgevoerd.',
    okText: 'Ja',
    okType: 'danger',
    cancelText: 'Nee',
    onOk: () => {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
  });
}
