import api from '../utils/api';
import { API_URL } from '../constants/settings';

export const getTransactionsAction = (from_date, untill_date) => (dispatch, getState) => {

  dispatch({
      type: 'TRANSACTIONS_ACTION_FETCH'
  });

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/transactions/customer/'+customer_id, { from_date: from_date, untill_date: untill_date }, true).then((response) => {

      dispatch({
          type: 'TRANSACTIONS_ACTION_SUCCESS',
          payload: response.data
      });

  }).catch((e) => {

    dispatch({
        type: 'TRANSACTION_ACTION_ERROR',
    });

    return false;
  });
}

export const exportTransactionsAction = (ids, type) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post', '/transactions/customer/'+customer_id+'/export/'+type, { ids: ids }, true, null, true).then((response) => {

        dispatch({
            type: 'TRANSACTIONS_EXPORT_ACTION_SUCCESS',
            payload: response.data
        });

        return true;

    }).catch((e) => {

        return false;
    });
}

export const updateTransactionStatusesAction = (status, ids) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
      type: 'TRANSACTION_ACTION_FETCH'
    })

    return api('post', '/transactions/customer/'+customer_id+'/update/status', { status: status, ids: ids }, true, null, true).then((response) => {

        dispatch({
          type: 'UPDATE_STATUSES_CUSTOMER_ACTION_SUCCESS',
          payload: response.data
        });

    }).catch((e) => {

        dispatch({
          type: 'TRANSACTION_ACTION_ERROR',
        });

        return false;
    });
}

export const getCustomerTransactions = (customer_id, from_date, untill_date) => dispatch => {

  dispatch({
    type: 'TRANSACTIONS_ACTION_FETCH'
  })

  return api('post','/customer/' + customer_id + '/transactions', { 'from_date': from_date, 'untill_date': untill_date }, true).then((response) => {
    dispatch({
      type: 'TRANSACTIONS_CUSTOMER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'TRANSACTION_ACTION_ERROR',
    })
    return false;
  });
}

export const searchTransactionsAction = (search, from_date, untill_date) => (dispatch, getState) => {

  dispatch({
    type: 'TRANSACTION_ACTION_FETCH'
  });

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/transactions/customer/'+customer_id+'/search', { search: search, from_date: from_date, untill_date: untill_date}, true).then((response) => {
    dispatch({
      type: 'TRANSACTIONS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'TRANSACTION_ACTION_ERROR',
    })
    return false;
  });
}

export const exportCustomerTransactions = (customer_id, from_date, untill_date) => dispatch => {

    // dispatch({
    //   type: 'CUSTOMERS_ACTION_FETCH'
    // })
    //
    // return api('post','/customer/' + customer_id + '/transactions/export', { 'from_date': from_date, 'untill_date': untill_date }, true).then((response) => {
    //   dispatch({
    //     type: 'CUSTOMER_BILLING_ACTION_SUCCESS',
    //     payload: response.data
    //   });
    // }).catch((e) => {
    //   dispatch({
    //     type: 'CUSTOMERS_ACTION_ERROR',
    //   })
    //   return false;
    // });

    // return api('post','/customer/' + customer_id + '/transactions/export', {'date': period}, true, null, true).then((response) => {
    //
    //     // 1
    //     // var reader = new FileReader();
    //     //
    //     // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     //
    //     // var new_window = window.open('');
    //     //
    //     // new_window.location = URL.createObjectURL(blob);
    //
    //     // 2
    //     var reader = new FileReader();
    //
    //     var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //
    //     reader.readAsDataURL(blob);
    //
    //     reader.onloadend = function (e) {
    //
    //         var win = window.open();
    //
    //         win.document.write('<iframe src="' + reader.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    //     }
    //
    // }).catch((e) => {
    //   return false;
    // });
}
