import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import SupportBaseCorrespondenceSteps from './SupportBaseCorrespondenceSteps'
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
  Menu,
  Upload,
  Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { getSupportBaseFormByIdAction, deleteSupportBaseFormAttachmentsAction, addSupportBaseFormAttachmentAction, updateSupportBaseFormAttachmentAction } from '../../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class SupportBaseCorrespondenceAttachments extends React.Component {

  state = {
      selectedRowKeys: [],
      modalAddAttachmentVisible: false,
      modalUpdateAttachmentVisible: false,
      update_attachment_id: false,
      errorDocumentFile: false,
      errorDocumentSizeFile: false,
      filelist: [],
      attachment_name: '',
      fileAlreadyUploaded: false,
      can_write: false,
  }

  examples = [{id: 1, name: 'Test document', file: 'test.docx'}];

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false);
  }

  patchInformationAddresses() {}

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteSupportBaseFormAttachmentsAction(this.props.match.params.form_id, this.state.selectedRowKeys).then(() => {
          this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false);
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleAttachment()
  {
      this.props.form.validateFields((err, values) => {

        // if(this.state.filelist.length == 0 && !this.state.fileAlreadyUploaded) {
        //   this.setState({errorDocumentFile: true});
        //   return;
        // } else {
        //   this.setState({errorDocumentFile: false});
        // }

        var files = [];
        files['file'] = this.state.filelist[0];

        if((!files['file'] || files['file'].size < 0) && this.state.modalAddAttachmentVisible)
        {
            message.error('Geef een bestand op');
        }
        else if (!err) {

          if(this.state.update_attachment_id > 0) {
            this.props.updateSupportBaseFormAttachmentAction(this.props.match.params.form_id, this.state.update_attachment_id, values, files).then(() => {
              showSuccess('Succesvol opgeslagen');

              this.setState({
                  attachment_name: '',
                  filelist: []
              });

              this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false);
            });
          } else {
            this.props.addSupportBaseFormAttachmentAction(this.props.match.params.form_id, values, files).then(() => {
              showSuccess('Succesvol opgeslagen');

              this.setState({
                  attachment_name: '',
                  filelist: []
              });

              this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false);
            });
          }

          this.setState({
              modalAddAttachmentVisible: false,
              modalUpdateAttachmentVisible: false,
              attachment_name: '',
              filelist: [],
          });

          this.props.form.resetFields();
          this.props.form.setFieldsValue({attachment_name: ''});

          //this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id);
        }
      });
  }

  render () {

    const columns = [{
        title: 'Omschrijving',
        dataIndex: 'name',
        render: (text, record) => (
          <span>
            <Link onClick={() => {
                this.setState({modalUpdateAttachmentVisible: true, update_attachment_id: record.id});

                // get attachment
                this.props.support_base_form.attachments.map((value, key) => {
                    if(value.id == record.id)
                    {
                        this.setState({
                            attachment_name: record.name
                        });
                    }
                });
            }}>
              {text}
            </Link>
          </span>
        )
    },
    {
        title: 'Documentnaam',
        dataIndex: 'file',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Openen',
        dataIndex: 'file',
        render: (text, record) => (
          <a href={record.url} target="_blank">
            Openen
          </a>
        )
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>*/}

        <div style={{padding: 24, width: '100%'}}>

            {/* buttons */}
            <Row className="mbm">
              <Col span={12}>
                <div>
                  <Button disabled={!this.state.can_write} onClick={() => { this.props.history.push(`/projects/${this.props.match.params.id}/support-base/form/${this.props.match.params.form_id}/correspondence/check`); }} type="new">Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/support-base/form/compose/${this.props.match.params.form_id}`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <SupportBaseCorrespondenceSteps current={3} {...this.props}/>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="Bijlagen worden niet per post en/of in de e-mail verstuurd. In de e-mail komen links te staan naar de bijlagen, en deze kunnen bekeken worden op de bewonerspagina en/of in de app. Er kan alleen een PDF bestand als bijlage geüpload worden. Het bestand mag niet groter zijn dan 16MB." />
              </Col>
            </Row>

              <Row className="mbm">
                <Col span={24}>
                    <Button type="new" onClick={() => {this.setState({ attachment_name: '', modalAddAttachmentVisible: true, update_attachment_id: null })}}>+ Toevoegen</Button>

                      <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                          <Menu>
                            <Menu.Item onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                          </Menu>
                        )}>
                        <Button type="secondary">
                          Acties <DownOutlined />
                        </Button>
                      </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomTable
                  rowKey='id'
                  size="middle"
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={this.props.support_base_form.attachments}
                  //dataSource={this.examples}
                  //loading={this.props.isFetching}
                />
              </Col>
            </Row>

        </div>


        <Modal
          title='Bijlage'
          visible={this.state.modalAddAttachmentVisible || this.state.modalUpdateAttachmentVisible}
          onCancel={() => this.setState({ modalAddAttachmentVisible: false, modalUpdateAttachmentVisible: false })}
          onOk={() => this.handleAttachment()}
          okText="Opslaan"
        >
            <Form className={'ant-form--custom'} onSubmit={() => { this.handleAttachment(); }} layout={'vertical'} colon={true}>
              <Row gutter={24} >
                <Col span={24}>
                  <Form.Item hasFeedback label="Omschrijving">
                    { getFieldDecorator('name', {
                      initialValue: this.state.attachment_name,
                      rules: [{ required: true, message: 'Vul een omschrijving in' }],
                    })(
                      <Input />
                    ) }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item multiple={false} label="Bijlage">
                      <Upload
                          accept="application/pdf"
                          showUploadList={true}
                          fileList={this.state.filelist}
                          defaultFileList={this.state.filelist}
                          beforeUpload={(file, filelist) => {
                          if(file.size < 16000000) {
                            if (file.type !== 'application/pdf') {
                              // message.error(`${file.name} is geen pdf bestand. Alleen pdf bestanden zijn toegestaan.`);
                              this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                            }
                            else if(file.size <= 0 && this.state.modalAddAttachmentVisible)
                            {
                                // message.error('Geef een bestand op');
                                this.setState({ errorDocumentFile: true, errorDocumentSizeFile: false });
                            }
                            else if(file.size > 0)
                            {
                                filelist = [];
                                filelist.push(file);

                                this.setState({filelist: filelist});

                                this.setState({ errorDocumentFile: false, errorDocumentSizeFile: false });
                            }
                          } else {
                              this.setState({ errorDocumentFile: false, errorDocumentSizeFile: true });
                          }

                          return false;
                        }} onRemove={() =>{
                          this.setState({filelist: null});
                        }}>
                        <Button >
                          <UploadOutlined /> Selecteer een bestand
                        </Button>
                      </Upload>

                      {this.state.errorDocumentFile ? (<Alert message="Upload een pdf bestand" type="error" showIcon />) : null}
                      {this.state.errorDocumentSizeFile ? (<Alert message="Bestand mag niet groter zijn dan 16MB" type="error" showIcon />) : null}
                      {/*this.props.document.file ? (
                        <Tag>{this.props.document.file}</Tag>
                      ) : null*/}

                    </Form.Item>
                  </Col>
                </Row>
              </Form>
          </Modal>

    </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseCorrespondenceAttachmentsForm = Form.create({ name: 'support_base_correspondence_attachments_form' })(SupportBaseCorrespondenceAttachments);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form : state.support_base.support_base_form,
        support_base_form_attachment : state.support_base.support_base_form_attachment,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        addSupportBaseFormAttachmentAction : bindActionCreators(addSupportBaseFormAttachmentAction, dispatch),
        updateSupportBaseFormAttachmentAction : bindActionCreators(updateSupportBaseFormAttachmentAction, dispatch),
        deleteSupportBaseFormAttachmentsAction : bindActionCreators(deleteSupportBaseFormAttachmentsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseCorrespondenceAttachmentsForm);
