import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, Radio, DatePicker, Select, Row, Col, Table } from 'antd';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import 'moment/locale/nl';

class CalendarForm extends Component {

    state = {}

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <Form onSubmit={this.props.onSubmit}>
                <Row gutter={24}>
                    <Col span={24}>
                        Kalender
                    </Col>
                </Row>
            </Form>
        );
    }

}

export default Form.create({ name: 'calendar_form' })(CalendarForm);
