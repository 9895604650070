import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { resetPointAction, getPointAction, updatePointAction, getUsersAction, addPointAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, getDrawingsAction, getDeliveryAction, getPointsDeliveriesAction } from '../../../actions/deliverActions';
import DeliverSideMenu from './DeliverSideMenu';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    Select,
    Dropdown,
    Menu,
    DatePicker,
    Tag,
    Tooltip,
    Timeline,
    Upload,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import './deliver.css';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

const { Option } = Select;
const { TextArea } = Input;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class DeliverPoint extends Component
{
    map = null;

    image_overlay = null;

    marker = null;

    state = {

        leaflet_initialized: false,

        show_photo_modal: false,
        photo_url: false,

        tags: [],
        inputVisible: false,
        inputValue: '',

        errors: [],
        can_write: false,

        comment: '',

        drawing_url: '',
        drawing_id: false,
        drawing_marker_position: false,
        drawing_marker_x_y: false,
        drawing_bounds: [[0, 0], [0, 0]],

        status: false,

        photos: [],
        fileList: []
    };

    componentDidMount()
    {
        //
        this.setState({
            can_write: this.props.HaveAbility('deliver' ,'write')
        });

        //this.props.resetPointAction();
        this.reset();

        //this.loadData();
        this.includeLeaflet();
    }

    reset()
    {
        this.setState({

            tags: [],
            photos: []
        });

        this.props.resetPointAction();
    }

    loadData(point_id = false)
    {
        if(!point_id)
        {
            point_id = this.props.match.params.point_id;
        }

        if(!isNaN(point_id))
        {
            this.props.getPointAction(point_id).then(() => {
                this.setState({
                    status: this.props.point.status,
                    tags: this.props.point.tags ? this.props.point.tags.split(',') : [],
                    drawing_url: this.props.point.drawing && this.props.point.drawing.drawing_url ? this.props.point.drawing.drawing_url : false,
                    drawing_id: this.props.point.drawing && this.props.point.drawing.id ? this.props.point.drawing.id : false,
                    drawing_marker_position: this.props.point.position ? JSON.parse(this.props.point.position) : false,
                    drawing_marker_x_y: this.props.point.x_y ? JSON.parse(this.props.point.x_y) : false,
                    photos: this.props.point.photos ? this.props.point.photos : []
                }, () => {

                    if(!this.props.point.parent_id)
                    {
                        this.initLeaflet();
                    }
                });
            });
        }

        this.props.getPointDeliveries(this.props.match.params.id);

        this.props.getUsersAction(this.props.match.params.id, 'all');

        this.props.getDrawingsAction(this.props.match.params.id);
    }

    includeLeaflet()
    {
        var this_obj = this;

        // leaflet
        // - css
        var style = document.createElement("link");
        style.rel = 'stylesheet';
        style.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';

        document.head.appendChild(style);

        // - js
        var script = document.createElement("script");
        script.async = true;
        script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
        script.onload = function()
        {
            this_obj.loadData();
        }

        document.head.appendChild(script);
    }

    initLeaflet()
    {
        if(this.state.leaflet_initialized){ return true; }

        this.setState({ leaflet_initialized: true }, () => {

            var center = [0, 0];

            var this_obj = this;

            // map
            this.map = window.L.map('map', {
                center: center,
                minZoom: 0,
                zoom: 0,
                attributionControl: false
            });

            // image
            var imageUrl = this.state.drawing_url;

            var img = new Image();
            var bounds = [[0, 0], [0, 0]];

            // click
            var disable_map_click = 0;

            img.onload = function()
            {
                var height = img.height;
                var width = img.width;

                var transformed_height = height;
                var transformed_width = width;

                if(height > (width / 2))
                {
                    transformed_width = 180 * width / height;
                    transformed_height = 180;
                }
                else
                {
                    transformed_height = 180 * height / width * 2;
                    transformed_width = 180;
                }

                // image bounds
                var imageBounds = [[(-transformed_height / 2), (-transformed_width / 1)], [(transformed_height / 2), (transformed_width / 1)]];

                // add image to map
                this_obj.image_overlay = window.L.imageOverlay(imageUrl, imageBounds).addTo(this_obj.map);
                // window.L.imageOverlay(imageUrl, imageBounds).bringToFront();
                this_obj.image_overlay.bringToFront();

                // image bounds
                bounds = window.L.latLngBounds([imageBounds, center]);
                this_obj.map.setMaxBounds(bounds);

                //
                window.L.tileLayer('').addTo(this_obj.map); // ?

                // marker
                var position = this_obj.state.drawing_marker_position;

                if(position)
                {
                    var redIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f00;" />'}),
                        orangeIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f80;" />'}),
                        greenIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #0f0;" />'});

                    var icon = redIcon;

                    if(this_obj.state.status == 'submitted'){ icon = orangeIcon; }
                    else if(this_obj.state.status == 'completed'){ icon = greenIcon; }

                    this_obj.marker = window.L.marker(new window.L.LatLng(position.lat, position.lng), {icon: icon}).addTo(this_obj.map);
                }
            }

            img.src = imageUrl;

            //
            this.map.on('click', onMapClick);

            // on map click, add point
            function onMapClick(event)
            {
                if(this_obj.marker)
                {   // move marker
                    var position = event.latlng;

                    this_obj.setState({
                        drawing_marker_position: position,
                        drawing_marker_x_y: this.map.latLngToLayerPoint(event.latlng)
                    });

                    if(position)
                    {
                        this_obj.marker.setLatLng(position);
                    }
                }
                else
                {   // add marker
                    var position = event.latlng;

                    this_obj.setState({
                        drawing_marker_position: position,
                        drawing_marker_x_y: this.map.latLngToLayerPoint(event.latlng)
                    });

                    if(position)
                    {
                        var redIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f00;" />'}),
                            orangeIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f80;" />'}),
                            greenIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #0f0;" />'});

                        var icon = redIcon;

                        if(this_obj.state.status == 'submitted'){ icon = orangeIcon; }
                        else if(this_obj.state.status == 'completed'){ icon = greenIcon; }

                        this_obj.marker = window.L.marker(new window.L.LatLng(position.lat, position.lng), {icon: icon}).addTo(this_obj.map);
                    }
                }
            };

            // drag handler
            this.map.on('drag', function(){
                this_obj.map.panInsideBounds(bounds, {animate: true});
            });
        });
    }

    changeStatus(status)
    {
        var redIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f00;" />'}),
            orangeIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f80;" />'}),
            greenIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #0f0;" />'});

        var icon = redIcon;

        if(status == 'submitted'){ icon = orangeIcon; }
        else if(status == 'completed'){ icon = greenIcon; }

        if(this.marker)
        {
            this.marker.setIcon(icon);
        }
    }

    changeDrawing(id)
    {
        // - remove marker
        if(this.marker)
        {
            this.marker.removeFrom(this.map);

            this.marker = null;
        }

        // - remove current image overlay
        this.map.removeLayer(this.image_overlay);

        // - add new image overlay
        var this_obj = this;

        var imageUrl = null;

        this.props.drawings.map((drawing) => {

            if(drawing.id == id)
            {
                imageUrl = drawing.drawing_url;
            }
        });

        //
        this.setState({ drawing_url: imageUrl });

        //
        //if(imageUrl)
        {
            var img = new Image();
            var bounds = [[0, 0], [0, 0]];

            // click
            var disable_map_click = 0;

            img.onload = function()
            {
                var height = img.height;
                var width = img.width;

                var transformed_height = height;
                var transformed_width = width;

                if(height > (width / 2))
                {
                    transformed_width = 180 * width / height;
                    transformed_height = 180;
                }
                else
                {
                    transformed_height = 180 * height / width;
                    transformed_width = 360;
                }

                // image bounds
                var imageBounds = [[(-transformed_height / 2), (-transformed_width / 1)], [(transformed_height / 2), (transformed_width / 1)]];

                // add image to map
                this_obj.image_overlay = window.L.imageOverlay(imageUrl, imageBounds).addTo(this_obj.map);
                // window.L.imageOverlay(imageUrl, imageBounds).bringToFront();
                // this_obj.image_overlay.bringToFront();
                this_obj.image_overlay.bringToBack();

                // image bounds
                bounds = window.L.latLngBounds([imageBounds, [0, 0]]);
                this_obj.map.setMaxBounds(bounds);

                // reinit map
                this_obj.map.fitWorld();
                this_obj.map.setView([0, 0], 0);
                this_obj.map.invalidateSize();
            }

            img.src = imageUrl;
        }
    }

    handleSave(redirect = true)
    {
        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors)
            {   // ok

                if(this.props.match.params.parent_id)
                {
                    values.parent_id = this.props.match.params.parent_id;
                }

                if(values.due_date)
                {
                    values.due_date = values.due_date.format('YYYY-MM-DD');
                }

                values.tags = this.state.tags.join(',');

                values.position = JSON.stringify(this.state.drawing_marker_position);
                values.x_y = JSON.stringify(this.state.drawing_marker_x_y);

                if(isNaN(this.props.match.params.point_id))
                {   // add point
                    this.props.addPointAction(this.props.match.params.id, values, this.state.fileList).then(() => {

                        const value = this.props.form.getFieldValue('comment');
                        this.props.form.setFieldsValue({ ['comment']: '' });

                        showSuccess('Succesvol opgeslagen');

                        if(redirect)
                        {
                            this.props.history.push(`/projects/${this.props.match.params.id}/deliver/points`);
                        }
                        else
                        {
                            this.loadData();
                        }
                    });
                }
                else
                {   // update point
                    this.props.updatePointAction(this.props.match.params.point_id, values, this.state.fileList).then(() => {

                        const value = this.props.form.getFieldValue('comment');
                        this.props.form.setFieldsValue({ ['comment']: '' });

                        showSuccess('Succesvol opgeslagen');

                        if(redirect)
                        {
                            this.props.history.push(`/projects/${this.props.match.params.id}/deliver/points`);
                        }
                        else
                        {
                            this.loadData();
                        }
                    });
                }
            }
        });
    }

    getStatusText(status)
    {
        if(status == 'submitted'){ return 'Controleren'; }
        else if(status == 'completed'){ return 'Afgehandeld'; }
        else { return 'Open'; }
    }

    showPhoto(url)
    {
        this.setState({
            show_photo_modal: true,
            photo_url: url,
        });
    }

    handleClose = removedTag => {

        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags });
    };

    showInput = () => {

        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = e => {

        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {

        let tags = this.state.tags;

        tags = [...tags, this.state.inputValue];

        this.setState({
            tags: tags,
            inputVisible: false,
            inputValue: '',
        });
    };

    saveInputRef = input => (this.input = input);

    //
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {

      if(!file.url && !file.preview){

        file.preview = await getBase64(file.originFileObj);
      }

      this.setState({

        previewImage: file.url || file.preview,
        previewVisible: true,
      });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    selectDelivery(id)
    {
        //this.setState({ delivery_id: false }, () => {

            // determine drawing
            this.props.getDeliveryAction(id).then(() => {

                this.setState({
                    drawing_url: this.props.delivery.drawing_url,
                    delivery_id: id
                }, () => {

                    if(!this.state.leaflet_initialized)
                    {
                        this.initLeaflet();
                    }
                    else
                    {
                        // - remove marker
                        if(this.marker)
                        {
                            this.marker.removeFrom(this.map);

                            this.marker = null;
                        }

                        // - remove current image overlay
                        this.map.removeLayer(this.image_overlay);

                        // - add new image overlay
                        var this_obj = this;

                        var imageUrl = this.state.drawing_url

                        //
                        var img = new Image();
                        var bounds = [[0, 0], [0, 0]];

                        // click
                        var disable_map_click = 0;

                        img.onload = function()
                        {
                            var height = img.height;
                            var width = img.width;

                            var transformed_height = height;
                            var transformed_width = width;

                            if(height > (width / 2))
                            {
                                transformed_width = 180 * width / height;
                                transformed_height = 180;
                            }
                            else
                            {
                                transformed_height = 180 * height / width;
                                transformed_width = 360;
                            }

                            // image bounds
                            var imageBounds = [[(-transformed_height / 2), (-transformed_width / 1)], [(transformed_height / 2), (transformed_width / 1)]];

                            // add image to map
                            this_obj.image_overlay = window.L.imageOverlay(imageUrl, imageBounds).addTo(this_obj.map);
                            // window.L.imageOverlay(imageUrl, imageBounds).bringToFront();
                            // this_obj.image_overlay.bringToFront();
                            this_obj.image_overlay.bringToBack();

                            // image bounds
                            bounds = window.L.latLngBounds([imageBounds, [0, 0]]);
                            this_obj.map.setMaxBounds(bounds);

                            // reinit map
                            this_obj.map.fitWorld();
                            this_obj.map.setView([0, 0], 0);
                            this_obj.map.invalidateSize();
                        }

                        img.src = imageUrl;
                    }
                });
            });
        //});
    }

    selectDrawing(id)
    {
        this.setState({ drawing_id: id });

        this.changeDrawing(id);
    }

    showDrawing()
    {
        this.setState({ show_drawing_modal: true }, () => {

            // leaflet
            this.map.fitWorld();
            this.map.setView([0, 0], 0);
            this.map.invalidateSize();
        });
    }

    getStatusColor(status)
    {
        // if(status == 'new'){ return '#888888'; }
        if(status == 'submitted'){ return '#ff8800'; }
        else if(status == 'completed'){ return '#00ff00'; }
        else { return '#ff0000'; }
    }

    addNewSubPoint()
    {
        this.props.history.push(`/projects/${this.props.match.params.id}/deliver/point/add/${this.props.point.id}`);

        //this.props.resetPointAction();
        this.reset();

        this.loadData('add');
    }

    //
    render()
    {
        const { getFieldDecorator } = this.props.form;

        const { tags, inputVisible, inputValue } = this.state;

        const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        );

        const columns = [
            {
              title: 'Status',
              dataIndex: 'status',
              // sorter: (a, b) => { return a.status.localeCompare(b.status)},
              render: (text, record) => (
                <div style={{height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></div>
              ),
              // filters: [
              //   {
              //     text: 'Open',
              //     value: 'open',
              //   },
              //   {
              //     text: 'Controleren',
              //     value: 'submitted',
              //   },
              //   {
              //     text: 'Afgehandeld',
              //     value: 'completed',
              //   }
              // ],
              // onFilter: (value, record) => record.status === value,
              width: 110
            },
            {
                title: 'Onderwerp',
                dataIndex: 'subject',
                render: (text, record) => (
                    <span>
                        <Link
                            to={'/projects/'+this.props.match.params.id+'/deliver/point/'+record.id}
                            onClick={() => { this.reset(); this.loadData(record.id); }}
                            >
                            {text}
                        </Link>
                    </span>
                )
            },
            {
                title: 'Verantwoordelijke',
                dataIndex: 'user_name',
                // sorter: (a, b) => { return a.user_name.localeCompare(b.user_name)},
                render: (text, record) => (
                    <span>
                        {text}
                    </span>
                ),
                // filters: this.getUserFilters(),
                // onFilter: (value, record) => record.assigned_to_delivery_user_id === value,
            },
            {
                title: 'Uiterlijk gereed',
                dataIndex: 'due_date',
                render: (text, record) => (
                    <span style={{color: (record.filter !== 'expired' ? '#000000' : '#ff0000')}}>
                        {record.expired ? 'Verlopen' : (text ? moment(text).format('DD/MM/YYYY') : 'Niet opgegeven')}
                    </span>
                ),
                // filters: [
                //   {
                //     text: 'Verlopen',
                //     value: 'expired',
                //   },
                //   {
                //     text: 'Niet verlopen',
                //     value: 'not-expired',
                //   },
                //   {
                //     text: 'Niet ingesteld',
                //     value: 'not-set',
                //   },
                // ],
                // onFilter: (value, record) => record.filter === value,
            }
        ];

        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
        };

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="1"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Form layout={'vertical'}>

                        <Row className="mbm">
                            <Col span={12}>
                                <div>
                                    <Button disabled={!this.state.can_write} type={'new'} onClick={() => { this.handleSave((!isNaN(this.props.match.params.id) ? true : false)); }}>Opslaan</Button>
                                    <Link to={`/projects/${this.props.match.params.id}/deliver/points`}>
                                        <Button>Sluiten</Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        { isNaN(this.props.match.params.point_id) && !this.props.match.params.parent_id ?
                        <Row>
                            <Col span={24}>

                                <Form.Item
                                    label="Oplevering"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('delivery_id', {
                                        rules: [{ required: false, message: true }],
                                        initialValue: this.props.point.delivery_id,
                                        onChange: (id) => { this.selectDelivery(id); }
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een oplevering"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        { /*this.props.delivery_filters.map((record, index) => {
                                            return <Option value={record.id}>{record.description}</Option>
                                        })/**/ }
                                        { this.props.points_deliveries.map((record, index) => {
                                            return <Option value={record.id}>{record.description + (record.address_label ? ' - ' + record.address_label : '')}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row> : null }

                        {/*<Row>
                            <Col span={24}>

                                <Form.Item
                                    label="(Voor)Oplevering formulier"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('delivery_form_id', {
                                        rules: [{ required: false, message: true }],
                                        initialValue: this.props.point.delivery_form_id
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een (voor)oplevering formulier"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        { this.props.form_filters.map((record, index) => {
                                            return <Option value={record.id}>{record.name}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>*/}

                        { isNaN(this.props.match.params.point_id) && !this.state.delivery_id && !this.props.match.params.parent_id ? null :
                        <div>

                            { !this.props.point.parent_id && !this.props.match.params.parent_id ?
                            <Row>
                                <Col span={24}>

                                    <Form.Item
                                        label="Tekening"
                                        hasFeedback
                                        >
                                        <div style={{ marginBottom: 6 }}>
                                            <div style={{ width: 85, height: 85, float: 'left', position: 'relative', marginRight: 8, marginBottom: 8 }}>
                                                <div
                                                    onClick={() => { this.showDrawing(); }}
                                                    style={{
                                                        backgroundImage: 'url('+this.state.drawing_url+')',
                                                        backgroundSize: 'contain',
                                                        backgroundPosition: 'center, center',
                                                        backgroundRepeat: 'no-repeat',
                                                        cursor: 'pointer',
                                                        width: 85,
                                                        height: 85,
                                                        float: 'left',
                                                        position: 'relative',
                                                        marginRight: 6,
                                                        marginBottom: 6,
                                                        borderWidth: 1,
                                                        borderStyle: 'solid',
                                                        borderColor: '#e0e0e0',
                                                        borderRadius: 5
                                                    }}
                                                    ></div>
                                            </div>
                                        </div>
                                    </Form.Item>

                                </Col>
                            </Row> : null }

                            <Row>
                                <Col span={24} style={{columnGap: '20px', rowGap: '20px'}}>

                                    <Form.Item
                                        label="Foto(s)"
                                        hasFeedback
                                        style={{width: '100%'}}
                                        >

                                        <div style={{ marginBottom: 6, width: '100%' }}>
                                            { this.state.photos.length > 0 ?
                                            this.state.photos.map((photo, index) => {
                                            return (<div
                                                id={'point-'+this.props.point.id+'-photo-'+(index + 1)}
                                                onClick={() => { this.showPhoto(photo.url); }}
                                                style={{
                                                    backgroundImage: 'url('+photo.url+')',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center, center',
                                                    backgroundRepeat: 'no-repeat',
                                                    cursor: 'pointer',
                                                    width: 85,
                                                    height: 85,
                                                    float: 'left',
                                                    position: 'relative',
                                                    marginRight: 6,
                                                    marginBottom: 6,
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#e0e0e0',
                                                    borderRadius: 5
                                                }}
                                                ></div>)
                                            })
                                            : null }
                                        </div>

                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>

                                    <Upload.Dragger
                                      style={{ backgroundColor: '#fff', marginBottom: 8 }}
                                      accept="image/jpeg, image/png"
                                      multiple
                                      listType="picture-card"
                                      beforeUpload={(file) => {
                                        this.setState((state) => ({
                                          fileList: [ ...state.fileList, file ]
                                        }));

                                        return false;
                                      }}
                                      onPreview={this.handlePreview}
                                      >
                                      <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                      </p>
                                      <p className="ant-upload-text">Klik of sleep foto's hierheen om te uploaden.</p>
                                      <p className="ant-upload-hint">Maximale bestandsgrootte van de upload: 8 MB.</p>
                                    </Upload.Dragger>

                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>

                                    <Form.Item
                                        label="Selecteer een verantwoordelijke"
                                        hasFeedback
                                        >
                                        {getFieldDecorator('assigned_to_delivery_user_id', {
                                            rules: [{ required: true, message: true }],
                                            initialValue: this.props.point.assigned_to_delivery_user_id,
                                        })(
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder="Selecteer een verantwoordelijke"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            { this.props.users.map((record, index) => {
                                                return <Option value={record.id}>{record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address}</Option>
                                            }) }
                                        </Select>
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>

                                    <Form.Item
                                        label="Onderwerp"
                                        hasFeedback
                                        >
                                        {getFieldDecorator('subject', {
                                            rules: [{ required: true, message: true }],
                                            initialValue: this.props.point.subject,
                                        })(
                                        <Input />
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>

                                    <Form.Item
                                        label="Uiterlijk gereed"
                                        >
                                        {getFieldDecorator('due_date', {
                                            initialValue: this.props.point.due_date ? moment(this.props.point.due_date) : null,
                                        })(
                                        <DatePicker
                                            format={'DD-MM-YYYY'}
                                        />
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>

                                    <Form.Item
                                        label="Status"
                                        hasFeedback
                                        >
                                        {getFieldDecorator('status', {
                                            rules: [{ required: true, message: true }],
                                            initialValue: this.props.point.status,
                                            onChange: (status) => {
                                                this.changeStatus(status);
                                            }
                                        })(
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder="Selecteer een status"
                                        >
                                            <Option value={'open'}>Open</Option>
                                            <Option value={'submitted'}>Controleren</Option>
                                            <Option value={'completed'}>Afgehandeld</Option>
                                        </Select>
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>

                                    <Form.Item
                                        label="Tags"
                                        hasFeedback
                                    >
                                        {this.state.tags.map((tag, index) => {
                                            const isLongTag = tag.length > 20;
                                            const tagElem = (
                                                <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
                                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                </Tag>
                                            );

                                            return isLongTag ? (
                                                <Tooltip title={tag} key={tag}>
                                                    {tagElem}
                                                </Tooltip>
                                            ) : (
                                                tagElem
                                            );
                                        })}

                                        {inputVisible && (
                                            getFieldDecorator('tags', {
                                                rules: [{ message: true }]
                                            })(
                                            <Input
                                                ref={this.saveInputRef}
                                                type="text"
                                                size="small"
                                                style={{ width: 78 }}
                                                value={this.state.inputValue}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputConfirm}
                                                onPressEnter={this.handleInputConfirm}
                                            />
                                            )
                                        )}

                                        {!inputVisible && (
                                            <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                                + Nieuwe tag
                                            </Tag>
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row className={'mbm'}>
                                <Col span={24}>

                                    <h2 style={{marginTop: 24}}>Opmerkingen</h2>

                                    {
                                        this.props.comments.length > 0 && this.props.comments.map((object, key) => {
                                            return (
                                    <div style={{marginBottom: 0}}>
                                        <h4 style={{marginBottom: 2}}>Door: {object.from_user_email_address}</h4>
                                        <div style={{fontStyle: 'italic', marginBottom: 6}}>Geplaatst op: {moment(object.created_at).format('DD-MM-YYYY - HH:mm')} uur</div>
                                        <p>{ object.comment }</p>
                                    </div>
                                            )
                                        })
                                    }

                                    {
                                        !this.props.comments.length ?
                                    <p>Er zijn nog geen opmerkingen geplaatst.</p>
                                        : null
                                    }

                                    <Form.Item
                                        label="Plaats een opmerking"
                                        hasFeedback
                                        >
                                        {getFieldDecorator('comment', {
                                            rules: [{ message: true }]
                                        })(
                                        <TextArea
                                            rows={4}
                                            disabled={!this.state.can_write}
                                            />
                                        )}
                                    </Form.Item>

                                    <Button type={'new'} disabled={!this.state.can_write} onClick={() => { this.handleSave(false); }}>+ Plaats opmerking</Button>

                                </Col>
                            </Row>

                            { !isNaN(this.props.match.params.point_id) && !this.props.point.parent_id ?
                            <Row gutter={24}>
                                <Col span={24}>

                                    <h2 style={{marginTop: 24}}>Sub punten</h2>

                                    <CustomTable
                                        rowKey='id'
                                        size="middle"
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        dataSource={this.props.point.children}
                                        loading={this.props.isFetching}
                                        />

                                    <Button type={'new'} style={{ marginTop: 28 }} disabled={!this.state.can_write} onClick={() => { this.addNewSubPoint(); }}>+ Sub punt toevoegen</Button>

                                </Col>
                            </Row> : null }

                            <Row gutter={32}>
                                <Col span={24}>

                                    <h2 style={{marginTop: 28}}>Tijdlijn</h2>

                                    { this.props.log.length > 0 ?
                                    <Timeline style={{marginTop: 24}}>
                                      {
                                          this.props.log.map((object, key) => {
                                              return (<Timeline.Item><RenderHTML HTML={(moment(object.created_at).format('DD/MM/YYYY HH:mm')  + ' uur - ' + object.message)} /></Timeline.Item>);
                                          })
                                      }
                                    </Timeline> : null }

                                    {
                                        !this.props.log.length ?
                                    <p>Er staat nog niets op de tijdlijn.</p>
                                        : null
                                    }

                                </Col>
                            </Row>

                        </div>}

                    </Form>

                </div>

                <Modal
                    centered
                    className="drawing"
                    width="800"
                    height="400"
                    footer={null}
                    visible={this.state.show_drawing_modal}
                    forceRender={true}
                    onCancel={() => { this.setState({ show_drawing_modal: false }); }}
                    >
                    <div id="map" style={{ width: 800, height: 400 }}></div>
                </Modal>

                <Modal
                    centered
                    className="large-photo"
                    width="800"
                    footer={null}
                    visible={this.state.show_photo_modal}
                    onCancel={() => { this.setState({ show_photo_modal: false }); }}
                    >
                    <div onClick={() => { this.setState({ show_photo_modal: false }); }} style={{ width: '100%', textAlign: 'center' }}>
                        <img src={this.state.photo_url} style={{ maxWidth: '600px', maxHeight: '600px' }} />
                    </div>
                </Modal>

            </div>
        );
    }
}

const DeliverPointForm = Form.create({ name: 'point_details' })(DeliverPoint);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        point : state.deliver.point,
        photos : state.deliver.photos,
        log : state.deliver.log,
        users : state.deliver.users,
        comments : state.deliver.comments,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        drawings : state.deliver.drawings,
        delivery : state.deliver.delivery,
        points_deliveries : state.deliver.points_deliveries,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPointAction : bindActionCreators(getPointAction, dispatch),
        updatePointAction : bindActionCreators(updatePointAction, dispatch),
        resetPointAction : bindActionCreators(resetPointAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        addPointAction : bindActionCreators(addPointAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        getDrawingsAction : bindActionCreators(getDrawingsAction, dispatch),
        getDeliveryAction : bindActionCreators(getDeliveryAction, dispatch),
        getPointsDeliveriesAction : bindActionCreators(getPointsDeliveriesAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverPointForm);
