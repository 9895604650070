import React, { Component } from 'react';
// import './ProjectsCorrespondenceDetail.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { API_URL, JOB_URL } from '../../../../constants/settings'

import { getGroupsAction } from '../../../../actions/groupsActions';
import { getAddressesAction } from '../../../../actions/addressesActions';
import { resetCorrespondenceAction, getCorrespondenceAddressesAction, searchCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction } from '../../../../actions/correspondencesActions';

import SetupMailCorrespondence from '../Form/SetupMailCorrespondence';
import AddCorrespondence from '../Form/AddCorrespondence';
import CorrespondenceSteps from '../Steps/CorrespondenceSteps';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { InfoCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  notification,
  Tooltip,
} from 'antd';
import { showSuccess } from '../../../../utils/Notifications';
import moment from 'moment';
import _ from 'underscore';

class ProjectsCorrespondenceSetup extends Component {

  filteredDataSource = [];

  state = {
    current: 0,
    smsModule: false,
    letterVisible: false,
    letterMailVisible: false,
    emailVisible: false,
    smsVisible: false,
    pushVisible: false,

    expandedRowKeys: [],
    expandButton: true,

    subject: '',

    selectedLetterIds: [],
    selectedLetterEmailIds: [],
    selectedEmailIds: [],
    selectedSmsIds: [],
    selectedPushIds: [],

    currentDataSource: [],
    filteredDataSource: [],
    expandTable: true,
    //expandedRowKeys: [],

    sendDate: null,
    sendTime: null,

    filteredInfo: null,

    group_filter: [],
    group_filter_and: true
  };

  componentDidMount() {

    this.props.resetCorrespondenceAction();

    this.props.getCorrespondenceAddressesAction(this.props.match.params.id, this.props.match.params.typeid, this.props.match.params.type).then(() => {
      this.props.getGroupsAction({"projectId": this.props.match.params.id});

      if(this.props.match.params.correspondenceid) {
        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {
          this.setState({
            subject: this.props.correspondence.subject,
            selectedLetterIds: this.props.correspondence.selection && this.props.correspondence.selection.mail,
            selectedLetterEmailIds: this.props.correspondence.selection && this.props.correspondence.selection.email_mail,
            selectedEmailIds: this.props.correspondence.selection && this.props.correspondence.selection.email,
            selectedSmsIds: this.props.correspondence.selection && this.props.correspondence.selection.sms,
            selectedPushIds: this.props.correspondence.selection && this.props.correspondence.selection.push,
          })
        });
      }

      this.setState({currentDataSource: this.props.addresses},() => {
        var expandIds = [];
        this.state.currentDataSource.map((v) => {
          if(v.occupants) {
            expandIds.push('address-'+v.id)
          }
        });
        this.setState({expandedRowKeys: expandIds, expandButton: true});
      });

      //
      // var filteredDataSource = this.props.addresses.map((element) => {
      //   if(element.occupants && element.occupants.length == 0) {
      //     element.occupants = null;
      //   }
      //   return element;
      // });
      //
      // this.setState({ filteredDataSource: filteredDataSource });

    });

    if((_.findWhere(this.props.project.modules, {id: 10}) && _.findWhere(this.props.selectedCustomer.modules, {id: 10}))) {
      // enable sms
      this.setState({smsModule: true});
    }
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      // currentDataSource: extra.currentDataSource,
      // filteredDataSource: extra.filteredDataSource
    });
  };

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = (record.groups === undefined ? true : false);

                  record.groups && record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = (record.groups === undefined ? true : false);

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.groups && record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  saveStepOne = () => {
    this.addCorrespondenceForm.props.form.validateFields((error, values) => {
      if(!error) {

        if(
          this.state.selectedLetterIds.length == 0 &&
          this.state.selectedLetterEmailIds.length == 0 &&
          this.state.selectedEmailIds.length == 0 &&
          this.state.selectedSmsIds.length == 0 &&
          this.state.selectedPushIds.length == 0
        ) {
          showSuccess('Sorry, er is iets mis gegaan', 'Selecteer een verzendmethode', 'close-circle', '#f5222d');
          return;
        }

        if(this.props.match.params.correspondenceid) {
          this.props.patchCorrespondenceAction(this.props.match.params.correspondenceid, {
            'subject': values.subject,
            'mail': this.state.selectedLetterIds,
            'email': this.state.selectedEmailIds,
            'email_mail': this.state.selectedLetterEmailIds,
            'sms': this.state.selectedSmsIds,
            'push': this.state.selectedPushIds,
            'appointment_id': this.props.match.params.type == 'appointment' ? this.props.match.params.typeid : null,
          }).then(() => this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.correspondence.id}/compose`));
        } else {
          this.props.addCorrespondenceAction(this.props.match.params.id, {
            'subject': values.subject,
            'mail': this.state.selectedLetterIds,
            'email': this.state.selectedEmailIds,
            'email_mail': this.state.selectedLetterEmailIds,
            'sms': this.state.selectedSmsIds,
            'push': this.state.selectedPushIds,
            'appointment_id': this.props.match.params.type == 'appointment' ? this.props.match.params.typeid : null,
          }).then((response) => {
            //this.props.history.push(this.props.correspondence.id+'/compose/');
            this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.correspondence.id}/compose`);
          })
        }
        showSuccess();
      }
    });

    // showSuccess('Succesvol opgeslagen');
  }

  onSelectLetterChange = (value) => {
    if(this.state.selectedLetterIds.includes(value.target.value)) {
      this.setState({ selectedLetterIds: this.state.selectedLetterIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedLetterIds: [...this.state.selectedLetterIds, value.target.value]});
    }
  }

  onSelectLetterEmailChange = (value) => {

    if(this.state.selectedLetterEmailIds.includes(value.target.value)) {
      this.setState({ selectedLetterEmailIds: this.state.selectedLetterEmailIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedLetterEmailIds: [...this.state.selectedLetterEmailIds, value.target.value]});
    }
  }

  onSelectEmailChange = (value) => {
    if(this.state.selectedEmailIds.includes(value.target.value)) {
      this.setState({ selectedEmailIds: this.state.selectedEmailIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedEmailIds: [...this.state.selectedEmailIds, value.target.value]});
    }
  }

  onSelectSmsChange = (value) => {
    if(this.state.selectedSmsIds.includes(value.target.value)) {
      this.setState({ selectedSmsIds: this.state.selectedSmsIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedSmsIds: [...this.state.selectedSmsIds, value.target.value]});
    }
  }

  onSelectPushChange = (value) => {
    if(this.state.selectedPushIds.includes(value.target.value)) {
      this.setState({ selectedPushIds: this.state.selectedPushIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedPushIds: [...this.state.selectedPushIds, value.target.value]});
    }
  }

  // onCheckLetterAll = (value) => {
  //
  //   if(value.target.checked) {
  //
  //     var occ_ids = [];
  //     this.state.currentDataSource.map((v) => {
  //       v.occupants && v.occupants.map((elem) => {
  //         if(elem.different_address) {
  //           occ_ids.push('occ-'+elem.id);
  //         }
  //       })
  //     });
  //
  //     var ids = this.state.currentDataSource.map((v) => v.id);
  //
  //     this.setState({ selectedLetterIds: [...ids, ...occ_ids]});
  //   } else {
  //     this.setState({ selectedLetterIds: []});
  //   }
  // }

  onCheckLetterAll = (value) => {

    if(value.target.checked) {

      // var ids = [];
      //
      // this.props.addresses.map((record) => {
      //
      //   ids.push(record.id);
      // });
      //
      // this.setState({ selectedLetterIds: ids});

      var occ_ids = [];

      this.filteredDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.different_address) {
            occ_ids.push('occ-'+elem.id);
          }
        })
      });

      var ids = this.filteredDataSource.map((v) => v.id);

      this.setState({ selectedLetterIds: [...ids, ...occ_ids]});

    }
    else
    {
      this.setState({ selectedLetterIds: []});
    }
  }

  onCheckLettermailAll = (value) => {

    if(value.target.checked) {

      // var ids = [];
      //
      // this.props.addresses.map((record) => {
      //
      //   if(record.occupants && record.occupants.length >= 1) {
      //
      //       record.occupants && record.occupants.map((elem) => {
      //
      //         if(elem.email_address && this.validateEmail(elem.email_address)) {
      //
      //           ids.push('occ-'+elem.id);
      //         }
      //       });
      //   }
      // });
      //
      // this.setState({ selectedLetterEmailIds: ids});

      var ids = [];
      this.filteredDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.email_address && this.validateEmail(elem.email_address)) {
            ids.push('occ-'+elem.id);
          }
        })
      });

      this.setState({ selectedLetterEmailIds: ids});
    }
    else
    {
      this.setState({ selectedLetterEmailIds: []});
    }
  }

  onCheckEmailAll = (value) => {

    if(value.target.checked) {

      // var ids = [];
      //
      // this.props.addresses.map((record) => {
      //
      //   if(record.occupants && record.occupants.length >= 1) {
      //
      //       record.occupants && record.occupants.map((elem) => {
      //
      //         if(elem.email_address && this.validateEmail(elem.email_address)) {
      //
      //           ids.push('occ-'+elem.id);
      //         }
      //       });
      //   }
      // });
      //
      // this.setState({ selectedEmailIds: ids});

      var ids = [];

      this.filteredDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.email_address && this.validateEmail(elem.email_address)) {
            ids.push('occ-'+elem.id);
          }
        })
      });

      this.setState({ selectedEmailIds: ids});

    } else {

      this.setState({ selectedEmailIds: []});
    }
  }

  onCheckSmsAll = (value) => {

    if(value.target.checked) {

      // var ids = [];
      //
      // this.props.addresses.map((record) => {
      //
      //   if(record.occupants && record.occupants.length >= 1) {
      //
      //       record.occupants && record.occupants.map((elem) => {
      //
      //         if(elem.mobile) {
      //
      //           ids.push('occ-'+elem.id);
      //         }
      //       });
      //   }
      // });
      //
      // this.setState({ selectedSmsIds: ids});

      var ids = [];

      this.filteredDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.mobile && this.validateMobile(elem.mobile)) {
            ids.push('occ-'+elem.id);
          }
        })
      });

      this.setState({ selectedSmsIds: ids});

    } else {

      this.setState({ selectedSmsIds: []});
    }
  }

  onCheckPushAll = (value) => {

    if(value.target.checked) {

        // var ids = [];
        //
        // this.props.addresses.map((record) => {
        //
        //   if(record.has_devices == true) {
        //
        //       ids.push(record.id);
        //   }
        //
        //   if(record.occupants && record.occupants.length >= 1) {
        //
        //       record.occupants && record.occupants.map((elem) => {
        //
        //         if(elem.has_device) {
        //
        //           ids.push('occ-'+elem.id);
        //         }
        //       });
        //   }
        // });
        //
        // this.setState({ selectedPushIds: ids});

      var ids = [];

      this.filteredDataSource.map((v) => {

        if(v.has_devices == true) {
          ids.push(v.id);
        }

        // v.occupants && v.occupants.map((elem) => {
        //   if(elem.has_device) {
        //     //ids.push('occ-'+elem.id);
        //   }
        // })
      });

      this.setState({ selectedPushIds: ids});

    } else {

      this.setState({ selectedPushIds: []});
    }
  }

  onSearch(value) {
    this.props.searchCorrespondenceAddressesAction(this.props.match.params.id, this.props.match.params.appointmentid ? this.props.match.params.appointmentid : '', {'search': value});
  }

  removeKey(arrayName,key)
  {
   var x;
   var tmpArray = new Array();
   for(x in arrayName)
   {
    if(x!=key) { tmpArray[x] = arrayName[x]; }
   }
   return tmpArray;
  }

  removeItem(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  validateMobile(string)
  {
      if(!string){ return false; }

      return isNaN(string) ? false : true;

      // var valid = true;
      //
      // var mails = string.split(',');
      //
      // mails.map((mail, index) => {
      //
      //     if(/^06[0-9]{8}$/.test(mail) !== true)
      //     {
      //       valid = false;
      //     }
      // });
      //
      // return valid;
  }

  validateEmail(string)
  {
      if(!string){ return false; }

      var valid = true;

      var mails = string.split(',');

      mails.map((mail, index) => {

          if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true)
          {
            valid = false;
          }
      });

      return valid;
  }

  render() {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button style={{width: 17, height: 17, margin: 0, padding: 0, paddingTop: -5, marginRight: 10, marginTop: 2}} onClick={() => {

              var expandIds = [];
              if(!this.state.expandButton) {
                this.state.currentDataSource.map((v) => {
                  if(v.occupants) {
                    expandIds.push('address-'+v.id)
                  }
                });
              }
              this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
            }}><span style={{ display: 'flex', justifyContent: 'center', marginTop: -4}}>{this.state.expandButton ? '-' : '+'}</span></Button>
            <span>Adressen</span>
          </div>
        ),
        dataIndex: 'full_address',
        key: 'street',
        render: (text, record) =>
        record.full_address ? (
          record.full_address
        ) : (
          <span style={{fontSize:12}}>
            <UserOutlined size={30} style={{fontSize: 15}} /> {record.full_name} <Tag >{record.description ? record.description : 'Bewoner'}</Tag>
        </span>
        ),
        width: 240
  },
  {
    title: 'Groepen',
    dataIndex: '',
    key: 'groups',
    // width: 250,
    width: 100,
    // filters: this.props.groups && this.props.groups.map((elem) => {
    //   return {
    //     text: elem.name,
    //     value: elem.name,
    //   }
    // }),
    filterMultiple: true,
    // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 0 }}>

          <div>
              {this.props.groups && this.props.groups.map((obj) => {
                  return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                      <Checkbox
                          onChange={(e) => {
                              var my_group_filter = this.state.group_filter;

                              if(e.target.checked == true){ my_group_filter.push(obj.name); }
                              else{ this.removeItem(my_group_filter, obj.name); }

                              this.setState({ group_filter: my_group_filter });

                              setSelectedKeys(my_group_filter);
                          }}
                          checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                          >
                          {obj.name}
                      </Checkbox>
                  </div>
              })}
          </div>

          <div style={{padding: 8, width: '100%', clear: 'both'}}>
              <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
              <div style={{float: 'left', marginLeft: 8}}>filter</div>
          </div>

          <div style={{width: '100%', clear: 'both'}}>
              <Button
                type="link"
                onClick={() => { confirm(); }}
                style={{marginLeft: -8, marginRight: 8 }}
                >OK</Button>

              <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
          </div>

        </div>
    ),
    onFilter: (value, record) => this.groupsFilter(value, record),
    render: (text, record) =>
      record.groups && record.groups.map((v) => (<Tag color={v.color} key={v.id}>{v.name}</Tag>)
    )
  },
  {
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckLetterAll}>Brief</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedLetterIds && this.state.selectedLetterIds.length}></Badge></div>
    ),
    key: 'letter',
    width: 140,
    filters: [
        {
            text: 'Voorkeur voor e-mail',
            value: true
        },
        {
            text: 'Geen voorkeur e-mail',
            value: false
        }
    ],
    onFilter: (value, record) => {

        if(value == true)
        {   // prefer e-mail
            if(record.occupants && record.occupants.length >= 1)
            {
                var filter = false;

                record.occupants.map((occupant) => {
                    if(occupant.prefer_email){ filter = true; }
                })

                return filter;
            }
            else if(record.prefer_email)
            {
                return record.prefer_email ? true : false;
            }
            else
            {
                return false;
            }
        }
        else
        {   // no preference
            if(record.occupants)
            {
                var filter = false;

                record.occupants.map((occupant) => {
                    if(!occupant.prefer_email){ filter = true; }
                })

                return filter;
            }
            else if(record.prefer_email != undefined)
            {
                return record.prefer_email ? false : true;
            }
            else
            {
                return true;
            }
        }
    },
    render: (value, index) => {
      var valueId       = value.id;
      var disabledCheck = false;

      if(value.address_id) {
        valueId = 'occ-'+value.id
      }

      if(value.address_id && !value.different_address) {
        disabledCheck = true;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'letter-'+valueId} checked={this.state.selectedLetterIds && this.state.selectedLetterIds.includes(valueId)} value={valueId} onChange={this.onSelectLetterChange} />
      )
    }
  },{
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckLettermailAll}>Brief per e-mail</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedLetterEmailIds && this.state.selectedLetterEmailIds.length}></Badge></div>
    ),
    key: 'lettermail',
    width: 180,
    filters: [
        {
            text: 'Voorkeur voor e-mail',
            value: true
        },
        {
            text: 'Geen voorkeur e-mail',
            value: false
        }
    ],
    onFilter: (value, record) => {
        if(value == true)
        {   // prefer e-mail
            if(record.occupants && record.occupants.length >= 1)
            {
                var filter = false;

                record.occupants.map((occupant) => {
                    if(occupant.prefer_email){ filter = true; }
                })

                return filter;
            }
            else if(record.prefer_email)
            {
                return record.prefer_email ? true : false;
            }
            else
            {
                return false;
            }
        }
        else
        {   // no preference
            if(record.occupants)
            {
                var filter = false;

                record.occupants.map((occupant) => {
                    if(!occupant.prefer_email){ filter = true; }
                })

                return filter;
            }
            else if(record.prefer_email != undefined)
            {
                return record.prefer_email ? false : true;
            }
            else
            {
                return true;
            }
        }
    },
    render: (value, index) => {
      var valueId = value.id;
      var disabledCheck = false;

      if(!value.address_id && value.email != ''){
        disabledCheck = true;
      }
      else if(value.email_address == undefined) {
        disabledCheck = true;
      }
      else if(!value.email_address) {
        disabledCheck = true;
      }
      else if(!this.validateEmail(value.email_address)) {
        disabledCheck = true;
      }

      if(value.address_id) {
        valueId = 'occ-'+value.id;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'lettermail-'+valueId} checked={this.state.selectedLetterEmailIds && this.state.selectedLetterEmailIds.includes(valueId)} value={valueId} onChange={this.onSelectLetterEmailChange} />
      )
    }
  },{
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckEmailAll}>E-mail</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedEmailIds && this.state.selectedEmailIds.length}></Badge></div>
    ),
    key: 'email',
    width: 140,
    filters: [
        {
            text: 'Voorkeur voor e-mail',
            value: true
        },
        {
            text: 'Geen voorkeur e-mail',
            value: false
        }
    ],
    onFilter: (value, record) => {
        if(value == true)
        {   // prefer e-mail
            if(record.occupants && record.occupants.length >= 1)
            {
                var filter = false;

                record.occupants.map((occupant) => {
                    if(occupant.prefer_email){ filter = true; }
                })

                return filter;
            }
            else if(record.prefer_email)
            {
                return record.prefer_email ? true : false;
            }
            else
            {
                return false;
            }
        }
        else
        {   // no preference
            if(record.occupants)
            {
                var filter = false;

                record.occupants.map((occupant) => {
                    if(!occupant.prefer_email){ filter = true; }
                })

                return filter;
            }
            else if(record.prefer_email != undefined)
            {
                return record.prefer_email ? false : true;
            }
            else
            {
                return true;
            }
        }
    },
    render: (value, index) => {
      var valueId = value.id;
      var disabledCheck = false;

      //console.log(value.prefer_email == true ? 'NU' : '');

      if(!value.address_id && value.email != ''){
        disabledCheck = true;
      }

      if(value.email_address == undefined || value.email_address == "") {
        disabledCheck = true;
      }

      if(!this.validateEmail(value.email_address)){
        disabledCheck = true;
      }

      if(value.address_id) {
        valueId = 'occ-'+value.id
      } else {
        disabledCheck = true;
      }

      return (
        <span>
          <Checkbox disabled={disabledCheck} key={'email-'+valueId} checked={this.state.selectedEmailIds && this.state.selectedEmailIds.includes(valueId)} value={valueId} onChange={this.onSelectEmailChange} />
          {value.prefer_email == true ? (
            <span>
              <Tooltip className="mhs" title='Voorkeur om e-mail te ontvangen'>
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          ) : null}
        </span>
      );
    }
  },{
    title: (
      <div>
        <Checkbox disabled={!this.state.smsModule} indeterminate={this.state.indeterminate} onChange={this.onCheckSmsAll}>
          <Tooltip className="mhs" title='Het veld mobiel bij een contactpersoon van een adres wordt gebruikt voor het versturen van een SMS'>
            SMS
            <InfoCircleTwoTone style={{ marginLeft: 8 }} />
          </Tooltip>
        </Checkbox>
        <Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedSmsIds && this.state.selectedSmsIds.length}></Badge>
      </div>
    ),
    key: 'sms',
    width: 120,
    render: (value, index) => {
      var valueId = value.id;
      var disabledCheck = false;

      if(!value.address_id && value.mobile == ''){
        disabledCheck = true;
      }

      if(value.mobile == undefined) {
        disabledCheck = true;
      }

      if(!value.mobile) {
        disabledCheck = true;
      }

      if(!this.validateMobile(value.mobile)) {
        disabledCheck = true;
      }

      // if(value.mobile && value.mobile.length != 10) {
      //     disabledCheck = true;
      // }

      if(value.address_id) {
        valueId = 'occ-'+value.id
      }

      if(!this.state.smsModule) {
        disabledCheck = true;
      }

      return (
        <Checkbox disabled={disabledCheck} key={'sms-'+valueId} checked={this.state.selectedSmsIds && this.state.selectedSmsIds.includes(valueId)} value={valueId} onChange={this.onSelectSmsChange} />
      )
    }
  },{
    title: (
      <div><Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckPushAll}>Push</Checkbox><Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedPushIds && this.state.selectedPushIds.length}></Badge></div>
    ),
    key: 'push',
    width: 120,
    render: (value, index) => {
      var valueId = value.id;
      var disabledCheck = false;

      if(value.address_id){
        disabledCheck = true;
      }
      if((!this.props.project.use_wocoapp && value.has_devices != true) || (this.props.project.use_wocoapp && !value.occupants)) {
        disabledCheck = true;
      }

      // if(value.address_id) {
      //   valueId = 'occ-'+value.id
      // }
      return (
        <Checkbox disabled={disabledCheck} key={'push-'+valueId} checked={this.state.selectedPushIds && this.state.selectedPushIds.includes(valueId)} value={valueId} onChange={this.onSelectPushChange} />
      )
    }
  }
];

return (
  <div>
    <Row className="mbm">
      <Col span={12}>
        <div>
          <Button disabled={!this.props.HaveAbility('correspondence', 'write')} loading={this.props.isFetching} type="new" onClick={this.saveStepOne}>Opslaan en volgende</Button>
          <Link to={`/projects/${this.props.match.params.id}/correspondence`}>
            <Button>Sluiten</Button>
          </Link>
        </div>
      </Col>
    </Row>

    <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
      <Row>
        <CorrespondenceSteps current={0} {...this.props} />
      </Row>
    </Card>

    {this.state.current === 0 && (
      <div>
        <Card className="mbm pvs phm" style={{backgroundColor: 'white', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
          <Row type="flex" align="middle">
            <Col span={24}>
              {/*<Input value={this.state.subject} onChange={(text) => this.setState({subject:text.target.value})} placeholder="Onderwerp" />*/}
              <AddCorrespondence wrappedComponentRef={formRef => { this.addCorrespondenceForm = formRef }} {...this.props} />
            </Col>
          </Row>
        </Card>

          <Row className="mbm">
            <Col span={4}>
              {/*<Button onClick={() => {

                var expandIds = [];
                if(!this.state.expandButton) {
                  this.state.currentDataSource.map((v) => {
                    if(v.occupants) {
                      expandIds.push('address-'+v.id)
                    }
                  });
                }
                this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
              }}>Alles {this.state.expandButton ? 'inklappen' : 'uitklappen'}</Button>*/}
            </Col>
            <Col span={20}>
              <Input.Search
                allowClear
                placeholder="Zoeken..."
                onSearch={value => this.onSearch(value)}
                style={{ width: 200, float: 'right' }}
                />
            </Col>
          </Row>

        <CustomTable
          rowKey={(record) => {
            if(record.address_id) {
              return record.address_id+"-"+record.id;
            } else {
              return 'address-'+record.id;
            }
          }}
          columns={columns}
          dataSource={this.props.addresses.map((element) => {
            if(element.occupants && element.occupants.length == 0) {
              element.occupants = null;
            }
            return element;
          })}
          // dataSource={this.state.filteredDataSource}
          childrenColumnName="occupants"
          indentSize="0"
          expandedRowKeys={this.state.expandedRowKeys}
          onExpandedRowsChange={(id) => this.setState({expandedRowKeys: id})}
          loading={this.props.isFetching}
          onChange={(pagination, filters, sorter, extra) => {
            // this.setState({currentDataSource: extra.currentDataSource}, () => {

                // console.log(extra);

                this.handleTableChange(pagination, filters, sorter, extra);

                // setTimeout(() => {
                //     this.handleTableChange(pagination, filters, sorter, extra);
                // }, 200);
            // });
          }}
          footer={(currentPageData) => { this.filteredDataSource = currentPageData; }}
          scroll={{ y: true }}
          />
      </div>
    )}
  </div>
);
}
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    addresses : state.correspondence.addresses,
    groups : state.group.groups,
    selectedCustomer : state.auth.selectedCustomer,
    project : state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    searchCorrespondenceAddressesAction : bindActionCreators(searchCorrespondenceAddressesAction, dispatch),
    resetCorrespondenceAction : bindActionCreators(resetCorrespondenceAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCorrespondenceSetup);
