import React, { Component } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Tag, Row, Col, message, Drawer } from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLetterPapersAction, searchLetterPapersAction, storeLetterPaperAction, deleteLetterPapersAction } from '../../actions/letterPapersActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { API_URL } from '../../constants/settings'
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';

class CorporateIdentity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      modalVisible: false,
      letterpapers: []
    };
  }

  componentDidMount() {
    this.props.getLetterPapersAction();
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteLetterPapersAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  render() {

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
          <Link to={`/library/corporate-identity/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },{
      dataIndex: 'name',
      render: (text, record) => (
        <span style={{display: 'flex', justifyContent:'flex-end'}}>
          <Button onClick={() => window.open(API_URL + '/library/letterpaper/'+ record.id +'/preview?token=' + localStorage.getItem('authToken'), '_blank')}>Voorbeeld</Button>
        </span>
      )
    }];

    return (
      <div>

        <PageHeader path={[{ 'label': 'Bibliotheek', 'link': '#' }, { 'label': 'Briefpapier' }]} />

        <div style={{ backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden' }}>

          <LibrarySideMenu id={this.props.match.params.id} activeMenu='4' />

          <div style={{ padding: 24, width: '100%' }}>
            <Row className="mbm">
              <Col span={12}>
                <Button disabled={!this.props.HaveAbility('library', 'write')} onClick={() => this.props.history.push('/library/corporate-identity/add')} icon={<PlusOutlined />} type="primary ant-btn-new">
                  Toevoegen
                </Button>
                <Dropdown trigger={['click']} disabled={this.props.HaveAbility('library', 'delete') && (this.state.selectedRowKeys.length > 0) ? false : true} overlay={(
                  <Menu>
                    <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('library', 'delete')} style={{ color: this.props.HaveAbility('library', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                  </Menu>)}>
                  <Button type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col span={12}>
                <Input.Search
                  allowClear
                  placeholder="Zoeken..."
                  onSearch={value => this.props.searchLetterPapersAction(value)}
                  style={{ width: 200, float: 'right' }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomTable
                  rowKey="id"
                  rowSelection={rowSelection}
                  columns={columns}
                  loading={false}
                  dataSource={this.props.letterpapers}
                />
              </Col>
            </Row>
          </div>

        </div>

      </div>
    );
}
}

const CorporateIdentityForm = Form.create({ name: 'horizontal_login' })(CorporateIdentity);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.library.isFetching,
    isError : state.library.isError,
    letterpapers : state.letterpaper.letterpapers,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLetterPapersAction : bindActionCreators(getLetterPapersAction, dispatch),
    storeLetterPaperAction : bindActionCreators(storeLetterPaperAction, dispatch),
    deleteLetterPapersAction : bindActionCreators(deleteLetterPapersAction, dispatch),
    searchLetterPapersAction : bindActionCreators(searchLetterPapersAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateIdentityForm);
