import api from '../../../utils/api'
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProjectUserAction, addProjectUserAction, getUnassignedProjectUsersAction, updateProjectUserAction, resetProjectUserAction } from '../../../actions/projectActions';
import { getAccountsAction, getAccountRoleAction } from '../../../actions/accountActions';
import { getCustomerAction } from '../../../actions/customerActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import ProjectSidemenu from './ProjectSidemenu';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Select,
  Alert,
  Tooltip,
  Spin,
  Radio,
  Skeleton,
} from 'antd';
import { showMultiDelete, showSuccess } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';
import _ from 'underscore'

const { Title, Text } = Typography;
const Option = Select.Option;

class ProjectUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      permissions: [],
      userModules: this.props.userModules ? this.props.userModules : [],
      restore: false,
    }
  }

  componentDidMount() {

    this.setState({ loading: true });

    this.props.resetProjectUserAction();

    api('get','/userrights/get-possible-project-rights/' + this.props.match.params.id, null, true).then((response) => {

        this.setState({permissions: response.data}, () => {

            // this.state.permissions.map((element, index) => {
            //     this.setState({ [element.object]: this.getInitialValue(element.object) });
            // });

            // this.props.getAccountsAction();
            // this.props.getUnassignedProjectUsersAction(this.props.match.params.id).then(() => {

                if(this.props.match.params.userid) {
                    this.props.getProjectUserAction(this.props.match.params.id, this.props.match.params.userid).then(() => {

                        this.state.permissions.map((element, index) => {
                            this.setState({ [element.object]: this.getInitialValue(element.object, null, false, true) });
                            // this.setState({ ['default_' + element.object]: this.getInitialValue(element.object, null, true) });
                        });

                        this.props.user.project_rights.map((element) => {

                            var default_access = element.default ? element.default : 'read';
                            if(this.props.user.global_user_role == 'user'){ default_access = 'write'; }
                            if(this.props.user.global_user_role == 'admin'){ default_access = 'delete'; }

                            this.setState({ ['default_' + element.object]: default_access });
                        });

                        this.setState({ loading: false });

                    });
                }
                else
                {
                    this.props.getUnassignedProjectUsersAction(this.props.match.params.id).then(() => {
                        this.setState({ userModules: [] });
                        this.setState({ loading: false });
                    });
                }
            // });
        });
    });
  }

  patchProjectUser(e) {
    if(e) {
      e.preventDefault();
    }

    this.props.form.validateFields((error, values) => {
      if(!error) {
        this.props.updateProjectUserAction(this.props.match.params.id, this.props.match.params.userid || values.user_id , values).then(() => this.props.history.push(`/projects/${this.props.match.params.id}/users`));
        // if(this.props.match.params.userid) {
        //   this.props.updateProjectUserAction(this.props.match.params.id, this.props.match.params.userid, values).then(() => this.props.history.push(`/projects/${this.props.match.params.id}/users`));
        // } else {
        //   this.props.addProjectUserAction(this.props.match.params.id, values).then(() => this.props.history.push(`/projects/${this.props.match.params.id}/users`));
        // }
        showSuccess();
      }
    })

    // this.patchProjectuserform.props.form.validateFields((err, values) => {
    //   if (!err) {
    //     if(this.props.match.params.newsid) {
    //         this.props.updateProjectUserAction(this.props.match.params.projectid, values);
    //     } else {
    //         this.props.addProjectUserAction(this.props.match.params.id, values).then((response) => {
    //           this.props.history.push(`/project/${this.props.match.params.id}/user/${response.data.user.id}/`);
    //         });
    //     }
    //
    //     showSuccess('Gebruiker opgeslagen');
    //   }
    // });
  }

  showAllView = (e) => {
    // console.log(this.props)
    Object.values(this.props.customer.modules).forEach((value) => {
      this.props.form.setFieldsValue({['module['+value.id+']['+e.target.value+']'] : e.target.checked ? 1 : 0});
    });
  }

  getInitialValue = (object, default_access = 'read', restore = false, init = false) => {

    // if(!this.props.match.params.userid) {
    //   return 'write';
    // }

    // if(this.props.user && this.props.user.project_rights) {
    //   var index = _.findKey(this.props.user.project_rights, { 'object': object });

    //   if(this.props.user && this.props.user.project_rights && this.props.user.project_rights[index] && this.props.user.project_rights[index].allowed == 1) {
    //     return this.props.user.project_rights[index].type;
    //   }

    // }

    // return object == 'address' ? 'read' : '';

    var access = default_access;

    if(this.props.user && this.props.user.project_rights) {
      this.props.user.project_rights.map((element) => {
        if(element.object == object) {

          if(restore)
          {
              access = element.default;
          }
          else if(!init)
          {
              if(element.allowed){
                access = this.state[object]; // element.type;
              }
              else {
                access = '';
              }
          }
          else
          {
              if(element.allowed){
                access = element.type;
              }
              else {
                access = '';
              }
          }
        }
      });
    }

    return access;
  }

  hasDifferentRights = (object) /*, access = 'read')*/ => {

    var different = false;

    var access = this.state[object];

    var default_access = 'read';

    if(this.props.user.global_user_role == 'user'){ default_access = 'write'; }
    if(this.props.user.global_user_role == 'admin'){ default_access = 'delete'; }

    if(this.props.user && this.props.user.id && this.props.user.project_rights)
    {
      this.props.user.project_rights.map((element) => {
        if(element.object == object) {
          // access = element.different_rights;
          if(access != (element.default ? element.default : default_access)){ different = true; console.log('different!', object, access, element); }
        }
      });
    }

    // return access;
    return different; // this.props.user && this.props.user.id && this.props.user.different_rights ? true : false;
  }

  selectUser(id) {

      this.setState({ loading: true });

      this.props.unassigned.map((user) => {
          if(user.id == id)
          {
              // if(user.global_user_role)
              // {
                  this.props.getAccountRoleAction(user.global_user_role, this.props.match.params.id).then(() => {
                      // set default value(s)
                      this.props.accountrole && this.props.accountrole.rights && this.props.accountrole.rights.map((element) => {
                          this.setState({ [element.object]: element.access });
                          // console.log(element);
                      });

                      this.setState({ loading: false });
                  });
              // }
          }
      });
  }

  restoreRights()
  {
      // this.setState({ restore: true });
      // this.props.getProjectUserAction(this.props.match.params.id, this.props.match.params.userid).then(() => {

          this.state.permissions.map((element, index) => {
              // this.setState({ [element.object]: this.getInitialValue(element.object, null, true) });
              this.props.form.setFieldsValue({[element.object]: this.state['default_' + element.object]});
              this.setState({ [element.object]: this.state['default_' + element.object] });
          });

          this.setState({ loading: false });

      // });
  }

  selectAll(access)
  {
      this.state.permissions && this.state.permissions.map((element, index) => {

         var new_access = access;

         if(element.object == 'address' && !access){ new_access = 'read'; }

         this.setState({ [element.object]: new_access }, () => {

             this.props.form.setFieldsValue({ [element.object]: new_access });
         });

      });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <ProjectSidemenu id={this.props.match.params.id} activeMenu={["4"]} {...this.props}/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={24}>
              <div>
                <Button disabled={!this.props.HaveAbility('project-users', 'write')} type="new" onClick={() => this.patchProjectUser(null)}>Opslaan</Button>
                <Link to={`/projects/${this.props.match.params.id}/users`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>
            </Col>
          </Row>

          <Divider></Divider>

          {!this.props.match.params.userid ? (
            <Alert
              message="Let op!"
              description="Na het toevoegen van een account aan dit project ontvangt deze persoon automatisch een e-mail."
              type="info"
              showIcon
              style={{marginBottom: 24}}
            />
          ): null}

          { this.props.user.different_rights ? (
            <Alert
              message="Let op!"
              description="Deze gebruiker heeft afwijkende rechten ten opzichte van zijn of haar geselecteerde rol."
              type="info"
              showIcon
              style={{marginBottom: 24}}
            />
          ): null }

          <Form onSubmit={this.patchProjectUser} layout={'vertical'} colon={true}>
            <Row gutter={24}>
              <Col span={12}>

                {this.props.match.params.userid ? (
                  <div>
                    {this.props.user.email}
                  </div>
                ) : (
                  <Form.Item required hasFeedback label="Selecteer een gebruiker">
                    {getFieldDecorator('user_id', {
                      initialValue: this.props.match.params.userid ? Number(this.props.match.params.userid) : [],
                      rules: [{ required: true, message: 'Selecteer een gebruiker' }],
                      onChange: (value) => { this.selectUser(value); },
                    })(
                      <Select showSearch optionFilterProp="children" disabled={this.props.match.params.userid} placeholder="Selecteer een gebruiker">
                        { this.props.unassigned && this.props.unassigned.map((element) => {
                          return (
                            <Option value={element.id}>{element.last_name ? (element.first_name + ' ' + (element.affix ? element.affix + ' ' : '') + element.last_name + ' (' + element.email + ')') : element.email + ' (' + element.role_name + ')'}</Option>
                          )

                        })}
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>

              <Divider></Divider>
              <Row style={{borderBottom:'1px solid #e8e8e8', paddingTop:6, paddingBottom:6 }}>
                <Col span={24}>
                  <Title level={4}>
                    Rechten
                    <Button style={{marginLeft: 8}} onClick={() => { this.restoreRights(); }}>Herstel rechten</Button>
                    { this.state.loading ? <Spin size="small" style={{marginLeft: 10}} /> : null }
                </Title>
                </Col>
              </Row>

              <Skeleton active loading={this.state.loading} title={false} paragraph={{ rows: 4 }}>
                <table style={{width: '100%'}}>
                  <thead>
                    <tr style={{ border: '1px solid #f0f2f5', backgroundColor: '#f0f2f5'}}>
                      <th style={{ padding: '4px 8px'}}>Rechten</th>
                      <th colspan="3" style={{ padding: '4px 8px'}}>
                        <td style={{ width: 140, cursor: 'pointer' }} onClick={() => { this.selectAll(''); }}>Geen toegang</td>
                        <td style={{ width: 100, cursor: 'pointer' }} onClick={() => { this.selectAll('read'); }}>Lezen</td>
                        <td style={{ width: 100, cursor: 'pointer' }} onClick={() => { this.selectAll('write'); }}>Bewerken</td>
                        <td style={{ width: 200, cursor: 'pointer' }} onClick={() => { this.selectAll('delete'); }}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: 8 }}>Bewerken en verwijderen</span>
                            {/* <Checkbox onClick={() => this.setValues()}></Checkbox> */}
                          </div>
                        </td>
                      </th>
                      {/* <th style={{ padding: '4px 8px'}}>Schrijven</th> */}
                      {/* <th style={{ padding: '4px 8px'}}>Verwijderen</th> */}
                    </tr>
                  </thead>
                  <tbody>

                  {this.state.permissions && this.state.permissions.map((element, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ padding: '4px 8px'}}>
                          {element.label}
                          { this.hasDifferentRights(element.object) /*, this.state[element.object])*/ ?
                            <Tooltip className="mhs" title='Let op: de gebruiker heeft op dit onderdeel afwijkende rechten.'>
                              <ExclamationCircleOutlined style={{ color: "rgb(250, 173, 20)" }} />
                            </Tooltip> : null
                          }
                        </td>
                        <td colspan="3" style={{ padding: '4px 8px'}}>
                          <Form.Item style={{ margin: 0}}>
                            { getFieldDecorator(element.object, {
                              initialValue: this.state[element.object], // ? this.state[element.object] : '',
                              onChange: (e) => {

                                  // this.props.form.setFieldsValue({[element.object]: e.target.value});

                                  this.setState({
                                      [element.object]: e.target.value /*, restore: false*/
                                    }, () => {
                                        // this.props.form.setFieldsValue({[element.object]: e.target.value});
                                    }
                                  );
                              },
                              valuePropName: 'checked',
                            })(
                              <Radio.Group style={{ width: '100%' }}
                                value={this.state[element.object]}
                                >
                                <td style={{width: 142}}>
                                  {element.object == 'address' ? null : <Radio disabled={element.object == 'address' ? true : false} style={{ width: 140, marginRight: 0 }} value={''}></Radio>}
                                </td>
                                <td><Radio style={{ width: 100, marginRight: 0 }} value={'read'}></Radio></td>
                                <td><Radio style={{ width: 100, marginRight: 0 }} value={'write'}></Radio></td>
                                <td><Radio style={{ width: 200, marginRight: 0 }} value={'delete'}></Radio></td>
                              </Radio.Group>
                            )}
                          </Form.Item>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </Skeleton>

          </Form>
        </div>
      </div>
    );
  }
}


const ProjectUserForm = Form.create({ name: 'project_user', /*onFieldsChange: (props, changedValues, allValues) => console.log(allValues)*/ })(ProjectUser);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isError : state.project.isError,
    user : state.project.user,
    userModules : state.project.userModules,
    accounts : state.account.accounts,
    customer : state.auth.selectedCustomer,
    unassigned : state.project.unassigned,
    test : state.project.test,
    accountrole : state.account.accountrole,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectUserAction : bindActionCreators(getProjectUserAction, dispatch),
    addProjectUserAction : bindActionCreators(addProjectUserAction, dispatch),
    updateProjectUserAction : bindActionCreators(updateProjectUserAction, dispatch),
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    resetProjectUserAction : bindActionCreators(resetProjectUserAction, dispatch),
    getUnassignedProjectUsersAction : bindActionCreators(getUnassignedProjectUsersAction, dispatch),
    getAccountRoleAction : bindActionCreators(getAccountRoleAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUserForm);
