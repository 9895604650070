import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Card, Row, Col, Button, Input } from 'antd';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddFromLibrary from '../../../../components/AddFromLibrary/AddFromLibrary';
import PushnoticationSteps from '../Steps/PushnotificationSteps';
import { storePushNotificationAction, getPushNotificationDetailAction, resetPushnotificationAction, patchPushnotificationAction, sendPushnotificationAction } from '../../../../actions/pushNotificationsActions';
// import { setCorrespondenceMethodAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction } from '../../../../../actions/correspondencesActions';

class AddPublicPush extends React.Component {
  state = {
    method: {},
  };

  componentDidMount() {

    this.props.resetPushnotificationAction();

    if(this.props.match.params.pushnotificationid) {
      this.props.getPushNotificationDetailAction(this.props.match.params.pushnotificationid).then(() => {
      });
    }
  }

  saveMethod = () => {
    this.props.form.validateFields((errors, values) => {
      if(!errors) {
        if(!this.props.match.params.pushnotificationid) {
          this.props.storePushNotificationAction(this.props.match.params.id, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/pushnotification`);
          });
        } else {
          this.props.patchPushnotificationAction(this.props.match.params.pushnotificationid, values).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/pushnotification`);
          });
        }
      }
    })
  }

  sendPush = () => {
    this.props.sendPushnotificationAction(this.props.match.params.pushnotificationid).then(() => {
        this.props.getPushNotificationDetailAction(this.props.match.params.pushnotificationid).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/pushnotification`);
        });
    });
  }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Link to={`/projects/${this.props.match.params.id}/pushnotification`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Card style={{backgroundColor: '', borderRadius: '5px', marginBottom: 24}} bodyStyle={{padding:0}}>
          <Row>
            <PushnoticationSteps current={1} {...this.props}/>
          </Row>
        </Card>
        <Row>
          <Card>
            {/*<Form layout={'vertical'} colon={true}>
              <Row gutter={24} >
                <Col span={24}>
                  <AddFromLibrary onSelectLibrary={(library) => this.props.form.setFieldsValue({'message':library.content.replace(/(<([^>]+)>)/gi, "")})}/>
                  <Form.Item label="Push bericht">
                    { getFieldDecorator('message', {
                      initialValue: this.props.notification && this.props.notification.message || '',
                    })(
                      <Input.TextArea rows={4} />
                    ) }
                  </Form.Item>
                </Col>
              </Row>
            </Form>*/}
            <p>Wordt verzonden naar <strong>{this.props.notification.nr_of_devices}</strong> apparaten</p>
            {this.props.match.params.pushnotificationid && (
              <Button disabled={this.props.notification.status === 'send'} type="new" onClick={this.sendPush}>Verzenden</Button>
            )}

          </Card>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.pushnotification.isFetching,
    isError : state.pushnotification.isError,
    notification : state.pushnotification.notification
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    storePushNotificationAction : bindActionCreators(storePushNotificationAction, dispatch),
    getPushNotificationDetailAction : bindActionCreators(getPushNotificationDetailAction, dispatch),
    patchPushnotificationAction : bindActionCreators(patchPushnotificationAction, dispatch),
    sendPushnotificationAction : bindActionCreators(sendPushnotificationAction, dispatch),
    resetPushnotificationAction : bindActionCreators(resetPushnotificationAction, dispatch)
  }
};

var formAddPublicPush = Form.create({ name: 'compose_mail_form' })(AddPublicPush);

export default connect(mapStateToProps, mapDispatchToProps)(formAddPublicPush);
