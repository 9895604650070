import api from '../utils/api'

export const getAccountsAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/userrights/customer/'+customer_id, params, true).then((response) => {
    dispatch({
      type: 'ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNTS_ACTION_ERROR',
    })
    return false;
  });

}

export const getAccountAction = (id) => dispatch => {

  dispatch({
    type: 'ACCOUNT_ACTION_FETCH'
  })

  return api('get','/user/'+id, null, true).then((response) => {
    dispatch({
      type: 'ACCOUNT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNT_ACTION_SUCCESS',
    })
    return false;
  });
}

export const getAccountCustomerAction = (id) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/userrights/user/'+id+'/customer/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'ACCOUNT_ACTION_SUCCESS',
      payload: response.data.user
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNT_ACTION_SUCCESS',
    })
    return false;
  });
}

export const patchAccountAction = (id, values, files) => dispatch => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  return api('post','/user/'+id, values, true, files).then((response) => {
    dispatch({
      type: 'PATCH_ACCOUNT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNT_ACTION_SUCCESS',
    })
    return false;
  });
}

export const patchAccountCustomerAction = (userid, values) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/customer/'+ customer_id +'/user/'+userid, values, true).then((response) => {
    dispatch({
      type: 'PATCH_ACCOUNT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNT_ACTION_SUCCESS',
    })
    return false;
  });
}

export const addAccountAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  if(!params.customer_id) {
      params.customer_id = localStorage.getItem('customerId');
  }

  if(!params.customer_id && getState().auth.selectedCustomer) {
    params.customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/register', params, true).then((response) => {
    dispatch({
      type: 'ADD_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADD_ACCOUNTS_ACTION_ERROR',
    })
    return false;
  });
}

export const addAccountToCustomerAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/customer/'+customer_id+'/user', params, true).then((response) => {
    dispatch({
      type: 'ADD_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADD_ACCOUNTS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchCustomerAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  var search_url = '/users/search/customer/'+ customer_id;

  return api('post',search_url, {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_ACCOUNTS_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteAccountsAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/users/' + customer_id + '/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const updateAccountsAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/users/' + customer_id + '/multiupdate', params, true).then((response) => {
    dispatch({
      type: 'UPDATE_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const sendPasswordAccountsAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/users/' + customer_id + '/multiresetpw', params, true).then((response) => {
    dispatch({
      type: 'PASSWORD_SEND_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const multiStatusAccountsAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/users/' + customer_id + '/multistatus', params, true).then((response) => {
    dispatch({
      type: 'MULTI_STATUS_ACCOUNTS_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}



export const getAccountRolesAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTROLES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/user-roles/' + customer_id, params, true).then((response) => {
    dispatch({
      type: 'ACCOUNTROLES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNTROLES_ACTION_ERROR',
    })
    return false;
  });

}

export const searchAccountRolesAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'ADD_ACCOUNTROLES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/user-roles/' + customer_id + '/search', {search: search}, true).then((response) => {
    dispatch({
      type: 'ACCOUNTROLES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNTROLES_ACTION_ERROR',
    })
    return false;
  });

}

export const getAccountRoleAction = (id, project_id = false) => dispatch => {

  dispatch({
    type: 'ACCOUNTROLES_ACTION_FETCH'
  })

  return api('get','/user-role/' + id + (project_id ? '/' + project_id : '') , null, true).then((response) => {
    dispatch({
      type: 'ACCOUNTROLE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNTROLE_ACTION_SUCCESS',
    })
    return false;
  });
}

export const patchAccountRoleAction = (id, values) => dispatch => {

  dispatch({
    type: 'ACCOUNTROLES_ACTION_FETCH'
  })

  return api('post','/user-role/' + id, values, true).then((response) => {
    dispatch({
      type: 'PATCH_ACCOUNTROLE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ACCOUNTROLE_ACTION_SUCCESS',
    })
    return false;
  });
}

export const addAccountRoleAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ACCOUNTROLES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/user-roles/' + customer_id, params, true).then((response) => {
    dispatch({
      type: 'ADD_ACCOUNTROLE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ADD_ACCOUNTROLE_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteAccountRolesAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/user-roles/' + customer_id + '/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_ACCOUNTROLES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}



export const resetAccountsAction = () => dispatch => {
  dispatch({
    type: 'RESET_ACCOUNTS_ACTION_SUCCESS',
  });
}

export const resetAccountRoleAction = () => dispatch => {
  dispatch({
    type: 'RESET_ACCOUNTROLE_ACTION_SUCCESS',
  });
}
