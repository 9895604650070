import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../../utils/Notifications';
import { resetDeliveryAction, getDeliveryAction, getDeliverAddressesAction, updateDeliveryAction } from '../../../../actions/deliverActions';
import DeliverSideMenu from '../DeliverSideMenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    Select,
    Dropdown,
    Menu,
    DatePicker,
    Tag,
    Tooltip,
    Timeline,
    Switch,
    Steps,
    Card,
} from 'antd';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import '../deliver.css';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import DeliverySteps from './Steps.js';

const { Option } = Select;
const { TextArea } = Input;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

class DeliveryAddresses extends Component
{
    state = {
        selectedAddressIds: [],
        loaded: false,
    };

    constructor(props) {
      super(props);
      // this.selectaddresses = React.createRef();
    }

    componentDidMount()
    {
        this.props.resetDeliveryAction();

        this.loadData();
    }

    loadData()
    {
        this.props.getDeliveryAction(this.props.match.params.delivery_id).then(() => {

            this.setState({ selectedAddressIds: this.props.delivery.address_ids }, () => { this.setState({ loaded: true }); });
        });

        this.props.getDeliverAddressesAction(this.props.match.params.id);
    }

    handleSave()
    {
        // update
        this.props.updateDeliveryAction(this.props.match.params.delivery_id, {address_id: this.state.selectedAddressIds}).then(() => {

            showSuccess('Succesvol opgeslagen');

            this.props.history.push(`/projects/${this.props.match.params.id}/deliver/deliveries`);
        });
    }

    render()
    {
        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="5"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Row className="mbm">
                        <Col span={24}>
                            <div>
                                <Button disabled={!this.state.loaded} type={'new'} onClick={() => { this.handleSave(); }}>Opslaan</Button>
                                <Link to={`/projects/${this.props.match.params.id}/deliver/deliveries`}>
                                    <Button>Sluiten</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mbm">
                      <Col span={24}>
                        <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                            <DeliverySteps type="navigation" size="small" current={1} onChange={this.onChange} style={{display: 'block'}} {...this.props} />
                        </Card>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <SelectAddresses
                                {...this.props}
                                /*forwardedRef={this.selectaddresses}*/
                                childRef={ref => this.myref = ref}
                                initialAddresses={this.props.delivery.address_ids}
                                objectId={this.props.match.params.delivery_id}
                                objectType="delivery"
                                onAddressSelection={(selectedAddressIds) => this.setState({selectedAddressIds})}
                                disabledIds={this.props.delivery.disabled_address_ids}
                                />
                        </Col>
                    </Row>

                </div>

            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        delivery : state.deliver.delivery,
        addresses : state.deliver.addresses,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetDeliveryAction : bindActionCreators(resetDeliveryAction, dispatch),
        getDeliveryAction : bindActionCreators(getDeliveryAction, dispatch),
        updateDeliveryAction : bindActionCreators(updateDeliveryAction, dispatch),
        getDeliverAddressesAction : bindActionCreators(getDeliverAddressesAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddresses);
