import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Upload,
    Input,
    Select,
    Switch,
    Button,
    Drawer,
    Popconfirm,
    message,
    Divider,
    Steps,
    Slider,
    PageHeader,
    Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getContactAction, addContactAction, patchContactAction, resetContactAction } from '../../../../../actions/contactsActions';
//import { getLibrariesAction } from '../../../../../actions/librariesActions';
import PatchContactForm from '../Form/PatchContact';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import { showSuccess } from '../../../../../utils/Notifications';
import NumberFormat from 'react-number-format';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class ContactDetail extends Component {

    constructor(props) {
      super(props);

      this.state = {
        avatarMediaId: null,
        logoMediaId: null
      }
    }

    componentDidMount()
    {
        this.props.resetContactAction();

        if(this.props.match.params.contactid)
        {
          this.props.getContactAction(this.props.match.params.contactid); //.then(() => this.props.getLibrariesAction());
        }
    }

    patchContact(e) {
      if(e) {
        e.preventDefault();
      }

      this.props.form.validateFields((err, values) => {
        console.log(values)
        if (!err) {
          if(this.props.match.params.contactid) {
            this.props.patchContactAction(this.props.match.params.contactid, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/contacts`);
            });
          } else {
            this.props.addContactAction(this.props.match.params.id, values).then(() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/modules/contacts`);
            });
          }

          showSuccess('Succesvol opgeslagen')
        }
      });
    }

    render() {
         const { getFieldDecorator } = this.props.form;

        return (
            <div>
              <Row className="mbm">

                <Button disabled={!this.props.HaveAbility('contact' ,'write')} type="new" onClick={() => this.patchContact(null)}>Opslaan</Button>
                <Link to={'/projects/'+this.props.match.params.id+'/modules/contacts'}>
                  <Button>Sluiten</Button>
                </Link>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Card>
                        <Form className={'ant-form--custom'} onSubmit={this.patchContact} layout={'vertical'} colon={true}>
                            <Row gutter={24}>

                                <Col span={8}>
                                    <Form.Item required hasFeedback label="Voornaam">
                                          {getFieldDecorator('first_name', {
                                              initialValue: this.props.contact.first_name,
                                              rules: [{ required: true, message: 'Vul een voornaam in' }],
                                          })(
                                              <Input />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item hasFeedback label="Tussenvoegsel">
                                          {getFieldDecorator('affix', {
                                              initialValue: this.props.contact.affix,
                                              rules: [{ required: false, message: 'Vul een tussenvoegsel in' }],
                                          })(
                                              <Input />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item required hasFeedback label="Achternaam">
                                          {getFieldDecorator('last_name', {
                                              initialValue: this.props.contact.last_name,
                                              rules: [{ required: true, message: 'Vul een achternaam in' }],
                                          })(
                                              <Input />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hasFeedback label="Telefoon">
                                          {getFieldDecorator('phone_number', {
                                              initialValue: this.props.contact.phone_number ? this.props.contact.phone_number : '',
                                              rules: [{ required: false, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                                          })(
                                              <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input" />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hasFeedback label="Mobiel">
                                          {getFieldDecorator('mobile_number', {
                                              initialValue: this.props.contact.mobile_number ? this.props.contact.mobile_number : '',
                                              rules: [{ required: false, message: 'Vul een mobiel telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                                          })(
                                              <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input" />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hasFeedback label="E-mailadres">
                                          {getFieldDecorator('email', {
                                              initialValue: this.props.contact.email,
                                              rules: [
                                                  { required: false, message: 'Vul een e-mailadres in' },
                                                  { type: 'email', message: 'Vul een geldig e-mailadres in' }
                                              ],
                                          })(
                                              <Input type={'email'} />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Chat">
                                          {getFieldDecorator('can_chat', {
                                              initialValue: this.props.contact && this.props.contact.can_chat ? true : false,
                                              valuePropName: 'checked'
                                              // defaultChecked: this.props.contact.can_chat ? true : false,
                                              /*rules: [
                                                  { required: false, message: 'Vul een e-mailadres in' },
                                                  { type: 'email', message: 'Vul een geldig e-mailadres in' }
                                              ],*/
                                          })(
                                              <Switch
                                                  // disabled={!this.props.HaveAbility('correspondence', 'write')}
                                                  checkedChildren={<CheckOutlined />}
                                                  unCheckedChildren={<CloseOutlined />}
                                                  // defaultChecked={this.props.contact && this.props.contact.can_chat ? true : false}
                                                  // checked={this.props.correspondence.on_stationery} onChange={(value) => this.saveMethodletterIdentity(value, 'mail')}
                                                  />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item hasFeedback label="Functie">
                                          {getFieldDecorator('function', {
                                              initialValue: this.props.contact.function,
                                              rules: [{ required: false, message: 'Vul een functie in' }],
                                          })(
                                              <Input type={'phone'} />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item hasFeedback label="Omschrijving">
                                          {getFieldDecorator('description', {
                                              initialValue: this.props.contact.description,
                                              rules: [{ required: false, message: 'Vul een omschrijving in' }],
                                          })(
                                              <Input type={'phone'} />
                                          )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Avatar">
                                      {getFieldDecorator('avatar_id', {
                                        initialValue: this.props.contact.avatar_id,
                                      })(
                                        <AddFromMedia
                                          projectId={this.props.match.params.id}
                                          initialMediaId={this.props.contact.avatar_id}
                                          initialMediaUrl={this.props.contact.avatar_url}
                                          onMediaSelection={(item) => this.props.form.setFieldsValue({'avatar_id': item.id})}
                                          onMediaRemove={() => this.props.form.setFieldsValue({'avatar_id': ''})}
                                        />
                                      )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Logo">
                                      {getFieldDecorator('logo_id', {
                                        initialValue: this.props.contact.logo_id,
                                      })(
                                        <AddFromMedia
                                          projectId={this.props.match.params.id}
                                          initialMediaId={this.props.contact.logo_id}
                                          initialMediaUrl={this.props.contact.logo_url}
                                          onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
                                          onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
                                        />
                                      )}
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>

                  </Card>
                </Col>
              </Row>
            </div>
        );
    }
}

const ContactForm = Form.create({ name: 'contact_form' })(ContactDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.contact.isFetching,
      isError : state.contact.isError,
      contact: state.contact.contact
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getContactAction : bindActionCreators(getContactAction, dispatch),
      patchContactAction : bindActionCreators(patchContactAction, dispatch),
      addContactAction : bindActionCreators(addContactAction, dispatch),
      resetContactAction : bindActionCreators(resetContactAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
