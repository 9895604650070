export default (state = {isError: false, isFetching: false, transactions: [], filters: [], billing: { projects: [] } }, action) => {
    switch (action.type) {
    case 'TRANSACTIONS_ACTION_FETCH':
    return {
        ...state,
        transactions: [],
        isFetching: true,
        isError: false,
    }
    case 'TRANSACTION_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'TRANSACTIONS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        transactions: action.payload['transactions'],
        filters: action.payload['filters']
    }
    case 'TRANSACTION_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'TRANSACTION_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false,
    }
    case 'TRANSACTIONS_CUSTOMER_ACTION_SUCCESS':
    return {
        ...state,
        isError: true,
        isFetching: false,
        billing: action.payload,
    }
    default:
    return {
        ...state,
        isError: false,
        isFetching: false,
    }
}
}
