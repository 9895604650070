import api from '../utils/api'

export const getEnvelopesAction = (params) => (dispatch, getState) => {

  dispatch({
    type: 'ENVELOPES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/envelopes/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'ENVELOPES_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ENVELOPES_ACTION_ERROR',
    })
    return false;
  });
}

export const searchEnvelopesAction = (search) => (dispatch, getState) => {

  dispatch({
    type: 'ENVELOPES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/envelopes/'+customer_id+'/search', {'search': search}, true).then((response) => {
    dispatch({
      type: 'SEARCH_ENVELOPES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    dispatch({
      type: 'SEARCH_ENVELOPES_ACTION_ERROR',
    })
    return false;
  });
}

export const getEnvelopeAction = (id) => dispatch => {

  dispatch({
    type: 'ENVELOPE_ACTION_FETCH'
  })

  return api('get','/library/envelope/'+id, null, true).then((response) => {
    dispatch({
      type: 'ENVELOPE_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'ENVELOPES_ACTION_ERROR',
    })
    return false;
  });
}

export const storeEnvelopeAction = (params, envelope) => (dispatch, getState) => {

  dispatch({
    type: 'ENVELOPES_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer)
  {
      customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/envelopes/'+customer_id, params, true, envelope).then((response) => {
    dispatch({
      type: 'STORE_ENVELOPE_ACTION_SUCCESS',
      payload: response.data.envelope
    });
  }).catch((e) => {
    dispatch({
      type: 'ENVELOPES_ACTION_ERROR',
    })
    return false;
  });
}

export const patchEnvelopeAction = (id, params, envelope) => dispatch => {

  dispatch({
    type: 'ENVELOPES_ACTION_FETCH'
  })

  return api('post','/library/envelope/'+id, params, true, envelope).then((response) => {
    dispatch({
      type: 'ENVELOPE_ACTION_SUCCESS',
      payload: response.data.envelope
    });
  }).catch((e) => {
    dispatch({
      type: 'ENVELOPES_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteEnvelopesAction = (params) => (dispatch, getState) => {

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

  return api('post','/library/envelopes/' + customer_id + '/multidelete', params, true).then((response) => {
    dispatch({
      type: 'DELETE_ENVELOPES_ACTION_SUCCESS',
      payload: response.data
    });
    return true;
  }).catch((e) => {
    // dispatch({
    //     type: 'DELETE_CUSTOMERS_ACTION_ERROR',
    // })
    return false;
  });
}

export const resetEnvelopeAction = () => dispatch => {

  dispatch({
    type: 'RESET_ENVELOPE_ACTION_ERROR',
  })

}
