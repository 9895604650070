import React from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid';

import {
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  DatePicker,
  Collapse,
  Tooltip,
  Upload,
  message,
  Avatar,
} from 'antd';
import { API_URL } from '../../../../constants/settings';

import OptionList from '../OptionList';

class DrawingInputForm extends React.Component {

  state = {
    imageUrl: '',
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>
        <Form.Item label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Tekening
            <Tooltip className="mhs" title='Upload hier de tekening. De tekening is te uploaden als JPEG, PNG of PDF bestand.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>


        {getFieldDecorator('drawing', {
          initialValue: this.props.formData && this.props.formData.drawing,
        })(
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Avatar shape="square" style={{width: 200, height: 200}} src={this.props.form.getFieldValue('drawing')} />
            <Upload
              accept="image/png,image/jpeg,application/pdf"
              showUploadList={false}
              name="drawing"
              action={`${API_URL}/intake/form/${this.props.formId}/drawing`}
              headers={{'Authorization': `Bearer ${localStorage.authToken}`}}
              onChange={(info) => {
                if(info.file.status == 'done') {
                  // console.log(info.file.response.drawing_url);
                  this.props.form.setFieldsValue({'drawing': info.file.response.drawing_url})
                }
              }}
            >
              <Button>Upload een tekening</Button>
            </Upload>
          </div>
        )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('pinpointsEnabled', {
            initialValue: this.props.formData && this.props.formData.pinpointsEnabled,
            valuePropName: 'checked'
          })(
            <Checkbox>
              <span>
                Onderdelen pinnen
                <Tooltip className="mhs" title='Op de tekening kunnen bepaalde onderdelen worden aangeduid (worden gepind).'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
            </Checkbox>
          )}
        </Form.Item>

        {this.props.form.getFieldValue('pinpointsEnabled') === true ? (
          <Form.Item label={(
            <span>
              Opties
              <Tooltip className="mhs" title='Definieer welke opties er op de tekening mogen worden aangeduid (worden gepind).'>
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          )} style={{marginBottom: 0}}>
            {getFieldDecorator('options', {
              initialValue: this.props.formData && this.props.formData.options ? this.props.formData.options : [{'id': uuidv1() ,'label' : 'Optie 1'}, {'id': uuidv1() ,'label' : 'Optie 2'}, {'id': uuidv1() ,'label' : 'Optie 3'}],
            })(
              <OptionList />
            )}
          </Form.Item>
        ) : null}
        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>
              Dit veld is verplicht
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('listField', {
            initialValue: this.props.formData && this.props.formData.listField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld tonen op de meterkastlijst</Checkbox>
          )}
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('commentsEnabled', {
            initialValue: this.props.formData && this.props.formData.commentsEnabled,
            valuePropName: 'checked'
          })(
            <Checkbox
              onChange={(event) => { if(event.target.checked){ this.props.form.setFieldsValue({'pinpointsEnabled': true}); } }}
              >
              <span>
                Opmerking toestaan
                <Tooltip className="mhs" title='Er kan een opmerking bij de tekening worden geplaatst.'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
            </Checkbox>
          )}
        </Form.Item>

      </Form>
    );
  }
}

const WrappedDrawingInputForm = Form.create({ name: 'text_input_form' })(DrawingInputForm);

class DrawingInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {},
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  renderExtraButtons = () => {
    return (
      <Button.Group>
        <Popconfirm
          icon={(<QuestionCircleOutlined />)}
          placement="top"
          onCancel={(event) => event.stopPropagation()}
          size="small"
          okType="danger"
          title={"Element verwijderen"}
          onConfirm={() => this.removeElement()}
          okText="Ja"
          cancelText="Nee"
          disabled={this.props.disabled}
        >
          <DeleteOutlined
            onClick={event => {
              // event.stopPropagation();
            }} />
        </Popconfirm>
        <PlusSquareOutlined
          style={{marginLeft: 5}}
          onClick={(event) => {
            event.stopPropagation();
            this.duplicateElement(this.props.id);
          }} />
      </Button.Group>
    );
  };

  saveFieldValues = () => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          console.log(values);
          this.setState({formData: values, openKeys: []});
          this.props.onSaveData(this.props.id, values);
        }
      })
    }
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Tekening</b> - {this.state.formData && this.state.formData.label || 'label'}</div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>

              <WrappedDrawingInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} {...this.props} />
              <Button onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Foto
        </div>
      );
    }
  }
}

export default DrawingInput;
