/*global gantt*/
import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Spin,
  Dropdown,
  Menu,
} from 'antd';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetAddressesAction, getAddressesAction, searchAddressesAction, exportScheduleAction } from '../../../../../actions/addressesActions';
import {  } from '../../../../../actions/scheduleActions';
import Settings from '../Form/Settings';
import ScheduleSteps from '../Steps/ScheduleSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import CustomTable from '../../../../../components/CustomTable/CustomTable';

// function onChange(pagination, sorter) {
//   //console.log('params', pagination, sorter);
// }

class ScheduleExport extends Component
{

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isFetching: false,
      selectedRowKeys: [],
    }

  }

  componentDidMount()
  {
      this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => {});
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  exportToPDF(type)
  {
      this.props.exportScheduleAction(this.props.match.params.id, type, this.state.selectedRowKeys, 'planning_'+this.props.match.params.id+'_export'+(type != 'simple' ? '_uitgebreid' : '')+'.zip').then(() => {
          this.setState({
            selectedRowKeys: []
          });
      });
  }

  render()
  {
      const { targetKeys, selectedKeys, selectedRowKeys, disabled } = this.state;
      // const { getFieldDecorator } = this.props.form;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: this.props.address.map(value => value.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
      };

      const columns = [{
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/addresses/${record.id}/general`}>
              {text ? text : '-'}
            </Link>
          </span>
        )
      }, {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => { return a.zipcode.localeCompare(b.zipcode)},
      }, {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => { return a.city.localeCompare(b.city)},
      },
      {
        title: 'Groep',
        dataIndex: 'groups',
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag>{v.name}</Tag>
        ))
      }];

    return (
      <div>
        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleSteps current={4} {...this.props} />
          </Card>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card>

                <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                    <Menu>
                      <Menu.Item onClick={() => { this.exportToPDF('simple'); }} key="7">Eenvoudige export</Menu.Item>
                      <Menu.Item onClick={() => { this.exportToPDF('advanced'); }} key="8">Uitgebreide export</Menu.Item>
                    </Menu>
                )}>
                    <Button type="secondary">
                        Acties <DownOutlined />
                    </Button>
                </Dropdown>

            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              // onChange={onChange}
              columns={columns}
              dataSource={this.props.address}
              loading={this.props.isFetching}
              />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    address: state.address.addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    exportScheduleAction : bindActionCreators(exportScheduleAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleExport);
