import api from '../utils/api'

export const getWorkOrderAction = (projectid) => dispatch => {

  dispatch({
    type: 'WORKORDER_ACTION_FETCH'
  })

  return api('get','/addresses/'+projectid+'/workorder/', null, true).then((response) => {
    dispatch({
      type: 'WORKORDER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'WORKORDER_ACTION_ERROR',
    })
    return false;
  });
}

export const getWorkOrderSettingsAction = (projectid) => dispatch => {

  dispatch({
    type: 'WORKORDER_ACTION_FETCH'
  })

  return api('get','/addresses/'+projectid+'/workorder-settings', null, true).then((response) => {
    dispatch({
      type: 'WORKORDER_SETTINGS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'WORKORDER_ACTION_ERROR',
    })
    return false;
  });
}

export const updateWorkOrderAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'WORKORDER_ACTION_FETCH'
  })

  return api('POST','/addresses/'+projectid+'/workorder', params, true).then((response) => {
    dispatch({
      type: 'WORKORDER_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'WORKORDER_ACTION_ERROR',
    })
    return false;
  });
}

export const updateWorkOrderSettingsAction = (projectid, params) => dispatch => {

  dispatch({
    type: 'WORKORDER_ACTION_FETCH'
  })

  return api('POST','/addresses/'+projectid+'/workorder-settings', params, true).then((response) => {
    dispatch({
      type: 'WORKORDER_SETTINGS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'WORKORDER_ACTION_ERROR',
    })
    return false;
  });
}
