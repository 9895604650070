import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Select, Menu, Tag } from 'antd';
import { bindActionCreators } from 'redux';
import { getCobeeInformationsAction, cloneCobeeInformationAction } from '../../../actions/globalInformationActions'
import { connect } from 'react-redux';

class CloneInformation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      selectedId: [],
    };
  }

  cloneInformation = () => {
    if(this.state.selectedId) {

      this.props.cloneCobeeInformationAction(this.state.selectedId).then((response) => {
        this.props.onClone(response);
        this.setState({selectedId: [], modalVisible: false});
      });
    }
  }

  openModal = () => {
    this.setState({modalVisible: true});
    this.props.getCobeeInformationsAction();
  }

  render () {
    return (
      <span>
        {this.props.plain ? (
          <span style={{display:'block'}}onClick={this.openModal}>
            {this.props.buttonLabel ? this.props.buttonLabel : 'Bibliotheekesdfdsf'}
          </span>
        ) : (
          <Button style={{marginRight: 10}} onClick={this.openModal}>Selecteer Cobee teksten</Button>
        )}

        <Modal
          title='Selecteer Cobee teksten'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: [] })}
          onOk={() => this.cloneInformation()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="Cobee teksten">
              <Select
                showSearch
                value={this.state.selectedId}
                placeholder={'Selecteer Cobee tekst'}
                optionFilterProp="children"
                onChange={(selectedId) => this.setState({selectedId})}
                mode='multiple'
                >
                {this.props.information.map((element) => (
                  <Select.Option value={element.id}>{element.title}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    information: state.information.globalCobeeInformation,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCobeeInformationsAction:  bindActionCreators(getCobeeInformationsAction, dispatch),
    cloneCobeeInformationAction:  bindActionCreators(cloneCobeeInformationAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneInformation);
