import React from 'react'
import PropTypes from 'prop-types'

import {
    BgColorsOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
    Row,
    Col,
    Card,
    Button,
    Input,
    Select,
    Modal,
    Popconfirm,
    Collapse,
    Checkbox,
    Tooltip,
} from 'antd';
import { TwitterPicker, SketchPicker } from 'react-color';
import './Section.css'

const ButtonGroup = Button.Group;

class SectionItem extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      edit: false,
      label: this.props.element.label || '',
      clone_section: this.props.element.clone_section || false,
      color: this.props.element.color || '#000000',
      showColor: false,
      type: this.props.type ? this.props.type : false
    }
  }

  saveTitle = () => {

    this.props.onSaveTitle(this.props.element.id, this.state.label)
    this.setState({edit: false});
  }

  saveColor = () => {

    this.props.onSaveColor(this.props.element.id, this.state.color);
  }

  renderTitle = () => {
    return !this.state.edit ? (
    <div onClick={(e) => {

        if(this.props.disabled){ return; }

        if(this.props.sections != 1)
        {
              this.setState({edit: !this.state.edit}, ()=>{
                this.refs.titleinput.input.focus();
              });
        }

        e.stopPropagation();
      }}
      style={{
          display: 'inline-block',
          fontWeight: 'bold',
          height: 18,
          width: 'auto',
          // whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
      }}>{this.props.element.label}</div>)
    :
    (
      <span style={{ width: 400, alignItems: 'center'}}>
        <Input disabled={this.props.disabled}
            ref='titleinput'
            onBlur={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            style={{ width:180, marginRight: 5}}
            onChange={(e) => this.setState({label: e.target.value})}
            value={this.state.label}
            maxlength={40}
            />
        <Button disabled={this.props.disabled} type="new" onClick={(e) => {
            this.saveTitle();
            e.stopPropagation();
          }}>Opslaan</Button>
      </span>
    );
  };

  toggleAllowCloneSection = () => {

      var checked = (this.state.clone_section == true ? false : true);

      this.setState({ clone_section: checked });

      this.props.onSaveCloneSection(this.props.element.id, checked);
  };

  renderExtraButtons = () => {

    if(this.props.disabled == true) {
      return;
    }

    return (
        <div style={{ display: 'flex', marginTop: 0, marginRight: -2 }}>

          <ButtonGroup style={{ marginRight: 8, fontSize: 12 }}>

              <Tooltip title="Sectie kleur op de compacte meterkastlijst">
                  { this.state.type == 'form' ? <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.setState({ showColor: !this.state.showColor }, () => {

                              if(!this.state.showColor){

                                  this.saveColor();
                              }
                          });
                      }}
                      >
                      <BgColorsOutlined style={{ color: this.state.color }} />
                  </Button> : null }

                  { this.state.showColor ?
                  <div onClick={(event) => { event.stopPropagation();/**/ }} style={{ position: 'absolute', zIndex: 2, width: 240 }}>
                      <div
                          style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                          onClick={(event) => { event.stopPropagation(); this.setState({ showColor: !this.state.showColor }); }}
                          />
                      <TwitterPicker
                          style={{ width: 240 }}
                          colors={['#f5222d', '#fa8c16', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#2f54eb', '#722ed1', '#eb2f96', '#000000']}
                          color={this.state.color ? this.state.color : '#000000'}
                          onChangeComplete={(color, event) => {

                              event.stopPropagation();

                              this.setState({color: color.hex}, () => {

                                  this.saveColor();
                              });

                              return false;
                          }}
                          />
                  </div> : null}
              </Tooltip>

              { this.props.cloneSections ?
              <Tooltip title="Gebruiker kan de sectie zelf dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {
                          event.stopPropagation();
                          this.toggleAllowCloneSection(this.props.element.id);
                      }}
                      style={{ backgroundColor: (this.state.clone_section ? '#1890ff' : '#ffffff'), color: (this.state.clone_section ? '#ffffff' : '#000000') }}
                      >
                      <CopyOutlined />
                  </Button>
              </Tooltip> : null }

          </ButtonGroup>

          { this.props.sections != 1 ?
          <ButtonGroup>

              <Tooltip title="Sectie titel aanpassen">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {

                          this.setState({edit: !this.state.edit}, () => {

                              if(this.refs.titleinput) {

                                  this.refs.titleinput.input.focus();
                              }
                          });

                          event.stopPropagation();
                      }}
                      >
                      <EditOutlined style={{display: (this.props.sections != 1 ? 'flex' : 'none')}} />
                  </Button>
              </Tooltip>

              <Tooltip
                  title="Sectie verwijderen"
                  >
                  <Popconfirm
                      icon={(<QuestionCircleOutlined />)}
                      placement="bottom"
                      title={"Sectie verwijderen"}
                      onConfirm={() => this.props.removeSection(this.props.element.id)}
                      onCancel={(event) => event.stopPropagation()}
                      okText="Ja"
                      okType="danger"
                      cancelText="Nee"
                      disabled={this.props.disabled}
                      >
                      <Button disabled={this.props.disabled}
                          size="small"
                          onClick={event => {
                              // event.stopPropagation();
                          }}
                          >
                      <DeleteOutlined style={{display: (this.props.sections != 1 ? 'flex' : 'none')}} />
                      </Button>
                  </Popconfirm>
              </Tooltip>

              <Tooltip title="Sectie dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => { event.stopPropagation(); this.props.duplicateSection(this.props.element.id); }}
                      >
                      <PlusSquareOutlined style={{display: (this.props.sections != 1 ? 'flex' : 'none')}} />
                  </Button>
              </Tooltip>

          </ButtonGroup> : null }

        </div>
    );
  };

  render () {

    return(
    <Collapse defaultActiveKey={[0]} style={{marginBottom: 24}} >
        <Collapse.Panel
            key={0}
            header={this.renderTitle()}
            extra={this.renderExtraButtons()}
            >
            <div style={{paddingTop: 24, paddingLeft: 24, paddingRight: 24}}>
                {this.props.children}
            </div>
        </Collapse.Panel>
    </Collapse>
    );
  }
}

export default SectionItem;
