import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess, showMultiDelete } from '../../../utils/Notifications'
import { getSupportBaseRemindersAction, searchSupportBaseRemindersAction, cloneSupportBaseRemindersAction, deleteSupportBaseRemindersAction } from '../../../actions/supportBaseActions';
import SupportBaseSideMenu from './SupportBaseSideMenu';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Switch,
  InputNumber,
  Tag,
  Menu,
  Dropdown,
  Alert,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

import moment from 'moment';
import {Link} from 'react-router-dom';

const { Title, Text } = Typography;

const Option = Select.Option;

class ProjectSupportBaseReminders extends Component {
  state = {
    disabled: true,
    edit: true,
    selectedRowKeys: [],
    can_write: false,
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

      this.props.getSupportBaseRemindersAction(this.props.match.params.id).then(() => {

      });
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  cloneSelected = () => {

    this.props.cloneSupportBaseRemindersAction(this.props.match.params.id, this.state.selectedRowKeys[0]).then(() => {
      this.props.getSupportBaseRemindersAction(this.props.match.params.id);
      showSuccess('Succesvol gedupliceerd');
      this.setState({selectedRowKeys: []})
    });
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteSupportBaseRemindersAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
          this.props.getSupportBaseRemindersAction(this.props.match.params.id);
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = (e) => { };

  render() {

    const columns = [{
        title: 'Omschrijving',
        dataIndex: 'subject', //email_subject
        render: (text, record) => (
          <span>
            <Link to={`/projects/${this.props.match.params.id}/support-base/reminder/settings/${record.id}`}>
              {text ? text : '[Onbekend onderwerp]'}
            </Link>
          </span>
        )
    },
    {
        title: 'Verzenden',
        dataIndex: 'reminder_date',
        render: (text, record) => (
          <span>
            { text && text != '0000-00-00 00:00:00' ? moment(text).format('DD/MM/YYYY - HH:mm') + ' uur' : 'direct' }
          </span>
        )
    }];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

        <SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="3"/>

        <div style={{padding: 24, width: '100%'}}>

          <Row className="mbm">
            <Col span={12}>
              <div>
                <Button disabled={!this.state.can_write} type="new" onClick={() => {this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/settings/add`);}}>+ Toevoegen</Button>

                <Dropdown trigger={['click']} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true } overlay={(
                    <Menu>
                      <Menu.Item disabled={this.state.selectedRowKeys.length > 1} onClick={() => this.cloneSelected()} key="6">Dupliceren</Menu.Item>
                      <Menu.Divider></Menu.Divider>
                      <Menu.Item disabled={!this.props.HaveAbility('support-base' ,'delete')} onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button disabled={!this.state.can_write} type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
          </Col>
          <Col span={12}>
              <Input.Search
                allowClear
                placeholder="Zoeken..."
                onSearch={ value => this.props.searchSupportBaseRemindersAction(this.props.match.params.id, value) }
                style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
              <Alert style={{marginBottom: 16}} showIcon type="info" message="Let op" description="De herinnering(en) die wordt aangemaakt wordt verstuurd op het gewenste ingeplande moment. De herinnering wordt alleen verstuurd naar de adressen waarvan op het geplande moment nog geen ondertekend formulier retour is ontvangen." />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.support_base_reminders}
              // dataSource={this.examples}
              // loading={this.props.isFetching}
            />
          </Col>
        </Row>

      </div>

    </div>
    );
  }
}

const ProjectSupportBaseRemindersForm = Form.create({ name: 'projects_support_base_forms' })(ProjectSupportBaseReminders);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.support_base.isFetching,
    isError : state.support_base.isError,
    support_base_reminders : state.support_base.reminders,
    //support_base_reminder : state.support_base.reminder,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportBaseRemindersAction : bindActionCreators(getSupportBaseRemindersAction, dispatch),
    searchSupportBaseRemindersAction : bindActionCreators(searchSupportBaseRemindersAction, dispatch),
    cloneSupportBaseRemindersAction : bindActionCreators(cloneSupportBaseRemindersAction, dispatch),
    deleteSupportBaseRemindersAction : bindActionCreators(deleteSupportBaseRemindersAction, dispatch),
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSupportBaseRemindersForm);
