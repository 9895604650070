import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Update extends Component {

  componentDidMount() {

      localStorage.clear();

      document.location.href = (this.props.selectedCustomer && this.props.selectedCustomer.login_url_admins ? 'https://'+this.props.selectedCustomer.login_url_admins : '') + '?v=' + Date.now(); // fix for not rebuilding correct local storage
  }

  render() {

      return null;
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedCustomer: state.auth.selectedCustomer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
