import api from '../utils/api'

export const getCobeeOptionAction = (name) => dispatch => {

    dispatch({
        type: 'COBEEOPTION_ACTION_FETCH'
    })

    return api('get', '/option/'+name, null, true).then((response) => {
        dispatch({
            type: 'COBEEOPTION_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'COBEEOPTION_ACTION_ERROR',
        })
        return false;
    });
}

export const updateCobeeOptionAction = (name, value) => dispatch => {

    dispatch({
        type: 'COBEEOPTION_ACTION_FETCH'
    })

    return api('post', '/option/'+name, { value: value }, true).then((response) => {
        dispatch({
            type: 'COBEEOPTION_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'COBEEOPTION_ACTION_ERROR',
        })
        return false;
    });
}

export const resetCobeeOptionAction = () => dispatch => {
    dispatch({
        type: 'COBEEOPTION_ACTION_RESET'
    })
}
