import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Radio,
  DatePicker,
  notification,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getComplaintAction, patchComplaintsAction, addComplaintAction, resetComplaintsAction } from '../../../../../actions/complaintsActions';
import { getLibrariesAction } from '../../../../../actions/librariesActions';
import { getProjectAction } from '../../../../../actions/projectActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import PatchComplaintsForm from '../Form/PatchComplaints';
import ComplaintsSteps from '../Steps/ComplaintsSteps';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import { showSuccess } from '../../../../../utils/Notifications';

class ComplaintsDetail extends Component {

    state = {
      imageMediaId: this.props.complaint.image_id || null
    }

    componentDidMount()
    {
        this.props.resetComplaintsAction();

        // this.props.getProjectAction(this.props.match.params.id).then(() => {
        //
        // });

        if(this.props.match.params.complaintid) {
            this.props.getComplaintAction(this.props.match.params.complaintid).then(() => {

            });
        }
    }

    patchComplaints(e, redirect = true) {
      if(e) {
        e.preventDefault();
      }

      this.patchComplaintsform.props.form.validateFields((err, values) => {
        if (!err) {
          if(this.props.match.params.complaintid) {
              this.props.patchComplaintsAction(this.props.match.params.complaintid, values).then(() => {
                if(redirect){ this.props.history.push(`/projects/${this.props.match.params.id}/modules/complaints/${this.props.match.params.complaintid}/addresses/`); }
              });
          } else {
              this.props.addComplaintAction(this.props.match.params.id, values).then((response) => {
                if(redirect){ this.props.history.push(`/projects/${this.props.match.params.id}/modules/complaints/${response.data.complaint.id}/addresses/`); }
                else{ this.props.history.push(`/projects/${this.props.match.params.id}/modules/complaints/${response.data.complaint.id}/detail/`); }
              });
          }

          showSuccess();
        }
      });
    }

    render() {
        return (
          <div>

            <Row className="mbm">
              <Col span={12}>
                <div>

                  { !this.props.project.is_public ?
                  <Button type="new" disabled={!this.props.HaveAbility('complaints', 'write')} onClick={() => this.patchComplaints(null)}>Opslaan en volgende</Button>
                    :
                  <Button type="new" disabled={!this.props.HaveAbility('complaints', 'write')} onClick={() => this.patchComplaints(null, false)}>Opslaan</Button>
                  }

                  <Link to={`/projects/${this.props.match.params.id}/modules/complaints`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
              {/*<Col span={12} align="right">
                <div>
                  <Button disabled type="primary" style={{marginRight:10}}><Icon type="left" /> Vorige</Button>
                  <Button type="primary" onClick={() => this.patchComplaints().then(() => this.props.history.push(`/projects/${this.props.match.params.id}/modules/complaints/${this.props.match.params.complaintid}/addresses/`)) }>Volgende<Icon type="right" /></Button>
                </div>
              </Col>*/}
            </Row>
            <Row className="mbm" style={{ display: !this.props.project.is_public ? 'flex' : 'none'}}>
              <Col span={24}>
                <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                  <ComplaintsSteps current={0} {...this.props}/>
                </Card>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <PatchComplaintsForm key="complaintsform" wrappedComponentRef={formRef => { this.patchComplaintsform = formRef }} {...this.props} />
              </Col>
            </Row>
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.complaints.isFetching,
      isError : state.complaints.isError,
      complaint: state.complaints.complaint,
      library: state.library.libraries,
      project: state.project.project,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getComplaintAction : bindActionCreators(getComplaintAction, dispatch),
      patchComplaintsAction : bindActionCreators(patchComplaintsAction, dispatch),
      getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
      addComplaintAction : bindActionCreators(addComplaintAction, dispatch),
      resetComplaintsAction : bindActionCreators(resetComplaintsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
      getProjectAction : bindActionCreators(getProjectAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsDetail);
