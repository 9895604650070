import React, { Component } from 'react';
// import '../Schedule.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox, DatePicker, Input, Typography } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { getScheduleAction } from '../../../../../actions/scheduleActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';

const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;

class Settings extends Component
{
  state = {
    schedule: {},
    image_id: false,
    filelist: []
  }

  componentDidMount()
  {
    this.props.getScheduleAction(this.props.match.params.id).then(() => {

      this.setState({image_id: this.props.schedule.image_id});
      // this.setState({
      //   schedule: this.props.schedule
      // });

    });
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'} onSubmit={this.props.onSubmit}>

        <Row gutter={24}>
          <Col span={24}>
            <Title level={4}>Werkdagen</Title>
          </Col>
          <Col span={3}>
            <Form.Item>
              {getFieldDecorator('monday_workday', {
                initialValue: this.props.schedule.monday_workday ? true : false,
                valuePropName: 'checked'
              })(
                <Checkbox>
                  Maandag
                </Checkbox>
              )}
            </Form.Item>
          </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('tuesday_workday', {
                    initialValue: this.props.schedule.tuesday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Dinsdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('wednesday_workday', {
                    initialValue: this.props.schedule.wednesday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Woensdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('thursday_workday', {
                    initialValue: this.props.schedule.thursday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Donderdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('friday_workday', {
                    initialValue: this.props.schedule.friday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Vrijdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('saturday_workday', {
                    initialValue: this.props.schedule.saturday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Zaterdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('sunday_workday', {
                    initialValue: this.props.schedule.sunday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Zondag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

        <Row gutter={24}>
          <Col span={12}>

            <Form.Item label="Opmerkingen" hasFeedback>
              {getFieldDecorator('comment', {
                initialValue: this.props.schedule.comment,
              })(
                <TextArea rows={4} />
              )}
            </Form.Item>

          </Col>
          <Col span={12}>
            <Form.Item label="Afbeelding">
              <AddFromMedia
                projectId={this.props.match.params.id}
                initialMediaId={this.props.schedule.image_id}
                initialMediaUrl={this.props.schedule.image_url}
                onMediaSelection={(item) => this.setState({image_id: item.id})}
                onMediaRemove={() => this.setState({image_id: null})}
              />

            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const SettingsPage = Form.create({ name: 'data_form' })(Settings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.schedule.isFetching,
    isError : state.schedule.isError,
    schedule: state.schedule.schedule,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScheduleAction : bindActionCreators(getScheduleAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
