import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Button,
  Drawer,
  Divider,
  Tag,
  Modal,
  Upload,
  Input,
  Avatar,
  Typography,
  Tooltip,
  Alert,
} from 'antd';

class AddCorrespondence extends Component {

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} layout={'vertical'} colon={true}>
        <Row gutter={24} >
          <Col span={24}>
            {this.props.match.params.title && this.props.match.params.type == 'appointment' ? (
              <Alert style={{marginTop: 10}} message={`Correspondentie wordt gekoppeld aan de afspraak: ${decodeURIComponent(this.props.match.params.title)}`} type="info" showIcon />
            ) : null}
            {this.props.match.params.title && this.props.match.params.type == 'choice' ? (
              <Alert style={{marginTop: 10}} message={`Correspondentie hoort bij het keuzeformulier: ${decodeURIComponent(this.props.match.params.title)}`} type="info" showIcon />
            ) : null}
            <Form.Item required hasFeedback label="Onderwerp">
              {getFieldDecorator('subject', {
                initialValue: this.props.correspondence && this.props.correspondence.subject || decodeURIComponent(this.props.match.params.title ? this.props.match.params.title : ''),
                rules: [{ required: true, message: 'Vul een onderwerp in' }],
              })(
                <Input />
              )}
            </Form.Item>

          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'add_correspondence_form' })(AddCorrespondence);
