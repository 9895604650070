import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { exportDossierAction, resetAddressesAction, getAddressesAction, addAddressesAction, deleteAddressesAction, importAddressesAction, searchAddressesAction/*, exportAddressesAction*/, resetPasswordAddressesAction, updateStatusAddressesAction, searchAddressesByZipcodeAction, importAddressesByZipcodeAction, downloadDossier, syncWithWocoAction } from '../../../actions/addressesActions';
import { getGroupsAction } from '../../../actions/groupsActions';
import { getProjectAction } from '../../../actions/projectActions';
import { getCustomerAction } from '../../../actions/customerActions';
// import './ProjectsAddresses.css';
import moment from 'moment';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Upload,
  message,
  Drawer,
  Transfer,
  Tag,
  InputNumber,
} from 'antd';
import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';
import EmailGeneratedLetters from '../Correspondence/Form/EmailGeneratedLetters';
import { showSuccess, showExtraConfirm } from '../../../utils/Notifications';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../constants/settings';
import { NL, GB, DE, FR, ES, AR, TR, SA } from 'country-flag-icons/react/3x2';

// function onChange(pagination, sorter) {
//   //console.log('params', pagination, sorter);
// }

function onChangeZipImport(pagination, sorter) {
  // console.log('params', pagination, sorter);
}

const Search  = Input.Search;

const confirm = Modal.confirm;

function onChangeCheckbox(e) {
  // console.log(`checked = ${e.target.checked}`);
}

const Dragger = Upload.Dragger;

class ProjectsAddresses extends Component {

  state = {
    visible: false,
    // windowHeight: window.innerHeight,
    manualVisible: false,
    importVisible: false,
    zipVisible: false,
    selectedKeys: [],
    disabled: false,
    importFile: [],
    selectedRowKeys: [],
    selectedRowKeysZipImport: [],
    downloadDossier: false,
    address: [],
    filteredInfo: null,
    filter: null
  };

  showConfirm = () => {
    confirm({
      title: 'Wil je deze items verwijderen?',
      content: 'Als je op JA klikt, wordt ook het gehele dossier verwijderd.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.props.deleteAddressesAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {

            // this.setState({ address: this.props.address });

            this.setState({selectedRowKeys: []});

            this.props.getAddressesAction({'projectId': this.props.match.params.id}, null).then(() => {

              // this.props.getGroupsAction({'projectId': this.props.match.params.id});
              //
              // this.props.getCustomerAction(this.props.project.custromer_id);

              this.setState({ address: this.props.address });
            });

            resolve();

            showSuccess('Succesvol verwijderd');

          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  resetPassword = () => {
    confirm({
      title: 'Wachtwoorden resetten?',
      content: 'Als je op JA klikt, worden de geselecteerde wachtwoorden gereset.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.props.resetPasswordAddressesAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {
            this.setState({selectedRowKeys: []});
            resolve();
            showSuccess('Succesvol gereset');
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.addAddressesAction(this.props.match.params.id, values).then(() => {
            this.setState({manualVisible: false});
            this.props.form.resetFields();
            showSuccess('Succesvol opgeslagen');
        });
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  onSelectChangeZipImport = (selectedRowKeysZipImport) => {
    this.setState({ selectedRowKeysZipImport });
  }

  componentDidMount() {
    //this.props.resetAddressesAction();

    // this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => {
    //   this.props.getGroupsAction({'projectId': this.props.match.params.id});
    //   this.props.getProjectAction(this.props.match.params.id).then(() =>{
    //       this.props.getCustomerAction(this.props.project.custromer_id);
    //   });
    //
    //   this.setState({address: this.props.address});
    // });

    this.loadData();

    //window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.handleResize);
  }

  loadData(filter = null)
  {
      this.setState({ filter: filter }, () => {

          this.props.getAddressesAction({'projectId': this.props.match.params.id}, filter).then(() => {

            this.props.getGroupsAction({'projectId': this.props.match.params.id});

            // this.props.getProjectAction(this.props.match.params.id).then(() => {

                this.props.getCustomerAction(this.props.project.custromer_id);
            // });

            this.setState({address: this.props.address});
          });
      });
  }

  // handleResize = (e) => {
  //   // this.setState({windowHeight: window.innerHeight})
  // };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  handleScroll = (direction, e) => {};

  handleDisable = disabled => {
    this.setState({ disabled });
  };

  uploadImportFile = () => {
    this.props.importAddressesAction(this.props.match.params.id, {'excel_sheet': this.state.importFile, 'clean_up': 1}).then(() => {
      this.setState({address: this.props.address});
    });
  }

  onSearch(value) {
    this.setState({selectedRowKeys: []});
    this.props.searchAddressesAction(this.props.match.params.id, {'search': value}, this.state.filter);
  }

  /**
  *
  */
  // exportAddresses()
  // {
  //   //this.props.exportAddressesAction(this.props.match.params.id);
  //   window.open(API_URL + '/addresses/'+ this.props.match.params.id +'/export/xls/?token=' + localStorage.getItem('authToken'), '_blank');
  // }

  /**
  *
  */
  syncWithWoco(status)
  {
    this.props.syncWithWocoAction(this.props.match.params.id).then(() => {

        showSuccess('Succesvol gesynchroniseerd met Woco');
    });
  }

  /**
   *
   */
  updateStatusAddresses(status)
  {
    this.props.updateStatusAddressesAction(this.props.match.params.id,
      {
        status: status,
        ids: this.state.selectedRowKeys
      }).then(() => {
        this.setState({selectedRowKeys: []});
        this.setState({address: this.props.address});
        this.setState({visible: false});
        showSuccess('Succesvol bijgewerkt');
      });
    }

   downloadDossierAsZip()
   {
     this.props.downloadDossier(this.props.match.params.id,
       {
         ids: this.state.selectedRowKeys
       }).then(() => {
         this.setState({selectedRowKeys: []});
         //this.setState({address: this.props.address});
         this.setState({visible: false});
         //showSuccess('Succesvol bijgewerkt');
       });
   }

    /**
    *
    */
    searchAddressesByZipcode(e)
    {
      e.preventDefault();

      this.props.form.validateFields(['zipcode'], (err, values) =>
      {
        if(!err)
        {
          this.props.searchAddressesByZipcodeAction(this.props.match.params.id, values).then(() => {
            this.setState({ addressesbyzip: this.props.addressesbyzip });
          });
        }
      });
    }

    /**
    *
    */
    importAddressesByZipcode()
    {
      this.props.form.validateFields(['zipcode'], (err, values) =>
      {
        // this.props.importAddressesByZipcodeAction(this.props.match.params.id, {zipcode: values.zipcode, row_nrs: this.state.selectedRowKeysZipImport}).then(() => {
        //   this.setState({ address: this.props.address });
        //   this.setState({ selectedRowKeysZipImport: [] });
        //   this.setState({ addressesbyzip: [] });
        //   //this.setState({ importVisible: false });
        //
        //   showSuccess('Succesvol geïmporteerd');
        // });
      });
    }

    addAddress() {
        if(this.props.customer.license_nr_of_addresses > 0 && this.props.customer.nr_of_addresses >= this.props.customer.license_nr_of_addresses)
        {
            showExtraConfirm((confirm) => {
                if(confirm)
                {
                  this.props.history.push('addresses/add');
                }
            }, 'Licentie', 'De limiet van het aantal adressen voor je licentie wordt hiermee overschreden. Extra kosten zullen dan in rekening worden gebracht. Weet je zeker dat je door wilt gaan?')
        }
        else
        {
            this.props.history.push('addresses/add');
        }
    }

    groupsFilter(value, record)
    {
        var all_groups_found = true;

        if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
        {
            this.state.filteredInfo.groups.map((group_name) => {
                var group_found = false;

                record.groups.map((record_group) => {
                    if(record_group.name == group_name)
                    {
                        group_found = true;
                    }
                });

                if(!group_found)
                {
                    all_groups_found = false;
                }
            });
        }

        return all_groups_found;
    }

    render() {
      const SubMenu = Menu.SubMenu;

      let { filteredInfo } = this.state;
      filteredInfo = filteredInfo || {};

      const { targetKeys, selectedKeys, selectedRowKeys, selectedRowKeysZipImport, disabled } = this.state;
      const { getFieldDecorator } = this.props.form;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
        selections: [
          {
            key: 'all-data',
            text: 'Selecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: this.props.address.map(value => value.id),
              });
            },
          },
          {
            key: 'all-data',
            text: 'Deselecteer alles',
            onSelect: () => {
              this.setState({
                selectedRowKeys: [], // 0...45
              });
            },
          },
        ],
      };
      const rowSelectionZipImport = {
        selectedRowKeysZipImport,
        onChange: this.onSelectChangeZipImport,
        hideDefaultSelections: true,
        onSelection: this.onSelectionZipImport,
      };

      const columns = [
      {
        title: 'Adres',
        dataIndex: 'full_address',
        sorter: (a, b) => { return a.full_address.localeCompare(b.full_address)},
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/addresses/${record.id}/general`}>
              {text ? text : '-'}
            </Link>
          </span>
        )
      },
      {
        title: 'Postcode',
        dataIndex: 'zipcode',
        sorter: (a, b) => { return a.zipcode.localeCompare(b.zipcode)},
      },
      {
        title: 'Plaats',
        dataIndex: 'city',
        sorter: (a, b) => { return a.city.localeCompare(b.city)},
      },
      // {
      //   title: 'Contactpersoon',
      //   dataIndex: 'contactperson',
      //   render: (text, record) => {
      //     return (
      //       <div>{record.nr_of_occupants && record.nr_of_occupants > 0 ? record.nr_of_occupants : '-'}</div>
      //     );
      //   }
      // },
      // {
      //   title: 'Eerste keer App gebruikt',
      //   dataIndex: 'first_time_app_used',
      //   render: (text, record) => (
      //       <span>{text ? moment(text, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm') : ''}</span>
      //   )
      // },
      {
        title: 'App',
        dataIndex: 'app_used',
        render: (text, record) => {
          if(text) {
            return text ?
            <CheckCircleOutlined style={{color: 'green'}} />
            :
            <CloseCircleOutlined style={{color: 'red'}} />;
          }

        }
      },
      {
        title: 'Taal',
        dataIndex: 'language',
        render: (language, record) => {
          // NL, EN, DE, FR, ES, AR, TR
          if(language == 'nl'){ return <NL style={{marginTop: 6, width: 24}} title="Nederlands" /> }
          else if(language == 'en'){ return <GB style={{marginTop: 6, width: 24}} title="Engels" /> }
          else if(language == 'de'){ return <DE style={{marginTop: 6, width: 24}} title="Duits" /> }
          else if(language == 'fr'){ return <FR style={{marginTop: 6, width: 24}} title="Frans" /> }
          else if(language == 'es'){ return <ES style={{marginTop: 6, width: 24}} title="Spaans" /> }
          else if(language == 'ar'){ return <SA style={{marginTop: 6, width: 24}} title="Arabisch" /> }
          else if(language == 'tr'){ return <TR style={{marginTop: 6, width: 24}} title="Turks" /> }
        }
      },
      {
        title: 'Push berichten',
        dataIndex: 'has_push_enabled',
        render: (text, record) => {
          if(text) {
            return text ?
            <CheckCircleOutlined style={{color: 'green'}} />
            :
            <CloseCircleOutlined style={{color: 'red'}} />;
          }

        }
      },
      /*{
        title: 'App gedownload',
        dataIndex: 'first_time_app_used',
        render: (text, record) => (
            <span>{text ? moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}</span>
        )
      },*/
      {
        title: 'Eerst ingelogd',
        dataIndex: 'first_logged_in',
        render: (text, record) => (
            <span>{text ? moment(text, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm') : ''}</span>
        )
      },
      {
        title: 'Laatst ingelogd',
        dataIndex: 'last_logged_in',
        render: (text, record) => (
            <span>{text ? moment(text, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm') : ''}</span>
        )
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   render: (text, record) => {
      //     if(text != '0') {
      //       return (
      //         text
      //       );
      //     }
      //   }
      // },
      {
        title: 'Groep',
        dataIndex: 'groups',
        filters: this.props.groups && this.props.groups.map((elem) => {
          return {
            text: elem.name,
            value: elem.name,
          }
        }),
        filterMultiple: true,
        // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
        onFilter: (value, record) => this.groupsFilter(value, record),
        filteredValue: filteredInfo.groups || null,
        render: (text, record) =>
        record.groups && record.groups.map((v) => (
          <Tag color={v.color}>{v.name}</Tag>
        ))
      }
      ];

      const columnsZipImport = [
        {
          title: 'Adres',
          dataIndex: 'address',
          render: (text, record) => (
            <span>
              {record.street + ' ' + record.house_number + (record.house_number_suffix ? ' ' + record.house_number_suffix : '')}
            </span>
          )
        },
        {
          title: 'Plaats',
          dataIndex: 'city'
        },
        {
          title: 'Postcode',
          dataIndex: 'zipcode'
        }
      ];

      return (
        <div>
          <Row className="mbm">
            <Col span={12} >
              <Dropdown disabled={!this.props.HaveAbility('address', 'write')} trigger={['click']} overlay={(
                <Menu>
                  <Menu.Item key="1">
                    <Link onClick={() => { this.addAddress(); }}>
                      Handmatig
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to='addresses/import'>
                      Importeren
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to='addresses/import/zipcode'>
                      Op postcode
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <Link to='addresses/import/map'>
                      Op kaart
                    </Link>
                  </Menu.Item>
                </Menu>
              )}>
                <Button type="new">
                  Toevoegen <DownOutlined />
                </Button>
              </Dropdown>

              <Dropdown trigger={['click']} disabled={(this.state.selectedRowKeys.length > 0 ? false : true) || !this.props.HaveAbility('address', 'write')} overlay={(
                <Menu>
                  <SubMenu title="Status wijzigen">
                    <Menu.Item key="4" onClick={() => this.updateStatusAddresses('')}>Geen status</Menu.Item>
                    <Menu.Item key="1" onClick={() => this.updateStatusAddresses('In voorbereiding')}>In voorbereiding</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.updateStatusAddresses('In uitvoering')}>In uitvoering</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.updateStatusAddresses('Gereed')}>Gereed</Menu.Item>
                  </SubMenu>
                  {/*<Menu.Item key="6">Exporteren</Menu.Item>
                    <Menu.Divider />*/}
                  {/*<Menu.Item onClick={() => this.downloadDossierAsZip()} key="8">Download dossier</Menu.Item>*/}
                  <Menu.Item onClick={this.resetPassword} key="8">Wachtwoorden resetten</Menu.Item>
                  <Menu.Item disabled={!this.props.HaveAbility('address', 'delete')} onClick={this.showConfirm} key="7" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>

                </Menu>
              )}>
                <Button type="secondary">
                  Acties <DownOutlined />
                </Button>
              </Dropdown>

              <div class="mls mrs" style={{ display: 'inline' }}>{ this.state.selectedRowKeys.length } / { this.props.address.length } adressen geselecteerd</div>

              {/*this.props.address.length > 0 && (
                <Button onClick={() => this.exportAddresses()} className="mls">Exporteren</Button>
            )*/}
              {/*this.props.address.length > 0 && (
                <Button onClick={() => this.setState({downloadDossier: true})} className="mls">Alle dossiers exporteren</Button>
            )*/}

              <Button onClick={() => this.syncWithWoco()} className="mls" style={{ display: this.props.project.use_wocoapp ? 'inline' : 'none' }}>Synchroniseren met Woco APP</Button>

            </Col>
            <Col span={12}>

              <Search
                placeholder="Zoeken..."
                allowClear
                onSearch={value => this.onSearch(value)}
                style={{ width: 200, float: 'right' }}
              />

              <Button onClick={() => { this.loadData(this.state.filter == 'old' ? null : 'old'); }} type="primary" className="mls" style={{ float: 'right', marginRight: 10 }}>Toon {this.state.filter != 'old' ? 'vorige' : 'huidige'} bewoners</Button>

            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomTable
                rowKey='id'
                size="middle"
                rowSelection={rowSelection}
                onChange={this.handleTableChange}
                columns={columns}
                dataSource={this.props.isFetching ? [] : this.props.address}
                loading={this.props.isFetching}
                />
            </Col>
          </Row>
        <Modal
          title="Download dossiers"
          visible={this.state.downloadDossier}

          onOk={() => {
            this.emailGeneratedLetters.props.form.validateFields((error, values) => {
                if(!error) {
                  this.props.exportDossierAction(this.props.match.params.id, { 'email': values.email }).then(() => {});
                  this.setState({ downloadDossier: false });
                  this.emailGeneratedLetters.props.form.resetFields();
              }
            });
        }}

        onCancel={() => this.setState({downloadDossier: false})}
        okText={'Versturen'}
      >
        <EmailGeneratedLetters wrappedComponentRef={formRef => { this.emailGeneratedLetters = formRef }} />
          {/*<Form.Item label="Verstuur de download link naar het volgende e-mailadres:">
            <Input value={this.state.emailTo} onChange={(e) => this.setState({emailTo: e.target.value})} />
          </Form.Item>*/}

      </Modal>
      </div>
      );
    }
  }

const ProjectsAddressesForm = Form.create({ name: 'customer_personalize' })(ProjectsAddresses);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.address.isFetching,
    isFetchingZip: state.address.isFetchingZip,
    isError: state.address.isError,
    isErrorZip: state.address.isErrorZip,
    address: state.address.addresses,
    groups: state.group.groups,
    addressesbyzip: state.address.addressesbyzip,
    project: state.project.project,
    customer: state.customer.customer
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    addAddressesAction : bindActionCreators(addAddressesAction, dispatch),
    importAddressesAction : bindActionCreators(importAddressesAction, dispatch),
    deleteAddressesAction : bindActionCreators(deleteAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
    //exportAddressesAction : bindActionCreators(exportAddressesAction, dispatch),
    updateStatusAddressesAction : bindActionCreators(updateStatusAddressesAction, dispatch),
    searchAddressesByZipcodeAction : bindActionCreators(searchAddressesByZipcodeAction, dispatch),
    importAddressesByZipcodeAction : bindActionCreators(importAddressesByZipcodeAction, dispatch),
    resetAddressesAction : bindActionCreators(resetAddressesAction, dispatch),
    downloadDossier : bindActionCreators(downloadDossier, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    syncWithWocoAction : bindActionCreators(syncWithWocoAction, dispatch),
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    resetPasswordAddressesAction : bindActionCreators(resetPasswordAddressesAction, dispatch),
    exportDossierAction : bindActionCreators(exportDossierAction, dispatch),
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesForm);
