export default (state = {
    isError: false,
    isFetching: false,
    queue: [],
    nr_of_new_queued_items: 0,
    nr_of_completed_queued_items: 0
}, action) => {
    switch (action.type) {
    case 'QUEUE_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'QUEUE_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        queue: action.payload
    }
    case 'QUEUE_COUNTER_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        nr_of_new_queued_items: action.payload.nr_of_new_queued_items,
        nr_of_completed_queued_items: action.payload.nr_of_completed_queued_items,
    }
    case 'QUEUE_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    default:
        return state
    }
}
