import api from '../utils/api'

export const getPartnersAction = (projectid) => dispatch => {

    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('get','/partners/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'PARTNERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const searchPartnersAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('post','/partners/'+projectid+'/search', params, true).then((response) => {
        dispatch({
            type: 'PARTNERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const getPartnerAction = (partnerid) => dispatch => {

    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('get','/partner/'+partnerid, null, true).then((response) => {
        dispatch({
            type: 'PARTNER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetPartnerAction = (partnerid) => dispatch => {

    dispatch({
        type: 'RESET_PARTNER_ACTION_FETCH'
    })
}

export const addPartnerAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'PARTNER_ACTION_FETCH'
    })

    return api('post','/partners/'+projectid, params, true).then((response) => {
        dispatch({
            type: 'ADD_PARTNER_ACTION_SUCCESS',
            payload: response.data.partner
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNER_ACTION_ERROR',
        })
        return false;
    });
}

export const getPartnerLibraryAction = (partnerid, params) => (dispatch, getState) => {

    dispatch({
        type: 'PARTNER_ACTION_FETCH'
    })

    return api('get','/library/partner/'+partnerid, params, true).then((response) => {
        dispatch({
          type: 'GET_PARTNER_LIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNER_ACTION_ERROR',
        })
        return false;
    });
}

export const addPartnerLibraryAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'PARTNER_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/partners/'+customer_id, params, true).then((response) => {
        dispatch({
            type: 'ADD_PARTNER_LIBRARY_ACTION_SUCCESS',
            payload: response.data.partner
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNER_ACTION_ERROR',
        })
        return false;
    });
}

export const patchPartnerLibraryAction = (partnerid, params) => (dispatch, getState) => {

    dispatch({
        type: 'PARTNER_ACTION_FETCH'
    })

    return api('post','/library/partner/'+partnerid, params, true).then((response) => {
        dispatch({
            type: 'PATCH_PARTNER_LIBRARY_ACTION_SUCCESS',
            payload: response.data.partner
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNER_ACTION_ERROR',
        })
        return false;
    });
}

export const patchPartnerAction = (partnerid, params, file) => dispatch => {
    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('post','/partner/'+partnerid, params, true, file).then((response) => {
        dispatch({
            type: 'PARTNER_ACTION_SUCCESS',
            payload: response.data.partner
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}

export const removePartnersAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'PARTNERS_ACTION_REMOVE_FETCH'
    })

    return api('post','/partners/'+projectid+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'PARTNERS_ACTION_REMOVE_SUCCESS',
            // payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_REMOVE_ERROR',
        })
        return false;
    });
}

export const updatePartnersOrderAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'PARTNERS_ACTION_FETCH'
    })

    return api('post','/partners/order/'+projectid, params, true).then((response) => {
        dispatch({
            type: 'SORT_PARTNERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'PARTNERS_ACTION_ERROR',
        })
        return false;
    });
}
