export default (state = {isError: false, isFetching: false, delivery_users: [], delivery_user: {email_address: ''}, globalDeliveryUsers: [], globalDeliveryUser: {}}, action) => {
    switch (action.type) {
    case 'DELIVERYUSERS_ACTION_FETCH':
    return {
        ...state,
        delivery_users: [],
        isFetching: true,
        isError: false,
    }
    case 'DELIVERYUSER_ACTION_FETCH':
    return {
        ...state,
        delivery_user: { email_address: '' },
        isFetching: true,
        isError: false,
    }
    case 'DELIVERYUSERS_ACTION_SUCCESS':
    return {
        ...state,
        delivery_users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'GLOBAL_DELIVERYUSERS_ACTION_SUCCESS':
    return {
        ...state,
        globalDeliveryUsers: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SORT_DELIVERYUSERS_ACTION_SUCCESS':
    return {
        ...state,
        //delivery_users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'DELIVERYUSER_ACTION_SUCCESS':
    return {
        ...state,
        delivery_user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CLONE_DELIVERYUSER_ACTION_SUCCESS':
    return {
        ...state,
        delivery_users: [action.payload, ...state.delivery_users],
        isFetching: false,
        isError: false,
    }
    case 'GET_DELIVERYUSER_LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        delivery_user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_DELIVERYUSER_ACTION_SUCCESS':
    return {
        ...state,
        delivery_users: [action.payload, ...state.delivery_users],
        delivery_user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_DELIVERYUSER_ACTION_SUCCESS':
    return {
        ...state,
        delivery_users: state.delivery_users.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_DELIVERYUSERS_LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        delivery_users: state.delivery_users.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'RESET_DELIVERYUSERS_ACTION_FETCH':
    return {
        ...state,
        delivery_users: [],
        isFetching: false,
        isError: false,
    }
    case 'RESET_DELIVERYUSER_ACTION_FETCH':
    return {
        ...state,
        delivery_user: {},
        isFetching: false,
        isError: false,
    }
    case 'SORT_DELIVERYUSERS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'DELIVERYUSERS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
