import api from '../utils/api'

export const getConversationsAction = (projectid) => dispatch => {

    dispatch({
        type: 'CONVERSATIONS_ACTION_FETCH'
    })

    return api('get','/chat/' + projectid, null, true).then((response) => {
        dispatch({
            type: 'CONVERSATIONS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONVERSATIONS_ACTION_ERROR',
        })
        return false;
    });
}

export const showConversationAction = (item) => dispatch => {

    dispatch({
        type: 'CONVERSATION_ACTION_FETCH'
    })

    var url = '/chat';

    if(item.from_address_id || item.to_address_id)
    {
        url += '/address/' + (item.from_address_id ? item.from_address_id : item.to_address_id);
    }
    else if(item.from_unique)
    {
        url += '/unique/' + item.from_unique;
    }

    return api('get', url, null, true).then((response) => {
        dispatch({
            type: 'CONVERSATION_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CONVERSATION_ACTION_ERROR',
        })
        return false;
    });
}

export const storeConversationMessageAction = (projectid, params) => (dispatch, getState) => {

    dispatch({
        type: 'CONVERSATIONS_ACTION_FETCH'
    });

    params = { "from_user_id": getState().auth.user.user.id, ...params }

    return api('POST','/chat/' + projectid, params, true).then((response) => {
        dispatch({
            type: 'ADD_CONVERSATIONS_ACTION_SUCCESS',
            payload: response.data.chatmessage
        });
    }).catch((e) => {
        dispatch({
            type: 'CONVERSATIONS_ACTION_ERROR',
        })
        return false;
    });
}
