import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Alert,
  Steps,
  Slider,
  PageHeader,
  Tag,
  Table,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess } from '../../../../../utils/Notifications';
import PatchAppointmentForm from '../Form/PatchAppointment';
import ClusterData from '../Component/ClusterData';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import _ from 'underscore';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';

class AppointmentData extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clusters: [],
      activeDays: [],
      selectedSlots: [],
      loaded: false,
    };
  }

  saveClusters = () => {
    this.props.applyAppointmentScheduleAction(this.props.match.params.appointmentid, {'slots':this.state.selectedSlots}).then(() => {
      showSuccess();
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/overview/`);
    });
  }

  componentDidMount() {

    // this.props.resetAppointmentAction();

    this.props.getAppointmentScheduleAction(this.props.match.params.appointmentid).then(() => {

      this.setState({ selectedSlots: this.props.cluster_timeslots }, () => {

          this.setupDays();
      });

    });
  }

  setupDays = () => {
    var activedaysArray = [];

    if(this.props.work_days.monday) { activedaysArray.push(1);}
    if(this.props.work_days.tuesday) { activedaysArray.push(2);}
    if(this.props.work_days.wednesday) { activedaysArray.push(3);}
    if(this.props.work_days.thursday) { activedaysArray.push(4);}
    if(this.props.work_days.friday) { activedaysArray.push(5);}
    if(this.props.work_days.saturday) { activedaysArray.push(6);}
    if(this.props.work_days.sunday) { activedaysArray.push(7);}

    this.setState({activeDays: activedaysArray}, () => {

        this.setState({ loaded: true });
    })
  }

  updateSelectedSlots(slot) {

    var filteredSlot = this.state.selectedSlots.filter((elem) => {
      if(slot.id == elem.id && slot.date == elem.date) return false;
      return true;
    });

    // var filteredSlot = this.state.selectedSlots.map((elem) => {
    //
    //   if(elem.id != slot.id) {
    //     return elem;
    //   }
    // });

    if(slot.timeslots.length == 0)  {
      this.setState({selectedSlots: [...filteredSlot]}, () => {
      });
    } else {
      this.setState({selectedSlots: [...filteredSlot, slot]}, () => {
      });
    }
  }

  render() {

    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => this.saveClusters()}>Opslaan en volgende</Button>
              <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="mbm">
          <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
            <AppointmentSteps current={2} {...this.props}/>
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            { this.state.loaded ? <Card>
              {!this.state.isFetching && this.props.clusters.length == 0 ? (
                <Alert showIcon message={'Voeg minimaal 1 cluster toe'}></Alert>
              ) : null}
              <Row gutter={24}>
                {this.props.clusters.map((element, index) => {

                  var addressCount = this.props.cluster_addresses.filter((address) => address.cluster_id == element.id);
                  var timeslots = this.state.selectedSlots.filter((slots) => slots.id == element.id);

                  var timeslotCount = 0;

                  timeslots.map((slot) => {
                    // timeslotCount = timeslotCount + slot.timeslots.length; // old

                    if(this.props.appointment && this.props.appointment.slot == 'range')
                    {
                        slot.timeslots.map((subslot) => {

                            var selected_daypart = subslot.substr(11);

                            this.props.dayparts.map((obj) => {
                                var daypart = obj.name + ' (' + obj.from_time.substr(0, 5) + ' - ' + obj.till_time.substr(0, 5) + ')';

                                if(daypart == selected_daypart)
                                {
                                    timeslotCount = timeslotCount + obj.max_nr_of_appointments;
                                }
                            });

                            // timeslotCount = timeslotCount + 1;
                        });
                    }
                    else
                    {
                        slot.timeslots.map((subslot) => {
                            timeslotCount = timeslotCount + 1;
                        });
                    }

                  });

                  return (
                    <Col key={index} span={24}>
                      <ClusterData
                        name={element.name}
                        timeslotCount={timeslotCount}
                        addresses={addressCount}
                        clusterid={element.id}
                        index={index}
                        firstDay={this.props.first_date}
                        onSelectSlot={(slot) => this.updateSelectedSlots(slot)}
                        availableTimes={this.props.available_times}
                        disabledTimeSlots={this.state.selectedSlots}
                        activeDays={this.state.activeDays}
                        />
                    </Col>
                  );
                })}
              </Row>
            </Card> : null }
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    appointment: state.appointment.appointment,
    clusters: state.appointment.clusters,
    work_days: state.appointment.work_days,
    cluster_addresses: state.appointment.cluster_addresses,
    first_date: state.appointment.first_date,
    cluster_timeslots: state.appointment.cluster_timeslots,
    available_times: state.appointment.available_times,
    addresses: state.address.addresses,
    groups: state.group.groups,
    dayparts: state.appointment.dayparts,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentData);
