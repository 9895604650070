import React, { Component } from 'react';
import { MinusOutlined, PlusOutlined, ScissorOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select, Card, Button, message, Upload, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMediaFileAction, addMediaFileAction, addMediaProjectFileAction, patchMediaFileAction, resetMediaFileAction, getMediaCategoriesAction } from '../../../../actions/medialibraryActions';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AddFromMedia from '../../../../components/AddFromMedia/AddFromMedia';
import getCroppedImg from '../../../../utils/cropImage';
import ProjectMediaSideMenu from '../ProjectMediaSideMenu';
import Compressor from 'compressorjs';

class ProjectMediaDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false,
      selected: [],
      text: '',
      tags: [],
      filelist: [],
      crop: { x: 0, y: 0 },
      zoom: 1,
      visible: false,
      croppedAreaPixels: null,
      croppedImage: null,
      cropVisible: false,
      tempImage: null,
      tempImageName: ''
    };
  }

  componentDidMount()
  {
    this.myRef = React.createRef();

    this.props.resetMediaFileAction();

    if(this.props.match.params.id)
    {
        this.props.getMediaCategoriesAction().then(() => {

            this.props.getMediaFileAction(this.props.match.params.media_id);
        });
    }
  }

  getCategoryOptions()
  {
      var categories = [];

      this.props.mediacategories && this.props.mediacategories.map((category, index) => {

          categories.push({
              label: ''+category.name,
              value: ''+category.id
          });
      });

      return categories;
  }

  getSelectedCategoryOptions()
  {
      var categories = [];

      this.props.mediafile && this.props.mediafile.category_ids && this.props.mediafile.category_ids.map((category_id, index) => {

          this.props.mediacategories && this.props.mediacategories.map((category, index) => {

              if(category.id == category_id)
              {
                  // categories.push(''+category.name);
                  categories.push(''+category.id);
              }
          });

      });

      return categories;
  }

  dataURLtoFile(dataurl, filename)
  {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  handleCroppedMediaUpload = (file) => {

    // old
    // file = file.getCroppedCanvas().toDataURL();
    //
    // file = this.dataURLtoFile(file, this.state.tempImageName);

    if(this.props.mediafile.project_id) {
      this.props.addMediaProjectFileAction(this.props.mediafile.project_id, null, {'image': file}).then((response) => {
        this.setState({visible: false})
      });
    } else {
      this.props.addMediaFileAction(null, {'image': file}).then((response) => {
        this.setState({visible: false})
      });
    }

    this.props.history.push(`/media`);

    showSuccess('Afbeelding bijgesneden.');
  }

  async getCroppedImage() {

      var cropper = this.myRef.current.cropper;

      var croppedImage = cropper.getCroppedCanvas().toDataURL();

      croppedImage = this.dataURLtoFile(croppedImage, this.state.tempImageName);

      var this_obj = this;

      new Compressor(croppedImage, {

        quality: 0.8,

        success(compressedImage)
        {
          this_obj.handleCroppedMediaUpload(compressedImage);

          this_obj.setState({ cropVisible: false, tempImageName: '', tempImage: '', croppedImage: '' });

          // this_obj.setState({ croppedImage: compressedImage });
        },
        error(err) {

          console.log('getCroppedImage', 'Compressor err:', err.message);
        },
      });

      // this.handleCroppedMediaUpload(this.state.croppedImage);
      // this.setState({cropVisible: false, tempImageName: '', tempImage: '', croppedImage: ''})
      // this.setState({croppedImage: this.state.croppedImage});
  }

  onCrop = () => {

      const imageElement = this.myRef.current;
      const cropper = imageElement.cropper;

      this.setState({croppedImage: cropper});
  };

  handleSubmit = (e, values) => {

    if (e) {
      e.preventDefault();
    }

    this.props.form.validateFields((err, values) => {

      if (!err)
      {
        if (this.props.match.params.media_id)
        {
          this.props.patchMediaFileAction(this.props.match.params.media_id, values, this.state.filelist).then(() => {
            window.history.back();
          });
        }
        else
        {
          this.props.addMediaFileAction(values, this.state.filelist).then(() => {
            window.history.back();
          });
        }

        showSuccess();
      }
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <ProjectMediaSideMenu id={this.props.match.params.id} activeMenu='1' />

            <div style={{padding: 24, width: '100%'}}>

              <Row className="mbm">
                <Col span={12} >
                  <Button disabled={!this.props.HaveAbility('media' ,'write')} type="new" onClick={this.handleSubmit}>
                    Opslaan
                  </Button>
                  <Button onClick={() => this.props.history.push('/projects/' + this.props.match.params.id + '/media')} style={{marginRight: 10}}>Sluiten</Button>
                </Col>
              </Row>

              <Card>

                <Form layout={'vertical'} onSubmit={this.handleSubmit}>

                    <Row gutter={24}>

                        <Col span={24}>
                          <Form.Item required label="Naam">
                            {getFieldDecorator('name', {
                              initialValue: this.props.mediafile ? this.props.mediafile.name : '',
                              rules: [{ required: true, message: 'Vul een naam in' }],
                            })(
                              <Input disabled={!this.props.HaveAbility('media' ,'write')} placeholder="Naam" />
                            )}
                          </Form.Item>
                        </Col>

                        {<Col span={24}>
                            <Form.Item hasFeedback label="Categorieën">
                              {getFieldDecorator('categories', {
                                  // initialValue: this.props.mediafile && this.props.mediafile.category_ids ? this.props.mediafile.category_ids : [],
                                  initialValue: this.getSelectedCategoryOptions(),
                                  validateTrigger: ["onChange"]
                              })(
                                <Select
                                  mode="multiple"
                                  style={{ width: '100%' }}
                                  placeholder="Geef één of meerdere categorieën op"
                                  >
                                { this.getCategoryOptions().map((v, k) => (
                                    <Select.Option key={String(v.value)}>{v.label}</Select.Option>
                                )) }
                                </Select>
                              )}
                            </Form.Item>
                        </Col>}

                        <Col span={24}>

                          {this.props.mediafile && this.props.mediafile.media && this.props.mediafile.original_image ? (
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                              <img width={400} src={this.props.mediafile ? this.props.mediafile.original_image : ''} />
                              <div>
                                <Button type="primary" icon={<ScissorOutlined />} style={{ marginTop: 16 }} onClick={() => {
                                  this.setState({
                                    cropVisible: true,
                                    tempImageName: this.props.mediafile ? this.props.mediafile.name : '',
                                    tempImage: this.props.mediafile ? this.props.mediafile.original_image : '',
                                  })
                                }}>Bijsnijden</Button>
                              </div>
                            </div>
                            ) : (
                            <Form.Item label="Bestand">
                              <Upload accept="image/jpg,image/jpeg,image/png" beforeUpload={(file) => {
                                  this.setState({filelist: {'image': file}});
                                  return false;
                                }} onRemove={() =>{
                                  this.setState({filelist: null});
                                }}>
                                <Button type="dashed"><UploadOutlined /> Upload een afbeelding</Button>
                              </Upload>
                            </Form.Item>
                          )}
                        </Col>

                    </Row>

                </Form>

            </Card>

            <Modal
              title="Bijsnijden"
              width={800}
              okText="Opslaan"
              visible={this.state.cropVisible}
              onOk={() => {this.getCroppedImage()}}
              onCancel={() => this.setState({cropVisible: false})}>
              <div>
                <div style={{marginBottom: '10px'}}>
                  <p style={{marginBottom: 0}}>Bestandsnaam:</p>
                  <Input value={this.state.tempImageName} placeholder={'Bestandsnaam'} onChange={(text) => this.setState({tempImageName: text.target.value})} />
                </div>
                <div style={{position: 'relative', width: '100%'}}>
                  <Cropper
                    src={this.state.tempImage}
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={4 / 3}
                    guides={true}
                    crop={this.onCrop}
                    dragMode='move'
                    ref={this.myRef}
                    />
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                  <Button style={{marginRight: 5}} type="default" icon={<MinusOutlined />} shape="circle" onClick={() => {
                    const imageElement = this.myRef.current;
                    const cropper = imageElement.cropper;
                    cropper.zoom(-0.1);
                  }}></Button>
                  <Button style={{marginleft: 5}} type="default" shape="circle" icon={<PlusOutlined />} onClick={() => {
                    const imageElement = this.myRef.current;
                    const cropper = imageElement.cropper;
                    cropper.zoom(0.1);
                  }}></Button>
                </div>
                </div>
                {/*<Slider tooltipVisible={false} value={this.state.zoom} defaultValue={1} onChange={(zoom) => this.setState({zoom: zoom})} min={0.4} max={3} step={0.1} />*/}
              </div>
            </Modal>

          </div>

        </div>

      </div>
    );
  }
}

const ProjectMediaDetailForm = Form.create({ name: 'horizontal_login' })(ProjectMediaDetail);

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.medialibrary.isFetching,
      isError : state.medialibrary.isError,
      mediafile: state.medialibrary.mediafile,
      mediacategories: state.medialibrary.categories,
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    getMediaFileAction : bindActionCreators(getMediaFileAction, dispatch),
    addMediaFileAction : bindActionCreators(addMediaFileAction, dispatch),
    addMediaProjectFileAction : bindActionCreators(addMediaProjectFileAction, dispatch),
    patchMediaFileAction : bindActionCreators(patchMediaFileAction, dispatch),
    resetMediaFileAction : bindActionCreators(resetMediaFileAction, dispatch),
    getMediaCategoriesAction : bindActionCreators(getMediaCategoriesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMediaDetailForm);
