import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Select, Menu, Tag } from 'antd';
import { bindActionCreators } from 'redux';
import { getDeliveryUsersLibraryAction, duplicateDeliveryUserLibraryAction } from '../../../actions/deliveryusersActions'
import { connect } from 'react-redux';

class CloneDeliveryUser extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      selectedId: null,
    };
  }

  cloneDeliveryUser = () => {
    if(this.state.selectedId) {
      this.props.duplicateDeliveryUserLibraryAction(this.props.match.params.id, this.state.selectedId).then((response) => {
        this.props.onClone(response);
        this.setState({selectedId: null, modalVisible: false});
      });
    }
  }

  openModal = () => {
    this.setState({modalVisible: true});
    this.props.getDeliveryUsersLibraryAction();
  }

  render () {
    return (
      <span>
        {this.props.plain ? (
          <span style={{display:'block'}}onClick={this.openModal}>
            {this.props.buttonLabel ? this.props.buttonLabel : 'Accounts'}
          </span>
        ) : (
          <Button onClick={this.openModal}>Selecteer uit bibliotheek</Button>
        )}

        <Modal
          title='Accounts'
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible:false, selectedId: null })}
          onOk={() => this.cloneDeliveryUser()}
          okText="Selecteer"
        >
          <Form layout={'vertical'}>
            <Form.Item required hasFeedback label="verantwoordelijke">
              <Select showSearch value={this.state.selectedId} placeholder={'Selecteer verantwoordelijke'} optionFilterProp="children" onChange={(selectedId) => this.setState({selectedId})}>
                {this.props.delivery_users.map((element) => (
                  <Select.Option value={element.id}>{element.email_address}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    delivery_users: state.delivery_users.delivery_users,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryUsersLibraryAction: bindActionCreators(getDeliveryUsersLibraryAction, dispatch),
    duplicateDeliveryUserLibraryAction: bindActionCreators(duplicateDeliveryUserLibraryAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneDeliveryUser);
