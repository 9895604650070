import React, { Component } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Tag, Row, Col, message, Drawer } from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPartnersAction, addPartnerAction, removePartnersAction, searchPartnersAction } from '../../actions/globalpartnersActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import CustomTable from '../../components/CustomTable/CustomTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import LibrarySideMenu from './LibrarySideMenu';


class GlobalPartners extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    this.props.getPartnersAction();
  }

  onSelectChange = (selectedRowKeys) => { this.setState({ selectedRowKeys });}

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.removePartnersAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.props.getPartnersAction();
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  render() {

    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };

    const columns = [{
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      render: (text, record) => (
        <span>
          <Link to={`/library/partner/${record.id}/detail`}>
            {record.name}
          </Link>
        </span>
      )
    }, {
      title: 'Plaats',
      dataIndex: 'city',
      sorter: (a, b) => { return a.city.localeCompare(b.city)},
      render: (text, record) => (
        <span>{text}</span>
      ),
    }];

    return (
      <div>

        <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Partners'}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <LibrarySideMenu id={this.props.match.params.id} activeMenu='2' />

          <div style={{padding: 24, width: '100%'}}>
            <Row className="mbm">
              <Col span={12} >
                <Button disabled={!this.props.HaveAbility('library', 'write')} onClick={() => this.props.history.push('/library/partner/add')} icon={<PlusOutlined />} type="primary ant-btn-new">
                  Toevoegen
                </Button>
                <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('library', 'delete') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                    <Menu>
                      <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('library' ,'delete')} style={{ color: this.props.HaveAbility('library', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                    </Menu>
                  )}>
                  <Button type="secondary">
                    Acties <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col span={12}>
                <Input.Search
                  allowClear
                  placeholder="Zoeken..."
                  onSearch={ value => {
                    if(value != '') {
                      this.props.searchPartnersAction(value);
                    } else {
                      this.props.getPartnersAction();
                    }

                  } }
                  style={{ width: 200, float: 'right' }}
                  />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomTable
                  size="middle"
                  rowKey="id"
                  rowSelection={rowSelection}
                  //onChange={() => console.log('test')}
                  columns={columns}
                  loading={false}
                  dataSource={this.props.partners}
                  />
              </Col>
            </Row>
          </div>

        </div>

      </div>
    );
  }
}

const PartnerForm = Form.create({ name: 'horizontal_login' })(GlobalPartners);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.partner.isFetching,
    isError : state.partner.isError,
    partners : state.partner.globalPartners,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPartnersAction : bindActionCreators(getPartnersAction, dispatch),
    removePartnersAction : bindActionCreators(removePartnersAction, dispatch),
    searchPartnersAction : bindActionCreators(searchPartnersAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm);
