import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { EuroOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { Menu, Tag } from 'antd';
import { Link } from "react-router-dom";
import { HaveAbility } from '../../utils/RolesAndAbilities';

class AdministrationSideMenu extends Component {
   render() {
       return (
           <Menu
               mode="inline"
               style={{ width: 300}}
               defaultSelectedKeys={[this.props.activeMenu]}
               >
               <Menu.Item key={'1'}>
                   <Link to={`/administration`}>
                       <EuroOutlined />
                       <span>Transacties</span>
                   </Link>
               </Menu.Item>

               <Menu.Item key={'2'}>
                   <Link to={`/administration/choice-module`}>
                       <ReconciliationOutlined />
                       <span>Keuze</span>
                   </Link>
               </Menu.Item>

               { this.props.HaveAbility('price-calculator', 'read') ? <Menu.Item key={'3'}>
                   <Link to={`/administration/price-calculator`}>
                       <ReconciliationOutlined />
                       <span>Prijzen calculator</span>
                   </Link>
               </Menu.Item> : null }

           </Menu>
       );
   }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationSideMenu);
