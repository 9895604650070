export default (state = {isError: false, isFetching: false, occupants: [], occupant:{}}, action) => {
    switch (action.type) {
    case 'OCCUPANTS_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'OCCUPANTS_ACTION_SUCCESS':
    return {
        ...state,
        occupants: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'OCCUPANT_ACTION_SUCCESS':
    return {
        ...state,
        occupant: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_OCCUPANTS_ACTION_SUCCESS':
    return {
        ...state,
        occupants: [action.payload, ...state.occupants],
        isFetching: false,
        isError: false,
    }
    case 'PATCH_OCCUPANTS_ACTION_SUCCESS':
    return {
        ...state,
        occupant: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_OCCUPANTS_ACTION':
    return {
        ...state,
        occupant: {},
        isFetching: false,
        isError: false,
    }
    case 'OCCUPANT_REMOVE_SUCCESS':
    return {
        ...state,
        occupants: state.occupants.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'OCCUPANTS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
