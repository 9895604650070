import React from 'react'
import PropTypes from 'prop-types'

import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  Collapse,
  Tooltip,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
} from 'antd';
import CustomTable from '../../../CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Media from "react-media";

const { Option } = Select;

const ButtonGroup = Button.Group;

class FDeliveryPointsInputForm extends React.Component {

  state = {
      status: this.props.formData && this.props.formData.status ? this.props.formData.status : ['open']
  }

  getAddressFieldOptions()
  {
      var options = [];

      if(this.props.addressFields)
      {
          for(var af = 0; af < this.props.addressFields.length; af++)
          {
              var address_field = this.props.addressFields[af];

              options.push(<Option value={address_field.id}>{address_field.name}</Option>);
          }
      }

      return options;
  }

  updateStatus = (status) =>
  {
      this.setState({ status: status });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>

        <Form.Item hasFeedback required label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
            onChange: (event) => { this.props.form.setFieldsValue({ label: event.target.value }); this.props.saveFieldValues(true); }
          })(
            <Input disabled={this.props.disabled} />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Status
            <Tooltip className="mhs" title='Selecteer de status(sen) van de opleverpunten die opgenomen moeten worden in het opleverformulier.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('status', {
            initialValue: this.props.formData && this.props.formData.status ? this.props.formData.status : this.state.status,
          })(
            <Checkbox.Group
                options={[
                    { label: 'Open', value: 'open'},
                    { label: 'Controleren', value: 'submitted'},
                    { label: 'Afgehandeld', value: 'completed'}
                ]}
                disabled={this.props.disabled}
                onChange={this.updateStatus}
                />
          )}
        </Form.Item>

      </Form>
    );
  }
}

const WrappedFDeliveryPointsInputForm = Form.create({ name: 'text_input_form' })(FDeliveryPointsInputForm);

class FDeliveryPointsInput extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {},
      hide_for_occupant: ((this.props.parseData && this.props.parseData.hide_for_occupant != undefined && this.props.parseData.hide_for_occupant != false)) ? true : false,
      requiredField: ((this.props.parseData && this.props.parseData.requiredField != undefined && this.props.parseData.requiredField != false)) ? true : false,
      listField: ((this.props.parseData && this.props.parseData.listField != undefined && this.props.parseData.listField != false) || !this.props.parseData) ? true : false,
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = (event) => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  saveFieldValues = (silent = false) => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          values.hide_for_occupant = this.state.hide_for_occupant;
          values.requiredField = this.state.requiredField;
          values.listField = this.state.listField;

          if(silent)
          {
              this.setState({formData: values});
          }
          else
          {
              this.setState({formData: values, openKeys: []});
          }

          this.props.onSaveData(this.props.id, values, silent);
        }
      })
    }
  }

  saveField = () => {
      var values = this.state.formData;
      values.hide_for_occupant = this.state.hide_for_occupant;
      values.requiredField = this.state.requiredField;
      values.listField = this.state.listField;

      this.setState({formData: values}); // , openKeys: []});

      this.props.onSaveData(this.props.id, values);
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  toggleOption = (option) => {

      var checked = (this.state[option] == true ? false : true);

      this.setState({ [option]: checked }, () => {

          this.saveField();
      });
  };

  renderExtraButtons = () => {
    return (
      <div
          onClick={(event) => event.stopPropagation()}
          style={{ display: 'flex', marginTop: -1, marginRight: -13 }}
          >

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Vraag verplicht">
                  <Button disabled={true}
                      size="small"
                      onClick={(event) => {

                          event.stopPropagation();

                          this.toggleOption('requiredField');
                          // this.setState({ requiredField: event.target.checked }, function(){
                          //     this.saveField();
                          // });
                      }}
                      style={{ backgroundColor: '#ffffff', color: '#AAAAAA' }}
                      >
                      <CheckOutlined onClick={(event) => { /*event.stopPropagation();*/ }} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

          <ButtonGroup style={{ marginRight: 8 }}>

              <Tooltip title="Element verwijderen">
                  <Popconfirm
                    icon={(<QuestionCircleOutlined />)}
                    placement="bottom"
                    onCancel={(event) => event.stopPropagation()}
                    size="small"
                    okType="danger"
                    title={"Element verwijderen"}
                    onConfirm={() => this.removeElement()}
                    okText="Ja"
                    cancelText="Nee"
                    disabled={this.props.disabled}
                  >
                      <Button disabled={this.props.disabled}
                          size="small">
                          <DeleteOutlined
                            onClick={event => {
                                // event.stopPropagation();
                            }} />
                      </Button>
                  </Popconfirm>
              </Tooltip>

              <Tooltip title="Element dupliceren">
                  <Button disabled={this.props.disabled}
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.duplicateElement(this.props.id);
                      }}
                      >
                      <PlusSquareOutlined style={{marginLeft: 0}} />
                  </Button>
              </Tooltip>

          </ButtonGroup>

      </div>
    );
  };

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (
        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
        <Collapse.Panel
          key={this.props.id}
          header={(
              <div
                  style={{
                      display: 'inline-block',
                      height: 20,
                      width: 'auto',
                      // maxWidth: '67%',
                      // whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                  }}>
                  <b>Opleverpunten</b> - {this.state.formData && this.state.formData.label || 'label'}
              </div>
          )}
          extra={this.renderExtraButtons()}
          >
            <div style={{padding: 15}}>
              <WrappedFDeliveryPointsInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} addressFields={this.props.addressFields} saveFieldValues={this.saveFieldValues} disabled={this.props.disabled} />
              <Button disabled={this.props.disabled} onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>
      );
    } else {
      return (
        <div style={{backgroundColor: '#FAFAFA', display: 'flex', flex: 1, height: 50, border: '1px solid #D9D9D9', alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 10, borderRadius: 6 }}>
          Overleverpunten
        </div>
      );
    }
  }
}

export default FDeliveryPointsInput;
