import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Transfer, Card, Button, message } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getGroupAction, addGroupsAction, syncAddressesGroupAction, patchGroupsAction, resetGroupAction } from '../../../../actions/groupsActions';
import { getAddressesAction } from '../../../../actions/addressesActions';
import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { Link } from 'react-router-dom';
import { SketchPicker, ChromePicker, TwitterPicker } from 'react-color';

class GroupDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false,
      targetKeys: [],
      text: '',
      tags: [],
      color: '#cccccc',
      showcolor: false,
    };
  }

  componentDidMount() {
    this.props.resetGroupAction();

    if(this.props.match.params.id) {
      if(this.props.match.params.groupid) {
        this.props.getGroupAction(this.props.match.params.groupid).then(() => {
          this.setState({'targetKeys': this.props.group.address_ids, 'color': this.props.group.color ? this.props.group.color : '#cccccc'});
        });
      }
    }
  }

  handleSubmit = (e, values) => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.color = this.state.color;
        if(this.props.match.params.groupid) {
          this.props.patchGroupsAction(this.props.match.params.groupid, {...values, 'addresses':this.state.targetKeys }).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/groups`);
          });
        } else {
          this.props.addGroupsAction(this.props.match.params.id, {...values, 'addresses':this.state.targetKeys }).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/groups`);
          });
        }
        showSuccess();
      }
    });
  };


  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Button disabled={!this.props.HaveAbility('address', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
              <Link to={`/projects/${this.props.match.params.id}/groups`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Card>
        <Form layout={'vertical'} colon={true} onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item required label="Naam groep" hasFeedback>
                  {getFieldDecorator('name', {
                    initialValue: this.props.group && this.props.group.name,
                    rules: [{ required: true, message: 'Vul een naam in' }],
                  })(
                    <Input />
                  )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Omschrijving" hasFeedback>
                {getFieldDecorator('description', {
                  initialValue: this.props.group && this.props.group.description,
                  rules: [{ required: false, message: 'Vul een omschrijving in' }],
                })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Kleur" hasFeedback>
                    {getFieldDecorator('color', {
                        initialValue: this.state.color,
                        rules: [{ required: true, message: 'Geef een kleur op' }],
                      })(
                          <Input prefix={(<span style={{marginLeft: -3, paddingRight: 5, width:15, height: 15, background: this.state.color, borderRadius: 3}}></span>)} onFocus={() => this.setState({showcolor: true})} onBlur={() => this.setState({showcolor: true})}></Input>
                        )}

                        {this.state.showcolor && (
                          <div style={{position: 'absolute', zIndex: '2'}}>
                            <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}} onClick={() => this.setState({showcolor: false})} />
                              <TwitterPicker colors={['#f5222d', '#fa8c16', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#1890ff', '#2f54eb', '#722ed1', '#eb2f96']} style={{position: 'absolute'}} color={this.state.color} onChangeComplete={(color) => {
                                this.setState({color: color.hex});
                                //this.props.form.setFieldsValue({'color': color});
                              }} />
                          </div>
                        )}
                  </Form.Item>
                </Col>
              <Col span={24}>
                <Form.Item label="Selecteer adressen" hasFeedback>
                  {(this.props.group && this.props.group.address_ids) || !this.props.match.params.groupid ? (
                    <SelectAddresses
                        initialAddresses={this.props.match.params.groupid ? this.props.group.address_ids: []}
                        onAddressSelection={(ids) => this.setState({targetKeys: ids})}
                        // childRef={ref => this.myref = ref}
                        {...this.props}
                        />
                  ) : null}
                </Form.Item>
              </Col>
              {/*<Col span={12}>
                <Form.Item required label="Selecteer adressen">
                  {this.props.isFetching || this.props.isAddressFetching ? (
                    <div>Laden...</div>
                  ) : (
                    <Transfer
                      listStyle={{
                        width: 300,
                        height: 300,
                      }}
                      dataSource={this.props.addresses.map((v,k) => { return {'key': v.id, 'title': v.full_address};})}
                      showSearch
                      targetKeys={this.state.targetKeys}
                      onChange={this.handleChange}
                      render={item => item.title}
                      />
                  )}
                </Form.Item>
                </Col>*/}
                </Row>
              </Form>
            </Card>
      </div>
    );
  }
}

const GroupDetailForm = Form.create({ name: 'horizontal_login' })(GroupDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.group.isFetching,
    isAddressFetching : state.address.isFetching,
    isError : state.group.isError,
    group: state.group.group,
    addresses: state.address.addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupAction : bindActionCreators(getGroupAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    syncAddressesGroupAction : bindActionCreators(syncAddressesGroupAction, dispatch),
    patchGroupsAction : bindActionCreators(patchGroupsAction, dispatch),
    resetGroupAction : bindActionCreators(resetGroupAction, dispatch),
    addGroupsAction : bindActionCreators(addGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailForm);
