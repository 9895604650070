import React, { Component } from 'react';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import { v1 as uuidv1 } from 'uuid';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal, Divider, Switch, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../utils/Notifications';
import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormAction, updateDeliveryFormAction, resetDeliveryFormAction } from '../../../actions/deliverActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import PageHeader from '../../../components/PageHeader/PageHeader';
import LibrarySideMenu from '../LibrarySideMenu';
import DeliverySteps from '../Steps/DeliverySteps';
import { getProjectsAction, searchProjectsAction } from '../../../actions/projectActions';
import CustomTable from '../../../components/CustomTable/CustomTable';


class LibraryDeliveryFormProjects extends Component {

  constructor(props) {
    super(props);

    this.state = {

      loading: true,

      projects: []
    };
  }

  componentDidMount()
  {
    this.props.resetDeliveryFormAction();

    this.props.getDeliveryFormAction(this.props.match.params.id).then(() => {

        this.setState({ selectedRowKeys: this.props.delivery_form.project_ids });

        this.props.getProjectsAction().then(() => {

            this.setState({ projects: this.props.projects });
            this.setState({ loading: false });
        });
    });
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleSubmit = (e, values, redirect) => {

    this.props.updateDeliveryFormAction(this.props.match.params.id, { project_ids: this.state.selectedRowKeys }).then(() => {

      showSuccess();

      this.props.history.push(`/library/delivery`);
    });
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
    };

    return (
      <div>

          <PageHeader path={[{'label': 'Bibliotheek', 'link': '#'}, {'label': 'Opleverformulieren', 'link': '#'}, {'label': 'Opleverformulier'}]} />

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <LibrarySideMenu id={this.props.match.params.id} activeMenu='20' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Button disabled={this.state.loading || !this.props.HaveAbility('library' ,'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                      <Link to={`/library/delivery`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                {/* steps */}
                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliverySteps current={2} locked={this.props.delivery_form.locked} {...this.props}/>
                    </Card>
                  </Col>
                </Row>

                <Form className={'ant-form--custom'} onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>

                    <Row gutter={24} className="mbm" style={{display: (this.props.match.params.id ? 'block' : 'none')}}>
                        <Col span={24}>

                            <Row>
                                <Col span={12}>
                                    <h2>Projecten</h2>
                                </Col>
                                <Col span={12}>
                                  <Input.Search
                                    allowClear
                                    placeholder="Zoeken..."
                                    onSearch={ (value) => { this.props.searchProjectsAction(value).then(() => { this.setState({ projects: this.props.projects }); }); } }
                                    style={{ width: 200, float: 'right' }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                              <Col span={24}>

                                <CustomTable
                                  size="middle"
                                  rowKey='id'
                                  rowSelection={rowSelection}
                                  columns={
                                      [{
                                        title: 'Project',
                                        dataIndex: 'name',
                                        sorter: (a, b) => { return a.name.localeCompare(b.name)},
                                        render: (text, record) => (
                                          <span>{text}</span>
                                        )
                                      },
                                      {
                                        title: 'Nummer',
                                        dataIndex: 'project_number',
                                        sorter: (a, b) => { return a.project_number.localeCompare(b.project_number)},
                                        sortDirections: ['descend', 'ascend'],
                                        render: (text, record) => (
                                          <span>{text}</span>
                                        )
                                      }]
                                  }
                                  dataSource={this.state.projects}
                                  />
                              </Col>
                            </Row>

                        </Col>
                    </Row>

                </Form>

            </div>

          </div>

      </div>
    );
  }
}


const LibraryDeliveryFormProjectsForm = Form.create({ name: 'delivery_form_form' })(LibraryDeliveryFormProjects);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.deliver.isFetching,
    isError : state.deliver.isError,
    delivery_form : state.deliver.form,
    projects: state.project.projects
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryFormAction : bindActionCreators(getDeliveryFormAction, dispatch),
    updateDeliveryFormAction : bindActionCreators(updateDeliveryFormAction, dispatch),
    resetDeliveryFormAction : bindActionCreators(resetDeliveryFormAction, dispatch),
    getProjectsAction : bindActionCreators(getProjectsAction, dispatch),
    searchProjectsAction : bindActionCreators(searchProjectsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryDeliveryFormProjectsForm);
