import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../SupportBaseSideMenu';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, Table, Typography, Card } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseFormByIdAction } from '../../../../../actions/supportBaseActions';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import CustomTable from '../../../../../components/CustomTable/CustomTable';

class SupportBaseCorrespondenceStatus extends React.Component {

  state = {
      //isFetching: false,
      nr_of_send: '',
      can_write: false,
  }

  constructor(props)
  {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('support-base' ,'write')
      });

    this.setState({ isFetching: true });

    this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id, false, true).then(() => {
        this.setState({
            //isFetching: false,
            nr_of_send: this.props.support_base_form.send
        });
    });
  }

  render () {

    /*const columns = [{
        title: 'Type',
        dataIndex: 'type',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Aantal',
        dataIndex: 'nr_of',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Prijs per stuk',
        dataIndex: 'price_per_item',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Totaal',
        dataIndex: 'total',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    },
    {
        title: 'Facturatie',
        dataIndex: 'invoice',
        render: (text, record) => (
          <span>
            {text}
          </span>
        )
    }];/**/

    const AddressesColumns = [
      {
        title: 'Adres',
        dataIndex: 'label'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (type, index) => {
          var text = '';

          if(type == 'letter') text = "Brief";
          if(type == 'email') text = "E-mail";

          return (
            <span>{text}</span>
          )
        }
      },
      {
        title: 'Verzonden',
        dataIndex: 'is_send',
        render: (text, index) => {
          if(text == 1) {
            return <CheckCircleOutlined style={{color: 'green'}} />;
          } else {
            return <CloseCircleOutlined style={{color: 'red'}} />;
          }

        }

      },
      {
        title: 'Download',
        dataIndex: 'download_url',
        render: (index,record) => {
          return (<a href={record.download_url + '?token=' + localStorage.authToken} target='_blank'>
            Download en bekijk
          </a>);
        }
      }
    ];

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="1"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/forms`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={24} style={{padding: '0 0 8px 0'}}>
                    <Typography.Title level={4}>STATUS PAGINA</Typography.Title>
                </Col>
              </Row>

              <Row>
                <Col span={24} style={{padding: '0 0 24px 0'}}>
                    <span>Aantal formulieren verzonden: { this.state.nr_of_send ? this.state.nr_of_send : '...' }</span>
                </Col>
              </Row>

              <Row>
                <Col span={24} style={{padding: '0 0 24px 0'}}>
                    <Card style={{backgroundColor: 'white', borderRadius: '5px', marginBottom: 24 }}>
                        <h3>Overzicht</h3>
                        <CustomTable
                          rowKey='id'
                          size="middle"
                          columns={AddressesColumns}
                          dataSource={this.props.support_base_form && this.props.support_base_form.address_stats}
                          loading={this.props.isFetching}
                        />
                    </Card>
                </Col>
              </Row>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
// const SupportBaseCorrespondenceStatusForm = Form.create({ name: 'intake_form' })(SupportBaseCorrespondenceCheck);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form: state.support_base.support_base_form,
        auth: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseCorrespondenceStatus); // Form
