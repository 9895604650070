import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { ClusterOutlined, TeamOutlined } from '@ant-design/icons';
import { Menu, Tag } from 'antd';
import { Link } from "react-router-dom";

class AccountsSideMenu extends Component {
   render() {
       return (
         <Menu
             mode="inline"
             style={{ width: 300}}
             defaultSelectedKeys={[this.props.activeMenu]}
         >
             <Menu.Item key={'1'}>
               <Link to={`/accounts`}>
                 <TeamOutlined />
                 <span>Accounts</span>
               </Link>
             </Menu.Item>
             <Menu.Item key={'2'}>
               <Link to={`/account-roles`}>
                 <ClusterOutlined />
                 <span>Rollen</span>
               </Link>
             </Menu.Item>
             <Menu.Item key={'3'}>
               <Link to={`/delivery-users`}>
                 <ClusterOutlined />
                 <span>Verantwoordelijken</span>
               </Link>
             </Menu.Item>
         </Menu>
       );
   }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsSideMenu);
