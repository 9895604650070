import api from '../utils/api'

export const getClientsAction = () => (dispatch, getState) => {

  dispatch({
    type: 'CLIENTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('get','/library/clients/'+customer_id, null, true).then((response) => {
    dispatch({
      type: 'CLIENTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CLIENTS_ACTION_ERROR',
    })
    return false;
  });
}

export const getClientAction = (clientid) => (dispatch, getState) => {

  dispatch({
    type: 'CLIENT_ACTION_FETCH'
  })

  return api('get','/library/client/'+clientid, null, true).then((response) => {
    dispatch({
      type: 'CLIENT_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CLIENTS_ACTION_ERROR',
    })
    return false;
  });
}


export const addClientAction = (values) => (dispatch, getState) => {

  dispatch({
    type: 'CLIENTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/clients/'+customer_id, values, true).then((response) => {
    dispatch({
      type: 'ADD_CLIENTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CLIENTS_ACTION_ERROR',
    })
    return false;
  });
}

export const patchClientAction = (clientid, values) => (dispatch, getState) => {

  dispatch({
    type: 'CLIENTS_ACTION_FETCH'
  })

  return api('post','/library/client/'+clientid, values, true).then((response) => {
    dispatch({
      type: 'PATCH_CLIENT_ACTION_SUCCESS',
      payload: response.data.client
    });
  }).catch((e) => {
    dispatch({
      type: 'CLIENTS_ACTION_ERROR',
    })
    return false;
  });
}

export const removeClientsAction = (values) => (dispatch, getState) => {

  dispatch({
    type: 'CLIENTS_ACTION_FETCH'
  })

  var customer_id = '';

  if(!customer_id) {
      customer_id = localStorage.getItem('customerId');
  }

  if(!customer_id && getState().auth.selectedCustomer) {
    customer_id = getState().auth.selectedCustomer.id;
  }

  return api('post','/library/clients/'+customer_id+'/multidelete', values, true).then((response) => {
    dispatch({
      type: 'REMOVE_CLIENTS_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'CLIENTS_ACTION_ERROR',
    })
    return false;
  });
}

export const searchClientsAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'CLIENTS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/clients/'+customer_id+'/search', {'search': params}, true).then((response) => {
        dispatch({
            type: 'CLIENTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CLIENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const resetClientAction = () => (dispatch, getState) => {

  dispatch({
    type: 'RESET_CLIENT_ACTION_FETCH'
  })
}
