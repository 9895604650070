export default (state = {isError: false, isFetching: false, clients: [], client: {}}, action) => {
    switch (action.type) {
    case 'CLIENTS_ACTION_FETCH':
    return {
        ...state,
        clients: [],
        isFetching: true,
        isError: false,
    }
    case 'CLIENT_ACTION_FETCH':
    return {
        ...state,
        client: [],
        isFetching: true,
        isError: false,
    }
    case 'CLIENTS_ACTION_SUCCESS':
    return {
        ...state,
        clients: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CLIENT_ACTION_SUCCESS':
    return {
        ...state,
        client: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_CLIENTS_ACTION_SUCCESS':
    return {
        ...state,
        clients: [action.payload, ...state.clients],
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_CLIENTS_ACTION_SUCCESS':
    return {
        ...state,
        clients: state.clients.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'RESET_CLIENT_ACTION_FETCH':
    return {
        ...state,
        client: {},
        isFetching: false,
        isError: false,
    }
    case 'CLIENTS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
