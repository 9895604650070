import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { DownloadOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Upload,
  message,
  Drawer,
  Card,
  Alert,
  Spin,
  Modal,
} from 'antd';
import { importAddressesAction } from '../../../actions/addressesActions';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../constants/settings';

class ProjectAddressesImports extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      importing: false,
      show_import_results: false,
      dry_run: true,
      fatal_errors: [],
      warnings: [],
      info: [],
      skipped_line_nrs: [],
      added_line_nrs: [],
      updated_line_nrs: []
    }
  }

  handleSubmit = (dry_run = true) => {

    this.setState({ importing: true });
    this.setState({ dry_run: dry_run });

    this.props.importAddressesAction(this.props.match.params.id, {'excel_sheet': this.state.importFile, 'clean_up': 0}, dry_run).then((data) => {
      //this.setState({address: this.props.address});

      this.setState({
          fatal_errors: this.props.fatal_errors,
          warnings: this.props.warnings,
          info: this.props.info,
          skipped_line_nrs: this.props.skipped_line_nrs,
          added_line_nrs: this.props.added_line_nrs,
          updated_line_nrs: this.props.updated_line_nrs
      }, () => {

          // console.log('data', data);
          // console.log('this.state.updated_line_nrs', this.state.updated_line_nrs);

          if(dry_run)
          {
            this.setState({importing: false, show_import_results: true});
          }
          else
          {
            // this.setState({address: this.props.address});
            // showSuccess('Succesvol geïmporteerd');
            this.setState({ importing: false, show_import_results: true });
            this.setState({ address: this.props.address });
            this.setState({ importFile: false });
            // this.props.history.push(`/projects/${this.props.match.params.id}/addresses`);
          }

      });
    });
  }

  render () {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <Button icon={<PlusOutlined />} type="new" disabled={this.state.importFile ? false : true} onClick={() => { this.handleSubmit(); }}>Importbestand controleren</Button>
            <Button>
              <Link to={`/projects/${this.props.match.params.id}/addresses`}>
                Sluiten
              </Link>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card>
                  <Alert
                    message="Let op"
                    description={<p>Bij het importeren worden adressen toegevoegd of bijgewerkt. Adressen worden bijgewerkt op basis van de combinatie postcode, huisnummer en toevoeging.
                        <br />
                        Het importeren gebeurt in twee stappen. In de eerste stap wordt het opgegeven bestand gecontroleerd op wat de resultaten van het importeren zal worden. In de tweede stap wordt er daadwerkelijk geimporteerd en worden de resultaten daarvan getoond.</p>}
                    type="info"
                    showIcon
                    style={{marginBottom: 24, whiteSpace: 'pre-line'}}
                  />
                  {this.state.importing ? (
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                      <Spin tip="Bezig met importeren van adressen">
                      </Spin>
                    </div>
                  ) : (
                    <Upload.Dragger accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" beforeUpload={(importFile) => {
                        this.setState({importFile});
                        return false;
                      }}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Kies een bestand</p>
                      <p className="ant-upload-hint" style={{whiteSpace: 'pre-line'}}>
                        Om een goede import uit te voeren, is het van belang om een .csv of .xls(x) bestand te uploaden.
                      </p>
                    </Upload.Dragger>
                  )}
                  <Row type="flex" justify="space-between" style={{marginTop: 24}}>
                    {/*<Button ghost type="primary" onClick={() => window.open('https://api.cobee.nl/cobee_import_sjabloon.xlsx')}><Icon type="download" /> Download sjabloon</Button>*/}
                    <Button icon={<PlusOutlined />} type="new" disabled={this.state.importFile ? false : true} onClick={() => { this.handleSubmit(); }}>Importbestand controleren</Button>
                    <Button ghost type="primary" onClick={() => { window.open(API_URL + '/addresses/'+ this.props.match.params.id +'/export/xls/?demo=true&name=Cobee_adressen_sjabloon_project_'+this.props.match.params.id+'.xlsx&token=' + localStorage.getItem('authToken'), '_blank'); }}><DownloadOutlined /> Download sjabloon</Button>
                  </Row>

            </Card>
          </Col>
        </Row>

        <Modal
            title="Import resultaten"
            visible={this.state.show_import_results}
            footer={
                <div>
                    { this.state.dry_run ?
                    <Button onClick={() => { this.setState({ show_import_results: false }); }}>Annuleren</Button>
                    :
                    <Button onClick={() => { this.setState({ show_import_results: false }); this.props.history.push(`/projects/${this.props.match.params.id}/addresses`); }}>Sluiten</Button>
                    }

                    { (!this.state.fatal_errors || this.state.fatal_errors.length <= 0) && this.state.dry_run ?
                    <Button icon={<PlusOutlined />} type="new" onClick={() => { this.setState({ show_import_results: false }); this.handleSubmit(false); }}>Nu importeren</Button>
                    : null }
                </div>
            }
            onCancel={() => {
                this.setState({ show_import_results: false });
            }}
            // cancelText="Annuleren"
            // okText="Opslaan"
            >
            <div style={{whiteSpace: 'pre-line'}}>
                { this.props.message ?
                <div>{ this.props.message }</div>
                 : (
                <div>
                    { this.state.dry_run ?
                    <h2>Stap 1: Controle van het adressen importbestand</h2>
                    :
                    <h2>Stap 2: Resultaten van de adressen import</h2> }

                    { this.state.fatal_errors && this.state.fatal_errors.length > 0 ? <div><h3 style={{ color: 'red'}}>Er hebben zich de volgende fouten voorgedaan:</h3>{this.state.fatal_errors.join("\r\n")+"\r\n\r\n"}</div> : null }

                    { this.state.warnings && this.state.warnings.length > 0 ? <div><h3 style={{ color: 'orange'}}>Er hebben zich de volgende waarschuwingen voorgedaan:</h3>{this.state.warnings.join("\r\n")+"\r\n\r\n"}</div> : null }

                    { this.state.info && this.state.info.length > 0 ? <div><h3 style={{ color: 'blue'}}>Er hebben zich de volgende meldingen voorgedaan:</h3>{this.state.info.join("\r\n")+"\r\n\r\n"}</div> : null }

                    { this.state.skipped_line_nrs && this.state.skipped_line_nrs.length > 0 ? <div><h3>De volgende {this.state.skipped_line_nrs.length} regel nummer{(this.state.skipped_line_nrs.length != 1 ? "s" : "")} {(!this.state.dry_run ? "zijn" : "worden")} overgeslagen:</h3>{this.state.skipped_line_nrs.join(", ")+"\r\n\r\n"}</div> : null }

                    { this.state.added_line_nrs && this.state.added_line_nrs.length > 0 ? <div><h3>De volgende {this.state.added_line_nrs.length} regel nummer{(this.state.added_line_nrs.length != 1 ? "s" : "")} {(!this.state.dry_run ? "zijn" : "kunnen worden")} toegevoegd:</h3>{this.state.added_line_nrs.join(", ")+"\r\n\r\n"}</div> : null }

                    { this.state.updated_line_nrs && this.state.updated_line_nrs.length > 0 ? <div><h3>De volgende {this.state.updated_line_nrs.length} regel nummer{(this.state.updated_line_nrs.length != 1 ? "s" : "")} {(!this.state.dry_run ? "zijn" : "kunnen worden")} gewijzigd:</h3>{this.state.updated_line_nrs.join(", ")+"\r\n\r\n"}</div> : null }
                </div>
                ) }

            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      message: state.address.message,
      fatal_errors: state.address.fatal_errors,
      warnings: state.address.warnings,
      info: state.address.info,
      skipped_line_nrs: state.address.skipped_line_nrs,
      added_line_nrs: state.address.added_line_nrs,
      updated_line_nrs: state.address.updated_line_nrs
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    importAddressesAction : bindActionCreators(importAddressesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddressesImports);
