import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import SupportBaseSideMenu from '../../../SupportBaseSideMenu';
import SupportBaseReminderCorrespondenceSteps from './SupportBaseReminderCorrespondenceSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Typography,
    Input,
    Tag,
    Button,
    Modal,
    Dropdown,
    Search,
    Steps,
    Divider,
    Collapse,
    Panel,
    Badge,
    Table,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    DatePicker,
    Popover,
    TimePicker,
    message,
    Tooltip,
    Select,
    Alert,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../../../utils/Notifications';
import CustomEditor from '../../../../../../components/CustomEditor/CustomEditor';
import { getSupportBaseFormByIdAction, updateSupportBaseCorrespondenceAction, checkSupportBaseFormEmailAction, getSupportBaseFormsByProjectAction, getSupportBaseFormCorrespondencesAction } from '../../../../../../actions/supportBaseActions';

const { Option } = Select;

class SupportBaseReminderCorrespondenceEmail extends React.Component {

    state = {
      subject: '',
      message: '',
      saved: true,
    }

    constructor(props) {
      super(props);
    }

    componentDidMount()
    {
         this.props.getSupportBaseFormByIdAction(this.props.match.params.form_id).then(() => {
             this.setState({
                 'subject' : this.props.support_base_form.correspondences[2].subject,
                 'message' : this.props.support_base_form.correspondences[2].message
             });
         });

         this.props.getSupportBaseFormsByProjectAction(this.props.match.params.id);
    }

    allowNextStep = () =>
    {
        var status = true;

        this.props && this.props.form && this.props.form.validateFields((errors, values) => {

            if(errors){ this.setState({ saved: false }); }

            status = (errors || !this.state.saved ? false : true);

            if(!status){ showError('Het formulier is niet correct ingevuld en opgeslagen'); }
        });

        return status;
    }

    useTexts()
    {
        var form_id = this.state.selected_form_id;

        if(!form_id)
        {
            showError('Geef een formulier op');
        }
        else
        {
            this.props.support_base_forms.map((object, index) => {
                if(object.id == form_id)
                {
                    this.props.getSupportBaseFormCorrespondencesAction(form_id).then(() => {

                        this.props.correspondences.map((correspondence, index) => {
                            if(correspondence.type == 'email')
                            { // take over message from email letter
                                this.props.form.setFieldsValue({['message']: correspondence.message});
                            }
                        });

                        showSuccess('Succesvol geplaatst');

                        this.setState({ show_copy_text_modal: false });
                    })
                }
            });
        }
    }

    saveAndContinue(do_continue = true, preview = false)
    {
      this.props.form.validateFields((errors, values) => {

          if(!errors || !do_continue)
          {
              this.props.updateSupportBaseCorrespondenceAction(this.props.match.params.form_id, 'email', {
                  'subject' : values.subject,
                  'message': values.message,
              }).then(() => {

                  showSuccess('Succesvol opgeslagen');

                  if(do_continue)
                  {
                      this.props.history.push(`/projects/${this.props.match.params.id}/support-base/reminder/${this.props.match.params.form_id}/correspondence/check`);
                  }

                  if(preview)
                  {
                      this.props.checkSupportBaseFormEmailAction(this.props.match.params.form_id);
                  }
              });
          }
      });
    }

  render () {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          {/*<SupportBaseSideMenu projectid={this.props.match.params.id} activeMenu="4"/>*/}

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/support-base/resend/compose/${this.props.match.params.form_id}`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <SupportBaseReminderCorrespondenceSteps validate={true} validator={() => { return this.allowNextStep(); }} current={1} {...this.props}/>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="In de e-mail kan de placeholder [formulier ondertekenen knop] gebruikt worden om een knop te plaatsen naar het online formulier." />
                </Col>
              </Row>

              <Form className={'ant-form--custom'} layout={'vertical'} colon={true}>

                  <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item label="Onderwerp e-mail">
                            {getFieldDecorator('subject', {
                              initialValue: this.state.subject,
                              rules: [{ required: true, message: 'Vul een onderwerp in' }],
                            })(
                              <Input />
                            )}
                        </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col align={'right'} span={24}>

                          <Button style={{marginLeft: 4}} onClick={() => {
                              this.setState({
                                  show_copy_text_modal: true,
                              });
                          }}>Tekst overnemen uit</Button>

                          <Button style={{marginLeft: 24}} onClick={() => { this.saveAndContinue(false, true); }}>Voorbeeld</Button>

                      </Col>
                  </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item required label="Tekst e-mail">
                          { getFieldDecorator('message', {
                            initialValue: this.state.message,
                            rules: [{ required: true, message: 'Vul een tekst in' }],
                            valuePropName: 'value',
                            trigger: 'onEditorChange',
                          })(
                            <CustomEditor placeholders={'supportbase'} project_id={this.props.match.params.id} />
                          ) }
                        </Form.Item>
                    </Col>
                </Row>

                <Modal
                    title="Tekst overnemen uit"
                    visible={this.state.show_copy_text_modal}
                    onCancel={() => this.setState({ show_copy_text_modal: false, selected_form_id: false })}
                    onOk={() => { this.useTexts(); }}
                    okText="Plaatsen"
                    >
                      <Row>
                          <Col span={24}>
                              <Form.Item label="Formulier">
                                  <Select
                                      style={{ width: '100%' }}
                                      onChange={(value) => { this.setState({ selected_form_id: value }); }}
                                      value={this.state.selected_form_id}
                                      >
                                      { this.props.support_base_forms && this.props.support_base_forms.map((object, index) => {
                                          if(object.id != this.props.match.params.form_id && object.has_email)
                                          {   // not this form
                                              return <Option value={object.id}>{object.name}</Option>
                                          }
                                      }) }
                                  </Select>
                              </Form.Item>
                          </Col>
                      </Row>
                </Modal>

              </Form>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const SupportBaseReminderCorrespondenceEmailForm = Form.create({ name: 'intake_form' })(SupportBaseReminderCorrespondenceEmail);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.support_base.isFetching,
        isError : state.support_base.isError,
        support_base_form : state.support_base.support_base_form,
        support_base_forms : state.support_base.forms,
        correspondences : state.support_base.correspondences,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupportBaseFormByIdAction : bindActionCreators(getSupportBaseFormByIdAction, dispatch),
        getSupportBaseFormsByProjectAction : bindActionCreators(getSupportBaseFormsByProjectAction, dispatch),
        updateSupportBaseCorrespondenceAction : bindActionCreators(updateSupportBaseCorrespondenceAction, dispatch),
        checkSupportBaseFormEmailAction : bindActionCreators(checkSupportBaseFormEmailAction, dispatch),
        getSupportBaseFormCorrespondencesAction : bindActionCreators(getSupportBaseFormCorrespondencesAction, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportBaseReminderCorrespondenceEmailForm);
