import React from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Card, Row, Col, Button, Upload, Tag, Alert, notification, Input } from 'antd';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ComposeEmailForm from '../../Form/ComposeEmailForm';
import { setCorrespondenceMethodAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, resetCorrespondenceAction } from '../../../../../actions/correspondencesActions';
import { showSuccess } from '../../../../../utils/Notifications';


class ComposeEmail extends React.Component {

  state = {
    method: {},
    file: null,
    filelist: [],
    filenames: [],
    removeFile: false,
    pdf_attachments: [],
    removeFiles: [],
    loading: true
  };

  componentDidMount() {

    this.props.resetCorrespondenceAction();

    this.loadData();
  }

  loadData()
  {
      this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

          var selected_method = this.props.correspondence.methods.find((element) => {
            if(this.props.match.params.addressid) {
              if(element.type === this.props.match.params.type && element.address_id == this.props.match.params.addressid) {
                return true;
              }
            } else {
              if(this.props.match.params.occupantid) {
                if(element.type === this.props.match.params.type && element.occupant_id == this.props.match.params.occupantid) {
                  return true;
                }
              } else {
                if(element.type === this.props.match.params.type && element.address_id == null && element.occupant_id == null) {
                  return true;
                }
              }

            }
          });

          this.props.setCorrespondenceMethodAction(selected_method);

          //
          this.setState({
              pdf_attachments: selected_method ? selected_method.pdf_attachments : []
          }, () => {

              var filenames = [];

              this.state.pdf_attachments && this.state.pdf_attachments.map && this.state.pdf_attachments.map((record, index) => {

                  if(record.filename != undefined)
                  {
                      filenames[index] = record.filename;
                  }
                  else
                  {
                      filenames[index] = '';
                  }

              });

              this.setState({ filenames: filenames, loading: false });
          });
      });
  }

  saveMethod = (go_back = true) => {

    this.composeMailForm.props.form.validateFields((errors, values) => {

      if(this.props.match.params.addressid) {
        values = {...values, 'address_id': this.props.match.params.addressid};
      }

      if(this.props.match.params.occupantid) {
        values = {...values, 'occupant_id': this.props.match.params.occupantid};
      }

      values.filenames = this.state.filenames;

      if(!errors) {

        this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {...values, 'type':this.props.match.params.type, 'remove_attachments': this.state.removeFiles}, this.state.filelist).then((response) => {

          if(response.data.success == false) {
            notification.open({
              message: 'Bijlage te groot',
              type: 'error',
              description:
                "Maximaal 4 pagina's",
              onClick: () => {
                // console.log('Notification Clicked!');
              },
            });

            return;
          }
          else
          {
              showSuccess();

              if(go_back)
              {
                  // this.setState({ filelist: [] });
                  // this.setState({ pdf_attachments: response.data.correspondence_method.pdf_attachments });
                  this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/${this.props.match.params.type == 'email' ? 3 : 2}`);
              }
              else
              {
                  this.setState({ filelist: [], removeFiles: [] }, () => {

                      this.loadData();
                  });
              }
          }
        });
      }
    })
  }

  render () {
    return (
      <div>
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Button loading={this.props.isFetching} type="new" onClick={() => { this.saveMethod() }}>Opslaan en ga terug</Button>
              <Button loading={this.props.isFetching} type="new" onClick={() => { this.saveMethod(false) }}>Opslaan</Button>
              <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/tab/${this.props.match.params.type == 'email' ? 3 : 2}`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          <Card>
            { !this.state.loading ? <ComposeEmailForm wrappedComponentRef={formRef => { this.composeMailForm = formRef }} {...this.props}/> : null }

                <Form.Item label="Bijlage">
                  <Alert style={{ marginBottom: 24 }} showIcon type="info" message="Let op" description="Er kan alleen een PDF bestand in A4 formaat als bijlage geüpload worden.
                    De bijlage mag maximaal 4 pagina's (bij enkelzijdig versturen) of 8 pagina's (bij dubbelzijdig versturen) A4 formaat bevatten. Het bestand mag niet groter zijn dan 8MB.
                    Laat je de correspondentie dubbelzijdig afdrukken? De bijlage begint op een nieuw A4."
                    />

                    { this.state.pdf_attachments && this.state.pdf_attachments.map && this.state.pdf_attachments.map((obj, file_index) => {

                        // if(obj.filename)
                        // {
                        //     this.state.filenames[file_index] = obj.filename;
                        //
                        //     this.setState({ filenames: this.state.filenames });
                        // }

                        return <div>
                            <Row className="mbm">
                                <Col span={11}>
                                    <Tag>{obj && obj.file ? obj.file : obj}</Tag>
                                </Col>
                                <Col span={11}>
                                    <Input
                                        value={this.state.filenames[file_index]}
                                        onChange={(e) => {

                                            this.state.filenames[file_index] = e.target.value;

                                            this.setState({ filenames: this.state.filenames });
                                        }}
                                        placeholder="Bestandsnaam (i.p.v. Bijlage X)"
                                        />
                                </Col>
                                <Col span={2}>
                                    <Button onClick={() => {

                                        var files = this.state.pdf_attachments;

                                        delete files[file_index];

                                        var filenames = this.state.filenames;

                                        delete filenames[file_index];

                                        this.setState({
                                            pdf_attachments: files,
                                            filenames: filenames
                                        });

                                        var remove = this.state.removeFiles;

                                        remove.push(obj);

                                        this.setState({
                                            removeFiles: remove
                                        });
                                      }}
                                      type={'link'}
                                      style={{ color: '#f5222d' }}
                                      >
                                      Verwijderen
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    }) }

                    <Upload
                        accept="application/pdf"
                        beforeUpload={(file) => {

                            if(file.size > 8000000)
                            {   // file too large
                                notification.open({
                                  message: 'Bijlage te groot',
                                  type: 'error',
                                  description:
                                    "De bijlage mag niet groter zijn dan 8MB",
                                  onClick: () => {},
                                });
                            }
                            else
                            {
                                var filelist = this.state.filelist;

                                filelist.push(file);

                                this.setState({filelist: filelist});
                            }

                            return false;
                        }}
                        onRemove={(file) => {

                          this.setState((state) => {

                            const index = state.filelist.indexOf(file);
                            const newfilelist = state.filelist.slice();
                            newfilelist.splice(index, 1);

                            return {
                              filelist: newfilelist
                            };
                          });
                        }}
                        fileList={this.state.filelist}
                        >
                        <Button><UploadOutlined />Upload een bijlage</Button>
                    </Upload>

              </Form.Item>

          </Card>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    selectedMethod : state.correspondence.selectedMethod,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    setCorrespondenceMethodAction : bindActionCreators(setCorrespondenceMethodAction, dispatch),
    resetCorrespondenceAction : bindActionCreators(resetCorrespondenceAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeEmail);
