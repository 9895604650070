import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import ChoiceSideMenu from '../ChoiceSideMenu';
import ChoiceFormSteps from './ChoiceFormSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';
// import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { /*getAddressesAction,*/ getChoiceFormByIdAction, addChoiceFormToProjectAction, updateChoiceFormAction } from '../../../../actions/choiceActions';
// import { shareAddressesAction, searchAddressesAction } from '../../../../actions/addressesActions';
import { resetAddressesAction, getAddressesAction, searchAddressesAction } from '../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class ChoiceFormAddresses extends React.Component {

  state = {

    expandedRowKeys: [],
    expandButton: true,

    selectedLetterIds: [],

    currentDataSource: [],
    expandTable: true,

    can_write: false,

    filteredInfo: null,

    loaded: false,

  }

  // example data
  examples = [{id: 1, full_address: 'Straat 1', groups: '', occupants: [{id: 1, address_id: 1, email_address: 'email@adres.nl', full_name: 'Voornaam Achternaam', description: 'Bewoner' }]}];

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write')
      });

    this.setState({ isFetching: true });

    this.props.getAddressesAction({'projectId': this.props.match.params.id}).then(() => {

      this.props.getGroupsAction({'projectId': this.props.match.params.id});

      this.setState({currentDataSource: this.props.addresses},() => {
        var expandIds = [];
        this.state.currentDataSource.map((v) => {
          if(v.occupants) {
            expandIds.push(/*'address-'+*/v.id)
          }
        });
        this.setState({expandedRowKeys: expandIds, expandButton: true});
      });
    });

    if(this.props.match.params.form_id && this.props.match.params.form_id != 'add')
    {
      this.props.getChoiceFormByIdAction(this.props.match.params.form_id, false).then(() => {

        this.setState({
          selectedLetterIds: this.props.choice_form.selection && this.props.choice_form.selection.letter,
          //selectedEmailIds: this.props.choice_form.selection && this.props.choice_form.selection.email,
        }, () => {
          this.setState({ isFetching: false, loaded: true });
        });
      });
    }
    else{ this.setState({ isFetching: false, loaded: true }); }
  }

  handleTableChange = (pagination, filters, sorter, extra) => {

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      currentDataSource: extra.currentDataSource
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  patchInformationAddresses() {}

  saveStep(url = false)
  {
      this.saveAndContinue(url);
  }

  saveAndContinue(url = false)
  {
      if(!this.state.selectedLetterIds.length /*&& !this.state.selectedEmailIds.length*/)
      {
          showError('Er zijn geen adressen geselecteerd');
      }
      else
      {
          this.props.updateChoiceFormAction(this.props.match.params.form_id, {
              letterIds: this.state.selectedLetterIds,
              // emailIds : this.state.selectedEmailIds
          }).then(() => {

              showSuccess('Succesvol opgeslagen');

              if(!url){ url = `/projects/${this.props.match.params.id}/choice/forms`; }

              // this.props.history.push(`/projects/${this.props.match.params.id}/choice/form/status/${this.props.match.params.form_id}`);
              this.props.history.push(url);
          });
      }
  }

  onSearch(value) {
    this.props.searchAddressesAction(this.props.match.params.id, {'search': value});
  }

  // onSelectChange = (selectedLetterIds) => {
  //     this.setState({ selectedLetterIds: selectedLetterIds });
  // };

  onSelectChange = (selectedRowKeys) => {

    var allSelectedAddresses = this.state.selectedLetterIds;
    var addAddresses = [];
    var removeAddresses = [];
    var newAddressesSelection = [];

    this.props.addresses.map((record) => {

        if(!selectedRowKeys.includes(record.id))
        {
            // if(!this.state.disabledIds || !this.state.disabledIds.includes || (this.state.disabledIds && this.state.disabledIds.includes && !this.state.disabledIds.includes(record.id)))
            {
                removeAddresses.push(record.id);
            }
        }
        else if(!allSelectedAddresses.includes(record.id))
        {
            newAddressesSelection.push(record.id);
        }
    });

    //
    allSelectedAddresses.map((id) => {

        if(removeAddresses.includes(id))
        {
            // do nothing
        }
        // else if(addAddresses.includes(id) && !newAddressesSelection.includes(id))
        // {
        //     newAddressesSelection.push(id);
        // }
        else if(!newAddressesSelection.includes(id))
        {
            newAddressesSelection.push(id);
        }
    });

    //
    this.setState({ selectedLetterIds: newAddressesSelection });

    //
    // this.props.onAddressSelection(newAddressesSelection);
  }

  render () {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const { getFieldDecorator } = this.props.form;

    const { selectedLetterIds } = this.state;

    // const { selectedRowKeys } = this.state;

    // let { filteredInfo } = this.state;
    // filteredInfo = filteredInfo || {};

    const rowSelection = {
        selectedRowKeys: selectedLetterIds,
        onChange: this.onSelectChange,
        // hideDefaultSelections: true,
        onSelection: this.onSelection,
        // selections: [
        //   {
        //     key: 'all-data',
        //     text: 'Selecteer alles',
        //     onSelect: () => {
        //       // this.setState({
        //       //   selectedRowKeys: this.props.address.map(value => value.id),
        //       // });

        //       this.onSelectChange(this.props.address.map(value => value.id));
        //     },
        //   },
        //   {
        //     key: 'all-data',
        //     text: 'Deselecteer alles',
        //     onSelect: () => {
        //       // this.setState({
        //       //   selectedRowKeys: [], // 0...45
        //       // });
        //       this.onSelectChange([]);
        //     },
        //   },
        // ],
        // getCheckboxProps: (record) => ({
        //     disabled: this.state.disabledIds.includes(record.id)
        // }),
    };

    const columns = [
      {
        title: <div>
            <span style={{marginRight: 4}}>Adressen</span>
            <Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedLetterIds && this.state.selectedLetterIds.length}></Badge>
        </div>,
        render: (text, record) => {
            return record.full_address
        }
    },
    {
      title: 'Postcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
      width: 100
    },
    {
      title: 'Plaats',
      dataIndex: 'city',
      key: 'city',
      width: 200
    },
    {
      title: 'Groepen',
      dataIndex: '',
      key: 'groups',
      width: 250,
      filters: this.props.groups && this.props.groups.map((elem) => {
        return {
          text: elem.name,
          value: elem.name,
        }
      }),
      // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
      onFilter: (value, record) => this.groupsFilter(value, record),
      filterMultiple: true,
      render: (text, record) =>
      record.groups && record.groups.map((v) => (<Tag color={v.color} key={v.id}>{v.name}</Tag>)
    )
  }
];

    // const rowSelection = {
    //   selectedLetterIds,
    //   onChange: this.onSelectChange,
    //   hideDefaultSelections: true,
    //   selectedRowKeys: selectedLetterIds
    // };

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write || !this.state.loaded} type="new" onClick={() => { this.saveAndContinue(); }}>Opslaan</Button>
                    <Link to={`/projects/${this.props.match.params.id}/choice/forms`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <ChoiceFormSteps current={2} saveStep={(url) => { this.saveStep(url); }} {...this.props} />
                  </Card>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={12} style={{ height: 32, padding: 5 }}>
                  <div>{ this.state.selectedLetterIds.length } / { this.props.addresses.length } adressen geselecteerd</div>
                </Col>
                <Col span={12}>
                  <Input.Search
                    placeholder="Zoeken..."
                    allowClear
                    onSearch={value => this.onSearch(value)}
                    style={{ width: 200, float: 'right' }}
                    />
                </Col>
              </Row>
              <CustomTable
                rowKey={(record) => {
                    return record.id;
                }}
                size="middle"
                columns={columns}
                dataSource={this.props.addresses.map((element) => {
                  return element;
                })}
                loading={this.state.isFetching}
                onChange={(pagination, filters, sorter, extra) => {
                  this.handleTableChange(pagination, filters, sorter, extra);
                }}
                rowSelection={rowSelection}
                />

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const ChoiceFormAddressesForm = Form.create({ name: 'choice_form' })(ChoiceFormAddresses);

const mapStateToProps = (state, ownProps) => {
    return {
        // isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        choice_form : state.choice.choice_form,
        // addresses : state.choice.addresses,
        addresses: state.address.addresses,
        groups : state.group.groups,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChoiceFormByIdAction : bindActionCreators(getChoiceFormByIdAction, dispatch),
        addChoiceFormToProjectAction : bindActionCreators(addChoiceFormToProjectAction, dispatch),
        updateChoiceFormAction : bindActionCreators(updateChoiceFormAction, dispatch),
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        // shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
        searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormAddressesForm);
