import React, { Component } from 'react';
// import './Complaints.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
// import  AddComplaintsForm from './Form/AddComplaints';
import { getComplaintsAction, removeComplaintsAction, searchComplaintsAction } from '../../../../actions/complaintsActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import CustomTable from '../../../../components/CustomTable/CustomTable';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Upload,
  Tag,
  Button,
  Drawer,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
  Search,
} from 'antd';

import moment from 'moment';
import 'moment/locale/nl';

class Complaints extends Component {
  state = {
      drawerVisible: false,
      selectedRowKeys: [],
  };

  componentDidMount() {
    this.loadData();
  }

  loadData()
  {
      this.props.getComplaintsAction(this.props.match.params.id);
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.addComplaintsform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addComplaintsAction(this.props.match.params.id, values);
  //       this.addComplaintsform.props.form.resetFields();
  //     }
  //   });
  // }

  showConfirm = () => {
      Modal.confirm({
          title: 'Weet je zeker dat je deze items wilt verwijderen?',
          content: 'Klik op JA om deze actie te bevestigen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                this.props.removeComplaintsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {

                    resolve();

                    this.setState({selectedRowKeys: []})

                    this.loadData();

                    showSuccess('Succesvol verwijderd');
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  onSearch(value) {
      this.props.searchComplaintsAction(this.props.match.params.id, {'search': value});
  }

  render() {

    const columns = [{
        title: 'Titel',
        dataIndex: 'title',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/modules/complaints/${record.id}/detail`}>
              {text}
            </Link>
          </span>
        )
    }/*, {
        title: 'Datum',
        dataIndex: 'date',
        render: (text, record) => {
          return (
            <span>
              { moment(text).format('DD/MM/YYYY') }
            </span>
          )
        },
    }, {
        title: '',
        dataIndex: 'is_published',
        render: (text, record) => (
          <span>
            <Tag color={text == 0 ? "blue" : "green" }>
              {text == 0 ? "Concept" : "Gepubliceerd" }
            </Tag>
          </span>
        ),
    }*/];
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (
      <div>
        <Row className="mbm">
          <Col span={12}>

              <Link to={`/projects/${this.props.match.params.id}/modules/complaints/add`}>
                <Button disabled={!this.props.HaveAbility('complaints', 'write')} type="new" icon={<PlusOutlined />} onClick={() => this.setState({drawerVisible: true})}>Toevoegen</Button>
              </Link>


              <Dropdown trigger={['click']} disabled={this.props.HaveAbility('complaints', 'write') && (this.state.selectedRowKeys.length > 0 ? false : true) } overlay={(
                  <Menu>
                    <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={!this.props.HaveAbility('complaints' ,'delete')} style={{ color: this.props.HaveAbility('complaints', 'delete') ? '#f5222d' : '' }}>Verwijderen</Menu.Item>
                  </Menu>
                )}>
                <Button type="secondary">
                  Acties <DownOutlined />
                </Button>
              </Dropdown>
          </Col>
          <Col span={12}>
              <Input.Search
                allowClear
                placeholder="Zoeken..."
                onSearch={value => this.onSearch(value)}
                style={{ width: 200, float: 'right' }}
              />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomTable
              rowKey='id'
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.isFetching ? [] : this.props.complaints}
              loading={this.props.isFetching}
            />
          </Col>
        </Row>
      </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.complaints.isFetching,
      isError : state.complaints.isError,
      complaints: state.complaints.complaints,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getComplaintsAction : bindActionCreators(getComplaintsAction, dispatch),
      removeComplaintsAction : bindActionCreators(removeComplaintsAction, dispatch),
      searchComplaintsAction : bindActionCreators(searchComplaintsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaints);
