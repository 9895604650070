import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess, showError } from '../../../utils/Notifications';
import { getDrawingsAction, deleteDrawingsAction, searchDrawingsAction, archiveDrawingsAction, addDrawingAction, updateDrawingAction, resetDrawingsAction } from '../../../actions/deliverActions';
import DeliverSideMenu from './DeliverSideMenu';
import { DownOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    Upload,
    Alert,
    Checkbox,
    Dropdown,
    Menu,
    Confirm,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../utils/RolesAndAbilities';

class DeliverDrawings extends Component
{
    refresh_drawings_ref = null;

    is_uploading = false;

    state = {
        disabled: true,
        edit: true,
        selectedRowKeys: [],
        is_archive: false,
        show_upload_drawing_modal: false,
        drawing_filelist: [],
        error_file_type: false,
        error_file_size: false,
        drawing_id: null,
        errors: {},
        show_drawing_preview_modal: false,
        drawing_name: false,
        drawing_url: false,
        can_write: false,
        loading: true
    };

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver' ,'write')
        });

        this.props.resetDrawingsAction();

        this.setState({ is_archive: this.isArchive() }, () => {

            this.loadData();
        })
    }

    componentWillUnmount()
    {
        clearTimeout(this.refresh_drawings_ref);
    }

    componentWillReceiveProps(nextprops)
    {
        if(this.isArchive() != this.state.is_archive)
        {
            this.setState({ is_archive: !this.state.is_archive }, () => {
                this.loadData();
            });
        }
    }

    isArchive()
    {
        var url = window.location.href;

        url = url.split('/');

        return (url[url.length - 1] == 'archive' ? true : false);
    }

    loadData()
    {
        this.props.getDrawingsAction(this.props.match.params.id, this.state.is_archive).then(() => {

            this.setState({ loading: false });

            var refresh = false;

            this.props.drawings.map((drawing) => {

                if(drawing.drawing_url == '')
                {
                    refresh = true;
                }
            });

            var that = this;

            if(refresh)
            {
                this.refresh_drawings_ref = setTimeout(() => {

                    that.loadData();

                }, 5000);
            }
        });
    }

    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });
    }

    toggleArchive(status)
    {
        this.props.resetDrawingsAction();

        this.setState({ is_archive: status }, function(){

            window.history.pushState({}, null, '/projects/'+this.props.match.params.id+'/deliver/drawings'+(this.state.is_archive ? '/archive' : ''));

            this.loadData();
        });
    }

    addNewDrawing()
    {
        this.props.form.resetFields();

        this.setState({
            drawing_id: false,
            show_upload_drawing_modal: true,
            errors: {},
            drawing_filelist: [],
        });
    }

    updateDrawing(id)
    {
        this.props.form.resetFields();

        this.props.drawings.map((record, index) => {

            if(record.id == id)
            {
                this.props.form.setFieldsValue({
                    description: record.description,
                });

                this.setState({ description: record.description });
            }
        });

        this.props.form.resetFields();

        this.setState({
            drawing_id: id,
            show_upload_drawing_modal: true,
            errors: {},
            drawing_filelist: [],
        });
    }

    handleUploadDrawing = event => {

        if(!this.is_uploading)
        {
            this.is_uploading = true;

            event.preventDefault();

            this.props.form.validateFields((errors, values) => {

                this.setState({ errors: errors });

                if(!errors)
                {   // upload

                    // var files = [];
                    // files['drawing'] = this.state.drawing_filelist[0];

                    if(this.state.drawing_id)
                    {   // update
                        this.props.updateDrawingAction(this.state.drawing_id, values, []).then(() => {

                            showSuccess('Succesvol opgeslagen');

                            //
                            this.loadData();

                            // hide
                            this.setState({ show_upload_drawing_modal: false });

                            // reset fields
                            this.props.form.resetFields();

                            //
                            this.is_uploading = false;
                        });
                    }
                    else
                    {   // add (old)
                        // this.props.addDrawingAction(this.props.match.params.id, values, files).then(() => {
                        //
                        //     showSuccess('Succesvol toegevoegd');
                        //
                        //     this.loadData();
                        // });

                        // add (new)
                        this.props.addDrawingAction(this.props.match.params.id, values, []).then((data) => {

                            showSuccess('Succesvol toegevoegd, bezig met verwerken');

                            var files = [];
                            files['drawing'] = this.state.drawing_filelist[0];

                            this.props.updateDrawingAction(data.id, values, files).then(() => {});

                            //
                            this.loadData();

                            // hide
                            this.setState({ show_upload_drawing_modal: false });

                            // reset fields
                            this.props.form.resetFields();

                            //
                            this.is_uploading = false;
                        });
                    }

                    // var this_obj = this;
                    //
                    // setTimeout(() => { this_obj.loadData() }, 1000);
                    //
                    // // hide
                    // this.setState({ show_upload_drawing_modal: false });
                    //
                    // // reset fields
                    // this.props.form.resetFields();
                }
                else
                {
                    //
                    this.is_uploading = false;
                }
            });
        }
        else
        {
            showError('Nog even geduld... De afbeelding wordt nog geupload.');
        }
    }

    deleteDrawings()
    {
        Modal.confirm({
          title: 'Wil je de geselecteerde items verwijderen?',
          content: 'Als je op JA klikt, worden alle geselecteerde tekeningen verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

                this.props.deleteDrawingsAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {

                    showSuccess('Succesvol verwijderd');

                    this.setState({
                        selectedRowKeys: []
                    });

                    this.loadData();

                    resolve();
                });

            }).catch((e) => { console.log('Error archive points', e) });
          },
          onCancel() {},
        });

        // this.props.deleteDrawingsAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {
        //     showSuccess('Succesvol gearchiveerd');
        //
        //     this.setState({
        //         selectedRowKeys: []
        //     });
        //
        //     this.loadData();
        // });
    }

    archiveDrawings()
    {
        Modal.confirm({
          title: 'Wil je de geselecteerde items archiveren?',
          content: 'Als je op JA klikt, worden alle geselecteerde punten gearchiveerd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

                this.props.archiveDrawingsAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {

                    showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));

                    this.setState({
                        selectedRowKeys: []
                    });

                    this.loadData();

                    resolve();
                });

            }).catch((e) => { console.log('Error archive drawings', e) });
          },
          onCancel() {},
        });

        // this.props.archiveDrawingsAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
        //     showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));
        //
        //     this.setState({
        //         selectedRowKeys: []
        //     });
        //
        //     this.loadData();
        // });
    }

    showPreview(drawing)
    {
        this.setState({
            drawing_url: drawing.drawing_url,
            drawing_name: drawing.description,
            show_drawing_preview_modal: true,
        });
    }

    render()
    {
        const columns = [{
            title: 'Omschrijving',
            dataIndex: 'description',
            render: (text, record) => (
                <Link disabled={!this.state.can_write} onClick={(event) => { event.preventDefault(); this.updateDrawing(record.id); }}>
                    {text}
                </Link>
            )
        },
        {
            title: 'Bestandsnaam',
            dataIndex: 'drawing_name',
            width: '300px',
            render: (text, record) => (
                <span>
                    {record.drawing_url ? text : 'Bezig met omzetten...'}
                </span>
            )
        },
        {
            title: '',
            dataIndex: 'drawing_url',
            width: '130px',
            render: (text, record) => (
                record.drawing_url != '' ?
                <Button onClick={() => { this.showPreview(record); }}>
                    Voorbeeld
                </Button>
                :
                <LoadingOutlined />
            )
        }];

        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            getCheckboxProps: (record) => ({
                disabled: record.has_deliveries
            }),
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="4"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Row className="mbm">
                        <Col span={12}>
                            <Button disabled={!this.state.can_write} type="new" style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.addNewDrawing(); }}>+ Toevoegen</Button>

                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item key="2" type="secondary" disabled={!this.state.can_write || this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'none' : 'flex')}} onClick={() => { this.archiveDrawings(); }}>Archiveren</Menu.Item>
                                  <Menu.Item key="3" type="secondary" disabled={!this.state.can_write || this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'flex' : 'none')}} onClick={() => { this.archiveDrawings(); }}>Herstellen</Menu.Item>
                                  <Menu.Divider style={{/*display: (this.state.is_archive ? 'none' : 'flex')*/}}></Menu.Divider>
                                  <Menu.Item key="1" disabled={(this.state.selectedRowKeys.length ? false : true) || !this.props.HaveAbility('deliver' ,'delete')} style={{/*display: (this.state.is_archive ? 'none' : 'flex'),*/ color: 'red'}} onClick={() => { this.deleteDrawings(); }}>Verwijderen</Menu.Item>
                                </Menu>
                              )} disabled={ this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true }>
                              <Button type="secondary">
                                Acties <DownOutlined />
                              </Button>
                            </Dropdown>

                            {/*<Button disabled={!this.state.can_write} type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.archiveDrawings(); }}>Archiveren</Button>
                            <Button disabled={!this.state.can_write} type="secondary" disabled={this.state.selectedRowKeys.length ? false : true} style={{display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.archiveDrawings(); }}>Herstellen</Button>*/}
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchDrawingsAction(this.props.match.params.id, value, this.state.is_archive); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                            />
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.toggleArchive(true); }}>Archief</Button>
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.toggleArchive(false); }}>Overzicht</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ padding: 6, paddingLeft: 0 }}>
                            Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <CustomTable
                                rowKey='id'
                                size="middle"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={this.props.drawings}
                                //loading={this.props.isFetching}
                                loading={this.state.loading}
                            />
                        </Col>
                    </Row>

                </div>

                <Modal
                    title={this.state.drawing_name}
                    centered
                    width="650"
                    footer={null}
                    visible={this.state.show_drawing_preview_modal}
                    onCancel={() => { this.setState({ show_drawing_preview_modal: false }); }}
                    >
                    <div onClick={() => { this.setState({ show_drawing_preview_modal: false }); }} style={{width: '100%', textAlign: 'center'}}>
                        <img src={this.state.drawing_url} style={{maxWidth: '600px', maxHeight: '600px'}} />
                    </div>
                </Modal>

                <Modal
                    title="Tekening uploaden"
                    visible={this.state.show_upload_drawing_modal}
                    onCancel={() => {
                        this.setState({ show_upload_drawing_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'upload_drawing',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="upload_drawing"
                        onSubmit={(event) => { this.handleUploadDrawing(event); }}
                        >
                        { !this.state.drawing_id ? <div>
                            <Row>
                              <Col span={24}>
                                <Alert style={{marginBottom: 24}} showIcon type="info" message="Let op" description="De PDF mag uit maximaal 1 pagina bestaan. Bij een PDF met meerdere pagina's wordt alleen de eerste pagina opgeslagen. PDF mag maximaal 8MB groot zijn." />
                              </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>

                                    <Form.Item
                                        multiple={false}
                                        label="Tekening uploaden"
                                        >
                                        {getFieldDecorator('drawing', {
                                            rules: [{ required: (this.state.drawing_id ? false : true), message: true }],
                                        })(
                                        <Upload
                                            accept="image/png,image/jpeg,application/pdf"
                                            showUploadList={true}
                                            fileList={this.state.drawing_filelist}
                                            defaultFileList={this.state.drawing_filelist}
                                            beforeUpload={(file, drawing_filelist) => {
                                                if(file.size > 8000000)
                                                {   // file too large
                                                    this.setState({ error_file_type: false, error_file_size: true });
                                                }
                                                else
                                                {
                                                    if(file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf')
                                                    {   // wrong file type
                                                        this.setState({ error_file_type: true, error_file_size: false });
                                                    }
                                                    else if(file.size <= 0)
                                                    {   // no file
                                                        this.setState({ error_file_type: true, error_file_size: false });
                                                    }
                                                    else if(file.size > 0)
                                                    {   // set file
                                                        drawing_filelist = [];
                                                        drawing_filelist.push(file);

                                                        this.setState({ drawing_filelist: drawing_filelist });

                                                        this.setState({ error_file_type: false, error_file_size: false });
                                                    }
                                                }

                                                return false; // do not upload automatically
                                            }}
                                            onRemove={() => {
                                                this.setState({ drawing_filelist: [] });
                                            }}
                                            >
                                            <Button>
                                                <UploadOutlined />
                                                Upload een tekening
                                            </Button>
                                        </Upload>
                                        )}
                                        { this.state.error_file_type || (this.state.errors && this.state.errors.drawing) ? (<Alert message="Upload een jpg of png bestand" type="error" showIcon />) : null }
                                        { this.state.error_file_size ? (<Alert message="Bestand mag niet groter zijn dan 8MB" type="error" showIcon />) : null }
                                    </Form.Item>

                                </Col>
                            </Row>
                        </div> : null }

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Omschrijving"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('description', {
                                        initialValue: this.state.description,
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Input />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

            </div>
        );
    }
}

const DeliverDrawingsForm = Form.create({ name: 'upload_drawing' })(DeliverDrawings);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        drawings : state.deliver.drawings,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDrawingsAction : bindActionCreators(getDrawingsAction, dispatch),
        searchDrawingsAction : bindActionCreators(searchDrawingsAction, dispatch),
        deleteDrawingsAction : bindActionCreators(deleteDrawingsAction, dispatch),
        archiveDrawingsAction : bindActionCreators(archiveDrawingsAction, dispatch),
        addDrawingAction : bindActionCreators(addDrawingAction, dispatch),
        updateDrawingAction : bindActionCreators(updateDrawingAction, dispatch),
        resetDrawingsAction : bindActionCreators(resetDrawingsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverDrawingsForm);
