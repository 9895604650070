import api from '../utils/api'

export const getCustomerDashboard = () => (dispatch, getState) => {

    dispatch({
        type: 'DASHBOARD_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/customer/' + customer_id + '/dashboard', null, true).then((response) => {
        dispatch({
            type: 'DASHBOARD_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DASHBOARD_ACTION_ERROR',
        })
        return false;
    });
}

export const getProjectDashboard = (project_id) => (dispatch) => {

    dispatch({
        type: 'DASHBOARD_ACTION_FETCH'
    })

    return api('get','/project/' + project_id + '/dashboard', null, true).then((response) => {
        dispatch({
            type: 'DASHBOARD_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'DASHBOARD_ACTION_ERROR',
        })
        return false;
    });
}
