import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid'
import SavePesetModal from '../Modals/SavePresetModal'
import IntakeSteps from '../Steps/IntakeSteps'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class ProjectIntakeDetail extends React.Component {

  state = {
    can_write: false,
  };

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('intake' ,'write')
      });
  }

  render () {
    return (
      <div>
          {/* buttons */}
          <Row className="mbm">
            <Col span={12}>
              <div>
                <Button disabled={!this.state.can_write} type="new" onClick={() => console.log(this.state)}>Opslaan en volgende</Button>
                <Link to={`/projects/${this.props.match.params.id}/intake`}>
                  <Button>Sluiten</Button>
                </Link>
              </div>
            </Col>
          </Row>

          {/* steps */}
          <Row className="mbm">
            <Col span={24}>
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <IntakeSteps current={0} {...this.props}/>
              </Card>
            </Col>
          </Row>

          {/* form */}
          <Row className="mbm">
            <Col span={24}>
              <Card>
                <Row gutter={24} className="mbm">
                  <Col span={12}>
                    <Form.Item required label="Titel">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item required label="Labels">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Voeg een label toe"></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }

  }

const mapStateToProps = (state, ownProps) => {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    HaveAbility:  bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntakeDetail);
