import React, { Component } from 'react';
// import './Chat.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getProjectAction, statusProjectsAction, patchProjectAction } from '../../../../actions/projectActions';
import { getCustomersAction } from '../../../../actions/customerActions';
import { getAccountsAction } from '../../../../actions/accountActions';
import { getClientsAction } from '../../../../actions/clientsActions';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import ChatSidemenu from './ChatSidemenu';
import { showSuccess } from '../../../../utils/Notifications'
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/nl';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Tooltip,
  Switch,
  Card,
  Checkbox,
  TimePicker,
} from 'antd';

const { Title, Text } = Typography;

const { TextArea } = Input;

const Option = Select.Option;

class ChatSettings extends Component {
  state = {
    disabled: true,
    edit: true
  };

  componentDidMount() {
    this.props.getProjectAction(this.props.match.params.id).then((data) => {

      this.setState({ show_occupant_message_between: this.props.project.show_occupant_message_between ? this.props.project.show_occupant_message_between : 'off' });

      //this.props.getAccountsAction();
      //this.props.getClientsAction();

      if(this.props.HaveRole(['super-admin'])) {
        //this.props.getCustomersAction();
      }
    });
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = e => {

    e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {

          values.monday_from_hour = values.monday_from_hour && values.monday_from_hour.format('HH:mm');
          values.monday_till_hour = values.monday_till_hour && values.monday_till_hour.format('HH:mm');
          values.tuesday_from_hour = values.tuesday_from_hour && values.tuesday_from_hour.format('HH:mm');
          values.tuesday_till_hour = values.tuesday_till_hour && values.tuesday_till_hour.format('HH:mm');
          values.wednesday_from_hour = values.wednesday_from_hour && values.wednesday_from_hour.format('HH:mm');
          values.wednesday_till_hour = values.wednesday_till_hour && values.wednesday_till_hour.format('HH:mm');
          values.thursday_from_hour = values.thursday_from_hour && values.thursday_from_hour.format('HH:mm');
          values.thursday_till_hour = values.thursday_till_hour && values.thursday_till_hour.format('HH:mm');
          values.friday_from_hour = values.friday_from_hour && values.friday_from_hour.format('HH:mm');
          values.friday_till_hour = values.friday_till_hour && values.friday_till_hour.format('HH:mm');
          values.saturday_from_hour = values.saturday_from_hour && values.saturday_from_hour.format('HH:mm');
          values.saturday_till_hour = values.saturday_till_hour && values.saturday_till_hour.format('HH:mm');
          values.sunday_from_hour = values.sunday_from_hour && values.sunday_from_hour.format('HH:mm');
          values.sunday_till_hour = values.sunday_till_hour && values.sunday_till_hour.format('HH:mm');

          this.props.patchProjectAction(this.props.match.params.id, values, this.state.filelist).then(() => {
            showSuccess();
          });
        }
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <ChatSidemenu id={this.props.match.params.id} activeMenu={["2"]} {...this.props}/>
          <Form layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('project-personalisation', 'write')}  type="new" onClick={this.handleSubmit}>Opslaan</Button>
                </div>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Notificatie</Title>
              </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                  <Form.Item>
                    <div>
                      <Text strong>Toon notificatie</Text>
                      <br />
                      {getFieldDecorator('show_occupant_message_between', {
                        initialValue: String(this.props.project.show_occupant_message_between ? this.props.project.show_occupant_message_between : 'off'),
                        onChange: (data) => { this.setState({ show_occupant_message_between: data });  }
                      })(
                        <Select
                          placeholder="Toon notificatie"
                          >
                          <Option value="on">Altijd</Option>
                          <Option value="between">Tussen bepaalde tijdstippen</Option>
                          <Option value="off">Nooit</Option>
                        </Select>
                      )}
                    </div>
                  </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} style={{display: this.state.show_occupant_message_between != 'off' ? 'block' : 'none'}}>
                <Col span={12}>
                  <Form.Item>
                    <div>
                      <Text strong>Notificatie titel</Text>
                      <br />
                      {getFieldDecorator('occupant_message_title', {
                        initialValue: this.props.project.occupant_message_title
                      })(
                        <Input></Input>
                      )}
                    </div>
                  </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} style={{display: this.state.show_occupant_message_between != 'off' ? 'block' : 'none'}}>
                <Col span={12}>
                  <Form.Item>
                    <div>
                      <Text strong>Notificatie bericht</Text>
                      <br />
                      {getFieldDecorator('occupant_message_text', {
                        initialValue: this.props.project.occupant_message_text
                      })(
                        <TextArea rows={4}></TextArea>
                      )}
                    </div>
                  </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} style={{display: this.state.show_occupant_message_between == 'between' ? 'block' : 'none'}}>
                <Col span={12}>

                      <Row className="mbs" gutter={24}>
                        <Col span={6}><b>Dag</b></Col>
                        <Col span={9}><b>Van</b></Col>
                        <Col span={9}><b>Tot</b></Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('monday', {
                              initialValue: this.props.project.monday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Maandag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('monday_from_hour', {
                              initialValue: this.props.project.monday_from_hour ? moment(this.props.project.monday_from_hour, 'HH:mm') : null,
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('monday_till_hour', {
                              initialValue: this.props.project.monday_till_hour ? moment(this.props.project.monday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('tuesday', {
                              initialValue: this.props.project.tuesday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Dinsdag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('tuesday_from_hour', {
                              initialValue: this.props.project.tuesday_from_hour ? moment(this.props.project.tuesday_from_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('tuesday_till_hour', {
                              initialValue: this.props.project.tuesday_till_hour ? moment(this.props.project.tuesday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('wednesday', {
                              initialValue: this.props.project.wednesday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Woensdag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('wednesday_from_hour', {
                              initialValue: this.props.project.wednesday_from_hour ? moment(this.props.project.wednesday_from_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('wednesday_till_hour', {
                              initialValue: this.props.project.wednesday_till_hour ? moment(this.props.project.wednesday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('thursday', {
                              initialValue: this.props.project.thursday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Donderdag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('thursday_from_hour', {
                              initialValue: this.props.project.thursday_from_hour ? moment(this.props.project.thursday_from_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('thursday_till_hour', {
                              initialValue: this.props.project.thursday_till_hour ? moment(this.props.project.thursday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('friday', {
                              initialValue: this.props.project.friday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Vrijdag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('friday_from_hour', {
                              initialValue: this.props.project.friday_from_hour ? moment(this.props.project.friday_from_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('friday_till_hour', {
                              initialValue: this.props.project.friday_till_hour ? moment(this.props.project.friday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('saturday', {
                              initialValue: this.props.project.saturday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Zaterdag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('saturday_from_hour', {
                              initialValue: this.props.project.saturday_from_hour ? moment(this.props.project.saturday_from_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('saturday_till_hour', {
                              initialValue: this.props.project.saturday_till_hour ? moment(this.props.project.saturday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item>
                            {getFieldDecorator('sunday', {
                              initialValue: this.props.project.sunday ? true : false,
                              valuePropName: 'checked'
                            })(
                              <Checkbox>
                                Zondag
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('sunday_from_hour', {
                              initialValue: this.props.project.sunday_from_hour ? moment(this.props.project.sunday_from_hour, 'HH:mm') : null,
                            })(
                              <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item>
                            {getFieldDecorator('sunday_till_hour', {
                              initialValue: this.props.project.sunday_till_hour ? moment(this.props.project.sunday_till_hour, 'HH:mm') : null
                            })(
                              <TimePicker    minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const ChatSettingsForm = Form.create({ name: 'projects_general' })(ChatSettings);


const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isCustomersFetching : state.customer.isFetching,
    isAccountsFetching : state.account.isFetching,
    isError : state.project.isError,
    project: state.project.project,
    customers: state.customer.customers,
    accounts: state.account.accounts,
    clients: state.clients.clients,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    statusProjectsAction : bindActionCreators(statusProjectsAction, dispatch),
    patchProjectAction : bindActionCreators(patchProjectAction, dispatch),
    getClientsAction : bindActionCreators(getClientsAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSettingsForm);
