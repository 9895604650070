import React from 'react'
import PropTypes from 'prop-types'

import {
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Card,
  Input,
  Checkbox,
  Popconfirm,
  InputNumber,
  Collapse,
  Tooltip,
  Select,
} from 'antd';

class SSmileyInputForm extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'}>

        <Form.Item required hasFeedback label='Label' style={{marginBottom: 0}}>
          {getFieldDecorator('label', {
            initialValue: this.props.formData && this.props.formData.label,
            rules: [{ required: true, message: 'Vul een label in' }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
            Weergave
            <Tooltip className="mhs" title='Bepaal hoe de waardering weergegeven moet worden.'>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        )} style={{marginBottom: 0}}>
          {getFieldDecorator('icon', {
            initialValue: (this.props.formData && this.props.formData.icon) || 'smiley',
            onChange: (e) => { this.props.formData.icon = e; }
          })(
            <Select>
              <Select.Option value="smiley">Smileys</Select.Option>
              <Select.Option value="star">Sterren</Select.Option>
           </Select>
          )}
        </Form.Item>

        { this.props.formData && this.props.formData.icon == 'smiley' || !this.props.formData || !this.props.formData.icon ?
        <div>
            <Form.Item label={(
              <span>
                Waarde 1 (max. waarde / blij)
                <Tooltip className="mhs" title='Welke waarde hangt er achter het eerste icoon. Voor iedere waarde die wordt ingevuld wordt er een extra icoon getoond.'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_1', {
                initialValue: this.props.formData && this.props.formData.value_1,
                onChange: (e) => { this.props.formData.value_1 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_2 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 2
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_2', {
                initialValue: this.props.formData && this.props.formData.value_2,
                onChange: (e) => { this.props.formData.value_2 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_3 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 3
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_3', {
                initialValue: this.props.formData && this.props.formData.value_3,
                onChange: (e) => { this.props.formData.value_3 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_4 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 4
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_4', {
                initialValue: this.props.formData && this.props.formData.value_4,
                onChange: (e) => { this.props.formData.value_4 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_5 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 5 (min. waarde / verdrietig)
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_5', {
                initialValue: this.props.formData && this.props.formData.value_5,
                onChange: (e) => { this.props.formData.value_5 = e.target.value; },
              })(
                <Input />
              )}
            </Form.Item>
        </div>
        :
        <div>
            <Form.Item label={(
              <span>
                Waarde 1
                <Tooltip className="mhs" title='Welke waarde hangt er achter het eerste icoon. Voor iedere waarde die wordt ingevuld wordt er een extra icoon getoond.'>
                  <InfoCircleTwoTone />
                </Tooltip>
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_1', {
                initialValue: this.props.formData && this.props.formData.value_1,
                onChange: (e) => { this.props.formData.value_1 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_2 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 2
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_2', {
                initialValue: this.props.formData && this.props.formData.value_2,
                onChange: (e) => { this.props.formData.value_2 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_3 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 3
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_3', {
                initialValue: this.props.formData && this.props.formData.value_3,
                onChange: (e) => { this.props.formData.value_3 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_4 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 4
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_4', {
                initialValue: this.props.formData && this.props.formData.value_4,
                onChange: (e) => { this.props.formData.value_4 = e.target.value; },
                rules: [{ required: (this.props.formData && this.props.formData.value_5 ? true : false), message: 'Vul een waarde in' }],
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item label={(
              <span>
                Waarde 5
              </span>
              )} style={{marginBottom: 0}}>
              {getFieldDecorator('value_5', {
                initialValue: this.props.formData && this.props.formData.value_5,
                onChange: (e) => { this.props.formData.value_5 = e.target.value; },
              })(
                <Input />
              )}
            </Form.Item>
        </div>
        }

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('hideLabels', {
            initialValue: this.props.formData && this.props.formData.hideLabels,
            valuePropName: 'checked'
          })(
            <Checkbox>Verberg waardes</Checkbox>
          )}
        </Form.Item>

        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('requiredField', {
            initialValue: this.props.formData && this.props.formData.requiredField,
            valuePropName: 'checked'
          })(
            <Checkbox>Dit veld is verplicht</Checkbox>
          )}
        </Form.Item>

      </Form>
    );
  }
}

const WrappedSSmileyInputForm = Form.create({ name: 'text_input_form' })(SSmileyInputForm);

class SSmileyInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modus: this.props.modus || '',
      formData: this.props.parseData || {},
    };
  }

  componentDidMount() {
    if(!this.props.parseData) {
      this.setState({openKeys: [this.props.id]});
    }
  }

  toggleModus = (modus) => {
    this.saveFieldValues();
    this.setState({ modus })
  }

  removeElement = () => {
    if(this.props.removeElement) {
      this.props.removeElement();
    }
  }

  saveFieldValues = () => {
    if(this.state.modus == 'edit') {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({formData: values, openKeys: []});
          this.props.onSaveData(this.props.id, values);
        }
      })
    }
  }

  onChangeCollapse = (panel) => {
    if(panel.length) {
      this.setState({modus: 'edit', openKeys: panel});
    } else {
      this.form.props.form.validateFields((error, values) => {
        if(!error) {
          this.setState({modus: 'show', openKeys: []});
        }
      });
    }
  }

  duplicateElement = (id) => {
    if(this.props.duplicateElement) {
      this.props.duplicateElement(id);
    }
  }

  renderExtraButtons = () => {
    return (
      <Button.Group>
        <Popconfirm
          icon={(<QuestionCircleOutlined />)}
          placement="top"
          onCancel={(event) => event.stopPropagation()}
          size="small"
          okType="danger"
          title={"Element verwijderen"}
          onConfirm={() => this.removeElement()}
          okText="Ja"
          cancelText="Nee"
          disabled={this.props.disabled}
        >
          <DeleteOutlined
            onClick={event => {
              // event.stopPropagation();
            }} />
        </Popconfirm>
        <PlusSquareOutlined
          style={{marginLeft: 5}}
          onClick={(event) => {
            event.stopPropagation();
            this.duplicateElement(this.props.id);
          }} />
      </Button.Group>
    );
  };

  render (){
    if(this.state.modus == 'edit' || this.state.modus == 'show') {
      return (

        <Collapse activeKey={this.state.openKeys} onChange={(panel) => this.onChangeCollapse(panel)}>
          <Collapse.Panel key={this.props.id} header={(<div style={{ display: 'inline-block', height: 20, width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>Waardering ☺</b> - {this.state.formData && this.state.formData.label || 'label'}</div>)} extra={this.renderExtraButtons()} >
            <div style={{padding: 15}}>
              <WrappedSSmileyInputForm wrappedComponentRef={(form) => this.form = form} formData={this.state.formData} />
              <Button onClick={() => this.saveFieldValues()} type="new">Opslaan</Button>
            </div>
          </Collapse.Panel>
        </Collapse>

      );
    } else {
      return (
        <div style={{display: 'flex', flex: 1, height: 50, border: '1px solid black', alignItems: 'center', justifyContent: 'center' }}>
          Waardering ☺
        </div>
      );
    }
  }
}

export default SSmileyInput;
