import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid'
import ChoiceSideMenu from '../ChoiceSideMenu';
import ChoiceFormSteps from './ChoiceFormSteps'
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { showSuccess, showError } from '../../../../utils/Notifications';
import CustomTable from '../../../../components/CustomTable/CustomTable';

// import SelectAddresses from '../../../../components/SelectAddresses/SelectAddresses';
import { getAddressesAction, getChoiceFormByIdAction, addChoiceFormToProjectAction, updateChoiceFormAction } from '../../../../actions/choiceActions';
// import { shareAddressesAction, searchAddressesAction } from '../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../actions/groupsActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

class ChoiceFormSend extends React.Component {

  state = {
    // current: 0,
    // smsModule: false,
    // letterVisible: false,
    // letterMailVisible: false,
    // emailVisible: false,
    // smsVisible: false,
    // pushVisible: false,

    expandedRowKeys: [],
    expandButton: true,

    // description: '',

    selectedLetterIds: [],
    //selectedLetterEmailIds: [],
    //selectedEmailIds: [],
    // selectedSmsIds: [],
    // selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,
    // expandedRowKeys: [],

    // sendDate: null,
    // sendTime: null,

    can_write: false,

  }

  // example data
  examples = [{id: 1, full_address: 'Straat 1', groups: '', occupants: [{id: 1, address_id: 1, email_address: 'email@adres.nl', full_name: 'Voornaam Achternaam', description: 'Bewoner' }]}];

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write')
      });

    this.setState({ isFetching: true });

    this.props.getAddressesAction(this.props.match.params.id, this.props.match.params.form_id ? this.props.match.params.form_id : 'new').then(() => {

      this.props.getGroupsAction({'projectId': this.props.match.params.id});

      this.setState({currentDataSource: this.props.addresses},() => {
        var expandIds = [];
        this.state.currentDataSource.map((v) => {
          if(v.occupants) {
            expandIds.push('address-'+v.id)
          }
        });
        this.setState({expandedRowKeys: expandIds, expandButton: true});
      });
    });

    if(this.props.match.params.form_id && this.props.match.params.form_id != 'add')
    {
      this.props.getChoiceFormByIdAction(this.props.match.params.form_id, false).then(() => {

        this.setState({
          // description: this.props.choice_form.description,
          selectedLetterIds: this.props.choice_form.selection && this.props.choice_form.selection.letter,
          selectedEmailIds: this.props.choice_form.selection && this.props.choice_form.selection.email,
        });

        this.setState({ isFetching: false });
      });
    }
    else{ this.setState({ isFetching: false }); }
  }

  patchInformationAddresses() {}

  saveAndContinue()
  {

  }

  onSelectLetterChange = (value) => {
    if(this.state.selectedLetterIds.includes(value.target.value)) {
      this.setState({ selectedLetterIds: this.state.selectedLetterIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedLetterIds: [...this.state.selectedLetterIds, value.target.value]});
    }
  }

  onSelectEmailChange = (value) => {
    if(this.state.selectedEmailIds.includes(value.target.value)) {
      this.setState({ selectedEmailIds: this.state.selectedEmailIds.filter((v) => v !== value.target.value)});
    } else {
      this.setState({ selectedEmailIds: [...this.state.selectedEmailIds, value.target.value]});
    }
  }

  onCheckLetterAll = (value) => {

    if(value.target.checked) {

      var occ_ids = [];
      this.state.currentDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.different_address) {
            occ_ids.push('occ-'+elem.id);
          }
        })
      });

      var ids = this.state.currentDataSource.map((v) => v.id);

      this.setState({ selectedLetterIds: [...ids, ...occ_ids]});
    } else {
      this.setState({ selectedLetterIds: []});
    }

  }

  onCheckEmailAll = (value) => {

    if(value.target.checked) {

      var ids = [];
      this.state.currentDataSource.map((v) => {
        v.occupants && v.occupants.map((elem) => {
          if(elem.email_address) {
            ids.push('occ-'+elem.id);
          }
        })
      });

      this.setState({ selectedEmailIds: ids});
    } else {
      this.setState({ selectedEmailIds: []});
    }
  }

  onSearch(value) {
    // this.props.searchCorrespondenceAddressesAction(this.props.match.params.id, {'search': value});
  }

  render () {

    const columns = [
      {
        title: <div>Adressen <Badge overflowCount={1000} style={{ backgroundColor: '#f5222d' }} count={this.state.selectedLetterIds && this.state.selectedLetterIds.length}></Badge></div>,
        dataIndex: 'full_address',
        key: 'street',
        render: (text, record) => {

          var valueId = record.id;
          //var disabledCheck = false;

          if(!record.address_id && record.email != ''){
            //disabledCheck = true;
          }

          if(record.email_address == undefined) {
            //disabledCheck = true;
          }

          if(record.address_id) {
            valueId = 'occ-'+record.id
          } else {
            //disabledCheck = true;
          }

          return record.full_address ? (
                <span>{record.full_address}</span>
              ) : (
                <span><UserOutlined size={30} style={{fontSize: 15}} /> {record.full_name} <Tag >{record.description ? record.description : 'Bewoner'}</Tag></span>
          );
        }
    },
    {
      title: 'Postcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
      width: 100
    },
    {
      title: 'Plaats',
      dataIndex: 'city',
      key: 'city',
      width: 200
    },
    {
      title: 'Groepen',
      dataIndex: '',
      key: 'groups',
      width: 250,
      filters: this.props.groups && this.props.groups.map((elem) => {
        return {
          text: elem.name,
          value: elem.name,
        }
      }),
      filterMultiple: true,
      onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,

      render: (text, record) =>
      record.groups && record.groups.map((v) => (<Tag color={v.color} key={v.id}>{v.name}</Tag>)
    )
  }];

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">
                <Col span={12}>
                  <div>
                    {/*<Button disabled={!this.state.can_write} onClick={() => { this.saveAndContinue(); }}>Maak correspondentie</Button>*/}
                    <Link to={"/projects/" + this.props.match.params.id + "/correspondence/add/choice/" + this.props.match.params.form_id + "/" + encodeURIComponent(this.props.choice_form ? this.props.choice_form.description : '')}
                      disabled={!this.props.HaveAbility('correspondece' ,'write')}
                      >
                      <Button disabled={!this.props.HaveAbility('correspondece' ,'write')}>Maak correspondentie</Button>
                    </Link>
                    &nbsp;
                    <Link to={`/projects/${this.props.match.params.id}/choice/forms`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row className="mbm">
                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <ChoiceFormSteps current={3} {...this.props}/>
                  </Card>
                </Col>
              </Row>

                <div>
                  <CustomTable
                    rowKey={(record) => {
                      if(record.address_id) {
                        return record.address_id + "-" + record.id;
                      } else {
                        return 'address-' + record.id;
                      }
                    }}
                    size="middle"
                    columns={columns}
                    dataSource={this.props.addresses.filter((element) => {
                      // if(element.occupants && element.occupants.length == 0) {
                      //   element.occupants = null;
                      // }
                      return this.state.selectedLetterIds && this.state.selectedLetterIds.includes(element.id) ? element : null;
                    })}
                    loading={this.state.isFetching}
                    onChange={(pagination, filters, sorter, extra) => {
                      this.setState({currentDataSource: extra.currentDataSource})
                    }}
                    />
                </div>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const ChoiceFormSendForm = Form.create({ name: 'choice_form' })(ChoiceFormSend);

const mapStateToProps = (state, ownProps) => {
    return {
        // isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        choice_form : state.choice.choice_form,
        addresses : state.choice.addresses,
        groups : state.group.groups,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChoiceFormByIdAction : bindActionCreators(getChoiceFormByIdAction, dispatch),
        addChoiceFormToProjectAction : bindActionCreators(addChoiceFormToProjectAction, dispatch),
        updateChoiceFormAction : bindActionCreators(updateChoiceFormAction, dispatch),
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        // shareAddressesAction : bindActionCreators(shareAddressesAction, dispatch),
        // searchAddressesAction : bindActionCreators(searchAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormSendForm);
