export default (state = {
    isError: false,
    isFetching: false,
    appointments: [],
    appointment: { dayparts: [] },
    dayparts: [],
    // available_times: [],
    scheduled: [],
    work_days: [],
    available_times: {},
    cluster_timeslots: [],
    addresses: [],
    first_date: null,
    clusters: [],
    first_date: '',
    free_slots: [],
    cluster_addresses: []
    },
action) => {

    switch (action.type) {
    case 'APPOINTMENTS_ACTION_FETCH':
    return {
        ...state,
        appointments: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENT_ACTION_FETCH':
    return {
        ...state,
        appointment: { dayparts: [] },
        addresses: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENTS_ACTION_SUCCESS':
    return {
        ...state,
        appointments: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_ACTION_SUCCESS':
    return {
        ...state,
        appointment: action.payload.appointment,
        dayparts: action.payload.appointment.dayparts ? action.payload.appointment.dayparts : [],
        clusters: action.payload.clusters,
        scheduled: action.payload.schedule,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_SCHEDULE_ACTION_SUCCESS':
    return {
        ...state,
        first_date: action.payload.first_date,
        // available_times: action.payload.available_times,
        scheduled: action.payload.scheduled,
        clusters: action.payload.clusters,
        work_days: action.payload.work_days,
        available_times: action.payload.available_times,
        cluster_timeslots: action.payload.cluster_timeslots,
        first_date: action.payload.first_date,
        cluster_addresses: action.payload.cluster_addresses,
        dayparts: action.payload.dayparts,
        appointment: action.payload.appointment,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_APPOINTMENT_SUCCESS':
    return {
        ...state,
        addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_FREESLOTS_ACTION_FETCH':
    return {
        ...state,
        free_slots: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENT_FREESLOTS_ACTION_SUCCESS':
    return {
        ...state,
        free_slots: action.payload.free_slots,
        isFetching: false,
        isError: false,
    }
    case 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_FETCH':
    return {
        ...state,
        /*addresses: [],*/
        isFetching: true,
        isError: false,
    }
    case 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_SUCCESS':
    return {
        ...state,
        addresses: state.addresses.map((element) => {
          if(element.id == action.payload.clusterschedule.address_id) {
            element.cluster_schedule = action.payload.clusterschedule;
          }
          return element;
        }),
        isFetching: false,
        isError: false,
    }
    case 'ADD_APPOINTMENT_ACTION_SUCCESS':
    return {
        ...state,
        appointments: [action.payload, ...state.appointments],
        appointment: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_REMOVE_SUCCESS':
    return {
        ...state,
        appointments: state.appointments.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    // case 'APPOINTMENTS_ACTION_RESET':
    // return {
    //     ...state,
    //     appointment: {},
    //     isFetching: false,
    //     isError: false,
    // }
    case 'APPOINTMENTS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'APPOINTMENT_ACTION_RESET':
    return {
        ...state,
        appointment: {},
        dayparts: [],
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'ADD_DAYPART_ACTION':

    // state.appointment.dayparts.push({'name': '', 'from_time': '', 'till_time': ''});

    return {
        ...state,
        dayparts: [...state.dayparts, {'name': '', 'from_time': '', 'till_time': '', 'max_nr_of_appointments': null}].filter(Boolean),
    }
    default:
        return state
    }
}
